import React, { Component } from "react";
import BookingBox from "../BookingBox";
import Slider from "react-slick";
import ApiURL from "../../../api/ApiURL";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InclusionExclusion from "../Sikkim_Darjeeling/InclusionExclusion_NorthSikkim_Silkroute_8N_9D";
import NorthSikkim_Silkroute_8N_9D_Page from "../../../pages/packages/Sikkim_Darjeeling/NorthSikkim_Silkroute_8N_9D_Page";
class NorthSikkim_Silkroute_8N_9D_Package extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customActiveTab: 'tab1',
      daywiseActiveTab: 'day1',
      currentPage: 1,
    };
  }

  handleCustomTabClick = (tab) => {
    this.setState({
      customActiveTab: tab,
    });
  };

  handleDayWiseTabClick = (tab) => {
    this.setState({
      daywiseActiveTab: tab,
    });
  };
  handleClick = () => {
    const currentUrl = window.location.href;
    const pageTitle = document.title;
    const message = `Hi, Please send about the Tour Package details "${pageTitle}"`;

    const whatsappLink = `https://api.whatsapp.com/send?phone=+919674738480&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
    window.open(whatsappLink, '_blank');
  };
  handlePageChange = (direction) => {
    this.setState((prevState) => {
      const newPage = prevState.currentPage + direction;
      return { currentPage: newPage };
    });
  };
  render() {
    const rows = [
      { date: '24th Sept - 30th Sept', availability: 'Available', price: '84,500' },
      { date: '01st Oct - 07th Oct', availability: 'Available', price: '84,500' },
      { date: '04th Oct - 10th Oct', availability: 'Available', price: '84,500' },
      { date: '08th Oct - 14th Oct', availability: 'Available', price: '84,500' },
      { date: '11th Oct - 17th Oct', availability: 'Available', price: '84,500' },
      { date: '15th Oct - 21st Oct', availability: 'Available', price: '84,500' },
      { date: '18th Oct - 24th Oct', availability: 'Available', price: '84,500' },
      { date: '22nd Oct - 28th Oct', availability: 'Available', price: '84,500' },
      { date: '25th Oct - 31st Oct', availability: 'Available', price: '84,500' },
      { date: '29th Oct - 04th Nov', availability: 'Available', price: '84,500' },
      { date: '1st Nov - 07th Nov', availability: 'Available', price: '84,500' },
      { date: '05th Nov - 11th Nov', availability: 'Available', price: '84,500' },
    ];

    const rowsPerPage = 6;
    const totalPages = Math.ceil(rows.length / rowsPerPage);
    const currentRows = rows.slice((this.state.currentPage - 1) * rowsPerPage, this.state.currentPage * rowsPerPage);
    const { customActiveTab, daywiseActiveTab } = this.state;
    const packageTopBannerImageURL = ApiURL.NorthSikkim_Silkroute_8N_9DImageURL + "/North-Sikkim-Tour-Pakage-with-Silkroute.webp";
    const packageTopBanner2ImageURL = ApiURL.NorthSikkim_Silkroute_8N_9DImageURL + "/North-Sikkim-Pakage-Tour-with-Silkroute.webp";
    const sightseen1ImageURL = ApiURL.NorthSikkim_Silkroute_8N_9DImageURL + "/North-Sikkim-gangtok-package-Tour.webp";
    const sightseen2ImageURL = ApiURL.NorthSikkim_Silkroute_8N_9DImageURL + "/North-Sikkim-package-Tour-Silkroute.webp";
    const sightseen3ImageURL = ApiURL.NorthSikkim_Silkroute_8N_9DImageURL + "/North-Sikkim-silkroute-Tour-package.webp";
    const sightseen4ImageURL = ApiURL.NorthSikkim_Silkroute_8N_9DImageURL + "/North-Sikkim-Tour-Package-from-NJP.webp";
    const sightseen5ImageURL = ApiURL.NorthSikkim_Silkroute_8N_9DImageURL + "/North-Sikkim-Tour-Pakage-from-Siliguri.webp";
    const sightseen6ImageURL = ApiURL.NorthSikkim_Silkroute_8N_9DImageURL + "/North-Sikkim-Package-Tour-with-Silkroute.webp";
    const sightseen7ImageURL = ApiURL.NorthSikkim_Silkroute_8N_9DImageURL + "/North-Sikkim-Tour-Itinery-Packages.webp";
    const sightseen8ImageURL = ApiURL.NorthSikkim_Silkroute_8N_9DImageURL + "/North-Sikkim-Tour-Package-Cost-with-Silkroute.webp";
    const sightseen9ImageURL = ApiURL.NorthSikkim_Silkroute_8N_9DImageURL + "/North-Sikkim-Tour-Packages-with-Silkroute.webp";
    const navDay1ImageURL = ApiURL.NorthSikkim_Silkroute_8N_9DImageURL + "/North-Sikkim-Tour-Package.webp";
    const navDay2ImageURL = ApiURL.NorthSikkim_Silkroute_8N_9DImageURL + "/North-Sikkim-Tour-Packages.webp";
    const navDay3ImageURL = ApiURL.NorthSikkim_Silkroute_8N_9DImageURL + "/North-Sikkim-Package-Tour.webp";
    const navDay4ImageURL = ApiURL.NorthSikkim_Silkroute_8N_9DImageURL + "/North-Sikkim-Tour-Itinerary.webp";
    const navDay5ImageURL = ApiURL.NorthSikkim_Silkroute_8N_9DImageURL + "/North-Sikkim-Tour-Package-Cost.webp";
    const navDay6ImageURL = ApiURL.NorthSikkim_Silkroute_8N_9DImageURL + "/North-Sikkim-Pakage-Tour-Cost.webp";
    const navDay7ImageURL = ApiURL.NorthSikkim_Silkroute_8N_9DImageURL + "/North-Sikkim-Package-Tour-Itinerary-with-Silkroute.webp";
    const navDay8ImageURL = ApiURL.NorthSikkim_Silkroute_8N_9DImageURL + "/North-Sikkim-Tour-Pakage-Cost-with-Silkroute.webp";
    const navDay9ImageURL = ApiURL.NorthSikkim_Silkroute_8N_9DImageURL + "/North-Sikkim-Tour-Package-with-Silkroute.webp";


    const settings = {
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };


    const reviewersDataList = this.props.ReviewersData;
    const reviewersDataView = reviewersDataList.map((dataItem, i) => {
      const reviewerImageURL =
        ApiURL.NorthSikkim_Silkroute_8N_9DImageURL + "/" + dataItem.ImageName;
      return (
        <div className="testimonial-box" key={i}>
          <div className="box-top">
            <div className="profile">
              <div className="profile-img">
                <img src={reviewerImageURL} alt="..." />
              </div>
              <div className="name-user">
                <strong>{dataItem.Name}</strong>
                <span>{dataItem.Location}</span>
              </div>
            </div>
            <div className="reviews">
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
            </div>
          </div>
          <div className="client-comment">
            <p>{dataItem.Comment}</p>
          </div>
        </div>
      );
    });

    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <img
              src={packageTopBannerImageURL}
              className="mobile-image2"
              alt="..."
            />
          </div>
        </div>

        <div className="container-fluid custom-container py-3">
          <div className="row">
            <div className="col-md-9">
              <div className="row">
                <div className="col-md-12">
                  <div className="container-fluid custom-container">
                    <h1 className="content-desktop" style={{ fontSize: "2.0vw" }}><center><strong>North Sikkim Tour Package with Silk Route</strong></center></h1>
                    <h1 className="content-mobile" style={{ fontSize: "4vw" }}><center><strong>North Sikkim Tour Package with Silk Route</strong></center>
                      <hr />
                    </h1>
                    <div className="row">
                      <img src={packageTopBanner2ImageURL} className="mobile-image" alt="..." />
                    </div>
                    <hr />
                    <div className="d-flex flex-column flex-sm-row align-items-sm-center px-2">
                      <div className="font-lg">
                        5 Destinations:{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Gangtok
                        </a>
                        &nbsp;{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Lachen
                        </a>{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Lachung
                        </a>{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Zuluk
                        </a>{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Rongpokhola
                        </a>
                      </div>
                      <div className="reviews mx-5">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                      </div>
                    </div>
                    <p className="p-2">
                      If you are looking for a memorable <strong>North Sikkim</strong>, you have come to the right place!
                    </p>
                    <hr />
                    <br />
                    <br />
                    <h2 className="content-desktop p-2" style={{ fontSize: "1.4vw" }}>ARE YOU LOOKING FOR <strong>NORTH SIKKIM TOUR PACKAGE WITH SILK ROUTE ?</strong> <a href='tel:9674738480' class="tomato">TO BOOK A NORTH SIKKIM TOUR PACKAGES ☎ CALL MRS. SONALI +91-96747-38480</a></h2>
                    <h2 className="content-mobile p-4" style={{ fontSize: "4.5vw" }}>ARE YOU LOOKING FOR <strong>NORTH SIKKIM TOUR PACKAGE WITH SILK ROUTE ?</strong> <a href='tel:9674738480' class="tomato">TO BOOK A NORTH SIKKIM TOUR PACKAGES ☎ CALL MRS. SONALI +91-96747-38480</a></h2>
                  </div>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>SILK ROUTE WITH NORTH SIKKIM PACKAGE TOURS OPERATORS</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>SILK ROUTE WITH NORTH SIKKIM PACKAGE TOURS OPERATORS</strong></h4>
                </div>
                <div className="card-body">
                  <p>Adorable Vacation offers an exciting and comprehensive <strong>North Sikkim Tour Package</strong> that explores the beautiful regions of the Silk Route and North Sikkim. This package provides a unique opportunity to experience the serene landscapes, rich cultural heritage, and adventure-filled activities of Sikkim. <strong>Silk Route Tour</strong> Designed for travellers seeking a blend of natural beauty and cultural exploration, the tour covers some of the most picturesque and offbeat destinations in the region.</p>
                  <p>The journey begins in Gangtok, the vibrant capital city of Sikkim, known for its beautiful monasteries, bustling markets, and stunning views of the Himalayas. From Gangtok, the tour proceeds to Lachen, a quaint village nestled amidst towering peaks. <strong>Gangtok to Silk Route Package</strong> Lachen serves as a gateway to the stunning Gurudongmar Lake, one of the highest lakes in the world, offering breath-taking views and a tranquil atmosphere. Next, the journey continues to Lachung, another charming village known for its lush greenery and the famous Yumthang Valley, also known as the "Valley of Flowers." <strong>North Sikkim Package Tour with Silk Route from Kolkata</strong> This area is a paradise for nature lovers, with its colorful rhododendron blooms and pristine landscapes.</p>
                  <p>The tour also includes a visit to Zuluk, a lesser-known gem along the historic Silk Route. <strong>Silk Route Tour Package with North Sikkim</strong> Zuluk is renowned for its unique zigzag roads and panoramic views of the Eastern Himalayas. This offbeat destination offers a peaceful retreat and a chance to witness the mesmerizing sunrise over the Kanchenjunga range. The final stop is Rongpokhola, a serene riverside destination perfect for relaxation and enjoying the natural beauty of Sikkim. <strong>Old Silk Route Tour</strong> The gurgling streams and lush surroundings make it an ideal spot for nature walks and picnics.</p>
                  <p>Adorable Vacation ensures a hassle-free experience with comfortable accommodations, guided tours, and personalized itineraries. North Sikkim Tour with Gangtok Whether you're an adventure enthusiast, a nature lover, or someone looking to immerse yourself in the local culture, this Silk route North Sikkim Tour Package offers a memorable journey through some of the most beautiful and untouched parts of Sikkim. For bookings and more information, travellers can contact Adorable Vacation directly.</p>
                  <br /> <br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen1ImageURL} alt="North-Sikkim-Package-Tour-Itinerary-with-Silkroute" />
                          <img src={sightseen2ImageURL} alt="North-Sikkim-Tour-Package-Cost-with-Silkroute" />
                          <img src={sightseen3ImageURL} alt="North-Sikkim-Tour-Package-from-NJP" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /> <br />
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div className="card-body">
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <tbody>
                        <tr>
                          <td><strong>Duration for Silk Route Tour Packages with North Sikkim</strong></td>
                          <td>8N | 9D</td>
                        </tr>
                        <tr>
                          <td>Silk Route North Sikkim Tour Package Cost</td>
                          <td>₹ 21,700/P</td>
                        </tr>
                        <tr>
                          <td>Pickup & Drop Point for <strong>Silk Route North Sikkim Tour Packages</strong></td>
                          <td>NJP Stn / Siliguri Stn / Bagdogra Airport</td>
                        </tr>
                        <tr>
                          <td>Nearest Rail Station from Silk Route</td>
                          <td>NJP / Siliguri Station</td>
                        </tr>
                        <tr>
                          <td>Nearest Bus Stand from Silk Route</td>
                          <td>Tenzing Norgay Bus Terminus, Siliguri</td>
                        </tr>
                        <tr>
                          <td>Nearest Airport from Silk Route</td>
                          <td>Bagdogra International Airport [IXB]</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br />
                  <h5>DURATION FOR <b>SILK ROUTE PACKAGE TOUR PLAN WITH GANGTOK,LACHEN,LACHUNG : 8 NIGHTS | 9 DAYS</b></h5>
                  <br />
                  <p>Destinations Covered in Silk Route North Sikkim Package Tour from NJP Station </p>
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr className="table-danger">
                          <th>Gangtok</th>
                          <th>Lachen</th>
                          <th>Lachung</th>
                          <th>Nathang</th>
                          <th>Zuluk</th>
                          <th>Rangpokhola</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td data-title="Gangtok">3 Night</td>
                          <td data-title="Lachen">1 Night</td>
                          <td data-title="Lachung">1 Night</td>
                          <td data-title="Nathang">1 Night</td>
                          <td data-title="Zuluk">1 Night</td>
                          <td data-title="Rongpokhola">1 Night</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br />
                  <p><strong>Silk Route Package Tour</strong> Pickup & Drop Points: Siliguri Bus Stand | New Jalpaiguri Station</p>
                  <br /><br /><br />
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr className="table-danger">
                          <th>PLACES</th>
                          <th>ALTITUDE</th>
                          <th>CO-ORDINATES</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td data-title="PLACES">Gangtok</td>
                          <td data-title="ALTITUDE">1,650 ft</td>
                          <td data-title="CO-ORDINATES">27.3314° N, 88.6138° E</td>
                        </tr>
                        <tr>
                          <td data-title="PLACES">Lachen</td>
                          <td data-title="ALTITUDE">8,838 ft</td>
                          <td data-title="CO-ORDINATES">27.7319° N, 88.5490° E</td>
                        </tr>
                        <tr>
                          <td data-title="PLACES">Lachung</td>
                          <td data-title="ALTITUDE">2,700 m</td>
                          <td data-title="CO-ORDINATES">27.6891° N, 88.7430° E</td>
                        </tr>
                        <tr>
                          <td data-title="PLACES">Gurudongmar Lake</td>
                          <td data-title="ALTITUDE">5,425 m</td>
                          <td data-title="CO-ORDINATES">28.0258° N, 88.7092° E</td>
                        </tr>
                        <tr>
                          <td data-title="PLACES">Yumthang Valley</td>
                          <td data-title="ALTITUDE">11,394 ft</td>
                          <td data-title="CO-ORDINATES">27.8268° N, 88.6958° E</td>
                        </tr>
                        <tr>
                          <td data-title="PLACES">Zero Point</td>
                          <td data-title="ALTITUDE">15,300 ft</td>
                          <td data-title="CO-ORDINATES">27.9305° N, 88.7346° E</td>
                        </tr>
                        <tr>
                          <td data-title="PLACES">Tsomgo Lake</td>
                          <td data-title="ALTITUDE">12,300 ft</td>
                          <td data-title="CO-ORDINATES">27.3742° N, 88.7619° E</td>
                        </tr>
                        <tr>
                          <td data-title="PLACES">SilleryGaon</td>
                          <td data-title="ALTITUDE">6,000 ft</td>
                          <td data-title="CO-ORDINATES">27.1396° N, 88.5804° E</td>
                        </tr>
                        <tr>
                          <td data-title="PLACES">Aritar</td>
                          <td data-title="ALTITUDE">1,498 m</td>
                          <td data-title="CO-ORDINATES">27.1879° N, 88.6748° E</td>
                        </tr>
                        <tr>
                          <td data-title="PLACES">Zuluk</td>
                          <td data-title="ALTITUDE">2,865 m</td>
                          <td data-title="CO-ORDINATES">27.2518° N, 88.7775° E</td>
                        </tr>
                        <tr>
                          <td data-title="PLACES">Nathang</td>
                          <td data-title="ALTITUDE">13,500 ft</td>
                          <td data-title="CO-ORDINATES">27.2986° N, 88.8173° E</td>
                        </tr>
                        <tr>
                          <td data-title="PLACES">Rongpokhola</td>
                          <td data-title="ALTITUDE">3,000 ft</td>
                          <td data-title="CO-ORDINATES">27.2215° N, 88.6892° E</td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                  <br /><br /><br />
                </div>
              </div>
              <br /><br />
              {/* start short itinerary */}

              <div className="card">
                <div className="card-body">
                  <table className="table table-bordered">
                    <thead>
                      <tr className="table-danger">
                        <th>DAY WISE SILK ROUTE NORTH SIKKIM TOUR ITINERARY</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td
                          className="clickable"
                          data-target="panelsStayOpen-headingOne"
                        >
                          <a href="#day1" style={{ color: 'black' }}><b>DAY 1 → </b>
                            <span>
                              PICKUP FROM NJP/BAGDOGRA/SILIGURI BUS STAND THEN TRANSFER TO GANGTOK
                            </span>
                          </a>
                        </td>
                      </tr>

                      <tr>
                        <td
                          className="clickable"
                          data-target="panelsStayOpen-headingTwo"
                        >
                          <a href="#day2" style={{ color: 'black' }}><b>DAY 2 → </b>
                            <span>GANGTOK TO LACHEN</span>
                          </a>

                        </td>
                      </tr>

                      <tr>
                        <td
                          className="clickable"
                          data-target="panelsStayOpen-headingThree"
                        >
                          <a href="#day3" style={{ color: 'black' }}><b>DAY 3 → </b>
                            <span>GURUDONGMAR LAKE EXCURSION - LACHUNG</span>
                          </a>
                        </td>
                      </tr>

                      <tr>
                        <td
                          className="clickable"
                          data-target="panelsStayOpen-headingFour"
                        >
                          <a href="#day4" style={{ color: 'black' }}><b>DAY 4 → </b>
                            <span>LACHUNG TO GANGTOK VIA YUMTHANG VALLEY</span>
                          </a>

                        </td>
                      </tr>

                      <tr>
                        <td
                          className="clickable"
                          data-target="panelsStayOpen-headingFive"
                        >
                          <a href="#day5" style={{ color: 'black' }}><b>DAY 5 → </b>
                            <span>FULL DAY GANGTOK CITY TOUR</span>
                          </a>
                        </td>
                      </tr>

                      <tr>
                        <td
                          className="clickable"
                          data-target="panelsStayOpen-headingSix"
                        >
                          <a href="#day6" style={{ color: 'black' }}><b>DAY 6 → </b>
                            <span>GANGTOK TO NATHANG VALLEY</span>
                          </a>
                        </td>
                      </tr>

                      <tr>
                        <td
                          className="clickable"
                          data-target="panelsStayOpen-headingSeven"
                        >
                          <a href="#day7" style={{ color: 'black' }}><b>DAY 7 → </b>
                            <span>NATHANG TO ZULUK TOUR</span>
                          </a>

                        </td>
                      </tr>

                      <tr>
                        <td
                          className="clickable"
                          data-target="panelsStayOpen-headingTen"
                        >
                          <a href="#day8" style={{ color: 'black' }}><b>DAY 8 → </b>
                            <span>ZULUK TO RONGPOKHOLA TOUR</span>
                          </a>

                        </td>
                      </tr>
                      <tr>
                        <td
                          className="clickable"
                          data-target="panelsStayOpen-headingTen"
                        >
                          <a href="#day9" style={{ color: 'black' }}><b>DAY 9 → </b>
                            <span>RONGPOKHOLA TO NJP/SILIGURI</span>
                          </a>

                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              {/* <!--end Short description--> */}

              <br /><br />
              <div className="card py-5">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>HOW TO BOOK <strong>SILK ROUTE TOUR PACKAGES WITH NORTH SIKKIM?</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>HOW TO BOOK <strong>SILK ROUTE TOUR PACKAGES WITH NORTH SIKKIM?</strong></h4>
                </div>
                <div className="card-body">
                  <p>Booking a <strong>North Sikkim silk Route Package Tour</strong> for the 2024/25 holidays is straightforward. <strong>North Sikkim with Silk Route Tour Package</strong> are easily accessible, with good road and air connections to major cities in India. The most convenient way to reach Sikkim is via Bagdogra Airport, located approximately 150 km from Gangtok, the capital city of Sikkim. Bagdogra [IXB] is a key airport in the northern part of West Bengal, handling around 30 flights daily from various Indian cities. Another significant airport is Pakyong Airport [PYG], situated just 31 km from Gangtok at an elevation of 4,646 ft.</p>
                  <p>Before booking a tour package for Lachen Lachung or the Silk Route, it's important to understand the travel logistics. Now, let's discuss how to book a <strong>Silk Route North Sikkim Package with Gangtok</strong>. You can reach out to us at Adorable Vacation, where our Sikkim specialists will assist you with all the necessary details, including tour itineraries, accommodations, homestays, transportation, sightseeing, meals, pick-up and drop-off services, COVID-19 safety protocols, dos and don'ts, and more.</p>
                  <p>After discussing your preferences and receiving your approval, we will provide you with payment details. You can secure your booking for the <strong>Silk Route Tour Package</strong> by making an initial payment, with the remaining amount to be paid closer to the travel date. In some cases, you can also make a portion of the payment upon arrival. Booking a <strong>Silk Route Package Tour with North Sikkim</strong> with Adorable Vacation is a simple and hassle-free process.</p>
                  <br /><br />

                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen4ImageURL} alt="North-Sikkim-Tour-Package-with-Silkroute" />
                          <img src={sightseen5ImageURL} alt="North-Sikkim-Package-Tour" />
                          <img src={sightseen6ImageURL} alt="Sightseeing 6" />

                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /><br />
                </div>
              </div>

              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>DISTANCES BETWEEN KEY DESTINATIONS FOR A <strong>NORTH SIKKIM PACKAGE WITH SILK ROUTE FROM GANGTOK</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>DISTANCES BETWEEN KEY DESTINATIONS FOR A <strong>NORTH SIKKIM PACKAGE WITH SILK ROUTE FROM GANGTOK</strong></h4>
                </div>
                <div className="card-body">
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr className="table-danger">
                          <th>FROM</th>
                          <th>TO</th>
                          <th>DISTANCE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td data-title="FROM">NJP</td>
                          <td data-title="TO">Gangtok</td>
                          <td data-title="DISTANCE">3 hr 54 min (118.1 km) via NH10</td>
                        </tr>
                        <tr>
                          <td data-title="FROM">Bagdogra</td>
                          <td data-title="TO">Gangtok</td>
                          <td data-title="DISTANCE">3 hr 50 min (122.2 km) via NH10</td>
                        </tr>
                        <tr>
                          <td data-title="FROM">Gangtok</td>
                          <td data-title="TO">Lachen</td>
                          <td data-title="DISTANCE">4 hr 33 min (114.0 km) via Gangtok-Chungthang Rd</td>
                        </tr>
                        <tr>
                          <td data-title="FROM">Lachen</td>
                          <td data-title="TO">Gurudongmar Lake</td>
                          <td data-title="DISTANCE">2 hr 30 min (65.9 km) via Gurudongmar Road</td>
                        </tr>
                        <tr>
                          <td data-title="FROM">Lachen</td>
                          <td data-title="TO">Lachung</td>
                          <td data-title="DISTANCE">1 hr 44 min (46.9 km) via Lachen Rd</td>
                        </tr>
                        <tr>
                          <td data-title="FROM">Lachung</td>
                          <td data-title="TO">Yumthang Valley</td>
                          <td data-title="DISTANCE">1 hr 22 min (25 km) by road</td>
                        </tr>
                        <tr>
                          <td data-title="FROM">Lachung</td>
                          <td data-title="TO">Gangtok</td>
                          <td data-title="DISTANCE">4 hr 10 min (109.2 km) via Singtam - Chungthang Rd</td>
                        </tr>
                        <tr>
                          <td data-title="FROM">Gangtok</td>
                          <td data-title="TO">Tsomgo Lake</td>
                          <td data-title="DISTANCE">1 hr 29 min (44.1 km) via Jawaharlal Nehru Rd</td>
                        </tr>
                        <tr>
                          <td data-title="FROM">Tsomgo Lake</td>
                          <td data-title="TO">Baba Mandir</td>
                          <td data-title="DISTANCE">Tsomgo Lake to Baba Mandir</td>
                        </tr>
                        <tr>
                          <td data-title="FROM">Zuluk</td>
                          <td data-title="TO">Nathang</td>
                          <td data-title="DISTANCE">1 hr (23.2 km) via Dzuluk-Nathang Valley Part I Rd</td>
                        </tr>
                        <tr>
                          <td data-title="FROM">Nathang</td>
                          <td data-title="TO">Rongpokhola</td>
                          <td data-title="DISTANCE">2 hr 47 min (61.0 km) via Rongli - Chujachen Rd</td>
                        </tr>
                        <tr>
                          <td data-title="FROM">Rongpokhola</td>
                          <td data-title="TO">NJP</td>
                          <td data-title="DISTANCE">122 km via NH10</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>HIGHLIGHTS OF A <strong>NORTH SIKKIM PACKAGE TOUR WITH SILK ROUTE FROM GANGTOK</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>HIGHLIGHTS OF A <strong>NORTH SIKKIM PACKAGE TOUR WITH SILK ROUTE FROM GANGTOK</strong></h4>
                </div>
                <div className="card-body">
                  <h5><b>1. Gangtok</b></h5><br />
                  <ul>
                    <li><b>•	Tsomgo Lake</b>: A mesmerizing glacial lake at 12,400 feet, known for its changing colors and serene beauty.</li><br />
                    <li><b>•	Nathula Pass</b>: A historic pass on the Indo-China border, offering spectacular views and a peek into cross-border trade.</li><br />
                    <li><b>•	Baba Harbhajan Singh Temple</b>: A temple and memorial honoring a revered soldier, known for its spiritual significance</li><br />
                  </ul>
                  <br />
                  <h5><b>2. Lachen</b></h5><br />
                  <ul>
                    <li><b>•	Gurudongmar Lake</b>: One of the highest lakes in the world at 17,800 feet, renowned for its clear waters and spiritual importance.</li><br />
                    <li><b>•	Thangu Valley</b>: A scenic valley offering breathtaking views, particularly during the flower-blooming season.</li><br />
                    <li><b>•	Chopta Valley</b>: A peaceful and less explored area, ideal for nature walks and enjoying the tranquil Himalayan landscape.</li>
                  </ul>
                  <br />
                  <h5><b>3. Lachung</b></h5><br />
                  <ul>
                    <li><b>• Yumthang Valley</b>: Also known as the "Valley of Flowers," famous for its vibrant blooms, hot springs, and stunning scenery.</li><br />
                    <li><b>• Zero Point</b>: A high-altitude destination at 15,300 feet, known for its snow-covered vistas year-round.</li><br />
                    <li><b>• Lachung Monastery</b>: A serene monastery offering insights into local Buddhist culture and a peaceful retreat.</li>
                  </ul>
                  <br />
                  <h5><b>4. Zuluk</b></h5><br />
                  <ul>
                    <li><b>• Zuluk Zigzag Road</b>: A unique route with numerous hairpin bends, providing panoramic views of the Eastern Himalayas.</li><br />
                    <li><b>• Thambi Viewpoint</b>: A perfect spot to witness the sunrise over the majestic Kanchenjunga range.</li><br />
                    <li><b>• Lungthung</b>: A charming village with breathtaking mountain views and a chance to experience local culture.</li>
                  </ul>
                  <br />
                  <h5><b>5. Nathang Valley</b></h5><br />
                  <ul>
                    <li><b>• Nathang Valley</b>: Often called the "Ladakh of the East," known for its stunning landscapes, especially at sunrise and sunset.</li><br />
                    <li><b>• Old Baba Mandir</b>: A revered shrine near Nathang Valley, known for its peaceful ambiance and spiritual significance.</li>
                  </ul>
                  <br />
                  <h5><b>6. Rongpokhola</b></h5><br />
                  <ul>
                    <li><b>• Rongpokhola River</b>: A tranquil riverside spot ideal for relaxation and nature appreciation, also great for trout fishing.</li>
                  </ul>
                  <br />
                  <h5><b>7. Additional Attractions</b></h5><br />
                  <ul>
                    <li><b>• Kupup Lake</b>: A sacred and beautiful high-altitude lake, close to the Yak Golf Course, one of the highest in the world.</li><br />
                    <li><b>• Elephant Lake</b>: Another picturesque lake offering serene surroundings and breathtaking views.</li>
                  </ul>
                  <br />
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>WHICH IS THE BEST TIME FOR BOOKING <strong>NORTH SIKKIM,LACHEN LACHUNG TOUR PACKAGE WITH EAST SIKKIM SILK ROUTE?</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>WHICH IS THE BEST TIME FOR BOOKING <strong>NORTH SIKKIM,LACHEN LACHUNG TOUR PACKAGE WITH EAST SIKKIM SILK ROUTE?</strong></h4>
                </div>
                <div className="card-body">
                  <p>This Silk Route Tour Package from Kolkata provides a rich experience of Sikkim's diverse landscapes, vibrant culture, and natural wonders, making it an ideal choice for travellers looking to explore both the popular and offbeat attractions of the region.</p>
                  <p>The ideal time to Silk Route Package Tour Booking depends on the experience you desire. Here are the best times to consider:</p>
                  <br />
                  <h5><b>Spring (March to May)</b></h5><br />
                  <ul>
                    <li><b>• Weather</b>: Pleasant with clear skies.</li><br />
                    <li><b>• Highlights</b>: Perfect for witnessing the blooming flowers in Yumthang Valley and enjoying lush greenery.</li><br />
                    <li><b>• Experience</b>: Great for sightseeing and outdoor activities with mild temperatures.</li>
                  </ul>
                  <br />
                  <h5><b>Summer (June to August)</b></h5><br />
                  <ul>
                    <li><b>• Weather</b>: Warm in lower regions and cooler in higher altitudes.</li><br />
                    <li><b>• Highlights</b>: Ideal for exploring vibrant green valleys and engaging in outdoor adventures.</li><br />
                    <li><b>• Experience</b>: While the scenery is beautiful, occasional rain may lead to landslides or road disruptions.</li>
                  </ul>
                  <br />
                  <h5><b>Autumn (September to November)</b></h5><br />
                  <ul>
                    <li><b>• Weather</b>: Clear skies and comfortable temperatures with minimal rain.</li><br />
                    <li><b>• Highlights</b>: Best time for panoramic views and trekking, with clear visibility of the Himalayan peaks.</li><br />
                    <li><b>• Experience</b>: Excellent for a smooth travel experience with stable weather.</li>
                  </ul>
                  <br />
                  <h5><b>Winter (December to February)</b></h5><br />
                  <ul>
                    <li><b>• Weather</b>: Cold, especially at higher elevations, with snowfall in some areas.</li><br />
                    <li><b>• Highlights</b>: Offers stunning snow-covered landscapes and the possibility of experiencing snowfall.</li><br />
                    <li><b>• Experience</b>: Ideal for those who enjoy winter scenery, though some high-altitude areas may be less accessible.</li>
                  </ul>
                  <br />
                  <h5><b>Conclusion</b></h5><br />
                  <ul>
                    <li><b>• Optimal Times</b>: <b>Spring (March to May)</b> and <b>Autumn (September to November)</b> are the best seasons for booking this tour, providing a perfect blend of pleasant weather and accessible routes.</li><br />
                    <li><b>• Winter Enthusiasts</b>: If you love snow and winter landscapes, consider traveling between <b>December and February</b>, but be prepared for cold weather and possible travel challenges.</li>
                  </ul>
                  <br />
                  <p>Booking in advance, especially during peak seasons, is recommended to ensure availability and the best travel arrangements.</p><br />
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr className="table-danger">
                          <th>TRAVEL MONTHS</th>
                          <th>WEATHER</th>
                          <th>TEMPERATURE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td data-title="MONTHS">April to June</td>
                          <td data-title="WEATHER">Summer</td>
                          <td data-title="TEMPERATURE">9°C to 15°C</td>
                        </tr>
                        <tr>
                          <td data-title="MONTHS">July to September</td>
                          <td data-title="WEATHER">Monsoon</td>
                          <td data-title="TEMPERATURE">8°C to 10°C</td>
                        </tr>
                        <tr>
                          <td data-title="MONTHS">October to March</td>
                          <td data-title="WEATHER">Winter</td>
                          <td data-title="TEMPERATURE">-10° C to 4° C</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>TRAVEL TIPS FOR SILK ROUTE TOUR FROM NJP</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>TRAVEL TIPS FOR SILK ROUTE TOUR FROM NJP</h4>
                </div>
                <div className="card-body">
                  <h5><b>1. Pack Appropriately</b></h5><br />
                  <ul>
                    <li><b>• Clothing</b>: Carry layers, including warm clothes, as temperatures can vary significantly. Include thermal wear, jackets, and rain gear, especially if traveling during monsoon or winter.</li><br />
                    <li><b>• Footwear</b>: Comfortable and sturdy walking shoes are essential for exploring the hilly terrain.</li><br />
                    <li><b>• Accessories</b>: Sunglasses, hats, and sunscreen for protection against the sun. Don't forget essentials like gloves and scarves during winter.</li>
                  </ul><br />

                  <h5><b>2. Travel Documents</b></h5><br />
                  <ul>
                    <li><b>• Documentation</b>: Keep a copy of your identity proof, permits, and other necessary documents. Foreign tourists may need special permits to visit certain areas along the Silk Route.</li>
                  </ul><br />

                  <h5><b>3. Health Precautions</b></h5><br />
                  <ul>
                    <li><b>• Altitude Sickness</b>: Altitude sickness can be a concern in high-altitude areas. It's advisable to acclimatize properly and stay hydrated. Carry any necessary medications and a basic first-aid kit.</li>
                  </ul><br />

                  <h5><b>4. Transportation</b></h5><br />
                  <ul>
                    <li><b>• Air Travel</b>: The nearest major airport is Bagdogra (IXB), and Pakyong Airport (PYG) offers another option closer to Gangtok. From Kolkata, flights to these airports are convenient.</li><br />
                    <li><b>• Road Travel</b>: For road travel, hire a reliable local driver familiar with the mountainous terrain. The roads can be narrow and winding, so experienced driving is crucial.</li>
                  </ul><br />

                  <h5><b>5. Currency and Payments</b></h5><br />
                  <ul>
                    <li><b>• Cash</b>: Carry sufficient cash, as ATMs may not be available in remote areas. Some homestays and small vendors may not accept card payments.</li>
                  </ul><br />

                  <h5><b>6. Local Cuisine</b></h5><br />
                  <ul>
                    <li><b>• Sikkimese Dishes</b>: Try local Sikkimese cuisine, which includes dishes like momo, thukpa, and gundruk. Be mindful of food hygiene and stick to bottled or purified water.</li>
                  </ul><br />

                  <h5><b>7. Cultural Respect</b></h5><br />
                  <ul>
                    <li><b>• Customs</b>: Respect local customs and traditions. Dress modestly, especially when visiting monasteries and other religious sites. Always ask for permission before taking photos of people.</li>
                  </ul><br />

                  <h5><b>8. Connectivity</b></h5><br />
                  <ul>
                    <li><b>• Network</b>: Mobile network coverage can be spotty in remote areas. Inform your family and friends about limited connectivity, and carry a power bank for charging devices.</li>
                  </ul><br />

                  <h5><b>9. Sustainable Travel</b></h5><br />
                  <ul>
                    <li><b>• Environmental Responsibility</b>: Avoid using plastic and dispose of waste responsibly. Support local businesses by purchasing souvenirs and goods from local artisans.</li>
                  </ul><br />

                  <h5><b>10. Weather Awareness</b></h5><br />
                  <ul>
                    <li><b>• Weather Forecast</b>: Check the weather forecast before your trip and be prepared for sudden changes in weather, especially in mountainous regions.</li>
                  </ul><br />
                  <p>By following these tips, you can ensure a smooth and enjoyable experience on your <strong>North Sikkim Tour Plan</strong>.</p>
                  <br />
                  <h5><b>TRAVEL TIPS FOR A NORTH SIKKIM PACKAGE TOUR WITH SILK ROUTE SILK ROUTE FROM KOLKATA</b></h5><br />
                  <h5><b>1. Plan and Prepare</b></h5><br />
                  <ul>
                    <li><b>• Booking</b>: Book your tour package and accommodations in advance, especially during peak seasons.</li><br />
                    <li><b>• Permits</b>: Obtain the necessary permits for North Sikkim, as areas like Lachen and Lachung require special permissions. Your tour operator can usually assist with this.</li>
                  </ul><br />

                  <h5><b>2. Transportation</b></h5><br />
                  <ul>
                    <li><b>• Getting There</b>: Fly into Bagdogra Airport, the nearest airport to Gangtok, or take a train to New Jalpaiguri (NJP) Railway Station.</li><br />
                    <li><b>• Local Travel</b>: Arrange for a reliable vehicle for the journey from Gangtok to Lachen and Lachung, as the roads can be challenging.</li>
                  </ul><br />

                  <h5><b>3. Accommodation</b></h5><br />
                  <ul>
                    <li><b>• Variety</b>: Options range from hotels to guesthouses and homestays. Verify the amenities provided, such as heating and hot water, especially in remote areas.</li><br />
                    <li><b>• Comfort</b>: Choose accommodations with basic comforts and good reviews.</li>
                  </ul><br />

                  <h5><b>4. Packing Essentials</b></h5><br />
                  <ul>
                    <li><b>• Clothing</b>: Pack warm clothing, including thermal wear, jackets, hats, gloves, and scarves. The weather can be cold, especially in the higher altitudes.</li><br />
                    <li><b>• Footwear</b>: Bring sturdy, comfortable shoes suitable for walking and trekking.</li><br />
                    <li><b>• Accessories</b>: Sunglasses, sunscreen, lip balm, and a good moisturizer are essential due to the dry air and strong sunlight.</li>
                  </ul><br />

                  <h5><b>5. Health and Safety</b></h5><br />
                  <ul>
                    <li><b>• Altitude Sickness</b>: Gradually acclimatize to higher altitudes to prevent altitude sickness. Drink plenty of water and avoid overexertion.</li><br />
                    <li><b>• Medical Kit</b>: Carry a basic first-aid kit and any personal medications. Include remedies for motion sickness if you're prone to it.</li><br />
                    <li><b>• Health Precautions</b>: Be cautious with food and water. Drink bottled water and eat at reputable places to avoid stomach issues.</li>
                  </ul><br />

                  <h5><b>6. Weather Preparedness</b></h5><br />
                  <ul>
                    <li><b>• Seasonal Considerations</b>: North Sikkim experiences harsh winters and monsoons. The best time to visit is between March to June and September to November.</li><br />
                    <li><b>• Rain Gear</b>: During the monsoon season, pack raincoats and umbrellas as the region can experience heavy rainfall.</li>
                  </ul><br />

                  <h5><b>7. Connectivity</b></h5><br />
                  <ul>
                    <li><b>• Mobile Network</b>: Expect limited mobile network coverage, especially in remote areas. BSNL and Airtel are the most reliable networks.</li><br />
                    <li><b>• Power Banks</b>: Carry power banks as electricity supply can be inconsistent.</li>
                  </ul><br />

                  <h5><b>8. Local Cuisine</b></h5><br />
                  <ul>
                    <li><b>• Food</b>: Try local dishes like momo, thukpa, and Gundruk. However, avoid eating at unhygienic places to prevent foodborne illnesses.</li><br />
                    <li><b>• Snacks</b>: Bring snacks and bottled water, particularly for long journeys.</li>
                  </ul><br />

                  <h5><b>9. Cultural Respect</b></h5><br />
                  <ul>
                    <li><b>• Etiquette</b>: Respect local customs and traditions. Be polite and courteous to the locals.</li><br />
                    <li><b>• Photography</b>: Ask for permission before taking photos of people or private areas.</li>
                  </ul><br />

                  <h5><b>10. Environmental Responsibility</b></h5><br />
                  <ul>
                    <li><b>• Sustainability</b>: Avoid using plastic, do not litter, and leave no trace. Dispose of waste properly.</li><br />
                    <li><b>• Wildlife and Nature</b>: Respect the natural environment and wildlife. Avoid disturbing the local flora and fauna.</li>
                  </ul><br />

                  <h5><b>11. Financial Preparedness</b></h5><br />
                  <ul>
                    <li><b>• Cash</b>: Carry sufficient cash, as ATMs are scarce in remote areas. Smaller denominations are useful for local purchases.</li><br />
                    <li><b>• Payments</b>: Confirm whether your accommodations and local vendors accept digital payments.</li>
                  </ul><br />

                  <h5><b>12. Local Guidance and Information</b></h5><br />
                  <ul>
                    <li><b>• Tour Guides</b>: Consider hiring local guides for their knowledge and insights about the area.</li><br />
                    <li><b>• Emergency Contacts</b>: Keep a list of emergency contacts, including local hospitals or clinics, tour operator contacts, and consulate numbers if you're a foreign tourist.</li>
                  </ul><br />

                  <h5><b>13. Activities and Sightseeing</b></h5><br />
                  <ul>
                    <li><b>• Must-Visit Sites</b>: Include Gurudongmar Lake, Yumthang Valley, Zero Point, and local monasteries in your itinerary.</li><br />
                    <li><b>• Photography</b>: The region offers stunning landscapes, so keep your camera ready but be respectful of local customs and private property.</li>
                  </ul>
                  <br />
                  <p>By following these tips, you can have a safe, comfortable, and memorable trip to North Sikkim and Gangtok</p>
                  <br /> <br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen7ImageURL} alt="North-Sikkim-Package-Tour-Itinerary-with-Silkroute" />
                          <img src={sightseen8ImageURL} alt="North-Sikkim-Tour-Package-Cost-with-Silkroute" />
                          <img src={sightseen9ImageURL} alt="North-Sikkim-Tour-Package-from-NJP" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /> <br />
                </div>
              </div>
              <br /><br /><br />
              <div className="row py-5">
                <div className="col-md-12">
                  <div className="site-heading text-center">
                    <h2>
                      DAY WISE <strong><span>NORTH SIKKIM PACKAGE TOUR </span></strong>
                    </h2>
                    <h4>8 Nights 9 Days North Sikkim Package Tour Itineraries</h4>
                  </div>
                </div>
              </div>

              <div className="card">
                <div
                  className="accordion"
                  id="accordionPanelsStayOpenExample"
                >
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="day1"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseOne"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseOne"
                      >
                        DAY 01: Arrival at Bagdogra/NJP and Transfer to Gangtok [4 hr 15 min (122.2 km)]
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingOne"
                    >
                      <div className="accordion-body">
                        <p>Upon arrival at Bagdogra Airport (IXB), Siliguri Station, or NJP Station, you will be greeted and transferred to Gangtok. As you drive, the scenic Teesta River accompanies you. Upon arrival in Gangtok (altitude 1,650 m / 5550 ft), check into your hotel. In the evening, take a leisurely stroll around the neighborhood or explore MG Marg for some shopping. Overnight stay at a hotel in Gangtok.</p>
                        <br /> <br />
                        <div className="content-desktop">
                          <center><img src={navDay1ImageURL} alt="bhutan tour package from mumbai and transfer to thimphu" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay1ImageURL} style={{ width: '320px' }} alt="bhutan tour package from mumbai and transfer to thimphu" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="day2"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseTwo"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseTwo"
                      >
                        DAY 02: Gangtok to Lachen Transfer [4 hr 57 min (120.4 km)]
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseTwo"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingTwo"
                    >
                      <div className="accordion-body">
                        <p>
                          After breakfast, depart from Gangtok around 10 a.m. and head towards Lachen via NH 31A. Along the way, visit several scenic spots, including:
                        </p>
                        <ul>
                          <li><b>•	Tashi View Point</b>: Offers panoramic views of Mt. Khangchendzonga.</li><br />
                          <li><b>•	Kabi Longstok</b>: A historical site marking the signing of a treaty between the Lepcha and Bhutia chiefs.</li><br />
                          <li><b>•	Seven Sister Waterfalls</b>: Named for its seven stages, this waterfall is a beautiful stop with facilities for visitors.</li><br />
                          <li><b>•	Naga Waterfalls</b>: See the confluence of the Lachen-Chu and Lachung-Chu rivers at Chungthang.</li>
                        </ul><br />
                        <p>Enjoy lunch at Namok and continue to Lachen, arriving by evening. Overnight stay at a hotel or homestay in Lachen.</p>
                        <br /><br />
                        <div className="content-desktop">
                          <center><img src={navDay2ImageURL} alt="bhutan package tour booking from mumbai city airport explore buddha dordenma" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay2ImageURL} style={{ width: '320px' }} alt="bhutan package tour booking from mumbai city airport explore buddha dordenma" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="day3"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseThree"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        DAY 03: Lachen to Gurudongmar Lake and Transfer to Lachung
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseThree"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingThree"
                    >
                      <div className="accordion-body">
                        <p>Start early for a visit to Gurudongmar Lake, situated at an altitude of 5,154 m, considered sacred by Buddhists and Hindus. The lake is known for its stunning, milky-blue waters. After the visit, return to Lachen for lunch and then transfer to Lachung (1 hr 55 min, 46.9 km). Check into your hotel or homestay in Lachung for an overnight stay.
                        </p>
                        <br />  <br />
                        <div className="content-desktop">
                          <center><img src={navDay3ImageURL} alt="with bhutan package tour from mumbai with direct flight explore dochula pass" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay3ImageURL} style={{ width: '320px' }} alt="with bhutan package tour from mumbai with direct flight explore dochula pass" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="day4"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseFour"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        Day 04: - Lachung to Gangtok via Yumthang Valley Excursion
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseFour"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingFour"
                    >
                      <div className="accordion-body">
                        <p>After breakfast, visit the picturesque Yumthang Valley, located 25 km from Lachung at an altitude of 11,800 ft. The valley is known for its blooming rhododendrons and beautiful landscapes. You can also visit Zero Point, located 23 km away at an altitude of 15,000 ft (additional cost and subject to road conditions). After exploring Yumthang Valley, head back to Gangtok for an overnight stay.</p>

                        <br /><br />
                        <div className="content-desktop">
                          <center><img src={navDay4ImageURL} alt="bhutan package tour from mumbai and explore phobjhika valley" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay4ImageURL} style={{ width: '320px' }} alt="bhutan package tour from mumbai and explore phobjhika valley" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="day5"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseFive"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        DAY 05: Full Day Gangtok City Tour
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseFive"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingFive"
                    >
                      <div className="accordion-body">
                        <p>
                          After breakfast, explore the attractions in and around Gangtok, including:
                        </p><br />
                        <ul>
                          <li><b>•	Tashi View Point</b>: Known for its views of Mt. Khangchendzonga.</li><br />
                          <li><b>• Gonjang Monastery</b>: Established in 1981, it follows the Jangter tradition of Nyingmapa Tibetan Buddhism.</li><br />
                          <li><b>• Do-drul Chorten</b>: A stupa built in 1945, surrounded by 108 prayer wheels.</li><br />
                          <li><b>• Namgyal Institute of Tibetology</b>: A renowned research center with a collection of rare manuscripts and Buddhist artifacts.</li><br />
                          <li><b>• Orchid House</b>: Home to 250 exotic species of orchids.</li><br />
                          <li><b>• Hur Huray Dara (Nam Nang View Point)</b>: Offers panoramic views of lush forests and valleys.</li><br />
                          <li><b>• Tsuklakhang (Palace Monastery)</b>: Located inside the Chogyals' palace, featuring interesting murals.</li><br />
                          <li><b>• White Memorial Hall</b>: A British-era structure built in 1932.</li><br />
                          <li><b>• Flower Show Hall</b>: A permanent exhibition of Sikkim's diverse flora.</li><br />
                          <li><b>• Gangtok Ropeway</b>: Enjoy breathtaking views of the mountains and valley.</li>
                        </ul>
                        <p>Spend the evening shopping at MG Marg. Overnight stay in Gangtok.</p>
                        <br />
                        <div className="content-desktop">
                          <center><img src={navDay5ImageURL} alt="with bhutan packages from mumbai explore punkha suspension bridge" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay5ImageURL} style={{ width: '320px' }} alt="with bhutan packages from mumbai explore punkha suspension bridge" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="day6"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseSix"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        DAY 06: Gangtok to Nathang Transfer
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseSix"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingSix"
                    >
                      <div className="accordion-body">
                        <p>
                          After breakfast, depart for Nathang. En route, visit Changu (Tsomgo) Lake, New Baba Mandir, Kupup Lake, Nathula Pass Gate, and Tukla Valley. Arrive in Nathang Valley for an overnight stay.
                        </p>

                        <br />
                        <div className="content-desktop">
                          <center><img src={navDay6ImageURL} alt="bhutan package tour booking from-mumbai and explore tigers nest monastery" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay6ImageURL} style={{ width: '320px' }} alt="bhutan package tour booking from-mumbai and explore tigers nest monastery" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="day7"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseSeven"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        DAY 07: Nathang to Zuluk Tour
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseSeven"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingSeven"
                    >
                      <div className="accordion-body">
                        <p>
                          After breakfast, explore Nathang Valley, Kalapokhri, Laxman Chawk, Mamecho Lake View Point, Old Baba Mandir, Lungthung, Thambi View Point, and the famous 95 Hairpin Bends of the Old Silk Route. End the day at Upper Zuluk, known for its serene beauty. Overnight stay in Zuluk.
                        </p>

                        <br />
                        <div className="content-desktop">
                          <center><img src={navDay7ImageURL} alt="bhutan package tour cost from mumbai and explore chele la pass" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay7ImageURL} style={{ width: '320px' }} alt="bhutan package tour cost from mumbai and explore chele la pass" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="day8"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseTen"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        DAY 08: Zuluk to Rongpokhola [2 hr 1 min / 41.1 km]
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseTen"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingTen"
                    >
                      <div className="accordion-body">
                        <p>
                          After breakfast, head towards Rongpokhola. Visit notable spots like:
                        </p>
                        <ul>
                          <li><b>•	Rongli</b>: A township known for its dam and permit office for travel towards Nathula.</li><br />
                          <li><b>•	Rongli Dam</b>: A significant structure on the Rongli River.</li>
                          <br />
                          <li><b>•	Lingtam</b>: A peaceful village with the Lingtam Monastery.</li><br />
                          <li><b>•	Keukhela Falls</b>: A beautiful waterfall on the Silk Route.</li>
                        </ul>
                        <br />
                        <p>Arrive in Rongpokhola, enjoy the serene surroundings, and stay overnight at a homestay.</p>
                        <br />
                        <div className="content-desktop">
                          <center><img src={navDay8ImageURL} alt="bhutan package tour cost from mumbai and explore chele la pass" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay8ImageURL} style={{ width: '320px' }} alt="bhutan package tour cost from mumbai and explore chele la pass" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="day8"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseTen"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        DAY 09: Rongpokhola to NJP/Siliguri Transfer [135 km / 5.00 hrs]
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseTen"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingTen"
                    >
                      <div className="accordion-body">
                        <p>
                          On the final day, enjoy a morning visit to the Rongpokhola riverside, taking in the natural beauty and tranquility. After breakfast, depart for NJP/Siliguri, concluding your memorable North & East Sikkim trip. Return with cherished memories of your journey.
                        </p>
                        <br />
                        <div className="content-desktop">
                          <center><img src={navDay9ImageURL} alt="bhutan package tour cost from mumbai and explore chele la pass" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay9ImageURL} style={{ width: '320px' }} alt="bhutan package tour cost from mumbai and explore chele la pass" /></center>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br /><br />
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">{<InclusionExclusion />}</div>
                </div>
              </div>

              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>BEST SIGHTSEEING PLACES TO EXPLORE IN SILK ROUTE PACKAGE BOOKING</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>BEST SIGHTSEEING PLACES TO EXPLORE IN SILK ROUTE PACKAGE BOOKING</h4>
                </div>
                <div className="card-body">
                  <h5><b>Silk Route Highlights</b></h5>
                  <br />
                  <ul>
                  <li><b>• Nathang Valley</b>: Known as the "Ladakh of East India," this high-altitude valley offers stunning views of snow-capped mountains and a serene atmosphere.</li><br />
                  <li><b>• Zuluk</b>: A quaint village famous for its 95-hairpin bends and panoramic views of the Eastern Himalayas, especially the Kanchenjunga range.</li><br />
                  <li><b>• Tsomgo Lake (Changu Lake)</b>: A glacial lake at an altitude of 12,310 ft, surrounded by snow-covered mountains and home to migratory birds.</li><br />
                  <li><b>• Kupup Lake</b>: Also known as Elephant Lake due to its shape, this beautiful high-altitude lake is a must-visit.</li><br />
                  <li><b>• Old Baba Mandir</b>: A memorial and temple dedicated to the Indian Army soldier, Baba Harbhajan Singh, believed to protect the soldiers in the region.</li><br />
                  <li><b>• Thambi View Point</b>: Offers breathtaking views of the sunrise over the Kanchenjunga range and the winding Silk Route road.</li><br />
                  <li><b>• Lungthung</b>: A perfect spot for photography enthusiasts, offering panoramic views and a glimpse of the rich flora and fauna of the region.</li><br />
                  <li><b>• Mankhim</b>: A viewpoint near Zuluk, offering a panoramic view of the entire Silk Route and the Kanchenjunga range.</li><br />
                  <h5><b>North Sikkim Highlights</b></h5><br />
                  <li><b>• Gurudongmar Lake</b>: Situated at an altitude of 5,154 meters, this sacred lake is known for its stunning turquoise waters and spiritual significance.</li><br />
                  <li><b>• Lachen</b>: A picturesque village serving as the gateway to Gurudongmar Lake and Chopta Valley, offering traditional Sikkimese culture and beautiful landscapes.</li><br />
                  <li><b>• Lachung</b>: A beautiful village surrounded by snow-capped mountains, famous for its apple orchards and the Lachung Monastery.</li><br />
                  <li><b>• Yumthang Valley</b>: Also known as the "Valley of Flowers," this valley offers a vibrant display of rhododendrons and other alpine flowers, especially in spring.</li><br />
                  <li><b>• Zero Point</b>: The last outpost of civilization, offering a stunning view of the snow-clad Himalayas and an experience of high-altitude life.</li><br />
                  <li><b>• Shingba Rhododendron Sanctuary</b>: A sanctuary rich in rhododendron species, making it a paradise for nature lovers.</li><br />
                  <li><b>• Seven Sisters Waterfall</b>: A spectacular waterfall that cascades in seven stages, offering a beautiful and serene environment.</li><br />
                  <li><b>• Naga Waterfalls</b>: Another picturesque waterfall, known for its serene beauty and tranquil surroundings.</li>
                  </ul>
                  <br />
                  <p>These locations offer a diverse range of experiences, from breath taking natural landscapes and high-altitude lakes to charming villages and cultural sites. Whether you're seeking adventure, serenity, or cultural immersion, the <strong>North Sikkim package Tour in Summer</strong> has something for everyone.</p><br />
                  <p>Exploring the North Sikkim Package Tour offers a treasure trove of offbeat places that provide a unique blend of natural beauty, cultural richness, and serene solitude. Here are some lesser-known yet captivating destinations to explore:</p>
                    <br />
                  <h5><b>OFFBEAT PLACES IN THE</b> <strong>OLD SILK ROUTE TOUR</strong></h5><br />
                 <ul>
                  <li><b>• Dzuluk (Zuluk)</b>: A quaint village situated at an altitude of about 10,000 feet, Zuluk is famous for its winding roads with 95 hairpin bends, offering breathtaking views of the Kanchenjunga range. It's an excellent spot for witnessing stunning sunrises and sunsets.</li><br />
                  <li><b>• Nathang Valley (Gnathang)</b>: Often referred to as the "Ladakh of the East," Nathang Valley is a high-altitude valley nestled at 13,500 feet. The valley is surrounded by snow-covered peaks and offers panoramic views, making it a perfect spot for photography and solitude.</li><br />
                  <li><b>• Kupup Lake</b>: Also known as Elephant Lake due to its unique shape, Kupup Lake is located at a high altitude and is a hidden gem of the Silk Route. The tranquil surroundings and the reflection of the mountains in the lake's water create a mesmerizing scene.</li><br />
                  <li><b>• Lungthung</b>: This tiny hamlet offers panoramic views of the Kanchenjunga range and the winding roads of the Old Silk Route. It's an ideal spot for bird watching and enjoying the untouched beauty of nature.</li><br />
                  <li><b>• Mankhim</b>: A serene village that provides a panoramic view of the entire Silk Route. Mankhim is also known for its temple dedicated to the Rai community and offers a peaceful environment for those looking to escape the tourist crowds.</li><br />
                  <li><b>• Keukhela Waterfalls (Kali Khola Falls)</b>: Located near Lingtam, this beautiful waterfall is relatively lesser-known and offers a peaceful retreat amidst nature. The waterfall cascades down a height, creating a picturesque setting.</li><br />
                  <li><b>• Aritar Lake (Lampokhari Lake)</b>: One of the oldest natural lakes in Sikkim, Aritar Lake is a tranquil spot surrounded by lush greenery. Visitors can enjoy boating on the lake and visit the nearby Aritar Monastery.</li><br />
                  <li><b>• Padamchen</b>: A small village on the Silk Route, Padamchen offers a serene environment with lush forests and vibrant birdlife. It's an ideal place for nature walks and bird watching.</li><br />
                  <li><b>• Rolep</b>: A peaceful village located away from the touristy hustle, Rolep is known for its pristine landscapes and the Rolep River, where visitors can indulge in angling and experience village life.</li><br />
                  <li><b>• Suntaleykhola</b>: A lesser-explored destination near Padamchen, Suntaleykhola is a beautiful spot for nature lovers. It offers pristine surroundings and is perfect for short hikes and nature walks.</li><br />
                  <p>These offbeat destinations along the Old Silk Route provide a unique and tranquil experience, away from the usual tourist trails. They offer an opportunity to connect with nature, experience local culture, and enjoy the unspoiled beauty of the Eastern Himalayas.</p>
                  </ul>
                </div>
              </div>
              <br /><br /><br />
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h2>
                        Frequently Ask <span>Questions</span>
                      </h2>
                      <h4>NORTH EAST SIKKIM TOUR RELATED QUESTION </h4>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div
                    className="accordion accordion-flush faQStyle"
                    id="accordionFlushExample"
                  >
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingOne">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseOne"
                          aria-expanded="false"
                          aria-controls="flush-collapseOne"
                        >
                          What is the best time to visit North Sikkim and the Silk Route?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingOne"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          The best time to visit North Sikkim and the Silk Route is during the summer months from April to June and the autumn months from September to November. These periods offer pleasant weather and clear skies, making it ideal for sightseeing and exploring the natural beauty of the region.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingTwo">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseTwo"
                          aria-expanded="false"
                          aria-controls="flush-collapseTwo"
                        >
                          How many days are recommended for a North Sikkim Tour?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseTwo"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingTwo"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          A typical Lachen Tour combining North Sikkim and the Silk Route requires around 8 to 10 days. This allows ample time to explore key destinations like Gangtok, Lachen, Lachung, Zuluk, Nathang Valley, and other scenic spots along the route.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingThree">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseThree"
                          aria-expanded="false"
                          aria-controls="flush-collapseThree"
                        >
                          What kind of accommodation is available on this tour?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseThree"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingThree"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Accommodations range from hotels and resorts in Gangtok to homestays and basic lodges in more remote areas like Lachen, Lachung, Zuluk, and Nathang Valley. While Gangtok offers modern amenities, accommodations in remote areas are more rustic but comfortable and provide an authentic experience.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingFour">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFour"
                          aria-expanded="false"
                          aria-controls="flush-collapseFour"
                        >
                          Is a special permit required to visit Silk Route Trip?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseFour"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingFour"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Yes, special permits are required to visit certain areas in North Sikkim and along the Silk Route, such as Gurudongmar Lake, Nathula Pass, and Tsomgo Lake. These permits can be arranged by your Silk Route Tour Operator in Kolkata or travel agency, and you'll need to provide valid identification and passport-sized photographs.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingFive">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFive"
                          aria-expanded="false"
                          aria-controls="flush-collapseFive"
                        >
                          What are the essential things to pack for this Lachen Lachung Package Tour?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseFive"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingFive"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <ul>
                            <li>Warm clothing, including jackets, sweaters, and thermal wear, especially for high-altitude areas.</li>
                            <li>Comfortable walking shoes with good grip.</li>
                            <li>Personal medications and a basic first-aid kit.</li>
                            <li>Sunscreen, sunglasses, and a hat to protect against the sun.</li>
                            <li>Snacks, water bottles, and energy bars for long journeys.</li>
                            <li>A camera and binoculars for capturing the scenic views and spotting wildlife.</li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingSix">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseSix"
                          aria-expanded="false"
                          aria-controls="flush-collapseSix"
                        >
                          Are there any health considerations for traveling to high-altitude areas like Gurudongmar Lake and Nathang Valley?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseSix"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingSix"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Travelers should be aware of the risks associated with high altitudes, such as Acute Mountain Sickness (AMS). It is advisable to acclimatize gradually, stay hydrated, and avoid alcohol. Those with respiratory or heart conditions should consult their doctor before traveling.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingSeven">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseSeven"
                          aria-expanded="false"
                          aria-controls="flush-collapseSeven"
                        >
                          How is the road condition in North Sikkim and along the Silk Route?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseSeven"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingSeven"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Roads in these regions can be challenging due to their high altitude and rugged terrain. While main roads are generally well-maintained, some areas may have narrow, winding roads that can be rough, especially during the monsoon season. It's recommended to travel in a sturdy vehicle with an experienced driver.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingEight">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseEight"
                          aria-expanded="false"
                          aria-controls="flush-collapseEight"
                        >
                          Is the Silk Route Sillery-Gaon Tour accessible during the winter?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseEight"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingEight"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          The Silk Route can be challenging to access during the winter months (December to February) due to heavy snowfall, especially in high-altitude areas like Zuluk and Nathang Valley. Road conditions can be unpredictable, and some routes may be closed. However, the winter season offers stunning snow-covered landscapes.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingNine">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseNine"
                          aria-expanded="false"
                          aria-controls="flush-collapseNine"
                        >
                          What kind of food is available during the Gangtok North Sikkim Tour?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseNine"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingNine"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          In major towns like Gangtok, a wide variety of cuisines, including Indian, Chinese, and continental, are available. In remote areas, the food options are more limited and generally consist of simple local fare, such as rice, lentils, vegetables, and momos (dumplings). Non-vegetarian options are also available, but choices may be limited.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingTen">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseTen"
                          aria-expanded="false"
                          aria-controls="flush-collapseTen"
                        >
                          Can we include Nathula Pass in Silk Route Tour Itinerary?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseTen"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingTen"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Yes, Nathula Pass can be included in the tour itinerary. However, it requires a special permit, and the visit is subject to road and weather conditions. Nathula Pass is usually closed on Mondays and Tuesdays, and Indian nationals only are allowed to visit.
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h3>
                        <b>
                          North Sikkim <span className="tomato">Tour Reviews</span>
                        </b>
                      </h3>
                      <h4>
                        <i>"Your Experience is our Marketing"</i>
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="row row-cols-1 ">
                  <div id="testimonials">
                    <div className="testimonial-box-container">
                      {reviewersDataView}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <BookingBox />
            </div>
          </div>
        </div>
        <div class="btn-call-pulse">
                    <a style={{ color: 'white' }} href="tel:+91-96747-38480">
                        <i className="fa fa-phone"></i>
                        {/* <span><small>+91 9147061467</small></span> */}
                    </a>
                </div>
                <div className="btn-whatsapp-pulse">
                    <a style={{ color: 'white' }} href="#" onClick={this.handleClick}>
                        <i className="fab fa-whatsapp"></i>
                        {/* <span>
                                <small>+91 9883359713</small>
                            </span> */}
                    </a>
                </div>
      </>
    );
  }
}
export default NorthSikkim_Silkroute_8N_9D_Package;
