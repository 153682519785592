import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import ApiURL from "../../../api/ApiURL";
import '../Bhutan/InclusionExclusion';
import InclusionExclusion from '../Bhutan/InclusionExclusion';
import BookingBox from '../BookingBox';

class BhutanTourPackage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customActiveTab: 'tab1'
        }
    }

    handleCustomTabClick = (tab) => {
        this.setState({
            customActiveTab: tab,
        });
    };

    render() {
        const { customActiveTab } = this.state;
        const packageTopBannerImageURL = ApiURL.BhutanTourPackageImageURL + "/bhutan-tour-from-india.webp";
        const packageDestinationBannerImageURL = ApiURL.BhutanTourPackageImageURL + "/Bhutan-Travel-Agents.webp";
        const brandedTourackageImage_1_URL = ApiURL.BhutanTourPackageImageURL + "/Six-Sense-Bhutan-Packages.webp";
        const brandedTourackageImage_2_URL = ApiURL.BhutanTourPackageImageURL + "/Aman-Kora-Bhutan-Packages.png";

        const hotPackagesDataList = this.props.HotPackagesData;
        const hotPackagesView = hotPackagesDataList.length > 0 ?
            (
                hotPackagesDataList.map((dataItem, i) => {
                    const hotImgURL = ApiURL.BhutanTourPackageImageURL + "/" + dataItem.ImageName;
                    const packageURL = dataItem.Url;
                    return (<div className="col-md-4" key={i}>
                        <div className="card h-100">
                            <img src={hotImgURL} className="card-img-top" alt={dataItem.AllternateText} />
                            <div className="card-body">
                                <h6 className="card-title">{dataItem.Title} <span> &#8377; {dataItem.PricePerNight}/-</span></h6>
                                <p className="card-text">{dataItem.Description}</p>
                            </div>
                            <div className="card-footer">
                                <small className="text-muted">{dataItem.MutedText}</small>
                                <Link to={packageURL} target="_blank" className="btn btn-Warning btn-sm float-end">Book Now</Link>
                            </div>
                        </div>
                    </div>)
                })
            ) :
            (<div></div>)


        const settings = {
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };


        const hotelDataList = this.props.HotelsData;
        const hotelDataView = hotelDataList.map((hotelItem, i) => {
            const hotelImageURL = ApiURL.BhutanTourPackageImageURL + "/" + hotelItem.ImageName;
            return (
                <div className="col-md-3" key={i}>
                    <img src={hotelImageURL} className="rounded mx-auto d-block" alt={hotelItem.AllternateText} /><br />
                    <span><b>{hotelItem.HotelName}</b> | <span className="tomato">{hotelItem.HotelStar}</span></span><br />
                    <span className="rating-box">
                        <span className="star">{hotelItem.RatingBoxStar}</span>
                        <b className="rating-value">{hotelItem.RatingValue}</b>
                    </span> &nbsp;<b>{hotelItem.Reviews}</b>
                </div>
            )
        })


        const themePackageSliderList = this.props.ThemePackagesData;
        const themePackagesDataView = themePackageSliderList.map((sliderItem, i) => {
            const sliderImageURL = ApiURL.BhutanTourPackageImageURL + "/" + sliderItem.ImageName;
            return (
                <div className="theme-cards-wrapper" key={i}>
                    <div className="theme-card">
                        <div className="image-wrapper">
                            <img src={sliderImageURL} alt={sliderItem.AllternateText} />
                        </div>
                        <div className="card-body">
                            <h5 className="card-title">{sliderItem.Title}</h5>
                            <p className="card-text">{sliderItem.Description}</p>
                            <Link to={"#"} className="btn btn-primary">Book Now</Link>
                        </div>
                    </div>
                    <div className="clearfix"></div>
                </div>
            )
        })



        const reviewersDataList = this.props.ReviewersData;
        const reviewersDataView = reviewersDataList.map((dataItem, i) => {
            const reviewerImageURL = ApiURL.BhutanTourPackageImageURL + "/" + dataItem.ImageName;
            return (
                <div className="testimonial-box" key={i}>
                    <div className="box-top">
                        <div className="profile">
                            <div className="profile-img">
                                <img src={reviewerImageURL} alt="..." />
                            </div>
                            <div className="name-user">
                                <strong>{dataItem.Name}</strong>
                                <span>{dataItem.Location}</span>
                            </div>
                        </div>
                        <div className="reviews">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </div>
                    </div>
                    <div className="client-comment">
                        <p>{dataItem.Comment}</p>
                    </div>
                </div>
            )
        })


        return (
            <>
                <div className="container-fluid">
                    <div className="row inner-page-banner">
                        <img src={packageTopBannerImageURL} className="img-fluid px-0" alt="..." />
                    </div>
                </div>
                <div className="container-fluid py-3">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="container-fluid">
                                        <h3 className="px-2">Bhutan Tour Package!</h3>

                                        <div className="d-flex flex-column flex-sm-row align-items-sm-center px-2">
                                            <div className="font-lg">
                                                3 Cities:
                                                <Link to={"#"}><i className="fa fa-map-marker text-warning mx-2"></i> Thimphu</Link>&nbsp;
                                                <Link to={"#"}><i className="fa fa-map-marker text-warning mx-2"></i> Paro</Link>
                                                <Link to={"#"}><i className="fa fa-map-marker text-warning mx-2"></i> Punakha</Link>
                                            </div>
                                            <div className="reviews mx-5">
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                            </div>
                                        </div>
                                        <p className="p-2">Embark on a Captivating Journey to Bhutan with our All-Inclusive Bhutan Tour Package!</p>
                                        <h3 className="p-2"><b>
                                            LOOKING FOR BHUTAN TOUR PACKAGE FROM INDIA ? FOR<br /><a href='tel:9883359713' class="tomato"> BOOKING ☎ CALL +91-98833-59713</a></b></h3>
                                        <div className="card">
                                            <div className="card-header custHeaer">
                                                India's No #1 Bhutan Tour Partner
                                            </div>
                                            <div className="card-body">
                                                <p>Are you yearning to explore the mystical landscapes and vibrant culture of Bhutan? Look no further! Adorable Vacation LLP presents an exclusive Bhutan Tour Package that will take you on an extraordinary adventure to the Land of the Thunder Dragon - Bhutan. This mesmerizing kingdom is renowned for its pristine natural beauty, ancient monasteries, and the happiness that prevails among its people.</p>
                                                <h3 className="px-2">Unbeatable Prices for an Unforgettable Journey</h3>
                                                <p>At Adorable Vacation, we believe that travel should be accessible to everyone, and that's why we offer the most competitive Bhutan Tour Package Price. We understand the value of your hard-earned money and are committed to providing you with an exceptional experience without compromising on quality. With our budget-friendly Bhutan Tour Package Price, you can make your dream vacation a reality without breaking the bank. </p>
                                                <a href="tel://9883359713" className="btn btn-Warning">Call Now</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>Our Bhutan <span>Tour Packages</span></h2>
                                            <h4>Trending Bhutan Trip from India</h4>
                                        </div>
                                    </div>

                                </div>
                                <div className="row row-cols-1 row-cols-md-4 g-4">

                                    {hotPackagesView}

                                </div>

                            </div>

                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>Day Wise Bhutan Tour <span>Plan from India</span></h2>
                                            <h4>7 Nights 8 Days Tour (EX-Paro Airport)</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="container-fluid py-3">
                                    <div className="row">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr className="table-danger">
                                                    <th>DAY WISE SUMMARY FOR 7N/8D BHUTAN TOUR</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="clickable" data-target="panelsStayOpen-headingOne">
                                                        <b>DAY 1 → </b><span>WELCOME TO BHUTAN AND TRANSFER TO THIMPU</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="clickable" data-target="panelsStayOpen-headingTwo">
                                                        <b>DAY 2 → </b><span>THIMPU SIGHTSEEING - AFTER BREAKFAST, START FOR LOCAL THIMPU SIGHTSEEING</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="clickable" data-target="panelsStayOpen-headingThree">
                                                        <b>DAY 3 → </b><span>THIMPU TO PUNAKHA TRANSFER</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="clickable" data-target="panelsStayOpen-headingFour">
                                                        <b>DAY 4 → </b><span>PHOBJIKHA VALLAY EXCURSION</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="clickable" data-target="panelsStayOpen-headingFive">
                                                        <b>DAY 5 → </b><span>PUNAKHA TO PARO</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="clickable" data-target="panelsStayOpen-headingSix">
                                                        <b>DAY 6 → </b><span>PUNAKHA TO PARO SIGHTSEEING</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="clickable" data-target="panelsStayOpen-headingSeven">
                                                        <b>DAY 7 → </b><span>PARO SIGHTSEEING TO TAKTSANG MONASTERY (TIGER NEST) </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="clickable" data-target="panelsStayOpen-headingTen">
                                                        <b>DAY 8 → </b><span>CHERISH YOUR MEMORIES</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div >
                                </div >

                                <div className="row">
                                    <div className="accordion" id="accordionPanelsStayOpenExample">
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true"
                                                    aria-controls="panelsStayOpen-collapseOne">
                                                    DAY 01: Arrival at Paro International Airport (PBH) - Thimphu (Distance: 47 km. / Driving Time: 1.5
                                                    hrs.)
                                                </button>
                                            </h2>
                                            <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingOne">
                                                <div className="accordion-body">
                                                    Meet & greet on arrival  and as the aircraft enters the Paro valley, look down and you will see the
                                                    Paro Dzong on the hillside overlooking Paro Chu (river) with Ta Dzong, You will be received by our
                                                    representative at the airport and On the way to Paro, halt at Chojum to take photographs of
                                                    confluence of two rivers of Bhutan, Thumpu Chu and Paro Chu. Reach Thimpu and entire day is free to
                                                    roam around Thimpu. Night Stay in Thimpu.
                                                    <ul className="itinerary-meta list-inline-block text-danger">
                                                        <li><i className="fa fa-clock-o"></i> Checkin time: 1 P.M.</li>
                                                        <li><i className="fa fa-clock-o"></i> Checkout time: 11 A.M.</li>
                                                    </ul>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseTwo">
                                                    DAY 02: Thimphu Local Sightseeing ( Approx Time Taken: 5 hrs.)
                                                </button>
                                            </h2>
                                            <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingTwo">
                                                <div className="accordion-body">
                                                    <p><b><u>The National Memorial Chorten </u></b>&nbsp;or Thimpu Chorten is a site for evening prayer.
                                                        The chorten or stupa was built in the memory of the third king , continuously circumambulated by
                                                        people, murmuring mantras and spinning their prayer wheels. Construction of this landmark was the
                                                        idea of Bhutan's third king, His Majesty Jigme Dorji Wangchuk ("the father of modern Bhutan") who
                                                        has wished to erect monument to world peace and prosperity. Completed in 1974 after his untimely
                                                        death, it serves both as a memorial to the Late King and as a monument to peace.<br /><br />
                                                        <b><u>Sakyamuni Buddha - </u></b>&nbsp;On the top of Kuensel Phodrang hill sits the statue of
                                                        Sakyamuni Buddha. The 51.5 meter long bronze statue would surely attract you and the uninterrupted
                                                        view of the valley would leave you spellbound. The site is also known as <b>Buddha View
                                                            Point.</b><br /><br />
                                                        <b><u>Changangkha Lhakhang - </u></b>&nbsp;At Thimphu you will go across several monasteries and
                                                        temples. Changangkha Lhakhang is among the most important religious structures in Thimphu. The
                                                        structure dates back to the 15th century and is one of the oldest in the region. The structure is
                                                        dedicated to Avalokiteshvara, the sign of compassion. You will also enjoy the grand view of
                                                        Thimphu valley from here.<br /><br />
                                                        <b><u>Takin Zoo –</u></b>&nbsp;You will find the National animal of Bhutan “Takin” in this open
                                                        zoo. Takin is one of the most endangered species of the World and it is available only in
                                                        Bhutan.<br /><br />
                                                        <b><u>The National Library -</u></b>&nbsp;It has got a fabulous collection of Buddhist manuscripts
                                                        which are priceless. It also houses the World’s largest published book which is about 130 pounds
                                                        and 5X7 feet.<br /><br />
                                                        <b><u>Zorig Chusum  - </u></b>&nbsp;Institute for Zorig Chusum (commonly known as the Painting
                                                        School) where students undergo a 6-year training course in Bhutan's 13 traditional arts and
                                                        crafts.<br /><br />
                                                        <b><u>The Royal Textile Museum  -</u></b> & nbsp;Thimphu also introduces visitors with the culture
                                                        and heritage of Bhutan.The Royal Textile Museum at Norzin Lam is a site where you will get
                                                        introduced to Bhutanese textiles, some of which date back to the 1600s.<br /><br />
                                                        <b><u>Trashi Chhoe Dzong -</u></b> & nbsp;or the Fortress of the Glorious Region is another ‘must
                                                        visit’ site.Situated on the west bank of Wang Chhu river, Trashi Chhoe Dzong is the formal
                                                        coronation site of the fifth King.The Dzong also hosts the annual Tsechu festival.Built in 1641
                                                        by the political and religious unifier of Bhutan, Shabdrung Ngawang Namgyal, it was reconstructed
                                                        in 1960s in traditional Bhutanese manner, without nails or architectural plans.<br /><br />
                                                    </p >
                                                    <p><b>Overnight stay at Thimphu.</b></p>
                                                </div >
                                            </div >
                                        </div >
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree">
                                                    DAY 03: THIMPHU TO PUNAKHA VIA DOCHULA PASS
                                                </button>
                                            </h2>
                                            <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingThree">
                                                <div className="accordion-body">
                                                    <p>After breakfast, start for Punakha . One the way, visit<br />
                                                        <b><u>Dochula view point stop: </u></b>&nbsp;The first stop after leaving Thimphu on the journey
                                                        is Dochu La pass around 3100 m high and way above the clouds.  Only an hour’s drive from Thimphu,
                                                        it offers visitors their first glimpse of the Eastern Himalayan range. From the pass the road
                                                        curls its way down into the relative lowlands of the Punakha Valley. Before Thimphu was made the
                                                        permanent capital of Bhutan.<br /><br />
                                                        <b>In Punakha , you will also visit</b><br /><br />
                                                        <b><u> Punakha Dzong: </u></b> & nbsp; Beautiful < b > Punakha Dzong</b > (known as the<b>“the palace of
                                                            great happiness”</b >),located at the < b > confluence of the Pho Chhu(father) and Mo Chhu(mother)
                                                                rivers</b > in the Punakha–Wangdue valley.the city’s dzong is one of the most picturesque of all
                                                        Bhutan’s ancient fortresses The dzong is joined to the mainland by an arched wooden bridge, and
                                                        contains many precious relics from the days when successive kings reined the kingdom from this
                                                        valley.<br /><br />
                                                        <b><u>Chimi Lhakhang:</u></b> & nbsp;It is famous throughout Bhutan as a place which induces
                                                        fertility for
                                                        all the visitors who come here wishing for a child.Several pilgrims, including couples with a
                                                        newly born child, come here to seek the blessings of the saint who is said to have the Magical
                                                        Thunderbolt Of Wisdom.<br /><br />

                                                        <b><u>Punakha Suspension Bridge:</u></b> & nbsp;Punakha Suspension Bridge is an important part of
                                                        the architectural history of Bhutan as it was built to connect the villages of Shengana,
                                                        Samdingkha and Wangkha to the Palace of the Wangchuk Kings<br /><br />

                                                        <b><u>Wangdue:</u></b> & nbsp;After crossing the Fo - Chu - Mo - Chu river, driver along the Wangdue
                                                        phradong and visit newly developed small beautiful town, Bazo Town in Wangdue.<br /><br />
                                                    </p >
                                                    <p><b>Overnight Stay at Punakha hotel.</b></p>
                                                </div >
                                            </div >
                                        </div >
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="panelsStayOpen-headingFour">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree">
                                                    Day 04: - A DAY EXCURSION TO PHOBJIKHA VALLEY
                                                </button>
                                            </h2>
                                            <div id="panelsStayOpen-collapseFour" className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingFour">
                                                <div className="accordion-body">
                                                    <p><b>After breakfast, Drive to Gangtey valley</b> . Its located in the beautiful, broad and U
                                                        shaped glacial valley of Phobjika. After arrival hotel check in and latter after lunch we will
                                                        hike to nature trails .From the small hilltop overlooking Gangtey
                                                        Goemba, head downhill through flower meadows to Semchubara village and from here through beautiful
                                                        forests and into the open valley. After passing a chorten and Khewa Lhakhang, the trail ends at
                                                        the local community school; this involves a 30 minute walk up to the start of town.Black Necked
                                                        Crane Information Centre
                                                        Situated on the edge of the forest and wetland along the main road of Phobjikha valley, the
                                                        black-necked crane information Centre has an observation room equipped with high power telescope
                                                        and spotting scopes for catching the best view of the cranes. The centre also offers display
                                                        information that outline the natural and cultural history of the area. There is a small gift shop,
                                                        which sells handicrafts produced by the local people.
                                                        Back to hotel
                                                    </p>
                                                    <p><b>Overnight Stay in Punakha.</b></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="panelsStayOpen-headingFive">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseFive" aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree">
                                                    DAY 05: PUNAKHA to PARO
                                                </button>
                                            </h2>
                                            <div id="panelsStayOpen-collapseFive" className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingFive">
                                                <div className="accordion-body">
                                                    <div>After breakfast, you will leave Punakha/Wangdue and drive to Paro via Dochu La Pass, covering
                                                        Paro Chuu river valley and Wang Chhu river valley. On the way, you will be visiting Simtokha
                                                        Dzong, an old fortress which is now a school for Dzongkha language.
                                                        Once you reach Paro, you will be checked into a hotel. Later, you will be taken on a sightseeing
                                                        tour covering important destinations of Paro:- <br />
                                                        <ol>
                                                            <li>Ta Dzong</li>
                                                            <li>Rinpung Dzong</li>
                                                            <li>Tamchog lhakhang</li>
                                                        </ol>

                                                    </div>
                                                    <p><b>Overnight Stay in Paro.</b></p>
                                                </div>
                                            </div>
                                        </div >
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="panelsStayOpen-headingSix">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseSix" aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree">
                                                    DAY 06: PUNAKHA TO PARO VIA SIGHTSEEING
                                                </button>
                                            </h2>
                                            <div id="panelsStayOpen-collapseSix" className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingSix">
                                                <div className="accordion-body">
                                                    <p>In the morning, after breakfast , start for Paro local sightseeing <br /><br />
                                                        <b>National Museum of Bhutan: </b>&nbsp;Situated right above <b>Rinpung Dzong </b>in Paro, the
                                                        National Museum of Bhutan is a house that showcases Bhutan’s culture. Built in 1968, the museum
                                                        stands inside the ancient Ta-dzong building. The museum houses the collection of finest specimens
                                                        of Bhutanese culture and art and its highlights are paintings and some bronze statues, which are
                                                        simply masterpieces. The items are demonstrated in large galleries and you can get to know a lot
                                                        about Bhutan’s art and culture from these objects.<br /><br />
                                                        <b>Rinpung Dzong:</b> & nbsp; Built in 1646 by Shabdrung Ngawang Namgyal, the first spiritual and
                                                        temporal ruler of Bhutan, the Dzong houses the monastic body of Paro, the office of the Dzongda
                                                        (district administrative head) and Thrimpon(judge) of Paro district.The approach to the Dzong is
                                                        through a traditional covered bridge called Nemi Zam.<br /><br />
                                                        <b>Ta Dzong:</b> & nbsp; Ta Dzong, once a watchtower, built to defend Rinpung Dzong during
                                                        inter - valley wars of the 17th century, Ta Dzong was inaugurated as Bhutan's National Museum in
                                                        1968.<br /><br />
                                                        <b>Nya Mey Zam Bridge:</b> & nbsp; "The Bridge with No Fish " is the bridge that connects Paro Town
                                                        to Dzong..It is one of finest specimens in Bhutan and local believes if one sees a fish in the
                                                        river, it is ill omen..The earlier bridge which could be folded during war was washed away by
                                                        flood in 1969.<br /><br />
                                                        <b>Paro Airport View Point:</b> & nbsp;This is finest valley view point to look after Paro Valley
                                                        and Paro Airport.This view point overlooking the Paro river along with the all the Dzongs and
                                                        Monastry.<br /><br />
                                                    </p >
                                                    <p><b>Overnight at Paro</b></p>
                                                </div >
                                            </div >
                                        </div >
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="panelsStayOpen-headingSeven">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseSeven" aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree">
                                                    DAY 07: A DAY'S EXCURSION TAKTSANG MONASTERY
                                                </button>
                                            </h2>
                                            <div id="panelsStayOpen-collapseSeven" className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingSeven">
                                                <div className="accordion-body">
                                                    <p>
                                                        <b>Kyichu Lhakhang:</b>&nbsp; Kyichu Lhakhang, (also known as Kyerchu Temple or Lho Kyerchu) is an
                                                        important Himalayan Buddhist temple situated in Lamgong Gewog of Paro District in Bhutan. The Jowo
                                                        Temple of Kyichu is one of the oldest temples in Bhutan, originally built in the 7th century by
                                                        the Tibetan Emperor Songsten Gampo.<br /><br />
                                                        <b>Taktsang Monastery:</b>&nbsp; If you can brave the risky cliffs on a high mountain, Paro will
                                                        astonish you in the form of Taktsang Monastery. After covering 1200 metres on a fierce cliff you
                                                        will reach this sacred site, also known as the Tiger’s Nest. Take a deep breath after reaching the
                                                        spot and then comfort your eyes and soul with the stunning view of the scenic surroundings and the
                                                        valley lying underneath. The Taktsang Monastery was established by Sain Guru Rinpoche who came to
                                                        Bhutan in 747 CE. Construction of the complex was completed in 1694. Discovering the Tiger’s Nest
                                                        after 3 hours of trekking is real adventure but you may also opt for a horse ride from the parking
                                                        lot.<br /><br />
                                                    </p >
                                                    <p><b>Overnight at Paro</b></p>
                                                </div >
                                            </div >
                                        </div >
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="panelsStayOpen-headingTen">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseTen" aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree">
                                                    DAY 08: Paro To Airport Drop
                                                </button>
                                            </h2>
                                            <div id="panelsStayOpen-collapseTen" className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingTen">
                                                <div className="accordion-body">
                                                    <p> Post breakfast bid farewell to the Last Buddhist Kingdom and start for Paro Airport with
                                                        long-lasting sweet Bhutan Holiday memories, while chanting one mantra, <b>"Life is short, and the
                                                            World is wide" With Adorable Vacation LLP.</b></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div >
                                </div >

                            </div >

                            <div className="container-fluid navtabCustom">
                                <nav>
                                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                        <a onClick={() => this.handleCustomTabClick('tab1')} className={customActiveTab === 'tab1' ? 'nav-item nav-link active' : 'nav-item nav-link'} role="tab" aria-controls="nav-price" aria-selected="true">Price</a>
                                        <a onClick={() => this.handleCustomTabClick('tab2')} className={customActiveTab === 'tab2' ? 'nav-item nav-link active' : 'nav-item nav-link'} role="tab" aria-controls="nav-hotels" aria-selected="false">Hotels</a>
                                        <a onClick={() => this.handleCustomTabClick('tab3')} className={customActiveTab === 'tab3' ? 'nav-item nav-link active' : 'nav-item nav-link'} role="tab" aria-controls="nav-travellers-info" aria-selected="false">Travellers Info</a>
                                        <a onClick={() => this.handleCustomTabClick('tab4')} className={customActiveTab === 'tab4' ? 'nav-item nav-link active' : 'nav-item nav-link'} role="tab" aria-controls="nav-flight" aria-selected="false">Flight</a>
                                    </div>
                                </nav>
                                <div className="tab-content" id="nav-tabContent">
                                    <div className={customActiveTab === 'tab1' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-price-tab">
                                        <div id="no-more-tables">
                                            <table class="col-md-12 table-bordered table-striped table-condensed cf">
                                                <thead class="cf">
                                                    <tr className="table-primary text-center">
                                                        <th colSpan="5">Fly-In & Fly-Out (FIFO) – EX PARO INTERNATIONAL AIRPORT (PBH)</th>
                                                    </tr>
                                                    <tr className="table-danger">
                                                        <th>Itinerary</th>
                                                        <th>02 Pax (INR)</th>
                                                        <th>04 Pax (INR)</th>
                                                        <th>06 Pax (INR)</th>
                                                        <th>Extra Person</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td data-title="Itinerary">07 nights – 08 days</td>
                                                        <td data-title="02 Pax (INR)">56,300 PP</td>
                                                        <td data-title="04 Pax (INR)">42,900 PP</td>
                                                        <td data-title="06 Pax (INR)">38,200 PP</td>
                                                        <td data-title="Extra Person">20,500 PP</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className={customActiveTab === 'tab2' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-hotels-tab">
                                        <h5 className="center font-weight">TCB Approved 3-Star Hotels</h5>
                                        <div className="row row-cols-1 row-cols-md-4 g-4">

                                            {hotelDataView}

                                        </div>
                                    </div>
                                    <div className={customActiveTab === 'tab3' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-travellers-info-tab">
                                        <h5><b>Bhutan Visa/ Permit</b></h5>
                                        <span className="green-dot px-3">●</span>While booking a package tour to Bhutan you need a permit/ visa
                                        to enter Bhutan.
                                        <br /><br />
                                        <span className="green-dot px-3">●</span>For Indians – 4 copies passport size recent colour
                                        photographs, Photo ID proof (Passport/ Voter ID) & hotel confirmation vouchers. For minor, its compulsory to carry
                                        original birth certificate along with school photo ID or passport.<br /><br />
                                        <span className="green-dot px-3">●</span>PAN & AADHAR cards are strictly not valid.<br /><br />
                                        <span className="green-dot px-3">●</span>Foreigners (other than Indians) must have visa clearance
                                        before entering Bhutan. Visa can be processed online by providing photo page of your passport.<br /><br />
                                        <span className="green-dot px-3">●</span>Passport should have a minimum 6 months validity
                                        left.<br /><br />
                                        <span className="green-dot px-3">●</span>Permit can be issued during Govt.holidays & weekends(Online
                                        & Offline both).<br /><br />
                                        <span className="green-dot px-3">●</span>Thimphu / Paro / Phuentsholing immigration office is opened on
                                        Saturday, Sunday & Government holidays.But online permit process will be closed, Guest has to
                                        choose the offline process.<br /><br />
                                        <h5><b>Bhutan Currency</b></h5>
                                        <span className="green-dot px-3">●</span>Bhutan currency in called Ngultrum & carries equal value to
                                        Indian Rupee.Indian Rupees are widely accepted except 10, 20, 50, 100, 500 rupee notes only.
                                        <br /><br />
                                        <span className="green-dot px-3">●</span>Bank & major hotels can exchange major currencies.But it’s
                                        important to carry the bank exchange receipt to get it changed back to US Dollar.Foreigners cannot carry
                                        Indian Rupee.<br /><br />
                                        <span className="green-dot px-3">●</span>Credit & Debit cards(Visa & Mastercard) are accepted in
                                        Bhutan.But the service is not widely available.So it is always advisable to carry sufficient cash.<br /><br />
                                        <h5><b>Communication </b></h5>
                                        While on your Bhutan Package Tour your Indian SIM card will not be working in the country unless you have
                                        an international roaming facility.So it’s important to carry a local SIM card.It will help you to
                                        communicate with the service providers.Our team will be in touch on a regular basis through your local
                                        no.You can easily opt for a local SIM by showing your Bhutan permit / visa.
                                        <br /><br />
                                        Almost all the hotels have WiFi facility.So you can communicate with any Indian / International no through
                                        WhatsApp or any other communication APP while you are on your Bhutan package tour.
                                        <br /><br />
                                    </div >
                                    <div className={customActiveTab === 'tab4' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-flight-tab">
                                        <span>Bhutan Flight From - <b>Kolkata</b> <i>(CCU - PBH)</i></span>
                                        <br />
                                        <br />
                                        <div id="no-more-tables">
                                            <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                <thead className="cf">
                                                    <tr className="table-danger">
                                                        <th>Airlines</th>
                                                        <th>Flight Number</th>
                                                        <th>Operating Days</th>
                                                        <th>Time</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td data-title="Airlines">Druk Air</td>
                                                        <td data-title="Flight Number">KB 211, KB 210</td>
                                                        <td data-title="Operating Days">Sat, Mon, Wed, Fri</td>
                                                        <td data-title="Time">9:30 - 11:30</td>
                                                    </tr>
                                                    <tr>
                                                        <td data-title="Airlines">Bhutan Airlines</td>
                                                        <td data-title="Flight Number">B3 701, B3 700</td>
                                                        <td data-title="Operating Days">Mon, Tue, Wed, Fri, Sat, Sun</td>
                                                        <td data-title="Time">8:15 - 9:55</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <br />
                                        <span>Bhutan Flight From - <b>Delhi</b> <i>(DEL - PBH)</i></span>
                                        <br />
                                        <br />
                                        <div id="no-more-tables">
                                            <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                <thead>
                                                    <tr className="table-danger">
                                                        <th>Airlines</th>
                                                        <th>Flight Number</th>
                                                        <th>Operating Days</th>
                                                        <th>Time</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td data-title="Airlines">Druk Air</td>
                                                        <td data-title="Flight Number">KB 201, KB 200</td>
                                                        <td data-title="Operating Days">Mon, Tue, Wed, Fri, Sat, Sun</td>
                                                        <td data-title="Time">12:30 - 15:20</td>
                                                    </tr>
                                                    <tr>
                                                        <td data-title="Airlines">Bhutan Airlines</td>
                                                        <td data-title="Flight Number">B3 774, B3 773</td>
                                                        <td data-title="Operating Days">Mon, Wed, Sat, Sun</td>
                                                        <td data-title="Time">10:55 - 14:35</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <!--insert table inclusion exclusion--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        {
                                            <InclusionExclusion />
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* <!--end table inclusion exclusion--> */}

                            <div className="container-fluid py-5">
                                <div className="row">
                                    <img src={packageDestinationBannerImageURL} alt='...' />
                                </div>
                            </div>

                            <div className="container-fluid">
                                <div className="row placetoVisit">
                                    <h2>7 BEST PLACES TO VISIT AT BHUTAN</h2>
                                    <ul>
                                        <li><strong>Punakha Dzong - </strong> The Punakha Dzong is located in a serene surroundings. You have to cross the Punakha river on a swinging bridge to reach the fort which houses the monastery and the royal living quarters. The whole place is very majestic and decorative. It is quite a large space and it takes almost an hour to do even a quick tour tour of the place. The doors, windows and the courtyard are all so ornate and colourful. The prayer area is very colourful and the statues are large sized and realistic.</li>
                                        <li> <strong>Paro Taktsang - </strong> If you google Bhutan, it's impossible not to have come across an image of a monastery perched precariously in the cliff side. Yes, that's Paro Taktsang, also known as Tiger's Nest monastery. If it is your first time there, you ought to visit. According to legend, back in 8th century, Guru Rinpoche (second Buddha) flew here on the back of a tigress. Due to its location, the only way to reach up is to hike or you could hire a horse for a major part of the distance.</li>
                                        <li> <strong>Buddha Dordenma - </strong> Great Buddha Dordenma is a gigantic Shakyamuni Buddha statue in the mountains of Bhutan celebrating the 60th anniversary of fourth king Jigme Singye Wangchuck. The statue houses over one hundred thousand smaller Buddha statues, each of which, like the Great Buddha Dordenma itself, are made of bronze and gilded in gold. The Great Buddha Dordenma is sited amidst the ruins of Kuensel Phodrang, the palace of Sherab Wangchuck, the thirteenth Desi Druk, overlooking the southern approach to Thimphu, the capital of Bhutan.</li>
                                        <li> <strong>Dochula (Pass) - </strong>  Nestled in the eastern edge of the Himalayas, the Kingdom of Bhutan boasts of spectacular mountain views and some of the happiest people in the world. And with this beautiful outlook of life came the creation of Dochula Pass, a breath-taking mountain pass, in commemoration of Bhutanese soldiers who passed away in military operations for the country.

                                            Along the Dochula Pass, there are 108 memorial chortens (also known as stupas) called the 'Druk Wangyal Chortens' for each soldier's life lost in war. The hills are also decorated with colourful religious flags in five colours to represent the natural elements: blue for the sky, red for fire, green for water, white for clouds, and yellow for earth. To the local Buddhist people, these flags are symbols of veneration and the inscriptions of prayers on each flag signals peace and prosperity for Bhutan.</li>
                                        <li> <strong>Tashichho Dzong - </strong> Tashichho Dzong is a fortress located about 2 km away from the city of Thimphu in Bhutan. It had been constructed in 1641 and underwent several restorations since then. It currently serves both as a monastery and the central secretariat of the present government. Also known as the "fortress of the glorious religion", it is found right on the banks of the Wangchhu River.

                                            Originally built in 1216, the original structure of the Tashichho Dzong was destroyed in a fire, with the current dzong structure being newly built. A lovely rose garden in front of the building welcomes the visitors to the fortress. The manicured garden surrounding the building is a beautiful sight too. The tall building is a prominent landmark of Thimphu with almost all sightseeing tours of the city beginning with a visit to this beautiful Dzong resplendent with a golden roof. </li>
                                        <li><strong>Chele La Pass - </strong> Bhutan, being a country with a mountainous landscape, is linked with a series of passes. Located at approximately 13,000 feet between the valley of Paro and Haa, Chele La Pass is the highest motorable road pass in Bhutan. The pass is famous for the stunning Himalayan views it offers, especially Mt. Jhomolari which is Bhutan’s most sacred peak at 22,000 feet, Tsherimgang mountains, Jichu Drake as well as views of both the valleys, Paro and Haa.

                                            Just a two-hour drive from the valley floor in Paro, you will reach Chele La Pass, covered in untouched forests, home to thriving flora and fauna. The surrounding area of this pass has several ancient trails, perfect for hikers. </li>
                                        <li> <strong>Pobjikha valley - </strong> Against the backdrop of western slopes of the Black Mountains in Wangdue Phodrang, lies the bowl-shaped Phobjikha valley that offers breathtaking views of vast expanses of green fields. It is home to the endangered black-necked crane that migrates to this area during winters. Phobjikha valley also called Gangtey, is one of the few glacial valleys in the Kingdom of Bhutan.

                                            Bordering the Jigme Singye Wangchuck National Park, the valley is located at an altitude of 3000m above sea level, and hence comparatively cooler. It boasts being the winter home to black-necked cranes that fly from Tibet to escape the harsh winter and also one of the most prominent conservative sites of Bhutan. Relatively untouched by outsiders, the use of electricity in the valley began only a few years ago. </li>
                                    </ul>

                                </div>
                                <div className="container-fluid py-5">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="site-heading text-center">
                                                <h2>Branded Bhutan Tour <span>- The SIX SENSE</span></h2>
                                                <h4><i>Next Level Luxury Sense</i></h4>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row py-3">
                                        <img src={brandedTourackageImage_1_URL} alt="..." />
                                    </div>

                                </div>
                                <div id="no-more-tables">
                                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                        <thead className="cf">
                                            <tr className="table-danger">
                                                <th>Packages</th>
                                                <th>Duration</th>
                                                <th>Hotel Brand</th>
                                                <th>Price (Per Head)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td data-title="Packages">Luxury Trip</td>
                                                <td data-title="Duration">5N/6D</td>
                                                <td data-title="Hotel Brand">The SIX SENSE (Ultra Luxury)<div className="reviews">
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                </div></td>
                                                <td data-title="Price (Per Head)">Rs 5,36,789/- </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="card mx-0 my-5">
                                    <div className="card-header custHeaer">
                                        Six Sense Bhutan Packages - An Ultra Luxury Five Star Brand
                                    </div>
                                    <div className="card-body">
                                        <p>Adorable can adjust your itinerary depending on your needs & preference. We are happy to arrange your itinerary to fit your interests, allowing you to explore and enjoy the Kingdom of Bhutan at your own peace.</p>
                                        <h3 className="px-2">Unbeatable GEM of Bhutan</h3>
                                        <p>Travel back in time and take a journey that few people have had the opportunity to experience. Druk Yul, Bhutan’s official name, is known as the Land of the Dragon People with a rich cultural heritage that has largely remained hidden until its recent emergence onto the world stage. <b>Your Six Senses GEM (Guest Experience Maker)</b> will meet you upon arrival and accompany you on your khamsa ‘royal stroll’ through the Kingdom, sharing insights to the culture, history and local life of each valley as you go. Discover the country’s breathtaking natural beauty, meet and mingle with our Bhutanese friends as you embrace the philosophy of a nation that is guided by the principles of ‘Gross National Happiness’. Our khamsas are designed with just the right balance of meaningful encounters and experiences in mind along with a healthy dose of fun.

                                            The following is only a guideline. Your GEM will help to tailor your 5-day Bhutan itinerary to your needs and interests each day.</p>
                                        <i><h6>Service available at Paro, Punakha, Thimphu, Bumthang and Gangtey.</h6></i>
                                        <a href="tel://9883359713" className="btn btn-Warning">Book Now</a>


                                    </div>
                                </div>

                            </div>

                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>Branded Bhutan Tour <span>- The AMAN KORA</span></h2>
                                            <h4><i>Stay with Trust</i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row py-3">
                                    <img src={brandedTourackageImage_2_URL} alt="..." />
                                </div>

                                <div id="no-more-tables">
                                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                        <thead>
                                            <tr className="table-danger">
                                                <th>Packages</th>
                                                <th>Duration</th>
                                                <th>Hotel Brand</th>
                                                <th>Price (Per Head)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td data-title="Packages">Premium Trip</td>
                                                <td data-title="Duration">5N/6D</td>
                                                <td data-title="Hotel Brand">The AMAN KORA (Premium)<div className="reviews">
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                </div></td>
                                                <td data-title="Price (Per Head)">Rs 4,70,389/- </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                <div className="card mx-0 my-5">
                                    <div className="card-header custHeaer">
                                        AMAN KORA Bhutan Packages - A Super Premium Five Star Brand
                                    </div>
                                    <div className="card-body">
                                        <p>Adorable can adjust your itinerary depending on your needs & preference. We are happy to arrange your itinerary to fit your interests, allowing you to explore and enjoy the Kingdom of Bhutan at your own peace.</p>
                                        <h3 className="px-2">Unbeatable GEM of Bhutan</h3>
                                        <p>Travel back in time and take a journey that few people have had the opportunity to experience. Druk Yul, Bhutan’s official name, is known as the Land of the Dragon People with a rich cultural heritage that has largely remained hidden until its recent emergence onto the world stage. <b>Your Six Senses GEM (Guest Experience Maker)</b> will meet you upon arrival and accompany you on your khamsa ‘royal stroll’ through the Kingdom, sharing insights to the culture, history and local life of each valley as you go. Discover the country’s breathtaking natural beauty, meet and mingle with our Bhutanese friends as you embrace the philosophy of a nation that is guided by the principles of ‘Gross National Happiness’. Our khamsas are designed with just the right balance of meaningful encounters and experiences in mind along with a healthy dose of fun.

                                            The following is only a guideline. Your GEM will help to tailor your 5-day Bhutan itinerary to your needs and interests each day.</p>
                                        <i><h6>Service available at Paro, Punakha, Thimphu, Bumthang and Gangtey.</h6></i>
                                        <a href="tel://9883359713" className="btn btn-Warning">Book Now</a>

                                    </div>
                                </div>
                            </div>

                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>Frequently Ask <span>Questions</span></h2>
                                            <h4>Bhutan Trip Related Questions</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="accordion accordion-flush faQStyle" id="accordionFlushExample">
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingOne">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                                    Is Bhutan visa free for Indian?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">Yes, Indian citizens do not require a visa to enter Bhutan.</div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingTwo">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                                    How can I go to Bhutan from India?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">You can take a flight to Bagdogra (IXB). After that enter to Bhutan by road via PHUENTSHOLING. This will be an economical option. In terms of luxury option, you can avail Direct Non Stop flight to PARO (PBH) airport. Operated from three major cities DELHI, KOLKATA & GUWAHATI.</div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingThree">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                                    Do Bhutanese Speak English?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">Dzongkha is the national language of the Bhutan, which is predominantly spoken in the western region of Bhutan. However, there are 25 dialects spoken in Bhutan. Bhutanese people speak English as it is the medium of instruction in the schools. Hence it is easy to talk to Bhutanese people if you are fluent in English. The official documents, road signs, and even the major national newspapers are published in English making it easier for tourists to understand.</div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingFour">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                                    Is Alcohol cheaper in Bhutan?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">Yes, alcohol is cheaper in Bhutan, and the locally made alcohol is good in taste, and these beverages act as a great souvenir for the boozers. Do try out Ara, the traditional hot alcoholic beverage consumed by the majority of the population and is made of rice, barley, wheat, or millet, and herbs which is either fermented or diluted.</div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingFive">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                                                    Can I use Indian money in Bhutan Tour from Mumbai?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseFive" className="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">Yes, you can carry Indian Note to Bhutan. Except Rs 2000/- Note, which is not a legal tender in Bhutan? However, many merchants in Bhutan now days accept Rs 2000/- Note.</div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingSix">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseFive">
                                                    How are the Bhutan Mobile / Internet Connectivity?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseSix" className="accordion-collapse collapse" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">ndian Mobile Operators provide the Global Roaming Connectivity in Bhutan, However, it is expensive. Please check with your operator about Global Roaming Activation / Incoming and Out Going Charges. Tourists can easily get the Bhutan Tourists SIM card (Tashi Cell and BMobile ) at Rs 200/- with data option at Rs 99/- for 400 MB data.</div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingSeven">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
                                                    Can I get wine in Bhutan including imported Brands?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseSeven" className="accordion-collapse collapse" aria-labelledby="flush-headingSeven" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">In Paro Duty free shop, you can get various brands of imported foreign Whisky, Wine, Vodka, Beer etc and price is relatively cheaper compared to any Indian Duty Free shop. In Thimphu, you can get the imported wine brand, however in Paro and Punakha, you may get the imported brands only in your hotel, not in any off shop.

                                                    However, in Bhutan, availability of local brand wines is abundant and they are really worthy to buy. While you are in Bhutan Package Tour, you must buy Local Peach Wine and am confident you will love it.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container-fluid py-4">

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h3><b>Bhutan <span>Theme Package</span></b></h3>
                                            <h4>Similar Mountain Trip</h4>

                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                                        <div className="carousel-inner">
                                            <div className="carousel-item active">
                                                <Slider {...settings}>

                                                    {themePackagesDataView}

                                                </Slider>
                                            </div>
                                        </div>
                                    </div><br />
                                </div>
                            </div>

                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h3 className="tomato"><b>Bhutan Climate Guide</b></h3>
                                            <h4><i>"Yearly Temperature Update"</i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="table-responsive">

                                    <table className="table table-bordered table-striped table-condensed cf">
                                        <thead>
                                            <tr className="table-primary">
                                                <th scope="col">Destination</th>
                                                <th scope="col">Temperature</th>
                                                <th scope="col">Jan</th>
                                                <th scope="col">Feb</th>
                                                <th scope="col">Mar</th>
                                                <th scope="col">Apr</th>
                                                <th scope="col">May</th>
                                                <th scope="col">Jun</th>
                                                <th scope="col">Jul</th>
                                                <th scope="col">Aug</th>
                                                <th scope="col">Sept</th>
                                                <th scope="col">Oct</th>
                                                <th scope="col">Nov</th>
                                                <th scope="col">Dec</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="table-danger">
                                                <th scope="row" rowspan="3">Paro</th>
                                                <td rowspan="3">Min Temp(C)<br />Max Temp(C)<br />clear days</td>
                                                <td rowspan="3">-1<br />11<br />26</td>
                                                <td rowspan="3">1<br />11<br />23</td>
                                                <td rowspan="3">4<br />14<br />25</td>
                                                <td rowspan="3">7<br />17<br />25</td>
                                                <td rowspan="3">10.5<br />20.4<br />22</td>
                                                <td rowspan="3">13<br />21<br />14</td>
                                                <td rowspan="3">15<br />22<br />14</td>
                                                <td rowspan="3">14<br />22<br />16</td>
                                                <td rowspan="3">13<br />21<br />20</td>
                                                <td rowspan="3">8<br />19<br />27</td>
                                                <td rowspan="3">3<br />16<br />27</td>
                                                <td rowspan="3">-1<br />13<br />29</td>
                                            </tr>
                                            <tr></tr>
                                            <tr></tr>
                                            <tr></tr>

                                            {/* <!-- Next set of data for Paro --> */}
                                            <tr>
                                                <th scope="row" rowspan="3">Thimphu</th>
                                                <td rowspan="3">Min Temp(C)<br />Max Temp(C)<br />clear days</td>
                                                <td rowspan="3">-2.6<br />13<br />27</td>
                                                <td rowspan="3">0.6<br />14.4<br />23</td>
                                                <td rowspan="3">4<br />13<br />25</td>
                                                <td rowspan="3">7<br />16<br />25</td>
                                                <td rowspan="3">9.5<br />20<br />22</td>
                                                <td rowspan="3">12.5<br />21<br />21</td>
                                                <td rowspan="3">14<br />22<br />14</td>
                                                <td rowspan="3">14<br />21<br />17</td>
                                                <td rowspan="3">12<br />21<br />20</td>
                                                <td rowspan="3">7<br />19<br />27</td>
                                                <td rowspan="3">2<br />15<br />28</td>
                                                <td rowspan="3">-1.5<br />12<br />29</td>
                                            </tr>
                                            <tr></tr>
                                            <tr></tr>
                                            <tr></tr>
                                            <tr className="table-danger">
                                                <th scope="row" rowspan="3">Jakar</th>
                                                <td rowspan="3">Min Temp(C)<br />Max Temp(C)<br />clear days</td>
                                                <td rowspan="3">-3<br />9<br />29</td>
                                                <td rowspan="3">-6<br />8<br />26</td>
                                                <td rowspan="3">3<br />12<br />29</td>
                                                <td rowspan="3">6<br />18.5<br />29</td>
                                                <td rowspan="3">9.5<br />22.1<br />22</td>
                                                <td rowspan="3">13<br />22<br />14</td>
                                                <td rowspan="3">14<br />22<br />19</td>
                                                <td rowspan="3">13<br />23<br />21</td>
                                                <td rowspan="3">12<br />21<br />22</td>
                                                <td rowspan="3">7<br />19<br />28</td>
                                                <td rowspan="3">1<br />15<br />30</td>
                                                <td rowspan="3">-2.5<br />12.5<br />30</td>
                                            </tr>
                                            <tr></tr>
                                            <tr></tr>
                                            <tr></tr>
                                            <tr>
                                                <th scope="row" rowspan="3">Bumthang</th>
                                                <td rowspan="3">Min Temp(C)<br />Max Temp(C)<br />clear days</td>
                                                <td rowspan="3">-3.7<br />11.1<br />29</td>
                                                <td rowspan="3">-7<br />8<br />26</td>
                                                <td rowspan="3">-5<br />4<br />29</td>
                                                <td rowspan="3">-1<br />7<br />28</td>
                                                <td rowspan="3">8.5<br />19.6<br />23</td>
                                                <td rowspan="3">14<br />23<br />16</td>
                                                <td rowspan="3">13<br />21<br />19</td>
                                                <td rowspan="3">13<br />21<br />21</td>
                                                <td rowspan="3">11<br />20<br />22</td>
                                                <td rowspan="3">6<br />18<br />28</td>
                                                <td rowspan="3">0<br />14<br />30</td>
                                                <td rowspan="3">-3.5<br />11.5<br />30</td>
                                            </tr>
                                            <tr></tr>
                                            <tr></tr>
                                            <tr></tr>
                                            <tr className="table-danger">
                                                <th scope="row" rowspan="3">Punakha</th>
                                                <td rowspan="3">Min Temp(C)<br />Max Temp(C)<br />clear days</td>
                                                <td rowspan="3">4.9<br />16.8<br />29</td>
                                                <td rowspan="3">7<br />18.7<br />27</td>
                                                <td rowspan="3">10.5<br />22<br />29</td>
                                                <td rowspan="3">13.8<br />24.7<br />29</td>
                                                <td rowspan="3">16.5<br />25.8<br />19</td>
                                                <td rowspan="3">19<br />23<br />16</td>
                                                <td rowspan="3">20<br />27<br />20</td>
                                                <td rowspan="3">19<br />26<br />22</td>
                                                <td rowspan="3">18<br />26<br />23</td>
                                                <td rowspan="3">15<br />25<br />28</td>
                                                <td rowspan="3">9.5<br />21<br />29</td>
                                                <td rowspan="3">6<br />18<br />30</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>


                            <div className="container-fluid py-5">
                                <div className="card">
                                    <div className="card-header custHeaer">
                                        Discover the Best Time to Book Bhutan Tour Package from Mumbai with Adorable Vacation LLP
                                    </div>
                                    <div className="card-body">
                                        <span className="icon-img-color">
                                            <i className="fa fa-sun-o" aria-hidden="true"></i>&nbsp;
                                            <span className="black"><strong>Summer Season in Bhutan</strong></span>
                                        </span>
                                        <br />
                                        <span>The summer months are June, July, and August. It is the best time to visit Bhutan as these months
                                            are
                                            the warmest and sunniest, excellent for all the outdoor activities and sightseeing.</span><br />
                                        <br />
                                        <span className="icon-img-color">
                                            <i className="fa fa-umbrella" aria-hidden="true"></i>&nbsp;
                                            <span className="black"><strong>Monsoon Season in Bhutan</strong></span>
                                        </span>
                                        <br />
                                        <span>Though, during the months of June to August, there might be mild showers in the afternoon; but the
                                            regions of Bhutan do not experience the monsoon season as such, for the rain fall very
                                            rarely.</span><br />
                                        <br />
                                        <span className="icon-img-color">
                                            <i className="fa fa-snowflake-o" aria-hidden="true"></i>&nbsp;
                                            <span className="black"><strong>Monsoon Season in Bhutan</strong></span>
                                        </span>
                                        <br />
                                        <span>Though, during the months of June to August, there might be mild showers in the afternoon; but the
                                            regions of Bhutan do not experience the monsoon season as such, for the rain fall very
                                            rarely.</span><br />
                                        <br />
                                    </div>
                                </div>
                            </div >


                            <div className="container-fluid py-5">

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h3><b>Bhutan Tour</b> Reviews</h3>
                                            <h4><i>"Your Experience is our Marketing"</i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-cols-1">
                                    <div id="testimonials">
                                        <div className="testimonial-box-container">

                                            {reviewersDataView}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div >
                        <div className="col-md-3">
                            <BookingBox />
                        </div>

                    </div >
                </div >

            </>
        );
    }
}

export default BhutanTourPackage;