import React, { Component } from 'react';
import BookingBox from '../BookingBox';
import Slider from "react-slick";
import ApiURL from "../../../api/ApiURL";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InclusionExclusion from '../Meghalaya/InclusionExclusion';

class Arunachal_11N_12D_Package extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customActiveTab: 'tab1',
            daywiseActiveTab: 'day1'
        }
    }

    handleCustomTabClick = (tab) => {
        this.setState({
            customActiveTab: tab,
        });
    };

    handleDayWiseTabClick = (tab) => {
        this.setState({
            daywiseActiveTab: tab,
        });
    };

    render() {
        const { customActiveTab, daywiseActiveTab } = this.state;
        const packageTopBannerImageURL = ApiURL.Arunachal_11N_12D_PackageImageURL + "/heder-image.webp";
        const navDay1ImageURL = ApiURL.Arunachal_11N_12D_PackageImageURL + "/day1.webp";
        const navDay2ImageURL = ApiURL.Arunachal_11N_12D_PackageImageURL + "/day2.webp";
        const navDay3ImageURL = ApiURL.Arunachal_11N_12D_PackageImageURL + "/day3.webp";
        const navDay4ImageURL = ApiURL.Arunachal_11N_12D_PackageImageURL + "/day4.webp";
        const navDay5ImageURL = ApiURL.Arunachal_11N_12D_PackageImageURL + "/day5.webp";
        const navDay6ImageURL = ApiURL.Arunachal_11N_12D_PackageImageURL + "/day6.webp";
        const navDay7ImageURL = ApiURL.Arunachal_11N_12D_PackageImageURL + "/day7.webp";
        const navDay8ImageURL = ApiURL.Arunachal_11N_12D_PackageImageURL + "/day8.webp";
        const navDay9ImageURL = ApiURL.Arunachal_11N_12D_PackageImageURL + "/day9.webp";
        const navDay10ImageURL = ApiURL.Arunachal_11N_12D_PackageImageURL + "/day10.webp";
        const navDay11ImageURL = ApiURL.Arunachal_11N_12D_PackageImageURL + "/day11.webp";
        const navDay12ImageURL = ApiURL.Arunachal_11N_12D_PackageImageURL + "/day12.webp";

        const TrendingPackagesDataList = this.props.TrendingPackagesData;
        const hotPackagesView = TrendingPackagesDataList.length > 0 ?
            (
                TrendingPackagesDataList.map((dataItem, i) => {
                    const hotelImgURL = ApiURL.Arunachal_11N_12D_PackageImageURL + "/" + dataItem.ImageName;
                    return (<div className="col-md-12 p-0" key={i}>
                        <div className="card h-100">
                            <img src={hotelImgURL} className="card-img-top" alt={dataItem.AllternateText} />
                            <div className="card-body">
                                <h6 className="card-title">{dataItem.Title} <span> &#8377; {dataItem.PricePerNight}</span></h6>
                                <p className="card-text">
                                    <i className="fa fa-building tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-utensils tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-car-side tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-male tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-camera tomato"></i>
                                </p>
                                <p className="card-text"><span>{dataItem.Description}</span></p>
                                <p>
                                    <span><b className="tomato">{dataItem.NoOfTours} </b>Tours</span> | &nbsp;
                                    <span><b className="tomato">{dataItem.NoOfDeparture} </b>Departures</span> | &nbsp;
                                    <span><b className="sdf-blue"> {dataItem.Include}</b></span>
                                </p>
                            </div>
                            <div className="card-footer">
                                <small className="text-muted"><strong className="tomato">{dataItem.MutedText}</strong></small>
                                <button type="button" className="btn btn-Warning btn-sm float-end">Book Now</button>
                            </div>
                        </div>
                    </div>)
                })
            ) : (<div></div>)


        const settings = {
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const reviewersDataList = this.props.ReviewersData;
        const reviewersDataView = reviewersDataList.map((dataItem, i) => {
            const reviewerImageURL = ApiURL.Arunachal_11N_12D_PackageImageURL + "/" + dataItem.ImageName;
            return (
                <div className="testimonial-box" key={i}>
                    <div className="box-top">
                        <div className="profile">
                            <div className="profile-img">
                                <img src={reviewerImageURL} alt="..." />
                            </div>
                            <div className="name-user">
                                <strong>{dataItem.Name}</strong>
                                <span>{dataItem.Location}</span>
                            </div>
                        </div>
                        <div className="reviews">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </div>
                    </div>
                    <div className="client-comment">
                        <p>{dataItem.Comment}</p>
                    </div>
                </div>
            )
        })

        return (
            <>
                <div className="container-fluid">
                    <div className="row">
                        <img src={packageTopBannerImageURL} className="img-fluid px-0" alt="..." />
                    </div>
                </div>
                <div className="container-fluid py-3">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="container-fluid py-1">

                                        <h5 className="tomato">Assam Arunachal Pradesh 11N/12D Tour Package</h5>
                                        <hr />

                                        <div className="col-12">
                                            <big className="text-muted"><strong className="tomato">11N/12D </strong></big>&nbsp;
                                            <i className="fa fa-plane-departure"></i>&nbsp;&nbsp;
                                            <i className="fa fa-arrow-right"></i>&nbsp;Guwahati(2N)&nbsp;
                                            <i className="fa fa-arrow-right"></i>&nbsp;Shillong(3N)&nbsp;
                                            <i className="fa fa-arrow-right"></i>&nbsp;Kaziranga(1N)&nbsp;
                                            <i className="fa fa-arrow-right"></i>&nbsp;Bhalukpong(1N)&nbsp;
                                            <i className="fa fa-arrow-right"></i>&nbsp;Dirang(1N)&nbsp;
                                            <i className="fa fa-arrow-right"></i>&nbsp;Tawang(2N)&nbsp;
                                            <i className="fa fa-arrow-right"></i>&nbsp;Bomdila(1N)&nbsp;
                                            <i className="fa fa-arrow-right"></i>&nbsp;&nbsp;
                                            <i className="fa fa-plane-departure fa-flip-horizontal"></i>
                                            <br />
                                        </div>
                                        <hr />
                                    </div>

                                    <div className="container-fluid navtabCustom">
                                        <nav>
                                            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                                <a onClick={() => this.handleCustomTabClick('tab1')} className={customActiveTab === 'tab1' ? 'nav-item nav-link active' : 'nav-item nav-link'} role="tab" aria-controls="nav-highlights" aria-selected="true">Highlights</a>
                                                <a onClick={() => this.handleCustomTabClick('tab2')} className={customActiveTab === 'tab2' ? 'nav-item nav-link active' : 'nav-item nav-link'} role="tab" aria-controls="nav-flights" aria-selected="false">Flights</a>
                                                <a onClick={() => this.handleCustomTabClick('tab3')} className={customActiveTab === 'tab3' ? 'nav-item nav-link active' : 'nav-item nav-link'} role="tab" aria-controls="nav-hotel" aria-selected="false">Hotel</a>
                                                <a onClick={() => this.handleCustomTabClick('tab4')} className={customActiveTab === 'tab4' ? 'nav-item nav-link active' : 'nav-item nav-link'} role="tab" aria-controls="nav-sightseeing" aria-selected="false">Sightseeings</a>
                                                <a onClick={() => this.handleCustomTabClick('tab5')} className={customActiveTab === 'tab5' ? 'nav-item nav-link active' : 'nav-item nav-link'} role="tab" aria-controls="nav-meals" aria-selected="false">Meals</a>
                                            </div>
                                        </nav>
                                        <div className="tab-content" id="nav-tabContent">
                                            <div className={customActiveTab === 'tab1' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-highlights-tab">
                                                Travel to some of the remotest and vigor corners of North eastern part of India during this private
                                                multi state travel through Assam, Meghalaya and Arunchal Pradesh.
                                                Experience one of the best natural attractions, traditional food and cultural heritage, this includes
                                                the jeep safari through Kaziranga National Park, caves and waterfalls, and visits to the Chinese and
                                                Bangladeshi border areas.
                                            </div>
                                            <div className={customActiveTab === 'tab2' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-flights-tab">
                                                <div className="overflow-table">
                                                    <span>Guwahati Non-Stop Flight <b>Kolkata <i>(GAU)</i></b></span><br /><br />
                                                    <table className="table table-bordered">
                                                        <tr className="table-danger">
                                                            <th>FLIGHT</th>
                                                            <th>AIRLINES</th>
                                                            <th>AIRPORT</th>
                                                            <th>TIME</th>
                                                            <th>DAY</th>
                                                        </tr>
                                                        <tr>
                                                            <td>6E262</td>
                                                            <td>IndiGo</td>
                                                            <td>Delhi</td>
                                                            <td>07:25 - 09:40</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>SG1852</td>
                                                            <td>SpiceJet</td>
                                                            <td>Delhi</td>
                                                            <td>10:10 - 12:15</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>6C228</td>
                                                            <td>IndiGo</td>
                                                            <td>Mumbai</td>
                                                            <td>05:40 - 08:50</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>SG695</td>
                                                            <td>SpiceJet</td>
                                                            <td>Mumbai</td>
                                                            <td>06:40 - 09:30</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>6C457</td>
                                                            <td>IndiGo</td>
                                                            <td>Bengaluru</td>
                                                            <td>08:20 - 11:20</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>QP1323</td>
                                                            <td>Akasa Air</td>
                                                            <td>Bengaluru</td>
                                                            <td>09:20 - 11:20</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>6E6309</td>
                                                            <td>IndiGo</td>
                                                            <td>Chennai</td>
                                                            <td>05:40 - 08:25</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>6E6011</td>
                                                            <td>IndiGo</td>
                                                            <td>Chennai</td>
                                                            <td>15:55 - 18:30</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className={customActiveTab === 'tab3' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-hotel-tab">

                                                <div className="overflow-table">
                                                    <table className="table table-bordered">
                                                        <tr className="table-primary text-center">
                                                            {/* <!-- Add the "text-center" className to center content --> */}
                                                            <th colspan="6">HOTEL CATEGORY - 3 STAR </th>
                                                        </tr>
                                                        <tr className="table-danger">
                                                            <th className="text-center">Destination</th>
                                                            <th className="text-center">Hotel Names</th>
                                                            <th className="text-center">Hotel Type</th>
                                                            <th className="text-center">No of Nights</th>
                                                            <th className="text-center">Meal Plan</th>
                                                        </tr>
                                                        <tr>
                                                            <td>Kaziranga</td>
                                                            <td>D’Courtyard Resort / Dhanshree Resort / La Vue Resort </td>
                                                            <td>3 Star</td>
                                                            <td>2N</td>
                                                            <td>Room with Breakfast</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Shillong</td>
                                                            <td>The Eee Cee Hotel / HOTEL SAPPHIRE / Hotel Jessica Residency </td>
                                                            <td>3 Star</td>
                                                            <td>3N</td>
                                                            <td>Room with Breakfast</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Cherapunjee</td>
                                                            <td>7 Sisters Falls View Inn / SERENE RESORT </td>
                                                            <td>3 Star</td>
                                                            <td>1N</td>
                                                            <td>Room with Breakfast</td>
                                                        </tr>
                                                    </table>

                                                    <table className="table table-bordered">
                                                        <tr className="table-primary text-center">
                                                            {/*  <!-- Add the "text-center" className to center content --> */}
                                                            <th colspan="6">PACKAGE PRICE IN 3 STAR CATEGORY HOTEL</th>
                                                        </tr>
                                                        <tr class="table-danger">
                                                            <th class="text-center">No of Pax</th>
                                                            <th class="text-center">Package Price</th>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-center">02 Adults</td>
                                                            <td class="text-center">28,900/- PP</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-center">
                                                                04 Adults
                                                            </td>
                                                            <td class="text-center">
                                                                22,800/- PP
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-center">
                                                                06 Adults
                                                            </td>
                                                            <td class="text-center">
                                                                19,200/- PP
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    <br />

                                                </div>
                                            </div>
                                            <div className={customActiveTab === 'tab4' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-sightseeing-tab">
                                                <div className="container-fluid px-0 py-1">
                                                    <h5 class="tomato">Kaziranga</h5>
                                                    <ul class="place">
                                                        <p> Kazirnaga Jungle Safari, Cultural program in orchid.</p>
                                                    </ul>
                                                    <h5 class="tomato">Shillong</h5>
                                                    <ul class="place">
                                                        <p>Umiam Lake, Wards lake, Hyadari Park, Don Bosco Muesum, Indian Air Force Muesum, Elephant Falls and Shillong Peak, police bazar.</p>
                                                    </ul>
                                                    <h5 class="tomato">Cherapunjee</h5>
                                                    <ul class="place">
                                                        <p> Nohkalikai Falls, Mawsmai Cave, Seven Sister Falls, Arwah Cave, Eco Park and Thangkharang Park.</p>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className={customActiveTab === 'tab5' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-meals-tab">
                                                <div className="overflow-table">
                                                    <h5 class="tomato">11 times Meals and 11 times Breakfast has been Provided</h5>
                                                    <table className="table table-bordered">
                                                        <tr className="table-danger">
                                                            <th colspan="6" className="text-center">Category</th>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-center">JAIN</td>
                                                            <td class="text-center">PURE-VEG</td>
                                                            <td class="text-center">VEGAN</td>
                                                            <td class="text-center">PURE-NON-VEG</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <!--By Itinerary--> */}
                            <div className="container-fluid navtabCustom">

                                <h5 className="tomato">Day wise Itinerary</h5>
                                <hr />

                                <nav>
                                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                        <a onClick={() => this.handleDayWiseTabClick('day1')} className={daywiseActiveTab === 'day1' ? 'nav-item nav-link active' : 'nav-item nav-link'} role="tab" aria-controls="nav-day-one" aria-selected="true">DAY-1</a>
                                        <a onClick={() => this.handleDayWiseTabClick('day2')} className={daywiseActiveTab === 'day2' ? 'nav-item nav-link active' : 'nav-item nav-link'} role="tab" aria-controls="nav-day-two" aria-selected="false">DAY-2</a>
                                        <a onClick={() => this.handleDayWiseTabClick('day3')} className={daywiseActiveTab === 'day3' ? 'nav-item nav-link active' : 'nav-item nav-link'} role="tab" aria-controls="nav-day-three" aria-selected="false">DAY-3</a>
                                        <a onClick={() => this.handleDayWiseTabClick('day4')} className={daywiseActiveTab === 'day4' ? 'nav-item nav-link active' : 'nav-item nav-link'} role="tab" aria-controls="nav-day-four" aria-selected="true">DAY-4</a>
                                        <a onClick={() => this.handleDayWiseTabClick('day5')} className={daywiseActiveTab === 'day5' ? 'nav-item nav-link active' : 'nav-item nav-link'} role="tab" aria-controls="nav-day-five" aria-selected="false">DAY-5</a>
                                        <a onClick={() => this.handleDayWiseTabClick('day6')} className={daywiseActiveTab === 'day6' ? 'nav-item nav-link active' : 'nav-item nav-link'} role="tab" aria-controls="nav-day-six" aria-selected="false">DAY-6</a>
                                        <a onClick={() => this.handleDayWiseTabClick('day7')} className={daywiseActiveTab === 'day7' ? 'nav-item nav-link active' : 'nav-item nav-link'} role="tab" aria-controls="nav-day-seven" aria-selected="false">DAY-7</a>
                                        <a onClick={() => this.handleDayWiseTabClick('day8')} className={daywiseActiveTab === 'day8' ? 'nav-item nav-link active' : 'nav-item nav-link'} role="tab" aria-controls="nav-day-eight" aria-selected="false">DAY-8</a>
                                        <a onClick={() => this.handleDayWiseTabClick('day9')} className={daywiseActiveTab === 'day9' ? 'nav-item nav-link active' : 'nav-item nav-link'} role="tab" aria-controls="nav-day-nine" aria-selected="false">DAY-9</a>
                                        <a onClick={() => this.handleDayWiseTabClick('day10')} className={daywiseActiveTab === 'day10' ? 'nav-item nav-link active' : 'nav-item nav-link'} role="tab" aria-controls="nav-day-ten" aria-selected="false">DAY-10</a>
                                        <a onClick={() => this.handleDayWiseTabClick('day11')} className={daywiseActiveTab === 'day11' ? 'nav-item nav-link active' : 'nav-item nav-link'} role="tab" aria-controls="nav-day-eleven" aria-selected="false">DAY-11</a>
                                        <a onClick={() => this.handleDayWiseTabClick('day12')} className={daywiseActiveTab === 'day12' ? 'nav-item nav-link active' : 'nav-item nav-link'} role="tab" aria-controls="nav-day-twelve" aria-selected="false">DAY-12</a>

                                    </div>
                                </nav>
                                <div className="tab-content" id="nav-tabContent">
                                    <div className={daywiseActiveTab === 'day1' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-one-tab">
                                        <img src={navDay1ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 class="tomato"> <b>Day 1 – Guwahati</b></h5>
                                        <p><b>Meet and greet on arrival</b> Check in hotel. After freshen up visit Umananda & Gandhi Mandap.
                                            Evening enjoy Sunset Cruise at Mighty River Brahmaputra (on direct payment basis).
                                        </p>
                                        <b>Overnight stay at the hotel Guwahati.</b>
                                    </div>
                                    <div className={daywiseActiveTab === 'day2' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-two-tab">
                                        <img src={navDay2ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 class="tomato"><b>Day 2 - Guwahati - Shillong</b></h5>
                                        <p>
                                            <b>Today after breakfast, </b> check out from hotel and visit Maa Kamakhya Temple. Later drive to
                                            Shillong (Meghalaya). Enroute visit Umiam Lake. If time permits visit Wards Lake & Lady Hyderi Park.
                                            Evening free for leisure.<br /><br />
                                        </p>
                                        <b>Overnight stay at Hotel Shillong.</b>
                                    </div>
                                    <div className={daywiseActiveTab === 'day3' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-three-tab">
                                        <img src={navDay3ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 class="tomato"> <b>Day 3 –Shillong - Mawlynnong - Dawki - Shillong</b></h5>
                                        <p>Today after breakfast visit Elephant Falls. Later drive to visit Mawlynnong village and Dawki. You can
                                            also visit Single Decker Root Bridge in Riwai Village and Balancing Rock in Mawlynnong. On the way to
                                            Dawki you can enjoy boating (on direct payment basis) in Umangkt River (known as Tamabil in Bangladesh).
                                        </p>
                                        <b>Later drive to Shillong and night stay, in Shillong.</b>
                                    </div>
                                    <div className={daywiseActiveTab === 'day4' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-four-tab">
                                        <img src={navDay4ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 class="tomato"> <b>Day 4 – Shillong - Cherrapunjee - Shillong</b></h5>
                                        <p>
                                            Today after breakfast drive to Cherrapunjee for a day trip. En route visit Shillong Peak & Mawkdok
                                            Dympep Valley. Later visit Thankaranga Park, Mawsmai Cave, Nohkalikai Falls & Seven Sister Falls.
                                            Evening drive back to Shillong.
                                        </p>
                                        <b>Overnight stay at Hotel Shillong.</b>
                                    </div>
                                    <div className={daywiseActiveTab === 'day5' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-five-tab">
                                        <img src={navDay5ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 class="tomato"> <b>Day 5 – Shillong - Kaziranga</b></h5>
                                        <p>
                                            <b>Morning after breakfast,</b>
                                            Today after breakfast check out from hotel and visit Don Bosco Museum & Glaneagles Golf Course. Later
                                            drive to Kaziranga & visit Orchid museum & enjoy cultural shows (on direct payment basis).
                                        </p>
                                        <b>Overnight stay at Kaziranga.</b>
                                    </div>
                                    <div className={daywiseActiveTab === 'day6' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-six-tab">
                                        <img src={navDay6ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 class="tomato"> <b>Day 6 – Kaziranga - Bhalukpong</b></h5>
                                        <p>
                                            Early morning wake up call for Elephant safari. Post breakfast check out & Jeep Safari inside at
                                            Kaziranga National Park (Central/Western Range). After lunch visit Orchid Museum. Later transfer to
                                            Bhalukpong.</p>
                                        <b>Overnight stay at a hotel, in Bhalukpong.</b>
                                    </div>
                                    <div className={daywiseActiveTab === 'day7' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-seven-tab">
                                        <img src={navDay7ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 class="tomato"> <b>Day 7 – Bhalukpong - Dirang</b></h5>
                                        <p>
                                            Today after breakfast check out from hotel & visit Bhalukpong Fort. Later drive to Dirang. On the way
                                            enjoy breathtaking beauty of Kameng river.
                                        </p>
                                        <b>Overnight in Dirang.</b>
                                    </div>
                                    <div className={daywiseActiveTab === 'day8' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-eight-tab">
                                        <img src={navDay8ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 class="tomato"> <b>Day 8 – Dirang - Tawang</b></h5>
                                        <p>
                                            Morning after breakfast drive to Tawang. Enroute visit Hot Spring, Sela Pass, Jaswantgarh and the
                                            Spectacular Nuranang Water Falls at Dirang.</p>
                                        <b>Overnight stay, in Tawang.</b>
                                    </div>
                                    <div className={daywiseActiveTab === 'day9' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-nine-tab">
                                        <img src={navDay9ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 class="tomato"> <b>Day 9 – Tawang - Bumla Pass - Tawang</b></h5>
                                        <p>
                                            Morning after breakfast visit Bumla Pass (Indo-China Border), Sangetsar Lake (Madhuri Lake).</p>
                                        <b>Overnight stay at a hotel, in Tawang.</b>
                                    </div>
                                    <div className={daywiseActiveTab === 'day10' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-ten-tab">
                                        <img src={navDay10ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 class="tomato"> <b>Day 10 – Tawang - Bomdila</b></h5>
                                        <p>
                                            Morning after breakfast visit Tawang Monastery & War Memorial. Later drive to Bomdila. Enroute visit
                                            Buddhists Gompas & Apple Orchards.</p>
                                        <b>Overnight stay, in Bomdila.</b>
                                    </div>
                                    <div className={daywiseActiveTab === 'day11' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-eleven-tab">
                                        <img src={navDay11ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 class="tomato"> <b>Day 11 – Bomdila - Guwahati</b></h5>
                                        <p>
                                            Morning after breakfast visit Bomdila Monastery. Later drive to Guwahati. Enroute visit Orchid research
                                            center at Tipi.</p>
                                        <b>Overnight stay, in Guwahati.</b>
                                    </div>
                                    <div className={daywiseActiveTab === 'day12' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-twelve-tab">
                                        <img src={navDay12ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 class="tomato"> <b>Day 12 – Guwahati Airport / Railway Station Drop</b></h5>
                                        <p>
                                            Today after breakfast check out & and drive to Guwahati Airport for your onward destination. Tour ends
                                            with everlasting memories…!!!</p>
                                    </div>
                                </div>
                            </div>
                            {/* <!--By Itinerary--> */}

                            {/* <!--insert table inclusion exclusion--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        {
                                            <InclusionExclusion />
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* <!--end table inclusion exclusion--> */}

                            {/* <!--start of package details--> */}
                            <div className="container-fluid py-5">

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>Trending Meghalaya<span>Tour Packages</span></h2>
                                            <h4>Related Assam Meghalaya Tour plan</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                                        <div className="carousel-inner">
                                            <div className="carousel-item active">

                                                <Slider {...settings}>
                                                    {hotPackagesView}
                                                </Slider>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!--end of package details--> */}

                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h3><b>Meghalaya <span class="tomato">Tour Reviews</span></b></h3>
                                            <h4><i>"Your Experience is our Marketing"</i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-cols-1">
                                    <div id="testimonials">
                                        <div className="testimonial-box-container">

                                            {reviewersDataView}

                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="col-md-3">
                            <BookingBox />
                        </div>
                    </div >
                </div >
                <div class="call-float">
                        <a href="tel:+91-91636-76037">
                            <i className="fa fa-phone"></i><span><small>+91 9163676037</small></span>
                        </a>
                    </div>
            </>
        );
    }
}

export default Arunachal_11N_12D_Package;