import React, { Component } from 'react';
import BookingBox from '../BookingBox';
import Slider from "react-slick";
import ApiURL from "../../../api/ApiURL";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InclusionExclusion from './InclusionExclusion_kashmir_surat';

class Kashmir_7N_8D_Package_Tour_From_Surat extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customActiveTab: 'tab1',
            daywiseActiveTab: 'day1'
        }
    }

    handleCustomTabClick = (tab) => {
        this.setState({
            customActiveTab: tab,
        });
    };

    handleDayWiseTabClick = (tab) => {
        this.setState({
            daywiseActiveTab: tab,
        });
    };
    handleClick = () => {
        const currentUrl = window.location.href;
        const pageTitle = document.title;
        const message = `Hi, Please send about the Tour Package details "${pageTitle}"`;

        const whatsappLink = `https://api.whatsapp.com/send?phone=+919147061467&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
        window.open(whatsappLink, '_blank');
    };

    render() {
        const { customActiveTab, daywiseActiveTab } = this.state;
        const packageTopBannerImageURL = ApiURL.Kashmir_Tour_Package_from_Surat + "/Kashmir-Package-Tour-from-Surat.webp";
        const packageTopBanner2ImageURL = ApiURL.Kashmir_Tour_Package_from_Surat + "/Kashmir-Tour-Package-from-Surat.webp";
        const sightseen1ImageURL = ApiURL.Kashmir_Tour_Package_from_Surat + "/Kashmir-Packages-Tour-Cost.webp";
        const sightseen2ImageURL = ApiURL.Kashmir_Tour_Package_from_Surat + "/Kashmir-Package-Tour-Operator-in Surat.webp";
        const sightseen3ImageURL = ApiURL.Kashmir_Tour_Package_from_Surat + "/Kashmir-Tours-from-Surat.webp";
        const sightseen4ImageURL = ApiURL.Kashmir_Tour_Package_from_Surat + "/Surat-to-Kashmir-Package-Tour.webp";
        const sightseen5ImageURL = ApiURL.Kashmir_Tour_Package_from_Surat + "/Surat-to-Kashmir-Package-Tour-Cost.webp";
        const sightseen6ImageURL = ApiURL.Kashmir_Tour_Package_from_Surat + "/Surat-to-Kashmir-Package-Tours.webp";
        const sightseen7ImageURL = ApiURL.Kashmir_Tour_Package_from_Surat + "/Surat-to-Kashmir-Tour-Package-Booking.webp";
        const sightseen8ImageURL = ApiURL.Kashmir_Tour_Package_from_Surat + "/Surat-to-Kashmir-Trip.webp";
        const sightseen9ImageURL = ApiURL.Kashmir_Tour_Package_from_Surat + "/Kashmir-from-Surat.webp";
        const sightseen10ImageURL = ApiURL.Kashmir_Tour_Package_from_Surat + "/Kashmir-Packages-from-Surat.webp";
        const sightseen11ImageURL = ApiURL.Kashmir_Tour_Package_from_Surat + "/Kashmir-Package-Tour.webp";
        const sightseen12ImageURL = ApiURL.Kashmir_Tour_Package_from_Surat + "/Kashmir-Package-Tour-Cost.webp";
        //day wise itenaryy
        const navDay1ImageURL = ApiURL.Kashmir_Tour_Package_from_Surat + "/7N-8D-Kashmir-Tulip-Festival-Package-Tour.webp";
        const navDay2ImageURL = ApiURL.Kashmir_Tour_Package_from_Surat + "/2024-Tulip-Garden-Kashmir-Package.webp";
        const navDay3ImageURL = ApiURL.Kashmir_Tour_Package_from_Surat + "/Customized-Kashmir-Tour.webp";
        const navDay4ImageURL = ApiURL.Kashmir_Tour_Package_from_Surat + "/Tulip-Festival-in-Kashmir.webp";
        const navDay5ImageURL = ApiURL.Kashmir_Tour_Package_from_Surat + "/Srinagar-Tulip-Garden-Tour.webp";
        const navDay6ImageURL = ApiURL.Kashmir_Tour_Package_from_Surat + "/Tulip-Garden-Tour-Package.webp";
        const navDay7ImageURL = ApiURL.Kashmir_Tour_Package_from_Surat + "/Book-Kashmir-Tulip-Trip.webp";
        const navDay8ImageURL = ApiURL.Kashmir_Tour_Package_from_Surat + "/Tulip-Festival-Special-2024.webp";
        const Topbanner0ImageURL = ApiURL.Kashmir_Tour_Package_from_Surat + "/Kashmir-Houseboat-Packages.webp";
        const Topbanner1ImageURL = ApiURL.Kashmir_Tour_Package_from_Surat + "/Kashmir-Adventure-Tour-Plan.webp";
        const Topbanner2ImageURL = ApiURL.Kashmir_Tour_Package_from_Surat + "/Kashmir-Gondola-Package-Tour.webp";
        const Topbanner3ImageURL = ApiURL.Kashmir_Tour_Package_from_Surat + "/Kashmir-Trekking-Packages.webp";
        const Topbanner4ImageURL = ApiURL.Kashmir_Tour_Package_from_Surat + "/Kashmir-White-River-Rafting-Plan.webp";
        const brandedTourackageImage_1_URL = ApiURL.Kashmir_Tour_Package_from_Surat + "/The-Khyber-Himalayan-Resort-&-Spa.webp";
        const brandedTourackageImage_2_URL = ApiURL.Kashmir_Tour_Package_from_Surat + "/The-LaLiT-Grand-Palace-Srinagar-Hotel.webp";
        const TrendingPackagesDataList = this.props.TrendingPackagesData;
        const hotPackagesView = TrendingPackagesDataList.length > 0 ?
            (
                TrendingPackagesDataList.map((dataItem, i) => {
                    const hotelImgURL = ApiURL.Kashmir_Tour_Package_from_Surat + "/" + dataItem.ImageName;
                    return (<div className="col-md-12 p-0" key={i}>
                        <div className="card h-100">
                            <img src={hotelImgURL} className="card-img-top" alt={dataItem.AllternateText} />
                            <div className="card-body">
                                <h6 className="card-title">{dataItem.Title} <span> &#8377; {dataItem.PricePerNight}</span></h6>
                                <p className="card-text">
                                    <i className="fa fa-building tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-utensils tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-car-side tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-male tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-camera tomato"></i>
                                </p>
                                <p className="card-text"><span>{dataItem.Description}</span></p>
                                <p>
                                    <span><b className="tomato">{dataItem.NoOfTours} </b>Tours</span> | &nbsp;
                                    <span><b className="tomato">{dataItem.NoOfDeparture} </b>Departures</span> | &nbsp;
                                    <span><b className="sdf-blue"> {dataItem.Include}</b></span>
                                </p>
                            </div>
                            <div className="card-footer">
                                <small className="text-muted"><strong className="tomato">{dataItem.MutedText}</strong></small>
                                <a href="#" className="btn btn-success btn-sm float-end" onClick={this.handleClick}>
                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                </a>
                            </div>
                        </div>
                    </div>)
                })
            ) : (<div></div>)


        const settings = {
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const reviewersDataList = this.props.ReviewersData;
        const reviewersDataView = reviewersDataList.map((dataItem, i) => {
            const reviewerImageURL = ApiURL.Kashmir_Tour_Package_from_Surat + "/" + dataItem.ImageName;
            return (
                <div className="testimonial-box" key={i}>
                    <div className="box-top">
                        <div className="profile">
                            <div className="profile-img">
                                <img src={reviewerImageURL} alt="..." />
                            </div>
                            <div className="name-user">
                                <strong>{dataItem.Name}</strong>
                                <span>{dataItem.Location}</span>
                            </div>
                        </div>
                        <div className="reviews">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </div>
                    </div>
                    <div className="client-comment">
                        <p>{dataItem.Comment}</p>
                    </div>
                </div>
            )
        })

        return (
            <>

                <div className="container-fluid">
                    <div className="row">
                        <img src={packageTopBannerImageURL} className="mobile-image2" alt="..." />
                    </div>
                </div>
                <div className="container-fluid custom-container py-3">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="container-fluid custom-container py-1">

                                        <h1 className="content-desktop" style={{ fontSize: "2vw" }}><center><strong>BEST PRICE FOR A KASHMIR TOUR PACKAGE FROM SURAT </strong></center></h1>

                                        <h3 className="content-mobile" style={{ fontSize: "4vw" }}><center><strong>BEST PRICE FOR A KASHMIR TOUR PACKAGE FROM SURAT </strong></center>
                                            <hr />
                                        </h3>
                                        <div className="row">
                                            <img src={packageTopBanner2ImageURL} className="mobile-image" alt="..." />
                                        </div>
                                        <hr />
                                        <div className="d-flex flex-column flex-sm-row align-items-sm-center px-2">
                                            <div className="font-lg">
                                                3 Destination:{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Srinagar (3N)
                                                </a>
                                                &nbsp;{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Gulmarg (1N)
                                                </a>{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Pahalgam (3N)
                                                </a>
                                            </div>
                                            <div className="reviews mx-5">
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                            </div>
                                        </div>
                                        <p className="p-2">
                                            If you are looking for a memorable <strong>Kashmir Package Tour From Surat</strong>, you have come to the right place!
                                        </p>
                                        <hr />
                                        <br />
                                    </div>
                                    <h2 className="content-desktop p-2" style={{ fontSize: "1.6vw" }}><b>DISCOVER AMAZING KASHMIR TOUR PACKAGES FROM SURAT</b><a href='tel:9147061467' class="tomato"> TO BOOK A KASHMIR TO SURAT TOUR PACKAGE ☎ CALL Mr. Soumya +91- 91470-61467</a></h2>
                                    <h2 className="content-mobile p-4" style={{ fontSize: "4.5vw" }}><b>DISCOVER AMAZING KASHMIR TOUR PACKAGES FROM SURAT</b><a href='tel:9147061467' class="tomato"> TO BOOK A KASHMIR TO SURAT TOUR PACKAGE ☎ CALL Mr. Soumya +91- 91470-61467</a></h2>
                                    <br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> Explore the Enchanting Beauty of Kashmir from Surat with Adorable Vacation</h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}> Explore the Enchanting Beauty of Kashmir from Surat with Adorable Vacation</h4>
                                        </div>
                                        <div className="card-body">
                                            <p>Are you ready to experience the mesmerizing beauty of Kashmir, the "Paradise on Earth"? Adorable Vacation brings to you an unforgettable <strong>Kashmir tour package from Surat</strong>, crafted to offer a perfect blend of adventure, relaxation, and cultural exploration. With this exclusive package, embark on a journey to explore the scenic landscapes, tranquil lakes, snow-capped mountains, and vibrant gardens of this magical region.</p>
                                            <p><b>Discover Srinagar: The Jewel of Kashmir </b>
                                                Your journey begins in Srinagar, the summer capital of Jammu and Kashmir, famous for its picturesque Dal Lake, Mughal Gardens, and vibrant houseboats. Enjoy a serene Shikara ride on Dal Lake, and explore the enchanting gardens like Shalimar Bagh and Nishat Bagh. Experience the local culture and cuisine by visiting the bustling markets and savoring authentic Kashmiri dishes.
                                            </p>
                                            <p><b>Pahalgam: A Glimpse of Heaven </b>
                                                Next, we take you to Pahalgam, a charming town nestled in the lush green valleys and surrounded by the majestic Himalayas. Known as the "Valley of Shepherds," Pahalgam offers breathtaking views and a range of activities such as trekking, river rafting, and horse riding. Stroll along the Lidder River, visit the ancient Mamleshwar Temple, or simply relax and soak in the natural beauty.
                                            </p>
                                            <p><b>Gulmarg: The Meadow of Flowers </b>
                                                Continue your adventure to Gulmarg, a paradise for adventure enthusiasts and nature lovers alike. Renowned for its pristine landscapes and world-class skiing facilities, Gulmarg also offers an exciting gondola ride to Apharwat Peak.Kashmir Trip Booking from Surat Witness the stunning views of the snow-covered peaks, or indulge in snow activities like skiing, snowboarding, and snowmobiling during the winter season.
                                            </p>
                                            <p><b>Sonmarg: The Golden Meadow </b>
                                                No trip to Kashmir is complete without visiting Sonmarg, a serene hill station known for its breathtaking vistas and lush meadows. Enjoy a pony ride to Thajiwas Glacier, or explore the Sindh River for trout fishing. The beauty of Sonmarg lies in its untouched and pristine surroundings, making it a perfect spot for nature walks and photography.
                                            </p>
                                            <p><b>Apart from that, we also provide </b></p>
                                            <a href='/kashmir-offbeat-package-tour-with-baisaran-valley-doodhpathri-yusmarg' target='_blank'><b className="tomato">Kashmir Package Tour with Doodhpathri Yusmarg</b></a>, <a href='/kashmir-offbeat-package-tour-with-gurez-valley' target='_blank'><b className="tomato">Kashmir Package Tour with Gurez Valley</b></a>,
                                            <a href='/kashmir-offbeat-package-tour-with-sinthan-top-aru-valley-betab-valley-chandanbari-pahalgam-sonmarg' target='_blank'><b className="tomato">Kashmir Offbeat Package Tour with Sinthan Top</b></a>,
                                            <a href='/kashmir-package-tour-with-vaishno-devi-katra-pahalgam-gulmarg-srinagar' target='_blank'><b className="tomato">Kashmir Package Tour with Vaishno Devi</b></a>,
                                            <a href='/kashmir-tulip-garden-festival-package-tour' target='_blank'><b className="tomato">Kashmir Package Tour with Tulip Package</b></a>
                                            <a href='/kashmir-package-tour-from-kolkata-with-srinagar-gulmarg-pahalgam-sonmarg' target='_blank'><b className="tomato">Kashmir Package Tour from Kolkata</b></a>
                                            <br /><br />
                                            <table className="image-table">
                                                <tbody>
                                                    <tr>
                                                        <td className="image-cell">
                                                            <img src={sightseen1ImageURL} alt="kashmir tour packages" />
                                                            <img src={sightseen2ImageURL} alt="kashmir trip from srinagar airport" />
                                                            <img src={sightseen3ImageURL} alt="kashmir package tour cost from srinagar airport" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <br />
                                        </div>
                                    </div>
                                    <br /> <br /> <br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>DURATION OF KASHMIR TOUR PACKAGE FROM SURAT : 7N | 8D</strong></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>DURATION OF KASHMIR TOUR PACKAGE FROM SURAT : 7N | 8D</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <p><strong>Itinerary for Kashmir Package Tour From Surat</strong> : Srinagar Houseboat 1N | Gulmarg 1N | Pahalgam 2N | Srinagar 3N </p>
                                            <p>Pickup <b>Kashmir Tour Packages</b> : Jammu Tawi Railway Station (JAT) / Jammu Airport (IXJ)
                                            </p>
                                            <p><b>Pickup & Drop Location:</b> Sheikh Ul Alam International Airport, Srinagar [SXR]</p>
                                            <br /><br />
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <tbody>
                                                        <tr>
                                                            <td><strong>Pickup & Drop Location</strong></td>
                                                            <td>Sheikh Ul Alam International Airport, Srinagar [SXR]</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Surat to Kashmir Package Duration</strong></td>
                                                            <td>07 Nights | 08 Days</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Surat to Kashmir Package Tour Cost </strong></td>
                                                            <td>Rs. 28,100/Based on 8 Pax</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Srinagar</strong></td>
                                                            <td>1,585 m | Coordinates - 34.0837° N, 74.7973° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Gulmarg</strong></td>
                                                            <td>2,650 m | Coordinates - 34.0484° N, 74.3805° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Sonmarg</strong></td>
                                                            <td>2,730 m | Coordinates - 34.3032° N, 75.2931° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Pahalgam</strong></td>
                                                            <td>2,740 m | Coordinates - 34.0161° N, 75.3150° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Betaab Valley</strong></td>
                                                            <td>2,393 m | Coordinates - 34.0541° N, 75.3639° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Aru Valley</strong></td>
                                                            <td>2,414 m | Coordinates - 34.0922° N, 75.2632° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Chandanwadi</strong></td>
                                                            <td>3,288 m | Coordinates - 34.2122° N, 73.9295° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Doodhpathri</strong></td>
                                                            <td>2,730 m | Coordinates - 33.8509° N, 74.5630° E</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <br /><br /><br />
                                            <table className="image-table">
                                                <tbody>
                                                    <tr>
                                                        <td className="image-cell">
                                                            <img src={sightseen4ImageURL} alt="kashmir package tour from srinagar" />
                                                            <img src={sightseen5ImageURL} alt="kashmir package price from srinagar airport" />
                                                            <img src={sightseen6ImageURL} alt="kashmir packages from srinagar airport" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <br />

                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    {/* start short itinerary */}

                                    <div className="card">
                                        <div className="card-body">
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr className="table-danger">
                                                        <th>DAY-BY-DAY SUMMARY FOR KASHMIR TOUR PACKAGES FROM SURAT</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingOne"
                                                        >
                                                            <a href="#day1" style={{ color: 'black' }}><b>DAY 1 → </b>
                                                                <span>
                                                                    ARRIVE IN SRINAGAR
                                                                </span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingTwo"
                                                        >
                                                            <a href="#day2" style={{ color: 'black' }}><b>DAY 2 → </b>
                                                                <span>TAKE A FULL-DAY TRIP TO GULMARG </span>
                                                            </a>

                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingThree"
                                                        >
                                                            <a href="#day3" style={{ color: 'black' }}><b>DAY 3 → </b>
                                                                <span>TRAVEL FROM GULMARG TO PAHALGAM AND STAY </span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingFour"
                                                        >
                                                            <a href="#day4" style={{ color: 'black' }}><b>DAY 4 → </b>
                                                                <span>EXPLORE PAHALGAM, VISITING BETAAB VALLEY AND MORE</span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingFive"
                                                        >
                                                            <a href="#day5" style={{ color: 'black' }}><b>DAY 5 → </b>
                                                                <span>TRANSFER BACK TO SRINAGAR</span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingSix"
                                                        >
                                                            <a href="#day6" style={{ color: 'black' }}><b>DAY 6 → </b>
                                                                <span>ENJOY A DAY TRIP TO DOODHPATHRI</span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingSeven"
                                                        >
                                                            <a href="#day7" style={{ color: 'black' }}><b>DAY 7 → </b>
                                                                <span>TAKE A DAY TRIP TO SONMARG </span>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingEight"
                                                        >
                                                            <a href="#day8" style={{ color: 'black' }}><b>DAY 8 → </b>
                                                                <span>DEPART FROM SRINAGAR AIRPORT </span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    {/* <!--end Short description--> */}
                                    <br /><br /><br />

                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>Discover the Ancient Temples and Rich Heritage of Kashmir with Our Surat to Kashmir Package Tours</b></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>Discover the Ancient Temples and Rich Heritage of Kashmir with Our Surat to Kashmir Package Tours</b></h4>
                                        </div>
                                        <div className="card-body">

                                            <p>Explore the rich cultural heritage and ancient temples of Kashmir with our exclusive <strong>Surat to Kashmir package tour</strong>. This tour is perfect for those who want to dive deep into the history and spirituality of the region while enjoying the stunning natural beauty that Kashmir has to offer.</p>
                                            <p><b>Shankaracharya Temple </b>
                                                Your journey begins with a visit to the Shankaracharya Temple, located on a hilltop overlooking the beautiful city of Srinagar. This ancient temple, dedicated to Lord Shiva, is believed to date back to 200 BC. As you climb the steps to reach the temple, you'll be rewarded with breathtaking views of the Dal Lake and the surrounding mountains. The temple's serene atmosphere makes it an ideal place for meditation and spiritual reflection.
                                            </p>
                                            <p><b>Kheer Bhawani Temple </b>
                                                Next, visit the Kheer Bhawani Temple, one of the most revered temples for Kashmiri Hindus. Situated in the village of Tulmulla, the temple is dedicated to the goddess Ragnya Devi. The temple's sacred spring, whose water changes color, is considered a divine sign by the devotees. Surrounded by majestic chinar trees, the temple complex provides a peaceful and calming environment for visitors.
                                            </p>
                                            <p><b>Martand Sun Temple </b>
                                                Your journey through Kashmir's rich heritage continues with a visit to the Martand Sun Temple, located near Anantnag. Built in the 8th century by King Lalitaditya, this temple is dedicated to the Sun God, Surya. Although now in ruins, the temple's grand architecture and intricate carvings provide a glimpse into Kashmir's glorious past. The panoramic views of the valley from the temple are simply breathtaking.
                                            </p>
                                            <p><b>Awantipora Ruins </b>
                                                Explore the ruins of the ancient Awantipora Temples, located in the town of Awantipora, built by King Avantivarman in the 9th century. These temples, dedicated to Lord Shiva and Lord Vishnu, showcase remarkable stone carvings and a unique architectural style. Walking through these ruins, you can almost feel the ancient history and stories that the stones hold.
                                            </p>
                                            <p><b>Mughal Gardens</b>
                                                No visit to Kashmir is complete without exploring the famous Mughal Gardens, which are a blend of Persian and Mughal styles. Visit Shalimar Bagh, Nishat Bagh, and Chashme Shahi to admire the beautiful terraced lawns, cascading fountains, and vibrant flowerbeds. These gardens reflect the rich history of the Mughal era and offer a peaceful retreat amidst nature.
                                            </p>
                                            <p><b>Hari Parbat Fort</b>
                                                End your heritage tour with a visit to the Hari Parbat Fort, which overlooks the city of Srinagar. The fort, built by Afghan governor Atta Mohammed Khan in the 18th century, offers stunning views of the city and the Dal Lake. The fort is also home to several religious shrines, making it a significant cultural and spiritual landmark in Kashmir.
                                            </p>

                                            <table className="image-table">
                                                <tbody>
                                                    <tr>
                                                        <td className="image-cell">
                                                            <img src={sightseen10ImageURL} alt="kashmir package tour booking from srinagar" />
                                                            <img src={sightseen11ImageURL} alt="kashmir package tour booking from srinagar airport" />
                                                            <img src={sightseen12ImageURL} alt="kashmir package tour cost from srinagar" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <br />
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>KASHMIR TOUR PACKAGE COST FROM SURAT</strong></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>KASHMIR TOUR PACKAGE COST FROM SURAT</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <thead className="cf">
                                                        <tr className="table-danger">
                                                            <th>TOUR DURATION</th>
                                                            <th>2 PAX</th>
                                                            <th>4 PAX</th>
                                                            <th>6 PAX</th>
                                                            <th>8 PAX</th>
                                                            <th>BOOK NOW</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td data-title="TOUR DURATION">4N/5D</td>
                                                            <td data-title="2 ADULTS">₹ 20,500/-</td>
                                                            <td data-title="4 ADULTS">₹ 18,300/-</td>
                                                            <td data-title="6 ADULTS">₹ 16,600/-</td>
                                                            <td data-title="8 ADULTS">₹ 16,400/-</td>
                                                            <td data-title="Book Now"><a href="#" class="btn btn-success" onClick={this.handleClick}>Book Now</a></td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="TOUR DURATION">5N/6D</td>
                                                            <td data-title="2 ADULTS">₹ 24,700/-</td>
                                                            <td data-title="4 ADULTS">₹ 22,000/-</td>
                                                            <td data-title="6 ADULTS">₹ 19,800/-</td>
                                                            <td data-title="8 ADULTS">₹ 20,800/-</td>
                                                            <td data-title="Book Now"><a href="#" class="btn btn-success" onClick={this.handleClick}>Book Now</a></td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="TOUR DURATION">6N/7D</td>
                                                            <td data-title="2 ADULTS">₹ 30,900/-</td>
                                                            <td data-title="4 ADULTS">₹ 27,400/-</td>
                                                            <td data-title="6 ADULTS">₹ 24,700/-</td>
                                                            <td data-title="8 ADULTS">₹ 24,500/-</td>
                                                            <td data-title="Book Now"><a href="#" class="btn btn-success" onClick={this.handleClick}>Book Now</a></td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="TOUR DURATION">7N/8D</td>
                                                            <td data-title="2 ADULTS">₹ 35,600/-</td>
                                                            <td data-title="4 ADULTS">₹ 30,800/-</td>
                                                            <td data-title="6 ADULTS">₹ 28,300/-</td>
                                                            <td data-title="8 ADULTS">₹ 28,100/-</td>
                                                            <td data-title="Book Now"><a href="#" class="btn btn-success" onClick={this.handleClick}>Book Now</a></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <br /><br />

                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>How to Best Explore Local Markets in Kashmir Packages from Surat</b></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>How to Best Explore Local Markets in Kashmir Packages from Surat</b></h4>
                                        </div>
                                        <div className="card-body">
                                            <p>Taking a Kashmir package tour from Surat lets you explore some of the most vibrant local markets in the region. Kashmir is famous for its beautiful landscapes and rich culture, and its markets offer a unique shopping experience that showcases the region's artistry and craftsmanship.</p>
                                            <p>Kashmir tour packages from Surat often include visits to well-known local markets, giving travellers a chance to experience the diverse range of handmade goods. Kashmir Package Tour Operator from Surat ensures you don't miss out on the authentic charm of these local bazaars.</p>
                                            <p>One of the must-visit spots is <strong>Lal Chowk Market</strong> in the heart of Srinagar. This busy market is a great place to find traditional Kashmiri items like Pashmina shawls, detailed carpets, and beautifully hand-carved wooden artifacts. Walking through Lal Chowk is a treat for the senses, with bright colors everywhere and the smell of spices filling the air.</p>
                                            <p>Another unique experience is the <strong>Floating Vegetable Market</strong> on Dal Lake. Here, local farmers and traders sell fresh fruits, vegetables, and traditional Kashmiri spices from their boats, called Shikaras. This market offers a colorful and lively glimpse into the daily life of the locals.</p>
                                            <p>To get the most out of your <strong>Surat to Kashmir package tour</strong>, make sure to visit the <strong>ZainaKadal Market</strong> in the Old City. This market is famous for antique silverware and traditional Kashmiri jewelry. Exploring the narrow lanes of ZainaKadal gives you a chance to see a blend of history and skilled craftsmanship.</p>
                                            <p>For a taste of local cuisine, head to <strong>Polo View Market</strong>, known for its street food. This market is perfect for food lovers who want to try delicious Kashmiri dishes and snacks.</p>
                                            <p>In short, a <strong>Kashmir package tour from Surat</strong> is more than just a journey through beautiful scenery; it's also a chance to experience the true essence of Kashmir by exploring its lively local markets. Make sure your tour includes time to visit these markets, and you'll return with not only wonderful memories but also a piece of Kashmir's rich culture.</p>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>Kashmir Temperature Guide : Plan a </b><strong>Kashmir Trip from Surat</strong></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>Kashmir Temperature Guide : Plan a </b><strong>Kashmir Trip from Surat</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <p>Before booking a <strong>Kashmir package tour from Surat</strong>, it's important to know the best time to visit and what temperatures to expect throughout the year. Here is a month-by-month temperature guide to help you prepare for your <strong>Kashmir Tours from Surat</strong>:</p>
                                            <br /><br />
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <thead className="cf">
                                                        <tr className="table-danger">
                                                            <th>Months</th>
                                                            <th>Temp. (Min - Max)</th>
                                                            <th>Season</th>
                                                            <th>Description</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td data-title="Months">March - May</td>
                                                            <td data-title="Temp. (Min - Max)">10°C - 20°C</td>
                                                            <td data-title="Season">Spring</td>
                                                            <td data-title="Description">Pleasant weather with blooming flowers. Ideal for sightseeing and outdoor activities.</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Months">June - August</td>
                                                            <td data-title="Temp. (Min - Max)">15°C - 30°C</td>
                                                            <td data-title="Season">Summer</td>
                                                            <td data-title="Description">Warm days, perfect for exploring gardens and lakes. A good time to escape the heat of the plains.</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Months">September - November</td>
                                                            <td data-title="Temp. (Min - Max)">10°C - 25°C</td>
                                                            <td data-title="Season">Autumn</td>
                                                            <td data-title="Description">Cool and comfortable with clear skies. Ideal for experiencing the autumn foliage.</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Months">December - February</td>
                                                            <td data-title="Temp. (Min - Max)">-2°C - 10°C</td>
                                                            <td data-title="Season">Winter</td>
                                                            <td data-title="Description">Cold and snowy, especially in higher altitudes. Great for snow activities like skiing and snowboarding.</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <br />
                                            <p>This temperature chart helps you decide the best time for your <strong>Kashmir trip</strong>. Whether you prefer a warm summer visit or a snowy winter adventure, knowing the seasonal temperatures will help you <strong>Kashmir Tour Package Booking from Surat</strong> and enjoy your trip to the fullest!</p>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>What Makes Kashmir a Shopper’s Delight</b></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>What Makes Kashmir a Shopper’s Delight</b></h4>
                                        </div>
                                        <div className="card-body">
                                            <p>When planning a <strong>Surat to Kashmir Trip</strong>, you might first think of its stunning landscapes and peaceful scenery. But Kashmir is also famous for its beautiful handicrafts and unique gifts. Here’s a guide to the top items to look for on your <strong>Kashmir package tour from Surat</strong>, making it easy to pick the perfect souvenirs.</p>
                                            <p>Explore Kashmir’s shopping treasures and discover why this region is a must-visit for anyone who loves unique and high-quality crafts.</p>
                                            <h3><b>What to Shop for in Kashmir</b></h3>
                                            <br />
                                            <p><b>1. Pashmina Shawls & Blankets </b>
                                                Kashmir is famous for its Pashmina shawls and blankets, which are made from fine sheep wool. These items are known for their softness, warmth, and luxurious feel. Pashmina shawls, once worn by royalty, are a must-buy to experience top-notch Kashmiri craftsmanship.
                                            </p>
                                            <p><b>2. Carpets </b>
                                                Kashmiri carpets are renowned for their rich texture and detailed designs. Handcrafted with skill, these carpets come in pure wool or silk and are known for their intricate knotting. They are a valuable addition to any home and a true example of local artistry.
                                            </p>
                                            <p><b>3. Traditional Jewellery </b>
                                                Kashmir is known for its beautiful traditional Jewellery, which includes pieces adorned with semi-precious gemstones. Look for items like Kundals, Nupura, ChaunkPhool, Tika, Balu, and Kada to add a touch of luxurious Kashmiri culture to your collection.
                                            </p>
                                            <p><b>4. Paper Mache Products </b>
                                                Paper Mache is a traditional Kashmiri art form that transforms waste paper into exquisite decorative pieces. You'll find beautifully crafted boxes, flower vases, coasters, and more, highlighting the artistic talent of Kashmiri artisans.
                                            </p>
                                            <p><b>5. Silverware Items </b>
                                                Kashmiri silverware, created through the ancient technique of 'naqash,' features stunning items such as flower vases, tea sets, picture frames, and bowls. These intricately designed pieces hold significant cultural value.
                                            </p>
                                            <p><b>6. Popular Fruits </b>
                                                Kashmir is famous for its delightful fruits, including apples, pears, pomegranates, peaches, plums, kiwis, and cherries. These fresh and flavorful fruits make for a delicious souvenir from your trip.
                                            </p>
                                            <p><b>7. Dry Fruits </b>
                                                Kashmir is renowned for its wide variety of dry fruits, including almonds, walnuts, cashews, hazelnuts, and figs. These nutritious and delicious treats are perfect for enjoying or bringing home.
                                            </p>
                                            <p>As you plan your <strong>Kashmir Packages from Surat</strong>, dive into the local markets like Maharaja Bazaar and Kokar Bazaar to find these wonderful items. Shopping for these treasures will make your Kashmir experience even more memorable.</p>
                                            <br /><br />

                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>How to Travel from Surat to kashmir Tour</b></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>What Makes Kashmir a Shopper’s Delight</b></h4>
                                        </div>
                                        <div className="card-body">
                                            <p>If you’re dreaming of traveling from Surat to the stunning landscapes of Kashmir, you have several options to make your journey. Here’s a guide to the best ways to reach <strong>Kashmir from Surat</strong>, ensuring a smooth and enjoyable trip.</p>
                                            <h3>Kashmir Tour Packages from Surat with Flight</h3><br />
                                            <p>The fastest and easiest way to get to <strong>Kashmir Tour from Surat</strong> is by flying. You can catch a direct flight from Sardar Vallabhbhai Patel International Airport (STV) in Surat to Sheikh ul-Alam International Airport (SXR) in Srinagar. This option is convenient, saves time, and ensures a comfortable trip.</p>
                                            <h3>Popular Airfare Options for Kashmir Package Tour from Surat</h3><br />
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <thead className="cf">
                                                        <tr className="table-danger">
                                                            <th>Flight Operator</th>
                                                            <th>Dep.</th>
                                                            <th>Arr</th>
                                                            <th>Stopover</th>
                                                            <th>Travel Time</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td data-title="Operator">IndiGo</td>
                                                            <td data-title="Dep">08:30</td>
                                                            <td data-title="Arr">14:20</td>
                                                            <td data-title="Stop Over">Delhi</td>
                                                            <td data-title="Travel Time">5 hrs. 50 min.</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Operator">IndiGo</td>
                                                            <td data-title="Dep">09:25</td>
                                                            <td data-title="Arr">17:30</td>
                                                            <td data-title="Stop Over">Delhi</td>
                                                            <td data-title="Travel Time">8 hrs. 5 min.</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <br />
                                            <h3><b>Kashmir Trip from Surat by Train</b></h3><br />
                                            <p>If you enjoy a scenic train ride, you can choose the rail route for your trip. You can catch a train from Surat Railway Station to Jammu Tawi, which is the closest railway station to Kashmir. From Jammu, you can take different types of transport to reach Srinagar, the main city in the Kashmir Valley. Although the train journey takes longer than flying, it provides a special experience with beautiful views along the way.</p>
                                            <h4><b>Here's a simple chart showing train details from Surat to Jammu Tawi:</b></h4><br />
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <thead className="cf">
                                                        <tr className="table-danger">
                                                            <th>Train No</th>
                                                            <th>Train Name</th>
                                                            <th>Dep Stn.</th>
                                                            <th>Dep Time.</th>
                                                            <th>Arr Stn.</th>
                                                            <th>Arr Time</th>
                                                            <th>Travel Time</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td data-title="Train No">12471</td>
                                                            <td data-title="Train Name">Swaraj Express</td>
                                                            <td data-title="Dep Stn">Surat (ST)</td>
                                                            <td data-title="Dep Time">14:24</td>
                                                            <td data-title="Arr Stn">Jammu Tawi (JAT)</td>
                                                            <td data-title="Arr Time">14:55</td>
                                                            <td data-title="Travel Time">24hr 31min</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Train No">19027</td>
                                                            <td data-title="Train Name">Vivek Express</td>
                                                            <td data-title="Dep Stn">Surat (ST)</td>
                                                            <td data-title="Dep Time">15:16</td>
                                                            <td data-title="Arr Stn">Jammu Tawi (JAT)</td>
                                                            <td data-title="Arr Time">23:05</td>
                                                            <td data-title="Travel Time">31hr 49min</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <br />
                                            <h3>Kashmir Package from Surat by Road</h3><br />
                                            <p>If you love road trips, driving to <strong>Kashmir Trip from Surat</strong> is an exciting option. Although it takes longer than flying, the journey lets you see a variety of landscapes across India. Make sure to plan your route well and schedule comfortable stops along the way to rest and refresh.</p><br />
                                            <h4><b>Journey Details</b></h4><br />
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <thead className="cf">
                                                        <tr className="table-danger">
                                                            <th>From</th>
                                                            <th>To</th>
                                                            <th>Route</th>
                                                            <th>Distance</th>
                                                            <th>Time</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td data-title="From">Surat</td>
                                                            <td data-title="To">Ahmedabad</td>
                                                            <td data-title="Route">Via NH 48 and NE1</td>
                                                            <td data-title="Distance">267.2 km</td>
                                                            <td data-title="Time">5 hr 11 min</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="From">Ahmedabad</td>
                                                            <td data-title="To">Udaipur</td>
                                                            <td data-title="Route">Via NH 48</td>
                                                            <td data-title="Distance">257.8 km</td>
                                                            <td data-title="Time">4 hr 37 min</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="From">Udaipur</td>
                                                            <td data-title="To">Jaipur</td>
                                                            <td data-title="Route">Via NH 48</td>
                                                            <td data-title="Distance">396.2 km</td>
                                                            <td data-title="Time">6 hr 54 min</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="From">Jaipur</td>
                                                            <td data-title="To">Delhi</td>
                                                            <td data-title="Route">Via NE 4</td>
                                                            <td data-title="Distance">308.2 km</td>
                                                            <td data-title="Time">5 hr 18 min</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="From">Delhi</td>
                                                            <td data-title="To">Jammu</td>
                                                            <td data-title="Route">Via NH 44</td>
                                                            <td data-title="Distance">586.2 km</td>
                                                            <td data-title="Time">10 hr 38 min</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="From">Jammu</td>
                                                            <td data-title="To">Srinagar</td>
                                                            <td data-title="Route">Via NH 244A and NH 44</td>
                                                            <td data-title="Distance">244.5 km</td>
                                                            <td data-title="Time">5 hr 47 min</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <br /><br />
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div className="card-header custHeaer">
                                            <h4 className='content-desktop' style={{ fontSize: "1.5vw" }}>Must-Try Kashmiri Dishes on Your <strong>Kashmir Package from Surat</strong></h4>
                                            <h4 className='content-mobile2' style={{ fontSize: "3.5vw" }}>Must-Try Kashmiri Dishes on Your <strong>Kashmir Package from Surat</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <p>Kashmir, located in the stunning Himalayas, is not only known for its beautiful scenery but also for its delicious food. During your <strong>Kashmir tour from Surat</strong>, make sure to try these flavorful and aromatic dishes that will delight your taste buds.</p>
                                            <p><b>1. Rogan Josh: </b>Try Rogan Josh, a signature Kashmiri dish made from tender lamb slow-cooked with spices, ginger, and garlic. This flavorful dish, influenced by Mughal cuisine, is best enjoyed with naan or roti.</p>
                                            <p><b>2. Dum Aloo: </b>Dum Aloo features soft potatoes cooked in a yogurt-based gravy with ginger, fennel, and spices. This popular dish, often made with baby potatoes, pairs well with rice or rotis.</p>
                                            <p><b>3. Goshtaba: </b>Goshtaba is a special dish with minced mutton balls cooked in a yogurt and spice gravy. The tender meatballs almost melt in your mouth, making it a perfect treat for special occasions.</p>

                                            <p><b>4. Yakhni: </b>Yakhni is a savory dish made with mutton, yogurt, and a blend of spices like cardamom, cloves, and cinnamon. The saffron adds a special touch, and it's best enjoyed with pulao for a comforting meal.</p>

                                            <p><b>5. Modur Pulao: </b>Modur Pulao is a sweet rice dish made with ghee, mild spices, and a mix of dry fruits and nuts. It offers a delightful contrast when paired with the spicy Dum Aloo.</p>

                                            <p><b>6. Matschgand: </b>For a spicy kick, try Matschgand, featuring minced meatballs in a hot red chili gravy. It's especially delicious when paired with the sweet Modur Pulao.</p>

                                            <p><b>7. Kashmiri MujhGaad: </b>MujhGaad combines fish with radish and lotus stem in a spiced, flavorful dish. It’s a unique experience that pairs well with rice.</p>

                                            <p><b>8. AabGosht: </b>AabGosht is a regional specialty where tender mutton is cooked in a milk-based gravy with spices. The dish offers a delightful blend of sweetness from cardamom and spicy notes.</p>

                                            <p><b>9. Tabak Maaz: </b>Tabak Maaz is a tasty appetizer or side dish featuring lamb ribs marinated in spices and yogurt, then deep-fried. They’re crispy on the outside and tender on the inside.</p>

                                            <p><b>10. Lyodur Tschaman: </b>Lyodur Tschaman is a creamy paneer dish made with turmeric and Kashmiri spices like fennel and black pepper. It’s a great choice with rice, rotis, or naan.</p>
                                            <p>As you plan your <strong>Surat to Kashmir Tour Package Booking</strong>, get ready to enjoy these delicious local dishes and experience the rich flavours of Kashmiri cuisine.</p>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div className="card-header custHeaer">
                                            <h4 className='content-desktop' style={{ fontSize: "1.5vw" }}><b>Top Muslim Shrines and Mosques to Visit on Your</b> <strong>Kashmir Trip from Surat</strong></h4>
                                            <h4 className='content-mobile2' style={{ fontSize: "3.5vw" }}><b>Top Muslim Shrines and Mosques to Visit on Your</b><strong>
                                                Kashmir Trip from Surat</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <p>Discover the rich Islamic heritage of Kashmir by visiting its beautiful mosques, where people gather for prayer and spiritual connection. Kashmir is known for its inclusive philosophy, called Kashmiriyat, which promotes harmony among different religions. This spirit of unity is reflected in the peaceful coexistence of Islamic mosques, Dargahs, and Hindu temples. On your <strong>Surat to Kashmir tour</strong>, take time to experience the peaceful atmosphere of these important mosques.</p>
                                            <p><b>Hazratbal Mosque:</b> This stunning white marble mosque is situated near Dal Lake, offering a serene reflection in the lake's calm waters. It houses the revered relic Moi-e-Muqqadas, believed to be a hair of Prophet Muhammad (pbuh). As the only mosque in Srinagar with a dome, it attracts many visitors, especially during religious festivals.</p>

                                            <p><b>Jamia Masjid:</b> Located in the bustling Nowhatta area, this 600-year-old marvel is influenced by Persian architecture. It features 378 wooden pillars and impressive design. Despite suffering damage from fires, it remains a peaceful sanctuary for Friday prayers.</p>

                                            <p><b>Shah-i-Hamdan Mosque:</b> Built in 1359 and restored in 1732, this mosque is one of the oldest by the River Jhelum. Constructed entirely without nails, it showcases breathtaking woodwork and intricate designs. It honors Mir Syed Ali Hamdani and holds significant historical importance.</p>

                                            <p><b>Pathar Masjid:</b> This mosque, located in Nowhatta, is known for its unique sloping roof and Mughal architecture. The roof is supported by eighteen large columns, and its structure features distinct upper and lower sections. Twenty-seven domes with internal ribs contribute to its distinctive look in Kashmir.</p>

                                            <p><b>Aali Mosque:</b> Built in 1471 A.D., Aali Mosque is the second-largest mosque in Kashmir after Jamia Masjid. Covering an area of over 14,000 square feet, its ceiling is supported by 156 deodar columns. It has stood through significant periods in Kashmir’s history.</p>

                                            <p><b>Makhdoom Sahib Mosque:</b> Located at the base of Hari Parbat, this shrine offers captivating views of the Mughal fort. Built in honor of Hazrat Sultan, a Sufi Saint, its striking two-story structure is open year-round and showcases beautiful Mughal architecture.</p>

                                            <p><b>Charar-E-Sharief Shrine and Mosque:</b> Situated 40 kilometers from Srinagar, this shrine honors Sufi Saint Sheikh Noor-ud-din Noorani. The mausoleum, known as Alamdar-e-Kashmir, reflects the historical transition from Hinduism to Islam in Kashmir and provides a deeply spiritual experience.</p>

                                            <p><b>Ashmuqam Shrine/Mosque:</b> This hilltop shrine, located 20 kilometers from Pahalgam, holds historical relics of Sheikh Zain-ud-din. It is connected to two mosques, known as Khankahs, that preserve the saint’s relics. This shrine is a revered place of worship, drawing thousands of devotees annually.</p>

                                            <p><b>Naqshband Shrine/Mosque:</b> Also known as the Khanqah of Khwaja Moinuddin Naqshbandi, this mosque is situated in the heart of Srinagar. It holds significant religious importance and once housed a sacred hair of Prophet Muhammad (pbuh). The mosque is renowned for its intricate khatamband ceilings, showcasing traditional timber craftsmanship.</p>

                                            <p><b>Masjid of Akhund Mullah/Akhoon Mulla:</b> This simple yet spiritual mosque, located beneath the Makhdoom Sahib Shrine, was built by Dara Shikoh in memory of Akhun Mulla Shah. Its centerpiece is a unique stone lotus with a carving from 1649. The mosque, made from shiny grey limestone, reflects its profound spiritual essence.</p>
                                            <p>Book your <strong>Kashmir package tour from Surat</strong> to see the beautiful and sacred landmarks of the region. Experience the rich culture of <strong>Surat to Kashmir Trip</strong>.</p>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div className="card-header custHeaer">
                                            <h4 className='content-desktop' style={{ fontSize: "1.5vw" }}>MUST DO ACTIVITIES DURING <strong>KASHMIR TOUR PACKAGES FROM SURAT</strong></h4>
                                            <h4 className='content-mobile2' style={{ fontSize: "3.5vw" }}>MUST DO ACTIVITIES DURING <strong>KASHMIR TOUR PACKAGES FROM SURAT</strong></h4>

                                        </div>
                                        <div className="card-body">
                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>Shikara Rides:</b><b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Shikara Rides:</b> Experience the tranquility of Dal Lake with a leisurely Shikara ride During your <strong> Kashmir Tulip Festival Package Tour</strong>. Glide across the serene waters, passing by floating gardens, houseboats, and vibrant markets. Sunset and early morning rides offer particularly magical views.</p>
                                            <center> <img src={Topbanner0ImageURL} className="img-fluid" alt="..." /></center><br /><br /><br />

                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>Skiing and Snowboarding:</b>
                                                <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Skiing and Snowboarding:</b> During the winter months, places like Gulmarg transform into ski resorts, attracting enthusiasts from around the world. Gulmarg offers excellent slopes for skiing, snowboarding, and heli-skiing, with breathtaking views of the surrounding Himalayan peaks.</p>
                                            <center> <img src={Topbanner1ImageURL} className="img-fluid" alt="..." /></center><br /><br /><br />

                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>Gondola Ride: </b>
                                                <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Gondola Ride: </b>
                                                Take a ride on the Gulmarg Gondola, one of the highest cable cars in the world. Enjoy panoramic views of the snow-capped peaks as you ascend to Kongdori or Apharwat Peak, where you can indulge in activities like hiking and mountain biking.Experience this Awesome Ride During your <strong>Kashmir Tulip Garden Tour</strong> with Adorable Vacation and make this as a life long memories.</p>
                                            <center> <img src={Topbanner2ImageURL} className="img-fluid" alt="..." /></center><br /><br /><br />

                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>Trekking:</b>
                                                <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Trekking:</b> Kashmir is a paradise for trekkers, with numerous trails that cater to all levels of expertise. Popular trekking destinations include the Kashmir Great Lakes Trek, Tarsar Marsar Trek, and the trek to Thajiwas Glacier in Sonamarg.</p>
                                            <center> <img src={Topbanner3ImageURL} className="img-fluid" alt="..." /></center><br /><br /><br />
                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>River Rafting:</b>
                                                <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>River Rafting:</b> The Lidder River in Pahalgam offers exciting opportunities for white-water rafting. Experience the thrill of navigating through rapids while enjoying the scenic beauty of the surrounding pine forests and mountains.</p>
                                            <center> <img src={Topbanner4ImageURL} className="img-fluid" alt="..." /></center>
                                            <br /><br /><br />
                                            <a href="tel://9147061467" className="btn btn-Warning" style={{ padding: '15px 30px', fontSize: '20px' }}>CALL NOW</a>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="container-fluid navtabCustom">
                                        <nav>
                                            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                                <button onClick={() => this.handleCustomTabClick('tab1')} className={`tab button ${customActiveTab === 'tab1' ? 'active' : ''}`} role="tab" aria-controls="nav-highlights" aria-selected={customActiveTab === 'tab1'}>Highlights</button>
                                                <button onClick={() => this.handleCustomTabClick('tab2')} className={`tab button ${customActiveTab === 'tab2' ? 'active' : ''}`} role="tab" aria-controls="nav-flights" aria-selected={customActiveTab === 'tab2'}>Flights</button>
                                                <button onClick={() => this.handleCustomTabClick('tab3')} className={`tab button ${customActiveTab === 'tab3' ? 'active' : ''}`} role="tab" aria-controls="nav-hotel" aria-selected={customActiveTab === 'tab3'}>Hotel</button>
                                                <button onClick={() => this.handleCustomTabClick('tab4')} className={`tab button ${customActiveTab === 'tab4' ? 'active' : ''}`} role="tab" aria-controls="nav-sightseeing" aria-selected={customActiveTab === 'tab4'}>Sightseeing</button>
                                                <button onClick={() => this.handleCustomTabClick('tab5')} className={`tab button ${customActiveTab === 'tab5' ? 'active' : ''}`} role="tab" aria-controls="nav-meals" aria-selected={customActiveTab === 'tab5'}>Meals</button>
                                            </div>
                                        </nav>
                                        <div className="tab-content" id="nav-tabContent" >
                                            <div className={customActiveTab === 'tab1' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-highlights-tab">
                                                <p>The <strong>Kashmir Package Tour</strong> is famous for its stunning landscapes, including well-known spots like Dal Lake, Nigeen Lake, Gulmarg, Pahalgam, and Sonamarg. These places draw tourists from all over the world who come to enjoy the beautiful scenery and take part in outdoor activities.</p>
                                                <p>Planning a <strong>Kashmir Tour Pacakages From Surat</strong> is a great choice for travelers looking for beautiful scenery, cultural experiences, and adventure in a unique and stunning location.</p>
                                                <p>Kashmir is located in the Himalayan Mountains and is surrounded by India, Pakistan, and China. It has three main regions: Jammu, the Kashmir Valley, and Ladakh. The area is known for its snowy mountains, green valleys, winding rivers, and peaceful lakes.</p>
                                                <p>The <strong>Kashmir Package Tour From Surat</strong> are famous for their stunning landscapes, including places like Dal Lake, Nigeen Lake, Gulmarg, Pahalgam, and Sonamarg. Tourists from around the world visit to enjoy the beautiful scenery and take part in outdoor activities. Kashmir has always been a popular destination, known for its natural beauty, adventure sports, religious sites, and historical landmarks. Visitors can enjoy activities like trekking, skiing, staying in houseboats, taking shikara rides, and exploring Mughal gardens and ancient temples.</p>
                                            </div>
                                            <div className={customActiveTab === 'tab2' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-flights-tab">
                                                <div className="overflow-table">
                                                    <span>POPULAR FLIGHTS FOR THE KASHMIR TULIP GARDEN TRIP 2025<b> <i>(SXR)</i></b></span><br /><br />
                                                    <table className="table table-bordered">
                                                        <tr className="table-danger">
                                                            <th>Flight Operator</th>
                                                            <th>Departure From</th>
                                                            <th>Departure Time</th>
                                                            <th>Arrival at Srinagar</th>
                                                            <th>Stopover</th>
                                                            <th>Travel Time</th>
                                                        </tr>
                                                        <tr>
                                                            <td>IndiGo</td>
                                                            <td>Kolkata [CCU]</td>
                                                            <td>08:15</td>
                                                            <td>15:15</td>
                                                            <td>New Delhi [DEL]</td>
                                                            <td>7h 00m</td>
                                                        </tr>
                                                        <tr>
                                                            <td>IndiGo</td>
                                                            <td>Mumbai [BOM]</td>
                                                            <td>09:30</td>
                                                            <td>15:15</td>
                                                            <td>New Delhi [DEL]</td>
                                                            <td>5h 45m</td>
                                                        </tr>
                                                        <tr>
                                                            <td>SpiceJet</td>
                                                            <td>New Delhi [DEL]</td>
                                                            <td>08:00</td>
                                                            <td>09:25</td>
                                                            <td>(None)</td>
                                                            <td>1h 25m</td>
                                                        </tr>
                                                        <tr>
                                                            <td>IndiGo</td>
                                                            <td>Bangalore [BLR]</td>
                                                            <td>09:30</td>
                                                            <td>14:10</td>
                                                            <td>Sri Guru Ram Dass Jee [ATQ]</td>
                                                            <td>4h 40m</td>
                                                        </tr>
                                                        <tr>
                                                            <td>IndiGo</td>
                                                            <td>Goa [GOX]</td>
                                                            <td>10:30</td>
                                                            <td>16:45</td>
                                                            <td>New Delhi [DEL]</td>
                                                            <td>6h 15m</td>
                                                        </tr>
                                                        <tr>
                                                            <td>IndiGo</td>
                                                            <td>Hyderabad [HYD]</td>
                                                            <td>08:55</td>
                                                            <td>15:15</td>
                                                            <td>New Delhi [DEL]</td>
                                                            <td>6h 20m</td>
                                                        </tr>
                                                        <tr>
                                                            <td>IndiGo</td>
                                                            <td>Chennai [MAA]</td>
                                                            <td>08:30</td>
                                                            <td>14:20</td>
                                                            <td>New Delhi [DEL]</td>
                                                            <td>5h 50m</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className={customActiveTab === 'tab3' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-hotel-tab">

                                                <div className="overflow-table">
                                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }} ><center><b>4 STAR - CERTIFIED BY 'ADORABLE' </b></center></h4>
                                                    <div id="no-more-tables">
                                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                            <thead class="cf">
                                                                <tr className="text-center">
                                                                    {/* <!-- Add the "text-center" className to center content --> */}
                                                                    <th colspan="6" style={{ fontSize: "1.3vw" }}><h6><b>3 STAR CATEGORY HOTEL CERTIFIED BY 'ADORABLE VACATION' </b></h6></th>
                                                                </tr>
                                                                <tr className="table-danger">
                                                                    <th className="text-center">Destination</th>
                                                                    <th className="text-center">Hotel Names</th>
                                                                    <th className="text-center">Hotel Type</th>
                                                                    <th className="text-center">No of Nights</th>
                                                                    <th className="text-center">Meal Plan</th>
                                                                </tr>
                                                            </thead>
                                                            <tr>
                                                                <td data-title="Destination"><div style={{ display: 'inline-block' }}>Srinagar</div></td>
                                                                <td data-title="Hotel Names"><div style={{ display: 'inline-block' }}>Hotel Grand Fortune / Hotel Shefaf / Hotel Siddique Palace / Similar</div></td>
                                                                <td data-title="Hotel Type">3 Star
                                                                </td>
                                                                <td data-title="No of Nights"><div style={{ display: 'inline-block' }}>5N</div></td>
                                                                <td data-title="Meal Plan"><div style={{ display: 'inline-block' }}>Room with Breakfast & Dinner</div></td>
                                                            </tr>
                                                            <tr>
                                                                <td data-title="Destination"><div style={{ display: 'inline-block' }}>Gulmarg</div></td>
                                                                <td data-title="Hotel Names"><div style={{ display: 'inline-block' }}>Hotel Affarwat / Hotel Mama Palace /Gulmarg House / Similar
                                                                </div>
                                                                </td>
                                                                <td data-title="Hotel Type">3 Star
                                                                </td>
                                                                <td data-title="No of Nights"><div style={{ display: 'inline-block' }}>1N</div></td>
                                                                <td data-title="Meal Plan"><div style={{ display: 'inline-block' }}>Room with Breakfast & Dinner</div></td>
                                                            </tr>
                                                            <tr>
                                                                <td data-title="Destination"><div style={{ display: 'inline-block' }}>Pahalgam</div></td>
                                                                <td data-title="Hotel Names"><div style={{ display: 'inline-block' }}>Hotel Pine Palace Resort / Hotel Fifth Season / Hotel Moonview Resort /Similar
                                                                </div>
                                                                </td>
                                                                <td data-title="Hotel Type">3 Star
                                                                </td>
                                                                <td data-title="No of Nights"><div style={{ display: 'inline-block' }}>2N</div></td>
                                                                <td data-title="Meal Plan"><div style={{ display: 'inline-block' }}>Room with Breakfast & Dinner</div></td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={customActiveTab === 'tab4' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-sightseeing-tab">
                                                <div className="container-fluid px-0 py-1">
                                                    <h5>TOP ATTRACTIONS TO EXPLORE DURING THE <strong>KASHMIR PACKAGE TOUR FROM Surat</strong></h5><br />
                                                    <p>During the Kashmir Tulip Holiday Package from Adorable Vacation, the region offers a wealth of captivating attractions that complement the vibrant display of tulips. Here are the must-see highlights:</p><br />
                                                    <p><b className='tomato'>1.	Indira Gandhi Memorial Tulip Garden:</b> Dive into the festival's heart, where sprawling tulip beds create a riot of colours against the backdrop of the majestic Himalayas.</p>
                                                    <p><b className='tomato'>2. Srinagar's Dal Lake:</b> Take a serene shikara ride on this iconic lake, known for its houseboats and picturesque views, offering a tranquil escape from the festival's bustle.</p>
                                                    <p><b className='tomato'>3. Mughal Gardens:</b> Explore the timeless beauty of Shalimar Bagh, Nishat Bagh, and Chashme Shahi, where Mughal architecture blends with lush landscapes.</p>
                                                    <p><b className='tomato'>4. Gulmarg:</b> Discover the lush meadows and pristine surroundings of Gulmarg, perfect for a day trip with opportunities for cable car rides and panoramic views.</p>
                                                    <p><b className='tomato'>5. Pahalgam:</b> Experience the natural splendor of Pahalgam, from its scenic meadows to the picturesque Betab Valley and Aru Valley.</p>
                                                    <p><b className='tomato'>6. Sonmarg:</b> Venture to Sonmarg's breathtaking landscapes, where stunning views and serene environments make for a memorable excursion.</p>
                                                    <p><b className='tomato'>7. Doodhpathri:</b> Explore this lesser-known gem, renowned for its lush green meadows and gentle streams, offering a peaceful retreat.</p>
                                                    <p><b className='tomato'>8. Yusmarg:</b> Visit this idyllic meadow surrounded by pine forests, providing a serene spot for relaxation and picnicking.</p>
                                                    <p><b className='tomato'>9. Srinagar's Local Markets:</b> Immerse yourself in the vibrant local culture by visiting bustling markets like Lal Chowk and Polo View Market, where you can shop for traditional Kashmiri crafts and souvenirs.</p>
                                                    <p><b className='tomato'>10. Vaishno Devi Temple:</b> Take a pilgrimage to this revered shrine, nestled in the Trikuta Mountains, offering spiritual solace and panoramic views.</p>
                                                    <p><b className='tomato'>11. Shankaracharya Temple:</b> Visit this ancient temple perched on a hilltop for a blend of spiritual significance and stunning panoramic views of Srinagar.</p>
                                                    <p>The <strong>Tulip Garden Tour Package</strong> promises not only a visual feast of flowers but also a rich array of cultural and natural wonders to explore.</p>
                                                </div>
                                            </div>
                                            <div className={customActiveTab === 'tab5' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-meals-tab">
                                                <div className="overflow-table">
                                                    <h5 class="tomato">7 times Meals and 7 times Breakfast has been Provided</h5>
                                                    <table className="table table-bordered">
                                                        <tr className="table-danger">
                                                            <th colspan="6" className="text-center">Category</th>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-center">JAIN</td>
                                                            <td class="text-center">PURE-VEG</td>
                                                            <td class="text-center">VEGAN</td>
                                                            <td class="text-center">PURE-NON-VEG</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                           
                            <br /><br /><br />
                            {/* <!--By Itinerary--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>
                                                DAY WISE <strong><span>KASHMIR TOUR ITINERARY FROM SURAT</span></strong>
                                            </h2>
                                            <h4>7 Nights 8 Days <strong>Kashmir Tour Itinerary</strong></h4>
                                        </div>
                                    </div>
                                </div>


                                {/* <!--end Short description--> */}
                                <br />
                                <div className="row">
                                    <div
                                        className="accordion"
                                        id="accordionPanelsStayOpenExample"
                                    >
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day1"
                                            >
                                                <button
                                                    className="accordion-button"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseOne"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseOne"
                                                >
                                                    DAY 1  – ARRIVAL IN SRINAGAR
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseOne"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingOne"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        Welcome to your <strong>Kashmir package Tour From Surat</strong>! Upon arrival at Srinagar Sheikh Ul Alam International Airport, you will be greeted by our representative. After checking into your hotel, enjoy a welcome drink. In the evening, embark on a Shikara ride on the famous Dal Lake. Explore the floating vegetable gardens and the lake’s serene waters. As you float along, you may spot various bird species such as Kingfishers and Common Pariah Kites. Overnight stay at a houseboat.
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay1ImageURL} alt="Kashmir Offbeat Packages for Family" /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay1ImageURL} style={{ width: '320px' }} alt="Kashmir Offbeat Packages for Family" /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day2"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseTwo"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseTwo"
                                                >
                                                    DAY 02: SRINAGAR TO GULMARG
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseTwo"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingTwo"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        After a leisurely breakfast, check out from the houseboat and drive to Gulmarg, also known as the "Meadow of Flowers," which is about 51 kilometres away. Gulmarg sits at an altitude of 8,700 feet and is renowned for its breath-taking scenery and its status as a premier ski resort. Upon arrival, check into your hotel. Enjoy the famous Gondola Ride, one of the highest cable cars in the world. The ride takes you up to 3,979 meters, offering stunning views. You can also explore off-piste areas and enjoy skiing. Return to your hotel in the evening for an overnight stay.
                                                    </p>

                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay2ImageURL} alt="Kashmir Tour Package cost offbeat" /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay2ImageURL} style={{ width: '320px' }} alt="Kashmir Tour Package cost offbeat" /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day3"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseThree"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 03: GULMARG TO PAHALGAM
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseThree"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingThree"
                                            >
                                                <div className="accordion-body">
                                                    <p>After breakfast, check out from the hotel and travel to Pahalgam, a 148-kilometer journey that takes around 4 to 5 hours. Known as the "Valley of Shepherds," Pahalgam is situated where the streams from Sheshnag Lake meet the Lidder River. Once a quiet shepherd’s village, it now offers breath-taking views and is a base camp for the Amarnath Pilgrimage. After checking into your hotel, spend the rest of the day relaxing or exploring the area.</p>
                                                    <br />

                                                    <div className="content-desktop">
                                                        <center><img src={navDay3ImageURL} alt="Kashmir Tour Packages" /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay3ImageURL} style={{ width: '320px' }} alt="Kashmir Tour Packages" /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day4"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseFour"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    Day 04: - PAHALGAM LOCAL SIGHTSEEING
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseFour"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingFour"
                                            >
                                                <div className="accordion-body">
                                                    <p>Enjoy a full day to explore Pahalgam. You can visit Chandanwari, the starting point of the Amarnath Yatra, or Aru Valley, which is known for its picturesque meadows and trekking routes. Alternatively, visit Betaab Valley, famous for its lush green meadows and clear streams, or Baisaran Valley, which resembles European resorts with its dense pine forests and scenic views. Return to your hotel in the evening.</p>

                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay4ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay4ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day5"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseFive"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 05: PAHALGAM TO SRINAGAR
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseFive"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingFive"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        After an early breakfast, check out from your hotel and drive back to Srinagar. Visit the Hazratbal Shrine and the Shankaracharya Temple. In the afternoon, explore the Mughal Gardens: Nishat Garden, Shalimar Garden, and Chashmashahi. These gardens, built by Mughal emperors, are known for their beauty and historical significance. Return to your Srinagar hotel in the evening for an overnight stay.
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay5ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay5ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day6"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseSix"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 06: SRINAGAR TO DOODHPATHRI AND BACK
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseSix"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingSix"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        After breakfast, take a day trip to Doodhpathri, located 42 kilometers from Srinagar. Known as the "Valley of Milk," Doodhpathri is famous for its lush meadows and cold, milky streams. The area is covered with wildflowers in spring and summer. Return to Srinagar in the evening for an overnight stay.
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay6ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay6ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day7"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseSeven"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 07: SRINAGAR TO SONMARG AND BACK
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseSeven"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingSeven"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        After breakfast, check out from your hotel and head to Sonmarg, meaning "Meadow of Gold." The 87-kilometer drive offers stunning views of forests and mountains. Sonmarg is a gateway to trekking routes and glaciers. You can hire ponies or vehicles to visit Thajiwas Glacier, a major attraction. Return to Srinagar in the evening for your last overnight stay.
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay7ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay7ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day8"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseTen"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 08:DEPARTURE FROM SRINAGAR
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseTen"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingTen"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        Your <strong>Kashmir Tour From Surat</strong> concludes today. After breakfast, you will be transferred to the Domestic Airport for your flight to your next destination. Thank you for traveling with us, and we hope you had a memorable experience.
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay8ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay8ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <br />
                            {/* <!--insert table inclusion exclusion--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        {
                                            <InclusionExclusion />
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* <!--end table inclusion exclusion--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center" >
                                            <h2>The Khyber Himalayan Resort & Spa <span>- Gulmarg</span></h2>
                                            <h4><i>Next Level Luxury Resort </i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row py-3">
                                    <img src={brandedTourackageImage_1_URL} alt="..." />
                                </div>

                                <div id="no-more-tables" >
                                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                        <thead>
                                            <tr className="table-danger">
                                                <th>Category</th>
                                                <th>Size(Room)</th>
                                                <th>Hotel Brand</th>
                                                <th>Price (Per Night)</th>
                                                <th>Book Now</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td data-title="Packages">Platinum Accommodation</td>
                                                <td data-title="Size(Room)">386+ SQ.FT</td>
                                                <td data-title="Hotel Brand">Khyber Hotels (Heritage)<div className="reviews">
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                </div></td>
                                                <td data-title="Price (Per Night)">Rs 53,119/- </td>
                                                <td data-title="Book Now"><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick}>
                                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                                </a></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2 >The LaLiT Grand Place <span>- Srinagar</span></h2>
                                            <h4 ><i>Fill the Luxury</i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row py-3">
                                    <img src={brandedTourackageImage_2_URL} alt="..." />
                                </div>

                                <div id="no-more-tables" >
                                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                        <thead>
                                            <tr className="table-danger">
                                                <th>Category</th>
                                                <th>Size(Room)</th>
                                                <th>Hotel Brand</th>
                                                <th>Price (Per Night)</th>
                                                <th>Book Now</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td data-title="Packages">Premium Accommodation</td>
                                                <td data-title="Size(Room)">290+ SQ.FT</td>
                                                <td data-title="Hotel Brand">The LaLiT Hotels<div className="reviews">
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                </div></td>
                                                <td data-title="Price (Per Night)">Rs 28,332/- </td>
                                                <td data-title="Book Now"><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick}>
                                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                                </a></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                <div className="card mx-0 my-5">
                                    <div className="card-header custHeaer">
                                        <h3 className='content-desktop' style={{ fontSize: "1.5vw" }}><b>EMBARKED ON A SENSE OF PRIDE AT PREMIUMNESS</b></h3>
                                        <h3 className='content-mobile2' style={{ fontSize: "3.5vw" }}><b>EMBARKED ON A SENSE OF PRIDE AT PREMIUMNESS</b></h3>
                                    </div>
                                    <div className="card-body">
                                        <p ><b>The LaLit Grand Palace</b> was originally built in 1901 for the royal family. It now provides luxurious rooms with views of Dal Lake and the beautiful gardens. An indoor pool, tennis courts and pampering spa treatments are also available.The tastefully designed rooms are modern with a touch of traditional charm. All have a satellite TV, minibar and free tea/coffee making facilities. It is 18 km from Srinagar Airport and 4 km from Lal Chowk city centre. Jammu Railway Station is 300 km away. Guests can enjoy a relaxing massage at Rejuve Spa Health Club, practice yoga, or play golf. The hotel also has a gym and 24-hour business center. The Chinar Garden serves North Indian cuisine, while international buffet spreads are available at The Chinar – The All Day Coffee Shop. Other dining options include Dal Bar and 24-hour room service.</p>

                                        <a href="tel://9883359713" className="btn btn-Warning">Book Now</a>

                                    </div>
                                </div>
                            </div>

                            <br /><br />
                            {/* <!--start of package details--> */}
                            <div className="container-fluid py-5">

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>Kashmir<span> Package Tour From Surat </span></h2>
                                            <h4>Related Kashmir Tour plan</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                                        <div className="carousel-inner">
                                            <div className="carousel-item active">

                                                <Slider {...settings}>
                                                    {hotPackagesView}
                                                </Slider>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!--end of package details--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2 >Frequently Ask <span>Questions</span></h2>
                                            <h4 >Kashmir Package Tour From Surat Related Questions</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="row" >
                                    <div className="accordion" id="accordionPanelsStayOpenExample">
                                        <div className="accordion" id="accordionFlushExample">
                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingEight">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
                                                        Are Houseboats a Popular Place to Stay in Kashmir?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseEight" className="accordion-collapse collapse show" aria-labelledby="flush-headingEight" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p>Yes, houseboats are a very popular place to stay in Kashmir, offering a unique and memorable experience. These floating homes on Dal Lake and other water bodies provide a true taste of Kashmiri culture. Staying in a houseboat can make your Kashmir tour from Surat even more special.</p>
                                                        <p>Houseboats let you wake up to the peaceful waters and stunning mountain views. They are equipped with modern comforts, ensuring your stay is both cozy and enjoyable amidst the beautiful scenery. Book your Kashmir package tour now and enjoy the charm of staying in these floating homes, making your Customized Kashmir Tour truly unforgettable.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingNine">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseNine">
                                                        Can You Go River Rafting in Kashmir?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseNine" className="accordion-collapse collapse show" aria-labelledby="flush-headingNine" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p>Yes, you can enjoy river rafting in Kashmir, which is a great addition to your Kashmir package tour from Surat. The region is full of rivers and streams, making it perfect for adventure lovers seeking a thrilling experience.</p>
                                                        <p><b>1. Pahalgam - Lidder River:</b> Pahalgam is a popular spot for river rafting. The Lidder River, originating from the beautiful Betaab Valley, offers rapids suitable for both beginners and experienced rafters. Rafting on the Lidder River gives you an exciting ride and a chance to admire the natural beauty of Pahalgam.</p>
                                                        <p><b>2. Sonamarg - Indus River:</b> For a more challenging rafting adventure, head to Sonamarg. The Indus River flows through this stunning valley, offering thrilling rapids with a backdrop of snow-covered peaks. Rafting on the Indus River is both exciting and breathtaking.</p>
                                                        <p>Book your Surat to Kashmir tour package today and experience the excitement of river rafting, creating unforgettable memories in the paradise of Kashmir.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingTen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
                                                        Why the Mughal Road is Important for Your Kashmir Tour
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseTen" className="accordion-collapse collapse show" aria-labelledby="flush-headingTen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p>The Mughal Road is an important part of exploring Kashmir. This historic route, spanning 84 kilometers, connects Bufliaz in the Poonch district to Shopian in the Kashmir valley. During the Mughal era, Emperor Akbar fortified this road, known as the 'Imperial Road,' to link Lahore with Kashmir.</p>
                                                        <p>For travelers on a Kashmir tour from Surat, the Mughal Road offers a shorter and scenic route from Poonch and Rajouri to Srinagar, reducing the distance from 588 kilometers to just 126 kilometers. Along the way, you can enjoy picturesque spots like Buffliaz, Peer Ki Gali, and Shopian.</p>
                                                        <p>Besides offering stunning views, the Mughal Road is rich in history, with strategic importance noted by historian Mohibbul Hasan. Experience this blend of history and beauty on your Surat to Kashmir tour.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingEleven">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEleven" aria-expanded="false" aria-controls="flush-collapseEleven">
                                                        How Cold Does It Get in Winter in Kashmir?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseEleven" className="accordion-collapse collapse show" aria-labelledby="flush-headingEleven" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p>If you're planning a trip to Kashmir from Surat and curious about winter weather, Kashmir's winters are both cold and magical. From December to February, temperatures range between -2°C and -10°C (28°F to 14°F), transforming the region into a snowy wonderland.</p>
                                                        <p>Visiting Kashmir in winter means experiencing snow-covered valleys and frozen lakes. The sight of snow-capped peaks and frozen Dal Lake is an unforgettable experience.</p>
                                                        <p>Book your Kashmir tour from Surat and enjoy the winter beauty of this paradise.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingTwelve">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwelve" aria-expanded="false" aria-controls="flush-collapseTwelve">
                                                        Can You Experience Snowfall on a Kashmir Tour from Surat?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseTwelve" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwelve" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p>If you're planning a Kashmir tour from Surat and hoping to see snowfall, the best time to visit is from December to February. During these months, Kashmir transforms into a snowy wonderland, offering a magical experience in places like Srinagar, Gulmarg, and Pahalgam.</p>
                                                        <p>Experience snowy meadows, frozen Dal Lake, and skiing in Gulmarg on your Kashmir tour. Book now to enjoy the winter charm of this paradise.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center" >
                                            <h3><b>Kashmir <span class="tomato">Holiday Reviews</span></b></h3>
                                            <h4><i>"Your Experience is our Marketing"</i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-cols-1">
                                    <div id="testimonials">
                                        <div className="testimonial-box-container" >

                                            {reviewersDataView}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <BookingBox />
                        </div>
                    </div >
                </div >
                <div class="btn-call-pulse">
                    <a style={{ color: 'white' }} href="tel:+91-91470-61467">
                        <i className="fa fa-phone"></i>
                        {/* <span><small>+91 9147061467</small></span> */}
                    </a>
                </div>
                <div className="btn-whatsapp-pulse">
                    <a style={{ color: 'white' }} href="#" onClick={this.handleClick}>
                        <i className="fab fa-whatsapp"></i>
                        {/* <span>
                                <small>+91 9883359713</small>
                            </span> */}
                    </a>
                </div>

            </>
        );
    }
}

export default Kashmir_7N_8D_Package_Tour_From_Surat;