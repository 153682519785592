import React, { Component } from 'react';
import MainHeader from '../../../components/common/MainHeader';
import FooterDesktop from '../../../components/common/FooterDesktop';
import Andaman_6N_7D_Package from '../../../components/package/Andaman/Andaman_6N_7D_Package';
import { Helmet } from "react-helmet";


class Andaman_6N_7D_Page extends Component {
    constructor() {
        super();
        this.state = {
            TrendingPackagesData: [],
            ReviewersData: [],
        }
    }

    componentDidMount() {
        window.scroll(0, 0);
        const TrendingPackageDataList = [
            {
                Title: "Untouched Manipur",
                ImageName: "5N-6D-manipur.webp",
                AllternateText: "...",
                Description: "2N Kohima | 1N Imphal | 1N Moreh | 1N Imphal | Private Transfer | Deluxe Hotel  ",
                PricePerNight: "24,990",
                NoOfTours: 47,
                NoOfDeparture: 40,
                Include: "EX-GAU",
                MutedText: "*Per head / Min 6 Pax",
                Url: 'tel://9163676037'
            },
            {
                Title: "Peaceful Meghalaya",
                ImageName: "6N-7D-meghalaya.webp",
                AllternateText: "...",
                Description: "2N Kaziranga | 3N Shillong | 1N Guwahati | Private Transfer | Deluxe Hotel",
                PricePerNight: "22,800",
                NoOfTours: 43,
                NoOfDeparture: 38,
                Include: "EX-GAU",
                MutedText: "*Per head / Min 6 Pax",
                Url: 'tel://9163676037'
            },
            {
                Title: "Unexplored Manipur",
                ImageName: "7N-8D-manipur.webp",
                AllternateText: "...",
                Description: "2N Kaziranga | 2N Kohima | 3N Imphal | Private Transfer | Deluxe Hotel",
                PricePerNight: "27,900",
                NoOfTours: 44,
                NoOfDeparture: 37,
                Include: "EX-GAU",
                MutedText: "*Per head / Min 6 Pax",
                Url: 'tel://9163676037'
            },

            {
                Title: "Extended Meghalaya",
                ImageName: "8N-9D-meghalaya.webp",
                AllternateText: "...",
                Description: "2N Kaziranga | 3N Shillong | 1N Cherrapunjee...more | Private Transfer | Deluxe Hotel",
                PricePerNight: "33,700",
                NoOfTours: 46,
                NoOfDeparture: 39,
                Include: "EX-GAU",
                MutedText: "*Per head / Min 6 Pax",
                Url: 'tel://9163676037'
            },
            {
                Title: "Glimpses of North East",
                ImageName: "10N-11D-sangti-vally.webp",
                AllternateText: "...",
                Description: "2N Guwahati | 1N Kaziranga | 1N Bhalukpong...more | Private Transfer | Deluxe Hotel",
                PricePerNight: "28,300",
                NoOfTours: 44,
                NoOfDeparture: 37,
                Include: "EX-GAU",
                MutedText: "*Per head / Min 6 Pax",
                Url: 'tel://9163676037'
            },
            {
                Title: "Extended North East",
                ImageName: "11N-12D-madhuri.webp",
                AllternateText: "...",
                Description: "2N Guwahati | 3N Shillong | 1N Kaziranga...more | Private Transfer | Deluxe Hotel",
                PricePerNight: "53,899",
                NoOfTours: 47,
                NoOfDeparture: 40,
                Include: "EX-GAU",
                MutedText: "*Per head / Min 6 Pax",
                Url: 'tel://9163676037'
            }

        ];

        const reviewersDataList = [
            {
                Name: "Shivaji Mhatre",
                ImageName: "surjo.webp",
                AllternateText: "...",
                Location: "@Mumbai",
                Comment: "We had the most remarkable tour with Adorable Vacation. Not only everything was perfect, but we felt, in every instance, Anushka fromAdorable and our driver Pankaj truly cared about us, and did their best to make this trip memorable. For us although we are well-traveled this definitely has become the most eye-opening, We were pleasantly surprised by the diversity of our trip: from Mawphlang sacred forest , from kayaking at Shnopdeng, to the trek to Double Decker Root Bridge and Rainbow falls, from ever-changing khasi meals offered, every stay was very nice, spacious and comfortable. We definitely will be back !.",
            },
            {
                Name: "Chandra Sekhar Reddy",
                ImageName: "vijoy.webp",
                AllternateText: "...",
                Location: "@Bengaluru",
                Comment: "Had a great time travelling with Adorable Vacation. They take care of the smallest detail and make your trip a memorable one. Thank you so much Noorul and Soumya! We will definitely suggest you guys to our friends and family and would love to travel with you again.😍",
            },
            {
                Name: "Aravind Pillai",
                ImageName: "vj.webp",
                AllternateText: "...",
                Location: "@Hyderabad",
                Comment: "A dream trip to shillong and cherrapungi in midst of this monsoon was only possible because of Adorable Vacation. A special thanks to Soumya for taking all the trouble for making this trip memorable for us .I will highly recommend Adorable Vacation LLP to be your travel partner 🙏😊🤗🤩.",
            },
            {
                Name: "Dhanalaxmi Arjun",
                ImageName: "sayatani.webp",
                AllternateText: "...",
                Location: "@Chennai",
                Comment: "I have completed my trip to meghalaya with adorable vacation. Our trip planner Mr Soumya helped customize our tour patiently and as per our needs. Our tour operator Ms Anushka co-ordinated the tour very well. She followed up with us every day. She also helped with the recovery of our lost belongings.The tour was wonderful in august. Nongriat double decker and rainbow falls trek was tiring but very much worth seeing. Our driver and local guide Mr. Sanjay had excellent behaviour and very friendly nature.",
            },
        ]

        this.setState({
            TrendingPackagesData: TrendingPackageDataList,
            ReviewersData: reviewersDataList
        })
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>Andaman Tour 6N 7D </title>
                </Helmet>
                <MainHeader />
                <Andaman_6N_7D_Package
                    TrendingPackagesData={this.state.TrendingPackagesData}
                    ReviewersData={this.state.ReviewersData}
                />
                <FooterDesktop />
            </>
        );
    }
}

export default Andaman_6N_7D_Page;