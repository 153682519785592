import React, { Component } from 'react';

class InclusionExclusion extends Component {
    render() {
        return (
            <>
                <h4 className='tomato'><u>INCLUSION: <strong>7 NIGHTS 8 DAYS BHUTAN TOUR PACKAGE </strong></u></h4>
                <ul>
                <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Stay in TCB-listed hotels (Double/Twin Sharing Room).
                    </li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Meal Plan MAP (Breakfast and Dinner).
                    </li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Transfers and all sightseeing in a private non-AC vehicle.</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Parking, Toll Tax, and e-Permits.</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Thimphu Local Sightseeing.</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Punakha Sightseeing.</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Paro City Tour.</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;English/Hindi Speaking Tour Guide.</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;All transfers will be point-to-point.</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;SDF Charges (Sustainable Development Fee).</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;1 Bottle of Packaged Drinking Water per day in the vehicle.</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;24/7 guest service support during the trip from Bhutan and India offices.</li>
                </ul><br/><br/>
                <h4 className='tomato'><u>EXCLUSION: <strong>BHUTAN TRIP FROM PUNE </strong></u></h4>
                <ul>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Airfare, Train fare.</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Snacks, lunch, or any other meals.</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Monument entrance fees.</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Laundry, tips, phone calls, or any other personal expenses.</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Anything not listed in the 'Inclusions' section.</li>
                </ul>
            </>
        );
    }
}

export default InclusionExclusion;