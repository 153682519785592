import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import ApiURL from "../../../api/ApiURL";
import InclusionExclusion from '../Ladakh/InclusionExclusion';
import BookingBox from '../BookingBox';
class Thailand_8N_9D_Package extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customActiveTab: 'tab1',
            daywiseActiveTab: 'day1'
        }
    }

    handleCustomTabClick = (tab) => {
        this.setState({
            customActiveTab: tab,
        });
    };

    handleDayWiseTabClick = (tab) => {
        this.setState({
            daywiseActiveTab: tab,
        });
    };
    handleClick = () => {
        const currentUrl = window.location.href;
        const pageTitle = document.title;
        const message = `Hi, Please send about the Tour Package details "${pageTitle}"`;

        const whatsappLink = `https://api.whatsapp.com/send?phone=+9163676037&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
        window.open(whatsappLink, '_blank');
    };
    render() {
        const { customActiveTab, daywiseActiveTab } = this.state;
        const packageTopBannerImageURL = ApiURL.Thailand_8N_9D_Package + "/Thailand-Tour-Package.webp";
        const packageTopBanner2ImageURL = ApiURL.Thailand_8N_9D_Package + "/Thailand-Package-Tour.webp";
        const packageDestinationBannerImageURL = ApiURL.Thailand_8N_9D_Package + "/Sikkim-Travel-Agents.webp";
        const brandedTourackageImage_1_URL = ApiURL.Thailand_8N_9D_Package + "/The-Grand-Dragon-Thailand.webp";
        const brandedTourackageImage_2_URL = ApiURL.Thailand_8N_9D_Package + "/The-Chospa-Thailand-Hotel.webp";
        const brandedTourackageImage_3_URL = ApiURL.Thailand_8N_9D_Package + "/The-Abduz-Thailand.webp";
        const Topbanner0ImageURL = ApiURL.Thailand_8N_9D_Package + "/Ladakh-Royal-Enfield-Ride.webp";
        const Topbanner1ImageURL = ApiURL.Thailand_8N_9D_Package + "/Ladakh-Camel-Ride.webp";
        const Topbanner2ImageURL = ApiURL.Thailand_8N_9D_Package + "/Thailand-Ladakh-River-Rafting.webp";
        const Topbanner3ImageURL = ApiURL.Thailand_8N_9D_Package + "/Thailand-Ladakh-Quad-Biking.webp";
        const TopbannerImageURL = ApiURL.Thailand_8N_9D_Package + "/Thailand-Ladakh-Airport.webp";
        const navDay1ImageURL = ApiURL.Thailand_8N_9D_Package + "/Bangkok-Pattaya-Phuket-Krabi-Package-Tour-from-Kolkata.webp";
        const navDay2ImageURL = ApiURL.Thailand_8N_9D_Package + "/Bangkok-Pattaya-Tour-Package.webp";
        const navDay3ImageURL = ApiURL.Thailand_8N_9D_Package + "/Bangkok-Pattaya-Package-Tour-from-Kolkata-with-Phuket-Krabi.webp";
        const navDay4ImageURL = ApiURL.Thailand_8N_9D_Package + "/Thailand-Trip-From-Kolkata.webp";
        const navDay5ImageURL = ApiURL.Thailand_8N_9D_Package + "/Bangkok-Pattaya-Krabi-Phuket-Tour-From-Kolkata.webp";
        const navDay6ImageURL = ApiURL.Thailand_8N_9D_Package + "/Bangkok-Pattaya-Krabi-Phuket-Tour-Package-From-Kolkata.webp";
        const navDay7ImageURL = ApiURL.Thailand_8N_9D_Package + "/Thailand-Tour-Packages-From-Kolkata.webp";
        const navDay8ImageURL = ApiURL.Thailand_8N_9D_Package + "/Thailand-Package-Tour-From-Kolkata.webp";
        const navDay9ImageURL = ApiURL.Thailand_8N_9D_Package + "/Thailand-Trip-Packages-From-Kolkata.webp";


        const TrendingPackagesDataList = this.props.TrendingPackagesData;
        const hotPackagesView = TrendingPackagesDataList.length > 0 ?
            (
                TrendingPackagesDataList.map((dataItem, i) => {
                    const hotelImgURL = ApiURL.Thailand_8N_9D_Package + "/" + dataItem.ImageName;
                    return (<div className="col-md-12 p-0" key={i}>
                        <div className="card h-100">
                            <img src={hotelImgURL} className="card-img-top" alt={dataItem.AllternateText} />
                            <div className="card-body">
                                <h6 className="card-title">{dataItem.Title} <span> &#8377; {dataItem.PricePerNight}</span></h6>
                                <p className="card-text">
                                    <i className="fa fa-building tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-utensils tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-car-side tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-male tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-camera tomato"></i>
                                </p>
                                <p className="card-text"><span>{dataItem.Description}</span></p>
                                <p>
                                    <span><b className="tomato">{dataItem.NoOfTours} </b>Tours</span> | &nbsp;
                                    <span><b className="tomato">{dataItem.NoOfDeparture} </b>Departures</span> | &nbsp;
                                    <span><b className="sdf-blue"> {dataItem.Include}</b></span>
                                </p>
                            </div>
                            <div className="card-footer">
                                <small className="text-muted"><strong className="tomato">{dataItem.MutedText}</strong></small>
                                <a href="#" className="btn btn-success btn-sm float-end" onClick={this.handleClick}>
                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                </a>
                            </div>
                        </div>
                    </div>)
                })
            ) : (<div></div>)


        const settings = {
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const reviewersDataList = this.props.ReviewersData;
        const reviewersDataView = reviewersDataList.map((dataItem, i) => {
            const reviewerImageURL = ApiURL.Thailand_8N_9D_Package + "/" + dataItem.ImageName;
            return (
                <div className="testimonial-box" key={i}>
                    <div className="box-top">
                        <div className="profile">
                            <div className="profile-img">
                                <img src={reviewerImageURL} alt="..." />
                            </div>
                            <div className="name-user">
                                <strong>{dataItem.Name}</strong>
                                <span>{dataItem.Location}</span>
                            </div>
                        </div>
                        <div className="reviews">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </div>
                    </div>
                    <div className="client-comment">
                        <p>{dataItem.Comment}</p>
                    </div>
                </div>
            )
        })


        return (
            <>
                <div className="container-fluid">
                    <div className="row">
                        <img src={packageTopBannerImageURL} className="mobile-image2" alt="..." />
                    </div>
                </div>
                <div className="container-fluid custom-container py-3">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="container-fluid custom-container py-1">
                                        <h1 className="content-desktop" style={{ fontSize: "2vw" }}>Looking for <b>Bangkok Pattaya Phuket Krabi Package Tour from Kolkata</b></h1>

                                        <h1 className="content-mobile" style={{ fontSize: "4vw" }}><center>Looking for <b>Bangkok Pattaya Phuket Krabi Package!</b></center>
                                            <hr />
                                        </h1>
                                        <div className="row">
                                            <img src={packageTopBanner2ImageURL} className="mobile-image" alt="..." />

                                        </div>
                                        <hr />
                                        <div className="col-12">
                                            <big className="text-muted"><strong className="tomato">8N/9D</strong></big>&nbsp;
                                            <i className="fa fa-plane-departure"></i>&nbsp;&nbsp;
                                            <i className="fa fa-arrow-right"></i>&nbsp;Bangkok(2N)&nbsp;
                                            <i className="fa fa-arrow-right"></i>&nbsp;Pattaya(2N)&nbsp;
                                            <i className="fa fa-arrow-right"></i>&nbsp;Phuket(2N)&nbsp;
                                            <i className="fa fa-arrow-right"></i>&nbsp;Krabi(2N)&nbsp;
                                            <i className="fa fa-arrow-right"></i>&nbsp;&nbsp;
                                            <i className="fa fa-plane-departure fa-flip-horizontal"></i>
                                            <br />
                                        </div>
                                        <hr />
                                        <h2 className="content-desktop p-2" style={{ fontSize: "1.5vw" }}> For Booking <a href='tel:9163676037' class="tomato">☎ CALL Mr. Akash Dutta  +91- 91636-76037</a></h2>
                                        <h2 className="content-mobile p-4" style={{ fontSize: "4.5vw" }}> For Booking <a href='tel:9163676037' class="tomato">☎ CALL Mr. Akash Dutta  +91- 91636-76037</a></h2>

                                        <div className="card">
                                            <div className="card-header custHeaer">
                                                <h3 className='content-desktop' style={{ fontSize: "1.5vw" }}>Your <b>THAILAND HOLIDAY ADVENTURE</b> Activities </h3>
                                                <h3 className='content-mobile2' style={{ fontSize: "3.5vw" }}>Your <b>THAILAND HOLIDAY ADVENTURE</b> Activities </h3>
                                            </div>
                                            <div className="card-body">
                                                <p>Are you ready to experience the wonders of Thailand Package Tour From Kolkata? Look no further than our exclusive Bangkok Pattaya Phuket Krabi Package Tour From Kolkata! This all-inclusive adventure is designed to immerse you in the vibrant culture, stunning landscapes, and unforgettable experiences that Thailand has to offer.</p>
                                                <br />
                                                <p>Kickstart your journey in the bustling metropolis of Bangkok, where ancient temples, bustling markets, and mouthwatering cuisine await. Then, escape to the sun-drenched shores of Pattaya, known for its pristine beaches and vibrant nightlife. Dive into thrilling water sports or simply relax and soak up the tropical vibes.</p>
                                                <br/>
                                                <p>Next, we whisk you away to the picturesque island of Phuket, where turquoise waters and lush greenery beckon. Explore hidden coves, indulge in fresh seafood, and witness breathtaking sunsets that will leave you speechless. Finally, conclude your adventure in the natural paradise of Krabi, where towering limestone cliffs and emerald seas await exploration.</p>
                                                <br/>
                                                <p>With our <b>Bangkok Pattaya Phuket Krabi Tour Package</b>, every moment is filled with excitement, relaxation, and cultural immersion. Don't miss out on the trip of a lifetime – book your <b>Thailand Trip Package</b> adventure today!</p>
                                                <a href="tel://9163676037" className="btn btn-Warning">Call Now</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className='container-fluid'>
                                <div className="container-fluid navtabCustom py-3">
                                    <nav>
                                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                            <button onClick={() => this.handleCustomTabClick('tab1')} className={`tab button ${customActiveTab === 'tab1' ? 'active' : ''}`} role="tab" aria-controls="nav-highlights" aria-selected={customActiveTab === 'tab1'}>Highlights</button>
                                            <button onClick={() => this.handleCustomTabClick('tab2')} className={`tab button ${customActiveTab === 'tab2' ? 'active' : ''}`} role="tab" aria-controls="nav-flights" aria-selected={customActiveTab === 'tab2'}>Flights</button>
                                            <button onClick={() => this.handleCustomTabClick('tab3')} className={`tab button ${customActiveTab === 'tab3' ? 'active' : ''}`} role="tab" aria-controls="nav-hotel" aria-selected={customActiveTab === 'tab3'}>Hotel</button>
                                            <button onClick={() => this.handleCustomTabClick('tab4')} className={`tab button ${customActiveTab === 'tab4' ? 'active' : ''}`} role="tab" aria-controls="nav-sightseeing" aria-selected={customActiveTab === 'tab4'}>Sightseeing</button>
                                            <button onClick={() => this.handleCustomTabClick('tab5')} className={`tab button ${customActiveTab === 'tab5' ? 'active' : ''}`} role="tab" aria-controls="nav-meals" aria-selected={customActiveTab === 'tab5'}>Meals</button>
                                        </div>
                                    </nav>
                                    <div className="tab-content" id="nav-tabContent">
                                        <div className={customActiveTab === 'tab1' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-highlights-tab">
                                            <p>
                                            Are you looking to explore the wonderful destination of Thailand? Adorable Vacation has got your back. Adorable Vacation presents perfectly curated <b>Thailand Tour package</b> for you according to your needs and requirements. Our <b>Thailand  Package</b> offer an memorable experience for lifetime through the natural scenic beauty of the country.</p>
                                            <p>
                                            With our well articulated itinerary of Thailand Trip Package, you will explore like Phi Phi Island , James bond island & four island, you will also visit safari world and marine park. </p>
                                            <p>Get lost in the majestic country that has so much to offer with lot of adorable memories.</p>
                                           
                                        </div>
                                        <div className={customActiveTab === 'tab2' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-flights-tab">
                                            <div className="overflow-table">
                                                <h4>BEST FLIGHT CONNECTION for<b> LADAKH</b>
                                                    <br /><br />
                                                </h4>
                                                <table className="table table-bordered">
                                                    <tr className="table-danger">
                                                        <th>Airlines</th>
                                                        <th>Flight Number</th>
                                                        <th>City</th>
                                                        <th>Departure Time</th>
                                                        <th>Arrival Time</th>
                                                        <th>Operating Day</th>
                                                    </tr>
                                                    <tr>
                                                        <td>IndiGo</td>
                                                        <td>6E-802</td>
                                                        <td>Mumbai</td>
                                                        <td>07:20</td>
                                                        <td>10:20</td>
                                                        <td>S,M,T,W,T,F,S</td>
                                                    </tr>
                                                    <tr>
                                                        <td>IndiGo</td>
                                                        <td>6E-301</td>
                                                        <td>Mumbai</td>
                                                        <td>07:00</td>
                                                        <td>12:50</td>
                                                        <td>S,M,T,W,T,F,S</td>
                                                    </tr>
                                                    <tr>
                                                        <td>IndiGo</td>
                                                        <td>6E-2209</td>
                                                        <td>Ahmedabad</td>
                                                        <td>04:55</td>
                                                        <td>10:50</td>
                                                        <td>S,M,T,W,T,F,S</td>
                                                    </tr>
                                                    <tr>
                                                        <td>IndiGo</td>
                                                        <td>6E-2385</td>
                                                        <td>Ahmedabad</td>
                                                        <td>23:20</td>
                                                        <td>07:00</td>
                                                        <td>S,M,T,W,T,F,S</td>
                                                    </tr>
                                                    <tr>
                                                        <td>SpiceJet</td>
                                                        <td>SG-160</td>
                                                        <td>Hyderabad</td>
                                                        <td>06:00</td>
                                                        <td>11:40</td>
                                                        <td>S,M,T,W,T,F,S</td>
                                                    </tr>
                                                    <tr>
                                                        <td>IndiGo</td>
                                                        <td>6E -2379</td>
                                                        <td>Hyderabad</td>
                                                        <td>06:00</td>
                                                        <td>12:25</td>
                                                        <td>S,M,T,W,T,F,S</td>
                                                    </tr>
                                                    <tr>
                                                        <td>IndiGo</td>
                                                        <td>6E - 2415</td>
                                                        <td>Kolkata</td>
                                                        <td>22:30</td>
                                                        <td>07:00</td>
                                                        <td>S,M,T,W,T,F,S</td>
                                                    </tr>
                                                    <tr>
                                                        <td>IndiGo</td>
                                                        <td>6E - 474</td>
                                                        <td>Kolkata</td>
                                                        <td>05:30</td>
                                                        <td>12:25</td>
                                                        <td>S,M,T,W,T,F,S</td>
                                                    </tr>
                                                </table>
                                                <br /><br />
                                                <h4><b>DELHI SPECIAL </b>FLIGHT CONNECTION -
                                                    <br /><br />
                                                </h4>
                                                <table className="table table-bordered">
                                                    <tr className="table-danger">
                                                        <th>Airlines</th>
                                                        <th>Flight Number</th>
                                                        <th>City</th>
                                                        <th>Departure Time</th>
                                                        <th>Arrival Time</th>
                                                        <th>Operating Day</th>
                                                    </tr>
                                                    <tr>
                                                        <td>SpiceJet</td>
                                                        <td>SG - 121</td>
                                                        <td>Delhi</td>
                                                        <td>06:10</td>
                                                        <td>07:10</td>
                                                        <td>S,M,T,W,T,F,S</td>
                                                    </tr>
                                                    <tr>
                                                        <td>SpiceJet</td>
                                                        <td>SG - 8125</td>
                                                        <td>Delhi</td>
                                                        <td>08:45</td>
                                                        <td>10:10</td>
                                                        <td>S,M,T,W,T,F,S</td>
                                                    </tr>
                                                    <tr>
                                                        <td>SpiceJet</td>
                                                        <td>SG - 123</td>
                                                        <td>Delhi</td>
                                                        <td>10:15</td>
                                                        <td>11:40</td>
                                                        <td>S,M,T,W,T,F,S</td>
                                                    </tr>
                                                    <tr>
                                                        <td>IndiGo</td>
                                                        <td>6E - 2003</td>
                                                        <td>Delhi</td>
                                                        <td>05:40</td>
                                                        <td>07:00</td>
                                                        <td>S,M,T,W,T,F,S</td>
                                                    </tr>
                                                    <tr>
                                                        <td>IndiGo</td>
                                                        <td>6E - 2006</td>
                                                        <td>Delhi</td>
                                                        <td>06:30</td>
                                                        <td>07:50</td>
                                                        <td>S,M,T,W,T,F,S</td>
                                                    </tr>
                                                </table>
                                            </div>

                                        </div>
                                        <div className={customActiveTab === 'tab3' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-hotel-tab">
                                            <div className="overflow-table">
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }} ><center><b>4 STAR - CERTIFIED BY 'ADORABLE' </b></center></h4>
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                <thead class="cf">
                                                    <tr className="text-center">
                                                        {/* <!-- Add the "text-center" className to center content --> */}
                                                        <th colspan="6" style={{ fontSize: "1.3vw" }}><h6><b>4 STAR - CERTIFIED BY 'ADORABLE' </b></h6></th>
                                                    </tr>
                                                    <tr className="table-danger">
                                                        <th className="text-center">Destination</th>
                                                        <th className="text-center">Hotel Names</th>
                                                        <th className="text-center">Hotel Type</th>
                                                        <th className="text-center">No of Nights</th>
                                                        <th className="text-center">Meal Plan</th>
                                                    </tr>
                                                    </thead>
                                                    <tr>
                                                        <td data-title="Destination"><div style={{ display: 'inline-block' }}>Thailand</div></td>
                                                        <td data-title="Hotel Names"><div style={{ display: 'inline-block' }}>Bodhi Tree / Royal Palace /Lakrook</div></td>
                                                        <td data-title="Hotel Type">4 Star
                                                        </td>
                                                        <td data-title="No of Nights"><div style={{ display: 'inline-block' }}>4N</div></td>
                                                        <td data-title="Meal Plan"><div style={{ display: 'inline-block' }}>Room with Breakfast & Dinner</div></td>
                                                    </tr>
                                                    <tr>
                                                        <td data-title="Destination"><div style={{ display: 'inline-block' }}>Nubra</div></td>
                                                        <td data-title="Hotel Names"><div style={{ display: 'inline-block' }}>Nubra Residency / Himalayan Desert Villa / Nubra Ethnic
                                                        </div>
                                                        </td>
                                                        <td data-title="Hotel Type">Premium Camp
                                                        </td>
                                                        <td data-title="No of Nights"><div style={{ display: 'inline-block' }}>1N</div></td>
                                                        <td data-title="Meal Plan"><div style={{ display: 'inline-block' }}>Room with Breakfast & Dinner</div></td>
                                                    </tr>
                                                    <tr>
                                                        <td data-title="Destination"><div style={{ display: 'inline-block' }}>Pangong</div></td>
                                                        <td data-title="Hotel Names"><div style={{ display: 'inline-block' }}>World Attic Camp / Red Start Camp / Highlake Camp
                                                        </div>
                                                        </td>
                                                        <td data-title="Hotel Type">Premium Camp
                                                        </td>
                                                        <td data-title="No of Nights"><div style={{ display: 'inline-block' }}>1N</div></td>
                                                        <td data-title="Meal Plan"><div style={{ display: 'inline-block' }}>Room with Breakfast & Dinner</div></td>
                                                    </tr>
                                                </table>
                                                </div>
                                                <br/>
                                                <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }} ><center>Your <b>Thailand Tour Package Price</b> - with 4 <div style={{ display: 'inline-block', color: 'gold' }}>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                </div> Accommodation </center>
                                                </h4>
                                                <div id="no-more-tables">
                                                    <table class="col-md-12 table-bordered table-striped table-condensed cf" style={{ width: "100%", height: "50%" }}>
                                                        <thead class="cf">
                                                            <tr className="text-center">
                                                                {/*  <!-- Add the "text-center" className to center content --> */}
                                                                <th colspan="6">
                                                                    <h4 className="content-desktop" style={{ fontSize: "1.3vw" }} ><center>Your <b>Thailand Tour Package Price</b> - with 4 <div style={{ display: 'inline-block', color: 'gold' }}>
                                                                        <i className="fa fa-star"></i>
                                                                        <i className="fa fa-star"></i>
                                                                        <i className="fa fa-star"></i>
                                                                        <i className="fa fa-star"></i>
                                                                    </div> Accommodation </center>
                                                                    </h4>

                                                                </th>
                                                            </tr>
                                                            <tr class="table text-center">
                                                                <th class="text-center">No of Pax</th>
                                                                <th class="text-center">Package Price (Including GST.)</th>
                                                                <th class="text-center">Transport</th>
                                                                <th class="text-center">WhatsApp Now</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td class="text-center" data-title="No of Pax">
                                                                    04 Adults
                                                                </td>
                                                                <td class="text-center" data-title=" Price(Inc. GST.)">
                                                                    32,299/- (Per Person)
                                                                </td>
                                                                <td class="text-center" data-title="Transport">Luxury SEDAN</td>
                                                                <td class="text-center" data-title="WhatsApp Now"><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick}>
                                                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                                                </a></td>
                                                            </tr>
                                                            <tr>
                                                                <td class="text-center" data-title="No of Pax">
                                                                    06 Adults
                                                                </td>
                                                                <td class="text-center" data-title=" Price(Inc. GST.)">
                                                                    29,899 /- (Per Person)
                                                                </td>
                                                                <td class="text-center" data-title="Transport">Luxury SUV</td>
                                                                <td class="text-center" data-title="WhatsApp Now"><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick}>
                                                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                                                </a></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <br />
                                            </div>

                                        </div >
                                        <div className={customActiveTab === 'tab4' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-sightseeing-tab">
                                            <p>Ladakh, often referred to as the "Land of High Passes," is renowned for its stunning landscapes, ancient monasteries, and rich cultural heritage. Here are some of the best tourist attractions to include in a <b>Thailandpackage tour</b>:</p>
                                            <p><b>Pangong Lake -</b> This iconic lake, situated at an altitude of 4,350 meters, is famous for its mesmerizing shades of blue and breathtaking scenery. A visit to Pangong Lake, especially during sunrise or sunset, offers a truly unforgettable experience.</p>
                                            <p><b>Nubra Valley -</b> Known for its stark desert landscapes, towering sand dunes, and lush green oasis villages, Nubra Valley is a must-visit destination in Ladakh. Highlights include the Diskit Monastery, Hunder Sand Dunes, and the scenic drive through Khardung La Pass, one of the highest motorable passes in the world.</p>
                                            <p><b>Thailand Palace - </b>Perched atop a hill overlooking the Thailand town, Thailand Palace is a striking architectural marvel reminiscent of the Potala Palace in Lhasa, Tibet. Explore the palace's intricately decorated interiors, panoramic views of the surrounding mountains, and learn about its historical significance.</p>
                                            <p><b>Thiksey Monastery -</b>This magnificent monastery, located approximately 19 kilometers from Thailand, is one of the largest and most impressive monasteries in Ladakh. Admire the stunning architecture, intricate murals, and panoramic views of the Indus Valley from the monastery's vantage point.</p>
                                            <p><b>Hemis Monastery -</b>Hemis Monastery is not only the largest monastery in Thailandbut also one of the wealthiest and most important monasteries in the region. Visit during the annual Hemis Festival to witness colorful masked dances, cultural performances, and religious rituals.</p>
                                            <p><b>Shanti Stupa -</b> This white-domed Buddhist stupa, situated atop a hill overlooking Thailand, offers panoramic views of the city and surrounding mountains. It is a popular spot to watch the sunrise or sunset and to meditate in the tranquil surroundings.</p>
                                            <p><b>Alchi Monastery -</b> Known for its exquisite frescoes and ancient sculptures, Alchi Monastery is a hidden gem nestled in the Indus Valley. Marvel at the intricate artwork adorning the monastery's walls and immerse yourself in its serene ambiance.</p>
                                            <p><b>Lamayuru Monastery -</b>This centuries-old monastery, perched atop a hill overlooking the village of Lamayuru, is one of the oldest and most picturesque monasteries in Ladakh. Explore its labyrinthine corridors, visit the cave temples, and soak in the mystical atmosphere.</p>
                                            <p><b>Tso Moriri Lake -</b>Located in the remote Changthang region of Ladakh, Tso Moriri Lake is a pristine high-altitude lake surrounded by snow-capped mountains and vast grasslands. It offers a peaceful retreat away from the crowds and a chance to spot rare wildlife such as Tibetan wild ass and migratory birds.</p>
                                            <p><b>Khardung La Pass -</b>Journey to one of the world's highest motorable passes, Khardung La Pass, located at an altitude of over 5,300 meters. Enjoy panoramic views of the snow-capped Himalayas, rugged terrain, and dramatic landscapes en route to the Nubra Valley.</p>
                                            <p>These are just a few of the best tourist attractions to include in a <b>Thailandpackage tour</b>. Each destination offers a unique blend of natural beauty, cultural heritage, and spiritual significance, making Thailanda truly unforgettable destination for travelers seeking adventure and exploration.</p>
                                        </div>
                                        <div className={customActiveTab === 'tab5' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-meals-tab">
                                            <div className="overflow-table">
                                                <h5 class="tomato">6 times Meals and 6 times Breakfast has been Provided</h5>
                                                <table className="table table-bordered">
                                                    <tr className="table-danger">
                                                        <th colspan="6" className="text-center">Category</th>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-center">JAIN</td>
                                                        <td class="text-center">PURE-VEG</td>
                                                        <td class="text-center">VEGAN</td>
                                                        <td class="text-center">PURE-NON-VEG</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div
                                        className="card-header custHeaer"
                                    >
                                        <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>How to Start <b>Planning for Thailand Tour ?</b></h3>
                                        <h3 className="content-mobile2" style={{ fontSize: "5.0vw" }}>How to Start <b>Planning for Thailand Tour ?</b></h3>
                                    </div>
                                    <div className="card-body">
                                      <p>Traveling to Thailand offers an exciting blend of cultural experiences, beautiful landscapes, and thrilling activities. Here's a step-by-step guide by Adorable Vacation on how to make the most of your <b>Thailand Travel Packages</b>, especially with our <b>Bangkok Pattaya Phuket Krabi Package Tour From Kolkata</b>.</p>
                                      <h4 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>Best Time to Visit Thailand</b></h4>
                                      <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}><b>Best Time to Visit Thailand</b></h4>
                                      <p>Thailand is a year-round destination, but the best time to visit is from November to February when the weather is cooler and dry. This period is perfect for exploring the vibrant streets of Bangkok, lounging on Pattaya's beaches, and diving into the clear waters of Phuket and Krabi.</p>
                                      <h4 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>How to Reach Thailand</b></h4>
                                      <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}><b>How to Reach Thailand</b></h4>
                                      <p>The most convenient way to reach <b>Thailand Tour From Kolkata</b> is by air. Numerous airlines operate daily flights from Kolkata to Bangkok, the primary gateway to Thailand. Once in Bangkok, you can easily connect to other destinations like Pattaya, Phuket, and Krabi.</p>
                                      <h4 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>Bangkok: The Starting Point</b></h4>
                                      <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}><b>Bangkok: The Starting Point</b></h4>
                                      <p>Begin your <b>Thailand Tour Package</b> in Bangkok, a city that seamlessly blends ancient culture with modern attractions. Visit the Grand Palace, shop at bustling markets, and savor delectable street food.</p>
                                      <h4 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>Pattaya: Beachside Bliss</b></h4>
                                      <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}><b>Pattaya: Beachside Bliss</b></h4>
                                      <p>Next, head to Pattaya. Known for its lively nightlife and stunning beaches, Pattaya offers a mix of relaxation and adventure. Enjoy water sports, explore nearby islands, and soak in the vibrant atmosphere of Walking Street.</p>
                                      <h4 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>Phuket: Island Paradise</b></h4>
                                      <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}><b>Phuket: Island Paradise</b></h4>
                                    <p><b>Phuket  Island Tour</b> is a must-visit for beach lovers. Relax on its pristine beaches, take boat trips to nearby islands, and experience the local nightlife. Phuket's natural beauty and vibrant culture make it a highlight of any <b>Thailand Trips</b>.</p>
                                    <h4 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>Krabi: Natural Wonder</b></h4>
                                      <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}><b>Krabi: Natural Wonder</b></h4>
                                      <p>Conclude your journey in Krabi, famous for its dramatic limestone cliffs and emerald waters. Kayak through mangroves, climb to panoramic viewpoints, and explore secluded beaches for a truly serene experience.</p>
                                   <p>With our <b>Bangkok Pattaya Phuket Tour Package Tour</b>, every detail is taken care of, ensuring you have a seamless and unforgettable adventure in <b>Best Thailand Tour Packages</b>. Book your <b>Thailand Family Tour Packages</b> today and get ready for the journey of a lifetime!</p>
                                    </div>
                                </div>
                            </div>
                            <br /><br />
                            <div className="container-fluid navtabCustom py-4">

                                <h5 className="tomato">DAY WISE <b>BANGKOK PATTAYA PHUKET KRABI TOUR PLAN FROM KOLKATA</b></h5>
                                <hr />

                                <nav>
                                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                        <button onClick={() => this.handleDayWiseTabClick('day1')} className={`tab button ${daywiseActiveTab === 'day1' ? 'active' : ''}`} role="tab" aria-controls="nav-day-one" aria-selected={daywiseActiveTab === 'day1'}>DAY-1</button>
                                        <button onClick={() => this.handleDayWiseTabClick('day2')} className={`tab button ${daywiseActiveTab === 'day2' ? 'active' : ''}`} role="tab" aria-controls="nav-day-two" aria-selected={daywiseActiveTab === 'day2'}>DAY-2</button>
                                        <button onClick={() => this.handleDayWiseTabClick('day3')} className={`tab button ${daywiseActiveTab === 'day3' ? 'active' : ''}`} role="tab" aria-controls="nav-day-three" aria-selected={daywiseActiveTab === 'day3'}>DAY-3</button>
                                        <button onClick={() => this.handleDayWiseTabClick('day4')} className={`tab button ${daywiseActiveTab === 'day4' ? 'active' : ''}`} role="tab" aria-controls="nav-day-four" aria-selected={daywiseActiveTab === 'day4'}>DAY-4</button>
                                        <button onClick={() => this.handleDayWiseTabClick('day5')} className={`tab button ${daywiseActiveTab === 'day5' ? 'active' : ''}`} role="tab" aria-controls="nav-day-five" aria-selected={daywiseActiveTab === 'day5'}>DAY-5</button>
                                        <button onClick={() => this.handleDayWiseTabClick('day6')} className={`tab button ${daywiseActiveTab === 'day6' ? 'active' : ''}`} role="tab" aria-controls="nav-day-six" aria-selected={daywiseActiveTab === 'day6'}>DAY-6</button>
                                        <button onClick={() => this.handleDayWiseTabClick('day7')} className={`tab button ${daywiseActiveTab === 'day7' ? 'active' : ''}`} role="tab" aria-controls="nav-day-seven" aria-selected={daywiseActiveTab === 'day7'}>DAY-7</button>
                                        <button onClick={() => this.handleDayWiseTabClick('day8')} className={`tab button ${daywiseActiveTab === 'day8' ? 'active' : ''}`} role="tab" aria-controls="nav-day-eight" aria-selected={daywiseActiveTab === 'day8'}>DAY-8</button>
                                        <button onClick={() => this.handleDayWiseTabClick('day9')} className={`tab button ${daywiseActiveTab === 'day9' ? 'active' : ''}`} role="tab" aria-controls="nav-day-nine" aria-selected={daywiseActiveTab === 'day9'}>DAY-9</button>
                                    </div>
                                </nav>
                                <div className="tab-content" id="nav-tabContent">
                                    <div className={daywiseActiveTab === 'day1' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-one-tab">
                                        <img src={navDay1ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 class="tomato"> <b>DAY 1: ARRIVAL AT PHUKET AIRPORT, TRANSFER TO HOTEL</b></h5>
                                        <p>Your Phuket Krabi package tour from Kolkata starts with your arrival in Phuket. Upon arrival, you will be greeted by our representative at the airport and transferred to your hotel. The transfer will take around 1 hour, depending on the location of your hotel.</p>
                                        <p>You will find that Phuket is one of the most popular tourist destinations in <b>Thailand Vacation</b>, known for its beautiful beaches, vibrant nightlife, and cultural attractions. Once you reach your hotel, check in and relax for the day.</p>
                                        
                                    </div>
                                    <div className={daywiseActiveTab === 'day2' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-two-tab">
                                        <img src={navDay2ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 class="tomato"><b>Day 2 - PHI PHI ISLAND TOUR WITH LUNCH [EXCLUDING NATIONAL PARK FEE]</b></h5>
                                        <p>
                                        On day 2 of your <b>Phuket package tour</b>, get ready for an exciting <b>Phi Phi Island day trip</b>. This beautiful island is located just a short boat ride away from Phuket and is known for its crystal-clear waters, stunning beaches, and amazing marine life.
                                        </p>
                                        <p>Your <b>Phi Phi Island tour</b> will start with a hotel pickup and transfer to the pier. From there, you will board a speedboat and head out to the island. During the tour, you will get to visit several popular attractions on the island, including Maya Bay, Viking Cave, and Monkey Beach.</p>
                                        <p>In addition to sightseeing, you will also get to enjoy swimming and snorkelling in the clear blue waters of the Andaman Sea. The tour includes lunch at a local restaurant on the island. Please note that the National Park fee is excluded from the tour cost and needs to be paid separately.</p>
                                    </div>
                                    <div className={daywiseActiveTab === 'day3' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-three-tab">
                                        <img src={navDay3ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 class="tomato"> <b>Day 3 - TRANSFER FROM PHUKET HOTEL TO KRABI HOTEL [2 hr 49 min (163.0 km) via Route 4]</b></h5>
                                        <p>On day 3 of your <b>Phuket Krabi tour package</b>, you will be transferred from your hotel in Phuket to your hotel in Krabi. The transfer will take around 2 hours and 49 minutes via Route 4, covering a distance of 163 km.</p>
                                        <p>Krabi is another popular tourist destination in Thailand, known for its stunning limestone cliffs, beautiful beaches, and clear blue waters. Once you reach your hotel, check in and relax for the day.</p>
                                    </div>
                                    <div className={daywiseActiveTab === 'day4' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-four-tab">
                                        <img src={navDay4ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 class="tomato"> <b>Day 4 – KRABI, 04 ISLAND TOUR WITH LUNCH [EXCLUDING NATIONAL PARK FEE]</b></h5>
                                        <p>On day 4th day of your <b>Phuket Krabi tour packages</b>, get ready for a thrilling day trip to the 4 Islands of Krabi. This tour is a great way to explore the natural beauty of the Krabi region, including the famous Railay Beach and the stunning islands of Koh Poda, Koh Gai, and Koh Tub.
                                        </p>
                                        <p>Your 4 Island tour will start with a hotel pickup and transfer to the pier. From there, you will board a speedboat and head out to the islands. During the tour, you will get to enjoy swimming, snorkelling, and sunbathing on the beautiful beaches of the islands.</p>
                                        <p>The <b>Krabi tour</b> also includes a delicious lunch at a local restaurant on one of the islands. Please note that the National Park fee is excluded from the tour cost and needs to be paid separately.</p>
                                        
                                    </div>
                                    <div className={daywiseActiveTab === 'day5' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-five-tab">
                                        <img src={navDay5ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 class="tomato"> <b>Day 5 –  <b>KRABI TO PATTAYA TOUR</b> VIA BANGKOK AIRPORT + EVENING ALCAZAR SHOW AT PATTAYA (VIP SEAT)</b></h5>
                                        <p> On day 5 of your Bangkok Pattaya tour packages from Kolkata, you will be transferred from your hotel in Krabi to Pattaya via Bangkok Airport. Once you reach Pattaya, check in to your hotel and relax for the day.</p>
                                        <p>In the evening, get ready for a mesmerizing show at the famous Alcazar Theater in Pattaya. The Alcazar Show is one of the most popular tourist attractions in Pattaya, known for its spectacular performances by talented artists in dazzling costumes.</p>
                                        <p>You will enjoy the show from the comfort of your VIP seat, which provides a great view of the stage.</p>
                                    </div>
                                    <div className={daywiseActiveTab === 'day6' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-six-tab">
                                        <img src={navDay6ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 class="tomato"> Day 6 –  PATTAYA CORAL ISLAND TOUR WITH LUNCH + ART IN PARADISE PATTAYA</h5>
                                        <p>On the 6th day of your <b>Bangkok Pattaya Phuket Krabi Tour Packages from Kolkata</b>, after breakfast, get ready for a visit to the beautiful Coral Island, which is just a short boat ride away from Pattaya Beach. The island is a perfect spot for adventure and relaxation. You can enjoy activities like swimming, snorkelling, and sunbathing on the sandy beaches. The crystal clear water and vibrant coral reefs make for an unforgettable experience. You can also try out exciting water sports activities like parasailing, banana boat rides, and jet skiing at your own cost.</p>
                                        <p>After the <b>Coral Island tour</b>, head towards the Art in Paradise Pattaya. This is a unique art museum that features interactive 3D paintings. Visitors can pose and click pictures with these paintings that make it look like they are a part of the painting itself. The museum is divided into ten sections, each with its own theme, and it is a perfect place for a fun family outing.</p>
                                        
                                    </div>
                                    <div className={daywiseActiveTab === 'day7' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-seven-tab">
                                        <img src={navDay7ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 class="tomato"> Day 7 –  PATTAYA TO BANGKOK TRANSFER + BANGKOK CITY TOUR [GOLDEN BUDDHA + MARBLE BUDDHA + EVENING CHAO PHRAYA DINNER CRUISE]</h5>
                                        <p>After breakfast on day 7 of your <b>Thailand Trip from Kolkata</b>, check out from the hotel in Pattaya and get transferred for <b>Bangkok Tour</b>. On the way to Bangkok, you will have the opportunity to explore the city's stunning sights, such as the Golden Buddha and the Marble Buddha. The Golden Buddha is one of the most visited tourist attractions in Bangkok. It is a massive statue of Lord Buddha made of solid gold and weighs around 5.5 tons. The statue is housed inside the Wat Traimit temple and is a beautiful example of traditional Thai architecture. The Marble Buddha, also known as the Wat Benchamabophit, is another famous temple in Bangkok. The temple is made of white Italian marble and is a prime example of classical Thai style. Inside the temple, you will find a beautiful Buddha statue made of green jade. After the sightseeing tour, you can enjoy a romantic Chao Phraya dinner cruise, where you can indulge in Thai cuisine while taking in the stunning views of the city's skyline. The cruise is an excellent way to experience Bangkok's vibrant nightlife and culture.</p>
                                    </div>
                                    <div className={daywiseActiveTab === 'day8' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-eight-tab">
                                        <img src={navDay8ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 class="tomato">Day 8 –  BANGKOK SAFARI WORLD + MARINE PARK TOUR WITH LUNCH</h5>
                                        <p>On the 8th day of your Bangkok Tour, head towards Safari World and Marine Park, one of the most popular tourist attractions in Bangkok. The park is spread over 170 acres and is home to a wide range of animals, including zebras, giraffes, tigers, lions, and many more.</p>
                                        <p>The Safari Park is divided into four sections: Safari Park, Bird Park, Games Corner, and Macaw Island. Visitors can explore the park in their car or hop on a guided safari tour, where they can get up close and personal with some of the park's animals.</p>
                                        <p>The Marine Park features a range of shows and attractions, including the Dolphin Show, Sea Lion Show, and Orangutan Boxing Show. You can also explore the jungle walk, which is a nature trail that features various exotic birds and animals. Lunch is included in the tour package, and you can choose from a range of cuisines.</p>
                                    </div>
                                    <div className={daywiseActiveTab === 'day9' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-nine-tab">
                                        <img src={navDay8ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 class="tomato">Day 9 –  BYE BYE THAILAND WITH SWEET MEMORIES</h5>
                                        <p>On the last day of your <b>Thailand Trip</b> with <b>Bangkok Pattaya Phuket Krabi Tour</b>, have breakfast at the hotel and check out. Depending on your flight schedule, you may have some time to explore the city or do some last-minute shopping before getting transferred to the airport.</p>
                                    </div>
                                </div>
                            </div>



                            {/* <!--insert table inclusion exclusion--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        {
                                            <InclusionExclusion />
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* <!--end table inclusion exclusion--> */}


                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>The Grand Dragon <span>- Thailand</span></h2>
                                            <h4><i>5 Star Hotel in Thailand</i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row py-3">
                                    <img src={brandedTourackageImage_1_URL} alt="..." />
                                </div>

                                <div id="no-more-tables">
                                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                        <thead>
                                            <tr className="table-danger">
                                                <th>Category</th>
                                                <th>Size(Room)</th>
                                                <th>Hotel Brand</th>
                                                <th>Price (Per Night)</th>
                                                <th>Book Now</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td data-title="Packages">Luxury Accommodation</td>
                                                <td data-title="Size(Room)">320+ SQ.FT</td>
                                                <td data-title="Hotel Brand">Dragon<div className="reviews">
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                </div></td>
                                                <td data-title="Price (Per Night)">Rs 15,729/- </td>
                                                <td data-title="Book Now"><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick}>
                                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                                </a></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                <div className="card mx-0 my-5">
                                    <div className="card-header custHeaer">
                                        <h3 className='content-desktop' style={{ fontSize: "1.5vw" }}><b>Only Certified 5 Star Hotel in Thailand</b></h3>
                                        <h3 className='content-mobile2' style={{ fontSize: "3.5vw" }}><b>Only Certified 5 Star Hotel in Thailand</b></h3>
                                    </div>
                                    <div className="card-body">
                                        <p>Located at a height of 11,562 ft above sea level in the Himalayas, <b>The Grand Dragon Ladakh</b> in Thailand is the region’s only certified 5-Star hotel. Open all year round, this indigenously designed property with 76 rooms including family suites, offers all modern comforts and conveniences with stunning views of the picturesque landscape, the vivid colours of an ever-changing sky and the lofty Himalayan peaks. An ideal base from where visitors to Thailand can make day trips to centuries old monasteries, notably Hemis, Thiksey or Alchi or an overnight journey to soak in the surreal landscapes at Pangong Tso, Nubra Valley or Lamayuru. Dining options include Zasgyath the all day dining restaurant with alfresco seating, Solja the Tea & Coffee Lounge, BBQ and bonfire in the lawns as well as a full service picnic by the banks of the river or any scenic spot. The Wellness centre comprises a fully equipped gym and SPA soon. Book your stay here and take your experience of Thailand to another level.</p>


                                        <a href="tel://9163676037" className="btn btn-Warning">Book Now</a>

                                    </div>
                                </div>
                            </div>

                            <div className="container-fluid py-2">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>The Chospa <span>- Thailand</span></h2>
                                            <h4><i>Vernacular Ladakhi Architecture</i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row py-3">
                                    <img src={brandedTourackageImage_2_URL} alt="..." />
                                </div>

                                <div id="no-more-tables">
                                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                        <thead>
                                            <tr className="table-danger">
                                                <th>Category</th>
                                                <th>Size(Room)</th>
                                                <th>Hotel Brand</th>
                                                <th>Price (Per Night)</th>
                                                <th>Book Now</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td data-title="Packages">Platinum Accommodation</td>
                                                <td data-title="Size(Room)">270+ SQ.FT</td>
                                                <td data-title="Hotel Brand">Earth<div className="reviews">
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                </div></td>
                                                <td data-title="Price (Per Night)">Rs 14,843/- </td>
                                                <td data-title="Book Now"><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick}>
                                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                                </a></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="card mx-0 my-5">
                                    <div className="card-header custHeaer">
                                        <h3 className='content-desktop' style={{ fontSize: "1.5vw" }}><b>CHOSPA – MADE OF THE EARTH</b></h3>
                                        <h3 className='content-mobile2' style={{ fontSize: "3.5vw" }}><b>CHOSPA – MADE OF THE EARTH</b></h3>
                                    </div>
                                    <div className="card-body">
                                        <p><b>Hotel Chospa</b> is a stunning retreat nestled in the heart of the vibrant city centre of Thailand, located on the Old Thailand Road. This enchanting abode has been curated to cater to the discerning explorer and those who appreciate the finer things in life.
                                            Chospa is the union of contemporary hospitality with the scenic and material biome of Ladakh. The architecture mirrors the traditional Ladakhi construction and materiality, rendering an ambience familiar to locals and unique for visitors.

                                            The spatial organization is deliberately insular to mitigate the noise from the abutting busy street yet allowing a convenient flow of passersby to access the alfresco of the café from the upper corner. The atrium lobby aims to lend a casual yet a calming vibe found in most traditional Ladakhi homes. The lobby atrium opens out to the rear with open seating on the south side receiving much needed ample south facing sunlight.</p>


                                        <a href="tel://9163676037" className="btn btn-Warning">Book Now</a>

                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid py-4">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>The Abduz <span>- Thailand</span></h2>
                                            <h4><i>The Best Luxury Hotel</i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row py-3">
                                    <img src={brandedTourackageImage_3_URL} alt="..." />
                                </div>

                                <div id="no-more-tables">
                                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                        <thead>
                                            <tr className="table-danger">
                                                <th>Category</th>
                                                <th>Size(Room)</th>
                                                <th>Hotel Brand</th>
                                                <th>Price (Per Night)</th>
                                                <th>Book Now</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td data-title="Packages">Gold Accommodation</td>
                                                <td data-title="Size(Room)">320+ Sq Ft</td>
                                                <td data-title="Hotel Brand">Luxury <div className="reviews">
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                </div></td>
                                                <td data-title="Price (Per Night)">Rs 13,573/- </td>
                                                <td data-title="Book Now"><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick}>
                                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                                </a></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                <div className="card mx-0 my-5">
                                    <div className="card-header custHeaer">
                                        <h3 className='content-desktop' style={{ fontSize: "1.5vw" }}><b>TIME TRAVEL TO LUXURY</b></h3>
                                        <h3 className='content-mobile2' style={{ fontSize: "3.5vw" }}><b>TIME TRAVEL TO LUXURY</b></h3>
                                    </div>
                                    <div className="card-body">
                                        <p>Nestled in the heart of the Himalayan land, Thailand, The Abduz is a finely crafted abode where you’ll surely be spoilt for choice. Enjoy hotel’s local architecture, outdoor seating areas, exquisite interiors along with a state of comfort, invoking great experiences.

                                            <b>The Abduz</b> symbolise warm reception and hospitality withholding a legacy which is more than two decades old. The hotel is a 5 minutes drive from the airport and only a few minutes from the famed local market. Featuring a business convention centre, an exquisite in-house patisserie, a sophisticated bar, a Shisha lounge, a multi-cuisine restaurant, a well-equipped fitness centre, an entertainment zone, and convenient valet parking.</p>

                                        <a href="tel://9163676037" className="btn btn-Warning">Book Now</a>

                                    </div>
                                </div>
                            </div>

                            <div className="container-fluid py-5">

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>Thailand<span> Tour Packages</span></h2>
                                            <h4>Related Thailand Tour plan</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                                        <div className="carousel-inner">
                                            <div className="carousel-item active">

                                                <Slider {...settings}>
                                                    {hotPackagesView}
                                                </Slider>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h3>FREQUENTLY ASKED QUESTIONS [FAQ]</h3>
                                            <h4><b>Thailand Travel Agency</b> Related Questions</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div
                                        className="accordion accordion-flush faQStyle"
                                        id="accordionFlushExample"
                                    >
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingOne">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseOne"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseOne"
                                                >
                                                    1. What is included in the Thailand package tour ?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseOne"
                                                className="accordion-collapse"
                                                aria-labelledby="flush-headingOne"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Thailand package tours typically include transportation, accommodations, guided tours to popular attractions, meals (depending on the package), and sometimes activities such as trekking or rafting.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingTwo">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseTwo"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseTwo"
                                                >
                                                    2. What is the best time to visit Thailand ?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseTwo"
                                                className="accordion-collapse collapseShow"
                                                aria-labelledby="flush-headingTwo"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    <p>The best time to visit Thailand is during the summer months, from May to September, when the weather is pleasant, and most of the region's attractions are accessible. However, some travelers also visit Thailand in the shoulder seasons of spring (March to April) and autumn (September to October) for fewer crowds and cooler temperatures.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingThree">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseThree"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseThree"
                                                >
                                                    3. How do I travel to Thailand ?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseThree"
                                                className="accordion-collapse collapseShow"
                                                aria-labelledby="flush-headingThree"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    <p>Traveling to Thailand usually involves taking a flight to Thailand, the main town in Ladakh. Several airlines operate flights to Thailand, with a layover in Delhi or other major cities. Alternatively, you can also travel by train to Jammu Tawi or Srinagar and then continue your journey to Thailand by road or air.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingFour">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseFour"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseFour"
                                                >
                                                    4. Do I need a permit to visit Thailand ?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseFour"
                                                className="accordion-collapse collapseShow"
                                                aria-labelledby="flush-headingFour"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    <p>Yes, Indian nationals require an Inner Line Permit (ILP) to visit certain areas in Ladakh, including Nubra Valley, Pangong Lake, and Tso Moriri Lake. You can obtain the permit online or upon arrival in Thailand by providing the necessary documents such as identification proof and passport-sized photographs.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingFive">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseFive"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseFive"
                                                >
                                                    5. What are the must-visit attractions in Thailand ?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseFive"
                                                className="accordion-collapse collapseShow"
                                                aria-labelledby="flush-headingFive"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    <p>Must-visit attractions in Thailand include Pangong Lake, Nubra Valley, Thailand Palace, Thiksey Monastery, Hemis Monastery, Shanti Stupa, and Khardung La Pass. These landmarks offer stunning landscapes, cultural insights, and historical significance.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingSix">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseSix"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseFive"
                                                >
                                                    6. How do I acclimatize to the high altitude in Thailand ?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseSix"
                                                className="accordion-collapse collapseShow"
                                                aria-labelledby="flush-headingSix"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    <p>To acclimatize to the high altitude in Ladakh, it's essential to take it slow and allow your body time to adjust. Spend a couple of days in Thailand before venturing to higher-altitude destinations, drink plenty of water, avoid strenuous activity, and consider taking altitude sickness medication if necessary.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingSeven">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseSeven"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseSeven"
                                                >
                                                    7. What should I pack for a Thailand package tour ?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseSeven"
                                                className="accordion-collapse collapseShow"
                                                aria-labelledby="flush-headingSeven"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    <p>When packing for a Thailand package tour, include essentials such as warm clothing, layers, sunscreen, sunglasses, hats, sturdy walking shoes, a reusable water bottle, altitude sickness medication, and any necessary travel documents (including permits and identification proof).</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingEight">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseEight"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseEight"
                                                >
                                                    8. Is there mobile connectivity and internet access in Thailand?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseEight"
                                                className="accordion-collapse collapseShow"
                                                aria-labelledby="flush-headingEight"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    <p>Yes, there is mobile connectivity and internet access in Ladakh, particularly in the main towns like . However, connectivity may be limited or unreliable in more remote areas and higher-altitude regions. It's advisable to carry a local SIM card for better coverage.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingEight">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseEight"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseEight"
                                                >
                                                    9. Are there any ATMs in  ?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseEight"
                                                className="accordion-collapse collapseShow"
                                                aria-labelledby="flush-headingEight"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    <p>Thailand has four ATMs owned by Axis Bank, Jammu and Kashmir Bank, Punjab National Bank and State Bank of India.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingEight">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseEight"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseEight"
                                                >
                                                    10. Does credit card work in hotels and restaurants?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseEight"
                                                className="accordion-collapse collapseShow"
                                                aria-labelledby="flush-headingEight"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    <p>Yes, the credit card is accepted in a few hotels and restaurants but a service tax is charged.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container-fluid py-5">

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h3><b>Sikkim Tour</b> Reviews</h3>
                                            <h4><i>"Your Experience is our Marketing"</i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-cols-1">
                                    <div id="testimonials">
                                        <div className="testimonial-box-container">

                                            {reviewersDataView}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div >
                        <div className="col-md-3">
                            <BookingBox />
                        </div>

                    </div >
                </div >

            </>
        );
    }
}

export default Thailand_8N_9D_Package;