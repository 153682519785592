import React, { Component } from 'react';

class InclusionExclusion extends Component {
    render() {
        return (
            <>
                <table className="table-borderDodont">
                    <thead>
                        <tr>
                            <td className="tomato"><strong>SIKKIM DARJEELING TOUR PACKAGE INCLUSION</strong></td>
                            <td className="tomato"><strong>SIKKIM DARJEELING PACKAGE TOUR EXCLUSION</strong></td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span> Accommodation in the listed category hotels.</td>
                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span> Nathula Pass Permit.</td>
                        </tr>
                        <tr>
                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span> All transfer and local sightseeing are arranged by an exclusive private vehicle as per the itinerary.</td>
                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span> Entry fees, Camera Permit, Parking fees, Room Heaters</td>
                        </tr>
                        <tr>
                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span> Meals - Daily Breakfast and Dinner.</td>
                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span> Any cost arising due to any unavoidable circumstances or changes in the program by guest.</td>
                        </tr>
                        <tr>
                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span> Permit including for Changu Lake / Baba Mandir. </td>
                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span> GST 5%.</td>
                        </tr>
                        <tr>
                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span> 24x7 Adorable Operation Executive Support.</td>
                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>Anything not mentioned in the inclusion.
                            </td>
                        </tr>
                    </tbody>
                </table>
            </>
        );
    }
}

export default InclusionExclusion;