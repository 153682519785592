import React, { Component } from 'react';

class InclusionExclusion_group extends Component {
    render() {
        return (
            <>
                <h4 className='tomato'><u>ARUNACHAL PRADESH PACKAGE INCLUSION </u></h4>
                <ul>
                <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Exclusive vehicle for your group's transportation throughout Arunachal Pradesh.
                    </li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Accommodation in the best properties at specified destinations with comfort and local experiences.
                    </li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Covering all parking, tolls, fuel charges, and state permits for seamless travel logistics.</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Arrangement of Arunachal Pradesh Entry Permits (ILP) for hassle-free access to restricted areas.</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;These ensure a hassle-free journey, allowing you to focus on enjoying Arunachal Pradesh's beauty and culture.</li>
                    
                </ul><br/><br/>
                <h4 className='tomato'><u>ARUNACHAL PRADESH PACKAGE EXCLUSION </u></h4>
                <ul>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Personal expenses, shopping, additional meals, and souvenirs.</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Transportation costs to Guwahati (starting point) like train or airfare.</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Costs for laundry, tips, phone calls, and porter services.</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Charges for visiting Bumla Pass or any additional local transportation.</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Government GST applicable on services provided is not included.</li>
                </ul>
            </>
        );
    }
}

export default InclusionExclusion_group;