import React, { Component } from 'react';

class InclusionExclusion_bagdogra_group extends Component {
    render() {
        return (
            <>
             <table className="table-borderDodont">
                    <thead>
                        <tr>
                            <td className="tomato"><h6>INCLUSIONS: <strong>BAGDOGRA TO BHUTAN TRIP</strong> </h6></td>
                            <td className="tomato"><h6>EXCLUSIONS: <strong>BAGDOGRA TO BHUTAN PACKAGES</strong> </h6></td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>Online E-Permit assistance.</td>
                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>Extras of personal nature like tips, laundry, and others.</td>
                        </tr>
                        <tr>
                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>Transfer all on a Sharing Basis Vehicle.</td>
                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>Travel Insurance (Mandatory for Bhutan travel).</td>
                        </tr>
                        <tr>
                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>Sustainable Development Fees (SDF)Rs.1,200/Per adult / Per Night.</td>
                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>Insurance, Medical & Emergency rescue costs.</td>
                        </tr>
                        <tr>
                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>Pick up & Drop at Bagdogra Airport.</td>
                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>Does not include Hard
                                drinks liquor or soft drinks/juices.</td>
                        </tr>
                        <tr>
                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>Unlimited Breakfast, Lunch, Dinner in Buffet.</td>
                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>Does not include Entrance
                                Fees at Monasteries / Monuments /Museums etc.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span><b className="red-dot">1 Day *Special Lunch LM Paro in 5 Star Property.</b></td>
                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>Air Fare / Train Tickets.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>Sightseeing as per the itinerary point-to-a-point basis.</td>
                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>Additional sightseeing or extra usage of vehicles, other than those mentioned in the itinerary.</td>
                        </tr>
                        <tr>
                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>One bottle of Mineral Water per head per day.</td>
                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>Any cost arising due to natural calamities like landslides, road blockage, political disturbances (strikes), etc (to be borne by the client, which is directly payable on the spot).</td>
                        </tr>
                        <tr>
                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>Professional English-Speaking Guide.</td>
                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>Any increase in taxes or fuel price, leading to an increase in cost on surface transportation & land arrangements, which may come into effect prior to departure.</td>
                        </tr>
                        <tr>
                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>One tourist Sim Card on Arrival (Per Family).</td>
                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>Does not include any
                                Lunch & Evening Tea/snacks.</td>
                        </tr>
                        <tr>
                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>One time Lataguri Jeep Safari.</td>
                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>Anything which are not mentioned in inclusion list.
                               </td>
                        </tr>
                    </tbody>
                </table>
            {/* <h4 className='tomato'><u>BHUTAN TOUR PACKAGE INCLUSION</u></h4>
                <ul>
                   
              
                  
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;One time Lataguri Jeep Safari.</li>
                </ul><br/><br/>
                <h4 className='tomato'><u>BHUTAN PACKAGE TOUR EXCLUSION</u></h4>
                <ul>
                  
                </ul> */}
            </>
        );
    }
}

export default InclusionExclusion_bagdogra_group;