import React, { Component } from 'react';
import MainHeader from '../../../components/common/MainHeader';
import FooterDesktop from '../../../components/common/FooterDesktop';
import Meghalaya_7N_8D_Package from '../../../components/package/Meghalaya/Meghalaya_7N_8D_Package';
import { Helmet } from "react-helmet";

class Meghalaya_7N_8D_Page extends Component {
    constructor() {
        super();
        this.state = {
            TrendingPackagesData: [],
            ReviewersData: [],
        }
    }

    componentDidMount() {
        window.scroll(0, 0);
        const TrendingPackageDataList = [
            {
                Title: "Untouched Manipur",
                ImageName: "5N-6D-manipur.webp",
                AllternateText: "...",
                Description:"2N Kohima | 1N Imphal | 1N Moreh | 1N Imphal | Private Transfer | Deluxe Hotel  ",
                PricePerNight: "24,990",
                NoOfTours: 47,
                NoOfDeparture: 40,
                Include: "EX-GAU",
                MutedText: "*Per head / Min 6 Pax"
            },
            {
                Title: "Peaceful Meghalaya",
                ImageName: "6N-7D-meghalaya.webp",
                AllternateText: "...",
                Description: "2N Kaziranga | 3N Shillong | 1N Guwahati | Private Transfer | Deluxe Hotel",
                PricePerNight: "22,800",
                NoOfTours: 43,
                NoOfDeparture: 38,
                Include: "EX-GAU",
                MutedText: "*Per head / Min 6 Pax"
            },
            {
                Title: "Unexplored Manipur",
                ImageName: "7N-8D-meghalaya.webp",
                AllternateText: "...",
                Description: "2N Kaziranga | 2N Kohima | 3N Imphal | Private Transfer | Deluxe Hotel",
                PricePerNight: "27,900",
                NoOfTours: 44,
                NoOfDeparture: 37,
                Include: "EX-GAU",
                MutedText: "*Per head / Min 6 Pax"
            },

            {
                Title: "Extended Meghalaya",
                ImageName: "8N-9D-meghalaya.webp",
                AllternateText: "...",
                Description: "2N Kaziranga | 3N Shillong | 1N Cherrapunjee...more | Private Transfer | Deluxe Hotel",
                PricePerNight: "33,700",
                NoOfTours: 46,
                NoOfDeparture: 39,
                Include: "EX-GAU",
                MutedText: "*Per head / Min 6 Pax"
            },
            {
                Title: "Glimpses of North East",
                ImageName: "10N-11D-sangti-vally.webp",
                AllternateText: "...",
                Description: "2N Guwahati | 1N Kaziranga | 1N Bhalukpong...more | Private Transfer | Deluxe Hotel",
                PricePerNight: "28,300",
                NoOfTours: 44,
                NoOfDeparture: 37,
                Include: "EX-GAU",
                MutedText: "*Per head / Min 6 Pax"
            },
            {
                Title: "Extended North East",
                ImageName: "11N-12D-madhuri.webp",
                AllternateText: "...",
                Description: "2N Guwahati | 3N Shillong | 1N Kaziranga...more | Private Transfer | Deluxe Hotel",
                PricePerNight: "53,899",
                NoOfTours: 47,
                NoOfDeparture: 40,
                Include: "EX-GAU",
                MutedText: "*Per head / Min 6 Pax"
            }

        ];

        const reviewersDataList = [
            {
                Name: "Dr Amutha",
                ImageName: "Dr-Amutha.webp",
                AllternateText: "...",
                Location: "@Bengaluru",
                Comment: "Amazing place and the arrangements made by Adorable Vacation were superb with great stay at amazing places. The entire crew members were very helpful and we really enjoyed the entire trip with the help of team adorable.",
            },
            {
                Name: "Haneen Ali",
                ImageName: "Haneen-ALi.webp",
                AllternateText: "...",
                Location: "@Hyderabad",
                Comment: "The overall experience of tour is good, the operations head Ms Anushka is very helpful, expect the vehicle issue The places visited is Guwhati, Shillong and Cherrapungi Organisation – Adorable Vacation LLP.",
            },
            {
                Name: "Gopika Rajesh",
                ImageName: "Gopika-rajesh.webp",
                AllternateText: "...",
                Location: "@Pune",
                Comment: "We had a fabulous trip to the north east in the third week of June 2023. Our trip was planned for us by Adorable Vacation LLP based in Calcutta. I had their reference from a friend in Delhi.",
            },
            {
                Name: "Navya Khandelwal",
                ImageName: "Navya-Khandelwal.webp",
                AllternateText: "...",
                Location: "@Mumbai",
                Comment: "We visited Gauhati, Shillong and Cherrapunji. While Planning the itenary Ms Sonali Adorbale Vacation was extremely patient in answering every small query regarding the choice of itenary.",
            },
        ]

        this.setState({
            TrendingPackagesData: TrendingPackageDataList,
            ReviewersData: reviewersDataList
        })
    }

    render() {
        return (
            <>
              <Helmet>
                    <title>Assam Meghalaya 7N 8D Tour Itinerary</title>
                </Helmet>
                <MainHeader />
                <Meghalaya_7N_8D_Package
                    TrendingPackagesData={this.state.TrendingPackagesData}
                    ReviewersData={this.state.ReviewersData}
                />
                <FooterDesktop />
            </>
        );
    }
}

export default Meghalaya_7N_8D_Page;