import React, { Component } from 'react';
import MainHeader from '../../../components/common/MainHeader';
import FooterDesktop from '../../../components/common/FooterDesktop_bhutan';
import AhemdabadToBhutanPackage from '../../../components/package/Bhutan/AhemdabadToBhutanPackage';
import { Helmet } from "react-helmet";

class AhemdabadToBhutanPage extends Component {
    constructor() {
        super();
        this.state = {
            HotPackagesData: [],
            ThemePackagesData: [],
            HotelsData: [],
            TrendingBhutanDataList: [],
            ReviewersData: [],

        }
    }


    componentDidMount() {
        window.scroll(0, 0);
        const hotPackageDataList = [
            {
                Title: "Blissful Bhutan",
                ImageName: "4N-5D-Bhutan-Tour.webp",
                AllternateText: "...",
                Description: "2N Thimphu | 1N Punakha | 1N Paro | Free Sim Card | E-Permit",
                PricePerNight: "22,599",
                NoOfTours: 42,
                NoOfDeparture: 35,
                SDFInclude: "SDF Inc.",
                MutedText: "*Per head / Min 6 Pax",
                book: "Book Now",
                Url: '/4N-5D-FIFO-package'
            },
            {
                Title: "Untouched Bhutan",
                ImageName: "5N-6D-Bhutan-Tour.webp",
                AllternateText: "...",
                Description: "2N Thimphu | 1N Punakha | 2N Paro | Free Sim Card | E-Permit",
                PricePerNight: "27,899",
                NoOfTours: 45,
                NoOfDeparture: 39,
                SDFInclude: "SDF Inc.",
                MutedText: "*Per head / Min 6 Pax",
                book: "Book Now",
                Url: '/5N-6D-FIFO-package'

            },
            {
                Title: "Explore Bhutan",
                ImageName: "6N-7D-Bhutan-Tour.webp",
                AllternateText: "...",
                Description: "2N Thimphu | 2N Punakha | 2N Paro | Free Sim Card | E-Permit",
                PricePerNight: "32,999",
                NoOfTours: 43,
                NoOfDeparture: 38,
                SDFInclude: "SDF Inc.",
                MutedText: "*Per head / Min 6 Pax",
                book: "Book Now",
                Url: '/6N-7D-FIFO-package'
            },

            {
                Title: "Excape Bhutan",
                ImageName: "7N-8D-Bhutan-Tour.webp",
                AllternateText: "...",
                Description: "2N Thimphu | 2N Punakha | 3N Paro | Free Sim Card | E-Permit",
                PricePerNight: "37,999",
                NoOfTours: 47,
                NoOfDeparture: 40,
                SDFInclude: "SDF Inc.",
                MutedText: "*Per head / Min 6 Pax",
                book: "Book Now",
                Url: '/7N-8D-FIFO-package'
            },
            {
                Title: "Pleasing Bhutan",
                ImageName: "8N-9D-Bhutan-Tour.webp",
                AllternateText: "...",
                Description: "3N Thimphu | 2N Punakha | 3N Paro | Free Sim Card | E-Permit",
                PricePerNight: "43,499",
                NoOfTours: 44,
                NoOfDeparture: 37,
                SDFInclude: "SDF Inc.",
                MutedText: "*Per head / Min 6 Pax",
                book: "Book Now",
                Url: '/8N-9D-FIFO-package'
            },
            {
                Title: "Fascinating Bhutan",
                ImageName: "10N-11D-Bhutan-Tour.webp",
                AllternateText: "...",
                Description: "2N Thimphu | 2N Bumthang...more | Free Sim Card | E-Permit",
                PricePerNight: "53,899",
                NoOfTours: 46,
                NoOfDeparture: 39,
                SDFInclude: "SDF Inc.",
                MutedText: "*Per head / Min 6 Pax",
                book: "Book Now",
                Url: '/10N-11D-FIFO-package'
            }

        ];

        const themePackageDataList = [
            {
                Title: "Bhutan Honeymoon",
                ImageName: "Bhutan-Honeymoon-Package.webp",
                AllternateText: "...",
                Description: "Bhutan Package is your ultimate destination. 7N/8D is the decent duration to explore the relation between Nature & Better Half.",
            },
            {
                Title: "Wedding at Bhutan",
                ImageName: "Destination-Wedding-at-Bhutan.webp",
                AllternateText: "...",
                Description: "Make your iconic marriage day very very special at Bhutan. Marriage like VIP, execute a super destination wedding 'LIKE NEVER BEFORE'.available.",
            },
            {
                Title: "Bhutan Solo Trip (Woman)",
                ImageName: "Bhutan-Solo-Trip-Woman-Special.webp",
                AllternateText: "...",
                Description: "Love to explore everything by yourself ? Then Bhutan is the god gifted gem for you. Numerous things are present to explore,experience & recreate.",
            },
            {
                Title: "Bhutan Adventure Trip",
                ImageName: "Bhutan-Adventure-Packages.webp",
                AllternateText: "...",
                Description: "After NEPAL, Bhutan is an amazing counter destiation for Adventure lover. Explore with thrilling activities - River Rafting, Paragliding & Kayaking etc.",
            },
            {
                Title: "Bhutan Bike Trip",
                ImageName: "Bhutan-Bike-Package-Tour.webp",
                AllternateText: "...",
                Description: "Throughout the Bhutan bike trip, you will pass through curvy roads with most picturesque surroundings with high eastern Himalayan snow-capped peaks.",
            },
            {
                Title: "Pilgrimage of Bhutan",
                ImageName: "Bhutan-Monastery-Packages.webp",
                AllternateText: "...",
                Description: "Bhutan is the only country in the world that officially practices Mahayana Buddhism or Vajrayana Buddhism. Buddhism is a way of life in Bhutan.",
            },
            {
                Title: "Bhutan Snowman Trek",
                ImageName: "Bhutan-Snowman-Trek.webp",
                AllternateText: "...",
                Description: "The Snowman Trek is the longest hiking trail of Bhutan that extends from Laya to the high Bhutanese Himalayas, covering up the northern Kingdom.",
            },
            {
                Title: "Bhutan Foreigners Package",
                ImageName: "Bhutan-Forigners-Package.webp",
                AllternateText: "...",
                Description: "As a foreigner, living in Bhutan can be a rewarding and fascinating experience,a small landlocked country in the eastern Himalayas, is known for its unique culture.",
            },

        ]

        const reviewersDataList = [
            {
                Name: "Raj Patel",
                ImageName: "raj.webp",
                AllternateText: "...",
                Location: "@Ahemdabad",
                Comment: "I recently had the pleasure of booking a tour with Adorable Tours to see Bhutan, and I must say,it was one of the most amazing experiences of my life. From first contact to the end of our trip, every aspect of the tour was handled with professionalism and care.What I loved most about this company was how they listened to our needs and adjusted the itinerary to meet them, always with a smile and willingness to make us happy. They were always accessible through WhatsApp, which made communication so much easier and convenient, especially since we were traveling with our family.",
            },
            {
                Name: "Amit Desai",
                ImageName: "amit.webp",
                AllternateText: "...",
                Location: "@Ahemdabad",
                Comment: "I had a great experience visiting Bhutan with their assistance. Had a memorable recent trip to Bhutan. Recommended to try them at least once for your next trip.",
            },
            {
                Name: "Anita Shah",
                ImageName: "anita.webp",
                AllternateText: "...",
                Location: "@Ahemdabad",
                Comment: "I had booked my Bhutan trip through them. Mr. Soumalya had helped us with all the bookings. The hotels booked were really good, the driver assigned was helpful and really humourous. We have enjoyed the trip to the most. I cannot think of anything negative that i can actually state in this review. Everything was handled exceptionally well from the start to the last day. We never had to call the agency for any follow up during the trip.",
            },
            {
                Name: "Mohan Patel",
                ImageName: "mohon_patel.webp",
                AllternateText: "...",
                Location: "@Ahemdabad",
                Comment: "The overall experience with the team was very good. Mr Soumya was very prompt and well guided through the process. Mr Soumalya is supreme leader. We have really enjoyed our honeymoon trip to Bhutan. Keep up your good and valued service.",
            },
        ];

        const hotelDataList = [
            {
                HotelName: 'Hotel Ugyen, Thimpu',
                ImageName: "hotel-ugyen.webp",
                AllternateText: "...",
                HotelStar: '★★★',
                RatingBoxStar: '★',
                RatingValue: 4.9,
                Reviews: '50 Reviews'
            },
            {
                HotelName: 'Amodhara, Thimphu',
                ImageName: "amodhara-2.webp",
                AllternateText: "...",
                HotelStar: '★★★',
                RatingBoxStar: '★',
                RatingValue: 4.8,
                Reviews: '47 Reviews'
            },
            {
                HotelName: 'White Dragon, Punakha',
                ImageName: "white-dragon.webp",
                AllternateText: "...",
                HotelStar: '★★★',
                RatingBoxStar: '★',
                RatingValue: 4.7,
                Reviews: '46 Reviews'
            },
            {
                HotelName: 'Pema karpo, Punakha',
                ImageName: "pema-karpo.webp",
                AllternateText: "...",
                HotelStar: '★★★',
                RatingBoxStar: '★',
                RatingValue: 4.6,
                Reviews: '48 Reviews'
            },
            {
                HotelName: 'Layhuel, Paro',
                ImageName: "lehul.webp",
                AllternateText: "...",
                HotelStar: '★★★',
                RatingBoxStar: '★',
                RatingValue: 4.8,
                Reviews: '49 Reviews'
            },
            {
                HotelName: 'Tshringma, Paro',
                ImageName: "Tsherim-resort.webp",
                AllternateText: "...",
                HotelStar: '★★★',
                RatingBoxStar: '★',
                RatingValue: 4.7,
                Reviews: '43 Reviews'
            },
            {
                HotelName: 'Green Touch, Lataguri',
                ImageName: "green-touch.webp",
                AllternateText: "...",
                HotelStar: '★★★',
                RatingBoxStar: '★',
                RatingValue: 4.6,
                Reviews: '44 Reviews'
            },
            {
                HotelName: 'Suncity, Lataguri',
                ImageName: "suncity.webp",
                AllternateText: "...",
                HotelStar: '★★★',
                RatingBoxStar: '★',
                RatingValue: 4.8,
                Reviews: '40 Reviews'
            }
        ];

        const trendingBhutanDataList = [
            {
                Title: "Blissful Bhutan",
                ImageName: "6N-7D-Bhutan-Tour1.webp",
                AllternateText: "...",
                Description: "2N Thimphu | 2N Punakha | 2N Paro | Free Sim Card | E-Permit",
                PricePerNight: "31,899",
                NoOfTours: 43,
                NoOfDeparture: 38
            },
            {
                Title: "Untouched Bhutan",
                ImageName: "7N-8D-Bhutan-Tour1.webp ",
                AllternateText: "...",
                Description: "2N Thimphu | 2N Punakha | 3N Paro | Free Sim Card | E-Permit",
                PricePerNight: "37,599",
                NoOfTours: 47,
                NoOfDeparture: 40
            },
            {
                Title: "Explore Bhutan",
                ImageName: "9N-10D-Bhutan-Tour1.webp",
                AllternateText: "...",
                Description: "2N Thimphu | 2N Punakha | 2N Paro | Free Sim Card | E-Permit",
                PricePerNight: "48,899",
                NoOfTours: 44,
                NoOfDeparture: 37
            },
            {
                Title: "Excape Bhutan",
                ImageName: "12N-13D-Bhutan-Tour1.webp",
                AllternateText: "...",
                Description: "2N Thimphu | 2N Punakha | 3N Paro | Free Sim Card | E-Permit",
                PricePerNight: "76,500",
                NoOfTours: 46,
                NoOfDeparture: 39
            }


        ];


        this.setState({
            HotPackagesData: hotPackageDataList,
            ThemePackagesData: themePackageDataList,
            HotelsData: hotelDataList,
            TrendingBhutanDataList: trendingBhutanDataList,
            ReviewersData: reviewersDataList
        })
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>Bhutan Tour from Ahemdabad</title>
                </Helmet>

                <MainHeader />

                <AhemdabadToBhutanPackage
                    HotPackagesData={this.state.HotPackagesData}
                    ThemePackagesData={this.state.ThemePackagesData}
                    HotelsData={this.state.HotelsData}
                    TrendingBhutanDataList={this.state.TrendingBhutanDataList}
                    ReviewersData={this.state.ReviewersData}
                />

                <FooterDesktop />
            </>
        )
    }
}

export default AhemdabadToBhutanPage;