import React, { Component } from 'react';

class SocialMediaCounter extends Component {
    render() {
        return (
            <>
                <div className="counters bgImgCounter py-5">
                    <div className="container onlyCounterBG">
                        <div>
                            <i className="fa fa-youtube fa-3x"></i>
                            <div className="counter" data-target="60000">60000</div>
                            <h3>Subscribers</h3>
                        </div>
                        <div>
                            <i className="fa fa-twitter fa-3x"></i>
                            <div className="counter" data-target="15000">15000</div>
                            <h3>Followers</h3>
                        </div>
                        <div>
                            <i className="fa fa-facebook fa-3x"></i>
                            <div className="counter" data-target="9000">9000</div>
                            <h3>Likes</h3>
                        </div>
                        <div>
                            <i className="fa fa-linkedin fa-3x"></i>
                            <div className="counter" data-target="5000">5000</div>
                            <h3>Connections</h3>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default SocialMediaCounter;