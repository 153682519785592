import React, { Component } from 'react';
import ApiURL from "../../api/ApiURL";
// import required modules
import Slider from 'react-slick';

class Testimonial extends Component {
    constructor(props) {
        super(props);
        this.state = {
            TestimonialSliderData: []
        }
    }

    componentDidMount() {
        const testimonialSliderDataList = [
            {
                ClientImageName: "Krishna-K.webp",
                AlternateText: "...",
                Feedback: "Very good objection handling. Faced no issues with the internal travels. Hotel locations were very much appropriate. Timely pick up and drop service along with great food. Overall a memorable journey with Adorable Vacation.",
                ClientName: "Somak Chowdhury",
                Designation: "Branch manager (Axis Bank)",
                Rating: 5
            },
            {
                ClientImageName: "masud.webp",
                AlternateText: "...",
                Feedback: "Couple of days back we returned from a trip organised by Adorable Vacation to North Sikkim and Jaldapara Forest. We also had friends from Bangladesh who accompanied us in this trip. The trip was planned and executed very well by Mr Akash and his team members. Special thanks goes to Ms Anushka who was in constant touch with us during the program. We all are very happy for the warm way this trip was done. I am looking forward to more such trips in the near future with them.",
                ClientName: "Masud Ali",
                Designation: "Doctor from Dhaka (Bangladesh)",
                Rating: 5
            },
             {
                ClientImageName: "rikiya_basu.webp",
                AlternateText: "...",
                Feedback: "Had the most comfortable stay in Bhutan, thanks to adorable vacation LLp. Our guide Mr. Gyeltshen and our driver were extremely helpful and nice. They were always ready to help us out with everything. I would highly recommend Adorable Vacation LLP to everyone and would love to book our next trip with them too. Thank you team for taking care of us.",
                ClientName: "Rikhiya Sawalkar",
                Designation: "PWC Mumbai",
                Rating: 5
            },
            {
                ClientImageName: "rajani_ch.webp",
                AlternateText: "...",
                Feedback: "Wonderful experience! Decent hotels and transport facility! Guide was not upto the mark but they couldn't do much as there is a scarcity of guides in Bhutan as of now! Ask for best guide available before making the booking! 5 star for Ganesh and Snehita for handling bookings and responding efficiently.",
                ClientName: "Rajani Ch.",
                Designation: "Product Manager",
                Rating: 5
            },           
        ];

        this.setState({ TestimonialSliderData: testimonialSliderDataList })
    }

    render() {

        const settings = {
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 5000,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const sliderList = this.state.TestimonialSliderData;
        const MyView = sliderList.length > 0 ?
            (sliderList.map((sliderItem, i) => {
                const sliderImgURL = ApiURL.TestimonialClientImageURL + "/" + sliderItem.ClientImageName;
                return (<div className="card" key={i}>
                    <div className="card-body m-3">
                        <div className="row">
                            <div className="col-lg-4 d-flex justify-content-center align-items-center mb-4 mb-lg-0">
                                <img src={sliderImgURL}
                                    className="rounded-circle img-fluid shadow-1"
                                    alt={sliderItem.AlternateText} width="200" height="200"
                                />
                            </div>
                            <div className="col-lg-8 pull-left">
                                <p className="text-muted fw-light mb-4">{sliderItem.Feedback}</p>
                                <p className="fw-bold lead mb-2"><strong>{sliderItem.ClientName}</strong></p>
                                <p className="fw-bold text-muted mb-0">{sliderItem.Designation}</p>
                            </div>
                        </div>
                    </div>
                </div>
                )
            })) :
            (<div></div>)

        return (
            <>
                <div className="container-fluid text-lg-start shadow-1-strong rounded">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="site-heading pt-3 text-center">
                                <h2>Clients <span>Testimonials</span></h2>                                
                            </div>
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center">
                        <div className="col-md-10">
                            <Slider {...settings}>
                                {MyView}
                            </Slider>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Testimonial;