import React, { Component } from "react";
import BookingBox from "../BookingBox";
import Slider from "react-slick";
import ApiURL from "../../../api/ApiURL";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InclusionExclusion from "../Thailand/InclusionExclusion_BangkokPattaya_8N_9D";

class BangkokPattaya_8N_9D_Package extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customActiveTab: 'tab1',
      daywiseActiveTab: 'day1',
      currentPage: 1,
    };
  }

  handleCustomTabClick = (tab) => {
    this.setState({
      customActiveTab: tab,
    });
  };

  handleDayWiseTabClick = (tab) => {
    this.setState({
      daywiseActiveTab: tab,
    });
  };
  handleClick = () => {
    const currentUrl = window.location.href;
    const pageTitle = document.title;
    const message = `Hi, Please send about the Tour Package details "${pageTitle}"`;

    const whatsappLink = `https://api.whatsapp.com/send?phone=+919163676037&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
    window.open(whatsappLink, '_blank');
  };
  handlePageChange = (direction) => {
    this.setState((prevState) => {
      const newPage = prevState.currentPage + direction;
      return { currentPage: newPage };
    });
  };
  render() {
    const rows = [
      { date: '24th Sept - 30th Sept', availability: 'Available', price: '84,500' },
      { date: '01st Oct - 07th Oct', availability: 'Available', price: '84,500' },
      { date: '04th Oct - 10th Oct', availability: 'Available', price: '84,500' },
      { date: '08th Oct - 14th Oct', availability: 'Available', price: '84,500' },
      { date: '11th Oct - 17th Oct', availability: 'Available', price: '84,500' },
      { date: '15th Oct - 21st Oct', availability: 'Available', price: '84,500' },
      { date: '18th Oct - 24th Oct', availability: 'Available', price: '84,500' },
      { date: '22nd Oct - 28th Oct', availability: 'Available', price: '84,500' },
      { date: '25th Oct - 31st Oct', availability: 'Available', price: '84,500' },
      { date: '29th Oct - 04th Nov', availability: 'Available', price: '84,500' },
      { date: '1st Nov - 07th Nov', availability: 'Available', price: '84,500' },
      { date: '05th Nov - 11th Nov', availability: 'Available', price: '84,500' },
    ];

    const rowsPerPage = 6;
    const totalPages = Math.ceil(rows.length / rowsPerPage);
    const currentRows = rows.slice((this.state.currentPage - 1) * rowsPerPage, this.state.currentPage * rowsPerPage);
    const { customActiveTab, daywiseActiveTab } = this.state;
    const packageTopBannerImageURL = ApiURL.BangkokPattaya_8N_9D_Package + "/Thailnd-Tour-Package.webp";
    const packageTopBanner2ImageURL = ApiURL.BangkokPattaya_8N_9D_Package + "/Thailnd-Tour-Packages.webp";
    const sightseen1ImageURL = ApiURL.BangkokPattaya_8N_9D_Package + "/THAILAND-BANGKOK.webp";
    const sightseen2ImageURL = ApiURL.BangkokPattaya_8N_9D_Package + "/THAILAND-KRABI.webp";
    const sightseen3ImageURL = ApiURL.BangkokPattaya_8N_9D_Package + "/THAILAND-PATTAYA.webp";
    const sightseen4ImageURL = ApiURL.BangkokPattaya_8N_9D_Package + "/BANGKOK-Wat-Arun.webp";
    const sightseen5ImageURL = ApiURL.BangkokPattaya_8N_9D_Package + "/PHUKET-PHI-PHI-ISLAND.webp";
    const sightseen6ImageURL = ApiURL.BangkokPattaya_8N_9D_Package + "/PATTAYA-Coral-Island.webp";
    const sightseen7ImageURL = ApiURL.BangkokPattaya_8N_9D_Package + "/Bangkok-pattaya-packages.webp";
    const sightseen8ImageURL = ApiURL.BangkokPattaya_8N_9D_Package + "/BANGKOK-TOUR-PACKAGE.webp";
    const sightseen9ImageURL = ApiURL.BangkokPattaya_8N_9D_Package + "/THAILAND-TOUR-PACKAGE.webp";
    //Hotel set 
    const sightseen10ImageURL = ApiURL.BangkokPattaya_8N_9D_Package + "/Bangkok-pattaya-luxury-stay.webp";
    const sightseen11ImageURL = ApiURL.BangkokPattaya_8N_9D_Package + "/Bangkok-phuket-karabi-luxury-stay.webp";
    const sightseen12ImageURL = ApiURL.BangkokPattaya_8N_9D_Package + "/Bangkok-pattaya-tour-packages-luxury-stay.webp";
    const sightseen13ImageURL = ApiURL.BangkokPattaya_8N_9D_Package + "/Thailand-tour-packages.webp";
    const sightseen14ImageURL = ApiURL.BangkokPattaya_8N_9D_Package + "/Bangkok-packages-tour-from-kolkata.webp";
    const sightseen15ImageURL = ApiURL.BangkokPattaya_8N_9D_Package + "/THAILAND-HOLIDAY-TOUR-PACKAGE-FROM-KOLKATA.webp";
    const sightseen16ImageURL = ApiURL.BangkokPattaya_8N_9D_Package + "/SHALINI-PAUL-GUEST.webp";
    const sightseen17ImageURL = ApiURL.BangkokPattaya_8N_9D_Package + "/SOMAK-CHOWDHURY-GUEST.webp";
    const sightseen18ImageURL = ApiURL.BangkokPattaya_8N_9D_Package + "/SOURAV-DAS-Guest.webp";
    //Itenary wise picture start
    const navDay1ImageURL = ApiURL.BangkokPattaya_8N_9D_Package + "/Phuket-Krabi-tour-package-from-Kolkata.webp";
    const navDay2ImageURL = ApiURL.BangkokPattaya_8N_9D_Package + "/Phi-Phi-Island-day-trip.webp";
    const navDay3ImageURL = ApiURL.BangkokPattaya_8N_9D_Package + "/KRABI-TOUR-PACKAGE.webp";
    const navDay4ImageURL = ApiURL.BangkokPattaya_8N_9D_Package + "/FOUR-ISLAND-TOUR-KRABI.webp";
    const navDay5ImageURL = ApiURL.BangkokPattaya_8N_9D_Package + "/Bangkok-Pattaya-tour-packages-from-Kolkata.webp";
    const navDay6ImageURL = ApiURL.BangkokPattaya_8N_9D_Package + "/PATTAYA-CORAL-ISLAND-TOUR.webp";
    const navDay7ImageURL = ApiURL.BangkokPattaya_8N_9D_Package + "/BANGKOK-CITY-TOUR.webp";
    const navDay8ImageURL = ApiURL.BangkokPattaya_8N_9D_Package + "/BANGKOK-SAFARI-WORLD-MARINE-PARK-TOUR.webp";
    const navDay9ImageURL = ApiURL.BangkokPattaya_8N_9D_Package + "/Bangkok-Pattaya-Phuket-Krabi-tour.webp";
    //  Itenary wise picture end
    const settings = {
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    const reviewersDataList = this.props.ReviewersData;
    const reviewersDataView = reviewersDataList.map((dataItem, i) => {
      const reviewerImageURL =
        ApiURL.BangkokPattaya_8N_9D_Package + "/" + dataItem.ImageName;
      return (
        <div className="testimonial-box" key={i}>
          <div className="box-top">
            <div className="profile">
              <div className="profile-img">
                <img src={reviewerImageURL} alt="..." />
              </div>
              <div className="name-user">
                <strong>{dataItem.Name}</strong>
                <span>{dataItem.Location}</span>
              </div>
            </div>
            <div className="reviews">
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
            </div>
          </div>
          <div className="client-comment">
            <p>{dataItem.Comment}</p>
          </div>
        </div>
      );
    });

    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <img
              src={packageTopBannerImageURL}
              className="mobile-image2"
              alt="..."
            />
          </div>
        </div>

        <div className="container-fluid custom-container py-3">
          <div className="row">
            <div className="col-md-9">
              <div className="row">
                <div className="col-md-12">
                  <div className="container-fluid custom-container py-1">
                    <h1 className="content-desktop" style={{ fontSize: "2.0vw" }}><center><strong>Bangkok Pattaya package tour from Kolkata</strong><b>, including Phuket and Krabi</b></center></h1>
                    <h1 className="content-mobile" style={{ fontSize: "4vw" }}><center><strong>Bangkok Pattaya package tour from Kolkata</strong><b>, including Phuket and Krabi</b></center>
                      <hr />
                    </h1>
                    <div className="row">
                      <img src={packageTopBanner2ImageURL} className="mobile-image" alt="..." />
                    </div>
                    <hr />
                    <div className="d-flex flex-column flex-sm-row align-items-sm-center px-2">
                      <div className="font-lg">
                        4 Cities:{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Bangkok
                        </a>
                        &nbsp;{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Pattaya
                        </a>{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Phuket
                        </a>
                        {" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          krabi
                        </a>
                      </div>
                      <div className="reviews mx-5">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                      </div>
                    </div>
                    <p className="p-2">
                      If you are looking for a memorable <strong>Bangkok Pattaya Tour Package</strong>, you have come to the right place!
                    </p>
                    <hr />
                    <br />

                    <h2 className="content-desktop p-2" style={{ fontSize: "1.4vw" }}> LOOKING FOR A <strong>BANGKOK PATTAYA PACKAGE TOUR FROM KOLKATA </strong>? <a href='tel:9163676037' class="tomato">FOR BOOKING/QUERY ☎ CALL Mr. AKASH +91-91636-76037</a></h2>
                    <h2 className="content-mobile p-4" style={{ fontSize: "4.5vw" }}>LOOKING FOR A <strong>PACKAGE TOUR FROM KOLKATA TO BANGKOK, PATTAYA, PHUKET, AND KRABI?</strong> <a href='tel:9163676037' class="tomato">☎ CALL Mr. AKASH +91-91636-76037</a></h2>

                  </div>
                </div>
              </div>
              <br /> <br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>LARGEST <strong>BANGKOK PATTAYA TOUR OPERATOR IN KOLKATA</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>LARGEST <strong>BANGKOK PATTAYA TOUR OPERATOR IN KOLKATA</strong></h4>
                </div>
                <div className="card-body">
                  <p>Explore the enchanting beauty of <strong>Thailand with Adorable Vacation LLP's exclusive tour package from Kolkata</strong>. This meticulously <strong>Bangkok Pattaya tour package from Kolkata</strong> includes visits to the vibrant cities of Bangkok, Pattaya, Phuket, and Krabi, offering a perfect blend of culture, adventure, and relaxation. Enjoy the bustling streets of Bangkok, the nightlife in Pattaya, the serene beaches of Phuket, and the stunning landscapes of Krabi. Our all-inclusive package covers flights, accommodation, guided tours, and exciting activities to ensure you have a memorable experience. Book now to embark on an unforgettable journey with <strong>Adorable Vacation LLP</strong>.</p>
                  <p>Bangkok, the bustling capital of Thailand, is a city that seamlessly blends the old with the new. Renowned for its vibrant street life, grand palaces, and ornate temples, <strong>Bangkok Pattaya tour from Kolkata</strong> offers a sensory overload of sights, sounds, and flavors. The city's iconic landmarks, such as the opulent Grand Palace, the revered Wat Phra Kaew, and the stunning Wat Arun, reflect its rich cultural heritage. Meanwhile, its modern skyline, dotted with sleek skyscrapers and luxury hotels, showcases its rapid urban development. The famous floating markets, bustling street food stalls, and lively night markets provide endless culinary delights, making Bangkok a paradise for food lovers. Whether you're exploring its historic sites, indulging in world-class shopping, or experiencing its dynamic nightlife, Bangkok promises an unforgettable adventure at every turn.</p>
                  <p>Pattaya, located on the eastern Gulf coast of Thailand, is a vibrant city known for its stunning beaches, bustling nightlife, and a wide array of entertainment options. Originally a quiet fishing village, Pattaya has transformed into one of Thailand's premier tourist destinations, attracting visitors from around the world. The city offers a mix of traditional Thai culture and modern attractions, including water parks, amusement parks, and cultural sites like the Sanctuary of Truth. Pattaya's Walking Street is famous for its lively bars, clubs, and restaurants, making it a hotspot for nightlife enthusiasts. Additionally, nearby islands like Koh Larn provide opportunities for serene beach getaways, making Pattaya a versatile destination for both relaxation and excitement.</p>
                  <p><strong>Phuket, Thailand's largest island</strong>, is a vibrant destination renowned for its stunning beaches, crystal-clear waters, and lively nightlife. Nestled in the Andaman Sea, Phuket offers a diverse range of activities for travelers, from sunbathing on the pristine shores of Patong, Kata, and Karon beaches to exploring the lush jungles and scenic viewpoints. The island is also rich in cultural attractions, including the Big Buddha and Wat Chalong temple, offering a glimpse into the spiritual heritage of the region. Phuket's bustling markets, delectable street food, and luxurious resorts make it a versatile destination that caters to both budget travelers and those seeking opulence. Whether you're interested in adventure, relaxation, or cultural immersion, Phuket provides a perfect blend of experiences for an unforgettable getaway.</p>
                  <p>Krabi, located on Thailand's stunning Andaman Sea coastline, is a paradise known for its dramatic limestone cliffs, lush mangroves, and over 150 islands that dot its turquoise waters. This coastal province offers a tranquil escape with its pristine beaches like Railay and Ao Nang, ideal for sunbathing, rock climbing, and <strong>water sports</strong>. Beyond the beaches, Krabi is rich in natural beauty with attractions like the Emerald Pool, a thermal spring in the middle of a tropical forest, and the <strong>Tiger Cave Temple</strong>, which rewards visitors with panoramic views after a challenging climb. Whether you're seeking adventure, relaxation, or a glimpse into Thailand's cultural heritage, Krabi delivers an unforgettable experience.</p>
                  <p>In conclusion, a <strong>Bangkok Pattaya package tour from Kolkata with Phuket karabi</strong> offers an unparalleled journey through Thailand's most iconic destinations. Starting in the vibrant city of Bangkok, you'll experience the perfect blend of modernity and tradition with its bustling markets, grand palaces, and serene temples. Pattaya brings a lively contrast with its energetic nightlife, beautiful beaches, and water sports. Moving on to Phuket, you'll discover a tropical paradise renowned for its stunning beaches, vibrant nightlife, and exciting activities. Finally, Krabi captivates with its natural beauty, dramatic landscapes, and serene ambiance. This comprehensive <strong>Bangkok Pattaya tour package</strong> ensures a diverse and enriching experience, allowing you to explore Thailand's rich culture, breath-taking scenery, and exciting adventures all in one unforgettable trip.</p>

                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>
                    <strong>WHY THAILAND SHOULD BE ON YOUR TRAVEL WISH LIST:</strong>
                  </h3>
                  <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>WHY THAILAND SHOULD BE ON YOUR TRAVEL WISH LIST:</strong></h3>
                </div>
                <div className="card-body">

                  <p><b>1. Stunning Beaches:</b> Thailand is home to some of the world's most beautiful beaches, with crystal-clear waters and white sandy shores.
                  </p>
                  <p><b>2. Delicious Cuisine:</b> Thai food is renowned for its vibrant flavors and diverse dishes, from street food to fine dining.</p>
                  <p><b>3. Rich Culture and History:</b> Explore ancient temples, royal palaces, and vibrant festivals that showcase Thailand's rich cultural heritage.</p>
                  <p><b>4. Affordable Travel:</b> Thailand offers excellent value for money, with affordable accommodation, food, and transportation options.</p>
                  <p><b>5. Friendly Locals:</b> Known as the "Land of Smiles," Thailand is famous for its warm and welcoming people.</p>
                  <p><b>6. Exciting Nightlife:</b> From bustling night markets to lively bars and clubs, Thailand's nightlife is vibrant and diverse.</p>
                  <p><b>7. Adventure Activities:</b> Enjoy a wide range of activities like scuba diving, snorkeling, trekking, and zip-lining.</p>
                  <p><b>8. Shopping Paradise:</b> From luxury malls to bustling markets, Thailand offers a fantastic shopping experience for every budget.</p>
                  <p><b>9. Beautiful Nature:</b> Explore lush jungles, stunning waterfalls, and exotic wildlife in Thailand's national parks.</p>
                  <p><b>10. Relaxing Spas and Wellness:</b> Thailand is famous for its spa treatments and wellness retreats, offering relaxation and rejuvenation.</p>
                  <br />

                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen1ImageURL} alt="Sightseeing 1" />
                          <img src={sightseen2ImageURL} alt="Sightseeing 3" />
                          <img src={sightseen3ImageURL} alt="Sightseeing 2" />

                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>
                    <strong>DURATION FOR BANGKOK,PATTAYA,PHUKET, KRABI TOUR FROM KOLKATA: 8N 9D</strong>
                  </h3>
                  <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>DURATION FOR BANGKOK,PATTAYA,PHUKET, KRABI TOUR FROM KOLKATA: 8N 9D</strong></h3>
                </div>
                <div className="card-body">

                  <p><strong>Destinations included in the tour:</strong> Bangkok (2 nights), Pattaya (2 nights), Phuket (2 nights), Krabi (2 nights).
                  </p>
                  <p><strong>Pickup point for the tour with flight:</strong> Phuket International Airport <b>(HKT)</b>.</p>
                  <p><strong>Drop-off point for the tour:</strong> Suvarnabhumi International Airport <b>(BKK)</b>.</p>
                </div>
              </div>
              <br /><br />
              {/* start short itinerary */}

              <div className="card">
                <div className="card-body">
                  <table className="table table-bordered">
                    <thead>
                      <tr className="table-danger">
                        <th><b>DAY-BY-DAY ITINERARY FOR BANGKOK, PATTAYA, PHUKET, KRABI TOUR FROM KOLKATA</b></th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td
                          className="clickable"
                          data-target="panelsStayOpen-headingOne"
                        >
                          <a href="#day1" style={{ color: 'black' }}><b>DAY 1 → </b>
                            <span>
                              Arrive at Phuket Airport and transfer to your hotel.
                            </span>
                          </a>
                        </td>
                      </tr>

                      <tr>
                        <td
                          className="clickable"
                          data-target="panelsStayOpen-headingTwo"
                        >
                          <a href="#day2" style={{ color: 'black' }}><b>DAY 2 → </b>
                            <span>Take a day trip to Phi Phi Island from Phuket, including lunch.</span>
                          </a>

                        </td>
                      </tr>

                      <tr>
                        <td
                          className="clickable"
                          data-target="panelsStayOpen-headingThree"
                        >
                          <a href="#day3" style={{ color: 'black' }}><b>DAY 3 → </b>
                            <span>Transfer from your Phuket hotel to your Krabi hotel.</span>
                          </a>
                        </td>
                      </tr>

                      <tr>
                        <td
                          className="clickable"
                          data-target="panelsStayOpen-headingFour"
                        >
                          <a href="#day4" style={{ color: 'black' }}><b>DAY 4 → </b>
                            <span>Enjoy a 4-island tour in Krabi with lunch.</span>
                          </a>

                        </td>
                      </tr>

                      <tr>
                        <td
                          className="clickable"
                          data-target="panelsStayOpen-headingFive"
                        >
                          <a href="#day5" style={{ color: 'black' }}><b>DAY 5 → </b>
                            <span>Travel from Krabi to Pattaya via Bangkok Airport, and watch the Alcazar Show in the evening.</span>
                          </a>
                        </td>
                      </tr>

                      <tr>
                        <td
                          className="clickable"
                          data-target="panelsStayOpen-headingSix"
                        >
                          <a href="#day5" style={{ color: 'black' }}><b>DAY 6 → </b>
                            <span>Visit Coral Island in Pattaya with lunch, and explore Art in Paradise.</span>
                          </a>
                        </td>
                      </tr>

                      <tr>
                        <td
                          className="clickable"
                          data-target="panelsStayOpen-headingSeven"
                        >
                          <a href="#day7" style={{ color: 'black' }}><b>DAY 7 → </b>
                            <span>Transfer from Pattaya to Bangkok and go on a city tour.</span>
                          </a>

                        </td>
                      </tr>

                      <tr>
                        <td
                          className="clickable"
                          data-target="panelsStayOpen-headingTen"
                        >
                          <a href="#day8" style={{ color: 'black' }}><b>DAY 8 → </b>
                            <span>Explore Safari World and Marine Park in Bangkok with lunch.</span>
                          </a>

                        </td>
                      </tr>
                      <tr>
                        <td
                          className="clickable"
                          data-target="panelsStayOpen-headingTen"
                        >
                          <a href="#day9" style={{ color: 'black' }}><b>DAY 9 → </b>
                            <span>Depart Thailand with wonderful memories.</span>
                          </a>

                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              {/* <!--end Short description--> */}

              <br /><br />
              <div className="card">
                <div className="card-body">
                  <table className="table table-bordered">
                    <thead>
                      <tr className="table-danger">
                        <th><b>PLACES ALTITUDES COORDINATES TO NOTE </b><strong>BANGKOK PATTAYA TOUR FROM KOLKATA</strong></th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td
                          className="clickable"
                          data-target="panelsStayOpen-headingOne"
                        >
                          <span><b>Bangkok:</b> 1.5 meters above sea level, located at 13.7563° N, 100.5018° E</span>
                        </td>
                      </tr>

                      <tr>
                        <td
                          className="clickable"
                          data-target="panelsStayOpen-headingTwo">

                          <span><b>Pattaya:</b> 6.79 meters above sea level, located at 12.9236° N, 100.8825° E</span>

                        </td>
                      </tr>

                      <tr>
                        <td
                          className="clickable"
                          data-target="panelsStayOpen-headingThree"
                        >
                          <span><b>Phuket:</b> Average altitude of 51 meters, located at 7.8804° N, 98.3923° E</span>
                        </td>
                      </tr>

                      <tr>
                        <td
                          className="clickable"
                          data-target="panelsStayOpen-headingFour"
                        >
                          <span><b>Krabi:</b> Average altitude of 37 meters, located at 8.0863° N, 98.9063° E</span>

                        </td>
                      </tr>

                      <tr>
                        <td
                          className="clickable"
                          data-target="panelsStayOpen-headingFive"
                        >
                          <span><b>Koh Samui:</b> Highest point at 635 meters, located at 8.0863° N, 98.9063° E

                          </span>
                        </td>
                      </tr>

                      <tr>
                        <td
                          className="clickable"
                          data-target="panelsStayOpen-headingSix"
                        >
                          <span><b>James Bond Island:</b> 34 meters above sea level, located at 8.2745° N, 98.5012° E</span>
                        </td>
                      </tr>

                      <tr>
                        <td
                          className="clickable"
                          data-target="panelsStayOpen-headingSeven"
                        >
                          <span><b>Phi Phi Island:</b> 1 meter above sea level, located at 7.7407° N, 98.7784° E</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen4ImageURL} alt="Sightseeing 4" />
                          <img src={sightseen5ImageURL} alt="Sightseeing 5" />
                          <img src={sightseen6ImageURL} alt="Sightseeing 6" />

                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>SELECTING FLIGHTS TO CHOOSE FOR BANGKOK PATTAYA TOUR PACKAGES FROM KOLKATA</strong> WITH PHUKET KARABI</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>SELECTING FLIGHTS TO CHOOSE FOR BANGKOK PATTAYA TOUR PACKAGES FROM KOLKATA</strong> WITH PHUKET KARABI</h4>
                </div>
                <div className="card-body">
                  <h5>FLIGHT SCHEDULE FROM KOLKATA TO PHUKET</h5>

                  <br />
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr className="table-danger">
                          <th>AIR LINES</th>
                          <th>DPRT CITY</th>
                          <th>DPRT TIME</th>
                          <th>DURATION</th>
                          <th>ARV CITY</th>
                          <th>ARV TIME</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td data-title="AIR LINES">Thai Airasia
                            [FD-121]
                          </td>
                          <td data-title="DPRT CITY">KOLKATA [CCU]</td>
                          <td data-title="DPRT TIME">00:30 hrs</td>
                          <td data-title="DURATION">02h 30m</td>
                          <td data-title="ARV CITY">BANGKOK [DMK]</td>
                          <td data-title="ARV TIME">04:30</td>
                        </tr>
                        <tr>
                          <td data-title="AIR LINES">Thai Airasia [FD-3007]</td>
                          <td data-title="DPRT CITY">BANGKOK [DMK]</td>
                          <td data-title="DPRT TIME">14:40 hrs</td>
                          <td data-title="DURATION">01h 30m</td>
                          <td data-title="ARV CITY">PHUKET [HKT]</td>
                          <td data-title="ARV TIME">16:10</td>
                        </tr>
                        <tr>
                          <td data-title="AIR LINES">Air Asia [AK-62]</td>
                          <td data-title="DPRT CITY">KOLKATA [CCU]</td>
                          <td data-title="DPRT TIME">00:45 hrs</td>
                          <td data-title="DURATION">04h 00m</td>
                          <td data-title="ARV CITY">KUALA LUMPUR [KUL]</td>
                          <td data-title="ARV TIME">07:15</td>
                        </tr>
                        <tr>
                          <td data-title="AIR LINES">Air Asia [AK-824]</td>
                          <td data-title="DPRT CITY">KUALA LUMPUR [KUL]</td>
                          <td data-title="DPRT TIME">12:30 hrs</td>
                          <td data-title="DURATION">01h 30m</td>
                          <td data-title="ARV CITY">PHUKET [HKT]</td>
                          <td data-title="ARV TIME">13:00</td>
                        </tr>
                        <tr>
                          <td data-title="AIR LINES">Go Air [G8-71]</td>
                          <td data-title="DPRT CITY">KOLKATA [CCU]</td>
                          <td data-title="DPRT TIME">08:05 hrs</td>
                          <td data-title="DURATION">03h 25m</td>
                          <td data-title="ARV CITY">PHUKET [HKT]</td>
                          <td data-title="ARV TIME">13:00</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br /><br /><br />
                  <h5>FLIGHT SCHEDULE FROM KRABI TO BANGKOK</h5>
                  <br />
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr className="table-danger">
                          <th>AIR LINES</th>
                          <th>DPRT CITY</th>
                          <th>DPRT TIME</th>
                          <th>DURATION</th>
                          <th>ARV CITY</th>
                          <th>ARV TIME</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td data-title="AIR LINES">Hahn Air Systems [H1-4703]</td>
                          <td data-title="DPRT CITY">KRABI [KBV]</td>
                          <td data-title="DPRT TIME">09:15 hrs</td>
                          <td data-title="DURATION">01h 25m</td>
                          <td data-title="ARV CITY">BANGKOK [BKK]</td>
                          <td data-title="ARV TIME">10:40</td>
                        </tr>
                        <tr>
                          <td data-title="AIR LINES">Bangkok Airways [PG-262]</td>
                          <td data-title="DPRT CITY">KRABI [KBV]</td>
                          <td data-title="DPRT TIME">10:20 hrs</td>
                          <td data-title="DURATION">01h 30m</td>
                          <td data-title="ARV CITY">BANGKOK [BKK]</td>
                          <td data-title="ARV TIME">11:50</td>
                        </tr>
                        <tr>
                          <td data-title="AIR LINES">Thai Airways [TG-2242]</td>
                          <td data-title="DPRT CITY">KRABI [KBV]</td>
                          <td data-title="DPRT TIME">09:30 hrs</td>
                          <td data-title="DURATION">01h 25m</td>
                          <td data-title="ARV CITY">BANGKOK [BKK]</td>
                          <td data-title="ARV TIME">10:55</td>
                        </tr>
                        <tr>
                          <td data-title="AIR LINES">Thai Smile Airways [WE-242]</td>
                          <td data-title="DPRT CITY">KRABI [KBV]</td>
                          <td data-title="DPRT TIME">09:30 hrs</td>
                          <td data-title="DURATION">01h 25m</td>
                          <td data-title="ARV CITY">BANGKOK [BKK]</td>
                          <td data-title="ARV TIME">10:55</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>


                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>TOP ATTRACTIONS TO VISIT ON A <strong>BANGKOK PATTAYA PHUKET KRABI TOUR PACKAGE </strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>TOP ATTRACTIONS TO VISIT ON A <strong>BANGKOK PATTAYA PHUKET KRABI TOUR PACKAGE </strong></h4>
                </div>
                <div className="card-body">
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr className="table-danger">
                          <th>Bangkok</th>
                          <th>Pattaya</th>
                          <th>Phuket</th>
                          <th>Krabi</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td data-title="Bangkok">Grand Palace and Wat Phra Kaew</td>
                          <td data-title="Pattaya">Pattaya Beach</td>
                          <td data-title="Phuket">Patong Beach</td>
                          <td data-title="Krabi">Railay Beach</td>
                        </tr>
                        <tr>
                          <td data-title="Bangkok">Wat Arun (Temple of Dawn)</td>
                          <td data-title="Pattaya">Coral Island (Koh Larn)</td>
                          <td data-title="Phuket">Big Buddha</td>
                          <td data-title="Krabi">Four Islands Tour (Poda Island, Chicken Island, Tup Island, and Phra Nang Cave Beach)</td>
                        </tr>
                        <tr>
                          <td data-title="Bangkok">Chatuchak Weekend Market</td>
                          <td data-title="Pattaya">Sanctuary of Truth</td>
                          <td data-title="Phuket">Phi Phi Islands</td>
                          <td data-title="Krabi">Emerald Pool</td>
                        </tr>
                        <tr>
                          <td data-title="Bangkok">Jim Thompson House</td>
                          <td data-title="Pattaya">Walking Street</td>
                          <td data-title="Phuket">Phang Nga Bay (James Bond Island)</td>
                          <td data-title="Krabi">Tiger Cave Temple (Wat Tham Suea)</td>
                        </tr>
                        <tr>
                          <td data-title="Bangkok">Jim Thompson House</td>
                          <td data-title="Pattaya">Nong Nooch Tropical Garden</td>
                          <td data-title="Phuket">Old Phuket Town</td>
                          <td data-title="Krabi">Ao Nang Beach</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br /> <br /> <br />
                  <h5>BANGKOK IS FAMOUS FOR SEVERAL REASONS:</h5>
                  <br />
                  <ul>
                    <li><b>1.	Grand Palace:</b> The city's most iconic landmark, showcasing stunning architecture and rich history.</li><br />
                    <li><b>2.	Temples:</b> Notable temples like Wat Pho and Wat Arun, which feature beautiful and intricate designs.</li><br />
                    <li><b>3.	Vibrant Markets:</b> Places like Chatuchak Weekend Market and the floating markets, where you can shop for everything from souvenirs to local food.</li><br />
                    <li><b>4.	Street Food:</b> Delicious and diverse street food, including dishes like pad Thai, mango sticky rice, and spicy noodles.</li><br />
                    <li><b>5.	Bustling Nightlife:</b> A lively nightlife scene with numerous bars, clubs, and entertainment options.</li><br />
                    <li><b>6.	Cultural Experiences:</b> Unique cultural experiences, such as traditional Thai dance performances and tuk-tuk rides.</li>
                  </ul>
                  <br />
                  <p>These highlights make Bangkok a vibrant and exciting destination for travelers.</p>
                  <br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen7ImageURL} alt="Sightseeing 4" />
                          <img src={sightseen8ImageURL} alt="Sightseeing 5" />
                          <img src={sightseen9ImageURL} alt="Sightseeing 6" />

                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>DISTANCES BETWEEN PLACES TO NOTE FOR <strong>BANGKOK PATTAYA PHUKET KRABI TOUR PACKAGES FROM KOLKATA</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>DISTANCES BETWEEN PLACES TO NOTE FOR <strong>BANGKOK PATTAYA PHUKET KRABI TOUR PACKAGES FROM KOLKATA</strong></h4>
                </div>
                <div className="card-body">
                  <br />
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr className="table-danger">
                          <th>PLACES FROM</th>
                          <th>PLACES TO</th>
                          <th>DISTANCE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td data-title="PLACES FROM">Suvarnabhumi International Airport
                          </td>
                          <td data-title="PLACES TO">Bangkok City</td>
                          <td data-title="DISTANCE">27 min (31.5 km) via Route 7 and ทางพิเศษศรีรัช</td>
                        </tr>
                        <tr>
                          <td data-title="PLACES FROM">Suvarnabhumi International Airport</td>
                          <td data-title="PLACES TO">Pattaya (by surface)</td>
                          <td data-title="DISTANCE">1 hr 29 min (122.0 km) via Route 7</td>
                        </tr>
                        <tr>
                          <td data-title="PLACES FROM">Don Mueang International Airport</td>
                          <td data-title="PLACES TO">Bangkok City</td>
                          <td data-title="DISTANCE">26 min (27.3 km) via ทางยกระดับอุตราภิมุข</td>
                        </tr>
                        <tr>
                          <td data-title="PLACES FROM">Don Mueang International Airport</td>
                          <td data-title="PLACES TO">Pattaya City</td>
                          <td data-title="DISTANCE">1 hr 51 min (162.5 km) via Route 7</td>
                        </tr>
                        <tr>
                          <td data-title="PLACES FROM">Pattaya City Centre</td>
                          <td data-title="PLACES TO">Walking Street</td>
                          <td data-title="DISTANCE">7 min (2.9 km) via ถ. พัทยา สาย 3 and ถนน พัทยาใต้</td>
                        </tr>
                        <tr>
                          <td data-title="PLACES FROM">Bangkok City</td>
                          <td data-title="PLACES TO">MBK Center</td>
                          <td data-title="DISTANCE">10 min (4.8 km) via ถ. บำรุงเมือง</td>
                        </tr>
                        <tr>
                          <td data-title="PLACES FROM">Bangkok</td>
                          <td data-title="PLACES TO">Pattaya City</td>
                          <td data-title="DISTANCE">By Road - 1 hr 43 min (149.2 km) via Route 7</td>
                        </tr>
                        <tr>
                          <td data-title="PLACES FROM">Bangkok</td>
                          <td data-title="PLACES TO">Chiang Mai</td>
                          <td data-title="DISTANCE">By Flight - 594 km</td>
                        </tr>
                        <tr>
                          <td data-title="PLACES FROM">Bangkok</td>
                          <td data-title="PLACES TO">Koh Samui</td>
                          <td data-title="DISTANCE">By Flight - 466 km</td>
                        </tr>
                        <tr>
                          <td data-title="PLACES FROM">Bangkok</td>
                          <td data-title="PLACES TO">Phuket</td>
                          <td data-title="DISTANCE">By Flight - 688 km</td>
                        </tr>
                        <tr>
                          <td data-title="PLACES FROM">Phuket</td>
                          <td data-title="PLACES TO">Krabi</td>
                          <td data-title="DISTANCE">By Flight - 155 km (35 min)</td>
                        </tr>
                        <tr>
                          <td data-title="PLACES FROM">Phuket</td>
                          <td data-title="PLACES TO">Krabi</td>
                          <td data-title="DISTANCE">By Road - 2 hr 22 min (164.5 km) via Route 402 and Route 4</td>
                        </tr>
                        <tr>
                          <td data-title="PLACES FROM">Phuket</td>
                          <td data-title="PLACES TO">Phi Phi Island</td>
                          <td data-title="DISTANCE">By Boat - 2 hr (46 km)</td>
                        </tr>
                        <tr>
                          <td data-title="PLACES FROM">Krabi</td>
                          <td data-title="PLACES TO">Phi Phi Island</td>
                          <td data-title="DISTANCE">By Boat - 2 hr (45 km)</td>
                        </tr>
                        <tr>
                          <td data-title="PLACES FROM">Phi Phi Island</td>
                          <td data-title="PLACES TO">Maya Bay Island</td>
                          <td data-title="DISTANCE">7.1 km</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>BEST AREAS TO STAY DURING BANGKOK PATTAYA TOUR FROM KOLKATA:</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>BEST AREAS TO STAY DURING BANGKOK PATTAYA TOUR FROM KOLKATA:</strong></h4>
                </div>
                <div className="card-body">

                  <p>For a Bangkok and Pattaya package tour, here are some top areas to choose for your stay, along with options for 3, 4, and 5-star hotels provided by Adorable Vacation LLP:</p>
                  <br />

                  <h5><b>1. BANGKOK:</b></h5>
                  <br />
                  <p><b>•	Sukhumvit:</b> A bustling area with easy access to shopping, dining, and nightlife. Recommended hotels:</p>

                  <li><b>3-Star:</b> Ibis Bangkok Sukhumvit 4</li><br />
                  <li><b>4-Star:</b> Radisson Blu Plaza Bangkok</li><br />
                  <li><b>5-Star:</b> The Landmark Bangkok</li>

                  <br />
                  <p><b>•	Riverside:</b> Offers stunning views of the Chao Phraya River and a tranquil atmosphere. Recommended hotels:</p>

                  <li><b>3-Star:</b> Ibis Bangkok Riverside</li><br />
                  <li><b>4-Star:</b> Chatrium Hotel Riverside Bangkok</li><br />
                  <li><b>5-Star:</b> Mandarin Oriental Bangkok</li>

                  <br />
                  <p><b>•	Silom:</b> A business and entertainment hub with vibrant nightlife and shopping. Recommended hotels:</p>

                  <li><b>3-Star:</b> Amara Bangkok Hotel</li><br />
                  <li><b>4-Star:</b> The Siam Heritage Hotel</li><br />
                  <li><b>5-Star:</b> Lebua at State Tower</li>
                  <br /> <br />
                  <h5><b>2. PATTAYA:</b></h5>
                  <br />
                  <p><b>•	North Pattaya:</b> A quieter area with beautiful beaches and upscale resorts. Recommended hotels:</p>
                  <li><b>3-Star:</b> The Zign Hotel</li><br />
                  <li><b>4-Star:</b> Holiday Inn Pattaya</li><br />
                  <li><b>5-Star:</b> Dusit Thani Pattaya</li>
                  <br />
                  <p><b>•	Central Pattaya:</b> Ideal for those who enjoy shopping, dining, and nightlife. Recommended hotels:</p>
                  <li><b>3-Star:</b> Ibis Pattaya</li><br />
                  <li><b>4-Star:</b> Holiday Inn Resort Pattaya</li><br />
                  <li><b>5-Star:</b> Hilton Pattaya</li>
                  <br />
                  <p><b>•	Jomtien Beach:</b> Known for its relaxed atmosphere and less crowded beaches. Recommended hotels:</p>
                  <li><b>3-Star:</b> Jomtien Palm Beach Hotel & Resort</li><br />
                  <li><b>4-Star:</b> Novotel Pattaya Resort</li><br />
                  <li><b>5-Star:</b> Dusit Thani Pattaya</li>
                  <br />
                  <p>These areas and hotels offer a range of luxury accommodations to ensure a memorable and comfortable trip.</p>
                  <br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen10ImageURL} alt="Sightseeing 4" />
                          <img src={sightseen11ImageURL} alt="Sightseeing 5" />
                          <img src={sightseen12ImageURL} alt="Sightseeing 6" />

                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>WHAT ARE THE BEST THINGS TO DO IN PATTAYA AT NIGHT?</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>WHAT ARE THE BEST THINGS TO DO IN PATTAYA AT NIGHT?</strong></h4>
                </div>
                <div className="card-body">

                  <p>For tourists in Pattaya, the nightlife offers a vibrant and diverse array of activities. Here are some of the best things to do in <strong>Pattaya at night</strong>:</p>

                  <p><b>1.	Visit Walking Street:</b> This bustling street is famous for its lively atmosphere, featuring numerous bars, nightclubs, and entertainment venues. It's the heart of Pattaya's nightlife.</p>

                  <p><b>2. Watch the Alcazar Cabaret Show:</b> Enjoy a dazzling performance at this famous ladyboy cabaret show, known for its spectacular costumes, music, and dance.</p>
                  <p><b>3. Explore Pattaya Beach Road:</b> Stroll along the beach road to enjoy street performances, food stalls, and beachside bars.</p>
                  <p><b>4. Experience the Tiffany's Show:</b> Another renowned ladyboy cabaret show offering elaborate performances and vibrant stage productions.</p>
                  <p><b>5. Dine at a Beachfront Restaurant:</b> Enjoy a romantic dinner at one of the many beachfront restaurants while taking in the beautiful sunset views.</p>
                  <p><b>6. Visit the Pattaya Viewpoint:</b> Head to the viewpoint for stunning night-time views of the city and coastline.</p>
                  <p><b>7. Go to a Night Market:</b> Explore local night markets like Thepprasit Night Market for shopping, street food, and entertainment.</p>
                  <p><b>8. Enjoy a Sunset Cruise:</b> Take a cruise around Pattaya Bay to witness a beautiful sunset and enjoy a relaxing evening on the water.</p>
                  <p><b>9. Try Local Nightlife Hotspots:</b> Explore popular nightclubs and bars, such as Mixx Discotheque or Insomnia Pattaya, for dancing and live music.</p>
                  <p><b>10. Relax at a Rooftop Bar:</b> Unwind with a cocktail at one of Pattaya's rooftop bars, offering panoramic views of the city skyline.</p>
                  <br />
                  <h5><b>TOP 5 BEST PLACE TO VISIT IN PATTAYA</b></h5>
                  <br />
                  <p>For a <strong>Bangkok Pattaya package tour from Kolkata</strong> with Adorable Vacation LLP, here are the top 5 best places to visit in Pattaya:</p>
                  <p><strong>1.	Sanctuary of Truth</strong>: This stunning wooden temple is an architectural marvel, showcasing intricate carvings and sculptures that reflect traditional Thai craftsmanship and philosophy. It’s a unique cultural and spiritual experience.</p>
                  <p><strong>2.	Coral Island (Koh Larn)</strong>: A beautiful island just off the coast of Pattaya, offering pristine beaches, clear waters, and various water activities such as snorkeling and parasailing. It’s a perfect spot for a relaxing day trip.</p>
                  <p><strong>3.	Nong Nooch Tropical Garden</strong>: A vast and beautifully landscaped garden featuring a variety of themed sections, including a tropical garden, orchid garden, and dinosaur park. It also hosts traditional Thai cultural shows and elephant performances.</p>
                  <p><strong>4.	Pattaya Floating Market</strong>: This lively market offers a taste of local culture with its array of traditional Thai food, crafts, and souvenirs. The market is built on water, providing a unique shopping experience.</p>
                  <p><strong>5.	Alcazar Cabaret Show</strong>: Experience one of Pattaya’s most famous cabaret shows, known for its spectacular performances, dazzling costumes, and energetic dance routines. It’s a highlight of Pattaya’s vibrant nightlife.</p>
                  <p>These attractions offer a mix of cultural, natural, and entertainment experiences, making for a memorable visit to Pattaya.</p>
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>TOP 5 BEST PLACE TO VISIT IN PATTAYA</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>TOP 5 BEST PLACE TO VISIT IN PATTAYA</strong></h4>
                </div>
                <div className="card-body">
                  <p>For a <strong>Bangkok Pattaya Tour Package from Kolkata</strong> with Adorable Vacation LLP, here are the top 5 best places to visit in Pattaya:</p>
                  <p><strong>1.	Sanctuary of Truth</strong>: This stunning wooden temple is an architectural marvel, showcasing intricate carvings and sculptures that reflect traditional Thai craftsmanship and philosophy. It’s a unique cultural and spiritual experience.</p>
                  <p><strong>2.	Coral Island (Koh Larn)</strong>: A beautiful island just off the coast of Pattaya, offering pristine beaches, clear waters, and various water activities such as snorkeling and parasailing. It’s a perfect spot for a relaxing day trip.</p>
                  <p><strong>3.	Nong Nooch Tropical Garden</strong>: A vast and beautifully landscaped garden featuring a variety of themed sections, including a tropical garden, orchid garden, and dinosaur park. It also hosts traditional Thai cultural shows and elephant performances.</p>
                  <p><strong>4.	Pattaya Floating Market</strong>: This lively market offers a taste of local culture with its array of traditional Thai food, crafts, and souvenirs. The market is built on water, providing a unique shopping experience.</p>
                  <p><strong>5.	Alcazar Cabaret Show</strong>: Experience one of Pattaya’s most famous cabaret shows, known for its spectacular performances, dazzling costumes, and energetic dance routines. It’s a highlight of Pattaya’s vibrant nightlife.</p>
                  <p>These attractions offer a mix of cultural, natural, and entertainment experiences, making for a memorable visit to Pattaya.</p>
                </div>
              </div>
              <br /><br /><br />
              <div className="row py-5">
                <div className="col-md-12">
                  <div className="site-heading text-center">
                    <h3>
                      DAY WISE ITINERARY FOR <strong><span className="tomato">BANGKOK PATTAYA PHUKET KRABI TOUR FROM KOLKATA</span></strong>
                    </h3>
                    <h4>8 Nights 9 Days Thailand Tour Itinerary from Kolkata</h4>
                  </div>
                </div>
              </div>

              <div className="card">
                <div
                  className="accordion"
                  id="accordionPanelsStayOpenExample"
                >
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="day1"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseOne"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseOne"
                      >
                        DAY 01: ARRIVAL AT PHUKET AIRPORT, TRANSFER TO HOTEL
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingOne"
                    >
                      <div className="accordion-body">
                        <p>Your <strong>Phuket Krabi tour package from Kolkata with Airfare</strong> starts with your arrival in Phuket. Upon arrival, you will be greeted by our representative at the airport and transferred to your hotel. The transfer will take around 1 hour, depending on the location of your hotel.</p>
                        <p>You will find that Phuket is one of the most popular tourist destinations in <strong>Thailand trip</strong>, known for its beautiful beaches, vibrant nightlife, and cultural attractions. Once you reach your hotel, check in and relax for the day.</p>
                        <br /> <br />
                        <div className="content-desktop">
                          <center><img src={navDay1ImageURL} alt="bhutan tour package from mumbai and transfer to thimphu" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay1ImageURL} style={{ width: '320px' }} alt="bhutan tour package from mumbai and transfer to thimphu" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="day2"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseTwo"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseTwo"
                      >
                        DAY 02: PHI PHI ISLAND TOUR WITH LUNCH
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseTwo"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingTwo"
                    >
                      <div className="accordion-body">
                        <p>
                          On day 2 of your <strong>Bangkok Pattaya package tour from kolkata</strong>, get ready for an exciting <strong>Phi Phi Island day trip</strong>. This beautiful island is located just a short boat ride away from Phuket and is known for its crystal-clear waters, stunning beaches, and amazing marine life.
                        </p><br />
                        <p>
                          Your <strong>Phi Phi Island tour</strong> will start with a hotel pickup and transfer to the pier. From there, you will board a speedboat and head out to the island. During the tour, you will get to visit several popular attractions on the island, including Maya Bay, Viking Cave, and Monkey Beach.
                        </p><br />
                        <p>In addition to sightseeing, you will also get to enjoy swimming and snorkelling in the clear blue waters of the Andaman Sea. The tour includes lunch at a local restaurant on the island. Please note that the National Park fee is excluded from the tour cost and needs to be paid separately.</p><br />

                        <div className="content-desktop">
                          <center><img src={navDay2ImageURL} alt="bhutan package tour booking from mumbai city airport explore buddha dordenma" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay2ImageURL} style={{ width: '320px' }} alt="bhutan package tour booking from mumbai city airport explore buddha dordenma" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="day3"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseThree"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        DAY 03: TRANSFER FROM PHUKET HOTEL TO KRABI HOTEL
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseThree"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingThree"
                    >
                      <div className="accordion-body">
                        <p>On day 3 of your <strong>Phuket Krabi tour package</strong>, you will be transferred from your hotel in Phuket to your hotel in Krabi. The transfer will take around 2 hours and 49 minutes via Route 4, covering a distance of 163 km.
                        </p><br />
                        <p>
                          Krabi is another popular tourist destination in Thailand, known for its stunning limestone cliffs, beautiful beaches, and clear blue waters. Once you reach your hotel, check in and relax for the day.
                        </p><br />

                        <div className="content-desktop">
                          <center><img src={navDay3ImageURL} alt="with bhutan package tour from mumbai with direct flight explore dochula pass" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay3ImageURL} style={{ width: '320px' }} alt="with bhutan package tour from mumbai with direct flight explore dochula pass" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="day4"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseFour"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        Day 04: - KRABI, 04 ISLAND TOUR WITH LUNCH
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseFour"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingFour"
                    >
                      <div className="accordion-body">
                        <p>On day 4th day of your <strong>Phuket Krabi tour packages</strong>, get ready for a thrilling day trip to the 4 Islands of Krabi. This tour is a great way to explore the natural beauty of the Krabi region, including the famous <strong>Railay Beach</strong> and the stunning islands of <strong>Koh Poda</strong>, <strong>Koh Gai</strong>, and <strong>Koh Tub</strong>.</p>
                        <p>Your <strong>4 Island tour</strong> will start with a hotel pickup and transfer to the pier. From there, you will board a speedboat and head out to the islands. During the tour, you will get to enjoy swimming, snorkelling, and sunbathing on the beautiful beaches of the islands.</p>
                        <p>The <strong>Krabi tour</strong> also includes a delicious lunch at a local restaurant on one of the islands. Please note that the National Park fee is excluded from the tour cost and needs to be paid separately.</p><br />

                        <div className="content-desktop">
                          <center><img src={navDay4ImageURL} alt="bhutan package tour from mumbai and explore phobjhika valley" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay4ImageURL} style={{ width: '320px' }} alt="bhutan package tour from mumbai and explore phobjhika valley" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="day5"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseFive"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        DAY 05: KRABI TO PATTAYA TOUR VIA BANGKOK AIRPORT + EVENING ALCAZAR SHOW AT PATTAYA (VIP SEAT)
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseFive"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingFive"
                    >
                      <div className="accordion-body">
                        <p>
                          On day 5 of your <strong>Bangkok Pattaya tour packages from Kolkata</strong>, you will be transferred from your hotel in Krabi to Pattaya via Bangkok Airport. Once you reach Pattaya, check in to your hotel and relax for the day.
                        </p><br />
                        <p>In the evening, get ready for a mesmerizing show at the famous <strong>Alcazar Theater in Pattaya</strong>. <strong>The Alcazar Show</strong> is one of the most popular tourist attractions in Pattaya, known for its spectacular performances by talented artists in dazzling costumes.</p><br />
                        <p>You will enjoy the show from the comfort of your VIP seat, which provides a great view of the stage.</p>
                        <br />
                        <div className="content-desktop">
                          <center><img src={navDay5ImageURL} alt="with bhutan packages from mumbai explore punkha suspension bridge" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay5ImageURL} style={{ width: '320px' }} alt="with bhutan packages from mumbai explore punkha suspension bridge" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="day6"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseSix"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        DAY 06: PATTAYA CORAL ISLAND TOUR WITH LUNCH + ART IN PARADISE PATTAYA
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseSix"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingSix"
                    >
                      <div className="accordion-body">
                        <p>
                          On the 6th day of your <strong>Bangkok Pattaya Phuket Krabi Tour Packages from Kolkata</strong>, after breakfast, get ready for a visit to the beautiful Coral Island, which is just a short boat ride away from Pattaya Beach. The island is a perfect spot for adventure and relaxation. You can enjoy activities like swimming, snorkelling, and sunbathing on the sandy beaches. The crystal-clear water and vibrant coral reefs make for an unforgettable experience. You can also try out exciting water sports activities like parasailing, banana boat rides, and jet skiing at your own cost.
                        </p>
                        <p>After the <strong>Coral Island tour</strong>, head towards the Art in Paradise Pattaya. This is a unique art museum that features interactive 3D paintings. Visitors can pose and click pictures with these paintings that make it look like they are a part of the painting itself. The museum is divided into ten sections, each with its own theme, and it is a perfect place for a fun family outing.</p>
                        <br />
                        <div className="content-desktop">
                          <center><img src={navDay6ImageURL} alt="bhutan package tour booking from-mumbai and explore tigers nest monastery" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay6ImageURL} style={{ width: '320px' }} alt="bhutan package tour booking from-mumbai and explore tigers nest monastery" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="day7"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseSeven"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        DAY 07: PATTAYA TO BANGKOK TRANSFER + BANGKOK CITY TOUR [GOLDEN BUDDHA + MARBLE BUDDHA + EVENING CHAO PHRAYA DINNER CRUISE]
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseSeven"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingSeven"
                    >
                      <div className="accordion-body">
                        <p>
                          After breakfast on day 7 of your <strong>Thailand Trip from Kolkata</strong>, check out from the hotel in Pattaya and get transferred for <strong>Bangkok Tour</strong>. On the way to Bangkok, you will have the opportunity to explore the city's stunning sights, such as the Golden Buddha and the Marble Buddha. The Golden Buddha is one of the most visited tourist attractions in Bangkok. It is a massive statue of <strong>Lord Buddha</strong> made of solid gold and weighs around 5.5 tons. The statue is housed inside the Wat Traimit temple and is a beautiful example of traditional Thai architecture.
                        </p>
                        <p>
                          The Marble Buddha, also known as the Wat Benchamabophit, is another famous temple in Bangkok. The temple is made of white Italian marble and is a prime example of classical Thai style. Inside the temple, you will find a beautiful Buddha statue made of green jade. After the sightseeing tour, you can enjoy a romantic Chao Phraya dinner cruise, where you can indulge in Thai cuisine while taking in the stunning views of the city's skyline. The cruise is an excellent way to experience Bangkok's vibrant nightlife and culture.
                        </p>

                        <br />
                        <div className="content-desktop">
                          <center><img src={navDay7ImageURL} alt="bhutan package tour cost from mumbai and explore chele la pass" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay7ImageURL} style={{ width: '320px' }} alt="bhutan package tour cost from mumbai and explore chele la pass" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="day8"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseTen"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        DAY 08:BANGKOK SAFARI WORLD + MARINE PARK TOUR WITH LUNCH
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseTen"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingTen"
                    >
                      <div className="accordion-body">
                        <p>
                          On the 8th day of your <strong>Bangkok Tour</strong>, head towards Safari World and Marine Park, one of the most popular tourist attractions in Bangkok. The park is spread over 170 acres and is home to a wide range of animals, including zebras, giraffes, tigers, lions, and many more.
                        </p>
                        <p><strong>The Safari Park</strong> is divided into four sections: Safari Park, Bird Park, Games Corner, and Macaw Island. Visitors can explore the park in their car or hop on a guided safari tour, where they can get up close and personal with some of the park's animals.</p>
                        <p><strong>The Marine Park</strong> features a range of shows and attractions, including the Dolphin Show, Sea Lion Show, and Orangutan Boxing Show. You can also explore the jungle walk, which is a nature trail that features various exotic birds and animals. Lunch is included in the tour package, and you can choose from a range of cuisines.</p>
                        <br />

                        <div className="content-desktop">
                          <center><img src={navDay8ImageURL} alt="bhutan package tour booking from mumbai city airport explore buddha dordenma" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay8ImageURL} style={{ width: '320px' }} alt="bhutan package tour booking from mumbai city airport explore buddha dordenma" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="day9"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseEleven"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseEleven"
                      >
                        DAY 09:BYE BYE THAILAND WITH SWEET MEMORIES
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseEleven"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingEleven"
                    >
                      <div className="accordion-body">
                        <p>
                          On the last day of your <strong>Thailand trip</strong> with <strong>Bangkok Pattaya Phuket Krabi tour</strong>, have breakfast at the hotel and check out. Depending on your flight schedule, you may have some time to explore the city or do some last-minute shopping before getting transferred to the airport.
                        </p>
                        <br />

                        <div className="content-desktop">
                          <center><img src={navDay9ImageURL} alt="bhutan package tour booking from mumbai city airport explore buddha dordenma" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay9ImageURL} style={{ width: '320px' }} alt="bhutan package tour booking from mumbai city airport explore buddha dordenma" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <br /><br />

              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">{<InclusionExclusion />}</div>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>
                    WHAT ARE THE BEST THINGS TO BUY FROM THAILAND TOUR FROM KOLKATA WITH ADORABLE VACATION LLP?
                  </h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}> WHAT ARE THE BEST THINGS TO BUY FROM THAILAND TOUR FROM KOLKATA WITH ADORABLE VACATION LLP?</h4>
                </div>
                <div className="card-body">
                  <p>During your <strong>Bangkok Pattaya package tour from Kolkata</strong> with Adorable Vacation LLP, here are some great items to consider buying:</p>
                  <p><b>1. Thai Silk:</b> Known for its high quality and vibrant colors, Thai silk products like scarves, shawls, and clothing make excellent souvenirs.</p>
                  <p><b>2. Handcrafted Jewelry:</b> Thailand is famous for its beautiful handcrafted jewelry, including silver and gemstone pieces.</p>
                  <p><b>3. Thai Spices and Sauces:</b> Bring home authentic Thai spices, curry pastes, and sauces to recreate Thai dishes.</p>
                  <p><b>4. Traditional Thai Crafts:</b> Look for handcrafted items such as wooden carvings, lacquerware, and traditional Thai masks.</p>
                  <p><b>5. Elephant-themed Souvenirs:</b> Items featuring elephants, a symbol of Thailand, like figurines, paintings, and textiles, are popular choices.</p>
                  <p><b>6. Thai Herbal Products:</b> Consider purchasing herbal teas, essential oils, and beauty products made from Thai herbs.</p>
                  <p><b>7. Local Artwork:</b> Original paintings, prints, and traditional Thai art are great for adding a touch of Thailand to your home decor.</p>
                  <p><b>8. Thai Teas and Coffee:</b> Unique blends of Thai teas and coffee make wonderful gifts and a taste of Thailand.</p>
                  <p><b>9. Clothing and Accessories:</b> Shop for stylish and affordable clothing and accessories, such as dresses, shirts, and bags, in local markets.</p>
                  <p><b>10. Handmade Pottery:</b> Thai pottery, including decorative vases and bowls, can be a charming addition to your collection.</p>
                  <p>These items reflect Thailand’s rich culture and craftsmanship, making them memorable keepsakes from your trip.</p>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>BEST PLACES TO SHOP DURING THAILAND TOUR PACKAGE FROM KOLKATA FOR COUPLE</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>BEST PLACES TO SHOP DURING THAILAND TOUR PACKAGE FROM KOLKATA FOR COUPLE</h4>
                </div>
                <div className="card-body">
                  <p>For a couple on a <strong>Thailand tour Package from Kolkata</strong>, here are some of the best places to shop – </p>
                  <p><b>Bangkok:</b></p>
                  <p><b>1.	Chatuchak Weekend Market (ตลาดนัดจตุจักร)</b></p>
                  <p>A massive market with everything from clothes to souvenirs. Perfect for a leisurely shopping experience.</p>
                  <p><b>2.	MBK Center (เอ็มบีเค เซ็นเตอร์)</b></p>
                  <p>A popular shopping mall offering electronics, fashion, and accessories. Great for finding good deals</p>
                  <p><b>3. Siam Paragon (สยามพารากอน)</b></p>
                  <p>A luxury mall with high-end brands and gourmet food. Ideal for upscale shopping.</p>
                  <p><b>4.	Asiatique The Riverfront (เอเชียทีค เดอะ ริเวอร์ฟรอนต์)</b></p>
                  <p>A night market and mall by the river with boutiques, restaurants, and a romantic atmosphere.</p>
                  <p><b>5.	Pratunam Market (ประตูน้ำ)</b></p>
                  <p>Known for wholesale fashion and textiles, it's a great place for trendy clothing at bargain prices.</p>
                  <br />
                  <h5><b>Pattaya:</b></h5>
                  <br />
                  <p><b>1. Pattaya Floating Market (ตลาดน้ำพัทยา)</b></p>
                  <p>A vibrant market with a variety of local goods, crafts, and street food. Great for unique souvenirs and snacks.</p>

                  <p><b>2. Central Festival Pattaya Beach (เซ็นทรัลเฟสติวัล พัทยา บีช)</b></p>
                  <p>A large mall with international brands, dining options, and entertainment. Perfect for shopping and relaxing.</p>

                  <p><b>3. Thepprasit Night Market (ตลาดเทพประสิทธิ์)</b></p>
                  <p>A bustling night market offering clothing, accessories, and delicious street food.</p>
                  <br />
                  <h5><b>Phuket:</b></h5>
                  <br />
                  <p><b>1. Jungceylon Shopping Mall (จังซีลอน)</b></p>
                  <p>A major shopping destination in Patong with a range of shops, dining options, and entertainment.</p>
                  <p><b>2. Phuket Weekend Market (ตลาดนัดสุดสัปดาห์ภูเก็ต)</b></p>
                  <p>Known for its wide variety of local products, including clothes, accessories, and crafts.</p>
                  <p><b>3. Banzaan Fresh Market (ตลาดบันซาน)</b></p>
                  <p>A great place to experience local produce, seafood, and snacks.</p>
                  <br />
                  <h5><b>Krabi:</b></h5>
                  <br />
                  <p><b>1.	Krabi Town Walking Street (ถนนคนเดินกระบี่)</b></p>
                  <p>A vibrant market with local handicrafts, souvenirs, and street food.</p>
                  <p><b>2.	Krabi Night Market (ตลาดไนท์บาซาร์กระบี่)</b></p>
                  <p>Offers a range of local products and delicious Thai street food.</p>
                  <p><b>3.	Ao Nang Beach Road (ถนนชายหาดอ่าวนาง)</b></p>
                  <p>Stroll along the beach road for boutique shops, souvenirs, and local crafts.</p>
                  <p>Thai Phrases for Shopping:</p>
                  <p><b>•	"ราคาเท่าไหร่?" (Raa-kaa thaao-rai?)</b> – How much is this?</p>
                  <p><b>•	"ลดราคาได้ไหม?" (Lot raa-kaa daai mai?)</b> – Can you give me a discount?</p>
                  <p><b>•	"ขอบคุณครับ/ค่ะ" (Khob khun krap/ka)</b> – Thank you (male/female).</p>
                  <p>Enjoy your shopping adventure in Thailand!</p>
                  <br />

                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen16ImageURL} alt="Sightseeing 1" />
                          <img src={sightseen17ImageURL} alt="Sightseeing 3" />
                          <img src={sightseen18ImageURL} alt="Sightseeing 2" />

                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>10 MUST THINGS TO DO IN <strong>BANGKOK PATTAYA PHUKET KRABI TOUR FROM KOLKATA</strong> WITH ADORABLE VACATION LLP </h3>
                  <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>10 MUST THINGS TO DO IN <strong>BANGKOK PATTAYA PHUKET KRABI TOUR FROM KOLKATA</strong> WITH ADORABLE VACATION LLP </h3>
                </div>
                <div className="card-body">
                  <p>
                    Here are 10 must-do activities during your <strong>Bangkok, Pattaya, Phuket, and Krabi tour from Kolkata with Adorable Vacation LLP</strong>:
                  </p>
                  <p><b>THINGS TO DO DURING BANGKOK TOUR : </b></p>
                  <p>During your Bangkok tour, here are some top things to do:</p>
                  <p><b>1.	Visit the Grand Palace:</b></p>
                  <p> •	Explore the stunning architecture and historical significance of this iconic palace and temple complex.</p>

                  <p><b>2. Explore Wat Pho:</b></p>
                  <p>• Home to the Reclining Buddha and known for its traditional Thai massage school. Don’t miss the impressive golden statue and the serene atmosphere.</p>

                  <p><b>3. Tour Wat Arun (Temple of Dawn):</b></p>
                  <p>• Admire the beautiful ceramic-tiled temple on the banks of the Chao Phraya River, especially stunning at sunrise or sunset.</p>

                  <p><b>4. Shop at Chatuchak Weekend Market:</b></p>
                  <p>• Dive into one of the world’s largest markets, offering everything from clothing and accessories to antiques and street food.</p>

                  <p><b>5. Take a Chao Phraya River Cruise:</b></p>
                  <p>• Enjoy a relaxing boat ride along Bangkok’s main river, providing unique views of the city’s landmarks and local life.</p>

                  <p><b>6. Visit Jim Thompson House:</b></p>
                  <p>• Discover the traditional Thai house and art collection of the American businessman who helped revive the Thai silk industry.</p>

                  <p><b>7. Stroll Through Lumpini Park:</b></p>
                  <p>• Escape the city’s hustle and bustle in this large urban park, perfect for a leisurely walk, paddle boating, or enjoying the green space.</p>

                  <p><b>8. Explore Asiatique The Riverfront:</b></p>
                  <p>• A bustling night market and shopping complex by the river with dining options, boutiques, and entertainment.</p>

                  <p><b>9. Experience the Vibrant Nightlife:</b></p>
                  <p>• Visit areas like Khao San Road or Sukhumvit for lively bars, clubs, and street food.</p>

                  <p><b>10. Relax with a Thai Massage:</b></p>
                  <p>• Treat yourself to a traditional Thai massage at one of the many spas or massage centers around the city.</p>
                  <p>These activities will give you a rich taste of Bangkok’s culture, history, and vibrant city life.</p>
                  <br />
                  <a href="tel://9163676037" className="btn btn-Warning" style={{ padding: '15px 30px', fontSize: '15px' }}>
                    <b>CALL NOW & TALK TO OUR EXPERT</b>
                  </a>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>THINGS TO DO DURING PHUKET TOUR : </h3>
                  <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>THINGS TO DO DURING PHUKET TOUR : </h3>
                </div>
                <div className="card-body">
                  <p>
                    During your Phuket tour, here are some top things to do:
                  </p>
                  <p><b>1. Visit Phi Phi Islands </b></p>
                  <p>•	Take a day trip to these stunning islands known for their crystal-clear waters, beautiful beaches, and vibrant marine life. Activities include snorkeling, swimming, and exploring the scenic beauty.</p>
                  <p><b>2. Relax at Patong Beach</b></p>
                  <p> •	Enjoy the lively atmosphere of Patong Beach, where you can sunbathe, swim, and participate in water sports. The area also offers plenty of restaurants, bars, and shopping options.</p>

                  <p><b>3. Explore Old Phuket Town</b></p>
                  <p>•	Wander through this historic area known for its well-preserved Sino-Portuguese architecture, colorful buildings, and charming street art. Visit local shops, cafes, and markets.</p>

                  <p><b>4. Visit the Big Buddha</b></p>
                  <p>•	See the impressive 45-meter tall statue located on Nakkerd Hill. The site offers panoramic views of Phuket and is a significant cultural and spiritual landmark.</p>

                  <p><b>5. Enjoy a Phuket Fantasea Show:</b></p>
                  <p>• Experience this popular cultural theme park show featuring Thai folklore, acrobatics, and elaborate costumes. It’s a vibrant and entertaining way to learn about Thai culture.</p>

                  <p><b>6. Take a Tour of Phang Nga Bay:</b></p>
                  <p>• Explore this stunning bay with its limestone cliffs, clear waters, and unique rock formations. Activities include boat tours, kayaking, and visits to James Bond Island.</p>

                  <p><b>7. Visit Wat Chalong:</b></p>
                  <p>• Explore Phuket's largest and most important Buddhist temple. The complex features beautiful architecture, intricate murals, and a serene atmosphere.</p>

                  <p><b>8. Go Island Hopping:</b></p>
                  <p>• Discover nearby islands like Similan Islands, Racha Island, and Coral Island. Each offers beautiful beaches, snorkeling spots, and clear waters.</p>

                  <p><b>9. Relax at Kata Noi Beach:</b></p>
                  <p>• Enjoy a quieter beach experience at Kata Noi, known for its soft sand and clear waters. It’s perfect for a peaceful day of relaxation.</p>

                  <p><b>10. Visit the Phuket Trickeye Museum:</b></p>
                  <p>• Have fun at this 3D art museum where you can interact with optical illusions and creative installations. It's a great spot for unique photos and entertainment.</p>
                  <p>These activities will help you make the most of your time in Phuket and experience its diverse attractions.</p>
                  <br />
                  <a href="tel://9163676037" className="btn btn-Warning" style={{ padding: '15px 30px', fontSize: '15px' }}>
                    <b>CALL NOW & TALK TO OUR EXPERT</b>
                  </a>
                </div>
              </div>
              <br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>THINGS TO DO DURING PATTAYA TOUR : </h3>
                  <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>THINGS TO DO DURING PATTAYA TOUR : </h3>
                </div>
                <div className="card-body">
                  <p>
                    During your Pattaya tour, here are some fantastic things to do:
                  </p>
                  <p><b>Visit Coral Island (Koh Larn) </b></p>
                  <p>•	Take a day trip to this beautiful island known for its white sandy beaches, crystal-clear waters, and water sports like snorkeling, parasailing, and jet-skiing.</p>
                  <p><b>2. Explore Pattaya Floating Market:</b></p>
                  <p>• Wander through this lively market set on water, offering a wide range of local foods, crafts, and souvenirs. It's a great place to experience Thai culture.</p>

                  <p><b>3. Enjoy the Alcazar Cabaret Show:</b></p>
                  <p>• Watch a spectacular ladyboy cabaret performance known for its elaborate costumes, vibrant music, and dazzling dance routines.</p>

                  <p><b>4. Relax at Pattaya Beach:</b></p>
                  <p>• Spend time at Pattaya Beach, where you can sunbathe, swim, or enjoy various beachside activities.</p>

                  <p><b>5. Visit Nong Nooch Tropical Garden:</b></p>
                  <p>• Explore this beautifully landscaped garden featuring themed sections, traditional Thai cultural shows, and elephant performances.</p>

                  <p><b>6. Discover Art in Paradise:</b></p>
                  <p>• Enjoy a fun and interactive 3D art museum where you can take creative photos with optical illusion artworks.</p>

                  <p><b>7. Explore Thepprasit Night Market:</b></p>
                  <p>• Shop for local goods, clothing, and street food at this popular night market. It's a great spot to try Thai snacks and find unique souvenirs.</p>

                  <p><b>8. Visit Pattaya Viewpoint:</b></p>
                  <p>• Head to the viewpoint for panoramic views of Pattaya city and the coastline, especially beautiful at sunset.</p>

                  <p><b>9. Enjoy a Dinner Cruise:</b></p>
                  <p>• Take a romantic dinner cruise around Pattaya Bay to enjoy the beautiful night views of the city and a delicious meal.</p>

                  <p><b>10. Visit the Sanctuary of Truth:</b></p>
                  <p>• Explore this impressive wooden temple featuring intricate carvings and sculptures that reflect traditional Thai art and philosophy.</p>
                  <p>These activities will help you make the most of your time in Phuket and experience its diverse attractions.</p>
                  <p>These activities will help you make the most of your time in Pattaya, offering a mix of cultural experiences, relaxation, and entertainment.</p>

                  <br />
                  <a href="tel://9163676037" className="btn btn-Warning" style={{ padding: '15px 30px', fontSize: '15px' }}>
                    <b>CALL NOW & TALK TO OUR EXPERT</b>
                  </a>
                </div>
              </div>
              <br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>THINGS TO DO DURING KRABI TOUR : </h3>
                  <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>THINGS TO DO DURING KRABI TOUR : </h3>
                </div>
                <div className="card-body">
                  <p>
                    During your Krabi tour, here are some top things to do:
                  </p>
                  <p><b>1. Explore the 4-Island Tour:</b></p>
                  <p>• Visit: Hong Island, Bamboo Island, Poda Island, and Chicken Island.</p>
                  <p>• Activities: Snorkeling, swimming, and relaxing on beautiful beaches.</p>

                  <p><b>2. Relax at Railay Beach:</b></p>
                  <p>• Location: Accessible by boat from Ao Nang.</p>
                  <p>• Highlights: Stunning limestone cliffs, clear waters, and white sandy beaches. Ideal for beach lounging and rock climbing.</p>

                  <p><b>3. Visit the Emerald Pool and Hot Springs:</b></p>
                  <p>• Emerald Pool: Swim in this natural hot spring pool surrounded by lush jungle.</p>
                  <p>• Hot Springs: Enjoy a relaxing soak in the thermal springs.</p>

                  <p><b>4. Discover Thung Teao Forest Natural Park:</b></p>
                  <p>• Location: Near the Emerald Pool.</p>
                  <p>• Highlights: Beautiful freshwater lagoon and scenic walking trails through the forest.</p>

                  <p><b>5. Explore Krabi Town Night Market:</b></p>
                  <p>• Location: Krabi Town.</p>
                  <p>• Highlights: Sample local street food, shop for souvenirs, and experience the vibrant local atmosphere.</p>

                  <p><b>6. Take a Sunset Cruise:</b></p>
                  <p>• Activities: Enjoy a scenic boat ride around Krabi's coastline and watch the sunset over the Andaman Sea.</p>

                  <p><b>7. Visit Wat Tham Sua (Tiger Cave Temple):</b></p>
                  <p>• Highlights: Climb the 1,237 steps to the summit for panoramic views of Krabi and visit the impressive Buddha statue.</p>

                  <p><b>8. Explore Ao Nang Beach:</b></p>
                  <p>• Activities: Enjoy water sports, shop at local markets, and dine at beachfront restaurants.</p>

                  <p><b>9. Discover Phra Nang Cave Beach:</b></p>
                  <p>• Location: Accessible from Railay Beach.</p>
                  <p>• Highlights: Known for its stunning cave, clear waters, and unique rock formations.</p>

                  <p><b>10. Go Kayaking or Stand-Up Paddleboarding:</b></p>
                  <p>• Locations: Various beaches and mangrove areas in Krabi.</p>
                  <p>• Activities: Explore the beautiful waters and mangroves at your own pace.</p>
                  <br />
                  <a href="tel://9163676037" className="btn btn-Warning" style={{ padding: '15px 30px', fontSize: '15px' }}>
                    <b>CALL NOW & TALK TO OUR EXPERT</b>
                  </a>
                </div>
              </div>
              <br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>THE BEST OPTIONAL TOURS YOU CAN CONSIDER IN PATTAYA:</h3>
                  <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>THE BEST OPTIONAL TOURS YOU CAN CONSIDER IN PATTAYA:</h3>
                </div>
                <div className="card-body">
                  <p><b>1. Coral Island (Koh Larn) Tour:</b></p>
                  <p>• Beautiful beaches, clear waters, and various water activities such as snorkeling, parasailing, and jet-skiing. Enjoy a relaxing day on the island with a beachside lunch.</p>

                  <p><b>2. Alcazar Cabaret Show:</b></p>
                  <p>• A dazzling ladyboy cabaret performance known for its elaborate costumes, impressive dance routines, and vibrant atmosphere. A popular evening entertainment option.</p>

                  <p><b>3. Nong Nooch Tropical Garden:</b></p>
                  <p>• A sprawling garden with beautifully landscaped areas, including a tropical garden, orchid garden, and dinosaur park. The park also features cultural shows and elephant performances.</p>

                  <p><b>4. Pattaya Floating Market:</b></p>
                  <p>• A market built on water offering a range of local goods, crafts, and delicious street food. A great place to experience traditional Thai shopping and cuisine.</p>

                  <p><b>5. The Sanctuary of Truth:</b></p>
                  <p>• A stunning wooden temple with intricate carvings and sculptures reflecting traditional Thai art and philosophy. A unique cultural and architectural experience.</p>

                  <p><b>6. Art in Paradise:</b></p>
                  <p>• An interactive 3D art museum where you can pose with optical illusion art pieces. A fun and creative experience perfect for photos.</p>

                  <p><b>7. Pattaya Elephant Village:</b></p>
                  <p>• Experience an elephant ride, learn about elephant care, and watch traditional elephant shows in a setting dedicated to the conservation and well-being of elephants.</p>

                  <p><b>8. Sriracha Tiger Zoo:</b></p>
                  <p>• A zoo featuring tigers, crocodiles, and other animals. Enjoy performances with tigers, as well as a variety of animal shows.</p>

                  <p><b>9. Pattaya Park Tower:</b></p>
                  <p>• Visit the tower for panoramic views of Pattaya and enjoy activities like bungee jumping or a thrilling tower drop ride.</p>

                  <p><b>10. Khao Kheow Open Zoo:</b></p>
                  <p>• A large open zoo with a wide variety of animals in naturalistic habitats. Offers educational exhibits and interactive animal encounters.</p>
                  <p>These optional tours provide a range of experiences, from cultural and historical to fun and adventurous, ensuring a memorable visit to Pattaya.</p>
                  <br />
                  <a href="tel://9163676037" className="btn btn-Warning" style={{ padding: '15px 30px', fontSize: '15px' }}>
                    <b>CALL NOW & TALK TO OUR EXPERT</b>
                  </a>
                </div>
              </div>
              <br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>WHAT ARE THE DOCUMENTS REQUIRED TO OBTAIN THAILAND VISA?</h3>
                  <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>WHAT ARE THE DOCUMENTS REQUIRED TO OBTAIN THAILAND VISA?</h3>
                </div>
                <div className="card-body">
                  <p>This is very important information that every traveller from India, Kolkata must know before booking <strong>Bangkok Pattaya Package Tour</strong> from any <strong>Thailand Tour Operator in Kolkata</strong>. It is hassle free to procure <strong>Thai Visa from India</strong>. Travellers have to produce following documents:</p>
                  <br />
                  <h5><b>DOCUMENTS REQUIRED FOR PROCESSING THAI VISA FROM INDIA</b></h5>
                  <br />
                  <li>Original Passports including old (if any)</li>
                  <li>02 Copy Photo 3.5 X 4.5, White Background, Mat Finish, 80% Face - Strictly adhere to the specifications mentioned. Ears should be properly visible. Hair should be tied neatly for female candidates. Head should not be covered with any scarf/hat/other objects</li>
                  <li>Hotel voucher</li>
                  <li>Last 3 Month Bank statements with minimum Balance INR 50,000/- (PER/Person Basis) with bank manager’s stamp and signature on all 3 month’s balance pages</li>
                  <li>Flight tickets</li>
                  <li>Hotel vouchers</li>
                  <li>Thailand Visa form</li>
                  <li>Thailand Visa fees (Ex: India) INR 3,215/-PP</li>
                  <br />
                  <h5><b>DOCUMENTS REQUIRED FOR PROCESSING ON ARRIVAL THAI VISA</b></h5>
                  <br />
                  <li>Passport with 6 months validity</li>
                  <li>Flight ticket copy</li>
                  <li>Hotel voucher</li>
                  <li>02 Copy Photo 3.5 X 4.5, White Background, Mat Finish, 80% Face</li>
                  <li>Forex THB (Thai Baht) of 10,000 per person or USD 500 per family</li>
                  <li>THB 2,000/- Per Person</li>
                  <br />
                  <p><b>LEARN SOME COMMON LANGUAGES BEFORE</b> <strong>BANGKOK PATTAYA TOUR PACKAGE BOOKING FROM KOLKATA</strong></p>
                  <br />
                  <p>You are travelling <strong>Phuket, Krabi, Pattaya and Bangkok from Kolkata</strong>, India, right? Great! But while you are packing your bags, let's get acquainted with some Thai phrases that will help you in every second while you are in <strong>Thailand Trip</strong>. Before you set off, here are some basic Thai phrases to help you out during your trip:</p>
                  <li>Hello – Sawadee ka (สวัสดี)</li>
                  <li>Yes – Chi (ใช่)</li>
                  <li>Thank you – Khob Khun Ka (ขอบคุณ)</li>
                  <li>How are you? – Sa bai dee mai ka? (สบายดีไหมครับ?)</li>
                  <li>Sorry/Excuse me – Kho thot kha (ขออภัย)</li>
                  <li>See you – Jəə gan (เจอกัน)</li>
                  <li>See you again – Jəə gan mài (เจอกันใหม่)</li>
                  <li>See you tomorrow – Prûng níi jəə gan (พรุ่งนี้เจอกัน)</li>
                  <li>Where is the restroom? – Hong nam yoo tee nai khrup/ka? (ห้องน้ำอยู่ที่ไหนครับ/ค่ะ?)</li>
                  <li>I need a doctor – Phom dong gaan hai mor maa raak sa khrup/ka (ผมต้องการหมอมาดูแลครับ/ค่ะ)</li>
                  <li>No worries – Mai pen rai (ไม่เป็นไร)</li>
                  <li>Delicious – Aroy (อร่อย)</li>
                  <li>Goodbye – Jur gan (เจอกัน)</li>
                  <li>I would like to order – Sang aa-haan (สั่งอาหาร)</li>
                  <p>These phrases will be useful as you explore and enjoy Thailand. Have a fantastic trip!</p>
                  <br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen13ImageURL} alt="Sightseeing 4" />
                          <img src={sightseen14ImageURL} alt="Sightseeing 5" />
                          <img src={sightseen15ImageURL} alt="Sightseeing 6" />

                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <br />
                  <h5>HOTEL ACCOMMODATION IN BANGKOK & PATTAYA</h5>
                  <br />
                  <p><b>5 Star Category Hotel :</b></p>
                  <p><b>Bangkok:</b></p>
                  <li>The Peninsula Bangkok</li>
                  <li>Mandarin Oriental Bangkok</li>
                  <li>Banyan Tree Bangkok</li>
                  <li>Shangri-La Hotel Bangkok</li>
                  <li>The St. Regis Bangkok</li>
                  <p><b>Pattaya:</b></p>
                  <li>Hilton Pattaya</li>
                  <li>InterContinental Pattaya Resort</li>
                  <li>Dusit Thani Pattaya</li>
                  <li>Royal Cliff Beach Hotel</li>
                  <li>Cantera Grand Mirage Beach Resort</li>
                </div>
              </div>
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h2>
                        Frequently Ask <span>Questions</span>
                      </h2>
                      <h4>FOR PHUKET KRABI BANGKOK PATTAYA TOUR PACKAGES</h4>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div
                    className="accordion accordion-flush faQStyle"
                    id="accordionFlushExample"
                  >
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingOne">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseOne"
                          aria-expanded="false"
                          aria-controls="flush-collapseOne"
                        >
                          What is included in the tour package?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseOne"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingOne"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Most tour packages include accommodation, daily breakfast, airport transfers, guided tours, and sometimes additional activities or meals. Specific inclusions can vary, so it’s best to check the details of your package.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingTwo">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseTwo"
                          aria-expanded="false"
                          aria-controls="flush-collapseTwo"
                        >
                          What documents do I need for the tour?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseTwo"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingTwo"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          You will typically need a valid passport, visa (if required), travel insurance, flight tickets, and hotel booking confirmations. Make sure to also carry any required documents specific to your tour.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingThree">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseThree"
                          aria-expanded="false"
                          aria-controls="flush-collapseThree"
                        >
                          Do I need a visa for Thailand?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseThree"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingThree"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Indian passport holders generally need a visa to enter Thailand. Ensure you check the latest visa requirements and apply in advance through the Thai embassy or consulate.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingFour">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFour"
                          aria-expanded="false"
                          aria-controls="flush-collapseFour"
                        >
                          Are meals included in the tour package?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseFour"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingFour"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Breakfast is usually included in most packages. Other meals may be included or offered as optional add-ons. Verify meal inclusions with your tour operator.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingFive">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFive"
                          aria-expanded="false"
                          aria-controls="flush-collapseFive"
                        >
                          What is the best time to visit Thailand?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseFive"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingFive"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          The best time to visit Thailand is between November and April when the weather is generally dry and pleasant. The monsoon season runs from May to October, which can bring heavy rains.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingSix">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseSix"
                          aria-expanded="false"
                          aria-controls="flush-collapseSix"
                        >
                          What currency should I carry?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseSix"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingSix"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          The local currency in Thailand is the Thai Baht (THB). It’s advisable to carry some cash for small purchases, though credit cards are widely accepted.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingSeven">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseSeven"
                          aria-expanded="false"
                          aria-controls="flush-collapseSeven"
                        >
                          What should I pack for the trip?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseSeven"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingSeven"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Pack light, comfortable clothing suitable for warm weather, swimwear, sunscreen, a hat, and comfortable walking shoes. Also, consider packing a rain jacket or umbrella if traveling during the monsoon season.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingEight">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseEight"
                          aria-expanded="false"
                          aria-controls="flush-collapseEight"
                        >
                          Are there any specific health precautions I should take?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseEight"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingEight"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Ensure you have travel insurance, stay hydrated, use sunscreen, and avoid drinking tap water. Check if any vaccinations are recommended for travel to Thailand.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingNine">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseNine"
                          aria-expanded="false"
                          aria-controls="flush-collapseNine"
                        >
                          How do I get around during the tour?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseNine"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingNine"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Transfers between destinations are usually arranged as part of the package. Local transportation options include taxis, tuk-tuks, and public transport. Your tour operator will provide details on how to get around.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingTen">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseTen"
                          aria-expanded="false"
                          aria-controls="flush-collapseTen"
                        >
                          Can I customize the tour package?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseTen"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingTen"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Yes, we offer customization options. You can often modify the itinerary, add extra activities, or upgrade accommodation based on your preferences.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingEleven">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseEleven"
                          aria-expanded="false"
                          aria-controls="flush-collapseEleven"
                        >
                          What should I do if I encounter a problem during the trip?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseEleven"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingEleven"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Contact us or your local guide for assistance. They can help resolve any issues or provide support as needed.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingTwelve">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseTwelve"
                          aria-expanded="false"
                          aria-controls="flush-collapseTwelve"
                        >
                          Are there any dress codes I should be aware of?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseTwelve"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingTwelve"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          In temples and religious sites, dress modestly by covering shoulders and knees. Casual clothing is generally acceptable in other areas.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingThirteen">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseThirteen"
                          aria-expanded="false"
                          aria-controls="flush-collapseThirteen"
                        >
                          What is the cancellation policy?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseThirteen"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingThirteen"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Cancellation policies vary by tour operator. Review the terms and conditions of your package to understand any cancellation fees or refund policies.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h3>
                        <b>
                          Bangkok <span className="tomato">Tour Reviews</span>
                        </b>
                      </h3>
                      <h4>
                        <i>"Your Experience is our Marketing"</i>
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="row row-cols-1 ">
                  <div id="testimonials">
                    <div className="testimonial-box-container">
                      {reviewersDataView}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <BookingBox />
            </div>
          </div>
          <div className="btn-whatsapp-pulse">
            <a style={{ color: 'white' }} href="#" onClick={this.handleClick}>
              <i className="fab fa-whatsapp"></i>
            </a>
          </div>
          <div class="btn-call-pulse">
            <a style={{ color: 'white' }} href="tel:+91-91636-76037">
              <i className="fa fa-phone"></i>
            </a>
          </div>
        </div>
      </>
    );
  }
}
export default BangkokPattaya_8N_9D_Package;
