import React, { Component } from 'react';
import BookingBox from '../BookingBox';
import Slider from "react-slick";
import ApiURL from "../../../api/ApiURL";
import { Link } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InclusionExclusion from '../Arunachal_Pradesh/InclusionExclusion_arunachalguwahati_15n_16d';

class ArunachalTourGuwahati_15N_16D_Package extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customActiveTab: 'tab1',
            daywiseActiveTab: 'day1'
        }
    }

    handleCustomTabClick = (tab) => {
        this.setState({
            customActiveTab: tab,
        });
    };

    handleDayWiseTabClick = (tab) => {
        this.setState({
            daywiseActiveTab: tab,
        });
    };
    handleClick = () => {
        const currentUrl = window.location.href;
        const pageTitle = document.title;
        const message = `Hi, Please send about the Tour Package details "${pageTitle}"`;

        const whatsappLink = `https://api.whatsapp.com/send?phone=+919163676037&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
        window.open(whatsappLink, '_blank');
    };
    render() {
        const { customActiveTab, daywiseActiveTab } = this.state;
        const packageTopBannerImageURL = ApiURL.Arunachal_guwahati_15N_16D_PackageImageURL + "/Arunachal-Pradesh-Package-Tour-from-Guwahati.webp";
        const packageTopBanner2ImageURL = ApiURL.Arunachal_guwahati_15N_16D_PackageImageURL + "/Arunachal-Pradesh-Tour-Package-from-Guwahati.webp";
        const navDay1ImageURL = ApiURL.Arunachal_guwahati_15N_16D_PackageImageURL + "/Meghalaya-Arunachal-Pradesh-Package-Tour.webp";
        const navDay2ImageURL = ApiURL.Arunachal_guwahati_15N_16D_PackageImageURL + "/North-East-Tour-Package-from-Guwahati.webp";
        const navDay4ImageURL = ApiURL.Arunachal_guwahati_15N_16D_PackageImageURL + "/Tawang-Tour-Package-from-Guwahati.webp";
        const navDay5ImageURL = ApiURL.Arunachal_guwahati_15N_16D_PackageImageURL + "/BUMLA-PASS-ARUNACHAL.webp";
        const navDay6ImageURL = ApiURL.Arunachal_guwahati_15N_16D_PackageImageURL + "/Dirang-Tour-Package.webp";
        const navDay7ImageURL = ApiURL.Arunachal_guwahati_15N_16D_PackageImageURL + "/Arunachal-Pradesh-Tour-Package-from-Kolkata.webp";
        const navDay8ImageURL = ApiURL.Arunachal_guwahati_15N_16D_PackageImageURL + "/ITANAGAR-TOUR-PACKAGE.webp";
        const navDay10ImageURL = ApiURL.Arunachal_guwahati_15N_16D_PackageImageURL + "/ZIRO-VALLEY-ARUNACHAL.webp";
        const navDay11ImageURL = ApiURL.Arunachal_guwahati_15N_16D_PackageImageURL + "/PASHIGHAT-TOUR-PACKAGE.webp";
        const navDay12ImageURL = ApiURL.Arunachal_guwahati_15N_16D_PackageImageURL + "/AALO-VALLEY-ARUNACHAL-PACKAGE.webp";
        const navDay14ImageURL = ApiURL.Arunachal_guwahati_15N_16D_PackageImageURL + "/MECHUKA-OFFBEAT-ARUNACHAL.webp";
        
        const TopbannerImageURL = ApiURL.Megalaya_5N_6D_PackageImageURL + "/Assam-Meghalaya-Tour-Plan-from-Guwahati-Airport.webp";



        const settings = {
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const reviewersDataList = this.props.ReviewersData;
        const reviewersDataView = reviewersDataList.map((dataItem, i) => {
            const reviewerImageURL = ApiURL.Arunachal_guwahati_15N_16D_PackageImageURL + "/" + dataItem.ImageName;
            return (
                <div className="testimonial-box" key={i}>
                    <div className="box-top">
                        <div className="profile">
                            <div className="profile-img">
                                <img src={reviewerImageURL} alt="..." />
                            </div>
                            <div className="name-user">
                                <strong>{dataItem.Name}</strong>
                                <span>{dataItem.Location}</span>
                            </div>
                        </div>
                        <div className="reviews">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </div>
                    </div>
                    <div className="client-comment">
                        <p>{dataItem.Comment}</p>
                    </div>
                </div>
            )
        })

        return (
            <>
                <div className="container-fluid">
                    <div className="row">
                        <img src={packageTopBannerImageURL} className="mobile-image2" alt="..." />
                    </div>
                </div>
                <div className="container-fluid custom-container py-3">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="container-fluid custom-container py-1">

                                        <h1 className="content-desktop" style={{ fontSize: "2vw" }}><center>Looking for <b>Arunachal Pradesh Package Tour from Guwahati?</b></center></h1>

                                        <h1 className="content-mobile" style={{ fontSize: "4vw" }}><center>Looking for <b>Arunachal Pradesh Package Tour from Guwahati?</b></center>
                                            <hr />
                                        </h1>
                                        <div className="container-fluid">
                                            <div className="row">
                                                <img src={packageTopBanner2ImageURL} className="mobile-image" alt="..." />
                                            </div>
                                        </div>
                                        <hr />
                                    </div>
                                    <h2 className="content-desktop p-4" style={{ fontSize: "2.0vw" }}> For Booking <b>ARUNACHAL PRADESH TOUR</b> <a href='tel:9163676037' class="tomato">☎ CALL Mr. Akash Dutta  +91- 91636-76037</a></h2>
                                    <h2 className="content-mobile p-4" style={{ fontSize: "4.5vw" }}> For Booking <b>ARUNACHAL PRADESH TOUR</b> <a href='tel:9163676037' class="tomato">☎ CALL Mr. Akash Dutta  +91- 91636-76037</a></h2>

                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> India's Largest <b>North East Tour Operator</b></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}> India's Largest <b>North East Tour Operator</b></h4>
                                        </div>
                                        <div className="card-body">
                                            <p>Discover the wonders of <strong>Arunachal Pradesh</strong> with a carefully crafted package tour starting from Guwahati. Nestled in <strong>India's northeast</strong>, Arunachal Pradesh beckons with its diverse landscapes—snowy peaks, verdant forests, and crystalline lakes—that promise an unforgettable adventure for nature enthusiasts and thrill-seekers alike</p>
                                            <p>Embark on an <strong>Arunachal Pradesh package tour from Guwahati</strong> to immerse yourself in the region's natural and cultural treasures. These tours are meticulously planned to ensure a seamless experience, covering essential aspects such as accommodation, transport, meals, and knowledgeable guides who accompany you every step of the journey.</p>
                                            <p>Begin your journey with the <strong>Arunachal packages tour from Guwahati</strong>, starting with Itanagar, the state capital renowned for its scenic vistas and iconic landmarks like the formidable Ita Fort and the enriching Jawaharlal Nehru State Museum. From there, venture deeper into Arunachal Pradesh to discover Tawang, Bomdila, and Ziro—each offering its own unique charm.</p>
                                            <p>Tawang, perched at 10,000 feet above sea level, captivates with the majestic Tawang Monastery, India's largest Buddhist monastery, set amidst breathtaking natural beauty. Bomdila charms with its apple orchards, traditional crafts, and panoramic views of the Himalayas. Ziro, a UNESCO World Heritage Site, captivates with its vibrant culture and stunning landscapes. Experience all these wonders and more with our <strong>Arunachal tour package</strong>, designed to showcase the best of <strong>Arunachal Pradesh</strong>.
                                            </p>
                                            <p>Beyond these famed locales, your <strong>Arunachal Pradesh package from Guwahati</strong> may unveil lesser-known gems like Namdapha National Park, Mechuka Valley, Pangsau Pass, Daporijo, Along, Zemithang, and Sangti—each offering a glimpse into the untouched beauty and cultural richness of the region</p>
                                            <p>Whether you seek serene natural vistas, cultural immersion, or thrilling adventures, an Arunachal Pradesh tour promises it all. Each destination unveils a new chapter in this land of untamed beauty and cultural heritage, ensuring an enriching and memorable travel experience.</p>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">

                                <div className="card-body">
                                    <p>Here are some of the Arunachal tour packages available, offering unique combinations:</p>
                                    <ul>
                                        <li><strong>1.	Tawang Tour Package from Guwahati:</strong> Explore the serene landscapes and cultural richness of Tawang.</li>
                                        <li><strong>2.	Tawang Ziro Daporijo Along Pasighat Tengapani Tour Package:</strong> Experience a blend of offbeat destinations in Arunachal Pradesh.</li>
                                        <li><strong>3.	Tawang Dirang Shergaon Tour Package:</strong> Discover the natural beauty of Dirang and Shergaon along with Tawang.</li>
                                        <li><strong>4.	Tawang Sangti Valley Tour Package from Guwahati:</strong> Immerse yourself in the tranquil beauty of Sangti Valley with Tawang.</li>
                                        <li><strong>5.	Tawang Zemithang Tour Package:</strong> Visit the picturesque Zemithang and explore its cultural treasures.</li>
                                        <li><strong>6.	Tawang Bumla Pass Tour Package from Guwahati:</strong> Journey to the historic Bumla Pass and witness its breathtaking views.</li>
                                        <li><strong>7.	Complete Arunachal Package Tour from Guwahati:</strong> A comprehensive tour covering major attractions across Arunachal Pradesh.</li>
                                    </ul>
                                    <br />
                                    <p>Additionally, there are options like:</p>
                                    <p><strong>•  Arunachal Pradesh Tour Package from Mumbai, Delhi, and Bangalore:</strong> Tailored packages from major cities for convenient travel.</p>
                                    <p>An Arunachal tour from Guwahati offers an incredible opportunity to explore this beautiful state, which should definitely be on every traveler's bucket list. The region's diverse landscapes—from snow-capped mountains to lush forests and crystal-clear lakes—promise an unforgettable adventure.</p>
                                    <p>Apart from Arunachal Pradesh, Adorable Vacation specializes in Himalayan destinations, offering personalized and flexible tour plans. These include:</p>
                                    <ul>
                                        <li><strong>•	Meghalaya Arunachal Pradesh Package Tour</strong></li>
                                        <li><strong>•	North East Tour Package from Guwahati</strong></li>
                                        <li><strong>•	Assam Meghalaya Arunachal Pradesh Package Tour</strong></li>
                                        <li><strong>•	Kaziranga Shillong Cherrapunji Package Tour from Guwahati</strong></li>
                                        <li><strong>•	Shillong Meghalaya Package Tour from Guwahati</strong></li>
                                        <li><strong>•	Tawang Tour Package from Guwahati</strong></li>
                                        <li><strong>•	Dirang Tour Package</strong></li>
                                        <li><strong>•	Shillong Tour Package</strong></li>
                                        <li><strong>•	Kaziranga Safari Package</strong></li>
                                        <li><strong>•	Kaziranga Tour Package from Guwahati</strong></li>
                                        <li><strong>•	Arunachal Pradesh Tour Package from Kolkata</strong></li>
                                    </ul><br />
                                    <p>Whether you're looking for adventure, cultural immersion, or serene natural beauty, Adorable Vacation has a tour package to suit your preferences. Explore the enchanting Northeast with us and create memories that will last a lifetime.</p>
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr className="table-danger">
                                                    <th>Arunachal Pradesh 15N/16D Tour from Guwahati</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr>
                                                    <td
                                                        className="clickable"
                                                    >
                                                        <a href="#day1" style={{ color: 'black' }}><b>DAY 1 → </b>
                                                            <span>
                                                                Travel from Guwahati to Bhalukpong.
                                                            </span>
                                                        </a>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td
                                                        className="clickable"
                                                    >
                                                        <a href="#day2" style={{ color: 'black' }}>
                                                            <b>DAY 2 → </b>
                                                            <span>  Travel from Bhalukpong to Bomdila.</span>
                                                        </a>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td
                                                        className="clickable"
                                                    >
                                                        <a href="#day3" style={{ color: 'black' }}>
                                                            <b>DAY 3 → </b>
                                                            <span>  Travel from Bomdila to Tawang.</span>
                                                        </a>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td
                                                        className="clickable"
                                                    >
                                                        <a href="#day4" style={{ color: 'black' }}>
                                                            <b>DAY 4 → </b>
                                                            <span>  Explore Tawang</span>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        className="clickable"
                                                    >
                                                        <a href="#day5" style={{ color: 'black' }}>
                                                            <b>DAY 5 → </b>
                                                            <span> Travel from Tawang to Dirang</span>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        className="clickable"
                                                    >
                                                        <a href="#day6" style={{ color: 'black' }}>
                                                            <b>DAY 6 → </b>
                                                            <span> Visit Mandala Top and Thembhang village in Dirang</span>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        className="clickable"
                                                    >
                                                        <a href="#day7" style={{ color: 'black' }}>
                                                            <b>DAY 7 → </b>
                                                            <span> Travel from Dirang to Nameri National Park</span>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        className="clickable"
                                                    >
                                                        <a href="#day8" style={{ color: 'black' }}>
                                                            <b>DAY 8 → </b>
                                                            <span> Travel from Nameri to Itanagar</span>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        className="clickable"
                                                    >
                                                        <a href="#day9" style={{ color: 'black' }}>
                                                            <b>DAY 9 → </b>
                                                            <span> Travel from Itanagar to Ziro</span>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        className="clickable"
                                                    >
                                                        <a href="#day10" style={{ color: 'black' }}>
                                                            <b>DAY 10 → </b>
                                                            <span>  Explore Ziro</span>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        className="clickable"
                                                    >
                                                        <a href="#day11" style={{ color: 'black' }}>
                                                            <b>DAY 11 → </b>
                                                            <span> Travel from Ziro to Pasighat</span>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        className="clickable"
                                                    >
                                                        <a href="#day12" style={{ color: 'black' }}>
                                                            <b>DAY 12 → </b>
                                                            <span>  Travel from Pasighat to Aalo (Along)</span>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        className="clickable"
                                                    >
                                                        <a href="#day13" style={{ color: 'black' }}>
                                                            <b>DAY 13 → </b>
                                                            <span>  Travel from Aalo to Menchuka</span>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        className="clickable"
                                                    >
                                                        <a href="#day14" style={{ color: 'black' }}>
                                                            <b>DAY 14 → </b>
                                                            <span>  Explore Menchuka</span>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        className="clickable"
                                                    >
                                                        <a href="#day15" style={{ color: 'black' }}>
                                                            <b>DAY 15 → </b>
                                                            <span>  Travel from Menchuka to Pasighat</span>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        className="clickable"
                                                    >
                                                        <a href="#day15" style={{ color: 'black' }}>
                                                            <b>DAY 16 → </b>
                                                            <span>  Travel from Pasighat to Dibrugarh Airport</span>
                                                        </a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                            {/* <div className="card">
                            <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> Embark on an extraordinary 15 nights and 16 days journey through Arunachal Pradesh starting from Guwahati</h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}> Embark on an extraordinary 15 nights and 16 days journey through Arunachal Pradesh starting from Guwahati</h4>
                                        </div>
                                <div className="card-body">
                                   <p><b>Bhalukpong (1 night):</b> Commence your adventure with a peaceful stay in Bhalukpong, surrounded by lush greenery and the gurgling Kameng River.</p>
                                   <p><b>Bomdila (1 night):</b> Delve into the tranquil charm of Bomdila, nestled amidst breathtaking landscapes and adorned with Buddhist monasteries.</p>
                                   <p><b>Dirang (2 nights):</b> Immerse yourself in the serene ambiance of Dirang, where verdant valleys and stunning views of the Eastern Himalayas await.</p>
                                </div>
                            </div>
                            <br /><br /> */}
                            <br />
                            <div className="card">
                                <div className="card-header custHeaer">
                                    <h3 className='content-desktop' style={{ fontSize: "1.5vw" }}>Discover Arunachal Pradesh <b>A Journey through Natural Wonders and Cultural Riches</b></h3>
                                    <h3 className='content-mobile2' style={{ fontSize: "3.5vw" }}>Discover Arunachal Pradesh <b>A Journey through Natural Wonders and Cultural Riches</b></h3>

                                </div>

                                <div className="card-body">
                                    <p>Arunachal Pradesh, nestled in India's northeastern corner, invites travelers with its breathtaking landscapes, vibrant cultures, and serene beauty. Here's why it deserves a spot on your travel list:</p>
                                    <p><strong>Tawang:</strong> Home to the majestic Tawang Monastery, India’s largest and one of the world’s largest, offering stunning views of the Indo-China border.</p>
                                    <p><strong>Ziro Valley:</strong> A UNESCO World Heritage Site known for its picturesque landscapes and Apatani tribal culture.</p>
                                    <p><strong>Sela Pass:</strong> Known for its majestic mountain views and challenging terrain.</p>
                                    <p><strong>Namdapha National Park:</strong> A biodiversity hotspot with diverse flora and fauna.</p>
                                    <p><strong>Bomdila and Dirang:</strong> Tranquil hill stations offering serene environments and cultural insights.</p>
                                    <p><strong>Gorichen Peak:</strong> Each adding a unique charm to the state’s allure,Cultural and Natural Treasures.
                                    </p>
                                    <p><strong>Tribal Cultures: </strong>Explore the rich heritage of Arunachal Pradesh through its indigenous tribal communities.
                                    </p>
                                    <p><strong>Buddhist Heritage:</strong> Discover ancient monasteries nestled amidst pristine landscapes.</p>
                                    <p><strong>Scenic Beauty:</strong> Pristine lakes, lofty mountains, and charming villages epitomize the state's natural serenity.</p>
                                </div>
                            </div>
                            <br />
                            {/* Arunachal weather start */}
                            <div className="card">
                                <div className="card-header custHeaer">
                                    <h3 className='content-desktop' style={{ fontSize: "1.5vw" }}><b>Arunachal Pradesh</b> Best Time to Visit </h3>
                                    <h3 className='content-mobile2' style={{ fontSize: "3.5vw" }}><b>Arunachal Pradesh</b> Best Time to Visit</h3>

                                </div>

                                <div className="card-body">
                                    <p><strong>Mid-September to October:</strong> Pleasant weather ideal for exploring Arunachal Pradesh’s famous attractions comfortably.</p>
                                    <p><strong>Winter (October to February):</strong> Experience snow-clad mountains and chilly landscapes, perfect for winter enthusiasts.
                                    </p>
                                    <p><strong>Kaziranga National Park:</strong> Visit during winter to explore both Arunachal Pradesh and Assam's famous wildlife sanctuary.

                                        Arunachal Pradesh promises an unforgettable journey with its unparalleled beauty and cultural diversity, offering every traveler a slice of unspoiled paradise in India.
                                    </p>
                                    <h5><strong>Weather Details </strong></h5><br />
                                    <p><strong>March to June (Summer):</strong><br /><br />
                                        <strong>Temperature:</strong> Daytime temperatures range from 10°C to 30°C.<br /><br />
                                        <strong>Description:</strong> Summer in Arunachal Pradesh is moderate to warm, ideal for outdoor activities and sightseeing. Higher-altitude regions like Tawang and Bomdila are lush and green, perfect for trekking and wildlife viewing in places such as Namdapha National Park.
                                    </p><br /><br />
                                    <p><strong>July to October (Monsoon):</strong><br /><br />
                                        <strong>Temperature:</strong> Temperatures range from 18°C to 32°C.<br /><br />
                                        <strong>Description:</strong> Monsoon brings heavy rainfall, transforming the region into lush green landscapes with abundant rivers and waterfalls. While the scenery is stunning, travel may be affected by landslides and road closures. Checking weather forecasts and road conditions is advisable during this period.
                                    </p><br /><br />
                                    <p><strong>July to October (Monsoon):</strong><br /><br />
                                        <strong>Temperature:</strong> Temperatures range from 18°C to 32°C.<br /><br />
                                        <strong>Description:</strong> Monsoon brings heavy rainfall, transforming the region into lush green landscapes with abundant rivers and waterfalls. While the scenery is stunning, travel may be affected by landslides and road closures. Checking weather forecasts and road conditions is advisable during this period.
                                    </p><br /><br />
                                </div>
                            </div>
                            <br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> Adorable Vacation is the top choice for <strong>Arunachal tour packages</strong> near you for several reasons</h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}> Adorable Vacation is the top choice for <strong>Arunachal tour packages</strong> near you for several reasons</h4>
                                </div>
                                <div className="card-body">
                                    <p><strong>Assured Room Inventory :</strong> Adorable Vacation maintains a diverse selection of pre-purchased rooms across Arunachal Pradesh. This ensures that depending on your booking date and preferences, you are guaranteed rooms with stunning views of valleys, mountains, or rivers. Whether you prefer serene landscapes or vibrant cityscapes, we cater to your accommodation needs with reliability and comfort.</p>
                                    <p><strong>Premium Accommodation Options :</strong> Experience the best during your Arunachal tour with our premium and deluxe hotel choices or luxurious home stays. We prioritize quality and comfort, ensuring that each accommodation option is equipped with top-notch facilities and amenities. Whether you seek a serene retreat amidst nature or a luxurious stay with modern conveniences, our packages are tailored to exceed your expectations.</p>
                                    <p><b>Convenient Transportation Services:</b> We operate your transportation needs seamlessly throughout your Arunachal tour from key locations such as Guwahati, Tezpur, Dibrugarh, Itanagar, and Jorhat. This ensures hassle-free travel arrangements, allowing you to focus on enjoying the scenic beauty and cultural richness of Arunachal Pradesh without logistical worries.</p>
                                    <p><b>Variety of Vehicle Options:</b> Depending on your budget and preferences, choose from a range of vehicles including comfortable models like Innova, Ertiga, Scorpio, or the stylish XUV 500 with a sunroof. Our fleet is well-maintained and offers both comfort and safety, ensuring a pleasant journey throughout your exploration of Arunachal Pradesh.</p>
                                    <p><strong>Dedicated and Specialized Staff: </strong>
                                        Our team comprises passionate individuals from the North East region who are experts in Arunachal tour operations. With a dedicated war room for immediate responses and specialized training in customer service, we ensure uninterrupted support and assistance throughout your journey. Your comfort and satisfaction are our top priorities.
                                    </p>
                                    <p><strong>Expert Guidance and Safety Measures: </strong>
                                        As leading Arunachal specialists, we provide expert guidance tailored to seasonal variations, peak travel times, and weather conditions. Whether you're traveling during the bustling peak season or prefer the tranquility of the off-season, we offer informed advice to optimize your travel experience while safeguarding your safety and travel budget.
                                    </p>
                                    <p><strong>Years of Experience and Reputation: </strong>
                                        With over 11 years of specialization in Arunachal tour packages from Guwahati, Adorable Vacation has established a strong reputation for excellence. Our extensive experience allows us to curate memorable experiences, offering deluxe homestays, premium stays at renowned hotels, luxury boutique resorts, and unique properties that reflect the essence of Arunachal Pradesh
                                    </p>
                                    <p><strong>Certified Accommodations and Quality Assurance: </strong>
                                        Each hotel and homestay recommended by Adorable Vacation is personally vetted and certified by our team of experienced travelers. We prioritize quality assurance to ensure that your accommodation not only meets but exceeds your expectations, providing comfort, cleanliness, and authentic local experiences.
                                    </p>
                                    <p><strong>Specialization in Group Tours: </strong>
                                        We excel in organizing Arunachal group tours, catering to travelers seeking shared experiences and camaraderie. Our scheduled departures during peak travel seasons and festive holidays such as Durga Puja and Diwali ensure that you can join like-minded individuals in exploring the diverse landscapes and cultural heritage of Arunachal Pradesh.
                                    </p>
                                    <p><strong>Personalized Service and On-field Representation:</strong>
                                        From the moment you embark on your journey from Guwahati to your return, Adorable Vacation offers personalized service. Our dedicated team provides day-to-day guidance and on-field representation, ensuring that every aspect of your Arunachal trip is handled directly by us. Unlike other operators who may delegate responsibilities to sub-agents, we maintain direct oversight to guarantee a seamless and enjoyable travel experience.
                                    </p>
                                    <p>At Adorable Vacation, we are committed to providing you with an unforgettable Arunachal tour experience characterized by comfort, safety, and personalized service. Whether you're a solo traveler, a family, or a group of friends, we are your trusted partner in exploring the natural beauty and cultural diversity of Arunachal Pradesh.</p>
                                </div>
                            </div>
                            <br /><br />
                            <div className="container-fluid navtabCustom">
                                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                    <button onClick={() => this.handleCustomTabClick('tab1')} className={`tab button ${customActiveTab === 'tab1' ? 'active' : ''}`} role="tab" aria-controls="nav-price" aria-selected={customActiveTab === 'tab1'}>Price</button>
                                    <button onClick={() => this.handleCustomTabClick('tab3')} className={`tab button ${customActiveTab === 'tab3' ? 'active' : ''}`} role="tab" aria-controls="nav-travellers-info" aria-selected={customActiveTab === 'tab3'}>Travellers Info</button>
                                    <button onClick={() => this.handleCustomTabClick('tab4')} className={`tab button ${customActiveTab === 'tab4' ? 'active' : ''}`} role="tab" aria-controls="nav-flight" aria-selected={customActiveTab === 'tab4'}>Flight</button>
                                </div>
                                <div className="tab-content" id="nav-tabContent">
                                    <div className={customActiveTab === 'tab1' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-price-tab">
                                        <div id="no-more-tables">
                                        <table class="col-md-12 table-bordered table-striped table-condensed cf">
                                                <thead class="cf">
                                                    <tr className="table-primary text-center">
                                                        <th colSpan="5"><h4 style={{ fontSize: "1.5vw" }} className="content-desktop"><strong>ARUNACHAL PRADESH TOUR PACKAGE COST</strong></h4></th>
                                                    </tr>
                                                    <h4 style={{ fontSize: "3.5vw" }} className="content-mobile2 tomato"><strong>ARUNACHAL PRADESH TOUR PACKAGE COST</strong></h4>
                                                    <tr className="table-danger">
                                                        <th>Package</th>
                                                        <th>02 Pax (INR)</th>
                                                        <th>04 Pax (INR)</th>
                                                        <th>06 Pax (INR)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td data-title="Category">15N/16D</td>
                                                        <td data-title="02 Pax (INR)">73,000 PP</td>
                                                        <td data-title="04 Pax (INR)">65,000 PP</td>
                                                        <td data-title="06 Pax (INR)">49,000 PP</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div className={customActiveTab === 'tab3' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-travellers-info-tab">
                                        <h5><b>For your Arunachal tour package starting from Guwahati, here's a list of essential items to carry:</b></h5>
                                        <p><strong>Documents:</strong> Inner Line Permit (ILP) obtained with original Voter Card, Aadhar card, or Driving License. Carry passport-sized photos and printouts of your tour details for convenience.</p>
                                        <p><strong>Sunscreen Lotion & Moisturizers:</strong> Protect your skin from sun rays during the day and cold at night. Carry adequate sunscreen lotion and moisturizers.</p>
                                        <p><strong>Camera with Extra Batteries:</strong> Ensure you have spare batteries for your camera. It's challenging to find shops in remote Arunachal locations for battery replacements.</p>
                                        <p><strong>Clothing:</strong> Pack heavy woolen clothes, a heavy jacket, and light clothing. Arunachal's weather is unpredictable, ranging from rain and wind to chilly breezes.</p>
                                        <p><strong>Shoes:</strong> Waterproof shoes and socks are essential due to the varied weather conditions. Pack slippers for light walking or relaxing.</p>
                                        <p><strong>Medicines:</strong> Include essential medicines such as paracetamol, pain relief balm or spray, headache tablets, motion sickness remedies, anti-allergic medicine, cough syrup, and medications for stomach issues like vomiting, loose motion, acidity, and gas.</p>
                                        <p><strong>Additional Essentials:</strong> Camphor, inhaler (if needed), sunglasses, tripod for your camera, rain gear (raincoat and umbrella), Arunachal tourism map, spare pairs of socks, underwear, and slippers.</p>
                                        <p>By packing these essentials, you'll be well-prepared to enjoy your trip to Arunachal Pradesh comfortably and safely.</p>

                                        <br /><br />
                                    </div >
                                    <div className={customActiveTab === 'tab4' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-flight-tab">
                                        <span><b className="tomato">Flights from Kolkata [CCU] to Guwahati [GAU]</b></span>
                                        <br /><br />
                                        <div id="no-more-tables">
                                            <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                <thead className="cf">
                                                    <tr className="table-danger">
                                                        <th>AIRLINES</th>
                                                        <th>DEPARTURE TIME</th>
                                                        <th>ARRIVAL TIME</th>
                                                        <th>DURATION</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td data-title="AIRLINES">IndiGo</td>
                                                        <td data-title="DEPARTURE TIME">07:25</td>
                                                        <td data-title="ARRIVAL TIME">08:45</td>
                                                        <td data-title="DURATION">01h 20m(non-stop)</td>
                                                    </tr>
                                                    <tr>
                                                        <td data-title="AIRLINES">IndiGo</td>
                                                        <td data-title="DEPARTURE TIME">10:05</td>
                                                        <td data-title="ARRIVAL TIME">11:55</td>
                                                        <td data-title="DURATION">01h 50m(non-stop)</td>
                                                    </tr>
                                                    <tr>
                                                        <td data-title="AIRLINES">Air India</td>
                                                        <td data-title="DEPARTURE TIME">05:45</td>
                                                        <td data-title="ARRIVAL TIME">07:00</td>
                                                        <td data-title="DURATION">01h 15m(non-stop)</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <br />
                                        <span><b className="tomato">Flights from Mumbai [BOM] to Guwahati [GAU]</b></span>
                                        <br /><br />
                                        <div id="no-more-tables">
                                            <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                <thead className="cf">
                                                    <tr className="table-danger">
                                                        <th>AIRLINES</th>
                                                        <th>DEPARTURE TIME</th>
                                                        <th>ARRIVAL TIME</th>
                                                        <th>DURATION</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td data-title="AIRLINES">IndiGo</td>
                                                        <td data-title="DEPARTURE TIME">06:25</td>
                                                        <td data-title="ARRIVAL TIME">09:20</td>
                                                        <td data-title="DURATION">02h 55m(non-stop)</td>
                                                    </tr>
                                                    <tr>
                                                        <td data-title="AIRLINES">SpiceJet</td>
                                                        <td data-title="DEPARTURE TIME">06:45</td>
                                                        <td data-title="ARRIVAL TIME">10:00</td>
                                                        <td data-title="DURATION">03h 15m(non-stop)</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <br />
                                        <span><b className="tomato">Flights from Delhi [DEL] to Guwahati [GAU]</b></span>
                                        <br /><br />
                                        <div id="no-more-tables">
                                            <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                <thead className="cf">
                                                    <tr className="table-danger">
                                                        <th>AIRLINES</th>
                                                        <th>DEPARTURE TIME</th>
                                                        <th>ARRIVAL TIME</th>
                                                        <th>DURATION</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td data-title="AIRLINES">AirAsia</td>
                                                        <td data-title="DEPARTURE TIME">06:05</td>
                                                        <td data-title="ARRIVAL TIME">08:25</td>
                                                        <td data-title="DURATION">02h 20m(non-stop)</td>
                                                    </tr>
                                                    <tr>
                                                        <td data-title="AIRLINES">AirAsia</td>
                                                        <td data-title="DEPARTURE TIME">07:15</td>
                                                        <td data-title="ARRIVAL TIME">09:40</td>
                                                        <td data-title="DURATION">02h 25m(non-stop)</td>
                                                    </tr>
                                                    <tr>
                                                        <td data-title="AIRLINES">IndiGo</td>
                                                        <td data-title="DEPARTURE TIME">05:00</td>
                                                        <td data-title="ARRIVAL TIME">07:20</td>
                                                        <td data-title="DURATION">02h 20m(non-stop)</td>
                                                    </tr>
                                                    <tr>
                                                        <td data-title="AIRLINES">IndiGo</td>
                                                        <td data-title="DEPARTURE TIME">07:25</td>
                                                        <td data-title="ARRIVAL TIME">09:45</td>
                                                        <td data-title="DURATION">02h 20m(non-stop)</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <br />
                                        <span><b className="tomato">Flights from Bangalore [BLR] to Guwahati [GAU]</b></span>
                                        <br /><br />
                                        <div id="no-more-tables">
                                            <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                <thead className="cf">
                                                    <tr className="table-danger">
                                                        <th>AIRLINES</th>
                                                        <th>DEPARTURE TIME</th>
                                                        <th>ARRIVAL TIME</th>
                                                        <th>DURATION</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td data-title="AIRLINES">AirAsia</td>
                                                        <td data-title="DEPARTURE TIME">08:25</td>
                                                        <td data-title="ARRIVAL TIME">11:20</td>
                                                        <td data-title="DURATION">02h 55m(non-stop)</td>
                                                    </tr>
                                                    <tr>
                                                        <td data-title="AIRLINES">IndiGo</td>
                                                        <td data-title="DEPARTURE TIME">08:20</td>
                                                        <td data-title="ARRIVAL TIME">11:20</td>
                                                        <td data-title="DURATION">03h 00m(non-stop)</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                        <br />
                                        <span><b className="tomato">Flights from Bangalore [BLR] to Guwahati [GAU]</b></span>
                                        <br /><br />
                                        <div id="no-more-tables">
                                            <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                <thead className="cf">
                                                    <tr className="table-danger">
                                                        <th>AIRLINES</th>
                                                        <th>DEPARTURE TIME</th>
                                                        <th>ARRIVAL TIME</th>
                                                        <th>DURATION</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td data-title="AIRLINES">AirAsia</td>
                                                        <td data-title="DEPARTURE TIME">08:25</td>
                                                        <td data-title="ARRIVAL TIME">11:20</td>
                                                        <td data-title="DURATION">02h 55m(non-stop)</td>
                                                    </tr>
                                                    <tr>
                                                        <td data-title="AIRLINES">IndiGo</td>
                                                        <td data-title="DEPARTURE TIME">08:20</td>
                                                        <td data-title="ARRIVAL TIME">11:20</td>
                                                        <td data-title="DURATION">03h 00m(non-stop)</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                        <br />
                                        <span><b className="tomato">Flights from Bangalore [BLR] to Guwahati [GAU]</b></span>
                                        <br /><br />
                                        <div id="no-more-tables">
                                            <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                <thead className="cf">
                                                    <tr className="table-danger">
                                                        <th>AIRLINES</th>
                                                        <th>DEPARTURE TIME</th>
                                                        <th>ARRIVAL TIME</th>
                                                        <th>DURATION</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td data-title="AIRLINES">AirAsia</td>
                                                        <td data-title="DEPARTURE TIME">08:25</td>
                                                        <td data-title="ARRIVAL TIME">11:20</td>
                                                        <td data-title="DURATION">02h 55m(non-stop)</td>
                                                    </tr>
                                                    <tr>
                                                        <td data-title="AIRLINES">IndiGo</td>
                                                        <td data-title="DEPARTURE TIME">08:20</td>
                                                        <td data-title="ARRIVAL TIME">11:20</td>
                                                        <td data-title="DURATION">03h 00m(non-stop)</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                        <br />
                                        <span><b className="tomato">Flights from Chennai [MAA] to Guwahati [GAU]</b></span>
                                        <br />
                                        <div id="no-more-tables">
                                            <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                <thead className="cf">
                                                    <tr className="table-danger">
                                                        <th>AIRLINES</th>
                                                        <th>DEPARTURE TIME</th>
                                                        <th>ARRIVAL TIME</th>
                                                        <th>DURATION</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td data-title="AIRLINES">IndiGo</td>
                                                        <td data-title="DEPARTURE TIME">05:30</td>
                                                        <td data-title="ARRIVAL TIME">08:15</td>
                                                        <td data-title="DURATION">02h 45m(non-stop)</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                        <br />
                                    </div>

                                </div>
                            </div>
                            {/* Arunachal weather start */}
                            <br />
                            {/* <!--By Itinerary--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h3 className="content-desktop" style={{ fontSize: "2.0vw" }}>
                                                DAY WISE DETAIL <strong style={{ color: 'tomato' }}>ARUNACHAL TOUR ITINERARY FROM GUWAHATI</strong>
                                            </h3>
                                            <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>
                                                DAY WISE DETAIL <strong>ARUNACHAL TOUR ITINERARY FROM GUWAHATI</strong>
                                            </h3>
                                        </div>
                                    </div>
                                </div>

                                <br />
                                <div className="row">
                                    <div
                                        className="accordion"
                                        id="accordionPanelsStayOpen"
                                    >
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day1"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseOne"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseOne"
                                                >
                                                    DAY 1 : GUWAHATI TO BHALUKPONG [4 HR 57 MIN | 234.4 KM]
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseOne"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingOne"
                                            >
                                                <div className="accordion-body">
                                                    <p>Upon arrival at Guwahati Airport, the gateway to your <strong>Arunachal package tour from Guwahati Airport</strong>, you'll receive a warm welcome. From there, embark on a scenic journey towards Bhalukpong, nestled in the West Kameng District along the Kameng River.</p>

                                                    <p>Bhalukpong, at an altitude of 213m, captivates with its lush evergreen forests, serene river vistas, and charming hamlets. It's an ideal introduction to Arunachal Pradesh's natural beauty.<br /> <b>Upon arrival, settle into your hotel for an overnight stay.</b></p>
<br/>
                                                    <div className="content-desktop">
                                                        <center><img src={navDay1ImageURL} alt="..." /></center>
                                                    </div>
                                                    <div className="content-mobile2">
                                                        <center><img src={navDay1ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day2"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseTwo"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseTwo"
                                                >
                                                    Day 2: BHALUKPONG TO BOMDILA [2 HR 54 MIN / 96.0 KM]
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseTwo"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingTwo"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        Start your morning with a refreshing cup of tea in Bhalukpong before heading towards Bomdila, located at 2415m altitude. Bomdila offers panoramic views of the Himalayas, enchanting landscapes, and abundant apple orchards, making it a photographer's delight. The scenic 4-hour drive ensures you absorb every bit of the Himalayan terrain before reaching <b>Bomdila for an overnight stay</b>.
                                                    </p>
<br/>
                                                    <div className="content-desktop">
                                                        <center><img src={navDay2ImageURL} alt="..." /></center>
                                                    </div>
                                                    <div className="content-mobile2">
                                                        <center><img src={navDay2ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day3"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseThree"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    Day 3: BOMDILA TO TAWANG TOUR [5 HR 47 MIN | 175.7 KM]
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseThree"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingThree"
                                            >
                                                <div className="accordion-body">
                                                    <p>Today, early morning, fuel up with a hearty breakfast as you embark on a 5-hour journey to Tawang. En route, visit: </p>
                                                    <ul>
                                                        <li><b>Sela Pass:</b> At 4170m, offering stunning views of the Eastern Himalayas.</li>
                                                        <li><b>Paradise Lake:</b> A serene spot near Sela Pass.</li>
                                                        <li><b>Jaswant Garh War Memorial:</b> Tribute to Jaswant Singh Rawat.</li>
                                                        <li><strong>Nuranang Fall:</strong> Also known as Jung Waterfalls.</li>
                                                        <li><strong>Upon arrival in Tawang, check into your hotel for an overnight stay.</strong></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day4"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseThree"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    Day 4: TAWANG LOCAL SIGHTSEEING
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseThree"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingThree"
                                            >
                                                <div className="accordion-body">
                                                    <p>Explore Tawang after breakfast: </p>
                                                    <ul>
                                                        <li><strong>• Tawang Monastery:</strong> Largest in India, second largest globally.</li>
                                                        <li><b>• Craft Centre:</b> Government-run shop for authentic handicrafts.</li>
                                                        <li><b>• Tawang War Memorial:</b> Honoring soldiers of the 1962 war.</li>
                                                        <li><strong>•	PT Tso Lake:</strong> Crystalline freshwater lake.</li>
                                                        <li><strong>•	Bumla Pass:</strong> Border meeting point with China (special permit required).</li>
                                                        <li><strong>•	Sangetsar Lake:</strong> Named after Bollywood actress Madhuri Dixit.</li>
                                                        <b>Return to Tawang for an overnight stay.</b>
                                                    </ul>
                                                    <br/>
                                                    <div className="content-desktop">
                                                        <center><img src={navDay4ImageURL} alt="..." /></center>
                                                    </div>
                                                    <div className="content-mobile2">
                                                        <center><img src={navDay4ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day5"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseThree"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    Day 5: TAWANG TO DIRANG TOUR [19 HR 5 MIN | 668.5 KM]
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseThree"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingThree"
                                            >
                                                <div className="accordion-body">
                                                    <p>After breakfast, journey towards Dirang, a quaint village on the banks of River Kameng. Check into your hotel upon arrival for an overnight stay.</p>
                                                    <br/>
                                                    <div className="content-desktop">
                                                        <center><img src={navDay5ImageURL} alt="..." /></center>
                                                    </div>
                                                    <div className="content-mobile2">
                                                        <center><img src={navDay5ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day6"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseThree"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    Day 6: DIRANG DAY EXCURSION TO MANDALA TOP AND THEMBHANG VILLAGE
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseThree"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingThree"
                                            >
                                                <div className="accordion-body">
                                                    <p>Explore Mandala Top's Buddhist stupas and Thembhang Village, known for its ancient heritage. Return to Dirang for an overnight stay.</p>
                                                    <br/>
                                                    <div className="content-desktop">
                                                        <center><img src={navDay6ImageURL} alt="..." /></center>
                                                    </div>
                                                    <div className="content-mobile2">
                                                        <center><img src={navDay6ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day7"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseThree"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    Day 7: DIRANG TO NAMERI [4 HR 33 MIN / 164.6 KM]
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseThree"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingThree"
                                            >
                                                <div className="accordion-body">
                                                    <p>Travel to Bhalukpong/Nameri, at the foothills of the Eastern Himalayas. Check into your hotel for an overnight stay.</p>
                                                    <br/>
                                                    <div className="content-desktop">
                                                        <center><img src={navDay7ImageURL} alt="..." /></center>
                                                    </div>
                                                    <div className="content-mobile2">
                                                        <center><img src={navDay7ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day8"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseThree"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    Day 8: NAMERI TO ITANAGAR [3 HR 51 MIN | 153.4 KM]
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseThree"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingThree"
                                            >
                                                <div className="accordion-body">
                                                    <p>Journey to Itanagar, the capital city of Arunachal Pradesh. Explore Ita Fort if time permits before an overnight stay.</p>
                                                    <br/>
                                                    <div className="content-desktop">
                                                        <center><img src={navDay8ImageURL} alt="..." /></center>
                                                    </div>
                                                    <div className="content-mobile2">
                                                        <center><img src={navDay8ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day9"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseThree"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    Day 9: ITANAGAR TO ZIRO [4 HR 17 MIN | 109.5 KM]
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseThree"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingThree"
                                            >
                                                <div className="accordion-body">
                                                    <p>Travel to Ziro, known for its scenic beauty and Apatani tribe. Check into your hotel for an overnight stay.</p>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day10"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseThree"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    Day 10: Explore Apatani tribal life and culture in Ziro. Return to your hotel for an overnight stay.
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseThree"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingThree"
                                            >
                                                <div className="accordion-body">
                                                    <p>Travel to Ziro, known for its scenic beauty and Apatani tribe. Check into your hotel for an overnight stay.</p>
                                                    <br/>
                                                    <div className="content-desktop">
                                                        <center><img src={navDay10ImageURL} alt="..." /></center>
                                                    </div>
                                                    <div className="content-mobile2">
                                                        <center><img src={navDay10ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day11"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseThree"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    Day 11: ZIRO TO PASIGHAT [8 HR 5 MIN | 309.8 KM]
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseThree"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingThree"
                                            >
                                                <div className="accordion-body">
                                                    <p>Drive to Pasighat, known for its scenic beauty and riverside views. Check into your hotel for an overnight stay.</p>
                                                    <br/>
                                                    <div className="content-desktop">
                                                        <center><img src={navDay11ImageURL} alt="..." /></center>
                                                    </div>
                                                    <div className="content-mobile2">
                                                        <center><img src={navDay11ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day12"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseThree"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    Day 12: PASIGHAT TO AALO [3 HR 26 MIN | 100.7 KM]
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseThree"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingThree"
                                            >
                                                <div className="accordion-body">
                                                    <p>Journey to Aalo, surrounded by orange orchards and picturesque landscapes. Overnight stay in Aalo.</p>
                                                    <br/>
                                                    <div className="content-desktop">
                                                        <center><img src={navDay12ImageURL} alt="..." /></center>
                                                    </div>
                                                    <div className="content-mobile2">
                                                        <center><img src={navDay12ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day13"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseThree"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    Day 13: AALO TO MENCHUKA [186 KM | 4.5 Hrs]
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseThree"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingThree"
                                            >
                                                <div className="accordion-body">
                                                    <p>Travel to Mechuka Valley, known for its pristine beauty and serene surroundings. Check into your hotel for an overnight stay.</p>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day14"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseThree"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    Day 14: MENCHUKA FULL DAY SIGHTSEEING
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseThree"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingThree"
                                            >
                                                <div className="accordion-body">
                                                    <p>Explore Mechuka Valley's landscapes and Siyom River. Return to your hotel for an overnight stay.</p>
                                                    <br/>
                                                    <div className="content-desktop">
                                                        <center><img src={navDay14ImageURL} alt="..." /></center>
                                                    </div>
                                                    <div className="content-mobile2">
                                                        <center><img src={navDay14ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day15"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseThree"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    Day 15: MENCHUKA TO PASIGHAT [286 KM | 7 Hrs]
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseThree"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingThree"
                                            >
                                                <div className="accordion-body">
                                                    <p>Return journey to Pasighat for your final stopover. Check into your hotel for an overnight stay.</p>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day16"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseThree"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    Day 16: DROP TO DIBRUGARH AIRPORT [3 HRS]
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseThree"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingThree"
                                            >
                                                <div className="accordion-body">
                                                    <p>After breakfast, depart for Dibrugarh Airport, concluding your memorable Arunachal Pradesh tour that began at Guwahati Airport.</p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!--By Itinerary--> */}
                            <br />
                            {/* <!--insert table inclusion exclusion--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        {
                                            <InclusionExclusion />
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* <!--end table inclusion exclusion--> */}
                            <br />
                            {/* <!--start of package details--> */}
                            <div className="card">
                                <div className="card-header custHeaer">
                                    <h3 className='content-desktop' style={{ fontSize: "1.5vw" }}>Explore Arunachal Pradesh's beauty gateway to Northeast India's stunning destinations </h3>
                                    <h3 className='content-mobile2' style={{ fontSize: "3.5vw" }}>Explore Arunachal Pradesh's beauty gateway to Northeast India's stunning destinations </h3>

                                </div>

                                <div className="card-body">
                                    <p><strong>•	Bhalukpong</strong> <br />
                                        <b>Activities:</b> Enjoy adventure activities like trekking, river rafting in the Kameng River, and wildlife spotting in Pakhui Wildlife Sanctuary.<br />
                                        <b>Attractions:</b> Visit Tipi Orchid Research Center to see a variety of orchids, and explore the local Nyishi tribal culture.

                                    </p>
                                    <p><strong>•	Bomdila:</strong><br />
                                        <b>Activities:</b> Experience the serene environment, visit craft centers, and indulge in short hikes around the town.<br />
                                        Attractions: Explore Bomdila Monastery, Upper Gompa, and soak in the panoramic views of the Himalayan landscapes.

                                    </p>
                                    <p><strong>•	Tawang: </strong><br />
                                        <b>Activities:</b> Visit Tawang Monastery, Tawang War Memorial, and enjoy views of Sela Pass and Sela Lake.<br />
                                        Attractions: Discover the birthplace of the 6th Dalai Lama at Urgelling Monastery and explore local markets for handicrafts.

                                    </p>
                                    <p><strong>•	Dirang: </strong><br />
                                        <b>Activities:</b> Relax in hot water springs, visit Dirang Dzong, and explore apple orchards and kiwi farms.
                                        Attractions: Trek to Sangti Valley, and visit Dirang Monastery and Kalachakra Gompa.
                                    </p>
                                    <p><strong>•	Nameri National Park: </strong>
                                        <b>Activities:</b> Enjoy wildlife safari, bird watching, river rafting on Jia Bhoroli River, and hiking in the forest.
                                        Attractions: Spot diverse flora and fauna including tigers, elephants, and rare bird species in their natural habitat.
                                    </p>
                                    <p><strong>•	Itanagar: </strong>
                                        <b>Activities:</b> Explore Ita Fort, Gompa Buddha Vihar, and Indira Gandhi Park.
                                        Attractions: Visit Jawaharlal Nehru Museum, and experience local culture and cuisine in Itanagar Market.
                                    </p>
                                    <p><strong>•	Ziro: </strong>
                                        <b>Activities:</b> Attend the Ziro Music Festival (if timed right), trek to Meghna Cave Temple, and explore paddy fields.
                                        Attractions: Visit Apatani villages, experience traditional Apatani culture, and trek to Talley Valley Wildlife Sanctuary.
                                    </p>
                                    <p><strong>•	Along (Aalo):</strong>
                                        <b>Activities:</b> Visit Kabu village, explore local Galo culture, and enjoy scenic views of Siyom River.
                                        Attractions: Participate in Mopin and other local festivals, visit Ramakrishna Ashram, and interact with the Galo tribe.
                                    </p>
                                    <p><strong>•	Mechuka:</strong>
                                        <b>Activities:</b> Trek to Samden Yongcha Monastery, visit the Indo-China border at Galling, and enjoy paragliding.
                                        Attractions: Explore local Memba tribal culture, and experience Mechuka Valley's pristine beauty and tranquility.
                                    </p>
                                    <p><strong>•	Dibrugarh (Transit): </strong>
                                        <b>Activities:</b> Explore Dibrugarh's tea estates, take a river cruise on Brahmaputra River, and visit local temples.
                                        Attractions: Learn about Assam's tea industry at Tocklai Tea Research Institute, and enjoy Assamese cuisine.
                                    </p>

                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div className="card-header custHeaer">
                                    <h3 className='content-desktop' style={{ fontSize: "1.5vw" }}>ADVENTURE ACTIVITIES TO INDULGE WITH <strong>ARUNACHAL TOUR PLAN FROM GUWAHATI?</strong> </h3>
                                    <h3 className='content-mobile2' style={{ fontSize: "3.5vw" }}>ADVENTURE ACTIVITIES TO INDULGE WITH <strong>ARUNACHAL TOUR PLAN FROM GUWAHATI?</strong></h3>

                                </div>

                                <div className="card-body">
                                    <p>Arunachal Pradesh's diverse landscapes and vibrant cultures make it an adventure seeker's paradise, with activities catering to various levels of expertise and preferences.<br />
                                        Embarking on an Arunachal Pradesh tour from Guwahati offers thrilling opportunities for adventure enthusiasts. Here are some exciting adventure activities you can indulge in along your journey:

                                    </p>
                                    <p><strong>1.	Trekking:</strong><br />
                                        <p>Arunachal Pradesh is blessed with stunning trekking routes amidst pristine Himalayan landscapes. Some popular treks include:</p>
                                        <ul>
                                            <li><strong>Gorichen Peak Trek</strong> near Tawang, offering breathtaking views of the Eastern Himalayas.</li>
                                            <li><strong>Eaglenest Wildlife Sanctuary Trek</strong> near Bomdila, renowned for its diverse flora and fauna.</li>
                                            <li><strong>Sangti Valley Trek</strong> near Dirang, with trails passing through lush forests and picturesque valleys.</li>
                                        </ul>

                                    </p>
                                    <p><strong>2.	River Rafting:</strong><br />
                                        <p>Experience adrenaline-pumping river rafting in the swift-flowing rivers of Arunachal Pradesh:</p>
                                        <ul>
                                            <li><b>Kameng River</b> near Bhalukpong offers thrilling rapids suitable for both beginners and experienced rafters.</li>
                                            <li><strong>Siang River (Brahmaputra)</strong> near Pasighat, known for its challenging rapids and scenic landscapes.</li>
                                        </ul>
                                    </p>
                                    <p><strong>3.	Wildlife Safari: </strong></p><br />
                                    <p>Explore the rich biodiversity of Arunachal Pradesh through wildlife safaris:</p>
                                    <ul>
                                        <li><strong>Nameri National Park:</strong> Spot tigers, elephants, and a variety of bird species during jeep safaris and nature walks.</li>
                                        <li><strong>Eaglenest Wildlife Sanctuary:</strong> Witness diverse wildlife including the endangered Bengal tiger and exotic bird species.</li>
                                    </ul>
                                    <p><strong>4.	Paragliding: </strong></p><br />
                                    <p>Soar high above the picturesque landscapes of Arunachal Pradesh:</p>
                                    <ul>
                                        <li><strong>Mechuka: </strong>Enjoy tandem paragliding flights amidst the stunning Mechuka Valley and snow-capped peaks.</li>
                                        <li><strong>Dirang: </strong> Experience paragliding over lush valleys and glistening rivers for a bird's-eye view of the Eastern Himalayas.</li>
                                    </ul>
                                    <p><strong>5.	Camping and Nature Trails:</strong></p><br />
                                    <p>Immerse yourself in the tranquility of nature with camping and nature trails:</p>
                                    <ul>
                                        <li><strong>Ziro Valley:</strong> Set up camps amidst rice fields and pine forests, and explore the valley's serene surroundings on foot.</li>
                                        <li><strong>Sangti Valley:</strong> Camp by the riverbanks and indulge in birdwatching and nature photography amidst scenic landscapes.</li>
                                    </ul>
                                    <p><stong>6.	Mountain Biking:</stong></p>
                                    <p>Pedal through challenging terrains and picturesque trails in Arunachal Pradesh</p>
                                    <ul>
                                        <li><strong>Bomdila to Tawang:</strong> Embark on a thrilling mountain biking expedition through rugged mountain roads and scenic vistas.</li>
                                        <li><strong>Sela Pass Circuit:</strong> Cycle through high-altitude landscapes and breathtaking mountain passes for an exhilarating adventure.</li>
                                    </ul>
                                    <p><strong>7.	Rock Climbing and Rappelling:</strong></p>
                                    <p>Test your climbing skills amidst Arunachal's rocky cliffs and gorges:</p>
                                    <ul>
                                        <li><strong>Bhalukpong:</strong> Try rock climbing and rappelling activities under the guidance of experienced instructors amidst scenic surroundings.</li>
                                        <li><strong>Dirang Valley:</strong> Challenge yourself with rock climbing and rappelling adventures amidst pristine natural settings and waterfalls.</li>
                                    </ul>
                                    <p>These adventure activities in Arunachal Pradesh promise an unforgettable experience for adventure seekers, combining adrenaline-pumping thrills with breathtaking natural beauty and cultural richness along your tour starting from Guwahati. Whether trekking in remote valleys, rafting down roaring rivers, or soaring above majestic landscapes, each activity offers a unique way to immerse yourself in the rugged charm of Northeast India.</p>
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div className="card-header custHeaer">
                                    <h3 className='content-desktop' style={{ fontSize: "1.5vw" }}>Explore offbeat Arunachal Pradesh: discover hidden gems away from tourist crowds</h3>
                                    <h3 className='content-mobile2' style={{ fontSize: "3.5vw" }}>Explore offbeat Arunachal Pradesh: discover hidden gems away from tourist crowds</h3>

                                </div>

                                <div className="card-body">
                                    <p><strong>Dambuk:</strong> Scenic landscapes, Orange Festival, and river rafting.</p>
                                    <p><strong>Pakke-Kessang:</strong> Wildlife sanctuary, Nyishi tribe, and birdwatching.</p>
                                    <p><strong>Doni Polo Village:</strong> Tagin culture and Subansiri River views.</p>
                                    <p><strong>Sangti Valley:</strong> Black-Necked Cranes and tranquil ambiance.</p>
                                    <p><strong>Miao:</strong> Namdapha National Park gateway and wildlife.</p>
                                    <p><strong>Tuting:</strong> Rafting on Siang River and Adi culture.</p>
                                    <p><strong>Roing:</strong> Lakes, Bhismaknagar Fort, and Mishmi culture.</p>
                                    <p><strong>Khonsa:</strong> Nocte traditions and Eastern Himalayas.</p>
                                    <p><strong>Yingkiong:</strong> Memba communities and Buddhist monasteries.</p>
                                    <p><strong>Bhalukpong Hot Springs:</strong> Relaxation in natural hot springs.</p>
                                    <p><strong>Anini:</strong> Dibang Wildlife Sanctuary and remote landscapes.</p>
                                    <p><strong>Menchuka:</strong> Memba villages and Tibetan Buddhist heritage.</p>

                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div className="card-header custHeaer">
                                    <h3 className='content-desktop' style={{ fontSize: "1.5vw" }}>Discover Arunachal Pradesh's valleys for breath-taking landscapes and rich culture:</h3>
                                    <h3 className='content-mobile2' style={{ fontSize: "3.5vw" }}>Discover Arunachal Pradesh's valleys for breath-taking landscapes and rich culture:</h3>

                                </div>

                                <div className="card-body">
                                    <p><strong>Ziro Valley:</strong> Apatani culture, rice fields, and UNESCO heritage.</p>
                                    <p><strong>Dirang Valley:</strong> Hot springs, monasteries, and Sangti Valley's tranquility.</p>
                                    <p><strong>Sangti Valley:</strong> Migratory birds and serene riverside walks.</p>
                                    <p><strong>Tawang Valley:</strong> Tawang Monastery, Sela Pass, and spiritual experiences.</p>
                                    <p><strong>Mechuka Valley:</strong> Siom River, Samden Yongcha Monastery, and Memba culture.</p>
                                    <p><strong>Dibang Valley:</strong> Dibang Wildlife Sanctuary, Anini's remote beauty, and Mehao Lake.</p>
                                    <p><strong>Kameng Valley:</strong> River rafting, Eaglenest Wildlife Sanctuary, and Monpa villages.</p>
                                    <p><strong>Siang Valley:</strong> Siang River adventures, Adi communities, and Buddhist monasteries.</p>
                                    <p>These valleys offer diverse experiences from cultural immersion to adventure, ideal for your Arunachal Pradesh tour.</p>

                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div className="card-header custHeaer">
                                    <h3 className='content-desktop' style={{ fontSize: "1.5vw" }}>Inner Line Permit (ILP). </h3>
                                    <h3 className='content-mobile2' style={{ fontSize: "3.5vw" }}>Inner Line Permit (ILP). </h3>

                                </div>

                                <div className="card-body">
                                    <p>Arunachal Pradesh is sensitive due to its strategic importance, so an ILP is required to travel there. You can apply for the ILP online through the Arunachal Pradesh tourism website. After you get the ILP, you must carry it with you at all times, especially when traveling to places like Dirang, Bomdila, Tawang in the western part, Itanagar in the eastern part, and other protected areas like Ziro. If you plan to visit Ziro or other preserved areas, you may also need a Restricted Area Permit (RAP), which can be arranged through travel agencies like <strong>Adorable Vacation</strong>. </p>

                                </div>
                            </div>
                            {/* <!--end of package details--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>Frequently Ask <span>Questions</span></h2>
                                            <h4><strong>Arunachal Pradesh tour from Guwahati</strong> Trip Related Questions</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="accordion" id="accordionPanelsStayOpenExample">
                                        <div className="accordion" id="accordionPanelsStayOpenExample">
                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingOne">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                                        What is Losar?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseOne" className="accordion-collapse collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">Losar is a major festival in Arunachal Pradesh that celebrates the Tibetan New Year. It's celebrated with great pomp by various tribes like Monpas, Akas, Sherdukpens, Memba, Khamba, and Nah who follow Mahayana Buddhism. During Losar, priests make offerings to high priests like Dharmapala or Palden Lhamo, while people visit friends and family to wish them "Tashi Delek" (Best Wishes). Leaders and kings are honored, and on the final day, people make offerings and tie prayer flags around their homes and communities.</div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingTwo">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                                        Can we do River Rafting during our Arunachal trip?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseTwo" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">Yes, you can go river rafting during your trip to Arunachal Pradesh. Popular spots for river rafting include Aalong, Pasighat, and Nameri where adventure enthusiasts can participate in this thrilling sport.</div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingThree">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                                        What food to try during our trip to Arunachal Pradesh?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseThree" className="accordion-collapse collapse show" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">Arunachal Pradesh is known for its diverse cuisine, especially its meat preparations like lukter (beef), chicken or yak momos, thukpas, bamboo shoot dishes, dung po, Chura sabji, pika pila, and more.</div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingFour">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                                        Can we do angling while touring Arunachal Pradesh?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseFour" className="accordion-collapse collapse show" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">Yes, you can enjoy angling while visiting Arunachal Pradesh. The region offers opportunities for fishing enthusiasts to indulge in this activity.</div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingFive">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                                                        Can I do trekking during my North East tour package from Guwahati?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseFive" className="accordion-collapse collapse show" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">Yes, trekking can be included in your North East tour package. There are many enthralling trekking routes in Arunachal Pradesh and other parts of the North East.</div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingSix">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                                                        Which are the best spots to watch birds during my North East trip?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseSix" className="accordion-collapse collapse show" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">Kaziranga National Park, Nameri National Park, Eagle Nest Wildlife Sanctuary, and Dipor Bil are renowned birdwatching destinations in the North East known for their diverse bird species and natural beauty.</div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingSeven">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
                                                        Can I avail Jain food during my Arunachal Tour?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseSeven" className="accordion-collapse collapse show" aria-labelledby="flush-headingSeven" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">Yes, you can request Jain food during your Arunachal tour package. It's advisable to mention your dietary preferences at the time of booking with your tour operator.</div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingEight">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
                                                        Can I get medical shops on the way during the Arunachal Pradesh Tour?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseEight" className="accordion-collapse collapse show" aria-labelledby="flush-headingEight" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">Yes, you can find medical shops in towns like Guwahati, Itanagar, and Pasighat. However, due to the high altitude terrain in many parts of Arunachal Pradesh, it's recommended to carry necessary medications with you.</div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingNine">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseNine">
                                                        Do I need to carry high altitude medicines while traveling to Arunachal Pradesh?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseNine" className="accordion-collapse collapse show" aria-labelledby="flush-headingNine" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">It's advisable to carry high altitude medicines after consulting with your physician, especially if you have health conditions that may be affected by high altitudes.</div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingTen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
                                                        Shall we visit offbeat destinations during our Arunachal Pradesh Tour?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseTen" className="accordion-collapse collapse show" aria-labelledby="flush-headingTen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">Yes, your Arunachal Pradesh tour package can include visits to offbeat destinations like Ziro Valley, Mechuka Valley, Pasighat, and Aalo, providing a unique and enriching travel experience.</div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingEleven">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEleven" aria-expanded="false" aria-controls="flush-collapseEleven">
                                                        Who is the chief minister of Arunachal Pradesh?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseEleven" className="accordion-collapse collapse show" aria-labelledby="flush-headingEleven" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">Shri Pema Khandu is the chief minister of Arunachal Pradesh.</div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingTwelve">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwelve" aria-expanded="false" aria-controls="flush-collapseTwelve">
                                                        How many districts are there in Arunachal Pradesh as of 2023?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseTwelve" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwelve" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">As of 2023, Arunachal Pradesh comprises 25 districts, including the newly created districts like Shi-Yomi, Pakke-Kessang, and others.</div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingThirteen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThirteen" aria-expanded="false" aria-controls="flush-collapseThirteen">
                                                        Which is the capital city of Arunachal Pradesh?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseThirteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingThirteen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">Itanagar is the capital city of Arunachal Pradesh.</div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingFourteen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFourteen" aria-expanded="false" aria-controls="flush-collapseFourteen">
                                                        Which is the main river in Arunachal Pradesh?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseFourteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingFourteen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">The Brahmaputra River, known as "Yarlung Tsangpo" in Tibet and "Siang/Dihang" in Arunachal Pradesh, is the main river and a lifeline for the state.</div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingFifteen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFifteen" aria-expanded="false" aria-controls="flush-collapseFifteen">
                                                        What is the weather like in Arunachal Pradesh?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseFifteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingFifteen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">Arunachal Pradesh has varied climate zones due to its topographical diversity, ranging from sub-tropical to temperate based on altitude. Summers are moderate, winters can be cold, and the monsoon season brings heavy rainfall.</div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingSixteen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSixteen" aria-expanded="false" aria-controls="flush-collapseSixteen">
                                                        Is it safe to travel to Arunachal Pradesh?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseSixteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingSixteen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">Yes, Arunachal Pradesh is considered one of the safest states in the northeastern region of India. It's advisable to carry identity cards and obtain necessary permits like the Inner Line Permit (ILP) for travel within the state.</div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingSeventeen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeventeen" aria-expanded="false" aria-controls="flush-collapseSeventeen">
                                                        Do I need a permit to travel to Arunachal Pradesh?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseSeventeen" className="accordion-collapse collapse show" aria-labelledby="flush-headingSeventeen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">Yes, you need an Inner Line Permit (ILP) to travel to Arunachal Pradesh. This permit can be obtained online or from designated authorities before entering the state.</div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingEighteen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEighteen" aria-expanded="false" aria-controls="flush-collapseEighteen">
                                                        Can I experience snowfall in Tawang?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseEighteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingEighteen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">Yes, Tawang, located at a high altitude, experiences snowfall from late November to April. The weather is cold with chilly breezes during the winter season.</div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingNineteen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNineteen" aria-expanded="false" aria-controls="flush-collapseNineteen">
                                                        How many days does it take to complete the Arunachal Pradesh Tour?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseNineteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingNineteen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">The duration of your Arunachal Pradesh tour depends on the places you want to cover. Typically, a 9 to 10-day tour package from Guwahati can cover major attractions like Tawang, Bum La Pass, Zimithang, and other regions like Ziro and Mechuka.</div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h3><b> Arunachal Tour Guwahati<span class="tomato">Holiday Reviews</span></b></h3>
                                            <h4><i>"Your Experience is our Marketing"</i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-cols-1">
                                    <div id="testimonials">
                                        <div className="testimonial-box-container">

                                            {reviewersDataView}

                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="col-md-3">
                            <BookingBox />
                        </div>
                    </div >
                </div >
                <div className="btn-whatsapp-pulse">
                    <a style={{ color: 'white' }} href="#" onClick={this.handleClick}>
                        <i className="fab fa-whatsapp"></i>
                    </a>
                </div>
                <div class="btn-call-pulse">
                    <a style={{ color: 'white' }} href="tel:+91-91636-76037">
                        <i className="fa fa-phone"></i>
                    </a>
                </div>
            </>
        );
    }
}

export default ArunachalTourGuwahati_15N_16D_Package;