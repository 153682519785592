import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ApiURL from '../../api/ApiURL';

class FooterDesktop extends Component {
    RedirectToSocialMedia = (e, sm) => {
        e.preventDefault();
        var url = '';
        switch (sm) {
            case "facebook":
                url = "https://m.facebook.com/adorablevacationllp/"
                break;
            case "insta":
                url = "https://instagram.com/adorablevacation?igshid=NGVhN2U2NjQ0Yg=="
                break;
            case "youtube":
                url = "https://www.youtube.com/@adorablevacationllp5516"
                break;
        }
        window.open(url, '__blank');

    }
    render() {
       const logoImgURL = ApiURL.BaseImageURL + "/vacationLLP.png";
        return (
            <>
                <footer className="footer-section bg-bottom content-desktop">
                    <div className="container">
                        <div className="footer-content pt-3 pb-2">
                            <div className="row">
                                <div className="col-xl-4 col-lg-4 col-md-4 mb-50 content-desktop">
                                    <div className="footer-widget">
                                        {/* <div className="footer-logo">
                                            <Link to={"#"}>
                                                <img src={logoImgURL} className="img-fluid" alt="logo" />
                                            </Link>
                                        </div> */}
                                        {/* <div className="footer-text">
                                            <p>Our Life Is Stacked With Surprises. Which Is Fulfilled With Wanted & Unwanted Circumstances. Don't Think Too Much Just Travel Without Trouble.</p>
                                        </div> */}
                                        {/* <div className="footer-social-icon">
                                            <span>Follow us</span>
                                            <Link to={"#"} onClick={(e) => this.RedirectToSocialMedia(e, 'facebook')}>
                                                <i className="fab fa-facebook-f facebook-bg"></i>
                                            </Link>
                                            <Link to={"#"} onClick={(e) => this.RedirectToSocialMedia(e, 'insta')}>
                                                <i className="fab fa-instagram fa-instagram"></i>
                                            </Link>
                                            <Link to={"#"} onClick={(e) => this.RedirectToSocialMedia(e, 'youtube')}>
                                                <i className="fab fa-youtube google-bg"></i>
                                            </Link>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-4 py-5 mb-70 content-desktop">
                                    <div className="footer-widget">
                                        <div className="footer-widget-heading usefull-link">
                                            <h3 style={{color:'black'}}>Useful Links</h3>
                                        </div>
                                        <ul>
                                            <li><Link to={"#"}>Home</Link></li>
                                            <li><Link to={"#"}>Bhutan</Link></li>
                                            <li><Link to="/about">About us</Link></li>
                                            <li><Link to={"#"}>Arunachal</Link></li>
                                            <li><Link to={"#"}>Contact us</Link></li>
                                            <li><Link to={"#"}>Meghalaya</Link></li>
                                            <li><Link to={"#"}>Our Services</Link></li>
                                            <li><Link to={"#"}>Sikkim</Link></li>
                                            <li><Link to={"#"}>Holiday Packages</Link></li>
                                            <li><Link to={"#"}>Kashmir</Link></li>
                                            <li><Link to={"/cancellation-policy"}>Cancellation Policy</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-4 py-5 mb-50 content-desktop">
                                    <div className="footer-widget">
                                        <div className="footer-widget-heading">
                                            <h3 style={{color:'black'}}>Contact Details</h3>
                                        </div>
                                        <div className="footer-text mb-25">                                            
                                            <div className="address-details-list">
                                                <ul>
                                                    <li><Link to={"#"}><span className="fa fa-map-marker"></span> &nbsp;5 Dwarik Ganguly St, Kolkata - 700026</Link></li>
                                                    <li><Link to={"#"}><span className="fa fa-phone fa-rotate-90"></span><span className="text"> &nbsp;<a href='tel://+91-9883359713' style={{color:'gray'}}>+91 98833-59713 </a>| <a href='tel://+91-9163676037' style={{color:'gray'}}>+91 9163676037</a></span></Link></li>
                                                    <li><Link to={"#"}><span className="fa fa-envelope"></span><span className="text" style={{ textTransform: 'lowercase' }}> &nbsp;adorablevacationllp@gmail.com</span></Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="footer-social-icon">
                                            <span style={{color:'black'}}>Follow us</span>
                                            <Link to={"#"} onClick={(e) => this.RedirectToSocialMedia(e, 'facebook')}>
                                                <i className="fab fa-facebook-f facebook-bg"></i>
                                            </Link>
                                            <Link to={"#"} onClick={(e) => this.RedirectToSocialMedia(e, 'insta')}>
                                                <i className="fab fa-instagram fa-instagram"></i>
                                            </Link>
                                            <Link to={"#"} onClick={(e) => this.RedirectToSocialMedia(e, 'youtube')}>
                                                <i className="fab fa-youtube google-bg"></i>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-xl-2 col-lg-2 col-md-2 mb-50 py-4 content-desktop">
                                    <div className="footer-widget">
                                        <div className="footer-logo">
                                            <center><Link to={"#"}>
                                                <img src={logoImgURL} className="img-fluid" alt="logo" />
                                            </Link></center>
                                        </div>
                                        <div className="footer-text">
                                            <p>Our Life Is Stacked With Surprises. Which Is Fulfilled With Wanted & Unwanted Circumstances. Don't Think Too Much Just Travel Without Trouble.</p>
                                        </div>
                                       
                                    </div>
                                </div> */}
                            </div >
                        </div >
                    </div >
                    <div className="copyright-area">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 d-none d-lg-block text-center text-lg-left"><br/>
                                    <div className="copyright-text">
                                        <p>&nbsp;&nbsp;&nbsp;Copyright &copy; 2024, <Link to={"#"}>Adorable Vacation LLP</Link></p>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 d-none d-lg-block text-right">
                                    <div className="footer-menu">
                                        <ul>
                                            <li><Link to={"#"}>Home</Link></li>
                                            <li><Link to={"#"}>Terms</Link></li>
                                            <li><Link to={"#"}>Privacy</Link></li>
                                            <li><Link to={"/cancellation-policy"}>Cancellation Policy</Link></li>
                                            <li><Link to={"#"}>Contact</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer >
                {/* mobile view */}
                <footer className="footer-section bg-bottom-mobile mobile-image">
                    <div className="container">
                        <div className="footer-content pt-3 pb-2">
                            <div className="row">
                                <div className="col-xl-3 col-lg-3 mb-50 content-mobile">
                                    <div className="footer-widget">
                                        {/* <div className="footer-logo">
                                            <Link to={"#"}>
                                                <img src={logoImgURL} className="img-fluid" alt="logo" />
                                            </Link>
                                        </div> */}
                                        {/* <div className="footer-text">
                                            <p>Our Life Is Stacked With Surprises. Which Is Fulfilled With Wanted & Unwanted Circumstances. Don't Think Too Much Just Travel Without Trouble.</p>
                                        </div> */}
                                        {/* <div className="footer-social-icon">
                                            <span>Follow us</span>
                                            <Link to={"#"} onClick={(e) => this.RedirectToSocialMedia(e, 'facebook')}>
                                                <i className="fab fa-facebook-f facebook-bg"></i>
                                            </Link>
                                            <Link to={"#"} onClick={(e) => this.RedirectToSocialMedia(e, 'insta')}>
                                                <i className="fab fa-instagram fa-instagram"></i>
                                            </Link>
                                            <Link to={"#"} onClick={(e) => this.RedirectToSocialMedia(e, 'youtube')}>
                                                <i className="fab fa-youtube google-bg"></i>
                                            </Link>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-6 mb-30 content-mobile">
                                    <div className="footer-widget">
                                        <div className="footer-widget-heading usefull-link">
                                            <h3 style={{color:'black'}}>Useful Links</h3>
                                        </div>
                                        <ul>
                                            <li><Link to={"#"}>Home</Link></li>
                                            <li><Link to={"#"}>Bhutan</Link></li>
                                            <li><Link to="/about">About us</Link></li>
                                            <li><Link to={"#"}>Arunachal</Link></li>
                                            <li><Link to={"#"}>Contact us</Link></li>
                                            <li><Link to={"#"}>Meghalaya</Link></li>
                                            <li><Link to={"#"}>Our Services</Link></li>
                                            <li><Link to={"#"}>Sikkim</Link></li>
                                            <li><Link to={"#"}>Holiday Packages</Link></li>
                                            <li><Link to={"#"}>Kashmir</Link></li>
                                            <li><Link to={"/cancellation-policy"}>Cancellation Policy</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-6 mb-50 content-mobile">
                                    <div className="footer-widget">
                                        <div className="footer-widget-heading">
                                            <h3 style={{color:'black'}}>Contact Details</h3>
                                        </div>
                                        <div className="footer-text mb-25">                                            
                                            <div className="address-details-list">
                                                <ul>
                                                    <li><Link to={"#"}><span className="fa fa-map-marker"></span> &nbsp;5 Dwarik Ganguly St, Kalighat, Kolkata - 700026</Link></li>
                                                    <li><Link to={"#"}><span className="fa fa-phone fa-rotate-90"></span><span className="text"> &nbsp;+91 9883359713 | +91 9163676037</span></Link></li>
                                                    <li><Link to={"#"}><span className="fa fa-envelope"></span><span className="text" style={{ textTransform: 'lowercase' }}> &nbsp;adorablevacationllp@gmail.com</span></Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div >
                        </div >
                    </div >
                    <div className="copyright-area">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 d-none d-lg-block text-center text-lg-left"><br/>
                                    <div className="copyright-text">
                                        <p>&nbsp;&nbsp;&nbsp;Copyright &copy; 2024, <Link to={"#"}>Adorable Vacation LLP</Link></p>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 d-none d-lg-block text-right">
                                    <div className="footer-menu">
                                        <ul>
                                            <li><Link to={"#"}>Home</Link></li>
                                            <li><Link to={"#"}>Terms</Link></li>
                                            <li><Link to={"#"}>Privacy</Link></li>
                                            <li><Link to={"/cancellation-policy"}>Cancellation Policy</Link></li>
                                            <li><Link to={"#"}>Contact</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer >
            </>
        );
    }
}

export default FooterDesktop;