import React, { Component } from 'react';
import BookingBox from '../BookingBox';
import Slider from "react-slick";
import ApiURL from "../../../api/ApiURL";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InclusionExclusion from '../Kashmir/InclusionExclusion_kashmir_doodhpathri_yusmarg';

// import FloatingWhatsAppButton from 'react-floating-whatsapp';

class Kashmir_8N_9D_Doodhpathri_Yusmarg_Package extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customActiveTab: 'tab1',
            daywiseActiveTab: 'day1'
        }
    }

    handleCustomTabClick = (tab) => {
        this.setState({
            customActiveTab: tab,
        });
    };

    handleDayWiseTabClick = (tab) => {
        this.setState({
            daywiseActiveTab: tab,
        });
    };
    handleClick = () => {
        const currentUrl = window.location.href;
        const pageTitle = document.title;
        const message = `Hi, Please send about the Tour Package details "${pageTitle}"`;

        const whatsappLink = `https://api.whatsapp.com/send?phone=+919147061467&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
        window.open(whatsappLink, '_blank');
    };

    render() {
        const { customActiveTab, daywiseActiveTab } = this.state;
        const packageTopBannerImageURL = ApiURL.Kashmir_Tour_Package_with_Doodhpathri_Yusmarg + "/Kashmir-Tour-Package-with-Yusmarg.webp";
        const packageTopBanner2ImageURL = ApiURL.Kashmir_Tour_Package_with_Doodhpathri_Yusmarg + "/Kashmir-Tour-Package-with-Doodhpathri.webp";
        const sightseen1ImageURL = ApiURL.Kashmir_Tour_Package_with_Doodhpathri_Yusmarg + "/Kashmir-Offbeat-packages-tour.webp";
        const sightseen2ImageURL = ApiURL.Kashmir_Tour_Package_with_Doodhpathri_Yusmarg + "/Kashmir-Packag-Tour-with-Doodhpathri.webp";
        const sightseen3ImageURL = ApiURL.Kashmir_Tour_Package_with_Doodhpathri_Yusmarg + "/Kashmir Package-Tour-with-Doodhpathri-Yusmarg.webp";
        const sightseen4ImageURL = ApiURL.Kashmir_Tour_Package_with_Doodhpathri_Yusmarg + "/Kashmir-Offbeat-Package-Cost.webp";
        const sightseen5ImageURL = ApiURL.Kashmir_Tour_Package_with_Doodhpathri_Yusmarg + "/Kashmir-Offbeat-package-tour.webp";
        const sightseen6ImageURL = ApiURL.Kashmir_Tour_Package_with_Doodhpathri_Yusmarg + "/Kashmir-Package-Tour-with-Doodhpathri.webp";
        const sightseen7ImageURL = ApiURL.Kashmir_Tour_Package_with_Doodhpathri_Yusmarg + "/Kashmir-Tour-Packages-with-Yusmarg.webp";
        const sightseen8ImageURL = ApiURL.Kashmir_Tour_Package_with_Doodhpathri_Yusmarg + "/Doodhpathri-Kashmir-Package.webp";
        const sightseen9ImageURL = ApiURL.Kashmir_Tour_Package_with_Doodhpathri_Yusmarg + "/Kashmir-Offbeat-Package.webp";
        const sightseen10ImageURL = ApiURL.Kashmir_Tour_Package_with_Doodhpathri_Yusmarg + "/Kashmir-Tour-Packages-Doodhpathri.webp";
        const sightseen11ImageURL = ApiURL.Kashmir_Tour_Package_with_Doodhpathri_Yusmarg + "/Kashmir-Tour-Packages-with-Doodhpathri.webp";
        const sightseen12ImageURL = ApiURL.Kashmir_Tour_Package_with_Doodhpathri_Yusmarg + "/Kashmir-Trip-with-Doodhpathri.webp";
        //day wise itenaryy
        const navDay1ImageURL = ApiURL.Kashmir_Tour_Package_with_Doodhpathri_Yusmarg + "/KASHMIR-DOODHPATHRI-TOUR-PACKAGES.webp";
        const navDay2ImageURL = ApiURL.Kashmir_Tour_Package_with_Doodhpathri_Yusmarg + "/KASHMIR-GULMARG-TOUR-PACKAGES.webp";
        const navDay3ImageURL = ApiURL.Kashmir_Tour_Package_with_Doodhpathri_Yusmarg + "/KASHMIR-PAHALGAM-TOUR-PACKAGES.webp";
        const navDay4ImageURL = ApiURL.Kashmir_Tour_Package_with_Doodhpathri_Yusmarg + "/ARU-VALLEY-PAHALGAM-SIGHTSEEING.webp";
        const navDay5ImageURL = ApiURL.Kashmir_Tour_Package_with_Doodhpathri_Yusmarg + "/SRINAGAR-TULIP-GARDEN-KASHMIR.webp";
        const navDay6ImageURL = ApiURL.Kashmir_Tour_Package_with_Doodhpathri_Yusmarg + "/DOODHPATHRI-SRINAGAR-SIGHTSEEING.webp";
        const navDay7ImageURL = ApiURL.Kashmir_Tour_Package_with_Doodhpathri_Yusmarg + "/SONMARG-EXCURSION-FROM-SRINAGAR.webp";
        const navDay8ImageURL = ApiURL.Kashmir_Tour_Package_with_Doodhpathri_Yusmarg + "/YUSMARG-OFFBEAT-DESTINATION-KASHMIR-TOUR-PACKAGES.webp";
        const navDay9ImageURL = ApiURL.Kashmir_Tour_Package_with_Doodhpathri_Yusmarg + "/SRINAGAR-AIRPORT.webp";
        const Topbanner0ImageURL = ApiURL.Kashmir_Tour_Package_with_Doodhpathri_Yusmarg + "/Kashmir-Houseboat-Packages.webp";
        const Topbanner1ImageURL = ApiURL.Kashmir_Tour_Package_with_Doodhpathri_Yusmarg + "/Kashmir-Adventure-Tour-Plan.webp";
        const Topbanner2ImageURL = ApiURL.Kashmir_Tour_Package_with_Doodhpathri_Yusmarg + "/Kashmir-Gondola-Package-Tour.webp";
        const Topbanner3ImageURL = ApiURL.Kashmir_Tour_Package_with_Doodhpathri_Yusmarg + "/Kashmir-Trekking-Packages.webp";
        const Topbanner4ImageURL = ApiURL.Kashmir_Tour_Package_with_Doodhpathri_Yusmarg + "/Kashmir-White-River-Rafting-Plan.webp";
        const brandedTourackageImage_1_URL = ApiURL.Kashmir_Tour_Package_with_Doodhpathri_Yusmarg + "/The-Khyber-Himalayan-Resort-&-Spa.webp";
        const brandedTourackageImage_2_URL = ApiURL.Kashmir_Tour_Package_with_Doodhpathri_Yusmarg + "/The-LaLiT-Grand-Palace-Srinagar-Hotel.webp";
        const TrendingPackagesDataList = this.props.TrendingPackagesData;
        const hotPackagesView = TrendingPackagesDataList.length > 0 ?
            (
                TrendingPackagesDataList.map((dataItem, i) => {
                    const hotelImgURL = ApiURL.Kashmir_Tour_Package_with_Doodhpathri_Yusmarg + "/" + dataItem.ImageName;
                    return (<div className="col-md-12 p-0" key={i}>
                        <div className="card h-100">
                            <img src={hotelImgURL} className="card-img-top" alt={dataItem.AllternateText} />
                            <div className="card-body">
                                <h6 className="card-title">{dataItem.Title} <span> &#8377; {dataItem.PricePerNight}</span></h6>
                                <p className="card-text">
                                    <i className="fa fa-building tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-utensils tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-car-side tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-male tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-camera tomato"></i>
                                </p>
                                <p className="card-text"><span>{dataItem.Description}</span></p>
                                <p>
                                    <span><b className="tomato">{dataItem.NoOfTours} </b>Tours</span> | &nbsp;
                                    <span><b className="tomato">{dataItem.NoOfDeparture} </b>Departures</span> | &nbsp;
                                    <span><b className="sdf-blue"> {dataItem.Include}</b></span>
                                </p>
                            </div>
                            <div className="card-footer">
                                <small className="text-muted"><strong className="tomato">{dataItem.MutedText}</strong></small>
                                <a href="#" className="btn btn-success btn-sm float-end" onClick={this.handleClick}>
                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                </a>
                            </div>
                        </div>
                    </div>)
                })
            ) : (<div></div>)


        const settings = {
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const reviewersDataList = this.props.ReviewersData;
        const reviewersDataView = reviewersDataList.map((dataItem, i) => {
            const reviewerImageURL = ApiURL.Kashmir_Tour_Package_with_Doodhpathri_Yusmarg + "/" + dataItem.ImageName;
            return (
                <div className="testimonial-box" key={i}>
                    <div className="box-top">
                        <div className="profile">
                            <div className="profile-img">
                                <img src={reviewerImageURL} alt="..." />
                            </div>
                            <div className="name-user">
                                <strong>{dataItem.Name}</strong>
                                <span>{dataItem.Location}</span>
                            </div>
                        </div>
                        <div className="reviews">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </div>
                    </div>
                    <div className="client-comment">
                        <p>{dataItem.Comment}</p>
                    </div>
                </div>
            )
        })

        return (
            <>

                <div className="container-fluid">
                    <div className="row">
                        <img src={packageTopBannerImageURL} className="mobile-image2" alt="..." />
                    </div>
                </div>
                <div className="container-fluid custom-container py-3">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="container-fluid custom-container py-1">

                                        <h1 className="content-desktop" style={{ fontSize: "2vw" }}><center><strong>Kashmir Tour Packages with Doodhpathri Yusmarg</strong></center></h1>

                                        <h1 className="content-mobile" style={{ fontSize: "4vw" }}><center><strong>Kashmir Tour Packages with Doodhpathri Yusmarg</strong></center>
                                            <hr />
                                        </h1>
                                        <div className="row">
                                            <img src={packageTopBanner2ImageURL} className="mobile-image" alt="..." />
                                        </div>
                                        <hr />
                                        <div className="d-flex flex-column flex-sm-row align-items-sm-center px-2">
                                            <div className="font-lg">
                                                3 Destination:{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Srinagar (5N)
                                                </a>
                                                &nbsp;{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Gulmarg (1N)
                                                </a>{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Pahalgam (2N)
                                                </a>
                                            </div>
                                            <div className="reviews mx-5">
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                            </div>
                                        </div>
                                        <p className="p-2">
                                            If you are looking for a memorable <strong>Kashmir Tour Packages with Doodhpathri Yusmarg</strong>, you have come to the right place!
                                        </p>
                                        <hr />
                                        <br />
                                    </div>
                                    <h2 className="content-desktop p-2" style={{ fontSize: "1.6vw" }}> Looking for <strong>Kashmir tour packages that include Doodhpathri and Yusmarg ?</strong> For offbeat Kashmir tour packages that include Yusmarg <a href='tel:9147061467' class="tomato">☎ CALL Mr. Soumya +91- 91470-61467</a></h2>
                                    <h2 className="content-mobile p-4" style={{ fontSize: "4.5vw" }}> Looking for <strong>Kashmir tour packages that include Doodhpathri and Yusmarg ?</strong> For offbeat Kashmir tour packages that include Yusmarg <a href='tel:9147061467' class="tomato">☎ CALL Mr. Soumya +91- 91470-61467</a></h2>
                                    <br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> Experience the enchantment of Kashmir with our exclusive tour package with Doodhpathri and Yusmarg</h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}> Experience the enchantment of Kashmir with our exclusive tour package with Doodhpathri and Yusmarg</h4>
                                        </div>
                                        <div className="card-body">
                                            <p>Discover the hidden gems of <strong>Kashmir with Adorable Vacation's offbeat tour packages</strong>, featuring enchanting destinations like Doodhpathri and Yusmarg. These lesser-known spots offer a serene and untouched beauty, perfect for travellers seeking a unique experience away from the usual tourist trails. Our <strong>Kashmir tour packages</strong> provide an opportunity to explore the lush meadows, crystal-clear streams, and majestic landscapes that make this region a paradise on earth. Doodhpathri, known as the "Valley of Milk," mesmerizes visitors with its pristine beauty, while Yusmarg, often referred to as the "Meadow of Jesus," offers breath-taking views and tranquil surroundings. </p>
                                            <p>With comfortable accommodations and expert drivers, our <strong>Kashmir Tour packages</strong> ensure a memorable journey through <strong>the natural splendor of Kashmir</strong>. Whether you're an adventure enthusiast or a nature lover, these offbeat destinations in <strong>Kashmir packages tour</strong> promise an unforgettable vacation. <strong>Book your Kashmir tour package</strong> today with Adorable Vacation and immerse yourself in the untouched beauty of Doodhpathri and Yusmarg.</p>
                                            <p>Kashmir, often referred to as "Paradise on Earth," is a breathtaking region in northern India, renowned for its stunning landscapes, serene lakes, and majestic mountains. Nestled in the lap of the Himalayas, Kashmir tour packages offers a unique blend of natural beauty and cultural richness. The region is dotted with picturesque valleys like the lush green meadows of Gulmarg, the tranquil waters of Dal Lake in Srinagar, and the enchanting landscapes of Pahalgam. For those seeking offbeat experiences, places like Doodhpathri and Yusmarg provide serene escapes into nature, away from the usual tourist trails. The vibrant culture and warm hospitality of the locals add to the charm of Kashmir, making it a perfect destination for all kinds of travelers. Whether you're looking for adventure, peace, or a glimpse into a rich cultural tapestry, <strong>Kashmir offbeat packages</strong> has something to offer everyone.</p>
                                            <br />
                                            <h5><b>Explore Kashmir's unique spots: Baisaran Valley, Doodhpathri, and Yusmarg</b></h5>
                                            <br />
                                            <p><b>Baisaran Valley</b>: Often called <b>"Mini Switzerland"</b>, Baisaran Valley is a picturesque meadow surrounded by dense pine forests and snow-capped mountains. Located just a short trek from Pahalgam, this serene spot offers breathtaking views, lush green pastures, and a perfect escape from the crowded tourist spots. It's an ideal destination for nature lovers, adventure seekers, and those looking to unwind amidst the tranquility of nature.</p>
                                            <p><b>Doodhpathri</b>: Known as the <b>"Valley of Milk"</b>, Doodhpathri is a pristine, unspoiled destination in Kashmir. The name comes from the milky appearance of its streams, which flow through the verdant meadows and alpine forests. This offbeat location is perfect for picnicking, leisurely walks, and enjoying the serene beauty of its untouched landscapes. It's an ideal getaway for those looking to experience Kashmir's natural beauty away from the hustle and bustle of city life.</p>
                                            <p><b>Yusmarg</b>: <b>A lesser-known gem</b>, Yusmarg is a beautiful hill station in the Badgam district of Kashmir. Surrounded by dense pine forests and snow-capped peaks, it offers breathtaking views and a peaceful atmosphere. Yusmarg is known for its lush meadows, tranquil lakes, and opportunities for trekking and horse riding. It's a perfect destination for travelers seeking a quiet retreat in nature and a chance to explore the hidden beauty of Kashmir.</p>
                                            <table className="image-table">
                                                <tbody>
                                                    <tr>
                                                        <td className="image-cell">
                                                            <img src={sightseen1ImageURL} alt="kashmir tour packages" />
                                                            <img src={sightseen2ImageURL} alt="kashmir trip from srinagar airport" />
                                                            <img src={sightseen3ImageURL} alt="kashmir package tour cost from srinagar airport" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <br />
                                        </div>
                                    </div>
                                    <br /> <br /> <br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>KASHMIR PACKAGE TOUR WITH DOODHPATHRI AND YUSMARG DURATION : 8N | 9D</strong></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>KASHMIR PACKAGE TOUR WITH DOODHPATHRI AND YUSMARG DURATION : 8N | 9D</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <p>Places to cover in <strong>Offbeat Kashmir Tour </strong> : Srinagar HouseBoat 1N | Gulmarg 1N | Pahalgam 2N | Srinagar 4N</p>
                                            <p><b>Pickup & Drop point for Kashmir tour</b> : Sheikh Ul Alam International Airport Srinagar [SXR]
                                            </p>
                                            <p><b>Address :</b> Indra Gandhi Road, Humhama, Badgam, Srinagar, Jammu and Kashmir 190007</p>
                                            <br /><br /><br />
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <tbody>
                                                        <tr>
                                                            <td><strong>Kashmir Offbeat Package Tour</strong> Pickup & Drop	</td>
                                                            <td>Srinagar Airport [SXR]</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Kolkata to Kashmir Package Duration</strong></td>
                                                            <td>08 Nights | 09 Days</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Kashmir Tour With Doodhpathri & Yusmarg Package Cost </strong></td>
                                                            <td>Rs. 30,600/Based on 8 Pax</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Srinagar</strong></td>
                                                            <td>1,585 m | Coordinates - 34.0837° N, 74.7973° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Gulmarg</strong></td>
                                                            <td>2,650 m | Coordinates - 34.0484° N, 74.3805° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Sonmarg</strong></td>
                                                            <td>2,730 m | Coordinates - 34.3032° N, 75.2931° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Pahalgam</strong></td>
                                                            <td>2,740 m | Coordinates - 34.0161° N, 75.3150° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Yusmarg</strong></td>
                                                            <td>2,396 m | Coordinates - 33.8316° N, 74.6644° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Doodhpathri</strong></td>
                                                            <td>2,730 m | Coordinates - 33.5423° N, 74.3615° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Betaab Valley</strong></td>
                                                            <td>2,393 m | Coordinates - 34.0541° N, 75.3639° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Aru Valley</strong></td>
                                                            <td>2,414 m | Coordinates - 34.0922° N, 75.2632° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Chandanwadi</strong></td>
                                                            <td>3,288 m | Coordinates - 34.2122° N, 73.9295° E</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <br /><br /><br />
                                            <table className="image-table">
                                                <tbody>
                                                    <tr>
                                                        <td className="image-cell">
                                                            <img src={sightseen4ImageURL} alt="kashmir package tour from srinagar" />
                                                            <img src={sightseen5ImageURL} alt="kashmir package price from srinagar airport" />
                                                            <img src={sightseen6ImageURL} alt="kashmir packages from srinagar airport" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <br />

                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    {/* start short itinerary */}

                                    <div className="card">
                                        <div className="card-body">
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr className="table-danger">
                                                        <th>DAY WISE ITINERRARY FOR KASHMIR PACKAGE TOUR WITH DOODHPATHRI YUSMARG</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingOne"
                                                        >
                                                            <a href="#day1" style={{ color: 'black' }}><b>DAY 1 → </b>
                                                                <span>
                                                                    ARRIVAL AT SRINAGAR, LOCAL SIGHTSEEING, OVER NIGHT STAY AT HOUSEBOAT
                                                                </span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingTwo"
                                                        >
                                                            <a href="#day2" style={{ color: 'black' }}><b>DAY 2 → </b>
                                                                <span>SRINAGAR TO GULMARG FULL DAY EXCURSION & OVER NIGHT STAY AT HOTEL</span>
                                                            </a>

                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingThree"
                                                        >
                                                            <a href="#day3" style={{ color: 'black' }}><b>DAY 3 → </b>
                                                                <span>GULMARG TO PAHALGAM TOUR & NIGHT STAY AT HOTEL</span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingFour"
                                                        >
                                                            <a href="#day4" style={{ color: 'black' }}><b>DAY 4 → </b>
                                                                <span>PAHALGAM SIGHTSEEING [BETAB + ARU VALLEY & CHANDANWADI] & NIGHT STAY</span>
                                                            </a>

                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingFive"
                                                        >
                                                            <a href="#day5" style={{ color: 'black' }}><b>DAY 5 → </b>
                                                                <span>PAHALGAM TO SRINAGAR DAY TRIP, OVER NIGHT STAY AT HOUSEBOAT</span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingSix"
                                                        >
                                                            <a href="#day6" style={{ color: 'black' }}><b>DAY 6 → </b>
                                                                <span>SRINAGAR TO DOODH PATHRI TO SRINAGAR DAY TRIP & NIGHT STAY AT SRINAGAR</span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingSeven"
                                                        >
                                                            <a href="#day7" style={{ color: 'black' }}><b>DAY 7 → </b>
                                                                <span>SRINAGAR TO SONMARG TO SRINAGAR DAY TRIP & NIGHT STAY IN SRINAGAR </span>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingEight"
                                                        >
                                                            <a href="#day8" style={{ color: 'black' }}><b>DAY 8 → </b>
                                                                <span>SRINAGAR TO YUSMARG TO SRINAGAR DAY TRIP & NIGHT STAY IN SRINAGAR </span>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingNine"
                                                        >
                                                            <a href="#day9" style={{ color: 'black' }}><b>DAY 9 → </b>
                                                                <span>DEPARTURE FROM SRINAGAR AIRPORT </span>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    {/* <!--end Short description--> */}

                                    <br /><br /><br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>COST OF<strong> KASHMIR PACKAGE TOUR WITH DOODHPATHRI AND YUSMARG</strong></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>COST OF <strong>KASHMIR PACKAGE TOUR WITH DOODHPATHRI AND YUSMARG</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <h5>Discover Baisaran Valley: A Hidden Gem in Kashmir's Natural Beauty</h5><br />
                                            <p>Baisaran Valley, often referred to as "Mini Switzerland," is a picturesque meadow located near Pahalgam in Kashmir. The valley is known for its lush green pastures, surrounded by dense pine forests and towering snow-capped mountains. This serene and beautiful landscape offers a perfect escape for nature lovers and those seeking peace and tranquility.</p>
                                            <p>One of the highlights of <strong>Kashmir tour packages with Baisaran Valley</strong> is its stunning panoramic views. The rolling meadows are a great spot for picnics, leisurely walks, or just relaxing while taking in the breathtaking scenery. <strong>Doodhpathri Kashmir Package</strong> the cool, refreshing air and the sound of the wind rustling through the pine trees create a calming atmosphere that makes it an ideal destination for unwinding.</p>
                                            <p>The valley is also a popular spot for adventure activities. Visitors can enjoy horse riding and trekking, exploring the scenic trails that wind through the forested hills. <strong>Kashmir Package Tour with Yusmarg</strong> the journey to Baisaran is as captivating as the destination itself, with picturesque vistas along the way, making it a memorable experience for travelers.</p>
                                            <p>Baisaran Valley is relatively less crowded compared to other tourist spots in Kashmir, making it a perfect offbeat destination. Whether you're looking for a <strong>Kashmir tour packages with Doodhpathri Yusmarg</strong>, peaceful retreat or an adventurous getaway, Baisaran Valley offers a unique and memorable experience in the heart of Kashmir's natural beauty.</p>
                                            <br />
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <thead className="cf">
                                                        <tr className="table-danger">
                                                            <th>TOUR DURATION</th>
                                                            <th>2 PAX</th>
                                                            <th>4 PAX</th>
                                                            <th>6 PAX</th>
                                                            <th>8 PAX</th>
                                                            <th>BOOK NOW</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td data-title="DURATION">4N/5D</td>
                                                            <td data-title="2 PAX">₹ 20,500/-</td>
                                                            <td data-title="4 PAX">₹ 18,300/-</td>
                                                            <td data-title="6 PAX">₹ 16,600/-</td>
                                                            <td data-title="8 PAX">₹ 16,400/-</td>
                                                            <td data-title="Book Now"><a href="#" class="btn btn-success" onClick={this.handleClick}>Book Now</a></td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="DURATION">5N/6D</td>
                                                            <td data-title="2 PAX">₹ 24,700/-</td>
                                                            <td data-title="4 PAX">₹ 22,000/-</td>
                                                            <td data-title="6 PAX">₹ 19,800/-</td>
                                                            <td data-title="8 PAX">₹ 20,800/-</td>
                                                            <td data-title="Book Now"><a href="#" class="btn btn-success" onClick={this.handleClick}>Book Now</a></td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="DURATION">6N/7D</td>
                                                            <td data-title="2 PAX">₹ 30,900/-</td>
                                                            <td data-title="4 PAX">₹ 27,400/-</td>
                                                            <td data-title="6 PAX">₹ 24,700/-</td>
                                                            <td data-title="8 PAX">₹ 24,500/-</td>
                                                            <td data-title="Book Now"><a href="#" class="btn btn-success" onClick={this.handleClick}>Book Now</a></td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="DURATION">7N/8D</td>
                                                            <td data-title="2 PAX">₹ 35,600/-</td>
                                                            <td data-title="4 PAX">₹ 30,800/-</td>
                                                            <td data-title="6 PAX">₹ 28,300/-</td>
                                                            <td data-title="8 PAX">₹ 28,100/-</td>
                                                            <td data-title="Book Now"><a href="#" class="btn btn-success" onClick={this.handleClick}>Book Now</a></td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="DURATION">8N/9D</td>
                                                            <td data-title="2 PAX">₹ 39,900/-</td>
                                                            <td data-title="4 PAX">₹ 34,900/-</td>
                                                            <td data-title="6 PAX">₹ 31,100/-</td>
                                                            <td data-title="8 PAX">₹ 30,600/-</td>
                                                            <td data-title="Book Now"><a href="#" class="btn btn-success" onClick={this.handleClick}>Book Now</a></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <br /><br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>EXPERIENCE DOODHPATHRI:<strong> THE MILK VALLEY IN KASHMIR'S BEAUTY</strong></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>EXPERIENCE DOODHPATHRI:<strong> THE MILK VALLEY IN KASHMIR'S BEAUTY</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <p>Doodhpathri, known as the "Valley of Milk," is a serene and picturesque destination in Kashmir. Its name comes from the milky appearance of the streams that flow through the valley, adding to its enchanting charm.</p>
                                            <p>Surrounded by lush meadows and dense forests, Doodhpathri offers a peaceful escape from the busy tourist spots. <strong>Kashmir Trip with Doodhpathri</strong> the clean, fresh air and stunning views make it an ideal spot for picnicking, leisurely walks, and enjoying nature's beauty.</p>
                                            <p>The valley's tranquil environment provides a perfect backdrop for relaxation and reflection. With its untouched landscapes and serene atmosphere, Doodhpathri offers a unique and memorable experience for anyone looking to explore Kashmir’s hidden treasures.</p>
                                            <br />
                                            <h5><b>Attractions of Doodhpathri:</b></h5><br />
                                            <ul>
                                                <li>1.	Milky Streams: The valley’s streams have a unique milky appearance, giving the area its name and adding to its scenic charm.</li><br />
                                                <li>2.	Lush Meadows: Expansive green meadows provide a perfect setting for picnics, leisurely walks, and enjoying the natural beauty.</li><br />
                                                <li>3.	Dense Pine Forests: Surrounding the meadows are dense pine forests that offer shade and a tranquil environment for relaxation.</li><br />
                                                <li>4.	Serene Atmosphere: The peaceful and quiet environment makes it an ideal place for unwinding and escaping the hustle and bustle of city life.</li><br />
                                                <li>5.	Scenic Views: The valley provides breathtaking views of the surrounding mountains and pristine landscapes, perfect for photography and nature appreciation.</li>
                                            </ul>
                                            <br />
                                            <p><b>Adventure in Doodhpathri</b> includes various activities that let you fully experience the valley's natural beauty. Trekking through the expansive meadows and gentle slopes offers breathtaking views and a chance to connect with nature. <strong>Kashmir Package Tour with Doodhpathri</strong> Yusmarg Horse riding is a popular way to explore the valley, providing a unique perspective of the stunning landscape. Nature walks and picnics in the serene environment add to the adventure, making Doodhpathri a perfect destination for outdoor enthusiasts seeking both excitement and tranquility.</p>
                                        </div>
                                    </div>
                                    <br /><br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>IN DOODHPATHRI, THE BEST ACTIVITIES TO ENJOY INCLUDE</h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>IN DOODHPATHRI, THE BEST ACTIVITIES TO ENJOY INCLUDE</h4>
                                        </div>
                                        <div className="card-body">
                                            <ul>
                                                <li><b>1. Trekking:</b> Explore the picturesque meadows and surrounding hills with scenic treks that offer stunning views of the valley and its natural beauty.</li><br />
                                                <li><b>2. Horse Riding:</b> Take a horse ride through the lush meadows and forested areas to experience the landscape from a different perspective.</li><br />
                                                <li><b>3. Nature Walks:</b> Enjoy leisurely walks through the serene environment, perfect for taking in the fresh air and peaceful surroundings.</li><br />
                                                <li><b>4. Picnicking:</b> Set up a picnic in the expansive meadows, where you can relax and enjoy the breathtaking scenery.</li><br />
                                                <li><b>5. Photography:</b> Capture the stunning landscapes, milky streams, and lush greenery for lasting memories of this tranquil destination.</li><br />
                                                <li><b>6. Camping:</b> Spend a night under the stars in the valley, experiencing the beauty and serenity of Doodhpathri in a more immersive way.</li><br />
                                            </ul>
                                        </div>
                                    </div>
                                    <br /><br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>EXPLORING YUSMARG: A HIDDEN GEM OF KASHMIR</h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>EXPLORING YUSMARG: A HIDDEN GEM OF KASHMIR</h4>
                                        </div>
                                        <div className="card-body">
                                            <p>Yusmarg, a serene hill station in Kashmir, is a hidden gem that offers a peaceful retreat away from the bustling tourist spots. Surrounded by dense pine forests and snow-capped mountains, Yusmarg's lush meadows provide a picturesque setting for relaxation and exploration.</p>
                                            <p>The area is known for its tranquil atmosphere and stunning landscapes. Visitors can enjoy scenic walks, horse riding, and picnic spots amidst the natural beauty. The gentle slopes and expansive meadows create an ideal environment for leisurely activities and unwinding.</p>
                                            <p>Yusmarg’s charm lies in its offbeat appeal, making it a perfect destination for those looking to experience the quieter side of Kashmir. Its unspoiled beauty and serene environment offer a unique and memorable escape into nature.</p>
                                            <br />
                                            <h5>In Yusmarg, the best activities to enjoy include</h5><br />
                                            <ul>
                                                <li>1.Scenic Walks: Explore the lush meadows and wooded areas on foot, taking in the stunning landscapes and peaceful surroundings.</li><br />
                                                <li>2.Horse Riding: Enjoy a horse ride through the picturesque meadows and forests, offering a unique view of Yusmarg's natural beauty.</li><br />
                                                <li><b>3. Picnicking:</b> Set up a picnic in the expansive meadows, where you can relax and enjoy the serene environment and breathtaking views.</li><br />
                                                <li><b>4. Photography:</b> Capture the stunning landscapes, including the rolling meadows, dense forests, and distant snow-capped peaks.</li><br />
                                                <li><b>5. Fishing:</b> Spend some time by the river, where you can enjoy fishing in the tranquil waters of Yusmarg.</li><br />
                                                <li><b>6. Nature Exploration:</b> Discover the local flora and fauna, taking in the diverse plant life and bird species that inhabit the area.</li><br />
                                            </ul>
                                        </div>
                                    </div>
                                    <br /><br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>EXPLORING THE HISTORICAL SIGNIFICANCE OF YUSMARG IN KASHMIR</h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>EXPLORING THE HISTORICAL SIGNIFICANCE OF YUSMARG IN KASHMIR</h4>
                                        </div>
                                        <div className="card-body">
                                            <p>Yusmarg, while primarily known for its natural beauty, also holds historical significance in Kashmir. The region has been a notable site for centuries, drawing attention for its serene landscapes and cultural heritage.</p>
                                            <p>Historically, Yusmarg was a favored retreat for Kashmiri rulers and notable figures who sought refuge from the bustling life in the valley. The area's tranquil environment and scenic beauty made it an ideal location for leisure and reflection.</p>
                                            <p>The name "Yusmarg" translates to "Meadow of Jesus," which adds to its intriguing history. According to local legends, it is believed that Jesus visited this region, further enhancing its cultural and historical allure.</p>
                                            <p>Yusmarg's historical charm is complemented by its natural allure, making it a unique destination that offers both scenic beauty and a touch of historical intrigue. Exploring Yusmarg provides visitors with a chance to connect with both the natural and historical aspects of this hidden gem in Kashmir.</p>
                                            <br />
                                            <h5>The Mysterious Nilnag Lake</h5><br />
                                            <p>Tucked away within dense deodar forests, Nilnag Lake is a place of timeless intrigue and natural splendor. Its clear, tranquil waters mirror the surrounding snow-capped peaks, forming a stunning visual symphony. Rich in history and legend, Nilnag Lake is shrouded in tales of hidden treasures and ancient rituals, sparking the imagination of those who visit. This mystical lake invites explorers and dreamers alike to uncover the secrets of its past and revel in its serene beauty.</p><br />
                                            <p>Begin your historic journey through Yusmarg's timeless landscapes with our exclusive Kashmir package tour that includes Doodhpathri and Yusmarg. Immerse yourself in the rich history and heritage of the region as you discover Kashmir's hidden treasures. Book your Kashmir tour package today and embark on an unforgettable adventure through the annals of time. Experience the enchanting beauty of Yusmarg and create memories that will last a lifetime.</p>
                                            <br /><br />
                                            <table className="image-table">
                                                <tbody>
                                                    <tr>
                                                        <td className="image-cell">
                                                            <img src={sightseen7ImageURL} alt="kashmir package tour from srinagar" />
                                                            <img src={sightseen8ImageURL} alt="kashmir package price from srinagar airport" />
                                                            <img src={sightseen9ImageURL} alt="kashmir packages from srinagar airport" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <br /> <br />
                                        </div>
                                    </div>
                                    <br /><br />

                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>What are the top attractions on a <strong>Kashmir Tour Packages with Doodhpathri Yusmarg?</strong></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>What are the top attractions on a <strong>Kashmir Tour Packages with Doodhpathri Yusmarg?</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <p>When you embark on a <strong>Kashmir Tour Packages with Doodhpathri Yusmarg</strong> with Adorable Vacation, the leading <strong>Kashmir specialist in India</strong>, you’ll explore some of the most breath-taking attractions in the region. Your journey will include visits to top destinations like Srinagar, Gulmarg, Sonmarg, and Pahalgam, as well as iconic sites such as Mughal Gardens, Dal Lake, and Manasbal Lake. You’ll also experience the beauty of Shalimar Gardens, Nishat Gardens, Chashme Shahi, Badamwari Garden, and Pari Mahal.</p>
                                            <p>Additional highlights include spiritual sites like Shankaracharya Temple, Vaishno Devi Temple, Hazratbal Shrine, and Kheer Bhawani Temple. For natural wonders, explore Betaab Valley, Yusmarg, Aru, Gurez, Dudhpathri, Bangus Valley, Patnitop, Poonch, Bhimgarh Fort, and Ramnagar Fort.
                                            </p>
                                            <p>A special highlight of the <strong>Kashmir Tour Packages with Doodhpathri Yusmarg</strong>, especially if you visit during the spring, is the Tulip Festival. The Kashmir Tulip Festival, is going to held from March to April, 2025, showcases a stunning display of tulips in full bloom. The festival takes place at the Indira Gandhi Memorial Tulip Garden, also known as Siraj Bagh, in Srinagar. This is Asia's largest tulip garden, where visitors can enjoy a vibrant array of tulip varieties and the beautifully landscaped garden setting.</p>
                                            <br /><br />
                                            <h5>KASHMIR TULIP FESTIVAL 2025: DATE, TIMING, TICKETS FEE</h5>
                                            <br />
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <tbody>
                                                        <tr>
                                                            <td>Highlights</td>
                                                            <td>Tulip Festival Details</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Kashmir Tulip Festival Date</td>
                                                            <td>March - April, 2025</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Kashmir Tulip Festival Location</td>
                                                            <td>Indira Gandhi Memorial Tulip Garden, Srinagar, J&K</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Kashmir Tulip Festival Timings</td>
                                                            <td>10:00am to 7:30pm Every Days</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Kashmir Tulip Festival Tickets Fee</td>
                                                            <td>INR 50 per adult INR 25 Per Child</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                            <br /><br />

                                            <table className="image-table">
                                                <tbody>
                                                    <tr>
                                                        <td className="image-cell">
                                                            <img src={sightseen10ImageURL} alt="kashmir package tour booking from srinagar" />
                                                            <img src={sightseen11ImageURL} alt="kashmir package tour booking from srinagar airport" />
                                                            <img src={sightseen12ImageURL} alt="kashmir package tour cost from srinagar" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <br />
                                        </div>
                                    </div>
                                    <br /><br /><br />

                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>FAMOUS LAKES TO EXPLORE DURING <strong>KASHMIR TOUR PACKAGES WITH DOODHPATHRI</strong></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>FAMOUS LAKES TO EXPLORE DURING <strong>KASHMIR TOUR PACKAGES WITH DOODHPATHRI</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <p>If you're embarking on a <strong>Kashmir Tour Packages with Doodhpathri Yusmarg</strong>, you absolutely must explore the region’s stunning lakes. Kashmir is renowned for its breath-taking lakes surrounded by snow-capped mountains, Chinar trees, pine forests, and vibrant gardens full of blooming tulips and roses. Here are some of the most famous lakes to include in your <strong>Kashmir Tour Packages with Doodhpathri Yusmarg</strong>.</p>
                                            <p><b>Dal Lake</b> is undoubtedly the most popular destination on a <strong>Kashmir Tour Packages with Doodhpathri Yusmarg</strong>. Spanning around 22 square kilometres, this picturesque lake is famous for its colourful Shikaras (traditional boats), houseboats, and floating markets. A Shikara ride on Dal Lake is an essential part of any <strong>Kashmir package with Doodhpathri Yusmarg</strong>. You must also visit Char Chinar, a beautiful island in the middle of the lake with four Chinar trees at its corners. Depending on the season, Char Chinar provides stunning views of the lake and its surroundings. Dal Lake has been featured in many Bollywood movies, making it a quintessential part of any <strong>Kashmir honeymoon tour</strong>. Floating on a Shikara, enjoying hot noon Chai (also known as Shir Chai or Gulabi Chai), and sampling authentic Kashmiri cuisine makes for a truly memorable experience. Staying in a houseboat on the tranquil waters of Dal Lake adds to its romantic appeal.
                                            </p>
                                            <p><b>Wular Lake</b> is another significant attraction in Kashmir. Covering about 190 square kilometres in the Bandipora district, Wular Lake is the largest in Jammu and Kashmir and the second largest in Asia. Originating from the Jhelum River, Wular Lake is vital for the local ecosystem and boasts a diverse range of aquatic plants and fish. It’s a key part of any Kashmir package tour with Doodhpathri for those interested in natural beauty and local wildlife.</p>
                                            <p><b>Nagin Lake</b>, often considered an extension of Dal Lake, offers a more serene and less crowded experience. Surrounded by majestic hills, this pristine lake is perfect for a romantic Shikara ride in a peaceful, pollution-free environment. It’s an excellent choice for honeymooners looking for a quieter escape.</p>
                                            <p><b>Manasbal Lake</b>, located 28 kilometers from Srinagar at an elevation of 1,583 meters, is smaller compared to Dal and Nagin Lakes but equally charming. Known for its beautiful blooms of lilies and lotuses, Manasbal Lake is an ideal spot for spending quality time with loved ones. The lake is surrounded by well-maintained gardens filled with seasonal flowers, including tulips and roses, making it a sought-after destination in Kashmir.</p>
                                            <p>Including these lakes in your Kashmir Tour Packages with Doodhpathri Yusmarg will ensure a memorable and picturesque experience, showcasing the natural beauty and romantic allure of the region.</p>
                                            <br />
                                            <br /> <br />
                                            <h5><b>ENTRY FEES FOR SIGHTSEEING DURING KASHMIR TOUR</b></h5>
                                            <br />

                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <tbody>
                                                        <tr>
                                                            <td>Places</td>
                                                            <td>Entry Fees</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Dal Lake</td>
                                                            <td>NIL</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Nishant Bagh</td>
                                                            <td>Rs. 24 per adult, Rs. 12 per child</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Pari Mahal</td>
                                                            <td>Rs. 40/Person</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Shalimar Bagh</td>
                                                            <td>Rs. 24 per adult, Rs. 12 per child</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Shankaracharya Temple</td>
                                                            <td>NIL</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Chashme Shahi</td>
                                                            <td>Rs. 24 per adult, Rs. 12 per child</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Verinag Garden</td>
                                                            <td>Rs. 10/Person</td>
                                                        </tr>
                                                        <tr>
                                                            <td>SPS Museum</td>
                                                            <td>Rs. 10 for Indian, Rs. 50 for Foreigners</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Tulip Garden</td>
                                                            <td>Rs. 75 per adult, Rs. 30 per child</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Aru Valley</td>
                                                            <td>Rs. 25/Person</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                            <br />
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div className="card-header custHeaer">
                                            <h4 className='content-desktop' style={{ fontSize: "1.5vw" }}>MUST DO ACTIVITIES DURING <strong>KASHMIR TOUR PACKAGES WITH DOODHPATHRI</strong></h4>
                                            <h4 className='content-mobile2' style={{ fontSize: "3.5vw" }}>MUST DO ACTIVITIES DURING <strong>KASHMIR TOUR PACKAGES WITH DOODHPATHRI</strong></h4>

                                        </div>
                                        <div className="card-body">
                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>Shikara Rides:</b><b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Shikara Rides:</b> Experience the tranquility of Dal Lake with a leisurely Shikara ride During your <strong> Kashmir Tour Packages with Doodhpathri</strong>. Glide across the serene waters, passing by floating gardens, houseboats, and vibrant markets. Sunset and early morning rides offer particularly magical views.</p>
                                            <center> <img src={Topbanner0ImageURL} className="img-fluid" alt="..." /></center><br /><br /><br />

                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>Skiing and Snowboarding:</b>
                                                <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Skiing and Snowboarding:</b> During the winter months, places like Gulmarg transform into ski resorts, attracting enthusiasts from around the world. Gulmarg offers excellent slopes for skiing, snowboarding, and heli-skiing, with breathtaking views of the surrounding Himalayan peaks.</p>
                                            <center> <img src={Topbanner1ImageURL} className="img-fluid" alt="..." /></center><br /><br /><br />

                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>Gondola Ride: </b>
                                                <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Gondola Ride: </b>
                                                Take a ride on the Gulmarg Gondola, one of the highest cable cars in the world. Enjoy panoramic views of the snow-capped peaks as you ascend to Kongdori or Apharwat Peak, where you can indulge in activities like hiking and mountain biking.Experience this Awesome Ride During your <strong>Kashmir Tour Package with Doodhpathri</strong> with Adorable Vacation and make this as a life long memories.</p>
                                            <center> <img src={Topbanner2ImageURL} className="img-fluid" alt="..." /></center><br /><br /><br />

                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>Trekking:</b>
                                                <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Trekking:</b> Kashmir is a paradise for trekkers, with numerous trails that cater to all levels of expertise. Popular trekking destinations include the Kashmir Great Lakes Trek, Tarsar Marsar Trek, and the trek to Thajiwas Glacier in Sonamarg.</p>
                                            <center> <img src={Topbanner3ImageURL} className="img-fluid" alt="..." /></center><br /><br /><br />
                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>River Rafting:</b>
                                                <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>River Rafting:</b> The Lidder River in Pahalgam offers exciting opportunities for white-water rafting. Experience the thrill of navigating through rapids while enjoying the scenic beauty of the surrounding pine forests and mountains.</p>
                                            <center> <img src={Topbanner4ImageURL} className="img-fluid" alt="..." /></center>
                                            <br /><br /><br />
                                            <a href="tel://9147061467" className="btn btn-Warning" style={{ padding: '15px 30px', fontSize: '20px' }}>CALL NOW</a>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="container-fluid navtabCustom">
                                        <nav>
                                            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                                <button onClick={() => this.handleCustomTabClick('tab1')} className={`tab button ${customActiveTab === 'tab1' ? 'active' : ''}`} role="tab" aria-controls="nav-highlights" aria-selected={customActiveTab === 'tab1'}>Highlights</button>
                                                <button onClick={() => this.handleCustomTabClick('tab2')} className={`tab button ${customActiveTab === 'tab2' ? 'active' : ''}`} role="tab" aria-controls="nav-flights" aria-selected={customActiveTab === 'tab2'}>Flights</button>
                                                <button onClick={() => this.handleCustomTabClick('tab3')} className={`tab button ${customActiveTab === 'tab3' ? 'active' : ''}`} role="tab" aria-controls="nav-hotel" aria-selected={customActiveTab === 'tab3'}>Hotel</button>
                                                <button onClick={() => this.handleCustomTabClick('tab4')} className={`tab button ${customActiveTab === 'tab4' ? 'active' : ''}`} role="tab" aria-controls="nav-sightseeing" aria-selected={customActiveTab === 'tab4'}>Sightseeing</button>
                                                <button onClick={() => this.handleCustomTabClick('tab5')} className={`tab button ${customActiveTab === 'tab5' ? 'active' : ''}`} role="tab" aria-controls="nav-meals" aria-selected={customActiveTab === 'tab5'}>Meals</button>
                                            </div>
                                        </nav>
                                        <div className="tab-content" id="nav-tabContent" >
                                            <div className={customActiveTab === 'tab1' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-highlights-tab">
                                                <p><strong>Kashmir Tour Packages with Doodhpathri</strong> is renowned for its breathtaking landscapes, which include iconic features like the Dal Lake, Nigeen Lake, Gulmarg, Pahalgam, and Sonamarg. These areas attract tourists from around the world who come to admire the region's picturesque scenery and engage in various outdoor activities.</p>
                                                <p>Planning for a <b>Kashmir Holiday</b> remains a captivating destination for travellers seeking natural beauty, cultural experiences, and adventure in a unique and picturesque setting. </p>
                                                <p><b>Potential Geography -</b> Kashmir is nestled in the Himalayan Mountain range and is bordered by India, Pakistan, and China. It is divided into three regions: Jammu, the Kashmir Valley, and Ladakh. The region is characterized by its snow-capped peaks, lush valleys, meandering rivers, and serene lakes.</p>
                                                <p><b>Kashmir Tourism</b> is renowned for its breathtaking landscapes, which include iconic features like the <b>Dal Lake, Nigeen Lake, Gulmarg, Pahalgam, and Sonamarg.</b> These areas attract tourists from around the world who come to admire the region's picturesque scenery and engage in various outdoor activities. Kashmir has long been a popular tourist destination, attracting visitors with its natural beauty, adventure sports, religious sites, and historical landmarks. Tourists can enjoy activities such as trekking, skiing, houseboat stays, shikara rides, and visits to Mughal gardens and ancient temples.</p>
                                            </div>
                                            <div className={customActiveTab === 'tab2' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-flights-tab">
                                                <div className="overflow-table">
                                                    <span>Best Srinagar Non-Stop Flight connection from your city<b> <i>(SXR)</i></b></span><br /><br />
                                                    <table className="table table-bordered">
                                                        <tr className="table-danger">
                                                            <th>FLIGHT</th>
                                                            <th>AIRLINES</th>
                                                            <th>AIRPORT</th>
                                                            <th>TIME</th>
                                                            <th>DAY</th>
                                                        </tr>
                                                        <tr>
                                                            <td>SG-509</td>
                                                            <td>SpiceJet</td>
                                                            <td>Mumbai</td>
                                                            <td>14:45</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>QP-1637</td>
                                                            <td>Akasa Air</td>
                                                            <td>Mumbai</td>
                                                            <td>5:35</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>63-266</td>
                                                            <td>IndiGo</td>
                                                            <td>Mumbai</td>
                                                            <td>06:45</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>SG-8913</td>
                                                            <td>SpiceJet</td>
                                                            <td>Delhi</td>
                                                            <td>08:00</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>SG-8373</td>
                                                            <td>SpiceJet</td>
                                                            <td>Delhi</td>
                                                            <td>09:45</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>6E-6947</td>
                                                            <td>IndiGo</td>
                                                            <td>Bengaluru</td>
                                                            <td>13:45</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>6E6253</td>
                                                            <td>IndiGo</td>
                                                            <td>Hyderabad</td>
                                                            <td>14:50</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>6E6945</td>
                                                            <td>IndiGo</td>
                                                            <td>Ahmedabad</td>
                                                            <td>06:55</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>6E6961</td>
                                                            <td>IndiGo</td>
                                                            <td>Kolkata</td>
                                                            <td>14:45</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className={customActiveTab === 'tab3' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-hotel-tab">

                                                <div className="overflow-table">
                                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }} ><center><b>4 STAR - CERTIFIED BY 'ADORABLE' </b></center></h4>
                                                    <div id="no-more-tables">
                                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                            <thead class="cf">
                                                                <tr className="text-center">
                                                                    {/* <!-- Add the "text-center" className to center content --> */}
                                                                    <th colspan="6" style={{ fontSize: "1.3vw" }}><h6><b>3 STAR CATEGORY HOTEL CERTIFIED BY 'ADORABLE VACATION' </b></h6></th>
                                                                </tr>
                                                                <tr className="table-danger">
                                                                    <th className="text-center">Destination</th>
                                                                    <th className="text-center">Hotel Names</th>
                                                                    <th className="text-center">Hotel Type</th>
                                                                    <th className="text-center">No of Nights</th>
                                                                    <th className="text-center">Meal Plan</th>
                                                                </tr>
                                                            </thead>
                                                            <tr>
                                                                <td data-title="Destination"><div style={{ display: 'inline-block' }}>Srinagar</div></td>
                                                                <td data-title="Hotel Names"><div style={{ display: 'inline-block' }}>Hotel Grand Fortune / Hotel Shefaf / Hotel Siddique Palace / Similar</div></td>
                                                                <td data-title="Hotel Type">3 Star
                                                                </td>
                                                                <td data-title="No of Nights"><div style={{ display: 'inline-block' }}>5N</div></td>
                                                                <td data-title="Meal Plan"><div style={{ display: 'inline-block' }}>Room with Breakfast & Dinner</div></td>
                                                            </tr>
                                                            <tr>
                                                                <td data-title="Destination"><div style={{ display: 'inline-block' }}>Gulmarg</div></td>
                                                                <td data-title="Hotel Names"><div style={{ display: 'inline-block' }}>Hotel Affarwat / Hotel Mama Palace /Gulmarg House / Similar
                                                                </div>
                                                                </td>
                                                                <td data-title="Hotel Type">3 Star
                                                                </td>
                                                                <td data-title="No of Nights"><div style={{ display: 'inline-block' }}>1N</div></td>
                                                                <td data-title="Meal Plan"><div style={{ display: 'inline-block' }}>Room with Breakfast & Dinner</div></td>
                                                            </tr>
                                                            <tr>
                                                                <td data-title="Destination"><div style={{ display: 'inline-block' }}>Pahalgam</div></td>
                                                                <td data-title="Hotel Names"><div style={{ display: 'inline-block' }}>Hotel Pine Palace Resort / Hotel Fifth Season / Hotel Moonview Resort /Similar
                                                                </div>
                                                                </td>
                                                                <td data-title="Hotel Type">3 Star
                                                                </td>
                                                                <td data-title="No of Nights"><div style={{ display: 'inline-block' }}>2N</div></td>
                                                                <td data-title="Meal Plan"><div style={{ display: 'inline-block' }}>Room with Breakfast & Dinner</div></td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={customActiveTab === 'tab4' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-sightseeing-tab">
                                                <div className="container-fluid px-0 py-1">
                                                    <h3>Sightseeing for <strong>Kashmir Tour Packages with Doodhpathri</strong></h3>
                                                    <p>Kashmir, often referred to as "Paradise on Earth," is renowned for its breathtaking natural beauty, serene landscapes, and rich cultural heritage. Here are some of the most popular sightseeing destinations in Kashmir:</p><br />
                                                    <p><b style={{ fontSize: "1.3vw" }} className='content-desktop'>Dal Lake:</b>
                                                        <b style={{ fontSize: "3.5vw" }} className='content-mobile2'>Dal Lake:</b> This iconic lake in Srinagar is famous for its picturesque surroundings, houseboats, and Shikara rides. Visitors can explore the floating gardens, old Mughal gardens along the shores, and enjoy the stunning views of the surrounding mountains.</p><br />
                                                    <p><b className='tomato content-desktop' style={{ fontSize: "1.3vw" }}>Gulmarg:</b>
                                                        <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Gulmarg:</b> Known for its lush green meadows, Gulmarg is a popular hill station and skiing destination. Visitors can take the Gulmarg Gondola, one of the highest cable cars in the world, to reach Kongdori and enjoy panoramic views of the Himalayas.</p><br />
                                                    <p><b className='content-desktop' style={{ fontSize: "1.3vw" }}>Sonamarg:</b>
                                                        <b className='content-mobile2' style={{ fontSize: "3.5vw" }}>Sonamarg:</b>  Translating to "Meadow of Gold," Sonamarg is a scenic valley situated at an altitude of 2800 meters. It offers breathtaking views of snow-capped peaks, glaciers, and meadows. It's also the base for various trekking routes.</p><br />
                                                    <p><b className='tomato' style={{ fontSize: "1.3vw" }}>Pahalgam:</b> This quaint town is renowned for its pristine beauty, lush greenery, and the Lidder River. Pahalgam serves as a starting point for the annual Amarnath Yatra pilgrimage and offers opportunities for trekking, horse riding, and fishing.</p><br />
                                                    <p><b style={{ fontSize: "1.3vw" }}>Srinagar:</b> The summer capital of Jammu and Kashmir, Srinagar is famous for its Mughal gardens, including Shalimar Bagh, Nishat Bagh, and Chashme Shahi. Visitors can also explore the old city, visit the Jama Masjid, and indulge in shopping for traditional Kashmiri handicrafts in the local markets.</p><br />
                                                    <p><b className='tomato' style={{ fontSize: "1.3vw" }}>Pari Mahal:</b> Also known as the "Palace of Fairies," Pari Mahal is a historic monument located above the Chashme Shahi Gardens in Srinagar. It offers panoramic views of the city and Dal Lake and is surrounded by beautiful terraced gardens.</p><br />
                                                    <p><b style={{ fontSize: "1.3vw" }}>Betaab Valley:</b> Named after the Bollywood movie "Betaab," this picturesque valley is located near Pahalgam and offers stunning views of snow-capped mountains, lush greenery, and the Lidder River. It's a popular picnic spot and filming location.</p><br />
                                                    <p><b className='tomato' style={{ fontSize: "1.3vw" }}>Aru Valley:</b> Situated near Pahalgam, Aru Valley is known for its scenic beauty, alpine meadows, and trekking trails. It's a great place for nature lovers and adventure enthusiasts.</p><br />
                                                    <p><b style={{ fontSize: "1.3vw" }}>Mughal Road:</b> This historic road connects the Kashmir Valley with the regions of Poonch and Rajouri. It passes through picturesque landscapes, including dense forests, meadows, and small villages, offering a scenic drive.</p><br />
                                                    <p><b className='tomato' style={{ fontSize: "1.3vw" }}>Dachigam National Park:</b> Located near Srinagar, this national park is home to endangered species like the Kashmir stag (Hangul) and the Himalayan black bear. Visitors can enjoy wildlife safari tours and birdwatching in the park.</p><br />
                                                </div>
                                            </div>
                                            <div className={customActiveTab === 'tab5' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-meals-tab">
                                                <div className="overflow-table">
                                                    <h5 class="tomato">8 times Meals and 8 times Breakfast has been Provided</h5>
                                                    <table className="table table-bordered">
                                                        <tr className="table-danger">
                                                            <th colspan="6" className="text-center">Category</th>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-center">JAIN</td>
                                                            <td class="text-center">PURE-VEG</td>
                                                            <td class="text-center">VEGAN</td>
                                                            <td class="text-center">PURE-NON-VEG</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br /> <br /> <br />
                            {/* <!--By Itinerary--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>
                                                DAY WISE <strong><span>ITINERARY KASHMIR TOUR PACKAGES DOODHPATHRI YUSMARG</span></strong>
                                            </h2>
                                            <h4>8 Nights 9 Days <strong>Kashmir Tour Packages With Doodhpathri Yusmarg</strong></h4>
                                        </div>
                                    </div>
                                </div>


                                {/* <!--end Short description--> */}
                                <br />
                                <div className="row">
                                    <div
                                        className="accordion"
                                        id="accordionPanelsStayOpenExample"
                                    >
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day1"
                                            >
                                                <button
                                                    className="accordion-button"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseOne"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseOne"
                                                >
                                                    DAY 1  – ARRIVAL AT SRINAGAR
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseOne"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingOne"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        Upon arrival at Srinagar Sheikh Ul Alam Int’l Airport ( This must be one of the Most Scenic Flights in the World, Srinagar is popularly known as the “City of Reshi’s & Sufi’s” and it is also the Summer capital of state Jammu & Kashmir), you will be met by our representative and transfer to Hotel for Check in followed by welcome drink. In the evening you take a shikara ride on world famous Dal Lake, where you visit the floating vegetable gardens & open Dal Lake. While riding the Shikara’s there are plenty of opportunities for bird watching with plentiful species including Kingfisher, Little Bittern, Common Pariah Kites, Grebe etc. Overnight stay at HouseBoat.
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay1ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay1ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day2"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseTwo"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseTwo"
                                                >
                                                    DAY 02: SRINAGAR – GULMARG [1 hr 27 min (52.0 km) via Srinagar - Tangmarg Rd]
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseTwo"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingTwo"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        After a leisurely breakfast, check out from Houseboat & drive to Gulmarg via Bye-Pass. Gulmarg, called the "Meadow of Flowers". Gulmarg is at an altitude of 8700 ft. Gulmarg also referred to as Meadow of Flowers, is a famous Ski Resort & the world's highest 18 hole Golf Course. The place was prominent during British time. Gulmarg's legendary beauty, prime location and proximity to Srinagar naturally make it one of the premier hill resorts in the country. Originally called 'Gaurimarg' by shepherds, its present name was given in the 16th century by Sultan Yusuf Shah, who was inspired by the sight of its grassy slopes emblazoned with wild flowers. Gulmarg was a favourite haunt of Emperor Jehangir who once collected 21 different varieties of flowers from here. Today Gulmarg is not merely a mountain resort of exceptional beauty, it also has the highest green golf course in the world, at an altitude of 2,650 m, and is the country's premier ski resort in the winter. On Arrival in Gulmarg, check in to the pre-arranged Hotel.
                                                    </p>
                                                    <p>One can go for the Gondola Ride up to 1st or 2nd Phase. The Gulmarg Gondola is a tourist attraction in itself. It is one of the highest cable cars in the world, reaching 3,979 meters. The two-stage ropeway ferries about 600 people per hour to and from the gondola main station in Gulmarg to a shoulder of nearby Mt. Affarwat Summit [4,200 m / 13,780 ft]. There are also countless off-piste possibilities through the beautiful Khilanmarg meadows on the foot of Mount Affarwat, which offer a scenic view of Nanga Parbat. The Affarwat ridge at 3850+ meters offer an avalanche-controlled ski area that offers a wide field of snow to descend 800 m in approximately 3 km of skiing, and is for advanced skiers only. Due to Gulmarg's steep terrain, the region is popular amongst advanced and extreme skiers from around the world and has been visited by a number of ski professionals and featured in a number of ski films also. Return by the evening. Night Stay at Hotel in Gulmarg. Or, you can explore the Beauty of “Buta Pathri”. You can hire a union vehicle (directly) for a visit to Buta Pathri.</p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay2ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay2ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day3"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseThree"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 03: GULMARG – PAHALGAM [148 KMS – 4 TO 5 HRS ONE WAY]
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseThree"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingThree"
                                            >
                                                <div className="accordion-body">
                                                    <p>After a sumptious breakfast, check out from the Hotel & leave for Pahalgam – better known as the “Valley of Shepherds”. Pahalgam is situated at the confluence of the streams flowing from Sheshnag Lake and the Lidder River. Pahalgam (2,130 m) was once a humble shepherd’s village with breath-taking views. Enjoy nature & walk around the bank of river Lidder. Pahalgam is famous for some trekking routes also & is the base camp for Amarnath Pilgrimage. Pahalgam is also famous in the Indian film Industry (Bollywood). Now it is Kashmir's premier resort, cool even during the height of summer when the maximum temperature does not exceed 25 Degree C. Upon arrival, check in to the pre-arranged Hotel. Rest of the day is free for leisure.</p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay3ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay3ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day4"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseFour"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    Day 04: - PAHALGAM [LOCAL SIGHTSEEING Include]
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseFour"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingFour"
                                            >
                                                <div className="accordion-body">
                                                    <p>After a leisurely breakfast, a full day free to enjoy and explore the Beauty of “Pahalgam”. One can go to Chandanwari (16 Kms from Pahalgam) which is the starting point of Amarnath Yatra. Aru (10 kms from Pahalgam) which is a fine meadow of picturesque scenery and starting point of trekking of (Liddarwat, Kolahoi Glacier & Trsar Lake) & Betaab Valley.</p>
                                                    <p><b>ARU VALLEY :</b> It is located around 12 km from Pahalgam, 11 km upstream from the Lidder River. Noted for its scenic meadows, lakes and mountains, it is a base camp for trekking to the Kolahoi Glacier and Tarsar Lake. The village lies on the left bank of the Aru River, which is a tributary of the Lidder River the Aru valley is noted for its scenic meadows. It is popular among the tourists for its peaceful environment and scenic beauty. Other popular tourist activities include fishing for trout in the Lidder River, trekking, hiking, horse riding, sightseeing and photography.</p>
                                                    <p><b>BETAAB VALLEY :</b> It is situated at a distance of 15 kilometres from Pahalgam. The valley got its name from the Sunny Deol - Amrita Singh hit debut film Betaab. The valley is surrounded by lush green meadows, snow -clad mountains and covered with dense vegetation. Betaab Valley is a very popular tourist destination. Betaab Valley is also a favourite campsite of travellers as it also serves as a base camp for trekking and further exploration of the mountains. The valley is a walk-able distance from Pahalgam. The crystal clear & chilly water of the stream bubbling down from the snowy hills is a delight. Locals here drink this water too.</p>
                                                    <p><b>CHANDANWARI :</b>Chandanwari - Situated 16 km from Pahalgam, is the starting point of the Amarnath Yatra, which takes place every year in the month of Sawan (Rain). It is famous for snow sledding on a snow bridge. Taxi cabs can be hired from Pahalgam for a roundtrip. Snow fights is what people generally indulge in here before they sledge back down. Many prefer to sip a cup of tea or snack at the nearby dhabas. Hajan, on the way to Chandanwari is an idyllic spot for a picnic. Filmgoers will recognize it instantly as it has been the location of several movie scenes. Return by the evening for dinner & overnight stay at Hotel.</p>
                                                    <p>Or, you can explore the beauty of "Pahalgam". You can hire ponies (directly) for a visit to Baisaran Valley / Mini Switzerland.</p>
                                                    <p><b>BAISARAN VALLEY :</b>Baisaran Valley, a lush meadow surrounded by hills and covered with dense vegetation. This region looks very similar to European resorts and is a good campsite. Pine forests dot this meadow which presents a picturesque view of the snow-clad mountains. The most beautiful of these is the huge, undulating meadow of Baisaran, surrounded by thickly wooded forests of pine. Filmgoers will recognize it instantly as it has been the location of several movie scenes. Return by the evening. Overnight stay at Pahalgam Hotel.</p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay4ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay4ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day5"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseFive"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 05: PAHALGAM – SRINAGAR [96 KMS – 2 TO 3 HRS] ONE WAY IMAGE
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseFive"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingFive"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        After an early breakfast, check out from the Hotel & transfer to Srinagar. On arrival in Srinagar, take a visit of Hazratbal Shrine, Shankaracharya Temple, - It is believed that it was the place where the great philosopher and saint Shankaracharya stayed when he visited Kashmir ten centuries ago to revive Sanathan Dharma. The original ceiling was dome- shaped and the brick roof is not more than a century old. In the afternoon, we took a visit to Mughal Gardens, Nishat Garden known as ‘Garden of pleasure’ built in 16th century (1633) by Mughal King Asif Khan. Then visit to Shalimar Garden known as ‘Garden of love’ built in 16th century (1619) by Mughal King Jahangir for his beloved wife Noor Jahan or (Mehr-un-Nissa & Chashmashahi) (The Royal Spring). The Chashmashahi Mughal garden in Srinagar had been loved by emperors and picnickers alike. by the evening. Overnight stay at Srinagar Hotel.
                                                    </p>

                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay5ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay5ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day6"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseSix"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 06: SRINAGAR – DOODH PATHRI – SRINAGAR [42 KMS – 1 TO 2 HRS ONE WAY]
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseSix"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingSix"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        After an early breakfast, leave for a full day excursion to Doodhpathri. “Doodhpathri” - known as the Valley of Milk is a tourist destination and hill station in Kashmir. It is located in Budgam district of Jammu & Kashmir, located at a distance of 42 km from state summer capital Srinagar. It is said that the famous saint of Kashmir Sheikh ul Aalam Sheikh Noor din Noorani has prayed here, and once when he was in search of water in the meadows to offer prayers, he pricked the ground with his stick to search water, and milk came out, he asked the milk that you can only be used for drinking and not performing ablution. Hearing this milk at once changed its state to water and the meadow got its name DOODHPATHRI.
                                                    </p>
                                                    <p>The water which is at present flowing through the meadows looks like milk from a distance and remains very cold throughout the year. The lush green grasses over the vast meadows and silver shining stream running over the large stones further increase its beauty. Doodhpathri is sloping grassy landscapes with a diversity of multicoloured flowers up to Chang. The natural meadows, which are covered with snow in winter, allow the growth of wild flowers such as daisies, forget-me-nots and butter cups during spring and summer. Return by the evening to Srinagar. Overnight stay at Srinagar Hotel.</p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay6ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay6ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day7"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseSeven"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 07: SRINAGAR – SONMARG – SRINAGAR [87 KMS – 2 TO 3 HRS ONE WAY]
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseSeven"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingSeven"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        After an early breakfast, check out from Hotel & leave for a full day excursion to Sonmarg, which means Meadow of Gold, has its backdrop of snowy mountains against the blue sky. The drive offers incredible scenic beauty, dense pine forests and Gigantics Mountains. The place provides an ideal base for various trekking routes, high mountain lakes, glaciers, and Shri Amarnath Holy cave. The river meanders along here & abounds with Trout & Mahseer. Sonamarg’s importance lies in the fact that it is the last halt on the Kashmir side for the drive from Srinagar to Leh. Ponies / vehicles (direct payment basis) can be hired for the trip up to Thajiwas Glacier, Which is a major local attraction during the summer. Return back in the evening to Srinagar for Overnight stay at Hotel.
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay7ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay7ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day8"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseTen"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 08:SRINAGAR – YUSMARG – SRINAGAR [55 KMS – 1 TO 2 HRS ONE WAY]
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseTen"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingTen"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        After an early breakfast, leave for a full day excursion to Yusmarg in our vehicle and drive through the Historic LalChowk towards the bye pass. We will cross the byepass road and begin an exciting two hours journey to a place relatively unknown to the outside world. The Drive to Yusmarg takes you across grassy meadows, ringed by forests of pine, and towering beyond them, the awesome and majestic snow clad mountains. On your arrival in Yusmarg you can take all kinds of walks across the rolling meadows which sometimes resemble its twin resort of Gulmarg. You can also stroll along the flower strewn meadows towards the mighty river froths of the Doodh Ganga. Yusmarg is ringed by several notable peaks including Tata-Kuti and Sang Safed. About 3 kilometers below Yusmarg a historical and religious place will be visited where stands the tomb of Sheikh Nu-Ud-Din who is still revered by both muslims and Hindus from time to time. We will drive back to Srinagar in the evening and transfer to the hotel for our. Overnight stay at Srinagar Hotel.
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay8ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay8ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day9"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseNine"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseNine"
                                                >
                                                    DAY 09: DEPART SRINAGAR [FLY OUT]
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseTen"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingNine"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        <strong>Kashmir Tour concludes Adorable Vacatioon</strong> thanks you for your patronage and ensures a punctual transfer to Domestic Airport to catch the flight for your onward Destination.
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay9ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay9ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />

                            {/* <!--insert table inclusion exclusion--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        {
                                            <InclusionExclusion />
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* <!--end table inclusion exclusion--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center" >
                                            <h2>The Khyber Himalayan Resort & Spa <span>- Gulmarg</span></h2>
                                            <h4><i>Next Level Luxury Resort </i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row py-3">
                                    <img src={brandedTourackageImage_1_URL} alt="..." />
                                </div>

                                <div id="no-more-tables" >
                                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                        <thead>
                                            <tr className="table-danger">
                                                <th>Category</th>
                                                <th>Size(Room)</th>
                                                <th>Hotel Brand</th>
                                                <th>Price (Per Night)</th>
                                                <th>Book Now</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td data-title="Packages">Platinum Accommodation</td>
                                                <td data-title="Size(Room)">386+ SQ.FT</td>
                                                <td data-title="Hotel Brand">Khyber Hotels (Heritage)<div className="reviews">
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                </div></td>
                                                <td data-title="Price (Per Night)">Rs 53,119/- </td>
                                                <td data-title="Book Now"><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick}>
                                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                                </a></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                <div className="card mx-0 my-5">
                                    <div className="card-header custHeaer">
                                        <h3 className='content-desktop' style={{ fontSize: "1.5vw" }}><b>HEAVEN IS CLOSER THAN YOU CAN IMAGINE</b></h3>
                                        <h3 className='content-mobile2' style={{ fontSize: "3.5vw" }}><b>HEAVEN IS CLOSER THAN YOU CAN IMAGINE</b></h3>
                                    </div>
                                    <div className="card-body">
                                        <p ><b>8,825 feet</b> above sea level in the Pir Panjal range of the Himalayas lies a higher order of luxury. <b>The Khyber Himalayan Resort & Spa</b> is spread over seven unspoilt, fir-laden acres, with majestic views of the Affarwat Peaks. This 85-room resort is framed at every vantage point by nature at its most spectacular. <b>The world’s highest ski lift</b>, the Gulmarg Gondola – which takes you to Kongdoori Mountain, is only a short walk away. Meadows and forests that have enchanted travellers for centuries wait to be explored by trekking enthusiasts and undying romantics. Clad in locally sourced pine and slate, the resort is built in a style true to the architectural heritage of Kashmir. In every way the indoors mirror the beauty of the outdoors. Specialty restaurants, a cigar lounge are amongst the necessary luxuries in place. Add to these other thoughtful additions like our children’s club, a mini theatre and a dedicated Activities Concierge and you will find The Khyber is a resort at par with the best in the world.</p>

                                        <a href="tel://9883359713" className="btn btn-Warning">Book Now</a>

                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2 >The LaLiT Grand Place <span>- Srinagar</span></h2>
                                            <h4 ><i>Fill the Luxury</i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row py-3">
                                    <img src={brandedTourackageImage_2_URL} alt="..." />
                                </div>

                                <div id="no-more-tables" >
                                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                        <thead>
                                            <tr className="table-danger">
                                                <th>Category</th>
                                                <th>Size(Room)</th>
                                                <th>Hotel Brand</th>
                                                <th>Price (Per Night)</th>
                                                <th>Book Now</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td data-title="Packages">Premium Accommodation</td>
                                                <td data-title="Size(Room)">290+ SQ.FT</td>
                                                <td data-title="Hotel Brand">The LaLiT Hotels<div className="reviews">
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                </div></td>
                                                <td data-title="Price (Per Night)">Rs 28,332/- </td>
                                                <td data-title="Book Now"><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick}>
                                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                                </a></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                <div className="card mx-0 my-5">
                                    <div className="card-header custHeaer">
                                        <h3 className='content-desktop' style={{ fontSize: "1.5vw" }}><b>EMBARKED ON A SENSE OF PRIDE AT PREMIUMNESS</b></h3>
                                        <h3 className='content-mobile2' style={{ fontSize: "3.5vw" }}><b>EMBARKED ON A SENSE OF PRIDE AT PREMIUMNESS</b></h3>
                                    </div>
                                    <div className="card-body">
                                        <p ><b>The LaLit Grand Palace</b> was originally built in 1901 for the royal family. It now provides luxurious rooms with views of Dal Lake and the beautiful gardens. An indoor pool, tennis courts and pampering spa treatments are also available.The tastefully designed rooms are modern with a touch of traditional charm. All have a satellite TV, minibar and free tea/coffee making facilities. It is 18 km from Srinagar Airport and 4 km from Lal Chowk city centre. Jammu Railway Station is 300 km away. Guests can enjoy a relaxing massage at Rejuve Spa Health Club, practice yoga, or play golf. The hotel also has a gym and 24-hour business center. The Chinar Garden serves North Indian cuisine, while international buffet spreads are available at The Chinar – The All Day Coffee Shop. Other dining options include Dal Bar and 24-hour room service.</p>

                                        <a href="tel://9883359713" className="btn btn-Warning">Book Now</a>

                                    </div>
                                </div>
                            </div>

                            {/* <!--start of package details--> */}
                            <div className="container-fluid py-5">

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>Kashmir<span> Tour With Doodhpathri Yusmarg</span></h2>
                                            <h4>Related Kashmir Tour plan</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                                        <div className="carousel-inner">
                                            <div className="carousel-item active">

                                                <Slider {...settings}>
                                                    {hotPackagesView}
                                                </Slider>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!--end of package details--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2 >Frequently Ask <span>Questions</span></h2>
                                            <h4 >Kashmir Tour With Doodhpathri Yusmarg Trip Related Questions</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="row" >
                                    <div className="accordion" id="accordionPanelsStayOpenExample">
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingTwo">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                                    What is the best time to visit Kashmir for this tour?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseTwo" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    The best time to visit is from April to October, when the weather is pleasant and the landscapes are lush and vibrant. Spring and summer are ideal for sightseeing, while early autumn offers beautiful fall foliage.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingThree">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                                    How can I reach Kashmir for the tour?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseThree" className="accordion-collapse collapse show" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    You can fly into Sheikh Ul Alam International Airport in Srinagar, which is well-connected to major cities in India. From there, our tour package includes transportation to all destinations.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingFour">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                                    Is it safe to travel to Doodhpathri and Yusmarg?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseFour" className="accordion-collapse collapse show" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    Yes, Doodhpathri and Yusmarg are generally safe for tourists. We recommend following local guidelines and staying informed about the current situation.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingFive">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                                                    What kind of accommodations are included in the package?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseFive" className="accordion-collapse collapse show" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    The package includes comfortable stays in hotels, houseboats, and guesthouses, offering a mix of traditional and modern amenities.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingSix">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                                                    What should I pack for the trip?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseSix" className="accordion-collapse collapse show" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    Pack comfortable clothing, warm layers (as evenings can be cool), comfortable walking shoes, and personal essentials. Don’t forget a camera to capture the stunning landscapes.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingSeven">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
                                                    Are meals included in the tour package?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseSeven" className="accordion-collapse collapse show" aria-labelledby="flush-headingSeven" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    The package usually includes breakfast and dinner. However, it's best to confirm the meal plan with the tour provider.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingEight">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
                                                    What activities are available in Doodhpathri and Yusmarg?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseEight" className="accordion-collapse collapse show" aria-labelledby="flush-headingEight" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    In Doodhpathri, you can enjoy trekking, picnicking, and exploring the meadows. In Yusmarg, popular activities include scenic walks, horse riding, and nature exploration.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingNine">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseNine">
                                                    Is this tour suitable for families and elderly travelers?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseNine" className="accordion-collapse collapse show" aria-labelledby="flush-headingNine" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    Yes, the tour is suitable for all ages, including families with children and elderly travelers. The itinerary is designed to provide a comfortable and enjoyable experience for everyone.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingTen">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
                                                    Do I need a special permit to visit Doodhpathri and Yusmarg?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseTen" className="accordion-collapse collapse show" aria-labelledby="flush-headingTen" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    No special permits are required for Indian nationals. However, international tourists may need to check the local requirements and carry valid identification.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingEleven">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEleven" aria-expanded="false" aria-controls="flush-collapseEleven">
                                                    Can I customize the tour package?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseEleven" className="accordion-collapse collapse show" aria-labelledby="flush-headingEleven" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    Yes, most tour providers offer customization options to suit your preferences, including extending your stay or adding additional activities.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center" >
                                            <h3><b>Kashmir <span class="tomato">Holiday Reviews</span></b></h3>
                                            <h4><i>"Your Experience is our Marketing"</i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-cols-1">
                                    <div id="testimonials">
                                        <div className="testimonial-box-container" >

                                            {reviewersDataView}

                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="col-md-3">
                            <BookingBox />
                        </div>
                    </div >
                </div >
                <div class="btn-call-pulse">
                    <a style={{ color: 'white' }} href="tel:+91-91470-61467">
                        <i className="fa fa-phone"></i>
                        {/* <span><small>+91 9147061467</small></span> */}
                    </a>
                </div>
                <div className="btn-whatsapp-pulse">
                    <a style={{ color: 'white' }} href="#" onClick={this.handleClick}>
                        <i className="fab fa-whatsapp"></i>
                        {/* <span>
                                <small>+91 9883359713</small>
                            </span> */}
                    </a>
                </div>

            </>
        );
    }
}

export default Kashmir_8N_9D_Doodhpathri_Yusmarg_Package;