import React, { Component } from 'react';
import AdventureTourLadakhToSikkim from '../../components/package/AdventureTourLadakhToSikkim';
import MainHeader from '../../components/common/MainHeader';
import FooterDesktop from '../../components/common/FooterDesktop';

class AdventureTourLadakhToSikkimPage extends Component {
    constructor() {
        super();
        this.state={
          HotPackagesData:[],
          HotelsData:[],
          slidesToShow: 3,
          hotelSlidesToShow: 3
        }    
      }
    
      componentDidMount() {
        window.scroll(0, 0);  
        const hotPackagesDataList = [
            {
                ImageName: "package_1.jpg",
                AllternateText: "...",
                Description: "This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.",
                Title: "Saloon services",
                PricePerNight: "3450/Night",
                MutedText: "Package 1"
            },
            {
                ImageName: "package_2.jpg",
                AllternateText: "...",
                Description: "This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.",
                Title: "Saloon services",
                PricePerNight: "3450/Night",
                MutedText: "Package 2"
            },
            {
                ImageName: "package_3.jpg",
                AllternateText: "...",
                Description: "This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.",
                Title: "Saloon services",
                PricePerNight: "3450/Night",
                MutedText: "Package 3"
            },
        ];

        const hotelDataList=[
            {
                ImageName: "hotel_1.jpg",
                AllternateText: "...",
                Description: "This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.",
                Title: "Sundarban",
                PricePerNight: "3450/Night",
                MutedText: "Sundarban WB"
            },
            {
                ImageName: "hotel_2.jpg",
                AllternateText: "...",
                Description: "This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.",
                Title: "Sundarban",
                PricePerNight: "3450/Night",
                MutedText: "Sundarban WB"
            },
            {
                ImageName: "hotel_3.jpg",
                AllternateText: "...",
                Description: "This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.",
                Title: "Sundarban",
                PricePerNight: "3450/Night",
                MutedText: "Sundarban WB"
            }
        ]
    
        this.setState({ 
            HotPackagesData: hotPackagesDataList, 
            HotelsData: hotelDataList 
        })
    }

    render() {
        return (
            <>
                <MainHeader />
                <AdventureTourLadakhToSikkim 
                    slidesToShow={this.state.slidesToShow} 
                    hotPackagesDataList ={this.state.HotPackagesData} 
                    HotelsData={this.state.HotelsData}
                    hotelSlidesToShow={this.state.hotelSlidesToShow}
                />
                <FooterDesktop />
            </>
        );
    }
}

export default AdventureTourLadakhToSikkimPage;