import React, {Component, Fragment} from 'react';
import {Breadcrumb, Card, Col, Container, Row} from "react-bootstrap";

class Refund extends Component {
    render() {
        return (
            <Fragment>
                <Container className="TopSection">                    
                    <Breadcrumb className="w-100 bg-white">
                        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                        <Breadcrumb.Item>Refund Policy</Breadcrumb.Item>
                    </Breadcrumb>
                    <Row>
                        <Col className="mt-2" md={12} lg={12} sm={12} xs={12}>
                            <Card>
                                <Card.Header>
                                    <h3>Cancellation/Refund Policies</h3>
                                </Card.Header>
                                <Card.Body>
                                    <div style={{marginTop:"10px", padding:"10px 2px" }}>
                                        <h5 style={{textAlign: "justify"}}><strong>RETURNS</strong></h5>
                                        <p style={{textAlign: "justify"}}>Our policy lasts 30 days. If 30 days have gone by since your purchase, unfortunately we can&rsquo;t offer you a refund or exchange. Voucher if purchased/ received as gift shall not be eligible for refund. To be eligible for a return, you must have purchased the voucher in MRP and your voucher code must be unused/ unaltered. A processing fee of 30% shall be deducted from actual voucher price.</p>
                                        <p style={{textAlign: "justify"}}>To complete your return, we require the original mail denoting proof of purchase.</p>
                                        <p style={{textAlign: "justify"}}>Please do not send/ forward the mail to anywhere other than info@feescount.com.</p>
                                        <p style={{textAlign: "justify"}}>&nbsp;</p>
                                        <h5 style={{textAlign: "justify"}}><strong>REFUNDS</strong></h5>
                                        <p style={{textAlign: "justify"}}>Once initiate a return request, we will send you an email to notify you that we have received your request. We will also notify you of the approval or rejection of your refund.</p>
                                        <p style={{textAlign: "justify"}}>If you are approved, then your refund will be processed, and a credit will automatically be applied to your credit card or original method of payment, within a certain amount of days.</p>
                                        <p style={{textAlign: "justify"}}>&nbsp;</p>
                                        <h5 style={{textAlign: "justify"}}><strong>LATE OR MISSING REFUNDS</strong></h5>
                                        <p style={{textAlign: "justify"}}>If you haven&rsquo;t received a refund yet, first check your bank account again.</p>
                                        <p style={{textAlign: "justify"}}>Then contact your credit card company, it may take some time before your refund is officially posted.</p>
                                        <p style={{textAlign: "justify"}}>Next contact your bank. There is often some processing time before a refund is posted.</p>
                                        <p style={{textAlign: "justify"}}>If you&rsquo;ve done all of this and you still have not received your refund yet, please contact us at&nbsp;<strong>info@feescount.com</strong></p>
                                        <p style={{textAlign: "justify"}}><strong>&nbsp;</strong></p>
                                        <h5 style={{textAlign: "justify"}}><strong>OFFERED/ GIFT VOUCHERS</strong></h5>
                                        <p style={{textAlign: "justify"}}>Only regular priced vouchers may be refunded, unfortunately sale/ offered/ gifted vouchers cannot be refunded.</p>
                                        <p style={{textAlign: "justify"}}><strong>&nbsp;</strong></p>
                                        <h5 style={{textAlign: "justify"}}><strong>EXCHANGES</strong></h5>
                                        <p style={{textAlign: "justify"}}>We only replace voucher codes in case if it gets rejected by the concerned institute or the code cannot be redeemed due to technical problems. If you need to exchange it for any other course programme, send us an email at&nbsp;<strong>info@feescount.com</strong></p>
                                        <p style={{textAlign: "justify"}}>&nbsp;</p>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default Refund;
