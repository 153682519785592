import React, { Component } from 'react';
import MainHeader from '../../../components/common/MainHeader';
import FooterDesktop from '../../../components/common/FooterDesktop';
import MeghalayaTourPackage from '../../../components/package/Meghalaya/MeghalayaTourPackage';
import { Helmet } from "react-helmet";

class MeghalayaTourPackagePage extends Component {
    constructor() {
        super();
        this.state = {
            HotPackagesData: [],
            ThemePackagesData: [],
            HotelsData: [],
            ReviewersData: [],
            TravellersData: [],
        }
    }

    componentDidMount() {
        window.scroll(0, 0);
        const hotPackageDataList = [
            {
                Title: "Untouched Manipur",
                ImageName: "5N-6D-manipur.webp",
                AllternateText: "...",
                Description: "2N Kohima |1N Imphal|1N Moreh |1N Imphal | Private Transfer | Deluxe Hotel",
                PricePerNight: "24,990",
                NoOfTours: 47,
                NoOfDeparture: 40,
                Include: "EX-DMU",
                MutedText: "*Per head / Min 6 Pax",
                Url:'/Assam-Meghalaya-5-Day-Itinerary'
            },
            {
                Title: "Peaceful Meghalaya",
                ImageName: "6N-7D-meghalaya.webp",
                AllternateText: "...",
                Description: "2N Kaziranga | 3N Shillong | 1N Guwahati | Private Transfer | Deluxe Hotel",
                PricePerNight: "22,800",
                NoOfTours: 43,
                NoOfDeparture: 38,
                Include: "EX-DMU",
                MutedText: "*Per head / Min 6 Pax",
                Url:'/meghalaya-6N-7D-Tour-package'
            },
            {
                Title: "Unexplored Manipur",
                ImageName: "7N-8D-manipur.webp",
                AllternateText: "...",
                Description: "2N Kaziranga | 2N Kohima | 3N Imphal | Private Transfer | Deluxe Hotel",
                PricePerNight: "27,900",
                NoOfTours: 44,
                NoOfDeparture: 37,
                Include: "EX-DMU",
                MutedText: "*Per head / Min 6 Pax",
                Url:'/meghalaya-7N-8D-Tour-package'
            },

            {
                Title: "Extended Meghalaya",
                ImageName: "8N-9D-meghalaya.webp",
                AllternateText: "...",
                Description: "2N Kaziranga | 3N Shillong | 1N Cherrapunjee...more | Private Transfer | Deluxe Hotel",
                PricePerNight: "33,700",
                NoOfTours: 46,
                NoOfDeparture: 39,
                Include: "EX-DMU",
                MutedText: "*Per head / Min 6 Pax",
                Url:'/arunachal-8N-9D-Tour-package'
            },
            {
                Title: "Glimpses of North East",
                ImageName: "10N-11D-sangti-vally.webp",
                AllternateText: "...",
                Description: "2N Guwahati | 1N Kaziranga | 1N Bhalukpong...more | Private Transfer | Deluxe Hotel",
                PricePerNight: "28,300",
                NoOfTours: 44,
                NoOfDeparture: 37,
                Include: "EX-DMU",
                MutedText: "*Per head / Min 6 Pax",
                Url:'/arunachal-10N-11D-Tour-package'
            },
            {
                Title: "Extended North East",
                ImageName: "11N-12D-madhuri.webp",
                AllternateText: "...",
                Description: "2N Guwahati | 3N Shillong | 1N Kaziranga...more | Private Transfer | Deluxe Hotel",
                PricePerNight: "53,899",
                NoOfTours: 47,
                NoOfDeparture: 40,
                Include: "EX-DMU",
                MutedText: "*Per head / Min 6 Pax",
                Url:'/arunachal-11N-12D-Tour-package'
            }

        ];

        const hotelDataList = [
            {
                HotelName: 'D’Courtyard Resort,Kaziranga',
                ImageName: "D-Courtyard.webp",
                AllternateText: "...",
                HotelStar: '★★★',
                RatingBoxStar: '★',
                RatingValue: 4.9,
                Reviews: '50 Reviews'
            },
            {
                HotelName: 'Dhanshree Resort, Kaziranga',
                ImageName: "dhanashree-resort.webp",
                AllternateText: "...",
                HotelStar: '★★★',
                RatingBoxStar: '★',
                RatingValue: 4.8,
                Reviews: '47 Reviews'
            },
            {
                HotelName: 'La Vue Resort, Kaziranga',
                ImageName: "la-vue.webp",
                AllternateText: "...",
                HotelStar: '★★★',
                RatingBoxStar: '★',
                RatingValue: 4.7,
                Reviews: '46 Reviews'
            },
            {
                HotelName: 'The Eee Cee Hotel,Shillong',
                ImageName: "lee-cee-hotel.webp",
                AllternateText: "...",
                HotelStar: '★★★',
                RatingBoxStar: '★',
                RatingValue: 4.6,
                Reviews: '48 Reviews'
            },
            {
                HotelName: 'Hotel Sapphire,Shillong',
                ImageName: "shaipper-hotel.webp",
                AllternateText: "...",
                HotelStar: '★★★',
                RatingBoxStar: '★',
                RatingValue: 4.8,
                Reviews: '49 Reviews'
            },
            {
                HotelName: 'Hotel Jessica Residency,Shillong',
                ImageName: "jasika-residency.webp",
                AllternateText: "...",
                HotelStar: '★★★',
                RatingBoxStar: '★',
                RatingValue: 4.7,
                Reviews: '43 Reviews'
            },
            {
                HotelName: '7 Sisters Falls View Inn,Cherrapunji',
                ImageName: "7-sister-falls.webp",
                AllternateText: "...",
                HotelStar: '★★★',
                RatingBoxStar: '★',
                RatingValue: 4.6,
                Reviews: '44 Reviews'
            },
            {
                HotelName: 'Serene Resort,Cherrapunji',
                ImageName: "seana-resort.webp",
                AllternateText: "...",
                HotelStar: '★★★',
                RatingBoxStar: '★',
                RatingValue: 4.8,
                Reviews: '40 Reviews'
            }
        ];

        const themePackageDataList = [
            {
                Title: "Meghalaya Honeymoon",
                ImageName: "meghalaya-honeymoon-package.webp",
                AllternateText: "...",
                Description: "Escape to the romantic heaven of Meghalaya to experience the blissful beauty of the Land of Clouds. Glide your boat of love on the transparent waters of the Umngot River.",
            },
            {
                Title: "Wedding at Shillong",
                ImageName: "Shillong-Wedding-package-tour.webp",
                AllternateText: "...",
                Description: "Away from the maddening crowd, find yourself a place in the hills where you can say your wedding vows to your partner behind the lush greens and and scenic landscapes.",
            },
            {
                Title: "Meghalaya Solo Trip",
                ImageName: "meghalaya-cherapunji-solo-trip.webp",
                AllternateText: "...",
                Description: "A hill station that has the potential to captivate the hearts and minds of solo travellers visiting the state which offers a plethora of attractions, come explore Meghalaya like never before.",
            },
            {
                Title: "Shillong Adventure Trip",
                ImageName: "shillong-adventure-trip.webp",
                AllternateText: "...",
                Description: "After NEPAL, Bhutan is an amazing counter destiation for Adventure lover. Explore with thrilling activities - River Rafting, Paragliding & Kayaking etc.",
            },
            {
                Title: "Meghalaya Bike Trip",
                ImageName: "Meghalaya-bike-trip.webp",
                AllternateText: "...",
                Description: "The Riding Experience in Meghalaya welcomes you with a stunning ray of sights and experiences, spellbinding cliffs, rushing rivers, windswept highlands, and lush greenery.",
            },
            {
                Title: "Meghalaya Trek",
                ImageName: "Shillong-cherrapunji-trekking-plan.webp",
                AllternateText: "...",
                Description: "Meghalaya would leave one rejoicing in the breathtaking yet infrequent natural reserves like the trek to the living root bridge or the double decker root bridge near Tyrna village in Cherrapunji.",
            },

        ];

        const reviewersDataList = [
            {
                Name: "Yash Singh",
                ImageName: "Shivaji-Mhatre.webp",
                AllternateText: "...",
                Location: "@Mumbai",
                Comment: "We just had a wonderfull trip with my family and loved the hotels suggested by Adorable Vacation was top notch and loved every bit staying over there.Thank you so much Noorul and Soumya for your trenmendous effort and hoping to travel with you again soon.",
            },
            {
                Name: "Venkat Naidu",
                ImageName: "c-reddy.webp",
                AllternateText: "...",
                Location: "@Bengaluru",
                Comment: "Had a comfortabe trip with Adorable Vacation. They took care of us throught the trip and took stock of our situtaion weather we had comfortable trip or not. Thank you so much Noorul and Soumya for your trenmendous effort and hoping to travel with you again soon.",
            },
            {
                Name: "Vijay Kumar",
                ImageName: "arvind-pillai.webp",
                AllternateText: "...",
                Location: "@Hyderabad",
                Comment: "We have took our recent trip with Adorable and it made a dream come true moment with mesmirising places to see my total family loved and special thanks to our driver Mr Sandip for explaing the places and showing us many places too.",
            },
            {
                Name: "Kanimozhi Murgesan",
                ImageName: "dhalaksmi-arjun.webp",
                AllternateText: "...",
                Location: "@Chennai",
                Comment: "I have completed my trip to meghalaya with adorable vacation. We had a very comfortable trip and Our trip planner Mr Soumya helped customize our tour patiently and as per our needs. Our tour operator Ms Anushka co-ordinated the tour very well. She took constatly about our well being and kept in check that we are comfortable enough or not. Thanks to team adorable for making this trip a memorable one for us.",
            },
        ];

        const travellersDataList = [
            {
            Title:"1. Trek To Bewildering Living Root Bridges",
            ImageName:"root-bridge.webp",
            Description:"Meghalaya's most famous root bridge, the 'double-decker' root bridge,can be found in the vicinity of one of the wettest places on earth - Cherrapunji (also known as Sohra). There are 11 functional root bridges in this area, situated about two and a half hours drive from Shillong.",
            },
            {
                Title:"2. Camping Around Umiam Lake",
                ImageName:"Umiam-Lake.webp",
                Description:"Camping in the Island in Umium Lake provides the ultimate fun filled camping and night trekking experience like never before with your family and friends.",
            },
            {
                Title:"3. Explore Mawsmai Cave",
                ImageName:"Mawsmai-cave.webp",
                Description:"You do not have to be a professional spelunker to get a taste of Meghalaya’s cave systems. Mawsmai Cave is located at a short distance from Cherrapunji and is an easy attraction to access and explore."
            },
            {
                Title:"4. Water Sports At Umiam Lake",
                ImageName:"Uminh-lake.webp",
                Description:"When at Umiam Lake, you cannot miss water sports. It is a haven for adventure enthusiasts. Arrayed with exciting and thrilling activities like boating, kayaking, skiing, and angling, Umiam Lake guarantees a fun time. Unwind and relax with your close ones away from the tedious city life on the yacht or unleash your stress by riding the speed boat.",
            },
            {
                Title:"5. Walking Along The David Scott Trail",
                ImageName:"david-trek.webp",
                Description:"The trail passes through quaint Khasi villages, ancient sacred groves,lush green meadows, sparse fields,rolling mountains,rushing streams, stone bridges, and natural pools."
            },
            {
                Title:"6. Expedition At Siju Cave",
                ImageName:"siju-cave.webp",
                Description:"Siju Cave is one of the longest caves in India. Located in the Garo Hills, it is also one of the most researched cave systems in India. It is also known as the Bat cave as it is the home to as many as 9 species of bats."
            },
            {
                Title:"7. Trekking At Laitlum Canyon",
                ImageName:"litelum-cayon.webp",
                Description:"Perched on the East Khasi Hills, Laitlum Canyons is a less explored but one of the most picturesque tourist and trekking destinations in Shillong.",
            },
            {
                Title:"8. Play Golf At Gleneagles",
                ImageName:"golf-Gleneagles.webp",
                Description:"Known as the 'Gleneagles of East', Shillong Golf Course is a jewel in the crown of Meghalaya's golfing experience.",
            },
            {
                Title:"9. Trip To Mawphanlur Village",
                ImageName:"mau-village.webp",
                Description:"Mawphanlur is a small village in the Khasi Hills of Meghalaya. It is a beautiful place to enjoy hiking, boating and kayaking in the lap of Khasi Hills.",
            },
            {
                Title:"10. Shop At Bara Bazaar Shillong",
                ImageName:"bara-bazar.webp",
                Description:"Once you’re at the Bara Bazaar market, you must hoard some of the iconic things available here. The first and foremost of them are the Khasi knives and one-of-its-kind Khasi floor scrubber brushes. You can either use them or simply keep them back with you as a memory of the market. Another interesting thing to snag from here is the feathered Khasi arrow, symbolic of the archery practised by the Khasi people.",
            },
            {
                Title:"11. Photography At Elephant Falls",
                ImageName:"elephant-falls.webp",
                Description:"Named after an Elephant like stone at its foot, the Elephant Falls are amongst the most popular falls in the North-East, situated next to Shillong. It is a tourists' paradise with three layers of the falls accessible to the layman from different vantage points.",
            },
            {
                Title:"12. Visit Don Bosco Museum",
                ImageName:"don-bosco.webp",
                Description:"Seven storeys of fascinating visuals, colours, and treasures – that is one way to describe Don Bosco Museum. You will first be greeted by the traditional wear of all the different North-Eastern communities as you enter the building, including well-researched sections on the neighbouring countries like Bhutan,Myanmar, and Nepal. The photography section is loaded with rare photos – you can spend hours lost exploring these snippets of tribal life taken across the region and spanning many generations.",
            },
            {
                Title:"13. Lunch At Trattori",
                ImageName:"tittoria.webp",
                Description:"Located right in the middle of the frenetic Police Bazar, Trattoria enjoys a cult following among the locals who swear by its flavours.Trattoria’s specialty lies in the innovative preparation and presentation of local Khasi food. A very tiny establishment in terms of the area it covers, Trattoria is not the place to enjoy the ambience or spend a laid back evening. The place is always crowded and might result in some waiting time for your order but the wait is always worth it. Instead of a leisurely meal,you are most often than not, expected to simply go, place your order, eat your food and leave with satisfaction and a new experience!",
            },
            {
                Title:"14. Churches Of Shillong",
                ImageName:"curches.webp",
                Description:"Cathedral Of Mary Help Of Christians,All Saints Church,Shillong Baptist Church,Independent Church Of India,Pentecostal Church Of God,Jaiaw Presbyterian Church,Baptist Church Of Mizoram,Seventh-Day Adventist Church,Umpling Presbyterian Church,Full Gospel Fellowship Church.",
            },
            {
                Title:"15. Explore The Mawlynnong Village",
                ImageName:"Mawlynnong-village.webp",
                Description:"Mawlynnong has earned a reputation for being one of the cleanest villages in Asia, but a sobriquet that does more justice is that of God’s Own Garden. The fruit orchards,the rushing streams, the evergreen surroundings, the swaying palms, and the well-preserved traditions of the Khasis provide a picture-perfect stage on the edge of Meghalaya’s southern ranges.",
            },
        ];

        this.setState({
            HotPackagesData: hotPackageDataList,
            ThemePackagesData: themePackageDataList,
            HotelsData: hotelDataList,
            ReviewersData: reviewersDataList,
            TravellersData: travellersDataList
        })
    }

    render() {
        return (
            <>
               <Helmet>
                    <title>Meghalaya Tour Packages from Guwahati Airport</title>
                </Helmet>
                <MainHeader />
                <MeghalayaTourPackage
                    HotPackagesData={this.state.HotPackagesData}
                    ThemePackagesData={this.state.ThemePackagesData}
                    HotelsData={this.state.HotelsData}
                    ReviewersData={this.state.ReviewersData}
                    TravellersData={this.state.TravellersData}
                />
                <FooterDesktop />
            </>
        );
    }
}

export default MeghalayaTourPackagePage;