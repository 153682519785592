import React, { Component } from 'react';
import BookingBox from '../BookingBox';
import Slider from "react-slick";
import ApiURL from "../../../api/ApiURL";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InclusionExclusion from '../Bhutan/InclusionExclusion';

class FIFO_5N_6D_Package extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customActiveTab: 'tab1',
            daywiseActiveTab: 'day1'
        }
    }

    handleCustomTabClick = (tab) => {
        this.setState({
            customActiveTab: tab,
        });
    };

    handleDayWiseTabClick = (tab) => {
        this.setState({
            daywiseActiveTab: tab,
        });
    };

    render() {
        const { customActiveTab, daywiseActiveTab } = this.state;
        const packageTopBannerImageURL = ApiURL.FIFO_5N_6D_ImageURL + "/heder-image.webp";
        const navDay1ImageURL = ApiURL.FIFO_5N_6D_ImageURL + "/day1.webp";
        const navDay2ImageURL = ApiURL.FIFO_5N_6D_ImageURL + "/day2.webp";
        const navDay3ImageURL = ApiURL.FIFO_5N_6D_ImageURL + "/day3.webp";
        const navDay4ImageURL = ApiURL.FIFO_5N_6D_ImageURL + "/day4.webp";
        const navDay5ImageURL = ApiURL.FIFO_5N_6D_ImageURL + "/day5.webp";
        const navDay6ImageURL = ApiURL.FIFO_5N_6D_ImageURL + "/day6.webp";

        const hotPackagesDataList = this.props.HotPackagesData;
        const hotPackagesView = hotPackagesDataList.length > 0 ?
            (
                hotPackagesDataList.map((dataItem, i) => {
                    const hotelImgURL = ApiURL.FIFO_5N_6D_ImageURL + "/" + dataItem.ImageName;
                    return (<div className="col-md-12 p-0" key={i}>
                        <div className="card h-100">
                            <img src={hotelImgURL} className="card-img-top" alt={dataItem.AllternateText} />
                            <div className="card-body">
                                <h6 className="card-title">{dataItem.Title} <span> &#8377; {dataItem.PricePerNight}</span></h6>
                                <p className="card-text">
                                    <i className="fa fa-building tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-utensils tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-car-side tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-male tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-camera tomato"></i>
                                </p>
                                <p className="card-text"><span>{dataItem.Description}</span></p>
                                <p>
                                    <span><b className="tomato">{dataItem.NoOfTours} </b>Tours</span> | &nbsp;
                                    <span><b className="tomato">{dataItem.NoOfDeparture} </b>Departures</span> | &nbsp;
                                    <span><b className="sdf-blue">{dataItem.SDFInclude}</b></span>
                                </p>
                            </div>
                            <div className="card-footer">
                                <small className="text-muted"><strong className="tomato">{dataItem.MutedText}</strong></small>
                                <button type="button" className="btn btn-Warning btn-sm float-end">Book Now</button>
                            </div>
                        </div>
                    </div>)
                })
            ) : (<div></div>)


        const settings = {
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const reviewersDataList = this.props.ReviewersData;
        const reviewersDataView = reviewersDataList.map((dataItem, i) => {
            const reviewerImageURL = ApiURL.FIFO_5N_6D_ImageURL + "/" + dataItem.ImageName;
            return (
                <div className="testimonial-box" key={i}>
                    <div className="box-top">
                        <div className="profile">
                            <div className="profile-img">
                                <img src={reviewerImageURL} alt="..." />
                            </div>
                            <div className="name-user">
                                <strong>{dataItem.Name}</strong>
                                <span>{dataItem.Location}</span>
                            </div>
                        </div>
                        <div className="reviews">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </div>
                    </div>
                    <div className="client-comment">
                        <p>{dataItem.Comment}</p>
                    </div>
                </div>
            )
        })



        return (
            <>
                <div className="container-fluid">
                    <div className="row">
                        <img src={packageTopBannerImageURL} className="img-fluid px-0" alt="..." />
                    </div>
                </div>
                <div className="container-fluid py-3">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="container-fluid py-1">

                                        <h5 className="tomato">Breathtaking Bhutan 5N/6D Tour Package With Flight</h5>
                                        <hr />

                                        <div className="col-12">
                                            <strong className="tomato">5N/6D</strong>&nbsp;
                                            <i className="fa fa-plane-departure"></i>&nbsp;&nbsp;
                                            <i className="fa fa-arrow-right"></i>&nbsp;Thimpu(2N)&nbsp;
                                            <i className="fa fa-arrow-right"></i>&nbsp;Punakha(1N)&nbsp;
                                            <i className="fa fa-arrow-right"></i>&nbsp;Paro(2N)&nbsp;
                                            <i className="fa fa-arrow-right"></i>&nbsp;&nbsp;
                                            <i className="fa fa-plane-departure fa-flip-horizontal"></i>
                                            <br />
                                        </div>
                                        <hr />
                                    </div>

                                    <div className="container-fluid navtabCustom">
                                        <nav>
                                            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                                <a onClick={() => this.handleCustomTabClick('tab1')} className={customActiveTab === 'tab1' ? 'nav-item nav-link active' : 'nav-item nav-link'} role="tab" aria-controls="nav-highlights" aria-selected="true">Highlights</a>
                                                <a onClick={() => this.handleCustomTabClick('tab2')} className={customActiveTab === 'tab2' ? 'nav-item nav-link active' : 'nav-item nav-link'} role="tab" aria-controls="nav-flights" aria-selected="false">Flights</a>
                                                <a onClick={() => this.handleCustomTabClick('tab3')} className={customActiveTab === 'tab3' ? 'nav-item nav-link active' : 'nav-item nav-link'} role="tab" aria-controls="nav-hotel" aria-selected="false">Hotel</a>
                                                <a onClick={() => this.handleCustomTabClick('tab4')} className={customActiveTab === 'tab4' ? 'nav-item nav-link active' : 'nav-item nav-link'} role="tab" aria-controls="nav-sightseeing" aria-selected="false">Sightseeings</a>
                                                <a onClick={() => this.handleCustomTabClick('tab5')} className={customActiveTab === 'tab5' ? 'nav-item nav-link active' : 'nav-item nav-link'} role="tab" aria-controls="nav-meals" aria-selected="false">Meals</a>
                                            </div>
                                        </nav>
                                        <div className="tab-content" id="nav-tabContent">
                                            <div className={customActiveTab === 'tab1' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-highlights-tab">
                                                It can be better to book your Kolkata to Bagdogra flight. As Druk Air & Bhutan Airlines operates
                                                flight on these five days only. Asia, IndiGo, Spice Jet, Go Air and Air India operate
                                                nonstop
                                                flight to Bagdogra Airport and the time taken by the flight ranges around 1 hour 10
                                                minutes.
                                            </div>
                                            <div className={customActiveTab === 'tab2' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-flights-tab">
                                                <span>Bhutan is the only country which measures its progress through the Gross National Happiness Index and hence, it is known as the ‘Happiest Country in the World’. Tourists from all the corners of the world visit Bhutan to explore its ancient monasteries and indulge in its adventurous trekking expeditions. Even though Bhutan tourism is flourishing by the day, the country adopts a holistic approach to protecting and preserving its natural resources, environment, culture and heritage. Want to visit this happiest country in the world? Wondering how to reach Bhutan? Here are the answers to all your questions.<br /><br />

                                                    Paro is the only international airport in Bhutan and has connecting flights from Delhi, Bagdogra, Guwahati, and Mumbai. You can take direct flights from Kolkata to Paro.  Hence, there is more frequency of flights from Kolkata to reach Bhutan. The number of flights from Delhi and Mumbai are less as compared to Kolkata and Bagdogra. Druk Air is the national airline of Bhutan and flights can be booked only through the official websites of this airline.</span>
                                                <br />
                                                <br />
                                                <div className="overflow-table">
                                                    <span>Bhutan Flight From - <b>Kolkata</b> <i>(CCU - PBH)</i></span>
                                                    <table className="table table-bordered">
                                                        <tbody>
                                                            <tr className="table-danger">
                                                                <th>Airlines</th>
                                                                <th>Flight Number</th>
                                                                <th>Operating Days</th>
                                                                <th>Time</th>
                                                            </tr>
                                                            <tr>
                                                                <td>Druk Air</td>
                                                                <td>KB 211, KB 210</td>
                                                                <td>Sat, Mon, Wed, Fri</td>
                                                                <td>9:30 - 11:30</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Bhutan Airlines</td>
                                                                <td>B3 701, B3 700</td>
                                                                <td>Mon, Tue, Wed, Fri, Sat, Sun</td>
                                                                <td>8:15 - 9:55</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <span>Bhutan Flight From - <b>Delhi</b> <i>(DEL - PBH)</i></span>
                                                    <br />
                                                    <br />
                                                    <table className="table table-bordered">
                                                        <tbody>
                                                            <tr className="table-danger">
                                                                <th>Airlines</th>
                                                                <th>Flight Number</th>
                                                                <th>Operating Days</th>
                                                                <th>Time</th>
                                                            </tr>
                                                            <tr>
                                                                <td>Druk Air</td>
                                                                <td>KB 201, KB 200</td>
                                                                <td>Mon, Tue, Wed, Fri, Sat, Sun</td>
                                                                <td>12:30 - 15:20</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Bhutan Airlines</td>
                                                                <td>B3 774, B3 773</td>
                                                                <td>Mon, Wed, Sat, Sun</td>
                                                                <td>10:55 - 14:35</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className={customActiveTab === 'tab3' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-hotel-tab">
                                                <div className="overflow-table">
                                                    <table className="table table-bordered">
                                                        <tbody>
                                                            <tr className="table-primary text-center">
                                                                {/* <!-- Add the "text-center" className to center content --> */}
                                                                <th colSpan="6">HOTEL CATEGORY - 3 STAR (TCB APPROVED)</th>
                                                            </tr>
                                                            <tr className="table-danger">
                                                                <th className="text-center">Destination</th>
                                                                <th className="text-center">Hotel Names</th>
                                                                <th className="text-center">Hotel Type</th>
                                                                <th className="text-center">No of Nights</th>
                                                                <th className="text-center">Meal Plan</th>
                                                            </tr>
                                                            <tr>
                                                                <td>Thimpu</td>
                                                                <td>Hotel Amodhara/Hotel Ugyan / Hotel Nordenma / Hotel Riverside Bhutan
                                                                </td>
                                                                <td>3 Star</td>
                                                                <td>2N</td>
                                                                <td>Room with Breakfast & Dinner</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Punakha</td>
                                                                <td>Hotel Khuru Khuru / Kingaling Resort & Spa / White Dragon / Punakha
                                                                    Residency</td>
                                                                <td>3 Star</td>
                                                                <td>1N</td>
                                                                <td>Room with Breakfast & Dinner</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Paro</td>
                                                                <td>Namsey Chholing Resort / Tsheringma Resort / Hotel Gawaling / Hotel
                                                                    Centre Point</td>
                                                                <td>3 Star</td>
                                                                <td>2N</td>
                                                                <td>Room with Breakfast & Dinner</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>

                                                    <table className="table table-bordered">
                                                        <tbody>
                                                            <tr className="table-primary text-center">
                                                                {/* <!-- Add the "text-center" className to center content --> */}
                                                                <th colSpan="6">PACKAGE PRICE IN 3 STAR CATEGORY HOTEL</th>
                                                            </tr>
                                                            <tr className="table-danger">
                                                                <th className="text-center">No of Pax</th>
                                                                <th className="text-center">Package Price</th>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-center">02 Adults</td>
                                                                <td className="text-center">42,800/- PP</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-center">
                                                                    04 Adults
                                                                </td>
                                                                <td className="text-center">
                                                                    32,900/- PP
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-center">
                                                                    06 Adults
                                                                </td>
                                                                <td className="text-center">
                                                                    29,500/- PP
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <br />

                                                </div>
                                            </div>
                                            <div className={customActiveTab === 'tab4' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-sightseeing-tab">
                                                <div className="container-fluid px-0 py-1">
                                                    <h5 className="tomato">Thimpu</h5>
                                                    <ul className="place">
                                                        <p> The National Memorial Chorten, Sakyamuni Buddha, Buddha View Point,
                                                            Changangkha Lhakhang, Takin Zoo, The National Library, Zorig Chusum, The
                                                            Royal Textile Museum, Trashi Chhoe Dzong.</p>
                                                    </ul>
                                                    <h5 className="tomato">Punakha</h5>
                                                    <ul className="place">
                                                        <p> Dochula view point stop, Dochu La pass, Punakha Valley, Punakha Dzong,
                                                            Wangdue. </p>
                                                    </ul>
                                                    <h5 className="tomato">Paro</h5>
                                                    <ul className="place">
                                                        <p>Simtokha Dzong,National Museum of Bhutan,Drukgyal Dzong,Ta Dzong,Rinpung
                                                            Dzong,Nya Mey Zam Bridge,Paro Airport View Point,Kyichu Lhakhang,Taktsang
                                                            Monastery.</p>
                                                    </ul>

                                                </div>
                                            </div>
                                            <div className={customActiveTab === 'tab5' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-meals-tab">
                                                <div className="overflow-table">
                                                    <h5 className="tomato">5 times Meals and 5 times Breakfast has been Provided</h5>
                                                    <table className="table table-bordered">
                                                        <tbody>
                                                            <tr className="table-danger">
                                                                <th colSpan="6" className="text-center">Category</th>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-center">JAIN</td>
                                                                <td className="text-center">PURE-VEG</td>
                                                                <td className="text-center">VEGAN</td>
                                                                <td className="text-center">PURE-NON-VEG</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <!--By Itinerary--> */}
                            <div className="container-fluid navtabCustom">

                                <h5 className="tomato">Day wise Itinerary</h5>
                                <hr />

                                <nav>
                                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                        <a onClick={() => this.handleDayWiseTabClick('day1')} className={daywiseActiveTab === 'day1' ? 'nav-item nav-link active' : 'nav-item nav-link'} role="tab" aria-controls="nav-day-one" aria-selected="true">DAY-1</a>
                                        <a onClick={() => this.handleDayWiseTabClick('day2')} className={daywiseActiveTab === 'day2' ? 'nav-item nav-link active' : 'nav-item nav-link'} role="tab" aria-controls="nav-day-two" aria-selected="false">DAY-2</a>
                                        <a onClick={() => this.handleDayWiseTabClick('day3')} className={daywiseActiveTab === 'day3' ? 'nav-item nav-link active' : 'nav-item nav-link'} role="tab" aria-controls="nav-day-three" aria-selected="false">DAY-3</a>
                                        <a onClick={() => this.handleDayWiseTabClick('day4')} className={daywiseActiveTab === 'day4' ? 'nav-item nav-link active' : 'nav-item nav-link'} role="tab" aria-controls="nav-day-four" aria-selected="true">DAY-4</a>
                                        <a onClick={() => this.handleDayWiseTabClick('day5')} className={daywiseActiveTab === 'day5' ? 'nav-item nav-link active' : 'nav-item nav-link'} role="tab" aria-controls="nav-day-five" aria-selected="false">DAY-5</a>
                                        <a onClick={() => this.handleDayWiseTabClick('day6')} className={daywiseActiveTab === 'day6' ? 'nav-item nav-link active' : 'nav-item nav-link'} role="tab" aria-controls="nav-day-six" aria-selected="false">DAY-6</a>
                                    </div>
                                </nav>
                                <div className="tab-content">
                                    <div className={daywiseActiveTab === 'day1' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-one-tab">
                                        <img src={navDay1ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 className="tomato"> <b>Day1 – Welcome to Bhutan and Transfer to Thimpu / 2 Hrs </b></h5>
                                        <p><b>Meet & greet on arrival</b>  and as the aircraft enters the Paro valley, look down and you
                                            will
                                            see the Paro Dzong on the hillside overlooking Paro Chu (river) with Ta Dzong, You will be
                                            received by our representative at the airport and On the way to Paro, halt at Chojum to take
                                            photographs of confluence of two rivers of Bhutan, Thumpu Chu and Paro Chu. Reach Thimpu and
                                            entire day is free to roam around Thimpu.</p>
                                    </div>
                                    <div className={daywiseActiveTab === 'day2' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-two-tab">
                                        <img src={navDay2ImageURL} className="img-fluid" alt="..." />
                                        <p></p>
                                        <h5 className="tomato"><b>Day2 - Thimpu Sightseeing - After breakfast, start for local Thimpu
                                            sightseeing</b></h5>
                                        <br />
                                        <p>
                                            <b>The National Memorial Chorten </b>or Thimpu Chorten is a site for evening prayer. The
                                            chorten or
                                            stupa was built in the memory of the third king, continuously circumambulated by people,
                                            murmuring mantras and spinning their prayer wheels. Construction of this landmark was the
                                            idea
                                            of Bhutan's third king, His Majesty Jigme Dorji Wangchuk ("the father of modern Bhutan") who
                                            has wished to erect monument to world peace and prosperity. Completed in 1974 after his
                                            untimely death, it serves both as a memorial to the Late King and as a monument to
                                            peace.<br /><br />

                                            <b>Sakyamuni Buddha - </b>On the top of Kuensel Phodrang hill sits the statue of Sakyamuni
                                            Buddha.
                                            The 51.5-meter-long bronze statue would surely attract you and the uninterrupted view of the
                                            valley would leave you spellbound. The site is also known as Buddha View Point.<br /><br />
                                            <b>Changangkha Lhakhang - </b>At Thimphu you will go across several monasteries and
                                            temples. Changangkha Lhakhang is among the most important religious structures in Thimphu.
                                            The
                                            structure dates back to the 15th century and is one of the oldest in the region. The
                                            structure
                                            is dedicated to Avalokiteshvara, the sign of compassion. You will also enjoy the grand view
                                            of
                                            Thimphu valley from here.<br /><br />
                                            <b>Takin Zoo –</b> You will find the National animal of Bhutan “Takin” in this open zoo.
                                            Takin
                                            is one
                                            of the most endangered species of the World and it is available only in Bhutan.<br /><br />

                                            <b>The National Library -</b>has got a fabulous collection of Buddhist manuscripts which are
                                            priceless. It also houses the World’s largest published book which is about 130 pounds and
                                            5X7
                                            feet.<br /><br />
                                            <b>Zorig Chusum  - </b>Institute for Zorig Chusum (commonly known as the Painting School)
                                            where
                                            students undergo a 6-year training course in Bhutan's 13 traditional arts and
                                            crafts.<br /><br />
                                            <b>The Royal Textile Museum  -</b>Thimphu also introduces visitors with the culture and
                                            heritage of
                                            Bhutan. The Royal Textile Museum at Norzin Lam is a site where you will get introduced to
                                            Bhutanese textiles, some of which date back to the 1600s.<br /><br />
                                            <b>Trashi Chhoe Dzong</b> or the Fortress of the Glorious Region is another ‘must visit’
                                            site.
                                            Situated on the west bank of Wang Chhu river, Trashi Chhoe Dzong is the formal coronation
                                            site
                                            of the fifth King. The Dzong also hosts the annual Tsechu festival. Built in 1641 by the
                                            political and religious unifier of Bhutan, Shabdrung Ngawang Namgyal, it was reconstructed
                                            in
                                            1960s in traditional Bhutanese manner, without nails or architectural plans.
                                        </p>
                                    </div>
                                    <div className={daywiseActiveTab === 'day3' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-three-tab">
                                        <img src={navDay3ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 className="tomato"> <b>Day 3 –Thimpu – Punakha /2 Hrs </b></h5>
                                        <p>After breakfast, start for Punakha . One the way, visit<br />
                                            <b><u>Dochula view point stop: </u></b>&nbsp;The first stop after leaving Thimphu on the
                                            journey is Dochu La pass around 3100 m high and way above the clouds.  Only an hour’s drive
                                            from Thimphu, it offers visitors their first glimpse of the Eastern Himalayan range. From
                                            the
                                            pass the road curls its way down into the relative lowlands of
                                            the<b> Punakha Valley.</b> Before
                                            Thimphu was made the permanent capital of Bhutan.<br /><br />
                                            <b>In Punakha , you will also visit</b><br /><br />
                                            <b><u> Punakha Dzong: </u></b>&nbsp;Beautiful <b>Punakha Dzong</b>(known as the  <b>“the
                                                palace of great happiness”</b>),located at the <b>confluence of the Pho Chhu (father)
                                                    and Mo
                                                    Chhu (mother) rivers</b> in the Punakha–Wangdue valley. the city’s dzong is one of the
                                            most
                                            picturesque of all Bhutan’s ancient fortresses The dzong is joined to the mainland by an
                                            arched wooden bridge, and contains many precious relics from the days when successive kings
                                            reined the kingdom from this valley.<br /><br />
                                            <b><u>Wangdue:</u></b> &nbsp;After crossing the Fo-Chu-Mo-Chu river , driver along the
                                            Wangdue
                                            phradong and visit newly developed small beautiful town , Bazo Town in Wangdue . After Bajo
                                            Town , drive to Thimpu via Lobesa . <br /><br />
                                        </p>
                                        <p><b>Overnight Stay at Punakha hotel.</b></p>
                                    </div>
                                    <div className={daywiseActiveTab === 'day4' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-four-tab">
                                        <img src={navDay4ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 className="tomato"> <b>Day 4 –Punakha – Paro /4 Hrs</b></h5>
                                        <p>In the morning, after breakfast , start for Paro , the picturesque town in Bhutan enroute<br />

                                            <b>Simtokha Dzong </b>is a small dzong, Built in 1629 by Zhabdrung Ngawang Namgyal, who
                                            unified
                                            Bhutan, the dzong is the first of its kind built in Bhutan. An important historical monument
                                            and former Buddhist monastery, today it houses one of the premier Dzongkha language learning
                                            institutes.<br /><br />
                                            <b>National Museum of Bhutan: </b>Situated right above Rinpung Dzong in Paro, the National
                                            Museum of
                                            Bhutan is a house that showcases Bhutan’s culture. Built in 1968, the museum stands inside
                                            the
                                            ancient Ta-dzong building. The museum houses the collection of finest specimens of Bhutanese
                                            culture and art and its highlights are paintings and some bronze statues, which are simply
                                            masterpieces. The items are demonstrated in large galleries and you can get to know a lot
                                            about Bhutan’s art and culture from these objects.<br /><br />
                                            <b>Drukgyal Dzong: Drukgyal Dzong </b>was a fortress and Buddhist monastery, now in ruins,
                                            located in
                                            the upper part of the Paro valley in Paro District of Bhutan. The Dzong was probably built
                                            by
                                            Tenzin Drukdra in 1649 at the behest of Shabdrung Ngawang Namgyal to commemorate victory
                                            over
                                            an invasion from Tibet.<br /><br />
                                            <b>Ta Dzong -</b> Ta Dzong, once a watchtower, built to defend Rinpung Dzong during
                                            inter-valley wars
                                            of the 17th century, Ta Dzong was inaugurated as Bhutan's National Museum in 1968.<br /><br />
                                            <b>Rinpung Dzong -</b>  Built in 1646 by Shabdrung Ngawang Namgyal , the first spiritual and
                                            temporal
                                            ruler of Bhutan, the Dzong houses the monastic body of Paro, the office of the Dzongda
                                            (district administrative head) and Thrimpon (judge) of Paro district. The approach to the
                                            Dzong is through a traditional covered bridge called Nemi Zam.<br /><br />
                                            <b>Nya Mey Zam Bridge - </b> "The Bridge with No Fish " is the bridge that connects Paro
                                            Town
                                            to
                                            Dzong ..It is one of finest specimens in Bhutan and local believes if one sees a fish in the
                                            river , it is ill omen..The earlier bridge which could be folded during war was washed away
                                            by
                                            flood in 1969.<br /><br />
                                            <b>Paro Airport View Point –</b> This is finest valley view point to look after Paro Valley
                                            and Paro
                                            Airport . This view point overlooking the Paro river along with the all the Dzongs and
                                            Monastery.<br /><br />
                                            <b>Overnight in Paro.</b>
                                        </p>
                                    </div>
                                    <div className={daywiseActiveTab === 'day5' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-five-tab">
                                        <img src={navDay5ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 className="tomato"> <b>Day 5 – Paro Sightseeing - Taktsang Monastery  </b></h5>
                                        <p>
                                            <b>Kyichu Lhakhang - Kyichu Lhakhang</b>, (also known as Kyerchu Temple or Lho Kyerchu) is
                                            an
                                            important Himalayan Buddhist temple situated in Lamgong Gewog of Paro District in Bhutan.
                                            The
                                            Jowo Temple of Kyichu is one of the oldest temples in Bhutan, originally built in the 7th
                                            century by the Tibetan Emperor Songsten Gampo.<br /><br />
                                            <b>Taktsang Monastery:</b> If you can brave the risky cliffs on a high mountain, Paro will
                                            astonish
                                            you in the form of Taktsang Monastery. After covering 1200 metres on a fierce cliff you will
                                            reach this sacred site, also known as the Tiger’s Nest. Take a deep breath after reaching
                                            the
                                            spot and then comfort your eyes and soul with the stunning view of the scenic surroundings
                                            and
                                            the valley lying underneath. The Taktsang Monastery was established by Sain Guru Rinpoche
                                            who
                                            came to Bhutan in 747 CE. Construction of the complex was completed in 1694. Discovering the
                                            Tiger’s Nest after 3 hours of trekking is real adventure but you may also opt for a horse
                                            ride
                                            from the parking lot.<br /><br />
                                            <b>Overnight in Paro</b>
                                        </p>
                                    </div>
                                    <div className={daywiseActiveTab === 'day6' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-six-tab">
                                        <img src={navDay6ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 className="tomato"> <b>Day 6 – Departure to Paro</b></h5>
                                        <p>
                                            After early breakfast, start for Paro Airport Foment your Bhutan Holiday memories With
                                            Adorable Vacation LLP</p>
                                    </div>
                                </div>
                            </div>

                            {/* <!--insert table inclusion exclusion--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        {
                                            <InclusionExclusion />
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* <!--end table inclusion exclusion--> */}


                            {/* <!--start of package details--> */}
                            <div className="container-fluid py-5">

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>Our Bhutan <span>Tour Packages</span></h2>
                                            <h4>Trending Bhutan Trip from BAGDOGRA Airport</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                                        <div className="carousel-inner">
                                            <div className="carousel-item active">

                                                <Slider {...settings}>
                                                    {hotPackagesView}
                                                </Slider>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!--end of package details--> */}


                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h3><b>Bhutan <span className="tomato">Tour Reviews</span></b></h3>
                                            <h4><i>"Your Experience is our Marketing"</i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-cols-1">
                                    <div id="testimonials">
                                        <div className="testimonial-box-container">

                                            {reviewersDataView}

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-3">

                            <BookingBox />

                        </div>

                    </div>
                </div>
            </>
        );
    }
}

export default FIFO_5N_6D_Package;