import React, { Component } from 'react';
import MainHeader from '../../../components/common/MainHeader';
import FooterDesktop from '../../../components/common/FooterDesktop';
import Sandakphu_land_rover_Package from '../../../components/package/Sandakphu/Sandakphu_land_rover_Package';
import { Helmet } from 'react-helmet';

class Sandakphu_land_rover_Page extends Component {
    constructor() {
        super();
        this.state = {
            ReviewersData: [],
        }
    }

    componentDidMount() {
        window.scroll(0, 0);
        const reviewersDataList = [
            {
                Name: "Mr. Rupam Paul",
                ImageName: "Mr-Rupam-Paul.webp",
                AllternateText: "...",
                Location: "@Mumbai",
                Comment: "ust returned from an amazing 3-night, 4-day adventure with friends to Manebhanjan, Sandakphu, Phalut, and Tumling, all organized by Team Adorable Vacation. Stunning landscapes, thrilling treks, and unforgettable memories made this trip truly special! Highly recommend",
            },
            {
                Name: "Mrs. Subham Raskhit",
                ImageName: "Mrs-Soumi-Raskhit.webp",
                AllternateText: "...",
                Location: "@Pune",
                Comment: "An exhilarating escape into the heart of the Himalayas with Adorable Vacation! Our 3-night, 4-day trek from Manebhanjan to Sandakphu, and Tumling was a breathtaking journey. Stunning vistas, challenging trails, and unforgettable camaraderie made this trip truly unforgettable",
            },
            {
                Name: "Dr. Rukmini Roy",
                ImageName: "Dr-Rukmini-Roy.webp",
                AllternateText: "...",
                Location: "@Kolkata",
                Comment: "Our 4-night, 5-day trek through Manebhanjan, Sandakphu, Phalut, and Tumling with Adorable Vacation was unforgettable! Stunning vistas, challenging trails, and great camaraderie made it an adventure of a lifetime. Highly recommend for offbeat trekking enthusiasts",
            },
            {
                Name: "Ms. Ritu Das",
                ImageName: "Ms-Ritu-Das.webp",
                AllternateText: "...",
                Location: "@Kolkata",
                Comment: "My 4-night, 5-day trek to Sandakphu with Adorable Vacation was nothing short of breathtaking. From the stunning sunrise views atop Sandakphu to the cozy nights in Tumling, every moment was filled with adventure and camaraderie. Highly recommend for nature lovers and trekking enthusiasts",
            },
        ]

        this.setState({
            ReviewersData: reviewersDataList,
        })
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>Book Sandakphu Land Rover Package from Manebhanjan – Best Deal Guaranteed</title>
                    <meta name="description" content="Explore the breathtaking beauty of Sandakphu Phalut with our exclusive trekking package from NJP. Enjoy a seamless journey with guided treks and top-notch accommodations. Book Now: +91-91636-76037" />
                </Helmet>

                <MainHeader />

                <Sandakphu_land_rover_Package
                    ReviewersData={this.state.ReviewersData}
                />

                <FooterDesktop />
            </>
        );
    }
}

export default Sandakphu_land_rover_Page;