import React, { Component } from 'react';
import MainHeader from '../../../components/common/MainHeader';
import FooterDesktop from '../../../components/common/FooterDesktop_kashmir';
import Kashmir_7N_8D_Package_Tour_From_Pune from '../../../components/package/Kashmir/Kashmir_7N_8D_Package_Tour_From_Pune';
import { Helmet } from "react-helmet";

class Kashmir_7N_8D_Tour_From_Pune_Page extends Component {
    constructor() {
        super();
        this.state = {
            TrendingPackagesData: [],
            ReviewersData: [],
        }
    }

    componentDidMount() {
        window.scroll(0, 0);
        const TrendingPackageDataList = [
            {
                Title: "Jannat-E-Kashmir",
                ImageName: "Kashmir-Package-Cost.webp",
                AllternateText: "...",
                Description: "3N Srinagar   |   Pahalgam |   Gulmarg |   Private Transfer   |   3 Star Hotel  |   Stay in House Boat  |   Shikara Ride",
                PricePerNight: "11,699",
                NoOfTours: 47,
                NoOfDeparture: 40,
                Include: "EX-SXR",
                MutedText: "*Per head / Min 6 Pax",
                Url: 'tel://9147061467'
            },
            {
                Title: "Peaceful Kashmir",
                ImageName: "Kashmir-Family-Tour-Plans.webp",
                AllternateText: "...",
                Description: "3N Srinagar | Sonmarg | Gulmarg | 1N Pahalgam | Private Transfer | 3 Star Hotel | Stay in House Boat | Shikara Ride",
                PricePerNight: "15,499",
                NoOfTours: 43,
                NoOfDeparture: 38,
                Include: "EX-SXR",
                MutedText: "*Per head / Min 6 Pax",
                Url: 'tel://9147061467'
            },
            {
                Title: "Charming Kashmir",
                ImageName: "Kashmir-Tour-Package-kolkata.webp",
                AllternateText: "...",
                Description: "4N Srinagar | Sonmarg | Gulmarg | 1N Pahalgam | Private Transfer | 3 Star Hotel | Stay in House Boat | Shikara Ride",
                PricePerNight: "18,499",
                NoOfTours: 44,
                NoOfDeparture: 37,
                Include: "EX-SXR",
                MutedText: "*Per head / Min 6 Pax",
                Url: 'tel://9147061467'
            },
            {
                Title: "Sparkling Kashmir",
                ImageName: "Kashmir-Honeymoon-Package.webp",
                AllternateText: "...",
                Description: "4N Srinagar | Sonmarg | 1N Gulmarg | 2N Pahalgam | Private Transfer | 3 Star Hotel | Stay in House Boat | Shikara Ride",
                PricePerNight: "26,499",
                NoOfTours: 46,
                NoOfDeparture: 39,
                Include: "EX-SXR",
                MutedText: "*Per head / Min 6 Pax",
                Url: 'tel://9147061467'
            }
        ];

        const reviewersDataList = [
            {
                Name: "Priya Sharma",
                ImageName: "Priya-Sharma.webp",
                AllternateText: "...",
                Location: "@Mumbai",
                Comment: "As someone who always dreamt of visiting the mesmerizing valleys of Kashmir, I couldn't have asked for a better travel partner than Adorable Vacation. From the moment we arrived in Srinagar, every detail was meticulously planned. The Shikara ride on Dal Lake was surreal, and the houseboat stay felt like living in a fairytale. The  were driver knowledgeable and friendly, ensuring we were safe and comfortable throughout. Adorable Vacation made my Kashmir trip a memory I’ll cherish forever.",
            },
            {
                Name: "Rahul Mehra",
                ImageName: "Rahul-Mehra.webp",
                AllternateText: "...",
                Location: "@Hyderabad",
                Comment: "For an adrenaline junkie like me, the offbeat trek to the hidden meadows of Sonmarg was the highlight of the trip. Adorable Vacation's team knew the best trails that offered breathtaking views while ensuring we stayed off the usual tourist paths. The thrill of trekking through untouched snow-covered landscapes and the warmth of the locals we met along the way was unparalleled. Kudos to the team for creating a perfect blend of adventure and relaxation.",
            },
            {
                Name: "Amit Verma",
                ImageName: "Amit-Verma.webp",
                AllternateText: "...",
                Location: "@Delhi",
                Comment: "Traveling with kids can be challenging, but Adorable Vacation made it a breeze for our family. The itinerary was thoughtfully curated to include activities that our little ones enjoyed, from horse riding in Pahalgam to exploring the beautiful gardens in Srinagar. The accommodation was comfortable, and the meals were delicious, catering to all our preferences. It was a joy to see our kids experience the magic of Kashmir, and we can't wait to plan our next vacation with Adorable Vacation.",
            },
            {
                Name: "Anjali Gupta",
                ImageName: "Anjali-Gupta.webp",
                AllternateText: "...",
                Location: "@Bangalore",
                Comment: "Our honeymoon in Kashmir was nothing short of a dream come true, thanks to Adorable Vacation. The team's attention to detail and personal touches made all the difference. From a candlelit dinner by the lake to a private tour of the Mughal gardens, every moment was magical. The stay in Gulmarg, with views of snow-capped peaks, was simply breathtaking. Adorable Vacation made our romantic getaway truly unforgettable.",
            },
        ]

        this.setState({
            TrendingPackagesData: TrendingPackageDataList,
            ReviewersData: reviewersDataList
        })
    }

    render() {
        const organizationSchema = {
            "@context": "https://schema.org",
            "@type": "TravelAgency",
            "url": "https://adorablevacation.com",
            "name": "Adorable Vacation LLP",
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91 9883359713",
                "contactType": "Customer Service",
                "areaServed": "Country",
                "availableLanguage": "Hindi, Tamil, English, Bengali, Malayam, Telegu"
            },
            "address": {
                "@type": "PostalAddress",
                "streetAddress": "ground floor, 5, Dwarik Ganguly St",
                "addressLocality": "Kolkata",
                "addressRegion": "West Bengal",
                "postalCode": "700026",
                "addressCountry": "India"
            },
            "openingHours": "Mo-Sat 10:00-19:00",
            "description": "We Offer the Best Travel Experinces and Luxery Tour Organizer. For Booking ☎ CALL Mr. SOUMALYA +91-98833-59713",
            "sameAs": [
                "https://www.facebook.com/adorablevacationllp/",
            ]
        };
        return (
            <>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>BEST PRICE FOR A KASHMIR TOUR PACKAGE FROM PUNE</title>
                    <meta name="description" content="We Offer the Best Kashmir Package Tour From Pune. For Booking ☎ CALL +91-91470-61467." />
                    <link rel="canonical" href="https://adorablevacation.com/kashmir-package-tour-from-pune" />
                    <script type="application/ld+json">
                        {JSON.stringify(organizationSchema)}
                    </script>
                    <meta property="og:title" content="KASHMIR TOUR PACKAGE FROM PUNE" />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content="https://adorablevacation.com/kashmir-package-tour-from-pune" />
                    <meta property="og:description" content="Book the Best Kashmir Package Tour From Pune bast deal from Adorable Vacation LLP. Call Now: +91-98833-59713" />
                </Helmet>
                <MainHeader />
                <Kashmir_7N_8D_Package_Tour_From_Pune
                    TrendingPackagesData={this.state.TrendingPackagesData}
                    ReviewersData={this.state.ReviewersData}
                />
                <FooterDesktop />
            </>
        );
    }
}

export default Kashmir_7N_8D_Tour_From_Pune_Page;