import React, { Component } from "react";
import BookingBox from "../BookingBox";
import Slider from "react-slick";
import axios from "axios";
import cogoToast from "cogo-toast";
import { Digital } from "react-activity";
import "react-activity/dist/library.css";
import ApiURL from "../../../api/ApiURL";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import "./InclusionExclusion";
import InclusionExclusion from "../Sandakphu/InclusionExclusion_sandakphu_trekking";

class Sandakphu_trekking_Package extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customActiveTab: "tab1",
      currentPage: 1,
    };
  }

  handleCustomTabClick = (tab) => {
    this.setState({
      customActiveTab: tab,
    });
  };
  handleClick = () => {
    const currentUrl = window.location.href;
    const pageTitle = document.title;
    const message = `Hi, Please send about the Tour Package details "${pageTitle}"`;

    const whatsappLink = `https://api.whatsapp.com/send?phone=+919883359713&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
    window.open(whatsappLink, '_blank');
  };
 

  render() {
    const rows = [
      { Train_No: '12345', Train_Name: 'Saraighat Exp', Boarding_From: 'Howrah', Boarding_time: '16:05', Arrival: 'New Alipurduar', Day: 'M,T,W,T,F,S,S' },
      { Train_No: '12377', Train_Name: 'Padatik Exp', Boarding_From: 'Sealdah', Boarding_time: '23:20', Arrival: 'New Alipurduar', Day: 'M,T,W,T,F,S,S' },
      { Train_No: '13147', Train_Name: 'Uttar Banga Exp', Boarding_From: 'Sealdah', Boarding_time: '19:40', Arrival: 'New Cooch Behar', Day: 'M,T,W,T,F,S,S' },
      { Train_No: '13175', Train_Name: 'Kanchanjunga', Boarding_From: 'Sealdah', Boarding_time: '06:50', Arrival: 'NJP', Day: 'M,W,SAT' },
      { Train_No: '15959', Train_Name: 'Kamrup Exp', Boarding_From: 'Howrah', Boarding_time: '18:30', Arrival: 'New Alipurduar', Day: 'T,W,T,S,S' },
      { Train_No: '13141', Train_Name: 'Teesta Torsha', Boarding_From: 'Sealdah', Boarding_time: '15:00', Arrival: 'New Alipurduar', Day: 'M,T,W,T,F,S,S' },
      { Train_No: '13149', Train_Name: 'Kanchan Kanya', Boarding_From: 'Sealdah', Boarding_time: '20:35', Arrival: 'New Alipurduar', Day: 'M,T,W,T,F,S,S' },
    ];

    const rowsPerPage = 4;
    const totalPages = Math.ceil(rows.length / rowsPerPage);
    const currentRows = rows.slice((this.state.currentPage - 1) * rowsPerPage, this.state.currentPage * rowsPerPage);
    const { customActiveTab } = this.state;
    const packageTopBannerImageURL = ApiURL.Sandakphu_trekking_PackageImageURL + "/Sandakphu-Phalut-Trekking-Package.webp";
    const packageTopBanner2ImageURL = ApiURL.Sandakphu_trekking_PackageImageURL + "/Sandakphu-Trekking-Package.webp";

    const navDay1ImageURL = ApiURL.Sandakphu_trekking_PackageImageURL + "/Sandakphu-Trek-Package.webp";
    const navDay2ImageURL = ApiURL.Sandakphu_trekking_PackageImageURL + "/Sandakphu-Trekking-Package-Tour.webp";
    const navDay3ImageURL = ApiURL.Sandakphu_trekking_PackageImageURL + "/Sandakphu-Phalut-Trekking-Package-Tour.webp";
    const navDay4ImageURL = ApiURL.Sandakphu_trekking_PackageImageURL + "/Sandakphu-Phalut-Trekking-Packages.webp";
    const navDay5ImageURL = ApiURL.Sandakphu_trekking_PackageImageURL + "/Sandakphu-Phalut-Trek-Package-Tour.webp";
    const navDay6ImageURL = ApiURL.Sandakphu_trekking_PackageImageURL + "/Sandakphu-Phalut-Trek-Package.webp";
    const sightseen1ImageURL = ApiURL.Sandakphu_trekking_PackageImageURL + "/NATURE-OF-SANDAKPHU.webp";
    const sightseen2ImageURL = ApiURL.Sandakphu_trekking_PackageImageURL + "/Sandakphu-Trek.webp";
    const sightseen3ImageURL = ApiURL.Sandakphu_trekking_PackageImageURL + "/SANDAKPHU-HOMESTAY.webp";
    const sightseen4ImageURL = ApiURL.Sandakphu_trekking_PackageImageURL + "/SANDAKPHU-PHALUT-TREKKING-PACKAGES-TOUR.webp";
    
    const settings = {
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    const reviewersDataList = this.props.ReviewersData;
    const reviewersDataView = reviewersDataList.map((dataItem, i) => {
      const reviewerImageURL = ApiURL.Sandakphu_trekking_PackageImageURL + "/" + dataItem.ImageName;
      return (
        <div className="testimonial-box" key={i}>
          <div className="box-top">
            <div className="profile">
              <div className="profile-img">
                <img src={reviewerImageURL} alt="..." />
              </div>
              <div className="name-user">
                <strong>{dataItem.Name}</strong>
                <span>{dataItem.Location}</span>
              </div>
            </div>
            <div className="reviews">
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
            </div>
          </div>
          <div className="client-comment">
            <p>{dataItem.Comment}</p>
          </div>
        </div>
      )
    })


    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <img
              src={packageTopBannerImageURL}
              className="mobile-image2"
              alt="..."
            />
          </div>
        </div>

        <div className="container-fluid custom-container py-3">
          <div className="row">
            <div className="col-md-9">
              <div className="row">
                <div className="col-md-12">
                  <div className="container-fluid custom-container py-1">


                    <h1 className="content-desktop" style={{ fontSize: "2vw" }}><center><strong>Book Sandakphu Trekking Package from NJP – Best Deal</strong></center></h1>

                    <h1 className="content-mobile" style={{ fontSize: "4vw" }}><center><strong>Book Sandakphu Trekking Package from NJP – Best Deal</strong></center>
                      <hr />
                    </h1>
                    <div className="container-fluid">
                      <div className="row">
                        <img src={packageTopBanner2ImageURL} className="mobile-image" alt="..." />
                      </div>
                    </div>
                    <hr />
                  </div>
                  <h2 className="content-desktop p-4" style={{ fontSize: "2.0vw" }}> Want to  <strong>Are You Looking For Sandakphu Trekking Package in 2024?</strong> For the best <a href='tel:9147061467' class="tomato"><strong>Sandakphu Trek</strong>, ☎ CALL +91-91470-61467 </a></h2>
                  <h2 className="content-mobile p-4" style={{ fontSize: "4.5vw" }}>Want to  <strong>Are You Looking For Sandakphu Trekking Package in 2024?</strong> For the best <a href='tel:9147061467' class="tomato"><strong>Sandakphu Trek</strong>, ☎ CALL +91-91470-61467 </a></h2>
                  <br />
                  <div className="card">
                    <div
                      className="card-header custHeaer"
                    >
                      <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong> SANDAKPHU TREKKING PACKAGE</strong> : <b>See The Four Tallest Mountains From One Place</b></h3>
                      <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}> India's Largest <b>North East Tour Operator</b></h4>
                    </div>
                    <div className="card-body">
                      <p>Sandakphu, at 3,636m (11,926 ft) above sea level, is the highest point in West Bengal, near the Nepal border. It offers the best <strong>Sandakphu Trekking Route</strong> in the world, with a panoramic view of the Himalayas and the four tallest mountains: Mt. Everest, Mt. Lhotse, Mt. Makalu, and Mt. Kanchenjunga.</p>
                      <p>No other viewpoint lets you see these four peaks from a moderate trek, making Sandakphu a favorite among trekkers and adventure lovers worldwide.</p>
                      <p>The <strong>Sandakphu Trekking</strong> provides an incredible view of the highest snow-covered peaks, from Nepal to Bhutan and Tibet. The route follows the Singalila National Park and the India-Nepal border, offering thrilling experiences as you cross between countries without boundaries or checkpoints.</p>
                      <p>You'll encounter numerous birds, diverse flora, and the pure wilderness of nature along the trek. Despite its remote feel, the route is dotted with Indian and Nepalese villages, making the <strong>Sandakphu Phalut Trek</strong> safe and accessible year-round.
                      </p>
                      <br />
                      <div className="content-desktop">
                        <center><img src={sightseen1ImageURL} alt="..." /></center>
                      </div>

                      <div className="content-mobile2">
                        <center><img src={sightseen1ImageURL} style={{ width: '320px' }} alt="..." /></center>
                      </div>
                      <br />
                      <div id="no-more-tables">
                        <table class="col-md-12 table-bordered table-striped table-condensed cf">
                          <tbody>
                            <tr>
                              <td><strong>Sandakphu Tour Package </strong> Pickup & Drop Point</td>
                              <td>NJP/Siliguri Station / Bagdogra Airport [IXB]</td>
                            </tr>
                            <tr>
                              <td><strong>Sandakphu Trek Package </strong>Duration</td>
                              <td>05 Nights | 06 Days</td>
                            </tr>
                            <tr>
                              <td>Trekking Region</td>
                              <td>Sandakphu, Singalila National Park, Darjeeling, West Bengal</td>
                            </tr>
                            <tr>
                              <td>Places Covered in <strong>Sandakpuh Trek Package</strong></td>
                              <td>Manebhanjan-1N, Tumling-1N, Kalipokhri-1N, Sandakphu-1N, Srikhola-1N</td>
                            </tr>
                            <tr>
                              <td>
                                Mountain Range
                              </td>
                              <td>Singalila Ridge</td>
                            </tr>
                            <tr>
                              <td>
                                Trekking Grade
                              </td>
                              <td>Moderate</td>
                            </tr>
                            <tr>
                              <td>
                                Max Altitude
                              </td>
                              <td>11,930 Ft.</td>
                            </tr>
                            <tr>
                              <td>
                                Trekking Distance
                              </td>
                              <td>32 kms</td>
                            </tr>
                            <tr>
                              <td>
                                Altitude of Manebhanjan
                              </td>
                              <td>1,928 m</td>
                            </tr>
                            <tr>
                              <td>
                                Altitude of Tumling
                              </td>
                              <td>2,970 m</td>
                            </tr>
                            <tr>
                              <td>
                                Altitude of Gairibas
                              </td>
                              <td>2,621 m</td>
                            </tr>
                            <tr>
                              <td>
                                Altitude of Kalipokhri
                              </td>
                              <td>3,186 m</td>
                            </tr>
                            <tr>
                              <td>
                                Altitude of Sandakphu
                              </td>
                              <td>3,636 m</td>
                            </tr>
                            <tr>
                              <td>
                                Altitude of Phalut
                              </td>
                              <td>3,595 m</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b> STUNNING HIGH PEAKS VISIBLE FROM </b> <strong>SANDAKPHU PHALUT TREKKING PACKAGE TOUR</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}> <b> STUNNING HIGH PEAKS VISIBLE FROM </b> <strong>SANDAKPHU PHALUT TREKKING PACKAGE TOUR</strong></h4>
                </div>
                <div className="card-body">
                  <p>From Sandakphu, you can see four of the world's highest peaks:</p>
                  <ul>
                    <li><b>Mt. Makalu</b> :  One of the eight-thousanders and the fifth highest peak at 27,838 ft. You can see it to the right of Mt. Everest from Sandakphu on a clear day.</li><br />
                    <li><b>Mt. Lhotse</b> : The fourth highest peak at 27,940 ft, seen just left of Mt. Everest from Sandakphu.</li><br />
                    <br />
                      <div className="content-desktop">
                        <center><img src={sightseen2ImageURL} alt="..." /></center>
                      </div>

                      <div className="content-mobile2">
                        <center><img src={sightseen2ImageURL} style={{ width: '320px' }} alt="..." /></center>
                      </div>
                      <br />
                    <li><b>Mt. Everest</b> : The tallest peak in the world at 29,031.7 ft, towering over Mt. Makalu and Mt. Lhotse. Use binoculars from Phalut to see it even closer.</li><br />
                    <li><b>Mt. Kanchenjunga :</b> The third highest peak at 28,169 ft. Known as the Sleeping Buddha, it appears very close on a clear day, especially from Phalut.</li>
                  </ul>
                  <p>This makes Sandakphu Trekking a magical experience.</p>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>HOW FAR AND HOW LONG DOES IT TAKE TO <strong>TREK FROM MANEBHANJAN TO SANDAKPHU?</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>HOW FAR AND HOW LONG DOES IT TAKE TO <strong>TREK FROM MANEBHANJAN TO SANDAKPHU?</strong></h4>
                </div>
                <br />
                <div className="card-body">
                  <div id="no-more-tables">
                    <table class="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead class="cf">
                        <tr className="table-danger">
                          <th>ORIGIN</th>
                          <th>DESTINATION</th>
                          <th>DISTANCE</th>
                          <th>TRAVEL TIME BY TREK</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td data-title="ORIGIN">Sandakphu</td>
                          <td data-title="DESTINATION">Phalut</td>
                          <td data-title="DISTANCE">20.6 km</td>
                          <td data-title="TRAVEL TIME">5/6 hrs</td>
                        </tr>
                        <tr>
                          <td data-title="ORIGIN">Manebhanjan</td>
                          <td data-title="DESTINATION">Sandakphu</td>
                          <td data-title="DISTANCE">32 km</td>
                          <td data-title="TRAVEL TIME">2 to 3 days</td>
                        </tr>
                        <tr>
                          <td data-title="ORIGIN">Kalipokhri</td>
                          <td data-title="DESTINATION">Sandakphu</td>
                          <td data-title="DISTANCE">6 km</td>
                          <td data-title="TRAVEL TIME">3-4 hrs</td>
                        </tr>
                        <tr>
                          <td data-title="ORIGIN">Gairibas</td>
                          <td data-title="DESTINATION">Sandakphu</td>
                          <td data-title="DISTANCE">6 km</td>
                          <td data-title="TRAVEL TIME">3-4 hrs</td>
                        </tr>
                        <tr>
                          <td data-title="ORIGIN">Tumling</td>
                          <td data-title="DESTINATION">Gairibas</td>
                          <td data-title="DISTANCE">9 km</td>
                          <td data-title="TRAVEL TIME">1.30-2.30 hrs</td>
                        </tr>
                        <tr>
                          <td data-title="ORIGIN">Chitre</td>
                          <td data-title="DESTINATION">Tumling</td>
                          <td data-title="DISTANCE">11 km</td>
                          <td data-title="TRAVEL TIME">4-6 hrs</td>
                        </tr>
                        <tr>
                          <td data-title="ORIGIN">Manebhanjan</td>
                          <td data-title="DESTINATION">Chitre</td>
                          <td data-title="DISTANCE">3 km</td>
                          <td data-title="TRAVEL TIME">1-5 hrs</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>CHECK THE TEMPERATURES FOR EACH MONTH BEFORE TREKKING TO SANDAKPHU</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>CHECK THE TEMPERATURES FOR EACH MONTH BEFORE TREKKING TO SANDAKPHU</strong></h4>
                </div>
                <br />

                <div className="card-body">
                  <div id="no-more-tables">
                    <table class="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead class="cf">
                        <tr className="table-danger">
                          <th>MONTH</th>
                          <th>MAX TEMP</th>
                          <th>MIN TEMP</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td data-title="MONTH">January</td>
                          <td data-title="MAX TEMP">7°c</td>
                          <td data-title="MIN TEMP">-15°c</td>
                        </tr>
                        <tr>
                          <td data-title="MONTH">February</td>
                          <td data-title="MAX TEMP">10°c</td>
                          <td data-title="MIN TEMP">-10°c</td>
                        </tr>
                        <tr>
                          <td data-title="MONTH">March</td>
                          <td data-title="MAX TEMP">15°c</td>
                          <td data-title="MIN TEMP">-8°c</td>
                        </tr>
                        <tr>
                          <td data-title="MONTH">April</td>
                          <td data-title="MAX TEMP">18°c</td>
                          <td data-title="MIN TEMP">-5°c</td>
                        </tr>
                        <tr>
                          <td data-title="MONTH">May</td>
                          <td data-title="MAX TEMP">20°c</td>
                          <td data-title="MIN TEMP">-3°c</td>
                        </tr>
                        <tr>
                          <td data-title="MONTH">June</td>
                          <td data-title="MAX TEMP">22°c</td>
                          <td data-title="MIN TEMP">2°c</td>
                        </tr>
                        <tr>
                          <td data-title="MONTH">July</td>
                          <td data-title="MAX TEMP">22°c</td>
                          <td data-title="MIN TEMP">4°c</td>
                        </tr>
                        <tr>
                          <td data-title="MONTH">August</td>
                          <td data-title="MAX TEMP">22°c</td>
                          <td data-title="MIN TEMP">5°c</td>
                        </tr>
                        <tr>
                          <td data-title="MONTH">September</td>
                          <td data-title="MAX TEMP">20°c</td>
                          <td data-title="MIN TEMP">0°c</td>
                        </tr>
                        <tr>
                          <td data-title="MONTH">October</td>
                          <td data-title="MAX TEMP">15°c</td>
                          <td data-title="MIN TEMP">-5°c</td>
                        </tr>
                        <tr>
                          <td data-title="MONTH">November</td>
                          <td data-title="MAX TEMP">12°c</td>
                          <td data-title="MIN TEMP">-10°c</td>
                        </tr>
                        <tr>
                          <td data-title="MONTH">December</td>
                          <td data-title="MAX TEMP">10°c</td>
                          <td data-title="MIN TEMP">-15°c</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br /> <br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>YOU CAN BOOK A TRAIN TICKET TO REACH NJP/SILIGURI FOR<strong> SANDAKPHU PHALUT TREKKING PACKAGE TOUR</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>YOU CAN BOOK A TRAIN TICKET TO REACH NJP/SILIGURI FOR<strong> SANDAKPHU PHALUT TREKKING PACKAGE TOUR</strong></h4>
                </div>
                <br />

                <div className="card-body">
                  <div id="no-more-tables">
                    <table class="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead class="cf">
                        <tr className="table-danger">
                          <th>TRAIN NO</th>
                          <th>TRAIN TIME</th>
                          <th>DEP. STN</th>
                          <th>DEP. TIME</th>
                          <th>ARR. STN</th>
                          <th>ARR. TIME</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td data-title="TRAIN NO">12343</td>
                          <td data-title="TRAIN TIME">Darjeeling mail</td>
                          <td data-title="DEP. STN">Sealdah (SDAH)</td>
                          <td data-title="DEP. TIME">22:05</td>
                          <td data-title="ARR. STN">New Jalpaiguri Jn (NJP)</td>
                          <td data-title="ARR. TIME">08:15</td>
                        </tr>
                        <tr>
                          <td data-title="TRAIN NO">12377</td>
                          <td data-title="TRAIN TIME">Padatik Express</td>
                          <td data-title="DEP. STN">Sealdah (SDAH)</td>
                          <td data-title="DEP. TIME">23:00</td>
                          <td data-title="ARR. STN">New Jalpaiguri Jn (NJP)</td>
                          <td data-title="ARR. TIME">09:15</td>
                        </tr>
                        <tr>
                          <td data-title="TRAIN NO">13149</td>
                          <td data-title="TRAIN TIME">Kanchan Kanya Express</td>
                          <td data-title="DEP. STN">Sealdah (SDAH)</td>
                          <td data-title="DEP. TIME">20:30</td>
                          <td data-title="ARR. STN">New Mal Jn (NMZ)</td>
                          <td data-title="ARR. TIME">09:31</td>
                        </tr>
                        <tr>
                          <td data-title="TRAIN NO">13147</td>
                          <td data-title="TRAIN TIME">Uttar Banga Express</td>
                          <td data-title="DEP. STN">Sealdah (SDAH)</td>
                          <td data-title="DEP. TIME">19:35</td>
                          <td data-title="ARR. STN">Falakata (FLK)</td>
                          <td data-title="ARR. TIME">08:38</td>
                        </tr>
                        <tr>
                          <td data-title="TRAIN NO">13141</td>
                          <td data-title="TRAIN TIME">Teesta Torsa Express</td>
                          <td data-title="DEP. STN">Sealdah (SDAH)</td>
                          <td data-title="DEP. TIME">13:40</td>
                          <td data-title="ARR. STN">New Maynaguri (NMX)</td>
                          <td data-title="ARR. TIME">04:22</td>
                        </tr>
                        <tr>
                          <td data-title="TRAIN NO">12345</td>
                          <td data-title="TRAIN TIME">SaraiGhat Express</td>
                          <td data-title="DEP. STN">Howrah (HWH)</td>
                          <td data-title="DEP. TIME">15:50</td>
                          <td data-title="ARR. STN">New Jalpaiguri (NJP)</td>
                          <td data-title="ARR. TIME">01:40</td>
                        </tr>
                        <tr>
                          <td data-title="TRAIN NO">12517</td>
                          <td data-title="TRAIN TIME">Garib Rath</td>
                          <td data-title="DEP. STN">Kolkata (KOOA)</td>
                          <td data-title="DEP. TIME">21:40</td>
                          <td data-title="ARR. STN">New Jalpaiguri (NJP)</td>
                          <td data-title="ARR. TIME">07:45</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>FOR</b> <strong>SANDAKPHU PHALUT TREK</strong>, <b>YOU CAN BOOK THE HOWRAH JUNCTION TO NEW JALPAIGURI (NJP) VANDE BHARAT 22301 EXPRESS, EXCEPT ON WEDNESDAYS</b></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>FOR</b> <strong>SANDAKPHU PHALUT TREK</strong>, <b>YOU CAN BOOK THE HOWRAH JUNCTION TO NEW JALPAIGURI (NJP) VANDE BHARAT 22301 EXPRESS, EXCEPT ON WEDNESDAYS</b></h4>
                </div>
                <br />

                <div className="card-body">
                  <div id="no-more-tables">
                    <table class="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead class="cf">
                        <tr className="table-danger">
                          <th>STATION</th>
                          <th>ARRIVAL DEPARTURE</th>
                          <th>HALT</th>
                          <th>DISTANCE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td data-title="STATION">Howrah Jn (HWH)</td>
                          <td data-title="ARR/DEP">Starts | 05:55</td>
                          <td data-title="HALT">Howrah (HWH)</td>
                          <td data-title="DISTANCE">0 km</td>
                        </tr>
                        <tr>
                          <td data-title="STATION">Bolpur Shantiniketan (BHP)</td>
                          <td data-title="ARR/DEP">07:43 | 07:45</td>
                          <td data-title="HALT">2 min</td>
                          <td data-title="DISTANCE">145 km</td>
                        </tr>
                        <tr>
                          <td data-title="STATION">Malda Town (MLDT)</td>
                          <td data-title="ARR/DEP">10:32 | 10:35</td>
                          <td data-title="HALT">3 min</td>
                          <td data-title="DISTANCE">329.5 km</td>
                        </tr>
                        <tr>
                          <td data-title="STATION">Barsoi Jn (BOE)</td>
                          <td data-title="ARR/DEP">11:50 | 11:52</td>
                          <td data-title="HALT">2 min</td>
                          <td data-title="DISTANCE">419.7 km</td>
                        </tr>
                        <tr>
                          <td data-title="STATION">New Jalpaiguri Jn (NJP)</td>
                          <td data-title="ARR/DEP">13:25 | ends</td>
                          <td data-title="HALT"></td>
                          <td data-title="DISTANCE">564.6 km</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>THE TRAIN SCHEDULE FOR THE VANDE BHARAT 22302 TRAIN FROM NEW JALPAIGURI (NJP) TO HOWRAH JUNCTION IS EVERY DAY EXCEPT WEDNESDAY</b></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>THE TRAIN SCHEDULE FOR THE VANDE BHARAT 22302 TRAIN FROM NEW JALPAIGURI (NJP) TO HOWRAH JUNCTION IS EVERY DAY EXCEPT WEDNESDAY</b></h4>
                </div>
                <br />

                <div className="card-body">
                  <div id="no-more-tables">
                    <table class="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead class="cf">
                        <tr className="table-danger">
                          <th>STATION</th>
                          <th>ARRIVAL DEPARTURE</th>
                          <th>HALT</th>
                          <th>DISTANCE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td data-title="STATION">New Jalpaiguri Jn (NJP)</td>
                          <td data-title="ARR/DEP">Starts | 15:05</td>
                          <td data-title="HALT"></td>
                          <td data-title="DISTANCE">0 km</td>
                        </tr>
                        <tr>
                          <td data-title="STATION">Barsoi Jn (BOE)</td>
                          <td data-title="ARR/DEP">16:44 | 16:46</td>
                          <td data-title="HALT">2 min</td>
                          <td data-title="DISTANCE">145 km</td>
                        </tr>
                        <tr>
                          <td data-title="STATION">Malda Town (MLDT)</td>
                          <td data-title="ARR/DEP">17:50 | 17:53</td>
                          <td data-title="HALT">3 min</td>
                          <td data-title="DISTANCE">235 km</td>
                        </tr>
                        <tr>
                          <td data-title="STATION">Bolpur Shantiniketan (BHP)</td>
                          <td data-title="ARR/DEP">20:22 | 20:24</td>
                          <td data-title="HALT">2 min</td>
                          <td data-title="DISTANCE">420 km</td>
                        </tr>
                        <tr>
                          <td data-title="STATION">Howrah Jn (HWH)</td>
                          <td data-title="ARR/DEP">22:35 | ends</td>
                          <td data-title="HALT"></td>
                          <td data-title="DISTANCE">565 km</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> HERE ARE THE BEST RETURN ROUTES FROM <strong>SANDAKPHU TREK PACKAGE</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>HERE ARE THE BEST RETURN ROUTES FROM <strong>SANDAKPHU TREK PACKAGE</strong></h4>
                </div>

                <div className="card-body">
                  <p className="p-2">Descending is just as important as ascending when trekking. Sandakphu is no different. There are many options for trekkers to choose from. First, please check the map:</p>
                  <p><b>OPTION 1:</b></p>
                  <p><strong>Sandakphu   →   Srikhola   →   Rimbik   →   Dhotrey   →   Manebhanjan   →   Mirik   →   NJP / Badgogra</strong></p>
                  <p>This is one of the most popular return routes from Sandakphu Trekking Package. It passes via Gurdum to timbury village to Srikhola. The route as per distances is like:</p>
                  <ul>
                    <li><b>Sandakphu to Gurdum</b> - 10km [4 hrs trek]</li><br />
                    <li><b>Gurdum to Srikhola (6900ft)</b> - 6km [2 hrs trek] (night stay preferable)</li><br />
                    <li><b>Srikhola to Rimbik (6500 ft)</b> - 7km [by Car]</li><br />
                    <li><b>Rimbik to Manebhanjan</b> - 43 km</li><br />
                    <li><b>Manebhanjan to NJP / Bagdogra</b> - 125km (4.30 hrs)</li>
                  </ul>
                  <br />
                      <div className="content-desktop">
                        <center><img src={sightseen3ImageURL} alt="..." /></center>
                      </div>

                      <div className="content-mobile2">
                        <center><img src={sightseen3ImageURL} style={{ width: '320px' }} alt="..." /></center>
                      </div>
                      <br />
                  <br />
                  <p><b>OPTION 2:</b></p>
                  <p><strong>Sandakphu → Sabargram → Molley → Rammam → Srikhola → Rimbik → Dhotrey → Manebhanjan → Mirik → NJP / Badgogra</strong></p>
                  <p>The route as per distances is like:</p>
                  <ul>
                    <li><b>Sandakphu to Sabargram (Sabarkum)</b> - 14km</li><br />
                    <li><b>Sabargram to Molley</b> - 8km</li><br />
                    <li><b>Molley to Rammam</b> - 11km</li><br />
                    <li><b>Rammam to Srikhola</b> - 12km</li><br />
                    <li><b>Srikhola to Rimbik</b> - 7km</li><br />
                    <li><b>Rimbik to Manebhanjan</b> - 43 km</li><br />
                    <li><b>Manebhanjan to NJP / Bagdogra</b> - 125km (4.30 hrs)</li>
                  </ul>
                  <br />
                  <p><b>OPTION 3:</b></p>
                  <p><strong>Sandakphu → Bikheybhanjan → Rimbik → Dhotrey → Manebhanjan → Mirik → NJP / Badgogra</strong></p>
                  <p>The route as per distances is like:</p>
                  <ul>
                    <li><b>Sandakphu to Bikheybhanjan</b> - 4km</li><br />
                    <li><b>Bikheybhanjan to Rimbik (6500 ft)</b> - 15km [4 hrs trek]</li><br />
                    <li><b>Rimbik to Manebhanjan</b> - 43 km [3 hrs by jeep]</li><br />
                    <li><b>Manebhanjan to NJP / Bagdogra</b> - 125km (4.30 hrs)</li>
                  </ul>
                  <br />
                  <p><b>OPTION 4:</b></p>
                  <p><strong>Sandakphu → Sabargram → Phalut → Gorkey → Rammam → Srikhola → Rimbik → Dhotrey → Manebhanjan → Mirik → NJP / Badgogra</strong> (NOTE: This is considered as longest Route)</p>
                  <p>The route as per distances is like:</p>
                  <ul>
                    <li><b>Sandakphu to Sabargram</b> - 14km</li><br />
                    <li><b>Sabargram to Phalut</b> - 7km</li><br />
                    <li><b>Phalut to Gorkey</b> - 15km</li><br />
                    <li><b>Gorkey to Rammam</b> - 9km</li><br />
                    <li><b>Rammam to Srikhola</b> - 12km</li><br />
                    <li><b>Srikhola to Rimbik</b> - 7km</li><br />
                    <li><b>Rimbik to Manebhanjan</b> - 43 km [3 hrs by jeep]</li><br />
                    <li><b>Manebhanjan to NJP / Bagdogra</b> - 125km (4.30 hrs)</li>
                  </ul>
                  <br />
                  <p>If you want, you can return from Sandakphu to Manebhanjan by Land Rover. It's just a 2-hour drive in a classic British-era Land Rover. From Manebhanjan, take another shared car to Sukhiapokhri, and from there, continue to NJP, Siliguri, or Bagdogra. This is the quickest way to get down from Sandakphu Land Rover Package Tour.</p>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>HERE ARE THE ESSENTIAL DOCUMENTS YOU NEED FOR <strong>SANDAKPHU LAND ROVER PACKAGE TOUR</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>HERE ARE THE ESSENTIAL DOCUMENTS YOU NEED FOR <strong>SANDAKPHU LAND ROVER PACKAGE TOUR</strong></h4>
                </div>
                <br />
                <div className="card-body">
                  <p>For <strong>Sandakphu Trekking:</strong></p>
                  <p>You need a permit to trek in Singalila National Park, which will be provided by Adorable Vacation LLP representative. You must also bring a photocopy of any government ID such as Voter Card, Aadhar Card, Driving License, Passport, etc. This permit and ID are necessary for entry because the area has wild animals.</p>
                  <br />
                  <div className="content-desktop">
                    <center><img src={sightseen4ImageURL} alt="..." /></center>
                  </div>

                  <div className="content-mobile2">
                    <center><img src={sightseen4ImageURL} style={{ width: '320px' }} alt="..." /></center>
                  </div>
                  <br />
                  <br />
                  <p>For <strong>Sandakphu Tour by Land Rover:</strong></p>
                  <p>If you're traveling to Sandakphu Phalut by Land Rover, carry both the original and a photocopy of a government-approved ID like Voter Card, Aadhar Card, Driving License, Passport, etc. Show these documents to the Adorable Vacation LLP representative before boarding the Land Rover in Manebhanjan. They will arrange the necessary permits and tickets for entering Singalila National Park. You'll also need to present your documents at various check posts guarded by SSB Jawans.</p>
                  <p>Note: It's recommended to keep all documents in a transparent plastic cover to protect them from rain. In addition to documents, trekkers should bring essentials like trekking shoes, a backpack, trekking pants, sunglasses, a sun cap, gloves, socks, a headlamp, rain gear, a rain cover for your backpack, and a toiletry kit with basic items like toothbrush, toothpaste, soap, toilet paper, moisturizer, lip balm, deodorant, and sanitary pads.</p>
                  <p>Note: Please ensure you bring back all waste, such as sanitary items, bottles, and wrappers, in a sealed bag to keep the mountains and trails clean. Leave only footprints and take away memories.</p>
                </div>
              </div>
              <br />
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h3 className="content-desktop" style={{ fontSize: "2.0vw" }}>
                        DAY WISE ITINERARY FOR <strong>SANDAKPHU TREKKING PACKAGE BOOKING</strong>
                      </h3>
                      <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>
                        DAY WISE ITINERARY FOR <strong>SANDAKPHU TREKKING PACKAGE BOOKING</strong>
                      </h3>
                    </div>
                  </div>
                </div>

                <br />
                <div className="row">
                  <div
                    className="accordion"
                    id="accordionPanelsStayOpen"
                  >
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="day1"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseOne"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseOne"
                        >
                          DAY 1 : NJP/BAGDOGRA to MANEBHANJAN (2 hr 46 min, 80.4 km)
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingOne"
                      >
                        <div className="accordion-body">
                          <p>It's the first day of your <strong>Sandakphu Trek</strong>. Many of you will be arriving from different places, so we'll meet at NJP Station or Siliguri Station. Our representative will give you a warm welcome. After a short briefing and tea break, we'll head to Manebhanjan.</p>
                          <p>Note: From Siliguri Station, we can take a small rickshaw or auto to Darjeeling More, where our car will be waiting. From there, we'll drive up the hill to Manebhanjan.</p>
                          <p>The journey from the plains (NJP/Siliguri/Bagdogra) to the hills is enjoyable, with the Sukna forest, army camps, Sal and Teak forests, tea gardens, and local huts decorated with flowers. You'll also see smiling children along the way.</p>
                          <p>After reaching Ghum Station, we'll take a left turn and go through Lepcha Jagat, Sukhia Pokhari, Simana Bazar, and then arrive at Manebhanjan, a small village near the Indo-Nepal border.</p>
                          <p>Manebhanjan is the gateway for your <strong>Sandakphu Trekking</strong>. Depending on road and weather conditions, you'll reach Manebhanjan around 1 to 1:30 pm. We'll spend the night here. After lunch, rest and explore Manebhanjan. Visit the small Buddhist Pagoda, stroll around, and enjoy the evening. Stay at a hotel or homestay.</p>
                          <p>Note: Manebhanjan is the hub of the Singalila Land Rover Association, with 45 old British-era Land Rovers. Though their iconic green color has faded, they are still charming and reliable for the rocky roads.</p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay1ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay1ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="day2">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseTwo"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseTwo"
                        >
                          DAY 2 : MANEBHANJAN to TUMLING/TONGLU (13 km, 6-8 hrs)
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseTwo"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingTwo"
                      >
                        <div className="accordion-body">
                          <p>Wake up early and enjoy a cup of hot tea. After breakfast, get ready with your rucksack. If you need any trekking gear, you can buy it here.</p>
                          <p>We'll start our trek to Tumling or Tonglu (depending on weather and availability), about 13 km away, via Chitrey, Meghma, and Tonglu.</p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay2ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay2ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                          <br />
                          <p>Chitrey: A small, picturesque village 3 km from Manebhanjan, at 8340 ft above sea level. It's surrounded by forest and meadows, offering beautiful views.</p>
                          <p>Meghma: A small village at 9514 ft, 9 km from Manebhanjan. Often covered in clouds, it's named "Meghma" (meaning cloud in the local language). It's a stunning place that will leave you spellbound.</p>
                          <p>Tumling: A small hamlet in Nepal with about 15 people. It's a gateway to Singalila National Park, located at 10,000 ft. On clear days, you can see Mt. Kanchenjunga.</p>
                          <p>After reaching Tumling, rest and explore the area. It's a great place for photography. Enjoy your evening and stay at Tumling.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="day3">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseThree"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          DAY 3 : TUMLING to KALIPOKHRI (12 km, 6-8 hrs)
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseThree"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingThree"
                      >
                        <div className="accordion-body">
                          <p>Wake up early to catch the sunrise at Tumling. After breakfast, start trekking to Kalipokhri, about 12 km away. You'll pass the Singalila National Park Check Post (1 km from Tumling, where you'll need to show your permits).</p>
                          <p>The route takes you through bamboo and rhododendron forests. In spring, the rhododendrons are in full bloom.</p>
                          <p>You'll reach Jhaubari, a small settlement with 5-6 homestays, after 5 km. From there, it's a 1 km steep descent to Gairibas, where you can take a tea or momo break.</p>
                          <p>Continue trekking through dense bamboo forest to Kalipokhri, a sacred pond for the local people.</p>
                          <p>Rest and enjoy the area. Night temperatures can drop below freezing, so dress warmly.</p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay3ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay3ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="day4">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseFour"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseFour"
                        >
                          DAY 4 : KALIPOKHRI to SANDAKPHU (6 km, 3 hrs)
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseFour"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingFour"
                      >
                        <div className="accordion-body">
                          <p>After breakfast, start the final part of your <strong>Sandakphu Phalut Trek</strong>. It's a 6 km steep trek through rocky paths. After 1.5 hours, you'll reach Bikhey Bhanjang, a small hamlet. From here, it's another 4 km to Sandakphu.</p>
                          <p>The trek is tough due to the high altitude, but the natural beauty is rewarding.</p>
                          <p>Reaching Sandakphu, you'll see magnificent views of the snowy mountains, including Everest, Kanchenjunga, and Chomolhari.</p>
                          <p>Rest and enjoy the views. Stay overnight at a homestay in Sandakphu.</p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay4ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay4ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="day5">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseFive"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseFive"
                        >
                          DAY 5 : SANDAKPHU to SRIKHOLA
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseFive"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingFive"
                      >
                        <div className="accordion-body">
                          <p>Start your return journey from Sandakphu. Trek 10 km to Gurdum through the dense Singalila forest, full of blooming rhododendrons in spring.</p>

                          <p>Gurdum is a small, picturesque village at 7150 ft. After lunch, continue trekking 2 hours to Srikhola, another beautiful place with mountain streams.</p>
                          <p>Rest and enjoy the evening. Stay overnight at Srikhola.</p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay5ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay5ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="day6">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseSix"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseSix"
                        >
                          DAY 6 : END OF TREK
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseSix"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingSix"
                      >
                        <div className="accordion-body">
                          <p>After breakfast, take a shared vehicle to NJP/Siliguri/Bagdogra via Rimbik, Manebhanjan, Sukhiapokhri, and Mirik. It's a long journey of about 160 km, taking 6-7 hours. Your <strong>Sandakphu Trekking</strong> ends with sweet memories.</p>

                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay6ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay6ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* inclusion start  */}

              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">{<InclusionExclusion />}</div>
                </div>
              </div>

              {/* inclusion End  */}
              <br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>WHO CAN JOIN <strong>SANDAKPHU PHALUT TREKKING PACKAGE TOUR</strong> WITH ADORABLE VACATION LLP?</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>WHO CAN JOIN <strong>SANDAKPHU PHALUT TREKKING PACKAGE TOUR</strong> WITH ADORABLE VACATION LLP?</h4>
                </div>
                <div className="card-body">
                  <ul>
                    <li>• Anyone above 12 years old, regardless of gender, who is physically fit and enjoys sports can join the <strong>Sandakphu Phalut Trekking Package</strong> group with Adorable Vacation LLP.</li>
                    <li>• First-time trekkers and adventure enthusiasts are welcome as long as they have a passion for trekking.</li>
                    <li>• Participants must be physically fit and have enough stamina to trek continuously for 5 to 6 kilometers without breaks or stress.</li>
                    <li>• Trekkers should be capable of carrying a backpack weighing approximately 10 kilograms over hills and slopes.</li>
                    <li>• Individuals with high blood pressure, heart disease, asthma, or vertigo are not permitted to participate in the trekking.</li>
                  </ul>
                </div>
              </div>
              <br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>IMPORTANT NOTE FOR SANDAKPHU AND PHALUT TREK</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>IMPORTANT NOTE FOR SANDAKPHU AND PHALUT TREK</h4>
                </div>
                <div className="card-body">
                  <p>You need to reach NJP or Bagdogra between 9:00 and 9:30 am. On the return day, you will arrive at NJP or Bagdogra around 4:00 to 5:00 pm. Please book your train or flight tickets accordingly.</p>
                  <ul>
                    <li><b>•	Customizable Trip:</b> You can add a visit to Darjeeling and see places like Lepcha Jagat, Lamahatta, Sitong, and the Toy Train Joy Ride. Contact Adorable Vacation LLP when booking your Sandakphu Trekking Package to customize your trip.</li><br />
                    <li><b>• Cash:</b> The last ATM is in Sukhiapokhri, but it may not always have cash. It’s better to withdraw enough cash from NJP, Siliguri, or Bagdogra and have small bills.</li><br />
                    <li><b>• Mobile Network:</b> Bring at least 2 SIM cards. Most mobile networks work, but at the top of Sandakphu and Phalut, Indian networks might not work well. Nepalese networks work fine and can be used with permission from hotel or trekkers hut owners.</li><br />
                    <li><b>• Accommodation:</b> We usually book homestays or trekkers huts for you. If you want to camp under the stars, let us know when you book.</li><br />
                    <li><b>• Medicine:</b> Bring important medicines for fever, cough, dizziness, vomiting, band-aids, ORS, etc.</li><br />
                    <li><b>• Water:</b> Carry enough bottled water for the trek and drink plenty of water.</li><br />
                    <li><b>• Toilets:</b> There will be no modern toilets during the trek. Be prepared for this.</li><br />
                    <li><b>• Raincoat:</b> Bring a raincoat. Waterproof trekking suits are even better.</li><br />
                    <li><b>• Clothing:</b> Wear bright-colored trekking suits and choose a bright-colored rucksack. This helps in locating you easily in tough situations.</li>
                  </ul>
                </div>
              </div>

              <br /><br />


              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h2>
                        Frequently Ask <span>Questions</span>
                      </h2>
                      <h4><strong>SANDAKPHU PHALUT TREKKING PACKAGE</strong></h4>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div
                    className="accordion accordion-flush faQStyle"
                    id="accordionFlushExample"
                  >
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingOne">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseOne"
                          aria-expanded="false"
                          aria-controls="flush-collapseOne"
                        >
                          Which is the nearest hospital to Sandakphu Trekking Package from NJP?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseOne"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingOne"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>There are no modern hospitals at the start of the trek from NJP or Manebhanjan. In an emergency, you need to go to Rimbik or Sukhiapokhri.</p>
                          <p><b>Health Center in Rimbik:</b>
                            Rimbik SC Health Sub-center
                            Above Rimbik Higher Secondary School, Darjeeling Pulbazar, Bijanbari, West Bengal 734201
                          </p>
                          <p><b>Sukhiapokhri:</b> 40 km from Sandakphu, about a 3-hour drive. You can book a Land Rover from Sandakphu to Manebhanjan, then another car to Sukhiapokhri.</p>
                          <p><b>Sukhiapokhri Govt Hospital:</b>
                            SukhiaPokhari, West Bengal 734221
                          </p>
                          <p><b>Sukhiapokhri Block Hospital:</b>
                            Alipurduar Rd, SukhiaPokhari, West Bengal 734221
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingTwo">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseTwo"
                          aria-expanded="false"
                          aria-controls="flush-collapseTwo"
                        >
                          How far is Sandakphu from Darjeeling?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseTwo"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingTwo"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p><b>From Darjeeling to Manebhanjan:</b> 25 km via Ghum and Sukhiapokhri</p>
                          <p><b>From Manebhanjan to Sandakphu:</b> 32 km</p>
                          <p><b>Total distance:</b> About 55 km</p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingThree">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseThree"
                          aria-expanded="false"
                          aria-controls="flush-collapseThree"
                        >
                          Can I trek to Sandakphu Phalut between September and December?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseThree"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingThree"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>Yes, the best time to trek is from the end of September to the end of November or the first half of December. The weather is cool and clear, offering great views of the Himalayas, including Mt. Everest, Mt. Makalu, Mt. Lhotse, and Mt. Kanchenjunga.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingFour">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFour"
                          aria-expanded="false"
                          aria-controls="flush-collapseFour"
                        >
                          Is Sandakphu Phalut trekking safe?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseFour"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingFour"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>Yes, trekking with Adorable Vacation LLP is safe. We provide top-level arrangements, including homestays, guides, porters, permits, equipment, and safety measures. We are always ready for urgent evacuation with Land Rovers.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingFive">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFive"
                          aria-expanded="false"
                          aria-controls="flush-collapseFive"
                        >
                          What is special about the Sandakphu Phalut Trekking?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseFive"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingFive"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>Sandakphu, at 11,950 ft, offers views of the four highest peaks in the world: Mt. Everest, Mt. Lhotse, Mt. Makalu, and Mt. Kanchenjunga. This unique location makes the trek special.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingSix">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseSix"
                          aria-expanded="false"
                          aria-controls="flush-collapseSix"
                        >
                          How long is the Sandakphu Phalut Trekking Package Tour?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseSix"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingSix"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>The trek from Manebhanjan (6,325 ft) to Sandakphu (11,950 ft) is 32 km. It usually takes 3 days with overnight stays at Tumling/Tonglu and Kalipokhri.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingSeven">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseSeven"
                          aria-expanded="false"
                          aria-controls="flush-collapseSeven"
                        >
                          How to book the Sandakphu Tour package?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseSeven"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingSeven"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>Talk to our Sandakphu Trekking expert, plan your trek, register your name, and pay 25% of the total package cost. Our trek coordinator will guide you through the process.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingEight">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseEight"
                          aria-expanded="false"
                          aria-controls="flush-collapseEight"
                        >
                          What is the temperature in Sandakphu in November and December?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseEight"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingEight"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>Temperatures can drop to zero or below, sometimes reaching -15°C in December. Bring enough warm clothes and equipment.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingNine">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseNine"
                          aria-expanded="false"
                          aria-controls="flush-collapseNine"
                        >
                          How to prepare for the Sandakphu Trek Package?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseNine"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingNine"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>Most of the trek is flat with some steep sections. Prepare with at least a month of training and ensure good fitness to avoid any problems during the trek.</p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingTen">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseTen"
                          aria-expanded="false"
                          aria-controls="flush-collapseTen"
                        >
                          What should I pack for the Sandakphu Phalut Trekking Package Tour?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseTen"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingTen"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>Pack carefully. Important items include jackets, waterproof jacket with hood, tracksuit, hiking shoes, socks, underwear, toilet paper, towel, trekking gloves, caps, snow glasses, sunglasses, trekking sticks, water bottle, purifying tablets, headlamp, torch, candles, matches/lighters, binoculars, camera, knife, first aid kit, moisturizer, dry foods, chocolate, and candy.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingEleven">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseEleven"
                          aria-expanded="false"
                          aria-controls="flush-collapseEleven"
                        >
                          What kind of food is available during the Sandakphu phalut Trekking Package?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseEleven"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingEleven"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>Both vegetarian and non-vegetarian meals are available, including rice, dal, chapati, roti, vegetables, egg curry, chicken curry, and some Chinese dishes like chow mein and fried rice.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingTwelve">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseTwelve"
                          aria-expanded="false"
                          aria-controls="flush-collapseTwelve"
                        >
                          Can I get drinks in Sandakphu Phalut Trekking?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseTwelve"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingTwelve"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>Yes, you can bring your own drinks or buy them at Sunrise Hotel and Restaurant in Sandakphu, which offers a variety of drinks including Nepalese beer and Old Monk Rum.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingThirteen">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseThirteen"
                          aria-expanded="false"
                          aria-controls="flush-collapseThirteen"
                        >
                          What is the best time for the Sandakphu Trekking Package Booking?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseThirteen"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingThirteen"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <ul>
                            <li><b>Spring/Summer (End of March to May):</b> Best for seeing the bloom of rhododendrons and primulas.</li><br/>
                            <li><b>Autumn/Winter (End of October to January):</b> Best for clear views of mountain peaks and playing in the snow.</li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingFourteen">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFourteen"
                          aria-expanded="false"
                          aria-controls="flush-collapseFourteen"
                        >
                          Is electricity available in Sandakphu?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseFourteen"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingFourteen"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>Yes, but with low voltage. No geysers or room heaters are available. Warm water is provided in buckets on request.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingFifteen">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFifteen"
                          aria-expanded="false"
                          aria-controls="flush-collapseFifteen"
                        >
                          How to reach Sandakphu from Kolkata/Delhi/Bengaluru/Mumbai?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseFifteen"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingFifteen"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <ul>
                            <li><b>By Train:</b> Various trains are available to NJP or Siliguri from these cities.</li><br/>
                            <li><b>By Flight:</b> Bagdogra (IXB) is the nearest airport with regular flights from these cities. From Bagdogra or NJP, start your trip to Manebhanjan (90 km/3 hrs), where the trek begins.</li>
                          </ul>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h3>
                        <b>
                          Sandakphu <span className="tomato">Tour Reviews</span>
                        </b>
                      </h3>
                      <h4>
                        <i>"Your Experience is our Marketing"</i>
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="row row-cols-1 ">
                  <div id="testimonials">
                    <div className="testimonial-box-container">
                      {reviewersDataView}
                    </div>
                  </div>
                </div>
              </div>
            </div >
            <div className="col-md-3">
              <BookingBox />
            </div>
          </div >
        </div >
      </>
    );
  }
}
export default Sandakphu_trekking_Package;
