import React, { Component } from 'react';
import BookingBox from '../BookingBox';
import Slider from "react-slick";
import ApiURL from "../../../api/ApiURL";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InclusionExclusion from '../Kashmir/InclusionExclusion_kashmir_vaishno_devi';

// import FloatingWhatsAppButton from 'react-floating-whatsapp';

class Kashmir_7N_8D_Vaishno_Devi_Package extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customActiveTab: 'tab1',
            daywiseActiveTab: 'day1'
        }
    }

    handleCustomTabClick = (tab) => {
        this.setState({
            customActiveTab: tab,
        });
    };

    handleDayWiseTabClick = (tab) => {
        this.setState({
            daywiseActiveTab: tab,
        });
    };
    handleClick = () => {
        const currentUrl = window.location.href;
        const pageTitle = document.title;
        const message = `Hi, Please send about the Tour Package details "${pageTitle}"`;

        const whatsappLink = `https://api.whatsapp.com/send?phone=+919147061467&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
        window.open(whatsappLink, '_blank');
    };

    render() {
        const { customActiveTab, daywiseActiveTab } = this.state;
        const packageTopBannerImageURL = ApiURL.Kashmir_Tour_Package_with_Vaishno_Devi + "/Kashmir-Package-Tour-with-Vaishno-Devi.webp";
        const packageTopBanner2ImageURL = ApiURL.Kashmir_Tour_Package_with_Vaishno_Devi + "/Kashmir-Tour-Package-with-Vaishno-Devi.webp";
        const sightseen1ImageURL = ApiURL.Kashmir_Tour_Package_with_Vaishno_Devi + "/Kashmir-Package-Gureaz-valley.webp";
        const sightseen2ImageURL = ApiURL.Kashmir_Tour_Package_with_Vaishno_Devi + "/Kashmir-Package-Trip-with-Gurez-Valley.webp";
        const sightseen3ImageURL = ApiURL.Kashmir_Tour_Package_with_Vaishno_Devi + "/Kashmir-Tour-Packages-with-Gurez-valley.webp";
        const sightseen4ImageURL = ApiURL.Kashmir_Tour_Package_with_Vaishno_Devi + "/Kashmir-Tour-Package.webp";
        const sightseen5ImageURL = ApiURL.Kashmir_Tour_Package_with_Vaishno_Devi + "/Kashmir-Offbeat-Family-Packages.webp";
        const sightseen6ImageURL = ApiURL.Kashmir_Tour_Package_with_Vaishno_Devi + "/kashmir-tour-packages-cost-with-Gurez-Valley.webp";
        const sightseen7ImageURL = ApiURL.Kashmir_Tour_Package_with_Vaishno_Devi + "/Kashmir-Tour-Packages-with-Yusmarg.webp";
        const sightseen8ImageURL = ApiURL.Kashmir_Tour_Package_with_Vaishno_Devi + "/Doodhpathri-Kashmir-Package.webp";
        const sightseen9ImageURL = ApiURL.Kashmir_Tour_Package_with_Vaishno_Devi + "/Kashmir-Offbeat-Package.webp";
        const sightseen10ImageURL = ApiURL.Kashmir_Tour_Package_with_Vaishno_Devi + "/Kashmir-Tours-Plan.webp";
        const sightseen11ImageURL = ApiURL.Kashmir_Tour_Package_with_Vaishno_Devi + "/Offbeat-Kashmir-Tours-with-Gurez-Valley.webp";
        const sightseen12ImageURL = ApiURL.Kashmir_Tour_Package_with_Vaishno_Devi + "/Kashmir-offbeat-package-tours-with-Gurez-Valley.webp";
        //day wise itenaryy
        const navDay1ImageURL = ApiURL.Kashmir_Tour_Package_with_Vaishno_Devi + "/Kashmir-Trip.webp";
        const navDay2ImageURL = ApiURL.Kashmir_Tour_Package_with_Vaishno_Devi + "/Kashmir-Tour-Packages-with-Vaishno-Devi.webp";
        const navDay3ImageURL = ApiURL.Kashmir_Tour_Package_with_Vaishno_Devi + "/Kashmir-Package-Tour-Cost-with-Vaishno-Devi.webp";
        const navDay4ImageURL = ApiURL.Kashmir_Tour_Package_with_Vaishno_Devi + "/Kashmir-Tour-Packages-with-Vaishno-Devi-7N-8D.webp";
        const navDay5ImageURL = ApiURL.Kashmir_Tour_Package_with_Vaishno_Devi + "/Kashmir-Package-Tour-Operator.webp";
        const navDay6ImageURL = ApiURL.Kashmir_Tour_Package_with_Vaishno_Devi + "/Kashmir-with-Vaishno-Devi.webp";
        const navDay7ImageURL = ApiURL.Kashmir_Tour_Package_with_Vaishno_Devi + "/Kashmir-with-Vaishno-Devi-Tour.webp";
        const navDay8ImageURL = ApiURL.Kashmir_Tour_Package_with_Vaishno_Devi + "/Kashmir-vaishno-Devi-Tour-Package.webp";
        const Topbanner0ImageURL = ApiURL.Kashmir_Tour_Package_with_Vaishno_Devi + "/Kashmir-Houseboat-Packages.webp";
        const Topbanner1ImageURL = ApiURL.Kashmir_Tour_Package_with_Vaishno_Devi + "/Kashmir-Adventure-Tour-Plan.webp";
        const Topbanner2ImageURL = ApiURL.Kashmir_Tour_Package_with_Vaishno_Devi + "/Kashmir-Gondola-Package-Tour.webp";
        const Topbanner3ImageURL = ApiURL.Kashmir_Tour_Package_with_Vaishno_Devi + "/Kashmir-Trekking-Packages.webp";
        const Topbanner4ImageURL = ApiURL.Kashmir_Tour_Package_with_Vaishno_Devi + "/Kashmir-White-River-Rafting-Plan.webp";
        const brandedTourackageImage_1_URL = ApiURL.Kashmir_Tour_Package_with_Vaishno_Devi + "/The-Khyber-Himalayan-Resort-&-Spa.webp";
        const brandedTourackageImage_2_URL = ApiURL.Kashmir_Tour_Package_with_Vaishno_Devi + "/The-LaLiT-Grand-Palace-Srinagar-Hotel.webp";
        const TrendingPackagesDataList = this.props.TrendingPackagesData;
        const hotPackagesView = TrendingPackagesDataList.length > 0 ?
            (
                TrendingPackagesDataList.map((dataItem, i) => {
                    const hotelImgURL = ApiURL.Kashmir_Tour_Package_with_Vaishno_Devi + "/" + dataItem.ImageName;
                    return (<div className="col-md-12 p-0" key={i}>
                        <div className="card h-100">
                            <img src={hotelImgURL} className="card-img-top" alt={dataItem.AllternateText} />
                            <div className="card-body">
                                <h6 className="card-title">{dataItem.Title} <span> &#8377; {dataItem.PricePerNight}</span></h6>
                                <p className="card-text">
                                    <i className="fa fa-building tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-utensils tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-car-side tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-male tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-camera tomato"></i>
                                </p>
                                <p className="card-text"><span>{dataItem.Description}</span></p>
                                <p>
                                    <span><b className="tomato">{dataItem.NoOfTours} </b>Tours</span> | &nbsp;
                                    <span><b className="tomato">{dataItem.NoOfDeparture} </b>Departures</span> | &nbsp;
                                    <span><b className="sdf-blue"> {dataItem.Include}</b></span>
                                </p>
                            </div>
                            <div className="card-footer">
                                <small className="text-muted"><strong className="tomato">{dataItem.MutedText}</strong></small>
                                <a href="#" className="btn btn-success btn-sm float-end" onClick={this.handleClick}>
                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                </a>
                            </div>
                        </div>
                    </div>)
                })
            ) : (<div></div>)


        const settings = {
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const reviewersDataList = this.props.ReviewersData;
        const reviewersDataView = reviewersDataList.map((dataItem, i) => {
            const reviewerImageURL = ApiURL.Kashmir_Tour_Package_with_Vaishno_Devi + "/" + dataItem.ImageName;
            return (
                <div className="testimonial-box" key={i}>
                    <div className="box-top">
                        <div className="profile">
                            <div className="profile-img">
                                <img src={reviewerImageURL} alt="..." />
                            </div>
                            <div className="name-user">
                                <strong>{dataItem.Name}</strong>
                                <span>{dataItem.Location}</span>
                            </div>
                        </div>
                        <div className="reviews">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </div>
                    </div>
                    <div className="client-comment">
                        <p>{dataItem.Comment}</p>
                    </div>
                </div>
            )
        })

        return (
            <>

                <div className="container-fluid">
                    <div className="row">
                        <img src={packageTopBannerImageURL} className="mobile-image2" alt="..." />
                    </div>
                </div>
                <div className="container-fluid custom-container py-3">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="container-fluid custom-container py-1">

                                        <h1 className="content-desktop" style={{ fontSize: "2vw" }}><center><strong>KASHMIR TOUR PACKAGE WITH VAISHNO DEVI </strong></center></h1>

                                        <h1 className="content-mobile" style={{ fontSize: "4vw" }}><center><strong>KASHMIR TOUR PACKAGE WITH VAISHNO DEVI </strong></center>
                                            <hr />
                                        </h1>
                                        <div className="row">
                                            <img src={packageTopBanner2ImageURL} className="mobile-image" alt="..." />
                                        </div>
                                        <hr />
                                        <div className="d-flex flex-column flex-sm-row align-items-sm-center px-2">
                                            <div className="font-lg">
                                                3 Destination:{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Srinagar (3N)
                                                </a>
                                                &nbsp;{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Gulmarg (1N)
                                                </a>{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Pahalgam (3N)
                                                </a>
                                            </div>
                                            <div className="reviews mx-5">
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                            </div>
                                        </div>
                                        <p className="p-2">
                                            If you are looking for a memorable <strong>Kashmir Tour Packages with Vaishno Devi</strong>, you have come to the right place!
                                        </p>
                                        <hr />
                                        <br />
                                    </div>
                                    <h2 className="content-desktop p-2" style={{ fontSize: "1.6vw" }}> Searching for <strong> Vaishno Devi Tour Packages ?</strong> For Kashmir Vaishno Devi Package Tour <a href='tel:9147061467' class="tomato">☎ CALL Mr. Soumya +91- 91470-61467</a></h2>
                                    <h2 className="content-mobile p-4" style={{ fontSize: "4.5vw" }}> Searching for <strong> Vaishno Devi Tour Packages ?</strong> For Kashmir Vaishno Devi Package Tour <a href='tel:9147061467' class="tomato">☎ CALL Mr. Soumya +91- 91470-61467</a></h2>
                                    <br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> Experience the enchantment of Kashmir with our exclusive tour package with Vaishno Devi</h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}> Experience the enchantment of Kashmir with our exclusive tour package with Vaishno Devi</h4>
                                        </div>
                                        <div className="card-body">
                                            <h5><b>Discover the Scenic Beauty of Kashmir</b></h5>
                                            <p>Begin your adventure with Adorable Vacation LLP as you explore the <strong>Kashmir Package Tour with Vaishno Devi</strong>, known as "Paradise on Earth." Your journey starts in Srinagar, where you’ll be captivated by the serene beauty of Dal Lake, dotted with traditional houseboats and Shikaras. Wander through the iconic Mughal gardens, such as Shalimar Bagh and Nishat Bagh, where the symphony of colours and fragrances, combined with breath-taking views of the surrounding mountains, offer a tranquil retreat.</p>
                                            <h5><b>Immerse Yourself in the Serenity of Pahalgam</b></h5>
                                            <p>Next, travel to Pahalgam, <strong>Kashmir with Vaishno Devi Tour</strong> a picturesque town nestled in the lush green meadows and surrounded by the majestic Himalayas. Known as the 'Valley of Shepherds,' Pahalgam offers a perfect blend of natural beauty and peacefulness. <strong>Kashmir vaishno Devi Tour Package</strong> Here, you can stroll along the banks of the Lidder River, enjoy a pony ride through the verdant valleys, or simply relax and soak in the serenity of this idyllic destination. Pahalgam is also a gateway to several trekking routes and adventure activities, making it a haven for nature lovers and thrill-seekers alike.</p>
                                            <h5><b>Experience the Winter Wonderland of Gulmarg</b></h5>
                                            <p>Your journey continues <strong>Kashmir Package Tour Operator</strong>, a destination renowned for its pristine snow-covered landscapes and world-class skiing opportunities. In Gulmarg, you’ll have the chance to ride the famous Gulmarg Gondola, one of the highest cable cars in the world, offering panoramic views of the snow-capped peaks and the surrounding valleys. Kashmir Trip Whether you’re interested in winter sports or simply want to bask in the beauty of the Himalayan winter, Gulmarg provides a memorable experience that’s both exhilarating and serene.</p>
                                            <h5><b>Embark on a Spiritual Pilgrimage to Vaishno Devi</b></h5>
                                            <p>After experiencing the natural wonders of Kashmir, the tour takes a spiritual turn as you journey to the holy shrine of Kashmir with Vaishno Devi. Located in the Trikuta Mountains, this revered pilgrimage site is visited by millions of devotees each year. The trek to the shrine, whether undertaken on foot, by pony, or via helicopter, is a deeply spiritual experience, offering breath-taking views and a profound sense of devotion. Visiting the Kashmir Vaishno Devi Package Tour, temple is a powerful spiritual journey that will leave you with a sense of inner peace and fulfilment.</p>
                                            <h5><b>Enjoy Seamless Travel and Exceptional Comfort</b></h5>
                                            <p>Adorable Vacation LLP ensures that every aspect of your tour is planned and executed with utmost care. From comfortable accommodations to hassle-free transfers and knowledgeable guides, we take care of all the details so you can focus on enjoying your trip. In <strong>Kashmir Tour Package with Vaishno Devi</strong> Our commitment to quality service ensures that your experience is not only enjoyable but also memorable.</p>
                                            <h5><b>Book Your Unforgettable Tour Today</b></h5>
                                            <p>This <strong>Kashmir and Vaishno Devi tour package</strong> is an ideal blend of natural beauty, adventure, and spiritual exploration. Don’t miss the opportunity to create lifelong memories in these two remarkable destinations. Contact Adorable Vacation LLP, the best <strong>Kashmir Tour Operator in Kolkata</strong>, and book your <strong>Kashmir Tour Packages with Vaishno Devi</strong> and begin an extraordinary journey that combines the best of both worlds.</p>

                                            <table className="image-table">
                                                <tbody>
                                                    <tr>
                                                        <td className="image-cell">
                                                            <img src={sightseen1ImageURL} alt="kashmir tour packages" />
                                                            <img src={sightseen2ImageURL} alt="kashmir trip from srinagar airport" />
                                                            <img src={sightseen3ImageURL} alt="kashmir package tour cost from srinagar airport" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <br />
                                        </div>
                                    </div>
                                    <br /> <br /> <br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>KASHMIR & VAISHNO DEVI TOUR PACKAGE : 7N | 8D</strong></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>KASHMIR & VAISHNO DEVI TOUR PACKAGE : 7N | 8D</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <p>Places to cover in <strong>Offbeat Kashmir Tour </strong> : Katra 2N | Pahalgam 2N | Gulmarg 1N | Srinagar 1N | Srinagar Houseboat 1N</p>
                                            <p>Pickup <b>Kashmir Tour Packages</b> : Jammu Tawi Railway Station (JAT) / Jammu Airport (IXJ)
                                            </p>
                                            <p><b>Drop :</b> Sheikh Ul Alam International Airport, Srinagar (SXR)</p>
                                            <br /><br />
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <tbody>
                                                        <tr>
                                                            <td><strong>Vaishno Devi Tour Packages Pickup</strong> Pickup & Drop	</td>
                                                            <td>Jammu Tawi Railway Station [JAT]</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Vaishno Devi Tour Packages Duration</strong></td>
                                                            <td>07 Nights | 08 Days</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Kashmir Tour With Vaishno Devi Tour Packages Cost </strong></td>
                                                            <td>Rs. 26,200/Based on 8 Pax</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Srinagar</strong></td>
                                                            <td>1,585 m | Coordinates - 34.0837° N, 74.7973° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Gulmarg</strong></td>
                                                            <td>2,650 m | Coordinates - 34.0484° N, 74.3805° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Sonmarg</strong></td>
                                                            <td>2,730 m | Coordinates - 34.3032° N, 75.2931° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Pahalgam</strong></td>
                                                            <td>2,740 m | Coordinates - 34.0161° N, 75.3150° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Betaab Valley</strong></td>
                                                            <td>2,393 m | Coordinates - 34.0541° N, 75.3639° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Aru Valley</strong></td>
                                                            <td>2,414 m | Coordinates - 34.0922° N, 75.2632° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Chandanwadi</strong></td>
                                                            <td>3,288 m | Coordinates - 34.2122° N, 73.9295° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Doodhpathri</strong></td>
                                                            <td>2,730 m | Coordinates - 33.8509° N, 74.5630° E</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <br /><br /><br />
                                            <table className="image-table">
                                                <tbody>
                                                    <tr>
                                                        <td className="image-cell">
                                                            <img src={sightseen4ImageURL} alt="kashmir package tour from srinagar" />
                                                            <img src={sightseen5ImageURL} alt="kashmir package price from srinagar airport" />
                                                            <img src={sightseen6ImageURL} alt="kashmir packages from srinagar airport" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <br />

                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    {/* start short itinerary */}

                                    <div className="card">
                                        <div className="card-body">
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr className="table-danger">
                                                        <th>DAY-WISE SUMMARY FOR KASHMIR TOUR PACKAGE WITH VAISHNO DEVI</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingOne"
                                                        >
                                                            <a href="#day1" style={{ color: 'black' }}><b>DAY 1 → </b>
                                                                <span>
                                                                    PICK UP AND TRANSFER TO HOTEL
                                                                </span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingTwo"
                                                        >
                                                            <a href="#day2" style={{ color: 'black' }}><b>DAY 2 → </b>
                                                                <span>VAISHNODEVI DARSHAN</span>
                                                            </a>

                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingThree"
                                                        >
                                                            <a href="#day3" style={{ color: 'black' }}><b>DAY 3 → </b>
                                                                <span>KATRA TO PAHALGAM TOUR & NIGHT STAY AT HOTEL</span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingFour"
                                                        >
                                                            <a href="#day4" style={{ color: 'black' }}><b>DAY 4 → </b>
                                                                <span>PAHALGAM SIGHTSEEING [BETAB + ARU VALLEY & CHANDANWADI] & NIGHT STAY</span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingFive"
                                                        >
                                                            <a href="#day5" style={{ color: 'black' }}><b>DAY 5 → </b>
                                                                <span>PAHALGAM TO GULMARG TOUR, OVER NIGHT STAY AT GULMARG</span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingSix"
                                                        >
                                                            <a href="#day6" style={{ color: 'black' }}><b>DAY 6 → </b>
                                                                <span>SRINAGAR LOCAL SIGHTSEEING & NIGHT STAY AT SRINAGAR HOUSEBOAT</span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingSeven"
                                                        >
                                                            <a href="#day7" style={{ color: 'black' }}><b>DAY 7 → </b>
                                                                <span>SRINAGAR TO DOODH PATHRI DAY TRIP & NIGHT STAY IN SRINAGAR </span>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingEight"
                                                        >
                                                            <a href="#day8" style={{ color: 'black' }}><b>DAY 8 → </b>
                                                                <span>DEPARTURE FROM SRINAGAR AIRPORT </span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    {/* <!--end Short description--> */}
                                    <br /><br /><br />

                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>EXPLORING KASHMIR’S SPIRITUAL HERITAGE WITH</b> <strong>VAISHNO DEVI TOUR PACKAGES</strong></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>EXPLORING KASHMIR’S SPIRITUAL HERITAGE WITH</b> <strong>VAISHNO DEVI TOUR PACKAGES</strong></h4>
                                        </div>
                                        <div className="card-body">

                                            <p>Embark on a transformative journey through the enchanting landscapes of <strong>Kashmir Tour Itinerary</strong>, where ancient Hindu temples stand as enduring symbols of devotion and spirituality. Adorable Vacation invites you to explore the sacred sites of this breath-taking region, each offering a unique glimpse into Kashmir’s rich spiritual and cultural heritage. Our <strong>Vaishno Devi Tour Packages</strong> provide a profound experience that combines the spiritual significance of Vaishno Devi with the grandeur of Kashmir's historic temples.</p>
                                            <br />
                                            <h5><b>Martand Sun Temple:</b></h5>
                                            <p>Discover the Martand Sun Temple, an architectural marvel located in the ruins of Anantnag. Built in the 8th century by King Lalitaditya Muktapida, this grand temple was dedicated to the sun god, Surya. Admire the intricately carved pillars, majestic gateway, and detailed sculptures that reflect the splendor of ancient Kashmiri architecture, offering a window into the region’s illustrious past.</p>
                                            <br />
                                            <h5><b>Shankaracharya Temple:</b></h5>
                                            <p>Perched on Shankaracharya Hill in Srinagar, the Shankaracharya Temple is a significant spiritual site dedicated to Lord Shiva. Dating back to the 9th century, this ancient temple is believed to have been visited by the philosopher Adi Shankaracharya. Climb the stone steps to the temple’s summit and enjoy sweeping views of Srinagar and Dal Lake, enhancing your spiritual journey with stunning natural beauty.</p>
                                            <br />
                                            <h5><b>Mamleshwar Temple:</b></h5>
                                            <p>Situated in Pahalgam, the Mamleshwar Temple is a sacred site dedicated to Lord Shiva. Set against a backdrop of lush pine forests and gurgling streams, this serene temple provides a peaceful retreat for those seeking spiritual solace and rejuvenation. In <strong>Vaishno Devi Tour Packages</strong> Pay homage to the divine lingam within the temple and immerse yourself in the tranquil ambiance of this natural sanctuary.</p>
                                            <br />
                                            <h5><b>Kheer Bhawani Temple:</b></h5>
                                            <p>In the quaint village of Tula Mula lies the Kheer Bhawani Temple, dedicated to the goddess Ragnya Devi. Known for its sacred spring that changes color, this temple attracts devotees seeking divine blessings. Experience the vibrant atmosphere of the Zestha Ashtami festival, where the temple comes alive with rituals and celebrations, reflecting the deep connection between the goddess and her followers.</p>
                                            <h5><b>Aap Shambhu Temple:</b></h5>
                                            <p>Located in Bijbehara, the Aap Shambhu Temple is a revered site dedicated to Lord Shiva, the principal deity of Kashmir. Steeped in legend, it is said to have been built by the Pandavas during their exile. Explore the temple’s intricate carvings and stone sculptures depicting Hindu mythology, and feel the spiritual energy that pervades this sacred space.</p>
                                            <h5><b>Raghunath Temple:</b></h5>
                                            <p>In the heart of Jammu, the Raghunath Temple stands as a testament to devotion to Lord Rama, the seventh avatar of Lord Vishnu. Constructed in the 19th century by Maharaja Gulab Singh, this grand temple complex features intricately carved shrines and ornate domes. It serves as a major pilgrimage site, attracting visitors from across the country.</p>
                                            <h5><b>Awantipur Temple:</b></h5>
                                            <p>The Awantipur Temple ruins, built in the 9th century by King Avantivarman, offer a glimpse into Kashmir’s rich architectural heritage. Dedicated to Lord Shiva and Goddess Parvati, the complex showcases exquisite stone carvings and graceful columns, providing a peaceful setting for reflection and reverence.</p>
                                            <h5><b>Aughar Nath Temple:</b></h5>
                                            <p>In Srinagar, the Aughar Nath Temple is a cherished site dedicated to Lord Shiva. Known for its ancient lingam and sacred rituals, this temple offers spiritual solace and a connection to cosmic energies. Witness the vibrant prayers and offerings that form a part of the temple’s daily life.</p>
                                            <h5><b>Maha Kali Temple:</b></h5>
                                            <p>On the banks of the Jhelum River in Srinagar, the Maha Kali Temple is dedicated to the powerful goddess Kali. Adorned with vibrant murals and intricate sculptures, this temple exudes an aura of mysticism. Join in the festivals and rituals that celebrate the goddess’s divine power and embrace the spiritual energy that permeates this sacred site.</p>
                                            <p>Start your journey through the sacred realms of <strong>Vaishno Devi and Kashmir Tour Packages</strong>, where devotion and spirituality merge with breath-taking landscapes. Our <strong>Vaishno Devi and Kashmir tour package</strong> offer an unparalleled opportunity to explore these divine treasures, including the Raghunath Temple, Awantipur Temple, Aughar Nath Temple, Maha Kali Temple, and more. Book your tour today with Adorable Vacation and embark on a quest for spiritual enlightenment and inner peace amidst Kashmir’s revered temples.</p>
                                            <table className="image-table">
                                                <tbody>
                                                    <tr>
                                                        <td className="image-cell">
                                                            <img src={sightseen10ImageURL} alt="kashmir package tour booking from srinagar" />
                                                            <img src={sightseen11ImageURL} alt="kashmir package tour booking from srinagar airport" />
                                                            <img src={sightseen12ImageURL} alt="kashmir package tour cost from srinagar" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <br />
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>COST OF <strong>KASHMIR OFFBEAT TOUR PACKAGE WITH VAISHNO DEVI</strong></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>COST OF <strong>KASHMIR OFFBEAT TOUR PACKAGE WITH VAISHNO DEVI</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <thead className="cf">
                                                        <tr className="table-danger">
                                                            <th>TOUR DURATION</th>
                                                            <th>2 PAX</th>
                                                            <th>4 PAX</th>
                                                            <th>6 PAX</th>
                                                            <th>8 PAX</th>
                                                            <th>BOOK NOW</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td data-title="TOUR DURATION">4N/5D</td>
                                                            <td data-title="2 ADULTS">₹ 20,500/-</td>
                                                            <td data-title="4 ADULTS">₹ 18,300/-</td>
                                                            <td data-title="6 ADULTS">₹ 16,600/-</td>
                                                            <td data-title="8 ADULTS">₹ 17,400/-</td>
                                                            <td data-title="Book Now"><a href="#" class="btn btn-success" onClick={this.handleClick}>Book Now</a></td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="TOUR DURATION">5N/6D</td>
                                                            <td data-title="2 ADULTS">₹ 24,700/-</td>
                                                            <td data-title="4 ADULTS">₹ 22,000/-</td>
                                                            <td data-title="6 ADULTS">₹ 19,800/-</td>
                                                            <td data-title="8 ADULTS">₹ 21,800/-</td>
                                                            <td data-title="Book Now"><a href="#" class="btn btn-success" onClick={this.handleClick}>Book Now</a></td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="TOUR DURATION">6N/7D</td>
                                                            <td data-title="2 ADULTS">₹ 30,900/-</td>
                                                            <td data-title="4 ADULTS">₹ 27,400/-</td>
                                                            <td data-title="6 ADULTS">₹ 24,700/-</td>
                                                            <td data-title="8 ADULTS">₹ 25,500/-</td>
                                                            <td data-title="Book Now"><a href="#" class="btn btn-success" onClick={this.handleClick}>Book Now</a></td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="TOUR DURATION">7N/8D</td>
                                                            <td data-title="2 ADULTS">₹ 35,600/-</td>
                                                            <td data-title="4 ADULTS">₹ 30,800/-</td>
                                                            <td data-title="6 ADULTS">₹ 28,300/-</td>
                                                            <td data-title="8 ADULTS">₹ 29,100/-</td>
                                                            <td data-title="Book Now"><a href="#" class="btn btn-success" onClick={this.handleClick}>Book Now</a></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <br /><br />

                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>FAMOUS LAKES TO EXPLORE DURING <strong>KASHMIR TOUR PACKAGES WITH VAISHNO DEVI</strong></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>FAMOUS LAKES TO EXPLORE DURING <strong>KASHMIR TOUR PACKAGES WITH VAISHNO DEVI</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <p>If you're embarking on a <strong>Kashmir Package Tour with Vaishno Devi</strong>, you absolutely must explore the region’s stunning lakes. Kashmir is renowned for its breath-taking lakes surrounded by snow-capped mountains, Chinar trees, pine forests, and vibrant gardens full of blooming tulips and roses. Here are some of the most famous lakes to include in your <strong>Kashmir Tour Packages with Vaishno Devi</strong>.</p>
                                            <p>Dal Lake is undoubtedly the most popular destination on a <strong>Kashmir Tour Package with Vaishno Devi</strong>. Spanning around 22 square kilometres, this picturesque lake is famous for its colourful Shikaras (traditional boats), houseboats, and floating markets. A Shikara ride on Dal Lake is an essential part of any <strong>Kashmir with Vaishno Devi</strong>. You must also visit Char Chinar, a beautiful island in the middle of the lake with four Chinar trees at its corners. Depending on the season, Char Chinar provides stunning views of the lake and its surroundings. Dal Lake has been featured in many Bollywood movies, making it a quintessential part of any <strong>Kashmir vaishno Devi Tour Package</strong>. Floating on a Shikara, enjoying hot noon Chai (also known as Shir Chai or Gulabi Chai), and sampling authentic Kashmiri cuisine makes for a truly memorable experience. Staying in a houseboat on the tranquil waters of Dal Lake adds to its romantic appeal.
                                            </p>
                                            <p><b>Wular Lake</b> is another significant attraction in Kashmir. Covering about 190 square kilometres in the Bandipora district, Wular Lake is the largest in Jammu and Kashmir and the second largest in Asia. Originating from the Jhelum River, Wular Lake is vital for the local ecosystem and boasts a diverse range of aquatic plants and fish. It’s a key part of any <strong>Customized Kashmir Tour</strong> for those interested in natural beauty and local wildlife.</p>
                                            <p><b>Manasbal Lake</b> located 28 kilometers from Srinagar at an elevation of 1,583 meters, is smaller compared to Dal and Nagin Lakes but equally charming. Known for its beautiful blooms of lilies and lotuses, Manasbal Lake is an ideal spot for spending quality time with loved ones. The lake is surrounded by well-maintained gardens filled with seasonal flowers, including tulips and roses, making it a sought-after destination in Kashmir.</p>
                                            <p>Including these lakes in your <strong>Kashmir Vaishno Devi Package Tour </strong> will ensure a memorable and picturesque experience, showcasing the natural beauty and romantic allure of the region.</p>
                                            <br />
                                            <br /> <br />
                                            <h5><b>ENTRY FEES FOR SIGHTSEEING DURING KASHMIR TOUR</b></h5>
                                            <br />

                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <tbody>
                                                        <tr>
                                                            <td>Places</td>
                                                            <td>Entry Fees</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Dal Lake</td>
                                                            <td>NIL</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Nishant Bagh</td>
                                                            <td>Rs. 24 per adult, Rs. 12 per child</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Pari Mahal</td>
                                                            <td>Rs. 40/Person</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Shalimar Bagh</td>
                                                            <td>Rs. 24 per adult, Rs. 12 per child</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Shankaracharya Temple</td>
                                                            <td>NIL</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Chashme Shahi</td>
                                                            <td>Rs. 24 per adult, Rs. 12 per child</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Verinag Garden</td>
                                                            <td>Rs. 10/Person</td>
                                                        </tr>
                                                        <tr>
                                                            <td>SPS Museum</td>
                                                            <td>Rs. 10 for Indian, Rs. 50 for Foreigners</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Tulip Garden</td>
                                                            <td>Rs. 75 per adult, Rs. 30 per child</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Aru Valley</td>
                                                            <td>Rs. 25/Person</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                            <br />
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div className="card-header custHeaer">
                                            <h4 className='content-desktop' style={{ fontSize: "1.5vw" }}>MUST DO ACTIVITIES DURING <strong>KASHMIR TOUR PACKAGES WITH VAISHNO DEVI</strong></h4>
                                            <h4 className='content-mobile2' style={{ fontSize: "3.5vw" }}>MUST DO ACTIVITIES DURING <strong>KASHMIR TOUR PACKAGES WITH VAISHNO DEVI</strong></h4>

                                        </div>
                                        <div className="card-body">
                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>Shikara Rides:</b><b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Shikara Rides:</b> Experience the tranquility of Dal Lake with a leisurely Shikara ride During your <strong> Kashmir Tour Packages with Vaishno Devi</strong>. Glide across the serene waters, passing by floating gardens, houseboats, and vibrant markets. Sunset and early morning rides offer particularly magical views.</p>
                                            <center> <img src={Topbanner0ImageURL} className="img-fluid" alt="..." /></center><br /><br /><br />

                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>Skiing and Snowboarding:</b>
                                                <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Skiing and Snowboarding:</b> During the winter months, places like Gulmarg transform into ski resorts, attracting enthusiasts from around the world. Gulmarg offers excellent slopes for skiing, snowboarding, and heli-skiing, with breathtaking views of the surrounding Himalayan peaks.</p>
                                            <center> <img src={Topbanner1ImageURL} className="img-fluid" alt="..." /></center><br /><br /><br />

                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>Gondola Ride: </b>
                                                <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Gondola Ride: </b>
                                                Take a ride on the Gulmarg Gondola, one of the highest cable cars in the world. Enjoy panoramic views of the snow-capped peaks as you ascend to Kongdori or Apharwat Peak, where you can indulge in activities like hiking and mountain biking.Experience this Awesome Ride During your <strong>Kashmir Tour Package with Vaishno Devi</strong> with Adorable Vacation and make this as a life long memories.</p>
                                            <center> <img src={Topbanner2ImageURL} className="img-fluid" alt="..." /></center><br /><br /><br />

                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>Trekking:</b>
                                                <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Trekking:</b> Kashmir is a paradise for trekkers, with numerous trails that cater to all levels of expertise. Popular trekking destinations include the Kashmir Great Lakes Trek, Tarsar Marsar Trek, and the trek to Thajiwas Glacier in Sonamarg.</p>
                                            <center> <img src={Topbanner3ImageURL} className="img-fluid" alt="..." /></center><br /><br /><br />
                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>River Rafting:</b>
                                                <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>River Rafting:</b> The Lidder River in Pahalgam offers exciting opportunities for white-water rafting. Experience the thrill of navigating through rapids while enjoying the scenic beauty of the surrounding pine forests and mountains.</p>
                                            <center> <img src={Topbanner4ImageURL} className="img-fluid" alt="..." /></center>
                                            <br /><br /><br />
                                            <a href="tel://9147061467" className="btn btn-Warning" style={{ padding: '15px 30px', fontSize: '20px' }}>CALL NOW</a>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="container-fluid navtabCustom">
                                        <nav>
                                            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                                <button onClick={() => this.handleCustomTabClick('tab1')} className={`tab button ${customActiveTab === 'tab1' ? 'active' : ''}`} role="tab" aria-controls="nav-highlights" aria-selected={customActiveTab === 'tab1'}>Highlights</button>
                                                <button onClick={() => this.handleCustomTabClick('tab2')} className={`tab button ${customActiveTab === 'tab2' ? 'active' : ''}`} role="tab" aria-controls="nav-flights" aria-selected={customActiveTab === 'tab2'}>Flights</button>
                                                <button onClick={() => this.handleCustomTabClick('tab3')} className={`tab button ${customActiveTab === 'tab3' ? 'active' : ''}`} role="tab" aria-controls="nav-hotel" aria-selected={customActiveTab === 'tab3'}>Hotel</button>
                                                <button onClick={() => this.handleCustomTabClick('tab4')} className={`tab button ${customActiveTab === 'tab4' ? 'active' : ''}`} role="tab" aria-controls="nav-sightseeing" aria-selected={customActiveTab === 'tab4'}>Sightseeing</button>
                                                <button onClick={() => this.handleCustomTabClick('tab5')} className={`tab button ${customActiveTab === 'tab5' ? 'active' : ''}`} role="tab" aria-controls="nav-meals" aria-selected={customActiveTab === 'tab5'}>Meals</button>
                                            </div>
                                        </nav>
                                        <div className="tab-content" id="nav-tabContent" >
                                            <div className={customActiveTab === 'tab1' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-highlights-tab">
                                                <p><strong>Vaishno Devi and Kashmir Tour Packages</strong> is renowned for its breathtaking landscapes, which include iconic features like the Dal Lake, Nigeen Lake, Gulmarg, Pahalgam, and Sonamarg. These areas attract tourists from around the world who come to admire the region's picturesque scenery and engage in various outdoor activities.</p>
                                                <p>Planning for a <strong>Vaishno Devi and Kashmir Tour Package</strong> remains a captivating destination for travellers seeking natural beauty, cultural experiences, and adventure in a unique and picturesque setting.</p>
                                                <p><b>Potential Geography</b> - Kashmir is nestled in the Himalayan Mountain range and is bordered by India, Pakistan, and China. It is divided into three regions: Jammu, the Kashmir Valley, and Ladakh. The region is characterized by its snow-capped peaks, lush valleys, meandering rivers, and serene lakes.</p>
                                                <p><strong>Vaishno Devi Tour Packages</strong> is renowned for its breathtaking landscapes, which include iconic features like the Dal Lake, Nigeen Lake, Gulmarg, Pahalgam, and Sonamarg. These areas attract tourists from around the world who come to admire the region's picturesque scenery and engage in various outdoor activities. Kashmir has long been a popular tourist destination, attracting visitors with its natural beauty, adventure sports, religious sites, and historical landmarks. Tourists can enjoy activities such as trekking, skiing, houseboat stays, shikara rides, and visits to Mughal gardens and ancient temples.</p>
                                            </div>
                                            <div className={customActiveTab === 'tab2' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-flights-tab">
                                                <div className="overflow-table">
                                                    <span>Best Srinagar Non-Stop Flight connection from your city<b> <i>(SXR)</i></b></span><br /><br />
                                                    <table className="table table-bordered">
                                                        <tr className="table-danger">
                                                            <th>FLIGHT</th>
                                                            <th>AIRLINES</th>
                                                            <th>AIRPORT</th>
                                                            <th>TIME</th>
                                                            <th>DAY</th>
                                                        </tr>
                                                        <tr>
                                                            <td>SG-509</td>
                                                            <td>SpiceJet</td>
                                                            <td>Mumbai</td>
                                                            <td>14:45</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>QP-1637</td>
                                                            <td>Akasa Air</td>
                                                            <td>Mumbai</td>
                                                            <td>5:35</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>63-266</td>
                                                            <td>IndiGo</td>
                                                            <td>Mumbai</td>
                                                            <td>06:45</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>SG-8913</td>
                                                            <td>SpiceJet</td>
                                                            <td>Delhi</td>
                                                            <td>08:00</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>SG-8373</td>
                                                            <td>SpiceJet</td>
                                                            <td>Delhi</td>
                                                            <td>09:45</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>6E-6947</td>
                                                            <td>IndiGo</td>
                                                            <td>Bengaluru</td>
                                                            <td>13:45</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>6E6253</td>
                                                            <td>IndiGo</td>
                                                            <td>Hyderabad</td>
                                                            <td>14:50</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>6E6945</td>
                                                            <td>IndiGo</td>
                                                            <td>Ahmedabad</td>
                                                            <td>06:55</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>6E6961</td>
                                                            <td>IndiGo</td>
                                                            <td>Kolkata</td>
                                                            <td>14:45</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className={customActiveTab === 'tab3' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-hotel-tab">

                                                <div className="overflow-table">
                                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }} ><center><b>4 STAR - CERTIFIED BY 'ADORABLE' </b></center></h4>
                                                    <div id="no-more-tables">
                                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                            <thead class="cf">
                                                                <tr className="text-center">
                                                                    {/* <!-- Add the "text-center" className to center content --> */}
                                                                    <th colspan="6" style={{ fontSize: "1.3vw" }}><h6><b>3 STAR CATEGORY HOTEL CERTIFIED BY 'ADORABLE VACATION' </b></h6></th>
                                                                </tr>
                                                                <tr className="table-danger">
                                                                    <th className="text-center">Destination</th>
                                                                    <th className="text-center">Hotel Names</th>
                                                                    <th className="text-center">Hotel Type</th>
                                                                    <th className="text-center">No of Nights</th>
                                                                    <th className="text-center">Meal Plan</th>
                                                                </tr>
                                                            </thead>
                                                            <tr>
                                                                <td data-title="Destination"><div style={{ display: 'inline-block' }}>Srinagar</div></td>
                                                                <td data-title="Hotel Names"><div style={{ display: 'inline-block' }}>Hotel Grand Fortune / Hotel Shefaf / Hotel Siddique Palace / Similar</div></td>
                                                                <td data-title="Hotel Type">3 Star
                                                                </td>
                                                                <td data-title="No of Nights"><div style={{ display: 'inline-block' }}>5N</div></td>
                                                                <td data-title="Meal Plan"><div style={{ display: 'inline-block' }}>Room with Breakfast & Dinner</div></td>
                                                            </tr>
                                                            <tr>
                                                                <td data-title="Destination"><div style={{ display: 'inline-block' }}>Gulmarg</div></td>
                                                                <td data-title="Hotel Names"><div style={{ display: 'inline-block' }}>Hotel Affarwat / Hotel Mama Palace /Gulmarg House / Similar
                                                                </div>
                                                                </td>
                                                                <td data-title="Hotel Type">3 Star
                                                                </td>
                                                                <td data-title="No of Nights"><div style={{ display: 'inline-block' }}>1N</div></td>
                                                                <td data-title="Meal Plan"><div style={{ display: 'inline-block' }}>Room with Breakfast & Dinner</div></td>
                                                            </tr>
                                                            <tr>
                                                                <td data-title="Destination"><div style={{ display: 'inline-block' }}>Pahalgam</div></td>
                                                                <td data-title="Hotel Names"><div style={{ display: 'inline-block' }}>Hotel Pine Palace Resort / Hotel Fifth Season / Hotel Moonview Resort /Similar
                                                                </div>
                                                                </td>
                                                                <td data-title="Hotel Type">3 Star
                                                                </td>
                                                                <td data-title="No of Nights"><div style={{ display: 'inline-block' }}>2N</div></td>
                                                                <td data-title="Meal Plan"><div style={{ display: 'inline-block' }}>Room with Breakfast & Dinner</div></td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={customActiveTab === 'tab4' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-sightseeing-tab">
                                                <div className="container-fluid px-0 py-1">
                                                    <h3>Sightseeing for <strong>Kashmir Tour Packages with Vaishno Devi</strong></h3>
                                                    <p>Kashmir, often referred to as "Paradise on Earth," is renowned for its breathtaking natural beauty, serene landscapes, and rich cultural heritage. Here are some of the most popular sightseeing destinations in Kashmir:</p><br />
                                                    <p><b style={{ fontSize: "1.3vw" }} className='content-desktop'>Dal Lake:</b>
                                                        <b style={{ fontSize: "3.5vw" }} className='content-mobile2'>Dal Lake:</b> This iconic lake in Srinagar is famous for its picturesque surroundings, houseboats, and Shikara rides. Visitors can explore the floating gardens, old Mughal gardens along the shores, and enjoy the stunning views of the surrounding mountains.</p><br />
                                                    <p><b className='tomato content-desktop' style={{ fontSize: "1.3vw" }}>Gulmarg:</b>
                                                        <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Gulmarg:</b> Known for its lush green meadows, Gulmarg is a popular hill station and skiing destination. Visitors can take the Gulmarg Gondola, one of the highest cable cars in the world, to reach Kongdori and enjoy panoramic views of the Himalayas.</p><br />
                                                    <p><b className='content-desktop' style={{ fontSize: "1.3vw" }}>Sonamarg:</b>
                                                        <b className='content-mobile2' style={{ fontSize: "3.5vw" }}>Sonamarg:</b>  Translating to "Meadow of Gold," Sonamarg is a scenic valley situated at an altitude of 2800 meters. It offers breathtaking views of snow-capped peaks, glaciers, and meadows. It's also the base for various trekking routes.</p><br />
                                                    <p><b className='tomato' style={{ fontSize: "1.3vw" }}>Pahalgam:</b> This quaint town is renowned for its pristine beauty, lush greenery, and the Lidder River. Pahalgam serves as a starting point for the annual Amarnath Yatra pilgrimage and offers opportunities for trekking, horse riding, and fishing.</p><br />
                                                    <p><b style={{ fontSize: "1.3vw" }}>Srinagar:</b> The summer capital of Jammu and Kashmir, Srinagar is famous for its Mughal gardens, including Shalimar Bagh, Nishat Bagh, and Chashme Shahi. Visitors can also explore the old city, visit the Jama Masjid, and indulge in shopping for traditional Kashmiri handicrafts in the local markets.</p><br />
                                                    <p><b className='tomato' style={{ fontSize: "1.3vw" }}>Pari Mahal:</b> Also known as the "Palace of Fairies," Pari Mahal is a historic monument located above the Chashme Shahi Gardens in Srinagar. It offers panoramic views of the city and Dal Lake and is surrounded by beautiful terraced gardens.</p><br />
                                                    <p><b style={{ fontSize: "1.3vw" }}>Betaab Valley:</b> Named after the Bollywood movie "Betaab," this picturesque valley is located near Pahalgam and offers stunning views of snow-capped mountains, lush greenery, and the Lidder River. It's a popular picnic spot and filming location.</p><br />
                                                    <p><b className='tomato' style={{ fontSize: "1.3vw" }}>Aru Valley:</b> Situated near Pahalgam, Aru Valley is known for its scenic beauty, alpine meadows, and trekking trails. It's a great place for nature lovers and adventure enthusiasts.</p><br />
                                                    <p><b style={{ fontSize: "1.3vw" }}>Mughal Road:</b> This historic road connects the Kashmir Valley with the regions of Poonch and Rajouri. It passes through picturesque landscapes, including dense forests, meadows, and small villages, offering a scenic drive.</p><br />
                                                    <p><b className='tomato' style={{ fontSize: "1.3vw" }}>Dachigam National Park:</b> Located near Srinagar, this national park is home to endangered species like the Kashmir stag (Hangul) and the Himalayan black bear. Visitors can enjoy wildlife safari tours and birdwatching in the park.</p><br />
                                                </div>
                                            </div>
                                            <div className={customActiveTab === 'tab5' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-meals-tab">
                                                <div className="overflow-table">
                                                    <h5 class="tomato">7 times Meals and 7 times Breakfast has been Provided</h5>
                                                    <table className="table table-bordered">
                                                        <tr className="table-danger">
                                                            <th colspan="6" className="text-center">Category</th>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-center">JAIN</td>
                                                            <td class="text-center">PURE-VEG</td>
                                                            <td class="text-center">VEGAN</td>
                                                            <td class="text-center">PURE-NON-VEG</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br /> <br /> <br />
                            {/* <!--By Itinerary--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>
                                                DAY WISE <strong><span>KASHMIR TOUR WITH VAISHNO DEVI</span></strong>
                                            </h2>
                                            <h4>7 Nights 8 Days <strong>Kashmir Tour Itinerary</strong></h4>
                                        </div>
                                    </div>
                                </div>


                                {/* <!--end Short description--> */}
                                <br />
                                <div className="row">
                                    <div
                                        className="accordion"
                                        id="accordionPanelsStayOpenExample"
                                    >
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day1"
                                            >
                                                <button
                                                    className="accordion-button"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseOne"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseOne"
                                                >
                                                    DAY 1  – ARRIVAL AND HOTEL CHECK-IN
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseOne"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingOne"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        We'll meet you at Jammu Railway Station and take you to Katra. After checking into the hotel, you can relax and explore the area on your own. <b>Overnight stay in Katra</b>.
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay1ImageURL} alt="Kashmir Offbeat Packages for Family" /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay1ImageURL} style={{ width: '320px' }} alt="Kashmir Offbeat Packages for Family" /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day2"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseTwo"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseTwo"
                                                >
                                                    DAY 02: VAISHNO DEVI VISIT
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseTwo"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingTwo"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        After breakfast and checking into the hotel, head to Vaishno Devi Temple. You can choose to take a helicopter to the temple (additional cost). Return to the hotel for an <b>overnight stay in Katra</b>.
                                                    </p>

                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay2ImageURL} alt="Kashmir Tour Package cost offbeat" /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay2ImageURL} style={{ width: '320px' }} alt="Kashmir Tour Package cost offbeat" /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day3"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseThree"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 03: KATRA TO PAHALGAM
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseThree"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingThree"
                                            >
                                                <div className="accordion-body">
                                                    <p>After breakfast, drive to Pahalgam (about 210 km, 5-6 hours). On the way, visit the saffron fields, Avantipur ruins, and Anantnag sulfur springs. Also see the Bat village and apple orchards. Pahalgam is known for its scenic beauty and is popular with the Indian film industry. Enjoy the natural beauty of the valley and relax by the Lidder River in the evening. Overnight stay in Pahalgam.</p>
                                                    <br />

                                                    <div className="content-desktop">
                                                        <center><img src={navDay3ImageURL} alt="Kashmir Tour Packages" /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay3ImageURL} style={{ width: '320px' }} alt="Kashmir Tour Packages" /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day4"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseFour"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    Day 04: - EXPLORING PAHALGAM
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseFour"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingFour"
                                            >
                                                <div className="accordion-body">
                                                    <p>After a relaxed breakfast, spend the day enjoying Pahalgam. You can visit:</p>
                                                    <p><b>•	Aru Valley:</b> Located around 12 km from Pahalgam, Aru Valley is known for its beautiful meadows, lakes, and mountains. It's a base camp for trekking to Kolahoi Glacier and Tarsar Lake. Activities here include fishing, hiking, and photography.</p>
                                                    <p><b>•	Betaab Valley:</b> About 15 km from Pahalgam, this valley is named after the film "Betaab" and is surrounded by lush meadows and snow-capped mountains. It's a popular spot for camping, trekking, and enjoying the clear, cool waters.</p>
                                                    <p><b>•	Chandanwari:</b> About 16 km from Pahalgam, it's the starting point for the Amarnath Yatra and a great spot for snow sledding. Taxis can be hired for a round trip. It's a fun place for snow fights, and you can enjoy snacks at nearby stalls.</p>
                                                    <p>Alternatively, you can hire ponies (pay directly) to visit Baisaran Valley, also known as Mini Switzerland. Return to the hotel in the evening for dinner and an overnight stay in Pahalgam.</p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay4ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay4ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day5"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseFive"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 05: PAHALGAM TO GULMARG
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseFive"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingFive"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        After breakfast, drive to Gulmarg (about 140 km, 3-4 hours). Gulmarg, known as the "Meadow of Flowers," is at an altitude of 8,700 feet. It’s famous for its ski resort and the world's highest 18-hole golf course. The area, once a favorite of Emperor Jehangir, is known for its beautiful wildflowers and stunning views.
                                                    </p>
                                                    <p>Upon arrival in Gulmarg, check into your hotel. You’ll have the rest of the day free to relax or explore.
                                                        You can take a Gondola ride up to the first or second phase. The Gulmarg Gondola is one of the highest cable cars in the world, reaching nearly 4,000 meters. It offers spectacular views and access to skiing areas. Advanced skiers can enjoy the slopes at Mount Affarwat, which also has beautiful meadows. Return to the hotel for an overnight stay in Gulmarg.
                                                    </p>

                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay5ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay5ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day6"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseSix"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 06: GULMARG TO SRINAGAR
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseSix"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingSix"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        After breakfast, drive to Srinagar (about 51 km, 1-2 hours). Spend the day exploring the city.
                                                    </p>
                                                    <p><b>•	Shankaracharya Temple:</b> Visit this historic temple, believed to be where the philosopher Shankaracharya stayed when he visited Kashmir over a thousand years ago.</p>
                                                    <p><b>Mughal Gardens:</b> In the afternoon, visit:</p>
                                                    <ul>
                                                        <li><b>Nishat Garden:</b> Known as the “Garden of Pleasure,” built in 1633 by Mughal King Asif Khan.</li><br />
                                                        <li><b>Shalimar Garden:</b> Called the “Garden of Love,” built in 1619 by Mughal King Jahangir for his wife Noor Jahan.</li><br />
                                                        <li><b>Chashmashahi:</b> The “Royal Spring” garden, loved by emperors and visitors.</li>
                                                    </ul>
                                                    <p>In the evening, enjoy a shikara ride on the famous Dal Lake. You can see floating vegetable gardens and spot various birds like kingfishers and kites. Return for an <b>overnight stay at a houseboat in Srinagar.</b></p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay6ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay6ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day7"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseSeven"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 07: SRINAGAR TO DOODHPATHRI AND BACK
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseSeven"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingSeven"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        After an early breakfast, take a day trip to Doodhpathri, also known as the "Valley of Milk." It's about 43 km from Srinagar, in the Budgam district.
                                                        According to legend, the famous Kashmiri saint Sheikh Noor Din Noorani once struck the ground here in search of water, and milk flowed out instead. He declared that the milk should be used for drinking only, and it magically turned into water. This gave the area its name, Doodhpathri. The meadows here are lush and green, with a stream that looks like milk from a distance and stays cold year-round. The area is beautiful with diverse wildflowers and is covered in snow during winter.Return to Srinagar in the evening and stay <b>overnight at a hotel</b>.

                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay7ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay7ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day8"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseTen"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 08:DEPARTURE FROM SRINAGAR
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseTen"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingTen"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        Your Kashmir tour ends today. Adorable Vacation thanks you for your visit and will ensure a timely transfer to the airport for your flight to your next destination.
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay8ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay8ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>FEATURES OF A KASHMIRI HOUSEBOAT: WHAT MAKES THEM UNIQUE?</b></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>FEATURES OF A KASHMIRI HOUSEBOAT: WHAT MAKES THEM UNIQUE?</b></h4>
                                </div>
                                <div className="card-body">
                                    <h5><b>Traditional Design and Decor</b></h5><br />
                                    <ul>
                                        <li><b>• Handcrafted Interiors</b>: Houseboats are beautifully decorated with intricate wood carvings and detailed designs.</li><br />
                                        <li><b>• Kashmiri Carpets and Furnishings</b>: They feature luxurious carpets and colourful textiles that add a traditional touch.</li><br />
                                        <li><b>• Decorative Elements</b>: The interiors often include local art and crafts, creating a unique atmosphere.</li>
                                    </ul><br />
                                    <h5><b>Floating Accommodation</b></h5><br />
                                    <ul>
                                        <li><b>• On the Lake</b>: Most houseboats are moored on scenic lakes like Dal Lake, offering stunning water views.</li><br />
                                        <li><b>• Unique Experience</b>: Staying on a houseboat gives you a special experience that's different from staying in regular hotels.</li>
                                    </ul><br />

                                    <h5><b>Modern Amenities</b></h5><br />
                                    <ul>
                                        <li><b>• Comfortable Bedrooms</b>: Houseboats have cosy bedrooms with comfortable beds and private bathrooms.</li><br />
                                        <li><b>• Living Areas</b>: They include spacious living rooms with comfy seating and sometimes a dining area.</li><br />
                                        <li><b>• Heating Facilities</b>: Many houseboats have heaters to keep you warm during the cold nights.</li>
                                    </ul><br />

                                    <h5><b>Private Decks and Gardens</b></h5><br />
                                    <ul>
                                        <li><b>• Open Decks</b>: Many houseboats have decks where you can relax and enjoy the beautiful views.</li><br />
                                        <li><b>• Small Gardens</b>: Some have small flower gardens or plants on the deck, adding to the charm.</li>
                                    </ul><br />

                                    <h5><b>Personalized Service</b></h5><br />
                                    <ul>
                                        <li><b>• Caring Hosts</b>: The owners often offer personal service, including local tour arrangements and home-cooked meals.</li><br />
                                        <li><b>• Local Cuisine</b>: You can enjoy traditional Kashmiri dishes prepared by the houseboat staff.</li>
                                    </ul><br />

                                    <h5><b>Scenic Views</b></h5><br />
                                    <ul>
                                        <li><b>• Lake Views</b>: You’ll wake up to amazing views of the lake, mountains, and nature.</li><br />
                                        <li><b>• Sunset and Sunrise</b>: The views of the sunset and sunrise over the lake are breath-taking.</li>
                                    </ul><br />

                                    <h5><b>Cultural Immersion</b></h5><br />
                                    <ul>
                                        <li><b>• Local Experience</b>: Staying on a houseboat lets you experience Kashmiri culture and hospitality first-hand.</li><br />
                                        <li><b>• Cultural Activities</b>: Some houseboats offer activities like traditional music performances or guided tours.</li>
                                    </ul><br />
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>WHAT ARE THE POPULAR TOURIST ATTRACTIONS IN GULMARG?</b></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>WHAT ARE THE POPULAR TOURIST ATTRACTIONS IN GULMARG?</b></h4>
                                </div>
                                <div className="card-body">
                                    <p>Gulmarg, a stunning hill station in Kashmir, boasts a range of captivating attractions that draw visitors from around the world. Here are some of the top spots to explore:</p>
                                    <h5><b>Gulmarg Gondola:</b></h5><br />
                                    <ul>
                                        <li><b>•	Overview:</b> One of the world's highest cable cars, the Gulmarg Gondola takes visitors up to Mount Apharwat.</li><br />
                                        <li><b>•	Highlights:</b> Enjoy breath-taking panoramic views of the Himalayas and the surrounding valley, especially during sunrise and sunset.</li>
                                    </ul><br />
                                    <h5><b>Mount Apharwat:</b></h5><br />
                                    <ul>
                                        <li><b>• Overview:</b> This snow-covered peak, accessible via the Gondola, is a highlight for visitors.</li><br />
                                        <li><b>• Highlights:</b> Popular for skiing and snowboarding in winter, and offers spectacular views of the mountain range.</li>
                                    </ul><br />
                                    <h5><b>St. Mary’s Church:</b></h5><br />
                                    <ul>
                                        <li><b>• Overview:</b> A historic church built in 1895, known for its Gothic architecture.</li><br />
                                        <li><b>• Highlights:</b> A serene location perfect for contemplation, offering picturesque views of the landscape.</li>
                                    </ul><br />
                                    <h5><b>Golf Course:</b></h5><br />
                                    <ul>
                                        <li><b>• Overview:</b> One of the highest golf courses globally, featuring an 18-hole course set in a beautiful natural setting.</li><br />
                                        <li><b>• Highlights:</b> Experience a unique round of golf with stunning mountain views and lush surroundings.</li>
                                    </ul><br />
                                    <h5><b>Betaab Valley:</b></h5><br />
                                    <ul>
                                        <li><b>• Overview:</b> Named after the Bollywood film "Betaab," this valley is renowned for its scenic beauty.</li><br />
                                        <li><b>• Highlights:</b> Ideal for picnics and leisurely strolls, with verdant meadows and crystal-clear streams.</li>
                                    </ul><br />
                                    <h5><b>Khilanmarg:</b></h5><br />
                                    <ul>
                                        <li><b>• Overview:</b> A charming meadow located near Gulmarg.</li><br />
                                        <li><b>• Highlights:</b> Known for its lush greenery and panoramic mountain views, perfect for day trips and light trekking.</li>
                                    </ul><br />
                                    <h5><b>Hari Parbat Fort:</b></h5><br />
                                    <ul>
                                        <li><b>• Overview:</b> An ancient fort situated on a hill with views extending to Gulmarg.</li><br />
                                        <li><b>• Highlights:</b> Offers historical insights and panoramic views of the region.</li>
                                    </ul><br />
                                    <h5><b>Alpathar Lake:</b></h5><br />
                                    <ul>
                                        <li><b>• Overview:</b> A serene high-altitude lake surrounded by snow-capped peaks.</li><br />
                                        <li><b>• Highlights:</b> A picturesque spot for trekking and enjoying the pristine natural beauty.</li>
                                    </ul><br />
                                    <h5><b>Shrine of Baba Reshi:</b></h5><br />
                                    <ul>
                                        <li><b>• Overview:</b> A revered shrine dedicated to a Sufi saint located on the outskirts of Gulmarg.</li><br />
                                        <li><b>• Highlights:</b> A spiritual site that provides a peaceful atmosphere and a place for reflection.</li>
                                    </ul><br />
                                    <h5><b>Mushroom-shaped Rocks:</b></h5><br />
                                    <ul>
                                        <li><b>• Overview:</b> Unique natural rock formations resembling mushrooms.</li><br />
                                        <li><b>• Highlights:</b> A popular spot for photography and exploration, showcasing natural geological wonders.</li>
                                    </ul>
                                    <p>Gulmarg’s diverse range of attractions ensures a memorable visit, <strong>Kashmir Trip</strong> offering everything from thrilling adventures and scenic landscapes to cultural and spiritual experiences.</p>
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>PAHALGAM ADVENTURES: DISCOVER NATURE, ADVENTURE, AND SPIRITUALITY IN KASHMIR</b></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>PAHALGAM ADVENTURES: DISCOVER NATURE, ADVENTURE, AND SPIRITUALITY IN KASHMIR</b></h4>
                                </div>
                                <div className="card-body">
                                    <p>Nestled in the stunning Kashmir Valley, Pahalgam stands out for its mesmerizing natural beauty, peaceful atmosphere, and diverse attractions that draw tourists from around the world. Here’s why Pahalgam is a favorite destination:</p>
                                    <p><b>Spectacular Scenery:</b> Pahalgam is renowned for its breath-taking landscapes, featuring snow-capped peaks, lush meadows, sparkling rivers, and dense pine forests. <strong>Kashmir Tour Itinerary</strong> This picturesque setting appeals to nature enthusiasts, photographers, and artists looking to capture its stunning views.</p>
                                    <p><b>Thrilling Adventures:</b> For adventure lovers, Pahalgam offers a range of exciting activities such as trekking, camping, horse riding, river rafting, and skiing. <strong>Kashmir Trip</strong> The rugged terrain and pristine wilderness provide an ideal backdrop for these exhilarating pursuits.</p>
                                    <p><b>Betaab Valley:</b> Famous for its appearance in the Bollywood film "Betaab," Betaab Valley is known for its vibrant greenery, clear streams, and panoramic mountain views. <strong>Kashmir Package Tour Operator</strong> It’s a perfect spot for leisurely walks, picnics, and horseback rides amidst its tranquil beauty.</p>
                                    <p><b>Aru Valley:</b> Aru Valley, with its untouched beauty and serene environment, offers a peaceful escape from urban life. Ideal for trekking, camping, and nature walks, Aru Valley is a haven for those seeking solitude and natural splendour.</p>
                                    <p><b>Lidder River:</b> Flowing through Pahalgam, the Lidder River is a focal point for scenic beauty and recreational activities. Visitors can enjoy trout fishing, riverside picnics, and peaceful strolls along its banks, surrounded by the soothing sounds of nature.</p>
                                    <p><b>Amarnath Yatra Base Camp:</b> Pahalgam is the starting point for the annual Amarnath Yatra pilgrimage to the sacred Amarnath Cave. <strong>Kashmir Package Tour with Vaishno Devi</strong> This spiritual journey adds a layer of cultural and religious significance to the town, drawing pilgrims and devotees from across the country.</p>
                                    <p><b>Golfing:</b> Home to one of the highest golf courses in the world, Pahalgam offers golf enthusiasts a unique experience. <strong>Kashmir Tour Packages with Vaishno Devi</strong> The course, set against a backdrop of lush greenery and snow-covered peaks, provides a challenging game in a stunning setting.</p>
                                    <p><b>Cultural Heritage:</b> Rich in cultural heritage, Pahalgam features vibrant festivals, traditional cuisine, and warm hospitality that reflect the region’s unique identity. <strong>Kashmir Tour Package with Vaishno Devi</strong> Visitors can immerse themselves in local culture and engage with the friendly residents, enriching their travel experience.</p>
                                    <p>For those seeking spiritual solace amid the natural beauty of Kashmir, combining a trip to Pahalgam with a visit to the Vaishno Devi shrine is a wonderful option. <strong>Kashmir tour packages</strong> that include Vaishno Devi offer a seamless blend of cultural exploration and natural wonders, creating a memorable and fulfilling experience. <strong>Kashmir with Vaishno Devi</strong> with customizable options, these <strong>Kashmir Package Tour Operator</strong> provide a convenient way to explore the enchanting landscapes of Kashmir while embarking on a spiritual journey.</p>
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>NAVIGATING THE SACRED PATHS: ROUTES TO REACH VAISHNO DEVI BHAWAN</b></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>NAVIGATING THE SACRED PATHS: ROUTES TO REACH VAISHNO DEVI BHAWAN</b></h4>
                                </div>
                                <div className="card-body">

                                    <p>For those embarking on a spiritual pilgrimage to the Vaishno Devi Jammu Kashmir Package, a revered shrine dedicated to Goddess Vaishno Devi, several routes offer unique experiences, each imbued with its own charm and spiritual significance. Here’s a detailed look at the various pathway’s pilgrims can take to reach this holy shrine, ensuring a fulfilling and serene journey.</p>
                                    <h5><b>The Traditional Trek - Katra to Bhawan:</b></h5><br />
                                    <ul>
                                        <li><b>• Starting Point:</b> Katra, the base camp for the Vaishno Devi pilgrimage, serves as the gateway to the divine ascent.</li><br />
                                        <li><b>• Distance:</b> Approximately 13 kilometers.</li><br />
                                        <li><b>• Highlights:</b> Pilgrims embark on a trek through the picturesque Trikuta Mountains, enjoying lush landscapes and tranquil surroundings along the way.</li><br />
                                        <li><b>• Duration:</b> The trek generally takes between 5 to 6 hours, depending on individual pace and weather conditions.</li><br />
                                    </ul>
                                    <br />
                                    <h5><b>Helicopter Ride - The Quick Ascent:</b></h5><br />
                                    <ul>
                                        <li><b>• Starting Point:</b> Helipads in Katra.</li><br />
                                        <li><b>• Distance:</b> Aerial route directly to Sanjichhat, close to the Bhawan.</li><br />
                                        <li><b>• Highlights:</b> Ideal for those seeking a swift and comfortable journey, the helicopter ride offers stunning aerial views of the mountainous terrain.</li><br />
                                        <li><b>• Duration:</b> The flight takes about 8 to 10 minutes, providing a fast and convenient way to reach the shrine.</li><br />
                                    </ul>
                                    <h5><b>Ardh Kuwari Route - The Midway Sanctuary:</b></h5><br />
                                    <ul>
                                        <li><b>• Starting Point:</b> Katra.</li><br />
                                        <li><b>• Distance:</b> Pilgrims first trek to Ardh Kuwari, a halfway point, before proceeding to the Bhawan.</li><br />
                                        <li><b>• Highlights:</b> Ardh Kuwari is a sacred cave where Goddess Vaishno Devi is believed to have meditated for nine months. Pilgrims often pause here for spiritual reflection.</li><br />
                                        <li><b>• Duration:</b> The trek to Ardh Kuwari takes roughly 4 to 5 hours, with an additional 3 to 4 hours needed to reach the Bhawan.</li><br />
                                    </ul>
                                    <h5><b>Banganga Route - The Riverside Journey:</b></h5><br />
                                    <ul>
                                        <li><b>• Starting Point:</b> Banganga, a holy stream near Katra.</li><br />
                                        <li><b>• Distance:</b> Pilgrims follow a route alongside the Banganga stream.</li><br />
                                        <li><b>• Highlights:</b> This path offers a serene, riverside experience, allowing pilgrims moments of peace and introspection.</li><br />
                                        <li><b>• Duration:</b> The trek from Banganga to the Bhawan typically takes 4 to 5 hours.</li><br />
                                    </ul>
                                    <h5><b>Sanjichhat Route - Cable Car and Scenic Views:</b></h5><br />
                                    <ul>
                                        <li><b>• Starting Point:</b> Sanjichhat, accessible by helicopter or on foot.</li><br />
                                        <li><b>• Distance:</b> Pilgrims can take a cable car from Sanjichhat to the Bhawan.</li><br />
                                        <li><b>• Highlights:</b> The cable car provides panoramic views of the surrounding mountains, offering a scenic and convenient alternative to the traditional trek.</li><br />
                                        <li><b>• Duration:</b> The cable car ride is a brief 5 to 7-minute journey.</li><br />
                                    </ul>
                                    <h5><b>Hathi-Matha Ascent - The Elephant’s Head Peak:</b></h5><br />
                                    <ul>
                                        <li><b>• Starting Point:</b> Katra.</li><br />
                                        <li><b>• Distance:</b> Pilgrims ascend the Hathi-Matha, a distinctive rock formation resembling an elephant’s head.</li><br />
                                        <li><b>• Highlights:</b> This route features unique natural formations and offers a distinctive pilgrimage experience.</li><br />
                                        <li><b>• Duration:</b> The trek takes about 5 to 6 hours, providing ample opportunity to appreciate the natural beauty and spiritual ambiance.</li><br />
                                    </ul>
                                    <br />
                                    <p>Embarking on the pilgrimage to <strong>Vaishno Devi and Kashmir Tour Package</strong> Bhawan through these diverse routes offers more than just a journey; it’s a profound spiritual experience. Whether you choose the traditional trek from Katra, the swift helicopter ride, or the peaceful riverside path from Banganga, each route provides its own significance and beauty. Our <strong>Kashmir package tour with Vaishno Devi</strong> ensures flexibility in choosing the route that best suits your spiritual journey. Book your pilgrimage today and let the sacred paths lead you to the divine presence of Goddess Vaishno Devi.</p>
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>THE SIGNIFICANCE OF THE VAISHNO DEVI YATRA: A SPIRITUAL JOURNEY</b></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>THE SIGNIFICANCE OF THE VAISHNO DEVI YATRA: A SPIRITUAL JOURNEY</b></h4>
                                </div>
                                <div className="card-body">
                                    <p>Embarking on the Kashmir Vaishno Devi Package Tour is more than just a pilgrimage; it is a profound spiritual journey that holds deep significance for millions of devotees. Nestled in the Trikuta Mountains of Jammu and Kashmir, the Vaishno Devi shrine is dedicated to Goddess Vaishno Devi, a revered manifestation of the divine feminine.</p>
                                    <p><b>Historical and Religious Significance:</b> The Vaishno Devi temple is steeped in ancient legend and religious importance. According to Hindu mythology, the goddess is believed to be a reincarnation of the three principal goddesses—Saraswati, Lakshmi, and Parvati. The shrine’s sacredness is rooted in the story of a devout princess who transformed into the goddess to protect and bless her devotees. This narrative, coupled with the temple’s history, has made Vaishno Devi one of the most revered pilgrimage destinations in India.</p>
                                    <p><b>Spiritual Fulfilment:</b> The journey to Kashmir with Vaishno Devi Tour is not just a physical trek but a spiritual quest. Pilgrims undertake the pilgrimage to seek divine blessings, fulfil vows, and find spiritual solace. The route, lined with scenic beauty and spiritual landmarks, provides a unique opportunity for reflection and meditation. As pilgrims traverse the paths leading to the shrine, they often experience a deep sense of connection and devotion, making the journey a transformative experience.</p>
                                    <p><b>Pilgrimage Experience:</b> The yatra begins from Katra, the base town, where devotees prepare for the trek to the shrine. The 13-kilometer trek through the Trikuta Mountains is both a physical challenge and a spiritual endeavour. The path is dotted with various sacred spots such as the Banganga and Ardh Kuwari caves, which hold special significance in the pilgrimage journey. Pilgrims often pause at these spots for prayer and contemplation, enhancing their spiritual experience.</p>
                                    <p><b>Cultural and Community Impact:</b> <strong>Kashmir Tour Package with Vaishno Devi</strong> is a vibrant reflection of India’s cultural and religious diversity. The pilgrimage attracts people from all walks of life, fostering a sense of unity and shared devotion. The influx of devotees also supports the local economy and promotes cultural exchange, enriching the region's social fabric.</p>
                                    <p><b>Personal Transformation:</b> Many devotees find the pilgrimage to <strong>Vaishno Devi and Kashmir Tour Package</strong> to be a deeply personal and transformative experience. The journey challenges physical endurance while offering spiritual rewards, leading to a renewed sense of faith and inner peace. For countless individuals, the yatra is a time of introspection, personal growth, and spiritual awakening.</p>
                                    <p>Vaishno Devi Yatra represents a sacred journey that transcends mere travel. It embodies a quest for spiritual enlightenment and divine connection. Whether undertaken for personal fulfilment, devotion, or cultural experience, the pilgrimage to Vaishno Devi offers a profound and enriching journey through faith, tradition, and self-discovery.</p>
                                </div>
                            </div>
                            <br /><br />
                            {/* <!--insert table inclusion exclusion--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        {
                                            <InclusionExclusion />
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* <!--end table inclusion exclusion--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center" >
                                            <h2>The Khyber Himalayan Resort & Spa <span>- Gulmarg</span></h2>
                                            <h4><i>Next Level Luxury Resort </i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row py-3">
                                    <img src={brandedTourackageImage_1_URL} alt="..." />
                                </div>

                                <div id="no-more-tables" >
                                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                        <thead>
                                            <tr className="table-danger">
                                                <th>Category</th>
                                                <th>Size(Room)</th>
                                                <th>Hotel Brand</th>
                                                <th>Price (Per Night)</th>
                                                <th>Book Now</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td data-title="Packages">Platinum Accommodation</td>
                                                <td data-title="Size(Room)">386+ SQ.FT</td>
                                                <td data-title="Hotel Brand">Khyber Hotels (Heritage)<div className="reviews">
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                </div></td>
                                                <td data-title="Price (Per Night)">Rs 53,119/- </td>
                                                <td data-title="Book Now"><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick}>
                                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                                </a></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                <div className="card mx-0 my-5">
                                    <div className="card-header custHeaer">
                                        <h3 className='content-desktop' style={{ fontSize: "1.5vw" }}><b>HEAVEN IS CLOSER THAN YOU CAN IMAGINE</b></h3>
                                        <h3 className='content-mobile2' style={{ fontSize: "3.5vw" }}><b>HEAVEN IS CLOSER THAN YOU CAN IMAGINE</b></h3>
                                    </div>
                                    <div className="card-body">
                                        <p ><b>8,825 feet</b> above sea level in the Pir Panjal range of the Himalayas lies a higher order of luxury. <b>The Khyber Himalayan Resort & Spa</b> is spread over seven unspoilt, fir-laden acres, with majestic views of the Affarwat Peaks. This 85-room resort is framed at every vantage point by nature at its most spectacular. <b>The world’s highest ski lift</b>, the Gulmarg Gondola – which takes you to Kongdoori Mountain, is only a short walk away. Meadows and forests that have enchanted travellers for centuries wait to be explored by trekking enthusiasts and undying romantics. Clad in locally sourced pine and slate, the resort is built in a style true to the architectural heritage of Kashmir. In every way the indoors mirror the beauty of the outdoors. Specialty restaurants, a cigar lounge are amongst the necessary luxuries in place. Add to these other thoughtful additions like our children’s club, a mini theatre and a dedicated Activities Concierge and you will find The Khyber is a resort at par with the best in the world.</p>

                                        <a href="tel://9883359713" className="btn btn-Warning">Book Now</a>

                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2 >The LaLiT Grand Place <span>- Srinagar</span></h2>
                                            <h4 ><i>Fill the Luxury</i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row py-3">
                                    <img src={brandedTourackageImage_2_URL} alt="..." />
                                </div>

                                <div id="no-more-tables" >
                                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                        <thead>
                                            <tr className="table-danger">
                                                <th>Category</th>
                                                <th>Size(Room)</th>
                                                <th>Hotel Brand</th>
                                                <th>Price (Per Night)</th>
                                                <th>Book Now</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td data-title="Packages">Premium Accommodation</td>
                                                <td data-title="Size(Room)">290+ SQ.FT</td>
                                                <td data-title="Hotel Brand">The LaLiT Hotels<div className="reviews">
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                </div></td>
                                                <td data-title="Price (Per Night)">Rs 28,332/- </td>
                                                <td data-title="Book Now"><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick}>
                                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                                </a></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                <div className="card mx-0 my-5">
                                    <div className="card-header custHeaer">
                                        <h3 className='content-desktop' style={{ fontSize: "1.5vw" }}><b>EMBARKED ON A SENSE OF PRIDE AT PREMIUMNESS</b></h3>
                                        <h3 className='content-mobile2' style={{ fontSize: "3.5vw" }}><b>EMBARKED ON A SENSE OF PRIDE AT PREMIUMNESS</b></h3>
                                    </div>
                                    <div className="card-body">
                                        <p ><b>The LaLit Grand Palace</b> was originally built in 1901 for the royal family. It now provides luxurious rooms with views of Dal Lake and the beautiful gardens. An indoor pool, tennis courts and pampering spa treatments are also available.The tastefully designed rooms are modern with a touch of traditional charm. All have a satellite TV, minibar and free tea/coffee making facilities. It is 18 km from Srinagar Airport and 4 km from Lal Chowk city centre. Jammu Railway Station is 300 km away. Guests can enjoy a relaxing massage at Rejuve Spa Health Club, practice yoga, or play golf. The hotel also has a gym and 24-hour business center. The Chinar Garden serves North Indian cuisine, while international buffet spreads are available at The Chinar – The All Day Coffee Shop. Other dining options include Dal Bar and 24-hour room service.</p>

                                        <a href="tel://9883359713" className="btn btn-Warning">Book Now</a>

                                    </div>
                                </div>
                            </div>

                            {/* <!--start of package details--> */}
                            <div className="container-fluid py-5">

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>Kashmir<span> Tour With Vaishno Devi</span></h2>
                                            <h4>Related Kashmir Tour plan</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                                        <div className="carousel-inner">
                                            <div className="carousel-item active">

                                                <Slider {...settings}>
                                                    {hotPackagesView}
                                                </Slider>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!--end of package details--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2 >Frequently Ask <span>Questions</span></h2>
                                            <h4 >Kashmir Offbeat Tour Package With Vaishno Devi Related Questions</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="row" >
                                    <div className="accordion" id="accordionPanelsStayOpenExample">
                                        <div className="accordion" id="accordionFlushExample">
                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingOne">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                                        What is the distance between Katra railway station and Vaishno Devi?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseOne" className="accordion-collapse collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        The distance from Katra railway station to the Vaishno Devi shrine is about 13.5 kilometers (around 8.4 miles). Katra is the base camp for pilgrims visiting the Vaishno Devi temple in the Trikuta Mountains of Jammu and Kashmir.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingTwo">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                                        What are some nearby attractions to visit along with Vaishno Devi?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseTwo" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <ul>
                                                            <li><b>Bhairavnath Temple:</b> About 2.5 kilometers from the Vaishno Devi shrine, this temple is dedicated to Bhairava, a form of Lord Shiva.</li>
                                                            <li><b>Ardhkuwari Temple:</b> Located halfway between Katra and the Vaishno Devi shrine, it’s where Mata Vaishno Devi is said to have meditated for nine months.</li>
                                                            <li><b>Shiv Khori:</b> A cave shrine in the Reasi district dedicated to Lord Shiva with a naturally formed Shivling.</li>
                                                            <li><b>Patnitop:</b> A hill station about 80 kilometers from Katra, offering beautiful views, trekking, and picnic spots.</li>
                                                            <li><b>Sanasar:</b> Near Patnitop, known for its green meadows, adventure activities like paragliding, and camping.</li>
                                                            <li><b>Jammu City:</b> Explore Raghunath Temple, Bahu Fort, Amar Mahal Palace, and the Dogra Art Museum.</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingThree">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                                        Are there any special pujas or ceremonies at Vaishno Devi?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseThree" className="accordion-collapse collapse show" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <ul>
                                                            <li><b>Aarti:</b> Performed during sunrise and sunset, involving hymns and offering lamps to the deity.</li>
                                                            <li><b>Bhaint:</b> Offerings of flowers, coconuts, and fruits made by devotees.</li>
                                                            <li><b>Havan/Yagna:</b> Ceremonies involving offerings into a sacred fire while chanting mantras.</li>
                                                            <li><b>Navratra Celebrations:</b> Special ceremonies during the Navratra festival, including bhajans, kirtans, and processions.</li>
                                                            <li><b>Charan Paduka Darshan:</b> Visiting the Charan Paduka, believed to be Mata Vaishno Devi's footprints, is considered auspicious.</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingFour">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                                        What is the significance of the Ardh Kuwari cave on the Vaishno Devi route?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseFour" className="accordion-collapse collapse show" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <ul>
                                                            <li><b>Legend:</b> Mata Vaishno Devi meditated in the Ardh Kuwari cave for nine months while evading the demon Bhairon Nath. The cave symbolizes her devotion and endurance.</li>
                                                            <li><b>Halfway Point:</b> Located halfway between Katra and the shrine, it’s a place where pilgrims stop to rest and seek blessings.</li>
                                                            <li><b>Spiritual Symbolism:</b> Climbing to the cave represents the devotee's determination to complete the pilgrimage.</li>
                                                            <li><b>Temple Inside:</b> A small temple dedicated to Mata Vaishno Devi is inside the cave where devotees offer prayers.</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingFive">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                                                        What is the significance of the Bhairon Temple at Vaishno Devi?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseFive" className="accordion-collapse collapse show" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <ul>
                                                            <li><b>Mythological Importance:</b> Bhairon Nath, a demon who pursued Mata Vaishno Devi, was granted forgiveness by her. Pilgrims must visit Bhairon Temple to complete their journey.</li>
                                                            <li><b>Completion of Pilgrimage:</b> Visiting Bhairon Temple is essential to complete the pilgrimage and seek blessings.</li>
                                                            <li><b>Location and Access:</b> The temple is about 2.5 kilometers from the main shrine, requiring an uphill trek.</li>
                                                            <li><b>Blessings:</b> It’s believed that seeking blessings at the Bhairon Temple brings protection, strength, and fulfillment of desires.</li>
                                                            <li><b>Rituals and Offerings:</b> Devotees offer flowers, sweets, and coconuts at the temple.</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingSix">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                                                        What is included in the Kashmir tour package with Vaishno Devi?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseSix" className="accordion-collapse collapse show" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        Kashmir with Vaishno Devi package includes 7 nights of accommodation, breakfast and dinner, transfers and sightseeing in non-AC cars, a 1-hour shikara ride on Dal Lake, inner line permits for restricted areas, and all hotel taxes, tolls.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingSeven">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
                                                        What is not included in the Kashmir Tour Package?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseSeven" className="accordion-collapse collapse show" aria-labelledby="flush-headingSeven" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        The package does not cover air or train tickets, pony rides or sledging (payable on the spot), tips for staff, entrance fees to monuments and gardens, non-veg items on the houseboat, and any other expenses not listed in the inclusions. Personal expenses like video camera fees, tips, and drinks are also not included.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center" >
                                            <h3><b>Kashmir <span class="tomato">Holiday Reviews</span></b></h3>
                                            <h4><i>"Your Experience is our Marketing"</i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-cols-1">
                                    <div id="testimonials">
                                        <div className="testimonial-box-container" >

                                            {reviewersDataView}

                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="col-md-3">
                            <BookingBox />
                        </div>
                    </div >
                </div >
                <div class="btn-call-pulse">
                    <a style={{ color: 'white' }} href="tel:+91-91470-61467">
                        <i className="fa fa-phone"></i>
                        {/* <span><small>+91 9147061467</small></span> */}
                    </a>
                </div>
                <div className="btn-whatsapp-pulse">
                    <a style={{ color: 'white' }} href="#" onClick={this.handleClick}>
                        <i className="fab fa-whatsapp"></i>
                        {/* <span>
                                <small>+91 9883359713</small>
                            </span> */}
                    </a>
                </div>

            </>
        );
    }
}

export default Kashmir_7N_8D_Vaishno_Devi_Package;