import React, {Component, Fragment} from 'react';
import FooterDesktop from '../components/common/FooterDesktop';
import MainHeader from '../components/common/MainHeader';
import Purchase from "../components/Others/Purchase";

class PurchasePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showMobileNavStatus: false
        }
    }

    componentDidMount() {
        window.scroll(0, 0);
        this.hideMobileNav();
    }

    showMobileNav = () => {
        this.setState({ showMobileNavStatus: true })
    }

    hideMobileNav = () => {
        this.setState({ showMobileNavStatus: false })
    }

    render() {
        return (
            <Fragment>
                <MainHeader />

                <Purchase/>

                <FooterDesktop />
            </Fragment>
        );
    }
}

export default PurchasePage;