import React, { Component } from 'react';
import BookingBox from '../BookingBox';
import Slider from "react-slick";
import ApiURL from "../../../api/ApiURL";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InclusionExclusion from '../Kashmir/InclusionExclusion_kashmir_gurez_valley';

// import FloatingWhatsAppButton from 'react-floating-whatsapp';

class Kashmir_7N_8D_Gurez_Valley_Package extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customActiveTab: 'tab1',
            daywiseActiveTab: 'day1'
        }
    }

    handleCustomTabClick = (tab) => {
        this.setState({
            customActiveTab: tab,
        });
    };

    handleDayWiseTabClick = (tab) => {
        this.setState({
            daywiseActiveTab: tab,
        });
    };
    handleClick = () => {
        const currentUrl = window.location.href;
        const pageTitle = document.title;
        const message = `Hi, Please send about the Tour Package details "${pageTitle}"`;

        const whatsappLink = `https://api.whatsapp.com/send?phone=+919147061467&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
        window.open(whatsappLink, '_blank');
    };

    render() {
        const { customActiveTab, daywiseActiveTab } = this.state;
        const packageTopBannerImageURL = ApiURL.Kashmir_Tour_Package_with_Gurez_Valley + "/Kashmir-Tour-Package-with-Gurez-Valley.webp";
        const packageTopBanner2ImageURL = ApiURL.Kashmir_Tour_Package_with_Gurez_Valley + "/Kashmir-Package-Tour-with-Gurez-Valley.webp";
        const sightseen1ImageURL = ApiURL.Kashmir_Tour_Package_with_Gurez_Valley + "/Kashmir-Package-Gureaz-valley.webp";
        const sightseen2ImageURL = ApiURL.Kashmir_Tour_Package_with_Gurez_Valley + "/Kashmir-Package-Trip-with-Gurez-Valley.webp";
        const sightseen3ImageURL = ApiURL.Kashmir_Tour_Package_with_Gurez_Valley + "/Kashmir-Tour-Packages-with-Gurez-valley.webp";
        const sightseen4ImageURL = ApiURL.Kashmir_Tour_Package_with_Gurez_Valley + "/Kashmir-Tour-Package.webp";
        const sightseen5ImageURL = ApiURL.Kashmir_Tour_Package_with_Gurez_Valley + "/Kashmir-Offbeat-Family-Packages.webp";
        const sightseen6ImageURL = ApiURL.Kashmir_Tour_Package_with_Gurez_Valley + "/kashmir-tour-packages-cost-with-Gurez-Valley.webp";
        const sightseen7ImageURL = ApiURL.Kashmir_Tour_Package_with_Gurez_Valley + "/Kashmir-Tour-Packages-with-Yusmarg.webp";
        const sightseen8ImageURL = ApiURL.Kashmir_Tour_Package_with_Gurez_Valley + "/Doodhpathri-Kashmir-Package.webp";
        const sightseen9ImageURL = ApiURL.Kashmir_Tour_Package_with_Gurez_Valley + "/Kashmir-Offbeat-Package.webp";
        const sightseen10ImageURL = ApiURL.Kashmir_Tour_Package_with_Gurez_Valley + "/Kashmir-Tours-Plan.webp";
        const sightseen11ImageURL = ApiURL.Kashmir_Tour_Package_with_Gurez_Valley + "/Offbeat-Kashmir-Tours-with-Gurez-Valley.webp";
        const sightseen12ImageURL = ApiURL.Kashmir_Tour_Package_with_Gurez_Valley + "/Kashmir-offbeat-package-tours-with-Gurez-Valley.webp";
        //day wise itenaryy
        const navDay1ImageURL = ApiURL.Kashmir_Tour_Package_with_Gurez_Valley + "/Kashmir-Packages-Tour-Cost-with-Gurez-Valley.webp";
        const navDay2ImageURL = ApiURL.Kashmir_Tour_Package_with_Gurez_Valley + "/Offbeat-Kashmir-Tour-with-Gurez-Valley.webp";
        const navDay3ImageURL = ApiURL.Kashmir_Tour_Package_with_Gurez_Valley + "/Kashmir-Tour-Packages.webp";
        const navDay4ImageURL = ApiURL.Kashmir_Tour_Package_with_Gurez_Valley + "/Kashmir-Offbeat-Packages-for-family.webp";
        const navDay5ImageURL = ApiURL.Kashmir_Tour_Package_with_Gurez_Valley + "/Kashmir-Trip.webp";
        const navDay6ImageURL = ApiURL.Kashmir_Tour_Package_with_Gurez_Valley + "/Kashmir-Offbeat-Holiday-Package.webp";
        const navDay7ImageURL = ApiURL.Kashmir_Tour_Package_with_Gurez_Valley + "/Kashmir-offbeat-package-tour-with-Gurez-Valley.webp";
        const navDay8ImageURL = ApiURL.Kashmir_Tour_Package_with_Gurez_Valley + "/Kashmir-Tour-Plan.webp";
        const Topbanner0ImageURL = ApiURL.Kashmir_Tour_Package_with_Gurez_Valley + "/Kashmir-Houseboat-Packages.webp";
        const Topbanner1ImageURL = ApiURL.Kashmir_Tour_Package_with_Gurez_Valley + "/Kashmir-Adventure-Tour-Plan.webp";
        const Topbanner2ImageURL = ApiURL.Kashmir_Tour_Package_with_Gurez_Valley + "/Kashmir-Gondola-Package-Tour.webp";
        const Topbanner3ImageURL = ApiURL.Kashmir_Tour_Package_with_Gurez_Valley + "/Kashmir-Trekking-Packages.webp";
        const Topbanner4ImageURL = ApiURL.Kashmir_Tour_Package_with_Gurez_Valley + "/Kashmir-White-River-Rafting-Plan.webp";
        const brandedTourackageImage_1_URL = ApiURL.Kashmir_Tour_Package_with_Gurez_Valley + "/The-Khyber-Himalayan-Resort-&-Spa.webp";
        const brandedTourackageImage_2_URL = ApiURL.Kashmir_Tour_Package_with_Gurez_Valley + "/The-LaLiT-Grand-Palace-Srinagar-Hotel.webp";
        const TrendingPackagesDataList = this.props.TrendingPackagesData;
        const hotPackagesView = TrendingPackagesDataList.length > 0 ?
            (
                TrendingPackagesDataList.map((dataItem, i) => {
                    const hotelImgURL = ApiURL.Kashmir_Tour_Package_with_Gurez_Valley + "/" + dataItem.ImageName;
                    return (<div className="col-md-12 p-0" key={i}>
                        <div className="card h-100">
                            <img src={hotelImgURL} className="card-img-top" alt={dataItem.AllternateText} />
                            <div className="card-body">
                                <h6 className="card-title">{dataItem.Title} <span> &#8377; {dataItem.PricePerNight}</span></h6>
                                <p className="card-text">
                                    <i className="fa fa-building tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-utensils tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-car-side tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-male tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-camera tomato"></i>
                                </p>
                                <p className="card-text"><span>{dataItem.Description}</span></p>
                                <p>
                                    <span><b className="tomato">{dataItem.NoOfTours} </b>Tours</span> | &nbsp;
                                    <span><b className="tomato">{dataItem.NoOfDeparture} </b>Departures</span> | &nbsp;
                                    <span><b className="sdf-blue"> {dataItem.Include}</b></span>
                                </p>
                            </div>
                            <div className="card-footer">
                                <small className="text-muted"><strong className="tomato">{dataItem.MutedText}</strong></small>
                                <a href="#" className="btn btn-success btn-sm float-end" onClick={this.handleClick}>
                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                </a>
                            </div>
                        </div>
                    </div>)
                })
            ) : (<div></div>)


        const settings = {
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const reviewersDataList = this.props.ReviewersData;
        const reviewersDataView = reviewersDataList.map((dataItem, i) => {
            const reviewerImageURL = ApiURL.Kashmir_Tour_Package_with_Gurez_Valley + "/" + dataItem.ImageName;
            return (
                <div className="testimonial-box" key={i}>
                    <div className="box-top">
                        <div className="profile">
                            <div className="profile-img">
                                <img src={reviewerImageURL} alt="..." />
                            </div>
                            <div className="name-user">
                                <strong>{dataItem.Name}</strong>
                                <span>{dataItem.Location}</span>
                            </div>
                        </div>
                        <div className="reviews">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </div>
                    </div>
                    <div className="client-comment">
                        <p>{dataItem.Comment}</p>
                    </div>
                </div>
            )
        })

        return (
            <>

                <div className="container-fluid">
                    <div className="row">
                        <img src={packageTopBannerImageURL} className="mobile-image2" alt="..." />
                    </div>
                </div>
                <div className="container-fluid custom-container py-3">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="container-fluid custom-container py-1">

                                        <h1 className="content-desktop" style={{ fontSize: "2vw" }}><center><strong>Kashmir Tour Packages with Gurez Valley </strong></center></h1>

                                        <h1 className="content-mobile" style={{ fontSize: "4vw" }}><center><strong>Kashmir Tour Packages with Gurez Valley </strong></center>
                                            <hr />
                                        </h1>
                                        <div className="row">
                                            <img src={packageTopBanner2ImageURL} className="mobile-image" alt="..." />
                                        </div>
                                        <hr />
                                        <div className="d-flex flex-column flex-sm-row align-items-sm-center px-2">
                                            <div className="font-lg">
                                                3 Destination:{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Srinagar (2N)
                                                </a>
                                                &nbsp;{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Gulmarg (1N)
                                                </a>{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Pahalgam (2N)
                                                </a>
                                                {" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Gurez valley (2N)
                                                </a>
                                            </div>
                                            <div className="reviews mx-5">
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                            </div>
                                        </div>
                                        <p className="p-2">
                                            If you are looking for a memorable <strong>Kashmir Tour Packages with Gurez Valley</strong>, you have come to the right place!
                                        </p>
                                        <hr />
                                        <br />
                                    </div>
                                    <h2 className="content-desktop p-2" style={{ fontSize: "1.6vw" }}> Looking for <strong> Kashmir Package Tour with Gurez Valley in 2024? ?</strong> For Kashmir Package Tour with Gurez Valley <a href='tel:9147061467' class="tomato">☎ CALL Mr. Soumya +91- 91470-61467</a></h2>
                                    <h2 className="content-mobile p-4" style={{ fontSize: "4.5vw" }}> Looking for <strong> Kashmir Package Tour with Gurez Valley in 2024? ?</strong> For Kashmir Package Tour with Gurez Valley <a href='tel:9147061467' class="tomato">☎ CALL Mr. Soumya +91- 91470-61467</a></h2>
                                    <br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> Experience the enchantment of Kashmir with our exclusive tour package with Gurez Valley</h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}> Experience the enchantment of Kashmir with our exclusive tour package with Gurez Valley</h4>
                                        </div>
                                        <div className="card-body">
                                            <p>Adorable Vacation is proud to offer <strong>offbeat Kashmir tour packages</strong> that highlight the stunning and lesser-known Gurez Valley. Nestled in the northern part of Kashmir, Gurez Valley is a hidden gem, offering breath-taking landscapes, rich culture, and serene beauty.<strong>Kashmir Tour package</strong> is designed for travellers seeking an immersive experience away from the usual tourist trails, providing an authentic glimpse into the untouched beauty of the region.</p>
                                            <p>Kashmir, often called "Paradise on Earth," is a region of unparalleled beauty, known for its stunning landscapes, serene lakes, and vibrant culture. Beyond the well-trodden paths of Srinagar and Gulmarg, we offer a treasure trove of <strong>Offbeat KashmirTour with Gurez Valley</strong> that captivate travellers seeking unique experiences. From the enchanting Gurez Valley, with its untouched natural beauty and rich cultural heritage, to the serene meadows of Lolab Valley and the remote charm of Bangus Valley, these lesser-known gems provide an intimate glimpse into the heart of Kashmir. <strong>Kashmir Offbeat Packages for family</strong> locations offer a blend of adventure, tranquillity, and cultural immersion, making Kashmir tourism a perfect choice for those looking to explore beyond the conventional.</p>
                                            <p>Gurez Valley, in particular, stands out as one of the most pristine and unexplored areas in Kashmir. Kashmir Tour Surrounded by snow-capped mountains and dotted with charming wooden houses, the valley is a haven for nature lovers and adventure enthusiasts. <strong>Kashmir Tour with Gurez Valley</strong> the Kishanganga River flows through the valley, adding to its picturesque charm. Gurez is also home to the Dard-Shin community, known for their unique culture and traditions. <strong>Kashmir Tour Plan</strong> Visitors can enjoy activities like trekking, fishing, and exploring the local villages, providing a truly offbeat and enriching travel experience.</p>
                                            <p>Adorable Vacation's <strong>offbeat Kashmir tour packages with Gurez Valley</strong>, are thoughtfully curated to offer travellers a unique and unforgettable journey. With comfortable accommodations, guided tours, and personalized itineraries, the company ensures that guests experience the best of what <strong>Kashmir Offbeat Gurez Valley Tour</strong> have to offer. Whether you're seeking adventure, tranquillity, or a cultural immersion, these tour packages provide an opportunity to explore the lesser-known facets of <strong>Kashmir offbeat package tour with Gurez Valley</strong>.</p>
                                            <table className="image-table">
                                                <tbody>
                                                    <tr>
                                                        <td className="image-cell">
                                                            <img src={sightseen1ImageURL} alt="kashmir tour packages" />
                                                            <img src={sightseen2ImageURL} alt="kashmir trip from srinagar airport" />
                                                            <img src={sightseen3ImageURL} alt="kashmir package tour cost from srinagar airport" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <br />
                                        </div>
                                    </div>
                                    <br /> <br /> <br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>KASHMIR TOUR PACKAGE INCLUDING GUREZ VALLEY DURATION : 7N | 8D</strong></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>KASHMIR TOUR PACKAGE INCLUDING GUREZ VALLEY DURATION : 7N | 8D</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <p>Places to cover in <strong>Offbeat Kashmir Tour </strong> : Srinagar HouseBoat 1N | Pahalgam 2N | Gulmarg 1N | Gurez Valley 2N | Srinagar 1N</p>
                                            <p>Pickup & Drop Point for <b>Kashmir Tour Packages</b> : Sheikh Ul Alam International Airport Srinagar [SXR]
                                            </p>
                                            <p><b>Address :</b> Indra Gandhi Road, Humhama, Badgam, Srinagar, Jammu and Kashmir 190007</p>
                                            <br /><br /><br />
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <tbody>
                                                        <tr>
                                                            <td><strong>Kashmir Offbeat Package Tour</strong> Pickup & Drop	</td>
                                                            <td>Srinagar Airport [SXR]</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Kolkata to Kashmir Package Duration</strong></td>
                                                            <td>08 Nights | 09 Days</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Kashmir Tour With Gurez Valley Package Cost </strong></td>
                                                            <td>Rs. 28,100/Based on 8 Pax</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Srinagar</strong></td>
                                                            <td>1,585 m | Coordinates - 34.0837° N, 74.7973° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Gulmarg</strong></td>
                                                            <td>2,650 m | Coordinates - 34.0484° N, 74.3805° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Sonmarg</strong></td>
                                                            <td>2,730 m | Coordinates - 34.3032° N, 75.2931° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Pahalgam</strong></td>
                                                            <td>2,740 m | Coordinates - 34.0161° N, 75.3150° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Yusmarg</strong></td>
                                                            <td>2,396 m | Coordinates - 33.8316° N, 74.6644° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Doodhpathri</strong></td>
                                                            <td>2,730 m | Coordinates - 33.5423° N, 74.3615° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Betaab Valley</strong></td>
                                                            <td>2,393 m | Coordinates - 34.0541° N, 75.3639° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Aru Valley</strong></td>
                                                            <td>2,414 m | Coordinates - 34.0922° N, 75.2632° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Chandanwadi</strong></td>
                                                            <td>3,288 m | Coordinates - 34.2122° N, 73.9295° E</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <br /><br /><br />
                                            <table className="image-table">
                                                <tbody>
                                                    <tr>
                                                        <td className="image-cell">
                                                            <img src={sightseen4ImageURL} alt="kashmir package tour from srinagar" />
                                                            <img src={sightseen5ImageURL} alt="kashmir package price from srinagar airport" />
                                                            <img src={sightseen6ImageURL} alt="kashmir packages from srinagar airport" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <br />

                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    {/* start short itinerary */}

                                    <div className="card">
                                        <div className="card-body">
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr className="table-danger">
                                                        <th>DAY WISE SUMMERY FOR KASHMIR TOUR ITINERARY</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingOne"
                                                        >
                                                            <a href="#day1" style={{ color: 'black' }}><b>DAY 1 → </b>
                                                                <span>
                                                                    ARRIVAL AT SRINAGAR, LOCAL SIGHTSEEING, OVERNIGHT STAY AT HOUSEBOAT
                                                                </span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingTwo"
                                                        >
                                                            <a href="#day2" style={{ color: 'black' }}><b>DAY 2 → </b>
                                                                <span>SRINAGAR TO PAHALGAM FULL DAY EXCURSION & OVER NIGHT STAY AT HOTEL</span>
                                                            </a>

                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingThree"
                                                        >
                                                            <a href="#day3" style={{ color: 'black' }}><b>DAY 3 → </b>
                                                                <span>PAHALGAM SIGHTSEEING [BETAB + ARU VALLEY & CHANDANWADI] & NIGHT STAY</span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingFour"
                                                        >
                                                            <a href="#day4" style={{ color: 'black' }}><b>DAY 4 → </b>
                                                                <span>PAHALGAM TO GULMARG EXCURSION, OVER NIGHT STAY AT HOTEL</span>
                                                            </a>

                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingFive"
                                                        >
                                                            <a href="#day5" style={{ color: 'black' }}><b>DAY 5 → </b>
                                                                <span>GULMARG TO GUREZ VALLEY TRIP & NIGHT STAY AT GUREZ VALLEY</span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingSix"
                                                        >
                                                            <a href="#day6" style={{ color: 'black' }}><b>DAY 6 → </b>
                                                                <span>GUREZ VALLEY SIGHTSEEING & NIGHT STAY AT GUREZ VALLEY</span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingSeven"
                                                        >
                                                            <a href="#day7" style={{ color: 'black' }}><b>DAY 7 → </b>
                                                                <span>GUREZ VALLEY TO SRINAGAR & OVER NIGHT STAY AT HOTEL </span>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingEight"
                                                        >
                                                            <a href="#day8" style={{ color: 'black' }}><b>DAY 8 → </b>
                                                                <span>DEPARTURE FROM SRINAGAR AIRPORT </span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    {/* <!--end Short description--> */}
                                    <br /><br /><br />

                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>TOP ATTRACTIONS TO VISIT ON A KASHMIR TOUR WITH GUREZ VALLEY</h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>TOP ATTRACTIONS TO VISIT ON A KASHMIR TOUR WITH GUREZ VALLEY</h4>
                                        </div>
                                        <div className="card-body">

                                            <p>A <strong>Kashmir Package Tour with Gurez Valley</strong> offers a unique blend of natural beauty, cultural experiences, and adventure. Here are some of the top attractions you can explore:</p>
                                            <br />
                                            <h5><b>Kishanganga River</b></h5>

                                            <p>The pristine waters of the Kishanganga River flow through Gurez Valley, offering opportunities for trout fishing, riverside picnics, and stunning views of the surrounding mountains.</p>
                                            <br />
                                            <h5><b>Habba Khatoon Peak</b></h5>
                                            <p>Named after the famous Kashmiri poetess Habba Khatoon, this distinctive pyramid-shaped peak is a significant landmark in the valley. It offers beautiful scenery and is a popular spot for trekking and photography.</p>
                                            <br />
                                            <h5><b>Dawar Village</b></h5>
                                            <p>The main town of Gurez Valley, Dawar, is known for its charming wooden houses and friendly locals. It's an excellent place to experience the culture and traditions of the Dard-Shin community.</p>
                                            <br />
                                            <h5><b>Razdan Pass</b></h5>
                                            <p>At an elevation of approximately 11,672 feet, Razdan Pass offers breathtaking panoramic views of the valley and the surrounding mountains. The pass serves as a gateway to Gurez and is especially stunning during the summer months when the meadows are in full bloom.</p>
                                            <br />

                                            <h5><b>Tulail Valley</b></h5>
                                            <p>A scenic sub-valley of Gurez, Tulail is renowned for its lush green landscapes, crystal-clear streams, and quaint villages. It's an ideal destination for trekking and experiencing the unspoiled beauty of nature.</p>
                                            <br />

                                            <h5><b>Chakwali Village</b></h5>
                                            <p>Located near the Line of Control, Chakwali Village offers a glimpse into the remote and untouched beauty of Gurez. The village is known for its traditional wooden architecture and serene environment.</p>
                                            <br />

                                            <h5><b>Historic Fort of Gurez</b></h5>
                                            <p>The remains of an ancient fort, believed to have been constructed during the Dard dynasty, offer a fascinating insight into the valley's history. The fort provides a panoramic view of the surrounding area.</p>
                                            <br />

                                            <h5><b>Flora and Fauna</b></h5>
                                            <p>Gurez Valley is home to diverse flora and fauna, including rare Himalayan species. During the summer, the valley's meadows are blanketed in wildflowers, making it a paradise for nature lovers and photographers.</p>
                                            <br />
                                            <p>These attractions, along with the valley's peaceful ambiance and hospitable people, make Gurez Valley a must-visit destination for those looking to explore the lesser-known beauty of Kashmir.</p>
                                            <table className="image-table">
                                                <tbody>
                                                    <tr>
                                                        <td className="image-cell">
                                                            <img src={sightseen10ImageURL} alt="kashmir package tour booking from srinagar" />
                                                            <img src={sightseen11ImageURL} alt="kashmir package tour booking from srinagar airport" />
                                                            <img src={sightseen12ImageURL} alt="kashmir package tour cost from srinagar" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <br />
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>COST OF<strong> KASHMIR PACKAGE TOUR WITH GUREZ VALLEY</strong></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>COST OF <strong>KASHMIR PACKAGE TOUR WITH GUREZ VALLEY</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <thead className="cf">
                                                        <tr className="table-danger">
                                                            <th>TOUR DURATION</th>
                                                            <th>2 PAX</th>
                                                            <th>4 PAX</th>
                                                            <th>6 PAX</th>
                                                            <th>8 PAX</th>
                                                            <th>BOOK NOW</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td data-title="TOUR DURATION">4N/5D</td>
                                                            <td data-title="2 ADULTS">₹ 20,500/-</td>
                                                            <td data-title="4 ADULTS">₹ 18,300/-</td>
                                                            <td data-title="6 ADULTS">₹ 16,600/-</td>
                                                            <td data-title="8 ADULTS">₹ 16,400/-</td>
                                                            <td data-title="Book Now"><a href="#" class="btn btn-success" onClick={this.handleClick}>Book Now</a></td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="TOUR DURATION">5N/6D</td>
                                                            <td data-title="2 ADULTS">₹ 24,700/-</td>
                                                            <td data-title="4 ADULTS">₹ 22,000/-</td>
                                                            <td data-title="6 ADULTS">₹ 19,800/-</td>
                                                            <td data-title="8 ADULTS">₹ 20,800/-</td>
                                                            <td data-title="Book Now"><a href="#" class="btn btn-success" onClick={this.handleClick}>Book Now</a></td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="TOUR DURATION">6N/7D</td>
                                                            <td data-title="2 ADULTS">₹ 30,900/-</td>
                                                            <td data-title="4 ADULTS">₹ 27,400/-</td>
                                                            <td data-title="6 ADULTS">₹ 24,700/-</td>
                                                            <td data-title="8 ADULTS">₹ 24,500/-</td>
                                                            <td data-title="Book Now"><a href="#" class="btn btn-success" onClick={this.handleClick}>Book Now</a></td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="TOUR DURATION">7N/8D</td>
                                                            <td data-title="2 ADULTS">₹ 35,600/-</td>
                                                            <td data-title="4 ADULTS">₹ 30,800/-</td>
                                                            <td data-title="6 ADULTS">₹ 28,300/-</td>
                                                            <td data-title="8 ADULTS">₹ 28,100/-</td>
                                                            <td data-title="Book Now"><a href="#" class="btn btn-success" onClick={this.handleClick}>Book Now</a></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <br /><br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>WHAT ARE THE CULTURAL HIGHLIGHTS OF GUREZ VALLEY ? </h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>WHAT ARE THE CULTURAL HIGHLIGHTS OF GUREZ VALLEY ? </h4>
                                        </div>
                                        <div className="card-body">
                                            <p>Gurez Valley, a picturesque and remote region in Kashmir, is not only known for its natural beauty but also for its rich cultural heritage. Here are some of the cultural highlights of Gurez Valley for <strong>Kashmir offbeat package tour with Gurez Valley</strong>:</p>
                                            <br />
                                            <h5><b>Dard-Shin Community</b></h5>
                                            <p>The valley is predominantly inhabited by the Dard-Shin people, an ancient community with a distinct cultural identity. They are known for their unique language, Shina, which is distinct from the Kashmiri spoken in the rest of the valley. The Dard-Shin people have a rich oral tradition, including folk songs and stories that have been passed down through generations.</p><br />
                                            <h5><b>Traditional Wooden Architecture</b></h5><br />
                                            <p>The architecture in Gurez Valley is characterized by traditional wooden houses, known as "Dhoks" or "Kotha," which are built to withstand the harsh winter climate. These houses often have intricate wood carvings and are a testament to the local craftsmanship.</p><br />
                                            <h5><b>Folk Music and Dance</b></h5><br />
                                            <p>Folk music and dance play an integral role in the cultural life of the valley. Traditional musical instruments like the rubab and dhol are used in local music. The residents celebrate festivals and weddings with vibrant folk dances and songs, which reflect the local folklore and history.</p><br />
                                            <h5><b>Local Festivals and Customs</b></h5><br />
                                            <p>The people of Gurez celebrate several local festivals, which are often tied to the agricultural calendar. These festivals include communal feasts, traditional music, and dance performances. The unique customs and rituals observed during these festivals offer a glimpse into the valley's rich cultural tapestry.</p><br />
                                            <h5><b>Traditional Attire</b></h5><br />
                                            <p>The traditional attire of the Dard-Shin community includes colorful woolen garments suited to the cold climate. Women often wear vibrant headscarves and jewelry, while men wear a traditional cap called "Pakol." The attire is both practical and a significant part of their cultural identity.</p><br />
                                            <h5><b>Local Cuisine</b></h5><br />
                                            <p>The cuisine of Gurez Valley is simple yet flavorful, with an emphasis on locally available ingredients. Dishes often include rice, lentils, and meat, flavored with regional spices. The use of dried vegetables and sun-dried meat is common due to the valley's long winters. The food reflects the valley's agrarian lifestyle and the influence of Central Asian culinary traditions.</p><br />
                                            <h5><b>Cultural Heritage Sites</b></h5><br />
                                            <p>The valley is home to several cultural heritage sites, including ancient forts and ruins that date back to the Dard dynasty. These historical landmarks provide a connection to the valley's past and are a source of pride for the local community.</p><br />
                                            <h5><b>Hospitality and Way of Life</b></h5><br />
                                            <p>The people of Gurez Valley are known for their warm hospitality. Visitors often experience the traditional way of life, which includes farming, animal husbandry, and handicrafts. The community's close-knit social structure and their deep respect for nature are integral aspects of their culture.</p>
                                            <p>The cultural richness of Gurez Valley, with its unique language, traditions, and customs, makes it a fascinating destination for those interested in exploring the diverse cultural heritage of <strong>Kashmir Tour with Gurez Valley</strong>.</p>
                                        </div>
                                    </div>
                                    <br /><br />

                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>FAMOUS LAKES TO EXPLORE DURING <strong>KASHMIR TOUR PACKAGES WITH GurezValley</strong></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>FAMOUS LAKES TO EXPLORE DURING <strong>KASHMIR TOUR PACKAGES WITH GurezValley</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <p>If you're embarking on a <strong>Kashmir Package Tour with Gurez Valley</strong>, you absolutely must explore the region’s stunning lakes. Kashmir is renowned for its breath-taking lakes surrounded by snow-capped mountains, Chinar trees, pine forests, and vibrant gardens full of blooming tulips and roses. Here are some of the most famous lakes to include in your <strong>Offbeat kashmir Tour with Gurez Valley</strong>.</p>
                                            <p><b>Dal Lake</b> is undoubtedly the most popular destination on a <strong>Kashmir Tour Packages with Gurez Valley</strong>. Spanning around 22 square kilometres, this picturesque lake is famous for its colourful Shikaras (traditional boats), houseboats, and floating markets. A Shikara ride on Dal Lake is an essential part of any <strong>Offbeat Kashmir Tour</strong>. You must also visit Char Chinar, a beautiful island in the middle of the lake with four Chinar trees at its corners. Depending on the season, Char Chinar provides stunning views of the lake and its surroundings. Dal Lake has been featured in many Bollywood movies, making it a quintessential part of any <strong>Kashmir Package Tour</strong>. Floating on a Shikara, enjoying hot noon Chai (also known as Shir Chai or Gulabi Chai), and sampling authentic Kashmiri cuisine makes for a truly memorable experience. Staying in a houseboat on the tranquil waters of Dal Lake adds to its romantic appeal.
                                            </p>
                                            <p><b>Wular Lake</b> is another significant attraction in Kashmir. Covering about 190 square kilometres in the Bandipora district, Wular Lake is the largest in Jammu and Kashmir and the second largest in Asia. Originating from the Jhelum River, Wular Lake is vital for the local ecosystem and boasts a diverse range of aquatic plants and fish. It’s a key part of any <strong>Kashmir offbeat package tour with Gurez Valley</strong> for those interested in natural beauty and local wildlife.</p>
                                            <p><b>Nagin Lake</b> often considered an extension of Dal Lake, offers a more serene and less crowded experience. Surrounded by majestic hills, this pristine lake is perfect for a romantic Shikara ride in a peaceful, pollution-free environment. It’s an excellent choice for honeymooners looking for a quieter escape.</p>
                                            <p><b>Manasbal Lake</b> located 28 kilometers from Srinagar at an elevation of 1,583 meters, is smaller compared to Dal and Nagin Lakes but equally charming. Known for its beautiful blooms of lilies and lotuses, Manasbal Lake is an ideal spot for spending quality time with loved ones. The lake is surrounded by well-maintained gardens filled with seasonal flowers, including tulips and roses, making it a sought-after destination in Kashmir.</p>
                                            <p>Including these lakes in your <strong>Kashmir Tour Packages with Gurez Valley</strong> will ensure a memorable and picturesque experience, showcasing the natural beauty and romantic allure of the region.</p>
                                            <br />
                                            <br /> <br />
                                            <h5><b>ENTRY FEES FOR SIGHTSEEING DURING KASHMIR TOUR</b></h5>
                                            <br />

                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <tbody>
                                                        <tr>
                                                            <td>Places</td>
                                                            <td>Entry Fees</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Dal Lake</td>
                                                            <td>NIL</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Nishant Bagh</td>
                                                            <td>Rs. 24 per adult, Rs. 12 per child</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Pari Mahal</td>
                                                            <td>Rs. 40/Person</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Shalimar Bagh</td>
                                                            <td>Rs. 24 per adult, Rs. 12 per child</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Shankaracharya Temple</td>
                                                            <td>NIL</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Chashme Shahi</td>
                                                            <td>Rs. 24 per adult, Rs. 12 per child</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Verinag Garden</td>
                                                            <td>Rs. 10/Person</td>
                                                        </tr>
                                                        <tr>
                                                            <td>SPS Museum</td>
                                                            <td>Rs. 10 for Indian, Rs. 50 for Foreigners</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Tulip Garden</td>
                                                            <td>Rs. 75 per adult, Rs. 30 per child</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Aru Valley</td>
                                                            <td>Rs. 25/Person</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                            <br />
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div className="card-header custHeaer">
                                            <h4 className='content-desktop' style={{ fontSize: "1.5vw" }}>MUST DO ACTIVITIES DURING <strong>KASHMIR TOUR PACKAGES WITH GUREZVALLEY</strong></h4>
                                            <h4 className='content-mobile2' style={{ fontSize: "3.5vw" }}>MUST DO ACTIVITIES DURING <strong>KASHMIR TOUR PACKAGES WITH GUREZVALLEY</strong></h4>

                                        </div>
                                        <div className="card-body">
                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>Shikara Rides:</b><b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Shikara Rides:</b> Experience the tranquility of Dal Lake with a leisurely Shikara ride During your <strong> Kashmir Tour Packages with GurezValley</strong>. Glide across the serene waters, passing by floating gardens, houseboats, and vibrant markets. Sunset and early morning rides offer particularly magical views.</p>
                                            <center> <img src={Topbanner0ImageURL} className="img-fluid" alt="..." /></center><br /><br /><br />

                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>Skiing and Snowboarding:</b>
                                                <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Skiing and Snowboarding:</b> During the winter months, places like Gulmarg transform into ski resorts, attracting enthusiasts from around the world. Gulmarg offers excellent slopes for skiing, snowboarding, and heli-skiing, with breathtaking views of the surrounding Himalayan peaks.</p>
                                            <center> <img src={Topbanner1ImageURL} className="img-fluid" alt="..." /></center><br /><br /><br />

                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>Gondola Ride: </b>
                                                <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Gondola Ride: </b>
                                                Take a ride on the Gulmarg Gondola, one of the highest cable cars in the world. Enjoy panoramic views of the snow-capped peaks as you ascend to Kongdori or Apharwat Peak, where you can indulge in activities like hiking and mountain biking.Experience this Awesome Ride During your <strong>Kashmir Tour Package with GurezValley</strong> with Adorable Vacation and make this as a life long memories.</p>
                                            <center> <img src={Topbanner2ImageURL} className="img-fluid" alt="..." /></center><br /><br /><br />

                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>Trekking:</b>
                                                <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Trekking:</b> Kashmir is a paradise for trekkers, with numerous trails that cater to all levels of expertise. Popular trekking destinations include the Kashmir Great Lakes Trek, Tarsar Marsar Trek, and the trek to Thajiwas Glacier in Sonamarg.</p>
                                            <center> <img src={Topbanner3ImageURL} className="img-fluid" alt="..." /></center><br /><br /><br />
                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>River Rafting:</b>
                                                <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>River Rafting:</b> The Lidder River in Pahalgam offers exciting opportunities for white-water rafting. Experience the thrill of navigating through rapids while enjoying the scenic beauty of the surrounding pine forests and mountains.</p>
                                            <center> <img src={Topbanner4ImageURL} className="img-fluid" alt="..." /></center>
                                            <br /><br /><br />
                                            <a href="tel://9147061467" className="btn btn-Warning" style={{ padding: '15px 30px', fontSize: '20px' }}>CALL NOW</a>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="container-fluid navtabCustom">
                                        <nav>
                                            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                                <button onClick={() => this.handleCustomTabClick('tab1')} className={`tab button ${customActiveTab === 'tab1' ? 'active' : ''}`} role="tab" aria-controls="nav-highlights" aria-selected={customActiveTab === 'tab1'}>Highlights</button>
                                                <button onClick={() => this.handleCustomTabClick('tab2')} className={`tab button ${customActiveTab === 'tab2' ? 'active' : ''}`} role="tab" aria-controls="nav-flights" aria-selected={customActiveTab === 'tab2'}>Flights</button>
                                                <button onClick={() => this.handleCustomTabClick('tab3')} className={`tab button ${customActiveTab === 'tab3' ? 'active' : ''}`} role="tab" aria-controls="nav-hotel" aria-selected={customActiveTab === 'tab3'}>Hotel</button>
                                                <button onClick={() => this.handleCustomTabClick('tab4')} className={`tab button ${customActiveTab === 'tab4' ? 'active' : ''}`} role="tab" aria-controls="nav-sightseeing" aria-selected={customActiveTab === 'tab4'}>Sightseeing</button>
                                                <button onClick={() => this.handleCustomTabClick('tab5')} className={`tab button ${customActiveTab === 'tab5' ? 'active' : ''}`} role="tab" aria-controls="nav-meals" aria-selected={customActiveTab === 'tab5'}>Meals</button>
                                            </div>
                                        </nav>
                                        <div className="tab-content" id="nav-tabContent" >
                                            <div className={customActiveTab === 'tab1' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-highlights-tab">
                                                <p><strong>Kashmir offbeat package tour with Gurez Valley</strong> is renowned for its breathtaking landscapes, which include iconic features like the Dal Lake, Nigeen Lake, Gulmarg, Pahalgam, and Sonamarg. These areas attract tourists from around the world who come to admire the region's picturesque scenery and engage in various outdoor activities.</p>
                                                <p>Planning for a <strong>Kashmir Offbeat Tour Packages</strong> remains a captivating destination for travellers seeking natural beauty, cultural experiences, and adventure in a unique and picturesque setting.</p>
                                                <p><b>Potential Geography</b> - Kashmir is nestled in the Himalayan Mountain range and is bordered by India, Pakistan, and China. It is divided into three regions: Jammu, the Kashmir Valley, and Ladakh. The region is characterized by its snow-capped peaks, lush valleys, meandering rivers, and serene lakes.</p>
                                                <p><b>Kashmir Tour</b> is renowned for its breathtaking landscapes, which include iconic features like the Dal Lake, Nigeen Lake, Gulmarg, Pahalgam, and Sonamarg. These areas attract tourists from around the world who come to admire the region's picturesque scenery and engage in various outdoor activities. Kashmir has long been a popular tourist destination, attracting visitors with its natural beauty, adventure sports, religious sites, and historical landmarks. Tourists can enjoy activities such as trekking, skiing, houseboat stays, shikara rides, and visits to Mughal gardens and ancient temples.</p>
                                            </div>
                                            <div className={customActiveTab === 'tab2' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-flights-tab">
                                                <div className="overflow-table">
                                                    <span>Best Srinagar Non-Stop Flight connection from your city<b> <i>(SXR)</i></b></span><br /><br />
                                                    <table className="table table-bordered">
                                                        <tr className="table-danger">
                                                            <th>FLIGHT</th>
                                                            <th>AIRLINES</th>
                                                            <th>AIRPORT</th>
                                                            <th>TIME</th>
                                                            <th>DAY</th>
                                                        </tr>
                                                        <tr>
                                                            <td>SG-509</td>
                                                            <td>SpiceJet</td>
                                                            <td>Mumbai</td>
                                                            <td>14:45</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>QP-1637</td>
                                                            <td>Akasa Air</td>
                                                            <td>Mumbai</td>
                                                            <td>5:35</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>63-266</td>
                                                            <td>IndiGo</td>
                                                            <td>Mumbai</td>
                                                            <td>06:45</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>SG-8913</td>
                                                            <td>SpiceJet</td>
                                                            <td>Delhi</td>
                                                            <td>08:00</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>SG-8373</td>
                                                            <td>SpiceJet</td>
                                                            <td>Delhi</td>
                                                            <td>09:45</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>6E-6947</td>
                                                            <td>IndiGo</td>
                                                            <td>Bengaluru</td>
                                                            <td>13:45</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>6E6253</td>
                                                            <td>IndiGo</td>
                                                            <td>Hyderabad</td>
                                                            <td>14:50</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>6E6945</td>
                                                            <td>IndiGo</td>
                                                            <td>Ahmedabad</td>
                                                            <td>06:55</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>6E6961</td>
                                                            <td>IndiGo</td>
                                                            <td>Kolkata</td>
                                                            <td>14:45</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className={customActiveTab === 'tab3' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-hotel-tab">

                                                <div className="overflow-table">
                                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }} ><center><b>4 STAR - CERTIFIED BY 'ADORABLE' </b></center></h4>
                                                    <div id="no-more-tables">
                                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                            <thead class="cf">
                                                                <tr className="text-center">
                                                                    {/* <!-- Add the "text-center" className to center content --> */}
                                                                    <th colspan="6" style={{ fontSize: "1.3vw" }}><h6><b>3 STAR CATEGORY HOTEL CERTIFIED BY 'ADORABLE VACATION' </b></h6></th>
                                                                </tr>
                                                                <tr className="table-danger">
                                                                    <th className="text-center">Destination</th>
                                                                    <th className="text-center">Hotel Names</th>
                                                                    <th className="text-center">Hotel Type</th>
                                                                    <th className="text-center">No of Nights</th>
                                                                    <th className="text-center">Meal Plan</th>
                                                                </tr>
                                                            </thead>
                                                            <tr>
                                                                <td data-title="Destination"><div style={{ display: 'inline-block' }}>Srinagar</div></td>
                                                                <td data-title="Hotel Names"><div style={{ display: 'inline-block' }}>Hotel Grand Fortune / Hotel Shefaf / Hotel Siddique Palace / Similar</div></td>
                                                                <td data-title="Hotel Type">3 Star
                                                                </td>
                                                                <td data-title="No of Nights"><div style={{ display: 'inline-block' }}>5N</div></td>
                                                                <td data-title="Meal Plan"><div style={{ display: 'inline-block' }}>Room with Breakfast & Dinner</div></td>
                                                            </tr>
                                                            <tr>
                                                                <td data-title="Destination"><div style={{ display: 'inline-block' }}>Gulmarg</div></td>
                                                                <td data-title="Hotel Names"><div style={{ display: 'inline-block' }}>Hotel Affarwat / Hotel Mama Palace /Gulmarg House / Similar
                                                                </div>
                                                                </td>
                                                                <td data-title="Hotel Type">3 Star
                                                                </td>
                                                                <td data-title="No of Nights"><div style={{ display: 'inline-block' }}>1N</div></td>
                                                                <td data-title="Meal Plan"><div style={{ display: 'inline-block' }}>Room with Breakfast & Dinner</div></td>
                                                            </tr>
                                                            <tr>
                                                                <td data-title="Destination"><div style={{ display: 'inline-block' }}>Pahalgam</div></td>
                                                                <td data-title="Hotel Names"><div style={{ display: 'inline-block' }}>Hotel Pine Palace Resort / Hotel Fifth Season / Hotel Moonview Resort /Similar
                                                                </div>
                                                                </td>
                                                                <td data-title="Hotel Type">3 Star
                                                                </td>
                                                                <td data-title="No of Nights"><div style={{ display: 'inline-block' }}>2N</div></td>
                                                                <td data-title="Meal Plan"><div style={{ display: 'inline-block' }}>Room with Breakfast & Dinner</div></td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={customActiveTab === 'tab4' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-sightseeing-tab">
                                                <div className="container-fluid px-0 py-1">
                                                    <h3>Sightseeing for <strong>Kashmir Tour Packages with GurezValley</strong></h3>
                                                    <p>Kashmir, often referred to as "Paradise on Earth," is renowned for its breathtaking natural beauty, serene landscapes, and rich cultural heritage. Here are some of the most popular sightseeing destinations in Kashmir:</p><br />
                                                    <p><b style={{ fontSize: "1.3vw" }} className='content-desktop'>Dal Lake:</b>
                                                        <b style={{ fontSize: "3.5vw" }} className='content-mobile2'>Dal Lake:</b> This iconic lake in Srinagar is famous for its picturesque surroundings, houseboats, and Shikara rides. Visitors can explore the floating gardens, old Mughal gardens along the shores, and enjoy the stunning views of the surrounding mountains.</p><br />
                                                    <p><b className='tomato content-desktop' style={{ fontSize: "1.3vw" }}>Gulmarg:</b>
                                                        <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Gulmarg:</b> Known for its lush green meadows, Gulmarg is a popular hill station and skiing destination. Visitors can take the Gulmarg Gondola, one of the highest cable cars in the world, to reach Kongdori and enjoy panoramic views of the Himalayas.</p><br />
                                                    <p><b className='content-desktop' style={{ fontSize: "1.3vw" }}>Sonamarg:</b>
                                                        <b className='content-mobile2' style={{ fontSize: "3.5vw" }}>Sonamarg:</b>  Translating to "Meadow of Gold," Sonamarg is a scenic valley situated at an altitude of 2800 meters. It offers breathtaking views of snow-capped peaks, glaciers, and meadows. It's also the base for various trekking routes.</p><br />
                                                    <p><b className='tomato' style={{ fontSize: "1.3vw" }}>Pahalgam:</b> This quaint town is renowned for its pristine beauty, lush greenery, and the Lidder River. Pahalgam serves as a starting point for the annual Amarnath Yatra pilgrimage and offers opportunities for trekking, horse riding, and fishing.</p><br />
                                                    <p><b style={{ fontSize: "1.3vw" }}>Srinagar:</b> The summer capital of Jammu and Kashmir, Srinagar is famous for its Mughal gardens, including Shalimar Bagh, Nishat Bagh, and Chashme Shahi. Visitors can also explore the old city, visit the Jama Masjid, and indulge in shopping for traditional Kashmiri handicrafts in the local markets.</p><br />
                                                    <p><b className='tomato' style={{ fontSize: "1.3vw" }}>Pari Mahal:</b> Also known as the "Palace of Fairies," Pari Mahal is a historic monument located above the Chashme Shahi Gardens in Srinagar. It offers panoramic views of the city and Dal Lake and is surrounded by beautiful terraced gardens.</p><br />
                                                    <p><b style={{ fontSize: "1.3vw" }}>Betaab Valley:</b> Named after the Bollywood movie "Betaab," this picturesque valley is located near Pahalgam and offers stunning views of snow-capped mountains, lush greenery, and the Lidder River. It's a popular picnic spot and filming location.</p><br />
                                                    <p><b className='tomato' style={{ fontSize: "1.3vw" }}>Aru Valley:</b> Situated near Pahalgam, Aru Valley is known for its scenic beauty, alpine meadows, and trekking trails. It's a great place for nature lovers and adventure enthusiasts.</p><br />
                                                    <p><b style={{ fontSize: "1.3vw" }}>Mughal Road:</b> This historic road connects the Kashmir Valley with the regions of Poonch and Rajouri. It passes through picturesque landscapes, including dense forests, meadows, and small villages, offering a scenic drive.</p><br />
                                                    <p><b className='tomato' style={{ fontSize: "1.3vw" }}>Dachigam National Park:</b> Located near Srinagar, this national park is home to endangered species like the Kashmir stag (Hangul) and the Himalayan black bear. Visitors can enjoy wildlife safari tours and birdwatching in the park.</p><br />
                                                </div>
                                            </div>
                                            <div className={customActiveTab === 'tab5' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-meals-tab">
                                                <div className="overflow-table">
                                                    <h5 class="tomato">7 times Meals and 7 times Breakfast has been Provided</h5>
                                                    <table className="table table-bordered">
                                                        <tr className="table-danger">
                                                            <th colspan="6" className="text-center">Category</th>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-center">JAIN</td>
                                                            <td class="text-center">PURE-VEG</td>
                                                            <td class="text-center">VEGAN</td>
                                                            <td class="text-center">PURE-NON-VEG</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br /> <br /> <br />
                            {/* <!--By Itinerary--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>
                                                DAY WISE <strong><span>KASHMIR TOUR WITH GUREZ VALLEY</span></strong>
                                            </h2>
                                            <h4>7 Nights 8 Days <strong>Kashmir Tour Itinerary</strong></h4>
                                        </div>
                                    </div>
                                </div>


                                {/* <!--end Short description--> */}
                                <br />
                                <div className="row">
                                    <div
                                        className="accordion"
                                        id="accordionPanelsStayOpenExample"
                                    >
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day1"
                                            >
                                                <button
                                                    className="accordion-button"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseOne"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseOne"
                                                >
                                                    DAY 1  – ARRIVAL AT SRINAGAR
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseOne"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingOne"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        This is 1st day of your most awaiting <strong>Kashmir Tour Itinerary</strong>. Upon arrival at Srinagar Sheikh Ul Alam Int’l Airport (This must be one of the Most Scenic Flights in the World, Srinagar is popularly known as the "City of Reshi’s & Sufi's" and it is also the Summer capital of state Jammu & Kashmir), you will be met by our representative and transfer to Hotel for Check in followed by welcome drink. In the evening you take a shikara ride on world famous Dal Lake, where you visit the floating vegetable gardens & open Dal Lake. While riding the Shikara’s there are plenty of opportunities for bird watching with plentiful species including Kingfisher, Little Bittern, Common Pariah Kites, Grebe etc. Overnight stay at HouseBoat.
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay1ImageURL} alt="Kashmir Packages Tour Cost with Gurez Valley" /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay1ImageURL} style={{ width: '320px' }} alt="Kashmir Packages Tour Cost with Gurez Valley" /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day2"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseTwo"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseTwo"
                                                >
                                                    DAY 02: SRINAGAR TO PAHALGAM [96 Kms – 2 to 3 Hrs One Way]
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseTwo"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingTwo"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        After Breakfast check out from hotel & transfer Towards Pahalgam. Pahalgam is called "The Valley of Shepherds". Pahalgam is situated at the confluence of the streams flowing from Sheshnag Lake and the Lidder River, Awantipura Ruins, Saffron Field, Apple Garden Pahalgam (2,130 m) was once a humble shepherd's village with breath-taking views. Enjoy nature & walk around the bank of river Lidder. Pahalgam is famous for some trekking routes also & is the base camp for Amarnath Pilgrimage. Pahalgam is also famous in the Indian film Industry (Bollywood). Now it is Kashmir's premier resort, cool even during the height of summer when the maximum temperature does not exceed 25 Degree C. Upon arrival, check in to the pre-arranged Hotel. Rest of the day is free. Overnight stay at Pahalgam Hotel.
                                                    </p>

                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay2ImageURL} alt="Offbeat Kashmir Tour with Gurez Valley" /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay2ImageURL} style={{ width: '320px' }} alt="Offbeat Kashmir Tour with Gurez Valley" /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day3"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseThree"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 03: PAHALGAM [LOCAL SIGHTSEEING]
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseThree"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingThree"
                                            >
                                                <div className="accordion-body">
                                                    <p>After a leisurely breakfast, a full day free to enjoy and explore the Beauty of Pahalgam. One can go to Chandanwari (16 Kms from Pahalgam) which is the starting point of Amarnath Yatra. Aru (10 kms from Pahalgam) which is a fine meadow of picturesque scenery and starting point of trekking of (Liddarwat, Kolahoi Glacier & Trsar Lake) & Betaab Valley.</p>
                                                    <br />
                                                    <h5><b>ARU VALLEY</b></h5><br />
                                                    <p>It is located around 12 km from Pahalgam, 11 km upstream from the Lidder River. Noted for its scenic meadows, lakes and mountains, it is a base camp for trekking to the Kolahoi Glacier and Tarsar Lake. The village lies on the left bank of the Aru River, which is a tributary of the Lidder River the Aru valley is noted for its scenic meadows. It is popular among the tourists for its peaceful environment and scenic beauty. Other popular tourist activities include fishing for trout in the Lidder River, trekking, hiking, horse riding, sightseeing and photography.</p>
                                                    <br />
                                                    <h5><b>BETAAB VALLEY</b></h5><br />
                                                    <p>It is situated at a distance of 15 kilometres from Pahalgam. The valley got its name from the Sunny Deol - Amrita Singh hit debut film Betaab. The valley is surrounded by lush green meadows, snow -clad mountains and covered with dense vegetation. Betaab Valley is a very popular tourist destination. Betaab Valley is also a favourite campsite of travellers as it also serves as a base camp for trekking and further exploration of the mountains. The valley is a walk-able distance from Pahalgam. The crystal clear & chilly water of the stream bubbling down from the snowy hills is a delight. Locals here drink this water too.</p>
                                                    <br />
                                                    <h5><b>CHANDANWARI</b></h5><br />
                                                    <p>Chandanwari - Situated 16 km from Pahalgam, is the starting point of the Amarnath Yatra, which takes place every year in the month of Sawan (Rain). It is famous for snow sledding on a snow bridge. Taxi cabs can be hired from Pahalgam for a roundtrip. Snow fights is what people generally indulge in here before they sledge back down. Many prefer to sip a cup of tea or snack at the nearby dhabas. Hajan, on the way to Chandanwari is an idyllic spot for a picnic. Filmgoers will recognize it instantly as it has been the location of several movie scenes. Return by the evening for dinner & overnight stay at Hotel.<br />
                                                        Or, you can explore the beauty of "Pahalgam". You can hire ponies (directly) for a visit to Baisaran Valley / Mini Switzerland.
                                                    </p>
                                                    <br />
                                                    <h5><b>BAISARAN VALLEY</b></h5><br />
                                                    <p>Baisaran Valley, a lush meadow surrounded by hills and covered with dense vegetation. This region looks very similar to European resorts and is a good campsite. Pine forests dot this meadow which presents a picturesque view of the snow-clad mountains. The most beautiful of these is the huge, undulating meadow of Baisaran, surrounded by thickly wooded forests of pine. Filmgoers will recognize it instantly as it has been the location of several movie scenes. Return by the evening. Overnight stay at Pahalgam Hotel.</p>
                                                    <div className="content-desktop">
                                                        <center><img src={navDay3ImageURL} alt="Kashmir Tour Packages" /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay3ImageURL} style={{ width: '320px' }} alt="Kashmir Tour Packages" /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day4"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseFour"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    Day 04: - PAHALGAM TO GULMARG [52 KMS – 2 HRS ONE WAY]
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseFour"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingFour"
                                            >
                                                <div className="accordion-body">
                                                    <p>After a leisurely breakfast, check out from Hotel & drive to Gulmarg via Bye-Pass. Gulmarg, called the "Meadow of Flowers". Gulmarg is at an altitude of 8700ft. Gulmarg also referred to as Meadow of Flowers is a famous Ski Resort & the world's highest 18-hole Golf Course. The place was prominent during British time. Gulmarg's legendary beauty, prime location and proximity to Srinagar naturally make it one of the premier hill resorts in the country. Originally called ‘Gaurimarg’ by shepherds, its present name was given in the 16th century by Sultan Yusuf Shah, who was inspired by the sight of its grassy slopes emblazoned with wild flowers. Gulmarg was a favourite haunt of Emperor Jehangir who once collected 21 different varieties of flowers from here. Today Gulmarg is not merely a mountain resort of exceptional beauty - it also has the highest green golf course in the world, at an altitude of 2,650 m, and is the country's premier ski resort in the winter. On Arrival in Gulmarg, check in to the pre-arranged Hotel.</p>
                                                    <p>One can go for the <strong>Gulmarg Gondola Ride up to 1st or 2nd Phase</strong>. The Gulmarg Gondola is a tourist attraction in itself. It is one of the highest cable cars in the world, reaching 3,979 meters. The two-stage ropeway ferries about 600 people per hour to and from the gondola main station in Gulmarg to a shoulder of nearby Mt. Affarwat Summit (4,200 m / 13,780 ft). There are also countless off-piste possibilities through the beautiful Khilanmarg meadows on the foot of Mount Affarwat, which offer a scenic view of Nanga Parbat. The Affarwat ridge at 3850+ meters offer an avalanche-controlled ski area that offers a wide field of snow to descend 800 m in approximately 3 km of skiing, and is for advanced skiers only. Due to Gulmarg's steep terrain, the region is popular amongst advanced and extreme skiers from around the world and has been visited by a number of ski professionals and featured in a number of ski films also. Return by the evening for. Overnight Stay at Hotel</p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay4ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay4ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day5"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseFive"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 05: GULMARG TO GUREZ VALLEY [160 Kms – 5 to 6 Hrs One Way]
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseFive"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingFive"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        This is the most astonishing part of your Kashmir tour plan as today we will visit much awaited Gurez Valley. After a leisurely breakfast, check out from Hotel & drive to Gurez Valley. Gurez Valley, is a valley located in the high Himalayas, about 86 kilometres (53 mi) from Bandipora and 123 kilometres (76 mi) from Srinagar, to the north of the Kashmir valley. At about 2,400 metres (8,000 ft) above sea level, the valley is surrounded by snow-capped mountains. It has diverse fauna and wildlife including the Himalayan brown bear and the snow leopard. The Kishanganga River flows through the valley.
                                                    </p>
                                                    <p>The valley lies near the line of control, which separates it from the Astore and Neelum districts of Pakistan-occupied Kashmir. Being situated very close to the Burzil Pass, which leads into Astore, the inhabitants are ethnic Dards/Shins. They speak the Shina language and have the same styles of dress and culture as their kinsmen in Pakistani-administered Gilgit-Baltistan. Overnight stay at Gurez Valley Hotel.</p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay5ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay5ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day6"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseSix"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 06: GUREZ VALLEY SIGHTSEEING [160 Kms – 5 to 6 Hrs One Way]
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseSix"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingSix"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        After Breakfast visit Tulail valley (Subject to weather condition & Permit issuance), Tulail Valley is one of the remotest valleys of Jammu and Kashmir. Overnight stay at Gurez Valley Hotel.
                                                    </p>

                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay6ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay6ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day7"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseSeven"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 07: GUREZ VALLEY TO SRINAGAR [125 Kms – 4 to 5 Hrs One Way]
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseSeven"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingSeven"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        After an early Breakfast check out & transfer to Srinagar. On arrival check in to the hotel. In the evening Srinagar, take a visit of Shankaracharya Temple, it is believed that it was the place where the great philosopher and saint Shankaracharya stayed when he visited Kashmir ten centuries ago to revive Sanathan Dharma. The original ceiling was dome- shaped and the brick roof is not more than a century old. In the afternoon, we took a visit to Jama Masjid, Mughal Gardens, and Nishat Garden Tulip Garden known as ‘Garden of pleasure’ built in 16th century (1633) by Mughal King Asif Khan. Then visit to Shalimar Garden known as ‘Garden of love’ built in 16th century (1619) by Mughal King Jahangir for his beloved wife Noor Jahan or Mehr-un-Nissa & Chashmashahi (The Royal Spring). The Chashmashahi Mughal garden in Srinagar had been loved by emperors and picnickers alike. Overnight stay at Srinagar Hotel.
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay7ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay7ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day8"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseTen"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 08:DEPART SRINAGAR [FLY OUT]
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseTen"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingTen"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        <strong>Kashmir Tour concludes Adorable Vacation</strong> thanks you for your patronage and ensures a punctual transfer to Domestic Airport to catch the flight for your onward Destination.
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay8ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay8ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <br />

                            {/* <!--insert table inclusion exclusion--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        {
                                            <InclusionExclusion />
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* <!--end table inclusion exclusion--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center" >
                                            <h2>The Khyber Himalayan Resort & Spa <span>- Gulmarg</span></h2>
                                            <h4><i>Next Level Luxury Resort </i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row py-3">
                                    <img src={brandedTourackageImage_1_URL} alt="..." />
                                </div>

                                <div id="no-more-tables" >
                                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                        <thead>
                                            <tr className="table-danger">
                                                <th>Category</th>
                                                <th>Size(Room)</th>
                                                <th>Hotel Brand</th>
                                                <th>Price (Per Night)</th>
                                                <th>Book Now</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td data-title="Packages">Platinum Accommodation</td>
                                                <td data-title="Size(Room)">386+ SQ.FT</td>
                                                <td data-title="Hotel Brand">Khyber Hotels (Heritage)<div className="reviews">
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                </div></td>
                                                <td data-title="Price (Per Night)">Rs 53,119/- </td>
                                                <td data-title="Book Now"><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick}>
                                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                                </a></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                <div className="card mx-0 my-5">
                                    <div className="card-header custHeaer">
                                        <h3 className='content-desktop' style={{ fontSize: "1.5vw" }}><b>HEAVEN IS CLOSER THAN YOU CAN IMAGINE</b></h3>
                                        <h3 className='content-mobile2' style={{ fontSize: "3.5vw" }}><b>HEAVEN IS CLOSER THAN YOU CAN IMAGINE</b></h3>
                                    </div>
                                    <div className="card-body">
                                        <p ><b>8,825 feet</b> above sea level in the Pir Panjal range of the Himalayas lies a higher order of luxury. <b>The Khyber Himalayan Resort & Spa</b> is spread over seven unspoilt, fir-laden acres, with majestic views of the Affarwat Peaks. This 85-room resort is framed at every vantage point by nature at its most spectacular. <b>The world’s highest ski lift</b>, the Gulmarg Gondola – which takes you to Kongdoori Mountain, is only a short walk away. Meadows and forests that have enchanted travellers for centuries wait to be explored by trekking enthusiasts and undying romantics. Clad in locally sourced pine and slate, the resort is built in a style true to the architectural heritage of Kashmir. In every way the indoors mirror the beauty of the outdoors. Specialty restaurants, a cigar lounge are amongst the necessary luxuries in place. Add to these other thoughtful additions like our children’s club, a mini theatre and a dedicated Activities Concierge and you will find The Khyber is a resort at par with the best in the world.</p>

                                        <a href="tel://9883359713" className="btn btn-Warning">Book Now</a>

                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2 >The LaLiT Grand Place <span>- Srinagar</span></h2>
                                            <h4 ><i>Fill the Luxury</i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row py-3">
                                    <img src={brandedTourackageImage_2_URL} alt="..." />
                                </div>

                                <div id="no-more-tables" >
                                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                        <thead>
                                            <tr className="table-danger">
                                                <th>Category</th>
                                                <th>Size(Room)</th>
                                                <th>Hotel Brand</th>
                                                <th>Price (Per Night)</th>
                                                <th>Book Now</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td data-title="Packages">Premium Accommodation</td>
                                                <td data-title="Size(Room)">290+ SQ.FT</td>
                                                <td data-title="Hotel Brand">The LaLiT Hotels<div className="reviews">
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                </div></td>
                                                <td data-title="Price (Per Night)">Rs 28,332/- </td>
                                                <td data-title="Book Now"><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick}>
                                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                                </a></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                <div className="card mx-0 my-5">
                                    <div className="card-header custHeaer">
                                        <h3 className='content-desktop' style={{ fontSize: "1.5vw" }}><b>EMBARKED ON A SENSE OF PRIDE AT PREMIUMNESS</b></h3>
                                        <h3 className='content-mobile2' style={{ fontSize: "3.5vw" }}><b>EMBARKED ON A SENSE OF PRIDE AT PREMIUMNESS</b></h3>
                                    </div>
                                    <div className="card-body">
                                        <p ><b>The LaLit Grand Palace</b> was originally built in 1901 for the royal family. It now provides luxurious rooms with views of Dal Lake and the beautiful gardens. An indoor pool, tennis courts and pampering spa treatments are also available.The tastefully designed rooms are modern with a touch of traditional charm. All have a satellite TV, minibar and free tea/coffee making facilities. It is 18 km from Srinagar Airport and 4 km from Lal Chowk city centre. Jammu Railway Station is 300 km away. Guests can enjoy a relaxing massage at Rejuve Spa Health Club, practice yoga, or play golf. The hotel also has a gym and 24-hour business center. The Chinar Garden serves North Indian cuisine, while international buffet spreads are available at The Chinar – The All Day Coffee Shop. Other dining options include Dal Bar and 24-hour room service.</p>

                                        <a href="tel://9883359713" className="btn btn-Warning">Book Now</a>

                                    </div>
                                </div>
                            </div>

                            {/* <!--start of package details--> */}
                            <div className="container-fluid py-5">

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>Kashmir<span> Tour With GurezValley</span></h2>
                                            <h4>Related Kashmir Tour plan</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                                        <div className="carousel-inner">
                                            <div className="carousel-item active">

                                                <Slider {...settings}>
                                                    {hotPackagesView}
                                                </Slider>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!--end of package details--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2 >Frequently Ask <span>Questions</span></h2>
                                            <h4 >Kashmir Tour With GurezValley Trip Related Questions</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="row" >
                                    <div className="accordion" id="accordionPanelsStayOpenExample">
                                        <div className="accordion" id="accordionFlushExample">
                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingOne">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                                        What is the best time to visit Gurez Valley?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseOne" className="accordion-collapse collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        The best time to visit Gurez Valley is from May to October, when the weather is pleasant and the valley is accessible. During this period, the landscape is lush and green, making it perfect for sightseeing and outdoor activities.
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingTwo">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                                        How can I reach Gurez Valley from Srinagar?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseTwo" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        Gurez Valley can be reached by road from Srinagar, which is approximately 123 kilometers away. The journey involves traveling through the scenic Razdan Pass. It typically takes about 5-6 hours by car.
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingThree">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                                        Is Gurez Valley safe for tourists?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseThree" className="accordion-collapse collapse show" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        Yes, Gurez Valley is considered safe for tourists. It is a peaceful region with a friendly local population. However, it is always advisable to check the latest travel advisories and follow local guidelines.
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingFour">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                                        What are the accommodation options in Gurez Valley?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseFour" className="accordion-collapse collapse show" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        Accommodation options in Gurez Valley are limited but include guesthouses, homestays, and small hotels. These provide basic amenities and a chance to experience the local way of life. It is recommended to book in advance due to limited availability.
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingFive">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                                                        What should I pack for a trip to Gurez Valley?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseFive" className="accordion-collapse collapse show" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        It is advisable to pack warm clothing, especially during the evenings and early mornings. Other essentials include comfortable walking shoes, sunscreen, a hat, and any necessary medications. Since Gurez Valley is a remote area, carrying basic toiletries and snacks is also recommended.
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingSix">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                                                        Are there any permits required to visit Gurez Valley?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseSix" className="accordion-collapse collapse show" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        Yes, Indian tourists need to obtain an Inner Line Permit to visit Gurez Valley. This permit can be obtained from the Deputy Commissioner’s Office in Bandipora or Srinagar. Foreign tourists are generally not allowed in Gurez Valley.
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingSeven">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
                                                        What activities can I enjoy in Gurez Valley?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseSeven" className="accordion-collapse collapse show" aria-labelledby="flush-headingSeven" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        Gurez Valley offers a range of activities, including trekking, fishing, nature walks, and photography. The valley's pristine landscapes and unique cultural experiences make it a perfect destination for adventure and nature enthusiasts.
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingEight">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
                                                        Can I find mobile network connectivity in Gurez Valley?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseEight" className="accordion-collapse collapse show" aria-labelledby="flush-headingEight" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        Mobile network connectivity in Gurez Valley is limited. Only specific networks like BSNL provide coverage, and even then, the signal can be weak in some areas. It's advisable to inform family and friends about the limited connectivity before traveling.
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingNine">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseNine">
                                                        What are the must-see places in Gurez Valley?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseNine" className="accordion-collapse collapse show" aria-labelledby="flush-headingNine" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        Key attractions in Gurez Valley include the Kishanganga River, Habba Khatoon Peak, Dawar Village, Razdan Pass, and Tulail Valley. These locations offer stunning natural beauty and opportunities for cultural immersion.
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingTen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
                                                        Is it possible to combine a visit to Gurez Valley with other destinations in Kashmir?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseTen" className="accordion-collapse collapse show" aria-labelledby="flush-headingTen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        Yes, a visit to Gurez Valley can be combined with other popular destinations in Kashmir, such as Srinagar, Gulmarg, Pahalgam, and Sonamarg. Many tour packages include these destinations along with Gurez Valley to offer a comprehensive experience of Kashmir.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center" >
                                            <h3><b>Kashmir <span class="tomato">Holiday Reviews</span></b></h3>
                                            <h4><i>"Your Experience is our Marketing"</i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-cols-1">
                                    <div id="testimonials">
                                        <div className="testimonial-box-container" >

                                            {reviewersDataView}

                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="col-md-3">
                            <BookingBox />
                        </div>
                    </div >
                </div >
                <div class="btn-call-pulse">
                    <a style={{ color: 'white' }} href="tel:+91-91470-61467">
                        <i className="fa fa-phone"></i>
                        {/* <span><small>+91 9147061467</small></span> */}
                    </a>
                </div>
                <div className="btn-whatsapp-pulse">
                    <a style={{ color: 'white' }} href="#" onClick={this.handleClick}>
                        <i className="fab fa-whatsapp"></i>
                        {/* <span>
                                <small>+91 9883359713</small>
                            </span> */}
                    </a>
                </div>

            </>
        );
    }
}

export default Kashmir_7N_8D_Gurez_Valley_Package;