import React, { Component } from "react";
import BookingBox from "../BookingBox";
import { Digital } from "react-activity";
import "react-activity/dist/library.css";
import ApiURL from "../../../api/ApiURL";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import "./InclusionExclusion";
import InclusionExclusion from "../Sandakphu/InclusionExclusion_sandakphu_trekking";

class Sandakphu_land_rover_Package extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customActiveTab: "tab1",
      currentPage: 1,
    };
  }

  handleCustomTabClick = (tab) => {
    this.setState({
      customActiveTab: tab,
    });
  };
  handleClick = () => {
    const currentUrl = window.location.href;
    const pageTitle = document.title;
    const message = `Hi, Please send about the Tour Package details "${pageTitle}"`;

    const whatsappLink = `https://api.whatsapp.com/send?phone=+919883359713&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
    window.open(whatsappLink, '_blank');
  };
  

  render() {
    const rows = [
      { Train_No: '12345', Train_Name: 'Saraighat Exp', Boarding_From: 'Howrah', Boarding_time: '16:05', Arrival: 'New Alipurduar', Day: 'M,T,W,T,F,S,S' },
      { Train_No: '12377', Train_Name: 'Padatik Exp', Boarding_From: 'Sealdah', Boarding_time: '23:20', Arrival: 'New Alipurduar', Day: 'M,T,W,T,F,S,S' },
      { Train_No: '13147', Train_Name: 'Uttar Banga Exp', Boarding_From: 'Sealdah', Boarding_time: '19:40', Arrival: 'New Cooch Behar', Day: 'M,T,W,T,F,S,S' },
      { Train_No: '13175', Train_Name: 'Kanchanjunga', Boarding_From: 'Sealdah', Boarding_time: '06:50', Arrival: 'NJP', Day: 'M,W,SAT' },
      { Train_No: '15959', Train_Name: 'Kamrup Exp', Boarding_From: 'Howrah', Boarding_time: '18:30', Arrival: 'New Alipurduar', Day: 'T,W,T,S,S' },
      { Train_No: '13141', Train_Name: 'Teesta Torsha', Boarding_From: 'Sealdah', Boarding_time: '15:00', Arrival: 'New Alipurduar', Day: 'M,T,W,T,F,S,S' },
      { Train_No: '13149', Train_Name: 'Kanchan Kanya', Boarding_From: 'Sealdah', Boarding_time: '20:35', Arrival: 'New Alipurduar', Day: 'M,T,W,T,F,S,S' },
    ];

    const rowsPerPage = 4;
    const totalPages = Math.ceil(rows.length / rowsPerPage);
    const currentRows = rows.slice((this.state.currentPage - 1) * rowsPerPage, this.state.currentPage * rowsPerPage);
    const { customActiveTab } = this.state;
    const packageTopBannerImageURL = ApiURL.Sandakphu_land_rover_PackageImageURL + "/Sandakphu-Land-Rover-Packages.webp";
    const packageTopBanner2ImageURL = ApiURL.Sandakphu_land_rover_PackageImageURL + "/Sandakphu-Land-Rover-Package.webp";

    const navDay1ImageURL = ApiURL.Sandakphu_land_rover_PackageImageURL + "/MANEBHANJAN.webp";
    const navDay2ImageURL = ApiURL.Sandakphu_land_rover_PackageImageURL + "/SANDAKPHU-SLEEPING-BUDDHA.webp";
    const navDay3ImageURL = ApiURL.Sandakphu_land_rover_PackageImageURL + "/PHALUT-SUNRISE.webp";
    const navDay4ImageURL = ApiURL.Sandakphu_land_rover_PackageImageURL + "/TUMLING-TREKKING.webp";
    
    const sightseen1ImageURL = ApiURL.Sandakphu_land_rover_PackageImageURL + "/LAND-ROVER.webp";
    const sightseen2ImageURL = ApiURL.Sandakphu_land_rover_PackageImageURL + "/ADVENTURE.webp";
    const sightseen3ImageURL = ApiURL.Sandakphu_land_rover_PackageImageURL + "/Nature.webp";
    const sightseen4ImageURL = ApiURL.Sandakphu_land_rover_PackageImageURL + "/ACCOMMODATION.webp";

    const settings = {
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    const reviewersDataList = this.props.ReviewersData;
    const reviewersDataView = reviewersDataList.map((dataItem, i) => {
      const reviewerImageURL = ApiURL.Sandakphu_land_rover_PackageImageURL + "/" + dataItem.ImageName;
      return (
        <div className="testimonial-box" key={i}>
          <div className="box-top">
            <div className="profile">
              <div className="profile-img">
                <img src={reviewerImageURL} alt="..." />
              </div>
              <div className="name-user">
                <strong>{dataItem.Name}</strong>
                <span>{dataItem.Location}</span>
              </div>
            </div>
            <div className="reviews">
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
            </div>
          </div>
          <div className="client-comment">
            <p>{dataItem.Comment}</p>
          </div>
        </div>
      )
    })


    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <img
              src={packageTopBannerImageURL}
              className="mobile-image2"
              alt="..."
            />
          </div>
        </div>

        <div className="container-fluid custom-container py-3">
          <div className="row">
            <div className="col-md-9">
              <div className="row">
                <div className="col-md-12">
                  <div className="container-fluid custom-container py-1">


                    <h1 className="content-desktop" style={{ fontSize: "2vw" }}><center><strong>Book Sandakphu Land Rover Package from Manebhanjan – Best Deal Guaranteed</strong></center></h1>

                    <h1 className="content-mobile" style={{ fontSize: "4vw" }}><center><strong>Book Sandakphu Land Rover Package from Manebhanjan – Best Deal Guaranteed</strong></center>
                      <hr />
                    </h1>
                    <div className="container-fluid">
                      <div className="row">
                        <img src={packageTopBanner2ImageURL} className="mobile-image" alt="..." />
                      </div>
                    </div>
                    <hr />
                  </div>
                  <h2 className="content-desktop p-4" style={{ fontSize: "2.0vw" }}> <strong>Are You Looking For Sandakphu Land Rover Packages in 2024?</strong> For the best <a href='tel:9147061467' class="tomato"><strong>Sandakphu Land Rover Package Tour</strong>, ☎ CALL +91-91470-61467 </a></h2>
                  <h2 className="content-mobile p-4" style={{ fontSize: "4.5vw" }}><strong>Are You Looking For Sandakphu Land Rover Packages in 2024?</strong> For the best <a href='tel:9147061467' class="tomato"><strong>Sandakphu Land Rover Package Tour</strong>, ☎ CALL +91-91470-61467 </a></h2>
                  <br />
                  <div className="card">
                    <div
                      className="card-header custHeaer"
                    >
                      <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong> Adorable Vacation LLP also provide <a href="/sandakphu-trekking-package-booking" target="_blank" style={{ color: "white" }}>Sandakphu Trekking Package from NJP</a> </strong> : <b>with Manebhanjan, Tumling, Kalipokhri, Phalut, Srikhola</b></h3>
                      <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong> Adorable Vacation LLP also provide <a href="/sandakphu-trekking-package-booking" target="_blank" style={{ color: "white" }}>Sandakphu Trekking Package from NJP</a> </strong> : <b>with Manebhanjan, Tumling, Kalipokhri, Phalut, Srikhola</b></h4>
                    </div>
                    <div className="card-body">
                      <p>Almost every Bengali has heard of the <strong>Sandakphu Tour Package</strong>. This amazing destination is a top choice for adventure lovers from India and around the world. Its stunning natural beauty attracts many visitors, adventure lovers, and trekkers every year. To make this experience accessible, Adorable vacation LLP offers a fantastic <strong>Sandakphu Land Rover Package Tour</strong>, perfect for families and adventure seekers. Traveling to the top of Sandakphu and Phalut is truly a once-in-a-lifetime experience.</p>
                      <p>Many people want to explore this beautiful destination, but the moderate trekking difficulty can be challenging for some, especially seniors. For those who prefer not to trek, Adorable vacation LLP offers <strong>Sandakphu Land Rover Packages</strong> starting from Manebhanjan. This <strong>Sandakphu Land Rover Package</strong> is ideal for those who want to enjoy the breathtaking beauty of the Himalayan mountain range without the strenuous trek.</p>
                      <p>Sandakphu, the highest point (3,636 m) in West Bengal, offers travelers and trekkers a mesmerizing view of the Himalayas, including four of the world's highest peaks: Mt. Everest (8,848 m), Mt. Makalu (8,481 m), Mt. Lhotse (8,516 m), and the stunning Mt. Kanchenjungha (8,586 m). The sight of the snow-capped Mt. Kanchenjungha from Sandakphu is so beautiful that you'll want to visit again and again. The charm of Sandakphu is truly addictive.</p>
                      <p>The <strong>Sandakphu Tour</strong> arranged by Adorable vacation LLP covers some of the best spots along the trekking route, making every part of the journey wonderful and thrilling. In addition to the <strong>Sandakphu Land Rover Tour Package</strong>.
                      </p>
                      <br />
                      <div className="content-desktop">
                        <center><img src={sightseen1ImageURL} alt="..." /></center>
                      </div>

                      <div className="content-mobile2">
                        <center><img src={sightseen1ImageURL} style={{ width: '320px' }} alt="..." /></center>
                      </div>
                      <br />
                      <p>
                        Destination Covered in <strong>Sandakphu Land Rover Tour Package</strong> : 1 Night Manebhanjan | 1 Night Sandakphu | 1 Night Tumling
                      </p>
                      <p><b>Attractions of Sandakphu Land Rover Package</b> : Manebhanjan, Chitrey, Meghma, Tumling, Tunglu, Gairibas, Kayakata, Kalipokhri, Sandakphu, Phalut</p>
                      <p><b>Pickup & Drop</b> : New Jalpaiguri Station [NJP] | Siliguri Station | Bagdogra Airport [IXB]</p>
                      <div id="no-more-tables">
                        <table class="col-md-12 table-bordered table-striped table-condensed cf">
                          <tbody>

                            <tr>
                              <td>Places Covered in <strong>Sandakpuh Trek Package</strong></td>
                              <td>1 Night Manebhanjan | 1 Night Sandakphu | 1 Night Tumling</td>
                            </tr>
                            <tr>
                              <td><strong>Sandakphu Trek Package </strong>Duration</td>
                              <td>03 Nights | 04 Days</td>
                            </tr>
                            <tr>
                              <td><strong>Sandakphu Tour Package </strong> Pickup & Drop Point</td>
                              <td>NJP/Siliguri Station / Bagdogra Airport [IXB]</td>
                            </tr>
                            <tr>
                              <td>
                                Altitude of Manebhanjan
                              </td>
                              <td>1,928 m</td>
                            </tr>
                            <tr>
                              <td>
                                Altitude of Sandakphu
                              </td>
                              <td>3,636 m</td>
                            </tr>
                            <tr>
                              <td>
                                Altitude of Phalut
                              </td>
                              <td>3,595 m</td>
                            </tr>
                            <tr>
                              <td>
                                Altitude of Tumling
                              </td>
                              <td>2,970 m</td>
                            </tr>
                            <tr>
                              <td>
                                Altitude of Kalipokhri
                              </td>
                              <td>3,186 m</td>
                            </tr>
                            <tr>
                              <td>
                                Altitude of Gairibas
                              </td>
                              <td>2,621 m</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>Our <strong>Sandakphu Land Rover Package Tour</strong> is personalized with a private vehicle and Land Rover based on your chosen date. This tour depends on</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}> Our <strong>Sandakphu Land Rover Package Tour</strong> is personalized with a private vehicle and Land Rover based on your chosen date. This tour depends on</h4>
                </div>
                <div className="card-body">
                  <p>From Sandakphu, you can see four of the world's highest peaks:</p>
                  <ul>
                    <li>•	The itinerary you choose and the duration of the Sandakphu Tour</li><br />
                    <li>•	The number of guests traveling or trekking</li><br />
                    <br />
                    <div className="content-desktop">
                      <center><img src={sightseen2ImageURL} alt="..." /></center>
                    </div>

                    <div className="content-mobile2">
                      <center><img src={sightseen2ImageURL} style={{ width: '320px' }} alt="..." /></center>
                    </div>
                    <br />
                    <li>•	Your choice of hotel or homestay category</li><br />
                    <li>•	Your choice of transport (Mahindra Bolero, Jeep, or Land Rover)</li><br />
                    <li>•	The activities you choose (trekking, hiking, birding)</li><br />
                  </ul>
                  <p><b>Please Note :</b>  Each person opting for this <strong>Sandakphu Land Rover Package</strong> or <strong>Sandakphu Trekking</strong></p>
                  <p><b>Package</b> must carry an original Voter ID card, Passport, or Driving License (PAN card and Aadhar Card are not valid), along with four photocopies and three passport-size photos. For children, you can bring a School ID and Birth Certificate along with photos.</p>
                  <p><b>What Documents Do You Need for the Sandakphu Land Rover Tour?</b></p>
                  <p>If you are traveling to <b>Sandakphu Phalut Land Rover Tour</b>, you must bring the original and a photocopy of a government-approved ID like a Voter Card, Aadhar Card, Driving License, or Passport. You need to show these to the Adorable Vacation LLP executive before boarding the Land Rover at Manebhanjan. Our executive will prepare the necessary permits and tickets to enter Singalila National Park. You will also need to show your documents and pass at various checkpoints guarded by SSB Jawans.</p>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>IMPORTANT ALTITUDES FOR <strong>SANDAKPHU PHALUT TREKKING AND TOUR BY LAND ROVER</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>IMPORTANT ALTITUDES FOR <strong>SANDAKPHU PHALUT TREKKING AND TOUR BY LAND ROVER</strong></h4>
                </div>
                <div className="card-body">
                  <div id="no-more-tables">
                    <table class="col-md-12 table-bordered table-striped table-condensed cf">
                      <tbody>
                        <tr>
                          <td>
                            Altitude of Manebhanjan
                          </td>
                          <td>1,928 m</td>
                        </tr>
                        <tr>
                          <td>
                            Altitude of Gorkhey
                          </td>
                          <td>2,343 m</td>
                        </tr>
                        <tr>
                          <td>
                            Altitude of Rammam
                          </td>
                          <td>2,560 m</td>
                        </tr>
                        <tr>
                          <td>
                            Altitude of Meghma
                          </td>
                          <td>3,636 m</td>
                        </tr>
                        <tr>
                          <td>
                            Altitude of Gairibas
                          </td>
                          <td>2,600 m</td>
                        </tr>
                        <tr>
                          <td>
                            Altitude of Tumling
                          </td>
                          <td>2,970 m</td>
                        </tr>
                        <tr>
                          <td>
                            Altitude of Tonglu
                          </td>
                          <td>3,070 m</td>
                        </tr>
                        <tr>
                          <td>
                            Altitude of Kalipokhri
                          </td>
                          <td>3,189 m</td>
                        </tr>
                        <tr>
                          <td>
                            Altitude of Phalut
                          </td>
                          <td>3,595 m</td>
                        </tr>
                        <tr>
                          <td>
                            Altitude of Sandakphu
                          </td>
                          <td>3,636 m</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>Distance and Travel Time for <strong>Sandakphu Tour by Land Rover from Manebhanjan</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>Distance and Travel Time for Sandakphu Tour by Land Rover from Manebhanjan</h4>
                </div>
                <br />
                <div className="card-body">
                  <div id="no-more-tables">
                    <table class="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead class="cf">
                        <tr className="table-danger">
                          <th>ORIGIN</th>
                          <th>DESTINATION</th>
                          <th>DISTANCE</th>
                          <th>TRAVEL TIME BY LAND ROVER</th>
                          <th>TRAVEL TIME BY TREK</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td data-title="ORIGIN">Manebhanjan</td>
                          <td data-title="DESTINATION">Chitre</td>
                          <td data-title="DISTANCE">3 km</td>
                          <td data-title="LAND ROVER">20 min</td>
                          <td data-title="TREK TIME">1.5 hrs</td>
                        </tr>
                        <tr>
                          <td data-title="ORIGIN">Chitre</td>
                          <td data-title="DESTINATION">Tumling</td>
                          <td data-title="DISTANCE">11 km</td>
                          <td data-title="LAND ROVER">1 hr</td>
                          <td data-title="TREK TIME">8-10 hrs</td>
                        </tr>
                        <tr>
                          <td data-title="ORIGIN">Tumling</td>
                          <td data-title="DESTINATION">Gairibas</td>
                          <td data-title="DISTANCE">9 km</td>
                          <td data-title="LAND ROVER">30 min</td>
                          <td data-title="TREK TIME">4-5 hrs</td>
                        </tr>
                        <tr>
                          <td data-title="ORIGIN">Gairibas</td>
                          <td data-title="DESTINATION">Kalipokhri</td>
                          <td data-title="DISTANCE">6 km</td>
                          <td data-title="LAND ROVER">30 min</td>
                          <td data-title="TREK TIME">3 hrs</td>
                        </tr>
                        <tr>
                          <td data-title="ORIGIN">Kalipokhri</td>
                          <td data-title="DESTINATION">Sandakphu</td>
                          <td data-title="DISTANCE">6 km</td>
                          <td data-title="LAND ROVER">40 min</td>
                          <td data-title="TREK TIME">3 hrs</td>
                        </tr>
                        <tr>
                          <td data-title="ORIGIN">Manebhanjan</td>
                          <td data-title="DESTINATION">Sandakphu</td>
                          <td data-title="DISTANCE">32 km</td>
                          <td data-title="LAND ROVER">3 hrs</td>
                          <td data-title="TREK TIME">2 to 3 days</td>
                        </tr>
                        <tr>
                          <td data-title="ORIGIN">Sandakphu</td>
                          <td data-title="DESTINATION">Phalut</td>
                          <td data-title="DISTANCE">20.6 km</td>
                          <td data-title="LAND ROVER">1.30 hrs</td>
                          <td data-title="TREK TIME">5/6 hrs</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br /> <br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>YOU CAN BOOK A TRAIN TICKET TO REACH NJP/SILIGURI FOR<strong> SANDAKPHU PHALUT TREKKING PACKAGE TOUR</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>YOU CAN BOOK A TRAIN TICKET TO REACH NJP/SILIGURI FOR<strong> SANDAKPHU PHALUT TREKKING PACKAGE TOUR</strong></h4>
                </div>
                <br />

                <div className="card-body">
                  <div id="no-more-tables">
                    <table class="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead class="cf">
                        <tr className="table-danger">
                          <th>TRAIN NO</th>
                          <th>TRAIN TIME</th>
                          <th>DEP. STN</th>
                          <th>DEP. TIME</th>
                          <th>ARR. STN</th>
                          <th>ARR. TIME</th>
                          <th>FREQUENCY</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td data-title="TRAIN NO">12343</td>
                          <td data-title="TRAIN TIME">Darjeeling mail</td>
                          <td data-title="DEP. STN">Sealdah (SDAH)</td>
                          <td data-title="DEP. TIME">22:05</td>
                          <td data-title="ARR. STN">New Jalpaiguri Jn (NJP)</td>
                          <td data-title="ARR. TIME">08:15</td>
                          <td data-title="FREQUENCY">Daily</td>
                        </tr>
                        <tr>
                          <td data-title="TRAIN NO">12377</td>
                          <td data-title="TRAIN TIME">Padatik Express</td>
                          <td data-title="DEP. STN">Sealdah (SDAH)</td>
                          <td data-title="DEP. TIME">23:00</td>
                          <td data-title="ARR. STN">New Jalpaiguri Jn (NJP)</td>
                          <td data-title="ARR. TIME">09:15</td>
                          <td data-title="FREQUENCY">Daily</td>
                        </tr>
                        <tr>
                          <td data-title="TRAIN NO">13149</td>
                          <td data-title="TRAIN TIME">Kanchan Kanya Express</td>
                          <td data-title="DEP. STN">Sealdah (SDAH)</td>
                          <td data-title="DEP. TIME">20:30</td>
                          <td data-title="ARR. STN">New Mal Jn (NMZ)</td>
                          <td data-title="ARR. TIME">09:31</td>
                          <td data-title="FREQUENCY">Daily</td>
                        </tr>
                        <tr>
                          <td data-title="TRAIN NO">13147</td>
                          <td data-title="TRAIN TIME">Uttar Banga Express</td>
                          <td data-title="DEP. STN">Sealdah (SDAH)</td>
                          <td data-title="DEP. TIME">19:35</td>
                          <td data-title="ARR. STN">Falakata (FLK)</td>
                          <td data-title="ARR. TIME">08:38</td>
                          <td data-title="FREQUENCY">Daily</td>
                        </tr>
                        <tr>
                          <td data-title="TRAIN NO">13141</td>
                          <td data-title="TRAIN TIME">Teesta Torsa Express</td>
                          <td data-title="DEP. STN">Sealdah (SDAH)</td>
                          <td data-title="DEP. TIME">13:40</td>
                          <td data-title="ARR. STN">New Maynaguri (NMX)</td>
                          <td data-title="ARR. TIME">04:22</td>
                          <td data-title="FREQUENCY">Daily</td>
                        </tr>
                        <tr>
                          <td data-title="TRAIN NO">12345</td>
                          <td data-title="TRAIN TIME">SaraiGhat Express</td>
                          <td data-title="DEP. STN">Howrah (HWH)</td>
                          <td data-title="DEP. TIME">15:50</td>
                          <td data-title="ARR. STN">New Jalpaiguri (NJP)</td>
                          <td data-title="ARR. TIME">01:40</td>
                          <td data-title="FREQUENCY">Daily</td>
                        </tr>
                        <tr>
                          <td data-title="TRAIN NO">12517</td>
                          <td data-title="TRAIN TIME">Garib Rath</td>
                          <td data-title="DEP. STN">Kolkata (KOOA)</td>
                          <td data-title="DEP. TIME">21:40</td>
                          <td data-title="ARR. STN">New Jalpaiguri (NJP)</td>
                          <td data-title="ARR. TIME">07:45</td>
                          <td data-title="FREQUENCY">Sun,Thurs</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br />
                      <div className="content-desktop">
                        <center><img src={sightseen3ImageURL} alt="..." /></center>
                      </div>

                      <div className="content-mobile2">
                        <center><img src={sightseen3ImageURL} style={{ width: '320px' }} alt="..." /></center>
                      </div>
                </div>
              </div>

              <br />
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h3 className="content-desktop" style={{ fontSize: "2.0vw" }}>
                        DAY WISE ITINERARY FOR <strong>SANDAKPHU PHALUT LAND ROVER TOUR</strong>
                      </h3>
                      <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>
                        DAY WISE ITINERARY FOR <strong>SANDAKPHU PHALUT LAND ROVER TOUR</strong>
                      </h3>
                    </div>
                  </div>
                </div>

                <br />
                <div className="row">
                  <div
                    className="accordion"
                    id="accordionPanelsStayOpen"
                  >
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="day1"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseOne"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseOne"
                        >
                          DAY 1 : NJP to Manebhanjan 85 Kms (4-5hrs Drive ):- (Only Accommodation)
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingOne"
                      >
                        <div className="accordion-body">
                          <p>Meet & greet on arrival at New Jalpaiguri and drive Manebhanjan Via Mirik also known as a land of land rovers. After check in to the hotels free for personal activities and evening is free for local market visit by walk.</p>
                          <p>Ovenight Stay at Manebhanjan </p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay1ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay1ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="day2">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseTwo"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseTwo"
                        >
                          DAY 2 : Manebhanjan to Sandakphu (3 hrs)
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseTwo"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingTwo"
                      >
                        <div className="accordion-body">
                          <p>After breakfast starts for Sandakphu The drive from Manebhanjan is very steep and winding at several places.Only 4-wheel drive vehicles can negotiate this entire boulder stretch, normal cars can't make it. The journey from Manebhanjan will be slow. From here you can see  overlooking 180 degree Mt Kanchenjunga, Mt. Everest and many Himalyan peaks.</p>
                          <p>Ovenight Stay at Sandakphu.</p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay2ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay2ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>

                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="day3">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseThree"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          DAY 3 : Sandakphu To Phalut day excursion and transfer to Tumling (6 hrs activities)
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseThree"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingThree"
                      >
                        <div className="accordion-body">
                          <p>(Phalut Excursion is extra payment basis of Rs.3500/- per car)
                            After breakfast start for Phalut. Phalut is 20 kms from Sandakphu about 2.5 hrs Journey by Jeep.</p>
                          <p> It is The highest altitude place in Westbengal, from Phalut you can enjoy the panoramic view of Mighty Kanchenjunga and Mt. Everest.Afterwards you will strats towards Tumling / Tonglu.
                            Evening is free for personal activities.
                          </p>
                          <p>Ovenight Stay at Tumling.</p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay3ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay3ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="day4">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseFour"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseFour"
                        >
                          DAY 4 : Tumling to  Manebhanjan Drop
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseFour"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingFour"
                      >
                        <div className="accordion-body">
                          <p>After breakfast transfer to Manebhanjan with memories of a lifetime</p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay4ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay4ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              {/* inclusion start  */}

              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">{<InclusionExclusion />}</div>
                </div>
              </div>

              {/* inclusion End  */}
              <br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>WHAT ARE THE TOP ATTRACTIONS IN THE <strong>SANDAKPHU PHALUT TREKKING AND TOUR BY LAND ROVER</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>WHAT ARE THE TOP ATTRACTIONS IN THE <strong>SANDAKPHU PHALUT TREKKING AND TOUR BY LAND ROVER</strong></h4>
                </div>
                <div className="card-body">
                  <p><b>Trekking from Manebhanjan to Sandakphu (32km):</b></p>
                  <p>Trekking is the main highlight of your <strong>Sandakphu Tour</strong>. The route through Singalila Wildlife Sanctuary is unforgettable. The trek from Manebhanjan to Sandakphu is about 32 km and usually takes 3-4 days. You can stay at Tumling or Tonglu, about 16 km from Manebhanjan, which can be reached in one day if you start early. The next day, you can continue from Tumling to Kalaphokhri or even Sandakphu.</p>
                  <p><b>Trekking from Sandakphu to Phalut (20km):</b></p>
                  <p>Phalut, the highest point in West Bengal, offers a stunning view of Mt. Everest. This is the only place in India where you can see Mount Everest so closely and clearly. The trek from Sandakphu to Phalut is about 20 km and must be completed in one day since there is no accommodation along the way. In Phalut, there is a basic trekkers' hut for an overnight stay.</p>
                  <br />
                      <div className="content-desktop">
                        <center><img src={sightseen4ImageURL} alt="..." /></center>
                      </div>

                      <div className="content-mobile2">
                        <center><img src={sightseen4ImageURL} style={{ width: '320px' }} alt="..." /></center>
                      </div>
                </div>
              </div>

              <br /><br />


              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h2>
                        Frequently Ask <span>Questions</span>
                      </h2>
                      <h4><strong>SANDAKPHU PHALUT TOUR PLAN BY LAND ROVER</strong></h4>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div
                    className="accordion accordion-flush faQStyle"
                    id="accordionFlushExample"
                  >
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingOne">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseOne"
                          aria-expanded="false"
                          aria-controls="flush-collapseOne"
                        >
                          How long does it take to reach Sandakphu from Manebhanjan by Land Rover?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseOne"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingOne"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>The 32 km trip from Manebhanjan to Sandakphu takes about 2.5 hours by Land Rover, including two 15-minute stops at Tumling and Kalipokhri. The route is steep, so only a Land Rover can navigate it easily.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingTwo">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseTwo"
                          aria-expanded="false"
                          aria-controls="flush-collapseTwo"
                        >
                          How many Land Rovers are available for the Sandakphu Phalut Tour from Manebhanjan?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseTwo"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingTwo"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>There are about 40 operational Land Rovers in Manebhanjan. The Singalila Land Rover Owner's Welfare Association manages these vehicles.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingThree">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseThree"
                          aria-expanded="false"
                          aria-controls="flush-collapseThree"
                        >
                          How old are the Land Rovers in Sandakphu Tour Package?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseThree"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingThree"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>The Land Rovers in Sandakphu are over 70 years old, with the first one introduced in 1958. Some records suggest they were introduced in the 1940s by the British. Despite their age, they continue to serve travelers reliably.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingFour">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFour"
                          aria-expanded="false"
                          aria-controls="flush-collapseFour"
                        >
                          How many people can fit in a Land Rover at Sandakphu Package Tour?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseFour"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingFour"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>According to the Land Rover Owners Association, six people can fit in a Land Rover: one in the front seat and five in the back. Luggage can be stored on the roof.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingFive">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFive"
                          aria-expanded="false"
                          aria-controls="flush-collapseFive"
                        >
                          Where does the Land Rover stay during the trip?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseFive"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingFive"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>After reaching Sandakphu, the Land Rover will drop you at your pre-booked hotel or trekkers hut. The Land Rover stays there for the rest of the day. The driver usually goes to a nearby settlement for the night and returns in the morning.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingSix">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseSix"
                          aria-expanded="false"
                          aria-controls="flush-collapseSix"
                        >
                          When is the best time for scenic views during the Sandakphu Phalut Land Rover Tour?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseSix"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingSix"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>The best time for scenic views is from the end of October to the end of November. December to March offers moderate views, while April can be foggy.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingSeven">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseSeven"
                          aria-expanded="false"
                          aria-controls="flush-collapseSeven"
                        >
                          Can you see Mt. Kanchenjunga and other Himalayan peaks in December?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseSeven"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingSeven"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>October to November is the best time to see the Himalayan peaks clearly. In December, you can still expect good visibility, with about 70% clarity.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingEight">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseEight"
                          aria-expanded="false"
                          aria-controls="flush-collapseEight"
                        >
                          Do foreigners need a double entry visa to trek Sandakphu Phalut Trekking and Tour by Land Rover?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseEight"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingEight"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>No, a double entry visa is not required. The trek crosses the Nepal border several times, but there are no strict boundary checks.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingNine">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseNine"
                          aria-expanded="false"
                          aria-controls="flush-collapseNine"
                        >
                          What is the temperature in Sandakphu in December?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseNine"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingNine"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>The temperature in December can drop to zero or below, so be prepared for freezing conditions with appropriate clothing and equipment.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingTen">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseTen"
                          aria-expanded="false"
                          aria-controls="flush-collapseTen"
                        >
                          How should you prepare for the Sandakphu land Rover Tour & Sandakphu Trek?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseTen"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingTen"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>The trek involves flat walks and some steep climbs. You should prepare for at least a month and ensure good fitness to avoid any issues.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingEleven">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseEleven"
                          aria-expanded="false"
                          aria-controls="flush-collapseEleven"
                        >
                          How long does it take to reach Sandakphu from Manebhanjan by car?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseEleven"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingEleven"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>It takes about 3-4 hours by Land Rover, with the last 8 km from Kalipokhri to Sandakphu being particularly steep and taking around 1 hour.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingTwelve">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseTwelve"
                          aria-expanded="false"
                          aria-controls="flush-collapseTwelve"
                        >
                          What should you pack for the Sandakphu Tour by Land Rover?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseTwelve"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingTwelve"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>Essential items include trekking hats, warm clothing, sunscreen, sunglasses, gloves, trekking shoes, a sleeping bag, a backpack, snacks, and a personal medical kit.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingThirteen">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseThirteen"
                          aria-expanded="false"
                          aria-controls="flush-collapseThirteen"
                        >
                          When is the best season for the Sandakphu Phalut Land Rover Tour?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseThirteen"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingThirteen"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>The best season is from October to November for clear views. You can also experience trekking and jeep rides together by starting your trek from Tumling.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingFourteen">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFourteen"
                          aria-expanded="false"
                          aria-controls="flush-collapseFourteen"
                        >
                          Is there electricity in Sandakphu?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseFourteen"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingFourteen"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>Yes, but there are low voltage issues. Some guest houses use solar power with limited availability of electricity from 6 pm to 8 am.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingFifteen">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFifteen"
                          aria-expanded="false"
                          aria-controls="flush-collapseFifteen"
                        >
                          Can kids or senior citizens travel to Sandakphu Package?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseFifteen"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingFifteen"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>It's not recommended for children under 5 years and senior citizens over 65 years due to the cold weather and high altitude. Travelers with heart or lung issues should consult a doctor.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingSixteen">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseSixteen"
                          aria-expanded="false"
                          aria-controls="flush-collapseSixteen"
                        >
                          Can you trek to Phalut from Sandakphu?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseSixteen"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingSixteen"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>Yes, but you need to consult your tour operator and stay at Phalut for at least one night with a guide.</p>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h3>
                        <b>
                          Sandakphu <span className="tomato">Tour Reviews</span>
                        </b>
                      </h3>
                      <h4>
                        <i>"Your Experience is our Marketing"</i>
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="row row-cols-1 ">
                  <div id="testimonials">
                    <div className="testimonial-box-container">
                      {reviewersDataView}
                    </div>
                  </div>
                </div>
              </div>
            </div >
            <div className="col-md-3">
              <BookingBox />
            </div>
          </div >
        </div >
      </>
    );
  }
}
export default Sandakphu_land_rover_Package;
