import React, { Component } from 'react';
import Slider from "react-slick";
import ApiURL from "../../api/ApiURL";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


class DomesticExplorer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            DestinationData: []
        }
    }

    componentDidMount() {
        const destinationDataList = [
            {
                ImageName: "Sikkim.webp",
                AllternateText: "...",
                Title: "Sikkim",
                Description: "Get ready for a whirlwind journey through Gangtok, Pelling, and Darjeeling. These three incredibly beautiful destinations."
            },
            {
                ImageName: "Arunachal.webp",
                AllternateText: "...",
                Title: "Arunachal Pradesh",
                Description: "Arunachal Pradesh is known as the \"Land of the Dawn-lit Mountains,\" which greets travellers with some unique experiences."
            },
            {
                ImageName: "Kaziranga.webp",
                AllternateText: "...",
                Title: "Kaziranga",
                Description: "A tour of the beautiful forest of Kaziranga always fascinates the wildlife lovers with its unique and varied flora and fauna."
            },
            {
                ImageName: "Andaman.webp",
                AllternateText: "...",
                Title: "Andaman",
                Description: "The perfect combination of thrill and adventure, with views of serene beaches, picturesque shores, and a lot of water activities."
            },
            {
                ImageName: "Kashmir.webp",
                AllternateText: "...",
                Title: "Kashmir",
                Description: "Dotted with beautiful lakes and surrounded by magnificent valleys, Kashmir is sure to wash away all the stress."            },
            {
                ImageName: "Ladakh.webp",
                AllternateText: "...",
                Title: "Ladakh",
                Description: "The unique thing about the Ladakh terrain mountains,lakes, rivers, waterfalls in one of the beautiful and dynamic geography."
            },
            {
                ImageName: "Kerala.webp",
                AllternateText: "...",
                Title: "Kerala",
                Description: "Haven to azure canals, lush coconut groves, and serene backwaters, Kerala has gained a special place in the heart of world tourism."
            },
            {
                ImageName: "Meghalaya.webp",
                AllternateText: "...",
                Title: "Meghalaya",
                Description: "Set out on a 4-day tour of Mystic Meghalaya, a journey that promises to reveal the hidden treasures of India's."
            },

        ];

        this.setState({ DestinationData: destinationDataList })
    }

    render() {
        const settings = {
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const destinationSliderList = this.state.DestinationData;
        const MyView = destinationSliderList.map((sliderItem, i) => {
            console.log(sliderItem);
            const sliderImageURL = ApiURL.DomesticExplorerImageURL + "/" + sliderItem.ImageName;
            return (
                <div className="cards-wrapper" key={i}>
                    <div className="card">
                        <div className="image-wrapper">
                            <img src={sliderImageURL} alt={sliderItem.AllternateText} />
                        </div>
                        <div className="card-body">
                            <h5 className="card-title">{sliderItem.Title}</h5>
                            <p className="card-text">{sliderItem.Description}</p>
                        </div>
                    </div>
                    <div className="clearfix"></div>
                </div>
            )
        })

        return (
            <>
                <div className="container-fluid py-5">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="site-heading text-center">
                                <h2>Domestic <span>Explorer</span></h2>
                                <h4>Inside India</h4>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                            <div className="carousel-inner-two">
                                <div className="carousel-item active">
                                    <Slider {...settings}>
                                        {MyView}
                                    </Slider>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </>
        );
    }
}

export default DomesticExplorer;