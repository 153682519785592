import React, { Component } from 'react';
import MainHeader from '../../../components/common/MainHeader';
import FooterDesktop from '../../../components/common/FooterDesktop';
import Arunachal_10N_11D_Package from '../../../components/package/Meghalaya/Arunachal_10N_11D_Package';

class Arunachal_10N_11D_Page extends Component {
    constructor() {
        super();
        this.state = {
            TrendingPackagesData: [],
            ReviewersData: [],
        }
    }

    componentDidMount() {
        window.scroll(0, 0);
        const TrendingPackageDataList = [
            {
                Title: "Untouched Manipur",
                ImageName: "5N-6D-manipur.webp",
                AllternateText: "...",
                Description:"2N Kohima | 1N Imphal | 1N Moreh | 1N Imphal | Private Transfer | Deluxe Hotel  ",
                PricePerNight: "24,990",
                NoOfTours: 47,
                NoOfDeparture: 40,
                Include: "EX-GAU",
                MutedText: "*Per head / Min 6 Pax"
            },
            {
                Title: "Peaceful Meghalaya",
                ImageName: "6N-7D-meghalaya.webp",
                AllternateText: "...",
                Description: "2N Kaziranga | 3N Shillong | 1N Guwahati | Private Transfer | Deluxe Hotel",
                PricePerNight: "22,800",
                NoOfTours: 43,
                NoOfDeparture: 38,
                Include: "EX-GAU",
                MutedText: "*Per head / Min 6 Pax"
            },
            {
                Title: "Unexplored Manipur",
                ImageName: "7N-8D-meghalaya.webp",
                AllternateText: "...",
                Description: "2N Kaziranga | 2N Kohima | 3N Imphal | Private Transfer | Deluxe Hotel",
                PricePerNight: "27,900",
                NoOfTours: 44,
                NoOfDeparture: 37,
                Include: "EX-GAU",
                MutedText: "*Per head / Min 6 Pax"
            },

            {
                Title: "Extended Meghalaya",
                ImageName: "8N-9D-meghalaya.webp",
                AllternateText: "...",
                Description: "2N Kaziranga | 3N Shillong | 1N Cherrapunjee...more | Private Transfer | Deluxe Hotel",
                PricePerNight: "33,700",
                NoOfTours: 46,
                NoOfDeparture: 39,
                Include: "EX-GAU",
                MutedText: "*Per head / Min 6 Pax"
            },
            {
                Title: "Glimpses of North East",
                ImageName: "10N-11D-sangti-vally.webp",
                AllternateText: "...",
                Description: "2N Guwahati | 1N Kaziranga | 1N Bhalukpong...more | Private Transfer | Deluxe Hotel",
                PricePerNight: "28,300",
                NoOfTours: 44,
                NoOfDeparture: 37,
                Include: "EX-GAU",
                MutedText: "*Per head / Min 6 Pax"
            },
            {
                Title: "Extended North East",
                ImageName: "11N-12D-madhuri.webp",
                AllternateText: "...",
                Description: "2N Guwahati | 3N Shillong | 1N Kaziranga...more | Private Transfer | Deluxe Hotel",
                PricePerNight: "53,899",
                NoOfTours: 47,
                NoOfDeparture: 40,
                Include: "EX-GAU",
                MutedText: "*Per head / Min 6 Pax"
            }

        ];

        const reviewersDataList = [
            {
                Name: "Kedar Kamlesh",
                ImageName: "Kedar-Kamlesh.webp",
                AllternateText: "...",
                Location: "@Delhi",
                Comment: "First of all i thank the team of Adorable Vacation for making our trip to north east a memorable one. They planned the tour well and assistance was provided at every step. If planning a trip to north east i would refer Adorable Vacation. Special mention to Sonali and the driver Vivek for all the help during the entire trip.",
            },
            {
                Name: "Darshana Pillai",
                ImageName: "Darshana-Pillai.webp",
                AllternateText: "...",
                Location: "@Chennai",
                Comment: "We recently completed our Tawang trip with Adorable Vacation. It was a really great experience.Right from the start of planning, we received great help and support from Noorul. He was always ready to clarify our doubts and made our trip hassle free. I am happy that we are returning with many good memories. I would definitely recommend Adorable Vacation to my friends and relatives.",
            },
            {
                Name: "Karthik Nair",
                ImageName: "Kartik-Nair.webp",
                AllternateText: "...",
                Location: "@Hyderabad",
                Comment: "Was a part of December 16 Assam-Meghalaya tour. It was really amazing. Had a wonderful time exploring the North eastern part of india. Full credit to Miss Anushka for managing the Trip so well. She bought a personal touch where in every one of our group members felt like a family.Kudos to the entire team.",
            },
            {
                Name: "Sanjib Bannerjee",
                ImageName: "Sanjib-Bannerjee.webp",
                AllternateText: "...",
                Location: "@Bengaluru",
                Comment: "It was really a great experience with Adorable Vacation. This was my 3rd trip with them. Mr Akash is a very nice person, He understands clients need very well and manage the tour accordingly. The entire team is always available at your service whenever you need them. I would like to thank Adorable Team for making my trip memorable one.",
            },
        ]

        this.setState({
            TrendingPackagesData: TrendingPackageDataList,
            ReviewersData: reviewersDataList
        })
    }

    render() {
        return (
            <>
                <MainHeader />
                <Arunachal_10N_11D_Package
                    TrendingPackagesData={this.state.TrendingPackagesData}
                    ReviewersData={this.state.ReviewersData}
                />
                <FooterDesktop />
            </>
        );
    }
}

export default Arunachal_10N_11D_Page;