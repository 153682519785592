import React, {Component} from 'react';
import Contact from '../components/common/Contact';
import FooterDesktop from '../components/common/FooterDesktop';
import MainHeader from '../components/common/MainHeader';

class ContactPage extends Component {
    render() {
        return (
            <>
            
                <MainHeader/>
                
                <Contact />

                <FooterDesktop />
            </>
        );
    }
}

export default ContactPage;
