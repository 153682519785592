class validation {
    static NameRegx=/^[A-Za-z]+$/;
    static MobileRegx=/^\d{10}$/;
    static EmailRegx=/^([\w]+)@([\w]+)((\.(\w){2,3})+)$/gi;

    //static NameRegx=/^[A-Za-z\'\s\.\:\-]+$/;
    //static MobileRegx=/^\d{10}$/;
    //static EmailRegx=/^([\w\.\-]+)@([\w\-]+)((\.(\w){2,3})+)$/gi;
    //static EmailRegx=/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
}

export default validation;