import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import ApiURL from "../../../api/ApiURL";
import InclusionExclusion from '../Ladakh/InclusionExclusion';
import BookingBox from '../BookingBox';
class Leh_Ladakh_Turtuk_Package extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customActiveTab: 'tab1',
            daywiseActiveTab: 'day1'
        }
    }

    handleCustomTabClick = (tab) => {
        this.setState({
            customActiveTab: tab,
        });
    };

    handleDayWiseTabClick = (tab) => {
        this.setState({
            daywiseActiveTab: tab,
        });
    };
    handleClick = () => {
        const currentUrl = window.location.href;
        const pageTitle = document.title;
        const message = `Hi, Please send about the Tour Package details "${pageTitle}"`;

        const whatsappLink = `https://api.whatsapp.com/send?phone=+9163676037&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
        window.open(whatsappLink, '_blank');
    };
    render() {
        const { customActiveTab, daywiseActiveTab } = this.state;
        const packageTopBannerImageURL = ApiURL.Leh_Ladakh_Turtuk_Package + "/Leh-Ladakh-7N-8D-Package-Tour-from-Mumbai.webp";
        const packageTopBanner2ImageURL = ApiURL.Leh_Ladakh_Turtuk_Package + "/Leh-Ladakh-Tour-Packages-from-Mumbai.webp";
        const packageDestinationBannerImageURL = ApiURL.Leh_Ladakh_Turtuk_Package + "/Sikkim-Travel-Agents.webp";
        const brandedTourackageImage_1_URL = ApiURL.Leh_Ladakh_Turtuk_Package + "/The-Grand-Dragon-Leh.webp";
        const brandedTourackageImage_2_URL = ApiURL.Leh_Ladakh_Turtuk_Package + "/The-Chospa-Leh-Hotel.webp";
        const brandedTourackageImage_3_URL = ApiURL.Leh_Ladakh_Turtuk_Package + "/The-Abduz-Leh.webp";
        const Topbanner0ImageURL = ApiURL.Leh_Ladakh_Turtuk_Package + "/Ladakh-Royal-Enfield-Ride.webp";
        const Topbanner1ImageURL = ApiURL.Leh_Ladakh_Turtuk_Package + "/Ladakh-Camel-Ride.webp";
        const Topbanner2ImageURL = ApiURL.Leh_Ladakh_Turtuk_Package + "/Leh-Ladakh-River-Rafting.webp";
        const Topbanner3ImageURL = ApiURL.Leh_Ladakh_Turtuk_Package + "/Leh-Ladakh-Quad-Biking.webp";
        const TopbannerImageURL = ApiURL.Leh_Ladakh_Turtuk_Package + "/Leh-Ladakh-Airport.webp";
        const navDay1ImageURL = ApiURL.Leh_Ladakh_Turtuk_Package + "/Leh-Ladakh-Tour-Package-From-Mumbai.webp";
        const navDay2ImageURL = ApiURL.Leh_Ladakh_Turtuk_Package + "/Leh-Ladakh-Tour-From-Mumbai.webp";
        const navDay3ImageURL = ApiURL.Leh_Ladakh_Turtuk_Package + "/Leh-Ladakh-Package-from-Mumbai.webp";
        const navDay4ImageURL = ApiURL.Leh_Ladakh_Turtuk_Package + "/Leh-Ladakh-Package-Tour-From-Mumbai.webp";
        const navDay5ImageURL = ApiURL.Leh_Ladakh_Turtuk_Package + "/Leh-Ladakh-Package-Cost-From-Mumbai.webp";
        const navDay6ImageURL = ApiURL.Leh_Ladakh_Turtuk_Package + "/Leh-Ladakh-Family-Package-From-Mumbai.webp";
        const navDay7ImageURL = ApiURL.Leh_Ladakh_Turtuk_Package + "/Leh-ladakh-Packages-From-Mumbai.webp";
        const navDay8ImageURL = ApiURL.Leh_Ladakh_Turtuk_Package + "/Leh-Ladakh-Trip-Packages-From-Mumbai.webp";
        const desktopflightImageURL = ApiURL.Leh_Ladakh_Turtuk_Package + "/Mumbai-to-Leh-Ladakh-Package-with-Flight.webp";
        const mumbai_mobileflightImageURL = ApiURL.Leh_Ladakh_Turtuk_Package + "/Mumbai-to-Leh-Ladakh-Direct-Nonstop-Flight.webp";
        const leh_mobileflightImageURL = ApiURL.Leh_Ladakh_Turtuk_Package + "/Leh-Ladakh-Mumbai-to-Direct-Nonstop-Flight.webp";
        const leh_winter = ApiURL.Leh_Ladakh_Turtuk_Package + "/Leh-Ladakh-Turtuk-Tour-Package.webp";
        const leh_summer = ApiURL.Leh_Ladakh_Turtuk_Package + "/Leh-Ladakh-Turtuk-Package.png";
        const leh_auttum = ApiURL.Leh_Ladakh_Turtuk_Package + "/Leh-Ladakh-Turtuk-Trip-Plan.webp";
        const TrendingPackagesDataList = this.props.TrendingPackagesData;
        const hotPackagesView = TrendingPackagesDataList.length > 0 ?
            (
                TrendingPackagesDataList.map((dataItem, i) => {
                    const hotelImgURL = ApiURL.Leh_Ladakh_Turtuk_Package + "/" + dataItem.ImageName;
                    return (<div className="col-md-12 p-0" key={i}>
                        <div className="card h-100">
                            <img src={hotelImgURL} className="card-img-top" alt={dataItem.AllternateText} />
                            <div className="card-body">
                                <h6 className="card-title">{dataItem.Title} <span> &#8377; {dataItem.PricePerNight}</span></h6>
                                <p className="card-text">
                                    <i className="fa fa-building tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-utensils tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-car-side tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-male tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-camera tomato"></i>
                                </p>
                                <p className="card-text"><span>{dataItem.Description}</span></p>
                                <p>
                                    <span><b className="tomato">{dataItem.NoOfTours} </b>Tours</span> | &nbsp;
                                    <span><b className="tomato">{dataItem.NoOfDeparture} </b>Departures</span> | &nbsp;
                                    <span><b className="sdf-blue"> {dataItem.Include}</b></span>
                                </p>
                            </div>
                            <div className="card-footer">
                                <small className="text-muted"><strong className="tomato">{dataItem.MutedText}</strong></small>
                                <a href="#" className="btn btn-success btn-sm float-end" onClick={this.handleClick}>
                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                </a>
                            </div>
                        </div>
                    </div>)
                })
            ) : (<div></div>)


        const settings = {
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const reviewersDataList = this.props.ReviewersData;
        const reviewersDataView = reviewersDataList.map((dataItem, i) => {
            const reviewerImageURL = ApiURL.Leh_Ladakh_Turtuk_Package + "/" + dataItem.ImageName;
            return (
                <div className="testimonial-box" key={i}>
                    <div className="box-top">
                        <div className="profile">
                            <div className="profile-img">
                                <img src={reviewerImageURL} alt="..." />
                            </div>
                            <div className="name-user">
                                <strong>{dataItem.Name}</strong>
                                <span>{dataItem.Location}</span>
                            </div>
                        </div>
                        <div className="reviews">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </div>
                    </div>
                    <div className="client-comment">
                        <p>{dataItem.Comment}</p>
                    </div>
                </div>
            )
        })


        return (
            <>
                <div className="container-fluid">
                    <div className="row">
                        <img src={packageTopBannerImageURL} className="mobile-image2" alt="..." />
                    </div>
                </div>
                <div className="container-fluid custom-container py-3">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="container-fluid custom-container py-1">
                                        <h1 className="content-desktop" style={{ fontSize: "2vw", fontFamily: "Lucida" }}><center><b>Leh Ladakh Package Tour from Mumbai</b> by Flight</center></h1>

                                        <h3 className="content-mobile" style={{ fontSize: "4vw", fontFamily: "Lucida" }}><center><b>Leh Ladakh 7N 8D Tour Packages from Mumbai</b></center>
                                            <hr />
                                        </h3>
                                        <div className="row">
                                            <img src={packageTopBanner2ImageURL} className="mobile-image" alt="..." />

                                        </div>
                                        <hr />
                                        <div className="col-12">
                                            <center>
                                                <big className="text-muted"><strong className="tomato">7N/8D</strong></big>&nbsp;
                                                <i className="fa fa-plane-departure"></i>&nbsp;&nbsp;
                                                <i className="fa fa-arrow-right"></i>&nbsp;Leh(2N)&nbsp;
                                                <i className="fa fa-arrow-right"></i>&nbsp;Nubra Valley(2N)&nbsp;
                                                <i className="fa fa-arrow-right"></i>&nbsp;Pangong Lake(1N)&nbsp;
                                                <i className="fa fa-arrow-right"></i>&nbsp;Tsomoriri Korzok (1N)&nbsp;
                                                <i className="fa fa-arrow-right"></i>&nbsp;Leh(1N)&nbsp;
                                                <i className="fa fa-arrow-right"></i>&nbsp;&nbsp;
                                                <i className="fa fa-plane-departure fa-flip-horizontal"></i>
                                            </center>
                                        </div>
                                        <hr />
                                        <h2 className="content-desktop p-2" style={{ fontSize: "1.5vw" }}><center> For Booking <b>Ladakh Package Tour from Mumbai</b> <a href='tel:9163676037' class="tomato">☎ CALL Mr. Akash Dutta  +91- 91636-76037</a></center></h2>
                                        <h3 className="content-mobile p-1" style={{ fontSize: "4.5vw" }}> For Booking <b>Ladakh Package Tour from Mumbai</b> <a href='tel:9163676037' class="tomato">☎ CALL Mr. Akash Dutta  +91- 91636-76037</a></h3>

                                        <div className="card">
                                            {/* <div className="card-header custHeaer">
                                                <h3 className='content-desktop' style={{ fontSize: "1.5vw" }}>Your <b>LADAKH HOLIDAY ADVENTURE</b></h3>
                                                <h3 className='content-mobile2' style={{ fontSize: "3.5vw" }}>Your <b>LADAKH HOLIDAY ADVENTURE</b></h3>
                                            </div> */}
                                            <div className="card-body">
                                                <p>Planning for <b>Leh Ladakh Package Tour Plan from Mumbai</b>, a picture-perfect place which is enchanting region nestled in the northernmost part of India, renowned for its breathtaking landscapes, rich cultural heritage, and unique way of life. There are some hardcore reasons why Ladakh holds immense allure:</p>
                                                <p><b>Leh Ladakh Turtuk Package Tour</b> is adorned with majestic mountains, sparkling lakes, and vast barren landscapes. The region's rugged terrain offers a stunning backdrop for adventure seekers and nature lovers alike. Package <b>Tour to Leh Ladakh</b> is home to a diverse array of cultures and ethnicities, including the Ladakhi people, Tibetans, and Muslims. This cultural melting pot gives rise to vibrant festivals, traditions, and cuisine, offering visitors a fascinating glimpse into different ways of life. As a predominantly Buddhist region, <b>Ladakh Holiday Packages from Mumbai</b> boasts numerous monasteries, stupas, and prayer flags dotting its landscape. These sacred sites serve as centers for spiritual practice and reflection, contributing to Ladakh's serene and contemplative atmosphere. From trekking in the Himalayas to white-water rafting in the Indus River, <b>Ladakh Package</b> offers a plethora of adventure activities for thrill-seekers. The region's high-altitude terrain also makes it a popular destination for mountaineering and mountain biking enthusiasts.</p>
                                                <p><b>A Trip to Ladakh</b> is accessible via some of the highest motorable roads in the world, such as the famous <b>Khardung La Pass</b>. The thrill of traversing these challenging mountain passes adds an adventurous element to any journey to Ladakh.</p>
                                                <a href="tel://9163676037" className="btn btn-Warning">Call Now</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className='container-fluid'>
                                <div className="container-fluid navtabCustom py-3">
                                    <nav>
                                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                            <button onClick={() => this.handleCustomTabClick('tab1')} className={`tab button ${customActiveTab === 'tab1' ? 'active' : ''}`} role="tab" aria-controls="nav-highlights" aria-selected={customActiveTab === 'tab1'}>Highlights</button>
                                            <button onClick={() => this.handleCustomTabClick('tab2')} className={`tab button ${customActiveTab === 'tab2' ? 'active' : ''}`} role="tab" aria-controls="nav-flights" aria-selected={customActiveTab === 'tab2'}>Flights</button>
                                            <button onClick={() => this.handleCustomTabClick('tab3')} className={`tab button ${customActiveTab === 'tab3' ? 'active' : ''}`} role="tab" aria-controls="nav-hotel" aria-selected={customActiveTab === 'tab3'}>Hotel</button>
                                            <button onClick={() => this.handleCustomTabClick('tab4')} className={`tab button ${customActiveTab === 'tab4' ? 'active' : ''}`} role="tab" aria-controls="nav-sightseeing" aria-selected={customActiveTab === 'tab4'}>Sightseeing</button>
                                            <button onClick={() => this.handleCustomTabClick('tab5')} className={`tab button ${customActiveTab === 'tab5' ? 'active' : ''}`} role="tab" aria-controls="nav-meals" aria-selected={customActiveTab === 'tab5'}>Meals</button>
                                        </div>
                                    </nav>
                                    <div className="tab-content" id="nav-tabContent">
                                        <div className={customActiveTab === 'tab1' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-highlights-tab">
                                            <p>
                                                If you’re planning for Leh Ladakh Family Package, Then you can start your journey from Leh Airport. <b>Kushok Bakula Rimpochee Airport (IXL)</b>.  Are you ready to experience the breathtaking landscapes and vibrant culture of Ladakh? Our <b>Ladakh Tour Packages</b> offers an unforgettable journey to this remote Himalayan region. From the bustling streets of Mumbai to the serene mountains of Ladakh, prepare for an adventure of a lifetime.  </p>
                                            <p>
                                                With our expertly crafted itinerary, you'll explore iconic landmarks such as the stunning Pangong Lake, ancient monasteries like Thiksey and Hemis, and the majestic Nubra Valley. Immerse yourself in the rich cultural heritage of Ladakh as you interact with local communities and witness traditional rituals.
                                            </p>
                                        </div>
                                        <div className={customActiveTab === 'tab2' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-flights-tab">
                                            <div className="overflow-table">
                                                <h4>BEST FLIGHT CONNECTION for<b> LADAKH</b>
                                                    <br /><br />
                                                </h4>
                                                <table className="table table-bordered">
                                                    <tr className="table-danger">
                                                        <th>Airlines</th>
                                                        <th>Flight Number</th>
                                                        <th>City</th>
                                                        <th>Departure Time</th>
                                                        <th>Arrival Time</th>
                                                        <th>Operating Day</th>
                                                    </tr>
                                                    <tr>
                                                        <td>IndiGo</td>
                                                        <td>6E-802</td>
                                                        <td>Mumbai</td>
                                                        <td>07:20</td>
                                                        <td>10:20</td>
                                                        <td>S,M,T,W,T,F,S</td>
                                                    </tr>
                                                    <tr>
                                                        <td>IndiGo</td>
                                                        <td>6E-301</td>
                                                        <td>Mumbai</td>
                                                        <td>07:00</td>
                                                        <td>12:50</td>
                                                        <td>S,M,T,W,T,F,S</td>
                                                    </tr>
                                                    <tr>
                                                        <td>IndiGo</td>
                                                        <td>6E-2209</td>
                                                        <td>Ahmedabad</td>
                                                        <td>04:55</td>
                                                        <td>10:50</td>
                                                        <td>S,M,T,W,T,F,S</td>
                                                    </tr>
                                                    <tr>
                                                        <td>IndiGo</td>
                                                        <td>6E-2385</td>
                                                        <td>Ahmedabad</td>
                                                        <td>23:20</td>
                                                        <td>07:00</td>
                                                        <td>S,M,T,W,T,F,S</td>
                                                    </tr>
                                                    <tr>
                                                        <td>SpiceJet</td>
                                                        <td>SG-160</td>
                                                        <td>Hyderabad</td>
                                                        <td>06:00</td>
                                                        <td>11:40</td>
                                                        <td>S,M,T,W,T,F,S</td>
                                                    </tr>
                                                    <tr>
                                                        <td>IndiGo</td>
                                                        <td>6E -2379</td>
                                                        <td>Hyderabad</td>
                                                        <td>06:00</td>
                                                        <td>12:25</td>
                                                        <td>S,M,T,W,T,F,S</td>
                                                    </tr>
                                                    <tr>
                                                        <td>IndiGo</td>
                                                        <td>6E - 2415</td>
                                                        <td>Kolkata</td>
                                                        <td>22:30</td>
                                                        <td>07:00</td>
                                                        <td>S,M,T,W,T,F,S</td>
                                                    </tr>
                                                    <tr>
                                                        <td>IndiGo</td>
                                                        <td>6E - 474</td>
                                                        <td>Kolkata</td>
                                                        <td>05:30</td>
                                                        <td>12:25</td>
                                                        <td>S,M,T,W,T,F,S</td>
                                                    </tr>
                                                </table>
                                                <br /><br />
                                                <h4><b>DELHI SPECIAL </b>FLIGHT CONNECTION -
                                                    <br /><br />
                                                </h4>
                                                <table className="table table-bordered">
                                                    <tr className="table-danger">
                                                        <th>Airlines</th>
                                                        <th>Flight Number</th>
                                                        <th>City</th>
                                                        <th>Departure Time</th>
                                                        <th>Arrival Time</th>
                                                        <th>Operating Day</th>
                                                    </tr>
                                                    <tr>
                                                        <td>SpiceJet</td>
                                                        <td>SG - 121</td>
                                                        <td>Delhi</td>
                                                        <td>06:10</td>
                                                        <td>07:10</td>
                                                        <td>S,M,T,W,T,F,S</td>
                                                    </tr>
                                                    <tr>
                                                        <td>SpiceJet</td>
                                                        <td>SG - 8125</td>
                                                        <td>Delhi</td>
                                                        <td>08:45</td>
                                                        <td>10:10</td>
                                                        <td>S,M,T,W,T,F,S</td>
                                                    </tr>
                                                    <tr>
                                                        <td>SpiceJet</td>
                                                        <td>SG - 123</td>
                                                        <td>Delhi</td>
                                                        <td>10:15</td>
                                                        <td>11:40</td>
                                                        <td>S,M,T,W,T,F,S</td>
                                                    </tr>
                                                    <tr>
                                                        <td>IndiGo</td>
                                                        <td>6E - 2003</td>
                                                        <td>Delhi</td>
                                                        <td>05:40</td>
                                                        <td>07:00</td>
                                                        <td>S,M,T,W,T,F,S</td>
                                                    </tr>
                                                    <tr>
                                                        <td>IndiGo</td>
                                                        <td>6E - 2006</td>
                                                        <td>Delhi</td>
                                                        <td>06:30</td>
                                                        <td>07:50</td>
                                                        <td>S,M,T,W,T,F,S</td>
                                                    </tr>
                                                </table>
                                            </div>

                                        </div>
                                        <div className={customActiveTab === 'tab3' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-hotel-tab">
                                            <div className="overflow-table">
                                                <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }} ><center><b>4 STAR - CERTIFIED BY 'ADORABLE' </b></center></h4>
                                                <div id="no-more-tables">
                                                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                        <thead class="cf">
                                                            <tr className="text-center">
                                                                {/* <!-- Add the "text-center" className to center content --> */}
                                                                <th colspan="6" style={{ fontSize: "1.3vw" }}><h6><b>4 STAR - CERTIFIED BY 'ADORABLE' </b></h6></th>
                                                            </tr>
                                                            <tr className="table-danger">
                                                                <th className="text-center">Destination</th>
                                                                <th className="text-center">Hotel Names</th>
                                                                <th className="text-center">Hotel Type</th>
                                                                <th className="text-center">No of Nights</th>
                                                                <th className="text-center">Meal Plan</th>
                                                            </tr>
                                                        </thead>
                                                        <tr>
                                                            <td data-title="Destination"><div style={{ display: 'inline-block' }}>Leh</div></td>
                                                            <td data-title="Hotel Names"><div style={{ display: 'inline-block' }}>Bodhi Tree / Royal Palace /Lakrook</div></td>
                                                            <td data-title="Hotel Type">4 Star
                                                            </td>
                                                            <td data-title="No of Nights"><div style={{ display: 'inline-block' }}>4N</div></td>
                                                            <td data-title="Meal Plan"><div style={{ display: 'inline-block' }}>Room with Breakfast & Dinner</div></td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Destination"><div style={{ display: 'inline-block' }}>Nubra</div></td>
                                                            <td data-title="Hotel Names"><div style={{ display: 'inline-block' }}>Nubra Residency / Himalayan Desert Villa / Nubra Ethnic
                                                            </div>
                                                            </td>
                                                            <td data-title="Hotel Type">Premium Camp
                                                            </td>
                                                            <td data-title="No of Nights"><div style={{ display: 'inline-block' }}>1N</div></td>
                                                            <td data-title="Meal Plan"><div style={{ display: 'inline-block' }}>Room with Breakfast & Dinner</div></td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Destination"><div style={{ display: 'inline-block' }}>Pangong</div></td>
                                                            <td data-title="Hotel Names"><div style={{ display: 'inline-block' }}>World Attic Camp / Red Start Camp / Highlake Camp
                                                            </div>
                                                            </td>
                                                            <td data-title="Hotel Type">Premium Camp
                                                            </td>
                                                            <td data-title="No of Nights"><div style={{ display: 'inline-block' }}>1N</div></td>
                                                            <td data-title="Meal Plan"><div style={{ display: 'inline-block' }}>Room with Breakfast & Dinner</div></td>
                                                        </tr>
                                                    </table>
                                                </div>
                                                <br />
                                                <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }} ><center>Your <b>Leh Ladakh Tour Package Price</b> - with 4 <div style={{ display: 'inline-block', color: 'gold' }}>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                </div> Accommodation </center>
                                                </h4>
                                                <div id="no-more-tables">
                                                    <table class="col-md-12 table-bordered table-striped table-condensed cf" style={{ width: "100%", height: "50%" }}>
                                                        <thead class="cf">
                                                            <tr className="text-center">
                                                                {/*  <!-- Add the "text-center" className to center content --> */}
                                                                <th colspan="6">
                                                                    <h4 className="content-desktop" style={{ fontSize: "1.3vw" }} ><center>Your <b>Leh Ladakh Tour Package Price</b> - with 4 <div style={{ display: 'inline-block', color: 'gold' }}>
                                                                        <i className="fa fa-star"></i>
                                                                        <i className="fa fa-star"></i>
                                                                        <i className="fa fa-star"></i>
                                                                        <i className="fa fa-star"></i>
                                                                    </div> Accommodation </center>
                                                                    </h4>

                                                                </th>
                                                            </tr>
                                                            <tr class="table text-center">
                                                                <th class="text-center">No of Pax</th>
                                                                <th class="text-center">Package Price (Including GST.)</th>
                                                                <th class="text-center">Transport</th>
                                                                <th class="text-center">WhatsApp Now</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td class="text-center" data-title="No of Pax">
                                                                    04 Adults
                                                                </td>
                                                                <td class="text-center" data-title=" Price(Inc. GST.)">
                                                                    32,299/- (Per Person)
                                                                </td>
                                                                <td class="text-center" data-title="Transport">Luxury SEDAN</td>
                                                                <td class="text-center" data-title="WhatsApp Now"><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick}>
                                                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                                                </a></td>
                                                            </tr>
                                                            <tr>
                                                                <td class="text-center" data-title="No of Pax">
                                                                    06 Adults
                                                                </td>
                                                                <td class="text-center" data-title=" Price(Inc. GST.)">
                                                                    29,899 /- (Per Person)
                                                                </td>
                                                                <td class="text-center" data-title="Transport">Luxury SUV</td>
                                                                <td class="text-center" data-title="WhatsApp Now"><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick}>
                                                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                                                </a></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <br />
                                            </div>

                                        </div >
                                        <div className={customActiveTab === 'tab4' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-sightseeing-tab">
                                            <p>Ladakh, often referred to as the "Land of High Passes," is renowned for its stunning landscapes, ancient monasteries, and rich cultural heritage. Here are some of the best tourist attractions to include in a <b>Ladakh package tour</b>:</p>
                                            <p><b>Pangong Lake -</b> This iconic lake, situated at an altitude of 4,350 meters, is famous for its mesmerizing shades of blue and breathtaking scenery. A visit to Pangong Lake, especially during sunrise or sunset, offers a truly unforgettable experience.</p>
                                            <p><b>Nubra Valley -</b> Known for its stark desert landscapes, towering sand dunes, and lush green oasis villages, Nubra Valley is a must-visit destination in Ladakh. Highlights include the Diskit Monastery, Hunder Sand Dunes, and the scenic drive through Khardung La Pass, one of the highest motorable passes in the world.</p>
                                            <p><b>Leh Palace - </b>Perched atop a hill overlooking the Leh town, Leh Palace is a striking architectural marvel reminiscent of the Potala Palace in Lhasa, Tibet. Explore the palace's intricately decorated interiors, panoramic views of the surrounding mountains, and learn about its historical significance.</p>
                                            <p><b>Thiksey Monastery -</b>This magnificent monastery, located approximately 19 kilometers from Leh, is one of the largest and most impressive monasteries in Ladakh. Admire the stunning architecture, intricate murals, and panoramic views of the Indus Valley from the monastery's vantage point.</p>
                                            <p><b>Hemis Monastery -</b>Hemis Monastery is not only the largest monastery in Ladakh but also one of the wealthiest and most important monasteries in the region. Visit during the annual Hemis Festival to witness colorful masked dances, cultural performances, and religious rituals.</p>
                                            <p><b>Shanti Stupa -</b> This white-domed Buddhist stupa, situated atop a hill overlooking Leh, offers panoramic views of the city and surrounding mountains. It is a popular spot to watch the sunrise or sunset and to meditate in the tranquil surroundings.</p>
                                            <p><b>Alchi Monastery -</b> Known for its exquisite frescoes and ancient sculptures, Alchi Monastery is a hidden gem nestled in the Indus Valley. Marvel at the intricate artwork adorning the monastery's walls and immerse yourself in its serene ambiance.</p>
                                            <p><b>Lamayuru Monastery -</b>This centuries-old monastery, perched atop a hill overlooking the village of Lamayuru, is one of the oldest and most picturesque monasteries in Ladakh. Explore its labyrinthine corridors, visit the cave temples, and soak in the mystical atmosphere.</p>
                                            <p><b>Tso Moriri Lake -</b>Located in the remote Changthang region of Ladakh, Tso Moriri Lake is a pristine high-altitude lake surrounded by snow-capped mountains and vast grasslands. It offers a peaceful retreat away from the crowds and a chance to spot rare wildlife such as Tibetan wild ass and migratory birds.</p>
                                            <p><b>Khardung La Pass -</b>Journey to one of the world's highest motorable passes, Khardung La Pass, located at an altitude of over 5,300 meters. Enjoy panoramic views of the snow-capped Himalayas, rugged terrain, and dramatic landscapes en route to the Nubra Valley.</p>
                                            <p>These are just a few of the best tourist attractions to include in a <b>Ladakh package tour</b>. Each destination offers a unique blend of natural beauty, cultural heritage, and spiritual significance, making Ladakh a truly unforgettable destination for travelers seeking adventure and exploration.</p>
                                        </div>
                                        <div className={customActiveTab === 'tab5' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-meals-tab">
                                            <div className="overflow-table">
                                                <h5 class="tomato">7 times Meals and 7 times Breakfast has been Provided</h5>
                                                <table className="table table-bordered">
                                                    <tr className="table-danger">
                                                        <th colspan="6" className="text-center">Category</th>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-center">JAIN</td>
                                                        <td class="text-center">PURE-VEG</td>
                                                        <td class="text-center">VEGAN</td>
                                                        <td class="text-center">PURE-NON-VEG</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className='container-fluid py-3 content-desktop'>
                            <h2 style={{ fontSize: "2.5vw" }}><center> <b>Mumbai to Ladakh</b> Direct Flight</center></h2>
                                <div className="container-fluid py-0.9">
                                    <div className="row px-3">

                                        <center><a href="#" onClick={this.handleClick}><img style={{ width: '880px' }} src={desktopflightImageURL} alt="..." /> </a></center>

                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid py-3 content-mobile2">

                                <h3 className="content-mobile2" style={{ fontSize: "4.5vw" }}><center> Mumbai to Ladakh <b>Direct Flight</b></center></h3>


                                <div className="container-fluid py-0.9">
                                    <div className="row px-1">

                                        <a href="#" onClick={this.handleClick}><img style={{ width: '320px' }} src={mumbai_mobileflightImageURL} alt="..." /> </a>

                                    </div>
                                </div>
                                <br />
                                <div className="container-fluid py-0.9">
                                    <div className="row px-1">

                                        <a href="#" onClick={this.handleClick}><img style={{ width: '320px' }} src={leh_mobileflightImageURL} alt="..." /> </a>

                                    </div>
                                </div>

                            </div>
                            <br />

                            <div className="container-fluid navtabCustom py-4">

                                <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>A Perfect <b>Leh Ladakh Turtuk 7N 8D Tour Plan from Mumbai</b> [Day wise Itinerary]</h3>
                                <h3 className="content-mobile2" style={{ fontSize: "4.5vw" }}>A Perfect <b>Leh Ladakh Turtuk 7N 8D Tour Plan from Mumbai</b> [Day wise Itinerary]</h3>
                                <hr />

                                <nav>
                                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                        <button onClick={() => this.handleDayWiseTabClick('day1')} className={`tab button ${daywiseActiveTab === 'day1' ? 'active' : ''}`} role="tab" aria-controls="nav-day-one" aria-selected={daywiseActiveTab === 'day1'}>DAY-1</button>
                                        <button onClick={() => this.handleDayWiseTabClick('day2')} className={`tab button ${daywiseActiveTab === 'day2' ? 'active' : ''}`} role="tab" aria-controls="nav-day-two" aria-selected={daywiseActiveTab === 'day2'}>DAY-2</button>
                                        <button onClick={() => this.handleDayWiseTabClick('day3')} className={`tab button ${daywiseActiveTab === 'day3' ? 'active' : ''}`} role="tab" aria-controls="nav-day-three" aria-selected={daywiseActiveTab === 'day3'}>DAY-3</button>
                                        <button onClick={() => this.handleDayWiseTabClick('day4')} className={`tab button ${daywiseActiveTab === 'day4' ? 'active' : ''}`} role="tab" aria-controls="nav-day-four" aria-selected={daywiseActiveTab === 'day4'}>DAY-4</button>
                                        <button onClick={() => this.handleDayWiseTabClick('day5')} className={`tab button ${daywiseActiveTab === 'day5' ? 'active' : ''}`} role="tab" aria-controls="nav-day-five" aria-selected={daywiseActiveTab === 'day5'}>DAY-5</button>
                                        <button onClick={() => this.handleDayWiseTabClick('day6')} className={`tab button ${daywiseActiveTab === 'day6' ? 'active' : ''}`} role="tab" aria-controls="nav-day-six" aria-selected={daywiseActiveTab === 'day6'}>DAY-6</button>
                                        <button onClick={() => this.handleDayWiseTabClick('day7')} className={`tab button ${daywiseActiveTab === 'day7' ? 'active' : ''}`} role="tab" aria-controls="nav-day-seven" aria-selected={daywiseActiveTab === 'day7'}>DAY-7</button>
                                        <button onClick={() => this.handleDayWiseTabClick('day8')} className={`tab button ${daywiseActiveTab === 'day8' ? 'active' : ''}`} role="tab" aria-controls="nav-day-seven" aria-selected={daywiseActiveTab === 'day7'}>DAY-8</button>
                                    </div>
                                </nav>
                                <div className="tab-content" id="nav-tabContent">
                                    <div className={daywiseActiveTab === 'day1' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-one-tab">
                                        <img src={navDay1ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 class="tomato"> <b>Day 1 – Arrive Leh – Local Sightseeing (05-Kms | 15 minutes approx.) </b></h5>
                                        <p>In your <b>Itinerary of Leh Ladakh Packages from Mumbai</b> on first day - Arrive at Leh airport, meet and greet by our representative and transfer to your reserved accommodation. Check-In to the hotel and get proper rest to acclimatize. Later, in the evening visit Leh Market, Leh Palace and Shanti Stupa.<br />
                                        </p>
                                        <b>Dinner & Overnight at Leh Hotel.</b>
                                    </div>
                                    <div className={daywiseActiveTab === 'day2' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-two-tab">
                                        <img src={navDay2ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 class="tomato"><b>Day 2 - Sham Valley Tour (100-Kms | 5-6 hrs. approx.)</b></h5>
                                        <p>
                                            After breakfast visit to Hall of fame museum, Sangam (Confluence of Zanskar & Indus River), Spituk, magnetic hill, Pathar sahib Gurdwara. In the evening Return back to Leh. A mighty <b>Leh Off Beat Tour Plan</b> come day excursion.<br />
                                        </p>
                                        <b>Enjoy Dinner & Overnight at Leh Hotel.</b>
                                    </div>
                                    <div className={daywiseActiveTab === 'day3' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-three-tab">
                                        <img src={navDay3ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 class="tomato"> <b>Day 3 – Leh - Khardongla Pass - Nubra Valley (128-Kms | 4-5 hrs approx)</b></h5>
                                        <p>After breakfast, drive to Nubra Valley, also known as Valley of flowers via Khardungla, the highest motorable road in the World, (18,380 ft). On your arrival in Nubra Valley check in the hotel / camp. After enjoying lunch proceed to enjoy walk on the banks of Shayok river, you can also enjoy a ride on double humped camel on the Sand Dunes between Deskit & Hundur Village.
                                        </p>
                                        <p><b>Dinner & Overnight at Hotel/Camp/ Cottage.</b></p>
                                    </div>
                                    <div className={daywiseActiveTab === 'day4' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-four-tab">
                                        <img src={navDay4ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 class="tomato"> <b>Day 4 – Nubra Valley - Turtuk - Nubra Valley (165-Kms |3-4 hrs approx.)</b></h5>
                                        <p>After an early breakfast we drive to Turtuk, and a lot of other small villages, were brought under Indian control after the 1971 war, when the Indian army pushed the Pakistani soldiers past the mountains towering the Nubra Valley. Turtuk lies at the extreme corner of the Indian border, and hence its accessibility is a challenge. Due to treacherous roads and sensitive army base camps, permits were not issued to Indian civilians until late 2010. Although the roads are marred by landslides and shooting stones, the 3 hour drive from Hunder to Turtuk is nothing less than exceptional. Throughout the journey, one has the company of Shyok, as well as the vistas, that define the barrenness which is characteristic to Ladakh. This is a Very important day for exploring <b>Leh Ladakh Honeymoon Package from Mumbai</b>. After visiting we drive back to our Nubra camp/Hotel.
                                        </p>
                                        <p><b>Dinner & overnight at Hotel/ Camp/ Cottage.</b></p>
                                    </div>
                                    <div className={daywiseActiveTab === 'day5' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-five-tab">
                                        <img src={navDay5ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 class="tomato"> <b>Day 5 –  Nubra - Pangong Lake via Shyok river road (150-Kms | 6-7 hrs approx.)</b></h5>
                                        <p>On day 5 of <b>Ladakh Packages Tour from Mumbai</b>- After Breakfast visit Deskit Monastery (Big Buddha), where you can have a magnificent view of Nubra Valley and drive to Pangong Lake Via Shayok, Evening free for leisure.</p>
                                        <p><b>Dinner & overnight at Camp/Cottage..</b></p>
                                    </div>
                                    <div className={daywiseActiveTab === 'day6' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-six-tab">
                                        <img src={navDay6ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 class="tomato"> <b>Day 6 –  Pangong Lake - Tsomoriri Lake (200-Kms | 6 - 7 hrs approx.)</b></h5>
                                        <p>Morning after breakfast drive to Tsomoriri Lake via Chushul and Tsaga la. Arrival at Korzok village by evening. An amazing & must visiting point to enjoy differently during <b>Leh Ladakh Turtuk Tour Package ex Mumbai.</b></p>
                                        <p>Dinner & overnight at Tsomoriri Korzok.</p>
                                    </div>
                                    <div className={daywiseActiveTab === 'day7' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-seven-tab">
                                        <img src={navDay7ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 class="tomato"> <b>Day 7 –  Tsomoriri – Tsokar - Taklangla – Leh (240-Kms | 7- 8 hrs approx.)</b></h5>
                                        <p>Morning after breakfast take a walk around the Lake to enjoy the scenic beauty, watch the shades of green and blue mix together as the rolling grass lands surround the lake. Later drive to Leh via Puga (Sulphur Mine), Tsokar Lake and Taklang La Pass 17,585ft, one of the  Highest motorable road in the world. Arrival at Leh by evening. </p>
                                        <p>Dinner & Overnight at Leh Hotel.</p>
                                    </div>
                                    <div className={daywiseActiveTab === 'day8' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-seven-tab">
                                        <img src={navDay8ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 class="tomato"> <b>Day 8 – Leh Airport Drop (05-Kms | 15 minutes approx.)</b></h5>
                                        <p>After Breakfast transfer to Leh airport to board the flight back to destination. Here <b>Leh Ladakh Package Tour from Mumbai</b> journey will end.</p>
                                    </div>
                                </div>
                            </div>

                            {/* <!--insert table inclusion exclusion--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        {
                                            <InclusionExclusion />
                                        }
                                    </div>
                                </div>

                            </div>
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h4 className="content-desktop" style={{ fontSize: "1.5vw" }}>Our <b>Leh Ladakh Package Tour Price from Mumbai</b> 2024</h4>
                                    <h5 className="content-mobile2" style={{ fontSize: "3.5vw" }}>Our <b>Leh Ladakh Package Tour Price from Mumbai</b> 2024</h5>
                                </div>
                                <div className="card-body">
                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead>
                                                <tr className="table-danger">
                                                    <th>LEH LADAKH PACKAGES</th>
                                                    <th>TOUR DURATION</th>
                                                    <th>CATEGORY</th>
                                                    <th>TOUR PRICE (MIN 6PAX)</th>
                                                    <th>CONTACT</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td data-title="LEH PACKAGES">Leh Ladakh Tours from Mumbai</td>
                                                    <td data-title="TOUR DURATION">5N/6D</td>
                                                    <td data-title="CATEGORY">DELUXE</td>
                                                    <td data-title="PRICE (MIN 6PAX)">Rs 16,379/- </td>
                                                    <td data-title="CONTACT"><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick}>
                                                        <i className="fab fa-whatsapp"></i> WhatsApp
                                                    </a></td>
                                                </tr>
                                                <tr>
                                                    <td data-title="LEH PACKAGES">Ladakh Family Trip from Mumbai</td>
                                                    <td data-title="TOUR DURATION">6N/7D</td>
                                                    <td data-title="CATEGORY">DELUXE</td>
                                                    <td data-title="PRICE (MIN 6PAX)">Rs 18,779/- </td>
                                                    <td data-title="CONTACT"><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick}>
                                                        <i className="fab fa-whatsapp"></i> WhatsApp
                                                    </a></td>
                                                </tr>
                                                <tr>
                                                    <td data-title="LEH PACKAGES">Ladakh Packages from Mumbai</td>
                                                    <td data-title="TOUR DURATION">7N/8D</td>
                                                    <td data-title="CATEGORY"><b style={{ color: 'red' }}>PREMIUM</b></td>
                                                    <td data-title="PRICE (MIN 6PAX)">Rs 24,419/- </td>
                                                    <td data-title="CONTACT"><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick}>
                                                        <i className="fab fa-whatsapp"></i> WhatsApp
                                                    </a></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br />
                                    <p>A Perfect Leh Ladakh Tour Packages from Mumbai Price varies on various components:</p>
                                    <ul>
                                        <li>● Itinerary you'll choose</li>
                                        <li>● Accommodation you'll be comfortable [ 3 Star Hotel to 5 Star Hotel ]</li>
                                        <li>● Vehicles you'll prefer [ SUV, MUV & Adventure Vehicle ]</li>
                                        <li>● Adventure activities you'll do [Cammel Ride, Quad Biking & River Rafting ]</li>
                                        <li>● Duration of your tour [5N, 6N & 7N ]</li>
                                        <li>● No of Travellers [ 2, 4, 5, 6 & Children or Kids ]</li>
                                    </ul>
                                    <br />
                                    <p><b>Mumbai to Ladakh Flights</b> cost will be additional which is not constant and personal expenses include in your <b>Trip to Ladakh</b> from Mumbai.</p>
                                    <p>It is always recommended to compare different <b>Ladakh Local Tour Operators</b> and their respective Mumbai to Leh <b>Ladakh itinerary</b> and read the inclusions-exclusions and others offerings carefully to get the best value for money. <b>Leh Ladakh trip cost from Mumbai</b> can also be higher during peak season[ June, July Specifically], so it's better to book your <b>Mumbai to Ladakh package</b> well in advance to avoid any last-minute price hikes or non-availability of room in Leh, Nubra, Pangong & Turtuk.</p>
                                </div>

                            </div>
                            <br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h4 className="content-desktop" style={{ fontSize: "1.5vw" }}>Important Distance & Duration <b>Ladakh Packages from Mumbai</b> by Air </h4>
                                    <h5 className="content-mobile2" style={{ fontSize: "3.5vw" }}>Important Distance & Duration <b>Ladakh Packages from Mumbai</b> by Air </h5>
                                </div>
                                <div className="card-body">
                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead>
                                                <tr className="table-danger">
                                                    <th>PLACES FROM</th>
                                                    <th>PLACES TO</th>
                                                    <th>ROAD DISTANCES</th>
                                                    <th>TRAVEL DURATION</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td data-title="PLACES FROM">TSO MORIRI</td>
                                                    <td data-title="PLACES TO">LEH</td>
                                                    <td data-title="ROAD DISTANCES">220 km</td>
                                                    <td data-title="TRAVEL DURATION">4 hrs 50 min </td>
                                                </tr>
                                                <tr>
                                                    <td data-title="PLACES FROM">LEH</td>
                                                    <td data-title="PLACES TO">KARGIL</td>
                                                    <td data-title="ROAD DISTANCES">215 km</td>
                                                    <td data-title="TRAVEL DURATION">4 hrs 30 min </td>
                                                </tr>
                                                <tr>
                                                    <td data-title="PLACES FROM">LEH</td>
                                                    <td data-title="PLACES TO">JISPA</td>
                                                    <td data-title="ROAD DISTANCES">334 km</td>
                                                    <td data-title="TRAVEL DURATION">7 hrs 50 min </td>
                                                </tr>
                                                <tr>
                                                    <td data-title="PLACES FROM">JISPA</td>
                                                    <td data-title="PLACES TO">SARCHU</td>
                                                    <td data-title="ROAD DISTANCES">78 km</td>
                                                    <td data-title="TRAVEL DURATION">2 hrs 30 min </td>
                                                </tr>
                                                <tr>
                                                    <td data-title="PLACES FROM">LEH</td>
                                                    <td data-title="PLACES TO">NUBRA</td>
                                                    <td data-title="ROAD DISTANCES">160 km</td>
                                                    <td data-title="TRAVEL DURATION">4 hrs 30 min </td>
                                                </tr>
                                                <tr>
                                                    <td data-title="PLACES FROM">NUBRA</td>
                                                    <td data-title="PLACES TO">PANGONG</td>
                                                    <td data-title="ROAD DISTANCES">274 km</td>
                                                    <td data-title="TRAVEL DURATION">7 hrs 15 min </td>
                                                </tr>
                                                <tr>
                                                    <td data-title="PLACES FROM">PANGONG</td>
                                                    <td data-title="PLACES TO">TSO MORIRI</td>
                                                    <td data-title="ROAD DISTANCES">328 km</td>
                                                    <td data-title="TRAVEL DURATION">8 hrs 20 min </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>

                            </div>
                            <br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h5 className="content-desktop" style={{ fontSize: "1.5vw" }}>When is the <b>Best Time to Visit Leh Ladakh Package Tour from Mumbai</b> by Flight ?</h5>

                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>When is the <b>Best Time to Visit Leh Ladakh Package Tour from Mumbai</b> by Flight ?</h3>

                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-7">
                                    <span className="icon-img-color">
                                        <i className="fa fa-sun-o" aria-hidden="true"></i>&nbsp;
                                        <span className="black">
                                            <strong>Summer – May to September is Peak Season</strong><br />
                                            <strong className='p-4'>You Can Expect </strong>
                                        </span>
                                    </span>
                                   
                                    <ul className='p-4'>
                                        <li>• Pleasant and clear weather</li>
                                        <li>• Cool days, chilly nights</li>
                                        <li>• Peak tourist season</li>
                                        <li>• Picturesque mountains and sumptuous Tibetan delicacies</li>
                                        <li>• Temperatures ranging from 20-30 degree Celsius</li>
                                        <li>• Costly hotels/resorts and homestays</li>
                                    </ul>
                                    </div>
                                    <div className="col-md-5">
                                    <table style={{ border: '5px solid #DBD2BF' }}>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <img src={leh_summer} style={{ height: "170px", width: "300px" }} className="img-fluid" alt="..." />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                    </div>
                                    <br />
                                    </div>
                                    <div className="row">
                                        <div className="col-md-7">
                                    <span className="icon-img-color">
                                        <i className="fa fa-cloud" aria-hidden="true"></i>
                                        &nbsp;
                                        <span className="black">
                                            <strong>Autumn – September to October is Moderate Season</strong><br />
                                            <strong className='p-4'>You Can Expect </strong>
                                        </span>
                                    </span>
                                    <ul className='p-4'>
                                        <li>• Offset of winters</li>
                                        <li>• Live snowfall</li>
                                        <li>• Little bit Frozen lakes</li>
                                        <li>• Colouring mountain & landscape.</li>
                                        <li>• Temperatures ranging between 10-20 degree Celsius</li>
                                        <li>• Perfect time Ladakh Honeymoon Tour from Mumbai</li>
                                    </ul>
                                    </div>
                                    <div className='col-md-5'>
                                            <table style={{ border: '5px solid #DBD2BF' }}>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <img src={leh_auttum} style={{ height: "170px", width: "300px" }} className="img-fluid" alt="..." />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    <br />
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-7'>
                                            <span className="icon-img-color">
                                                <i className="fa fa-snowflake-o" aria-hidden="true"></i>
                                                &nbsp;
                                                <span className="black">
                                                    <strong>Winter – November to April is Winter Season</strong><br />
                                                    <strong className='p-4'>You Can Expect </strong>
                                                </span>
                                            </span>

                                            <ul className='p-4'>
                                                <li>• Onset of winters</li>
                                                <li>• Heavy snowfall</li>
                                                <li>• All Frozen lakes</li>
                                                <li>• Perfect time for Chadar Trek.</li>
                                                <li>• Temperatures ranging between -15 to -5 degree Celsius</li>
                                            </ul>
                                        </div>
                                        <div className='col-md-5'>
                                            <table style={{ border: '5px solid #DBD2BF' }}>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <img src={leh_winter} style={{ height: "170px", width: "300px" }} className="img-fluid" alt="..." />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <br />
                                    </div>
                                </div>
                            </div>
                            <br />
                            {/* <!--end table inclusion exclusion--> */}
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h5 className="content-desktop" style={{ fontSize: "1.5vw" }}>Month wise Average Temperature During <b>Leh Ladakh Pangong Package from Mumbai</b></h5>

                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>Month wise Average Temperature During <b>Leh Ladakh Pangong Package from Mumbai</b></h3>

                                </div>
                                <br />
                                <div className="table-responsive">
                                    <table className="table table-bordered table-striped table-condensed cf">
                                        <thead>
                                            <tr className="table-primary">
                                                <th scope="col">TRAVEL MONTHS</th>
                                                <th scope="col">MIN TEMP</th>
                                                <th scope="col">MAX TEMP</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>December</td>
                                                <td>-11°C</td>
                                                <td>1°C</td>
                                            </tr>
                                            <tr>
                                                <td>November</td>
                                                <td>-7°C</td>
                                                <td>7°C</td>
                                            </tr>
                                            <tr>
                                                <td>October</td>
                                                <td>-1°C</td>
                                                <td>14°C</td>
                                            </tr>
                                            <tr>
                                                <td>September</td>
                                                <td>5°C</td>
                                                <td>21°C</td>
                                            </tr>
                                            <tr>
                                                <td>August</td>
                                                <td>9°C</td>
                                                <td>24°C</td>
                                            </tr>
                                            <tr>
                                                <td>July</td>
                                                <td>10°C</td>
                                                <td>25°C</td>
                                            </tr>
                                            <tr>
                                                <td>June</td>
                                                <td>7°C</td>
                                                <td>21°C</td>
                                            </tr>
                                            <tr>
                                                <td>May</td>
                                                <td>3°C</td>
                                                <td>17°C</td>
                                            </tr>
                                            <tr>
                                                <td>April</td>
                                                <td>-1°C</td>
                                                <td>12°C</td>
                                            </tr>
                                            <tr>
                                                <td>March</td>
                                                <td>-6°C</td>
                                                <td>6°C</td>
                                            </tr>
                                            <tr>
                                                <td>February</td>
                                                <td>-12°C</td>
                                                <td>-1°C</td>
                                            </tr>
                                            <tr>
                                                <td>January</td>
                                                <td>-15°C</td>
                                                <td>-3°C</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>The Grand Dragon <span>- Leh</span></h2>
                                            <h4><i>5 Star Hotel in Leh</i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row py-3">
                                    <img src={brandedTourackageImage_1_URL} alt="..." />
                                </div>

                                <div id="no-more-tables">
                                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                        <thead>
                                            <tr className="table-danger">
                                                <th>Category</th>
                                                <th>Size(Room)</th>
                                                <th>Hotel Brand</th>
                                                <th>Price (Per Night)</th>
                                                <th>Book Now</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td data-title="Packages">Luxury Accommodation</td>
                                                <td data-title="Size(Room)">320+ SQ.FT</td>
                                                <td data-title="Hotel Brand">Dragon<div className="reviews">
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                </div></td>
                                                <td data-title="Price (Per Night)">Rs 15,729/- </td>
                                                <td data-title="Book Now"><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick}>
                                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                                </a></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                <div className="card mx-0 my-5">
                                    <div className="card-header custHeaer">
                                        <h3 className='content-desktop' style={{ fontSize: "1.5vw" }}><b>Only Certified 5 Star Hotel in LEH</b></h3>
                                        <h3 className='content-mobile2' style={{ fontSize: "3.5vw" }}><b>Only Certified 5 Star Hotel in LEH</b></h3>
                                    </div>
                                    <div className="card-body">
                                        <p>Located at a height of 11,562 ft above sea level in the Himalayas, <b>The Grand Dragon Ladakh</b> in Leh is the region’s only certified 5-Star hotel. Open all year round, this indigenously designed property with 76 rooms including family suites, offers all modern comforts and conveniences with stunning views of the picturesque landscape, the vivid colours of an ever-changing sky and the lofty Himalayan peaks. An ideal base from where visitors to Ladakh can make day trips to centuries old monasteries, notably Hemis, Thiksey or Alchi or an overnight journey to soak in the surreal landscapes at Pangong Tso, Nubra Valley or Lamayuru. Dining options include Zasgyath the all day dining restaurant with alfresco seating, Solja the Tea & Coffee Lounge, BBQ and bonfire in the lawns as well as a full service picnic by the banks of the river or any scenic spot. The Wellness centre comprises a fully equipped gym and SPA soon. Book your stay here and take your experience of Ladakh to another level.</p>


                                        <a href="tel://9163676037" className="btn btn-Warning">Book Now</a>

                                    </div>
                                </div>
                            </div>

                            <div className="container-fluid py-2">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>The Chospa <span>- Leh</span></h2>
                                            <h4><i>Vernacular Ladakhi Architecture</i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row py-3">
                                    <img src={brandedTourackageImage_2_URL} alt="..." />
                                </div>

                                <div id="no-more-tables">
                                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                        <thead>
                                            <tr className="table-danger">
                                                <th>Category</th>
                                                <th>Size(Room)</th>
                                                <th>Hotel Brand</th>
                                                <th>Price (Per Night)</th>
                                                <th>Book Now</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td data-title="Packages">Platinum Accommodation</td>
                                                <td data-title="Size(Room)">270+ SQ.FT</td>
                                                <td data-title="Hotel Brand">Earth<div className="reviews">
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                </div></td>
                                                <td data-title="Price (Per Night)">Rs 14,843/- </td>
                                                <td data-title="Book Now"><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick}>
                                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                                </a></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="card mx-0 my-5">
                                    <div className="card-header custHeaer">
                                        <h3 className='content-desktop' style={{ fontSize: "1.5vw" }}><b>CHOSPA – MADE OF THE EARTH</b></h3>
                                        <h3 className='content-mobile2' style={{ fontSize: "3.5vw" }}><b>CHOSPA – MADE OF THE EARTH</b></h3>
                                    </div>
                                    <div className="card-body">
                                        <p><b>Hotel Chospa</b> is a stunning retreat nestled in the heart of the vibrant city centre of Leh, located on the Old Leh Road. This enchanting abode has been curated to cater to the discerning explorer and those who appreciate the finer things in life.
                                            Chospa is the union of contemporary hospitality with the scenic and material biome of Ladakh. The architecture mirrors the traditional Ladakhi construction and materiality, rendering an ambience familiar to locals and unique for visitors.

                                            The spatial organization is deliberately insular to mitigate the noise from the abutting busy street yet allowing a convenient flow of passersby to access the alfresco of the café from the upper corner. The atrium lobby aims to lend a casual yet a calming vibe found in most traditional Ladakhi homes. The lobby atrium opens out to the rear with open seating on the south side receiving much needed ample south facing sunlight.</p>


                                        <a href="tel://9163676037" className="btn btn-Warning">Book Now</a>

                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid py-4">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>The Abduz <span>- Leh</span></h2>
                                            <h4><i>The Best Luxury Hotel</i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row py-3">
                                    <img src={brandedTourackageImage_3_URL} alt="..." />
                                </div>

                                <div id="no-more-tables">
                                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                        <thead>
                                            <tr className="table-danger">
                                                <th>Category</th>
                                                <th>Size(Room)</th>
                                                <th>Hotel Brand</th>
                                                <th>Price (Per Night)</th>
                                                <th>Book Now</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td data-title="Packages">Gold Accommodation</td>
                                                <td data-title="Size(Room)">320+ Sq Ft</td>
                                                <td data-title="Hotel Brand">Luxury <div className="reviews">
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                </div></td>
                                                <td data-title="Price (Per Night)">Rs 13,573/- </td>
                                                <td data-title="Book Now"><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick}>
                                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                                </a></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                <div className="card mx-0 my-5">
                                    <div className="card-header custHeaer">
                                        <h3 className='content-desktop' style={{ fontSize: "1.5vw" }}><b>TIME TRAVEL TO LUXURY</b></h3>
                                        <h3 className='content-mobile2' style={{ fontSize: "3.5vw" }}><b>TIME TRAVEL TO LUXURY</b></h3>
                                    </div>
                                    <div className="card-body">
                                        <p>Nestled in the heart of the Himalayan land, Leh, The Abduz is a finely crafted abode where you’ll surely be spoilt for choice. Enjoy hotel’s local architecture, outdoor seating areas, exquisite interiors along with a state of comfort, invoking great experiences.

                                            <b>The Abduz</b> symbolise warm reception and hospitality withholding a legacy which is more than two decades old. The hotel is a 5 minutes drive from the airport and only a few minutes from the famed local market. Featuring a business convention centre, an exquisite in-house patisserie, a sophisticated bar, a Shisha lounge, a multi-cuisine restaurant, a well-equipped fitness centre, an entertainment zone, and convenient valet parking.</p>

                                        <a href="tel://9163676037" className="btn btn-Warning">Book Now</a>

                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid py-5">

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>Ladakh<span> Tour Packages</span></h2>
                                            <h4>Related Ladakh Tour plan</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                                        <div className="carousel-inner">
                                            <div className="carousel-item active">

                                                <Slider {...settings}>
                                                    {hotPackagesView}
                                                </Slider>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header custHeaer">
                                    <h3 className='content-desktop' style={{ fontSize: "1.5vw" }}>Your <b>LADAKH HOLIDAY ADVENTURE</b> Activities </h3>
                                    <h3 className='content-mobile2' style={{ fontSize: "3.5vw" }}>Your <b>LADAKH HOLIDAY ADVENTURE</b> Activities </h3>
                                </div>
                                <div className="card-body">
                                    <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>Mountain Biking :</b><b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Mountain Biking :</b> </p>
                                    <p><b>Where: </b>Karakoram and Zanskar mountain ranges</p>
                                    <p><b>Price: </b>INR 1,500 to 5,000</p>
                                    <p><b>Best Time: </b>June to August</p>

                                    <center> <img src={Topbanner0ImageURL} className="img-fluid" alt="..." /></center>

                                    <p>If you are a thrill seeker who loves to indulge in adventure activities, Ladakh is the destination to be at. Wedged between the Karakoram and Zanskar mountain ranges, Ladakh is not only known for its rich culture and tradition but also for adventure activities. Mountain biking is one of the top adventure activities to enjoy in Leh Ladakh. You will not only get to cross the highest mountain passes but will also get breathtaking sights of the snow-capped Himalayas. On your way, you will find several monasteries and religious abodes. Mountain biking is an experience filled with lots of fun and excitement.</p>
                                    <br />
                                    <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>Camel Ride :</b><b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Camel Ride :</b></p>
                                    <p><b>Where:</b> Diskit and Hunder valley</p>
                                    <p><b>Price:</b> INR 750 to 1400</p>
                                    <p><b>Best Time:</b> Anytime</p>
                                    <center> <img src={Topbanner1ImageURL} className="img-fluid" alt="..." /></center>
                                    <p>
                                        Camel safari will be truly outstanding and remarkable encounters in Leh Ladakh trip! This is one of the silver linings in the Ladakh adventure sports zone. Riding on a twofold bumped camel and scaling the delightful scenes of the high elevation desert is rapidly increasing the notoriety of being perhaps the best activity in Ladakh. For the vacationers who are in for some crude country experience of Ladakh, these safaris fill in as a brilliant medium as riding on these camels feels a great deal like a wanderer meandering in the deserts. With a conveying limit of a few grown-ups at once, the prestigious camel safaris in Hunder are viewed as among the best on the planet.
                                    </p>
                                    <br />
                                    <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>River Rafting :</b><b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>River Rafting :</b></p>
                                    <p><b>Where:</b> Zanskar Stream, Padum and Nimu</p>
                                    <p><b>Price: </b>INR 2,000 to 3,500</p>
                                    <p><b>Best Time: </b>June to October</p>
                                    <center><img src={Topbanner2ImageURL} className="img-fluid" alt="..." /></center>
                                    <p>
                                        Stream boating in Leh-Ladakh takes you through mesmerizing views. Crossing through the profound waters amid profound nature and taking off snow-shrouded tops it is an encounter each beam couldn’t imagine anything better than to appreciate. There are numerous choices for waterway boating in Leh Ladakh. The fundamental ones being Indus Stream boating and Zanskar Waterway rafting. River Boating in Indus Waterway and its tributaries give the rafters numerous chances. The most amazing stretches in Stream Indus is the one among Spituk and Nimu or Saspol. Be that as it may, the individuals who are new into the game ought to pick the least demanding stretch upstream from Spituk up to Karu.
                                    </p>
                                    <br />
                                    <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>Quad Biking :</b><b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Quad Biking :</b> </p>
                                    <b>Where: Magnetic Hill, Nubra Valley</b>

                                    <center> <img src={Topbanner3ImageURL} className="img-fluid" alt="..." /></center>

                                    <p>Perfect for all thrill-seekers, Quad biking in Ladakh is one of the favorite adventure sports of the locals as well as tourists. Explore Ladakh on ATV on which you have to control the steering while you make your way through the crisscross roads. Quad biking is an out of the world experience for all those who crave thrill and want to satiate their lust for all things wild and challenging. Driving is a passion for many people and to take to the next level you must try driving and controlling an ATV.</p>
                                    <a href="tel://9163676037" className="btn btn-Warning">Call Now</a>
                                </div>
                            </div>
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h3>FREQUENTLY ASKED QUESTIONS [FAQ]</h3>
                                            <h4><b>Ladakh Travel Agency</b> Related Questions</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div
                                        className="accordion accordion-flush faQStyle"
                                        id="accordionFlushExample"
                                    >
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingOne">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseOne"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseOne"
                                                >
                                                    1. What is included in the Ladakh package tour ?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseOne"
                                                className="accordion-collapse"
                                                aria-labelledby="flush-headingOne"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Ladakh package tours typically include transportation, accommodations, guided tours to popular attractions, meals (depending on the package), and sometimes activities such as trekking or rafting.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingTwo">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseTwo"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseTwo"
                                                >
                                                    2. What is the best time to visit Ladakh ?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseTwo"
                                                className="accordion-collapse collapseShow"
                                                aria-labelledby="flush-headingTwo"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    <p>The best time to visit Ladakh is during the summer months, from May to September, when the weather is pleasant, and most of the region's attractions are accessible. However, some travelers also visit Ladakh in the shoulder seasons of spring (March to April) and autumn (September to October) for fewer crowds and cooler temperatures.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingThree">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseThree"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseThree"
                                                >
                                                    3. How do I travel to Ladakh ?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseThree"
                                                className="accordion-collapse collapseShow"
                                                aria-labelledby="flush-headingThree"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    <p>Traveling to Ladakh usually involves taking a flight to Leh, the main town in Ladakh. Several airlines operate flights to Leh, with a layover in Delhi or other major cities. Alternatively, you can also travel by train to Jammu Tawi or Srinagar and then continue your journey to Ladakh by road or air.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingFour">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseFour"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseFour"
                                                >
                                                    4. Do I need a permit to visit Ladakh ?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseFour"
                                                className="accordion-collapse collapseShow"
                                                aria-labelledby="flush-headingFour"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    <p>Yes, Indian nationals require an Inner Line Permit (ILP) to visit certain areas in Ladakh, including Nubra Valley, Pangong Lake, and Tso Moriri Lake. You can obtain the permit online or upon arrival in Leh by providing the necessary documents such as identification proof and passport-sized photographs.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingFive">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseFive"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseFive"
                                                >
                                                    5. What are the must-visit attractions in Ladakh ?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseFive"
                                                className="accordion-collapse collapseShow"
                                                aria-labelledby="flush-headingFive"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    <p>Must-visit attractions in Ladakh include Pangong Lake, Nubra Valley, Leh Palace, Thiksey Monastery, Hemis Monastery, Shanti Stupa, and Khardung La Pass. These landmarks offer stunning landscapes, cultural insights, and historical significance.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingSix">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseSix"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseFive"
                                                >
                                                    6. How do I acclimatize to the high altitude in Ladakh ?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseSix"
                                                className="accordion-collapse collapseShow"
                                                aria-labelledby="flush-headingSix"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    <p>To acclimatize to the high altitude in Ladakh, it's essential to take it slow and allow your body time to adjust. Spend a couple of days in Leh before venturing to higher-altitude destinations, drink plenty of water, avoid strenuous activity, and consider taking altitude sickness medication if necessary.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingSeven">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseSeven"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseSeven"
                                                >
                                                    7. What should I pack for a Ladakh package tour ?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseSeven"
                                                className="accordion-collapse collapseShow"
                                                aria-labelledby="flush-headingSeven"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    <p>When packing for a Ladakh package tour, include essentials such as warm clothing, layers, sunscreen, sunglasses, hats, sturdy walking shoes, a reusable water bottle, altitude sickness medication, and any necessary travel documents (including permits and identification proof).</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingEight">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseEight"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseEight"
                                                >
                                                    8. Is there mobile connectivity and internet access in Ladakh ?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseEight"
                                                className="accordion-collapse collapseShow"
                                                aria-labelledby="flush-headingEight"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    <p>Yes, there is mobile connectivity and internet access in Ladakh, particularly in the main towns like Leh. However, connectivity may be limited or unreliable in more remote areas and higher-altitude regions. It's advisable to carry a local SIM card for better coverage.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingEight">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseEight"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseEight"
                                                >
                                                    9. Are there any ATMs in Leh ?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseEight"
                                                className="accordion-collapse collapseShow"
                                                aria-labelledby="flush-headingEight"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    <p>Leh has four ATMs owned by Axis Bank, Jammu and Kashmir Bank, Punjab National Bank and State Bank of India.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingEight">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseEight"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseEight"
                                                >
                                                    10. Does credit card work in hotels and restaurants?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseEight"
                                                className="accordion-collapse collapseShow"
                                                aria-labelledby="flush-headingEight"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    <p>Yes, the credit card is accepted in a few hotels and restaurants but a service tax is charged.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container-fluid py-5">

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h3><b>Leh Ladakh Tour</b> Reviews</h3>
                                            <h4><i>"Your Experience is our Marketing"</i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-cols-1">
                                    <div id="testimonials">
                                        <div className="testimonial-box-container">

                                            {reviewersDataView}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div >
                        <div className="col-md-3">
                            <BookingBox />
                        </div>

                    </div >
                </div >

            </>
        );
    }
}

export default Leh_Ladakh_Turtuk_Package;