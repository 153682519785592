import React, { Component } from "react";
import BookingBox from "../BookingBox";
import Slider from "react-slick";
import ApiURL from "../../../api/ApiURL";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InclusionExclusion from "../Sundarban/InclusionExclusion_sundarban";
class Sundarban_SonarBanglaTourPackage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customActiveTab: 'tab1',
      daywiseActiveTab: 'day1',
      currentPage: 1,
    };
  }

  handleCustomTabClick = (tab) => {
    this.setState({
      customActiveTab: tab,
    });
  };

  handleDayWiseTabClick = (tab) => {
    this.setState({
      daywiseActiveTab: tab,
    });
  };
  handleClick1 = () => {
    const currentUrl = window.location.href;
    const pageTitle = document.title;
    const message = `Hi, Please send about the Tour Package details "${pageTitle}"`;

    const whatsappLink = `https://api.whatsapp.com/send?phone=+919883359713&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
    window.open(whatsappLink, '_blank');
  };
  handlePageChange = (direction) => {
    this.setState((prevState) => {
      const newPage = prevState.currentPage + direction;
      return { currentPage: newPage };
    });
  };
  render() {
    const rows = [
      { train: '34512, Sealdah - Canning Local', departure: '05:45', arrival: '06:57' },
      { train: '34514, Sealdah - Canning Local', departure: '06:30', arrival: '07:44' },
      { train: '34516, Sealdah - Canning Local', departure: '07:42', arrival: '08:56' },
      { train: '34518, Sealdah - Canning Local', departure: '08:28', arrival: '09:44' },
      { train: '34520, Sealdah - Canning Local', departure: '09:10', arrival: '10:25' },
      { train: '34522, Sealdah - Canning Local', departure: '10:24', arrival: '11:40' },
    ];

    const rowsPerPage = 6;
    const totalPages = Math.ceil(rows.length / rowsPerPage);
    const currentRows = rows.slice((this.state.currentPage - 1) * rowsPerPage, this.state.currentPage * rowsPerPage);
    const { customActiveTab, daywiseActiveTab } = this.state;
    const packageTopBannerImageURL = ApiURL.Sundarban_Sonarbangla_PackageImageURL + "/Sunderban-Tour-Package-from-Kolkata.webp";
    const packageTopBanner2ImageURL = ApiURL.Sundarban_Sonarbangla_PackageImageURL + "/Sunderban-Tour-Packages-from-Kolkata.webp";
    const Hotelsonarbangla1ImageURL = ApiURL.Sundarban_Sonarbangla_PackageImageURL + "/Sunderban-Tour-Plan.jpg";
    const Hotelsonarbangla2ImageURL = ApiURL.Sundarban_Sonarbangla_PackageImageURL + "/sundarban-tour-packages-kolkata-sonar-bangla.webp";
    const Hotelsonarbangla3ImageURL = ApiURL.Sundarban_Sonarbangla_PackageImageURL + "/sundarban-tour-package-kolkata-sonar-bangla.png";
    const HotelsonarbanglaFoodImageURL = ApiURL.Sundarban_Sonarbangla_PackageImageURL + "/Sundarban-tour-with-Hotel-Sonar-Bangla.webp";
    const HotelsonarbanglaBoatImageURL = ApiURL.Sundarban_Sonarbangla_PackageImageURL + "/Hotel-Sonar-Bangla-Sundarban-package-tour.webp";
    const reviewersDataList = this.props.ReviewersData;
    const reviewersDataView = reviewersDataList.map((dataItem, i) => {
      const reviewerImageURL =
        ApiURL.Sundarban_Sonarbangla_PackageImageURL + "/" + dataItem.ImageName;
      return (
        <div className="testimonial-box" key={i}>
          <div className="box-top">
            <div className="profile">
              <div className="profile-img">
                <img src={reviewerImageURL} alt="..." />
              </div>
              <div className="name-user">
                <strong>{dataItem.Name}</strong>
                <span>{dataItem.Location}</span>
              </div>
            </div>
            <div className="reviews">
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
            </div>
          </div>
          <div className="client-comment">
            <p>{dataItem.Comment}</p>
          </div>
        </div>
      );
    });

    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <img
              src={packageTopBannerImageURL}
              className="mobile-image2"
              alt="..."
            />
          </div>
        </div>

        <div className="container-fluid custom-container py-3">
          <div className="row">
            <div className="col-md-9">
              <div className="row">
                <div className="col-md-12">
                  <div className="container-fluid custom-container">
                    <h1 className="content-desktop" style={{ fontSize: "2.0vw" }}><center><strong>Hotel Sonar Bangla Sundarban Package Tour</strong></center></h1>
                    <h1 className="content-mobile" style={{ fontSize: "4vw" }}><center><strong>Hotel Sonar Bangla Sundarban Package Tour</strong></center>
                      <hr />
                    </h1>
                    <div className="row">
                      <img src={packageTopBanner2ImageURL} className="mobile-image" alt="..." />
                    </div>
                    <hr />
                    <div className="col-12">
                      <big className="text-muted"><strong className="tomato">2N/3D</strong></big>&nbsp;
                      <i className="fa fa-arrow-right"></i>&nbsp;Ex-Kolkata&nbsp;
                      <i className="fa fa-arrow-right"></i>&nbsp;Fooding & Accommodation&nbsp;
                      <i className="fa fa-arrow-right"></i>&nbsp;Guide&nbsp;
                      <i className="fa fa-arrow-right"></i>&nbsp;Luxury Boat&nbsp;
                      <br />
                    </div>
                    <p className="p-3">
                      <strong>Hotel Sonar Bangla Sundarban package tour</strong> offers a comfortable stay with beautiful views of the Sundarbans.
                    </p>
                    <hr />
                    <br />

                    <h2 className="content-desktop p-2" style={{ fontSize: "1.8vw" }}> For Booking <strong>Sonar Bangla Sundarban Package from Kolkata</strong> <a href='tel:9883359713' class="tomato">☎ CALL Mr. Soumalya Ghosh  +91- 98833-59713</a></h2>
                    <h2 className="content-mobile p-4" style={{ fontSize: "4.5vw" }}> For Booking <strong>Sonar Bangla Sundarban Package from Kolkata</strong> <a href='tel:9883359713' class="tomato">☎ CALL Mr. Soumalya Ghosh  +91- 98833-59713</a></h2>
                    {/* <h3 className="p-2"><b>
                      Looking For Best Bhutan Tour Packages From Mumbai? <br />  <a href='tel:9883359713' className="tomato"> CALL US NOW ☎ +91-98833-59713</a></b></h3> */}
                    <br />

                    <div className="card">
                      <div
                        className="card-header custHeaer"
                      >
                        <h3 className="content-desktop" style={{ fontSize: "1.7vw" }}> <strong>Best Sundarban Tour Operator</strong> in Kolkata</h3>
                        <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}> <strong>Best Sundarban Tour Operator</strong> in Kolkata</h4>
                      </div>
                      <div className="card-body">
                        <h3 className="content-desktop" style={{ fontSize: "1.4vw" }}><strong>Book Hotel Sonar Bangla</strong> : Your Premier Resort in the Sundarbans</h3>
                        <h3 className="content-mobile2" style={{ fontSize: "3.7vw" }}><strong>Book Hotel Sonar Bangla</strong> : Your Premier Resort in the Sundarbans</h3><br />
                        <p><strong>Hotel Sonar Bangla</strong> is the top resort in the Sundarbans, set in the vast mangrove forest in the Bay of Bengal delta. The Sundarbans is known for its rich biodiversity, including the famous Royal Bengal Tiger. Just a few kilometers from Gosaba, our resort combines luxury with eco-friendly practices. The <strong>Hotel Sonar Bangla Sundarban package tour</strong> lets visitors explore this unique ecosystem with expert guides, comfortable accommodations, and curated experiences. It's perfect for nature lovers and adventure seekers, promising unique experiences in one of the world's most fascinating ecosystems.</p>
                        <p className="tomato"><b>Activities at Hotel Sonar Bangla Sundarban</b></p>
                        <p>Guests at Hotel Sonar Bangla Sundarban can enjoy a variety of activities, such as:</p>
                        <ul>
                          <li>•	Mangrove jungle safaris</li>
                          <li>•	Nature trails</li>
                          <li>•	Birdwatching tours</li>
                          <li>•	Visits to tribal villages</li>
                          <li>•	Cycling excursions</li>
                          <li>•	Stargazing</li>
                          <li>•	Cultural evenings</li>
                        </ul><br />
                        <p>After exploring the wild beauty of the mangrove forest, you can treat yourself to exotic spa treatments and the healing benefits of Ayurveda at our wellness clinic.</p>
                        <p className="tomato"><b>Dining and Amenities</b></p>
                        <p>With <strong>Sundarban tour packages with Hotel Sonar Bangla</strong>, enjoy world-class dining at our multi-cuisine restaurant 'Breathing Roots', sip your favorite brew at 'Latte Beehive' Café, or relax at the 'La Crocodile' Bar overlooking the Gomati River. Guests can also enjoy full access to:</p>
                        <ul>
                          <li>•	Gym</li>
                          <li>•	Swimming pool</li>
                          <li>•	Library</li>
                          <li>•	Badminton court</li>
                          <li>•	Game room</li>
                          <li>•	Spa</li>
                        </ul><br />
                        <div className="content-desktop">
                        <center><img src={Hotelsonarbangla3ImageURL} alt="..." /></center>
                        </div>
                        <div className="content-mobile2">
                        <center><img src={Hotelsonarbangla3ImageURL} style={{ width: '320px' }} alt="..." /></center>
                        </div>
                        <p>Spanning over 9 acres beside the Gomati River, this luxurious resort has 75 splendid rooms with CCTV surveillance for your safety. Experience modern luxury with amenities like swimming pools, a kids' playing zone, gym, spa, and library - features that are rare in other five-star hotels in the Sundarbans.</p>
                        <p className="tomato"><b>Luxurious Accommodation</b></p>
                        <p>Immerse yourself in the finest hospitality in Sundarban with the <strong>Sonar Bangla Sundarban Package from Kolkata</strong>. Our suites offer stunning panoramic views of the river and mangrove landscape. Each suite's interior design harmonizes with the local culture, plants, and animals, fostering a deep connection with nature. Book your <strong>Sundarban tour package with Hotel Sonar Bangla</strong> now and begin your luxurious adventure today!</p>
                        <p className="tomato"><b>Special Offers</b></p>
                        <p> We also offer <strong>Sundarban package tours from Kolkata</strong> and <strong>Sundarban houseboat package tours</strong> at the best prices. So, why wait? Pick up your mobile and book the best <strong>Sonar Bangla Sundarban tour package from Kolkata</strong>. <br />
                          <a href="tel://9883359713">
                            CALL +91-98833-59713
                          </a></p>
                        <p className="tomato"><b>Detailed Itinerary and Cost</b></p>
                        <p>For a detailed <strong>Sundarban tour itinerary with Hotel Sonar Bangla</strong> and to know the <strong>Sundarban tour cost with Hotel Sonar Bangla</strong>, contact us today. Experience the perfect blend of luxury and nature with the <strong>Hotel Sonar Bangla Sundarban package tour!</strong></p>
                        <br />
                        <a href="tel://9883359713" className="btn btn-Warning">
                          Book Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="container-fluid py-5">
                <div className="content-desktop">
                <center><img src={Hotelsonarbangla1ImageURL} alt="..." /></center>
                </div>
                <div className="content-mobile2">
                <center><img src={Hotelsonarbangla1ImageURL} style={{ width: '320px' }} alt="..." /></center>
                </div>
              </div>
              
              <br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.7vw" }}><strong>You are Welcome to Sundarban Tour with Hotel Sonar Bangla</strong></h3>
                  <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>You are Welcome to Sundarban Tour with Hotel Sonar Bangla</strong></h3>
                </div>
                <div className="card-body">
                  <p>
                    Welcome to the <strong>Sundarban Hotel Sonar Bangla package</strong>, your gateway to a special luxury resort on 14 acres of beautiful land. <strong>Hotel Sonar Bangla in Sundarban</strong> combines modern comforts with the charm of a traditional village. It's located by the lovely Gomati River, providing a wonderful view of where Royal Bengal Tigers live.
                  </p>
                  <p>
                    Experience the <strong>Sundarban package tour with Hotel Sonar Bangla</strong>, where we surpass your expectations. With 76 luxurious rooms and cottages, we cater to every traveler's needs. From deluxe rooms to premium cottages, <strong>Adorable Vacation</strong> ensures a perfect blend of comfort and elegance in our spaces.
                  </p>
                  <p>Enjoy unmatched luxury with our <strong>2 Nights 3 Days Sonar Bangla Sundarban Tour Packages</strong>, offering stunning views of the river and the mangrove forest. Each suite is designed to give you an immersive experience, highlighting the diverse plants and animals of the Sundarbans. At the <strong>Hotel Sonar Bangla Sundarban Package Tour from Kolkata</strong>, we invite you to connect deeply with nature.</p>
                  <p>Whether you want a peaceful break, thrilling wildlife adventures, or a romantic escape, <strong>Sundarban tour packages with Hotel Sonar Bangla</strong> are perfect for you. Enjoy the natural beauty around us, and let the <strong>Sundarban private tour with Hotel Sonar Bangla from Kolkata</strong> be the backdrop for your unforgettable moments.</p>
                  <p>Book your <strong>Hotel Sonar Bangla Sundarban Package Tour</strong> now and start a journey where luxury meets the wild, creating memories that last long after you leave. Experience Sundarban like never before, and let us redefine your idea of a special getaway</p><br />

                  <a href="tel://9883359713" className="btn btn-Warning">
                    Call Now
                  </a>
                </div>
              </div>
              <br /> <br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.7vw" }}><strong>Destinations Covered in Sundarban Tour Package with Hotel Sonar Bangla</strong></h3>
                  <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>Destinations Covered in Sundarban Tour Package with Hotel Sonar Bangla</strong></h3>
                </div>
                <div className="card-body">
                  <p>
                    With our <strong>2 Nights 3 Days Sonar Bangla Sundarban Tour Packages</strong>, we will visit some amazing places, depending on the <strong>Sundarban package category</strong>, <strong>Sundarban package tour duration</strong>, and <strong>Sundarban tour cost with Hotel Sonar Bangla</strong>. Some of the places we can explore during the <strong>Hotel Sonar Bangla Sundarban package tour from Kolkata</strong> include:
                  </p>
                  <p><b>Pickup & Drop-off</b></p>
                  <p>The pickup and drop-off point for the <strong>Hotel Sonar Bangla Sundarban package tour from Kolkata</strong> is at Science City. For exclusive customized Sundarban tours, we can also pick you up from your home or office.</p>
                  <p>Experience the natural beauty and luxury with <strong>Hotel Sonar Bangla Sundarban</strong> in the heart of Sundarban. Book now and start creating unforgettable memories! Sajnekhali Wildlife Sanctuary,Burirdabri Forest Camp,Dobanki,Jharkhali,Kalash Island,Haliday Island,Bonycamp,Bhagabatpur Crocodile Project,Lothian Island,Sudhanyakhali Watch Tower,Jhingekhali Watchtower,Hamilton Bungalow,Bacon Bungalow,Pakhiralaya,Pirkhali,Banbibi Varani,Sundarkhali,Khonakhali,Choragaji,Deualvarani,SarakKhaliGazikhali</p>
                  {/* <a href="tel://9883359713" className="btn btn-Warning">
                    Call Now
                  </a> */}
                  <br />
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.7vw" }}><strong>How to Book the Hotel Sonar Bangla Sundarban Package Tour from Kolkata</strong></h3>
                  <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>How to Book the Hotel Sonar Bangla Sundarban Package Tour from Kolkata</strong></h3>
                </div>
                <div className="card-body">
                  <p>
                    <b>Overview</b>: Start an unforgettable adventure with the <strong>Hotel Sonar Bangla Sundarban package tour</strong> from Kolkata! Explore the stunning Sundarbans while staying in the comfort of Hotel Sonar Bangla. Our <strong>Sundarban tour packages with Hotel Sonar Bangla</strong> include transportation from Kolkata to the Sundarbans, ensuring a smooth travel experience. Choose between road or river transport, and our expert guides will make sure you have a safe and enjoyable trip. Discover the lush mangrove forests, spot exotic wildlife, and uncover hidden gems along the way. Join us for an adventure of a lifetime with <strong>Hotel Sonar Bangla Sundarban</strong>.
                  </p>
                  <p><strong>Sundarban Trip from Kolkata by Water</strong> Embark on an extraordinary Sundarban adventure by Deluxe Boat or Cruise. Many tour operators offer <strong>Sonar Bangla Sundarban Package from Kolkata</strong>, starting from Babu Ghat or Millennium Park. Cruising the river during winter creates a magical experience as your boat moves through the misty fog. For a luxurious river cruise, consider the Vivada Cruise M.V. Paramhamsa. This 53-meter vessel has 32 well-equipped cabins, luxurious amenities, delicious cuisine, an open-area deck, a library, LED TVs, and modern washrooms. This option guarantees a delightful and memorable journey, enhancing your <strong>Sundarban tour itinerary with Hotel Sonar Bangla</strong>.</p>

                  <div className="content-desktop">
                  <center><img src={Hotelsonarbangla2ImageURL} alt="..." /></center>
                  </div>

                  <div className="content-mobile2">
                  <center><img src={Hotelsonarbangla2ImageURL} style={{ width: '320px' }} alt="..." /></center>
                  </div>
                  <p><strong>Sundarban Package Tour from Kolkata by Flight</strong> For travelers arriving by air for the <strong>Hotel Sonar Bangla Sundarban Tour Packages</strong>, transportation will be arranged from Netaji Subhas Bose International Airport (CCU), the closest airport to Sundarban (110 km away). A pre-booked car will take you on a 2-hour journey to Godkhali, the starting point for your <strong>Sundarban tour package with Hotel Sonar Bangla</strong>.</p>
                  <p><strong>Sundarban Tour Packages from Kolkata by Train</strong> To travel to Sundarban by train, start from Canning. Reach Canning Station in the South Division of the Sealdah Train Service by taking the Canning Local from Sealdah Station, which departs hourly. The 45 km journey to Canning takes about 1 hour and 15 minutes, offering a scenic ride especially enjoyable with an early morning departure and a window seat. This option is also a great part of the <strong>Sundarban tour itinerary with Hotel Sonar Bangla</strong>.</p>
                  <p><strong>Hotel Sonar Bangla Sundarban Trip by Road Traveling to Sundarban by road from Kolkata is convenient. There are two routes to choose from:</strong></p>
                  <ul>
                    <li>•	<b>Route 1</b>: Via Baruipur – Canning Road (2 hr 59 min / 82.9 km)</li>
                    <li>•	<b>Route 2</b>: Via Basanti Highway (3 hr 13 min / 86 km)</li>
                  </ul>
                  <p>These routes offer flexibility and are ideal for those preferring a comfortable and direct journey to Sundarban, part of the <strong>Sundarban tour cost with Hotel Sonar Bangla</strong>.<br />
                    For a seamless experience, book the <strong>Hotel Sonar Bangla Sundarban package tour</strong> today and enjoy the beauty of the Sundarbans with comfort and ease.
                  </p>
                </div>
              </div>
           
              
              <br />
              <br /> 

              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h3 className="content-desktop" style={{ fontSize: "2.0vw" }}>
                        DAY WISE PLAN FOR <strong className="tomato">SUNDARBAN SONAR BANGLA PACKAGE TOUR</strong>
                      </h3>
                      <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>
                        DAY WISE PLAN FOR <strong className="tomato">SUNDARBAN SONAR BANGLA PACKAGE TOUR</strong>
                      </h3>
                    </div>
                  </div>
                </div>

                <br />
                <div className="row">
                  <div
                    className="accordion"
                    id="accordionPanelsStayOpenExample"
                  >
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="panelsStayOpen-headingOne"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseOne"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseOne"
                        >
                          DAY 1 : Pickup and Exploration
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingOne"
                      >
                        <div className="accordion-body">
                          <p>Your journey into the enchanting world of Sundarban with the <strong>Hotel Sonar Bangla Sundarban package tour</strong> starts with a pickup from Science City, Kolkata. From there, enjoy a scenic drive to Godkhali/Sonakhali, about 3.5 hours away. Upon arrival, you’ll be greeted by our Sundarban representative.</p>

                          <p><strong className="tomato">Boat Journey & Welcome:</strong> Board the boat at Godkhali jetty and enjoy a refreshing welcome drink like green coconut, along with tea and snacks. The boat will take you to Gosaba Island, the largest in the Sundarban region.</p>
                          <p><strong className="tomato">Exploring Gosaba Island:</strong> Visit historical sites like Hamilton Bungalow and Bacon Bungalow, which showcase the region’s colonial past and the contributions of Sir Daniel Mackinnon Hamilton.</p>
                          <p><strong className="tomato">Pakhiralaya - Home of Birds:</strong> In the afternoon, head by boat to Pakhiralaya, known as the "Home of Birds." Enjoy the sight of various bird species returning to their nests, a magical experience for bird enthusiasts.</p>
                          <p>
                            <strong className="tomato">Cultural Experience and Dining:</strong> In the evening, enjoy a traditional folk dance performance by village artists. Dinner will feature authentic regional cuisine.
                          </p>
                          <p><strong className="tomato">Meals for Day 1 Sundarban Trip:</strong></p>
                          <ul>
                            <li>•	Welcome Drinks and Tea/Snacks on the boat.</li><br />
                            <li><strong className="tomato">•	Lunch:</strong> Rice/Roti, Dal, Potato Fry, Mix Veg Curry, Doi Katla Fish, Prawn Curry, Chutney, Papad, Salad, Sweet.</li><br />
                            <li><strong className="tomato">•	Evening Snacks:</strong> Chicken Pakora, Salad, Samosa, Tea/Coffee.</li><br />
                            <li><strong className="tomato">•	Dinner:</strong> Roti/Rice, Chilli Chicken, Fried Rice, Salad, and Sweet.</li><br />
                          </ul>
                          <div className="content-desktop">
                        <center><img src={HotelsonarbanglaBoatImageURL} alt="..." /></center>
                        </div>
                        <div className="content-mobile2">
                        <center><img src={HotelsonarbanglaBoatImageURL} style={{ width: '320px' }} alt="..." /></center>
                        </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="panelsStayOpen-headingTwo"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseTwo"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseTwo"
                        >
                          Day 2: Full Day Sundarban Sightseeing Tour
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseTwo"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingTwo"
                      >
                        <div className="accordion-body">
                          <p>
                            Your second day in the Sundarban tour package with Hotel Sonar Bangla promises an exciting exploration of the region's natural wonders and cultural richness.
                          </p>
                          <p><strong className="tomato">Morning Delights:</strong> Start your day early with a walk around the resort, visit the man-made dams, and mingle with locals. Witness the sunrise by the river amidst the mangroves.</p>
                          <p><b>Preparations for the Day Ahead:</b> Return to the hotel for morning tea and perhaps a dip in the swimming pool. Prepare for an exciting jungle safari through Sundarban’s rivers and creeks to spot wildlife.</p>
                          <p><strong className="tomato">Sundarban Day Tour Package Excursion:</strong> After breakfast, embark on a day-long jungle safari, visiting:</p>
                          <p><strong className="tomato">•	Sajnekhali Watch Tower: </strong>Panoramic views of the mangroves and wildlife.</p>
                          <p><strong className="tomato">•	Mangrove Interpretation Centre: </strong>Learn about the ecosystem</p>
                          <p><strong className="tomato">•	Banbib Varani:</strong> Pay homage to the guardian spirit of the forest.</p>
                          <p><strong className="tomato">•	Sudhanyakhali Watch Tower:</strong> Known for tiger sightings.</p>
                          <p><strong className="tomato">•	Dobanki Canopy Walks & Watch Tower:</strong> Stunning views of the reserve forest.</p>
                          <p><strong className="tomato">•	Panch Mukhi (5 River Junction):</strong> Experience the confluence of five rivers.</p>
                          <p><strong className="tomato">Relaxation and Cultural Evening:</strong> Return to the hotel after a cruise, freshen up, and enjoy evening snacks. Optionally, enjoy cultural performances or relax by the bonfire.</p>
                          <p><b className="tomato">Sonar Bangla Sundarban Menu:</b></p>
                          <ul>
                            <li><b>•	Breakfast:</b> Hing Kachuri/Puri, Chana Masala, Banana, Sweet, Tea/Coffee.</li>
                            <li><b>•	Lunch:</b> Rice/Roti, Dal, Begun Bhaja, Mix Veg Curry, Bhetki Fish Curry, Crab Curry, Chutney, Papad, Sweet.</li>
                            <li><b>•	Evening Snacks:</b> Veg Pakora / Chowmein, Tea/Coffee.</li>
                            <li><b>•	Dinner:</b> Mutton Biryani / Veg Biryani, Chicken Kasa, Salad, Sweets.</li>
                          </ul>
                          <div className="content-desktop">
                        <center><img src={HotelsonarbanglaFoodImageURL} alt="..." /></center>
                        </div>
                        <div className="content-mobile2">
                        <center><img src={HotelsonarbanglaFoodImageURL} style={{ width: '320px' }} alt="..." /></center>
                        </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="panelsStayOpen-headingThree"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseThree"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          Day 3: Explore Village and Cherish Your Memories
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseThree"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingThree"
                      >
                        <div className="accordion-body">
                          <p><strong className="tomato">Morning Activities:</strong> Wake up early to enjoy the last day of your <strong>Sundarban tour package with Hotel Sonar Bangla</strong>. Take a walk over the dams, visit nearby villages, and interact with locals. You can buy Sundarban honey or fresh vegetables.</p>
                          <p><b className="tomato">Return to Resort:</b> After a shower, gather for breakfast. Depart from the resort around 9am, with a packed lunch served during the boat journey back to Godkhali. From there, you'll be driven back to Kolkata, promising to return to this beautiful place.</p>
                          <p><b className="tomato">Meals for 3rd Day Sundarban Tour:</b></p>
                          <ul>
                            <li>
                              <b>•	Breakfast:</b> Luchi, Aloo Dum, Sweet, Tea.
                            </li>
                            <li><b>•	Lunch:</b> Rice, Dal, Aloo Fry, Veg Curry, Fish Curry, Chutney, Papad, Sweet</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <h3><center><strong className="tomato"><u>Sundarban Sonar Bangla Hilsa Festival Special Package Price </u></strong></center></h3>
              <br />
              <div className="container-fluid navtabCustom">
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  <button onClick={() => this.handleCustomTabClick('tab1')} className={`tab button ${customActiveTab === 'tab1' ? 'active' : ''}`} role="tab" aria-controls="nav-price" aria-selected={customActiveTab === 'tab1'}>Price</button>

                  <button onClick={() => this.handleCustomTabClick('tab3')} className={`tab button ${customActiveTab === 'tab3' ? 'active' : ''}`} role="tab" aria-controls="nav-travellers-info" aria-selected={customActiveTab === 'tab3'}>Travellers Info</button>

                  <button onClick={() => this.handleCustomTabClick('tab2')} className={`tab button ${customActiveTab === 'tab2' ? 'active' : ''}`} role="tab" aria-controls="nav-food" aria-selected={customActiveTab === 'tab2'}>Food</button>
                </div>

                <div className="tab-content" id="nav-tabContent">
                  <div className={customActiveTab === 'tab1' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-price-tab">
                    <div id="no-more-tables">
                      <table class="col-md-12 table-bordered table-striped table-condensed cf">
                        <thead class="cf">
                          <tr className="table-primary text-center">
                            {/* <th colSpan="5"><h4 style={{ fontSize: "1.5vw" }} className="content-desktop">Sonar Bangla Tour Package Price</h4></th> */}
                          </tr>
                          {/* <h4 style={{ fontSize: "3.5vw" }} className="content-mobile2 tomato">Sonar Bangla Tour Package Price</h4> */}
                          <tr className="table-danger">
                            <th>Group</th>
                            <th>Date of Journey</th>
                            <th>Double Sharing (Per Pax)</th>
                            <th>Triple Sharing (Per Pax)</th>
                            <th>Meal</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td data-title="Group">Group 1</td>
                            <td data-title="Date of Journey">15 Aug (Ex-Kol)</td>
                            <td data-title="Dbl (Per Pax)">14500 /-</td>
                            <td data-title="Tpl (Per Pax)">13500 /-</td>
                            <td data-title="Meal">All Meals</td>
                          </tr>
                          <tr>
                            <td data-title="Group">Group 2</td>
                            <td data-title="Date of Journey">16 Aug (Ex-Kol)</td>
                            <td data-title="Dbl (Per Pax)">14500 /-</td>
                            <td data-title="Tpl (Per Pax)">13500 /-</td>
                            <td data-title="Meal">All Meals</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className={customActiveTab === 'tab3' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-travellers-info-tab">
                    <h5><b>Important Guidelines for Sundarban Tour Packages with Hotel Sonar Bangla</b></h5>
                    <p><b>1.	ID Requirements:</b></p>
                    <ul>
                      <li><b>Indian Nationals:</b> Carry a photo ID with two photocopies.</li><br />
                      <li><b>Foreigners:</b> Carry your original passport with a valid Indian Visa.</li>
                    </ul><br />
                    <p><b>2.	Health Precautions:</b></p>
                    <ul>
                      <li>Bring common medicines for any unexpected health needs.</li>
                    </ul><br />
                    <p><b>3.	Environmental Care:</b></p>
                    <ul>
                      <li>Sundarban is a plastic-free zone. Do not use plastic or throw anything from the boat.</li>
                    </ul><br />
                    <p><b>4.	Prohibited Activities:</b></p>
                    <ul>
                      <li>Hunting, fishing, and damaging flora are serious offenses.</li>
                    </ul>
                    <p><b>5.	Cash and Permits:</b></p>
                    <ul>
                      <li>Carry cash as ATMs are limited. Do not enter Sundarbans National Park/Tiger Reserve without a valid permit.</li>
                    </ul>
                    <p><b>6.	Respect for Nature:</b></p>
                    <ul>
                      <li>Dress in colors that blend with nature. Do not enter the protected area after sunset or before sunrise.</li>
                      <li>Maintain a safe distance from wild animals and avoid provoking them.</li>
                      <li>Do not play any sound systems during your visit.</li>
                    </ul><br />
                    <p><b>7.	Restricted Areas and Items:</b></p>
                    <ul>
                      <li>Entry into the Core/Non-tourism area is prohibited.</li>
                      <li>Carrying guns, weapons, bows, arrows, drones, and inflammable materials is strictly prohibited.</li>
                    </ul><br />
                    <p><b>8.	Essentials to Carry:</b></p>
                    <ul>
                      <li>Sunglasses, hats, and sunscreen.</li>
                      <li>Comfortable footwear, a camera, and binoculars.</li>
                      <li>Mask, hand sanitizer, and maintain social distance.</li>
                    </ul><br />
                    <p><b>9.	Seasonal Clothing:</b></p>
                    <ul>
                      <li><b>Winter:</b> Bring warm clothes as it might get chilly.</li>
                      <li><b>Summer:</b> Opt for light and comfortable clothing.</li>
                    </ul><br />
                    <p><b>10.	Food and Beverages:</b></p>
                    <ul>
                      <li>All food and beverages must be enjoyed from the property itself. Outside food deliveries (Zomato, Swiggy, etc.) are not allowed.</li>
                    </ul><br />
                    <p><b>11.	Pets:</b></p>
                    <ul>
                      <li>Pets are welcome at Hotel Sonar Bangla Sundarban. Ensure they are properly leashed.</li>
                    </ul><br />
                    <p><b>12.	Unmarried Couples:</b></p>
                    <ul>
                      <li>Unmarried couples are allowed but must submit valid ID proofs.</li>
                    </ul><br />
                    <p><b>13.	Private Parties and Events:</b></p>
                    <ul>
                      <li>You can arrange private parties or events, following all rules and regulations.</li>
                    </ul><br />
                    <p><b>14.	Visitors Policy:</b></p>
                    <ul>
                      <li>Guests cannot invite outside visitors into their rooms during their stay.</li>
                    </ul><br />
                    <p><b>15.	Swimming Pool Etiquette:</b></p>
                    <ul>
                      <li>Follow proper rules, decorum, and etiquette at the swimming pool.</li>
                    </ul>
                    <br /><br />
                  </div >
                  <div className={customActiveTab === 'tab2' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-food-tab">
                    <p><b>Foods We Offer in the 2 Nights 3 Days Sundarban Package Tour from Kolkata with Adorable Vacation</b></p><br />
                    <p>Guests traveling to Sundarban with <strong>Adorable Vacation</strong> love good food. Depending on the tour duration (1 Night 2 Days or 2 Nights 3 Days), we offer delicious meals at the resort or on the boat:</p><br />
                    <p><strong>Welcome Drink:</strong> Green Coconut / Tea / Coffee</p>
                    <p><strong>Breakfast:</strong> Hing Kachuri / Paratha / Luchi with Veg Curry / Chana Masala / Cholar Daal, Banana/Orange, Sweet, Tea/Coffee</p>
                    <p><strong>Morning Snacks:</strong> Prawn Pakora / Onion Pakora</p>
                    <p><strong>Lunch:</strong> Rice/Roti, Dal, Potato Fry, Mix Veg Curry, Doi Katla Fish / Pabda Jhol / Bhetki Fish Curry / Hilsa Fish / Prawn Curry / Crab Curry, Chutney, Papad, Salad, Sweet</p>
                    <p><strong>Evening Snacks:</strong> Veg Pakora / Chicken Pakora / Chowmein / Samosa, Salad, Coffee/Tea</p>
                    <p><strong>Dinner:</strong> Roti / Rice / Fried Rice / Dal, Veg Fry, Veg Curry, Veg Biryani, Chilli Chicken / Chicken Kasa, Salad, Sweet</p>
                    <p>Enjoy these tasty meals during your Sundarban tour package with Adorable Vacation LLP !</p>
                  </div>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.7vw" }}><strong>BEST TRAINS YOU CAN CATCH FOR SUNDARBAN TOUR FROM CANNING</strong></h3>
                  <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>BEST TRAINS YOU CAN CATCH FOR SUNDARBAN TOUR FROM CANNING</strong></h3>
                </div>
                <div className="card-body">
                  <p>&nbsp;KOLKATA [SEALDAH] &nbsp;
                    <i className="fa fa-arrow-right"></i>&nbsp;BALLYGUNGE JN&nbsp;
                    <i className="fa fa-arrow-right"></i>&nbsp;DHAKURIA&nbsp;
                    <i className="fa fa-arrow-right"></i>&nbsp;JADAVPUR&nbsp;
                    <i className="fa fa-arrow-right"></i>&nbsp;BAGHA JATIN&nbsp;
                    <i className="fa fa-arrow-right"></i>&nbsp;GARIA&nbsp;
                    <i className="fa fa-arrow-right"></i>&nbsp;NARENDRAPUR HALT&nbsp;
                    <i className="fa fa-arrow-right"></i>&nbsp;SONARPUR JN&nbsp;
                    <i className="fa fa-arrow-right"></i>&nbsp;BIDYADHARPUR&nbsp;
                    <i className="fa fa-arrow-right"></i>&nbsp;KALIKAPUR&nbsp;
                    <i className="fa fa-arrow-right"></i>&nbsp;CHAMPAHATI&nbsp;
                    <i className="fa fa-arrow-right"></i>&nbsp;PIALI&nbsp;
                    <i className="fa fa-arrow-right"></i>&nbsp;GOURDAHA (HALT)&nbsp;
                    <i className="fa fa-arrow-right"></i>&nbsp;GHUTIARI SHARIF&nbsp;
                    <i className="fa fa-arrow-right"></i>&nbsp;BETHBERIA&nbsp;
                    <i className="fa fa-arrow-right"></i>&nbsp;GHOLA&nbsp;
                    <i className="fa fa-arrow-right"></i>&nbsp;TALDI&nbsp;
                    <i className="fa fa-arrow-right"></i>&nbsp;CANNING&nbsp;
                  </p>
                  <br />
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf" style={{ width: '100%', height: '50%' }}>
                      <thead className="cf">
                        <tr className="table text-center">
                          <th>TRAIN</th>
                          <th>DEPARTURE [SEALDAH]</th>
                          <th>ARRIVAL [CANNING]</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentRows.map((row, index) => (
                          <tr className="text-center" key={index}>
                            <td data-title="Train">{row.train}</td>
                            <td data-title="Departure">{row.departure}</td>
                            <td data-title="Arrival">{row.arrival}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                  </div>
                </div>
              </div>
              <br />
              <div className="container-fluid py-5">
                <div id="no-more-tables">
                  <table class="col-md-12 table-bordered table-striped table-condensed cf">
                    <thead class="cf">
                      <tr className="table-primary text-center">
                        <th colSpan="5"><h4 style={{ fontSize: "1.5vw" }} className="content-desktop">Sonar Bangla Tour Premium Package Price</h4></th>
                      </tr>
                      <h4 style={{ fontSize: "3.5vw" }} className="content-mobile2 tomato"><center><strong>Sonar Bangla Tour Premium Package Price</strong></center></h4>
                      <tr className="table-danger">
                        <th>No of Pax</th>
                        <th>Premium (Per Pax)</th>
                        <th>Meal</th>
                        <th>Reach us</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td data-title="No of Pax">02 PAX
                          (Gadkhali)</td>
                        <td data-title="Premium (PP)">17000 /-</td>
                        <td data-title="Meal">Breakfast/Dinner</td>
                        <td data-title="Reach Us">
                          <a href="#" className="btn btn-success" onClick={this.handleClick1}>
                            Book Now
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td data-title="No of Pax">02 PAX
                          (Kolkata)</td>
                        <td data-title="Premium (PP)">20800 /-</td>
                        <td data-title="Meal">Breakfast/Dinner</td>
                        <td data-title="Reach Us">
                          <a href="#" className="btn btn-success" onClick={this.handleClick1}>
                            Book Now
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td data-title="No of Pax">04 PAX
                          (Gadkhali)</td>
                        <td data-title="Premium (PP)">13999 /-</td>
                        <td data-title="Meal">Breakfast/Dinner</td>
                        <td data-title="Reach Us">
                          <a href="#" className="btn btn-success" onClick={this.handleClick1}>
                            Book Now
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td data-title="No of Pax">04 PAX
                          (Kolkata)</td>
                        <td data-title="Premium (PP)">15900 /-</td>
                        <td data-title="Meal">Breakfast/Dinner</td>
                        <td data-title="Reach Us">
                          <a href="#" className="btn btn-success" onClick={this.handleClick1}>
                            Book Now
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td data-title="No of Pax">06 PAX
                          (Gadkhali)</td>
                        <td data-title="Premium (PP)">12999 /-</td>
                        <td data-title="Meal">Breakfast/Dinner</td>
                        <td data-title="Reach Us">
                          <a href="#" className="btn btn-success" onClick={this.handleClick1}>
                            Book Now
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td data-title="No of Pax">06 PAX
                          (Kolkata)</td>
                        <td data-title="Premium (PP)">14900 /-</td>
                        <td data-title="Meal">Breakfast/Dinner</td>
                        <td data-title="Reach Us">
                          <a href="#" className="btn btn-success" onClick={this.handleClick1}>
                            Book Now
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td data-title="No of Pax">08 PAX
                          (Gadkhali)</td>
                        <td data-title="Premium (PP)">13000 /-</td>
                        <td data-title="Meal">Breakfast/Dinner</td>
                        <td data-title="Reach Us">
                          <a href="#" className="btn btn-success" onClick={this.handleClick1}>
                            Book Now
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td data-title="No of Pax">08 PAX
                          (Kolkata)</td>
                        <td data-title="Premium (PP)">15040 /-</td>
                        <td data-title="Meal">Breakfast/Dinner</td>
                        <td data-title="Reach Us">
                          <a href="#" className="btn btn-success" onClick={this.handleClick1}>
                            Book Now
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td data-title="No of Pax">10 PAX
                          (Gadkhali)</td>
                        <td data-title="Premium (PP)">11680 /-</td>
                        <td data-title="Meal">Breakfast/Dinner</td>
                        <td data-title="Reach Us">
                          <a href="#" className="btn btn-success" onClick={this.handleClick1}>
                            Book Now
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td data-title="No of Pax">10 PAX
                          (Kolkata)</td>
                        <td data-title="Premium (PP)">13600 /-</td>
                        <td data-title="Meal">Breakfast/Dinner</td>
                        <td data-title="Reach Us">
                          <a href="#" className="btn btn-success" onClick={this.handleClick1}>
                            Book Now
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td data-title="No of Pax">20 PAX
                          (Gadkhali)</td>
                        <td data-title="Premium (PP)">11440 /-</td>
                        <td data-title="Meal">Breakfast/Dinner</td>
                        <td data-title="Reach Us">
                          <a href="#" className="btn btn-success" onClick={this.handleClick1}>
                            Book Now
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td data-title="No of Pax">20 PAX
                          (Kolkata)</td>
                        <td data-title="Premium (PP)">12544 /-</td>
                        <td data-title="Meal">Breakfast/Dinner</td>
                        <td data-title="Reach Us">
                          <a href="#" className="btn btn-success" onClick={this.handleClick1}>
                            Book Now
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td data-title="No of Pax">EXTRA PAX
                          (Gadkhali)</td>
                        <td data-title="Premium (PP)">7120 /-</td>
                        <td data-title="Meal">Breakfast/Dinner</td>
                        <td data-title="Reach Us">
                          <a href="#" className="btn btn-success" onClick={this.handleClick1}>
                            Book Now
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td data-title="No of Pax">EXTRA PAX
                          (Kolkata)</td>
                        <td data-title="Premium (PP)">9040 /-</td>
                        <td data-title="Meal">Breakfast/Dinner</td>
                        <td data-title="Reach Us">
                          <a href="#" className="btn btn-success" onClick={this.handleClick1}>
                            Book Now
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td data-title="No of Pax">CHILD NO BED
                          (Gadkhali)</td>
                        <td data-title="Premium (PP)">5800 /-</td>
                        <td data-title="Meal">Breakfast/Dinner</td>
                        <td data-title="Reach Us">
                          <a href="#" className="btn btn-success" onClick={this.handleClick1}>
                            Book Now
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td data-title="No of Pax">CHILD NO BED
                          (Kolkata)</td>
                        <td data-title="Premium (PP)">7720 /-</td>
                        <td data-title="Meal">Breakfast/Dinner</td>
                        <td data-title="Reach Us">
                          <a href="#" className="btn btn-success" onClick={this.handleClick1}>
                            Book Now
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <br />
              {/* <!--insert table inclusion exclusion--> */}
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    {
                      <InclusionExclusion />
                    }
                  </div>
                </div>
              </div>
              {/* <!--end table inclusion exclusion--> */}

              <div className="container-fluid py-4">

                <div>
                  <p>
                    Experience the magic of Sundarban with <strong>Sundarban tour packages with Hotel Sonar Bangla</strong>. Our well-planned Sundarban tour itinerary with Hotel Sonar Bangla ensures a seamless and enjoyable trip. The Sundarban tour cost with Hotel Sonar Bangla includes all essentials for a memorable adventure. Join the Hotel Sonar Bangla Sundarban package tour for an unforgettable journey.
                  </p>

                  <h4 className='tomato'><u>Things to Carry for Hotel Sonar Bangla Sundarban Package Tour</u></h4>
                  <ul>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>
                      &nbsp;&nbsp;Bring masks, hand sanitizer, and maintain social distance.
                    </li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>
                      &nbsp;&nbsp;Pack woolen clothes for the chilly Sundarban winters..
                    </li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Wear light-colored clothing to blend with the natural surroundings.</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Carry a photo ID proof along with two photocopies.</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Foreign visitors should have a valid passport with an Indian visa.</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Show respect towards the local inhabitants of Sundarban.</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Drink only packaged or bottled water.</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Pack snacks, dry fruits, or biscuits for the journey.</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Don’t forget sunglasses, hats, and sunblock for protection.</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Bring essentials like torches, candles, lighters, mobile chargers, and power banks.</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Keep enough cash handy as ATM availability might be limited.</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Pack basic medications for common ailments like fever, cough, or stomach upset.</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Consider gum boots if planning for a mud walk.</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Binoculars are handy for spotting animals and birds during boat cruises or jungle walks.</li>
                  </ul><br /><br />
                  <h4 className='tomato'><u>Things to Avoid for Sonar Bangla Sundarban Package from Kolkata</u></h4>
                  <ul>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>
                      &nbsp;&nbsp;Avoid bringing plastics as Sundarban is a plastic-free zone.</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>
                      &nbsp;&nbsp;Refrain from littering, especially from boats or launches.</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>
                      &nbsp;&nbsp;Avoid playing loud music or using loudspeakers to maintain the tranquility.</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>
                      &nbsp;&nbsp;Keep noise levels down to avoid disturbing wildlife and fellow visitors.</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>
                      &nbsp;&nbsp;Do not bring pets along for your Sundarban tour.</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>
                      &nbsp;&nbsp;Never tease or chase wild animals; it’s strictly prohibited.</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>
                      &nbsp;&nbsp;Feeding wild animals is also prohibited in Sundarban.</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>
                      &nbsp;&nbsp;Drone videography is not permitted in Sundarban.</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>
                      &nbsp;&nbsp;Enter Sundarban National Park/Tiger Reserve only with a valid permit.</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>
                      &nbsp;&nbsp;Stay within the designated areas of the reserve, permitted only from sunrise to sunset.</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>
                      &nbsp;&nbsp;Activities like hunting, fishing, and entering restricted forest areas are serious offenses.</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>
                      &nbsp;&nbsp;Prohibited items include guns, firearms, bows, arrows, crackers, and inflammable materials.</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>
                      &nbsp;&nbsp;Avoid bathing in river water for safety reasons.</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>
                      &nbsp;&nbsp;Stick to designated tourist zones; entry into core or protected areas is restricted.</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>
                      &nbsp;&nbsp;Consumption of liquor or alcohol is strictly prohibited within the Sundarban Reserve area.</li>
                  </ul>
                </div>
              </div>

              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h3 className="content-desktop" style={{ fontSize: "2.0vw" }}>
                        FAQs for <strong className="tomato">Sundarban Tour Packages with Hotel Sonar Bangla</strong>
                      </h3>
                      <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>
                      FAQs for <strong className="tomato">Sundarban Tour Packages with Hotel Sonar Bangla</strong>
                      </h3>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div
                    className="accordion accordion-flush faQStyle"
                    id="accordionFlushExample"
                  >
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingOne">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseOne"
                          aria-expanded="false"
                          aria-controls="flush-collapseOne"
                        >
                          What is the price range for Hotel Sonar Bangla in Sundarban?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseOne"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingOne"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          The Sundarban tour cost with Hotel Sonar Bangla starts from ₹6799 per room per night, offering excellent value for your stay.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingTwo">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseTwo"
                          aria-expanded="false"
                          aria-controls="flush-collapseTwo"
                        >
                          What types of rooms does Hotel Sonar Bangla in Sundarban offer?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseTwo"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingTwo"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Hotel Sonar Bangla Sundarban offers a range of rooms including Deluxe Rooms, Premium Rooms, and Suites.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingThree">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseThree"
                          aria-expanded="false"
                          aria-controls="flush-collapseThree"
                        >
                          What are the Check-In and Check-Out times at Hotel Sonar Bangla in Sundarban?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseThree"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingThree"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          The standard Check-In time at Hotel Sonar Bangla Sundarban is 1 PM, and Check-Out is at 11 AM. Early Check-In is subject to availability.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingFour">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFour"
                          aria-expanded="false"
                          aria-controls="flush-collapseFour"
                        >
                          Is it possible to reserve a private boat for the Sundarbans tour package with Hotel Sonar Bangla?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseFour"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingFour"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Yes, guests can book a private boat for their family or group tour from either Godkhali or Sonakhali.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingFive">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFive"
                          aria-expanded="false"
                          aria-controls="flush-collapseFive"
                        >
                          What are the inclusions of the Sundarban package if starting from Kolkata?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseFive"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingFive"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          The package includes car fare to Godkhali, accommodation at Hotel Sonar Bangla with breakfast, lunch on the boat, and dinner/snacks at the hotel. Cultural programs can be arranged upon request.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingSix">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseSix"
                          aria-expanded="false"
                          aria-controls="flush-collapseFive"
                        >
                          What modes of local transportation are available in Sundarbans?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseSix"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingSix"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          River cruises on boats are the best way to explore Sundarban. Within the delta, paddle vans or motor vans are available.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingSeven">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseSeven"
                          aria-expanded="false"
                          aria-controls="flush-collapseSeven"
                        >
                          Is it secure to explore the Sundarbans jungle?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseSeven"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingSeven"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Yes, visiting with a knowledgeable guide is considered safe. Organizing your trip through a reputable tour operator is recommended.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingEight">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseEight"
                          aria-expanded="false"
                          aria-controls="flush-collapseEight"
                        >
                          Where is Hotel Sonar Bangla, Sundarban located?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseEight"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingEight"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Hotel Sonar Bangla is in Village Dulki, PO/PS - Gosaba, Dist - 24 Parganas (South), West Bengal, India.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingNine">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseNine"
                          aria-expanded="false"
                          aria-controls="flush-collapseNine"
                        >
                          Where is the corporate office of Hotel Sonar Bangla?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseNine"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingNine"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          The corporate office is at Megatherm Building, Plot - L1, Block - GP, 6th Floor, Sector - V, Electronics Complex, Salt Lake City, Kolkata- 700091, West Bengal, India.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingTen">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseTen"
                          aria-expanded="false"
                          aria-controls="flush-collapseTen"
                        >
                          Is free parking available at Hotel Sonar Bangla Sundarban?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseTen"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingTen"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Parking facilities are not required as guests typically arrive via boat. However, a parking lot is planned for the future.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingEleven">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseEleven"
                          aria-expanded="false"
                          aria-controls="flush-collapseEleven"
                        >
                          Can we have hot water at Hotel Sonar Bangla, Sundarban?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseEleven"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingEleven"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Yes, each room has a geyser facility for hot water.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingTwelve">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseTwelve"
                          aria-expanded="false"
                          aria-controls="flush-collapseTwelve"
                        >
                          Does Hotel Sonar Bangla Sundarban have a swimming pool?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseTwelve"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingTwelve"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Yes, the hotel features a swimming pool open to all guests.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingThirteen">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseThirteen"
                          aria-expanded="false"
                          aria-controls="flush-collapseThirteen"
                        >
                          What is the ideal duration for a Sundarban tour with Hotel Sonar Bangla?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseThirteen"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingThirteen"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          A 2-night, 3-day Sundarban package tour with Hotel Sonar Bangla allows for a relaxed exploration, covering major attractions.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingFourteen">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFourteen"
                          aria-expanded="false"
                          aria-controls="flush-collapseFourteen"
                        >
                          What is the ideal duration for a Sundarban tour with Hotel Sonar Bangla?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseFourteen"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingFourteen"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          A 2-night, 3-day Sundarban package tour with Hotel Sonar Bangla allows for a relaxed exploration, covering major attractions.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingFifteen">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFifteen"
                          aria-expanded="false"
                          aria-controls="flush-collapseFifteen"
                        >
                          What are the popular amenities available at Hotel Sonar Bangla, Sundarban?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseFifteen"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingFifteen"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Popular amenities include well-furnished rooms, outdoor activities, CCTV surveillance, dining facilities, room service, and a swimming pool.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingSixteen">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseSixteen"
                          aria-expanded="false"
                          aria-controls="flush-collapseSixteen"
                        >
                          Can we book Hotel Sonar Bangla for our Sundarban tour?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseSixteen"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingSixteen"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Yes, Hotel Sonar Bangla in Sundarban is a luxurious 4-star property offering modern amenities. It’s an excellent choice for a lavish experience during your Sundarban tour.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container-fluid py-4">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h3 className="content-desktop" style={{ fontSize: "2.0vw" }}>
                        <strong className="tomato">Sundarban Tour Package</strong> Reviews
                      </h3>
                      <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>
                        <strong className="tomato">Sundarban Tour Package</strong> Reviews
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="row row-cols-1 ">
                  <div id="testimonials">
                    <div className="testimonial-box-container">
                      {reviewersDataView}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <BookingBox />
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default Sundarban_SonarBanglaTourPackage;
