import React, { Component } from 'react';
import BookingBox from '../BookingBox';
import Slider from "react-slick";
import ApiURL from "../../../api/ApiURL";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InclusionExclusion from '../Kashmir/InclusionExclusion';

// import FloatingWhatsAppButton from 'react-floating-whatsapp';

class Kashmir_6N_7D_Package extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customActiveTab: 'tab1',
            daywiseActiveTab: 'day1'
        }
    }

    handleCustomTabClick = (tab) => {
        this.setState({
            customActiveTab: tab,
        });
    };

    handleDayWiseTabClick = (tab) => {
        this.setState({
            daywiseActiveTab: tab,
        });
    };
    handleClick = () => {
        const currentUrl = window.location.href;
        const pageTitle = document.title;
        const message = `Hi, Please send about the Tour Package details "${pageTitle}"`;

        const whatsappLink = `https://api.whatsapp.com/send?phone=+919147061467&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
        window.open(whatsappLink, '_blank');
    };

    render() {
        const { customActiveTab, daywiseActiveTab } = this.state;
        const packageTopBannerImageURL = ApiURL.Kashmir_Tour_Package_from_Srinagar_Airport + "/Kashmir-Tour-Package-6N-7D.webp";
        const packageTopBanner2ImageURL = ApiURL.Kashmir_Tour_Package_from_Srinagar_Airport + "/Kashmir-Package-Tour.webp";
        const sightseen1ImageURL = ApiURL.Kashmir_Tour_Package_from_Srinagar_Airport + "/kashmir-tour-packages.webp";
        const sightseen2ImageURL = ApiURL.Kashmir_Tour_Package_from_Srinagar_Airport + "/kashmir-trip-from-srinagar-airport.webp";
        const sightseen3ImageURL = ApiURL.Kashmir_Tour_Package_from_Srinagar_Airport + "/kashmir-package-tour-cost-from-srinagar-airport.webp";
        const sightseen4ImageURL = ApiURL.Kashmir_Tour_Package_from_Srinagar_Airport + "/kashmir-package-tour-from-srinagar.webp";
        const sightseen5ImageURL = ApiURL.Kashmir_Tour_Package_from_Srinagar_Airport + "/kashmir-package-price-from-srinagar-airport.webp";
        const sightseen6ImageURL = ApiURL.Kashmir_Tour_Package_from_Srinagar_Airport + "/kashmir-packages-from-srinagar-airport.webp";
        const sightseen7ImageURL = ApiURL.Kashmir_Tour_Package_from_Srinagar_Airport + "/kashmir-package-tour-booking-from-srinagar.webp";
        const sightseen8ImageURL = ApiURL.Kashmir_Tour_Package_from_Srinagar_Airport + "/kashmir-package-tour-booking-from-srinagar-airport.webp";
        const sightseen9ImageURL = ApiURL.Kashmir_Tour_Package_from_Srinagar_Airport + "/kashmir-package-tour-cost-from-srinagar.webp";
        const sightseen10ImageURL = ApiURL.Kashmir_Tour_Package_from_Srinagar_Airport + "/kashmir-family-holiday-package-tour-from-srinagar-airport.webp";
        const sightseen11ImageURL = ApiURL.Kashmir_Tour_Package_from_Srinagar_Airport + "/kashmir-package-from-srinagar-airport.webp";
        const sightseen12ImageURL = ApiURL.Kashmir_Tour_Package_from_Srinagar_Airport + "/kashmir-package-price-from-srinagar.webp";
//day wise itenaryy
        const navDay1ImageURL = ApiURL.Kashmir_Tour_Package_from_Srinagar_Airport + "/6N-7D-Kashmir-Tour-Plan-from-Srinagar-Airport.webp";
        const navDay2ImageURL = ApiURL.Kashmir_Tour_Package_from_Srinagar_Airport + "/Best-Kashmir-Tour-Plan.webp";
        const navDay3ImageURL = ApiURL.Kashmir_Tour_Package_from_Srinagar_Airport + "/Kashmir-Family-Tour-Plan.webp";
        const navDay4ImageURL = ApiURL.Kashmir_Tour_Package_from_Srinagar_Airport + "/Kashmir-Houseboat-Package.webp";
        const navDay5ImageURL = ApiURL.Kashmir_Tour_Package_from_Srinagar_Airport + "/Kashmir-Houseboat-Tour-from-Srinagar.webp";
        const navDay6ImageURL = ApiURL.Kashmir_Tour_Package_from_Srinagar_Airport + "/Kashmir-Packages.webp";
        const navDay7ImageURL = ApiURL.Kashmir_Tour_Package_from_Srinagar_Airport + "/Kashmir-Tour-Cost.webp";
        const TopbannerImageURL = ApiURL.Kashmir_Tour_Package_from_Srinagar_Airport + "/Kashmir-Srinagar-Package-Tour-from-Airport.webp";
        const Topbanner0ImageURL = ApiURL.Kashmir_Tour_Package_from_Srinagar_Airport + "/Kashmir-Houseboat-Packages.webp";
        const Topbanner1ImageURL = ApiURL.Kashmir_Tour_Package_from_Srinagar_Airport + "/Kashmir-Adventure-Tour-Plan.webp";
        const Topbanner2ImageURL = ApiURL.Kashmir_Tour_Package_from_Srinagar_Airport + "/Kashmir-Gondola-Package-Tour.webp";
        const Topbanner3ImageURL = ApiURL.Kashmir_Tour_Package_from_Srinagar_Airport + "/Kashmir-Trekking-Packages.webp";
        const Topbanner4ImageURL = ApiURL.Kashmir_Tour_Package_from_Srinagar_Airport + "/Kashmir-White-River-Rafting-Plan.webp";
        const brandedTourackageImage_1_URL = ApiURL.Kashmir_Tour_Package_from_Srinagar_Airport + "/The-Khyber-Himalayan-Resort-&-Spa.webp";
        const brandedTourackageImage_2_URL = ApiURL.Kashmir_Tour_Package_from_Srinagar_Airport + "/The-LaLiT-Grand-Palace-Srinagar-Hotel.webp";
        const TrendingPackagesDataList = this.props.TrendingPackagesData;
        const hotPackagesView = TrendingPackagesDataList.length > 0 ?
            (
                TrendingPackagesDataList.map((dataItem, i) => {
                    const hotelImgURL = ApiURL.Kashmir_Tour_Package_from_Srinagar_Airport + "/" + dataItem.ImageName;
                    return (<div className="col-md-12 p-0" key={i}>
                        <div className="card h-100">
                            <img src={hotelImgURL} className="card-img-top" alt={dataItem.AllternateText} />
                            <div className="card-body">
                                <h6 className="card-title">{dataItem.Title} <span> &#8377; {dataItem.PricePerNight}</span></h6>
                                <p className="card-text">
                                    <i className="fa fa-building tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-utensils tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-car-side tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-male tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-camera tomato"></i>
                                </p>
                                <p className="card-text"><span>{dataItem.Description}</span></p>
                                <p>
                                    <span><b className="tomato">{dataItem.NoOfTours} </b>Tours</span> | &nbsp;
                                    <span><b className="tomato">{dataItem.NoOfDeparture} </b>Departures</span> | &nbsp;
                                    <span><b className="sdf-blue"> {dataItem.Include}</b></span>
                                </p>
                            </div>
                            <div className="card-footer">
                                <small className="text-muted"><strong className="tomato">{dataItem.MutedText}</strong></small>
                                <a href="#" className="btn btn-success btn-sm float-end" onClick={this.handleClick}>
                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                </a>
                            </div>
                        </div>
                    </div>)
                })
            ) : (<div></div>)


        const settings = {
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const reviewersDataList = this.props.ReviewersData;
        const reviewersDataView = reviewersDataList.map((dataItem, i) => {
            const reviewerImageURL = ApiURL.Kashmir_Tour_Package_from_Srinagar_Airport + "/" + dataItem.ImageName;
            return (
                <div className="testimonial-box" key={i}>
                    <div className="box-top">
                        <div className="profile">
                            <div className="profile-img">
                                <img src={reviewerImageURL} alt="..." />
                            </div>
                            <div className="name-user">
                                <strong>{dataItem.Name}</strong>
                                <span>{dataItem.Location}</span>
                            </div>
                        </div>
                        <div className="reviews">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </div>
                    </div>
                    <div className="client-comment">
                        <p>{dataItem.Comment}</p>
                    </div>
                </div>
            )
        })

        return (
            <>

                <div className="container-fluid">
                    <div className="row">
                        <img src={packageTopBannerImageURL} className="mobile-image2" alt="..." />
                    </div>
                </div>
                <div className="container-fluid custom-container py-3">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="container-fluid custom-container py-1">

                                        <h1 className="content-desktop" style={{ fontSize: "2vw" }}><center><strong>Kashmir package tour from Srinagar Airport</strong></center></h1>

                                        <h1 className="content-mobile" style={{ fontSize: "4vw" }}><center><strong>Kashmir package tour from Srinagar Airport</strong></center>
                                            <hr />
                                        </h1>
                                        <div className="row">
                                            <img src={packageTopBanner2ImageURL} className="mobile-image" alt="..." />
                                        </div>
                                        <hr />
                                        <div className="d-flex flex-column flex-sm-row align-items-sm-center px-2">
                                            <div className="font-lg">
                                                3 Destination:{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Srinagar (3N)
                                                </a>
                                                &nbsp;{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Gulmarg (1N)
                                                </a>{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Pahalgam (2N)
                                                </a>
                                            </div>
                                            <div className="reviews mx-5">
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                            </div>
                                        </div>
                                        <p className="p-2">
                                            If you are looking for a memorable <strong>Kashmir Tour from Srinagar Airport</strong>, you have come to the right place!
                                        </p>
                                        <hr />
                                        <br />
                                    </div>
                                    <h2 className="content-desktop p-2" style={{ fontSize: "1.6vw" }}> For Booking <strong>Kashmir Package Tour from Srinagar</strong> <a href='tel:9147061467' class="tomato">☎ CALL Mr. Soumya +91- 91470-61467</a></h2>
                                    <h2 className="content-mobile p-4" style={{ fontSize: "4.5vw" }}> For Booking <strong>Kashmir Package Tour from Srinagar</strong> <a href='tel:9147061467' class="tomato">☎ CALL Mr. Soumya +91- 91470-61467</a></h2>
                                    <br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> Experience the enchantment of Kashmir with our exclusive tour package from Srinagar</h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}> Experience the enchantment of Kashmir with our exclusive tour package from Srinagar</h4>
                                        </div>
                                        <div className="card-body">
                                            <p>Set off on a captivating journey with our <strong>Kashmir package tour from Srinagar</strong> and immerse yourself in one of the most picturesque destinations in the world. Often referred to as <b>"Jannat-e-Kashmir"</b> or <b>"Paradise on Earth"</b> Kashmir beckons with its stunning natural landscapes and rich cultural heritage. This tour offers a chance to explore the region's snow-covered mountains, lush alpine peaks, exquisite Mughal gardens, the charming Lidder River, vibrant tulip gardens, and bountiful apple orchards.</p>
                                            <p>Your adventure begins in Srinagar, the summer capital of Jammu and Kashmir, where you’ll be greeted with warm hospitality. The tour kicks off with a memorable stay on a traditional houseboat floating on the pristine Dal Lake. Imagine relaxing on the calm waters, sipping hot Kahwa, and enjoying the serene surroundings that make this experience truly unique.</p>
                                            <p>Explore the beauty of the Mughal Gardens, including Shalimar Bagh, Nishat Bagh, and Chashme Shahi. These historic gardens not only highlight Kashmir’s rich Mughal heritage but also offer lush greenery and stunning floral displays that transport you back in time.</p>
                                            <p>Next, embark on an adventurous visit to the Lidder River. Whether you seek the thrill of water activities or prefer a tranquil riverside escape, the Lidder River promises both. Enjoy the picturesque landscapes and soothing sounds of the flowing water as you capture the essence of Kashmir’s natural beauty.</p>
                                            <p>Marvel at the vibrant tulip gardens during the spring season and explore local apple orchards. Taste fresh apples and experience the agricultural richness of the region. Our Kashmir tour package ensures you fully experience the natural and cultural wonders of this enchanting land.</p>
                                            <p>Designed to be both affordable and enriching, our Kashmir tour package from Srinagar offers great value without compromising on quality. We ensure you get the most out of your journey while staying within budget.</p>
                                            <p>Don’t miss the chance to explore the magic of Kashmir. Book your <strong>Kashmir tour package from Srinagar</strong> today and discover why Mughal emperor Jahangir famously declared, "If there is a paradise on earth, it is this, it is this, it is this." Let Kashmir’s unparalleled beauty and cultural richness create lasting memories that you will cherish long after your journey concludes.</p>
                                            <br />
                                            <table className="image-table">
                                                <tbody>
                                                    <tr>
                                                        <td className="image-cell">
                                                            <img src={sightseen1ImageURL} alt="kashmir tour packages" />
                                                            <img src={sightseen2ImageURL} alt="kashmir trip from srinagar airport" />
                                                            <img src={sightseen3ImageURL} alt="kashmir package tour cost from srinagar airport" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <br />
                                        </div>
                                    </div>
                                    <br /> <br /> <br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>KASHMIR TOUR PACKAGE FROM SRINAGAR DURATION : 6N | 7D</strong></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>KASHMIR TOUR PACKAGE FROM SRINAGAR DURATION : 6N | 7D</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <p>Places to cover in <strong>Kashmir tour packages from Srinagar</strong> : Srinagar 3N | Gulmarg 1N | Pahalgam 2N</p>
                                            <p><b>Pickup & Drop point for Kashmir tour</b>  : Sheikh Ul Alam International Airport Srinagar [SXR]
                                            </p>

                                            <br /><br /><br />
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <tbody>
                                                        <tr>
                                                            <td><strong>Kolkata to Kashmir Tour Packages</strong> Pickup & Drop	</td>
                                                            <td>Srinagar Airport [SXR]</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Kolkata to Kashmir Package Duration</strong></td>
                                                            <td>06 Nights | 07 Days</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Kolkata to Kashmir Package Tour Cost </strong></td>
                                                            <td>Rs. 22,473/P</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Srinagar</strong></td>
                                                            <td>1,585 m | Coordinates - 34.0837° N, 74.7973° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Gulmarg</strong></td>
                                                            <td>2,650 m | Coordinates - 34.0484° N, 74.3805° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Sonmarg</strong></td>
                                                            <td>2,730 m | Coordinates - 34.3032° N, 75.2931° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Pahalgam</strong></td>
                                                            <td>2,740 m | Coordinates - 34.0161° N, 75.3150° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Doodhpathri</strong></td>
                                                            <td>2,730 m | Coordinates - 33.5423° N, 74.3615° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Betaab Valley</strong></td>
                                                            <td>2,393 m | Coordinates - 34.0541° N, 75.3639° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Aru Valley</strong></td>
                                                            <td>2,414 m | Coordinates - 34.0922° N, 75.2632° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Chandanwadi</strong></td>
                                                            <td>3,288 m | Coordinates - 34.2122° N, 73.9295° E</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <br /><br /><br />
                                            <table className="image-table">
                                                <tbody>
                                                    <tr>
                                                        <td className="image-cell">
                                                            <img src={sightseen4ImageURL} alt="kashmir package tour from srinagar" />
                                                            <img src={sightseen5ImageURL} alt="kashmir package price from srinagar airport" />
                                                            <img src={sightseen6ImageURL} alt="kashmir packages from srinagar airport" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <br />

                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>KASHMIR PACKAGE TOUR COST FROM SRINAGAR AIRPORT</strong></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>KASHMIR PACKAGE TOUR COST FROM SRINAGAR AIRPORT</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <thead className="cf">
                                                        <tr className="table-danger">
                                                            <th>TOUR DURATION</th>
                                                            <th>2 PAX</th>
                                                            <th>4 PAX</th>
                                                            <th>6 PAX</th>
                                                            <th>8 PAX</th>
                                                            <th>BOOK NOW</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td data-title="DURATION">4N/5D
                                                            </td>
                                                            <td data-title="2 PAX">₹ 20,500/-</td>
                                                            <td data-title="4 PAX">₹ 18,300/-</td>
                                                            <td data-title="6 PAX">₹ 16,600/-</td>
                                                            <td data-title="8 PAX">₹ 16,400/-</td>
                                                            <td data-title="Book Now"><a href="#" class="btn btn-success" onClick={this.handleClick}>
                                                                Book Now
                                                            </a></td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="DURATION">5N/6D</td>
                                                            <td data-title="2 PAX">₹ 24,700/-</td>
                                                            <td data-title="4 PAX">₹ 22,000/-</td>
                                                            <td data-title="6 PAX">₹ 19,800/-</td>
                                                            <td data-title="8 PAX">₹ 20,800/-</td>
                                                            <td data-title="Book Now">
                                                                <a href="#" class="btn btn-success" onClick={this.handleClick}>Book Now</a>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="DURATION">6N/7D</td>
                                                            <td data-title="2 PAX">₹ 30,900/-</td>
                                                            <td data-title="4 PAX">₹ 27,400/-</td>
                                                            <td data-title="6 PAX">₹ 24,700/-</td>
                                                            <td data-title="8 PAX">₹ 24,500/-</td>
                                                            <td data-title="Book Now">
                                                                <a href="#" class="btn btn-success" onClick={this.handleClick}>Book Now</a>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="DURATION">7N/8D</td>
                                                            <td data-title="2 PAX">₹ 35,600/-</td>
                                                            <td data-title="4 PAX">₹ 30,800/-</td>
                                                            <td data-title="6 PAX">₹ 28,300/-</td>
                                                            <td data-title="8 PAX">₹ 28,100/-</td>
                                                            <td data-title="Book Now">
                                                                <a href="#" class="btn btn-success" onClick={this.handleClick}>Book Now</a>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <br /><br />
                                    {/* start short itinerary */}

                                    <div className="card">
                                        <div className="card-body">
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr className="table-danger">
                                                        <th>6N/7D KASHMIR TOUR ITINERARY FROM SRINAGAR AIRPORT</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingOne"
                                                        >
                                                            <a href="#day1" style={{ color: 'black' }}><b>DAY 1 → </b>
                                                                <span>
                                                                    ARRIVAL SRINAGAR  - LOCAL SIGHTSEEING
                                                                </span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingTwo"
                                                        >
                                                            <a href="#day2" style={{ color: 'black' }}><b>DAY 2 → </b>
                                                                <span>SRINAGAR TO GULMARG FULL DAY EXCURSION & NIGHT STAY</span>
                                                            </a>

                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingThree"
                                                        >
                                                            <a href="#day3" style={{ color: 'black' }}><b>DAY 3 → </b>
                                                                <span>GULMARG TO PAHALGAM TOUR & NIGHT STAY IN HOTEL</span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingFour"
                                                        >
                                                            <a href="#day4" style={{ color: 'black' }}><b>DAY 4 → </b>
                                                                <span>PAHALGAM SIGHTSEEING & NIGHT STAY AT PAHALGAM</span>
                                                            </a>

                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingFive"
                                                        >
                                                            <a href="#day5" style={{ color: 'black' }}><b>DAY 5 → </b>
                                                                <span>PAHALGAM TO SRINAGAR SIGHTSEEING & NIGHT STAY IN SRINAGAR</span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingSix"
                                                        >
                                                            <a href="#day5" style={{ color: 'black' }}><b>DAY 6 → </b>
                                                                <span>SRINAGAR TO SONMARG DAY TRIP & NIGHT STAY AT HOTEL IN SRINAGAR</span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingSeven"
                                                        >
                                                            <a href="#day7" style={{ color: 'black' }}><b>DAY 7 → </b>
                                                                <span> DEPARTURE AT SRINAGAR AIRPORT </span>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    {/* <!--end Short description--> */}
                                    <br /><br /><br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>What are the top attractions on a <strong>Kashmir tour from Srinagar?</strong></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>What are the top attractions on a <strong>Kashmir tour from Srinagar?</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <p>When you embark on a <strong>Kashmir tour from Srinagar</strong> with Adorable Vacation, the leading <strong>Kashmir specialist in India</strong>, you’ll explore some of the most breath-taking attractions in the region. Your journey will include visits to top destinations like Srinagar, Gulmarg, Sonmarg, and Pahalgam, as well as iconic sites such as Mughal Gardens, Dal Lake, and Manasbal Lake. You’ll also experience the beauty of Shalimar Gardens, Nishat Gardens, Chashme Shahi, Badamwari Garden, and Pari Mahal.</p>
                                            <p>Additional highlights include spiritual sites like Shankaracharya Temple, Vaishno Devi Temple, Hazratbal Shrine, and Kheer Bhawani Temple. For natural wonders, explore Betaab Valley, Yusmarg, Aru, Gurez, Dudhpathri, Bangus Valley, Patnitop, Poonch, Bhimgarh Fort, and Ramnagar Fort.
                                            </p>
                                            <p>A special highlight of the <strong>Kashmir tour from Srinagar</strong>, especially if you visit during the spring, is the Tulip Festival. The Kashmir Tulip Festival, is going to held from March to April, 2025, showcases a stunning display of tulips in full bloom. The festival takes place at the Indira Gandhi Memorial Tulip Garden, also known as Siraj Bagh, in Srinagar. This is Asia's largest tulip garden, where visitors can enjoy a vibrant array of tulip varieties and the beautifully landscaped garden setting.</p>
                                            <br /><br />
                                            <h5>KASHMIR TULIP FESTIVAL 2025: DATE, TIMING, TICKETS FEE</h5>
                                            <br />
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <tbody>
                                                        <tr>
                                                            <td>Highlights</td>
                                                            <td>Tulip Festival Details</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Kashmir Tulip Festival Date</td>
                                                            <td>March - April, 2025</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Kashmir Tulip Festival Location</td>
                                                            <td>Indira Gandhi Memorial Tulip Garden, Srinagar, J&K</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Kashmir Tulip Festival Timings</td>
                                                            <td>10:00am to 7:30pm Every Days</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Kashmir Tulip Festival Tickets Fee</td>
                                                            <td>INR 50 per adult INR 25 Per Child</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                            <br /><br />

                                            <table className="image-table">
                                                <tbody>
                                                    <tr>
                                                        <td className="image-cell">
                                                            <img src={sightseen7ImageURL} alt="kashmir package tour booking from srinagar" />
                                                            <img src={sightseen8ImageURL} alt="kashmir package tour booking from srinagar airport" />
                                                            <img src={sightseen9ImageURL} alt="kashmir package tour cost from srinagar" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <br />
                                        </div>
                                    </div>
                                    <br /><br /><br />

                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>FAMOUS LAKES TO EXPLORE DURING <strong>KASHMIR TOUR PACKAGES FROM SRINAGAR</strong></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>FAMOUS LAKES TO EXPLORE DURING <strong>KASHMIR TOUR PACKAGES FROM SRINAGAR</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <p>If you're embarking on a <strong>Kashmir package tour from Srinagar</strong>, you absolutely must explore the region’s stunning lakes. Kashmir is renowned for its breath-taking lakes surrounded by snow-capped mountains, Chinar trees, pine forests, and vibrant gardens full of blooming tulips and roses. Here are some of the most famous lakes to include in your <strong>Kashmir tour packages from Srinagar</strong>.</p>
                                            <p><b>Dal Lake</b> is undoubtedly the most popular destination on a <strong>Kashmir tour from Srinagar</strong>. Spanning around 22 square kilometres, this picturesque lake is famous for its colourful Shikaras (traditional boats), houseboats, and floating markets. A Shikara ride on Dal Lake is an essential part of any <strong>Kashmir package from Srinagar</strong>. You must also visit Char Chinar, a beautiful island in the middle of the lake with four Chinar trees at its corners. Depending on the season, Char Chinar provides stunning views of the lake and its surroundings. Dal Lake has been featured in many Bollywood movies, making it a quintessential part of any <strong>Kashmir honeymoon tour</strong>. Floating on a Shikara, enjoying hot noon Chai (also known as Shir Chai or Gulabi Chai), and sampling authentic Kashmiri cuisine makes for a truly memorable experience. Staying in a houseboat on the tranquil waters of Dal Lake adds to its romantic appeal.
                                            </p>
                                            <p><b>Wular Lake</b> is another significant attraction in Kashmir. Covering about 190 square kilometres in the Bandipora district, Wular Lake is the largest in Jammu and Kashmir and the second largest in Asia. Originating from the Jhelum River, Wular Lake is vital for the local ecosystem and boasts a diverse range of aquatic plants and fish. It’s a key part of any Kashmir package tour from Srinagar for those interested in natural beauty and local wildlife.</p>
                                            <p><b>Nagin Lake</b>, often considered an extension of Dal Lake, offers a more serene and less crowded experience. Surrounded by majestic hills, this pristine lake is perfect for a romantic Shikara ride in a peaceful, pollution-free environment. It’s an excellent choice for honeymooners looking for a quieter escape.</p>
                                            <p><b>Manasbal Lake</b>, located 28 kilometers from Srinagar at an elevation of 1,583 meters, is smaller compared to Dal and Nagin Lakes but equally charming. Known for its beautiful blooms of lilies and lotuses, Manasbal Lake is an ideal spot for spending quality time with loved ones. The lake is surrounded by well-maintained gardens filled with seasonal flowers, including tulips and roses, making it a sought-after destination in Kashmir.</p>
                                            <p>Including these lakes in your Kashmir tour packages from Srinagar will ensure a memorable and picturesque experience, showcasing the natural beauty and romantic allure of the region.</p>
                                            <br />
                                            <br /> <br />
                                            <h5><b>ENTRY FEES FOR SIGHTSEEING DURING KASHMIR TOUR</b></h5>
                                            <br />
                                           
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <tbody>
                                                        <tr>
                                                            <td>Places</td>
                                                            <td>Entry Fees</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Dal Lake</td>
                                                            <td>NIL</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Nishant Bagh</td>
                                                            <td>Rs. 24 per adult, Rs. 12 per child</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Pari Mahal</td>
                                                            <td>Rs. 40/Person</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Shalimar Bagh</td>
                                                            <td>Rs. 24 per adult, Rs. 12 per child</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Shankaracharya Temple</td>
                                                            <td>NIL</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Chashme Shahi</td>
                                                            <td>Rs. 24 per adult, Rs. 12 per child</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Verinag Garden</td>
                                                            <td>Rs. 10/Person</td>
                                                        </tr>
                                                        <tr>
                                                            <td>SPS Museum</td>
                                                            <td>Rs. 10 for Indian, Rs. 50 for Foreigners</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Tulip Garden</td>
                                                            <td>Rs. 75 per adult, Rs. 30 per child</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Aru Valley</td>
                                                            <td>Rs. 25/Person</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <br /> <br />

                                            <table className="image-table">
                                                <tbody>
                                                    <tr>
                                                        <td className="image-cell">
                                                            <img src={sightseen10ImageURL} alt="kashmir family holiday package tour from srinagar airport" />
                                                            <img src={sightseen11ImageURL} alt="kashmir package from srinagar airport" />
                                                            <img src={sightseen12ImageURL} alt="kashmir package price from srinagar" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <br />
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div className="card-header custHeaer">
                                            <h4 className='content-desktop' style={{ fontSize: "1.5vw" }}>MUST DO ACTIVITIES DURING <strong>KASHMIR TOUR PACKAGE FROM SRINAGAR</strong></h4>
                                            <h4 className='content-mobile2' style={{ fontSize: "3.5vw" }}>MUST DO ACTIVITIES DURING <strong>KASHMIR TOUR PACKAGE FROM SRINAGAR</strong></h4>

                                        </div>
                                        <div className="card-body">
                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>Shikara Rides:</b><b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Shikara Rides:</b> Experience the tranquility of Dal Lake with a leisurely Shikara ride During your <strong>Srinagar to Kashmir Trip</strong>. Glide across the serene waters, passing by floating gardens, houseboats, and vibrant markets. Sunset and early morning rides offer particularly magical views.</p>
                                            <center> <img src={Topbanner0ImageURL} className="img-fluid" alt="..." /></center><br /><br /><br />

                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>Skiing and Snowboarding:</b>
                                                <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Skiing and Snowboarding:</b> During the winter months, places like Gulmarg transform into ski resorts, attracting enthusiasts from around the world. Gulmarg offers excellent slopes for skiing, snowboarding, and heli-skiing, with breathtaking views of the surrounding Himalayan peaks.</p>
                                            <center> <img src={Topbanner1ImageURL} className="img-fluid" alt="..." /></center><br /><br /><br />

                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>Gondola Ride: </b>
                                                <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Gondola Ride: </b>
                                                Take a ride on the Gulmarg Gondola, one of the highest cable cars in the world. Enjoy panoramic views of the snow-capped peaks as you ascend to Kongdori or Apharwat Peak, where you can indulge in activities like hiking and mountain biking.Experience this Awesome Ride During your <strong>Kashmir Tour Package from Srinagar Airport</strong> with Adorable Vacation and make this as a life long memories.</p>
                                            <center> <img src={Topbanner2ImageURL} className="img-fluid" alt="..." /></center><br /><br /><br />

                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>Trekking:</b>
                                                <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Trekking:</b> Kashmir is a paradise for trekkers, with numerous trails that cater to all levels of expertise. Popular trekking destinations include the Kashmir Great Lakes Trek, Tarsar Marsar Trek, and the trek to Thajiwas Glacier in Sonamarg.</p>
                                            <center> <img src={Topbanner3ImageURL} className="img-fluid" alt="..." /></center><br /><br /><br />
                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>River Rafting:</b>
                                                <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>River Rafting:</b> The Lidder River in Pahalgam offers exciting opportunities for white-water rafting. Experience the thrill of navigating through rapids while enjoying the scenic beauty of the surrounding pine forests and mountains.</p>
                                            <center> <img src={Topbanner4ImageURL} className="img-fluid" alt="..." /></center>
                                            <br /><br /><br />
                                            <a href="tel://9147061467" className="btn btn-Warning" style={{ padding: '15px 30px', fontSize: '20px' }}>CALL NOW</a>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="container-fluid navtabCustom">
                                        <nav>
                                            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                                <button onClick={() => this.handleCustomTabClick('tab1')} className={`tab button ${customActiveTab === 'tab1' ? 'active' : ''}`} role="tab" aria-controls="nav-highlights" aria-selected={customActiveTab === 'tab1'}>Highlights</button>
                                                <button onClick={() => this.handleCustomTabClick('tab2')} className={`tab button ${customActiveTab === 'tab2' ? 'active' : ''}`} role="tab" aria-controls="nav-flights" aria-selected={customActiveTab === 'tab2'}>Flights</button>
                                                <button onClick={() => this.handleCustomTabClick('tab3')} className={`tab button ${customActiveTab === 'tab3' ? 'active' : ''}`} role="tab" aria-controls="nav-hotel" aria-selected={customActiveTab === 'tab3'}>Hotel</button>
                                                <button onClick={() => this.handleCustomTabClick('tab4')} className={`tab button ${customActiveTab === 'tab4' ? 'active' : ''}`} role="tab" aria-controls="nav-sightseeing" aria-selected={customActiveTab === 'tab4'}>Sightseeing</button>
                                                <button onClick={() => this.handleCustomTabClick('tab5')} className={`tab button ${customActiveTab === 'tab5' ? 'active' : ''}`} role="tab" aria-controls="nav-meals" aria-selected={customActiveTab === 'tab5'}>Meals</button>
                                            </div>
                                        </nav>
                                        <div className="tab-content" id="nav-tabContent" >
                                            <div className={customActiveTab === 'tab1' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-highlights-tab">
                                                <p><strong>Kashmir Package Tour from Srinagar </strong>is renowned for its breathtaking landscapes, which include iconic features like the Dal Lake, Nigeen Lake, Gulmarg, Pahalgam, and Sonamarg. These areas attract tourists from around the world who come to admire the region's picturesque scenery and engage in various outdoor activities.</p>
                                                <p>Planning for a <b>Kashmir Holiday</b> remains a captivating destination for travellers seeking natural beauty, cultural experiences, and adventure in a unique and picturesque setting. </p>
                                                <p><b>Potential Geography -</b> Kashmir is nestled in the Himalayan Mountain range and is bordered by India, Pakistan, and China. It is divided into three regions: Jammu, the Kashmir Valley, and Ladakh. The region is characterized by its snow-capped peaks, lush valleys, meandering rivers, and serene lakes.</p>
                                                <p><b>Kashmir Tourism</b> is renowned for its breathtaking landscapes, which include iconic features like the <b>Dal Lake, Nigeen Lake, Gulmarg, Pahalgam, and Sonamarg.</b> These areas attract tourists from around the world who come to admire the region's picturesque scenery and engage in various outdoor activities. Kashmir has long been a popular tourist destination, attracting visitors with its natural beauty, adventure sports, religious sites, and historical landmarks. Tourists can enjoy activities such as trekking, skiing, houseboat stays, shikara rides, and visits to Mughal gardens and ancient temples.</p>
                                            </div>
                                            <div className={customActiveTab === 'tab2' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-flights-tab">
                                                <div className="overflow-table">
                                                    <span>Best Srinagar Non-Stop Flight connection from your city<b> <i>(SXR)</i></b></span><br /><br />
                                                    <table className="table table-bordered">
                                                        <tr className="table-danger">
                                                            <th>FLIGHT</th>
                                                            <th>AIRLINES</th>
                                                            <th>AIRPORT</th>
                                                            <th>TIME</th>
                                                            <th>DAY</th>
                                                        </tr>
                                                        <tr>
                                                            <td>SG-509</td>
                                                            <td>SpiceJet</td>
                                                            <td>Mumbai</td>
                                                            <td>14:45</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>QP-1637</td>
                                                            <td>Akasa Air</td>
                                                            <td>Mumbai</td>
                                                            <td>5:35</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>63-266</td>
                                                            <td>IndiGo</td>
                                                            <td>Mumbai</td>
                                                            <td>06:45</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>SG-8913</td>
                                                            <td>SpiceJet</td>
                                                            <td>Delhi</td>
                                                            <td>08:00</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>SG-8373</td>
                                                            <td>SpiceJet</td>
                                                            <td>Delhi</td>
                                                            <td>09:45</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>6E-6947</td>
                                                            <td>IndiGo</td>
                                                            <td>Bengaluru</td>
                                                            <td>13:45</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>6E6253</td>
                                                            <td>IndiGo</td>
                                                            <td>Hyderabad</td>
                                                            <td>14:50</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>6E6945</td>
                                                            <td>IndiGo</td>
                                                            <td>Ahmedabad</td>
                                                            <td>06:55</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>6E6961</td>
                                                            <td>IndiGo</td>
                                                            <td>Kolkata</td>
                                                            <td>14:45</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className={customActiveTab === 'tab3' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-hotel-tab">

                                                <div className="overflow-table">
                                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }} ><center><b>4 STAR - CERTIFIED BY 'ADORABLE' </b></center></h4>
                                                    <div id="no-more-tables">
                                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                            <thead class="cf">
                                                                <tr className="text-center">
                                                                    {/* <!-- Add the "text-center" className to center content --> */}
                                                                    <th colspan="6" style={{ fontSize: "1.3vw" }}><h6><b>4 STAR CATEGORY HOTEL CERTIFIED BY 'ADORABLE VACATION' </b></h6></th>
                                                                </tr>
                                                                <tr className="table-danger">
                                                                    <th className="text-center">Destination</th>
                                                                    <th className="text-center">Hotel Names</th>
                                                                    <th className="text-center">Hotel Type</th>
                                                                    <th className="text-center">No of Nights</th>
                                                                    <th className="text-center">Meal Plan</th>
                                                                </tr>
                                                            </thead>
                                                            <tr>
                                                                <td data-title="Destination"><div style={{ display: 'inline-block' }}>Srinagar</div></td>
                                                                <td data-title="Hotel Names"><div style={{ display: 'inline-block' }}>The Dewan by Royal Naqash / Palette Solar Residency</div></td>
                                                                <td data-title="Hotel Type">4 Star
                                                                </td>
                                                                <td data-title="No of Nights"><div style={{ display: 'inline-block' }}>3N</div></td>
                                                                <td data-title="Meal Plan"><div style={{ display: 'inline-block' }}>Room with Breakfast & Dinner</div></td>
                                                            </tr>
                                                            <tr>
                                                                <td data-title="Destination"><div style={{ display: 'inline-block' }}>Gulmarg</div></td>
                                                                <td data-title="Hotel Names"><div style={{ display: 'inline-block' }}>The RoseWood Hotel / Royal Castle
                                                                </div>
                                                                </td>
                                                                <td data-title="Hotel Type">4 Star
                                                                </td>
                                                                <td data-title="No of Nights"><div style={{ display: 'inline-block' }}>1N</div></td>
                                                                <td data-title="Meal Plan"><div style={{ display: 'inline-block' }}>Room with Breakfast & Dinner</div></td>
                                                            </tr>
                                                            <tr>
                                                                <td data-title="Destination"><div style={{ display: 'inline-block' }}>Pahalgam</div></td>
                                                                <td data-title="Hotel Names"><div style={{ display: 'inline-block' }}>Harmitage / The Chinar Resort and Spa
                                                                </div>
                                                                </td>
                                                                <td data-title="Hotel Type">4 Star
                                                                </td>
                                                                <td data-title="No of Nights"><div style={{ display: 'inline-block' }}>2N</div></td>
                                                                <td data-title="Meal Plan"><div style={{ display: 'inline-block' }}>Room with Breakfast & Dinner</div></td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                    <br />
                                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }} ><center> <strong>Kashmir Package Tour Price from Srinagar</strong> - with 4 <div style={{ display: 'inline-block', color: 'gold' }}>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                    </div> Accommodation </center>
                                                    </h4>
                                                    <div id="no-more-tables">
                                                        <table class="col-md-12 table-bordered table-striped table-condensed cf">
                                                            <thead class="cf">
                                                                <tr className="text-center">
                                                                    {/*  <!-- Add the "text-center" className to center content --> */}
                                                                    <th colspan="6">
                                                                        <h6 className='content-desktop' style={{ fontSize: "1.3vw" }}><center>Your <b>Jammu Kashmir Tour Package Price</b> - with 4 <div style={{ display: 'inline-block', color: 'gold' }}>
                                                                            <i className="fa fa-star"></i>
                                                                            <i className="fa fa-star"></i>
                                                                            <i className="fa fa-star"></i>
                                                                            <i className="fa fa-star"></i>
                                                                        </div> Accommodation </center></h6>
                                                                        {/* <h4 className="content-desktop" style={{ fontSize: "1.3vw" }} ><center>Your <b>Leh Ladakh Tour Package Price</b> - with 4 <div style={{ display: 'inline-block', color: 'gold' }}>
                                                                        <i className="fa fa-star"></i>
                                                                        <i className="fa fa-star"></i>
                                                                        <i className="fa fa-star"></i>
                                                                        <i className="fa fa-star"></i>
                                                                    </div> Accommodation </center>
                                                                    </h4> */}

                                                                    </th>
                                                                </tr>
                                                                <tr class="table text-center">
                                                                    <th class="text-center">No of Pax</th>
                                                                    <th class="text-center">Package Price (Including GST.)</th>
                                                                    <th class="text-center">Transport</th>
                                                                    <th class="text-center">WhatsApp Now</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td class="text-center" data-title="No of Pax">
                                                                        04 Adults
                                                                    </td>
                                                                    <td class="text-center" data-title="Price(Inc. GST.)">
                                                                        47,399 /- (Per Person)
                                                                    </td>
                                                                    <td class="text-center" data-title="Transport">Luxury SEDAN</td>
                                                                    <td class="text-center" data-title="WhatsApp Now"><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick}>
                                                                        <i className="fab fa-whatsapp"></i> WhatsApp
                                                                    </a></td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="text-center" data-title="No of Pax">
                                                                        06 Adults
                                                                    </td>
                                                                    <td class="text-center" data-title="Price(Inc. GST.)">
                                                                        46,299/- (Per Person)
                                                                    </td>
                                                                    <td class="text-center" data-title="Transport">Luxury SUV</td>
                                                                    <td class="text-center" data-title="WhatsApp Now"><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick}>
                                                                        <i className="fab fa-whatsapp"></i> WhatsApp
                                                                    </a></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <br />
                                            </div>
                                            <div className={customActiveTab === 'tab4' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-sightseeing-tab">
                                                <div className="container-fluid px-0 py-1">
                                                    <h3>Sightseeing for <strong>Kashmir Package Tour from Srinagar</strong></h3>
                                                    <p>Kashmir, often referred to as "Paradise on Earth," is renowned for its breathtaking natural beauty, serene landscapes, and rich cultural heritage. Here are some of the most popular sightseeing destinations in Kashmir:</p><br />
                                                    <p><b style={{ fontSize: "1.3vw" }} className='content-desktop'>Dal Lake:</b>
                                                        <b style={{ fontSize: "3.5vw" }} className='content-mobile2'>Dal Lake:</b> This iconic lake in Srinagar is famous for its picturesque surroundings, houseboats, and Shikara rides. Visitors can explore the floating gardens, old Mughal gardens along the shores, and enjoy the stunning views of the surrounding mountains.</p><br />
                                                    <p><b className='tomato content-desktop' style={{ fontSize: "1.3vw" }}>Gulmarg:</b>
                                                        <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Gulmarg:</b> Known for its lush green meadows, Gulmarg is a popular hill station and skiing destination. Visitors can take the Gulmarg Gondola, one of the highest cable cars in the world, to reach Kongdori and enjoy panoramic views of the Himalayas.</p><br />
                                                    <p><b className='content-desktop' style={{ fontSize: "1.3vw" }}>Sonamarg:</b>
                                                        <b className='content-mobile2' style={{ fontSize: "3.5vw" }}>Sonamarg:</b>  Translating to "Meadow of Gold," Sonamarg is a scenic valley situated at an altitude of 2800 meters. It offers breathtaking views of snow-capped peaks, glaciers, and meadows. It's also the base for various trekking routes.</p><br />
                                                    <p><b className='tomato' style={{ fontSize: "1.3vw" }}>Pahalgam:</b> This quaint town is renowned for its pristine beauty, lush greenery, and the Lidder River. Pahalgam serves as a starting point for the annual Amarnath Yatra pilgrimage and offers opportunities for trekking, horse riding, and fishing.</p><br />
                                                    <p><b style={{ fontSize: "1.3vw" }}>Srinagar:</b> The summer capital of Jammu and Kashmir, Srinagar is famous for its Mughal gardens, including Shalimar Bagh, Nishat Bagh, and Chashme Shahi. Visitors can also explore the old city, visit the Jama Masjid, and indulge in shopping for traditional Kashmiri handicrafts in the local markets.</p><br />
                                                    <p><b className='tomato' style={{ fontSize: "1.3vw" }}>Pari Mahal:</b> Also known as the "Palace of Fairies," Pari Mahal is a historic monument located above the Chashme Shahi Gardens in Srinagar. It offers panoramic views of the city and Dal Lake and is surrounded by beautiful terraced gardens.</p><br />
                                                    <p><b style={{ fontSize: "1.3vw" }}>Betaab Valley:</b> Named after the Bollywood movie "Betaab," this picturesque valley is located near Pahalgam and offers stunning views of snow-capped mountains, lush greenery, and the Lidder River. It's a popular picnic spot and filming location.</p><br />
                                                    <p><b className='tomato' style={{ fontSize: "1.3vw" }}>Aru Valley:</b> Situated near Pahalgam, Aru Valley is known for its scenic beauty, alpine meadows, and trekking trails. It's a great place for nature lovers and adventure enthusiasts.</p><br />
                                                    <p><b style={{ fontSize: "1.3vw" }}>Mughal Road:</b> This historic road connects the Kashmir Valley with the regions of Poonch and Rajouri. It passes through picturesque landscapes, including dense forests, meadows, and small villages, offering a scenic drive.</p><br />
                                                    <p><b className='tomato' style={{ fontSize: "1.3vw" }}>Dachigam National Park:</b> Located near Srinagar, this national park is home to endangered species like the Kashmir stag (Hangul) and the Himalayan black bear. Visitors can enjoy wildlife safari tours and birdwatching in the park.</p><br />
                                                </div>
                                            </div>
                                            <div className={customActiveTab === 'tab5' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-meals-tab">
                                                <div className="overflow-table">
                                                    <h5 class="tomato">6 times Meals and 6 times Breakfast has been Provided</h5>
                                                    <table className="table table-bordered">
                                                        <tr className="table-danger">
                                                            <th colspan="6" className="text-center">Category</th>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-center">JAIN</td>
                                                            <td class="text-center">PURE-VEG</td>
                                                            <td class="text-center">VEGAN</td>
                                                            <td class="text-center">PURE-NON-VEG</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br /> <br /> <br />
                            <div className="card">
                                <div className="card-header custHeaer">
                                    <h3 className='content-desktop' style={{ fontSize: "1.5vw" }}>HOW TO START YOUR  <strong> KASHMIR TOUR FROM SRINAGAR</strong></h3>
                                    <h3 className='content-mobile2' style={{ fontSize: "3.5vw" }}>HOW TO START YOUR  <strong> KASHMIR TOUR FROM SRINAGAR</strong></h3>

                                </div>

                                <div className="card-body" >
                                    <p>Reaching for <b>Kashmir Holiday Plan</b> largely depends on your starting point, whether it's within India or from an international location. Here are several common ways to reach Kashmir:</p>
                                    <center> <img src={TopbannerImageURL} className="img-fluid" alt="..." /></center><br />
                                    <ul>
                                        <li><b>Srinagar Airport (SXR):</b> This is the main airport serving for Kashmir Tourism. It has regular flights connecting it to major cities in India such as Delhi, Mumbai, Bangalore, Kolkata, and others. There are also occasional international flights, particularly from Middle Eastern countries.</li><br />
                                        <li><b>Jammu Tawi Railway Station:</b> Jammu is the nearest major railway station to Kashmir. From Jammu, you can take a taxi, bus, or hire a private vehicle to reach Kashmir. Jammu is well-connected by trains to major cities like Delhi, Mumbai, Kolkata, and others.</li><br />
                                        <li><b>National Highway 44 (NH 44):</b> This is the primary road route connecting Kashmir with the rest of India. The journey involves passing through the Jawahar Tunnel, which connects the Jammu region with the Kashmir Valley. You can drive or hire a taxi/private vehicle to reach Kashmir via this route.</li><br />
                                        <li><b>Travel Documents:</b> Ensure you have all necessary travel documents, including any required visas or permits, especially if you're traveling from an international location.</li>
                                    </ul><br /><br /><br />
                                    <a href="tel://9147061467" className="btn btn-Warning" style={{ padding: '15px 30px', fontSize: '20px' }}>CALL NOW</a>
                                </div>
                            </div>
                            <br /><br /><br />
                            {/* <!--By Itinerary--> */}
                            <div className="container-fluid navtabCustom">

                                <h5 className="tomato" > 6N/7D <strong>KASHMIR PACKAGE TOUR ITINERARY</strong> </h5>
                                <hr />

                                <nav>
                                    <div className="nav nav-tabs" id="nav-tab" role="tablist" >
                                        <button onClick={() => this.handleDayWiseTabClick('day1')} className={`tab button ${daywiseActiveTab === 'day1' ? 'active' : ''}`} role="tab" aria-controls="nav-day-one" aria-selected={daywiseActiveTab === 'day1'}>DAY-1</button>
                                        <button onClick={() => this.handleDayWiseTabClick('day2')} className={`tab button ${daywiseActiveTab === 'day2' ? 'active' : ''}`} role="tab" aria-controls="nav-day-two" aria-selected={daywiseActiveTab === 'day2'}>DAY-2</button>
                                        <button onClick={() => this.handleDayWiseTabClick('day3')} className={`tab button ${daywiseActiveTab === 'day3' ? 'active' : ''}`} role="tab" aria-controls="nav-day-three" aria-selected={daywiseActiveTab === 'day3'}>DAY-3</button>
                                        <button onClick={() => this.handleDayWiseTabClick('day4')} className={`tab button ${daywiseActiveTab === 'day4' ? 'active' : ''}`} role="tab" aria-controls="nav-day-four" aria-selected={daywiseActiveTab === 'day4'}>DAY-4</button>
                                        <button onClick={() => this.handleDayWiseTabClick('day5')} className={`tab button ${daywiseActiveTab === 'day5' ? 'active' : ''}`} role="tab" aria-controls="nav-day-five" aria-selected={daywiseActiveTab === 'day5'}>DAY-5</button>
                                        <button onClick={() => this.handleDayWiseTabClick('day6')} className={`tab button ${daywiseActiveTab === 'day6' ? 'active' : ''}`} role="tab" aria-controls="nav-day-six" aria-selected={daywiseActiveTab === 'day6'}>DAY-6</button>
                                        <button onClick={() => this.handleDayWiseTabClick('day7')} className={`tab button ${daywiseActiveTab === 'day7' ? 'active' : ''}`} role="tab" aria-controls="nav-day-seven" aria-selected={daywiseActiveTab === 'day7'}>DAY-7</button>
                                    </div>
                                </nav>
                                <div className="tab-content" id="nav-tabContent" >
                                    <div className={daywiseActiveTab === 'day1' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-one-tab">
                                        <h5 class="tomato"  id="day1"> <b>Day 1 – ARRIVAL SRINAGAR- LOCAL SIGHTSEEING</b></h5>
                                        <p><b>On your arrival</b> at the Srinagar International Airport for your <strong>Kashmir Package Tour</strong>, will be welcomed by our representative who will assist and escort you to your waiting vehicle. From the airport it is a 35 minutes drive through the heart of New Srinagar city. The drive takes you past the Abdullah Bridge which connects the banks of the Jhelum River, snaking its way towards the North-Western frontier of Kashmir. We arrive at the hotel and check in, after refresh (if time permits) you will be provided a shikara ride in world famous Dal Lake.Overnight stay in a hotel at srinagar.
                                        </p>
                                        <br /> <br />
                                        <center><img src={navDay1ImageURL} className="img-fluid" alt="..." /></center>
                                        <br /> <br />
                                    </div>
                                    <div className={daywiseActiveTab === 'day2' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-two-tab">

                                        <h5 class="tomato" id="day2"><b>Day 2 - SRINAGAR TO GULMARG</b></h5>
                                        <p>
                                            This is the 2<sup>nd</sup> day of your <strong>Srinagar to Kashmir Tour</strong>. We will begin our countryside day trip towards Gulmarg. Gulmarg (2440 Meters above Sea level). It is one of the most beautiful hill stations and also called “Meadows of Flower”. Gulmarg has one of the best ski slopes in the world and the highest golf course with 18 holes.
                                        </p>
                                        <p>In Gulmarg we will board the Gondola cable car system (on direct payment Basis), and ascend towards the Apharwat Mountain range. The 08 minutes of ropeway will take you past colorful shepherd huntsmen to the lower ridge of the Afarwat peak. From here you can click lots of photos of the nearby mountain ranges that stand tall like sentinels guarding the rich natural beauty of Kashmir valley.Dinner and Overnight at a hotel in Gulmarg</p>
                                        <br /> <br />
                                        <center><img src={navDay2ImageURL} className="img-fluid" alt="..." /></center>
                                        <br /> <br />
                                    </div>
                                    <div className={daywiseActiveTab === 'day3' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-three-tab">

                                        <h5 class="tomato"> <b>Day 3 – GULMARG TO-PAHALGAM</b></h5>
                                        <p>Say goooood morning Gulmarg sipping your hot tea and view the anazing beauty of snow carpeted valley. This is 3rd day of this exciting Kashmir trip which was started from Srinagar Airport. Today after breakfast,we will proceed for Pahalgam via Pampore. Here you can visit famous saffron fields, Awantipora and the village of Bijbehara which remains famous as the breadbasket of Kashmir. We switch from the national highway 1A at Khanabal and drive through the second largest city of Anantnag. Pahalgam is a picturesque valley around 97 kilometers South of Kashmir. Known as Pahalgam (which means The Shepherds Village), the valley is renowned the world over for its exotic forests, pretty water streams and imposing snow peaks.On the way of Pahalgam ,we will take Awantipora famous temple & Apple valley Garden,Later we will move to Pahalgam.Overnight stay in Pahalgam.
                                        </p>
                                        <br /> <br />
                                        <center>  <img src={navDay3ImageURL} className="img-fluid" alt="..." /></center>
                                        <br /> <br />
                                    </div>
                                    <div className={daywiseActiveTab === 'day4' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-four-tab">

                                        <h5 class="tomato"> <b>Day 4 – PAHALGAM SIGHTSEEING</b></h5>
                                        <p>
                                            Leave after breakfast & proceeding to Pahalgam sightseen, Pahalgam is a picturesque valley around 97 kilometers South of Kashmir. Known as Pahalgam (which means The Shepherds Village), the valley is renowned the world over for its exotic forests, pretty water streams and imposing snow peaks.Today you can visit (Auro valley,Betab valley & Chandanvadi by local union cab) & one can also visit (Bisaran valley ,Derban valley & Minisuzerland & by evening night) in hotel at pahalgam.
                                        </p>
                                        <br /> <br />
                                        <center><img src={navDay4ImageURL} className="img-fluid" alt="..." /></center>
                                        <br /> <br />
                                    </div>
                                    <div className={daywiseActiveTab === 'day5' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-five-tab">

                                        <h5 class="tomato"> <b>Day 5 – PAHALGAM TO SRINAGAR LOCAL SIGHTSEEING</b></h5>
                                        <p>
                                            <b>After Breakfast, </b>
                                            we will leave for full day srinagar via visit local sightseeing that includes a visit to the famous Mughal gardens , Cheshma Shahi(Royal Spring), Nishat(Garden of Pleasure), Shalimar (Abode of Love) Mughal laid Out in the 16th century & these fall along the bank of Dal-Lake in the heart of Srinagar City  & Shankaracharya Temple located on a hillock, thus providing panoramic view of entire City, along with famous Dal-Lake & River Jhelum, Enroute visiting handloom centre famous for  production of Pashmina/Shahtoos shawls & world over reckoned Carpets & Dinner.Overnight stay in a Hotel at srinagar.
                                        </p>
                                        <br /> <br />
                                        <center> <img src={navDay5ImageURL} className="img-fluid" alt="..." /></center>
                                        <br /> <br />
                                    </div>
                                    <div className={daywiseActiveTab === 'day6' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-six-tab">

                                        <h5 class="tomato"> <b>Day 6 – SRINAGAR TO SONMARG</b></h5>
                                        <p>
                                            After breakfast we will drive to the majestic mountain resort of Sonamarg. The route towards Sonamarg takes us on a picturesque countryside drive where we will come across rolling hills, green forests, clear water streams and blooming rice fields. We will zoom past the town of Kangan which handles all the local needs of this region. From Kangan, we will drive towards Mammar, famous for its trout farm before we head towards Sonamarg. We will once again alight from our vehicles at the tourist spot of Gagangir. The spot is built in the middle of the river Sindh and looks like an island with snow glaciers covering one or the other spot. In Sonamarg we will take a 3 hour walk or horseback trip/local Union cab to the Thajiwas glacier (on direct payment Basis).Overnight stay in Srinagar hotel.</p>
                                        <br /> <br />
                                        <center><img src={navDay6ImageURL} className="img-fluid" alt="..." /></center>
                                        <br /> <br />
                                    </div>
                                    <div className={daywiseActiveTab === 'day7' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-seven-tab">

                                        <h5 class="tomato"> <b>Day 7 – DEPARTURE </b></h5>
                                        <p>
                                            After morning breakfast you will be transferred to Srinagar Airport from Srinagar with sweet memories.</p>
                                        <br /> <br />
                                        <center><img src={navDay7ImageURL} className="img-fluid" alt="..." /></center>
                                        <br /> <br />
                                    </div>
                                </div>
                            </div>
                            {/* <!--By Itinerary--> */}

                            {/* <!--insert table inclusion exclusion--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        {
                                            <InclusionExclusion />
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* <!--end table inclusion exclusion--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center" >
                                            <h2>The Khyber Himalayan Resort & Spa <span>- Gulmarg</span></h2>
                                            <h4><i>Next Level Luxury Resort </i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row py-3">
                                    <img src={brandedTourackageImage_1_URL} alt="..." />
                                </div>

                                <div id="no-more-tables" >
                                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                        <thead>
                                            <tr className="table-danger">
                                                <th>Category</th>
                                                <th>Size(Room)</th>
                                                <th>Hotel Brand</th>
                                                <th>Price (Per Night)</th>
                                                <th>Book Now</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td data-title="Packages">Platinum Accommodation</td>
                                                <td data-title="Size(Room)">386+ SQ.FT</td>
                                                <td data-title="Hotel Brand">Khyber Hotels (Heritage)<div className="reviews">
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                </div></td>
                                                <td data-title="Price (Per Night)">Rs 53,119/- </td>
                                                <td data-title="Book Now"><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick}>
                                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                                </a></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                <div className="card mx-0 my-5">
                                    <div className="card-header custHeaer">
                                        <h3 className='content-desktop' style={{ fontSize: "1.5vw" }}><b>HEAVEN IS CLOSER THAN YOU CAN IMAGINE</b></h3>
                                        <h3 className='content-mobile2' style={{ fontSize: "3.5vw" }}><b>HEAVEN IS CLOSER THAN YOU CAN IMAGINE</b></h3>
                                    </div>
                                    <div className="card-body">
                                        <p ><b>8,825 feet</b> above sea level in the Pir Panjal range of the Himalayas lies a higher order of luxury. <b>The Khyber Himalayan Resort & Spa</b> is spread over seven unspoilt, fir-laden acres, with majestic views of the Affarwat Peaks. This 85-room resort is framed at every vantage point by nature at its most spectacular. <b>The world’s highest ski lift</b>, the Gulmarg Gondola – which takes you to Kongdoori Mountain, is only a short walk away. Meadows and forests that have enchanted travellers for centuries wait to be explored by trekking enthusiasts and undying romantics. Clad in locally sourced pine and slate, the resort is built in a style true to the architectural heritage of Kashmir. In every way the indoors mirror the beauty of the outdoors. Specialty restaurants, a cigar lounge are amongst the necessary luxuries in place. Add to these other thoughtful additions like our children’s club, a mini theatre and a dedicated Activities Concierge and you will find The Khyber is a resort at par with the best in the world.</p>

                                        <a href="tel://9883359713" className="btn btn-Warning">Book Now</a>

                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2 >The LaLiT Grand Place <span>- Srinagar</span></h2>
                                            <h4 ><i>Fill the Luxury</i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row py-3">
                                    <img src={brandedTourackageImage_2_URL} alt="..." />
                                </div>

                                <div id="no-more-tables" >
                                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                        <thead>
                                            <tr className="table-danger">
                                                <th>Category</th>
                                                <th>Size(Room)</th>
                                                <th>Hotel Brand</th>
                                                <th>Price (Per Night)</th>
                                                <th>Book Now</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td data-title="Packages">Premium Accommodation</td>
                                                <td data-title="Size(Room)">290+ SQ.FT</td>
                                                <td data-title="Hotel Brand">The LaLiT Hotels<div className="reviews">
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                </div></td>
                                                <td data-title="Price (Per Night)">Rs 28,332/- </td>
                                                <td data-title="Book Now"><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick}>
                                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                                </a></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                <div className="card mx-0 my-5">
                                    <div className="card-header custHeaer">
                                        <h3 className='content-desktop' style={{ fontSize: "1.5vw" }}><b>EMBARKED ON A SENSE OF PRIDE AT PREMIUMNESS</b></h3>
                                        <h3 className='content-mobile2' style={{ fontSize: "3.5vw" }}><b>EMBARKED ON A SENSE OF PRIDE AT PREMIUMNESS</b></h3>
                                    </div>
                                    <div className="card-body">
                                        <p ><b>The LaLit Grand Palace</b> was originally built in 1901 for the royal family. It now provides luxurious rooms with views of Dal Lake and the beautiful gardens. An indoor pool, tennis courts and pampering spa treatments are also available.The tastefully designed rooms are modern with a touch of traditional charm. All have a satellite TV, minibar and free tea/coffee making facilities. It is 18 km from Srinagar Airport and 4 km from Lal Chowk city centre. Jammu Railway Station is 300 km away. Guests can enjoy a relaxing massage at Rejuve Spa Health Club, practice yoga, or play golf. The hotel also has a gym and 24-hour business center. The Chinar Garden serves North Indian cuisine, while international buffet spreads are available at The Chinar – The All Day Coffee Shop. Other dining options include Dal Bar and 24-hour room service.</p>

                                        <a href="tel://9883359713" className="btn btn-Warning">Book Now</a>

                                    </div>
                                </div>
                            </div>

                            {/* <!--start of package details--> */}
                            <div className="container-fluid py-5">

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>Kashmir<span> Tour Packages From Srinagar</span></h2>
                                            <h4>Related Kashmir Tour plan</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                                        <div className="carousel-inner">
                                            <div className="carousel-item active">

                                                <Slider {...settings}>
                                                    {hotPackagesView}
                                                </Slider>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!--end of package details--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2 >Frequently Ask <span>Questions</span></h2>
                                            <h4 >Srinagar & Kashmir Trip Related Questions</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="row" >
                                    <div className="accordion" id="accordionPanelsStayOpenExample">
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingOne">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                                    What does the Kashmir tour package include ?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseOne" className="accordion-collapse collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">The package typically includes accommodation, transportation within Kashmir, meals as per the itinerary, and guided tours to popular tourist destinations.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingTwo">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                                    What are the best times to visit Kashmir ?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseTwo" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">The best time to visit Kashmir is during the summer months (April to June) when the weather is pleasant, and you can enjoy activities like trekking and sightseeing. Autumn (September to November) is also beautiful due to the vibrant foliage. However, avoid visiting during the winter months (December to February) if you're not accustomed to heavy snowfall and cold temperatures.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingThree">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                                    What should I pack for my Kashmir trip ?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseThree" className="accordion-collapse collapse show" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">Pack according to the season of your visit. In summers, light clothing is recommended along with sunscreen, sunglasses, and comfortable walking shoes. For autumn, carry layers as the temperature can vary. In winter, pack heavy woolens, jackets, gloves, and snow boots.</div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingFour">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                                    Are there any safety concerns in Kashmir ?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseFour" className="accordion-collapse collapse show" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">Kashmir is generally safe for tourists, but it's advisable to stay updated on the current situation through reliable sources. Follow the guidelines of your tour guide and avoid areas known for political tensions.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingFive">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                                                    What are the must-visit places in Kashmir ?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseFive" className="accordion-collapse collapse show" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">Some of the must-visit places include Srinagar (Dal Lake, Mughal Gardens), Gulmarg (for skiing and cable car rides), Pahalgam (Betaab Valley, Aru Valley), Sonamarg (Thajiwas Glacier), and the famous Shankaracharya Temple.</div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingSix">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseFive">
                                                    Can I try local cuisine during my Kashmir trip ?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseSix" className="accordion-collapse collapse show" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">Yes, definitely! Kashmiri cuisine is famous for its delicious flavors. Don't miss trying dishes like Rogan Josh, Kashmiri Pulao, Dum Aloo, and Kahwa (traditional Kashmiri tea).
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingSeven">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
                                                    Is it necessary to book a tour package, or can I explore Kashmir on my own ?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseSeven" className="accordion-collapse collapse show" aria-labelledby="flush-headingSeven" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body"> While it's possible to explore Kashmir independently, booking a tour package offers convenience, local expertise, and a structured itinerary that covers major attractions efficiently.</div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingEight">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
                                                    Are there any adventure activities available in Kashmir ?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseEight" className="accordion-collapse collapse show" aria-labelledby="flush-headingEight" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">Yes, Kashmir offers various adventure activities such as trekking in the Himalayas, skiing in Gulmarg, river rafting in Lidder River, and camping in scenic locations like Sonamarg and Pahalgam.</div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingNine">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseNine">
                                                    Can I buy souvenirs in Kashmir ?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseNine" className="accordion-collapse collapse show" aria-labelledby="flush-headingNine" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">Yes, Kashmir is known for its handicrafts like Pashmina shawls, carpets, wooden artifacts, and traditional Kashmiri jewelry. You can purchase these as souvenirs from local markets like Lal Chowk in Srinagar or at government-approved emporiums.</div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingTen">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
                                                    How do I book a Kashmir tour package ?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseTen" className="accordion-collapse collapse show" aria-labelledby="flush-headingTen" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">You can book a Kashmir tour package through travel agencies, online travel platforms, or directly from tour operators specializing in Kashmir tours. Compare prices, read reviews, and choose a package that suits your preferences and budget.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center" >
                                            <h3><b>Jammu Kashmir <span class="tomato">Holiday Reviews</span></b></h3>
                                            <h4><i>"Your Experience is our Marketing"</i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-cols-1">
                                    <div id="testimonials">
                                        <div className="testimonial-box-container" >

                                            {reviewersDataView}

                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="col-md-3">
                            <BookingBox />
                        </div>
                    </div >
                </div >
                <div class="btn-call-pulse">
                    <a style={{ color: 'white' }} href="tel:+91-91470-61467">
                        <i className="fa fa-phone"></i>
                        {/* <span><small>+91 9147061467</small></span> */}
                    </a>
                </div>
                <div className="btn-whatsapp-pulse">
                    <a style={{ color: 'white' }} href="#" onClick={this.handleClick}>
                        <i className="fab fa-whatsapp"></i>
                        {/* <span>
                                <small>+91 9883359713</small>
                            </span> */}
                    </a>
                </div>

            </>
        );
    }
}

export default Kashmir_6N_7D_Package;