import React, { Component } from 'react';
import BookingBox from '../BookingBox';
import Slider from "react-slick";
import ApiURL from "../../../api/ApiURL";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InclusionExclusion from '../Arunachal_Pradesh/InclusionExclusion_arunachalmumbai_14n_15d';

class ArunachalTourMumbai_14N_15D_Package extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customActiveTab: 'tab1',
            daywiseActiveTab: 'day1'
        }
    }

    handleCustomTabClick = (tab) => {
        this.setState({
            customActiveTab: tab,
        });
    };

    handleDayWiseTabClick = (tab) => {
        this.setState({
            daywiseActiveTab: tab,
        });
    };
    handleClick = () => {
        const currentUrl = window.location.href;
        const pageTitle = document.title;
        const message = `Hi, Please send about the Tour Package details "${pageTitle}"`;

        const whatsappLink = `https://api.whatsapp.com/send?phone=+919674738480&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
        window.open(whatsappLink, '_blank');
    };
    render() {
        const { customActiveTab, daywiseActiveTab } = this.state;
        const packageTopBannerImageURL = ApiURL.ArunachalTourMumbai_14N_15D_PackageImageURL + "/Arunachal-Pradesh-Packages-Tour-from-Mumbai.webp";
        const packageTopBanner2ImageURL = ApiURL.ArunachalTourMumbai_14N_15D_PackageImageURL + "/Arunachal-Pradesh-Tour-Package-from-Mumbai.webp";
        const sightseen1ImageURL = ApiURL.ArunachalTourMumbai_14N_15D_PackageImageURL + "/ADVENTUTRES-ARUNACHAL-PRADESH.webp";
        const sightseen2ImageURL = ApiURL.ArunachalTourMumbai_14N_15D_PackageImageURL + "/ARUNACHAL-TOUR-PACKAGE-FROM-MUMBAI.webp";
        const sightseen3ImageURL = ApiURL.ArunachalTourMumbai_14N_15D_PackageImageURL + "/CULTURES-ARUNACHAL-PACKAGE.webp";

        const navDay1ImageURL = ApiURL.ArunachalTourMumbai_14N_15D_PackageImageURL + "/Arunachal-Pradesh-package-tour-from-Mumbai.webp";
        const navDay2ImageURL = ApiURL.ArunachalTourMumbai_14N_15D_PackageImageURL + "/Arunachal-tour-from-Mumbai.webp";
        const navDay3ImageURL = ApiURL.ArunachalTourMumbai_14N_15D_PackageImageURL + "/MANDALA-TOP-DIRANG.webp";
        const navDay4ImageURL = ApiURL.ArunachalTourMumbai_14N_15D_PackageImageURL + "/Tribal-Culture-Tawang.webp";
        const navDay5ImageURL = ApiURL.ArunachalTourMumbai_14N_15D_PackageImageURL + "/BUMLA-PASS-Arunachal.webp";
        const navDay6ImageURL = ApiURL.ArunachalTourMumbai_14N_15D_PackageImageURL + "/Hidden-Gems-CHAGZAM-BRIDGE - TAWANG.webp";
        const navDay7ImageURL = ApiURL.ArunachalTourMumbai_14N_15D_PackageImageURL + "/BOMDILA.webp";
        // const navDay8ImageURL = ApiURL.ArunachalTourMumbai_14N_15D_PackageImageURL + "/day8.webp";
        const navDay9ImageURL = ApiURL.ArunachalTourMumbai_14N_15D_PackageImageURL + "/ITANAGAR-Arunachal-Package.webp";
        const navDay10ImageURL = ApiURL.ArunachalTourMumbai_14N_15D_PackageImageURL + "/ZIRO-MUSIC-FESTIVAL-Arunachal.webp";
        const navDay11ImageURL = ApiURL.ArunachalTourMumbai_14N_15D_PackageImageURL + "/ZIRO-VALLEY-Offbeat.webp";
        const navDay12ImageURL = ApiURL.ArunachalTourMumbai_14N_15D_PackageImageURL + "/DAPORIJO-BRIDGE-Arunachal.webp";
        const navDay13ImageURL = ApiURL.ArunachalTourMumbai_14N_15D_PackageImageURL + "/AALO VILLAGE - HANGING-BRIDGE.webp";
        const navDay14ImageURL = ApiURL.ArunachalTourMumbai_14N_15D_PackageImageURL + "/PASIGHAT-Arunachal-Tour-Package.webp";
        const navDay15ImageURL = ApiURL.ArunachalTourMumbai_14N_15D_PackageImageURL + "/Customized-Tours-NAMERI-TEZPUR.webp";

        const TrendingPackagesDataList = this.props.TrendingPackagesData;
        const hotPackagesView = TrendingPackagesDataList.length > 0 ?
            (
                TrendingPackagesDataList.map((dataItem, i) => {
                    const hotelImgURL = ApiURL.ArunachalTourMumbai_14N_15D_PackageImageURL + "/" + dataItem.ImageName;
                    return (<div className="col-md-12 p-0" key={i}>
                        <div className="card h-100">
                            <img src={hotelImgURL} className="card-img-top" alt={dataItem.AllternateText} />
                            <div className="card-body">
                                <h6 className="card-title">{dataItem.Title} <span> &#8377; {dataItem.PricePerNight}</span></h6>
                                <p className="card-text">
                                    <i className="fa fa-building tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-utensils tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-car-side tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-male tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-camera tomato"></i>
                                </p>
                                <p className="card-text"><span>{dataItem.Description}</span></p>
                                <p>
                                    <span><b className="tomato">{dataItem.NoOfTours} </b>Tours</span> | &nbsp;
                                    <span><b className="tomato">{dataItem.NoOfDeparture} </b>Departures</span> | &nbsp;
                                    <span><b className="sdf-blue"> {dataItem.Include}</b></span>
                                </p>
                            </div>
                            <div className="card-footer">
                                <small className="text-muted"><strong className="tomato">{dataItem.MutedText}</strong></small>
                                <button type="button" className="btn btn-Warning btn-sm float-end">Book Now</button>
                            </div>
                        </div>
                    </div>)
                })
            ) : (<div></div>)


        const settings = {
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const reviewersDataList = this.props.ReviewersData;
        const reviewersDataView = reviewersDataList.map((dataItem, i) => {
            const reviewerImageURL = ApiURL.ArunachalTourMumbai_14N_15D_PackageImageURL + "/" + dataItem.ImageName;
            return (
                <div className="testimonial-box" key={i}>
                    <div className="box-top">
                        <div className="profile">
                            <div className="profile-img">
                                <img src={reviewerImageURL} alt="..." />
                            </div>
                            <div className="name-user">
                                <strong>{dataItem.Name}</strong>
                                <span>{dataItem.Location}</span>
                            </div>
                        </div>
                        <div className="reviews">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </div>
                    </div>
                    <div className="client-comment">
                        <p>{dataItem.Comment}</p>
                    </div>
                </div>
            )
        })

        return (
            <>
                <div className="container-fluid">
                <div className="row">
                        <img src={packageTopBannerImageURL} className="mobile-image2" alt="..." />
                    </div>
                </div>
                <div className="container-fluid custom-container py-3">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="container-fluid custom-container py-1">


                                        <h1 className="content-desktop" style={{ fontSize: "2vw" }}><center>Looking for <strong>Arunachal Pradesh Tour Packages from Mumbai?</strong></center></h1>

                                        <h1 className="content-mobile" style={{ fontSize: "4vw" }}><center>Looking for <strong>Arunachal Pradesh Tour Packages from Mumbai?</strong></center>
                                            <hr />
                                        </h1>
                                        <div className="container-fluid">
                                        <div className="row">
                                                <img src={packageTopBanner2ImageURL} className="mobile-image" alt="..." />
                                            </div>
                                        </div>
                                        <hr />
                                    </div>
                                    <h2 className="content-desktop p-4" style={{ fontSize: "2.0vw" }}> For Booking <b>MUMBAI TO ARUNACHAL TOUR </b> <a href='tel:9674738480' class="tomato">☎ CALL Mr. Sonali Halder  +91- 96747-38480</a></h2>
                                    <h2 className="content-mobile p-4" style={{ fontSize: "4.5vw" }}> For Booking <b>MUMBAI TO ARUNACHAL TOUR </b> <a href='tel:9674738480' class="tomato">☎ CALL Mr. Sonali Halder  +91- 96747-38480</a></h2>
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> India's Largest <b>North East Tour Operator</b></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}> India's Largest <b>North East Tour Operator</b></h4>
                                        </div>
                                        <div className="card-body">
                                            <p>Embark on an unforgettable journey with an <strong>Arunachal Pradesh package tour from Mumbai</strong>! Nestled in the northeastern part of India, Arunachal Pradesh is a hidden gem waiting to be explored. Here's everything you need to know about why Arunachal is the perfect destination for your next adventures</p>
                                            <p>This <strong>Arunachal Pradesh Package Tour from Mumbai</strong>, covering Dirang, Tawang, Bomdila, Bhalukpong, Tezpur, Nameri, Itanagar, Ziro, Daporijo, Aalo, and Pasighat for 14N 15D – is your gateway to an unforgettable and thrilling vacation. Nestled in the northeastern region of India, Arunachal Pradesh beckons with its diverse landscapes, ranging from snow-capped mountains to lush forests and serene lakes, making it an idyllic haven for both nature enthusiasts and avid adventurers.</p>
                                            <p>Embrace the convenience of a meticulously crafted <strong>Arunachal package tour from Mumbai</strong>, designed to whisk you away to prime attractions while ensuring a seamless and enjoyable journey. With accommodation, transportation, meals, and expert guidance included, these tours offer a holistic experience that leaves no detail unattended.</p>
                                            <p>Your journey for <strong>Arunachal Pradesh tour package from Mumbai</strong> begins in Itanagar, the state capital known for its scenic splendor and iconic landmarks like the Ita Fort and Jawaharlal Nehru State Museum. From there, your exploration continues to coveted destinations such as Tawang, Bomdila, and Ziro.
                                            </p>
                                            <br />
                                            <div className="content-desktop">
                                                <center><img src={sightseen1ImageURL} alt="..." /></center>
                                            </div>

                                            <div className="content-mobile2">
                                                <center><img src={sightseen1ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                            </div>
                                            <br />
                                            <p>Tawang, a picturesque town perched at 10,000 feet above sea level, enchants with its natural grandeur and boasts the title of housing India's largest Buddhist monastery – the Tawang Monastery. Bomdila offers a different charm, with its apple orchards, traditional crafts, and awe-inspiring views of the Himalayas. Ziro, a UNESCO World Heritage Site, captivates with its rich cultural heritage and breathtaking landscapes.</p>
                                            <p>Venturing beyond the well-trodden paths, your Arunachal Pradesh package from Mumbai may unveil hidden treasures like Namdapha National Park, the captivating Mechuka Valley, or the enigmatic Pangsau Pass. These offbeat gems cater to the intrepid traveler’s quest for authentic and unspoiled beauty.</p>
                                            <p>In essence, embarking on an <strong>Arunachal tour from Mumbai</strong> unveils a splendid opportunity to unravel the wonders of this enchanting state. Arunachal Pradesh undoubtedly earns its spot on every traveler’s bucket list. So, prepare to pack your bags and embark on an expedition that promises lifelong memories!</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> DURATION OF ENCHANTING MUMBAI TO <strong>ARUNACHAL PRADESH TOUR PACKAGES: 14N | 15D</strong></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}> DURATION OF ENCHANTING MUMBAI TO <strong>ARUNACHAL PRADESH TOUR PACKAGES: 14N | 15D</strong></h4>
                                </div>
                                <div className="card-body">
                                    <p>Places to visit in <strong>Arunachal Tour Starting from Mumbai</strong>:<br /> Bhalukpong 1N | Dirang 2N | Tawang 3N | Bomdila 1N | Tezpur or Nameri 1N | Itanagar 1N | Ziro 2N | Daporijo or North Lakhimpur 1N | Aalo 1N | Pasighat 1N<br /></p>
                                    <p><strong>Pickup</strong>: Lokpriya Gopinath Bordoloi International Airport [GAU] / Guwahati Railway Station [GHY]</p>
                                    <p><strong>Drop:</strong> Dibrugarh Airport [DIB] / Dibrugarh Railway Station [DBRG]</p><br />
                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf">
                                                <tr className="table-danger">
                                                    <th>ALTITUDE</th>
                                                    <th>HEIGHT</th>
                                                    <th>COORDINATES</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td data-title="ALTITUDE">Bhalukpong</td>
                                                    <td data-title="HEIGHT">213 m</td>
                                                    <td data-title="COORDINATES">27.0137° N, 92.6345° E</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="ALTITUDE">Dirang</td>
                                                    <td data-title="HEIGHT">1,560 m</td>
                                                    <td data-title="COORDINATES">27.3584° N, 92.2409° E</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="ALTITUDE">Tawang</td>
                                                    <td data-title="HEIGHT">3,048 m</td>
                                                    <td data-title="COORDINATES">27.5861° N, 91.8594° E</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="ALTITUDE">Bomdila</td>
                                                    <td data-title="HEIGHT">2,415 m</td>
                                                    <td data-title="COORDINATES">27.2645° N, 92.4159° E</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="ALTITUDE">Tezpur</td>
                                                    <td data-title="HEIGHT">48 m</td>
                                                    <td data-title="COORDINATES">26.6528° N, 92.7926° E</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="ALTITUDE">Itanagar</td>
                                                    <td data-title="HEIGHT">310 m</td>
                                                    <td data-title="COORDINATES">27.0843° N, 93.6053° E</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="ALTITUDE">Ziro</td>
                                                    <td data-title="HEIGHT">80/225 m</td>
                                                    <td data-title="COORDINATES">26.9324° N, 92.8772° E</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="ALTITUDE">Daporijo/North Lakhimpur</td>
                                                    <td data-title="HEIGHT">600 m/101 m</td>
                                                    <td data-title="COORDINATES">28.2358° N, 93.8919° E/27.2363° N, 94.0958° E</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="ALTITUDE">Aalo/Aalong</td>
                                                    <td data-title="HEIGHT">619 m</td>
                                                    <td data-title="COORDINATES">28.1628° N, 94.8054° E</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="ALTITUDE">Pasighat</td>
                                                    <td data-title="HEIGHT">152 m</td>
                                                    <td data-title="COORDINATES">28.0632° N, 95.3239° E</td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr className="table-danger">
                                                    <th>
                                                        SUMMERY OF ARUNACHAL PRADESH TOUR PLAN FROM MUMBAI
                                                    </th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr>
                                                    <td
                                                        className="clickable"
                                                    >
                                                        <a href="#day1" style={{ color: 'black' }}><b>DAY 1 → </b>
                                                            <span>
                                                                GUWAHATI - BHALUKPONG [260 KM / 5 Hrs]
                                                            </span>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="clickable">
                                                        <a href="#day2" style={{ color: 'black' }}><b>DAY 2 → </b>
                                                            <span>BHALUKPONG - DIRANG [140 KM / 4 HRS]</span>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="clickable">
                                                        <a href="#day3" style={{ color: 'black' }}><b>DAY 3 → </b>
                                                            <span>DIRANG – DAY EXCURSION TO MANDALA TOP AND THEMBHANG VILLAGE (UNESCO)</span>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="clickable">
                                                        <a href="#day4" style={{ color: 'black' }}><b>DAY 4 → </b>
                                                            <span>DIRANG - TAWANG [135 KM / 4.5 HRS]</span>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="clickable">
                                                        <a href="#day5" style={{ color: 'black' }}><b>DAY 5 → </b>
                                                            <span>TAWANG – BUMLA PASS - TAWANG (BUMLA COST EXCLUDED)</span>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="clickable">
                                                        <a href="#day6" style={{ color: 'black' }}><b>DAY 6 → </b>
                                                            <span>TAWANG LOCAL SIGHTSEEING</span>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="clickable">
                                                        <a href="#day7" style={{ color: 'black' }}><b>DAY 7 → </b>
                                                            <span>TAWANG TO BOMDILA [175 KM / 6 HRS APPROX]</span>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="clickable">
                                                        <a href="#day8" style={{ color: 'black' }}><b>DAY 8 → </b>
                                                            <span>BOMDILA TO NAMERI NATIONAL PARK / TEZPUR [122 KM / 5HRS APPROX]</span>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="clickable">
                                                        <a href="#day9" style={{ color: 'black' }}><b>DAY 9 → </b>
                                                            <span>NAMERI NATIONAL PARK / TEZPUR – ITANAGAR [153 KM / 4.5 HRS]</span>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="clickable">
                                                        <a href="#day10" style={{ color: 'black' }}><b>DAY 10 → </b>
                                                            <span>ITANAGAR – ZIRO [109 KM / 5.5 HRS]</span>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="clickable">
                                                        <a href="#day11" style={{ color: 'black' }}><b>DAY 11 → </b>
                                                            <span>ZIRO VILLAGE TOUR</span>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="clickable">
                                                        <a href="#day12" style={{ color: 'black' }}><b>DAY 12 → </b>
                                                            <span>ZIRO TO DAPORIJO [167 KM / 7HRS]</span>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="clickable">
                                                        <a href="#day13" style={{ color: 'black' }}><b>DAY 13 → </b>
                                                            <span>DAPORIJO TO AALO [160KM / 8 HRS APPROX]</span>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="clickable">
                                                        <a href="#day14" style={{ color: 'black' }}><b>DAY 14 → </b>
                                                            <span>AALO TO PASIGHAT [101 KM / 3 HRS APPROX]</span>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="clickable">
                                                        <a href="#day15" style={{ color: 'black' }}><b>DAY 15 → </b>
                                                            <span>PASIGHAT TO DIBRUGARH DROP [150KM / 3.5 HRS APPROX]</span>
                                                        </a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> WHY <strong>ARUNACHAL PRADESH</strong> IS BEST FOR VACATION?</h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}> WHY <strong>ARUNACHAL PRADESH</strong> IS BEST FOR VACATION?</h4>
                                </div>
                                <div className="card-body">
                                    <p><strong>Untouched Natural Beauty:</strong> Discover the untouched natural beauty of <strong>Arunachal Pradesh on an Arunachal package tour from Mumbai</strong>. Arunachal Pradesh boasts breathtaking landscapes, including snow-capped mountains, lush valleys, and pristine rivers. From the majestic Himalayas to dense forests teeming with wildlife, the state offers a paradise for nature lovers and adventure enthusiasts a like.</p>
                                    <p><strong>Rich Cultural Heritage:</strong> During your Arunachal Pradesh tour package from Mumbai, you will experience that Arunachal is home to numerous indigenous tribes like Adi, Nissi, Sherdukpen, Aka, Monpa, Apa Tani, Hill Miri, etc., each with its unique traditions, customs, and way of life. Exploring the rich cultural diversity of the state provides a fascinating insight into the vibrant tapestry of Indian heritage.</p>
                                    <p><strong>Thriving Festivals:</strong> The state celebrates a myriad of festivals throughout the year, offering visitors a glimpse into its vibrant cultural heritage. From the colorful Losar festival of the Monpas to the vibrant Nyokum Yullo festival of the Nyishi tribe, there’s always something to celebrate in Arunachal Pradesh.</p>
                                    <p><strong>Scenic Places:</strong> Arunachal Pradesh is dotted with picturesque destinations that are sure to leave you spellbound. Explore the serene beauty of Tawang, marvel at the crystal-clear waters of Sela Lake, or trek through the dense forests of Namdapha National Park - the possibilities are endless!</p>
                                    <p><strong>Family Traditions:</strong> Arunachal Pradesh is known for its strong sense of community and family values. Experience the warmth and hospitality of the local people as you immerse yourself in their age-old traditions and customs.</p>
                                    <br />
                                            <div className="content-desktop">
                                                <center><img src={sightseen2ImageURL} alt="..." /></center>
                                            </div>

                                            <div className="content-mobile2">
                                                <center><img src={sightseen2ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                            </div>
                                            <br />
                                    <p><strong>Exciting Activities:</strong> Whether you're a thrill-seeker or a nature lover, Arunachal Pradesh offers a wide range of activities to suit every taste. From trekking and rafting to bird watching and wildlife safaris, there's no shortage of adventure here.</p>
                                    <p><strong>Delicious Cuisine:</strong> Indulge your taste buds in the flavors of Arunachal Pradesh with its delectable cuisine. From hearty rice-based dishes like thukpa and momos to spicy meat curries like lukter made out of Beef, chicken or yak momo, bamboo shoot dishes, dung po, Chura sabji, pika pila, and indigenous beverages like "Naara-Aaba" made from Kiwi and Apo, Poka, etc., the state's culinary delights are sure to leave you craving for more.</p>
                                    <p>Book your <strong>Arunachal tour package from Mumbai</strong> today to experience the wonders of this enchanting state firsthand! Explore the diverse landscapes, vibrant culture, and thrilling adventures that await you in Arunachal Pradesh.</p>

                                </div>
                            </div>
                            <br /><br />
                            {/* flight */}
                            <div className="container-fluid navtabCustom">
                                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                    <button onClick={() => this.handleCustomTabClick('tab1')} className={`tab button ${customActiveTab === 'tab1' ? 'active' : ''}`} role="tab" aria-controls="nav-price" aria-selected={customActiveTab === 'tab1'}>Price</button>
                                    <button onClick={() => this.handleCustomTabClick('tab3')} className={`tab button ${customActiveTab === 'tab3' ? 'active' : ''}`} role="tab" aria-controls="nav-travellers-info" aria-selected={customActiveTab === 'tab3'}>Travellers Info</button>
                                    <button onClick={() => this.handleCustomTabClick('tab4')} className={`tab button ${customActiveTab === 'tab4' ? 'active' : ''}`} role="tab" aria-controls="nav-flight" aria-selected={customActiveTab === 'tab4'}>Flight</button>
                                </div>
                                <div className="tab-content" id="nav-tabContent">
                                    <div className={customActiveTab === 'tab1' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-price-tab">
                                        <div id="no-more-tables">
                                            <table class="col-md-12 table-bordered table-striped table-condensed cf">
                                                <thead class="cf">
                                                    <tr className="table-primary text-center">
                                                        <th colSpan="5"><h4 style={{ fontSize: "1.5vw" }} className="content-desktop"><strong>ARUNACHAL PRADESH TOUR PACKAGE COST</strong></h4></th>
                                                    </tr>
                                                    <h4 style={{ fontSize: "3.5vw" }} className="content-mobile2 tomato"><strong>ARUNACHAL PRADESH TOUR PACKAGE COST</strong></h4>
                                                    <tr className="table-danger">
                                                        <th>Package</th>
                                                        <th>02 Pax (INR)</th>
                                                        <th>04 Pax (INR)</th>
                                                        <th>06 Pax (INR)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td data-title="Category">14N/15D</td>
                                                        <td data-title="02 Pax (INR)">75,999 PP</td>
                                                        <td data-title="04 Pax (INR)">63,999 PP</td>
                                                        <td data-title="06 Pax (INR)">54,999 PP</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div className={customActiveTab === 'tab3' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-travellers-info-tab">
                                        <h5><b>For your Arunachal tour package starting from Guwahati, here's a list of essential items to carry:</b></h5>
                                        <p><strong>Documents:</strong> Inner Line Permit (ILP) obtained with original Voter Card, Aadhar card, or Driving License. Carry passport-sized photos and printouts of your tour details for convenience.</p>
                                        <p><strong>Sunscreen Lotion & Moisturizers:</strong> Protect your skin from sun rays during the day and cold at night. Carry adequate sunscreen lotion and moisturizers.</p>
                                        <p><strong>Camera with Extra Batteries:</strong> Ensure you have spare batteries for your camera. It's challenging to find shops in remote Arunachal locations for battery replacements.</p>
                                        <p><strong>Clothing:</strong> Pack heavy woolen clothes, a heavy jacket, and light clothing. Arunachal's weather is unpredictable, ranging from rain and wind to chilly breezes.</p>
                                        <p><strong>Shoes:</strong> Waterproof shoes and socks are essential due to the varied weather conditions. Pack slippers for light walking or relaxing.</p>
                                        <p><strong>Medicines:</strong> Include essential medicines such as paracetamol, pain relief balm or spray, headache tablets, motion sickness remedies, anti-allergic medicine, cough syrup, and medications for stomach issues like vomiting, loose motion, acidity, and gas.</p>
                                        <p><strong>Additional Essentials:</strong> Camphor, inhaler (if needed), sunglasses, tripod for your camera, rain gear (raincoat and umbrella), Arunachal tourism map, spare pairs of socks, underwear, and slippers.</p>
                                        <p>By packing these essentials, you'll be well-prepared to enjoy your trip to Arunachal Pradesh comfortably and safely.</p>

                                        <br /><br />
                                    </div >
                                    <div className={customActiveTab === 'tab4' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-flight-tab">
                                        <span><b className="tomato">Flights from Mumbai [BOM] to Guwahati [GAU]</b></span>
                                        <br /><br />
                                        <div id="no-more-tables">
                                            <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                <thead className="cf">
                                                    <tr className="table-danger">
                                                        <th>AIRLINES</th>
                                                        <th>ORIGIN</th>
                                                        <th>DEPARTURE</th>
                                                        <th>DESTINATION</th>
                                                        <th>ARRIVAL</th>
                                                        <th>DURATION</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td data-title="AIRLINES">AirAsia</td>
                                                        <td data-title="ORIGIN">Mumbai [BOM]</td>
                                                        <td data-title="DEPARTURE">08:45</td>
                                                        <td data-title="DESTINATION">Guwahati [GAU]</td>
                                                        <td data-title="ARRIVAL">11:20</td>
                                                        <td data-title="DURATION">02 h 55 m (non-stop)</td>
                                                    </tr>
                                                    <tr>
                                                        <td data-title="AIRLINES">IndiGo</td>
                                                        <td data-title="ORIGIN">Mumbai [BOM]</td>
                                                        <td data-title="DEPARTURE">06:10</td>
                                                        <td data-title="DESTINATION">Guwahati [GAU]</td>
                                                        <td data-title="ARRIVAL">11:25</td>
                                                        <td data-title="DURATION">05 h 15 m (1 stop CCU)</td>
                                                    </tr>
                                                    <tr>
                                                        <td data-title="AIRLINES">IndiGo</td>
                                                        <td data-title="ORIGIN">Mumbai [BOM]</td>
                                                        <td data-title="DEPARTURE">06:35</td>
                                                        <td data-title="DESTINATION">Guwahati [GAU]</td>
                                                        <td data-title="ARRIVAL">11:55</td>
                                                        <td data-title="DURATION">05 h 20 m (1 stop CCU)</td>
                                                    </tr>
                                                    <tr>
                                                        <td data-title="AIRLINES">IndiGo</td>
                                                        <td data-title="ORIGIN">Mumbai [BOM]</td>
                                                        <td data-title="DEPARTURE">09:20</td>
                                                        <td data-title="DESTINATION">Guwahati [GAU]</td>
                                                        <td data-title="ARRIVAL">05:40</td>
                                                        <td data-title="DURATION">08 h 50 m (non-stop)</td>
                                                    </tr>
                                                    <tr>
                                                        <td data-title="AIRLINES">SpiceJet</td>
                                                        <td data-title="ORIGIN">Mumbai [BOM]</td>
                                                        <td data-title="DEPARTURE">07:00</td>
                                                        <td data-title="DESTINATION">Guwahati [GAU]</td>
                                                        <td data-title="ARRIVAL">12:25</td>
                                                        <td data-title="DURATION">05 h 25 m (1 stop DEL)</td>
                                                    </tr>
                                                    <tr>
                                                        <td data-title="AIRLINES">SpiceJet</td>
                                                        <td data-title="ORIGIN">Mumbai [BOM]</td>
                                                        <td data-title="DEPARTURE">06:40</td>
                                                        <td data-title="DESTINATION">Guwahati [GAU]</td>
                                                        <td data-title="ARRIVAL">09:30</td>
                                                        <td data-title="DURATION">02 h 50 m (1 stop)</td>
                                                    </tr>
                                                    <tr>
                                                        <td data-title="AIRLINES">Air India</td>
                                                        <td data-title="ORIGIN">Mumbai [BOM]</td>
                                                        <td data-title="DEPARTURE">06:00</td>
                                                        <td data-title="DESTINATION">Guwahati [GAU]</td>
                                                        <td data-title="ARRIVAL">11:25</td>
                                                        <td data-title="DURATION">05 h 25 m (1 stop CCU)</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <br />

                                    </div>

                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> WHICH IS THE BEST TIME TO BOOK <strong>ARUNACHAL PRADESH TOUR FROM GUWAHATI?</strong> </h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>WHICH IS THE BEST TIME TO BOOK <strong>ARUNACHAL PRADESH TOUR FROM GUWAHATI? </strong></h4>
                                </div>
                                <div className="card-body">
                                    <p>Booking <strong>Arunachal Pradesh tour package from Mumbai</strong> largely depends on the season. These northeastern states boast breathtaking landscapes and unique cultural experiences, making them popular tourist destinations. Here's a guide to help you choose the <strong>best time to book your Arunachal package tour from Mumbai:</strong></p>
                                    <p><strong>Spring [March to May]:</strong> Spring is the perfect time to visit Arunachal Pradesh from Mumbai, with pleasant weather and blooming flora adding to the charm of the landscape. Explore the colorful festivals of the tribes, such as the Sangken festival of the Khamptis, and indulge in outdoor activities like trekking and camping.</p>
                                    <p><strong>Summer [June to August]:</strong> While summer in Arunachal Pradesh can be warm, this season offers excellent opportunities for adventure activities like river rafting and paragliding. Beat the heat by exploring the cooler regions of the state, such as the high-altitude valleys of Tawang and Ziro.</p>
                                    <p><strong>Monsoon [September to November]:</strong> The monsoon season brings lush greenery to Arunachal Pradesh, making it an ideal time for nature lovers. Witness cascading waterfalls, blooming flowers, and vibrant landscapes as you explore the hidden treasures of the state.</p>
                                    <br />
                                            <div className="content-desktop">
                                                <center><img src={sightseen3ImageURL} alt="..." /></center>
                                            </div>

                                            <div className="content-mobile2">
                                                <center><img src={sightseen3ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                            </div>
                                            <br />
                                    <p><strong>Winter [December to February]:</strong> Winter is the best time to visit Arunachal Pradesh, with clear skies and comfortable weather conditions. Experience the magic of snowfall in destinations like Tawang and Dirang, and immerse yourself in the festive spirit of the local tribes during this season.</p>
                                    <br /><br />
                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf">
                                                <tr className="table-danger">
                                                    <th>TRAVEL MONTHS</th>
                                                    <th>WEATHER</th>
                                                    <th>TEMPERATURE</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td data-title="MONTHS">March to June</td>
                                                    <td data-title="WEATHER">Summer</td>
                                                    <td data-title="TEMPERATURE">10°C to 30°C</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="MONTHS">July to October</td>
                                                    <td data-title="WEATHER">Monsoon</td>
                                                    <td data-title="TEMPERATURE">18°C to 32°C</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="MONTHS">November to February</td>
                                                    <td data-title="WEATHER">Winter</td>
                                                    <td data-title="TEMPERATURE">6° C to 22° C</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br /><br />
                                    <p>In conclusion, an <strong>Arunachal Pradesh package tour from Mumbai</strong> promises an unforgettable journey filled with natural beauty, cultural richness, and exciting adventures. Whether you're seeking a thrilling outdoor experience or a peaceful retreat amidst nature, Arunachal Pradesh has something for everyone. So pack your bags and get ready to explore the wonders of this enchanting northeastern state!</p>
                                    <br />
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> WHY ADORABLE VACATION IS ONE OF THE TOP TOUR OPERATORS FOR <strong>ARUNACHAL PRADESH IN MUMBAI?</strong> </h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>WHY ADORABLE VACATION IS ONE OF THE TOP TOUR OPERATORS FOR <strong>ARUNACHAL PRADESH IN MUMBAI?</strong></h4>
                                </div>
                                <div className="card-body">
                                    <ul>
                                        <li>•	The Co. have a wide variety of Pre-Purchased Room Inventory in Arunachal Pradesh which ensures assured valley/mountain/river view room based on your <strong>Arunachal package booking date from Mumbai</strong>.</li><br />
                                        <li>• With our Premium & Deluxe Hotel/Luxury Home Stay, you get the best facilities and amenities during your <strong>Assam Arunachal tour from Mumbai</strong>.</li><br />
                                        <li>• Your vehicle for <strong>Arunachal tour packages</strong> will be operated from Guwahati / Tezpur / Dibrugarh / Itanagar / Jorhat.</li><br />
                                        <li>• You get a variety of cars according to your <strong>Arunachal tour budget from Mumbai</strong> like Innova / Ertiga / Scorpio / XUV 500 (Sunroof).</li><br />
                                        <li>• We have a separate war room and specialized staff from the Northeast for <strong>Arunachal tour operations</strong>, ensuring uninterrupted service throughout your <strong>Arunachal tour from Guwahati Airport</strong>.</li><br />
                                        <li>• As the Best <strong>Arunachal tour operator in Mumbai</strong>, we guide you based on Peak Season Rush, Weather, and Off-Season to ensure a safe and enjoyable trip. We are responsible Arunachal tour guides from Mumbai and prioritize your safety and budget.</li><br />
                                        <li>• Adorable Vacation is a specialist for <strong>Arunachal Pradesh Tour Packages</strong> with Tawang, Kaziranga, Majuli, and Nameri for more than 11 years. </li><br />
                                        <li>• The company offers Deluxe Homestays, Premium Stays, Luxury Boutique Resorts, and Flavored Properties. </li><br />
                                        <li>• We have a team of experienced and passionate travelers. Every hotel/homestay is personally visited and certified by the Adorable Vacation team.</li><br />
                                        <li>• The company is an expert in conducting Arunachal group tours. Every year, they conduct Arunachal Group Departures in Summer Vacation and <strong>Arunachal Tours</strong> during Durga Puja/Diwali Holidays.</li><br />
                                        <li>• Guests will be given complete day-to-day guidance and assistance from Guwahati to Guwahati by the Adorable Vacation team member and their representative on the field. Every Guwahati Kaziranga Tawang tour is always handled by the Adorable Vacation Team itself, not handed over to a Sub-Agent.</li><br />
                                    </ul>
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> DISTANCES TO NOTE FOR <strong>ARUNACHAL PRADESH TOUR PACKAGES WITH KAZIRANGA TEZPUR NAMERI GUWAHATI</strong></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>DISTANCES TO NOTE FOR <strong>ARUNACHAL PRADESH TOUR PACKAGES WITH KAZIRANGA TEZPUR NAMERI GUWAHATI</strong></h4>
                                </div>
                                <div className="card-body">
                                    <div id="no-more-tables">
                                        <table class="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead class="cf">
                                                <tr className="table-danger">
                                                    <th>FROM</th>
                                                    <th>TO</th>
                                                    <th>DISTANCE / TRAVEL TIME</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td data-title="FROM">Guwahati</td>
                                                    <td data-title="TO">Bhalukpong</td>
                                                    <td data-title="DISTANCE">5 hr 9 min (225.7 km) via NH 15</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="FROM">Guwahati</td>
                                                    <td data-title="TO">Kaziranga</td>
                                                    <td data-title="DISTANCE">4 hr 16 min (192.8 km) via NH27</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="FROM">Bhalukpong</td>
                                                    <td data-title="TO">Dirang</td>
                                                    <td data-title="DISTANCE">4 hr 49 min (138.4 km) via Chariduar - Tawang Rd</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="FROM">Bhalukpong</td>
                                                    <td data-title="TO">Tezpur</td>
                                                    <td data-title="DISTANCE">1 hr 12 min (57.7 km) via Chariduar - Tawang Rd and NH 15</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="FROM">Dirang</td>
                                                    <td data-title="TO">Sangti Valley</td>
                                                    <td data-title="DISTANCE">31 min (13.5 km) via NH13</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="FROM">Dirang</td>
                                                    <td data-title="TO">Tawang</td>
                                                    <td data-title="DISTANCE">4 hr 46 min (134.5 km) via NH13</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="FROM">Tawang</td>
                                                    <td data-title="TO">Bomdila</td>
                                                    <td data-title="DISTANCE">5 hr 57 min (175.7 km) via NH13</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="FROM">Bomdila</td>
                                                    <td data-title="TO">Sangti Valley</td>
                                                    <td data-title="DISTANCE">1 hr 30 min (49.6 km) via NH13</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="FROM">Bomdila</td>
                                                    <td data-title="TO">Tezpur</td>
                                                    <td data-title="DISTANCE">4 hr 46 min (153.9 km) via Chariduar - Tawang Rd</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="FROM">Itanagar</td>
                                                    <td data-title="TO">Ziro</td>
                                                    <td data-title="DISTANCE">2 hr 58 min (105 km) via Joram - Palin - Sangram - Koloraing Rd</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="FROM">Ziro</td>
                                                    <td data-title="TO">Daporijo</td>
                                                    <td data-title="DISTANCE">5 hr 22 min (161 km) via NH13</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="FROM">Daporijo</td>
                                                    <td data-title="TO">Aalo</td>
                                                    <td data-title="DISTANCE">7 hr 50 min (160 km) via NH13</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="FROM">Aalo</td>
                                                    <td data-title="TO">Mechuka</td>
                                                    <td data-title="DISTANCE">4 hr 40 min (186 km) via NH13</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="FROM">Aalo</td>
                                                    <td data-title="TO">Pasighat</td>
                                                    <td data-title="DISTANCE">2 hr 30 min (101 km) via NH13</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="FROM">Pasighat</td>
                                                    <td data-title="TO">Dibrugarh</td>
                                                    <td data-title="DISTANCE">3 hr 10 min (149 km) via NH715</td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            {/* <!--By Itinerary--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h3 className="content-desktop" style={{ fontSize: "2.0vw" }}>
                                                DAY WISE DETAIL <strong style={{ color: 'tomato' }}>ARUNACHAL TOUR ITINERARY FROM GUWAHATI</strong>
                                            </h3>
                                            <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>
                                                DAY WISE DETAIL <strong>ARUNACHAL TOUR ITINERARY FROM GUWAHATI</strong>
                                            </h3>
                                        </div>
                                    </div>
                                </div>

                                <br />
                                <div className="row">
                                    <div
                                        className="accordion"
                                        id="accordionPanelsStayOpen"
                                    >
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day1"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseOne"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseOne"
                                                >
                                                    DAY 1 : GUWAHATI-BHALUKPONG [260 KM / 5 HRS]
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseOne"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingOne"
                                            >
                                                <div className="accordion-body">
                                                    <p>This is the first day of your much awaited <strong>Arunachal Pradesh package tour from Mumbai</strong>. Arrival at Guwahati Airport. Pickup and Drive to Bhalukpong (260 km). It is located by the scenic Kameng river. Bhalukpong, which falls in West Kameng District is 56 km from Tezpur. Bhalukpong, at a height of 700 ft above sea level, is a little town on the Kameng River and lies along the dense forests that form the border of the Pakhui Wildlife Sanctuary. Bhalukpong is a mesmerizing place for wildlife enthusiasts and nature lovers. Scenic beauty, high water falls and the banks of river Kameng surrounded by evergreen forests are a photographer’s delight. Overnight at Bhalukpong.</p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay1ImageURL} alt="..." /></center>
                                                    </div>
                                                    <div className="content-mobile2">
                                                        <center><img src={navDay1ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="day2">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseTwo"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseTwo"
                                                >
                                                    DAY 2 : BHALUKPONG TO DIRANG [140 KM / 4 HRS]
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseTwo"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingTwo"
                                            >
                                                <div className="accordion-body">
                                                    <p>After breakfast, check out of the Hotel & transfer to Dirang. Then, visit Shangti Valley, famous for Black Named cranes that come in October and fly back in May. Considered to be the best valley in the Eastern Himalayan Zone. Overnight at Dirang.</p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay2ImageURL} alt="..." /></center>
                                                    </div>
                                                    <div className="content-mobile2">
                                                        <center><img src={navDay2ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="day3">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseThree"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 3 : DIRANG – DAY EXCURSION TO MANDALA TOP AND THEMBHANG VILLAGE [UNESCO]
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseThree"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingThree"
                                            >
                                                <div className="accordion-body">
                                                    <p>After breakfast, drive to Mandala Top, a series of 108 Buddhist Stupas built in a circle on a mountain top in Arunachal Pradesh. Built in 2018, it's worth visiting and attracts birding tourists and photographers. Have lunch on the way and drive to the fortified Thambang heritage village by UNESCO. Thembang is an ancient village established before the first century AD, originally known as Yuchho-Pema-Chen. Evening back to the hotel. Overnight at Dirang.</p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay3ImageURL} alt="..." /></center>
                                                    </div>
                                                    <div className="content-mobile2">
                                                        <center><img src={navDay3ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="day4">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseFour"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseFour"
                                                >
                                                    DAY 4 : DIRANG -TAWANG [135 KM / 4.5 HRS]
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseFour"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingFour"
                                            >
                                                <div className="accordion-body">
                                                    <p>Drive to Tawang crossing Sela Pass (13720 ft), which runs through a small ridge and along Paradise Lake. Stop to enjoy Sela Pass and Paradise Lake. From Sela Pass, the road winds down to Jong Village. Visit Yashwant Garh War Memorial on the way to Jong. After lunch at Jong Village, proceed to Tawang. On the way, visit Nuranang Fall. Overnight at Tawang.</p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay4ImageURL} alt="..." /></center>
                                                    </div>
                                                    <div className="content-mobile2">
                                                        <center><img src={navDay4ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="day5">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseFive"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseFive"
                                                >
                                                    DAY 5 : TAWANG – BUMLA PASS - TAWANG [BUMLA COST EXCLUDED]
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseFive"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingFive"
                                            >
                                                <div className="accordion-body">
                                                    <p>Enjoy a full-day excursion to visit PT Tso Lake, Bumla Pass (excluded), and Sangetsar Lake, popularly known as Madhuri Lake, named after the Bollywood star Madhuri Dixit. Located 35 km from Tawang near the China border, the drive to Sangetsar Lake is thrilling, with visible war bunkers from the 1962 Chinese aggression. Drive back to Tawang. Overnight at Tawang.</p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay5ImageURL} alt="..." /></center>
                                                    </div>
                                                    <div className="content-mobile2">
                                                        <center><img src={navDay5ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="day6">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseSix"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseSix"
                                                >
                                                    DAY 6 : TAWANG LOCAL SIGHTSEEING
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseSix"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingSix"
                                            >
                                                <div className="accordion-body">
                                                    <p>Sightseeing in and around Tawang city. Visit the famous Tawang Monastery, which is 400 years old, accommodates over 500 monks, and houses priceless Buddhist texts and scriptures. The Tibetan influence is unmistakable, with elaborately painted wooden windows and motifs. An 8 mt high gilded statue of the Buddha is seated here, along with numerous ancient Thankas or traditional paintings and manuscripts. Also, visit the Craft Centre and Tawang War Memorial. Evening free time. Overnight at Tawang.</p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay6ImageURL} alt="..." /></center>
                                                    </div>
                                                    <div className="content-mobile2">
                                                        <center><img src={navDay6ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="day7">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseSeven"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseSeven"
                                                >
                                                    DAY 7 : TAWANG TO BOMDILA [175 KM / 6 HRS APPROX]
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseSeven"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingSeven"
                                            >
                                                <div className="accordion-body">
                                                    <p>After breakfast, check out from your hotel & proceed to Bomdila. Reach Bomdila, check in at your hotel. Afternoon visit to Bomdila Gompa & local market. Evening free for leisure. Overnight at Bomdila.</p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay7ImageURL} alt="..." /></center>
                                                    </div>
                                                    <div className="content-mobile2">
                                                        <center><img src={navDay7ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="day8">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseEight"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseEight"
                                                >
                                                    DAY 8 : BOMDILA TO NAMERI NATIONAL PARK / TEZPUR [122 KM / 5 HRS APPROX]
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseEight"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingEight"
                                            >
                                                <div className="accordion-body">
                                                    <p>Drive toward the foothills of Arunachal Pradesh and reach the plains of Assam. If time permits, visit the Tipi Orchid Centre. Overnight stay at Nameri / Tezpur.</p>
                                                    <br />
                                                    {/* <div className="content-desktop">
                                                        <center><img src={navDay8ImageURL} alt="..." /></center>
                                                    </div>
                                                    <div className="content-mobile2">
                                                        <center><img src={navDay8ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="day9">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseNine"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseNine"
                                                >
                                                    DAY 9 : NAMERI NATIONAL PARK / TEZPUR – ITANAGAR [153 KM / 4.5 HRS]
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseNine"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingNine"
                                            >
                                                <div className="accordion-body">
                                                    <p>Drive to Itanagar, the capital city of Arunachal Pradesh. In the evening, explore local areas like Itanagar Gompa, Itafort, and local markets. Overnight at Itanagar.</p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay9ImageURL} alt="..." /></center>
                                                    </div>
                                                    <div className="content-mobile2">
                                                        <center><img src={navDay9ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="day10">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseTen"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseTen"
                                                >
                                                    DAY 10 : ITANAGAR – ZIRO [109 KM / 5.5 HRS]
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseTen"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingTen"
                                            >
                                                <div className="accordion-body">
                                                    <p>Day drive to Ziro. Ziro is a picturesque town in Arunachal Pradesh, famous for its natural charm and hilly areas. The town lies on the Apatani Plateau and is the cultural heritage site of the Apatani tribe. Ziro Valley is also declared a World Heritage Site by the UN. Overnight at Ziro.</p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay10ImageURL} alt="..." /></center>
                                                    </div>
                                                    <div className="content-mobile2">
                                                        <center><img src={navDay10ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="day11">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseEleven"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseEleven"
                                                >
                                                    DAY 11 : ZIRO VILLAGE TOUR
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseEleven"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingEleven"
                                            >
                                                <div className="accordion-body">
                                                    <p>After breakfast, explore the beautiful landscapes and tribes of Ziro. The Apatani are non-nomadic tribes settled around Ziro Valley, primarily engaged in wetland cultivation. This tribe is distinct from others in Arunachal and used to practice facial tattoos. Interact with the local people, listen to their stories, and learn about their cultures. Stay at the same hotel in Ziro. Overnight stay at Ziro.</p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay11ImageURL} alt="..." /></center>
                                                    </div>
                                                    <div className="content-mobile2">
                                                        <center><img src={navDay11ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="day12">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseTwelve"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseTwelve"
                                                >
                                                    DAY 12 : ZIRO TO DAPORIJO [167 KM / 7 HRS]
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseTwelve"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingTwelve"
                                            >
                                                <div className="accordion-body">
                                                    <p>Post breakfast, drive to Daporijo. On the way, visit Tagin villages. Daporijo is a town in Arunachal Pradesh, home to four ethnic groups: Galo, Tagin, Hillmiri, and Nah tribes. The evening is free to stroll around the market area. Overnight stay at Daporijo / North Lakhimpur.</p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay12ImageURL} alt="..." /></center>
                                                    </div>
                                                    <div className="content-mobile2">
                                                        <center><img src={navDay12ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="day13">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseThirteen"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThirteen"
                                                >
                                                    DAY 13 : DAPORIJO TO AALO [160 KM / 8 HRS APPROX]
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseThirteen"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingThirteen"
                                            >
                                                <div className="accordion-body">
                                                    <p>After breakfast, move towards Aalo (aka Along), situated on the bank of the Siang River at an altitude of 1020 ft. It is a beautiful valley with picturesque landscapes, home to the Galo and Adi tribes. Overnight stay at Aalo.</p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay13ImageURL} alt="..." /></center>
                                                    </div>
                                                    <div className="content-mobile2">
                                                        <center><img src={navDay13ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="day14">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseFourteen"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseFourteen"
                                                >
                                                    DAY 14 : AALO TO PASIGHAT [101 KM / 3 HRS APPROX]
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseFourteen"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingFourteen"
                                            >
                                                <div className="accordion-body">
                                                    <p>After breakfast, move towards Pasighat, located 145 km from Along on the bank of the Siang River. It is the land of the Passi and Minnyiong tribes. Overnight stay at Pasighat.</p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay14ImageURL} alt="..." /></center>
                                                    </div>
                                                    <div className="content-mobile2">
                                                        <center><img src={navDay14ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="day15">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseFifteen"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseFifteen"
                                                >
                                                    DAY 15 : PASIGHAT TO DIBRUGARH DROP [150 KM / 3.5 HRS APPROX]
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseFifteen"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingFifteen"
                                            >
                                                <div className="accordion-body">
                                                    <p>After breakfast, drive to Dibrugarh and cross the mighty Brahmaputra River by ferry. After spending more than a week amidst the serene hills, we will be in the plains of Assam. In Dibrugarh, visit the Jagannath Temple before heading to the airport for your departure, filled with sweet memories of northeast India. Drop off at Dibrugarh Railway Station / Airport.</p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay15ImageURL} alt="..." /></center>
                                                    </div>
                                                    <div className="content-mobile2">
                                                        <center><img src={navDay15ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                            <br /><br />
                            {/* <!--insert table inclusion exclusion--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        {
                                            <InclusionExclusion />
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* <!--end table inclusion exclusion--> */}
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> VALLEYS MUST VISIT DURING <strong>ARUNACHAL PRADESH TOUR PACKAGES FROM MUMBAI </strong></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>VALLEYS MUST VISIT DURING <strong>ARUNACHAL PRADESH TOUR PACKAGES FROM MUMBAI </strong></h4>
                                </div>
                                <div className="card-body">
                                    <p>Revered as the <strong>Land of The Rising Sun</strong> Arunachal unveils valleys of unparalleled beauty that leave every traveler and nature enthusiast awestruck. The valleys we are about to delve into are a canvas of picturesque charm, adorned with lush meadows, pine forests, crystalline streams, and unending apple and orange orchards. Allow us to offer you a glimpse into a selection of the most captivating valleys waiting to be discovered on your <strong>Arunachal Trip</strong>.</p>
                                    <p><strong>Sangti Valley - </strong>Situated within the West Kameng District of Arunachal Pradesh, this valley becomes a popular summer destination thanks to its delightful climate. What sets this valley apart is the graceful presence of black-necked cranes along with flourishing fruit orchards, boasting a variety of apples, oranges, and kiwis.</p>
                                    <p><strong>Mechuka Valley - </strong>Nestled within the Shi Yomi District of Arunachal Pradesh, this valley rests at an elevation of 6000 feet above sea level, in close proximity to the Indo-China border at a mere distance of 29 kilometers. Embellished with snow-capped peaks, meandering mountain streams, and luxuriant green meadows, Mechuka stands as one of Arunachal's most sought-after destinations.</p>
                                    <p><strong>The Three Valleys -</strong> Encompassing three distinct valleys – Pakke Valley, Pappu Valley, and Passa Valley – this region presents a captivating spectacle of natural beauty. During the summer season, the valleys come alive with a vibrant display of wildflowers, while an abundance of clear streams adds to the enchantment of the scenery, ensuring an unforgettable experience. Furthermore, the arrival of numerous migratory birds in these valleys during summer contributes to their allure, making them a highly favored destination among tourists.</p>
                                    <p>Apart from these valleys, there are Dibang Valley in Dibang District / Lohit Valley in Lohit District / Lal Ane valley in Papum Pare District / Aalo Valley in West Siang District / Ziro Valley in Lower Subansiri district are very popular among tourists.</p>
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div className="card-header custHeaer">
                                    <h3 className='content-desktop' style={{ fontSize: "1.5vw" }}>HOW TO GET <strong>ILP [INNER LINE PERMIT]</strong> FOR ARUNACHAL TOUR</h3>
                                    <h3 className='content-mobile2' style={{ fontSize: "3.5vw" }}>HOW TO GET <strong>ILP [INNER LINE PERMIT]</strong> FOR ARUNACHAL TOUR</h3>

                                </div>

                                <div className="card-body">
                                    <p>Here to note that due to its immense strategic importance, you can experience heavy deployment of Army personals and military hardware in different parts of Arunachal. So to travel sensitive area, you need to acquire a special permit called <strong>ILP or Inner Line Permit</strong>. Basically to enter Arunachal you need an ILP. After crossing Valukpong, in every location like Dirang, Bomdila, Tawang in western part and Itanagar in eastern part, travellers need to produce ILP. For Ziro and other protected/preserved areas, a special permit is required called <strong>RAP or Restricted Area Permit</strong>. That can be arranged by Adorable Vacation. You need not to take any headache.</p>

                                </div>
                            </div>

                            {/* <!--start of package details--> */}

                            {/* <!--end of package details--> */}
                            <br /><br />
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>Frequently Ask <span>Questions</span></h2>
                                            <h4><strong>Arunachal Pradesh tour from Mumbai</strong> Trip Related Questions</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="accordion" id="accordionPanelsStayOpenExample">
                                        <div className="accordion" id="accordionPanelsStayOpenExample">
                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingOne">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                                        What is Losar?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseOne" className="accordion-collapse collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">Losar is a major festival in Arunachal Pradesh that celebrates the Tibetan New Year. It's celebrated with great pomp by various tribes like Monpas, Akas, Sherdukpens, Memba, Khamba, and Nah who follow Mahayana Buddhism. During Losar, priests make offerings to high priests like Dharmapala or Palden Lhamo, while people visit friends and family to wish them "Tashi Delek" (Best Wishes). Leaders and kings are honored, and on the final day, people make offerings and tie prayer flags around their homes and communities.</div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingTwo">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                                        Can we do River Rafting during our Arunachal trip?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseTwo" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">Yes, you can go river rafting during your trip to Arunachal Pradesh. Popular spots for river rafting include Aalong, Pasighat, and Nameri where adventure enthusiasts can participate in this thrilling sport.</div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingThree">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                                        What food to try during our trip to Arunachal Pradesh?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseThree" className="accordion-collapse collapse show" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">Arunachal Pradesh is known for its diverse cuisine, especially its meat preparations like lukter (beef), chicken or yak momos, thukpas, bamboo shoot dishes, dung po, Chura sabji, pika pila, and more.</div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingFour">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                                        Can we do angling while touring Arunachal Pradesh?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseFour" className="accordion-collapse collapse show" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">Yes, you can enjoy angling while visiting Arunachal Pradesh. The region offers opportunities for fishing enthusiasts to indulge in this activity.</div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingFive">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                                                        Can I do trekking during my North East tour package from Guwahati?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseFive" className="accordion-collapse collapse show" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">Yes, trekking can be included in your North East tour package. There are many enthralling trekking routes in Arunachal Pradesh and other parts of the North East.</div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingSix">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                                                        Which are the best spots to watch birds during my North East trip?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseSix" className="accordion-collapse collapse show" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">Kaziranga National Park, Nameri National Park, Eagle Nest Wildlife Sanctuary, and Dipor Bil are renowned birdwatching destinations in the North East known for their diverse bird species and natural beauty.</div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingSeven">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
                                                        Can I avail Jain food during my Arunachal Tour?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseSeven" className="accordion-collapse collapse show" aria-labelledby="flush-headingSeven" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">Yes, you can request Jain food during your Arunachal tour package. It's advisable to mention your dietary preferences at the time of booking with your tour operator.</div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingEight">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
                                                        Can I get medical shops on the way during the Arunachal Pradesh Tour?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseEight" className="accordion-collapse collapse show" aria-labelledby="flush-headingEight" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">Yes, you can find medical shops in towns like Guwahati, Itanagar, and Pasighat. However, due to the high altitude terrain in many parts of Arunachal Pradesh, it's recommended to carry necessary medications with you.</div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingNine">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseNine">
                                                        Do I need to carry high altitude medicines while traveling to Arunachal Pradesh?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseNine" className="accordion-collapse collapse show" aria-labelledby="flush-headingNine" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">It's advisable to carry high altitude medicines after consulting with your physician, especially if you have health conditions that may be affected by high altitudes.</div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingTen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
                                                        Shall we visit offbeat destinations during our Arunachal Pradesh Tour?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseTen" className="accordion-collapse collapse show" aria-labelledby="flush-headingTen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">Yes, your Arunachal Pradesh tour package can include visits to offbeat destinations like Ziro Valley, Mechuka Valley, Pasighat, and Aalo, providing a unique and enriching travel experience.</div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingEleven">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEleven" aria-expanded="false" aria-controls="flush-collapseEleven">
                                                        Who is the chief minister of Arunachal Pradesh?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseEleven" className="accordion-collapse collapse show" aria-labelledby="flush-headingEleven" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">Shri Pema Khandu is the chief minister of Arunachal Pradesh.</div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingTwelve">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwelve" aria-expanded="false" aria-controls="flush-collapseTwelve">
                                                        How many districts are there in Arunachal Pradesh as of 2023?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseTwelve" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwelve" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">As of 2023, Arunachal Pradesh comprises 25 districts, including the newly created districts like Shi-Yomi, Pakke-Kessang, and others.</div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingThirteen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThirteen" aria-expanded="false" aria-controls="flush-collapseThirteen">
                                                        Which is the capital city of Arunachal Pradesh?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseThirteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingThirteen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">Itanagar is the capital city of Arunachal Pradesh.</div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingFourteen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFourteen" aria-expanded="false" aria-controls="flush-collapseFourteen">
                                                        Which is the main river in Arunachal Pradesh?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseFourteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingFourteen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">The Brahmaputra River, known as "Yarlung Tsangpo" in Tibet and "Siang/Dihang" in Arunachal Pradesh, is the main river and a lifeline for the state.</div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingFifteen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFifteen" aria-expanded="false" aria-controls="flush-collapseFifteen">
                                                        What is the weather like in Arunachal Pradesh?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseFifteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingFifteen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">Arunachal Pradesh has varied climate zones due to its topographical diversity, ranging from sub-tropical to temperate based on altitude. Summers are moderate, winters can be cold, and the monsoon season brings heavy rainfall.</div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingSixteen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSixteen" aria-expanded="false" aria-controls="flush-collapseSixteen">
                                                        Is it safe to travel to Arunachal Pradesh?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseSixteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingSixteen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">Yes, Arunachal Pradesh is considered one of the safest states in the northeastern region of India. It's advisable to carry identity cards and obtain necessary permits like the Inner Line Permit (ILP) for travel within the state.</div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingSeventeen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeventeen" aria-expanded="false" aria-controls="flush-collapseSeventeen">
                                                        Do I need a permit to travel to Arunachal Pradesh?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseSeventeen" className="accordion-collapse collapse show" aria-labelledby="flush-headingSeventeen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">Yes, you need an Inner Line Permit (ILP) to travel to Arunachal Pradesh. This permit can be obtained online or from designated authorities before entering the state.</div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingEighteen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEighteen" aria-expanded="false" aria-controls="flush-collapseEighteen">
                                                        Can I experience snowfall in Tawang?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseEighteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingEighteen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">Yes, Tawang, located at a high altitude, experiences snowfall from late November to April. The weather is cold with chilly breezes during the winter season.</div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingNineteen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNineteen" aria-expanded="false" aria-controls="flush-collapseNineteen">
                                                        How many days does it take to complete the Arunachal Pradesh Tour?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseNineteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingNineteen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">The duration of your Arunachal Pradesh tour depends on the places you want to cover. Typically, a 9 to 10-day tour package from Guwahati can cover major attractions like Tawang, Bum La Pass, Zimithang, and other regions like Ziro and Mechuka.</div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h3><b> Arunachal Tour Mumbai<span class="tomato">Holiday Reviews</span></b></h3>
                                            <h4><i>"Your Experience is our Marketing"</i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-cols-1">
                                    <div id="testimonials">
                                        <div className="testimonial-box-container">

                                            {reviewersDataView}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <BookingBox />
                        </div>
                    </div >
                </div >
                <div className="btn-whatsapp-pulse">
                    <a style={{ color: 'white' }} href="#" onClick={this.handleClick}>
                        <i className="fab fa-whatsapp"></i>
                    </a>
                </div>
                <div class="btn-call-pulse">
                    <a style={{ color: 'white' }} href="tel:+91-967473-8480">
                        <i className="fa fa-phone"></i>
                    </a>
                </div>
            </>
        );
    }
}

export default ArunachalTourMumbai_14N_15D_Package;