import React, { Component } from "react";
import BookingBox from "../BookingBox";
import Slider from "react-slick";
import axios from "axios";
import cogoToast from "cogo-toast";
import { Digital } from "react-activity";
import "react-activity/dist/library.css";
import ApiURL from "../../../api/ApiURL";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import "./InclusionExclusion";
import InclusionExclusion from "./InclusionExclusion_arunachalkolkata_9n_10d";

class ArunachalTourKolkata_9N_10D_Package extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customActiveTab: 'tab1',
      daywiseActiveTab: 'day1'
    }
  }

  handleCustomTabClick = (tab) => {
    this.setState({
      customActiveTab: tab,
    });
  };

  handleDayWiseTabClick = (tab) => {
    this.setState({
      daywiseActiveTab: tab,
    });
  };
  handleClick = () => {
    const currentUrl = window.location.href;
    const pageTitle = document.title;
    const message = `Hi, Please send about the Tour Package details "${pageTitle}"`;

    const whatsappLink = `https://api.whatsapp.com/send?phone=+919674738480&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
    window.open(whatsappLink, '_blank');
  };
  render() {
    const { customActiveTab, daywiseActiveTab } = this.state;
    const packageTopBannerImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/Arunachal-Pradesh-Package-Tour-from-Kolkata.webp";
    const packageTopBanner2ImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/Arunachal-Pradesh-Tour-Package-from-Kolkata.webp";
    const sightseen1ImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/ARUNACHAL PRADESH TOUR PACKAGES FROM KOLKATA.webp";
    const sightseen2ImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/Arunachal-Pradesh-Package-Tour-from-Guwahati.webp";
    const sightseen3ImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/Shillong-Package-Tour.webp";

    const navDay1ImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/BHALUKPONG-TO-DIRANG-TOUR.webp";
    const navDay2ImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/DIRANG-TO-TAWANG.webp";
    const navDay3ImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/TAWANG-LOCAL-SIGHTSEEING.webp";
    const navDay4ImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/TAWANG-BUMLA PASS.webp";
    const navDay5ImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/TEZPUR-KAZIRANGA.webp";
    const navDay6ImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/TAWANG-BOMDILA.webp";
    const navDay7ImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/BOMDILA-TEZPUR";
    const navDay8ImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/JUNGLE-SAFARI-KAZIRANGA.webp";
    const navDay9ImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/GUWAHATI-TO-BHALUKPONG.webp";
    const navDay10ImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/GUWAHATI.webp";
 
    const TrendingPackagesDataList = this.props.TrendingPackagesData;
    const hotPackagesView = TrendingPackagesDataList.length > 0 ?
      (
        TrendingPackagesDataList.map((dataItem, i) => {
          const hotelImgURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/" + dataItem.ImageName;
          return (<div className="col-md-12 p-0" key={i}>
            <div className="card h-100">
              <img src={hotelImgURL} className="card-img-top" alt={dataItem.AllternateText} />
              <div className="card-body">
                <h6 className="card-title">{dataItem.Title} <span> &#8377; {dataItem.PricePerNight}</span></h6>
                <p className="card-text">
                  <i className="fa fa-building tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-utensils tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-car-side tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-male tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-camera tomato"></i>
                </p>
                <p className="card-text"><span>{dataItem.Description}</span></p>
                <p>
                  <span><b className="tomato">{dataItem.NoOfTours} </b>Tours</span> | &nbsp;
                  <span><b className="tomato">{dataItem.NoOfDeparture} </b>Departures</span> | &nbsp;
                  <span><b className="sdf-blue"> {dataItem.Include}</b></span>
                </p>
              </div>
              <div className="card-footer">
                <small className="text-muted"><strong className="tomato">{dataItem.MutedText}</strong></small>
                <button type="button" className="btn btn-Warning btn-sm float-end">Book Now</button>
              </div>
            </div>
          </div>)
        })
      ) : (<div></div>)


    const settings = {
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    const reviewersDataList = this.props.ReviewersData;
    const reviewersDataView = reviewersDataList.map((dataItem, i) => {
      const reviewerImageURL = ApiURL.ArunachalTourKolkata_9N_10D_PackageImageURL + "/" + dataItem.ImageName;
      return (
        <div className="testimonial-box" key={i}>
          <div className="box-top">
            <div className="profile">
              <div className="profile-img">
                <img src={reviewerImageURL} alt="..." />
              </div>
              <div className="name-user">
                <strong>{dataItem.Name}</strong>
                <span>{dataItem.Location}</span>
              </div>
            </div>
            <div className="reviews">
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
            </div>
          </div>
          <div className="client-comment">
            <p>{dataItem.Comment}</p>
          </div>
        </div>
      )
    })

    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <img src={packageTopBannerImageURL} className="mobile-image2" alt="..." />
          </div>
        </div>
        <div className="container-fluid custom-container py-3">
          <div className="row">
            <div className="col-md-9">
              <div className="row">
                <div className="col-md-12">
                  <div className="container-fluid custom-container py-1">


                    <h1 className="content-desktop" style={{ fontSize: "2vw" }}><center><strong>Arunachal Tour Package from Kolkata  – Best Deal from Adorable Vacation LLP</strong></center></h1>

                    <h1 className="content-mobile" style={{ fontSize: "4vw" }}><center><strong>Arunachal Tour Package from Kolkata  – Best Deal from Adorable Vacation LLP</strong></center>
                      <hr />
                    </h1>
                    <div className="container-fluid">
                      <div className="row">
                        <img src={packageTopBanner2ImageURL} className="mobile-image" alt="..." />
                      </div>
                    </div>
                    <hr />
                  </div>
                  <h2 className="content-desktop p-4" style={{ fontSize: "2.0vw" }}> Are You Looking  <strong>Arunachal Package Tour from Kolkata in 2024? </strong>For Booking Kolkata to Arunachal Tour <a href='tel:9674738480' class="tomato">☎ CALL +91- 96747-38480/</a><a href='tel:9163676037' class="tomato">+91-91636-76037</a></h2>
                  <h2 className="content-mobile p-4" style={{ fontSize: "4.5vw" }}> Are You Looking  <strong>Arunachal Package Tour from Kolkata in 2024? </strong>For Booking Kolkata to Arunachal Tour <a href='tel:9674738480' class="tomato">☎ CALL +91- 96747-38480/</a><a href='tel:9163676037' class="tomato">+91-91636-76037</a></h2>
                  <div className="card">
                    <div
                      className="card-header custHeaer"
                    >
                      <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> India's Largest <b>North East Tour Operator</b></h3>
                      <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}> India's Largest <b>North East Tour Operator</b></h4>
                    </div>
                    <div className="card-body">
                      <p>Embark on an unforgettable journey through the unexplored wonders of Arunachal Pradesh with our exclusive <strong>Arunachal Trip from Kolkata</strong>. Immerse yourself in the breathtaking landscapes that unveil the raw beauty of hills, rivers, mountains, and valleys, creating a tapestry of nature at its purest form. This travel experience is meticulously crafted for those seeking a soulful escape from the routine, providing a blend of adventure, cultural immersion, and scenic splendour.</p>
                      <p>Arunachal Pradesh, the least visited gem of India, unfolds its charm to visitors with winding green valleys and gushing rivers. Our <strong>Arunachal Pradesh Tour Packages</strong> ensures you witness the untouched beauty of the Himalaya, offering a tranquil retreat by the riverside also. Immerse yourself in the serenity of nature, away from the hustle and bustle of city life.</p>
                      <p>Delve into the rich heritage and culture of Arunachal Pradesh as you visit magnificent monasteries that echo with spirituality. Our <strong>Tawang Tour Package from Guwahati</strong> provides an opportunity to witness the vibrant traditions and customs of the local tribes, creating a cultural tapestry that adds depth to your travel experience. The iconic Tawang Monastery stands as a testament to the spiritual essence of this region.</p>
                      <p>Indulge your senses with the sweet aroma of oranges and the exotic beauty of orchids that adorn the landscape. Arunachal Pradesh is a haven for nature enthusiasts and food lovers alike. Taste the mouth-watering delicacies unique to this region, offering a culinary journey that complements the visual feast of your surroundings.
                      </p>
                      <p>Our <strong>Arunachal Pradesh Tour Package from Kolkata</strong> are designed to cater to the discerning traveller, providing a seamless blend of adventure and relaxation. Whether you are an avid trekker or a culture enthusiast, our packages offer a diverse range of experiences, ensuring your journey is as unique as the destination.</p>
                      <p>Embark on a soul-stirring adventure with our <strong>Arunachal Tour Booking from Kolkata</strong>, where every moment is a discovery and every landscape is a masterpiece. Book your escape now and let the dawn-lit mountains of Arunachal Pradesh weave their magic on your soul.</p>
                      <br />
                      <div className="content-desktop">
                        <center><img src={sightseen1ImageURL} alt="..." /></center>
                      </div>

                      <div className="content-mobile2">
                        <center><img src={sightseen1ImageURL} style={{ width: '320px' }} alt="..." /></center>
                      </div>
                      <br />

                    </div>
                  </div>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>Arunachal Pradesh Tour Packages from Kolkata Duration: 9N | 10D</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>Arunachal Pradesh Tour Packages from Kolkata Duration: 9N | 10D</strong></h4>
                </div>
                <div className="card-body">
                  <p>Places to visit in <strong>Arunachal Tour from Kolkata</strong>:<br /> Bhalukpong 1N | Dirang 2N | Tawang 3N | Bomdila 1N | Kaziranga 2N<br /></p>
                  <p><strong>Pickup & Drop Point </strong>: <strong>Arunachal Pradesh tour starting from Kolkata</strong> Lokpriya Gopinath Bordoloi International Airport [GAU]</p>
                  <br />

                </div>
              </div>
              <br /><br />
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <table className="table table-bordered">
                      <thead>
                        <tr className="table-danger">
                          <th>
                            SUMMERY OF ARUNACHAL PRADESH TOUR PLAN FROM KOLKATA
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td
                            className="clickable"
                          >
                            <a href="#day1" style={{ color: 'black' }}><b>DAY 1 → </b>
                              <span>
                                GUWAHATI TO BHALUKPONG [4 HR 51 MIN | 239.5 KM]
                              </span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day2" style={{ color: 'black' }}><b>DAY 2 → </b>
                              <span>BHALUKPONG TO DIRANG TOUR [4 HR 36 MIN (138.4 KM)]</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day3" style={{ color: 'black' }}><b>DAY 3 → </b>
                              <span>DIRANG - TAWANG TRANSFER [140 KM / 5 HRS APPROX]</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day4" style={{ color: 'black' }}><b>DAY 4 → </b>
                              <span>TAWANG - BUMLA PASS – TAWANG</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day5" style={{ color: 'black' }}><b>DAY 5 → </b>
                              <span>TAWANG LOCAL SIGHTSEEING</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day6" style={{ color: 'black' }}><b>DAY 6 → </b>
                              <span>TAWANG - BOMDILA [180 KM / 5 HRS APPROX]</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day7" style={{ color: 'black' }}><b>DAY 7 → </b>
                              <span>BOMDILA TO TEZPUR TRANSFER [3 HR 50 MIN | 151 KM]</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day8" style={{ color: 'black' }}><b>DAY 8 → </b>
                              <span>TEZPUR TO KAZIRANGA [1 HR 10 MIN | 50.6 KM]</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day9" style={{ color: 'black' }}><b>DAY 9 → </b>
                              <span>JUNGLE ACTIVITIES AT KAZIRANGA [Safaris Included]</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day10" style={{ color: 'black' }}><b>DAY 10 → </b>
                              <span>TRIP ENDS WITH SWEET MEMORIES</span>
                            </a>
                          </td>
                        </tr>

                      </tbody>
                    </table>
                  </div>

                </div>
              </div>

              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> WHAT IS THE BEST TIME TO BOOK <strong>ARUNACHAL PRADESH TOUR PLAN FROM KOLKATA?</strong> </h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>WHAT IS THE BEST TIME TO BOOK <strong>ARUNACHAL PRADESH TOUR PLAN FROM KOLKATA? </strong></h4>
                </div>
                <div className="card-body">
                  <p>Before choosing your <strong>Arunachal Pradesh package Tour from Kolkata</strong> or <a href="/arunachal-pradesh-package-tour-from-guwahati" target="_blank"><strong>Arunachal Pradesh Package Tour from Guwahati</strong></a> let's first understand that Arunachal is a large state. It is almost 83,743Km2 in size that compared to Austria in Europe. Region wise Arunachal is divided in two main ranges, the high Himalayan range or cool region and the sub-Himalayan range or tropical region. So, the weather condition in Arunachal varies when you travel from tropical to cool zone. Now, during your Arunachal Tour Package from Kolkata, you can experience three types of seasons: Summer, Winter, and Monsoon.</p>
                  <p><strong>Spring [March to May]:</strong> Spring is the perfect time to visit Arunachal Pradesh from Mumbai, with pleasant weather and blooming flora adding to the charm of the landscape. Explore the colorful festivals of the tribes, such as the Sangken festival of the Khamptis, and indulge in outdoor activities like trekking and camping.</p>
                  <p><strong>SUMMER IN ARUNACHAL PRADESH</strong> When the entire plains of India burn due to scorching and unbearable heat of summer, Arunachal comes as an angel to save you from the boiling temperature. According to Adorable Vacation, summer is the perfect time to book <strong>Arunachal Pradesh Tour Packages from kolkata</strong>. During summer (end of May to end of June) the temperature in Sub-Himalayan Region (lower Himalayas) ranges between 22°c to 33°c and in Upper Himalayan Region you can experience temperature between 8°c to 14°c which makes Arunachal a perfect holiday place for family, honeymooners and solo travellers. Summer in Arunachal is the best time to behold the breathtaking beauties of mountains, streams, waterfalls, valleys, wildlife and monasteries.</p>
                  <p><strong>WINTER IN ARUNACHAL PRADESH:</strong> If you can bear the biting cold, then travelling Arunachal during winter (Oct to March) also can be an amazing experience. In winter you can experience temperatures between 5°c to -12°c which is sub-zero temperature and can freeze your bones easily. With zero probability of rain, travelling among the snow-covered roads and valleys can be a lifelong experience.</p>
                  <br />
                  <div className="content-desktop">
                    <center><img src={sightseen2ImageURL} alt="..." /></center>
                  </div>

                  <div className="content-mobile2">
                    <center><img src={sightseen2ImageURL} style={{ width: '320px' }} alt="..." /></center>
                  </div>
                  <br />
                  <p><strong>MONSOON IN ARUNACHAL PRADESH:</strong> Monsoon generally consists of July to September in Arunachal and if you travel this season, you can experience some difficulties like roadblocks, landslides, incessant rain for several days like cats and dogs. Adorable Vacation suggest its guests not to book any tour package during this season. In spite of that, if you are too much of a travel enthusiast, then explore the rain-washed valleys, gushing waterfalls, over flooded streams and lifestyle of people of Arunachal, travel here in monsoon only. We bet thrilling will be waiting in every nook and corner of Arunachal. Here one point to note that Meghalaya, one of the states of Seven Sisters State of North East, can be best travelled during monsoon if you wish to behold the original beauty of rain-kissed Meghalaya, so book a <strong>Shillong Package Tour now</strong>.</p>
                  <br /><br />
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr className="table-danger">
                          <th>TRAVEL MONTHS</th>
                          <th>WEATHER</th>
                          <th>TEMPERATURE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td data-title="MONTHS">March to June</td>
                          <td data-title="WEATHER">Summer</td>
                          <td data-title="TEMPERATURE">10°C to 30°C</td>
                        </tr>
                        <tr>
                          <td data-title="MONTHS">July to October</td>
                          <td data-title="WEATHER">Monsoon</td>
                          <td data-title="TEMPERATURE">18°C to 32°C</td>
                        </tr>
                        <tr>
                          <td data-title="MONTHS">November to February</td>
                          <td data-title="WEATHER">Winter</td>
                          <td data-title="TEMPERATURE">6° C to 22° C</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br />
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> HOW CAN WE TRAVEL <strong>KOLKATA ARUNACHAL TOUR PACKAGE? </strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>HOW CAN WE TRAVEL <strong>KOLKATA ARUNACHAL TOUR PACKAGE? </strong></h4>
                </div>
                <div className="card-body">
                  <p>Any traveller looking for Arunachal Pradesh Tour starting from Kolkata can reach Arunachal in 3 ways i.e. by Air, Rail and Land.</p>
                  <p style={{ fontSize: "1.5vw" }}><b>TRAVEL ARUNACHAL PRADESH BY FLIGHT FROM KOLKATA</b></p>
                  <p>Well, there are two ways. If any guests are willing to do <strong>West Arunachal tour</strong> comprising Bhalukpong, Sangti, Tawang, Dirang, Bomdila etc, then it is ideal to travel to <strong>Guwahati Airport [GAU] or Lokpriya Gopinath Bordoloi International Airport</strong> from <strong>Netaji Subhash Chandra Bose International Airport [CCU], Kolkata</strong> and from there guests can start <strong>West Arunachal Pradesh Package Tour</strong></p>
                  <br />
                  <div className="content-desktop">
                    <center><img src={sightseen3ImageURL} alt="..." /></center>
                  </div>

                  <div className="content-mobile2">
                    <center><img src={sightseen3ImageURL} style={{ width: '320px' }} alt="..." /></center>
                  </div>
                  <p>If guests wish to travel to the eastern part of Arunachal Pradesh particularly <strong>Mechuka</strong> and <strong>Mayodia Pass</strong> (Altitude 2655 mtr), then it is advisable to take flight up to Dibrugarh Airport [DIB] and from there they can start for <strong>East Arunachal Pradesh tour.</strong></p>
                  <p>If guests have enough time to cover <p>complete Arunachal tour</p> comprising Western, Central and Eastern Arunachal Pradesh [minimum 13 to 14 nights required], then guests can choose FIFO [fly-in, fly-out] method, fly-in to Guwahati Airport and after completing the trip, they can fly-out from Dibrugarh Airport.</p>
                  <br />
                  <p><b>LIST OF AIRPORTS IN ARUNACHAL PRADESH TO KNOW</b></p>
                  <br />
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr className="table-danger">
                          <th>AIRPORT NAME</th>
                          <th>LOCATION</th>
                          <th>AIRPORT CODE</th>
                          <th>CATEGORY</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td data-title="AIRPORT NAME">Itanagar Airport</td>
                          <td data-title="LOCATION">Itanagar</td>
                          <td data-title="AIRPORT CODE">HGI</td>
                          <td data-title="CATEGORY">Domestic Airport</td>
                        </tr>
                        <tr>
                          <td data-title="AIRPORT NAME">Pasighat Airport</td>
                          <td data-title="LOCATION">Pasighat</td>
                          <td data-title="AIRPORT CODE">IXT</td>
                          <td data-title="CATEGORY">Domestic Airport</td>
                        </tr>
                        <tr>
                          <td data-title="AIRPORT NAME">Tezu Airport</td>
                          <td data-title="LOCATION">Tezu</td>
                          <td data-title="AIRPORT CODE">TEI</td>
                          <td data-title="CATEGORY">Domestic Airport</td>
                        </tr>
                        <tr>
                          <td data-title="AIRPORT NAME">Zero Airport</td>
                          <td data-title="LOCATION">Ziro</td>
                          <td data-title="AIRPORT CODE">ZER</td>
                          <td data-title="CATEGORY">Domestic Airport</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br />
                  <p><b>REACH ARUNACHAL PRADESH BY TRAIN FROM KOLKATA</b></p>
                  <br />
                  <p>If you want to do <strong>Arunachal Tour from Kolkata by Train</strong>, then Adorable Vacation suggests you reach Guwahati Station in Assam. There are various trains that run between Kolkata and Guwahati. The trains are as follows:</p>
                  <br />
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr className="table-danger">
                          <th>TRAIN NAME</th>
                          <th>TRAIN NO</th>
                          <th>SERVICE DAYS</th>
                          <th>DURATION</th>
                          <th>DEP STN</th>
                          <th>ARRV STN</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td data-title="TRAIN NAME">Saraighat Express</td>
                          <td data-title="TRAIN NO">12345</td>
                          <td data-title="SERVICE DAYS">All day</td>
                          <td data-title="DURATION">17 hrs 40 mins</td>
                          <td data-title="DEP STN">Howrah Junction</td>
                          <td data-title="ARRV STN">Guwahati</td>
                        </tr>
                        <tr>
                          <td data-title="TRAIN NAME">Howrah Guwahati Weekly Sf Spl</td>
                          <td data-title="TRAIN NO">25649</td>
                          <td data-title="SERVICE DAYS">All day</td>
                          <td data-title="DURATION">1 day 17 hrs 45 mins</td>
                          <td data-title="DEP STN">Howrah Junction</td>
                          <td data-title="ARRV STN">Guwahati</td>
                        </tr>
                        <tr>
                          <td data-title="TRAIN NAME">HWH GHY SPL</td>
                          <td data-title="TRAIN NO">03057</td>
                          <td data-title="SERVICE DAYS">Tuesday</td>
                          <td data-title="DURATION">16 hrs 20 mins</td>
                          <td data-title="DEP STN">Howrah Junction</td>
                          <td data-title="ARRV STN">Guwahati</td>
                        </tr>
                        <tr>
                          <td data-title="TRAIN NAME">Kamrup Express</td>
                          <td data-title="TRAIN NO">15959</td>
                          <td data-title="SERVICE DAYS">All day</td>
                          <td data-title="DURATION">36 hrs 10 mins</td>
                          <td data-title="DEP STN">Howrah Junction</td>
                          <td data-title="ARRV STN">Dibrugarh</td>
                        </tr>
                        <tr>
                          <td data-title="TRAIN NAME">SDAH AGTL SPL</td>
                          <td data-title="TRAIN NO">03173</td>
                          <td data-title="SERVICE DAYS">Sun, Tue, Thr, Fri</td>
                          <td data-title="DURATION">35 hrs 20 mins</td>
                          <td data-title="DEP STN">Sealdah Junction</td>
                          <td data-title="ARRV STN">Agartala</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br />
                  <p>You can take these trains from Kolkata Sealdah Station or Howrah Station and reach Guwahati. From there, you can proceed to your desired tour destinations, mainly in the western part of Arunachal. Then you can proceed for <strong>East Arunachal Tour</strong> like Zero Valley, Menchuka, and Aalo. After completing Zero Valley trip, you can return via <strong>Lilabari Airport</strong> [IXI] (only a 4-hour drive from Ziro Valley) in North Lakhimpur District of Assam 787051.</p>
                  <p><strong>REACH ARUNACHAL PRADESH BY ROAD</strong> You can travel to a certain point (Guwahati Airport / Guwahati Station) by flight or rail for your <strong>Arunachal Pradesh Tour Package</strong>, but after reaching there, you need to complete your tour by road. So, reach Guwahati Airport or Guwahati Station and start your <strong>Arunachal tour</strong> via Balukpong, which is 138.4 km or a 4-hour 36-minute drive from Guwahati.</p>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> WHAT ARE THE PLACES OF ATTRACTIONS FOR <strong>ARUNACHAL PRADESH PACKAGE TOUR FROM KOLKATA? </strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>WHAT ARE THE PLACES OF ATTRACTIONS FOR <strong>ARUNACHAL PRADESH PACKAGE TOUR FROM KOLKATA? </strong></h4>
                </div>
                <div className="card-body">
                  <p> During your <strong>Arunachal Pradesh Tour Package</strong>, you will visit top attractions like Bhalukpong, Dirang, Dirang Valley, Dirang Monastery, Kameng River, Tippi Orchid Centre, Sella Pass, Jung Falls, Jaswant Garh War Memorial, Tawang Monastery, Urgelling Monastery, Ani Gompa, Sangestar Lake, Bum La Pass, China Border, Bomdila, Sangti Valley, Guwahati, MaaKamakshya Temple, etc.</p>
                  <p><b>Please Note:</b> Each traveller opting for this combined <strong>Arunachal Package Tour with Kaziranga National Park</strong> should carry original Voter Card, Passport, or Driving License (Pan card and Aadhar Card are not valid) along with four copies of Xerox and 3 copies of passport size photographs. For children, carry School ID proof and Birth certificate along with photographs. Else your <strong>Arunachal Tour Permit</strong> may not be issued, and you will not be able to do Elephant and Jeep safari in Kaziranga. So, bringing proper ID proof is a must to travel this extreme part of India as it's adjacent to China.</p>

                  <br /><br />
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr className="table-danger">
                          <th>PLACE NAMES</th>
                          <th>FAMOUS FOR</th>
                          <th>DISTANCE FROM GUWAHATI</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td data-title="PLACE NAMES">Tawang</td>
                          <td data-title="FAMOUS FOR">Sela pass, Tawang Monastery, Madhuri Lake, Nuranang Falls, Taktsang Gompa, Bumla Pass, Bap Teng Kang Waterfalls, Tawang War Memorial, Gorichen Peak, P.T Tso Lake, Jaswant Garh Memorial</td>
                          <td data-title="DIS. FROM GUW.">12 hr 25 min (438.0 km) via NH13</td>
                        </tr>
                        <tr>
                          <td data-title="PLACE NAMES">Dirang</td>
                          <td data-title="FAMOUS FOR">National Research Centre on Yak, Dirang Dzong, Hot Water Spring, Sangti Valley, Kalachakra Gompa, Kameng River</td>
                          <td data-title="DIS. FROM GUW.">6 hr 4 min (198.7 km) via NH6</td>
                        </tr>
                        <tr>
                          <td data-title="PLACE NAMES">Bomdila</td>
                          <td data-title="FAMOUS FOR">Bomdila Monastery, Apple Orchards, Bomdila View Point, Bomdila War Memorial, Eaglenest Wildlife Sanctuary</td>
                          <td data-title="DIS. FROM GUW.">6 hr 53 min (262.2 km) via Arnatulla Kalaktang Rd</td>
                        </tr>
                        <tr>
                          <td data-title="PLACE NAMES">Bhalukpong</td>
                          <td data-title="FAMOUS FOR">Jiya Bhareli River, Sessa Orchid Sanctuary, Tipi Orchidarium, Bhalukpong Fort, Pakhui Wildlife Sanctuary and Tiger Reserve</td>
                          <td data-title="DIS. FROM GUW.">4 hr 41 min (238.5 km) via NH 27</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br />
                </div>
              </div>

              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> DISTANCES TO NOTE FOR  <strong>ARUNACHAL TOUR FROM KOLKATA </strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}> DISTANCES TO NOTE FOR <strong>ARUNACHAL TOUR FROM KOLKATA</strong></h4>
                </div>
                <div className="card-body">
                  <div id="no-more-tables">
                    <table class="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead class="cf">
                        <tr className="table-danger">
                          <th>FROM</th>
                          <th>TO</th>
                          <th>DISTANCE / TRAVEL TIME</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td data-title="FROM">Kolkata</td>
                          <td data-title="TO">Guwahati</td>
                          <td data-title="DISTANCE">23 hr 8 min (980.4 km) via NH12 and NH 27</td>
                        </tr>
                        <tr>
                          <td data-title="FROM">Kolkata</td>
                          <td data-title="TO">Guwahati</td>
                          <td data-title="DISTANCE">1 hr 25 min [Flight]</td>
                        </tr>
                        <tr>
                          <td data-title="FROM">Guwahati</td>
                          <td data-title="TO">Bhalukpong</td>
                          <td data-title="DISTANCE">5 hr 9 min (225.7 km) via NH 15</td>
                        </tr>
                        <tr>
                          <td data-title="FROM">Guwahati</td>
                          <td data-title="TO">Kaziranga</td>
                          <td data-title="DISTANCE">4 hr 16 min (192.8 km) via NH27</td>
                        </tr>
                        <tr>
                          <td data-title="FROM">Bhalukpong</td>
                          <td data-title="TO">Dirang</td>
                          <td data-title="DISTANCE">4 hr 49 min (138.4 km) via Chariduar - Tawang Rd</td>
                        </tr>
                        <tr>
                          <td data-title="FROM">Bhalukpong</td>
                          <td data-title="TO">Tezpur</td>
                          <td data-title="DISTANCE">1 hr 12 min (57.7 km) via Chariduar - Tawang Rd and NH 15</td>
                        </tr>
                        <tr>
                          <td data-title="FROM">Dirang</td>
                          <td data-title="TO">Sangti Valley</td>
                          <td data-title="DISTANCE">31 min (13.5 km) via NH13</td>
                        </tr>
                        <tr>
                          <td data-title="FROM">Dirang</td>
                          <td data-title="TO">Tawang</td>
                          <td data-title="DISTANCE">4 hr 46 min (134.5 km) via NH13</td>
                        </tr>
                        <tr>
                          <td data-title="FROM">Tawang</td>
                          <td data-title="TO">Bomdila</td>
                          <td data-title="DISTANCE">5 hr 57 min (175.7 km) via NH13</td>
                        </tr>
                        <tr>
                          <td data-title="FROM">Bomdila</td>
                          <td data-title="TO">Sangti Valley</td>
                          <td data-title="DISTANCE">1 hr 30 min (49.6 km) via NH13</td>
                        </tr>
                        <tr>
                          <td data-title="FROM">Bomdila</td>
                          <td data-title="TO">Tezpur</td>
                          <td data-title="DISTANCE">4 hr 46 min (153.9 km) via Chariduar - Tawang Rd</td>
                        </tr>
                        <tr>
                          <td data-title="FROM">Bomdila</td>
                          <td data-title="TO">Guwahati</td>
                          <td data-title="DISTANCE">7 hr 16 min (269.9 km) via NH 15</td>
                        </tr>
                        <tr>
                          <td data-title="FROM">Tezpur</td>
                          <td data-title="TO">Kaziranga</td>
                          <td data-title="DISTANCE">1 hr 17 min (50.6 km) via NH715</td>
                        </tr>
                        <tr>
                          <td data-title="FROM">Tezpur</td>
                          <td data-title="TO">Nameri</td>
                          <td data-title="DISTANCE">49 min (36.5 km) via NH 15 and Balipara - Bhalukpung Rd</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* <!--By Itinerary--> */}
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h3 className="content-desktop" style={{ fontSize: "2.0vw" }}>
                        DAY WISE DETAIL <strong style={{ color: 'tomato' }}>ARUNACHAL PRADESH TOUR PACKAGES FROM KOLKATA</strong>
                      </h3>
                      <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>
                        DAY WISE DETAIL <strong>ARUNACHAL PRADESH TOUR PACKAGES FROM KOLKATA</strong>
                      </h3>
                    </div>
                  </div>
                </div>

                <br />
                <div className="row">
                  <div
                    className="accordion"
                    id="accordionPanelsStayOpen"
                  >
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="day1"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseOne"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseOne"
                        >
                          DAY 1 : GUWAHATI TO BHALUKPONG [4 HR 51 MIN | 239.5 KM]
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingOne"
                      >
                        <div className="accordion-body">
                          <p>Welcome to Guwahati Airport for your much-awaited <strong>Arunachal Pradesh Tour Package from Kolkata</strong>. After airport checkout, you will be picked up and driven straight to Bhalukpong (260 km). It is located by the scenic Kameng River. Bhalukpong, which falls in West Kameng District, is 56 km from Tezpur. Bhalukpong, at a height of 700 ft above sea level, is a small town on the Kameng River and lies along the dense forests that form the border of the Pakhui Wildlife Sanctuary. Bhalukpong is a mesmerizing place for wildlife enthusiasts and nature lovers. Scenic beauty, high waterfalls, and the banks of river Kameng surrounded by evergreen forests are a photographer's delight.<b> Overnight at Bhalukpong.</b></p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay1ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay1ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="day2">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseTwo"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseTwo"
                        >
                          DAY 2 : BHALUKPONG TO DIRANG TOUR [4 HR 36 MIN (138.4 KM)]
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseTwo"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingTwo"
                      >
                        <div className="accordion-body">
                          <p>After breakfast, we will drive to Dirang. Enter Arunachal by Bhalukpong check post. Dirang is an ideal getaway in West Kameng district with beautiful apple orchards and sheep breeding farms. The entire place is surrounded by apple and kiwi orchards and the atmosphere is picturesque. Overnight at Hotel in Dirang.</p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay2ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay2ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="day3">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseThree"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          DAY 3 : DIRANG - TAWANG TRANSFER [140 KM / 5 HRS APPROX]
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseThree"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingThree"
                      >
                        <div className="accordion-body">
                          <p> Drive to Tawang crossing Sela Pass (13720 ft.), which runs through a small ridge and along the paradise lake. Stop to enjoy the Sella Pass and Paradise Lake. From the Sela Pass, the road winds down to Jong Village. On the way to Jong, visit Yashwant Garh War Memorial. After lunch at Jong Village, continue to Tawang. On the way, visit Nuranang Fall. Overnight at Tawang.</p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay3ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay3ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="day4">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseFour"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseFour"
                        >
                          DAY 4 : TAWANG - BUMLA PASS – TAWANG
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseFour"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingFour"
                      >
                        <div className="accordion-body">
                          <p> Enjoy a full day excursion to visit the <strong>PT Tso Lake, Bumla Pass (Excluded), Sangetsar Lake</strong>. Sangetsar is popularly known as (Madhuri Lake) after the Bollywood star Madhuri Dixit, located 35 km from Tawang near the China border. The drive to Sangetsar Lake is thrilling, and you can still see the war bunkers used during the Chinese aggression in 1962. Drive back to Tawang. Overnight at Tawang.</p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay4ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay4ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="day5">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseFive"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseFive"
                        >
                          DAY 5 : TAWANG LOCAL SIGHTSEEING
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseFive"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingFive"
                      >
                        <div className="accordion-body">
                          <p>In the morning after breakfast, start for your full day <strong>Tawang sightseeing trip</strong>. Start sightseeing in and around Tawang city. Visit the famous Tawang Monastery, which is 400 years old, accommodates over 500 monks and houses priceless Buddhist texts and scriptures. The Tibetan influence here is unmistakable, with the elaborately painted wooden windows and other motifs. An 8 mt high gilded statue of the Buddha is seated here, as are numerous ancient Thankas or Traditional paintings and manuscripts. Also, visit the Craft Centre and Tawang War Memorial. Evening free time. Overnight at Tawang.</p>
                          <br />
                          {/* <div className="content-desktop">
                            <center><img src={navDay5ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay5ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div> */}
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="day6">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseSix"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseSix"
                        >
                          DAY 6 : TAWANG - BOMDILA [180 KM / 5 HRS APPROX]
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseSix"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingSix"
                      >
                        <div className="accordion-body">
                          <p>This is the 8th day of your <strong>Arunachal Pradesh Tour Package</strong>. Today we will bid adieu to Tawang and start for our next destination. On the way, visit <strong>Tipi Orchidarium</strong>& Drive to Bomdila (8000 ft.). Bomdila is situated at an altitude of over 8500 feet in the northeastern state of Arunachal Pradesh in India. Nestled amongst the mighty mountainous ranges of Himalaya, Bomdila is the headquarters of the West Kameng district of Arunachal Pradesh and offers a panoramic view of the awesome Himalayan terrain. Overnight at Bomdila.</p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay6ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay6ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="day7">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseSeven"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseSeven"
                        >
                          DAY 7 : BOMDILA TO TEZPUR TRANSFER [3 HR 50 MIN | 151 KM]
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseSeven"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingSeven"
                      >
                        <div className="accordion-body">
                          <p>The Bomdila to Tezpur transfer starts with breakfast and checkout from Bomdila, followed by a scenic 5-6 hour drive covering 160 km. A lunch stop is made en route. Arrival in Tezpur is by 4 PM, with hotel check-in and optional evening exploration of local attractions. The day ends with dinner and relaxation at the hotel.</p>
                          <p><b>Overnight stay at Bomdila.</b></p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay7ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay7ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="day8">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseEight"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseEight"
                        >
                          DAY 8 : TEZPUR TO KAZIRANGA [1 HR 10 MIN | 50.6 KM]
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseEight"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingEight"
                      >
                        <div className="accordion-body">
                          <p>After breakfast journey starts for Kamakhya Temple Tour. After offering puja to renowned MaaKamakhya Temple, proceed towards Kaziranga National Park. Covering an area of 430 sq-kms, Kaziranga is inhabited by the world's largest population of one-horned rhinoceroses, as well as many mammals, including tigers, elephants, panthers, and bears, and thousands of birds. In 1985, it was declared a World Heritage Site by UNESCO for its unique natural environment. Overnight Stay at Kaziranga National Park.</p>
                          <br />
                          <div className="content-desktop">
                                                    <center><img src={navDay8ImageURL} alt="..." /></center>
                                                </div>
                                                <div className="content-mobile2">
                                                    <center><img src={navDay8ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="day9">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseNine"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseNine"
                        >
                          DAY 9 : JUNGLE ACTIVITIES AT KAZIRANGA [Safaris Included]
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseNine"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingNine"
                      >
                        <div className="accordion-body">
                          <p>After an early wake-up, we will take you to the riding point for <strong>Kaziranga Elephant Safari</strong> in the Western Range. Watch the famous One-Horn Rhino within the jungle from a stone-throwing distance. After the elephant safari, return to the resort. After lunch, drive to Kaziranga Central Range for the <strong>Kaziranga Jeep Safari</strong>. The central zone of the Kaziranga National Park is the prime zone for ecotourism in the Kaziranga forest. The entry gate to the central zone is situated just about two km from the National Highway passing through the village of Kohora. The <strong>Kaziranga Jungle Safari</strong> offers you spectacular sightings of the Rhinos grazing or wallowing in the large grassland, a herd of elephants with adorable baby elephants, and many other species of the wild flora and fauna. Overnight Stay at Kaziranga National Park.<br /> <strong>NOTE: Park authority may close Kaziranga National Park for tourists without any prior notice. Overnight stay in Kaziranga National Park.</strong></p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay9ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay9ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="day10">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseTen"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseTen"
                        >
                          DAY 10 : TRIP ENDS WITH SWEET MEMORIES
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseTen"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingTen"
                      >
                        <div className="accordion-body">
                          <p>Kaziranga to Guwahati transfer (4:00 hrs of journey, approx). Early morning after breakfast, check out from your hotel, then transfer to Guwahati airport. Your <strong>Arunachal Pradesh Package Tour from Kolkata</strong> ends with sweet memories.</p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay10ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay10ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br /><br />
              {/* <!--insert table inclusion exclusion--> */}
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    {
                      <InclusionExclusion />
                    }
                  </div>
                </div>
              </div>
              {/* <!--end table inclusion exclusion--> */}
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> BEST HOMESTAYS TO KEEP IN MIND FOR <strong>ARUNACHAL TOUR BOOKING FROM KOLKATA </strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>BEST HOMESTAYS TO KEEP IN MIND FOR <strong>ARUNACHAL TOUR BOOKING FROM KOLKATA </strong></h4>
                </div>
                <div className="card-body">
                  <p>Most homestays in Arunachal provide similar services to guests. Don't expect star hotel services, but you can enjoy the true local flavors. Some popular homestays are:</p>
                  <ul><li>•	Mon Paradise - Tawang</li><br />
                    <li>• GakhiKhangzhang - Tawang</li><br />
                    <li>• J.C Homestay - Tawang</li><br />
                    <li>• Tashi Homestay - Tawang</li><br />
                    <li>• Dondrub Homestay - Tawang</li><br />
                    <li>• Bumla Inn - Tawang</li><br />
                    <li>• Anu Homestay - Bomdila</li><br />
                    <li>• TshepalYangzom - Bomdila</li><br />
                    <li>• Dorsum Homestay - Mechuka</li><br />
                    <li>• GTL Homestay - Mechuka</li><br />
                    <li>• Dirang Dzong Homestay - Dirāng</li><br />
                    <li>• Gonpalok Homestay - Dirāng</li><br />
                    <li>• Vamoose Keeduk - Dirāng</li><br />
                    <li>• Awoo Resort - Dirāng</li><br />
                    <li>• Red Berry Riverview Homestay - Shergaon</li><br />
                  </ul>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>BEST FOODS TO TRY DURING <strong>ARUNACHAL TRIP FROM KOLKATA</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>BEST FOODS TO TRY DURING <strong>ARUNACHAL TRIP FROM KOLKATA</strong></h4>
                </div>
                <div className="card-body">
                  <p>Here are some popular dishes to try during Arunachal Tour Package Starting from Guwahati:</p>
                  <ul><li><b>•	Rice -</b> the staple food of Arunachal Pradesh</li>
                    <li><b>• PanchPhoranTarkari -</b> a quick, easy, and tasty curry</li>
                    <li><b>• Laksa Stock -</b> a rich spicy broth with laksa noodles</li>
                    <li><b>• Bamboo Shoot -</b> widely used all over Arunachal</li>
                    <li><b>• PikaPika (a pickle) -</b> made from bamboo shoot, pork fat, and king chilly</li>
                    <li><b>• Lukter -</b> dry meat with chili flakes from King chilly / Bhut Jolokia</li>
                    <li><b>• Pehak -</b> spicy chutney made from fermented soybean and chili</li>
                    <li><b>• Apong -</b> rice beer made with millet</li>
                  </ul>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>VALLEYS MUST VISIT DURING <strong>ARUNACHAL PRADESH TOUR PACKAGE BOOKING FROM KOLKATA</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>VALLEYS MUST VISIT DURING <strong>ARUNACHAL PRADESH TOUR PACKAGE BOOKING FROM KOLKATA</strong></h4>
                </div>
                <div className="card-body">
                  <p>Known as the "Land of The Rising Sun," Arunachal has some of the best valleys with stunning beauty:</p>
                  <ul><li><b>•	Sangti Valley -</b> Located in West Kameng District, famous for black-necked cranes and fruit orchards like apple, oranges, and kiwi.</li>
                    <li><b>• Mechuka Valley -</b> Located in Shi Yomi District at 6000 ft above sea level, near the Indo-China Border, with snow-capped mountains, streams, and green meadows.</li>
                    <li><b>• The Three Valleys -</b> Comprising Pakke Valley, Pappu Valley, and Passa Valley, known for wildflowers, clear streams, and migratory birds.</li>
                  </ul>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div className="card-header custHeaer">
                  <h3 className='content-desktop' style={{ fontSize: "1.5vw" }}>HOW TO GET <strong>ILP [INNER LINE PERMIT]</strong>FOR KOLKATA TO ARUNACHAL TOUR</h3>
                  <h3 className='content-mobile2' style={{ fontSize: "3.5vw" }}>HOW TO GET <strong>ILP [INNER LINE PERMIT]</strong>FOR KOLKATA TO ARUNACHAL TOUR</h3>

                </div>

                <div className="card-body">
                  <p>Due to strategic importance, Arunachal has heavy military presence. To travel, you need an ILP (Inner Line Permit). ILP is required to enter and travel through Arunachal. For Ziro and other protected areas, a special permit called RAP (Restricted Area Permit) is needed, which can be arranged by Adorable Vacation.</p>

                </div>
              </div>

              {/* <!--start of package details--> */}

              {/* <!--end of package details--> */}
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>WHAT TO CARRY FOR <strong>ARUNACHAL TOUR FROM KOLKATA</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>WHAT TO CARRY FOR <strong>ARUNACHAL TOUR FROM KOLKATA</strong></h4>
                </div>
                <div className="card-body">

                  <ul><li><b>• Documents -</b> Original Voter Card, Aadhar card, Driving License, passport size photos, and tour details printout.</li>
                    <li><b>• Sunscreen Lotion & Moisturizers -</b> To protect your skin from sun and cold.</li>
                    <li><b>• Camera with extra batteries -</b> Carry extra batteries as remote locations have limited shops.</li>
                    <li><b>• Clothing -</b> Pack heavy woolen clothes, a heavy jacket, and light clothes for unpredictable weather.</li>
                    <li><b>• Shoes -</b> Waterproof shoes and socks, along with slippers for light walking.</li>
                    <li><b>• Medicines -</b> Essential medicines for headache, motion sickness, vomiting, loose motion, acidity, gas, anti-allergic medicine, and cough syrup.</li>
                  </ul><br />
                  <p>Other necessary items: Camphor, Inhaler, Sunglasses, Tripod, Rain Gear, Power Bank, Rain Coat, Umbrella, Arunachal Tourism Map, extra socks, undergarments, and slippers.</p>
                </div>
              </div>


              {/* <!--start of package details--> */}

              {/* <!--end of package details--> */}
              <br /><br />
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h2>Frequently Ask <span>Questions</span></h2>
                      <h4><strong>Arunachal Pradesh tour from Kolkata</strong> Trip Related Questions</h4>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="accordion" id="accordionPanelsStayOpenExample">
                    <div className="accordion" id="accordionPanelsStayOpenExample">
                      <div className="accordion-item">
                        <h4 className="accordion-header" id="flush-headingThree">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                            Can I get Jain Veg. food during my Arunachal Tour Package from Kolkata?
                          </button>
                        </h4>
                        <div id="flush-collapseThree" className="accordion-collapse collapse show" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body">Yes, but please inform us at the time of booking.</div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h4 className="accordion-header" id="flush-headingFour">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                            Are medical shops available widely across Arunachal Pradesh?
                          </button>
                        </h4>
                        <div id="flush-collapseFour" className="accordion-collapse collapse show" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body">It is better to carry prescribed and general medicines as many remote areas lack proper medical shops.</div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h4 className="accordion-header" id="flush-headingFive">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                            Do I need to carry high altitude medicines while traveling to Arunachal Pradesh Tour Plan from Kolkata?
                          </button>
                        </h4>
                        <div id="flush-collapseFive" className="accordion-collapse collapse show" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body">Generally not required, but consult with your physician to be safe.</div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h4 className="accordion-header" id="flush-headingSix">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                            Can we visit any offbeat destination during our Arunachal Pradesh package Tour from Kolkata?
                          </button>
                        </h4>
                        <div id="flush-collapseSix" className="accordion-collapse collapse show" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body">Yes, offbeat destinations like Ziro, Mechuka Valley, Pasighat, and Aalo can be included in your package.</div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h4 className="accordion-header" id="flush-headingSeven">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
                            How to reach Arunachal Pradesh?
                          </button>
                        </h4>
                        <div id="flush-collapseSeven" className="accordion-collapse collapse show" aria-labelledby="flush-headingSeven" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body">Arunachal Pradesh is about 350 km from Guwahati Airport. You can book a car to reach Itanagar, the capital.</div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h4 className="accordion-header" id="flush-headingEight">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
                            Who is the chief minister of Arunachal Pradesh?
                          </button>
                        </h4>
                        <div id="flush-collapseEight" className="accordion-collapse collapse show" aria-labelledby="flush-headingEight" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body">Shri Pema Khandu is the chief minister.</div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h4 className="accordion-header" id="flush-headingNine">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseNine">
                            How many districts are there in Arunachal Pradesh?
                          </button>
                        </h4>
                        <div id="flush-collapseNine" className="accordion-collapse collapse show" aria-labelledby="flush-headingNine" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body">There are 26 districts as of 2024.</div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h4 className="accordion-header" id="flush-headingTen">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
                            How many Airports are there in Arunachal Pradesh?
                          </button>
                        </h4>
                        <div id="flush-collapseTen" className="accordion-collapse collapse show" aria-labelledby="flush-headingTen" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body">There are a few commercial airports, including Itanagar Airport (Donyi Polo Airport). The Indian Air Force also has several Advance Landing Grounds (ALG) in the state.</div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h4 className="accordion-header" id="flush-headingEleven">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEleven" aria-expanded="false" aria-controls="flush-collapseEleven">
                            Which is the state animal of Arunachal Pradesh?
                          </button>
                        </h4>
                        <div id="flush-collapseEleven" className="accordion-collapse collapse show" aria-labelledby="flush-headingEleven" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body">Gayal, locally known as Mithun (Bos frontalis).</div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h4 className="accordion-header" id="flush-headingTwelve">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwelve" aria-expanded="false" aria-controls="flush-collapseTwelve">
                            Which is the state bird of Arunachal Pradesh?
                          </button>
                        </h4>
                        <div id="flush-collapseTwelve" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwelve" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body">Great hornbill (Buceros bicornis).</div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h4 className="accordion-header" id="flush-headingThirteen">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThirteen" aria-expanded="false" aria-controls="flush-collapseThirteen">
                            Which is the state flower of Arunachal Pradesh?
                          </button>
                        </h4>
                        <div id="flush-collapseThirteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingThirteen" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body">Lady Slipper Orchid (Cypripedioideae).</div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h4 className="accordion-header" id="flush-headingFourteen">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFourteen" aria-expanded="false" aria-controls="flush-collapseFourteen">
                            Which line separates Arunachal Pradesh from Tibet?
                          </button>
                        </h4>
                        <div id="flush-collapseFourteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingFourteen" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body">McMahon Line.</div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h4 className="accordion-header" id="flush-headingFifteen">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFifteen" aria-expanded="false" aria-controls="flush-collapseFifteen">
                            Which is the main river in Arunachal Trip?
                          </button>
                        </h4>
                        <div id="flush-collapseFifteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingFifteen" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body">Brahmaputra River, known as Yarlung Tsangpo in Tibet, Luit/Dilao in Assam, and Siang/Dihang in Arunachal.</div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h4 className="accordion-header" id="flush-headingSixteen">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSixteen" aria-expanded="false" aria-controls="flush-collapseSixteen">
                            Is it safe to travel to Arunachal Pradesh Tour Packages from Kolkata?
                          </button>
                        </h4>
                        <div id="flush-collapseSixteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingSixteen" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body">Arunachal Pradesh is very safe, but carry your identity cards and an Inner Line Permit (ILP).</div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h4 className="accordion-header" id="flush-headingSeventeen">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeventeen" aria-expanded="false" aria-controls="flush-collapseSeventeen">
                            Do I need a permit to travel to Arunachal Pradesh Tour Package?
                          </button>
                        </h4>
                        <div id="flush-collapseSeventeen" className="accordion-collapse collapse show" aria-labelledby="flush-headingSeventeen" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body">Yes, an Inner Line Permit (ILP) is required.</div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h4 className="accordion-header" id="flush-headingEighteen">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEighteen" aria-expanded="false" aria-controls="flush-collapseEighteen">
                            Can I receive snowfall in Tawang?
                          </button>
                        </h4>
                        <div id="flush-collapseEighteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingEighteen" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body">Yes, from the end of November to the end of April.</div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h4 className="accordion-header" id="flush-headingNineteen">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNineteen" aria-expanded="false" aria-controls="flush-collapseNineteen">
                            How many days does it take to complete the Arunachal Tour Booking from Kolkata?
                          </button>
                        </h4>
                        <div id="flush-collapseNineteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingNineteen" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body">A 10-day tour package is enough to cover vital places.</div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h4 className="accordion-header" id="flush-headingTwenty">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwenty" aria-expanded="false" aria-controls="flush-collapseTwenty">
                            How are the accommodations in Arunachal Trip from Kolkata?
                          </button>
                        </h4>
                        <div id="flush-collapseTwenty" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwenty" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body">Accommodations are limited, so pre-booking is recommended. Homestays are available, and travel agents can help secure the best options.</div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h3><b> Arunachal Tour Kolkata<span class="tomato">Holiday Reviews</span></b></h3>
                                            <h4><i>"Your Experience is our Marketing"</i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-cols-1">
                                    <div id="testimonials">
                                        <div className="testimonial-box-container">

                                            {reviewersDataView}

                                        </div>
                                    </div>
                                </div>
                            </div>
            </div>
            <div className="col-md-3">
              <BookingBox />
            </div>
          </div >
        </div >
        <div className="btn-whatsapp-pulse">
          <a style={{ color: 'white' }} href="#" onClick={this.handleClick}>
            <i className="fab fa-whatsapp"></i>
          </a>
        </div>
        <div class="btn-call-pulse">
          <a style={{ color: 'white' }} href="tel:+91-967473-8480">
            <i className="fa fa-phone"></i>
          </a>
        </div>
      </>
    );
  }
}
export default ArunachalTourKolkata_9N_10D_Package;
