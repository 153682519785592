import React, { Component } from 'react';
import MainHeader from '../../../components/common/MainHeader';
import FooterDesktop from '../../../components/common/FooterDesktop_bhutan';
import FIFO_6N_7D_Package from '../../../components/package/Bhutan/FIFO_6N_7D_Package';

class FIFO_6N_7D_Page extends Component {
    constructor() {
        super();
        this.state = {
            HotPackagesData: [],
            ReviewersData: [],
        }
    }

    componentDidMount() {
        window.scroll(0, 0);
        const hotPackageDataList = [
            {
                Title: "Blissful Bhutan",
                ImageName: "6N-7D-Bhutan-Tour1.webp",
                AllternateText: "...",
                Description: "2N Thimphu | 1N Punakha | 1N Paro...more | Free Sim Card | E-Permit",
                PricePerNight: "29,900/-",
                NoOfTours: 43,
                NoOfDeparture: 38,
                SDFInclude:"SDF Inc.",
                MutedText: "*Per head 6 Guests",
                book:"Book Now"
            },
            {
                Title: "Untouched Bhutan",
                ImageName: "7N-8D-Bhutan-Tour1.webp",
                AllternateText: "...",
                Description: "3N Thimphu | 2N Paro...more | Free Sim Card | E-Permit",
                PricePerNight: "35,100/-",
                NoOfTours: 47,
                NoOfDeparture: 40,
                SDFInclude:"SDF Inc.",
                MutedText: "*Per head 6 Guests",
                book:"Book Now"
            },
            {
                Title: "Explore Bhutan",
                ImageName: "8N-9D-Bhutan-Tour.webp",
                AllternateText: "...",
                Description: "2N Thimphu | 2N Punakha | 2N Paro | Free Sim Card | E-Permit",
                PricePerNight: "40,200/-",
                NoOfTours: 44,
                NoOfDeparture: 37,
                SDFInclude:"SDF Inc.",
                MutedText: "*Per head 6 Guests",
                book:"Book Now"
            },
            {
                Title: "Excape Bhutan",
                ImageName: "10N-11D-Bhutan-Tour.webp",
                AllternateText: "...",
                Description: "2N Thimphu | 2N Punakha | 3N Paro...more | Free Sim Card | E-Permit",
                PricePerNight: "48,200/-",
                NoOfTours: 46,
                NoOfDeparture: 39,
                SDFInclude:"SDF Inc.",
                MutedText: "*Per head 6 Guests",
                book:"Book Now"
            }

        ];

        const reviewersDataList = [
            {
                Name: "Ritwik Sarkar",
                ImageName: "ritik-sarker.webp",
                AllternateText: "...",
                Location: "@Kolkata",
                Comment: "Our vacation at Bhutan was booked through Rewa Adorable Vacation LLP. All the logistics like hotel booking, transport, guide etc. were managed quite professionally by Adorable Vacation. It was really a luxurious arrangement at an affordable rate. Our trip covered Thimphu, Punakha and Paro in Bhutan and Jaldapara in West Bengal. The locations of the hotels at all the places were simply superb, foods and hospitality at the hotels were excellent. The driver and guide were very much polite and experienced. Thanks Adorable Vacations for making our vacation very comfortable and helping us to create a lifetime memory of the picturesque Bhutan.",
            },
            {
                Name: "Rikhiya Basu",
                ImageName: "rikiya-basu.webp",
                AllternateText: "...",
                Location: "@Kolkata",
                Comment: "Had the most comfortable stay in Bhutan, thanks to adorable vacation LLp. Our guide Mr. Gyeltshen and our driver were extremely helpful and nice. They were always ready to help us out with everything. I would highly recommend Adorable Vacation LLP to everyone and would love to book our next trip with them too. Thank you team for taking care of us.",
            },
            {
                Name: "Kakoli Roy",
                ImageName: "Kakoli-Roy.webp",
                AllternateText: "...",
                Location: "@Kolkata",
                Comment: "Heartiest congratulations to Adorable Vacation LLP. This was my second trip with them. First being Nepal. My recent family trip to Bhutan with with them was simply amazing. All arrangements... vehicle, transport, hotel booking, food, guide and all other services were excellent and our satisfaction. Thank to Soumalya, Anushka and their ever helping team. Looking forward for next trip with them. Wish you all very best. God bless you all.",
            },
            {
                Name: "Pallab-Banerjee",
                ImageName: "pallab-banerjee.webp",
                AllternateText: "...",
                Location: "@Kolkata",
                Comment: "Excellent service provided during my trip to Bhutan. The entire team in Kolkata and Bhutan is commendable. Care extended for senior citizens is very good. I strongly recommend to tie up with Adorable Vacation for memorable excursion. Great service.",
            },
        ]

        this.setState({
            HotPackagesData: hotPackageDataList,
            ReviewersData: reviewersDataList
        })
    }

    render() {
        return (
            <>
                <MainHeader />
                <FIFO_6N_7D_Package 
                    HotPackagesData={this.state.HotPackagesData}
                    ReviewersData={this.state.ReviewersData}
                />
                <FooterDesktop />
            </>
        );
    }
}

export default FIFO_6N_7D_Page;