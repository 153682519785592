import React, { Component } from 'react';
import BookingBox from '../BookingBox';
import Slider from "react-slick";
import ApiURL from "../../../api/ApiURL";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InclusionExclusion from './InclusionExclusion_kashmir_chennai';

class Kashmir_7N_8D_Package_Tour_From_Chennai extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customActiveTab: 'tab1',
            daywiseActiveTab: 'day1'
        }
    }

    handleCustomTabClick = (tab) => {
        this.setState({
            customActiveTab: tab,
        });
    };

    handleDayWiseTabClick = (tab) => {
        this.setState({
            daywiseActiveTab: tab,
        });
    };
    handleClick = () => {
        const currentUrl = window.location.href;
        const pageTitle = document.title;
        const message = `Hi, Please send about the Tour Package details "${pageTitle}"`;

        const whatsappLink = `https://api.whatsapp.com/send?phone=+919147061467&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
        window.open(whatsappLink, '_blank');
    };

    render() {
        const { customActiveTab, daywiseActiveTab } = this.state;
        const packageTopBannerImageURL = ApiURL.Kashmir_Tour_Package_from_Chennai + "/Kashmir-Package-Tour-from-Chennai.webp";
        const packageTopBanner2ImageURL = ApiURL.Kashmir_Tour_Package_from_Chennai + "/Kashmir-Tour-Packages-from-Chennai.webp";
        const sightseen1ImageURL = ApiURL.Kashmir_Tour_Package_from_Chennai + "/Kashmir-Packages-Tour-Cost.webp";
        const sightseen2ImageURL = ApiURL.Kashmir_Tour_Package_from_Chennai + "/Kashmir-Package-Tour-Operator-in-Chennai.webp";
        const sightseen3ImageURL = ApiURL.Kashmir_Tour_Package_from_Chennai + "/Kashmir-Tours-from-Chennai.webp";
        const sightseen4ImageURL = ApiURL.Kashmir_Tour_Package_from_Chennai + "/Chennai-to-Kashmir-Package-Tour.webp";
        const sightseen5ImageURL = ApiURL.Kashmir_Tour_Package_from_Chennai + "/Chennai-to-Kashmir-Package-Tour-Cost.webp";
        const sightseen6ImageURL = ApiURL.Kashmir_Tour_Package_from_Chennai + "/Chennai-to-Kashmir-Package-Tours.webp";
        const sightseen7ImageURL = ApiURL.Kashmir_Tour_Package_from_Chennai + "/Chennai-to-Kashmir-Tour-Package-Booking.webp";
        const sightseen8ImageURL = ApiURL.Kashmir_Tour_Package_from_Chennai + "/Chennai-to-Kashmir-Trip.webp";
        const sightseen9ImageURL = ApiURL.Kashmir_Tour_Package_from_Chennai + "/Kashmir-from-Surat.webp";
        const sightseen10ImageURL = ApiURL.Kashmir_Tour_Package_from_Chennai + "/Kashmir-Packages-from-Surat.webp";
        const sightseen11ImageURL = ApiURL.Kashmir_Tour_Package_from_Chennai + "/Kashmir-Package-Tour.webp";
        const sightseen12ImageURL = ApiURL.Kashmir_Tour_Package_from_Chennai + "/Kashmir-Package-Tour-Cost.webp";
        //day wise itenaryy
        const navDay1ImageURL = ApiURL.Kashmir_Tour_Package_from_Chennai + "/7N-8D-Kashmir-Tulip-Festival-Package-Tour.webp";
        const navDay2ImageURL = ApiURL.Kashmir_Tour_Package_from_Chennai + "/2024-Tulip-Garden-Kashmir-Package.webp";
        const navDay3ImageURL = ApiURL.Kashmir_Tour_Package_from_Chennai + "/Customized-Kashmir-Tour.webp";
        const navDay4ImageURL = ApiURL.Kashmir_Tour_Package_from_Chennai + "/Tulip-Festival-in-Kashmir.webp";
        const navDay5ImageURL = ApiURL.Kashmir_Tour_Package_from_Chennai + "/Srinagar-Tulip-Garden-Tour.webp";
        const navDay6ImageURL = ApiURL.Kashmir_Tour_Package_from_Chennai + "/Tulip-Garden-Tour-Package.webp";
        const navDay7ImageURL = ApiURL.Kashmir_Tour_Package_from_Chennai + "/Book-Kashmir-Tulip-Trip.webp";
        const navDay8ImageURL = ApiURL.Kashmir_Tour_Package_from_Chennai + "/Tulip-Festival-Special-2024.webp";
        const Topbanner0ImageURL = ApiURL.Kashmir_Tour_Package_from_Chennai + "/Kashmir-Houseboat-Packages.webp";
        const Topbanner1ImageURL = ApiURL.Kashmir_Tour_Package_from_Chennai + "/Kashmir-Adventure-Tour-Plan.webp";
        const Topbanner2ImageURL = ApiURL.Kashmir_Tour_Package_from_Chennai + "/Kashmir-Gondola-Package-Tour.webp";
        const Topbanner3ImageURL = ApiURL.Kashmir_Tour_Package_from_Chennai + "/Kashmir-Trekking-Packages.webp";
        const Topbanner4ImageURL = ApiURL.Kashmir_Tour_Package_from_Chennai + "/Kashmir-White-River-Rafting-Plan.webp";
        const brandedTourackageImage_1_URL = ApiURL.Kashmir_Tour_Package_from_Chennai + "/The-Khyber-Himalayan-Resort-&-Spa.webp";
        const brandedTourackageImage_2_URL = ApiURL.Kashmir_Tour_Package_from_Chennai + "/The-LaLiT-Grand-Palace-Srinagar-Hotel.webp";
        const TrendingPackagesDataList = this.props.TrendingPackagesData;
        const hotPackagesView = TrendingPackagesDataList.length > 0 ?
            (
                TrendingPackagesDataList.map((dataItem, i) => {
                    const hotelImgURL = ApiURL.Kashmir_Tour_Package_from_Chennai + "/" + dataItem.ImageName;
                    return (<div className="col-md-12 p-0" key={i}>
                        <div className="card h-100">
                            <img src={hotelImgURL} className="card-img-top" alt={dataItem.AllternateText} />
                            <div className="card-body">
                                <h6 className="card-title">{dataItem.Title} <span> &#8377; {dataItem.PricePerNight}</span></h6>
                                <p className="card-text">
                                    <i className="fa fa-building tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-utensils tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-car-side tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-male tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-camera tomato"></i>
                                </p>
                                <p className="card-text"><span>{dataItem.Description}</span></p>
                                <p>
                                    <span><b className="tomato">{dataItem.NoOfTours} </b>Tours</span> | &nbsp;
                                    <span><b className="tomato">{dataItem.NoOfDeparture} </b>Departures</span> | &nbsp;
                                    <span><b className="sdf-blue"> {dataItem.Include}</b></span>
                                </p>
                            </div>
                            <div className="card-footer">
                                <small className="text-muted"><strong className="tomato">{dataItem.MutedText}</strong></small>
                                <a href="#" className="btn btn-success btn-sm float-end" onClick={this.handleClick}>
                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                </a>
                            </div>
                        </div>
                    </div>)
                })
            ) : (<div></div>)


        const settings = {
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const reviewersDataList = this.props.ReviewersData;
        const reviewersDataView = reviewersDataList.map((dataItem, i) => {
            const reviewerImageURL = ApiURL.Kashmir_Tour_Package_from_Chennai + "/" + dataItem.ImageName;
            return (
                <div className="testimonial-box" key={i}>
                    <div className="box-top">
                        <div className="profile">
                            <div className="profile-img">
                                <img src={reviewerImageURL} alt="..." />
                            </div>
                            <div className="name-user">
                                <strong>{dataItem.Name}</strong>
                                <span>{dataItem.Location}</span>
                            </div>
                        </div>
                        <div className="reviews">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </div>
                    </div>
                    <div className="client-comment">
                        <p>{dataItem.Comment}</p>
                    </div>
                </div>
            )
        })

        return (
            <>

                <div className="container-fluid">
                    <div className="row">
                        <img src={packageTopBannerImageURL} className="mobile-image2" alt="..." />
                    </div>
                </div>
                <div className="container-fluid custom-container py-3">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="container-fluid custom-container py-1">

                                        <h1 className="content-desktop" style={{ fontSize: "2vw" }}><center><strong>BEST PRICE FOR A KASHMIR TOUR PACKAGE FROM CHENNAI </strong></center></h1>

                                        <h3 className="content-mobile" style={{ fontSize: "4vw" }}><center><strong>BEST PRICE FOR A KASHMIR TOUR PACKAGE FROM CHENNAI </strong></center>
                                            <hr />
                                        </h3>
                                        <div className="row">
                                            <img src={packageTopBanner2ImageURL} className="mobile-image" alt="..." />
                                        </div>
                                        <hr />
                                        <div className="d-flex flex-column flex-sm-row align-items-sm-center px-2">
                                            <div className="font-lg">
                                                3 Destination:{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Srinagar (3N)
                                                </a>
                                                &nbsp;{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Gulmarg (1N)
                                                </a>{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Pahalgam (3N)
                                                </a>
                                            </div>
                                            <div className="reviews mx-5">
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                            </div>
                                        </div>
                                        <p className="p-2">
                                            If you are looking for a memorable <strong>Kashmir Package Tour From Chennai</strong>, you have come to the right place!
                                        </p>
                                        <hr />
                                        <br />
                                    </div>
                                    <h2 className="content-desktop p-2" style={{ fontSize: "1.6vw" }}><b>DISCOVER AMAZING KASHMIR TOUR PACKAGES FROM CHENNAI</b><a href='tel:9147061467' class="tomato"> TO BOOK A KASHMIR TO CHENNAI TOUR PACKAGE ☎ CALL Mr. Soumya +91- 91470-61467</a></h2>
                                    <h2 className="content-mobile p-4" style={{ fontSize: "4.5vw" }}><b>DISCOVER AMAZING KASHMIR TOUR PACKAGES FROM CHENNAI</b><a href='tel:9147061467' class="tomato"> TO BOOK A KASHMIR TO CHENNAI TOUR PACKAGE ☎ CALL Mr. Soumya +91- 91470-61467</a></h2>
                                    <br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> Explore the Enchanting Beauty of Kashmir from Chennai with Adorable Vacation</h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}> Explore the Enchanting Beauty of Kashmir from Chennai with Adorable Vacation</h4>
                                        </div>
                                        <div className="card-body">
                                            <p><b>Discover the Magic of Kashmir </b>
                                                Embark on an unforgettable journey to the breath-taking landscapes of <strong>Kashmir Package Tour Operator from Chennai with Adorable Vacation</strong>. Our <strong>Kashmir tour packages from Chennai</strong> are designed to offer you a seamless blend of stunning natural beauty and rich cultural experiences. From the serene waters of Dal Lake to the majestic peaks of Gulmarg, our carefully crafted itineraries ensure you capture the essence of this enchanting region.
                                            </p>
                                            <p><b>Experience Comfort and Convenience</b>
                                                Traveling from <strong>Chennai to Kashmir Tour Packages</strong> has never been easier. We provide comfortable and convenient travel options, including well-coordinated flights and accommodations that suit every budget. <strong>Chennai to Kashmir Tour Package Booking</strong> Our packages include everything you need for a hassle-free trip, from airport transfers to luxurious stays in houseboats or hotels.
                                            </p>
                                            <p><b>Explore Top Destinations</b>
                                                Our <strong>Kashmir tour packages</strong> take you to must-see destinations like Srinagar, known for its picturesque gardens and houseboats; Gulmarg, perfect for skiing and adventure; and Pahalgam, ideal for trekking and relaxation.<strong>Kashmir Trip Booking from Chennai</strong> Enjoy the unique charm of each location, from the lush greenery of Sonmarg to the historical beauty of Mughal Gardens.
                                            </p>
                                            <p><b>Indulge in Local Delights</b>
                                                Immerse yourself in the flavours of <strong>Kashmir Tours from Chennai</strong> cuisine with our curated food experiences.<strong>Kashmir Trip from Chennai</strong> Savour traditional dishes such as Rogan Josh, Dum Aloo, and the famous Kashmiri Kahwa tea. Kashmir Tour Itinerary Our packages include opportunities to explore local markets and taste authentic delicacies.
                                            </p>
                                            <p><b>Customizable Itineraries for Your Needs </b>
                                                At Adorable Vacation, we understand that each traveler has unique preferences. Our <strong>Kashmir tour packages from Chennai</strong> can be customized to fit your interests and schedule. Whether you want a romantic getaway, a family vacation, or an adventurous trip, we can tailor the itinerary to make your dream holiday a reality.
                                            </p>
                                            <p><b>Book Your Adventure Today </b>
                                                Ready to explore the beauty of Kashmir? Contact Adorable Vacation to book your <strong>Kashmir tour package from Chennai</strong>. Let us help you create lasting memories in one of the most stunning destinations in the world.
                                            </p>
                                            <p></p>
                                            <a href='/kashmir-offbeat-package-tour-with-baisaran-valley-doodhpathri-yusmarg' target='_blank'><b className="tomato">Kashmir Package Tour with Doodhpathri Yusmarg</b></a>, <a href='/kashmir-offbeat-package-tour-with-gurez-valley' target='_blank'><b className="tomato">Kashmir Package Tour with Gurez Valley</b></a>,
                                            <a href='/kashmir-offbeat-package-tour-with-sinthan-top-aru-valley-betab-valley-chandanbari-pahalgam-sonmarg' target='_blank'><b className="tomato">Kashmir Offbeat Package Tour with Sinthan Top</b></a>,
                                            <a href='/kashmir-package-tour-with-vaishno-devi-katra-pahalgam-gulmarg-srinagar' target='_blank'><b className="tomato">Kashmir Package Tour with Vaishno Devi</b></a>,
                                            <a href='/kashmir-tulip-garden-festival-package-tour' target='_blank'><b className="tomato">Kashmir Package Tour with Tulip Package</b></a>,
                                            <a href='/kashmir-package-tour-from-kolkata-with-srinagar-gulmarg-pahalgam-sonmarg' target='_blank'><b className="tomato">Kashmir Package Tour from Kolkata </b></a>,
                                            <a href='/kashmir-package-tour-from-surat' target='_blank'><b className="tomato">Kashmir Package Tour from Surat </b></a>
                                            <br /><br />
                                            <table className="image-table">
                                                <tbody>
                                                    <tr>
                                                        <td className="image-cell">
                                                            <img src={sightseen1ImageURL} alt="kashmir tour packages" />
                                                            <img src={sightseen2ImageURL} alt="kashmir trip from srinagar airport" />
                                                            <img src={sightseen3ImageURL} alt="kashmir package tour cost from srinagar airport" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <br />
                                        </div>
                                    </div>
                                    <br /> <br /> <br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>TOP PLACES TO EXPLORE DURING YOUR CHENNAI TO KASHMIR PACKAGE TOUR</strong></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>TOP PLACES TO EXPLORE DURING YOUR CHENNAI TO KASHMIR PACKAGE TOUR</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <p><b>1. Srinagar </b>
                                                Srinagar, the summer capital of Jammu and Kashmir, is renowned for its picturesque Dal Lake and vibrant Mughal Gardens. Enjoy a serene Shikara ride on Dal Lake, explore the lush gardens like Shalimar Bagh and Nishat Bagh, and experience the charm of staying on a traditional houseboat.
                                            </p>
                                            <p><b>2. Gulmarg </b>
                                                Known as the "Meadow of Flowers," Gulmarg is a haven for adventure enthusiasts and nature lovers. It's famous for its ski slopes and the Gulmarg Gondola, one of the highest cable cars in the world. The breathtaking views of snow-capped peaks and verdant meadows make Gulmarg a must-visit destination.
                                            </p>
                                            <p><b>3. Pahalgam</b>
                                                Often referred to as the "Valley of Shepherds," Pahalgam offers a tranquil retreat with its lush landscapes and the Lidder River. Explore the scenic beauty of Betaab Valley and Aru Valley, and indulge in outdoor activities like trekking and horseback riding.
                                            </p>

                                            <p><b>4. Sonmarg</b>
                                                Sonmarg, or the "Meadow of Gold," is famous for its stunning alpine scenery and glistening rivers. The area is perfect for nature walks, pony rides, and enjoying the picturesque views of snow-clad peaks and meadows.
                                            </p>

                                            <p><b>5. Betaab Valley</b>
                                                Named after the Bollywood movie "Betaab," this valley is surrounded by dense forests and meadows. It’s a serene spot for day trips and picnics, offering a peaceful escape into nature.
                                            </p>

                                            <p><b>6. Mughal Gardens</b>
                                                The Mughal Gardens of Srinagar, including Shalimar Bagh, Nishat Bagh, and Chashme Shahi, showcase the grandeur of Mughal architecture and landscape design. These gardens are known for their terraced lawns, vibrant flower beds, and stunning water features.
                                            </p>

                                            <p><b>7. Yusmarg</b>
                                                Yusmarg is a lesser-known gem that offers a tranquil setting with its lush green meadows and panoramic views of the surrounding mountains. It's an ideal spot for relaxation and enjoying the peaceful surroundings.
                                            </p>

                                            <p><b>8. Achabal Gardens</b>
                                                These historic gardens, created by the Mughal emperors, are known for their beautiful terraced layouts and cascading fountains. They provide a glimpse into the opulent gardens of the Mughal era.
                                            </p>

                                            <br /><br />


                                        </div>
                                    </div>
                                    <br /> <br /> <br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>DURATION OF KASHMIR TOUR PACKAGE FROM CHENNAI : 7N | 8D</strong></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>DURATION OF KASHMIR TOUR PACKAGE FROM CHENNAI : 7N | 8D</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <p><strong>Itinerary for Kashmir Package Tour From Chennai</strong> : Srinagar Houseboat 1N | Gulmarg 1N | Pahalgam 2N | Srinagar 3N </p>
                                            <p><b>Pickup & Drop-off Point for the Bangalore to Kashmir Package Booking:</b> Sheikh Ul Alam International Airport, Srinagar [SXR]</p>
                                            <br /><br />
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <tbody>
                                                        <tr>
                                                            <td><strong>Pickup & Drop Location</strong></td>
                                                            <td>Sheikh Ul Alam International Airport, Srinagar [SXR]</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Chennai to Kashmir Package Duration</strong></td>
                                                            <td>07 Nights | 08 Days</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Chennai to Kashmir Package Tour Cost </strong></td>
                                                            <td>Rs. 28,100/Based on 8 Pax</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Srinagar</strong></td>
                                                            <td>1,585 m | Coordinates - 34.0837° N, 74.7973° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Gulmarg</strong></td>
                                                            <td>2,650 m | Coordinates - 34.0484° N, 74.3805° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Sonmarg</strong></td>
                                                            <td>2,730 m | Coordinates - 34.3032° N, 75.2931° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Pahalgam</strong></td>
                                                            <td>2,740 m | Coordinates - 34.0161° N, 75.3150° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Betaab Valley</strong></td>
                                                            <td>2,393 m | Coordinates - 34.0541° N, 75.3639° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Aru Valley</strong></td>
                                                            <td>2,414 m | Coordinates - 34.0922° N, 75.2632° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Chandanwadi</strong></td>
                                                            <td>3,288 m | Coordinates - 34.2122° N, 73.9295° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Doodhpathri</strong></td>
                                                            <td>2,730 m | Coordinates - 33.8509° N, 74.5630° E</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <br /><br /><br />
                                            <table className="image-table">
                                                <tbody>
                                                    <tr>
                                                        <td className="image-cell">
                                                            <img src={sightseen4ImageURL} alt="kashmir package tour from srinagar" />
                                                            <img src={sightseen5ImageURL} alt="kashmir package price from srinagar airport" />
                                                            <img src={sightseen6ImageURL} alt="kashmir packages from srinagar airport" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <br />

                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    {/* start short itinerary */}

                                    <div className="card">
                                        <div className="card-body">
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr className="table-danger">
                                                        <th>DAY-BY-DAY SUMMARY FOR KASHMIR TOUR PACKAGES FROM CHENNAI</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingOne"
                                                        >
                                                            <a href="#day1" style={{ color: 'black' }}><b>DAY 1 → </b>
                                                                <span>
                                                                    ARRIVE IN SRINAGAR, ENJOY LOCAL SIGHTSEEING
                                                                </span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingTwo"
                                                        >
                                                            <a href="#day2" style={{ color: 'black' }}><b>DAY 2 → </b>
                                                                <span>TAKE A FULL-DAY TRIP TO GULMARG </span>
                                                            </a>

                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingThree"
                                                        >
                                                            <a href="#day3" style={{ color: 'black' }}><b>DAY 3 → </b>
                                                                <span>TRAVEL FROM GULMARG TO PAHALGAM AND STAY </span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingFour"
                                                        >
                                                            <a href="#day4" style={{ color: 'black' }}><b>DAY 4 → </b>
                                                                <span>EXPLORE PAHALGAM, VISITING BETAAB VALLEY AND MORE</span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingFive"
                                                        >
                                                            <a href="#day5" style={{ color: 'black' }}><b>DAY 5 → </b>
                                                                <span>TRANSFER BACK TO SRINAGAR</span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingSix"
                                                        >
                                                            <a href="#day6" style={{ color: 'black' }}><b>DAY 6 → </b>
                                                                <span>ENJOY A DAY TRIP TO DOODHPATHRI</span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingSeven"
                                                        >
                                                            <a href="#day7" style={{ color: 'black' }}><b>DAY 7 → </b>
                                                                <span>TAKE A DAY TRIP TO SONMARG </span>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingEight"
                                                        >
                                                            <a href="#day8" style={{ color: 'black' }}><b>DAY 8 → </b>
                                                                <span>DEPART FROM SRINAGAR AIRPORT </span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    {/* <!--end Short description--> */}
                                    <br /><br /><br />

                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>THINGS TO DO DURING YOUR KASHMIR TOUR FROM CHENNAI</b></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>THINGS TO DO DURING YOUR KASHMIR TOUR FROM CHENNAI</b></h4>
                                        </div>
                                        <div className="card-body">
                                            <p><strong>Kashmir Tour Package Booking from Chennai</strong> is not just about stunning views; it’s also packed with exciting activities. Here’s a guide to making the most of your <strong>Customized Kashmir Tour</strong>:</p>
                                            <p><b>1. Shikara Rides</b>:
                                                A must-do in Kashmir is a Shikara ride on Dal Lake in Srinagar. Relax on a traditional wooden boat as you float along the calm waters and enjoy the scenic beauty.
                                            </p>
                                            <p><b>2. Houseboat Stay:</b>
                                                For a truly unique experience, spend a night or two on a houseboat in Dal Lake. Wake up to beautiful lake views and enjoy the peaceful ambiance.
                                            </p>

                                            <p><b>3. Gondola Cable Car Ride:</b>
                                                If you love adventure, don’t miss the Gondola ride in Gulmarg. It’s divided into three sections and offers breathtaking views of the mountains and landscapes.
                                            </p>

                                            <p><b>4. Adventure Activities:</b>
                                                For thrill-seekers, Kashmir has plenty to offer. Go paragliding, trekking, or mountain biking. You can also enjoy river rafting in Lidder River (Pahalgam) or Sindh River (Sonamarg). In winter, try skiing or snowboarding in Gulmarg and Pahalgam.
                                            </p>

                                            <p><b>5. Horse/Pony Riding:</b>
                                                Explore the scenic beauty of Gulmarg and Pahalgam on horseback. Whether you prefer a gentle ride or a more adventurous journey through Baisaran Valley, horseback riding provides a unique view of the area.
                                            </p>

                                            <p><b>6. Shopping:</b>
                                                Kashmir is known for its handicrafts, fresh fruits like apples and walnuts, and spices such as saffron. Pick up some traditionally embroidered kurtas or warm Pashmina shawls as souvenirs.
                                            </p>

                                            <p><b>7. Try Local Cuisine:</b>
                                                Taste the rich flavors of Kashmiri food. Enjoy dishes like Paneer Chaman, Kashmiri Saag, NadruYakhni, Chicken Pulao, and Dum Olav. The local cuisine is sure to enhance your travel experience.
                                            </p>
                                            <p>Each of these activities will help make your Kashmir trip from Chennai a memorable adventure.</p>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>KASHMIR TOUR PACKAGE COST FROM CHENNAI</strong></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>KASHMIR TOUR PACKAGE COST FROM CHENNAI</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <thead className="cf">
                                                        <tr className="table-danger">
                                                            <th>TOUR DURATION</th>
                                                            <th>2 PAX</th>
                                                            <th>4 PAX</th>
                                                            <th>6 PAX</th>
                                                            <th>8 PAX</th>
                                                            <th>BOOK NOW</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td data-title="TOUR DURATION">4N/5D</td>
                                                            <td data-title="2 ADULTS">₹ 20,500/-</td>
                                                            <td data-title="4 ADULTS">₹ 18,300/-</td>
                                                            <td data-title="6 ADULTS">₹ 16,600/-</td>
                                                            <td data-title="8 ADULTS">₹ 16,400/-</td>
                                                            <td data-title="Book Now"><a href="#" class="btn btn-success" onClick={this.handleClick}>Book Now</a></td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="TOUR DURATION">5N/6D</td>
                                                            <td data-title="2 ADULTS">₹ 24,700/-</td>
                                                            <td data-title="4 ADULTS">₹ 22,000/-</td>
                                                            <td data-title="6 ADULTS">₹ 19,800/-</td>
                                                            <td data-title="8 ADULTS">₹ 20,800/-</td>
                                                            <td data-title="Book Now"><a href="#" class="btn btn-success" onClick={this.handleClick}>Book Now</a></td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="TOUR DURATION">6N/7D</td>
                                                            <td data-title="2 ADULTS">₹ 30,900/-</td>
                                                            <td data-title="4 ADULTS">₹ 27,400/-</td>
                                                            <td data-title="6 ADULTS">₹ 24,700/-</td>
                                                            <td data-title="8 ADULTS">₹ 24,500/-</td>
                                                            <td data-title="Book Now"><a href="#" class="btn btn-success" onClick={this.handleClick}>Book Now</a></td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="TOUR DURATION">7N/8D</td>
                                                            <td data-title="2 ADULTS">₹ 35,600/-</td>
                                                            <td data-title="4 ADULTS">₹ 30,800/-</td>
                                                            <td data-title="6 ADULTS">₹ 28,300/-</td>
                                                            <td data-title="8 ADULTS">₹ 28,100/-</td>
                                                            <td data-title="Book Now"><a href="#" class="btn btn-success" onClick={this.handleClick}>Book Now</a></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <br /><br />

                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>EXPLORING VIBRANT LOCAL MARKETS IN CHENNAI TO KASHMIR TRIP : A DELIGHT FOR CHENNAI TRAVELERS</b></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>EXPLORING VIBRANT LOCAL MARKETS IN CHENNAI TO KASHMIR TRIP : A DELIGHT FOR CHENNAI TRAVELERS</b></h4>
                                        </div>
                                        <div className="card-body">
                                            <p>Kashmir’s local markets are a treasure trove of vibrant colours, unique crafts, and delicious flavours. For travellers <strong>Kashmir Package Tour Itinerary from Chennai</strong> these bustling bazaars offer an unforgettable shopping experience and a deep dive into the region’s rich culture. Here’s a guide to some of the must-visit markets during your <strong>Kashmir tour</strong>:</p>
                                            <p><b>1. Lal Chowk, Srinagar:</b>
                                                Lal Chowk is Srinagar’s most famous market, offering everything from traditional Kashmiri handicrafts to modern goods. Wander through its lively streets to find exquisite Pashmina shawls, vibrant Kashmiri carpets, and intricately embroidered garments. It’s a great place to pick up souvenirs and experience the local atmosphere.
                                            </p>

                                            <p><b>2. Polo View Market, Srinagar:</b>
                                                Located near Lal Chowk, Polo View Market is known for its diverse range of products. You can shop for beautiful woolens, including cozy shawls and blankets, as well as unique jewelry and traditional artifacts. The market is also a good spot to sample local snacks and teas.
                                            </p>

                                            <p><b>3. Jamia Masjid Market, Srinagar:</b>
                                                Adjacent to the historic Jamia Masjid, this market is perfect for those interested in traditional crafts and antiques. Explore stalls selling hand-carved wooden items, copperware, and aromatic spices. The market’s setting adds to the charm, making it a cultural highlight of your trip.
                                            </p>

                                            <p><b>4. Pahalgam Market:</b>
                                                In Pahalgam, the local market offers a more relaxed shopping experience. Here, you can buy local handicrafts, fresh produce, and beautiful woolen products. Don’t miss out on local jams and pickles, which make excellent gifts and souvenirs.
                                            </p>

                                            <p><b>5. Gulmarg Market:</b>
                                                Gulmarg’s market is smaller but offers a range of unique items, including woolen wear and local crafts. It’s also a great place to pick up items related to the skiing and adventure sports available in the area.
                                            </p>

                                            <p><b>6. Anantnag Market:</b>
                                                For a taste of authentic Kashmiri life, visit Anantnag Market. Known for its lively atmosphere, the market features a wide array of local products, including fresh fruits, vegetables, and traditional sweets. It’s an excellent spot to interact with locals and get a sense of the region’s daily life.
                                            </p>
                                            <p>Exploring these vibrant markets will give Chennai travellers a rich and immersive experience of Kashmir’s culture, offering everything from handcrafted treasures to delicious local treats.</p>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>MONTH-WISE TEMPERATURE FOR</b> <strong>KASHMIR PACKAGE TOUR FROM CHENNAI</strong></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>MONTH-WISE TEMPERATURE FOR</b> <strong>KASHMIR PACKAGE TOUR FROM CHENNAI</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <p>Before booking a <strong>Kashmir package tour from Chennai</strong>, it's important to know the best time to visit and what temperatures to expect throughout the year. Here is a month-by-month temperature guide to help you prepare for your <strong>Kashmir Tours from Chennai</strong>:</p>
                                            <br /><br />
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <thead className="cf">
                                                        <tr className="table-danger">
                                                            <th>Months</th>
                                                            <th>Temp. (Min - Max)</th>
                                                            <th>Season</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td data-title="Months">March - May</td>
                                                            <td data-title="Temp. (Min - Max)">10°C - 20°C</td>
                                                            <td data-title="Season">Spring</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Months">June - August</td>
                                                            <td data-title="Temp. (Min - Max)">15°C - 30°C</td>
                                                            <td data-title="Season">Summer</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Months">September - November</td>
                                                            <td data-title="Temp. (Min - Max)">10°C - 25°C</td>
                                                            <td data-title="Season">Autumn</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Months">December - February</td>
                                                            <td data-title="Temp. (Min - Max)">-2°C - 10°C</td>
                                                            <td data-title="Season">Winter</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>OFFBEAT DESTINATIONS TO EXPLORE IN YOUR</b> <strong>KASHMIR TOUR PACKAGE FROM CHENNAI</strong></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>OFFBEAT DESTINATIONS TO EXPLORE IN YOUR</b> <strong>KASHMIR TOUR PACKAGE FROM CHENNAI</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <p>If you’re looking to discover the hidden gems of <strong>Kashmir from Chennai</strong> beyond the popular spots, here are some offbeat destinations that promise a truly enchanting experience:</p>
                                            <p><b>1. Yusmarg:</b>
                                                Often overshadowed by more famous destinations, Yusmarg is a serene meadow surrounded by pine forests and snow-capped mountains. Known for its tranquil beauty and picturesque landscapes, it’s perfect for a peaceful retreat away from the crowds.
                                            </p>

                                            <p><b>2. Doodhpathri:</b>
                                                Dubbed the "Valley of Milk," Doodhpathri is a pristine valley with lush green meadows and clear streams. The area is relatively untouched by tourism, offering a serene environment and breathtaking natural beauty ideal for nature lovers.
                                            </p>

                                            <p><b>3. Aru Valley:</b>
                                                Nestled near Pahalgam, Aru Valley is a hidden gem offering stunning views of the surrounding peaks and lush meadows. It’s a great spot for trekking, camping, and experiencing the raw beauty of Kashmir’s wilderness.
                                            </p>

                                            <p><b>4. Betaab Valley:</b>
                                                Named after the Bollywood movie "Betaab," this valley is surrounded by dense forests and stunning mountain views. Its scenic charm makes it a fantastic location for picnics and leisurely strolls, providing a more secluded experience compared to other popular spots.
                                            </p>

                                            <p><b>5. Gurez Valley:</b>
                                                Located close to the Line of Control (LOC), Gurez Valley offers a unique glimpse into traditional Kashmiri life and rugged landscapes. It’s less frequented by tourists, making it an ideal destination for those seeking adventure and cultural immersion.
                                            </p>

                                            <p><b>6. Pulwama:</b>
                                                Known for its picturesque saffron fields and apple orchards, Pulwama is a delightful spot for exploring traditional agriculture and enjoying the natural beauty. The area also has historical significance, with several ancient temples and shrines.
                                            </p>

                                            <p><b>7. Tarsar Marsar Lakes:</b>
                                                For trekking enthusiasts, the Tarsar Marsar Lakes offer a challenging yet rewarding adventure. These high-altitude lakes are surrounded by stunning landscapes, providing a unique opportunity to explore Kashmir’s natural beauty up close.
                                            </p>

                                            <p><b>8. Kangan:</b>
                                                Kangan is a small town located in the Sindh Valley, known for its beautiful rivers, meadows, and mountainous terrain. It’s a quiet destination perfect for those looking to escape the hustle and bustle and enjoy some time in nature.
                                            </p>
                                            <p>Exploring these offbeat destinations will give you a deeper appreciation of Kashmir’s diverse landscapes and hidden beauty, making your <strong>Kashmir Tour Pakcage from Chennai</strong> truly memorable.</p>
                                            <br /><br />

                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>BEST WAYS TO REACH KASHMIR TRIP FROM CHENNAI</b></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>BEST WAYS TO REACH KASHMIR TRIP FROM CHENNAI</b></h4>
                                        </div>
                                        <div className="card-body">
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <thead className="cf">
                                                        <tr className="table-danger">
                                                            <th>Mode of Travel</th>
                                                            <th>Details</th>
                                                            <th>Duration</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td data-title="Mode of Travel">By Flight</td>
                                                            <td data-title="Details"><b>Airline Options</b> : IndiGo, SpiceJet, Vistara, Air India<br />
                                                                <b>Airport in Kashmir</b>: Sheikh ul-Alam International Airport (SXR) in Srinagar<br />
                                                                <b>Stopover</b>: Usually in Delhi<br />
                                                                <b>Example Flight Times</b>:
                                                                IndiGo: Departure from Chennai - 06:00, Arrival in Srinagar - 12:15
                                                                Vistara: Departure from Chennai - 07:30, Arrival in Srinagar - 13:10

                                                            </td>
                                                            <td data-title="Duration">Approx. 5 hours 15 minutes<br />
                                                                (including stopover) </td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Mode of Travel">By Train</td>
                                                            <td data-title="Details"><b>Train to Jammu Tawi</b>: Chennai to Jammu Tawi (via Delhi or directly)<br />
                                                                <b>From Jammu</b>: Hire a taxi or take a bus to Srinagar (Approx. 9-10 hours drive)<br />
                                                                <b>Alternate Route</b>: Chennai to Udhampur (then taxi / bus to Srinagar)
                                                            </td>
                                                            <td data-title="Duration">Train: Approx. 36 hours<br />
                                                                Jammu to Srinagar: Approx. 8-10 hours
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Mode of Travel">By Road</td>
                                                            <td data-title="Details"><b>Route</b> : Chennai - Bengaluru - Hyderabad - Nagpur - Delhi - Jammu - Srinagar<br />
                                                                <b>Distance</b> : Approximately 2,245 km<br />
                                                                <b>Estimated Travel Time</b> :
                                                                Chennai to Jammu: Approx. 41 hours
                                                                Jammu to Srinagar: Approx. 8 hours 14 minutes<br />
                                                                <b>Total Travel Time</b> : Around 49 hours (including rest breaks)
                                                            </td>
                                                            <td data-title="Duration">Approx. 49 hours (including rest breaks)
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <br /><br />
                                            <p>Choose the travel option that best suits your schedule and preferences for a smooth journey to the enchanting landscapes of <strong>Kashmir Package Tour Operator</strong>.</p>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div className="card-header custHeaer">
                                            <h4 className='content-desktop' style={{ fontSize: "1.5vw" }}>POPULAR TREKKING ROUTES IN <strong>KASHMIR PACKAGE FROM CHENNAI</strong> BY ROAD</h4>
                                            <h4 className='content-mobile2' style={{ fontSize: "3.5vw" }}>POPULAR TREKKING ROUTES IN <strong>KASHMIR PACKAGE FROM CHENNAI</strong> BY ROAD</h4>
                                        </div>
                                        <div className="card-body">
                                            <p>Kashmir offers some of the most spectacular trekking routes, each providing breath-taking views and unique experiences. Here are some of the most popular trekking routes in <strong>Kashmir Tour Packages</strong>:</p>
                                            <p><b>1.	Great Lakes Trek</b><br />
                                                <b>Duration</b>: 8-10 days<br />
                                                <b>Highlights</b>: This challenging trek covers beautiful alpine lakes like Vishansar, Krishansar, and Gadsar. The route takes you through high-altitude meadows, snow-capped peaks, and diverse landscapes.
                                            </p>
                                            <p><b>2. Tarsar Marsar Trek</b><br />
                                                <b>Duration</b>: 8-9 days<br />
                                                <b>Highlights</b>: Known for its stunning high-altitude lakes, the Tarsar Marsar Trek offers mesmerizing views of Tarsar Lake and Marsar Lake, surrounded by snow-capped mountains and lush meadows.
                                            </p>

                                            <p><b>3. Lidderwat to Aru Valley Trek</b><br />
                                                <b>Duration</b>: 5-6 days<br />
                                                <b>Highlights</b>: This trek begins in the beautiful Lidderwat Valley and ends in the picturesque Aru Valley. The route features lush green meadows, flowing rivers, and magnificent mountain views.
                                            </p>

                                            <p><b>4. Kolahoi Glacier Trek</b><br />
                                                <b>Duration</b>: 7-8 days<br />
                                                <b>Highlights</b>: Starting from Pahalgam, this trek leads to the Kolahoi Glacier. It offers stunning views of the glacier, dense forests, and alpine meadows.
                                            </p>

                                            <p><b>5. Zanskar Valley Trek</b><br />
                                                <b>Duration</b>: 12-14 days<br />
                                                <b>Highlights</b>: Though more remote, the Zanskar Valley Trek provides an incredible experience through rugged terrain, high passes, and stunning landscapes, including the famous Chadar Frozen River Trek in winter.
                                            </p>

                                            <p><b>6. Shamsbari Range Trek</b><br />
                                                <b>Duration</b>: 7-8 days<br />
                                                <b>Highlights</b>: This trek explores the Shamsbari Range, known for its scenic beauty and diverse landscapes. It offers views of traditional villages, alpine meadows, and towering peaks.
                                            </p>

                                            <p><b>7. Nubra Valley Trek</b><br />
                                                <b>Duration</b>: 8-10 days<br />
                                                <b>Highlights</b>: Located in the Ladakh region but accessible from Kashmir, the Nubra Valley Trek features sand dunes, monasteries, and stunning mountain scenery.
                                            </p>

                                            <p><b>8. Sinthan Top Trek</b><br />
                                                <b>Duration</b>: 4-5 days<br />
                                                <b>Highlights</b>: Starting from the town of Anantnag, this trek leads to Sinthan Top, offering panoramic views of the surrounding peaks and lush green valleys.
                                            </p>
                                            <p>Each of these trekking routes provides a unique way to explore the natural beauty of <strong>Kashmir Tour Packages from Chennai</strong>, from serene alpine lakes and lush meadows to rugged mountains and ancient glaciers.</p>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div className="card-header custHeaer">
                                            <h4 className='content-desktop' style={{ fontSize: "1.5vw" }}><b>DISTANCES TO NOTE FOR KASHMIR TRIP BOOKING FROM CHENNAI</b></h4>
                                            <h4 className='content-mobile2' style={{ fontSize: "3.5vw" }}><b>DISTANCES TO NOTE FOR KASHMIR TRIP BOOKING FROM CHENNAI</b></h4>
                                        </div>
                                        <div className="card-body">
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <thead class="cf">
                                                        <tr className="table-danger">
                                                            <th className="text-center">From</th>
                                                            <th className="text-center">To</th>
                                                            <th className="text-center">Distance</th>
                                                            <th className="text-center">Duration</th>
                                                            <th className="text-center">Route</th>
                                                        </tr>
                                                    </thead>
                                                    <tr>
                                                        <td data-title="From"><div style={{ display: 'inline-block' }}>Chennai</div></td>
                                                        <td data-title="To"><div style={{ display: 'inline-block' }}>Srinagar</div></td>
                                                        <td data-title="Distance">3,017.4 km
                                                        </td>
                                                        <td data-title="Duration"><div style={{ display: 'inline-block' }}>54 hours</div></td>
                                                        <td data-title="Route"><div style={{ display: 'inline-block' }}>Via NH 44</div></td>
                                                    </tr>
                                                    <tr>
                                                        <td data-title="From"><div style={{ display: 'inline-block' }}>Bangalore</div></td>
                                                        <td data-title="To"><div style={{ display: 'inline-block' }}>Srinagar</div></td>
                                                        <td data-title="Distance">2,985.2 km</td>
                                                        <td data-title="Duration"><div style={{ display: 'inline-block' }}>53 hours</div></td>
                                                        <td data-title="Route"><div style={{ display: 'inline-block' }}>Via NH 44</div></td>
                                                    </tr>
                                                    <tr>
                                                        <td data-title="From"><div style={{ display: 'inline-block' }}>Jammu</div></td>
                                                        <td data-title="To"><div style={{ display: 'inline-block' }}>Srinagar</div></td>
                                                        <td data-title="Distance">266.5 km</td>
                                                        <td data-title="Duration"><div style={{ display: 'inline-block' }}>8 hours 14 minutes</div></td>
                                                        <td data-title="Route"><div style={{ display: 'inline-block' }}>Via NH 44</div></td>
                                                    </tr>
                                                    <tr>
                                                        <td data-title="From"><div style={{ display: 'inline-block' }}>Srinagar</div></td>
                                                        <td data-title="To"><div style={{ display: 'inline-block' }}>Gulmarg</div></td>
                                                        <td data-title="Distance">49.5 km</td>
                                                        <td data-title="Duration"><div style={{ display: 'inline-block' }}>1 hour 30 minutes</div></td>
                                                        <td data-title="Route"><div style={{ display: 'inline-block' }}>Via Srinagar - Tangmarg Rd</div></td>
                                                    </tr>
                                                    <tr>
                                                        <td data-title="From"><div style={{ display: 'inline-block' }}>Srinagar</div></td>
                                                        <td data-title="To"><div style={{ display: 'inline-block' }}>Sonmarg</div></td>
                                                        <td data-title="Distance">79.4 km</td>
                                                        <td data-title="Duration"><div style={{ display: 'inline-block' }}>2 hours 11 minutes</div></td>
                                                        <td data-title="Route"><div style={{ display: 'inline-block' }}>Via NH 1</div></td>
                                                    </tr>
                                                    <tr>
                                                        <td data-title="From"><div style={{ display: 'inline-block' }}>Srinagar</div></td>
                                                        <td data-title="To"><div style={{ display: 'inline-block' }}>Pahalgam</div></td>
                                                        <td data-title="Distance">89.6 km</td>
                                                        <td data-title="Duration"><div style={{ display: 'inline-block' }}>2 hours 11 minutes</div></td>
                                                        <td data-title="Route"><div style={{ display: 'inline-block' }}>Via Srinagar - Kanyakumari Hwy</div></td>
                                                    </tr>
                                                    <tr>
                                                        <td data-title="From"><div style={{ display: 'inline-block' }}>Srinagar</div></td>
                                                        <td data-title="To"><div style={{ display: 'inline-block' }}>Dras</div></td>
                                                        <td data-title="Distance">141.2 km</td>
                                                        <td data-title="Duration"><div style={{ display: 'inline-block' }}>4 hours 2 minutes</div></td>
                                                        <td data-title="Route"><div style={{ display: 'inline-block' }}>Via NH 1</div></td>
                                                    </tr>
                                                    <tr>
                                                        <td data-title="From"><div style={{ display: 'inline-block' }}>Srinagar</div></td>
                                                        <td data-title="To"><div style={{ display: 'inline-block' }}>Kargil</div></td>
                                                        <td data-title="Distance">202.1 km</td>
                                                        <td data-title="Duration"><div style={{ display: 'inline-block' }}>5 hours 19 minutes</div></td>
                                                        <td data-title="Route"><div style={{ display: 'inline-block' }}>Via NH 1</div></td>
                                                    </tr>
                                                    <tr>
                                                        <td data-title="From"><div style={{ display: 'inline-block' }}>Srinagar</div></td>
                                                        <td data-title="To"><div style={{ display: 'inline-block' }}>Leh</div></td>
                                                        <td data-title="Distance">418.5 km</td>
                                                        <td data-title="Duration"><div style={{ display: 'inline-block' }}>9 hours 41 minutes</div></td>
                                                        <td data-title="Route"><div style={{ display: 'inline-block' }}>Via NH 1</div></td>
                                                    </tr>
                                                </table>
                                            </div>
                                            <br />
                                            <p>This chart provides the key distances and estimated travel times for various routes from Chennai to different destinations in Kashmir.</p>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div className="card-header custHeaer">
                                            <h4 className='content-desktop' style={{ fontSize: "1.5vw" }}><b>EXPLORING SPIRITUAL MARVELS: TOP HOLY PLACES TO EXPLORE DURING</b> <strong>KASHMIR PACKAGE TOUR FROM CHENNAI</strong></h4>
                                            <h4 className='content-mobile2' style={{ fontSize: "3.5vw" }}><b>EXPLORING SPIRITUAL MARVELS: TOP HOLY PLACES TO EXPLORE DURING</b> <strong>KASHMIR PACKAGE TOUR FROM CHENNAI</strong></h4>

                                        </div>
                                        <div className="card-body">
                                            <p>Kashmir is not just a land of stunning landscapes but also a region rich in spiritual heritage. Here are some of the top holy places you should visit on your <strong>Kashmir tour from Chennai</strong>:</p>
                                            <p><b>1.	Vaishno Devi Temple</b><br />
                                                <b>o	Location:Katra, near Jammu</b><br />
                                                <b>o	Highlights:</b> One of the most revered Hindu shrines dedicated to Goddess Vaishno Devi. The temple is located in the Trikuta Mountains and requires a trek of approximately 13 km from Katra. It offers breathtaking views and a spiritually uplifting experience.
                                            </p>
                                            <p><b>2. Shankaracharya Temple</b><br />
                                                <b>o Location:</b> Srinagar<br />
                                                <b>o Highlights:</b> Perched on Shankaracharya Hill, this ancient temple is dedicated to Lord Shiva. It provides panoramic views of Srinagar and the surrounding valley. The temple holds significant religious importance and offers a serene atmosphere.
                                            </p>

                                            <p><b>3. Hazratbal Shrine</b><br />
                                                <b>o Location:</b> Srinagar<br />
                                                <b>o Highlights:</b> An important Muslim shrine on the northern shores of Dal Lake. It is known for housing a relic believed to be a hair strand of the Prophet Muhammad. The site offers spiritual reflection and stunning lake views.
                                            </p>

                                            <p><b>4. Jamia Masjid</b><br />
                                                <b>o Location:</b> Srinagar<br />
                                                <b>o Highlights:</b> A beautiful mosque with impressive architecture and 378 wooden pillars. It’s one of the largest mosques in Kashmir and an important center for prayer and community gathering.
                                            </p>

                                            <p><b>5. Amarnath Cave Temple</b><br />
                                                <b>o Location:</b> Amarnath, near Srinagar<br />
                                                <b>o Highlights:</b> Famous for the Amarnath Yatra pilgrimage, this cave temple is dedicated to Lord Shiva. The natural ice Shiva Lingam formed in the cave is a major attraction for devotees and trekkers.
                                            </p>

                                            <p><b>6. Kheer Bhawani Temple</b><br />
                                                <b>o Location:</b> Tullamulla, near Srinagar<br />
                                                <b>o Highlights:</b> Dedicated to the goddess Kheer Bhawani, this temple is known for its sacred spring and the annual festival celebrated here. It is a key pilgrimage site for Kashmiri Hindus.
                                            </p>

                                            <p><b>7. Mata Hari Temple</b><br />
                                                <b>o Location:</b> Kangan, near Srinagar<br />
                                                <b>o Highlights:</b> An ancient temple dedicated to the goddess Mata Hari. It offers a serene environment and is an important site for local devotees.
                                            </p>

                                            <p><b>8. Yusmarg Mosque</b><br />
                                                <b>o Location:</b> Yusmarg, near Srinagar<br />
                                                <b>o Highlights:</b> A picturesque mosque located in the beautiful meadow of Yusmarg. It is an ideal spot for quiet reflection amidst nature.
                                            </p>
                                            <p>These holy sites offer a rich blend of cultural and spiritual experiences, making your <strong>Kashmir Tour Guide from Chennai</strong> both enlightening and memorable.</p>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div className="card-header custHeaer">
                                            <h4 className='content-desktop' style={{ fontSize: "1.5vw" }}>MUST DO ACTIVITIES DURING <strong>KASHMIR TOUR PACKAGES FROM CHENNAI</strong></h4>
                                            <h4 className='content-mobile2' style={{ fontSize: "3.5vw" }}>MUST DO ACTIVITIES DURING <strong>KASHMIR TOUR PACKAGES FROM CHENNAI</strong></h4>

                                        </div>
                                        <div className="card-body">
                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>Shikara Rides:</b><b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Shikara Rides:</b> Experience the tranquility of Dal Lake with a leisurely Shikara ride During your <strong> Kashmir Tulip Festival Package Tour</strong>. Glide across the serene waters, passing by floating gardens, houseboats, and vibrant markets. Sunset and early morning rides offer particularly magical views.</p>
                                            <center> <img src={Topbanner0ImageURL} className="img-fluid" alt="..." /></center><br /><br /><br />

                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>Skiing and Snowboarding:</b>
                                                <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Skiing and Snowboarding:</b> During the winter months, places like Gulmarg transform into ski resorts, attracting enthusiasts from around the world. Gulmarg offers excellent slopes for skiing, snowboarding, and heli-skiing, with breathtaking views of the surrounding Himalayan peaks.</p>
                                            <center> <img src={Topbanner1ImageURL} className="img-fluid" alt="..." /></center><br /><br /><br />

                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>Gondola Ride: </b>
                                                <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Gondola Ride: </b>
                                                Take a ride on the Gulmarg Gondola, one of the highest cable cars in the world. Enjoy panoramic views of the snow-capped peaks as you ascend to Kongdori or Apharwat Peak, where you can indulge in activities like hiking and mountain biking.Experience this Awesome Ride During your <strong>Kashmir Tulip Garden Tour</strong> with Adorable Vacation and make this as a life long memories.</p>
                                            <center> <img src={Topbanner2ImageURL} className="img-fluid" alt="..." /></center><br /><br /><br />

                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>Trekking:</b>
                                                <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Trekking:</b> Kashmir is a paradise for trekkers, with numerous trails that cater to all levels of expertise. Popular trekking destinations include the Kashmir Great Lakes Trek, Tarsar Marsar Trek, and the trek to Thajiwas Glacier in Sonamarg.</p>
                                            <center> <img src={Topbanner3ImageURL} className="img-fluid" alt="..." /></center><br /><br /><br />
                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>River Rafting:</b>
                                                <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>River Rafting:</b> The Lidder River in Pahalgam offers exciting opportunities for white-water rafting. Experience the thrill of navigating through rapids while enjoying the scenic beauty of the surrounding pine forests and mountains.</p>
                                            <center> <img src={Topbanner4ImageURL} className="img-fluid" alt="..." /></center>
                                            <br /><br /><br />
                                            <a href="tel://9147061467" className="btn btn-Warning" style={{ padding: '15px 30px', fontSize: '20px' }}>CALL NOW</a>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="container-fluid navtabCustom">
                                        <nav>
                                            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                                <button onClick={() => this.handleCustomTabClick('tab1')} className={`tab button ${customActiveTab === 'tab1' ? 'active' : ''}`} role="tab" aria-controls="nav-highlights" aria-selected={customActiveTab === 'tab1'}>Highlights</button>
                                                <button onClick={() => this.handleCustomTabClick('tab2')} className={`tab button ${customActiveTab === 'tab2' ? 'active' : ''}`} role="tab" aria-controls="nav-flights" aria-selected={customActiveTab === 'tab2'}>Flights</button>
                                                <button onClick={() => this.handleCustomTabClick('tab3')} className={`tab button ${customActiveTab === 'tab3' ? 'active' : ''}`} role="tab" aria-controls="nav-hotel" aria-selected={customActiveTab === 'tab3'}>Hotel</button>
                                                <button onClick={() => this.handleCustomTabClick('tab4')} className={`tab button ${customActiveTab === 'tab4' ? 'active' : ''}`} role="tab" aria-controls="nav-sightseeing" aria-selected={customActiveTab === 'tab4'}>Sightseeing</button>
                                                <button onClick={() => this.handleCustomTabClick('tab5')} className={`tab button ${customActiveTab === 'tab5' ? 'active' : ''}`} role="tab" aria-controls="nav-meals" aria-selected={customActiveTab === 'tab5'}>Meals</button>
                                            </div>
                                        </nav>
                                        <div className="tab-content" id="nav-tabContent" >
                                            <div className={customActiveTab === 'tab1' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-highlights-tab">
                                            <p>The <strong>Kashmir Package Tour from Chennai</strong> is famous for its stunning landscapes, including well-known spots like Dal Lake, Nigeen Lake, Gulmarg, Pahalgam, and Sonamarg. These places draw tourists from all over the world who come to enjoy the beautiful scenery and take part in outdoor activities.</p>
                                                <p>Planning a <strong>Kashmir Tour Packages from Chennai</strong> is a great choice for travelers looking for beautiful scenery, cultural experiences, and adventure in a unique and stunning location.</p>
                                                <p>Kashmir is located in the Himalayan Mountains and is surrounded by India, Pakistan, and China. It has three main regions: Jammu, the Kashmir Valley, and Ladakh. The area is known for its snowy mountains, green valleys, winding rivers, and peaceful lakes.</p>
                                                <p>The <strong>Kashmir Tour Pakcage from Chennai</strong> are famous for their stunning landscapes, including places like Dal Lake, Nigeen Lake, Gulmarg, Pahalgam, and Sonamarg. Tourists from around the world visit to enjoy the beautiful scenery and take part in outdoor activities. Kashmir has always been a popular destination, known for its natural beauty, adventure sports, religious sites, and historical landmarks. Visitors can enjoy activities like trekking, skiing, staying in houseboats, taking shikara rides, and exploring Mughal gardens and ancient temples.</p>
                                            </div>
                                            <div className={customActiveTab === 'tab2' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-flights-tab">
                                                <div className="overflow-table">
                                                    <span>POPULAR FLIGHTS FOR THE KASHMIR TULIP GARDEN TRIP 2025<b> <i>(SXR)</i></b></span><br /><br />
                                                    <table className="table table-bordered">
                                                        <tr className="table-danger">
                                                            <th>Flight Operator</th>
                                                            <th>Departure From</th>
                                                            <th>Departure Time</th>
                                                            <th>Arrival at Srinagar</th>
                                                            <th>Stopover</th>
                                                            <th>Travel Time</th>
                                                        </tr>
                                                        <tr>
                                                            <td>IndiGo</td>
                                                            <td>Kolkata [CCU]</td>
                                                            <td>08:15</td>
                                                            <td>15:15</td>
                                                            <td>New Delhi [DEL]</td>
                                                            <td>7h 00m</td>
                                                        </tr>
                                                        <tr>
                                                            <td>IndiGo</td>
                                                            <td>Mumbai [BOM]</td>
                                                            <td>09:30</td>
                                                            <td>15:15</td>
                                                            <td>New Delhi [DEL]</td>
                                                            <td>5h 45m</td>
                                                        </tr>
                                                        <tr>
                                                            <td>SpiceJet</td>
                                                            <td>New Delhi [DEL]</td>
                                                            <td>08:00</td>
                                                            <td>09:25</td>
                                                            <td>(None)</td>
                                                            <td>1h 25m</td>
                                                        </tr>
                                                        <tr>
                                                            <td>IndiGo</td>
                                                            <td>Bangalore [BLR]</td>
                                                            <td>09:30</td>
                                                            <td>14:10</td>
                                                            <td>Sri Guru Ram Dass Jee [ATQ]</td>
                                                            <td>4h 40m</td>
                                                        </tr>
                                                        <tr>
                                                            <td>IndiGo</td>
                                                            <td>Goa [GOX]</td>
                                                            <td>10:30</td>
                                                            <td>16:45</td>
                                                            <td>New Delhi [DEL]</td>
                                                            <td>6h 15m</td>
                                                        </tr>
                                                        <tr>
                                                            <td>IndiGo</td>
                                                            <td>Hyderabad [HYD]</td>
                                                            <td>08:55</td>
                                                            <td>15:15</td>
                                                            <td>New Delhi [DEL]</td>
                                                            <td>6h 20m</td>
                                                        </tr>
                                                        <tr>
                                                            <td>IndiGo</td>
                                                            <td>Chennai [MAA]</td>
                                                            <td>08:30</td>
                                                            <td>14:20</td>
                                                            <td>New Delhi [DEL]</td>
                                                            <td>5h 50m</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className={customActiveTab === 'tab3' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-hotel-tab">

                                                <div className="overflow-table">
                                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }} ><center><b>4 STAR - CERTIFIED BY 'ADORABLE' </b></center></h4>
                                                    <div id="no-more-tables">
                                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                            <thead class="cf">
                                                                <tr className="text-center">
                                                                    {/* <!-- Add the "text-center" className to center content --> */}
                                                                    <th colspan="6" style={{ fontSize: "1.3vw" }}><h6><b>3 STAR CATEGORY HOTEL CERTIFIED BY 'ADORABLE VACATION' </b></h6></th>
                                                                </tr>
                                                                <tr className="table-danger">
                                                                    <th className="text-center">Destination</th>
                                                                    <th className="text-center">Hotel Names</th>
                                                                    <th className="text-center">Hotel Type</th>
                                                                    <th className="text-center">No of Nights</th>
                                                                    <th className="text-center">Meal Plan</th>
                                                                </tr>
                                                            </thead>
                                                            <tr>
                                                                <td data-title="Destination"><div style={{ display: 'inline-block' }}>Srinagar</div></td>
                                                                <td data-title="Hotel Names"><div style={{ display: 'inline-block' }}>Hotel Grand Fortune / Hotel Shefaf / Hotel Siddique Palace / Similar</div></td>
                                                                <td data-title="Hotel Type">3 Star
                                                                </td>
                                                                <td data-title="No of Nights"><div style={{ display: 'inline-block' }}>5N</div></td>
                                                                <td data-title="Meal Plan"><div style={{ display: 'inline-block' }}>Room with Breakfast & Dinner</div></td>
                                                            </tr>
                                                            <tr>
                                                                <td data-title="Destination"><div style={{ display: 'inline-block' }}>Gulmarg</div></td>
                                                                <td data-title="Hotel Names"><div style={{ display: 'inline-block' }}>Hotel Affarwat / Hotel Mama Palace /Gulmarg House / Similar
                                                                </div>
                                                                </td>
                                                                <td data-title="Hotel Type">3 Star
                                                                </td>
                                                                <td data-title="No of Nights"><div style={{ display: 'inline-block' }}>1N</div></td>
                                                                <td data-title="Meal Plan"><div style={{ display: 'inline-block' }}>Room with Breakfast & Dinner</div></td>
                                                            </tr>
                                                            <tr>
                                                                <td data-title="Destination"><div style={{ display: 'inline-block' }}>Pahalgam</div></td>
                                                                <td data-title="Hotel Names"><div style={{ display: 'inline-block' }}>Hotel Pine Palace Resort / Hotel Fifth Season / Hotel Moonview Resort /Similar
                                                                </div>
                                                                </td>
                                                                <td data-title="Hotel Type">3 Star
                                                                </td>
                                                                <td data-title="No of Nights"><div style={{ display: 'inline-block' }}>2N</div></td>
                                                                <td data-title="Meal Plan"><div style={{ display: 'inline-block' }}>Room with Breakfast & Dinner</div></td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={customActiveTab === 'tab4' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-sightseeing-tab">
                                                <div className="container-fluid px-0 py-1">
                                                    <h5>TOP ATTRACTIONS TO EXPLORE DURING THE <strong>KASHMIR PACKAGE TOUR FROM CHENNAI</strong></h5><br />
                                                    <p>During the Kashmir Tulip Holiday Package from Adorable Vacation, the region offers a wealth of captivating attractions that complement the vibrant display of tulips. Here are the must-see highlights:</p><br />
                                                    <p><b className='tomato'>1.	Indira Gandhi Memorial Tulip Garden:</b> Dive into the festival's heart, where sprawling tulip beds create a riot of colours against the backdrop of the majestic Himalayas.</p>
                                                    <p><b className='tomato'>2. Srinagar's Dal Lake:</b> Take a serene shikara ride on this iconic lake, known for its houseboats and picturesque views, offering a tranquil escape from the festival's bustle.</p>
                                                    <p><b className='tomato'>3. Mughal Gardens:</b> Explore the timeless beauty of Shalimar Bagh, Nishat Bagh, and Chashme Shahi, where Mughal architecture blends with lush landscapes.</p>
                                                    <p><b className='tomato'>4. Gulmarg:</b> Discover the lush meadows and pristine surroundings of Gulmarg, perfect for a day trip with opportunities for cable car rides and panoramic views.</p>
                                                    <p><b className='tomato'>5. Pahalgam:</b> Experience the natural splendor of Pahalgam, from its scenic meadows to the picturesque Betab Valley and Aru Valley.</p>
                                                    <p><b className='tomato'>6. Sonmarg:</b> Venture to Sonmarg's breathtaking landscapes, where stunning views and serene environments make for a memorable excursion.</p>
                                                    <p><b className='tomato'>7. Doodhpathri:</b> Explore this lesser-known gem, renowned for its lush green meadows and gentle streams, offering a peaceful retreat.</p>
                                                    <p><b className='tomato'>8. Yusmarg:</b> Visit this idyllic meadow surrounded by pine forests, providing a serene spot for relaxation and picnicking.</p>
                                                    <p><b className='tomato'>9. Srinagar's Local Markets:</b> Immerse yourself in the vibrant local culture by visiting bustling markets like Lal Chowk and Polo View Market, where you can shop for traditional Kashmiri crafts and souvenirs.</p>
                                                    <p><b className='tomato'>10. Vaishno Devi Temple:</b> Take a pilgrimage to this revered shrine, nestled in the Trikuta Mountains, offering spiritual solace and panoramic views.</p>
                                                    <p><b className='tomato'>11. Shankaracharya Temple:</b> Visit this ancient temple perched on a hilltop for a blend of spiritual significance and stunning panoramic views of Srinagar.</p>
                                                    <p>The <strong>Tulip Garden Tour Package</strong> promises not only a visual feast of flowers but also a rich array of cultural and natural wonders to explore.</p>
                                                </div>
                                            </div>
                                            <div className={customActiveTab === 'tab5' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-meals-tab">
                                                <div className="overflow-table">
                                                    <h5 class="tomato">7 times Meals and 7 times Breakfast has been Provided</h5>
                                                    <table className="table table-bordered">
                                                        <tr className="table-danger">
                                                            <th colspan="6" className="text-center">Category</th>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-center">JAIN</td>
                                                            <td class="text-center">PURE-VEG</td>
                                                            <td class="text-center">VEGAN</td>
                                                            <td class="text-center">PURE-NON-VEG</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <br /><br /><br />
                            {/* <!--By Itinerary--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>
                                                DAY WISE <strong><span>KASHMIR TOUR ITINERARY FROM CHENNAI</span></strong>
                                            </h2>
                                            <h4>7 Nights 8 Days <strong>Kashmir Tour Itinerary</strong></h4>
                                        </div>
                                    </div>
                                </div>


                                {/* <!--end Short description--> */}
                                <br />
                                <div className="row">
                                    <div
                                        className="accordion"
                                        id="accordionPanelsStayOpenExample"
                                    >
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day1"
                                            >
                                                <button
                                                    className="accordion-button"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseOne"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseOne"
                                                >
                                                    DAY 1  – ARRIVAL IN SRINAGAR
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseOne"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingOne"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        Arrive at Srinagar’s Sheikh Ul Alam International Airport. You’ll be greeted by our representative and taken to your hotel for check-in. Enjoy a welcome drink and then head out for a shikara ride on the famous Dal Lake. Explore the floating gardens and enjoy bird watching. Spend the night on a houseboat.
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay1ImageURL} alt="Kashmir Offbeat Packages for Family" /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay1ImageURL} style={{ width: '320px' }} alt="Kashmir Offbeat Packages for Family" /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day2"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseTwo"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseTwo"
                                                >
                                                    DAY 02: SRINAGAR TO GULMARG
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseTwo"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingTwo"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        After breakfast, check out from the houseboat and drive to Gulmarg, known as the “Meadow of Flowers.” Check in at your hotel in Gulmarg. You can take a gondola ride up to the first or second phase of the cable car, which offers stunning views and is one of the highest cable cars in the world. Gulmarg is also famous for skiing and its beautiful meadows. Return to your hotel for the night.
                                                    </p>

                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay2ImageURL} alt="Kashmir Tour Package cost offbeat" /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay2ImageURL} style={{ width: '320px' }} alt="Kashmir Tour Package cost offbeat" /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day3"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseThree"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 03: GULMARG TO PAHALGAM
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseThree"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingThree"
                                            >
                                                <div className="accordion-body">
                                                    <p>Enjoy breakfast before checking out and driving to Pahalgam, known as the “Valley of Shepherds.” This charming town sits where the streams from Sheshnag Lake meet the Lidder River. Check in at your hotel and enjoy the rest of the day at leisure.</p>
                                                    <br />

                                                    <div className="content-desktop">
                                                        <center><img src={navDay3ImageURL} alt="Kashmir Tour Packages" /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay3ImageURL} style={{ width: '320px' }} alt="Kashmir Tour Packages" /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day4"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseFour"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    Day 04: - PAHALGAM LOCAL SIGHTSEEING
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseFour"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingFour"
                                            >
                                                <div className="accordion-body">
                                                    <p>After breakfast, spend the day exploring Pahalgam. Visit Chandanwari, the starting point for the Amarnath Yatra, and enjoy snow sledding. Explore Aru Valley and Betaab Valley for their stunning scenery. You can also visit Baisaran Valley, known as “Mini Switzerland,” for beautiful meadows and dense pine forests. Return to your hotel in Pahalgam for the night.</p>

                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay4ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay4ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day5"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseFive"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 05: PAHALGAM TO SRINAGAR
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseFive"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingFive"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        Check out from your hotel and drive back to Srinagar. Visit the Hazratbal Shrine and Shankaracharya Temple. In the afternoon, explore the Mughal Gardens: Nishat Bagh, Shalimar Bagh, and Chashme Shahi. Return to your Srinagar hotel for the night.
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay5ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay5ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day6"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseSix"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 06: SRINAGAR TO DOODHPATHRI AND BACK
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseSix"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingSix"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        After breakfast, take a day trip to Doodhpathri, located 42 kilometers from Srinagar. Known as the "Valley of Milk," Doodhpathri is famous for its lush meadows and cold, milky streams. The area is covered with wildflowers in spring and summer. Return to Srinagar in the evening for an overnight stay.
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay6ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay6ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day7"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseSeven"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 07: SRINAGAR TO SONMARG AND BACK
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseSeven"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingSeven"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        After breakfast, check out from your hotel and head to Sonmarg, meaning "Meadow of Gold." The 87-kilometer drive offers stunning views of forests and mountains. Sonmarg is a gateway to trekking routes and glaciers. You can hire ponies or vehicles to visit Thajiwas Glacier, a major attraction. Return to Srinagar in the evening for your last overnight stay.
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay7ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay7ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day8"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseTen"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 08:DEPARTURE FROM SRINAGAR
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseTen"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingTen"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        Your <strong>Kashmir Tour From Chennai</strong> concludes today. After breakfast, you will be transferred to the Domestic Airport for your flight to your next destination. Thank you for traveling with us, and we hope you had a memorable experience.
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay8ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay8ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <br />
                            {/* <!--insert table inclusion exclusion--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        {
                                            <InclusionExclusion />
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* <!--end table inclusion exclusion--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center" >
                                            <h2>The Khyber Himalayan Resort & Spa <span>- Gulmarg</span></h2>
                                            <h4><i>Next Level Luxury Resort </i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row py-3">
                                    <img src={brandedTourackageImage_1_URL} alt="..." />
                                </div>

                                <div id="no-more-tables" >
                                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                        <thead>
                                            <tr className="table-danger">
                                                <th>Category</th>
                                                <th>Size(Room)</th>
                                                <th>Hotel Brand</th>
                                                <th>Price (Per Night)</th>
                                                <th>Book Now</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td data-title="Packages">Platinum Accommodation</td>
                                                <td data-title="Size(Room)">386+ SQ.FT</td>
                                                <td data-title="Hotel Brand">Khyber Hotels (Heritage)<div className="reviews">
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                </div></td>
                                                <td data-title="Price (Per Night)">Rs 53,119/- </td>
                                                <td data-title="Book Now"><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick}>
                                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                                </a></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2 >The LaLiT Grand Place <span>- Srinagar</span></h2>
                                            <h4 ><i>Fill the Luxury</i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row py-3">
                                    <img src={brandedTourackageImage_2_URL} alt="..." />
                                </div>

                                <div id="no-more-tables" >
                                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                        <thead>
                                            <tr className="table-danger">
                                                <th>Category</th>
                                                <th>Size(Room)</th>
                                                <th>Hotel Brand</th>
                                                <th>Price (Per Night)</th>
                                                <th>Book Now</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td data-title="Packages">Premium Accommodation</td>
                                                <td data-title="Size(Room)">290+ SQ.FT</td>
                                                <td data-title="Hotel Brand">The LaLiT Hotels<div className="reviews">
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                </div></td>
                                                <td data-title="Price (Per Night)">Rs 28,332/- </td>
                                                <td data-title="Book Now"><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick}>
                                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                                </a></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                <div className="card mx-0 my-5">
                                    <div className="card-header custHeaer">
                                        <h3 className='content-desktop' style={{ fontSize: "1.5vw" }}><b>EMBARKED ON A SENSE OF PRIDE AT PREMIUMNESS</b></h3>
                                        <h3 className='content-mobile2' style={{ fontSize: "3.5vw" }}><b>EMBARKED ON A SENSE OF PRIDE AT PREMIUMNESS</b></h3>
                                    </div>
                                    <div className="card-body">
                                        <p ><b>The LaLit Grand Palace</b> was originally built in 1901 for the royal family. It now provides luxurious rooms with views of Dal Lake and the beautiful gardens. An indoor pool, tennis courts and pampering spa treatments are also available.The tastefully designed rooms are modern with a touch of traditional charm. All have a satellite TV, minibar and free tea/coffee making facilities. It is 18 km from Srinagar Airport and 4 km from Lal Chowk city centre. Jammu Railway Station is 300 km away. Guests can enjoy a relaxing massage at Rejuve Spa Health Club, practice yoga, or play golf. The hotel also has a gym and 24-hour business center. The Chinar Garden serves North Indian cuisine, while international buffet spreads are available at The Chinar – The All Day Coffee Shop. Other dining options include Dal Bar and 24-hour room service.</p>

                                        <a href="tel://9883359713" className="btn btn-Warning">Book Now</a>

                                    </div>
                                </div>
                            </div>

                            <br /><br />
                            {/* <!--start of package details--> */}
                            <div className="container-fluid py-5">

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>Kashmir<span> Package Tour From Chennai </span></h2>
                                            <h4>Related Kashmir Tour plan</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                                        <div className="carousel-inner">
                                            <div className="carousel-item active">

                                                <Slider {...settings}>
                                                    {hotPackagesView}
                                                </Slider>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!--end of package details--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2 >Frequently Ask <span>Questions</span></h2>
                                            <h4 >Kashmir Package Tour From Chennai Related Questions</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="row" >
                                    <div className="accordion" id="accordionPanelsStayOpenExample">
                                        <div className="accordion" id="accordionFlushExample">
                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingTen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
                                                        What is the best time to visit Kashmir?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseTen" className="accordion-collapse collapse show" aria-labelledby="flush-headingTen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p><b>Answer:</b> The best time to visit Kashmir is from March to October. Spring (March-May) is ideal for blooming gardens and pleasant weather. Summer (June-August) offers warm temperatures perfect for sightseeing and trekking. Autumn (September-November) provides beautiful fall colors, while Winter (December-February) is great for snow activities, though it can be very cold.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingEleven">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEleven" aria-expanded="false" aria-controls="flush-collapseEleven">
                                                        What are the entry requirements for Kashmir from Chennai?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseEleven" className="accordion-collapse collapse show" aria-labelledby="flush-headingEleven" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p><b>Answer:</b> Indian citizens need a valid ID (Aadhaar Card, Passport, or Driving License) to enter Jammu and Kashmir. Foreign nationals may require an Inner Line Permit (ILP) or other permits depending on their travel plans.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingTwelve">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwelve" aria-expanded="false" aria-controls="flush-collapseTwelve">
                                                        What are the available travel options from Chennai to Kashmir?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseTwelve" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwelve" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p><b>Answer:</b> The fastest way to reach Kashmir from Chennai is by air. Direct flights are available from Chennai to Srinagar. Alternatively, you can travel by train to Jammu and then take a taxi or bus to Srinagar. For those preferring a road trip, it involves a long drive covering multiple states.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingThirteen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThirteen" aria-expanded="false" aria-controls="flush-collapseThirteen">
                                                        What are the popular places to visit in Kashmir?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseThirteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingThirteen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p><b>Answer:</b> Key attractions include Srinagar’s Dal Lake, Mughal Gardens, Gulmarg’s skiing slopes, Pahalgam’s lush meadows, Sonamarg’s alpine beauty, and the holy sites like Vaishno Devi Temple and Amarnath Cave Temple.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingFourteen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFourteen" aria-expanded="false" aria-controls="flush-collapseFourteen">
                                                        What kind of accommodation options are available?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseFourteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingFourteen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p><b>Answer:</b> Accommodation options range from luxury hotels and houseboats to budget guesthouses and lodges. Srinagar, Gulmarg, and Pahalgam offer a variety of choices to suit different preferences and budgets.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingFifteen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFifteen" aria-expanded="false" aria-controls="flush-collapseFifteen">
                                                        Are there any adventure activities in Kashmir?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseFifteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingFifteen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p><b>Answer:</b> Yes, Kashmir offers numerous adventure activities such as skiing and snowboarding in Gulmarg, trekking in the Himalayas, paragliding, and white-water rafting. Summer and winter seasons provide different sets of activities.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingSixteen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSixteen" aria-expanded="false" aria-controls="flush-collapseSixteen">
                                                        What is the local cuisine like?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseSixteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingSixteen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p><b>Answer:</b> Kashmiri cuisine is known for its rich flavors and use of spices. Popular dishes include Rogan Josh, Dum Aloo, Yakhni, and the famous Kashmiri Kahwa tea.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingSeventeen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeventeen" aria-expanded="false" aria-controls="flush-collapseSeventeen">
                                                        Is it safe to travel to Kashmir?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseSeventeen" className="accordion-collapse collapse show" aria-labelledby="flush-headingSeventeen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p><b>Answer:</b> Kashmir is generally safe for tourists, but it is important to stay updated on travel advisories. Follow local guidelines, respect regional customs, and keep in touch with your tour operator for any changes in travel conditions.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingEighteen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEighteen" aria-expanded="false" aria-controls="flush-collapseEighteen">
                                                        What should I pack for a Kashmir trip?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseEighteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingEighteen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p><b>Answer:</b> Pack according to the season. For winter, bring warm clothing, including thermals, jackets, and gloves. In summer, light and comfortable clothing, along with sun protection, is advisable. Always include comfortable walking shoes and any necessary medications.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingNineteen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNineteen" aria-expanded="false" aria-controls="flush-collapseNineteen">
                                                        How can I book a Kashmir package tour from Chennai?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseNineteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingNineteen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p><b>Answer:</b> You can book a Kashmir package tour through travel agencies, tour operators, or online travel platforms. Look for packages that include transportation, accommodation, and guided tours to ensure a smooth travel experience.</p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center" >
                                            <h3><b>Kashmir <span class="tomato">Holiday Reviews</span></b></h3>
                                            <h4><i>"Your Experience is our Marketing"</i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-cols-1">
                                    <div id="testimonials">
                                        <div className="testimonial-box-container" >

                                            {reviewersDataView}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <BookingBox />
                        </div>
                    </div >
                </div >
                <div class="btn-call-pulse">
                    <a style={{ color: 'white' }} href="tel:+91-91470-61467">
                        <i className="fa fa-phone"></i>
                        {/* <span><small>+91 9147061467</small></span> */}
                    </a>
                </div>
                <div className="btn-whatsapp-pulse">
                    <a style={{ color: 'white' }} href="#" onClick={this.handleClick}>
                        <i className="fab fa-whatsapp"></i>
                        {/* <span>
                                <small>+91 9883359713</small>
                            </span> */}
                    </a>
                </div>

            </>
        );
    }
}

export default Kashmir_7N_8D_Package_Tour_From_Chennai;