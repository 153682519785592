import React, { Component } from 'react';

class InclusionExclusion extends Component {
    render() {
        return (
            <>
                <table className="table-borderDodont">
                    <thead>
                        <tr>
                            <td className="tomato"><h6><b>LADAKH PACKAGES</b> INCLUSIONS </h6></td>
                            <td className="tomato"><h6><b>LEH LADAKH TOUR PACKAGE </b>EXCLUSIONS </h6></td>
                        </tr>
                    </thead>
                    <tbody>
                    <tr>
                <td><span class="green-dot"><i class="fa fa-check" aria-hidden="true"></i></span>All transfers between airport – hotel – airport by taxi.</td>
                <td><span class="red-dot"><i class="fa fa-times" aria-hidden="true"></i></span>Airfare to and from Leh.</td>
              </tr>
              <tr>
                <td><span class="green-dot"><i class="fa fa-check" aria-hidden="true"></i></span>Accommodation on Double Room Sharing basis.</td>
                <td><span class="red-dot"><i class="fa fa-times" aria-hidden="true"></i></span>Extra nights in hotel due to ill health, flight cancellation or any other reason.</td>
              </tr>
              <tr>
                <td><span class="green-dot"><i class="fa fa-check" aria-hidden="true"></i></span>Daily breakfast & Dinner in Hotels/ Camp/ Resort/ Cottage</td>
                <td><span class="red-dot"><i class="fa fa-times" aria-hidden="true"></i></span>Oxygen Cylinder INR 700 Per day (Ex-Leh)</td>
              </tr>
              <tr>
                <td><span class="green-dot"><i class="fa fa-check" aria-hidden="true"></i></span>All Sightseeing and tour as per Programme.</td>
                <td><span class="red-dot"><i class="fa fa-times" aria-hidden="true"></i></span>Extensions of tour programme or diversions in the tour itinerary for any reasons eg. due to bad weather, road closure, breakdown, natural calamities or any factors beyond our control.</td>
              </tr>
              <tr>
                <td><span class="green-dot"><i class="fa fa-check" aria-hidden="true"></i></span>Innerline permit / Environmental permit, Ladakh Entry Fees, Wild Life Fees & Red Cross society fee.</td>
                <td><span class="red-dot"><i class="fa fa-times" aria-hidden="true"></i></span>Personal expenses such as tips, laundry, phone call etc.
                </td>
              </tr>
              <tr>
                <td><span class="green-dot"><i class="fa fa-check" aria-hidden="true"></i></span>Transportation by Non AC SUV Cars</td>
                <td><span class="red-dot"><i class="fa fa-times" aria-hidden="true"></i></span> Any kind of drinks (alcoholic, mineral, soft or hard and starters.)
                </td>
              </tr>
              <tr>
                <td><span class="green-dot"><i class="fa fa-check" aria-hidden="true"></i></span>All the above mentioned rates are per person rates on Double Sharing Basis</td>
                <td><span class="red-dot"><i class="fa fa-times" aria-hidden="true"></i></span> Monastery entrance fees, camera fee, and monument guide services
                </td>
              </tr>
              <tr>
                <td><span class="green-dot"><i class="fa fa-check" aria-hidden="true"></i></span>All Service charges, driver allowances, Toll Tax, Parking etc.</td>
                <td><span class="red-dot"><i class="fa fa-times" aria-hidden="true"></i></span>Anything not specifically mentioned under the head “Cost Inclusion”.
                </td>
              </tr>
                    </tbody>
                </table>
            </>
        );
    }
}

export default InclusionExclusion;