import React, { Component } from "react";
import BookingBox from "../BookingBox";
import Slider from "react-slick";
import ApiURL from "../../../api/ApiURL";
import { Link } from "react-router-dom";
import axios from "axios";
import cogoToast from "cogo-toast";
import { Digital } from "react-activity";
import "react-activity/dist/library.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InclusionExclusion from "../Sundarban/InclusionExclusion_sundarban";
class Sundarban_kolkataTourPackage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customActiveTab: 'tab1',
      daywiseActiveTab: 'day1',
      currentPage: 1,
      btn: "Submit",
      showLoader: false,
      isHidden: true,
      showModal: false,
      originCities: [],
      firstName: '',
      lastName: '',
      mobile: '',
      email: '',
      city: '',
      travelDate: '',
      ticketBookedStatus: '',
      noOfGuests: '',
      hotelCategory: '',
      comments: ''
    };
  }

  handleCustomTabClick = (tab) => {
    this.setState({
      customActiveTab: tab,
    });
  };

  handleDayWiseTabClick = (tab) => {
    this.setState({
      daywiseActiveTab: tab,
    });
  };
  handleClick1 = () => {
    const currentUrl = window.location.href;
    const pageTitle = document.title;
    const message = `Hi, Please send about the Tour Package details "${pageTitle}"`;

    const whatsappLink = `https://api.whatsapp.com/send?phone=+919883359713&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
    window.open(whatsappLink, '_blank');
  };
  handlePageChange = (direction) => {
    this.setState((prevState) => {
      const newPage = prevState.currentPage + direction;
      return { currentPage: newPage };
    });
  };

  componentDidMount() {
    var _self = this;
    _self.getAllOriginCities();
  }


  onRequestCallback = (e) => {
    e.preventDefault();
    this.setState({ showModal: true });
  }



  onCloseModal = (e) => {
    e.preventDefault();
    this.setState({
      ...this.state,
      showModal: false,
      firstName: '',
      lastName: '',
      mobile: '',
      email: '',
      city: '',
      travelDate: '',
      ticketBookedStatus: '',
      noOfGuests: '',
      hotelCategory: '',
      comments: ''
    });
  }

  onChangeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  getAllOriginCities = () => {
    axios.get(ApiURL.GetAllOriginCities).then(response => {
      //(ApiURL.GetAllOriginCities)
      let respData = response.data;
      console.log(respData)
      if (respData.StatusCode === 200) {
        let dataResult = respData.DataResult;
        this.setState({ btn: "Submit", originCities: dataResult });

      } else if (respData.StatusCode === 400) {
        let dataResult = respData.DataResult;
        let message = respData.Message + '\n\t' + dataResult[0];
        cogoToast.error(message, { position: 'bottom-center' });
        this.setState({ btn: "Submit" });


      } else if (respData.StatusCode === 500) {
        this.setState({ btn: "Submit" });
        cogoToast.error("Internal Server Error ! Please Try Again.", { position: 'bottom-center' });
      }

    }).catch(error => {
      this.setState({ btn: "Submit" });
      cogoToast.error("Request Failed! Please Try Again.", { position: 'bottom-center' })
    })

  }

  getCityOptions() {
    let originCities = this.state.originCities;
    console.log(originCities)
    return originCities.map((city) => {
      return <option key={city.CityId} value={city.CityName}>{city.CityName}</option>;
    });
  }


  // Function to handle form submission
  onRequestCallbackSubmit = async (e) => {
    e.preventDefault();

    const { firstName, lastName, email, mobile, city, travelDate, ticketBookedStatus, noOfGuests, hotelCategory, comments } = this.state;

    // Validation
    if (firstName.trim() === '') {
      cogoToast.error("First Name Required.", { position: 'bottom-center' });
    } else if (lastName.trim() === '') {
      cogoToast.error("Last Name Required.", { position: 'bottom-center' });
    } else if (email.trim() === '') {
      cogoToast.error("Email Required.", { position: 'bottom-center' });
    } else if (mobile.trim() === '' || !/^\d{10}$/.test(mobile.trim())) {
      cogoToast.error("Invalid Mobile No.", { position: 'bottom-center' });
    } else if (travelDate.trim() === '') {
      cogoToast.error("Travel Date Required.", { position: 'bottom-center' });
    } else {
      this.setState({ btn: "Processing..", showLoader: true });
      // Proceed with form submission
      try {
        const _ref = 'yes';
        const _url = window.location.href;

        const formData = {
          FirstName: firstName,
          LastName: lastName,
          Email: email,
          Mobile: mobile,
          City: city,
          TravelDate: travelDate,
          TicketBooked: ticketBookedStatus,
          NoOfGuests: noOfGuests,
          HotelCategory: hotelCategory,
          Comments: comments,
          Referrer: _ref,
          URL: _url
        };

        const response = await axios.post(ApiURL.SaveCustomerEnquery, formData);
        console.log('Data submitted successfully:', response.data);
        // Optionally, reset the form state after successful submission
        // this.setState({ firstName: '', lastName: '', email: '', mobile: '', ... });

        // Optionally, show a success message to the user
        cogoToast.success("Form submitted successfully.", { position: 'bottom-center' });
        this.setState({ btn: "Submit", showLoader: false });
      } catch (error) {
        console.error('Error submitting data:', error);
        this.setState({ btn: "Submit", showLoader: false });
        // Optionally, show an error message to the user
        cogoToast.error("Failed to submit form. Please try again later.", { position: 'bottom-center' });
      }

    }

  };



  /* RedirectToOTPPage = () => {
    if (this.state.redirectOTPStatus === true) {
        let url = "/verifyotp/" + this.state.mobile;
        return <Redirect to={url} />
    }
  } */

  render() {
    const rows = [
      { train: '34512, Sealdah - Canning Local', departure: '05:45', arrival: '06:57' },
      { train: '34514, Sealdah - Canning Local', departure: '06:30', arrival: '07:44' },
      { train: '34516, Sealdah - Canning Local', departure: '07:42', arrival: '08:56' },
      { train: '34518, Sealdah - Canning Local', departure: '08:28', arrival: '09:44' },
      { train: '34520, Sealdah - Canning Local', departure: '09:10', arrival: '10:25' },
      { train: '34522, Sealdah - Canning Local', departure: '10:24', arrival: '11:40' },
    ];

    const rowsPerPage = 6;
    const totalPages = Math.ceil(rows.length / rowsPerPage);
    const currentRows = rows.slice((this.state.currentPage - 1) * rowsPerPage, this.state.currentPage * rowsPerPage);
    const { customActiveTab, daywiseActiveTab } = this.state;
    const packageTopBannerImageURL = ApiURL.Sundarban_Kolkata_PackageImageURL + "/Sunderban-Package-Tour-from-Kolkata.webp";
    const packageTopBanner2ImageURL = ApiURL.Sundarban_Kolkata_PackageImageURL + "/Sunderban-Tour-Packages-from-Kolkata.webp";
    const Hotelsonarbangla1ImageURL = ApiURL.Sundarban_Kolkata_PackageImageURL + "/SUNDARBAN-PREVIOUS-GROUP-TOUR.webp";
    const Hotelsonarbangla2ImageURL = ApiURL.Sundarban_Kolkata_PackageImageURL + "/sundarban-tour-packages-kolkata-sonar-bangla.png";
    const Hotelsonarbangla3ImageURL = ApiURL.Sundarban_Kolkata_PackageImageURL + "/SUNDARBAN-CAPTURE.webp";
    const HotelsonarbanglaFoodImageURL = ApiURL.Sundarban_Kolkata_PackageImageURL + "/Sundarban-tour-with-Hotel-Sonar-Bangla.webp";
    const HotelsonarbanglaBoatImageURL = ApiURL.Sundarban_Kolkata_PackageImageURL + "/Hotel-Sonar-Bangla-Sundarban-package-tour.webp";
    const reviewersDataList = this.props.ReviewersData;
    const reviewersDataView = reviewersDataList.map((dataItem, i) => {
      const reviewerImageURL =
        ApiURL.Sundarban_Kolkata_PackageImageURL + "/" + dataItem.ImageName;
      return (
        <div className="testimonial-box" key={i}>
          <div className="box-top">
            <div className="profile">
              <div className="profile-img">
                <img src={reviewerImageURL} alt="..." />
              </div>
              <div className="name-user">
                <strong>{dataItem.Name}</strong>
                <span>{dataItem.Location}</span>
              </div>
            </div>
            <div className="reviews">
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
            </div>
          </div>
          <div className="client-comment">
            <p>{dataItem.Comment}</p>
          </div>
        </div>
      );
    });

    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <img
              src={packageTopBannerImageURL}
              className="mobile-image2"
              alt="..."
            />
          </div>
        </div>

        <div className="container-fluid custom-container py-3">
          <div className="row">
            <div className="col-md-9">
              <div className="row">
                <div className="col-md-12">
                  <div className="container-fluid custom-container">
                    <h1 className="content-desktop" style={{ fontSize: "2.0vw" }}><center><strong> Sundarban Package Tour from Kolkata</strong></center></h1>
                    <h1 className="content-mobile" style={{ fontSize: "4vw" }}><center><strong> Sundarban Package Tour from Kolkata</strong></center>
                      <hr />
                    </h1>
                    <div className="row">
                      <img src={packageTopBanner2ImageURL} className="mobile-image" alt="..." />
                    </div>
                    <hr />
                    <div className="col-12">
                      <big className="text-muted"><strong className="tomato">2N/3D</strong></big>&nbsp;
                      <i className="fa fa-arrow-right"></i>&nbsp;Ex-Kolkata&nbsp;
                      <i className="fa fa-arrow-right"></i>&nbsp;Fooding & Accommodation&nbsp;
                      <i className="fa fa-arrow-right"></i>&nbsp;Guide&nbsp;
                      <i className="fa fa-arrow-right"></i>&nbsp;Luxury Boat&nbsp;
                      <br />
                    </div>
                    <p className="p-3">
                      <strong> Sundarban package tour from Kolkata</strong> offers a comfortable stay with beautiful views of the Sundarbans.
                    </p>
                    <hr />
                    <br />

                    <h2 className="content-desktop p-2" style={{ fontSize: "1.8vw" }}> For Booking <strong>Sundarban Tour Package from Kolkata</strong> <a href='tel:9883359713' class="tomato">☎ CALL Mr. Soumalya Ghosh  +91- 98833-59713</a></h2>
                    <h3 className="content-mobile p-4" style={{ fontSize: "4.5vw" }}> For Booking <strong>Sundarban Tour Package from Kolkata</strong> <a href='tel:9883359713' class="tomato">☎ CALL Mr. Soumalya Ghosh  +91- 98833-59713</a></h3>
                    {/* <h3 className="p-2"><b>
                      Looking For Best Bhutan Tour Packages From Mumbai? <br />  <a href='tel:9883359713' className="tomato"> CALL US NOW ☎ +91-98833-59713</a></b></h3> */}
                    <br />

                    <div className="card">
                      <div
                        className="card-header custHeaer"
                      >
                        <h3 className="content-desktop" style={{ fontSize: "1.7vw" }}> <strong>Best Sundarban Tour Operator</strong> in Kolkata</h3>
                        <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}> <strong>Best Sundarban Tour Operator</strong> in Kolkata</h4>
                      </div>
                      <div className="card-body">
                        <p>Winter is the perfect time for weekend trips with friends and family. Have you planned your next family tour? Surprise your loved ones with an exciting trip by booking a <strong>Sundarban package tour from Kolkata</strong> with the most trusted and affordable <strong>Sundarban tour operator in Kolkata</strong> - Adorable Vacation.</p>
                        <p>Adorable Vacation is the best <strong>Sundarban travel agency in Kolkata</strong>. They offer the best <strong>Sundarban tour package</strong> thanks to their excellent knowledge of the area, hotels, and other services. This <strong>Sundarban tour agency in Kolkata</strong> started 11 years ago to serve premium tourists from all over India, offering trips to destinations like Sundarban, Sandakphu, North Bengal, Bhutan, Sikkim, Darjeeling, Ladakh, and Northeast India at affordable prices. The company has quickly become the most respected and trusted DMC for <strong>Sundarban tour packages from Kolkata</strong>. Now, let's learn a bit about Sundarban.</p>
                        <p>Located just 100 km or a 2-hour journey from Kolkata, Sundarban is a favorite destination for <strong>weekend travel near Kolkata</strong>. With lush mangroves and full rivers, this amazing place attracts millions of visitors from Kolkata, India, and around the world. Its scenic beauty, fresh environment, wildlife, and serene atmosphere make it the best destination for <strong>weekend travel near Kolkata</strong>.</p>
                        <p >If you love nature, wildlife, and tranquility, book a <strong>Sundarban Package Tour from Kolkata</strong>. As you leave the crowded city, you'll enter a new world with no city noise or pollution, just greenery, rivers, canals, and friendly village faces until you reach your first pickup point - Godkhali. Here, you’ll notice a big difference from city life. An exciting journey through clear rivers will follow. So, book your <strong>Sundarban Tour Package from Kolkata</strong> now with Adorable Vacation.</p>
                        <p>Adorable Vacation has been the best <strong>Sundarban tour operator from Kolkata</strong> for over 12 years. They have successfully organized many <strong>Sundarban Tour for 1 Night 2 Days</strong>, <strong>Sundarban Tour for 2 Nights 3 Days</strong>, and <a href="/sundarban-package-tour-from-kolkata-with-hotel-sonar-bangla" target="_blank">Sundarban tour packages with Hotel Sonar Bangla</a>. They specialize in affordable customized Sundarban packages with the best resorts, hotels, boats, and delicious food. They offer home/hotel pickup and drop-off facilities for customized trips and can arrange tours on short notice. You can start your <strong>Sundarban trip from Kolkata Airport</strong>, <strong>Sundarban tour from Howrah Station</strong>, <strong>Sundarban tour from Canning Station</strong>, or <strong>Sundarban tour from Sealdah Station</strong>.</p>
                        <br />
                        <div className="content-desktop">
                          <center><img src={Hotelsonarbangla3ImageURL} alt="..." /></center>
                        </div>
                        <div className="content-mobile2">
                          <center><img src={Hotelsonarbangla3ImageURL} style={{ width: '320px' }} alt="..." /></center>
                        </div>
                        <p>Sundarban is a UNESCO World Heritage Site with diverse flora and fauna, especially the Royal Bengal Tiger. On many tours, Adorable Vacation has shown guests the majestic Royal Bengal Tiger. Visit this beautiful land with immense natural beauty and biodiversity and enjoy great food on your journey. It will be a memorable trip with Adorable Vacation.</p>
                        <p>Besides the <strong>Sundarban package from Kolkata</strong>, Adorable Vacation specializes in the <a href="/sundarban-package-tour-from-kolkata-with-hotel-sonar-bangla">Hotel Sonar Bangla Sundarban package tour</a>, where luxury meets adventure in the heart of the wild. Their exclusive <a href="/sundarban-package-tour-from-kolkata-with-hotel-sonar-bangla">Hotel Sonar Bangla Sundarban Package Tour</a> offers a perfect weekend getaway with spacious rooms, modern amenities, and recreational facilities including swimming pools, fitness centers, gyms, spas, bars, libraries, playrooms, and kids' rooms. Enjoy a variety of local and international cuisines prepared by skilled chefs. Surrounded by lush greenery and stunning views, the hotel provides the perfect ambiance for relaxation and rejuvenation. The dedicated staff ensures unparalleled hospitality, making it one of the best properties in the <strong>Sundarbans for weekend holidays</strong>. Book your stay today and discover the magic of the Sundarbans like never before.</p>

                        <br />
                        <a href="tel://9883359713" className="btn btn-Warning">
                          Book Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container-fluid py-5">
                <div className="content-desktop">
                <center><img src={Hotelsonarbangla1ImageURL} alt="..." /></center>
                </div>
                <div className="content-mobile2">
                <center><img src={Hotelsonarbangla1ImageURL} style={{ width: '320px' }} alt="..." /></center>
                </div>
              </div>

              <br />

              <br />
              <h3 className="content-desktop" style={{ fontSize: "1.9vw" }}><center><strong className="tomato"><u>SUNDARBAN TOUR PACKAGE PRICE FROM KOLKATA</u></strong></center></h3>
              <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}><center><strong className="tomato"><u>SUNDARBAN TOUR PACKAGE PRICE FROM KOLKATA</u></strong></center></h3>
              <br />
              <div className="container-fluid navtabCustom">
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  <button onClick={() => this.handleCustomTabClick('tab1')} className={`tab button ${customActiveTab === 'tab1' ? 'active' : ''}`} role="tab" aria-controls="nav-price" aria-selected={customActiveTab === 'tab1'}>Price</button>

                  <button onClick={() => this.handleCustomTabClick('tab3')} className={`tab button ${customActiveTab === 'tab3' ? 'active' : ''}`} role="tab" aria-controls="nav-travellers-info" aria-selected={customActiveTab === 'tab3'}>Travellers Info</button>

                  <button onClick={() => this.handleCustomTabClick('tab2')} className={`tab button ${customActiveTab === 'tab2' ? 'active' : ''}`} role="tab" aria-controls="nav-food" aria-selected={customActiveTab === 'tab2'}>Food</button>
                </div>

                <div className="tab-content" id="nav-tabContent">
                  <div className={customActiveTab === 'tab1' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-price-tab">
                    <div id="no-more-tables">
                      <table class="col-md-12 table-bordered table-striped table-condensed cf">
                        <thead class="cf">
                          <tr className="table-danger">
                            <th>Package Category</th>
                            <th>Tour Duration</th>
                            <th>Tour Price(PP)</th>
                            <th>BOOK NOW</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td data-title="Package Category">Standard Room</td>
                            <td data-title="Tour Duration">2 Nights 3 Days</td>
                            <td data-title="Tour Price(PP)">Rs. 5,300 /-</td>
                            <td data-title="Book Now"> <a href="#" className="btn btn-success" onClick={this.onRequestCallback}>
                              Book Now
                            </a></td>
                          </tr>
                          <tr>
                            <td data-title="Package Category">Deluxe Cottage</td>
                            <td data-title="Tour Duration">2 Nights 3 Days</td>
                            <td data-title="Tour Price(PP)">Rs. 6,300 /-</td>
                            <td data-title="Book Now"> <a href="#" className="btn btn-success" onClick={this.onRequestCallback}>
                              Book Now
                            </a></td>
                          </tr>
                          <tr>
                            <td data-title="Package Category">Deluxe Room</td>
                            <td data-title="Tour Duration">2 Nights 3 Days</td>
                            <td data-title="Tour Price(PP)">Rs. 7,200 /-</td>
                            <td data-title="Book Now"> <a href="#" className="btn btn-success" onClick={this.onRequestCallback}>
                              Book Now
                            </a></td>
                          </tr>
                          <tr>
                            <td data-title="Package Category">Premium Room</td>
                            <td data-title="Tour Duration">2 Nights 3 Days</td>
                            <td data-title="Tour Price(PP)">Rs. 8,100 /-</td>
                            <td data-title="Book Now"> <a href="#" className="btn btn-success" onClick={this.onRequestCallback}>
                              Book Now
                            </a></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className={customActiveTab === 'tab3' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-travellers-info-tab">
                    <h5><b>Important Guidelines for Sundarban Tour Packages with Hotel Sonar Bangla</b></h5>
                    <p><b>1.	ID Requirements:</b></p>
                    <ul>
                      <li><b>Indian Nationals:</b> Carry a photo ID with two photocopies.</li><br />
                      <li><b>Foreigners:</b> Carry your original passport with a valid Indian Visa.</li>
                    </ul><br />
                    <p><b>2.	Health Precautions:</b></p>
                    <ul>
                      <li>Bring common medicines for any unexpected health needs.</li>
                    </ul><br />
                    <p><b>3.	Environmental Care:</b></p>
                    <ul>
                      <li>Sundarban is a plastic-free zone. Do not use plastic or throw anything from the boat.</li>
                    </ul><br />
                    <p><b>4.	Prohibited Activities:</b></p>
                    <ul>
                      <li>Hunting, fishing, and damaging flora are serious offenses.</li>
                    </ul>
                    <p><b>5.	Cash and Permits:</b></p>
                    <ul>
                      <li>Carry cash as ATMs are limited. Do not enter Sundarbans National Park/Tiger Reserve without a valid permit.</li>
                    </ul>
                    <p><b>6.	Respect for Nature:</b></p>
                    <ul>
                      <li>Dress in colors that blend with nature. Do not enter the protected area after sunset or before sunrise.</li>
                      <li>Maintain a safe distance from wild animals and avoid provoking them.</li>
                      <li>Do not play any sound systems during your visit.</li>
                    </ul><br />
                    <p><b>7.	Restricted Areas and Items:</b></p>
                    <ul>
                      <li>Entry into the Core/Non-tourism area is prohibited.</li>
                      <li>Carrying guns, weapons, bows, arrows, drones, and inflammable materials is strictly prohibited.</li>
                    </ul><br />
                    <p><b>8.	Essentials to Carry:</b></p>
                    <ul>
                      <li>Sunglasses, hats, and sunscreen.</li>
                      <li>Comfortable footwear, a camera, and binoculars.</li>
                      <li>Mask, hand sanitizer, and maintain social distance.</li>
                    </ul><br />
                    <p><b>9.	Seasonal Clothing:</b></p>
                    <ul>
                      <li><b>Winter:</b> Bring warm clothes as it might get chilly.</li>
                      <li><b>Summer:</b> Opt for light and comfortable clothing.</li>
                    </ul><br />
                    <p><b>10.	Food and Beverages:</b></p>
                    <ul>
                      <li>All food and beverages must be enjoyed from the property itself. Outside food deliveries (Zomato, Swiggy, etc.) are not allowed.</li>
                    </ul><br />
                    <p><b>11.	Pets:</b></p>
                    <ul>
                      <li>Pets are welcome at Hotel Sonar Bangla Sundarban. Ensure they are properly leashed.</li>
                    </ul><br />
                    <p><b>12.	Unmarried Couples:</b></p>
                    <ul>
                      <li>Unmarried couples are allowed but must submit valid ID proofs.</li>
                    </ul><br />
                    <p><b>13.	Private Parties and Events:</b></p>
                    <ul>
                      <li>You can arrange private parties or events, following all rules and regulations.</li>
                    </ul><br />
                    <p><b>14.	Visitors Policy:</b></p>
                    <ul>
                      <li>Guests cannot invite outside visitors into their rooms during their stay.</li>
                    </ul><br />
                    <p><b>15.	Swimming Pool Etiquette:</b></p>
                    <ul>
                      <li>Follow proper rules, decorum, and etiquette at the swimming pool.</li>
                    </ul>
                    <br /><br />
                  </div >
                  <div className={customActiveTab === 'tab2' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-food-tab">
                    <p><b>Foods We Offer in the 2 Nights 3 Days Sundarban Package Tour from Kolkata with Adorable Vacation</b></p><br />
                    <p>Guests traveling to Sundarban with <strong>Adorable Vacation</strong> love good food. Depending on the tour duration (1 Night 2 Days or 2 Nights 3 Days), we offer delicious meals at the resort or on the boat:</p><br />
                    <p><strong>Welcome Drink:</strong> Green Coconut / Tea / Coffee</p>
                    <p><strong>Breakfast:</strong> Hing Kachuri / Paratha / Luchi with Veg Curry / Chana Masala / Cholar Daal, Banana/Orange, Sweet, Tea/Coffee</p>
                    <p><strong>Morning Snacks:</strong> Prawn Pakora / Onion Pakora</p>
                    <p><strong>Lunch:</strong> Rice/Roti, Dal, Potato Fry, Mix Veg Curry, Doi Katla Fish / Pabda Jhol / Bhetki Fish Curry / Hilsa Fish / Prawn Curry / Crab Curry, Chutney, Papad, Salad, Sweet</p>
                    <p><strong>Evening Snacks:</strong> Veg Pakora / Chicken Pakora / Chowmein / Samosa, Salad, Coffee/Tea</p>
                    <p><strong>Dinner:</strong> Roti / Rice / Fried Rice / Dal, Veg Fry, Veg Curry, Veg Biryani, Chilli Chicken / Chicken Kasa, Salad, Sweet</p>
                    <p>Enjoy these tasty meals during your Sundarban tour package with Adorable Vacation LLP !</p>
                  </div>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.7vw" }}><strong>3 Unique Key Point of Our Sundarban Tour from Kolkata</strong></h3>
                  <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>3 Unique Key Point of Our Sundarban Tour from Kolkata</strong></h3>
                </div>
                <div className="card-body">
                  <p> <strong>Sundarban Tour is Cost-Effective</strong></p>
                  <p>
                    Our <strong>Sundarban package tour from Kolkata</strong> is designed so you don't have to spend any extra money from the start of your journey. You will find our <strong>Sundarban tour cost from Kolkata</strong> very reasonable and competitive. Our tour includes everything you need: AC/Non-AC transportation, all sightseeing in a luxury boat with safety equipment, deluxe and luxury rooms in a resort, delicious meals with seasonal specialties, and entertainment like local folk songs and dances, Baul songs, Tusu songs and dances, DJ nights, and open BBQs. Adorable Vacation is committed to providing you with the best service to make your <strong>Sundarban package tour</strong> a lifetime experience.
                  </p>
                  <p><strong>Sundarban Trip Requires Less Packing</strong></p>
                  <p>Packing for a <strong>Sundarban trip</strong> is exciting. Since your tour is during the winter holidays, pack enough warm clothes. Don't forget a woolen cap, gloves, shoes, flip flops, and essential medicines. Bring a camera, binoculars, and a power bank if you want to capture great shots of the wildlife. Load your mobile with your favorite songs and movies as internet connectivity is slow in Sundarban, and some areas have no signal at all. At our resort, you can buy your favorite brand of cigarettes and hard drinks. Get ready and let's start the trip!</p>
                  <p><strong>Satisfaction is Guaranteed During Sundarban Tour from Kolkata</strong></p>
                  <p>The ultimate goal of your <strong>Sundarban tour from Kolkata</strong> is satisfaction. You don't need to pay anything extra during your trip. The transportation, food, resort room, and entertainment provided by Adorable Vacation will make your trip memorable and fulfilling. This is why our <strong>Sundarban tour cost</strong> is very reasonable. Satisfaction is 100% guaranteed for your Sundarban trip <strong>with Adorable Vacation.</strong></p>

                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.7vw" }}><strong>SUNDARBAN TOUR PACKAGE DURATION : 2 NIGHTS | 3 DAYS</strong></h3>
                  <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>SUNDARBAN TOUR PACKAGE DURATION : 2 NIGHTS | 3 DAYS</strong></h3>
                </div>
                <div className="card-body">
                  <p> Destinations Covered in <strong>2 Night 3 Days Sundarban Package tour :</strong> Gadkhali, Gosaba Island, Gosaba Island-Hamilton Bungalow, Bacon Bungalow, Pakhiralaya, Pirkhali, Banbibi Varani, Sundarkhali, Khonakhali, Choragaji, Deualvarani, SarakKhali, Gazikhali, Nobanki, Sudhanyakhali Watch Tower</p>
                  <p>
                    <strong>Pickup & Drop point for Sundarban Trip : </strong> Science City [for Exclusive Customized Sundarban Tour we can pick up you from Home/Office also]
                  </p>
                  <div id="no-more-tables">
                    <table class="col-md-12 table-bordered table-striped table-condensed cf">
                      <tbody>
                        <tr>
                          <td><center>Altitude of Sundarban</center></td>
                          <td><center>7.5 m [21.9497° N, 89.1833° E]</center></td>
                        </tr>
                        <tr>
                          <td><center>Nearest Rail Station</center></td>
                          <td><center>Canning Railway Station [CG]</center></td>
                        </tr>
                        <tr>
                          <td><center>Nearest Airport from Sundarban</center></td>
                          <td><center>Netaji Subhas Chandra Bose International Airport, Kolkata [CCU]</center></td>
                        </tr>
                        <tr>
                          <td><center>Nearest Rail Station</center></td>
                          <td><center>Canning Railway Station [CG]</center></td>
                        </tr>
                        <tr>
                          <td><center>Nearest Hospital from Sundarban</center></td>
                          <td><center>Canning Sub Divisional Hospital | Phone: (+91) 97325 95449</center></td>
                        </tr>
                        <tr>
                          <td><center>Nearest Police Station from Sundarban</center></td>
                          <td><center>Gosaba Police Station | Phone: 03218 236 566</center></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br />
                  <p>Adorable Vacation invites you to experience the sublime beauty of the Sundarbans with a stay at Hotel Sonar Bangla, where luxury meets nature in perfect harmony. Discover the enchanting <strong>Sonar Bangla Sundarban package</strong> and embark on an unforgettable journey with our exclusive <strong>Sonar Bangla Sundarban package tour</strong>. Immerse yourself in the beauty of the Sundarbans through our curated <a href="/sundarban-package-tour-from-kolkata-with-hotel-sonar-bangla">Sundarban tour package with Sonar Bangla</a> for a truly captivating experience.</p>
                  <br />
                  <p>Located in the heart of the Sundarbans, Hotel Sonar Bangla offers the epitome of luxury hospitality. Nestled on the banks of the picturesque Gomti River in a serene village setting, Hotel Sonar Bangla is the first luxury forest resort, featuring 75 opulent rooms and cottages. From luxury to premium lodging, our accommodations include suites that offer breathtaking panoramic views of the river and the mangrove environment.</p>
                  <p>Hotel Sonar Bangla Sundarban stands as a premier destination, offering a plethora of upscale services and facilities to guarantee a memorable stay. Immerse yourself in the tranquility of the Sundarbans while enjoying extraordinary amenities. We are dedicated to ensuring that our guests have an exceptional time exploring the serene attractions and activities in the vicinity. Reserve your stay today and let Hotel Sonar Bangla redefine your Sundarban experience with Adorable Vacation.</p>
                </div>
              </div>
              <br /><br />

              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <table className="table table-bordered">
                      <thead>
                        <tr className="table-danger">
                          <th>SUMMERY OF SUNDARBAN TOUR PLAN FROM KOLKATA</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td
                            className="clickable"
                          ><a href="#day1" style={{color:'black'}}>
                            <b>DAY 1 → </b>
                            <span>
                            PICKUP FROM KOLKATA - TRANSFER TO SUNDARBAN
                            </span>
                            </a>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                          >
                            <a href="#day2" style={{color:'black'}}>
                            <b>DAY 2 → </b>
                            <span>SUNDARBAN FULL DAY SIGHTSEEING [CULTURAL SHOW IN THE EVENING]</span>
                            </a>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                          >
                            <a href="#day3" style={{color:'black'}}>
                            <b>DAY 3 → </b>
                            <span> KOLKATA DEPARTURE [WITH PACKED LUNCH]</span>
                            </a>
                          </td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                  <br />
                  <p>When we think of a Sundarban tour from Kolkata, the first image that comes to mind is the thrilling sight of the <strong>Royal Bengal Tiger</strong> in its natural habitat. Spotting this majestic creature is the highlight of any <strong>Sundarban tour</strong>. However, it's not the only attraction that makes a <strong>Sundarban package tour</strong> worth booking. The <strong>Sundarban safari</strong> offers the chance to witness a wide variety of birds, reptiles, and aquatic creatures in their natural environment. If you seek a leisurely escape, this trip from Kolkata is ideal, combining a scenic drive through beautiful landscapes with a cruise through the endless rivers, creeks, and channels of <strong>Sundarban National Park</strong>.</p>
                  <p>Your <strong>Sundarban leisure trip</strong> can be even more relaxing with a stay in a luxurious cottage, resort, or hotel. Enjoy activities such as bonfires, local grilled chicken, folk dance performances, or dancing to the beats of a DJ. This getaway will allow you to escape the stress and chaos of city life for two days, leaving you rejuvenated and ready to face daily challenges anew. <strong>A Sundarban tour</strong> is always an amazing experience.</p>
                </div>
              </div>

              <br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.7vw" }}><strong>DISTANCE & TRAVEL DURATION TO NOTE FOR KOLKATA TO SUNDARBAN TOUR</strong></h3>
                  <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>DISTANCE & TRAVEL DURATION TO NOTE FOR KOLKATA TO SUNDARBAN TOUR</strong></h3>
                </div>
                <div className="card-body">
                  <div id="no-more-tables">
                    <table class="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead class="cf">
                        <tr className="table-danger">
                          <th>Places From</th>
                          <th>Places To</th>
                          <th>Road  Distance</th>
                          <th>Travel Duration</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td data-title="Place From">Science City</td>
                          <td data-title="Places To">Sundarban</td>
                          <td data-title="Road Distance">104 km</td>
                          <td data-title="Travel Duration">3 hrs</td>
                        </tr>
                        <tr>
                          <td data-title="Place From">Deshapriya Park</td>
                          <td data-title="Places To">Sundarban</td>
                          <td data-title="Road Distance">106 km</td>
                          <td data-title="Travel Duration">3 hrs</td>
                        </tr>
                        <tr>
                          <td data-title="Place From">Kolkata Airport</td>
                          <td data-title="Places To">Sundarban</td>
                          <td data-title="Road Distance">120 km</td>
                          <td data-title="Travel Duration">3.30 hrs</td>
                        </tr>
                        <tr>
                          <td data-title="Place From">Sealdah Station</td>
                          <td data-title="Places To">Sundarban</td>
                          <td data-title="Road Distance">108 km</td>
                          <td data-title="Travel Duration">3.10 hrs</td>
                        </tr>
                        <tr>
                          <td data-title="Place From">Howrah Station</td>
                          <td data-title="Places To">Sundarban</td>
                          <td data-title="Road Distance">111 km</td>
                          <td data-title="Travel Duration">3.20 hrs</td>
                        </tr>
                        <tr>
                          <td data-title="Place From">Canning Station</td>
                          <td data-title="Places To">Sundarban</td>
                          <td data-title="Road Distance">66.7 km</td>
                          <td data-title="Travel Duration">1.45 hrs</td>
                        </tr>
                        <tr>
                          <td data-title="Place From">Durgapur</td>
                          <td data-title="Places To">Sundarban</td>
                          <td data-title="Road Distance">605 km</td>
                          <td data-title="Travel Duration">6.11 hrs</td>
                        </tr>
                        <tr>
                          <td data-title="Place From">Asansole</td>
                          <td data-title="Places To">Sundarban</td>
                          <td data-title="Road Distance">209 km</td>
                          <td data-title="Travel Duration">4.11 hrs</td>
                        </tr>
                        <tr>
                          <td data-title="Place From">Dum Dum</td>
                          <td data-title="Places To">Sundarban</td>
                          <td data-title="Road Distance">120 km</td>
                          <td data-title="Travel Duration">3.45 hrs</td>
                        </tr>
                        <tr>
                          <td data-title="Place From">Jadavpur</td>
                          <td data-title="Places To">Sundarban</td>
                          <td data-title="Road Distance">103 km</td>
                          <td data-title="Travel Duration">3.10 hrs</td>
                        </tr>
                        <tr>
                          <td data-title="Place From">Tollygunge</td>
                          <td data-title="Places To">Sundarban</td>
                          <td data-title="Road Distance">106 km</td>
                          <td data-title="Travel Duration">3.20 hrs</td>
                        </tr>
                        <tr>
                          <td data-title="Place From">Ballygunge</td>
                          <td data-title="Places To">Sundarban</td>
                          <td data-title="Road Distance">108 km</td>
                          <td data-title="Travel Duration">3.30 hrs</td>
                        </tr>
                        <tr>
                          <td data-title="Place From">Barrackpore</td>
                          <td data-title="Places To">Sundarban</td>
                          <td data-title="Road Distance">130 km</td>
                          <td data-title="Travel Duration">4.10 hrs</td>
                        </tr>
                        <tr>
                          <td data-title="Place From">Sonarpur</td>
                          <td data-title="Places To">Sundarban</td>
                          <td data-title="Road Distance">98.4 km</td>
                          <td data-title="Travel Duration">3.00 hrs</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                </div>
              </div>
              <br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.7vw" }}><strong>WHICH IS THE GOOD TIME FOR SUNDARBAN PACKAGE BOOKING FROM KOLKATA?</strong></h3>
                  <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>WHICH IS THE GOOD TIME FOR SUNDARBAN PACKAGE BOOKING FROM KOLKATA?</strong></h3>
                </div>
                <div className="card-body">
                  <p><strong>WINTER (END OF NOVEMBER TO EARLY MARCH)</strong></p>
                  <p>Winter is the best time to book a <strong>Sundarban package tour from Kolkata</strong>, especially from the end of October to early March. The weather is cold and pleasant, with temperatures dropping to around 6°C. The mystic and foggy Sundarban in winter is particularly adventurous and thrilling, being the world's largest mangrove forest and home to the famous Royal Bengal Tiger.</p>
                  <p>In the mornings, temperatures can drop as low as 4 degrees due to the vast open land and riverine environment. As the sun rises, the temperature becomes more comfortable for traveling. This is the best time to spot wild animals that emerge from the dense forest to bask in the sunlight.</p>
                  <p>Keep in mind that Sundarban is popular with travelers during winter, so it’s advisable to book your Sundarban package from Kolkata well in advance to avoid any hassle.</p>
                  <p><strong>MONSOON (END OF JUNE TO EARLY OCTOBER)</strong></p>
                  <p>Monsoon in Sundarban starts at the end of June and lasts until early October. Being a river-oriented delta region, Sundarban experiences overflowing rivers and heavy rainfall that can cause flood-like conditions. Roads become muddy, making any <strong>Sundarban Sightseeing Tour</strong> difficult. Therefore, booking a <strong>Sundarban Tour during monsoon</strong> is generally not advisable. However, some adventure lovers and travel enthusiasts may suggest exploring Sundarban during this season to experience the true beauty of its mangroves and rivers. A rain-washed green forest safari by boat through narrow creeks can be thrilling. The combination of wind, rain, and river offers a unique adventure in Sundarban during the monsoon.</p>
                  <p>SUMMER (MARCH-MAY)</p>
                  <p>Summer in Sundarban starts from the end of March and lasts until early June. This season is not very comfortable due to scorching heat and humidity, with temperatures rising up to 42°C. Most animals hide in the deep mangroves for shade, making wildlife spotting almost impossible. Additionally, the region sometimes experiences strong North-wester or Kalbaishakhi thunderstorms. Therefore, according to Adorable Vacation, summer is not an ideal time to travel to Sundarban.</p>
                  <p>For a luxurious and relaxing experience, consider booking the <strong>Hotel Sonar Bangla Sundarban package tour</strong>. This exclusive package offers a perfect blend of luxury and nature, making it an ideal choice for <strong>Sundarbans for weekend holidays</strong>. With 75 opulent rooms and cottages situated in a serene village setting on the banks of the picturesque Ganga river, the Hotel Sonar Bangla provides a premier destination with upscale services and facilities, ensuring a memorable stay. Enjoy the tranquil beauty of the Sundarbans while indulging in extraordinary amenities. Reserve your stay today and let the Hotel Sonar Bangla redefine your Sundarban experience.</p>
                </div>
              </div>
              <br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.7vw" }}><strong>MONTH WISE AVERAGE TEMPERATURE IN SUNDARBAN</strong></h3>
                  <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>MONTH WISE AVERAGE TEMPERATURE IN SUNDARBAN</strong></h3>
                </div>
                <div className="card-body">
                  <div id="no-more-tables">
                    <table class="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead class="cf">
                        <tr className="table-danger">
                          <th>Travel Months</th>
                          <th>Min Temp</th>
                          <th>Max Temp</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td data-title="Travel Months">January</td>
                          <td data-title="Min Temp">10°C</td>
                          <td data-title="Max Temp">15°C</td>
                        </tr>
                        <tr>
                          <td data-title="Travel Months">February</td>
                          <td data-title="Min Temp">10°C</td>
                          <td data-title="Max Temp">15°C</td>
                        </tr>
                        <tr>
                          <td data-title="Travel Months">March</td>
                          <td data-title="Min Temp">15°C</td>
                          <td data-title="Max Temp">24°C</td>
                        </tr>
                        <tr>
                          <td data-title="Travel Months">April</td>
                          <td data-title="Min Temp">25°C</td>
                          <td data-title="Max Temp">30°C</td>
                        </tr>
                        <tr>
                          <td data-title="Travel Months">May</td>
                          <td data-title="Min Temp">28°C</td>
                          <td data-title="Max Temp">38°C</td>
                        </tr>
                        <tr>
                          <td data-title="Travel Months">June</td>
                          <td data-title="Min Temp">28°C</td>
                          <td data-title="Max Temp">36°C</td>
                        </tr>
                        <tr>
                          <td data-title="Travel Months">July</td>
                          <td data-title="Min Temp">26°C</td>
                          <td data-title="Max Temp">32°C</td>
                        </tr>
                        <tr>
                          <td data-title="Travel Months">August</td>
                          <td data-title="Min Temp">26°C</td>
                          <td data-title="Max Temp">32°C</td>
                        </tr>
                        <tr>
                          <td data-title="Travel Months">September</td>
                          <td data-title="Min Temp">25°C</td>
                          <td data-title="Max Temp">31°C</td>
                        </tr>
                        <tr>
                          <td data-title="Travel Months">October</td>
                          <td data-title="Min Temp">25°C</td>
                          <td data-title="Max Temp">30°C</td>
                        </tr>
                        <tr>
                          <td data-title="Travel Months">November</td>
                          <td data-title="Min Temp">24°C</td>
                          <td data-title="Max Temp">28°C</td>
                        </tr>
                        <tr>
                          <td data-title="Travel Months">December</td>
                          <td data-title="Min Temp">20°C</td>
                          <td data-title="Max Temp">24°C</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.7vw" }}><strong>HOW WE CAN TRAVEL SUNDARBAN FROM KOLKATA?</strong></h3>
                  <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>HOW WE CAN TRAVEL SUNDARBAN FROM KOLKATA?</strong></h3>
                </div>
                <div className="card-body">
                  <p><strong>BY WATER WAY:</strong></p>
                  <p>Traveling to <strong>Sundarban</strong> by deluxe boat or cruise is an incredible experience. Many customized <strong>Sundarban tour operators</strong> offer tours from <strong>Kolkata</strong> by river, starting from <strong>Babu Ghat</strong> or <strong>Millennium Park</strong>. Embracing the cool winter vibes, your boat sails through the mystic fog, leaving behind a mesmerizing trail on the rippling water.</p>
                  <p>For those seeking a <strong>luxury river cruise</strong> for a <strong>Sundarban tour from Kolkata</strong>, <strong>Vivada Cruise M.V. Paramhamsa</strong> is an excellent choice. This 53-meter long, four-story boat features 32 furnished cabins with a wide range of luxury amenities. Enjoy delicious food, relax on the open deck, visit the library, watch LED TV, and use the modern washrooms. If this aligns with your travel aspirations, you can confidently choose this option.</p>
                  <p><strong>By Flight:</strong></p>
                  <p>If you are arriving by flight for your <strong>Sundarban tour</strong>, we will pick you up from <strong>Netaji Subhas Bose International Airport (CCU) </strong>– the nearest airport to Sundarban, located 110 km away. From there, we will travel by road in your pre-booked car and reach <strong>Godkhali</strong> within 2 hours, the hub to start your <strong>Sundarban Package Tour</strong>.</p>
                  <p><strong>By Train:</strong></p>
                  <p>If you prefer to reach <strong>Sundarban</strong> by train, opt for our <strong>Sundarban Tour from Canning</strong>. First, you need to reach <strong>Canning Station</strong>, which is part of the South Division of the Sealdah Train Service. The <strong>Canning Local</strong> departs from <strong>Sealdah Station</strong> every hour and takes about 1 hour and 15 minutes to cover the 45 km journey to Canning, passing through 18 stations. The journey is very scenic and comfortable, especially if you catch an early morning train and get a window seat. From <strong>Canning</strong>, your <strong>Sundarban Tour</strong> will begin. Here are the stations you will cover during the <strong>Sundarban Package Tour from Canning:</strong></p>
                  <p><strong>BY ROAD :</strong></p>
                  <p>By road also, you can reach Sundarban. This is the most convenient way to <strong>travel Sundarban from Kolkata</strong>. There are two different road ways that you choose for your <strong>Sundarban</strong> Tour. They are:</p>
                  <p><strong>Road 1 : </strong>Via Baruipur – Canning Road [2 hr 59 min / 82.9 km]<br />
                    <strong>Road 2 : </strong>Via Basanti Highway [3 hr 13 min / 86 km]<br />
                  </p>
                </div>
              </div>
              <br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.7vw" }}><strong>BEST TRAINS TO CATCH FOR SUNDARBAN TOUR FROM CANNING</strong></h3>
                  <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>BEST TRAINS TO CATCH FOR SUNDARBAN TOUR FROM CANNING</strong></h3>
                </div>
                <div className="card-body">
                  <p>&nbsp;KOLKATA [SEALDAH] &nbsp;
                    <i className="fa fa-arrow-right"></i>&nbsp;BALLYGUNGE JN&nbsp;
                    <i className="fa fa-arrow-right"></i>&nbsp;DHAKURIA&nbsp;
                    <i className="fa fa-arrow-right"></i>&nbsp;JADAVPUR&nbsp;
                    <i className="fa fa-arrow-right"></i>&nbsp;BAGHA JATIN&nbsp;
                    <i className="fa fa-arrow-right"></i>&nbsp;GARIA&nbsp;
                    <i className="fa fa-arrow-right"></i>&nbsp;NARENDRAPUR HALT&nbsp;
                    <i className="fa fa-arrow-right"></i>&nbsp;SONARPUR JN&nbsp;
                    <i className="fa fa-arrow-right"></i>&nbsp;BIDYADHARPUR&nbsp;
                    <i className="fa fa-arrow-right"></i>&nbsp;KALIKAPUR&nbsp;
                    <i className="fa fa-arrow-right"></i>&nbsp;CHAMPAHATI&nbsp;
                    <i className="fa fa-arrow-right"></i>&nbsp;PIALI&nbsp;
                    <i className="fa fa-arrow-right"></i>&nbsp;GOURDAHA (HALT)&nbsp;
                    <i className="fa fa-arrow-right"></i>&nbsp;GHUTIARI SHARIF&nbsp;
                    <i className="fa fa-arrow-right"></i>&nbsp;BETHBERIA&nbsp;
                    <i className="fa fa-arrow-right"></i>&nbsp;GHOLA&nbsp;
                    <i className="fa fa-arrow-right"></i>&nbsp;TALDI&nbsp;
                    <i className="fa fa-arrow-right"></i>&nbsp;CANNING&nbsp;
                  </p>
                  <br />
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf" style={{ width: '100%', height: '50%' }}>
                      <thead className="cf">
                        <tr className="table text-center">
                          <th>TRAIN</th>
                          <th>DEPARTURE [SEALDAH]</th>
                          <th>ARRIVAL [CANNING]</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentRows.map((row, index) => (
                          <tr className="text-center" key={index}>
                            <td data-title="Train">{row.train}</td>
                            <td data-title="Departure">{row.departure}</td>
                            <td data-title="Arrival">{row.arrival}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                  </div>
                </div>
              </div>
              <br />
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h3 className="content-desktop" style={{ fontSize: "2.0vw" }}>
                        DAY WISE PLAN FOR <strong className="tomato">SUNDARBAN TOUR PACKAGE FROM KOLKATA</strong>
                      </h3>
                      <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>
                        DAY WISE PLAN FOR <strong className="tomato">SUNDARBAN TOUR PACKAGE FROM KOLKATA</strong>
                      </h3>
                    </div>
                  </div>
                </div>

                <br />
                <div className="row">
                  <div
                    className="accordion"
                    id="accordionPanelsStayOpen"
                  >
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="day1"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseOne"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseOne"
                        >
                          DAY 1 : PICKUP FROM GODKHALI AND TRANSFER TO SUNDARBAN
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingOne"
                      >
                        <div className="accordion-body">
                          <p>Your <strong>Sundarban package tour from Kolkata</strong> will start from Science City, Kolkata and will transfer you to <strong>Godkhali</strong>, approximately a 3.5-hour drive from Science City Junction. You will meet and greet your Sundarban representative at <strong>Godkhali</strong> and board the designated boat waiting for you at the <strong>Godkhali jetty</strong>. Welcome drinks (green coconut) will be served on the boat along with tea and snacks. The boat will start sailing, and you will reach <strong>Gosaba Island</strong> in 45 minutes, the largest island in the Sundarban region.</p>

                          <p>Visit a few important sightseeing places on <strong>Gosaba Island</strong> - <strong>Hamilton Bungalow</strong>,<strong>Bacon Bungalow</strong>, and then proceed to a lovely resort. Enjoy the cool breeze from the river and relax at the resort.</p>
                          <p>Have lunch and start for <strong>Pakhiralaya</strong> (Home of Birds) by boat at around 4 pm, watching the different kinds of birds returning to their home. It is a wonderful experience with different birds chirping, making the place a paradise for bird lovers.</p>
                          <p>The evening is for enjoying a local folk dance performed by local village performers and having dinner. Some beautiful places to cover during the <strong>Sundarban Tour</strong>:</p>
                          <p><strong>Beautiful Port of Godkhali: Godkhali</strong> is essentially a port and a hub where every <strong>Sundarban Tour</strong> starts. After a long journey from Kolkata, guests refresh themselves here. You can take a small stroll around the area and the bazaar for last-minute shopping before your boating starts. Often guests take their morning breakfast at <strong>Godkhali</strong>.</p>
                          <p>
                            <strong className="tomato">Hamilton Bungalow: </strong> This is one of the most visited tourist spots in Sundarban. Here are some facts you must know before visiting this place:
                            <br />
                            <ul>
                              <li>•	This bungalow was first built as a single story by Scottish businessman Sir Daniel Mackinnon Hamilton in 1903. He was a businessman-cum-social worker who made Bengal his second home.</li>
                              <li>•	Sir Hamilton was one of the richest men in British India. He bought 40 sq km (almost 90,000 acres) of land from the then British government, comprising the three islands of <strong>Gosaba</strong>, <strong>Rangabelia</strong>, and <strong>Satjelia</strong>.</li>
                              <li>•	Sir Daniel was a great human being. He deeply involved himself in enhancing the living conditions of the poor people of Sundarban.</li>
                              <li>•	He introduced the co-operative system on <strong>Gosaba Island</strong> and started his philanthropic work to uplift the living conditions of the people here.</li>
                              <li>•	At that time, most of the island was barren and isolated. He brought many native people from Orissa, Bengal, and Bihar to <strong>Gosaba Island</strong> and settled them.</li>
                              <li>•	In 1932, Rabindranath Tagore visited Sundarban and stayed at this bungalow. The poet was aware of Sir Hamilton’s activities and exchanged views for the betterment of the region and its people.</li>
                            </ul><br />
                            <p><strong>Bird Watching in Sundarban:</strong> Sundarban is a great place for bird watching. Due to its location, vast green forest, salty river bed, and migratory route, a large number of birds can be seen here. It's a heaven for birders.</p>
                            <p>More than 250 species of birds are found in Sundarban, including Common Sandpiper, Eurasian Curlew, Grey Plover, Black-tailed Godwit, Marsh Sandpiper, Barn Swallow, Lesser Sand Plover, Chestnut-tailed Starling, Pacific Golden Plover, Pallas's Gull, Common Iora, Orange-headed Thrush, Tickell's Thrush, Yellow Wagtail, Mangrove Whistler, Oriental Magpie Robin, Dusky Warbler, Greenish Warbler, Jungle Babbler, and Scarlet-backed Flowerpecker. If you are a bird enthusiast, please contact us and book your <strong>Sundarban Bird Watching Tour</strong>.</p>
                          </p>
                          <p><strong className="tomato">Meals for Day 1 Sundarban Trip:</strong></p>
                          <ul>
                            <li>•	Welcome Drinks and Tea/Snacks on the boat.</li><br />
                            <li><strong className="tomato">•	Lunch:</strong> Rice/Roti, Dal, Potato Fry, Mix Veg Curry, Doi Katla Fish, Prawn Curry, Chutney, Papad, Salad, Sweet.</li><br />
                            <li><strong className="tomato">•	Evening Snacks:</strong> Chicken Pakora, Salad/Singara, Tea/Coffee</li><br />
                            <li><strong className="tomato">•	Dinner:</strong> Roti/Rice, Chilli Chicken, Fried Rice, Salad, and Sweet.</li><br />
                          </ul>
                          <div className="content-desktop">
                            <center><img src={HotelsonarbanglaBoatImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={HotelsonarbanglaBoatImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="day2"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseTwo"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseTwo"
                        >
                          Day 2: Full Day Sundarban Sightseeing Tour
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseTwo"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingTwo"
                      >
                        <div className="accordion-body">
                          <p>
                            This is the second day of your <strong>Sundarban tour package from Kolkata</strong>. If you are an early riser, it's a good opportunity for you to take a stroll around the resort, man-made dams and nearby villages. Visit river bank and watch the magnificent Sun rise from the top of mangroves. The cool ambience, the mystic fogs, the chirping of unknown birds is really enjoyable. After all it is absolutely fresh air that you can't avail in dusty Kolkata city. When the skies turned blue from the radiant orangish or yellowish dawn, it's amazing. Start a new day in Sundarban.
                          </p>
                          <p>Return back to resort. Enjoy your morning tea and get ready for upcoming day long trip. It is going to be exciting because soon you will start for Sundarban Jungle Safari through wide rivers, narrow creeks, sailing slowly among the cannels heavily banked with thick mangrove forest. The thrilling boat ride will always look for the wild animals like Royal Bengal Tiger, Spotted Deer, Crocodile, Wild Boar, and different kind birds. If you can really spot a Royal Bengal Tiger at their own home, feel lucky and proud for your<strong> Sundarban Trip</strong>. After breakfast board the boat and start for day long <strong>Sundarban Jungle Safari</strong>. We will cover following places:</p>

                          <p><strong className="tomato">•	Sajnekhali Watch Tower: </strong>provide the best view of mangrove from the top. It's a perfect place to witness beautiful birds and wild life.</p>
                          <p><strong className="tomato">•	Mangrove Interpretation Centre: </strong>provide some vital information about this mangroves. How it balance the eco-system of the region, its inhabitant and wildlife.</p>
                          <p><strong className="tomato">•	Banbib Varani:</strong> as per local belief, she is the guardian of the forest. She is also known as Bondevi or Bondurga. Fisherman and honey collector always takes blessing before visiting thick wild forest for honey collection or fishing.</p>
                          <p><strong className="tomato">•	Sudhanyakhali Watch Tower:</strong> is one of the best watchtowers in Sundarban to witness the Royal Bengal Tiger with a panoramic view of the dense mangrove forests.</p>
                          <p><strong className="tomato">•	Dobanki Canopy Walks & Watch Tower:</strong> this 496 M long netted canopies is really exciting to walk around the mangroves. Socking with mild warmth of winter a through walk over concrete made fenced canopy offers some of the best view of mangrove. Also, this walkway offers a beautiful view of the Sundarban Tiger Reserve Forests.</p>
                          <p><strong className="tomato">•	Panch Mukhi (5 River Junction):</strong> is very exciting place to behold and enjoy. It is a confluence of five rivers, Padma, Meghna, Bhairab, Madhumati & Hooghly. After Do-Banki watch tower tour, the boat cross this Mohona or 5 river junctions. This place is very wide, windy and wavy. Sometime gushing cool wind can chill your bones and unpredictable high wave can make the journey adventurous. This place is best visited during winter season only. During monsoon this place is not at all suggested to visit as the monsoon makes the rivers in full brink and extreme wavy.</p>
                          <p>Return back and take rest after 2 hrs long cruises. Exhausted with day long excursion get fresh, relax and gather in the banquet for evening snacks. Evening cultural program like Baul Song and Tusu Dance will be arranged for you. Enjoy it. You can spend the time with your own choice. Play song in light mode and dance around the bonfire. Do whatever you wish as this is the last night in Sundarban. After dinner proceed for a tight sleep in the arm of Sundarban.</p>
                          <p><b className="tomato">Mouth Watering  Meals for 2nd Day Sundarban Tour Package:</b></p>
                          <ul>
                            <li><b>•	Breakfast:</b> Hing Kachuri/Puri, Chana Masala, Banana, Sweet, Tea/Coffee.</li>
                            <li><b>•	Lunch:</b> Rice/Roti, Dal, Begun Bhaja, Mix Veg Curry, Bhetki Fish Curry, Crab Curry, Chutney, Papad, Sweet.</li>
                            <li><b>•	Evening Snacks:</b> Veg Pakora / Chowmein, Tea/Coffee.</li>
                            <li><b>•	Dinner:</b> Mutton Biryani / Veg Biryani, Chicken Kasa, Salad, Sweets.</li>
                          </ul>
                          <div className="content-desktop">
                            <center><img src={HotelsonarbanglaFoodImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={HotelsonarbanglaFoodImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="day3"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseThree"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          Day 3: Explore Village and Cherish Your Memories
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseThree"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingThree"
                      >
                        <div className="accordion-body">
                          <p>After a tight sleep, wake up early saying gooooooooood morning Sundarban. It's your last day of your <strong>2N 3D Sundarban Tour</strong>. So you do not have much time. Leave your bed, open the resort gate and take a fresh look at vast mangroves on the other side of the river. The cool breeze and mystic foggy ambience will compel you to take some deep breath. It's fresh oxygen that is a booster for your fresh mind and soul until your next trip to somewhere.</p>
                          <p>Take a walk over the dams and visit nearby villages, talk with the local people, experience their day to day life, have some hot tea made of cow or local "Desi" goat milk. You can look around to collect some original Sundarban Honey or you can collect fresh veggies from the firm.</p>
                          <p>Return back to resort. Complete your shower with hot water and gather at breakfast table. After completing we will start from the resort around 9am. During boat journey, packed lunch will be served. Again start your depart journey among the channels and wide river with mangroves on both side. This journey is exciting too, though you may feel heavy heart to leave this beautiful place. But with a promise to visit again you will be dropped at Godkhali, from where your <strong>Sundarban Package Tour</strong> stared. Board your car and proceed to your sweet home.</p>
                          <p><b className="tomato">Meals for 3rd Day Sundarban Tour:</b></p>
                          <ul>
                            <li>
                              <b>•	Breakfast:</b> Luchi, Aloo Dum, Sweet, Tea.
                            </li>
                            <li><b>•	Lunch:</b> Rice, Dal, Aloo Fry, Veg Curry, Fish Curry, Chutney, Papad, Sweet</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              {/* <!--insert table inclusion exclusion--> */}
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    {
                      <InclusionExclusion />
                    }
                  </div>
                </div>
              </div>
              {/* <!--end table inclusion exclusion--> */}
              <div className="container-fluid py-4">
                <div>
                  <p><h4 className='tomato'><u>WHAT ARE THE DIFFERENT TYPES OF <strong>SUNDARBAN TOUR PACKAGE</strong> THAT Adorable OFFER</u></h4>
                    <ul>
                      <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>
                        &nbsp;&nbsp;Sundarban Tour Package
                      </li>
                      <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>
                        &nbsp;&nbsp;<a href="/sundarban-package-tour-from-kolkata-with-hotel-sonar-bangla">Hotel Sonar Bangla Sundarban Package</a>
                      </li>
                      <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Sundarban Houseboat Tour Packages</li>
                      <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Sundarban Package Tour 1N 2D</li>
                      <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Sundarban Conducted Tour</li>
                      <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Sundarban Day Tour</li>
                      <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Sundarban Delta Tour</li>
                      <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Sundarban Tour 1N 2D</li>
                      <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Sundarban Tour 2N 3D</li>
                      <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Sundarban Forest Tour</li>
                      <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Sundarban Group Tour</li>
                      <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Sundarban Holidays Tour</li>
                      <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Sundarban Jungle Tour</li>
                      <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Sundarban Cruise Tour</li>
                      <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Sundarban Luxury Tour</li>
                      <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Sundarban Mangrove Tour</li>
                      <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Sundarban Tiger Tour</li>
                      <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Sundarban MICE Tour</li>
                      <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Sundarban Bird Watching Tour</li>
                      <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Sundarban Watch Tower Tour</li>
                    </ul></p>
                </div>
              </div>
              <br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.7vw" }}><strong>WATCH TOWER TO EXPLORE DURING SUNDARBAN TOUR FROM KOLKATA</strong></h3>
                  <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>WATCH TOWER TO EXPLORE DURING SUNDARBAN TOUR FROM KOLKATA</strong></h3>
                </div>
                <div className="card-body">
                  <p><strong>
                    Bonnie Camp Watch Tower</strong><br /><br />
                    It is said to be one of the most beautiful watch Tower among Sundarban. The watch tower is almost 50 feet in height and boasts a 360 degree view of Sundarban Biosphere Reserve. This watch tower can accommodate 20 people at a time. There is an enclose having lots of spotted deer. Guests travelling to Bonnie Camp must obtain entry permit. Staying overnight is required pre booking, else guests have to spend the night on boat.
                  </p>
                  <p><strong>Sudhanyakhali Watch Tower</strong><br /><br />
                    Among many watch towers in Sundarban range, Sudhyankhali Watch Tower is best place for Tiger spotting. Before reaching there, one has to obtain permission from forest office by submitting valid documents. With 360 degree view of Sundarban Reserve Area, this tower can accommodate 25 guests at a time.<br />
                    Beside the tower, there is a huge sweet water pond that attracts many wild animal come to drink water there hence increases the chance to see Royal Bengal Tiger. Spotted Deer, river crocodile, wild boar, monkeys also can be seen from there.

                  </p>
                  <p>
                    <strong>Dobanki Watch Tower</strong><br /><br />
                    Dobanki Watch Tower is one of the most visited spot during <strong>Sundarban Tour Package from Kolkata</strong>. A wide range of animals including Royal Bengal Tiger is often noticed in the surrounding area of this watch tower. It is also known for its Canopy walk. Almost half a km stretch well fenced corridor with 12 ft high and 12ft above the ground resembles like flyover feeling. A nearby Bon-Bibir temple is also well attracted place during Dobanki Watch Tower Tour.
                  </p>
                  <p>
                    <strong>Sajnekhali Watch Tower</strong><br /><br />
                    Basically Sajnekhali is the entry point for other island in Sundarban and Sajnekhali Watch Tower is located within this Sajnekhali Eco Tourism centre. All permission from forest officials to enter other island in Sundarban is issued from here. Adjacent to this watchtower, there is a sweet water point, a mangrove interpretation centre and a crocodile park. One can spot other animals also from this watch tower like spotter deer, wild boar, and crocodile and of course the Royal Bengal Tiger. Most of the tiger is seen from this watch tower. The tower can accommodate 20 to 25 guests at a time and the view from here is breathtaking. It’s a must visit spot rather the heart of Sundarban and mostly visited by the travellers opting for Sundarban Package Tour from Kolkata.
                  </p>
                  <p>
                    <strong>Netidhopani Watch Tower</strong><br />
                    <p>In the series of several watch towers in Sundarban, Netidhopani is worth mentioning. This place is associated with the legend of Behula and Lakhindar story. It is believed that Behula reached heaven from this Ghat.

                    </p>
                    <p>During high season time a very limited no. of boats is allowed to visit Netidhopani watch tower due to restricted tourism. Around 20 travellers can accommodate in this watch tower providing a breath taking view of the Sundarban Tiger Reserve. There are some places of attractions where can be visited from here. They are:</p>
                    <ul>
                      <li>Chandkhali Island - 28 km from Netidhopani</li>
                      <li>Moore Island - 43 km from Netidhopani</li>
                      <li>Harinbhanga Island - 26 km from Netidhopani</li>
                      <li>Khatuajhuri Island- 31 km from Netidhopani</li>
                      <li>Chaimari (the land of tigers) - 30 km from Netidhopani</li>
                    </ul>
                  </p>
                </div>
              </div>
              <br />
              <div className="container-fluid py-4">

                <div>
                  {/* Thing to Avoid */}
                  <h4 className='tomato'><u>THINGS TO AVOID FOR <strong>KOLKATA TO SUNDARBAN PACKAGE TOUR</strong></u></h4>
                  <ul>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>
                      &nbsp;&nbsp;Sundarban is a Plastic free zone, so don't carry plastics during <strong>Sundarban Tour Package</strong>.</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>
                      &nbsp;&nbsp;Don't pollute the locality by throwing trash from the boat/Launch.</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>
                      &nbsp;&nbsp;Avoid playing loud music or using loudspeakers to maintain the tranquility.</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>
                      &nbsp;&nbsp;Keep noise levels down to avoid disturbing wildlife and fellow visitors.</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>
                      &nbsp;&nbsp;Don't plan your Sundarban Tour with Pets.</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>
                      &nbsp;&nbsp;Never tease or chase wild animals; it’s strictly prohibited.</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>
                      &nbsp;&nbsp;Feeding wild animals is also prohibited in Sundarban.</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>
                      &nbsp;&nbsp;Drone videography is not permitted in Sundarban.</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>
                      &nbsp;&nbsp;Enter Sundarban National Park/Tiger Reserve only with a valid permit.</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>
                      &nbsp;&nbsp;Stay within the designated areas of the reserve, permitted only from sunrise to sunset.</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>
                      &nbsp;&nbsp;Activities like hunting, fishing, and entering restricted forest areas are serious offenses.</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>
                      &nbsp;&nbsp;Prohibited items include guns, firearms, bows, arrows, crackers, and inflammable materials.</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>
                      &nbsp;&nbsp;Avoid bathing in river water for safety reasons.</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>
                      &nbsp;&nbsp;Stick to designated tourist zones; entry into core or protected areas is restricted.</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>
                      &nbsp;&nbsp;Consumption of liquor or alcohol is strictly prohibited within the Sundarban Reserve area.</li>
                  </ul>

                  {/* Thing to carry */}
                  <h4 className='tomato'><u>Things to Carry for Hotel Sonar Bangla Sundarban Package Tour</u></h4>
                  <ul>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>
                      &nbsp;&nbsp;Bring masks, hand sanitizer, and maintain social distance.
                    </li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>
                      &nbsp;&nbsp;Pack woolen clothes for the chilly Sundarban winters..
                    </li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Wear light-colored clothing to blend with the natural surroundings.</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Carry a photo ID proof along with two photocopies.</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Foreign visitors should have a valid passport with an Indian visa.</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Show respect towards the local inhabitants of Sundarban.</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Drink only packaged or bottled water.</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Pack snacks, dry fruits, or biscuits for the journey.</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Don’t forget sunglasses, hats, and sunblock for protection.</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Bring essentials like torches, candles, lighters, mobile chargers, and power banks.</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Keep enough cash handy as ATM availability might be limited.</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Pack basic medications for common ailments like fever, cough, or stomach upset.</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Consider gum boots if planning for a mud walk.</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Binoculars are handy for spotting animals and birds during boat cruises or jungle walks.</li>
                  </ul><br /><br />
                </div>
              </div>
              <br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.7vw" }}><strong>Why You Should Book Sundarban Package Tour from Adorable Vacation? </strong></h3>
                  <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>Why You Should Book Sundarban Package Tour from Adorable Vacation? </strong></h3>
                </div>
                <div className="card-body">
                  <p>We have been operating <strong>Sundarban Trip</strong> for the last <strong>12+</strong> years with overwhelming <strong>guest's satisfaction</strong>. We have <strong>6750+ satisfied guests</strong> for <strong>Sundarban Tour</strong> because of our <strong>seamless service</strong> and <strong>guest's commitment</strong>. There are many <strong>Sundarban Tour Agency</strong> in Kolkata. In spite of that, why guests prefer to travel with us must have some good cause. Please let's know why:
                  </p>
                  <p>We have a long list of <strong>Satisfied Guests</strong> with <strong>Sundarban Trip</strong> across Kolkata and India.Our Organization have a <strong>long database</strong> for <strong>satisfied guests</strong> who travelled with us. Our only rewards are their sheer <strong>satisfaction and happiness</strong> that is our ultimate goal.With a simple phone call, you can book your <strong>Sundarban Package Tour</strong> with us at any time you wish.We are <strong>24x7 active</strong> to serve you the BEST.<br/><br/>
                    <p><strong>We provide Accommodation with Customer's Choice</strong><br />
                      According to your choice and preferences, we will accommodate you to the best hotels and resort in Sundarban. You can stay at Hotels or Resort or if you wish, we can arrange it on a boat also. We have both AC and Non-AC room facilities with Hot water service. Our experienced and cordial tour manager always will accompany you for your best service and amenities.
                    </p>

                  </p>
                  <p><strong>Mouth Watering Meals as per your choice</strong><br/>
This is our main USP. Every guest who has travelled with us on <b>Sundarban Tour from Kolkata</b>, have praised for their meals. We cook food with utmost hygiene and ingredients. From the water, spices to fish, chickens, everything we buy fresh and cook with love to serve our guests the BEST FOOD during their <strong>Sundarban Tour Package from Canning</strong>. Cleanliness and hygiene is our top most priority. Moreover, we cook according to your request and preferences also. You can taste some freshly bought local delicacy. We will be happy to serve you. Elder people, children, diabetic patients are taken special care and consideration during food cooking and processing. That's why we have a set a level as per our food standard.
</p>
<p><strong>24x7 Tour Manager Support</strong><br/>
During your entire Kolkata to Sundarban Trip Booking, you will be guided by our experienced and cordial tour manager. He will look after every trivial detail for your safe, secure, and comfortable journey during Sundarban Cruise, jungle walking, sightseeing, night stay, and arranging events and entertainment. Any issue, he will be at your service.
</p>
<p><strong>Affordable Price Range</strong><br/>
Our Sundarban Tour Cost is very competitive and pocket friendly. That's why we have guests not only from Kolkata but also from all over India. We have successfully conducted some birding tours with foreign guests also. You can book your Sundarban Tour with just a simple call. Try it once, we will not disappoint you.
</p>
                </div>
              </div>
              <br />

              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h3 className="content-desktop" style={{ fontSize: "2.0vw" }}>
                        FAQs for <strong className="tomato">Sundarban Tour Packages with Hotel Sonar Bangla</strong>
                      </h3>
                      <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>
                        FAQs for <strong className="tomato">Sundarban Tour Packages with Hotel Sonar Bangla</strong>
                      </h3>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div
                    className="accordion accordion-flush faQStyle"
                    id="accordionFlushExample"
                  >
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingOne">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseOne"
                          aria-expanded="false"
                          aria-controls="flush-collapseOne"
                        >
                          What is the price range for Hotel Sonar Bangla in Sundarban?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseOne"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingOne"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          The Sundarban tour cost with Hotel Sonar Bangla starts from ₹6799 per room per night, offering excellent value for your stay.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingTwo">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseTwo"
                          aria-expanded="false"
                          aria-controls="flush-collapseTwo"
                        >
                          What types of rooms does Hotel Sonar Bangla in Sundarban offer?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseTwo"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingTwo"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Hotel Sonar Bangla Sundarban offers a range of rooms including Deluxe Rooms, Premium Rooms, and Suites.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingThree">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseThree"
                          aria-expanded="false"
                          aria-controls="flush-collapseThree"
                        >
                          What are the Check-In and Check-Out times at Hotel Sonar Bangla in Sundarban?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseThree"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingThree"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          The standard Check-In time at Hotel Sonar Bangla Sundarban is 1 PM, and Check-Out is at 11 AM. Early Check-In is subject to availability.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingFour">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFour"
                          aria-expanded="false"
                          aria-controls="flush-collapseFour"
                        >
                          Is it possible to reserve a private boat for the Sundarbans tour package with Hotel Sonar Bangla?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseFour"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingFour"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Yes, guests can book a private boat for their family or group tour from either Godkhali or Sonakhali.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingFive">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFive"
                          aria-expanded="false"
                          aria-controls="flush-collapseFive"
                        >
                          What are the inclusions of the Sundarban package if starting from Kolkata?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseFive"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingFive"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          The package includes car fare to Godkhali, accommodation at Hotel Sonar Bangla with breakfast, lunch on the boat, and dinner/snacks at the hotel. Cultural programs can be arranged upon request.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingSix">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseSix"
                          aria-expanded="false"
                          aria-controls="flush-collapseFive"
                        >
                          What modes of local transportation are available in Sundarbans?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseSix"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingSix"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          River cruises on boats are the best way to explore Sundarban. Within the delta, paddle vans or motor vans are available.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingSeven">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseSeven"
                          aria-expanded="false"
                          aria-controls="flush-collapseSeven"
                        >
                          Is it secure to explore the Sundarbans jungle?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseSeven"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingSeven"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Yes, visiting with a knowledgeable guide is considered safe. Organizing your trip through a reputable tour operator is recommended.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingEight">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseEight"
                          aria-expanded="false"
                          aria-controls="flush-collapseEight"
                        >
                          Where is Hotel Sonar Bangla, Sundarban located?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseEight"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingEight"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Hotel Sonar Bangla is in Village Dulki, PO/PS - Gosaba, Dist - 24 Parganas (South), West Bengal, India.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingNine">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseNine"
                          aria-expanded="false"
                          aria-controls="flush-collapseNine"
                        >
                          Where is the corporate office of Hotel Sonar Bangla?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseNine"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingNine"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          The corporate office is at Megatherm Building, Plot - L1, Block - GP, 6th Floor, Sector - V, Electronics Complex, Salt Lake City, Kolkata- 700091, West Bengal, India.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingTen">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseTen"
                          aria-expanded="false"
                          aria-controls="flush-collapseTen"
                        >
                          Is free parking available at Hotel Sonar Bangla Sundarban?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseTen"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingTen"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Parking facilities are not required as guests typically arrive via boat. However, a parking lot is planned for the future.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingEleven">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseEleven"
                          aria-expanded="false"
                          aria-controls="flush-collapseEleven"
                        >
                          Can we have hot water at Hotel Sonar Bangla, Sundarban?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseEleven"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingEleven"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Yes, each room has a geyser facility for hot water.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingTwelve">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseTwelve"
                          aria-expanded="false"
                          aria-controls="flush-collapseTwelve"
                        >
                          Does Hotel Sonar Bangla Sundarban have a swimming pool?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseTwelve"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingTwelve"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Yes, the hotel features a swimming pool open to all guests.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingThirteen">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseThirteen"
                          aria-expanded="false"
                          aria-controls="flush-collapseThirteen"
                        >
                          What is the ideal duration for a Sundarban tour with Hotel Sonar Bangla?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseThirteen"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingThirteen"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          A 2-night, 3-day Sundarban package tour with Hotel Sonar Bangla allows for a relaxed exploration, covering major attractions.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingFourteen">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFourteen"
                          aria-expanded="false"
                          aria-controls="flush-collapseFourteen"
                        >
                          What is the ideal duration for a Sundarban tour with Hotel Sonar Bangla?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseFourteen"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingFourteen"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          A 2-night, 3-day Sundarban package tour with Hotel Sonar Bangla allows for a relaxed exploration, covering major attractions.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingFifteen">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFifteen"
                          aria-expanded="false"
                          aria-controls="flush-collapseFifteen"
                        >
                          What are the popular amenities available at Hotel Sonar Bangla, Sundarban?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseFifteen"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingFifteen"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Popular amenities include well-furnished rooms, outdoor activities, CCTV surveillance, dining facilities, room service, and a swimming pool.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingSixteen">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseSixteen"
                          aria-expanded="false"
                          aria-controls="flush-collapseSixteen"
                        >
                          Can we book Hotel Sonar Bangla for our Sundarban tour?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseSixteen"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingSixteen"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Yes, Hotel Sonar Bangla in Sundarban is a luxurious 4-star property offering modern amenities. It’s an excellent choice for a lavish experience during your Sundarban tour.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container-fluid py-4">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h3 className="content-desktop" style={{ fontSize: "2.0vw" }}>
                        <strong className="tomato">Sundarban Tour Package</strong> Reviews
                      </h3>
                      <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>
                        <strong className="tomato">Sundarban Tour Package</strong> Reviews
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="row row-cols-1 ">
                  <div id="testimonials">
                    <div className="testimonial-box-container">
                      {reviewersDataView}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <BookingBox />
            </div>
            <div className="modal" style={{ display: this.state.showModal ? "block" : "none" }}>
              <div className="modal-dialog">
                <div className="modal-content cust-modContent">
                  <div className="modal-header">
                    <h4 className="modal-title ms-auto" id="exampleModalLabel">Get a Free Callback &nbsp;&nbsp;<i className="fa fa-phone fa-rotate-90"></i>
                    </h4>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={this.onCloseModal}></button>
                  </div>
                  <div className="modal-body">
                    <form className="row g-3">
                      <div className="col-md-6">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="First Name *"
                          name="firstName"
                          value={this.state.firstName}
                          onChange={this.onChangeHandler} />
                      </div>
                      <div className="col-md-6">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Last Name *"
                          name="lastName"
                          value={this.state.lastName}
                          onChange={this.onChangeHandler} />
                      </div>
                      <div className="col-12">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Email *"
                          name="email"
                          value={this.state.email}
                          onChange={this.onChangeHandler} />
                      </div>
                      <div className="col-md-6">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="10 Digit Mobile No.*"
                          name="mobile"
                          value={this.state.mobile}
                          onChange={this.onChangeHandler} />
                      </div>
                      <div className="col-6">
                        <select
                          name="city"
                          className="form-select"
                          value={this.state.city}
                          onChange={this.onChangeHandler}>
                          <option value="">Origin City</option>
                          {this.getCityOptions()}
                        </select>
                      </div>
                      <div className="col-6 date-picker">
                        <input
                          type="date"
                          className="form-control"
                          name="travelDate"
                          value={this.state.travelDate}
                          onChange={this.onChangeHandler} />
                      </div>
                      <div className="col-6">
                        <select
                          name="ticketBookedStatus"
                          className="form-select"
                          value={this.state.ticketBookedStatus}
                          onChange={this.onChangeHandler}>
                          <option value="">Ticket Booked</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                          <option value="Will do soon">Will do soon</option>
                        </select>
                      </div>
                      <div className="col-6">
                        <select
                          name="noOfGuests"
                          className="form-select"
                          value={this.state.noOfGuests}
                          onChange={this.onChangeHandler}>
                          <option value="">No. Of Guests</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="More than 8">More than 8</option>
                        </select>
                      </div>
                      <div className="col-6">
                        <select
                          name="hotelCategory"
                          className="form-select"
                          value={this.state.hotelCategory}
                          onChange={this.onChangeHandler}>
                          <option value="">Hotel Category</option>
                          <option value="5 Star">5 Star</option>
                          <option value="4 Star">4 Star</option>
                          <option value="3 Star">3 Star</option>
                          <option value="Other">Other</option>
                        </select>
                      </div>
                      <div className="col-12">
                        <textarea
                          className="form-control"
                          placeholder="Comments"
                          rows="2"
                          name="comments"
                          value={this.state.comments}
                          onChange={this.onChangeHandler}></textarea>
                      </div>
                    </form>
                  </div>
                  <div className="modal-footer">
                    <div className='container'>
                      <div className="row">
                        <div className='col-sm-4'>
                          {
                            (this.state.showLoader) &&
                            <div className='pull-left'><Digital size={25} /></div>
                          }
                        </div>
                        <div className='col-sm-8 '>
                          <div className='pull-right'>
                            <button type="button" className="btn btn-Warning mr-1" onClick={this.onCloseModal}>Close</button>
                            <button type="button" className="btn btn-Warning" onClick={this.onRequestCallbackSubmit} >Submit</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default Sundarban_kolkataTourPackage;
