import React, { Component } from 'react';
import BookingBox from '../BookingBox';
import Slider from "react-slick";
import ApiURL from "../../../api/ApiURL";
import { Link } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../Meghalaya/InclusionExclusion';
import InclusionExclusion from '../Meghalaya/InclusionExclusion';

class MeghalayaTourPackage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customActiveTab: 'tab1'
        }
    }

    handleCustomTabClick = (tab) => {
        this.setState({
            customActiveTab: tab,
        });
    };

    render() {
        const { customActiveTab } = this.state;
        const packageTopBannerImageURL = ApiURL.MeghalayaTourPackageImageURL + "/heder-image.webp";
        const brandedTourackageImage_1_URL = ApiURL.MeghalayaTourPackageImageURL + "/Ry-Kynjai-Shillong.webp";

        const hotPackagesDataList = this.props.HotPackagesData;
        const hotPackagesView = hotPackagesDataList.length > 0 ?
            (
                hotPackagesDataList.map((dataItem, i) => {
                    const hotelImgURL = ApiURL.MeghalayaTourPackageImageURL + "/" + dataItem.ImageName;
                    const packageURL = dataItem.Url;
                    return (<div className="col-md-4" key={i}>
                        <div className="card h-100">
                            <img src={hotelImgURL} className="card-img-top" alt={dataItem.AllternateText} />
                            <div className="card-body">
                                <h6 className="card-title">{dataItem.Title}<span> &#8377;{dataItem.PricePerNight} /-</span></h6>
                                <p className="card-text">
                                    <i className="fa fa-building tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-utensils tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-car-side tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-male tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-camera tomato"></i>
                                </p>
                                <p className="card-text">{dataItem.Description}</p>
                                <p>
                                    <span><b className="tomato">{dataItem.NoOfTours} </b>Tours</span> |
                                    <span><b className="tomato">{dataItem.NoOfDeparture} </b>Departures</span> |
                                    <span><b className="sdf-blue">{dataItem.Include}</b></span>
                                </p>
                            </div>
                            <div className="card-footer">
                                <small className="text-muted">{dataItem.MutedText}</small>
                                <Link to={packageURL} target="_blank" className="btn btn-Warning btn-sm float-end">Book Now</Link>
                            </div>
                        </div>
                    </div>)
                })
            ) :
            (<div></div>)


        const settings = {
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };


        const hotelDataList = this.props.HotelsData;
        const hotelDataView = hotelDataList.map((hotelItem, i) => {
            const hotelImageURL = ApiURL.MeghalayaTourPackageImageURL + "/" + hotelItem.ImageName;
            return (
                <div className="col-md-3" key={i}>
                    <img src={hotelImageURL} className="rounded mx-auto d-block" alt={hotelItem.AllternateText} /><br />
                    <span><b>{hotelItem.HotelName}</b> | <span className="tomato">{hotelItem.HotelStar}</span></span><br />
                    <span className="rating-box">
                        <span className="star">{hotelItem.RatingBoxStar}</span>
                        <b className="rating-value">{hotelItem.RatingValue}</b>
                    </span> &nbsp;<b>{hotelItem.Reviews}</b>
                </div>
            )
        })


        const themePackageSliderList = this.props.ThemePackagesData;
        const themePackagesDataView = themePackageSliderList.map((sliderItem, i) => {
            const sliderImageURL = ApiURL.MeghalayaTourPackageImageURL + "/" + sliderItem.ImageName;
            return (
                <div className="theme-cards-wrapper" key={i}>
                    <div className="theme-card">
                        <div className="image-wrapper">
                            <img src={sliderImageURL} alt={sliderItem.AllternateText} />
                        </div>
                        <div className="card-body">
                            <h5 className="card-title">{sliderItem.Title}</h5>
                            <p className="card-text">{sliderItem.Description}</p>
                            <Link to={"#"} className="btn btn-primary">Book Now</Link>
                        </div>
                    </div>
                    <div className="clearfix"></div>
                </div>
            )
        })



        const reviewersDataList = this.props.ReviewersData;
        const reviewersDataView = reviewersDataList.map((dataItem, i) => {
            const reviewerImageURL = ApiURL.MeghalayaTourPackageImageURL + "/" + dataItem.ImageName;
            return (
                <div className="testimonial-box" key={i}>
                    <div className="box-top">
                        <div className="profile">
                            <div className="profile-img">
                                <img src={reviewerImageURL} alt="..." />
                            </div>
                            <div className="name-user">
                                <strong>{dataItem.Name}</strong>
                                <span>{dataItem.Location}</span>
                            </div>
                        </div>
                        <div className="reviews">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </div>
                    </div>
                    <div className="client-comment">
                        <p>{dataItem.Comment}</p>
                    </div>
                </div>
            )
        })


        const travelsDataList = this.props.TravellersData;
        const travelDataView = travelsDataList.map((travelItem, i) => {
            const travelImageURL = ApiURL.MeghalayaTourPackageImageURL + "/" + travelItem.ImageName;
            return (
                <div key={i}>
                    <h6><b>{travelItem.Title} </b></h6><br />
                    <img src={travelImageURL} alt="..." style={{ display: 'block', margin: 'auto' }} /><br />
                    <span>{travelItem.Description}</span><br /><br />
                </div>
            )
        })


        return (
            <>
                <div className="container-fluid">
                    <div className="row">
                        <img src={packageTopBannerImageURL} className="img-fluid px-0" alt="..." />
                    </div>
                </div>
                <div className="container-fluid py-3">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="container-fluid">
                                        <h3 className="px-2">Assam Meghalaya Tour Package!</h3>

                                        <div className="d-flex flex-column flex-sm-row align-items-sm-center px-2">
                                            <div className="font-lg">
                                                3 Cities:
                                                <Link to={"#"}><i className="fa fa-map-marker text-warning mx-2"></i> Kazirnaga</Link>&nbsp;
                                                <Link to={"#"}><i className="fa fa-map-marker text-warning mx-2"></i> Shillong</Link>
                                                <Link to={"#"}><i className="fa fa-map-marker text-warning mx-2"></i> Cherrapunji</Link>
                                            </div>
                                            <div className="reviews mx-5">
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                            </div>
                                        </div>
                                        <p className="p-2">Meghalaya Package For the hard-core nature enthusiasts, North Eastern part of India remains
                                            among the lesser explored tourist's destinations in the world.</p>

                                        <div className="card">
                                            <div className="card-header custHeaer">
                                                India's Most Thrilling Travel Destination
                                            </div>
                                            <div className="card-body">
                                                <p>Are you looking to explore the thunderous mountains and diverse cultures and traditions of the
                                                    Assam Meghalaya? It's right here at Adorable Vacation LLP.
                                                    Adorable Vacation introduce an exclusive Assam Meghalaya tour package for your comfort at your
                                                    fingertips with various extraordinary adventures, humongous mountains which will make you awestruck
                                                    and flabbergasted to the most. This memorable destination is known for many living root bridges,
                                                    natural beauty and hospitable people.
                                                    Come explore the beautiful Assam Meghalaya with us.</p>
                                                <h3 class="px-2">A value for money Destination</h3>
                                                <p>Here at Adorable Vacation, we strongly believe that everyone should explore and know the diverse
                                                    cultures, people and cuisines of our nation. So that’s why we offer the most competitive and
                                                    economical Meghalaya tour packages according to your need and preferences. Our team understands your
                                                    hard-earned money and your passion for travelling to explore new things, which makes us more
                                                    dedicated and commits to an exceptional service without degrading the quality. With our budget
                                                    friendly packages your dream destination will be a reality without hampering your savings.</p>
                                                <a href="tel://9883359713" class="btn btn-Warning">Call Now</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br /><br />
                            <h3 className="p-4"><b>
                                LOOKING FOR THE BEST NORTH EAST TOUR PACKAGES FROM GUWAHATI ?<br /><br />  <a href='tel:9674738480' class="tomato"> FOR BOOKING ☎ CALL +91- 96747-38480</a></b></h3>
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>Assam Meghalaya <span>Tour Plan</span></h2>
                                            <h4>Trending Meghalaya Trip from Guwahati</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-cols-1 row-cols-md-4 g-4">

                                    {hotPackagesView}

                                </div>
                            </div>

                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>Day Wise Meghalaya Tour <span>Plan from India</span></h2>
                                            <h4>6 Nights 7 Days Tour (Ex-Guwhati Airport)</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="container-fluid py-3">
                                    <div className="row">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr className="table-danger">
                                                    <th>DAY WISE SUMMERY FOR 6N/7D MEGHALAYA TOUR</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td class="clickable" data-target="panelsStayOpen-headingOne"><b>DAY 1 → </b><span>GUWAHATI TO
                                                        KAZIRANGA</span></td>
                                                </tr>
                                                <tr>
                                                    <td class="clickable" data-target="panelsStayOpen-headingTwo"><b>DAY 2 → </b><span>JUNGLE SAFARI AT
                                                        KAZIRANGA</span></td>
                                                </tr>
                                                <tr>
                                                    <td class="clickable" data-target="panelsStayOpen-headingThree"><b>DAY 3 → </b><span>KAZIRANGA TO
                                                        SHILLONG</span></td>
                                                </tr>
                                                <tr>
                                                    <td class="clickable" data-target="panelsStayOpen-headingFour"><b>DAY 4 → </b><span>SHILLONG TO
                                                        MAWSYNRAM TO SHILLONG</span></td>
                                                </tr>
                                                <tr>
                                                    <td class="clickable" data-target="panelsStayOpen-headingFive"><b>DAY 5 → </b><span>SHILLONG TO
                                                        CHERRAPUNJEE</span></td>
                                                </tr>
                                                <tr>
                                                    <td class="clickable" data-target="panelsStayOpen-headingSix"><b>DAY 6 → </b><span>CHERRAPUNJEE TO
                                                        SHILLONG VIA MAWLYNNONG TO DAWKI</span></td>
                                                </tr>
                                                <tr>
                                                    <td class="clickable" data-target="panelsStayOpen-headingSeven"><b>DAY 7 → </b><span>DEPARTURE FROM
                                                        SHILLONG </span></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div >
                                </div >

                                <div className="row">
                                    <div className="accordion" id="accordionPanelsStayOpenExample">
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true"
                                                    aria-controls="panelsStayOpen-collapseOne">
                                                    DAY 01: GUWAHATI TO KAZIRANGA
                                                </button>
                                            </h2>
                                            <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingOne">
                                                <div className="accordion-body">
                                                    Welcome on arrival at Guwahati airport and transfer to Kaziranga National Park, the home of the “One
                                                    Horned Rhinoceros”. Check in at your hotel/Lodge/resort. Evening free for leisure. <br /><br />
                                                    <b>Overnight stay at Kaziranga</b>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseTwo">
                                                    DAY 02: JUNGLE SAFARI AT
                                                    KAZIRANGA
                                                </button>
                                            </h2>
                                            <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingTwo">
                                                <div className="accordion-body">
                                                    <p>
                                                        <b>Today after breakfast, </b> Early morning explores Western Zone of Kaziranga National Park on
                                                        back of
                                                        elephant. Apart from worldendangered One-horned Rhinoceros, the Park sustains half the world’s
                                                        population of genetically pureWild Water Buffaloes, over 1000 Wild elephants and perhaps the
                                                        densest
                                                        population of Royal BengalTigers anywhere. Kaziranga is also a bird watchers paradise and home to
                                                        some
                                                        500 species of birds. TheCrested Serpent Eagle, Pallas Fishing Eagle, Grey headed Fishing Eagle,
                                                        Swamp
                                                        Partridge, Bar-headedgoose, whistling Teal, Bengal Florican, Storks, Herons and Pelicans are some
                                                        of the
                                                        species found here.<br />
                                                        We will return to the resort for breakfast.Afternoon experience jeep safari through the Central
                                                        Zone
                                                        ofthe National Park. In evening enjoy the cultural program in Orchid Park.<br />
                                                    </p>
                                                    <b>Overnight stay at Hotel.</b>
                                                </div >
                                            </div >
                                        </div >
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree">
                                                    DAY 03: KAZIRANGA TO SHILLONG
                                                </button>
                                            </h2>
                                            <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingThree">
                                                <div className="accordion-body">
                                                    <p>Early morning explores Western Zone of Kaziranga National Park on back of elephant. After
                                                        breakfast
                                                        check out from hotel and transfer to Shillong. Often called the ‘Scotland of the East’, Shillong
                                                        has
                                                        lots to discover from lakes to hills to the jaw-dropping waterfalls. En route, stop at Barapani
                                                        Lake for
                                                        some amazing view & water activities. On arrival at Shillong, check in to the hotel. If time
                                                        permits
                                                        visit Ward’s lake, Lady Haydari Park and Police Bazar.
                                                    </p>
                                                    <b>Overnight stay at Shillong.</b>
                                                </div >
                                            </div >
                                        </div >
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="panelsStayOpen-headingFour">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree">
                                                    Day 04: - SHILLONG TO MAWSYNRAM TO SHILLONG
                                                </button>
                                            </h2>
                                            <div id="panelsStayOpen-collapseFour" className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingFour">
                                                <div className="accordion-body">
                                                    <p>
                                                        After breakfast drive to Mawsynram, En-route visit Elephant Falls, Shillong Peak (Entry depends
                                                        Indian
                                                        Army) and Cathedral Catholic Chruch.Visit Mawphlang Sacred Forest, Mawjymbuin Cave (Mawsynram
                                                        cave),
                                                        Naturally formed Shiva Linga. After sightseeing evening back to Shillong.
                                                    </p>
                                                    <b>Overnight stay at Hotel Shillong .</b>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="panelsStayOpen-headingFive">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseFive" aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree">
                                                    DAY 05: SHILLONG TO CHERRAPUNJEE
                                                </button>
                                            </h2>
                                            <div id="panelsStayOpen-collapseFive" className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingFive">
                                                <div className="accordion-body">
                                                    <p>
                                                        <b>Entire Day Cherrapunjee Local</b>
                                                        Includes, Today post breakfast, you will proceed to Cherrapunji. En-route
                                                        visit Elephant Falls, Shillong Peak (Entry depends Indian Army) and Cathedral Catholic Chruch.Upon
                                                        arrival at Cherrapunji, we will commence with our sightseeing tour and visit famous attractions
                                                        such as
                                                        Nohkalikai Falls, Mawsmai Cave, Seven Sister Falls, Arwah Cave, Eco Park and Thangkharang Park.
                                                    </p>
                                                    <b>Overnight stay at Cherrapunjee.</b>
                                                </div>
                                            </div>
                                        </div >
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="panelsStayOpen-headingSix">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseSix" aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree">
                                                    DAY 06: CHERRAPUNJEE TO SHILLONG VIA MAWLYNNONG TO DAWKI
                                                </button>
                                            </h2>
                                            <div id="panelsStayOpen-collapseSix" className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingSix">
                                                <div className="accordion-body">
                                                    <p>
                                                        Post breakfast, we will proceed to visit the cleanest village of Asia - Mawllynnong. You will also
                                                        get a
                                                        chance to explore the nearby villages and witness the amazing living root bridges.Later on, you
                                                        will be
                                                        transferred to the international border of Bangladesh, wherein you will be visiting Dawki Village.
                                                        This
                                                        village is known for having some of the cleanest rivers, a classic example of which is the
                                                        enchanting
                                                        Umngot River. Later on, you will return to Shillong for a relaxing overnight stay.</p>
                                                    <b>Overnight stay at Hotel Shillong .</b>
                                                </div >
                                            </div >
                                        </div >
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="panelsStayOpen-headingSeven">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseSeven" aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree">
                                                    DAY 07: DEPARTURE FROM SHILLONG
                                                </button>
                                            </h2>
                                            <div id="panelsStayOpen-collapseSeven" className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingSeven">
                                                <div className="accordion-body">
                                                    <p>
                                                        After breakfast check out from hotel and drop at Guwahati Airport / Railway station as per
                                                        timings.</p>
                                                </div >
                                            </div >
                                        </div >
                                    </div >
                                </div >

                            </div >

                            <div className="container-fluid navtabCustom">
                                <nav>
                                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                        <a onClick={() => this.handleCustomTabClick('tab1')} className={customActiveTab === 'tab1' ? 'nav-item nav-link active' : 'nav-item nav-link'} role="tab" aria-controls="nav-price" aria-selected="true">Price</a>
                                        <a onClick={() => this.handleCustomTabClick('tab2')} className={customActiveTab === 'tab2' ? 'nav-item nav-link active' : 'nav-item nav-link'} role="tab" aria-controls="nav-hotels" aria-selected="false">Hotels</a>
                                        <a onClick={() => this.handleCustomTabClick('tab3')} className={customActiveTab === 'tab3' ? 'nav-item nav-link active' : 'nav-item nav-link'} role="tab" aria-controls="nav-travellers-info" aria-selected="false">Things to do</a>
                                        <a onClick={() => this.handleCustomTabClick('tab4')} className={customActiveTab === 'tab4' ? 'nav-item nav-link active' : 'nav-item nav-link'} role="tab" aria-controls="nav-flight" aria-selected="false">Flight</a>
                                    </div>
                                </nav>
                                <div className="tab-content" id="nav-tabContent">
                                    <div className={customActiveTab === 'tab1' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-price-tab">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr className="table-primary text-center">
                                                    <th colSpan="5">Meghalaya Tour Cost from Guwahati Airport (GAU)</th>
                                                </tr>
                                                <tr className="table-danger">
                                                    <th>Itinerary</th>
                                                    <th>02 Head (INR)</th>
                                                    <th>04 Head (INR)</th>
                                                    <th>06 Head (INR)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>06 nights – 07 days</td>
                                                    <td>33,700 PP</td>
                                                    <td>26,800 PP</td>
                                                    <td>22,800 PP</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className={customActiveTab === 'tab2' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-hotels-tab">
                                        <h5 className="center font-weight">3-Star Hotels</h5>
                                        <div className="row row-cols-1 row-cols-md-4 g-4">

                                            {hotelDataView}

                                        </div>
                                    </div>
                                    <div className={customActiveTab === 'tab3' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-travellers-info-tab">
                                        {travelDataView}
                                    </div>
                                    <div className={customActiveTab === 'tab4' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-flight-tab">
                                        <span><b>Guwahati Non-Stop Flight (GAU)</b></span>
                                        <br />
                                        <br />
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr className="table-danger">
                                                    <th>FLIGHT</th>
                                                    <th>AIRLINES</th>
                                                    <th>AIRPORT</th>
                                                    <th>TIME</th>
                                                    <th>DAY</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>6E262</td>
                                                    <td>IndiGo</td>
                                                    <td>Delhi</td>
                                                    <td>07:25 - 09:40</td>
                                                    <td>M,T,W,T,F,S,S</td>
                                                </tr>
                                                <tr>
                                                    <td>SG1852</td>
                                                    <td>SpiceJet</td>
                                                    <td>Delhi</td>
                                                    <td>10:10 - 12:15</td>
                                                    <td>M,T,W,T,F,S,S</td>
                                                </tr>
                                                <tr>
                                                    <td>6C228</td>
                                                    <td>IndiGo</td>
                                                    <td>Mumbai</td>
                                                    <td>05:40 - 08:50</td>
                                                    <td>M,T,W,T,F,S,S</td>
                                                </tr>
                                                <tr>
                                                    <td>SG695</td>
                                                    <td>SpiceJet</td>
                                                    <td>Mumbai</td>
                                                    <td>06:40 - 09:30</td>
                                                    <td>M,T,W,T,F,S,S</td>
                                                </tr>
                                                <tr>
                                                    <td>6C457</td>
                                                    <td>IndiGo</td>
                                                    <td>Bengaluru</td>
                                                    <td>08:20 - 11:20</td>
                                                    <td>M,T,W,T,F,S,S</td>
                                                </tr>
                                                <tr>
                                                    <td>QP1323</td>
                                                    <td>Akasa Air</td>
                                                    <td>Bengaluru</td>
                                                    <td>09:20 - 11:20</td>
                                                    <td>M,T,W,T,F,S,S</td>
                                                </tr>
                                                <tr>
                                                    <td>6E6309</td>
                                                    <td>IndiGo</td>
                                                    <td>Chennai</td>
                                                    <td>05:40 - 08:25</td>
                                                    <td>M,T,W,T,F,S,S</td>
                                                </tr>
                                                <tr>
                                                    <td>6E6011</td>
                                                    <td>IndiGo</td>
                                                    <td>Chennai</td>
                                                    <td>15:55 - 18:30</td>
                                                    <td>M,T,W,T,F,S,S</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            {/* <!--insert table inclusion exclusion--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        {
                                            <InclusionExclusion />
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* <!--end table inclusion exclusion--> */}


                            <div className="container-fluid">
                                <div className="row placetoVisit">
                                    <h2>BEST PLACES TO VISIT AT MEGHALAYA</h2>
                                    <ul>
                                        <li><strong>Shillong - </strong> The capital city of Meghalaya, Shillong, is often referred to as the "Scotland of the East." It offers a pleasant climate, stunning viewpoints like Shillong Peak and Elephant Falls, and vibrant markets.</li>
                                        <li> <strong>Cherrapunji - </strong> Famous for being one of the wettest places on earth, Cherrapunji boasts mesmerizing landscapes, including Nohkalikai Falls, Mawsmai Cave, and the living root bridges of Nongriat village.</li>
                                        <li> <strong>Mawlynnong - </strong> Recognized as Asia's cleanest village, Mawlynnong is a picturesque hamlet known for its cleanliness, serene atmosphere, and the stunning Sky View Point offering panoramic views of the surrounding landscape.</li>
                                        <li> <strong>Dawki - </strong>  Situated near the Indo-Bangladesh border, Dawki is renowned for its crystal-clear Umngot River, ideal for boating and water activities. The suspension bridge over the river is also a popular attraction.</li>
                                        <li> <strong>Laitlum Canyons - </strong> Offering breathtaking views of deep gorges and lush green valleys, Laitlum Canyons near Shillong is a perfect spot for nature lovers and adventure enthusiasts. </li>
                                        <li><strong>Nongkhnum Island - </strong>  Located in the West Khasi Hills district, Nongkhnum Island is the largest river island in Meghalaya. Visitors can enjoy scenic beauty, trekking, and camping amidst nature. </li>
                                        <li><strong>Mawsynram - </strong> Another contender for the title of the wettest place on earth, Mawsynram is famous for its lush green landscapes, numerous waterfalls, and unique limestone caves. </li>
                                        <li><strong>Jaintia Hills - </strong> Known for its rich cultural heritage and natural beauty, Jaintia Hills offers attractions like Krang Suri Falls, Thadlaskein Lake, and the Nartiang Monoliths, ancient stone monoliths dating back to the Jaintia kings.</li>
                                        <li><strong>Elephant Falls - </strong> Located just outside Shillong, Elephant Falls is a three-tiered waterfall surrounded by lush greenery, making it a popular picnic spot and photography location.</li>
                                        <li><strong>Siju Cave - </strong>  Situated in the Garo Hills region, Siju Cave is one of the longest caves in India, offering an adventurous experience for spelunkers and nature enthusiasts.</li>
                                    </ul>

                                </div>
                                <div className="container-fluid py-5">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="site-heading text-center">
                                                <h2>Branded Meghalaya Tour <span>- Ri Kynjai</span></h2>
                                                <h4><i>Next Level Luxury Resort</i></h4>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row py-3">
                                        <img src={brandedTourackageImage_1_URL} alt="..." />
                                    </div>

                                </div>

                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Packages</th>
                                            <th>Duration</th>
                                            <th>Hotel Brand</th>
                                            <th>Price (Per Head)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Luxury Trip</td>
                                            <td>5N/6D</td>
                                            <td>Ri Kynjai , Polo<div class="reviews">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </div>
                                            </td>
                                            <td>Rs 71,569/- </td>
                                        </tr>

                                    </tbody>
                                </table>
                                <div className="card mx-0 my-5">
                                    <div class="card-header custHeaer">
                                        Ri Kynjai Meghalaya Packages - Four Star Khasi Brand
                                    </div>
                                    <div class="card-body">
                                        <p>With a name that literally means “Serenity by the Lake” in Khasi, Ri Kynjai – Serenity By The Lake
                                            offers you the perfect getaway from the urban hubbub. Nature lovers can choose to luxuriate in perfectly
                                            appointed cottages, undergo traditional Khasi spa treatments and dine on local delicacies.</p>
                                        <h3 class="px-2">About the Architecture</h3>
                                        <p>The architecture is inspired and derived from the original Khasi thatch huts. Legend has it that
                                            millennia ago the sea caressed the southern slopes of the Himalayas and the Ancient Khasis may have been
                                            a sea faring community. They readily adopted the upturned boat roof from which is inherently capable of
                                            withstanding the harsh monsoon winds and incessant rainfall. The resort reflects the warmth of Khasi
                                            hospitality, their eco-centric culture and captivating architecture combined with modern convenience and
                                            luxury. Set against the backdrop of the enchanting Khasi Hills, Ri Kynjai is spread over 45 acres and
                                            weaves three architectural styles into a harmonious whole that synergises perfectly with the idyllic
                                            natural surroundings. At the garden area are the Dining Room, Bar and conference facilities – all with a
                                            panoramic view of the lake, hills and forest. The Dining Room and Bar, ‘Sao Aiom’ serves Indian,
                                            Continental, Chinese and Northeastern specialities.<br /><br />
                                            <i>The Resort Emporium showcases textiles and crafts from around the Northeast. The ‘Khem Janai Spa’ is
                                                equipped with state-of-art hydrotherapy equipment. Traditional Khasi massage and Aqua Therapy are the
                                                highlights of the spa menu. To top it all, Ri Kynjai is designed with its orientation to the east –
                                                capturing the view of the lake and the rising sun a sublime wake up call for those who wish to revel
                                                in
                                                the ‘Spirit of the Rising Sun’.</i>
                                        </p>

                                        <a href="tel://9883359713" class="btn btn-Warning">Book Now</a>
                                    </div>
                                </div>

                            </div>

                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>Frequently Ask <span>Questions</span></h2>
                                            <h4>Meghalaya Trip Related Questions</h4>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="accordion accordion-flush faQStyle" id="accordionFlushExample">
                                        <div class="accordion-item">
                                            <h4 class="accordion-header" id="flush-headingOne">
                                                <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseOne" aria-expanded="true" aria-controls="flush-collapseOne">
                                                    What is the best time to visit Meghalaya?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseOne" class="accordion-collapse collapse show" aria-labelledby="flush-headingOne"
                                                data-bs-parent="#accordionFlushExample">
                                                <div class="accordion-body">The best time to visit Meghalaya is during the winter season from November
                                                    to February when the weather is pleasant and dry. The temperature is moderate with clear blue skies
                                                    which makes it suitable for sightseeing and other outdoor activities.</div>
                                            </div>
                                        </div>
                                        <div class="accordion-item">
                                            <h4 class="accordion-header" id="flush-headingTwo">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                                    What are the popular tourist destinations in Meghalaya that can be covered in a tour package from
                                                    Assam?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo"
                                                data-bs-parent="#accordionFlushExample">
                                                <div class="accordion-body">Some popular tourist destinations in Meghalaya that can be covered in a
                                                    tour package from Assam are Shillong, Cherrapunji, Mawlynnong, Dawki, Laitlum Canyon, Elephant
                                                    Falls, Umngot River, etc.</div>
                                            </div>
                                        </div>

                                        <div class="accordion-item">
                                            <h4 class="accordion-header" id="flush-headingThree">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                                    How long is the Meghalaya tour package from Assam?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree"
                                                data-bs-parent="#accordionFlushExample">
                                                <div class="accordion-body">The length of the Meghalaya tour package from Assam can vary depending on
                                                    the package chosen, but typically it ranges from 3 to 6 days. During this time, you can cover the
                                                    major attractions such as the Elephant Falls, Cherrapunji, Umngot River, Dawki Bridge, Shillong,
                                                    etc.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item">
                                            <h4 class="accordion-header" id="flush-headingFour">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                                    What is the mode of transportation to explore Meghalaya?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFour"
                                                data-bs-parent="#accordionFlushExample">
                                                <div class="accordion-body">The most common mode of transportation in Meghalaya is by road. Taxis,
                                                    buses, and private cars are readily available for hire. Trekking and walking are also popular modes
                                                    of transportation to explore the natural beauty of the state</div>
                                            </div>
                                        </div>
                                        <div class="accordion-item">
                                            <h4 class="accordion-header" id="flush-headingFive">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                                                    What are the best souvenirs from Assam?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseFive" class="accordion-collapse collapse" aria-labelledby="flush-headingFive"
                                                data-bs-parent="#accordionFlushExample">
                                                <div class="accordion-body">Assamese silk, tea leaves, bamboo and wood handicrafts, traditional
                                                    jewellery and maskas are some interesting souvenirs that can be bought from Assam.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item">
                                            <h4 class="accordion-header" id="flush-headingSix">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                                                    Do I need any special permissions to visit the North East?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseSix" class="accordion-collapse collapse" aria-labelledby="flush-headingSix"
                                                data-bs-parent="#accordionFlushExample">
                                                <div class="accordion-body">Assam, Meghalaya and Tripura do not need any permits to visit. Arunachal
                                                    Pradesh, Mizoram, Manipur and Nagaland, however, require an ILP or Inner Line Permit to visit.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item">
                                            <h4 class="accordion-header" id="flush-headingSeven">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
                                                    Where can I start a road trip through the North East?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseSeven" class="accordion-collapse collapse" aria-labelledby="flush-headingSeven"
                                                data-bs-parent="#accordionFlushExample">
                                                <div class="accordion-body">Guwahati is the best place to start a road trip across the North East. It
                                                    is one of the biggest cities in the region when you can get the Inner Line Passes for other states
                                                    quite easily.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item">
                                            <h4 class="accordion-header" id="flush-headingEight">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
                                                    Does it snow in the North East?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseEight" class="accordion-collapse collapse" aria-labelledby="flush-headingEight"
                                                data-bs-parent="#accordionFlushExample">
                                                <div class="accordion-body">Arunachal Pradesh and Sikkim are the two north east states that receive
                                                    seasonal snowfall during winter.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item">
                                            <h4 class="accordion-header" id="flush-headingNine">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseNine">
                                                    Is there proper network coverage?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseNine" class="accordion-collapse collapse" aria-labelledby="flush-headingNine"
                                                data-bs-parent="#accordionFlushExample">
                                                <div class="accordion-body">Yes most part of Assam, Meghalaya has flawless network area. Only ares
                                                    like Dawki, Mawlylnnog will some issue. Otherwise there will be full 4g network in all places for
                                                    Airtel, Vodafone,& Jio.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item">
                                            <h4 class="accordion-header" id="flush-headingTen">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
                                                    Will prepaid work or postpaid?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseTen" class="accordion-collapse collapse" aria-labelledby="flush-headingTen"
                                                data-bs-parent="#accordionFlushExample">
                                                <div class="accordion-body">Both have a proper Network.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item">
                                            <h4 class="accordion-header" id="flush-headingEleven">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseEleven" aria-expanded="false" aria-controls="flush-collapseEleven">
                                                    Do I need a permit to visit northeast India?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseEleven" class="accordion-collapse collapse" aria-labelledby="flush-headingEleven"
                                                data-bs-parent="#accordionFlushExample">
                                                <div class="accordion-body">Not for all states. But, out of the 7 northeastern states, you will need
                                                    permit for 3 of these states: Arunachal Pradesh, Nagaland, and Mizoram. This permit is called
                                                    as Inner Line Permit (ILP). A permit can be obtained at any Arunachal Bhawan, Nagaland Bhawan, or
                                                    Mizoram Bhawan, located outside of northaest. Major airports in northaest, like Guwahati
                                                    international airport, have the facility of providing permits. The government of Arunachal Pradesh
                                                    has also provided the facility of online application of Inner Line Permits. For foreign nationals,
                                                    you will require another type of permit called as Protected Area Permit (PAP).
                                                    We usually include the cost of application for ILPs in the total cost of our tour packages to keep
                                                    the tour hassle free for you.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item">
                                            <h4 class="accordion-header" id="flush-headingTwelve">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseTwelve" aria-expanded="false" aria-controls="flush-collapseTwelve">
                                                    Do I need an extra vehicle for visiting Bum La Pass in Tawang?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseTwelve" class="accordion-collapse collapse" aria-labelledby="flush-headingTwelve"
                                                data-bs-parent="#accordionFlushExample">
                                                <div class="accordion-body">As per the local taxi associations laws of Tawang, vehicles outside of
                                                    Tawang are not permitted to visit Bum La Pass and the adjacent areas. So, you will have to hire a
                                                    local taxi for that day.
                                                    We usually keep this as optional (and don’t include the cost of the additional taxi in our package)
                                                    as some guests choose to skip the sightseeing of Bum La. So, we let the guests decide, after
                                                    reaching Tawang, and if they choose to go for it then we help them in arranging a local taxi in
                                                    Tawang.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item">
                                            <h4 class="accordion-header" id="flush-headingThirteen">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseThirteen" aria-expanded="false"
                                                    aria-controls="flush-collapseThirteen">
                                                    What types of food are available in Northeast India? Is vegetarian food common in northeast?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseThirteen" class="accordion-collapse collapse"
                                                aria-labelledby="flush-headingThirteen" data-bs-parent="#accordionFlushExample">
                                                <div class="accordion-body">From pure vegetarian to non-vegetarian foods, all sorts of food are
                                                    available in Northeast India. Although the cuisine of certain places differs from the rest, there is
                                                    no scarcity in menus offered by restaurants, in the tourist hotspots.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item">
                                            <h4 class="accordion-header" id="flush-headingFourteen">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseFourteen" aria-expanded="false"
                                                    aria-controls="flush-collapseFourteen">
                                                    What are the major festivals of Northeast Indian states? Which time is the best to witness the
                                                    festivals?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseFourteen" class="accordion-collapse collapse"
                                                aria-labelledby="flush-headingFourteen" data-bs-parent="#accordionFlushExample">
                                                <div class="accordion-body">Assam celebrates Bihu during mid-April. Tawang, in Arunachal Pradesh,
                                                    celebrates Tawang (Monpa) festival during the second week of October. Kohima, in Nagaland,
                                                    celebrates Hornbill festival during second week of December.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container-fluid py-4">

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h3><b>Meghalaya <span>Theme Package</span></b></h3>
                                            <h4>Similar Mountain Trip</h4>

                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                                        <div className="carousel-inner">
                                            <div className="carousel-item active">
                                                <Slider {...settings}>

                                                    {themePackagesDataView}

                                                </Slider>
                                            </div>
                                        </div>
                                    </div><br />
                                </div>
                            </div>

                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h3 className="tomato"><b>Meghalaya Climate Guide</b></h3>
                                            <h4><i>"Yearly Temperature Update"</i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table className="table table-bordered table-striped table-condensed cf">
                                        <thead>
                                            <tr className="table-primary">
                                                <th scope="col">Destination</th>
                                                <th scope="col"></th>
                                                <th scope="col">Jan</th>
                                                <th scope="col">Feb</th>
                                                <th scope="col">Mar</th>
                                                <th scope="col">Apr</th>
                                                <th scope="col">May</th>
                                                <th scope="col">Jun</th>
                                                <th scope="col">Jul</th>
                                                <th scope="col">Aug</th>
                                                <th scope="col">Sept</th>
                                                <th scope="col">Oct</th>
                                                <th scope="col">Nov</th>
                                                <th scope="col">Dec</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="table-danger">
                                                <th scope="row" rowspan="3">Kaziranga</th>
                                                <td rowspan="3">Min Temp(C)<br />Max Temp(C)<br />clear days</td>
                                                <td rowspan="3">13.5<br />22.2<br />27</td>
                                                <td rowspan="3">15.6<br />24.4<br />21</td>
                                                <td rowspan="3">18.3<br />27.4<br />21</td>
                                                <td rowspan="3">20.5<br />28<br />14</td>
                                                <td rowspan="3">22.7<br />29.1<br />11</td>
                                                <td rowspan="3">24.8<br />30.2<br />9</td>
                                                <td rowspan="3">25.3<br />30.4<br />9</td>
                                                <td rowspan="3">25.2<br />30.6<br />10</td>
                                                <td rowspan="3">24.5<br />30.1<br />10</td>
                                                <td rowspan="3">22.1<br />28.7<br />19</td>
                                                <td rowspan="3">18.3<br />26.1<br />27</td>
                                                <td rowspan="3">14.9<br />23.2<br />29</td>
                                            </tr>
                                            <tr></tr>
                                            <tr></tr>
                                            <tr></tr>
                                            <tr>
                                                <th scope="row" rowspan="3">Shillong</th>
                                                <td rowspan="3">Min Temp(C)<br />Max Temp(C)<br />clear days</td>
                                                <td rowspan="3">7.4<br />16.9<br />27</td>
                                                <td rowspan="3">9.1<br />19<br />22</td>
                                                <td rowspan="3">11.9<br />21.7<br />19</td>
                                                <td rowspan="3">15<br />22.9<br />11</td>
                                                <td rowspan="3">17.4<br />23.7<br />10</td>
                                                <td rowspan="3">19.5<br />24.3<br />9</td>
                                                <td rowspan="3">19.9<br />24.4<br />9</td>
                                                <td rowspan="3">19.7<br />24.4<br />9</td>
                                                <td rowspan="3">18.7<br />23.9<br />10</td>
                                                <td rowspan="3">15.9<br />22.6<br />16</td>
                                                <td rowspan="3">12<br />20.2<br />24</td>
                                                <td rowspan="3">9.1<br />17.8<br />29</td>
                                            </tr>
                                            <tr></tr>
                                            <tr></tr>
                                            <tr></tr>
                                            <tr class="table-danger">
                                                <th scope="row" rowspan="3">Cherrapunji</th>
                                                <td rowspan="3">Min Temp(C)<br />Max Temp(C)<br />clear days</td>
                                                <td rowspan="3">7.7<br />18.5<br />29</td>
                                                <td rowspan="3">9.6<br />20.5<br />26</td>
                                                <td rowspan="3">12.9<br />22.9<br />29</td>
                                                <td rowspan="3">16.4<br />23.9<br />29</td>
                                                <td rowspan="3">18.6<br />24.7<br />22</td>
                                                <td rowspan="3">20.4<br />24.9<br />14</td>
                                                <td rowspan="3">20.7<br />24.8<br />19</td>
                                                <td rowspan="3">20.7<br />25.1<br />21</td>
                                                <td rowspan="3">19.9<br />24.9<br />22</td>
                                                <td rowspan="3">16.8<br />24.1<br />28</td>
                                                <td rowspan="3">12.2<br />22<br />30</td>
                                                <td rowspan="3">9.2<br />19.5<br />30</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>


                            <div className="container-fluid py-5">
                                <div className="card">
                                    <div className="card-header custHeaer">
                                        Discover the Best Time to Book Meghalaya Tour Package with Adorable Vacation LLP
                                    </div>
                                    <div className="card-body">
                                        <span className="icon-img-color">
                                            <i className="fa fa-sun-o" aria-hidden="true"></i>&nbsp;
                                            <span className="black"><strong>Meghalaya in summer (April-June)</strong></span>
                                        </span>
                                        <br />
                                        <span>Meghalaya experiences temperature ranging between
                                            12°C and 30°C, with pleasant mornings and evenings. It can be considered a good time to explore the
                                            unexplored corners of the town.</span><br />
                                        <br />
                                        <span className="icon-img-color">
                                            <i className="fa fa-umbrella" aria-hidden="true"></i>&nbsp;
                                            <span className="black"><strong>Meghalaya in Monsoon (June-October)</strong></span>
                                        </span>
                                        <br />
                                        <span>June to October is the monsoon months in
                                            Meghalaya. During these months, the state receives heavy rainfall. In fact, Meghalaya has world's two
                                            rainiest cities; Mawsynram and Cherrapunjee. <br />
                                            You can plan your trip to Meghalaya anytime during the year, While the ever beautiful state of Meghalaya
                                            looks like a lush green land and spreads a different charm during monsoon when the State receives high
                                            rainfall.</span><br />
                                        <br />
                                        <span className="icon-img-color">
                                            <i className="fa fa-snowflake-o" aria-hidden="true"></i>&nbsp;
                                            <span className="black"><strong>Meghalaya in Winter</strong></span>
                                        </span>
                                        <br />
                                        <span>Meghalaya experiences autumn between October and November and
                                            winter season from December to February. The average day-time temperature is extremely pleasant at 15°C,
                                            but the night-time temperature drops to around 3°C during the winters. Foggy days are typical here. It
                                            is a treat for those who wish to get engulfed in the Fog amidst beautiful surroundings left behind by
                                            the monsoons. One can unleash the explorer in them and go sightseeing or participate in a trekking
                                            expedition to traverse through the thrilling mountainous terrain. Shillong, Cherrapunji, Tura, Nongstoin,
                                            Nongpoh, Guwahati, East Garo, Jowai etc. are visited often during this season to explore attractions
                                            like Balpakram National Park, Umiam Lake, Laitlum Canyon, Double Decker Living Route Bridge, Ialong Park
                                            etc.</span><br />
                                        <br />
                                    </div>
                                </div>
                            </div >


                            <div className="container-fluid py-5">

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h3>
                                                <b>
                                                    Meghalaya <span className="tomato">Tour Reviews</span>
                                                </b>
                                            </h3>
                                            <h4><i>"Your Experience is our Marketing"</i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-cols-1">
                                    <div id="testimonials">
                                        <div className="testimonial-box-container">

                                            {reviewersDataView}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div >
                        <div className="col-md-3">
                            <BookingBox />
                        </div>

                    </div >
                </div >
                <div class="call-float">
                        <a href="tel:+91-91636-76037">
                            <i className="fa fa-phone"></i><span><small>+91 9163676037</small></span>
                        </a>
                    </div>
            </>
        );
    }
}

export default MeghalayaTourPackage;