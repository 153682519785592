import React, { Component } from 'react';
import MainHeader from '../../../components/common/MainHeader';
import FooterDesktop from '../../../components/common/FooterDesktop';
import Sandakphu_trekking_Package from '../../../components/package/Sandakphu/Sandakphu_trekking_Package';
import { Helmet } from 'react-helmet';

class Sandakphu_trekking_Page extends Component {
    constructor() {
        super();
        this.state = {
            ReviewersData: [],
        }
    }

    componentDidMount() {
        window.scroll(0, 0);
        const reviewersDataList = [
            {
                Name: "Dr. Samir Khan",
                ImageName: "Dr-Samir-Khan.webp",
                AllternateText: "...",
                Location: "@Mumbai",
                Comment: "Our 5-night, 6-day family trip to Sandakphu with Adorable Vacation was an unforgettable adventure! The journey offered breathtaking views of the Himalayas, and each day was filled with exciting activities and beautiful landscapes. The accommodations were cozy and comfortable, perfect for unwinding after our daily treks. We cherished the time spent together amidst nature's splendor and are grateful for the seamless organization by Adorable Vacation. Highly recommend this trip for a family adventure",
            },
            {
                Name: "Mrs. Sonali Shah",
                ImageName: "Mrs-Sonali-Shah.webp",
                AllternateText: "...",
                Location: "@Pune",
                Comment: "I recently spent 4 nights and 5 days exploring the stunning Sandakphu with my friends, all thanks to Adorable Vacation. From start to finish, the experience was incredible. The journey took us through breathtaking landscapes and charming villages, offering panoramic views of the Himalayas, including the majestic Kanchenjunga. Adorable Vacation truly delivered an unforgettable adventure, and I can't wait to plan my next trip with them. Highly recommended for anyone seeking an extraordinary travel experience",
            },
            {
                Name: "Mr. Kunal Roy",
                ImageName: "Mr-Kunal-Roy.webp",
                AllternateText: "...",
                Location: "@Kolkata",
                Comment: "My recent trip to Sandakphu,Tumling, Manebhanjan offbeat trekking with Adorable Vacation was an incredible 4-night, 5-day adventure with friends. We experienced the best of Sandakphu’s natural beauty and local culture. The accommodations were cozy, and the food was delicious, adding to the overall fantastic experience. The trip was well-organized, making it a memorable getaway filled with fun, laughter, and stunning landscapes. Highly recommend Adorable Vacation for a seamless and unforgettable travel experience",
            },
            {
                Name: "Ms. Monali Kharge",
                ImageName: "Ms-Monali-Kharge.webp",
                AllternateText: "...",
                Location: "@Mumbai",
                Comment: "We just returned from a thrilling 5-night, 6-day trip to Sandakphu, organized by Adorable Vacation. The journey was nothing short of spectacular! The trek offered breathtaking views of the Himalayan range, including the majestic Kanchenjunga. Our driver were knowledgeable and friendly, making the challenging trails manageable and fun. Each night, we stayed in cozy tea houses with stunning mountain vistas. The camaraderie among friends and the awe-inspiring landscapes made this trip truly unforgettable. Highly recommend Adorable Vacation for a seamless and memorable adventure",
            },
        ]

        this.setState({
            ReviewersData: reviewersDataList,
        })
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>Book Sandakphu Trekking Package from NJP – Best Deal </title>
                    <meta name="description" content="Explore the breathtaking beauty of Sandakphu Phalut with our exclusive trekking package from NJP. Enjoy a seamless journey with guided treks and top-notch accommodations. Book Now: +91-91636-76037" />
                </Helmet>

                <MainHeader />

                <Sandakphu_trekking_Package
                    ReviewersData={this.state.ReviewersData}
                />

                <FooterDesktop />
            </>
        );
    }
}

export default Sandakphu_trekking_Page;