import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import ApiURL from "../../../api/ApiURL";
import InclusionExclusion from '../Sikkim_Darjeeling/InclusionExclusionSikkim_5N_6D';
import BookingBox from '../BookingBox';

class Sikkim_5N_6D_Package extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customActiveTab: 'tab1',
            daywiseActiveTab: 'day1'
        }
    }

    handleCustomTabClick = (tab) => {
        this.setState({
            customActiveTab: tab,
        });
    };

    handleDayWiseTabClick = (tab) => {
        this.setState({
            daywiseActiveTab: tab,
        });
    };
    handleClick = () => {
        const currentUrl = window.location.href;
        const pageTitle = document.title;
        const message = `Hi, Please send about the Tour Package details "${pageTitle}"`;

        const whatsappLink = `https://api.whatsapp.com/send?phone=+919147061467&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
        window.open(whatsappLink, '_blank');
    };
    render() {
        const { customActiveTab, daywiseActiveTab } = this.state;
        const packageTopBannerImageURL = ApiURL.Sikkim_5N_6D_PackageImageURL + "/Sikkim-Tour-from-Bagdogra-Airport.webp";
        const packageTopBanner2ImageURL = ApiURL.Sikkim_5N_6D_PackageImageURL + "/Sikkim-Tour-Packages-from-Bagdogra.webp";
        const packageDestinationBannerImageURL = ApiURL.Sikkim_5N_6D_PackageImageURL + "/Sikkim-Travel-Agents.webp";
        const brandedTourackageImage_1_URL = ApiURL.Sikkim_5N_6D_PackageImageURL + "/Cilantro-Hotels.webp";
        const brandedTourackageImage_2_URL = ApiURL.Sikkim_5N_6D_PackageImageURL + "/Ramada-Hotels.webp";
        const Topbanner0ImageURL = ApiURL.Sikkim_5N_6D_PackageImageURL + "/Sikkim-Tour-Package-with-Helicopter-Ride.webp";
        const Topbanner1ImageURL = ApiURL.Sikkim_5N_6D_PackageImageURL + "/River-Rafting-at-Teesta-River.webp";
        const TopbannerImageURL = ApiURL.Sikkim_5N_6D_PackageImageURL + "/Sikkim-Holidays-Package-from-Bagdogra-Airport.webp";
        const navDay1ImageURL = ApiURL.Sikkim_5N_6D_PackageImageURL + "/Sikkim-Packages-from-Bagdogra-Airport.webp";
        const navDay2ImageURL = ApiURL.Sikkim_5N_6D_PackageImageURL + "/Sikkim-Tour-Plan-from-Bagdogra.webp";
        const navDay3ImageURL = ApiURL.Sikkim_5N_6D_PackageImageURL + "/Gangtok-Sikkim-Tour-Plan.webp";
        const navDay4ImageURL = ApiURL.Sikkim_5N_6D_PackageImageURL + "/Gangtok-Nathula-Package-Plan.webp";
        const navDay5ImageURL = ApiURL.Sikkim_5N_6D_PackageImageURL + "/Best-Sikkim-Darjeeling-Tour-Plan.webp";
        const navDay6ImageURL = ApiURL.Sikkim_5N_6D_PackageImageURL + "/Best-Sikkim-Tour-Plan.webp";

        const hotPackagesDataList = this.props.HotPackagesData;
        const hotPackagesView = hotPackagesDataList.length > 0 ?
            (
                hotPackagesDataList.map((dataItem, i) => {
                    const hotImgURL = ApiURL.Sikkim_5N_6D_PackageImageURL + "/" + dataItem.ImageName;
                    // const contactURL = dataItem.Url;
                    return (<div className="col-md-4" key={i}>
                        <div className="card h-100">
                            <img src={hotImgURL} className="card-img-top" alt={dataItem.AllternateText} />
                            <div className="card-body">
                                <h6 className="card-title">{dataItem.Title} <span> &#8377; {dataItem.PricePerNight}/-</span></h6>
                                <p className="card-text">{dataItem.Description}</p>
                            </div>
                            <div className="card-footer">
                                <small className="text-muted">{dataItem.MutedText}</small>
                                <a href="#" className="btn btn-success btn-sm float-end" onClick={this.handleClick}>
                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                </a>
                            </div>
                        </div>
                    </div>)
                })
            ) :
            (<div></div>)


        const settings = {
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const hotelDataList = this.props.HotelsData;
        const hotelDataView = hotelDataList.map((hotelItem, i) => {
            const hotelImageURL =
                ApiURL.Sikkim_5N_6D_PackageImageURL + "/" + hotelItem.ImageName;
            return (
                <div className="col-md-3" key={i}>
                    <img
                        src={hotelImageURL}
                        className="rounded mx-auto d-block"
                        alt={hotelItem.AllternateText}
                    />
                    <br />
                    <span>
                        <b>{hotelItem.HotelName}</b> |{" "}
                        <span className="tomato">{hotelItem.HotelStar}</span>
                    </span>
                    <br />
                    <span className="rating-box">
                        <span className="star">{hotelItem.RatingBoxStar}</span>
                        <b className="rating-value">{hotelItem.RatingValue}</b>
                    </span>{" "}
                    &nbsp;<b>{hotelItem.Reviews}</b>
                </div>
            );
        });

        const reviewersDataList = this.props.ReviewersData;
        const reviewersDataView = reviewersDataList.map((dataItem, i) => {
            const reviewerImageURL = ApiURL.Sikkim_5N_6D_PackageImageURL + "/" + dataItem.ImageName;
            return (
                <div className="testimonial-box" key={i}>
                    <div className="box-top">
                        <div className="profile">
                            <div className="profile-img">
                                <img src={reviewerImageURL} alt="..." />
                            </div>
                            <div className="name-user">
                                <strong>{dataItem.Name}</strong>
                                <span>{dataItem.Location}</span>
                            </div>
                        </div>
                        <div className="reviews">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </div>
                    </div>
                    <div className="client-comment">
                        <p>{dataItem.Comment}</p>
                    </div>
                </div>
            )
        })


        return (
            <>
                <div className="container-fluid">
                    <div className="row">
                        <img src={packageTopBannerImageURL} className="mobile-image2" alt="..." />
                    </div>
                </div>
                <div className="container-fluid custom-container py-3">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="container-fluid custom-container py-1">
                                        <h1 className="content-desktop" style={{ fontSize: "2vw" }}>Looking for <b>SIKKIM TOUR PACKAGE </b>- Bagdogra Airport (IXB)</h1>

                                        <h1 className="content-mobile" style={{ fontSize: "4vw" }}><center>Looking for <b>Sikkim Tour Package!</b></center>
                                            <hr />
                                        </h1>
                                        <div className="row">
                                            <img src={packageTopBanner2ImageURL} className="mobile-image" alt="..." />

                                        </div>
                                        <hr />
                                        <div className="col-12">
                                            <big className="text-muted"><strong className="tomato">5N/6D</strong></big>&nbsp;
                                            <i className="fa fa-plane-departure"></i>&nbsp;&nbsp;
                                            <i className="fa fa-arrow-right"></i>&nbsp;Gangtok(3N)&nbsp;
                                            <i className="fa fa-arrow-right"></i>&nbsp;Lachen(1N)&nbsp;
                                            <i className="fa fa-arrow-right"></i>&nbsp;Lachung(1N)&nbsp;
                                            <i className="fa fa-arrow-right"></i>&nbsp;&nbsp;
                                            <i className="fa fa-plane-departure fa-flip-horizontal"></i>
                                            <br />
                                        </div>
                                        <hr />
                                        <h2 className="content-desktop p-2" style={{ fontSize: "1.5vw" }}> For Booking <b>SIKKIM TRAVEL PACKAGES</b> <a href='tel:9147061467' class="tomato">☎ CALL Mr. Soumya Sankar  +91- 91470-61467</a></h2>
                                        <h2 className="content-mobile p-4" style={{ fontSize: "4.5vw" }}> For Booking <b>SIKKIM TRAVEL PACKAGES</b> <a href='tel:9147061467' class="tomato">☎ CALL Mr. Soumya Sankar  +91- 91470-61467</a></h2>

                                        <div className="card">
                                            <div className="card-header custHeaer">
                                                <h3 className='content-desktop' style={{ fontSize: "1.5vw" }}>Heartwarming <b>Sikkim Gangtok Tour</b> Activities </h3>
                                                <h3 className='content-mobile2' style={{ fontSize: "3.5vw" }}>Heartwarming <b>Sikkim Gangtok Tour</b> Activities </h3>
                                            </div>
                                            <div className="card-body">
                                                <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>LUXURY HELICOPTER RIDE (Bagdogra to Gangtok):</b><b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>LUXURY HELICOPTER RIDE (Bagdogra to Gangtok):</b> Do you know that you can actually avoid the tiring four to five hour Bagdogra to Gangtok journey along the winding uphill road. You can instead take a helicopter ride during Sikkim Holiday.</p>
                                                <p><b>DIRECTION -</b> Bagdogra Airport to Gangtok.</p>
                                                <center> <img src={Topbanner0ImageURL} className="img-fluid" alt="..." /></center>
                                                <p><b>SERVICE -</b> Sikkim Tourism Development Corporation (STDC)
                                                    DURATION - 35 Minutes</p>
                                                <p><b>HELICOPTER SCHEDULE -</b>
                                                    <ol>
                                                        <li>Gangtok (Burtuk)10:30 A.M – Bagdogra 11:00 A.M</li>
                                                        <li>Bagdogra 11:30 A.M – Gangtok (Burtuk) -12:00 P.M</li>
                                                        <li>Gangtok (Burtuk) 12:30 P.M – Bagdogra – 01:00 P.M</li>
                                                        <li>Bagdogra 01:30 P.M – Gangtok (Burtuk) – 2: 00 P.M</li>
                                                        <li>Gangtok Joy Ride of 20 Mins – Rs.3000/- per person.</li>
                                                    </ol></p>
                                                <p><b>FARE INFORMATION -</b>
                                                    <ol>
                                                        <li>@Rs.5000/- per person one way, Gangtok -Bagdogra</li>
                                                        <li>Child under 2 years considered as infant/ticket fare is Rs.1200. Infant shall not be allotted a seat.</li>
                                                        <li>Luggage – 22 inch of luggage allowed with the Total weight of 10kgs (strictly).</li>
                                                    </ol>
                                                </p>
                                                <p><b>HELICOPTER SPECIFICATION</b></p>
                                                <ul>
                                                    <li>
                                                        Make – MI 172.
                                                    </li>
                                                    <li>Seating Capacity – 20 Seater.</li>
                                                </ul><br />
                                                <p><i>Note: ❖ All the fights are subject to weather condition, adjustment in flight time can also occur during VIP movement, medical emergencies and Air Force Exercise.</i></p>

                                                <br />
                                                <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>RIVER RAFTING - TEESTA RIVER:</b><b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>RIVER RAFTING - TEESTA RIVER:</b></p>
                                                <p>During <b>Sikkim Package Tour</b> from Bagdogra Airport - You can indulge yourself with white water river rafting at mighty Tessta River. Are you an adventure seekers ? We heard yes. You are in the right tourist place. Heart-pumping rapids and high adrenaline are what you will experience at the Rafting Activity. The Teesta River is famous for its rapids and hence hosts rafting activities. So all you got to do is keep up with the power paddling and navigate through the wild currents of the river. While paddling down the river, you’ll be surrounded by deep forests, paddy fields and tiny hamlets. In fact, if you are up for some added adventure you can even pitch a tent and camp on the banks of the river.
                                                </p>
                                                <p><b>Price -</b> Rs 1000/- (Sharing Basis)</p>
                                                <p><b>Timing - </b>10am to 4pm</p>
                                                <p><b>Ideal Day -</b> On the way to Gangtok or return day from Gangtok to Bagdogra airport.</p>
                                                <p><b>Ping - </b>Carry extra pair of cloths</p>
                                                <center> <img src={Topbanner1ImageURL} className="img-fluid" alt="..." /></center>
                                                <br />

                                                <a href="tel://9883359713" className="btn btn-Warning">Call Now</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className='container-fluid'>
                                <div className="container-fluid navtabCustom py-3">
                                    <nav>
                                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                            <button onClick={() => this.handleCustomTabClick('tab1')} className={`tab button ${customActiveTab === 'tab1' ? 'active' : ''}`} role="tab" aria-controls="nav-highlights" aria-selected={customActiveTab === 'tab1'}>Highlights</button>
                                            <button onClick={() => this.handleCustomTabClick('tab2')} className={`tab button ${customActiveTab === 'tab2' ? 'active' : ''}`} role="tab" aria-controls="nav-flights" aria-selected={customActiveTab === 'tab2'}>Flights</button>
                                            <button onClick={() => this.handleCustomTabClick('tab3')} className={`tab button ${customActiveTab === 'tab3' ? 'active' : ''}`} role="tab" aria-controls="nav-hotel" aria-selected={customActiveTab === 'tab3'}>Hotel</button>
                                            <button onClick={() => this.handleCustomTabClick('tab4')} className={`tab button ${customActiveTab === 'tab4' ? 'active' : ''}`} role="tab" aria-controls="nav-sightseeing" aria-selected={customActiveTab === 'tab4'}>Sightseeing</button>
                                            <button onClick={() => this.handleCustomTabClick('tab5')} className={`tab button ${customActiveTab === 'tab5' ? 'active' : ''}`} role="tab" aria-controls="nav-meals" aria-selected={customActiveTab === 'tab5'}>Meals</button>
                                        </div>
                                    </nav>
                                    <div className="tab-content" id="nav-tabContent">
                                        <div className={customActiveTab === 'tab1' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-price-tab">
                                            <p>
                                                Enjoying <b>Sikkim Toursim</b> involves immersing yourself in its natural beauty, exploring its cultural heritage, and engaging in various adventure activities. Here are some tips on how to make the most of your <b>experience in Sikkim:</b>
                                            </p>
                                            <p><b>Explore the Natural Wonders:</b> Spend time exploring Sikkim's breathtaking landscapes, including its towering mountains, cascading waterfalls, serene lakes, and verdant valleys. Visit popular attractions such as Tsomgo Lake, Gurudongmar Lake, Yumthang Valley, and the Teesta River.</p>
                                            <p><b>Discover Cultural Gems:</b> Immerse yourself in Sikkim's rich cultural heritage by visiting ancient monasteries, temples, and traditional villages. Attend cultural festivals and events to witness colorful dances, music performances, and religious rituals. Don't miss exploring places like Rumtek Monastery, Pemayangtse Monastery, and Yuksom.
                                            </p>
                                            <p>
                                                <b>Enjoy Local Cuisine:</b> Indulge in Sikkimese cuisine, which offers a blend of Tibetan, Nepalese, and Sikkimese flavors. Sample local dishes such as momos (dumplings), thukpa (noodle soup), gundruk (fermented vegetable), and sel roti (rice bread). Visit local eateries and roadside stalls to savor authentic Sikkimese delicacies.
                                            </p>
                                            <p><b>Relax and Rejuvenate:</b> Take time to relax and rejuvenate amidst the tranquil surroundings of Sikkim. Unwind at luxury resorts, homestays, or eco-retreats nestled in the lap of nature. Practice yoga and meditation in serene settings to rejuvenate your mind, body, and soul.</p>
                                        </div>
                                        <div className={customActiveTab === 'tab2' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-flights-tab">
                                            <div className="overflow-table">
                                                <h4><b>BAGDOGRA SIKKIM </b>FLIGHT CONNECTION -
                                                    <br /><br />
                                                </h4>
                                                <table className="table table-bordered">
                                                    <tr className="table-danger">
                                                        <th>Airlines</th>
                                                        <th>Flight Number</th>
                                                        <th>City</th>
                                                        <th>Departure Time</th>
                                                        <th>Arrival Time</th>
                                                        <th>Travel Time</th>
                                                    </tr>
                                                    <tr>
                                                        <td>IndiGo</td>
                                                        <td>6E - 5305</td>
                                                        <td>Mumbai</td>
                                                        <td>8:05</td>
                                                        <td>10:50</td>
                                                        <td>2h 55m</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Akasa Air</td>
                                                        <td>QP - 1385</td>
                                                        <td>Mumbai</td>
                                                        <td>11:30</td>
                                                        <td>14:25</td>
                                                        <td>2h 55m</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Akasa Air</td>
                                                        <td>QP - 1850</td>
                                                        <td>Bangalore</td>
                                                        <td>9:10</td>
                                                        <td>12:10</td>
                                                        <td>3h 00m</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Air India</td>
                                                        <td>I5 - 1780</td>
                                                        <td>Bangalore</td>
                                                        <td>5:05</td>
                                                        <td>8:05</td>
                                                        <td>3h 00m</td>
                                                    </tr>
                                                    <tr>
                                                        <td>IndiGo</td>
                                                        <td>6E - 6518</td>
                                                        <td>Chennai</td>
                                                        <td>12:05</td>
                                                        <td>14:40</td>
                                                        <td>2h 35m</td>
                                                    </tr>
                                                    <tr>
                                                        <td>IndiGo</td>
                                                        <td>6E -149</td>
                                                        <td>Hyderabad</td>
                                                        <td>10:25</td>
                                                        <td>12:35</td>
                                                        <td>2h 10m</td>
                                                    </tr>
                                                    <tr>
                                                        <td>IndiGo</td>
                                                        <td>6E - 6872</td>
                                                        <td>Hyderabad</td>
                                                        <td>8:25</td>
                                                        <td>10:35</td>
                                                        <td>2h 10m</td>
                                                    </tr>
                                                </table>
                                                <br /><br />
                                                <h4><b>DELHI SPECIAL </b>FLIGHT CONNECTION -
                                                    <br /><br />
                                                </h4>
                                                <table className="table table-bordered">
                                                    <tr className="table-danger">
                                                        <th>Airlines</th>
                                                        <th>Flight Number</th>
                                                        <th>City</th>
                                                        <th>Departure Time</th>
                                                        <th>Arrival Time</th>
                                                        <th>Travel Time</th>
                                                    </tr>
                                                    <tr>
                                                        <td>IndiGo</td>
                                                        <td>6E - 5317</td>
                                                        <td>Delhi</td>
                                                        <td>7:30</td>
                                                        <td>9:35</td>
                                                        <td>2h 05m</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Air India</td>
                                                        <td>I5 - 552</td>
                                                        <td>Delhi</td>
                                                        <td>9:55</td>
                                                        <td>12:00</td>
                                                        <td>2h 05m</td>
                                                    </tr>
                                                </table>
                                            </div>

                                        </div>
                                        <div className={customActiveTab === 'tab3' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-hotel-tab">
                                            <div className="overflow-table">
                                            <h6 className="content-mobile2" style={{ fontSize: "3.5vw" }} ><center>Your <b>Sikkim Tour Package Price</b> - with 3 <div style={{ display: 'inline-block', color: 'gold' }}>
                                                                    <i className="fa fa-star"></i>
                                                                    <i className="fa fa-star"></i>
                                                                    <i className="fa fa-star"></i>
                                                                </div> Hotel </center>
                                                                </h6>
                                                <div id="no-more-tables">
                                                    <table class="col-md-12 table-bordered table-striped table-condensed cf" style={{ width: "100%", height: "50%" }}>
                                                        <thead class="cf">
                                                            <tr className="text-center">
                                                                {/*  <!-- Add the "text-center" className to center content --> */}
                                                                <th colspan="6">
                                                                    <h6 className="content-desktop" style={{ fontSize: "1.3vw" }} ><center>Your <b>Sikkim Tour Package Price</b> - with 3 <div style={{ display: 'inline-block', color: 'gold' }}>
                                                                    <i className="fa fa-star"></i>
                                                                    <i className="fa fa-star"></i>
                                                                    <i className="fa fa-star"></i>
                                                                </div> Hotel </center>
                                                                </h6>
                                                                
                                                                </th>
                                                            </tr>
                                                            <tr class="table text-center">
                                                                <th class="text-center">No of Pax</th>
                                                                <th class="text-center">5N 6D Package Price (Including GST.)</th>
                                                                <th class="text-center">Transport</th>
                                                                <th class="text-center">WhatsApp Now</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td class="text-center" data-title="No of Pax">
                                                                    06 Adults
                                                                </td>
                                                                <td class="text-center" data-title=" Price(Inc. GST.)">
                                                                    21,676 /- (Per Person)
                                                                </td>
                                                                <td class="text-center" data-title="Transport">Luxury SUV</td>
                                                                <td class="text-center" data-title="WhatsApp Now"><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick}>
                                                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                                                </a></td>
                                                            </tr>
                                                            <tr>
                                                                <td class="text-center" data-title="No of Pax">
                                                                    04 Adults
                                                                </td>
                                                                <td class="text-center" data-title=" Price(Inc. GST.)">
                                                                    24,255/- (Per Person)
                                                                </td>
                                                                <td class="text-center" data-title="Transport">Luxury SEDAN</td>
                                                                <td class="text-center" data-title="WhatsApp Now"><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick}>
                                                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                                                </a></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <br />
                                            </div>
                                            <br />
                                            <h5 className='center font-weight tomato'>ADORABLE</h5><h5 className="center font-weight"> Approved 3-Star Hotels</h5><br />
                                            <div className="row row-cols-1 row-cols-md-4 g-4">

                                                {hotelDataView}

                                            </div>
                                        </div >
                                        <div className={customActiveTab === 'tab4' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-sightseeing-tab">
                                            <p><b>Explore Gangtok -</b> Discover the charm of Gangtok, the capital city of Sikkim, nestled amidst the Himalayas. Visit prominent attractions like Rumtek Monastery, Namgyal Institute of Tibetology, and MG Marg, the bustling heart of the city.</p>
                                            <p><b>Scenic Drives -</b> Enjoy scenic drives through lush valleys and winding mountain roads as you travel between Sikkim and Darjeeling, soaking in breathtaking views of the Himalayas and cascading waterfalls.</p>
                                            <p><b>Visit Tsomgo Lake and Nathula Pass -</b>Experience the serene beauty of Tsomgo Lake, surrounded by snow-capped peaks, and pay homage at Baba Mandir. If permits allow, venture to Nathula Pass, an Indo-China border crossing known for its stunning vistas.</p>
                                            <p><b>Pelling Excursion -</b>Explore the tranquil town of Pelling, offering panoramic views of Mount Kanchenjunga. Visit Pemayangtse Monastery, Rabdentse Ruins, and the sacred Khecheopalri Lake.</p>
                                            <p><b>Darjeeling Delights -</b>Immerse yourself in the colonial charm of Darjeeling, famous for its tea plantations, toy train rides, and panoramic views of the Himalayas. Don't miss the sunrise from Tiger Hill and a visit to the Himalayan Mountaineering Institute and Padmaja Naidu Himalayan Zoological Park.</p>
                                            <p><b>Cultural Immersion -</b> Experience the rich cultural heritage of Sikkim and Darjeeling through visits to monasteries, temples, and local markets, where you can interact with friendly locals and savor authentic cuisine.</p>
                                            <p><b>Comfortable Accommodation -</b> Relax in comfortable accommodations throughout your journey, ensuring a restful stay after each day of exploration.</p>
                                        </div>
                                        <div className={customActiveTab === 'tab5' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-meals-tab">
                                            <div className="overflow-table">
                                                <h5 class="tomato">5 times Meals and 5 times Breakfast has been Provided</h5>
                                                <table className="table table-bordered">
                                                    <tr className="table-danger">
                                                        <th colspan="6" className="text-center">Category</th>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-center">JAIN</td>
                                                        <td class="text-center">PURE-VEG</td>
                                                        <td class="text-center">VEGAN</td>
                                                        <td class="text-center">PURE-NON-VEG</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div
                                        className="card-header custHeaer"
                                    >
                                        <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>Reach by Air with Your Family <b>for Sikkim Holidays</b></h3>
                                        <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>Reach by Air with Your Family <b>for Sikkim Holidays</b></h3>
                                    </div>
                                    <div className="card-body">
                                        <p>Planning for a <b>Sikkim Tour Package from Bagdogra</b> ? but not sure what you should visit or how you should plan? Don’t worry, Adorable Vacation will fully assist you.</p>
                                        <p>Our <b>Sikkim Packages</b> comes in different sizes as the place itself is a confluence of art, culture, natural beauty and adventure. Popularly Sikkim Darjeeling Tour, North Sikkim Package, Gangtok Pelling Tour, Gurudongmar Lake Visit, Gangtok Darjeeling Package.</p>
                                        <p>Bagdogra Airport, widely recognized as the Civil Aerodrome Bagdogra, serves as a bustling hub for both domestic and international travelers. Situated in the picturesque town of Bagdogra, merely 16 km away from Siliguri in Darjeeling, this airport has undergone a significant transformation. Originally serving as an Indian Air Force base, it has now evolved into a fully-fledged airport, catering to a wide range of domestic and international flights.</p>
                                        <center> <img src={TopbannerImageURL} className="img-fluid" alt="..." /></center><br />
                                        <p>
                                            <b>Sikkim-</b> a state that was once attributed as a different country became an inseparable part of India in 1975. It is situated in North East India with its rich history, and culture it attracts thousands of tourists from all across the world. Besides historical significance it offers adventurous activities to the people who love mountains.
                                        </p>
                                        <p>
                                            The state is known for its anti-pollution and anti-tobacco drive campaign. The tiny state nestled in the Himalayas is leading a green revolution in its own way.
                                        </p>
                                        <p>
                                            <b>Adorable Vacation</b>, sikkim travel agency is an ultimate specialist of the best Sikkim tour package with long years of experience. So, a trip with us will send you on an exclusive journey with an affordable sikkim tour cost and with the needed equipment.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <br /><br />
                            <div className="container-fluid navtabCustom py-4">

                                <h5 className="tomato">Day wise Itinerary</h5>
                                <hr />

                                <nav>
                                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                        <button onClick={() => this.handleDayWiseTabClick('day1')} className={`tab button ${daywiseActiveTab === 'day1' ? 'active' : ''}`} role="tab" aria-controls="nav-day-one" aria-selected={daywiseActiveTab === 'day1'}>DAY-1</button>
                                        <button onClick={() => this.handleDayWiseTabClick('day2')} className={`tab button ${daywiseActiveTab === 'day2' ? 'active' : ''}`} role="tab" aria-controls="nav-day-two" aria-selected={daywiseActiveTab === 'day2'}>DAY-2</button>
                                        <button onClick={() => this.handleDayWiseTabClick('day3')} className={`tab button ${daywiseActiveTab === 'day3' ? 'active' : ''}`} role="tab" aria-controls="nav-day-three" aria-selected={daywiseActiveTab === 'day3'}>DAY-3</button>
                                        <button onClick={() => this.handleDayWiseTabClick('day4')} className={`tab button ${daywiseActiveTab === 'day4' ? 'active' : ''}`} role="tab" aria-controls="nav-day-four" aria-selected={daywiseActiveTab === 'day4'}>DAY-4</button>
                                        <button onClick={() => this.handleDayWiseTabClick('day5')} className={`tab button ${daywiseActiveTab === 'day5' ? 'active' : ''}`} role="tab" aria-controls="nav-day-five" aria-selected={daywiseActiveTab === 'day5'}>DAY-5</button>
                                        <button onClick={() => this.handleDayWiseTabClick('day6')} className={`tab button ${daywiseActiveTab === 'day6' ? 'active' : ''}`} role="tab" aria-controls="nav-day-six" aria-selected={daywiseActiveTab === 'day6'}>DAY-6</button>
                                    </div>
                                </nav>
                                <div className="tab-content" id="nav-tabContent">
                                    <div className={daywiseActiveTab === 'day1' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-one-tab">
                                        <img src={navDay1ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 class="tomato"> <b>DAY 1: ARRIVAL AT IXB / NJP / SILIGURI AND TRANSFER TO GANGTOK [125 KMS/5 HR]</b></h5>
                                        <p><b>Welcome to the Cleanest State in India</b> - Sikkim, and transfer to the capital city. As the aircraft lands at the nearest airport, our representative waits outside to meet & greet on arrival at Bagdogra and drives towards Gangtok (Alt.5550Ft/1675M). The drive is about 4 hours following the most scenic way through the beautiful lanes of Sikkim along with the picturesque Teesta River.<br />
                                            On arrival, check into the beautiful view rooms of the hotel well equipped with modern amenities and sink into the soft bed to get rid of the exhaustion. In the evening, take a stroll in the neighborhood or enjoy some hot coffee sitting at the balcony while admiring the beauty of the place.<br />
                                        </p>
                                        <b>Overnight at Gangtok.</b>
                                    </div>
                                    <div className={daywiseActiveTab === 'day2' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-two-tab">
                                        <img src={navDay2ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 class="tomato"><b>Day 2 - DAY EXCURSION TO CHANGU LAKE, BABA MANDIR AND NATHULA PASS</b></h5>
                                        <p>
                                            <b>As the morning sores in, </b> the warm rays of the sun knock through the window to give a start-up call for the second day. Enjoy breakfast at the hotel in its peaceful surroundings, and load the bags with dry foods before leaving for a full day trip to Tsomgo Lake with Baba Mandir / Nathula Pass Excursion.<br /><br />
                                            <b>Tsomgo Lake (12400ft)- </b>Also known as Changu Lake is an oval shaped glacial lake which is only 40 km from Gangtok City, and almost about 1Km in length with 15 meters depth. The name Tsomgo is made of two words in Bhutia Language 'Tso' means 'lake' and 'Mgo' means 'head' in a short 'source of the lake'. This high-altitude lake is surrounded by snow-capped steep mountains and remains frozen during wintertime. As summer beings to step-in, the glacier melts and the lake gets its origin. The locals consider the lake to be sacred, and it reflects different colors with the change in seasons.<br /><br />
                                            <b>New Baba Harbhajan Singh Mandir - </b>The Baba Mandir was built in the Memorial of Baba Harbhajan Singh or the 'Hero of Nathula', a soldier in the Indian Army. It is situated at a distance of 12Km from Tsomgolake, and 52Km from Gangtok city. Baba Harbhajan served as a part of the border patrol force, and in the year 1968 while escorting a mule column he fell on a fast-flowing stream and got carried away by the powerful water current. Soldiers found his remains after 3 days and cremated with full military honour along with a shrine on his Samadhi built as per Baba’s wish, which he expressed through his friend post-death. The original bunker of Baba Harbhajan Singh is located further up near the Gnathang valley which is the Old Baba Mandir but because of its difficult terrain tourist can’t reach there so they build a New Mandir for easy access. This shrine is believed to have wish-fulfilling powers along with that Baba is still guarding the international border between India and China. On Sundays, a 'Langar' runs at the temple, which provides free meals to the visitors.<br /><br />
                                            <b>Nathula Pass - </b>A major mountain pass in the Himalayas in East Sikkim district, which is a corridor of passage between India and Tibet before it was closed in 1962 but reopened in 2006. Located around 56 km from Gangtok at an altitude of 14450 ft, the road to Nathula is the highest motorable road, passes through the Tsomgo lake, and requires an entry permit to visit. The view from this international border is breath-taking and allows the tourist to get a glimpse of China on the other side along with their Chinese militants. On a clear day, the road winding down the Chumbi valley can be seen.<br /><br />
                                            <b>(Nathula Pass is closed on every Tuesday and Permit depends on Weather conditions) and ALWAYS ON EXTRA PAYMENT BASIS</b><br />
                                        </p>
                                        <b>Overnight at Gangtok.</b>
                                    </div>
                                    <div className={daywiseActiveTab === 'day3' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-three-tab">
                                        <img src={navDay3ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 class="tomato"> <b>Day 3 - GANGTOK TO LACHEN (130 KMS/ 6 HRS)</b></h5>
                                        <p>After the blackness of night, Earth's star rises on the horizon, spreading her gold in every direction, to wake-up the dreamy city of Gangtok, to kick start the day. Get the tummy refilled with heavy breakfast before leaving for a 6 hours long journey towards Lachen 130 Km. Post breakfast, around 10 a.m. leave for Lachen via NH 31A. En-route visit Sightseeing:<br /><br />
                                            <p><b>Kabi Lungstok -</b> It's a historic place, located on North Sikkim Highway. This is the where a friendship treaty between Lepcha Chief Te-Kung-Tek and the Bhutia Chief Khey-Bum-Sar was signed. A small stone pillar was built to mark the location where the treaty was signed.</p>
                                            <p><b>Seven Sister Waterfalls -</b> The name is given to this waterfall as it makes its descent in seven stages, before gushing down through the dense forest and hitting the rock. This magnificent fall on the way to Lachen is sure to allure the tourist with its beauty in the background of lush-green Mountain. To facilitate the tourists to savor the pristine beauty and to be with nature, the Tourism Department has set up a waiting shed and cafeteria.</p >
                                            <p><b>Naga Waterfalls -</b> Another picturesque waterfall in North Sikkim near the confluence of Lachen-Chu and Lachung-Chu at Chungthang. This is the most scenic waterfall with the longest route and ice-cold water surrounded by forest in a peaceful domain. Lunch will be served at the Hotel after reaching Lachen in the evening.</p>
                                            <p><b>Lachen -</b> The name Lachen means "Big Pass" nestled at an altitude of 8838 ft in North Sikkim, this peaceful place is secluded far from the noisy city between Chopta Valley and Gurudongmar Lake. The small town is beautifully decorated with little hamlets in the background of the Lower Himalayas with their snowy cliffs and tall pine trees.</p>
                                            <p>After check-in to the hotel, head straight towards the cozy room equipped with basic amenities and facing the beautiful green mountain. This remote place doesn't have much to offer the tourist except for its natural beauty, so one can spend the evening tasting some local Sikkim flavours while admiring nature.</p>
                                        </p >
                                        <p><b>Overnight at Lachen.</b></p>
                                    </div>
                                    <div className={daywiseActiveTab === 'day4' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-four-tab">
                                        <img src={navDay4ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 class="tomato"> <b>Day 4 – GURUDONGMAR LAKE EXCURSION AND TRANSFER TO LACHUNG</b></h5>
                                        <p>After shedding off the exhaustion with a good night's sleep in a peaceful environment, day 04 calls to get up a bit early to get moving towards the sightseeing of the day after feasting on fresh and hot breakfast while appreciating the beautiful morning. Post breakfast visits Gurudongmar Lake (5450 M/70 Km/03 hours).<br /><br />
                                            <p><b>Gurudongmar Lake -</b> Located at an altitude of 17800 ft, this high-altitude natural lake is considered the most sacred lake as believed by Buddhists and Hindus alike. This high altitude beautiful and awe-inspiring lake remains milky in color throughout the year. This beautiful lake used to remain frozen most of the year, hence it was not possible to use its water for drinking purposes. When the Guru Padmasambhava passed by, while returning from Tibet, the residents are reported to have approached him for providing a source of water. The Guru obliged, and a portion of the lake touched by him does not freeze in extreme winter. Since the water of the lake is considered sacred, visitors carry the 'blessed' water in bottles.</p>
                                            <p>After lunch, check out from the hotel and transfer to Lachung. </p>
                                            <p><b>Lachung -</b> The name Lachung stands for a Small Pass is a mesmerizing hilly area near the border of Tibet in North Sikkim at an elevation of 9600 ft. This small dreamy destination is embraced with snowy mountains, tall coniferous trees, the sparkling stream which is a perfect treat for a vacationer, nature, and photography lover.</p>
                                            <p>On arrival, Check-in to the hotel in the middle of pristine nature with rooms having breathtaking views of the rugged mountains apart from basic amenities. The evening is free to enjoy the beauty of the place, or binge on Hot Thukpas, Momo, or local sprees while praising the mountains as the calm moonbeam hits the surface.</p>
                                        </p>
                                        <p><b>Overnight at Lachung.</b></p>
                                    </div>
                                    <div className={daywiseActiveTab === 'day5' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-five-tab">
                                        <img src={navDay5ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 class="tomato"> <b>Day 5 –  YUMTHANG VALLEY EXCURSION AND TRANSFER TO GANGTOK</b></h5>
                                        <p>For others, the arrival of a new day brings new hope and for explorers, a new day brings a new destination. As the golden hues of the sun illuminate the lazy town of Sikkim, with the birds joining for the get-up call fills the morning with their sweet melody to remind everyone that morning has arrived. Enjoy the fresh morning from the balcony, with the jaw-dropping view of the mountains while sipping some tea. Post breakfast, leave for Yumthang Valley Excursion to Gangtok.</p>
                                        <p><b>Yumthang Valley -</b> Also known as the Valley of Flowers when the 24 species of Rhododendron blooms during May and the Valley full of Snow during winter. Shingba Rhododendron Sanctuary Spread over 43 sq. km through which flows the Yumthang River, it adjoins Lachungand Yumthang. The Sanctuary which is known for its abundance of Rhododendron shrubs and trees is rich in ground flora and other vegetation as well. The road leading to the valley follows a scenic way with cascading waterfalls, and streams. This exotic location with hot springs, lazy yaks, and lush green meadows remains cold throughout the year due to the snow-capped mountains surrounding it.</p>
                                        <p><b>Zero Point -</b> The formal name of this Zero point is Yumesamdong, Zero points Sikkim stands at an altitude of 15,300 feet (23 kms from Yumthang Valley) is the last outpost of civilization where three rivers meet amid a panoramic view of the snow-clad mountain and picturesque surrounding. Tourists with asthma are advised to avoid this place due to high altitude sickness because of the low oxygen level.</p>
                                        <b>VISITING ZERO POINT AT AN ADDITIONAL COST IS SUBJECT TO PERMIT AND ROAD CONDITIONS.</b>
                                        <p><b>Overnight in Gangtok.</b></p>
                                    </div>
                                    <div className={daywiseActiveTab === 'day6' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-six-tab">
                                        <img src={navDay6ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 class="tomato"> <b>Day 6 –  GANGTOK TO NJP/IXB TRANSFER</b></h5>
                                        <p><b>After Breakfast Proceed for NJP from Gangtok with sweet memories.</b></p>
                                    </div>
                                </div>
                            </div>

                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>Our Sikkim <span>Tour Packages</span></h2>
                                            <h4>Trending Sikkim Trip from India</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-cols-1 row-cols-md-4 g-4">

                                    {hotPackagesView}

                                </div>
                            </div>

                            {/* <!--insert table inclusion exclusion--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        {
                                            <InclusionExclusion />
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* <!--end table inclusion exclusion--> */}
                            <div className="container-fluid py-5">
                                <div className="card">
                                    <div className="card-header custHeaer">
                                        <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>Discover the <b>Best Time to Visit Sikkim</b> with Adorable Vacation LLP</h3>
                                        <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>Discover the <b>Best Time to Visit Sikkim</b> with Adorable Vacation LLP</h3>
                                    </div>
                                    <div className="card-body">
                                        <span className="icon-img-color">
                                            <i className="fa fa-sun-o" aria-hidden="true"></i>&nbsp;
                                            <span className="black"><strong>Summer Season in Sikkim</strong></span>
                                        </span>
                                        <br />
                                        <span>The summer months are June, July, and August. It is the best time to visit Sikkim as these months
                                            are
                                            the warmest and sunniest, excellent for all the outdoor activities and sightseeing.</span><br />
                                        <br />
                                        <span className="icon-img-color">
                                            <i className="fa fa-umbrella" aria-hidden="true"></i>&nbsp;
                                            <span className="black"><strong>Monsoon Season in Sikkim</strong></span>
                                        </span>
                                        <br />
                                        <span>Though, during the months of June to August, there might be mild showers in the afternoon; but the
                                            regions of Sikkim do not experience the monsoon season as such, for the rain fall very
                                            rarely.</span><br />
                                        <br />
                                        <span className="icon-img-color">
                                            <i className="fa fa-snowflake-o" aria-hidden="true"></i>&nbsp;
                                            <span className="black"><strong>Monsoon Season in Sikkim</strong></span>
                                        </span>
                                        <br />
                                        <span>Though, during the months of June to August, there might be mild showers in the afternoon; but the
                                            regions of Sikkim do not experience the monsoon season as such, for the rain fall very
                                            rarely.</span><br />
                                        <br />
                                    </div>
                                </div>
                            </div >

                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>Cilantro Unwind Delisso Abode <span>- Gangtok</span></h2>
                                            <h4><i>4 Star Cottage Hotel</i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row py-3">
                                    <img src={brandedTourackageImage_1_URL} alt="..." />
                                </div>

                                <div id="no-more-tables">
                                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                        <thead>
                                            <tr className="table-danger">
                                                <th>Category</th>
                                                <th>Size(Room)</th>
                                                <th>Hotel Brand</th>
                                                <th>Price (Per Night)</th>
                                                <th>Book Now</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td data-title="Packages">Luxury Accommodation</td>
                                                <td data-title="Size(Room)">290+ SQ.FT</td>
                                                <td data-title="Hotel Brand">Cilantro<div className="reviews">
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                </div></td>
                                                <td data-title="Price (Per Night)">Rs 7,080/- </td>
                                                <td data-title="Book Now"><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick}>
                                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                                </a></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                <div className="card mx-0 my-5">
                                    <div className="card-header custHeaer">
                                        <h3 className='content-desktop' style={{ fontSize: "1.5vw" }}><b>UNLOCK THE SERENITY OF SIKKIM</b></h3>
                                        <h3 className='content-mobile2' style={{ fontSize: "3.5vw" }}><b>UNLOCK THE SERENITY OF SIKKIM</b></h3>
                                    </div>
                                    <div className="card-body">
                                        <p><b>Cilantro</b> Unwind is a combination of luxury and magnificent hospitality. We are always looking forward to providing you with all the care, love, and adhering support. At Cilantro, we cater to your needs to make you feel comfortable and more at home. Our restaurant and bar is the best place to replenish your hunger and mood!</p>
                                        <p>We are also located very close to MG Marg. Just a 2 minutes walk away!</p>
                                        <p>
                                            <b>Distance From
                                                Railway:</b> New Jalpaiguri Railway Station (148 Km)<br />
                                            <b>Airport:</b> Bagdogra Airport (124 Km)<br />
                                            <b>Bus Stand:</b> SNT Bus Station (1 Km)<br />
                                            <b>Airport:</b> Pakyong Airport (27 Km)<br /></p>

                                        <a href="tel://9147061467" className="btn btn-Warning">Book Now</a>

                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>Ramada <span>- Gangtok</span></h2>
                                            <h4><i>Stay with Trust</i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row py-3">
                                    <img src={brandedTourackageImage_2_URL} alt="..." />
                                </div>

                                <div id="no-more-tables">
                                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                        <thead>
                                            <tr className="table-danger">
                                                <th>Category</th>
                                                <th>Size(Room)</th>
                                                <th>Hotel Brand</th>
                                                <th>Price (Per Night)</th>
                                                <th>Book Now</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td data-title="Packages">Platinum Accommodation</td>
                                                <td data-title="Size(Room)">320+ Sq Ft</td>
                                                <td data-title="Hotel Brand">Wyndham <div className="reviews">
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                </div></td>
                                                <td data-title="Price (Per Night)">Rs 13,570/- </td>
                                                <td data-title="Book Now"><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick}>
                                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                                </a></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                <div className="card mx-0 my-5">
                                    <div className="card-header custHeaer">
                                        <h3 className='content-desktop' style={{ fontSize: "1.5vw" }}><b>ENJOY 5 STAR CASINO HOTEL IN THE LAP OF HIMALAYA</b></h3>
                                        <h3 className='content-mobile2' style={{ fontSize: "3.5vw" }}><b>ENJOY 5 STAR CASINO HOTEL IN THE LAP OF HIMALAYA</b></h3>
                                    </div>
                                    <div className="card-body">
                                    <p><b>Ramada</b> By Wyndham Gangtok Hotel & Casino Golden now becomes the first Ramada hotel in Sikkim. At a mere distance of just 10 minutes from the main mall area at M.G. Marg and 45 minutes from the closest operational airport Greenfield Pakyong, this is the perfect place to enjoy 5-star luxury services along with serene views of the mighty Kanchenjunga range. Additionally, we also have a live Casino that boasts of the biggest live gaming area in the entire country.</p>

                                        <a href="tel://9147061467" className="btn btn-Warning">Book Now</a>

                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h3>FREQUENTLY ASKED QUESTIONS [FAQ] FOR <b><span class="tomato">SIKKIM PACKAGE TOUR</span></b></h3>
                                            <h4>SUMMER HOLIDAYS FROM NJP / BAGDOGRA</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div
                                        className="accordion accordion-flush faQStyle"
                                        id="accordionFlushExample"
                                    >
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingOne">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseOne"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseOne"
                                                >
                                                    1. Why Sikkim Is The Best Place For Spending Your Summer Vacation ?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseOne"
                                                className="accordion-collapse"
                                                aria-labelledby="flush-headingOne"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Sikkim is a super famous place for family summer vacation and also for snow-covered landscapes. Mount Kanchenjungha makes a look like virgin bride itself. So, it is a heaven to look at during the summer too. You can indulge in different kind of experiences like trekking, kayaking, camping, and with the help of <b>Sikkim Tourism</b> you can enjoy the Paragliding – A panoramic view in bird eyes. Till last ten years ninety percent of honeymoon couples in all over the India are choosing <b>North Sikkim Tour Package</b> for honeymoon trip. Based on feedback of different tourists that Sikkim never fails to impress a traveler with its inborn natural beauty.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingTwo">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseTwo"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseTwo"
                                                >
                                                    2. Can You Please Suggest Some Best Restaurants In Gangtok City Or Near Mg Marg ?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseTwo"
                                                className="accordion-collapse collapseShow"
                                                aria-labelledby="flush-headingTwo"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    <p>Gangtok is specially famous for traditional Nepalese flavoured food. Apart from this here are some branded & high rated family bar cum restaurents are available like</p>
                                                    <ul>
                                                        <li>• Shuffle Momos</li>
                                                        <li>• Attic Lounge</li>
                                                        <li>• Downtown</li>
                                                        <li>• Cafe live & loud</li>
                                                        <li>• Mapple Leisure</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingThree">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseThree"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseThree"
                                                >
                                                    3. Can Foreigners Visit All Parts Of Sikkim ?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseThree"
                                                className="accordion-collapse collapseShow"
                                                aria-labelledby="flush-headingThree"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Although <b>Sikkim Silk Route & North Sikkim</b> is so near to Indo-Chaina (Nathu-la) border. However Foreign national & Overseas citizenship are not allowed to travel in Permit or Restricted area like <b>Zuluk</b>, Gnathang , Nathula Pass. These kind of tourists can travel no permit zone like <b>Sillery Gaon</b>, Reshikhola, Gangtok, Pelling & Ravangla.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingFour">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseFour"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseFour"
                                                >
                                                    4. In Sikkim, Which Part Is The Best Option For Short Trip During Summer Holiday ?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseFour"
                                                className="accordion-collapse collapseShow"
                                                aria-labelledby="flush-headingFour"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    <p>The beauty of <b>North Sikkim Tour</b> is incomparable.</p> Mysterious <b>Gurudongmar Lake</b> will give you a different kind of flavor on your tour plan. The sharpness of Kala Patthar & flourish welcome in Yumthang Valley will prove that North Sikkim is the best option for short summer holiday vacation within 4 days only.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingFive">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseFive"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseFive"
                                                >
                                                    5. How Many Days Are Enough To Explore Sikkim Tour Completely ?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseFive"
                                                className="accordion-collapse collapseShow"
                                                aria-labelledby="flush-headingFive"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    It is essential to keep in mind that when you are planning for <b>Sikkim Tour Package</b>, do not except to visit all the places in short trip like 5 Nights 6 Days. As North Sikkim & East Sikkim these parts of Sikkim is very tough and belonging in remote or countryside portion. If you continuously travel without resting, you will get sick. please make an extended tour program of minimum 11 Nights 12 Days of <b>complete Sikkim Tour Plan</b>.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingSix">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseSix"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseFive"
                                                >
                                                    6. How Much Far Gangtok ?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseSix"
                                                className="accordion-collapse collapseShow"
                                                aria-labelledby="flush-headingSix"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    <p><b>From NJP / Bagdogra</b> – 130 km about 4 hours</p>

                                                    <p><b>From Kalimpong</b> – 78 km, about 2 hours 30 minutes</p>

                                                    <p><b>From Pelling</b> – 134 km, about 4 hours</p>

                                                    <p><b>From Darjeeling</b> – 102 km, about 3 hours 25 minutes</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingSeven">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseSeven"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseSeven"
                                                >
                                                    7.	Can We Expect Modern Or Luxury Facilities During Sikkim Darjeeling Trip ?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseSeven"
                                                className="accordion-collapse collapseShow"
                                                aria-labelledby="flush-headingSeven"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    <p>You can expect modern facilities out there as Gangtok-Pelling-Ravangla are economically sustainable city and people out there are providing luxury accommodation for last few years only. Rooms are super standard type, running water, temperature control swimming pool,bathtub in bath room but no TV and Geyser. You can enjoy the strong connection of Wi-Fi also.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingEight">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseEight"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseEight"
                                                >
                                                    8. Any High Altitude Travel Solution From Expertise ?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseEight"
                                                className="accordion-collapse collapseShow"
                                                aria-labelledby="flush-headingEight"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    <p>During the travel to Old Silk Route If you have kids(below 3 years) may be someone having breathing issue we would recommend you not to stay at Gnathang Vally but stay in Zuluk and on the way to Kupup visit Gnathang and move. Alternatively you carry a portable Oxygen cylinder and Oxygen meter as well. In case any serious emergency you will get Indian Army help at most 1.5 Hrs driving interval and they are really supportive.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container-fluid py-5">

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h3><b>Sikkim Tour</b> Reviews</h3>
                                            <h4><i>"Your Experience is our Marketing"</i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-cols-1">
                                    <div id="testimonials">
                                        <div className="testimonial-box-container">

                                            {reviewersDataView}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div >
                        <div className="col-md-3">
                            <BookingBox />
                        </div>

                    </div >
                </div >

            </>
        );
    }
}

export default Sikkim_5N_6D_Package;