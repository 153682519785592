import React, { Component } from 'react';

class InclusionExclusion extends Component {
    render() {
        return (
            <>
                <table className="table-borderDodont">
                    <thead>
                        <tr>
                            <td className="tomato"><strong>SIKKIM TOUR PACKAGE INCLUSION</strong></td>
                            <td className="tomato"><strong>SIKKIM PACKAGE TOUR EXCLUSION</strong></td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span> Transporation includings pickup & drop.</td>
                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>  Air Fare/Train Fare.</td>
                        </tr>
                        <tr>
                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>  Accommodation on twin sharing basis.</td>
                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span> Personal Expenses such as tips & gratuity, mineral water, adventure activities and porterage.</td>
                        </tr>
                        <tr>
                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span> Sightseeings as per the itinerary.</td>
                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span> Entrance fees.</td>
                        </tr>
                        <tr>
                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>  Breakfast & Dinner meal (Veg, Non Veg & Jain).</td>
                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span> Anything not mentioned in inclusions.</td>
                        </tr>
                        <tr>
                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span> Exclusive private Non/Ac vehicle for transfers.</td>
                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span> Additional sightseeing, other than mentioned in the itinerary.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span> All permit fees, hotel taxes & GST.</td>
                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>  Any cost arising due to natural calamities.
                            </td>
                        </tr>
                    </tbody>
                </table>
            </>
        );
    }
}

export default InclusionExclusion;