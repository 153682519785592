import React, { Component } from 'react';
import BookingBox from '../BookingBox';
import Slider from "react-slick";
import ApiURL from "../../../api/ApiURL";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InclusionExclusion from '../Meghalaya/InclusionExclusion';

class Meghalaya_6N_7D_Package extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customActiveTab: 'tab1',
            daywiseActiveTab: 'day1'
        }
    }

    handleCustomTabClick = (tab) => {
        this.setState({
            customActiveTab: tab,
        });
    };

    handleDayWiseTabClick = (tab) => {
        this.setState({
            daywiseActiveTab: tab,
        });
    };

    render() {
        const { customActiveTab, daywiseActiveTab } = this.state;
        const packageTopBannerImageURL = ApiURL.Megalaya_6N_7D_PackageImageURL + "/Meghalaya-Kaziranga-Package-Tour-6N-7D.webp";
        const navDay1ImageURL = ApiURL.Megalaya_6N_7D_PackageImageURL + "/day1.webp";
        const navDay2ImageURL = ApiURL.Megalaya_6N_7D_PackageImageURL + "/day2.webp";
        const navDay3ImageURL = ApiURL.Megalaya_6N_7D_PackageImageURL + "/day3.webp";
        const navDay4ImageURL = ApiURL.Megalaya_6N_7D_PackageImageURL + "/day4.webp";
        const navDay5ImageURL = ApiURL.Megalaya_6N_7D_PackageImageURL + "/day5.webp";
        const navDay6ImageURL = ApiURL.Megalaya_6N_7D_PackageImageURL + "/day6.webp";
        const navDay7ImageURL = ApiURL.Megalaya_6N_7D_PackageImageURL + "/day7.webp";
        const TopbannerImageURL = ApiURL.Megalaya_6N_7D_PackageImageURL + "/Assam-Meghalaya-Tour-Plan-from-Guwahati-Airport.webp";

        const TrendingPackagesDataList = this.props.TrendingPackagesData;
        const hotPackagesView = TrendingPackagesDataList.length > 0 ?
            (
                TrendingPackagesDataList.map((dataItem, i) => {
                    const hotelImgURL = ApiURL.Megalaya_6N_7D_PackageImageURL + "/" + dataItem.ImageName;
                    return (<div className="col-md-12 p-0" key={i}>
                        <div className="card h-100">
                            <img src={hotelImgURL} className="card-img-top" alt={dataItem.AllternateText} />
                            <div className="card-body">
                                <h6 className="card-title">{dataItem.Title} <span> &#8377; {dataItem.PricePerNight}</span></h6>
                                <p className="card-text">
                                    <i className="fa fa-building tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-utensils tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-car-side tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-male tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-camera tomato"></i>
                                </p>
                                <p className="card-text"><span>{dataItem.Description}</span></p>
                                <p>
                                    <span><b className="tomato">{dataItem.NoOfTours} </b>Tours</span> | &nbsp;
                                    <span><b className="tomato">{dataItem.NoOfDeparture} </b>Departures</span> | &nbsp;
                                    <span><b className="sdf-blue"> {dataItem.Include}</b></span>
                                </p>
                            </div>
                            <div className="card-footer">
                                <small className="text-muted"><strong className="tomato">{dataItem.MutedText}</strong></small>
                                <button type="button" className="btn btn-Warning btn-sm float-end">Book Now</button>
                            </div>
                        </div>
                    </div>)
                })
            ) : (<div></div>)


        const settings = {
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const reviewersDataList = this.props.ReviewersData;
        const reviewersDataView = reviewersDataList.map((dataItem, i) => {
            const reviewerImageURL = ApiURL.Megalaya_6N_7D_PackageImageURL + "/" + dataItem.ImageName;
            return (
                <div className="testimonial-box" key={i}>
                    <div className="box-top">
                        <div className="profile">
                            <div className="profile-img">
                                <img src={reviewerImageURL} alt="..." />
                            </div>
                            <div className="name-user">
                                <strong>{dataItem.Name}</strong>
                                <span>{dataItem.Location}</span>
                            </div>
                        </div>
                        <div className="reviews">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </div>
                    </div>
                    <div className="client-comment">
                        <p>{dataItem.Comment}</p>
                    </div>
                </div>
            )
        })

        return (
            <>
                <div className="container-fluid">
                    <div className="row">
                        <img src={packageTopBannerImageURL} className="img-fluid px-0" alt="..." />
                    </div>
                </div>
                <div className="container-fluid py-3">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="container-fluid py-1">

                                        <h1 className="content-desktop" style={{ fontSize: "2vw" }}>
                                            Looking for <b>MEGHALAYA 6N 7D TOUR ITINERARY</b> from Guwahati Airport?</h1>

                                        <h1 className="content-mobile" style={{ fontSize: "4vw" }}><center>Looking for <b>MEGHALAYA 6N 7D TOUR ITINERARY</b> from Guwahati Airport?</center>
                                            <hr />
                                        </h1>

                                        <div className="col-12">
                                            <big className="text-muted"><strong className="tomato">6N/7D</strong></big>&nbsp;
                                            <i className="fa fa-plane-departure"></i>&nbsp;&nbsp;
                                            <i className="fa fa-arrow-right"></i>&nbsp;Kaziranga(2N)&nbsp;
                                            <i className="fa fa-arrow-right"></i>&nbsp;Shillong(3N)&nbsp;
                                            <i className="fa fa-arrow-right"></i>&nbsp;Cherapunjee(1N)&nbsp;
                                            <i className="fa fa-arrow-right"></i>&nbsp;&nbsp;
                                            <i className="fa fa-plane-departure fa-flip-horizontal"></i>
                                            <br />
                                        </div>
                                        <hr />
                                    </div>
                                    <h2 className="content-desktop p-4" style={{ fontSize: "1.5vw" }}> For Booking <b>MEGHALAYA ASSAM TOUR</b> <a href='tel:9163676037' class="tomato">☎ CALL +91- 96747-38480</a></h2>
                                    <h2 className="content-mobile p-4" style={{ fontSize: "2.5vw" }}> For Booking <b>MEGHALAYA ASSAM TOUR</b> <a href='tel:9163676037' class="tomato">☎ CALL +91- 96747-38480</a></h2>
                                    <div className="card">
                                        <div className="card-header custHeaer">
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>India's #1 Recommend <b>Shillong Cherrapunjee Trip</b></h3>
                                            <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>India's #1 Recommend <b>Shillong Cherrapunjee Trip</b></h3>
                                        </div>
                                        <div className="card-body">
                                            <p>Embark on an exquisite journey filled with natural beauty to <b>Assam and Meghalaya</b> with Adorable Vacation LLP. These two beautiful North eastern states are travellers’ haven with numerous paradisiacal attractions in its fold. These travel destinations in North East India are known for their serenity, tranquillity and incomparable natural beauty. So come along on a mesmerising  journey through the flourishing green hills of North East for the <b>Best and Cheap tour package of  Assam & Meghalaya</b> of India's north-eastern region with our meticulously crafted <b>North East tour packages</b>.</p>
                                            <p>Northeast India is the land of hidden natural and cultural treasure where one can spot exotic and amazing natural treasures. Our best designed Assam and Meghalaya Tour Packages from Guwahati are one the best combination for a nature lover to witness the hidden treasure.</p>

                                            <p><b>Adorable Vacation LLP</b> offers the widest range of <b>beautifully customizable Assam and Meghalaya Tour Packages From Guwahati</b>. Two of the greenest states from the <b>'Land of the 7 Sister States’</b> is an extremely underrated hidden paradise on earth that has seldom been explored to its fullest. But with <b>Adorable Vacation LLP’s North East holiday packages</b> you can enjoy an exotic vacation. Assam is endowed with lush inexperienced forests, 5 National Parks, eighteen wild life sanctuaries with wider style of flora and fauna to suit every kind of traveller from Delhi, Kolkata,Mumbai, Chennai or Bangalore.</p>

                                            <a href="tel://9674738480" className="btn btn-Warning">Call Now</a>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="card">
                                        <div className="card-header custHeaer">
                                            <h3 className='content-desktop' style={{ fontSize: "1.5vw" }}>How to Reach Shillong-Cherrapunjee | North-East Khasi Hills<b></b></h3>
                                            <h3 className='content-mobile2' style={{ fontSize: "3.5vw" }}>How to Reach Shillong-Cherrapunjee | North-East Khasi Hills<b></b></h3>

                                        </div>

                                        <div className="card-body">
                                            <p>Reaching for <b>Meghalaya Tour Packages</b> typically involves traveling to its capital city, Shillong, which is well-connected to major cities in India by air, road, and rail. Here are the primary modes of transportation:</p>
                                            <center> <img src={TopbannerImageURL} className="img-fluid" alt="..." /></center><br />
                                            <p><b className='tomato content-desktop' style={{ fontSize: "1.5vw" }}>By Air: </b><b className='tomato content-mobile2' style={{ fontSize: "3.5vw" }}>By Air:</b>The nearest airport to Shillong is the Shillong Airport, also known as Umroi Airport (located about 30 kilometers away). However, this airport has limited connectivity and mostly operates flights to and from Kolkata. Alternatively, travelers can fly to <b>Lokpriya Gopinath Bordoloi International Airport in Guwahati (GAU) </b>, which is about 125 kilometers away from Shillong. From Guwahati, you can hire a taxi or take a bus to reach Shillong, which usually takes around 3-4 hours depending on traffic and road conditions.</p>
                                            <p><b className='tomato content-desktop' style={{ fontSize: "1.5vw" }}>By Train: </b>
                                                <b className='tomato content-mobile2' style={{ fontSize: "3.5vw" }}>By Train: </b>The nearest major railway station to Shillong is in Guwahati, which is well-connected to cities across India. From <b>Guwahati Railway Station</b>, travelers can take a taxi or bus to reach Shillong.</p>
                                            <p><b className='tomato content-desktop' style={{ fontSize: "1.5vw" }}>By Road: </b>
                                                <b className='tomato content-mobile2' style={{ fontSize: "3.5vw" }}>By Road: </b>Meghalaya is well-connected to neighboring states for develop <b>Meghalaya Tourism</b> like Assam and Tripura via a network of highways. The most common route is from Guwahati, Assam, which is connected to Shillong by National Highway 6. Regular bus services and shared taxis are available from Guwahati to Shillong, with a travel time of around 3-4 hours. Private taxis can also be hired for a more comfortable journey.</p>
                                            <a href="tel://9674738480" className="btn btn-Warning">Call Now</a>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="container-fluid navtabCustom">
                                        <nav>
                                            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                                <a onClick={() => this.handleCustomTabClick('tab1')} className={customActiveTab === 'tab1' ? 'nav-item nav-link active' : 'nav-item nav-link'} role="tab" aria-controls="nav-highlights" aria-selected="true">Highlights</a>
                                                <a onClick={() => this.handleCustomTabClick('tab2')} className={customActiveTab === 'tab2' ? 'nav-item nav-link active' : 'nav-item nav-link'} role="tab" aria-controls="nav-flights" aria-selected="false">Flights</a>
                                                <a onClick={() => this.handleCustomTabClick('tab3')} className={customActiveTab === 'tab3' ? 'nav-item nav-link active' : 'nav-item nav-link'} role="tab" aria-controls="nav-hotel" aria-selected="false">Hotel</a>
                                                <a onClick={() => this.handleCustomTabClick('tab4')} className={customActiveTab === 'tab4' ? 'nav-item nav-link active' : 'nav-item nav-link'} role="tab" aria-controls="nav-sightseeing" aria-selected="false">Sightseeings</a>
                                                <a onClick={() => this.handleCustomTabClick('tab5')} className={customActiveTab === 'tab5' ? 'nav-item nav-link active' : 'nav-item nav-link'} role="tab" aria-controls="nav-meals" aria-selected="false">Meals</a>
                                            </div>
                                        </nav>
                                        <div className="tab-content" id="nav-tabContent">
                                            <div className={customActiveTab === 'tab1' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-highlights-tab">
                                                Engage on a journey through the flourishing green hills of Assam & Meghalaya and experience the North
                                                Eastern states natural and cultural words. You can avail a boat ride in the sparkling waters of Umiam
                                                Lake, trek through the dense forests to reach the double decker living root bridge, and spot the one
                                                horned rhinos in Kaziranga National Park. With our Experts and comfortable accommodation your trip
                                                will be surely be lifetime memorable trip.
                                            </div>
                                            <div className={customActiveTab === 'tab2' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-flights-tab">
                                                <div className="overflow-table">
                                                    <span>Guwahati Non-Stop Flight <b>Kolkata <i>(GAU)</i></b></span><br /><br />
                                                    <table className="table table-bordered">
                                                        <tr className="table-danger">
                                                            <th>FLIGHT</th>
                                                            <th>AIRLINES</th>
                                                            <th>AIRPORT</th>
                                                            <th>TIME</th>
                                                            <th>DAY</th>
                                                        </tr>
                                                        <tr>
                                                            <td>6E262</td>
                                                            <td>IndiGo</td>
                                                            <td>Delhi</td>
                                                            <td>07:25 - 09:40</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>SG1852</td>
                                                            <td>SpiceJet</td>
                                                            <td>Delhi</td>
                                                            <td>10:10 - 12:15</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>6C228</td>
                                                            <td>IndiGo</td>
                                                            <td>Mumbai</td>
                                                            <td>05:40 - 08:50</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>SG695</td>
                                                            <td>SpiceJet</td>
                                                            <td>Mumbai</td>
                                                            <td>06:40 - 09:30</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>6C457</td>
                                                            <td>IndiGo</td>
                                                            <td>Bengaluru</td>
                                                            <td>08:20 - 11:20</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>QP1323</td>
                                                            <td>Akasa Air</td>
                                                            <td>Bengaluru</td>
                                                            <td>09:20 - 11:20</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>6E6309</td>
                                                            <td>IndiGo</td>
                                                            <td>Chennai</td>
                                                            <td>05:40 - 08:25</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>6E6011</td>
                                                            <td>IndiGo</td>
                                                            <td>Chennai</td>
                                                            <td>15:55 - 18:30</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className={customActiveTab === 'tab3' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-hotel-tab">
                                                <div className="overflow-table">
                                                    <table className="table table-bordered">
                                                        <tr className="table-primary text-center">
                                                            {/* <!-- Add the "text-center" className to center content --> */}
                                                            <th colspan="6">HOTEL CATEGORY - 3 STAR </th>
                                                        </tr>
                                                        <tr className="table-danger">
                                                            <th className="text-center">Destination</th>
                                                            <th className="text-center">Hotel Names</th>
                                                            <th className="text-center">Hotel Type</th>
                                                            <th className="text-center">No of Nights</th>
                                                            <th className="text-center">Meal Plan</th>
                                                        </tr>
                                                        <tr>
                                                            <td>Kaziranga</td>
                                                            <td>D’Courtyard Resort / Dhanshree Resort / La Vue Resort </td>
                                                            <td>3 Star</td>
                                                            <td>2N</td>
                                                            <td>Room with Breakfast</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Shillong</td>
                                                            <td>The Eee Cee Hotel / HOTEL SAPPHIRE / Hotel Jessica Residency </td>
                                                            <td>3 Star</td>
                                                            <td>3N</td>
                                                            <td>Room with Breakfast</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Cherapunjee</td>
                                                            <td>7 Sisters Falls View Inn / SERENE RESORT </td>
                                                            <td>3 Star</td>
                                                            <td>1N</td>
                                                            <td>Room with Breakfast</td>
                                                        </tr>
                                                    </table>

                                                    <table className="table table-bordered">
                                                        <tr className="table-primary text-center">
                                                            {/*  <!-- Add the "text-center" className to center content --> */}
                                                            <th colspan="6">PACKAGE PRICE IN 3 STAR CATEGORY HOTEL</th>
                                                        </tr>
                                                        <tr class="table-danger">
                                                            <th class="text-center">No of Pax</th>
                                                            <th class="text-center">Package Price</th>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-center">02 Adults</td>
                                                            <td class="text-center">28,900/- PP</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-center">
                                                                04 Adults
                                                            </td>
                                                            <td class="text-center">
                                                                22,800/- PP
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-center">
                                                                06 Adults
                                                            </td>
                                                            <td class="text-center">
                                                                19,200/- PP
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    <br />

                                                </div>
                                            </div>
                                            <div className={customActiveTab === 'tab4' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-sightseeing-tab">
                                                <div className="container-fluid px-0 py-1">
                                                    <h5 class="tomato">Kaziranga</h5>
                                                    <ul class="place">
                                                        <p> Kazirnaga Jungle Safari, Cultural program in orchid.</p>
                                                    </ul>
                                                    <h5 class="tomato">Shillong</h5>
                                                    <ul class="place">
                                                        <p>Umiam Lake, Wards lake, Hyadari Park, Don Bosco Muesum, Indian Air Force Muesum, Elephant Falls and Shillong Peak, police bazar.</p>
                                                    </ul>
                                                    <h5 class="tomato">Cherapunjee</h5>
                                                    <ul class="place">
                                                        <p> Nohkalikai Falls, Mawsmai Cave, Seven Sister Falls, Arwah Cave, Eco Park and Thangkharang Park.</p>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className={customActiveTab === 'tab5' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-meals-tab">
                                                <div className="overflow-table">
                                                    <h5 class="tomato">6 times Meals and 6 times Breakfast has been Provided</h5>
                                                    <table className="table table-bordered">
                                                        <tr className="table-danger">
                                                            <th colspan="6" className="text-center">Category</th>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-center">JAIN</td>
                                                            <td class="text-center">PURE-VEG</td>
                                                            <td class="text-center">VEGAN</td>
                                                            <td class="text-center">PURE-NON-VEG</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <!--By Itinerary--> */}
                            <div className="container-fluid navtabCustom">

                                <h5 className="tomato">Day wise Itinerary</h5>
                                <hr />

                                <nav>
                                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                        <a onClick={() => this.handleDayWiseTabClick('day1')} className={daywiseActiveTab === 'day1' ? 'nav-item nav-link active' : 'nav-item nav-link'} role="tab" aria-controls="nav-day-one" aria-selected="true">DAY-1</a>
                                        <a onClick={() => this.handleDayWiseTabClick('day2')} className={daywiseActiveTab === 'day2' ? 'nav-item nav-link active' : 'nav-item nav-link'} role="tab" aria-controls="nav-day-two" aria-selected="false">DAY-2</a>
                                        <a onClick={() => this.handleDayWiseTabClick('day3')} className={daywiseActiveTab === 'day3' ? 'nav-item nav-link active' : 'nav-item nav-link'} role="tab" aria-controls="nav-day-three" aria-selected="false">DAY-3</a>
                                        <a onClick={() => this.handleDayWiseTabClick('day4')} className={daywiseActiveTab === 'day4' ? 'nav-item nav-link active' : 'nav-item nav-link'} role="tab" aria-controls="nav-day-four" aria-selected="true">DAY-4</a>
                                        <a onClick={() => this.handleDayWiseTabClick('day5')} className={daywiseActiveTab === 'day5' ? 'nav-item nav-link active' : 'nav-item nav-link'} role="tab" aria-controls="nav-day-five" aria-selected="false">DAY-5</a>
                                        <a onClick={() => this.handleDayWiseTabClick('day6')} className={daywiseActiveTab === 'day6' ? 'nav-item nav-link active' : 'nav-item nav-link'} role="tab" aria-controls="nav-day-six" aria-selected="false">DAY-6</a>
                                        <a onClick={() => this.handleDayWiseTabClick('day7')} className={daywiseActiveTab === 'day7' ? 'nav-item nav-link active' : 'nav-item nav-link'} role="tab" aria-controls="nav-day-seven" aria-selected="false">DAY-7</a>
                                    </div>
                                </nav>
                                <div className="tab-content" id="nav-tabContent">
                                    <div className={daywiseActiveTab === 'day1' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-one-tab">
                                        <img src={navDay1ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 class="tomato"> <b>Day 1 – GUWAHATI TO KAZIRANGA </b></h5>
                                        <p><b>Welcome on arrival</b> at Guwahati airport and Complimentary visit to
                                            Kamakhya Temple, (Arrival Should be Before 10.30 AM)The trip ends with memories.and transfer to
                                            Kaziranga National Park, the home of the “One Horned Rhinoceros”. Check in at your hotel/Lodge/resort.
                                            Evening free for leisure.
                                        </p>
                                        <b>Overnight stay at the hotel.</b>
                                    </div>
                                    <div className={daywiseActiveTab === 'day2' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-two-tab">
                                        <img src={navDay2ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 className="tomato"><b>Day 2 - JUNGLE SAFARI AT KAZIRANGA</b></h5>
                                        <p>
                                            <b>Today after breakfast, </b> Early morning explores Western Zone of Kaziranga National Park on back of
                                            elephant. Apart from worldendangered One-horned Rhinoceros, the Park sustains half the world’s
                                            population of genetically pureWild Water Buffaloes, over 1000 Wild elephants and perhaps the densest
                                            population of Royal BengalTigers anywhere. Kaziranga is also a bird watchers paradise and home to some
                                            500 species of birds. TheCrested Serpent Eagle, Pallas Fishing Eagle, Grey headed Fishing Eagle, Swamp
                                            Partridge, Bar-headedgoose, whistling Teal, Bengal Florican, Storks, Herons and Pelicans are some of the
                                            species found here.<br />
                                            We will return to the resort for breakfast.Afternoon experience jeep safari through the Central Zone
                                            ofthe National Park. In evening enjoy the cultural program in Orchid Park.<br /><br />
                                        </p>
                                        <b>Overnight stay at Hotel.</b>
                                    </div>
                                    <div className={daywiseActiveTab === 'day3' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-three-tab">
                                        <img src={navDay3ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 className="tomato"> <b>Day 3 – KAZIRANGA TO SHILLONG</b></h5>
                                        <p>Early morning explores Western Zone of Kaziranga National Park on back of elephant. After breakfast
                                            check out from hotel and transfer to Shillong. Often called the ‘Scotland of the East’, Shillong has
                                            lots to discover from lakes to hills to the jaw-dropping waterfalls. En route, stop at Barapani Lake for
                                            some amazing view & water activities. On arrival at Shillong, check in to the hotel. If time permits
                                            visit Ward’s lake, Lady Haydari Park and Police Bazar.
                                        </p>
                                        <b>Overnight stay at Shillong.</b>
                                    </div>
                                    <div className={daywiseActiveTab === 'day4' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-four-tab">
                                        <img src={navDay4ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 class="tomato"> <b>Day 4 – SHILLONG–MAWSYNRAM-SHILLONG</b></h5>
                                        <p>
                                            After breakfast drive to Mawsynram, En-route visit Elephant Falls, Shillong Peak (Entry depends Indian
                                            Army) and Cathedral Catholic Chruch.Visit Mawphlang Sacred Forest, Mawjymbuin Cave (Mawsynram cave),
                                            Naturally formed Shiva Linga. After sightseeing evening back to Shillong.
                                        </p>
                                        <b>Overnight stay at Hotel Shillong .</b>
                                    </div>
                                    <div className={daywiseActiveTab === 'day5' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-five-tab">
                                        <img src={navDay5ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 class="tomato"> <b>Day 5 – SHILLONG TO CHERRAPUNJEE</b></h5>
                                        <p>
                                            <b>Entire Day Cherrapunjee Local</b>
                                            Includes, Today post breakfast, you will proceed to Cherrapunji. En-route
                                            visit Elephant Falls, Shillong Peak (Entry depends Indian Army) and Cathedral Catholic Chruch.Upon
                                            arrival at Cherrapunji, we will commence with our sightseeing tour and visit famous attractions such as
                                            Nohkalikai Falls, Mawsmai Cave, Seven Sister Falls, Arwah Cave, Eco Park and Thangkharang Park.
                                        </p>
                                        <b>Overnight stay at Cherrapunjee.</b>
                                    </div>
                                    <div className={daywiseActiveTab === 'day6' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-six-tab">
                                        <img src={navDay6ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 class="tomato"> <b>Day 6 – CHERRAPUNJEE TO SHILLONG VIA MAWLYNNONG-DAWKI</b></h5>
                                        <p>
                                            Post breakfast, we will proceed to visit the cleanest village of Asia - Mawllynnong. You will also get a
                                            chance to explore the nearby villages and witness the amazing living root bridges.Later on, you will be
                                            transferred to the international border of Bangladesh, wherein you will be visiting Dawki Village. This
                                            village is known for having some of the cleanest rivers, a classic example of which is the enchanting
                                            Umngot River. Later on, you will return to Shillong for a relaxing overnight stay.</p>
                                        <b>Overnight stay at Hotel Shillong .</b>
                                    </div>
                                    <div className={daywiseActiveTab === 'day7' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-seven-tab">
                                        <img src={navDay7ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 class="tomato"> <b>Day 7 – DEPARTURE FROM SHILLONG</b></h5>
                                        <p>
                                            After breakfast check out from hotel and drop at Guwahati Airport / Railway station as per timings.</p>
                                    </div>
                                </div>
                            </div>
                            {/* <!--By Itinerary--> */}

                            {/* <!--insert table inclusion exclusion--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        {
                                            <InclusionExclusion />
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* <!--end table inclusion exclusion--> */}

                            {/* <!--start of package details--> */}
                            <div className="container-fluid py-5">

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>Trending Meghalaya<span>Tour Packages</span></h2>
                                            <h4>Related Assam Meghalaya Tour plan</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                                        <div className="carousel-inner">
                                            <div className="carousel-item active">

                                                <Slider {...settings}>
                                                    {hotPackagesView}
                                                </Slider>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!--end of package details--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>Frequently Ask <span>Questions</span></h2>
                                            <h4>Assam Meghalaya Trip Related Questions</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="accordion" id="accordionPanelsStayOpenExample">
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingOne">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                                    How is the climate in Kaziranga different from Meghalaya?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseOne" className="accordion-collapse collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">The climate in Kaziranga, Assam, is generally hotter and drier compared to Meghalaya, which experiences a milder and wetter climate due to its higher elevation and proximity to the Bay of Bengal.</div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingTwo">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                                    What is the best time to Travel ?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseTwo" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">The best time to travel to Kaziranga and Meghalaya is during the dry season, which typically spans from November to April. This period offers pleasant weather, making it ideal for exploring the natural beauty and wildlife of Kaziranga National Park and enjoying the lush landscapes of Meghalaya.</div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingThree">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                                How to reach Kziranga from Meghalaya ?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseThree" className="accordion-collapse collapse show" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">To reach Kaziranga from Meghalaya, you can travel by road. Start from Meghalaya and head towards Guwahati, Assam. From Guwahati, Kaziranga National Park is about 200 kilometers away, accessible by road via NH37.</div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingFour">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                                What are the must-visit attractions of Meghalaya?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseFour" className="accordion-collapse collapse show" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">The must-visit attractions in Meghalaya include Living Root Bridges, Dawki River and Umngot River, Mawlynnong Village, and Cherrapunji's waterfalls.</div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingFive">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                                                What are the major languages spoken in Assam and Meghalaya?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseFive" className="accordion-collapse collapse show" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">In Kaziranga, Assamese and Bengali are the major languages spoken. In Meghalaya, the major languages spoken are Khasi, Garo, and English.</div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingSix">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseFive">
                                                What are the must-visit attractions of  Kaziranga?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseSix" className="accordion-collapse collapse show" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">The must-visit attractions of Kaziranga National Park include its diverse wildlife, particularly the one-horned rhinoceros, and the scenic beauty of its grasslands and wetlands. Additionally, exploring the park through jeep or elephant safaris offers thrilling experiences.</div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingSeven">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
                                                Are there any guided tours available for exploring the regions?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseSeven" className="accordion-collapse collapse show" aria-labelledby="flush-headingSeven" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">Yes, there are guided tours available for exploring Kaziranga and Meghalaya given in our website, Adorable Vacation LLP.</div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingEight">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
                                                How many days should I allocate for a trip to Assam and Meghalaya to cover the major attractions?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseEight" className="accordion-collapse collapse show" aria-labelledby="flush-headingEight" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">Plan for at least 7-10 days to thoroughly explore Kaziranga and Meghalaya, allowing time for wildlife safaris in Kaziranga National Park and visits to major attractions like Cherrapunji, Dawki River, and Mawlynnong village, known as the cleanest village in Asia. This timeframe provides a balance between experiencing the natural beauty, cultural richness, and adventure activities in both regions.</div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingNine">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseNine">
                                                Are there any permits required to visit Meghalaya?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseNine" className="accordion-collapse collapse show" aria-labelledby="flush-headingNine" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">An inner line permit is mandatory. To obtain an Inner Line Permit (ILP) is an official travel document issued by the concerned state government allowing inward travel of a domestic traveler into a protected area for a limited period.</div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingTen">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
                                                How is the weather in Assam and Meghalaya?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseTen" className="accordion-collapse collapse show" aria-labelledby="flush-headingTen" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">The weather in Kaziranga and Meghalaya varies, but generally, they experience a humid subtropical climate with frequent rainfall throughout the year.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h3><b>Meghalaya <span class="tomato">Tour Reviews</span></b></h3>
                                            <h4><i>"Your Experience is our Marketing"</i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-cols-1">
                                    <div id="testimonials">
                                        <div className="testimonial-box-container">

                                            {reviewersDataView}

                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="col-md-3">
                            <BookingBox />
                        </div>
                    </div>
                </div>
                <div class="call-float">
                        <a href="tel:+91-91636-76037">
                            <i className="fa fa-phone"></i><span><small>+91 9163676037</small></span>
                        </a>
                    </div>
            </>
        );
    }
}

export default Meghalaya_6N_7D_Package;