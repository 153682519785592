import React, { Component } from 'react';
import MainHeader from '../../../components/common/MainHeader';
import FooterDesktop from '../../../components/common/FooterDesktop';
import Sikkim_Darjeeling_4N_5D_Package from '../../../components/package/Sikkim_Darjeeling/Sikkim_Darjeeling_4N_5D_Package';
import { Helmet } from "react-helmet";

class Sikkim_Darjeeling_4N_5D_Page extends Component {
    constructor() {
        super();
        this.state = {
            TrendingPackagesData: [],
            ReviewersData: [],
        }
    }

    componentDidMount() {
        window.scroll(0, 0);
        const TrendingPackageDataList = [
            {
                Title: "Sikkim for Soulmates",
                ImageName: "Sikkim-Tour-4N-5D.webp",
                AllternateText: "...",
                Description: "2N Gangtok | 2N Darjeeling | 3 Star Hotel",
                PricePerNight: "16,700",
                NoOfTours: 42,
                NoOfDeparture: 35,
                SDFInclude: "SDF Inc.",
                MutedText: "*Per head / Min 6 Pax",
                book: "Book Now",
                Url: 'tel://9147061467'
            },
            {
                Title: "Romanticize Sikkim",
                ImageName: "Sikkim-Tour-5N-6D.webp",
                AllternateText: "...",
                Description: "3N Gangtok | 1N Lachen | 1N Lachung | 3 Star Hotel",
                PricePerNight: "21,260",
                NoOfTours: 45,
                NoOfDeparture: 39,
                SDFInclude: "SDF Inc.",
                MutedText: "*Per head / Min 6 Pax",
                book: "Book Now",
                Url: 'tel://9147061467'
            },
            {
                Title: "Mesmerizing Sikkim",
                ImageName: "North-Sikkim-Packages-from-Gangtok-6N-7D.webp",
                AllternateText: "...",
                Description: "2N Gangtok | 1N Lachen | 1N Lachung | 2N Darjeeling | 3 Star Hotel",
                PricePerNight: "22,400",
                NoOfTours: 43,
                NoOfDeparture: 38,
                SDFInclude: "SDF Inc.",
                MutedText: "*Per head / Min 6 Pax",
                book: "Book Now",
                Url: 'tel://9147061467'
            },

            {
                Title: "Iconic Sikkim",
                ImageName: "North-Sikkim-Tour-5N-6D.webp",
                AllternateText: "...",
                Description: "3N Gangtok | 2N Darjeeling | 3 Star Hotel",
                PricePerNight: "15,120",
                NoOfTours: 47,
                NoOfDeparture: 40,
                SDFInclude: "SDF Inc.",
                MutedText: "*Per head / Min 6 Pax",
                Url: 'tel://9147061467'
            },
            {
                Title: "Pleasing Sikkim",
                ImageName: "Extended-Sikkim-Packages-7N-8D.webp",
                AllternateText: "...",
                Description: "3N Gangtok | 1N Lachen | 1N Lachung | 2N Darjeeling | 3 Star Hotel",
                PricePerNight: "21,126",
                NoOfTours: 44,
                NoOfDeparture: 37,
                SDFInclude: "SDF Inc.",
                MutedText: "*Per head / Min 6 Pax",
                Url: 'tel://9147061467'
            },
            {
                Title: "Extended Sikkim",
                ImageName: "Complete-Sikkim-Tour-Package-8N-9D.webp",
                AllternateText: "...",
                Description: "2N Gangtok | 1N Pelling | 1N Ravangla | 2 More.. | 3 Star Hotel",
                PricePerNight: "22,327",
                NoOfTours: 46,
                NoOfDeparture: 39,
                SDFInclude: "SDF Inc.",
                MutedText: "*Per head / Min 6 Pax",
                Url: 'tel://9147061467'
            }

        ];

        const reviewersDataList = [
            {
                Name: "Tamojit Ghosh",
                ImageName: "tamojit.webp",
                AllternateText: "...",
                Location: "@Kolkata",
                Comment: "Dear team Adorable , we have safely reached in our hometown leaving plenty of only good memories.As much I recall, we have started our discussion regarding Sikkim trip arnd month of July ,four months gone within a blink of eyes and today we completed our most memorable trip.Initially Adorable offered us a complete trip packages of Silkroute and north Sikkim , but after this unforeseen mud flood occurred on 3rd October at sikkim area we almost lost our hope about this trip but adorable was always with us and within a very short notice they have reframed the trip plan by adding Namchi ravangla Kaluk ( best among all ).<br/> In entire travel duration we enjoyed breathtaking beauty of Himalaya , lush green beauty of mountain ranges , cool calm serene valleys and many more which I cannot describe in such short message, our entire trip was well managed from starting to end , the selection of cabs,hotel /homestays meets our expectation without any doubt .Adorable flawlessly managed our safety and comfort throughout our trip. Therefore, I highly wish that me and my entire family would again explore some new places in near future with adorable , thanks a ton to entire adorable team for their awesome services , we are happy indeed You guys rocked 🤘",
            },
            {
                Name: "K A U S H A N I",
                ImageName: "kaushani.jpg",
                AllternateText: "...",
                Location: "@Kolkata",
                Comment: "A very satisfying experience with Adorable group. We were supposed to go to North Sikkim but just two days before the trip, it got cancelled due to natural calamities and they (adorable) arranged North Bengal trip for us in just that short notice and just saved our huge amount of expenses... Thanks.Appreciated 🤝👏.",
            },
            {
                Name: "Ramakrishnan Easwaran",
                ImageName: "ramkrishna.jpg",
                AllternateText: "...",
                Location: "@Bangalore",
                Comment: "We were a group of 11 members (including 2 kids) planning for a trip to North Sikkim. Through word of mouth referral we got in touch with the team. Right from our first interaction through the course of the trip, the striking aspect was the prompt and proactive communication.Everything (including hotel stays, cabs, itinerary et al) was very well planned and coordinated. Taking 2 kids (less than 5 years old) to Lachung and Yumthang Valley was no mean task but you guys made it simpler for us.Thanks team for making our vacations a dream stay. You guys are awesome. Keep going.",
            },
            {
                Name: "Thiyagarajan Amaresan",
                ImageName: "thiya.jpg",
                AllternateText: "...",
                Location: "@Mumbai",
                Comment: "This is my 2 nd time with this agency 1st time meghalaya 2nd time Gangtok Sikkim and Darjeeling over all the trip experience is awesome and enjoyed.Their arrangements, timely response made our trip is enjoyable i thank the entire adorable vacation team members...",
            },
        ]

        this.setState({
            TrendingPackagesData: TrendingPackageDataList,
            ReviewersData: reviewersDataList
        })
    }

    render() {
        return (
            <>
            <Helmet>
                    <title>Sikkim Darjeeling 4N 5D</title>
                    <meta name="description" content="Sikkim Tour Packages - Get Sikkim trip packages at best prices. Book Sikkim Tours starting from Rs. 12,090 with assured health & safety measures from Adorable Vacation." />
                </Helmet>
                
                <MainHeader />
                <Sikkim_Darjeeling_4N_5D_Package
                    TrendingPackagesData={this.state.TrendingPackagesData}
                    ReviewersData={this.state.ReviewersData}
                />
                <FooterDesktop />
            </>
        );
    }
}

export default Sikkim_Darjeeling_4N_5D_Page;