import React, { Component } from 'react';
import ApiURL from "../../api/ApiURL";
class About extends Component {

    render() {
        const topBannerImageURL = ApiURL.AboutUsImageURL + "/Arunachal.webp";
        const groupImageURL = ApiURL.AboutUsImageURL + "/group-img1.webp";
        const adorableBhutanOfficeImageURL = ApiURL.AboutUsImageURL + "/adorable-bhutan-office.webp";
        const seniorCitizensImageURL = ApiURL.AboutUsImageURL + "/senior-citizens.webp";
        const maldivesImageURL = ApiURL.AboutUsImageURL + "/maldives.webp";
        const knowledgeImageURL = ApiURL.AboutUsImageURL + "/knowledge.webp";
        const clientlyImageURL = ApiURL.AboutUsImageURL + "/cliently.webp";
        const customerRelationshipImageURL = ApiURL.AboutUsImageURL + "/customer-relationship.webp";
        const NicheTravelPartnerImageURL = ApiURL.AboutUsImageURL + "/NICHE-TRAVEL-PARTNER.webp";

        const teamMemberDataList = this.props.TeamMemberData;
        const teamMemberView = teamMemberDataList.length > 0 ?
            (
                teamMemberDataList.map((dataItem, i) => {
                    const teamMemberImgURL = ApiURL.AboutUsImageURL + "/" + dataItem.ImageName;
                    return (
                        <div className="col-md-2 col-sm-6 col-xs-12 imgCustWidth">
                            <div className="single-team">
                                <div className="img-area">
                                    <img src={teamMemberImgURL} className="img-responsive" alt="..." />
                                </div>
                                <div className="img-text">
                                    <h5>{dataItem.Name}</h5>
                                    <h4>{dataItem.Designation}</h4>
                                </div>
                            </div>
                        </div >
                    )
                })) :
            (<div></div>)

        const FounderDataList = this.props.FounderData;
        const FounderDataView = FounderDataList.length > 0 ?
            (
                FounderDataList.map((dataItem, i) => {
                    const FounderImgURL = ApiURL.AboutUsImageURL + "/" + dataItem.ImageName;
                    return (
                        <div
                        className="col-md-4 col-sm-6 col-xs-12 imgCustWidth">
                        <div className="single-team">
                          <div className="img-area">
                            <img
                              src={FounderImgURL}
                              className="img-responsive"
                              alt="..."
                            />
                          </div>
                          <div className="img-text">
                            <h5>{dataItem.Name}</h5>
                            <h4>{dataItem.Designation}</h4>
                          </div>
                        </div>
                      </div>
                    )
                })) :
            (<div></div>)

        return (
            <>
                <div className="container-fluid">
                    <div className="row">
                        <img src={topBannerImageURL} className="img-fluid px-0" alt="..." />
                    </div>
                </div>
                <div className="container-fluid py-5">
                    <div className="row">
                        <div className="col-md-12 content">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="site-heading">
                                        <h2 className="tomato center">Your Gateway to Memorable Journeys!</h2>
                                    </div>
                                    <div className="col-md-8"><b>Adorable Vacation LLP</b> is a distinguished travel company based in the vibrant city of
                                        Kolkata. Founded on 18 July 2022 by three passionate and experienced professionals in the travel industry,
                                        <b>Akash Dutta, Soumalya Ghosh, and Indrajit Kar,</b> we have quickly established ourselves as a leading name in
                                        the world of travel.
                                        <ul className="coreValue">
                                            <h2>Our Journey</h2>
                                            <p>With an unwavering commitment to excellence and a vast knowledge of the travel industry, our
                                                founders, <b>Soumalya Ghosh, Akash Dutta, and Indrajit Kar,</b> each bring unique expertise gained through
                                                years of dedicated service. <b>Soumalya Ghosh,</b> with over 8 years of experience, <b>Akash Dutta,</b> with more
                                                than 6 years, and <b>Indrajit Kar,</b> with almost 5 years, have an innate understanding of what it takes to
                                                create unforgettable travel experiences.</p>
                                        </ul>
                                    </div>
                                    <div className="col-md-4">
                                        <img src={groupImageURL} className="img-fluid imgStyle" alt="..." />
                                    </div>

                                    <div className="col-md-12">
                                        <h2 className="tomato pt-5">What We Offer</h2>
                                        At <b>Adorable Vacation LLP,</b> we specialize in crafting customized travel solutions for families, couples, and
                                        groups, catering to all your desires and preferences. Our primary focus lies in delivering exceptional
                                        experiences that make your vacation truly memorable.
                                        <br /><br />
                                        <h2 className="tomato">Specialized Packages</h2>
                                        <p>Our company takes pride in being experts in arranging tailor-made <b>Maldives Honeymoon Package Tours from
                                            Kolkata, Maldives Tour Packages from Kolkata, Bangkok Pattaya Package Tours from Kolkata, Dubai Package
                                            Tours from Kolkata, Egypt Package Tours from Kolkata, Kailash Mansarovar Package Tours from Kathmandu,
                                            Nepal Package Tours with Muktinath Darshan, Kailash Mansarovar Yatra Helicopter Packages, and Sri Lanka
                                            Package Tours from Kolkata.</b></p>
                                    </div>

                                    <div className="col-md-4 pt-5">
                                        <h4 className="tomato">Himalayan Destination Specialists</h4>
                                        We boast extensive knowledge of the <b>Himalayan region,</b> ranging from the serene landscapes of <b>Kashmir and
                                            Ladakh</b> to the enchanting states of <b>Northeastern India.</b> Our commitment to exploring these destinations
                                        personally enables us to provide the most authentic and well-rounded travel experiences to our clients.
                                    </div>

                                    <div className="col-md-4 pt-5">
                                        <img src={adorableBhutanOfficeImageURL} className="img-fluid imgStyle" alt="..." />
                                    </div>

                                    <div className="col-md-4 pt-5">
                                        <h4 className="tomato">International Office At Bhutan</h4>
                                        With our own office in <b>Lango village, Paro Bhutan,</b> we have established a strong foothold in this mesmerizing country, allowing
                                        us to curate immersive journeys that encapsulate the true essence of Bhutan's culture, heritage, and
                                        natural beauty.
                                    </div>

                                    <div className="col-md-4 pt-5">
                                        <img src={seniorCitizensImageURL} className="img-fluid imgStyle" alt="..." />
                                    </div>

                                    <div className="col-md-4 pt-5">
                                        <h4 className="tomato">Senior Citizen Assistance</h4>
                                        At <b>Adorable Vacation LLP,</b> we firmly believe that age should never hinder the joy of traveling. Therefore,
                                        we offer dedicated assistance and special provisions for senior citizens, ensuring that their travel
                                        experiences are comfortable, safe, and thoroughly enjoyable.
                                    </div>

                                    <div className="col-md-4"></div>

                                    <div className="col-md-4 pt-5">
                                        <h4 className="tomato">Customer Satisfaction and Authenticity</h4>
                                        In this digital era where scams and fraudulent activities are rampant, <b>Adorable Vacation LLP</b> stands as an
                                        authentic signature travel brand that is 100% legitimate. Our commitment to providing genuine and
                                        trustworthy services has earned us exceptional customer feedback, which can be seen on our Facebook page
                                        and through Google reviews. We pride ourselves on building lasting relationships with our clients based on
                                        trust and satisfaction.
                                    </div>

                                    <div className="col-md-4 pt-5">
                                        <img src={maldivesImageURL} className="img-fluid imgStyle" alt="..." />
                                    </div>
                                    <div className="col-md-4 pt-5">
                                        <h4 className="tomato">Continuous Monitoring and After-Sales Support</h4>
                                        We understand that a seamless travel experience goes beyond the planning stage. To ensure your journey is
                                        stress-free and smooth, we create WhatsApp groups for each trip, allowing our team to continuously monitor
                                        and support you throughout your adventure. Our dedicated after-sales (operations team) is available 24X7
                                        for any updates or assistance you may require during your travels.
                                    </div>

                                    <div className="col-md-4 pt-5"></div>

                                    <div className="col-md-4 pt-5">
                                        <h4 className="tomato">Ground Knowledge and Personalized Service:</h4>
                                        Our team possesses extensive ground knowledge of all destinations we cover. This expertise allows us to
                                        curate personalized itineraries that cater to your specific interests, ensuring that every aspect of your
                                        travel aligns with your preferences.
                                    </div>

                                    <div className="col-md-4 pt-5">
                                        <img src={knowledgeImageURL} className="img-fluid imgStyle" alt="..." />
                                    </div>

                                    <div className="col-md-4 pt-5">
                                        <h4 className="tomato">Our Diverse Clientele:</h4>
                                        In just one short year, <b>Adorable Vacation LLP</b> has been fortunate to build a diverse and rich client base
                                        on a pan-India basis. We take immense pride in serving a wide spectrum of travelers, each with unique
                                        backgrounds, professions, and interests. From high-profile businessmen from the Gujarati community to
                                        renowned doctors and lawyers, and even government service officials like the GST commissioner of West
                                        Bengal, our clientele is a testament to the trust and faith they place in our services.
                                    </div>

                                    <div className="col-md-4 pt-5">
                                        <img src={clientlyImageURL} className="img-fluid imgStyle" alt="..." />
                                    </div>
                                    <div className="col-md-4 pt-5">

                                        <h4 className="tomato">Catering to Every Segment</h4>
                                        At <b>Adorable Vacation LLP,</b> we believe that travel should be accessible to everyone, regardless of their
                                        profession or status. That is why we cater to the needs of a diverse range of travelers, from scientists
                                        associated with ISRO, to marine engineers, and common individuals from all walks of life. We ensure that
                                        every guest, no matter their background, receive the same level of attention, care, and exceptional
                                        service.
                                    </div>

                                    <div className="col-md-4 pt-5">
                                        <img src={customerRelationshipImageURL} className="img-fluid imgStyle" alt="..." />
                                    </div>

                                    <div className="col-md-4 pt-5">
                                        <h4 className="tomato">Building Lasting Relationships</h4>
                                        At <b>Adorable Vacation LLP,</b> we firmly believe that every customer who comes to us becomes a part of our
                                        extended family. We prioritize building lasting relationships with our clients, and we are humbled to see
                                        that once a customer travels with us, they become our permanent patrons. This loyalty and trust motivate
                                        us to continuously strive for excellence in crafting niche travel experiences that leave a lasting
                                        impression on our guests.
                                    </div>
                                    <div className="col-md-4 pt-5">
                                    </div>

                                    <div className="col-md-4 pt-5"></div>

                                    <div className="col-md-4 pt-5">
                                        <img src={NicheTravelPartnerImageURL} className="img-fluid imgStyle" alt="..." />
                                    </div>
                                    <div className="col-md-4 pt-5"><h4 className="tomato">Handcrafted Niche Travel Experience</h4>
                                        Each journey with <b>Adorable Vacation LLP</b> is a handcrafted and bespoke experience. We take the time to
                                        understand our clients' preferences, interests, and desires, curating itineraries that perfectly align
                                        with their expectations. Our attention to detail, personalized service, and commitment to authenticity set
                                        us apart as a travel brand that caters to the individuality of every traveler.
                                    </div>

                                    <div className="col-md-12">
                                        <br /><br /><br />
                                        <div className="site-heading text-center">
                                            <h2>Join the <span>Adorable Family</span></h2>
                                        </div>
                                        At <b>Adorable Vacation LLP,</b> our journey as a travel company is one that constantly evolves, but our core
                                        mission remains unchanged – to provide our clients with an unparalleled travel experience that goes beyond
                                        the ordinary and leaves an indelible mark on their hearts and souls. We take great pride in creating
                                        unforgettable journeys, meticulously crafted to ignite a sense of wonder, joy, and awe-inspiring memories
                                        that stay with our clients for a lifetime. When you choose <b>Adorable Vacation LLP,</b> you are not just
                                        selecting a travel agency; you are becoming a part of the Adorable family. Our commitment to building
                                        warm, genuine relationships with our clients goes beyond the confines of a mere business transaction. We
                                        welcome you with open arms, eager to share in the joy of exploration, discovery, and adventure. So,
                                        whether you are a seasoned traveler or taking your first steps on the path of wanderlust, we extend our
                                        hand to you with a promise – the promise of unforgettable journeys, cherished memories, and the joy of
                                        being a part of the Adorable family. Embrace the magic of travel with us and let the world unfold before
                                        you in all its splendor and magnificence. Together, we will create stories that are etched in time and
                                        treasured forever. Welcome to the Adorable family!
                                    </div>
                                </div>
                            </div>

                            <div className="team-area">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="site-heading text-center">
                                                <h2><span>Our Founding Partners</span></h2>
                                            </div>
                                        </div>

                                        {FounderDataView}

                                    </div>
                                </div>
                            </div>

                            <div className="team-area">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="site-heading text-center">
                                                <h2><span>Our Adorable Team</span></h2>
                                            </div>
                                        </div>

                                        {teamMemberView}

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default About;
