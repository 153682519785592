import React, { Component, Fragment } from "react";
import FooterDesktop from '../components/common/FooterDesktop';
import MainHeader from '../components/common/MainHeader';
import About from "../components/Others/About";
import { Helmet } from "react-helmet";

class AboutPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            FounderData: [],
            TeamMemberData: [],
            showMobileNavStatus: false,
        }
    }

    componentDidMount() {
        window.scroll(0, 0);
        this.hideMobileNav();
        const founderPartnerDataList = [
            {
                Name: "Soumalya Ghosh",
                ImageName: "Soumalya.webp",
                Designation: 'Managing Partner'
            },
            {
                Name: "Akash Dutta",
                ImageName: "Akash.webp",
                Designation: 'Managing Partner'
            },
            {
                Name: "Indrajit Kar",
                ImageName: "Indra.webp",
                Designation: 'Managing Partner'
            },
         ];
        const teamMemberDataList = [
            {
                Name: "Sonali Halder",
                ImageName: "sonali.webp",
                Designation: 'Senior Sales Executive'
            },
            {
                Name: "Soumya Sankar Dhar",
                ImageName: "soumya-dhar.webp",
                Designation: 'Manager'
            },
            {
                Name: "Anushka Majumdar",
                ImageName: "anuska.webp",
                Designation: 'Operation Manager'
            },
            {
                Name: "Sneheta Das",
                ImageName: "Sneheta.webp",
                Designation: 'Operation Executive'
            },
            {
                Name: "Noorul Ameen",
                ImageName: "Noorul.webp",
                Designation: 'B2B Sales Executive'
            },
            {
                Name: "Anannya Samaddar",
                ImageName: "Anannya.webp",
                Designation: 'Operation Executive'
            },
            {
                Name: "Subham Laha",
                ImageName: "Subham.webp",
                Designation: 'IT & Analyst'
            },
            {
                Name: "Soumi Das",
                ImageName: "Soumi.webp",
                Designation: 'Digital Marketing Executive'
            },
            {
                Name: "Geeta Sanyasi",
                ImageName: "Geeta.webp",
                Designation: 'Operation Manager (Bhutan)'
            },
            {
                Name: "Krishna Chakraborty",
                ImageName: "Krishna.webp",
                Designation: 'Accountant'
            },
            {
                Name: "Bikki Gupta",
                ImageName: "Bikki-Gupta.webp",
                Designation: 'Transport Manager (Sikkim)'
            },
            {
                Name: "Nima Tamang",
                ImageName: "Nina-Tamang.webp",
                Designation: 'Transport Manager (Bhutan)'
            }
        ];

        this.setState({ ...this.state, TeamMemberData: teamMemberDataList,FounderData: founderPartnerDataList })
    }

    showMobileNav = () => {
        this.setState({ showMobileNavStatus: true })
    }

    hideMobileNav = () => {
        this.setState({ showMobileNavStatus: false })
    }

    render() {
        return (
            <Fragment>
                <Helmet>
                    <title>About Us</title>
                </Helmet>
                <MainHeader />

                <About 
                TeamMemberData={this.state.TeamMemberData}
                FounderData={this.state.FounderData}
                 />
                <FooterDesktop />
            </Fragment>
        );
    }
}

export default AboutPage;