import React from "react";
import ReactDOM from "react-dom";
// import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import App from "./App";
//import "bootstrap/dist/css/bootstrap.min.css";
//import "./assets/css/bigSlider.css"
import "./assets/css/style.css";
//import "./assets/css/fontawesome.css";
//import 'font-awesome/css/font-awesome.min.css';
//import "./assets/css/animate.min.css";
//import "@szhsin/react-menu/dist/index.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
//import "./assets/css/placeholder-loading.min.css";
// import * as serviceWorker from "./serviceWorker";

import { hydrate, render } from "react-dom";

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  // Hydrate the app to attach event listeners to the existing markup
  hydrate(<App />, rootElement);
} else {
  // Render the app normally if no server-rendered content exists
  render(<App />, rootElement);
}

// import { hydrate } from "react-dom"

// ReactDOM.hydrate(<App/>, document.getElementById("root"));
// ReactDOM.render(
//     <React.StrictMode>
//       <App />
//     </React.StrictMode>,
//     document.getElementById("root")
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
