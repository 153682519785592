import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import BookingBox from './BookingBox';
import ApiURL from "../../api/ApiURL";
import HotPackageTour from '../home/HotPackageTour';

class AdventureTourLadakhToSikkim extends Component {
    render() {
        const packageTopBannerImageURL = ApiURL.PackageTopBannerImageURL + "/ladakh_bike_trip_from_manali.webp";
        const packageDestination_1_ImageURL = ApiURL.PackageDestinationBannerImageURL + "/ladakh_bike_trip_from_manali_1.jpg";
        const packageDestination_2_ImageURL = ApiURL.PackageDestinationBannerImageURL + "/ladakh_bike_trip_from_manali_2.jpg";

        const hotelsDataList = this.props.HotelsData;
        const MyView = hotelsDataList.length > 0 ?
            (
                hotelsDataList.map((hotelItem, i) => {
                    const hotelImgURL = ApiURL.HotelImageURL + "/" + hotelItem.ImageName;
                    return (<div className="col" key={i}>
                        <div className="card h-100">
                            <img src={hotelImgURL} className="card-img-top" alt={hotelItem.AllternateText} />
                            <div className="card-body">
                                <h5 className="card-title">{hotelItem.Title} <span> &#8377; {hotelItem.PricePerNight}</span></h5>
                                <p className="card-text">{hotelItem.Description}</p>
                            </div>
                            <div className="card-footer">
                                <small className="text-muted">{hotelItem.MutedText}</small>
                                <button type="button" className="btn btn-Warning btn-sm float-end">Book Now</button>
                            </div>
                        </div>
                    </div>)
                })
            ) :
            (<div></div>)


        return (
            <>
                <div className="container-fluid">
                    <div className="row">
                        <img src={packageTopBannerImageURL} className="img-fluid px-0" alt='...' />
                    </div>
                </div>
                <div className="container-fluid py-3">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="container-fluid">
                                        <h3 className="px-2">Adventure Tour Ladakh to Sikim</h3>

                                        <div className="d-flex flex-column flex-sm-row align-items-sm-center px-2">
                                            <div className="font-lg">
                                                2 States: 
                                                <Link to={"#"}><i className="fa fa-map-marker text-warning mx-2"></i> Sikim</Link>&nbsp;&nbsp; &amp; 
                                                <Link to={"#"}><i className="fa fa-map-marker text-warning mx-2"></i> Ladakh</Link>
                                            </div>
                                            <div className="reviews mx-5">
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                            </div>
                                        </div>
                                        <p className="p-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

                                        <div className="card">
                                            <div className="card-header custHeaer">
                                                All About Ladakh Package From Kolkata
                                            </div>
                                            <div className="card-body">
                                                <p>Ladakh is known for its stunning landscapes, including high mountain passes, deep valleys, and picturesque lakes.
                                                    If you are planning for a peaceful, soothing destination next summer then Leh-Ladakh is the best place for you to enjoy the beauty of nature. The cheapest Ladakh package tour from Mumbai has been arranged for you by Tourist Hub India. Before you make your visit to Leh - Ladakh, you must know some basic details about the places.
                                                    The largest town in Ladakh is Leh, which serves as the main center of tourism and commerce in the region. Other notable places in Ladakh include the Nubra Valley, Pangong Lake, and the Hemis National Park, which is home to the endangered snow leopard.</p>

                                                <p>You will be also able to explore various amazing places of India throung our customised packages like North Sikkim Tour 2n/3d, Pelling Ravangla Gangtok tour from Bagdogra, Silk Route Package Tour, North East Tour Package, Dooars Package Tour, Andaman Package Tour,North East Package Tour,Kashmir Package Tour, Sundarban Tour Package and many more with your friends and family. </p>
                                                <Link to={"#"} className="btn btn-Warning">Go Details</Link> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>Our Hotels <span>& Resturents</span></h2>
                                            <h4>Lorem Ipsum is simply dummy text</h4>
                                        </div>
                                    </div>
                                </div>
                                {
                                    (this.props.hotelSlidesToShow === 3)
                                        ? <div className="row row-cols-1 row-cols-md-3 g-3">{MyView}</div>
                                        : <div className="row row-cols-1 row-cols-md-4 g-4">{MyView}</div>
                                }
                            </div>

                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>Day wise Ladakh tour <span>plan from Kolkata</span></h2>
                                            <h4>Lorem Ipsum is simply dummy text</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="accordion" id="accordionPanelsStayOpenExample">
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                                    DAY 01: REACH AT LEH AND OVERNIGHT STAY
                                                </button>
                                            </h2>
                                            <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
                                                <div className="accordion-body">
                                                    On arrive in Leh Airport you will be greet by our representative & drive towards your confirmed hotel. Full day is for rest & leisure by your own. Dinner & overnight stay in Leh.
                                                    <ul className="itinerary-meta list-inline-block text-danger"><li><i className="fa fa-clock-o"></i> Checkout time: 9 A.M.</li> <li><i className="fa fa-clock-o"></i> Checkout time: 9 A.M.</li></ul>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                                                    DAY 02: LEH TO KARGIL VIA MULBEKH
                                                </button>
                                            </h2>
                                            <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
                                                <div className="accordion-body">
                                                    Morning after breakfast check out from the hotel & drive towards Kargil via Mulbekh route. Enroute visit sightseeing points like the Hall of Fame, Pathar Sahib Gurudwara, Magnetic Hill and Sangam Point. Later visit Mulbekh & Lamayuru Monastery, Moonland and witness beautiful mesmerizing moon landscapes. Late in the evening, I arrived at the hotel, overnight stay.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                                                    DAY 03: KARGIL TO TEMISGAM VILLAGE
                                                </button>
                                            </h2>
                                            <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                                                <div className="accordion-body">
                                                    Morning after breakfast check out from the hotel & drive towards Temisgam Village. On arrival check in to the camp/ homestay, after fresh up visit The Tingmosgang castle & monastery, dinner & overnight stay in Temisgam Village. Temisgam village: - Temisgam village is Small & peaceful village of Ladakh, ideal for people who love to explore offbeat destinations.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="panelsStayOpen-headingFour">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                                                    DAY 04: LEH LOCAL SIGHTSEEING
                                                </button>
                                            </h2>
                                            <div id="panelsStayOpen-collapseFour" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFour">
                                                <div className="accordion-body">
                                                    Morning after breakfast check out from the hotel & drive towards Temisgam Village. On arrival check in to the camp/ homestay, after fresh up visit The Tingmosgang castle & monastery, dinner & overnight stay in Temisgam Village. Temisgam village: - Temisgam village is Small & peaceful village of Ladakh, ideal for people who love to explore offbeat destinations.
                                                    <ul className="itinerary-meta list-inline-block text-danger"><li><i className="fa fa-clock-o"></i> Checkout time: 9 A.M.</li> <li><i className="fa fa-clock-o"></i> Checkout time: 9 A.M.</li></ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="panelsStayOpen-headingFive">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFive" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                                                    DAY 05: LEH TO NUBRA
                                                </button>
                                            </h2>
                                            <div id="panelsStayOpen-collapseFive" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFive">
                                                <div className="accordion-body">
                                                    Morning after breakfast check out from the hotel & drive towards Temisgam Village. On arrival check in to the camp/ homestay, after fresh up visit The Tingmosgang castle & monastery, dinner & overnight stay in Temisgam Village. Temisgam village: - Temisgam village is Small & peaceful village of Ladakh, ideal for people who love to explore offbeat destinations.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="panelsStayOpen-headingSix">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseSix" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                                                    DAY 03: KARGIL TO TEMISGAM VILLAGE
                                                </button>
                                            </h2>
                                            <div id="panelsStayOpen-collapseSix" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingSix">
                                                <div className="accordion-body">
                                                    Morning after breakfast check out from the hotel & drive towards Temisgam Village. On arrival check in to the camp/ homestay, after fresh up visit The Tingmosgang castle & monastery, dinner & overnight stay in Temisgam Village. Temisgam village: - Temisgam village is Small & peaceful village of Ladakh, ideal for people who love to explore offbeat destinations.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="panelsStayOpen-headingSeven">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseSeven" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                                                    DAY 03: KARGIL TO TEMISGAM VILLAGE
                                                </button>
                                            </h2>
                                            <div id="panelsStayOpen-collapseSeven" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingSeven">
                                                <div className="accordion-body">
                                                    Morning after breakfast check out from the hotel & drive towards Temisgam Village. On arrival check in to the camp/ homestay, after fresh up visit The Tingmosgang castle & monastery, dinner & overnight stay in Temisgam Village. Temisgam village: - Temisgam village is Small & peaceful village of Ladakh, ideal for people who love to explore offbeat destinations.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="panelsStayOpen-headingTen">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTen" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                                                    DAY 03: KARGIL TO TEMISGAM VILLAGE
                                                </button>
                                            </h2>
                                            <div id="panelsStayOpen-collapseTen" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTen">
                                                <div className="accordion-body">
                                                    Morning after breakfast check out from the hotel & drive towards Temisgam Village. On arrival check in to the camp/ homestay, after fresh up visit The Tingmosgang castle & monastery, dinner & overnight stay in Temisgam Village. Temisgam village: - Temisgam village is Small & peaceful village of Ladakh, ideal for people who love to explore offbeat destinations.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container-fluid py-3">
                                <div className="row packageListing">
                                    <h2>LADAKH TOUR PACKAGE INCLUSION:</h2>
                                    <ul>
                                        <li> Consigue una vida más feliz con menos problemas golpeándote todo el tiempo.</li>
                                        <li> Tener una mejor postura te da menos dolor a corto y largo plazo.</li>
                                        <li> Reducir el insomnio, y tener una mente sana y tranquila conmigo mismo.</li>
                                        <li> Recupera tu autoestima, confianza en ti mismo y seguridad de una mujer fuerte y empoderadora.</li>
                                        <li> Alcanza un nuevo nivel de felicidad con relaciones armoniosas y no más peleas innecesarias.</li>
                                        <li> Elimina las energías negativas y pon mucho de tu vida en el eje derecho.</li>
                                        <li> Una forma más sana, tranquila, tonificante y que te hace vivir mejor.</li>
                                        <li> Aumente su enfoque en los aspectos positivos de la vida y tener más equilibrio sentimental.</li>
                                        <li> Rescata tu fuerza interior dormida y ten más conciencia activa de tu transformación como persona.</li>
                                        <li> Mejora tu flexibilidad, todas las articulaciones y nunca más te sientas atascado independientemente de tu edad.</li>
                                    </ul>
                                    <h2 className="pt-4">LADAKH PACKAGE TOUR EXCLUSION:</h2>
                                    <ul>
                                        <li> Consigue una vida más feliz con menos problemas golpeándote todo el tiempo.</li>
                                        <li> Tener una mejor postura te da menos dolor a corto y largo plazo.</li>
                                        <li> Reducir el insomnio, y tener una mente sana y tranquila conmigo mismo.</li>
                                        <li> Recupera tu autoestima, confianza en ti mismo y seguridad de una mujer fuerte y empoderadora.</li>
                                    </ul>
                                </div>
                                <div className="row py-5"><img src={packageDestination_1_ImageURL} alt="..." /></div>
                            </div>

                            <div className="container-fluid">
                                <div className="row placetoVisit">
                                    <h2>PLACES TO VISIT AT LADAKH</h2>
                                    <ul>
                                        <li><strong>Thiksey Monastery</strong> is an ancient monastery located in the Shey village of Ladakh. It was built in the 17th century and is known for its   beautiful wall paintings and sculptures. The monastery is located on a hill and offers stunning views of the surrounding landscapes</li>
                                        <li> <strong>Shey Monastery</strong> is an ancient monastery located in the Shey village of Ladakh. It was built in the 17th century and is known for its beautiful wall paintings and sculptures. The monastery is located on a hill and offers stunning views of the surrounding landscapes. </li>
                                        <li> <strong>3 Idiot School</strong> is a popular tourist destination in Ladakh. The school was featured in the Bollywood movie "3 Idiots" and is known for its beautiful architecture and scenic location. The school is located in the Hemis village and offers stunning views of the Hemis National Park.</li>
                                        <li> <strong>Leh Palace</strong> is an ancient palace located in the city of Leh. It was built in the 17th century and is known for its beautiful architecture and stunning views of the surrounding landscapes. The palace was the former residence of the royal family of Ladakh and is now a popular tourist attraction in the region. </li>
                                        <li> <strong>Shanti Stupa</strong> is a beautiful stupa located in the city of Leh. It was built in the 20th century and is known for its stunning white dome and beautiful views of the surrounding landscapes. The stupa is a popular spot for tourists and locals alike and is a symbol of peace and unity. </li>
                                        <li><strong>Hemis Monastery</strong> is an ancient monastery located in the Hemis village of Ladakh. It was built in the 17th century and is known for its beautiful wall paintings and sculptures. The monastery is located in a scenic valley and is a popular destination for tourists.</li>
                                        <li> <strong>Sindhu Ghat</strong> is a beautiful ghat located on the banks of the Indus River in the city of Leh. It is a popular spot for tourists and locals alike and offers stunning views of the surrounding landscapes. The ghat is also a popular spot for picnics and other recreational activities. </li>
                                        <li> <strong>Pangong Lake</strong> is a beautiful lake located in the eastern part of Ladakh. It is known for its crystal-clear blue waters and stunning views of the surrounding mountains. The lake is a popular destination for tourists and is a must-visit spot for anyone traveling to Ladakh. </li>
                                        <li> <strong>Nubra Valley</strong> is a beautiful valley located in the northern part of Ladakh. It is known for its beautiful landscapes, picturesque villages, and ancient monasteries. The valley is home to several rare species of flora and fauna and is a popular destination for trekkers and nature enthusiasts. </li>
                                        <li> <strong>Khardung La Khardung</strong> La pass is a high mountain pass located in the Ladakh region of the Indian state of Jammu and Kashmir. It sits at an elevation of 5,359 meters (17,582 feet) above sea level, making it one of the highest motorable passes in the world. </li>
                                    </ul>

                                </div>
                                <div className="row py-5"><img src={packageDestination_2_ImageURL} alt="..." /></div>

                            </div>

                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>Package price <span>from Kolkata</span></h2>
                                            <h4>Lorem Ipsum is simply dummy text</h4>
                                        </div>
                                    </div>
                                </div>
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Packages</th>
                                            <th>Duration</th>
                                            <th>Min-Guest</th>
                                            <th>Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>LADAKH TOUR 6N/7D</td>
                                            <td>6N/7D</td>
                                            <td>3-5</td>
                                            <td>22,500</td>
                                        </tr>
                                        <tr>
                                            <td>LADAKH TOUR 7N/8D</td>
                                            <td>7N/8D</td>
                                            <td>6-9</td>
                                            <td>20,300</td>
                                        </tr>
                                        <tr>
                                            <td>LADAKH TOUR 9N/10D</td>
                                            <td>9N/10D</td>
                                            <td>Greater than 10</td>
                                            <td>22,000</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>Frequently Ask <span>Questions</span></h2>
                                            <h4>Lorem Ipsum is simply dummy text</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="accordion accordion-flush faQStyle" id="accordionFlushExample">
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="flush-headingOne">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                                    Accordion Item #1
                                                </button>
                                            </h2>
                                            <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> className. This is the first item's accordion body.</div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="flush-headingTwo">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                                    Accordion Item #2
                                                </button>
                                            </h2>
                                            <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> className. This is the second item's accordion body. Let's imagine this being filled with some actual content.</div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="flush-headingThree">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                                    Accordion Item #3
                                                </button>
                                            </h2>
                                            <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> className. This is the third item's accordion body. Nothing more exciting happening here in terms of content, but just filling up the space to make it look, at least at first glance, a bit more representative of how this would look in a real-world application.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container-fluid py-5">

                                <HotPackageTour
                                    slidesToShow={this.props.slidesToShow}
                                    hotPackagesDataList={this.props.hotPackagesDataList} />
                            </div>

                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>Leh Ladakh Tour<span> Packages Reviews</span></h2>
                                            <h4>Lorem Ipsum is simply dummy text</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-cols-1 row-cols-md-12 g-12">
                                    <div id="testimonials">
                                        <div className="testimonial-box-container">
                                            <div className="testimonial-box">
                                                <div className="box-top">
                                                    <div className="profile">
                                                        <div className="profile-img">
                                                            <img src="https://cdn3.iconfinder.com/data/icons/avatars-15/64/_Ninja-2-512.png"  alt="..."/>
                                                        </div>
                                                        <div className="name-user">
                                                            <strong>Liam mendes</strong>
                                                            <span>@liammendes</span>
                                                        </div>
                                                    </div>
                                                    <div className="reviews">
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                    </div>
                                                </div>
                                                <div className="client-comment">
                                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem, quaerat quis? Provident temporibus architecto asperiores nobis maiores nisi a. Quae doloribus ipsum aliquam tenetur voluptates incidunt blanditiis sed atque cumque.</p>
                                                </div>
                                            </div>
                                            <div className="testimonial-box">
                                                <div className="box-top">
                                                    <div className="profile">
                                                        <div className="profile-img">
                                                            <img src="https://cdn3.iconfinder.com/data/icons/avatars-15/64/_Ninja-2-512.png" alt="..." />
                                                        </div>
                                                        <div className="name-user">
                                                            <strong>Noah Wood</strong>
                                                            <span>@noahwood</span>
                                                        </div>
                                                    </div>
                                                    <div className="reviews">
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                    </div>
                                                </div>
                                                <div className="client-comment">
                                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem, quaerat quis? Provident temporibus architecto asperiores nobis maiores nisi a. Quae doloribus ipsum aliquam tenetur voluptates incidunt blanditiis sed atque cumque.</p>
                                                </div>
                                            </div>
                                            <div className="testimonial-box">
                                                <div className="box-top">
                                                    <div className="profile">
                                                        <div className="profile-img">
                                                            <img src="https://cdn3.iconfinder.com/data/icons/avatars-15/64/_Ninja-2-512.png" alt="..." />
                                                        </div>
                                                        <div className="name-user">
                                                            <strong>Oliver Queen</strong>
                                                            <span>@oliverqueen</span>
                                                        </div>
                                                    </div>
                                                    <div className="reviews">
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                    </div>
                                                </div>
                                                <div className="client-comment">
                                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem, quaerat quis? Provident temporibus architecto asperiores nobis maiores nisi a. Quae doloribus ipsum aliquam tenetur voluptates incidunt blanditiis sed atque cumque.</p>
                                                </div>
                                            </div>
                                            <div className="testimonial-box">
                                                <div className="box-top">
                                                    <div className="profile">
                                                        <div className="profile-img">
                                                            <img src="https://cdn3.iconfinder.com/data/icons/avatars-15/64/_Ninja-2-512.png" alt="..." />
                                                        </div>
                                                        <div className="name-user">
                                                            <strong>Barry Allen</strong>
                                                            <span>@barryallen</span>
                                                        </div>
                                                    </div>
                                                    <div className="reviews">
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                    </div>
                                                </div>
                                                <div className="client-comment">
                                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem, quaerat quis? Provident temporibus architecto asperiores nobis maiores nisi a. Quae doloribus ipsum aliquam tenetur voluptates incidunt blanditiis sed atque cumque.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-3">
                            <BookingBox />
                        </div>

                    </div>
                </div>
            </>
        );
    }
}

export default AdventureTourLadakhToSikkim;