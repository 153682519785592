import React, { Component } from 'react';
import BookingBox from '../BookingBox';
import Slider from "react-slick";
import ApiURL from "../../../api/ApiURL";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InclusionExclusion from '../Kashmir/InclusionExclusion_leh_kargil';
// import FloatingWhatsAppButton from 'react-floating-whatsapp';

class Kashmir_Leh_Ladakh_Tour_Package extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customActiveTab: 'tab1',
            daywiseActiveTab: 'day1'
        }
    }

    handleCustomTabClick = (tab) => {
        this.setState({
            customActiveTab: tab,
        });
    };

    handleDayWiseTabClick = (tab) => {
        this.setState({
            daywiseActiveTab: tab,
        });
    };
    handleClick = () => {
        const currentUrl = window.location.href;
        const pageTitle = document.title;
        const message = `Hi, Please send about the Tour Package details "${pageTitle}"`;

        const whatsappLink = `https://api.whatsapp.com/send?phone=+919163676037&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
        window.open(whatsappLink, '_blank');
    };

    render() {
        const { customActiveTab, daywiseActiveTab } = this.state;
        const packageTopBannerImageURL = ApiURL.Kashmir_Leh_Ladakh_Tour_PackageImageURL + "/Leh-Ladakh-Kashmir-Tour-Packages.webp";
        const packageTopBanner2ImageURL = ApiURL.Kashmir_Leh_Ladakh_Tour_PackageImageURL + "/Kashmir-Ladakh-Package-Tour.webp";
        const navDay1ImageURL = ApiURL.Kashmir_Leh_Ladakh_Tour_PackageImageURL + "/Kashmir-Leh-Ladakh.webp";
        const navDay2ImageURL = ApiURL.Kashmir_Leh_Ladakh_Tour_PackageImageURL + "/Kashmir-Ladakh-Family-Tour-Plan.webp";
        const navDay3ImageURL = ApiURL.Kashmir_Leh_Ladakh_Tour_PackageImageURL + "/Kashmir-Pahalgam-Ladakh-Kargil-Pangong.webp";
        const navDay4ImageURL = ApiURL.Kashmir_Leh_Ladakh_Tour_PackageImageURL + "/Kashmir-Ladakh-Tour-from-Srinagar-Airport.webp";
        const navDay5ImageURL = ApiURL.Kashmir_Leh_Ladakh_Tour_PackageImageURL + "/Leh-Ladakh-Kashmir-Tour-Cost.webp";
        const navDay6ImageURL = ApiURL.Kashmir_Leh_Ladakh_Tour_PackageImageURL + "/Kashmir-Leh-Ladakh-Tour-Cost.webp";
        const navDay7ImageURL = ApiURL.Kashmir_Leh_Ladakh_Tour_PackageImageURL + "/Kashmir-Leh-Tour.webp";
        const navDay8ImageURL = ApiURL.Kashmir_Leh_Ladakh_Tour_PackageImageURL + "/Jammu-Kashmir-Nubra-Pangong.webp";
        const navDay9ImageURL = ApiURL.Kashmir_Leh_Ladakh_Tour_PackageImageURL + "/Best-Ladakh-Kashmir-Tour-Plan.webp";
        const navDay10ImageURL = ApiURL.Kashmir_Leh_Ladakh_Tour_PackageImageURL + "/Kashmir-Kargil-Ladakh-Packages.webp";
        const TopbannerSXRImageURL = ApiURL.Kashmir_Leh_Ladakh_Tour_PackageImageURL + "/Kashmir-Srinagar-Package-Tour-from-Airport.webp";
        const TopbannerLEHImageURL = ApiURL.Kashmir_Leh_Ladakh_Tour_PackageImageURL + "/Leh-Ladakh-Airport.webp";
        const Topbanner0ImageURL = ApiURL.Kashmir_Leh_Ladakh_Tour_PackageImageURL + "/Kashmir-Houseboat-Packages.webp";
        const Topbanner1ImageURL = ApiURL.Kashmir_Leh_Ladakh_Tour_PackageImageURL + "/Kashmir-Adventure-Tour-Plan.webp";
        const Topbanner2ImageURL = ApiURL.Kashmir_Leh_Ladakh_Tour_PackageImageURL + "/Kashmir-Gondola-Package-Tour.webp";
        const Topbanner3ImageURL = ApiURL.Kashmir_Leh_Ladakh_Tour_PackageImageURL + "/Ladakh-Camel-Ride.webp";
        const Topbanner4ImageURL = ApiURL.Kashmir_Leh_Ladakh_Tour_PackageImageURL + "/Kashmir-White-River-Rafting-Plan.webp";
        const Topbanner5ImageURL = ApiURL.Kashmir_Leh_Ladakh_Tour_PackageImageURL + "/Leh-Ladakh-Quad-Biking.webp";
        const brandedTourackageImage_1_URL = ApiURL.Kashmir_Leh_Ladakh_Tour_PackageImageURL + "/The-Khyber-Himalayan-Resort-&-Spa.webp";
        const brandedTourackageImage_2_URL = ApiURL.Kashmir_Leh_Ladakh_Tour_PackageImageURL + "/The-LaLiT-Grand-Palace-Srinagar-Hotel.webp";
        const brandedTourackageImage_3_URL = ApiURL.Kashmir_Leh_Ladakh_Tour_PackageImageURL + "/The-Grand-Dragon-Leh.webp";
        const brandedTourackageImage_4_URL = ApiURL.Kashmir_Leh_Ladakh_Tour_PackageImageURL + "/The-Chospa-Leh-Hotel.webp";
        const TrendingPackagesDataList = this.props.TrendingPackagesData;
        const hotPackagesView = TrendingPackagesDataList.length > 0 ?
            (
                TrendingPackagesDataList.map((dataItem, i) => {
                    const hotelImgURL = ApiURL.Kashmir_Leh_Ladakh_Tour_PackageImageURL + "/" + dataItem.ImageName;
                    return (<div className="col-md-12 p-0" key={i}>
                        <div className="card h-100">
                            <img src={hotelImgURL} className="card-img-top" alt={dataItem.AllternateText} />
                            <div className="card-body">
                                <h6 className="card-title">{dataItem.Title} <span> &#8377; {dataItem.PricePerNight}</span></h6>
                                <p className="card-text">
                                    <i className="fa fa-building tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-utensils tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-car-side tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-male tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-camera tomato"></i>
                                </p>
                                <p className="card-text"><span>{dataItem.Description}</span></p>
                                <p>
                                    <span><b className="tomato">{dataItem.NoOfTours} </b>Tours</span> | &nbsp;
                                    <span><b className="tomato">{dataItem.NoOfDeparture} </b>Departures</span> | &nbsp;
                                    <span><b className="sdf-blue"> {dataItem.Include}</b></span>
                                </p>
                            </div>
                            <div className="card-footer">
                                <small className="text-muted"><strong className="tomato">{dataItem.MutedText}</strong></small>
                                <a href="#" className="btn btn-success btn-sm float-end" onClick={this.handleClick}>
                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                </a>
                            </div>
                        </div>
                    </div>)
                })
            ) : (<div></div>)


        const settings = {
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const reviewersDataList = this.props.ReviewersData;
        const reviewersDataView = reviewersDataList.map((dataItem, i) => {
            const reviewerImageURL = ApiURL.Kashmir_Leh_Ladakh_Tour_PackageImageURL + "/" + dataItem.ImageName;
            return (
                <div className="testimonial-box" key={i}>
                    <div className="box-top">
                        <div className="profile">
                            <div className="profile-img">
                                <img src={reviewerImageURL} alt="..." />
                            </div>
                            <div className="name-user">
                                <strong>{dataItem.Name}</strong>
                                <span>{dataItem.Location}</span>
                            </div>
                        </div>
                        <div className="reviews">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </div>
                    </div>
                    <div className="client-comment">
                        <p>{dataItem.Comment}</p>
                    </div>
                </div>
            )
        })

        return (
            <>
                <div className="container-fluid">
                    <div className="row">
                        <img src={packageTopBannerImageURL} className="mobile-image2" alt="..." />
                    </div>
                </div>
                <div className="container-fluid custom-container py-3">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="container-fluid custom-container">

                                        <h1 className="content-desktop" style={{ fontSize: "2vw" }}>Looking for <b>KASHMIR LADAKH PACKAGE TOUR </b>- Srinagar Airport (SXR)</h1>

                                        <h1 className="content-mobile" style={{ fontSize: "4vw" }}><center>Looking for <b>KASHMIR LADAKH PACKAGE TOUR </b>- Srinagar Airport (SXR)</center>
                                            <hr />
                                        </h1>
                                      
                                            <div className="row">
                                                <img src={packageTopBanner2ImageURL} className="mobile-image" alt="..." />
                                            </div>
                                        
                                        <hr />
                                        <div className="col-12">
                                            <big className="text-muted"><strong className="tomato">9N/10D </strong></big>&nbsp;
                                            <i className="fa fa-plane-departure"></i>&nbsp;&nbsp;
                                            <i className="fa fa-arrow-right"></i>&nbsp;Srinagar(2N)&nbsp;
                                            <i className="fa fa-arrow-right"></i>&nbsp;Gulmarg(1N)&nbsp;
                                            <i className="fa fa-arrow-right"></i>&nbsp;Pahalgam(1N)&nbsp;
                                            <i className="fa fa-arrow-right"></i>&nbsp;Kargil (1N)&nbsp;
                                            <i className="fa fa-arrow-right"></i>&nbsp;Leh(2N)&nbsp;
                                            <i className="fa fa-arrow-right"></i>&nbsp;Nubra Valley(1N)&nbsp;
                                            <i className="fa fa-arrow-right"></i>&nbsp;Pangong Lake(1N)&nbsp;
                                            <i className="fa fa-arrow-right"></i>&nbsp;&nbsp;
                                            <i className="fa fa-plane-departure fa-flip-horizontal"></i>
                                            <br />
                                        </div>
                                        <hr />
                                    </div>
                                    <h2 className="content-desktop p-4" style={{ fontSize: "1.5vw" }}> For Booking <b>LEH LADAKH KASHMIR PACKAGE</b> <a href='tel:9163676037' class="tomato">☎ CALL Mr. Akash Dutta  +91- 91636-76037</a></h2>
                                    <h2 className="content-mobile p-4" style={{ fontSize: "4.5vw" }}> For Booking <b>LEH LADAKH KASHMIR PACKAGE</b> <a href='tel:9163676037' class="tomato">☎ CALL Mr. Akash Dutta  +91- 91636-76037</a></h2>
                                    <div className="card">
                                        <div className="card-header custHeaer">
                                            <h4 className='content-desktop' style={{ fontSize: "1.5vw" }}>Top 6 <b>KASHMIR LEH LADAKH TOUR</b> Adventure Activities </h4>
                                            <h4 className='content-mobile2' style={{ fontSize: "3.5vw" }}>Top 6 <b>KASHMIR LEH LADAKH TOUR</b> Adventure Activities </h4>

                                        </div>
                                        <div className="card-body">
                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>Shikara Rides:</b><b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Shikara Rides:</b> Experience the tranquility of Dal Lake with a leisurely Shikara ride. Glide across the serene waters, passing by floating gardens, houseboats, and vibrant markets. Sunset and early morning rides offer particularly magical views.</p>
                                            <center> <img src={Topbanner0ImageURL} className="img-fluid" alt="..." /></center><br />

                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>Skiing and Snowboarding:</b>
                                                <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Skiing and Snowboarding:</b> During the winter months, places like Gulmarg transform into ski resorts, attracting enthusiasts from around the world. Gulmarg offers excellent slopes for skiing, snowboarding, and heli-skiing, with breathtaking views of the surrounding Himalayan peaks.</p>
                                            <center> <img src={Topbanner1ImageURL} className="img-fluid" alt="..." /></center><br />

                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>Gondola Ride: </b>
                                                <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Gondola Ride: </b>
                                                Take a ride on the Gulmarg Gondola, one of the highest cable cars in the world. Enjoy panoramic views of the snow-capped peaks as you ascend to Kongdori or Apharwat Peak, where you can indulge in activities like hiking and mountain biking.</p>
                                            <center> <img src={Topbanner2ImageURL} className="img-fluid" alt="..." /></center><br />

                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>Camel Ride :</b><b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Camel Ride :</b></p>
                                                <p><b>Where:</b> Diskit and Hunder valley</p>
                                                <p><b>Price:</b> INR 750 to 1400</p>
                                                <p><b>Best Time:</b> Anytime</p>
                                                <center> <img src={Topbanner3ImageURL} className="img-fluid" alt="..." /></center>
                                                <p>
                                                    Camel safari will be truly outstanding and remarkable encounters in Leh Ladakh trip! This is one of the silver linings in the Ladakh adventure sports zone. Riding on a twofold bumped camel and scaling the delightful scenes of the high elevation desert is rapidly increasing the notoriety of being perhaps the best activity in Ladakh. For the vacationers who are in for some crude country experience of Ladakh, these safaris fill in as a brilliant medium as riding on these camels feels a great deal like a wanderer meandering in the deserts. With a conveying limit of a few grown-ups at once, the prestigious camel safaris in Hunder are viewed as among the best on the planet.
                                                </p><br />
                                                <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>Quad Biking :</b><b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Quad Biking :</b> </p>
                                                <b>Where: Magnetic Hill, Nubra Valley</b>

                                                <center> <img src={Topbanner5ImageURL} className="img-fluid" alt="..." /></center>

                                                <p>Perfect for all thrill-seekers, Quad biking in Ladakh is one of the favorite adventure sports of the locals as well as tourists. Explore Ladakh on ATV on which you have to control the steering while you make your way through the crisscross roads. Quad biking is an out of the world experience for all those who crave thrill and want to satiate their lust for all things wild and challenging. Driving is a passion for many people and to take to the next level you must try driving and controlling an ATV.</p><br />
                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>River Rafting:</b>
                                                <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>River Rafting:</b> The Lidder River in Pahalgam offers exciting opportunities for white-water rafting. Experience the thrill of navigating through rapids while enjoying the scenic beauty of the surrounding pine forests and mountains.</p>
                                            <center> <img src={Topbanner4ImageURL} className="img-fluid" alt="..." /></center>
                                            <br />
                                            <a href="tel://9674738480" className="btn btn-Warning">Call Now</a>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="container-fluid navtabCustom">
                                        <nav>
                                            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                                <button onClick={() => this.handleCustomTabClick('tab1')} className={`tab button ${customActiveTab === 'tab1' ? 'active' : ''}`} role="tab" aria-controls="nav-highlights" aria-selected={customActiveTab === 'tab1'}>Highlights</button>
                                                <button onClick={() => this.handleCustomTabClick('tab2')} className={`tab button ${customActiveTab === 'tab2' ? 'active' : ''}`} role="tab" aria-controls="nav-flights" aria-selected={customActiveTab === 'tab2'}>Flights</button>
                                                <button onClick={() => this.handleCustomTabClick('tab3')} className={`tab button ${customActiveTab === 'tab3' ? 'active' : ''}`} role="tab" aria-controls="nav-hotel" aria-selected={customActiveTab === 'tab3'}>Hotel</button>
                                                <button onClick={() => this.handleCustomTabClick('tab4')} className={`tab button ${customActiveTab === 'tab4' ? 'active' : ''}`} role="tab" aria-controls="nav-sightseeing" aria-selected={customActiveTab === 'tab4'}>Sightseeing</button>
                                                <button onClick={() => this.handleCustomTabClick('tab5')} className={`tab button ${customActiveTab === 'tab5' ? 'active' : ''}`} role="tab" aria-controls="nav-meals" aria-selected={customActiveTab === 'tab5'}>Meals</button>
                                            </div>
                                        </nav>
                                        <div className="tab-content" id="nav-tabContent">
                                            <div className={customActiveTab === 'tab1' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-highlights-tab">
                                                <p>A <b>Kashmir Ladakh Tour</b> is a captivating journey through two distinct regions of India, each offering unique landscapes, cultures, and experiences. Also <b>Kashmir Ladakh Package</b> offers a blend of natural beauty, adventure, and cultural richness, making it a truly unforgettable experience. Customize your itinerary based on your interests and the duration of your trip to make the most of this enchanting region. Based on geography & weather best time for <b>Leh Ladakh Kashmir Tour Plan</b> is - (May to September) are ideal. When the weather is absolutely pleasant and most roads are accessible. Don’t forget to Carry warm clothing, sunscreen, medications, and plenty of water due to the high altitude and arid climate of Ladakh. During <b>Leh Ladakh Kashmir Family Holidays</b> must taste Kashmiri and Ladakhi cuisine. Don't miss out on Rogan Josh, Yakhni, Momos, and Butter Tea. The journey between Kashmir and Ladakh involves crossing mountain passes and can take several days. The roads can be challenging, so plan accordingly. 9N 10D is quite enough to cover <b>Kashmir Kargil Leh Tour</b> completely.</p>
                                            </div>
                                            <div className={customActiveTab === 'tab2' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-flights-tab">
                                                <div className="overflow-table">
                                                    <span>Best Srinagar Non-Stop Flight connection from your city<b> <i>(SXR)</i></b></span><br /><br />
                                                    <table className="table table-bordered">
                                                        <tr className="table-danger">
                                                            <th>FLIGHT</th>
                                                            <th>AIRLINES</th>
                                                            <th>AIRPORT</th>
                                                            <th>TIME</th>
                                                            <th>DAY</th>
                                                        </tr>
                                                        <tr>
                                                            <td>SG-509</td>
                                                            <td>SpiceJet</td>
                                                            <td>Mumbai</td>
                                                            <td>14:45</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>QP-1637</td>
                                                            <td>Akasa Air</td>
                                                            <td>Mumbai</td>
                                                            <td>5:35</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>63-266</td>
                                                            <td>IndiGo</td>
                                                            <td>Mumbai</td>
                                                            <td>06:45</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>SG-8913</td>
                                                            <td>SpiceJet</td>
                                                            <td>Delhi</td>
                                                            <td>08:00</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>SG-8373</td>
                                                            <td>SpiceJet</td>
                                                            <td>Delhi</td>
                                                            <td>09:45</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>6E-6947</td>
                                                            <td>IndiGo</td>
                                                            <td>Bengaluru</td>
                                                            <td>13:45</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>6E6253</td>
                                                            <td>IndiGo</td>
                                                            <td>Hyderabad</td>
                                                            <td>14:50</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>6E6945</td>
                                                            <td>IndiGo</td>
                                                            <td>Ahmedabad</td>
                                                            <td>06:55</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>6E6961</td>
                                                            <td>IndiGo</td>
                                                            <td>Kolkata</td>
                                                            <td>14:45</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className={customActiveTab === 'tab3' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-hotel-tab">

                                                <div className="overflow-table">
                                                    <table className="table table-bordered">
                                                        <tr className="text-center">
                                                            {/* <!-- Add the "text-center" className to center content --> */}
                                                            <th colspan="6" style={{ fontSize: "1.3vw" }}><h6><b>3 STAR - CERTIFIED BY 'ADORABLE' </b></h6></th>
                                                        </tr>
                                                        <tr className="table-danger">
                                                            <th className="text-center">Destination</th>
                                                            <th className="text-center">Hotel Names</th>
                                                            <th className="text-center">Hotel Type</th>
                                                            <th className="text-center">No of Nights</th>
                                                            <th className="text-center">Meal Plan</th>
                                                        </tr>
                                                        <tr>
                                                            <td><div style={{ display: 'inline-block' }}>Srinagar</div></td>
                                                            <td><div style={{ display: 'inline-block' }}>Curio's / City Grace / Hilltown</div></td>
                                                            <td>3 Star
                                                            </td>
                                                            <td><div style={{ display: 'inline-block' }}>2N</div></td>
                                                            <td><div style={{ display: 'inline-block' }}>Room with Breakfast & Dinner</div></td>
                                                        </tr>
                                                        <tr>
                                                            <td><div style={{ display: 'inline-block' }}>Gulmarg</div></td>
                                                            <td><div style={{ display: 'inline-block' }}>Mount View / Grand Jigar / Gulmarg House
                                                            </div>
                                                            </td>
                                                            <td>3 Star
                                                            </td>
                                                            <td><div style={{ display: 'inline-block' }}>1N</div></td>
                                                            <td><div style={{ display: 'inline-block' }}>Room with Breakfast & Dinner</div></td>
                                                        </tr>
                                                        <tr>
                                                            <td><div style={{ display: 'inline-block' }}>Pahalgam</div></td>
                                                            <td><div style={{ display: 'inline-block' }}>Valley Resort / Paradise Inn / Royal Spring
                                                            </div>
                                                            </td>
                                                            <td>3 Star
                                                            </td>
                                                            <td><div style={{ display: 'inline-block' }}>1N</div></td>
                                                            <td><div style={{ display: 'inline-block' }}>Room with Breakfast & Dinner</div></td>
                                                        </tr>
                                                        <tr>
                                                            <td><div style={{ display: 'inline-block' }}>Kargil</div></td>
                                                            <td><div style={{ display: 'inline-block' }}>Royal Kargil / Royal Gasho / Zojila Residency
                                                            </div>
                                                            </td>
                                                            <td>3 Star
                                                            </td>
                                                            <td><div style={{ display: 'inline-block' }}>1N</div></td>
                                                            <td><div style={{ display: 'inline-block' }}>Room with Breakfast & Dinner</div></td>
                                                        </tr>
                                                        <tr>
                                                            <td><div style={{ display: 'inline-block' }}>Leh</div></td>
                                                            <td><div style={{ display: 'inline-block' }}>Nalanda / Maitreya / Imperial Leh
                                                            </div>
                                                            </td>
                                                            <td>3 Star
                                                            </td>
                                                            <td><div style={{ display: 'inline-block' }}>2N</div></td>
                                                            <td><div style={{ display: 'inline-block' }}>Room with Breakfast & Dinner</div></td>
                                                        </tr>
                                                        <tr>
                                                            <td><div style={{ display: 'inline-block' }}>Nubra Valley</div></td>
                                                            <td><div style={{ display: 'inline-block' }}>Snow Leopard / Himalayan Eco Resort / Sangaylay Palace
                                                            </div>
                                                            </td>
                                                            <td>3 Star
                                                            </td>
                                                            <td><div style={{ display: 'inline-block' }}>1N</div></td>
                                                            <td><div style={{ display: 'inline-block' }}>Room with Breakfast & Dinner</div></td>
                                                        </tr>
                                                        <tr>
                                                            <td><div style={{ display: 'inline-block' }}>Pangong Lake</div></td>
                                                            <td><div style={{ display: 'inline-block' }}>Mystic Mountain Cottage / Ripple Cottage / Bliss Cottage
                                                            </div>
                                                            </td>
                                                            <td>3 Star
                                                            </td>
                                                            <td><div style={{ display: 'inline-block' }}>1N</div></td>
                                                            <td><div style={{ display: 'inline-block' }}>Room with Breakfast & Dinner</div></td>
                                                        </tr>
                                                    </table>

                                                    <table class="table table-bordered">
                                                        <tr className="text-center">
                                                            {/*  <!-- Add the "text-center" className to center content --> */}
                                                            <th colspan="6" style={{ fontSize: "1.3vw" }}><h6 className='content-desktop'><center>Your <b>Jammu Kashmir Ladakh Kargil Package Price</b> 9N 10D – with <div style={{ display: 'inline-block', color: 'gold' }}>
                                                                <i className="fa fa-star"></i>
                                                                <i className="fa fa-star"></i>
                                                                <i className="fa fa-star"></i>
                                                            </div> Star Accommodation </center></h6>
                                                                <h6 className='content-mobile2'><center>Your <b>Jammu Kashmir Ladakh Kargil Package Price</b> 9N 10D – with <div style={{ display: 'inline-block', color: 'gold' }}>
                                                                    <i className="fa fa-star"></i>
                                                                    <i className="fa fa-star"></i>
                                                                    <i className="fa fa-star"></i>
                                                                </div> Star Accommodation </center></h6></th>
                                                        </tr>
                                                        <tr className="table-danger">
                                                            <th class="text-center">No of Pax</th>
                                                            <th class="text-center">Package Price (Including GST.)</th>
                                                            <th class="text-center">Transport</th>
                                                            <th class="text-center">Contact Us</th>
                                                        </tr>

                                                        <tbody>
                                                            <tr>
                                                                <td class="text-center">
                                                                    04 Adults
                                                                </td>
                                                                <td class="text-center">
                                                                    36,199 /- (Per Person)
                                                                </td>
                                                                <td class="text-center">Luxury SEDAN</td>
                                                                <td class="text-center"><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick}>
                                                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                                                </a></td>
                                                            </tr>
                                                            <tr>
                                                                <td class="text-center">
                                                                    06 Adults
                                                                </td>
                                                                <td class="text-center">
                                                                    32,699/- (Per Person)
                                                                </td>
                                                                <td class="text-center">Luxury SUV</td>
                                                                <td class="text-center"><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick}>
                                                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                                                </a></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <br />
                                            </div>
                                            <div className={customActiveTab === 'tab4' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-sightseeing-tab">
                                                <div className="container-fluid px-0 py-1">
                                                    <h3 className='content-desktop' style={{ fontSize: "1.3vw" }}>Our <b>Kashmir Ladakh Sightseeing,</b> Daywise</h3>
                                                    <h3 style={{ fontSize: "3.5vw" }} className='content-mobile2'>Our <b>Kashmir Ladakh Sightseeing,</b> Daywise</h3>
                                                    <p><b style={{ fontSize: "1.3vw" }} className='content-desktop'>Dal Lake:</b>
                                                        <b style={{ fontSize: "3.5vw" }} className='content-mobile2'>Dal Lake:</b> This iconic lake in Srinagar is famous for its picturesque surroundings, houseboats, and Shikara rides. Visitors can explore the floating gardens, old Mughal gardens along the shores, and enjoy the stunning views of the surrounding mountains.</p>
                                                    <p><b className='tomato content-desktop' style={{ fontSize: "1.3vw" }}>Gulmarg:</b>
                                                        <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Gulmarg:</b> Known for its lush green meadows, Gulmarg is a popular hill station and skiing destination. Visitors can take the Gulmarg Gondola, one of the highest cable cars in the world, to reach Kongdori and enjoy panoramic views of the Himalayas.</p>
                                                    <p><b className='content-desktop' style={{ fontSize: "1.3vw" }}>Sonamarg:</b>
                                                        <b className='content-mobile2' style={{ fontSize: "3.5vw" }}>Sonamarg:</b>  Translating to "Meadow of Gold," Sonamarg is a scenic valley situated at an altitude of 2800 meters. It offers breathtaking views of snow-capped peaks, glaciers, and meadows. It's also the base for various trekking routes.</p>
                                                    <p><b className='tomato content-desktop' style={{ fontSize: "1.3vw" }}>Pahalgam:</b>
                                                        <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Pahalgam:</b> This quaint town is renowned for its pristine beauty, lush greenery, and the Lidder River. Pahalgam serves as a starting point for the annual Amarnath Yatra pilgrimage and offers opportunities for trekking, horse riding, and fishing.</p>
                                                    <p><b>Kargil War Memorial (Dras War Memorial)</b></p>
                                                    <p><b className='content-desktop' style={{ fontSize: "1.3vw" }}>Description:</b>
                                                        <b className='content-mobile2' style={{ fontSize: "3.5vw" }}>Description:</b>  Pay tribute to the Indian soldiers who sacrificed their lives during the Kargil War in 1999. The memorial, located in Dras (about 56 km from Kargil), commemorates the martyrs with exhibits, a memorial wall, and a peaceful ambiance.</p>
                                                    <p><b className='tomato content-desktop' style={{ fontSize: "1.3vw" }}>Nubra Valley :</b>
                                                        <b className='content-mobile2' style={{ fontSize: "3.5vw" }}>Nubra Valley :</b>  Known for its stark desert landscapes, towering sand dunes, and lush green oasis villages, Nubra Valley is a must-visit destination in Ladakh. Highlights include the Diskit Monastery, Hunder Sand Dunes, and the scenic drive through Khardung La Pass, one of the highest motorable passes in the world.</p>
                                                    <p><b className='content-desktop' style={{ fontSize: "1.3vw" }}>Pangong Lake :</b>
                                                        <b className='content-mobile2' style={{ fontSize: "3.5vw" }}>Pangong Lake :</b>  This iconic lake, situated at an altitude of 4,350 meters, is famous for its mesmerizing shades of blue and breathtaking scenery. A visit to Pangong Lake, especially during sunrise or sunset, offers a truly unforgettable experience.</p>
                                                    <p><b className='tomato content-desktop' style={{ fontSize: "1.3vw" }}>Khardung La Pass :</b>
                                                        <b className='content-mobile2' style={{ fontSize: "3.5vw" }}>Khardung La Pass :</b> Journey to one of the world's highest motorable passes, Khardung La Pass, located at an altitude of over 5,300 meters. Enjoy panoramic views of the snow-capped Himalayas, rugged terrain, and dramatic landscapes en route to the Nubra Valley.</p>
                                                    <p><b className='content-desktop' style={{ fontSize: "1.3vw" }}>Lamayuru Monastery :</b>
                                                        <b className='content-mobile2' style={{ fontSize: "3.5vw" }}>Lamayuru Monastery :</b> This centuries-old monastery, perched a top a hill overlooking the village of Lamayuru, is one of the oldest and most picturesque monasteries in Ladakh. Explore its labyrinthine corridors, visit the cave temples, and soak in the mystical atmosphere.</p>

                                                </div>
                                            </div>
                                            <div className={customActiveTab === 'tab5' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-meals-tab">
                                                <div className="overflow-table">
                                                    <h5 class="tomato">9 times Meals and 9 times Breakfast has been Provided</h5>
                                                    <table className="table table-bordered">
                                                        <tr className="table-danger">
                                                            <th colspan="6" className="text-center">Category</th>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-center">JAIN</td>
                                                            <td class="text-center">PURE-VEG</td>
                                                            <td class="text-center">VEGAN</td>
                                                            <td class="text-center">PURE-NON-VEG</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header custHeaer">
                                    <h3 className='content-desktop' style={{ fontSize: "1.5vw" }}>Easy Arrival for <b>Kashmir Jammu Ladakh Packages</b> Tour</h3>
                                    <h3 className='content-mobile2' style={{ fontSize: "3.5vw" }}>Easy Arrival for <b>Kashmir Jammu Ladakh Packages</b> Tour</h3>

                                </div>

                                <div className="card-body">
                                    <p>Reaching for <b>Kashmir Holiday Plan</b> largely depends on your starting point, whether it's within India or from an international location. Here are several common ways to reach Kashmir:</p>

                                    <ul>
                                        <li><b>Srinagar Airport (SXR):</b> This is the main airport serving for Kashmir Tourism. It has regular flights connecting it to major cities in India such as Delhi, Mumbai, Bangalore, Kolkata, and others. There are also occasional international flights, particularly from Middle Eastern countries.</li><br />
                                        <center> <img src={TopbannerSXRImageURL} className="img-fluid" alt="..." /></center><br />
                                        <li><b>Jammu Tawi Railway Station:</b> Jammu is the nearest major railway station to Kashmir. From Jammu, you can take a taxi, bus, or hire a private vehicle to reach Kashmir. Jammu is well-connected by trains to major cities like Delhi, Mumbai, Kolkata, and others.</li><br />
                                        <li><b>National Highway 44 (NH 44):</b> This is the primary road route connecting Kashmir with the rest of India. The journey involves passing through the Jawahar Tunnel, which connects the Jammu region with the Kashmir Valley. You can drive or hire a taxi/private vehicle to reach Kashmir via this route.</li><br />
                                        <li><b>Travel Documents:</b> Ensure you have all necessary travel documents, including any required visas or permits, especially if you're traveling from an international location.</li><br />
                                        <center> <img src={TopbannerLEHImageURL} className="img-fluid" alt="..." /></center><br />
                                        <li>The most convenient way to exit Ladakh is by air. The <b>Kushok Bakula Rimpochee Airport</b> in Leh is the main airport serving the region. Several airlines operate daily flights to Leh from major cities like Delhi, Mumbai,Kolkata and Srinagar.</li>
                                    </ul><br />
                                    <a href="tel://9674738480" className="btn btn-Warning">Call Now</a>
                                </div>
                            </div>
                            <br />
                            {/* <!--By Itinerary--> */}
                            <div className="container-fluid navtabCustom">

                                <h5><b>Daywise Kashmir Leh Ladakh Kargil 9N 10D Itinerary</b></h5>
                                <hr />

                                <nav>
                                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                        <button onClick={() => this.handleDayWiseTabClick('day1')} className={`tab button ${daywiseActiveTab === 'day1' ? 'active' : ''}`} role="tab" aria-controls="nav-day-one" aria-selected={daywiseActiveTab === 'day1'}>DAY-1</button>
                                        <button onClick={() => this.handleDayWiseTabClick('day2')} className={`tab button ${daywiseActiveTab === 'day2' ? 'active' : ''}`} role="tab" aria-controls="nav-day-two" aria-selected={daywiseActiveTab === 'day2'}>DAY-2</button>
                                        <button onClick={() => this.handleDayWiseTabClick('day3')} className={`tab button ${daywiseActiveTab === 'day3' ? 'active' : ''}`} role="tab" aria-controls="nav-day-three" aria-selected={daywiseActiveTab === 'day3'}>DAY-3</button>
                                        <button onClick={() => this.handleDayWiseTabClick('day4')} className={`tab button ${daywiseActiveTab === 'day4' ? 'active' : ''}`} role="tab" aria-controls="nav-day-four" aria-selected={daywiseActiveTab === 'day4'}>DAY-4</button>
                                        <button onClick={() => this.handleDayWiseTabClick('day5')} className={`tab button ${daywiseActiveTab === 'day5' ? 'active' : ''}`} role="tab" aria-controls="nav-day-five" aria-selected={daywiseActiveTab === 'day5'}>DAY-5</button>
                                        <button onClick={() => this.handleDayWiseTabClick('day6')} className={`tab button ${daywiseActiveTab === 'day6' ? 'active' : ''}`} role="tab" aria-controls="nav-day-six" aria-selected={daywiseActiveTab === 'day6'}>DAY-6</button>
                                        <button onClick={() => this.handleDayWiseTabClick('day7')} className={`tab button ${daywiseActiveTab === 'day7' ? 'active' : ''}`} role="tab" aria-controls="nav-day-seven" aria-selected={daywiseActiveTab === 'day7'}>DAY-7</button>
                                        <button onClick={() => this.handleDayWiseTabClick('day8')} className={`tab button ${daywiseActiveTab === 'day8' ? 'active' : ''}`} role="tab" aria-controls="nav-day-eight" aria-selected={daywiseActiveTab === 'day7'}>DAY-8</button>
                                        <button onClick={() => this.handleDayWiseTabClick('day9')} className={`tab button ${daywiseActiveTab === 'day9' ? 'active' : ''}`} role="tab" aria-controls="nav-day-nine" aria-selected={daywiseActiveTab === 'day9'}>DAY-9</button>
                                        <button onClick={() => this.handleDayWiseTabClick('day10')} className={`tab button ${daywiseActiveTab === 'day10' ? 'active' : ''}`} role="tab" aria-controls="nav-day-ten" aria-selected={daywiseActiveTab === 'day10'}>DAY-10</button>
                                    </div>
                                </nav>
                                <div className="tab-content" id="nav-tabContent">
                                    <div className={daywiseActiveTab === 'day1' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-one-tab">
                                        <img src={navDay1ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 class="tomato"> <b>Day 1 – Arrival in Srinagar</b></h5>
                                        <p><b>Once you reach Srinagar,</b> get transferred to a houseboat stationed at Dal Lake or Nagin Lake. Enjoy lunch on the houseboat. Later, leave to enjoy a Shikara boat ride on the lake, where you can witness the enthralling beauty of Char Chinar Island, Floating Gardens, and Floating Shops. Also, see the Kabutarkhana and Bathing Boats during the Shikara ride.
                                        </p>
                                        <b>Overnight stay at the houseboat.</b>
                                    </div>
                                    <div className={daywiseActiveTab === 'day2' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-two-tab">
                                        <img src={navDay2ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 class="tomato"><b>Day 2 - Srinagar – Gulmarg (50 km/1.5 hours)</b></h5>
                                        <p>
                                            Day 2 of your Ladakh Kashmir tour is booked for a day trip to Gulmarg. Upon your arrival in Gulmarg, you will have assisted check-in at your pre-booked hotel. You can rest for a while, and you are free to spend the day as per your liking. You can enjoy the majestic Himalayas' scenic beauty and partake in adventure activities like skiing; also, take a Gondola Cable ride. You can also opt for a pony ride from Gulmarg to Khilanmarg. Overall, there are plenty of opportunities to make this day an incredible day of your tour.</p>
                                        <br />
                                        <b>Overnight at the hotel in Gulmarg.</b>
                                    </div>
                                    <div className={daywiseActiveTab === 'day3' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-three-tab">
                                        <img src={navDay3ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 class="tomato"> <b>Day 3 – Gulmarg – Pahalgam (142 km/4 hours)</b></h5>
                                        <p>Post a refreshing breakfast in the morning, drive to the valley of Pahalgam on the banks of the River Lidder. En route, visit the Avantipur Ruins and Cricket Bat Factory. If time permits, you can see the temples dedicated to Lord Vishnu, approx. 1100 years old. By the afternoon, you will reach Pahalgam. Upon arriving, take some rest and then explore the scenic beauty of the Lidder River valley. The rest of the time is at your leisure. You can explore nearby places or opt for a pony ride to enjoy the lush scenery.
                                        </p>
                                        <b>Overnight stay in Pahalgam.</b>
                                    </div>
                                    <div className={daywiseActiveTab === 'day4' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-four-tab">
                                        <img src={navDay4ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 class="tomato"> <b>Day 4 – Pahalgam – Srinagar (90 km/2 hours)</b></h5>
                                        <p>
                                            On day 4 of your Kashmir and Ladakh tour, enjoy relaxing walks along the Lidder River to experience its mild lapping in the morning. After having breakfast, you can take a stroll to the beautiful spots or take a pony ride (at your own cost). The places which you should not miss here are Chandanwari, Aru Valley, and Betaab Valley.</p>
                                        <p> In the afternoon, drive to Srinagar. Upon reaching, check in at the hotel to relax and unwind. The evening is free for you; so if you want, you can also visit the city's local markets.</p>
                                        <b>Overnight at the hotel in Srinagar.</b>

                                    </div>
                                    <div className={daywiseActiveTab === 'day5' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-five-tab">
                                        <img src={navDay5ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 class="tomato"> <b>Day 5 – Srinagar - Sonamarg – Kargil (202 km/5 hours)</b></h5>
                                        <p>
                                            <b>After Breakfast,</b>
                                            This morning, proceed towards Kargil from Srinagar. En route, stop at the 'Valley of Meadows' Sonamarg for lunch and then cross the Zojila Pass, located 3900 meters above sea level. After driving for some time, reach the Drass Village, the second coldest inhabited place. Upon arriving there, you will get to Kargil town; check-in at the hotel/deluxe camp. The rest of the time is at your leisure. You can enjoy your evening at the bank of the Suru River or by taking a stroll in the market.
                                        </p>
                                        <b>Overnight stay in Kargil.</b>
                                    </div>
                                    <div className={daywiseActiveTab === 'day6' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-six-tab">
                                        <img src={navDay6ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 class="tomato"> <b>Day 6 – Kargil – Leh (217 km/ 5 hours)</b></h5>
                                        <p>
                                            Post breakfast, embarks on a drive to Leh from Kargil town. En route, visit the ancient rock-cut statue of Maitreya Buddha at Mulbekh village. Later, stop for a photo at lunar-like landscapes near Lamayuru Monastery. On the way, enjoy a mouth-tempting lunch and photo stop at the Sangam (confluence) of the Indus and Zanskar rivers. By the evening, arrive in Leh and check in at the hotel in Leh.</p>
                                        <b>Overnight stay at the hotel in Leh.</b>
                                    </div>
                                    <div className={daywiseActiveTab === 'day7' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-seven-tab">
                                        <img src={navDay7ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 class="tomato"> <b>Day 7 – Leh - Nubra Valley via Khardungla (160 km/4-5 hours)</b></h5>
                                        <p>
                                            On the 7th day of the Kashmir Ladakh tour, you will leave for Nubra Valley via Khardungla Pass, perched at 5359 meters above sea level. It is one of the world's highest motorable passes. So take a short break, click some pictures, and glare at the enchanting beauty of the valley from the mighty Khardungla Pass. In the afternoon, you will arrive at the Nubra Valley and then visit the dunes for the camel safari on a Bactrian camel or double-humped camel.</p>
                                        <b>Overnight stay at the deluxe camp or hotel in Nubra.</b>
                                    </div>
                                    <div className={daywiseActiveTab === 'day8' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-eight-tab">
                                        <img src={navDay8ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 class="tomato"> <b>Day 8 – Nubra Valley – Pangong via Shyok River (274 km/7 hours)</b></h5>
                                        <p>
                                            Post breakfast, visit the Diskit Monastery (Big Buddha) and spend some time in the serene environment of the monastery. You can glare at the beauty of the Nubra Valley from the top of the monastery. Then you will head towards Pangong Lake, located at 4350 meters above sea level. This is the world's highest saltwater lake. Upon your arrival, you will have an assisted check-in at the camp. The rest of the evening is free, and you can spend it as per your wish. Finally, you can take a stroll alongside the glistening lake.</p>
                                        <b>Overnight stay at the camp near Pangong Lake.</b>
                                    </div>
                                    <div className={daywiseActiveTab === 'day9' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-nine-tab">
                                        <img src={navDay9ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 class="tomato"> <b>Day 9 – Pangong Lake – Leh via Chang La Pass (223 km/6 hours)</b></h5>
                                        <p>
                                            Enjoy the picturesque sunrise view on the Pangong Lake and then leave for Leh after having breakfast. You will cross the mighty Chang La Pass on your way to Leh. En route, visit Thiksey and Hemis Monasteries. Upon your arrival in Leh, you will have an assisted check-in at the hotel. If your time and energy permit, you can explore the Leh market and buy some souvenirs for yourself and your loved ones.
                                            Return to the hotel in the evening and have a sumptuous dinner. </p>
                                        <b>Overnight stay at the hotel in Leh.</b>
                                    </div>
                                    <div className={daywiseActiveTab === 'day10' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-ten-tab">
                                        <img src={navDay10ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 class="tomato"> <b>Day 10 – Departure from Leh Airport</b></h5>
                                        <p>
                                            In the morning, get transferred to the Kushok Bakula airport to board a flight for your onward journey.</p>
                                    </div>
                                </div>
                            </div>
                            {/* <!--By Itinerary--> */}

                            {/* <!--insert table inclusion exclusion--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        {
                                            <InclusionExclusion />
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* <!--end table inclusion exclusion--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>The Khyber Himalayan Resort & Spa <span>- Gulmarg</span></h2>
                                            <h4><i>Next Level Luxury Resort </i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row py-3">
                                    <img src={brandedTourackageImage_1_URL} alt="..." />
                                </div>

                                <div id="no-more-tables">
                                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                        <thead>
                                            <tr className="table-danger">
                                                <th>Category</th>
                                                <th>Size(Room)</th>
                                                <th>Hotel Brand</th>
                                                <th>Price (Per Night)</th>
                                                <th>Book Now</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td data-title="Packages">Platinum Accommodation</td>
                                                <td data-title="Size(Room)">386+ SQ.FT</td>
                                                <td data-title="Hotel Brand">Khyber Hotels (Heritage)<div className="reviews">
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                </div></td>
                                                <td data-title="Price (Per Night)">Rs 53,119/- </td>
                                                <td data-title="Book Now"><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick}>
                                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                                </a></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                <div className="card mx-0 my-5">
                                    <div className="card-header custHeaer">
                                        <h3 className='content-desktop' style={{ fontSize: "1.5vw" }}><b>HEAVEN IS closer THAN YOU CAN IMAGINE</b></h3>
                                        <h3 className='content-mobile2' style={{ fontSize: "3.5vw" }}><b>HEAVEN IS closer THAN YOU CAN IMAGINE</b></h3>
                                    </div>
                                    <div className="card-body">
                                        <p><b>8,825 feet</b> above sea level in the Pir Panjal range of the Himalayas lies a higher order of luxury. <b>The Khyber Himalayan Resort & Spa</b> is spread over seven unspoilt, fir-laden acres, with majestic views of the Affarwat Peaks. This 85-room resort is framed at every vantage point by nature at its most spectacular. <b>The world’s highest ski lift</b>, the Gulmarg Gondola – which takes you to Kongdoori Mountain, is only a short walk away. Meadows and forests that have enchanted travellers for centuries wait to be explored by trekking enthusiasts and undying romantics. Clad in locally sourced pine and slate, the resort is built in a style true to the architectural heritage of Kashmir. In every way the indoors mirror the beauty of the outdoors. Specialty restaurants, a cigar lounge are amongst the necessary luxuries in place. Add to these other thoughtful additions like our children’s club, a mini theatre and a dedicated Activities Concierge and you will find The Khyber is a resort at par with the best in the world.</p>

                                        <a href="tel://9883359713" className="btn btn-Warning">Book Now</a>

                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>The LaLiT Grand Place <span>- Srinagar</span></h2>
                                            <h4><i>Fill the Luxury</i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row py-3">
                                    <img src={brandedTourackageImage_2_URL} alt="..." />
                                </div>

                                <div id="no-more-tables">
                                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                        <thead>
                                            <tr className="table-danger">
                                                <th>Category</th>
                                                <th>Size(Room)</th>
                                                <th>Hotel Brand</th>
                                                <th>Price (Per Night)</th>
                                                <th>Book Now</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td data-title="Packages">Premium Accommodation</td>
                                                <td data-title="Size(Room)">290+ SQ.FT</td>
                                                <td data-title="Hotel Brand">The LaLiT Hotels<div className="reviews">
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                </div></td>
                                                <td data-title="Price (Per Night)">Rs 28,332/- </td>
                                                <td data-title="Book Now"><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick}>
                                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                                </a></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                <div className="card mx-0 my-5">
                                    <div className="card-header custHeaer">
                                        <h3 className='content-desktop' style={{ fontSize: "1.5vw" }}><b>EMBARKED ON A SENSE OF PRIDE AT PREMIUMNESS</b></h3>
                                        <h3 className='content-mobile2' style={{ fontSize: "3.5vw" }}><b>EMBARKED ON A SENSE OF PRIDE AT PREMIUMNESS</b></h3>
                                    </div>
                                    <div className="card-body">
                                        <p><b>The LaLit Grand Palace</b> was originally built in 1901 for the royal family. It now provides luxurious rooms with views of Dal Lake and the beautiful gardens. An indoor pool, tennis courts and pampering spa treatments are also available.The tastefully designed rooms are modern with a touch of traditional charm. All have a satellite TV, minibar and free tea/coffee making facilities. It is 18 km from Srinagar Airport and 4 km from Lal Chowk city centre. Jammu Railway Station is 300 km away. Guests can enjoy a relaxing massage at Rejuve Spa Health Club, practice yoga, or play golf. The hotel also has a gym and 24-hour business center. The Chinar Garden serves North Indian cuisine, while international buffet spreads are available at The Chinar – The All Day Coffee Shop. Other dining options include Dal Bar and 24-hour room service.</p>

                                        <a href="tel://9883359713" className="btn btn-Warning">Book Now</a>

                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>The Grand Dragon <span>- Leh</span></h2>
                                            <h4><i>5 Star Hotel in Leh</i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row py-3">
                                    <img src={brandedTourackageImage_3_URL} alt="..." />
                                </div>

                                <div id="no-more-tables">
                                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                        <thead>
                                            <tr className="table-danger">
                                                <th>Category</th>
                                                <th>Size(Room)</th>
                                                <th>Hotel Brand</th>
                                                <th>Price (Per Night)</th>
                                                <th>Book Now</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td data-title="Packages">Luxury Accommodation</td>
                                                <td data-title="Size(Room)">320+ SQ.FT</td>
                                                <td data-title="Hotel Brand">Dragon<div className="reviews">
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                </div></td>
                                                <td data-title="Price (Per Night)">Rs 15,729/- </td>
                                                <td data-title="Book Now"><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick}>
                                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                                </a></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                <div className="card mx-0 my-5">
                                    <div className="card-header custHeaer">
                                        <h3 className='content-desktop' style={{ fontSize: "1.5vw" }}><b>Only Certified 5 Star Hotel in LEH</b></h3>
                                        <h3 className='content-mobile2' style={{ fontSize: "3.5vw" }}><b>Only Certified 5 Star Hotel in LEH</b></h3>
                                    </div>
                                    <div className="card-body">
                                        <p>Located at a height of 11,562 ft above sea level in the Himalayas, <b>The Grand Dragon Ladakh</b> in Leh is the region’s only certified 5-Star hotel. Open all year round, this indigenously designed property with 76 rooms including family suites, offers all modern comforts and conveniences with stunning views of the picturesque landscape, the vivid colours of an ever-changing sky and the lofty Himalayan peaks. An ideal base from where visitors to Ladakh can make day trips to centuries old monasteries, notably Hemis, Thiksey or Alchi or an overnight journey to soak in the surreal landscapes at Pangong Tso, Nubra Valley or Lamayuru. Dining options include Zasgyath the all day dining restaurant with alfresco seating, Solja the Tea & Coffee Lounge, BBQ and bonfire in the lawns as well as a full service picnic by the banks of the river or any scenic spot. The Wellness centre comprises a fully equipped gym and SPA soon. Book your stay here and take your experience of Ladakh to another level.</p>


                                        <a href="tel://9163676037" className="btn btn-Warning">Book Now</a>

                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid py-2">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>The Chospa <span>- Leh</span></h2>
                                            <h4><i>Vernacular Ladakhi Architecture</i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row py-3">
                                    <img src={brandedTourackageImage_4_URL} alt="..." />
                                </div>

                                <div id="no-more-tables">
                                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                        <thead>
                                            <tr className="table-danger">
                                                <th>Category</th>
                                                <th>Size(Room)</th>
                                                <th>Hotel Brand</th>
                                                <th>Price (Per Night)</th>
                                                <th>Book Now</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td data-title="Packages">Platinum Accommodation</td>
                                                <td data-title="Size(Room)">270+ SQ.FT</td>
                                                <td data-title="Hotel Brand">Earth<div className="reviews">
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                </div></td>
                                                <td data-title="Price (Per Night)">Rs 14,843/- </td>
                                                <td data-title="Book Now"><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick}>
                                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                                </a></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="card mx-0 my-5">
                                    <div className="card-header custHeaer">
                                        <h3 className='content-desktop' style={{ fontSize: "1.5vw" }}><b>CHOSPA – MADE OF THE EARTH</b></h3>
                                        <h3 className='content-mobile2' style={{ fontSize: "3.5vw" }}><b>CHOSPA – MADE OF THE EARTH</b></h3>
                                    </div>
                                    <div className="card-body">
                                        <p><b>Hotel Chospa</b> is a stunning retreat nestled in the heart of the vibrant city centre of Leh, located on the Old Leh Road. This enchanting abode has been curated to cater to the discerning explorer and those who appreciate the finer things in life.
                                            Chospa is the union of contemporary hospitality with the scenic and material biome of Ladakh. The architecture mirrors the traditional Ladakhi construction and materiality, rendering an ambience familiar to locals and unique for visitors.

                                            The spatial organization is deliberately insular to mitigate the noise from the abutting busy street yet allowing a convenient flow of passersby to access the alfresco of the café from the upper corner. The atrium lobby aims to lend a casual yet a calming vibe found in most traditional Ladakhi homes. The lobby atrium opens out to the rear with open seating on the south side receiving much needed ample south facing sunlight.</p>


                                        <a href="tel://9163676037" className="btn btn-Warning">Book Now</a>

                                    </div>
                                </div>
                            </div>
                            {/* <!--start of package details--> */}
                            <div className="container-fluid py-5">

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>Srinagar & Kashmir<span> Tour Packages</span></h2>
                                            <h4>Related Kashmir Tour plan</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                                        <div className="carousel-inner">
                                            <div className="carousel-item active">

                                                <Slider {...settings}>
                                                    {hotPackagesView}
                                                </Slider>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!--end of package details--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>Watch <b>Leh Kashmir Kargil Ladakh Tour</b> FAQs</h2>
                                            <h4>Srinagar & Kashmir Trip Related Questions</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="accordion" id="accordionPanelsStayOpenExample">
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingOne">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                                    Which is the best month to visit Kashmir & Leh Ladakh together ?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseOne" className="accordion-collapse collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">April to July and from mid-September to October are considered as the ideal time to visit Leh Ladakh Kashmir both.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingTwo">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                                    How many days are enough for Leh Ladakh Kashmir Package ?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseTwo" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body"><p>- Minimum 10 days require to complete Kashmir Ladakh Tour. Particular trip will be stared from Kashmir & end at Leh.
                                                </p>

                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingThree">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                                    Is there an oxygen problem ?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseThree" className="accordion-collapse collapse show" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">  <p>- In Srinagar you will not face any problem, but Ladakh situated 10,000 feet above sea level and hence there is a lack of oxygen and you might find it difficult to breathe. So, it is best to take it slow.</p></div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingFour">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                                    Which mobile network is best?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseFour" className="accordion-collapse collapse show" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">- Kashmir territory you need to communicate by postpaid SIM CARD. As prepaid SIM is not working here. Ladakh both type of SIM CARD will work. Based on strong connection BSNL is so far better that any other network.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingFive">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                                                    Is Leh trip safe for babies ?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseFive" className="accordion-collapse collapse show" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">- Children below 4 years of age do not have mature lungs to handle high altitude sickness. So, it is recommended not to plan a Leh trip with children below 4 years of age.</div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingSix">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseFive">
                                                    I am having Asthma/breathing problem. Can I visit Leh Ladakh Kashmir?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseSix" className="accordion-collapse collapse show" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">- For people having Asthama or other respiratory diseases, it is suggested to seek doctor's advice before planning the trip
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingSeven">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
                                                    Is liquor allowed in Leh Ladakh & Kashmir?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseSeven" className="accordion-collapse collapse show" aria-labelledby="flush-headingSeven" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body"> - Yes</div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingEight">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
                                                    What kind of clothing is required for the trip ?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseEight" className="accordion-collapse collapse show" aria-labelledby="flush-headingEight" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    <ul>
                                                        <li>- While you are traveling to Ladakh always be prepared for the cold which you are going to face here. Make sure you cross check the list of all the essentials things you must carry for Ladakh trip.  You also might be facing a zero degree temperature along with windy conditions. So carry woolen clothing with which should include:</li>
                                                        <li>- Woolen Cap</li>
                                                        <li>- Gloves</li>
                                                        <li>- Warm & wind proof jacket</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingNine">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseNine">
                                                What kind of bedding do we get while camping?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseNine" className="accordion-collapse collapse show" aria-labelledby="flush-headingNine" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                <ul>
                                                <li>-	Whether you are heading out for a road trip or trekking there will be proper bedding with proper beds and proper quilts to keep you warm. You need not bring any sleeping bags to protect you from the cold. All your bedding and camping facilities will be well taken care of. The facilities are fairly well developed to cover for the basic needs of the individuals.</li>
                                                    <li><b>- Camp Sites:</b></li>
                                                    <li>- Leh Ladakh Camps.</li>
                                                    <li>- Pangong Lake Camps.</li>
                                                    <li>- Tso Moriri Camps.</li>
                                                    <li>- Nubra Valley Camps.</li>
                                                    <li>- Sarchu Camps.</li>
                                                    <li>- Jispa Camps.</li>
                                                    </ul>
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h3><b>Our <span class="tomato">Jammu Kashmir Kargil Nubra Leh Package</span></b> Reviews</h3>
                                            <h4><i>"Your Experience is our Marketing"</i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-cols-1">
                                    <div id="testimonials">
                                        <div className="testimonial-box-container">

                                            {reviewersDataView}

                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="col-md-3">
                            <BookingBox />
                        </div>
                    </div >
                </div >
                <div class="btn-call-pulse">
                    <a style={{ color: 'white' }} href="tel:+91-91636-76037">
                        <i className="fa fa-phone"></i>
                        {/* <span><small>+91 9147061467</small></span> */}
                    </a>
                </div>
                <div className="btn-whatsapp-pulse">
                        <a  style={{ color: 'white' }} href="#" onClick={this.handleClick}>
                            <i className="fab fa-whatsapp"></i>
                            {/* <span>
                                <small>+91 9883359713</small>
                            </span> */}
                        </a>
                    </div>
            </>
        );
    }
}

export default Kashmir_Leh_Ladakh_Tour_Package;