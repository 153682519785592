import React, { Component, Fragment } from 'react';
import { Breadcrumb, Card, Col, Container, Row } from "react-bootstrap";

class Policy extends Component {
    render() {
        return (
            <Fragment>
                <Container className="TopSection">
                    <Breadcrumb className="w-100 bg-white">
                        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                        <Breadcrumb.Item>Privacy Policy</Breadcrumb.Item>
                    </Breadcrumb>
                    <Row>
                        <Col className="mt-4" md={12} lg={12} sm={12} xs={12}>
                            <Card>
                                <Card.Header>
                                    <h3>Cancellation Policy</h3>
                                </Card.Header>
                                <Card.Body>
                                    <div style={{ marginTop: "10px", padding: "10px 2px" }}>
                                        <h5 style={{ textAlign: "justify" }}><strong>ADORABLE CANCELLATION POLICY</strong></h5>
                                        <p style={{ textAlign: "justify" }}>CANCELLATIONS – As the Organization book services in advance and are bound to honor the commitments as to payment to the suppliers, cancellation of services earmarked results in the Organization losing money depending upon the time of communication of cancellation to the supplier and therefore, the client/s will have to agree to adhere to the cancellation policy / schedule and the same shall also form a part of the Contract. The Cancellation Policy / Schedule for the entire range of services or part thereof will be as follows:</p>
                                        <p style={{ textAlign: "justify" }}>&nbsp;</p>
                                        <div id="no-more-tables">
                                            <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                <thead>
                                                    <tr className="table-danger">
                                                        <th>DURATION</th>
                                                        <th>CANCELLATION (%)</th>
                                                        <th>BASED ON</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td data-title="Duration">45 Days Prior To Departure Date</td>
                                                        <td data-title="cancellation">20%</td>
                                                        <td data-title="based on">On Total Billing Value</td>
                                                    </tr>
                                                    <tr>
                                                        <td data-title="Duration">44 – 30 Days Prior To Departure Date</td>
                                                        <td data-title="cancellation">50%</td>
                                                        <td data-title="based on">On Total Billing Value</td>
                                                    </tr>
                                                    <tr>
                                                        <td data-title="Duration">29 – 15 Days Prior To Departure Date</td>
                                                        <td data-title="cancellation">75%</td>
                                                        <td data-title="based on">On Total Billing Value</td>
                                                    </tr>
                                                    <tr>
                                                        <td data-title="Duration">14 – 07 Days Prior To Departure Date</td>
                                                        <td data-title="cancellation">100%</td>
                                                        <td data-title="based on">On Total Billing Value</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <p style={{ textAlign: "justify" }}>&nbsp;</p>
                                        <h5 style={{ textAlign: "justify" }}><strong>POSTPONEMENT POLICY</strong></h5>
                                        <p style={{ textAlign: "justify" }}>We do not want to make profit from the cancellation. Instead of cancel, you can postpone or re-schedule your program. We can give you a Zero Cancellation Postponement Period of 90 Days (not valid if you were travelling in Peak Season).</p>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default Policy;
