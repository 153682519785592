import React, { Component } from 'react';
import BookingBox from '../BookingBox';
import Slider from "react-slick";
import ApiURL from "../../../api/ApiURL";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InclusionExclusion from '../Kashmir/InclusionExclusion_kashmir_gurez_valley';

// import FloatingWhatsAppButton from 'react-floating-whatsapp';

class Kashmir_7N_8D_Sinthan_Top_Package extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customActiveTab: 'tab1',
            daywiseActiveTab: 'day1'
        }
    }

    handleCustomTabClick = (tab) => {
        this.setState({
            customActiveTab: tab,
        });
    };

    handleDayWiseTabClick = (tab) => {
        this.setState({
            daywiseActiveTab: tab,
        });
    };
    handleClick = () => {
        const currentUrl = window.location.href;
        const pageTitle = document.title;
        const message = `Hi, Please send about the Tour Package details "${pageTitle}"`;

        const whatsappLink = `https://api.whatsapp.com/send?phone=+919147061467&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
        window.open(whatsappLink, '_blank');
    };

    render() {
        const { customActiveTab, daywiseActiveTab } = this.state;
        const packageTopBannerImageURL = ApiURL.Kashmir_Tour_Package_with_Sinthan_Top + "/Kashmir-Package-Tour-with-Sinthan-Top.webp";
        const packageTopBanner2ImageURL = ApiURL.Kashmir_Tour_Package_with_Sinthan_Top + "/Kashmir-Tour-Package-with-Sinthan-Top.webp";
        const sightseen1ImageURL = ApiURL.Kashmir_Tour_Package_with_Sinthan_Top + "/Kashmir-Package-Gureaz-valley.webp";
        const sightseen2ImageURL = ApiURL.Kashmir_Tour_Package_with_Sinthan_Top + "/Kashmir-Package-Trip-with-Gurez-Valley.webp";
        const sightseen3ImageURL = ApiURL.Kashmir_Tour_Package_with_Sinthan_Top + "/Kashmir-Tour-Packages-with-Gurez-valley.webp";
        const sightseen4ImageURL = ApiURL.Kashmir_Tour_Package_with_Sinthan_Top + "/Kashmir-Tour-Package.webp";
        const sightseen5ImageURL = ApiURL.Kashmir_Tour_Package_with_Sinthan_Top + "/Kashmir-Offbeat-Family-Packages.webp";
        const sightseen6ImageURL = ApiURL.Kashmir_Tour_Package_with_Sinthan_Top + "/kashmir-tour-packages-cost-with-Gurez-Valley.webp";
        const sightseen7ImageURL = ApiURL.Kashmir_Tour_Package_with_Sinthan_Top + "/Kashmir-Tour-Packages-with-Yusmarg.webp";
        const sightseen8ImageURL = ApiURL.Kashmir_Tour_Package_with_Sinthan_Top + "/Doodhpathri-Kashmir-Package.webp";
        const sightseen9ImageURL = ApiURL.Kashmir_Tour_Package_with_Sinthan_Top + "/Kashmir-Offbeat-Package.webp";
        const sightseen10ImageURL = ApiURL.Kashmir_Tour_Package_with_Sinthan_Top + "/Kashmir-Tours-Plan.webp";
        const sightseen11ImageURL = ApiURL.Kashmir_Tour_Package_with_Sinthan_Top + "/Offbeat-Kashmir-Tours-with-Gurez-Valley.webp";
        const sightseen12ImageURL = ApiURL.Kashmir_Tour_Package_with_Sinthan_Top + "/Kashmir-offbeat-package-tours-with-Gurez-Valley.webp";
        //day wise itenaryy
        const navDay1ImageURL = ApiURL.Kashmir_Tour_Package_with_Sinthan_Top + "/Kashmir-Offbeat-Packages-for-Family.webp";
        const navDay2ImageURL = ApiURL.Kashmir_Tour_Package_with_Sinthan_Top + "/Kashmir-Tour-Package-cost-offbeat.webp";
        const navDay3ImageURL = ApiURL.Kashmir_Tour_Package_with_Sinthan_Top + "/Kashmir-Package.webp";
        const navDay4ImageURL = ApiURL.Kashmir_Tour_Package_with_Sinthan_Top + "/Kashmir-Offbeat.webp";
        const navDay5ImageURL = ApiURL.Kashmir_Tour_Package_with_Sinthan_Top + "/Kashmir-Offbeat-Package.webp";
        const navDay6ImageURL = ApiURL.Kashmir_Tour_Package_with_Sinthan_Top + "/Kashmir-Offbeat-Packages-for-Family.webp";
        const navDay7ImageURL = ApiURL.Kashmir_Tour_Package_with_Sinthan_Top + "/Sinthan-top-Kashmir-Offbeat-Tour-Packages.webp";
        const navDay8ImageURL = ApiURL.Kashmir_Tour_Package_with_Sinthan_Top + "/Offbeat-Kashmir-Tour.webp";
        const Topbanner0ImageURL = ApiURL.Kashmir_Tour_Package_with_Sinthan_Top + "/Kashmir-Houseboat-Packages.webp";
        const Topbanner1ImageURL = ApiURL.Kashmir_Tour_Package_with_Sinthan_Top + "/Kashmir-Adventure-Tour-Plan.webp";
        const Topbanner2ImageURL = ApiURL.Kashmir_Tour_Package_with_Sinthan_Top + "/Kashmir-Gondola-Package-Tour.webp";
        const Topbanner3ImageURL = ApiURL.Kashmir_Tour_Package_with_Sinthan_Top + "/Kashmir-Trekking-Packages.webp";
        const Topbanner4ImageURL = ApiURL.Kashmir_Tour_Package_with_Sinthan_Top + "/Kashmir-White-River-Rafting-Plan.webp";
        const brandedTourackageImage_1_URL = ApiURL.Kashmir_Tour_Package_with_Sinthan_Top + "/The-Khyber-Himalayan-Resort-&-Spa.webp";
        const brandedTourackageImage_2_URL = ApiURL.Kashmir_Tour_Package_with_Sinthan_Top + "/The-LaLiT-Grand-Palace-Srinagar-Hotel.webp";
        const TrendingPackagesDataList = this.props.TrendingPackagesData;
        const hotPackagesView = TrendingPackagesDataList.length > 0 ?
            (
                TrendingPackagesDataList.map((dataItem, i) => {
                    const hotelImgURL = ApiURL.Kashmir_Tour_Package_with_Sinthan_Top + "/" + dataItem.ImageName;
                    return (<div className="col-md-12 p-0" key={i}>
                        <div className="card h-100">
                            <img src={hotelImgURL} className="card-img-top" alt={dataItem.AllternateText} />
                            <div className="card-body">
                                <h6 className="card-title">{dataItem.Title} <span> &#8377; {dataItem.PricePerNight}</span></h6>
                                <p className="card-text">
                                    <i className="fa fa-building tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-utensils tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-car-side tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-male tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-camera tomato"></i>
                                </p>
                                <p className="card-text"><span>{dataItem.Description}</span></p>
                                <p>
                                    <span><b className="tomato">{dataItem.NoOfTours} </b>Tours</span> | &nbsp;
                                    <span><b className="tomato">{dataItem.NoOfDeparture} </b>Departures</span> | &nbsp;
                                    <span><b className="sdf-blue"> {dataItem.Include}</b></span>
                                </p>
                            </div>
                            <div className="card-footer">
                                <small className="text-muted"><strong className="tomato">{dataItem.MutedText}</strong></small>
                                <a href="#" className="btn btn-success btn-sm float-end" onClick={this.handleClick}>
                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                </a>
                            </div>
                        </div>
                    </div>)
                })
            ) : (<div></div>)


        const settings = {
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const reviewersDataList = this.props.ReviewersData;
        const reviewersDataView = reviewersDataList.map((dataItem, i) => {
            const reviewerImageURL = ApiURL.Kashmir_Tour_Package_with_Sinthan_Top + "/" + dataItem.ImageName;
            return (
                <div className="testimonial-box" key={i}>
                    <div className="box-top">
                        <div className="profile">
                            <div className="profile-img">
                                <img src={reviewerImageURL} alt="..." />
                            </div>
                            <div className="name-user">
                                <strong>{dataItem.Name}</strong>
                                <span>{dataItem.Location}</span>
                            </div>
                        </div>
                        <div className="reviews">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </div>
                    </div>
                    <div className="client-comment">
                        <p>{dataItem.Comment}</p>
                    </div>
                </div>
            )
        })

        return (
            <>

                <div className="container-fluid">
                    <div className="row">
                        <img src={packageTopBannerImageURL} className="mobile-image2" alt="..." />
                    </div>
                </div>
                <div className="container-fluid custom-container py-3">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="container-fluid custom-container py-1">

                                        <h1 className="content-desktop" style={{ fontSize: "2vw" }}><center><strong>KASHMIR OFFBEAT TOUR WITH SINTHAN TOP </strong></center></h1>

                                        <h1 className="content-mobile" style={{ fontSize: "4vw" }}><center><strong>KASHMIR OFFBEAT TOUR WITH SINTHAN TOP </strong></center>
                                            <hr />
                                        </h1>
                                        <div className="row">
                                            <img src={packageTopBanner2ImageURL} className="mobile-image" alt="..." />
                                        </div>
                                        <hr />
                                        <div className="d-flex flex-column flex-sm-row align-items-sm-center px-2">
                                            <div className="font-lg">
                                                3 Destination:{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Srinagar (3N)
                                                </a>
                                                &nbsp;{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Gulmarg (1N)
                                                </a>{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Pahalgam (3N)
                                                </a>
                                            </div>
                                            <div className="reviews mx-5">
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                            </div>
                                        </div>
                                        <p className="p-2">
                                            If you are looking for a memorable <strong>Kashmir Tour Packages with Sinthan Top</strong>, you have come to the right place!
                                        </p>
                                        <hr />
                                        <br />
                                    </div>
                                    <h2 className="content-desktop p-2" style={{ fontSize: "1.6vw" }}> Looking for <strong> Offbeat Kashmir Tour Packages ?</strong> Offbeat Kashmir Holiday Packages Booking <a href='tel:9147061467' class="tomato">☎ CALL Mr. Soumya +91- 91470-61467</a></h2>
                                    <h2 className="content-mobile p-4" style={{ fontSize: "4.5vw" }}> Looking for <strong> Offbeat Kashmir Tour Packages ?</strong> Offbeat Kashmir Holiday Packages Booking <a href='tel:9147061467' class="tomato">☎ CALL Mr. Soumya +91- 91470-61467</a></h2>
                                    <br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> Experience the enchantment of Kashmir with our exclusive tour package with Sinthan Top</h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}> Experience the enchantment of Kashmir with our exclusive tour package with Sinthan Top</h4>
                                        </div>
                                        <div className="card-body">
                                            <p>Discover the Hidden Treasures of Kashmir with Our Exclusive <strong>Kashmir Offbeat Package Tour</strong>. This unique 7 Nights 8 Days adventure is designed to take you beyond the usual tourist spots, into the heart of Kashmir's untouched beauty. <strong>Kashmir Tour with Sinthan Top</strong>, Aru Valley, Betaab Valley, Chandanbari, Pahalgam, and Sonmarg, and experience the magic of these lesser-known destinations.</p>
                                            <p>Our <strong>Kashmir offbeat tour</strong> offers more than just a holiday; it's an authentic journey into the hidden corners of this stunning region. Perfect for those with an adventurous spirit, this tour reveals the true essence of Kashmir, far from the crowded tourist trails.</p>
                                            <p>Join us for the <strong>Offbeat Kashmir Tour 7 Nights 8 Days</strong> and immerse yourself in the captivating beauty of this enchanting region. From the tranquil Dal Lake to the bustling markets of Srinagar, each day brings a new adventure, blending cultural richness with thrilling experiences. Book now to uncover the secrets of <strong>Kashmir Offbeat Holiday Package</strong>!</p>
                                            <p>Book now for a journey beyond the ordinary and explore Kashmir like never before!</p>
                                            <table className="image-table">
                                                <tbody>
                                                    <tr>
                                                        <td className="image-cell">
                                                            <img src={sightseen1ImageURL} alt="kashmir tour packages" />
                                                            <img src={sightseen2ImageURL} alt="kashmir trip from srinagar airport" />
                                                            <img src={sightseen3ImageURL} alt="kashmir package tour cost from srinagar airport" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <br />
                                        </div>
                                    </div>
                                    <br /> <br /> <br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>KASHMIR OFFBEAT PACKAGE TOUR : 7N | 8D</strong></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>KASHMIR OFFBEAT PACKAGE TOUR : 7N | 8D</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <p>Places to cover in <strong>Offbeat Kashmir Tour </strong> : Srinagar HouseBoat 1N | Pahalgam 3N | Gulmarg 1N | Srinagar 2N</p>
                                            <p>Pickup & Drop Point for <b>Kashmir Tour Packages</b> : Sheikh Ul Alam International Airport Srinagar [SXR]
                                            </p>
                                            <p><b>Address :</b> Indra Gandhi Road, Humhama, Badgam, Srinagar, Jammu and Kashmir 190007</p>
                                            <br /><br /><br />
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <tbody>
                                                        <tr>
                                                            <td><strong>Offbeat Kashmir Tour Package</strong> Pickup & Drop	</td>
                                                            <td>Srinagar Airport [SXR]</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Kolkata to Kashmir Package Duration</strong></td>
                                                            <td>07 Nights | 08 Days</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Kashmir Tour With Sinthan Top Package Cost </strong></td>
                                                            <td>Rs. 28,100/Based on 8 Pax</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Srinagar</strong></td>
                                                            <td>1,585 m | Coordinates - 34.0837° N, 74.7973° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Gulmarg</strong></td>
                                                            <td>2,650 m | Coordinates - 34.0484° N, 74.3805° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Sonmarg</strong></td>
                                                            <td>2,730 m | Coordinates - 34.3032° N, 75.2931° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Pahalgam</strong></td>
                                                            <td>2,740 m | Coordinates - 34.0161° N, 75.3150° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Sinthantop</strong></td>
                                                            <td>3,800 m | Coordinates - 33.3453° N, 75.3039° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Betaab Valley</strong></td>
                                                            <td>2,393 m | Coordinates - 34.0541° N, 75.3639° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Aru Valley</strong></td>
                                                            <td>2,414 m | Coordinates - 34.0922° N, 75.2632° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Chandanwadi</strong></td>
                                                            <td>3,288 m | Coordinates - 34.2122° N, 73.9295° E</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <br /><br /><br />
                                            <table className="image-table">
                                                <tbody>
                                                    <tr>
                                                        <td className="image-cell">
                                                            <img src={sightseen4ImageURL} alt="kashmir package tour from srinagar" />
                                                            <img src={sightseen5ImageURL} alt="kashmir package price from srinagar airport" />
                                                            <img src={sightseen6ImageURL} alt="kashmir packages from srinagar airport" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <br />

                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    {/* start short itinerary */}

                                    <div className="card">
                                        <div className="card-body">
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr className="table-danger">
                                                        <th>DAY WISE ITINERARY FOR KASHMIR OFFBEAT PACKAGE TOUR WITH SINTHAN TOP</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingOne"
                                                        >
                                                            <a href="#day1" style={{ color: 'black' }}><b>DAY 1 → </b>
                                                                <span>
                                                                    Arrive in Srinagar, enjoy local sightseeing, and stay overnight on a houseboat
                                                                </span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingTwo"
                                                        >
                                                            <a href="#day2" style={{ color: 'black' }}><b>DAY 2 → </b>
                                                                <span>Full-day excursion from Srinagar to Gulmarg, with an overnight stay at a hotel.</span>
                                                            </a>

                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingThree"
                                                        >
                                                            <a href="#day3" style={{ color: 'black' }}><b>DAY 3 → </b>
                                                                <span>Travel from Gulmarg to Pahalgam for a tour, and stay overnight at a hotel.</span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingFour"
                                                        >
                                                            <a href="#day4" style={{ color: 'black' }}><b>DAY 4 → </b>
                                                                <span>Explore Pahalgam, including Betaab Valley, Aru Valley, and Chandanwadi. Overnight stay included.</span>
                                                            </a>

                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingFive"
                                                        >
                                                            <a href="#day5" style={{ color: 'black' }}><b>DAY 5 → </b>
                                                                <span>Enjoy a day excursion from Pahalgam to Sinthan Top, with an overnight stay back in Pahalgam.</span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingSix"
                                                        >
                                                            <a href="#day6" style={{ color: 'black' }}><b>DAY 6 → </b>
                                                                <span>Day trip from Pahalgam to Srinagar, with an overnight stay in Srinagar.</span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingSeven"
                                                        >
                                                            <a href="#day7" style={{ color: 'black' }}><b>DAY 7 → </b>
                                                                <span>Day trip from Srinagar to Sonmarg, returning for an overnight stay in Srinagar. </span>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingEight"
                                                        >
                                                            <a href="#day8" style={{ color: 'black' }}><b>DAY 8 → </b>
                                                                <span>Departure from Srinagar Airport. </span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    {/* <!--end Short description--> */}
                                    <br /><br /><br />

                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>DISCOVER THE SERENITY OF KASHMIR WITH AN OFFBEAT PACKAGE TOUR</h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>DISCOVER THE SERENITY OF KASHMIR WITH AN OFFBEAT PACKAGE TOUR</h4>
                                        </div>
                                        <div className="card-body">
                                            <h5><b>Explore the Hidden Gems of Kashmir</b></h5>
                                            <p>Embark on a captivating <strong>Offbeat Kashmir Tour</strong> journey through the enchanting valleys of Kashmir, where lush landscapes and tranquil beauty await. This <strong>exclusive Kashmir package tour</strong> takes you beyond the typical tourist paths, offering a chance to experience the region’s unspoiled charm and serene vistas. Your adventure begins with a visit to the stunning city of Srinagar, famous for its picturesque Dal Lake and the historic Mughal Gardens. <strong>Srinagar Kashmir Offbeat Tour Packages</strong> As you stroll through the vibrant flower markets and traditional houseboats, you’ll gain a deeper appreciation for the region's rich cultural heritage.</p>
                                            <br />
                                            <h5><b>Unveil the Mystique of Sinthan Top</b></h5>
                                            <p>Venture into the lesser-known yet breathtakingly beautiful Sinthan Top, a high-altitude pass offering panoramic views of the snow-capped peaks and verdant meadows. This hidden treasure is renowned for its pristine beauty and tranquil environment, making it an ideal destination for those seeking solitude and a connection with nature. <strong>Kashmir Offbeat Trip</strong> drive up to Sinthan Top provides awe-inspiring views and a unique opportunity to experience the untouched landscapes that lie beyond the usual tourist routes.</p>
                                            <br />
                                            <h5><b>Immerse in Local Culture and Traditions</b></h5>
                                            <p>During your tour, immerse yourself in the local culture by visiting traditional Kashmiri villages and interacting with friendly locals. Experience authentic Kashmiri cuisine, including saffron-infused dishes and the famous Rogan Josh. Participate in cultural activities such as traditional music and dance, and discover the artistry of hand-woven Kashmiri carpets and shawls. <strong>Kashmir Package Trip</strong> Each day of the tour presents a new opportunity to embrace the vibrant local traditions and create lasting memories.</p>
                                            <br />
                                            <h5><b>Enjoy the Scenic Splendour of Offbeat Locations</b></h5>
                                            <p>In addition to Sinthan Top, explore other offbeat locations such as Yusmarg and Doodhpathri, Sinthan Top known for their untouched beauty and peaceful surroundings. <strong>Offbeat Kashmir Tour</strong> These hidden gems offer serene landscapes, lush meadows, and clear, sparkling streams, perfect for leisurely walks and relaxation. Whether you’re an adventure enthusiast or someone seeking tranquillity, these destinations provide a refreshing contrast to the more frequented spots in <strong>Kashmir Offbeat Tour Packages</strong>.</p>
                                            <br />

                                            <h5><b>Conclude with a Memorable Experience</b></h5>
                                            <p>As your tour comes to a close, you’ll leave with cherished memories of <strong>Kashmir’s offbeat wonders</strong> and a newfound appreciation for the region’s natural and cultural splendour. The serene landscapes, welcoming locals, and unique experiences will ensure that your Kashmir adventure is both unforgettable and deeply fulfilling. <strong>Kashmir Offbeat Tour</strong> is a perfect choice for travellers seeking to uncover the true essence of Kashmir while enjoying a blend of adventure and relaxation.</p>

                                            <table className="image-table">
                                                <tbody>
                                                    <tr>
                                                        <td className="image-cell">
                                                            <img src={sightseen10ImageURL} alt="kashmir package tour booking from srinagar" />
                                                            <img src={sightseen11ImageURL} alt="kashmir package tour booking from srinagar airport" />
                                                            <img src={sightseen12ImageURL} alt="kashmir package tour cost from srinagar" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <br />
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>COST OF <strong>KASHMIR OFFBEAT TOUR PACKAGE WITH SINTHAN TOP</strong></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>COST OF <strong>KASHMIR OFFBEAT TOUR PACKAGE WITH SINTHAN TOP</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <thead className="cf">
                                                        <tr className="table-danger">
                                                            <th>TOUR DURATION</th>
                                                            <th>2 PAX</th>
                                                            <th>4 PAX</th>
                                                            <th>6 PAX</th>
                                                            <th>8 PAX</th>
                                                            <th>BOOK NOW</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td data-title="TOUR DURATION">4N/5D</td>
                                                            <td data-title="2 ADULTS">₹ 20,500/-</td>
                                                            <td data-title="4 ADULTS">₹ 18,300/-</td>
                                                            <td data-title="6 ADULTS">₹ 16,600/-</td>
                                                            <td data-title="8 ADULTS">₹ 17,400/-</td>
                                                            <td data-title="Book Now"><a href="#" class="btn btn-success" onClick={this.handleClick}>Book Now</a></td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="TOUR DURATION">5N/6D</td>
                                                            <td data-title="2 ADULTS">₹ 24,700/-</td>
                                                            <td data-title="4 ADULTS">₹ 22,000/-</td>
                                                            <td data-title="6 ADULTS">₹ 19,800/-</td>
                                                            <td data-title="8 ADULTS">₹ 21,800/-</td>
                                                            <td data-title="Book Now"><a href="#" class="btn btn-success" onClick={this.handleClick}>Book Now</a></td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="TOUR DURATION">6N/7D</td>
                                                            <td data-title="2 ADULTS">₹ 30,900/-</td>
                                                            <td data-title="4 ADULTS">₹ 27,400/-</td>
                                                            <td data-title="6 ADULTS">₹ 24,700/-</td>
                                                            <td data-title="8 ADULTS">₹ 25,500/-</td>
                                                            <td data-title="Book Now"><a href="#" class="btn btn-success" onClick={this.handleClick}>Book Now</a></td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="TOUR DURATION">7N/8D</td>
                                                            <td data-title="2 ADULTS">₹ 35,600/-</td>
                                                            <td data-title="4 ADULTS">₹ 30,800/-</td>
                                                            <td data-title="6 ADULTS">₹ 28,300/-</td>
                                                            <td data-title="8 ADULTS">₹ 29,100/-</td>
                                                            <td data-title="Book Now"><a href="#" class="btn btn-success" onClick={this.handleClick}>Book Now</a></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <br /><br />

                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>FAMOUS LAKES TO EXPLORE DURING <strong>KASHMIR TOUR PACKAGES WITH SINTHAN TOP</strong></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>FAMOUS LAKES TO EXPLORE DURING <strong>KASHMIR TOUR PACKAGES WITH SINTHAN TOP</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <p>If you're embarking on a <strong>Kashmir Offbeat Package Tour with Sinthan Top</strong>, you absolutely must explore the region’s stunning lakes. Kashmir is renowned for its breath-taking lakes surrounded by snow-capped mountains, Chinar trees, pine forests, and vibrant gardens full of blooming tulips and roses. Here are some of the most famous lakes to include in your <strong>Kashmir Offbeat Tour Packages</strong>.</p>
                                            <p><b>Dal Lake</b> is undoubtedly the most popular destination on a <strong>Kashmir Offbeat Package Tour</strong>. Spanning around 22 square kilometres, this picturesque lake is famous for its colourful Shikaras (traditional boats), houseboats, and floating markets. A Shikara ride on Dal Lake is an essential part of any <strong>Offbeat Kashmir Holiday Packages</strong>. You must also visit Char Chinar, a beautiful island in the middle of the lake with four Chinar trees at its corners. Depending on the season, Char Chinar provides stunning views of the lake and its surroundings. Dal Lake has been featured in many Bollywood movies, making it a quintessential part of any <strong>Kashmir Package Tour</strong>. Floating on a Shikara, enjoying hot noon Chai (also known as Shir Chai or Gulabi Chai), and sampling authentic Kashmiri cuisine makes for a truly memorable experience. Staying in a houseboat on the tranquil waters of Dal Lake adds to its romantic appeal.
                                            </p>
                                            <p><b>Wular Lake</b> is another significant attraction in Kashmir. Covering about 190 square kilometres in the Bandipora district, Wular Lake is the largest in Jammu and Kashmir and the second largest in Asia. Originating from the Jhelum River, Wular Lake is vital for the local ecosystem and boasts a diverse range of aquatic plants and fish. It’s a key part of any <strong>Customized Kashmir Tour</strong> for those interested in natural beauty and local wildlife.</p>
                                            <p><b>Manasbal Lake</b> located 28 kilometers from Srinagar at an elevation of 1,583 meters, is smaller compared to Dal and Nagin Lakes but equally charming. Known for its beautiful blooms of lilies and lotuses, Manasbal Lake is an ideal spot for spending quality time with loved ones. The lake is surrounded by well-maintained gardens filled with seasonal flowers, including tulips and roses, making it a sought-after destination in Kashmir.</p>
                                            <p>Including these lakes in your <strong>Kashmir Tour Packages with Sinthan Top</strong> will ensure a memorable and picturesque experience, showcasing the natural beauty and romantic allure of the region.</p>
                                            <br />
                                            <br /> <br />
                                            <h5><b>ENTRY FEES FOR SIGHTSEEING DURING KASHMIR TOUR</b></h5>
                                            <br />

                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <tbody>
                                                        <tr>
                                                            <td>Places</td>
                                                            <td>Entry Fees</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Dal Lake</td>
                                                            <td>NIL</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Nishant Bagh</td>
                                                            <td>Rs. 24 per adult, Rs. 12 per child</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Pari Mahal</td>
                                                            <td>Rs. 40/Person</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Shalimar Bagh</td>
                                                            <td>Rs. 24 per adult, Rs. 12 per child</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Shankaracharya Temple</td>
                                                            <td>NIL</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Chashme Shahi</td>
                                                            <td>Rs. 24 per adult, Rs. 12 per child</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Verinag Garden</td>
                                                            <td>Rs. 10/Person</td>
                                                        </tr>
                                                        <tr>
                                                            <td>SPS Museum</td>
                                                            <td>Rs. 10 for Indian, Rs. 50 for Foreigners</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Tulip Garden</td>
                                                            <td>Rs. 75 per adult, Rs. 30 per child</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Aru Valley</td>
                                                            <td>Rs. 25/Person</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                            <br />
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div className="card-header custHeaer">
                                            <h4 className='content-desktop' style={{ fontSize: "1.5vw" }}>MUST DO ACTIVITIES DURING <strong>KASHMIR TOUR PACKAGES WITH SINTHAN TOP</strong></h4>
                                            <h4 className='content-mobile2' style={{ fontSize: "3.5vw" }}>MUST DO ACTIVITIES DURING <strong>KASHMIR TOUR PACKAGES WITH SINTHAN TOP</strong></h4>

                                        </div>
                                        <div className="card-body">
                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>Shikara Rides:</b><b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Shikara Rides:</b> Experience the tranquility of Dal Lake with a leisurely Shikara ride During your <strong> Kashmir Tour Packages with Sinthan Top</strong>. Glide across the serene waters, passing by floating gardens, houseboats, and vibrant markets. Sunset and early morning rides offer particularly magical views.</p>
                                            <center> <img src={Topbanner0ImageURL} className="img-fluid" alt="..." /></center><br /><br /><br />

                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>Skiing and Snowboarding:</b>
                                                <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Skiing and Snowboarding:</b> During the winter months, places like Gulmarg transform into ski resorts, attracting enthusiasts from around the world. Gulmarg offers excellent slopes for skiing, snowboarding, and heli-skiing, with breathtaking views of the surrounding Himalayan peaks.</p>
                                            <center> <img src={Topbanner1ImageURL} className="img-fluid" alt="..." /></center><br /><br /><br />

                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>Gondola Ride: </b>
                                                <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Gondola Ride: </b>
                                                Take a ride on the Gulmarg Gondola, one of the highest cable cars in the world. Enjoy panoramic views of the snow-capped peaks as you ascend to Kongdori or Apharwat Peak, where you can indulge in activities like hiking and mountain biking.Experience this Awesome Ride During your <strong>Kashmir Tour Package with Sinthan Top</strong> with Adorable Vacation and make this as a life long memories.</p>
                                            <center> <img src={Topbanner2ImageURL} className="img-fluid" alt="..." /></center><br /><br /><br />

                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>Trekking:</b>
                                                <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Trekking:</b> Kashmir is a paradise for trekkers, with numerous trails that cater to all levels of expertise. Popular trekking destinations include the Kashmir Great Lakes Trek, Tarsar Marsar Trek, and the trek to Thajiwas Glacier in Sonamarg.</p>
                                            <center> <img src={Topbanner3ImageURL} className="img-fluid" alt="..." /></center><br /><br /><br />
                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>River Rafting:</b>
                                                <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>River Rafting:</b> The Lidder River in Pahalgam offers exciting opportunities for white-water rafting. Experience the thrill of navigating through rapids while enjoying the scenic beauty of the surrounding pine forests and mountains.</p>
                                            <center> <img src={Topbanner4ImageURL} className="img-fluid" alt="..." /></center>
                                            <br /><br /><br />
                                            <a href="tel://9147061467" className="btn btn-Warning" style={{ padding: '15px 30px', fontSize: '20px' }}>CALL NOW</a>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="container-fluid navtabCustom">
                                        <nav>
                                            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                                <button onClick={() => this.handleCustomTabClick('tab1')} className={`tab button ${customActiveTab === 'tab1' ? 'active' : ''}`} role="tab" aria-controls="nav-highlights" aria-selected={customActiveTab === 'tab1'}>Highlights</button>
                                                <button onClick={() => this.handleCustomTabClick('tab2')} className={`tab button ${customActiveTab === 'tab2' ? 'active' : ''}`} role="tab" aria-controls="nav-flights" aria-selected={customActiveTab === 'tab2'}>Flights</button>
                                                <button onClick={() => this.handleCustomTabClick('tab3')} className={`tab button ${customActiveTab === 'tab3' ? 'active' : ''}`} role="tab" aria-controls="nav-hotel" aria-selected={customActiveTab === 'tab3'}>Hotel</button>
                                                <button onClick={() => this.handleCustomTabClick('tab4')} className={`tab button ${customActiveTab === 'tab4' ? 'active' : ''}`} role="tab" aria-controls="nav-sightseeing" aria-selected={customActiveTab === 'tab4'}>Sightseeing</button>
                                                <button onClick={() => this.handleCustomTabClick('tab5')} className={`tab button ${customActiveTab === 'tab5' ? 'active' : ''}`} role="tab" aria-controls="nav-meals" aria-selected={customActiveTab === 'tab5'}>Meals</button>
                                            </div>
                                        </nav>
                                        <div className="tab-content" id="nav-tabContent" >
                                            <div className={customActiveTab === 'tab1' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-highlights-tab">
                                                <p>Including these lakes in your <strong>Kashmir Offbeat Package Tour with Adorable Vacation</strong> will ensure a memorable and picturesque experience, showcasing the natural beauty and romantic allure of the region.</p>
                                                <p><strong>Kashmir Offbeat Trip Package</strong> is renowned for its breathtaking landscapes, which include iconic features like the Dal Lake, Nigeen Lake, Gulmarg, Pahalgam, and Sonamarg. These areas attract tourists from around the world who come to admire the region's picturesque scenery and engage in various outdoor activities.</p>
                                                <p>Planning for a <strong>Offbeat Kashmir Tour Package</strong> remains a captivating destination for travellers seeking natural beauty, cultural experiences, and adventure in a unique and picturesque setting.</p>
                                                <p><b>Potential Geography</b> - Kashmir is nestled in the Himalayan Mountain range and is bordered by India, Pakistan, and China. It is divided into three regions: Jammu, the Kashmir Valley, and Ladakh. The region is characterized by its snow-capped peaks, lush valleys, meandering rivers, and serene lakes.</p>
                                                <p><strong>Offbeat Kashmir Holiday Package </strong> is renowned for its breathtaking landscapes, which include iconic features like the Dal Lake, Nigeen Lake, Gulmarg, Pahalgam, and Sonamarg. These areas attract tourists from around the world who come to admire the region's picturesque scenery and engage in various outdoor activities. Kashmir has long been a popular tourist destination, attracting visitors with its natural beauty, adventure sports, religious sites, and historical landmarks. Tourists can enjoy activities such as trekking, skiing, houseboat stays, shikara rides, and visits to Mughal gardens and ancient temples.</p>
                                            </div>
                                            <div className={customActiveTab === 'tab2' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-flights-tab">
                                                <div className="overflow-table">
                                                    <span>Best Srinagar Non-Stop Flight connection from your city<b> <i>(SXR)</i></b></span><br /><br />
                                                    <table className="table table-bordered">
                                                        <tr className="table-danger">
                                                            <th>FLIGHT</th>
                                                            <th>AIRLINES</th>
                                                            <th>AIRPORT</th>
                                                            <th>TIME</th>
                                                            <th>DAY</th>
                                                        </tr>
                                                        <tr>
                                                            <td>SG-509</td>
                                                            <td>SpiceJet</td>
                                                            <td>Mumbai</td>
                                                            <td>14:45</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>QP-1637</td>
                                                            <td>Akasa Air</td>
                                                            <td>Mumbai</td>
                                                            <td>5:35</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>63-266</td>
                                                            <td>IndiGo</td>
                                                            <td>Mumbai</td>
                                                            <td>06:45</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>SG-8913</td>
                                                            <td>SpiceJet</td>
                                                            <td>Delhi</td>
                                                            <td>08:00</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>SG-8373</td>
                                                            <td>SpiceJet</td>
                                                            <td>Delhi</td>
                                                            <td>09:45</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>6E-6947</td>
                                                            <td>IndiGo</td>
                                                            <td>Bengaluru</td>
                                                            <td>13:45</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>6E6253</td>
                                                            <td>IndiGo</td>
                                                            <td>Hyderabad</td>
                                                            <td>14:50</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>6E6945</td>
                                                            <td>IndiGo</td>
                                                            <td>Ahmedabad</td>
                                                            <td>06:55</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>6E6961</td>
                                                            <td>IndiGo</td>
                                                            <td>Kolkata</td>
                                                            <td>14:45</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className={customActiveTab === 'tab3' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-hotel-tab">

                                                <div className="overflow-table">
                                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }} ><center><b>4 STAR - CERTIFIED BY 'ADORABLE' </b></center></h4>
                                                    <div id="no-more-tables">
                                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                            <thead class="cf">
                                                                <tr className="text-center">
                                                                    {/* <!-- Add the "text-center" className to center content --> */}
                                                                    <th colspan="6" style={{ fontSize: "1.3vw" }}><h6><b>3 STAR CATEGORY HOTEL CERTIFIED BY 'ADORABLE VACATION' </b></h6></th>
                                                                </tr>
                                                                <tr className="table-danger">
                                                                    <th className="text-center">Destination</th>
                                                                    <th className="text-center">Hotel Names</th>
                                                                    <th className="text-center">Hotel Type</th>
                                                                    <th className="text-center">No of Nights</th>
                                                                    <th className="text-center">Meal Plan</th>
                                                                </tr>
                                                            </thead>
                                                            <tr>
                                                                <td data-title="Destination"><div style={{ display: 'inline-block' }}>Srinagar</div></td>
                                                                <td data-title="Hotel Names"><div style={{ display: 'inline-block' }}>Hotel Grand Fortune / Hotel Shefaf / Hotel Siddique Palace / Similar</div></td>
                                                                <td data-title="Hotel Type">3 Star
                                                                </td>
                                                                <td data-title="No of Nights"><div style={{ display: 'inline-block' }}>5N</div></td>
                                                                <td data-title="Meal Plan"><div style={{ display: 'inline-block' }}>Room with Breakfast & Dinner</div></td>
                                                            </tr>
                                                            <tr>
                                                                <td data-title="Destination"><div style={{ display: 'inline-block' }}>Gulmarg</div></td>
                                                                <td data-title="Hotel Names"><div style={{ display: 'inline-block' }}>Hotel Affarwat / Hotel Mama Palace /Gulmarg House / Similar
                                                                </div>
                                                                </td>
                                                                <td data-title="Hotel Type">3 Star
                                                                </td>
                                                                <td data-title="No of Nights"><div style={{ display: 'inline-block' }}>1N</div></td>
                                                                <td data-title="Meal Plan"><div style={{ display: 'inline-block' }}>Room with Breakfast & Dinner</div></td>
                                                            </tr>
                                                            <tr>
                                                                <td data-title="Destination"><div style={{ display: 'inline-block' }}>Pahalgam</div></td>
                                                                <td data-title="Hotel Names"><div style={{ display: 'inline-block' }}>Hotel Pine Palace Resort / Hotel Fifth Season / Hotel Moonview Resort /Similar
                                                                </div>
                                                                </td>
                                                                <td data-title="Hotel Type">3 Star
                                                                </td>
                                                                <td data-title="No of Nights"><div style={{ display: 'inline-block' }}>2N</div></td>
                                                                <td data-title="Meal Plan"><div style={{ display: 'inline-block' }}>Room with Breakfast & Dinner</div></td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={customActiveTab === 'tab4' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-sightseeing-tab">
                                                <div className="container-fluid px-0 py-1">
                                                    <h3>Sightseeing for <strong>Kashmir Tour Packages with Sinthan Top</strong></h3>
                                                    <p>Kashmir, often referred to as "Paradise on Earth," is renowned for its breathtaking natural beauty, serene landscapes, and rich cultural heritage. Here are some of the most popular sightseeing destinations in Kashmir:</p><br />
                                                    <p><b style={{ fontSize: "1.3vw" }} className='content-desktop'>Dal Lake:</b>
                                                        <b style={{ fontSize: "3.5vw" }} className='content-mobile2'>Dal Lake:</b> This iconic lake in Srinagar is famous for its picturesque surroundings, houseboats, and Shikara rides. Visitors can explore the floating gardens, old Mughal gardens along the shores, and enjoy the stunning views of the surrounding mountains.</p><br />
                                                    <p><b className='tomato content-desktop' style={{ fontSize: "1.3vw" }}>Gulmarg:</b>
                                                        <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Gulmarg:</b> Known for its lush green meadows, Gulmarg is a popular hill station and skiing destination. Visitors can take the Gulmarg Gondola, one of the highest cable cars in the world, to reach Kongdori and enjoy panoramic views of the Himalayas.</p><br />
                                                    <p><b className='content-desktop' style={{ fontSize: "1.3vw" }}>Sonamarg:</b>
                                                        <b className='content-mobile2' style={{ fontSize: "3.5vw" }}>Sonamarg:</b>  Translating to "Meadow of Gold," Sonamarg is a scenic valley situated at an altitude of 2800 meters. It offers breathtaking views of snow-capped peaks, glaciers, and meadows. It's also the base for various trekking routes.</p><br />
                                                    <p><b className='tomato' style={{ fontSize: "1.3vw" }}>Pahalgam:</b> This quaint town is renowned for its pristine beauty, lush greenery, and the Lidder River. Pahalgam serves as a starting point for the annual Amarnath Yatra pilgrimage and offers opportunities for trekking, horse riding, and fishing.</p><br />
                                                    <p><b style={{ fontSize: "1.3vw" }}>Srinagar:</b> The summer capital of Jammu and Kashmir, Srinagar is famous for its Mughal gardens, including Shalimar Bagh, Nishat Bagh, and Chashme Shahi. Visitors can also explore the old city, visit the Jama Masjid, and indulge in shopping for traditional Kashmiri handicrafts in the local markets.</p><br />
                                                    <p><b className='tomato' style={{ fontSize: "1.3vw" }}>Pari Mahal:</b> Also known as the "Palace of Fairies," Pari Mahal is a historic monument located above the Chashme Shahi Gardens in Srinagar. It offers panoramic views of the city and Dal Lake and is surrounded by beautiful terraced gardens.</p><br />
                                                    <p><b style={{ fontSize: "1.3vw" }}>Betaab Valley:</b> Named after the Bollywood movie "Betaab," this picturesque valley is located near Pahalgam and offers stunning views of snow-capped mountains, lush greenery, and the Lidder River. It's a popular picnic spot and filming location.</p><br />
                                                    <p><b className='tomato' style={{ fontSize: "1.3vw" }}>Aru Valley:</b> Situated near Pahalgam, Aru Valley is known for its scenic beauty, alpine meadows, and trekking trails. It's a great place for nature lovers and adventure enthusiasts.</p><br />
                                                    <p><b style={{ fontSize: "1.3vw" }}>Mughal Road:</b> This historic road connects the Kashmir Valley with the regions of Poonch and Rajouri. It passes through picturesque landscapes, including dense forests, meadows, and small villages, offering a scenic drive.</p><br />
                                                    <p><b className='tomato' style={{ fontSize: "1.3vw" }}>Dachigam National Park:</b> Located near Srinagar, this national park is home to endangered species like the Kashmir stag (Hangul) and the Himalayan black bear. Visitors can enjoy wildlife safari tours and birdwatching in the park.</p><br />
                                                </div>
                                            </div>
                                            <div className={customActiveTab === 'tab5' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-meals-tab">
                                                <div className="overflow-table">
                                                    <h5 class="tomato">7 times Meals and 7 times Breakfast has been Provided</h5>
                                                    <table className="table table-bordered">
                                                        <tr className="table-danger">
                                                            <th colspan="6" className="text-center">Category</th>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-center">JAIN</td>
                                                            <td class="text-center">PURE-VEG</td>
                                                            <td class="text-center">VEGAN</td>
                                                            <td class="text-center">PURE-NON-VEG</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br /> <br /> <br />
                            {/* <!--By Itinerary--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>
                                                DAY WISE <strong><span>KASHMIR TOUR WITH SINTHAN TOP</span></strong>
                                            </h2>
                                            <h4>7 Nights 8 Days <strong>Kashmir Tour Itinerary</strong></h4>
                                        </div>
                                    </div>
                                </div>


                                {/* <!--end Short description--> */}
                                <br />
                                <div className="row">
                                    <div
                                        className="accordion"
                                        id="accordionPanelsStayOpenExample"
                                    >
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day1"
                                            >
                                                <button
                                                    className="accordion-button"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseOne"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseOne"
                                                >
                                                    DAY 1  – ARRIVAL AT SRINAGAR
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseOne"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingOne"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        Arrive at Srinagar Sheikh Ul Alam International Airport, known for its scenic approach. Srinagar, also called the “City of Rishis & Sufis,” is the summer capital of Jammu & Kashmir. Our representative will meet you and take you to your hotel. After checking in and enjoying a welcome drink, spend the evening taking a Shikara ride on Dal Lake. During the ride, you can visit the floating vegetable gardens and spot various bird species such as Kingfishers and Common Pariah Kites. Overnight stay at a houseboat.
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay1ImageURL} alt="Kashmir Offbeat Packages for Family" /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay1ImageURL} style={{ width: '320px' }} alt="Kashmir Offbeat Packages for Family" /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day2"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseTwo"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseTwo"
                                                >
                                                    DAY 02: SRINAGAR TO GULMARG
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseTwo"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingTwo"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        After breakfast, check out from the houseboat and drive to Gulmarg, which is about an hour and a half away. Gulmarg, meaning "Meadow of Flowers," sits at an altitude of 8,700 feet and is known for its beautiful landscapes and as a popular ski resort. You can enjoy the Gondola ride, one of the highest cable cars in the world, reaching up to 13,780 feet. It offers spectacular views of Mt. Affarwat and the surrounding area. In winter, it’s a favorite spot for advanced skiers. Alternatively, you can visit Buta Pathri for its scenic beauty. Return to your hotel in Gulmarg for the night.
                                                    </p>

                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay2ImageURL} alt="Kashmir Tour Package cost offbeat" /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay2ImageURL} style={{ width: '320px' }} alt="Kashmir Tour Package cost offbeat" /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day3"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseThree"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 03: GULMARG TO PAHALGAM
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseThree"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingThree"
                                            >
                                                <div className="accordion-body">
                                                    <p>After breakfast, check out and drive to Pahalgam, known as the “Valley of Shepherds.” Pahalgam is where the Lidder River meets the streams from Sheshnag Lake. Once a shepherd's village, Pahalgam is now a major resort with beautiful views and cool temperatures even in summer. Check in to your hotel and enjoy the rest of the day at leisure.</p>
                                                    <br />

                                                    <div className="content-desktop">
                                                        <center><img src={navDay3ImageURL} alt="Kashmir Tour Packages" /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay3ImageURL} style={{ width: '320px' }} alt="Kashmir Tour Packages" /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day4"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseFour"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    Day 04: -EXPLORING PAHALGAM
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseFour"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingFour"
                                            >
                                                <div className="accordion-body">
                                                    <p>After breakfast, spend the day exploring Pahalgam. Visit Chandanwari, the starting point for the Amarnath Yatra, famous for snow sledding and picturesque views. Explore Aru Valley, known for its scenic meadows and as a base for treks to Kolahoi Glacier and Tarsar Lake. Alternatively, visit Betaab Valley, named after a Bollywood movie, or enjoy the lush meadows of Baisaran Valley, often called "Mini Switzerland." Return to your hotel in Pahalgam for the night.</p>

                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay4ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay4ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day5"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseFive"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 05: PAHALGAM TO SINTHAN TOP
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseFive"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingFive"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        After an early breakfast, check out and drive to Sinthan Top. This area is gaining popularity for its stunning landscapes and is great for trekking and skiing. The drive is adventurous and offers beautiful views. Spend the day exploring Sinthan Top and return to Pahalgam for the night.
                                                    </p>

                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay5ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay5ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day6"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseSix"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 06: PAHALGAM TO SRINAGAR
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseSix"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingSix"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        After breakfast, check out and head back to Srinagar. Visit the Hazratbal Shrine and Shankaracharya Temple, known for its historical and spiritual significance. In the afternoon, explore the Mughal Gardens, including Nishat Garden, Shalimar Garden, and Chashmashahi. Return to your hotel in Srinagar for the night.
                                                    </p>

                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay6ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay6ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day7"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseSeven"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 07: SRINAGAR TO SONMARG
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseSeven"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingSeven"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        After breakfast, drive to Sonmarg, meaning "Meadow of Gold." The drive offers beautiful views of dense pine forests and snowy mountains. Sonmarg is the gateway to various trekking routes, high mountain lakes, and glaciers. You can hire ponies or vehicles to visit Thajiwas Glacier, a popular spot in summer. Return to Srinagar for the night.
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay7ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay7ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day8"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseTen"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 08:DEPARTURE FROM SRINAGAR
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseTen"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingTen"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        Check out from your hotel and transfer to Srinagar Airport for your departure flight. Your Kashmir tour concludes with our thanks for choosing us and a wish for safe travels to your next destination.
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay8ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay8ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>BEST TIME TO BOOK <strong>KASHMIR OFFBEAT PACKAGE WITH SINTHAN TOP TOUR</strong></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>BEST TIME TO BOOK <strong>KASHMIR OFFBEAT PACKAGE WITH SINTHAN TOP TOUR</strong></h4>
                                </div>
                                <div className="card-body">
                                    <p>The best time to book a <strong>Kashmir offbeat package with Sinthan Top tour</strong> with Adorable Vacation is typically between April and October. During these months, the weather is pleasant and ideal for exploring the region's scenic beauty and offbeat locations.</p>
                                    <br />
                                    <ul>
                                        <li><b>• April to June</b>: Spring and early summer offer mild temperatures and blooming landscapes, making it a great time to visit Sinthan Top and other offbeat spots.</li><br />
                                        <li><b>• July to September</b>: Summer is also a good time, though it can be a bit warmer and there may be occasional rain. The lush greenery during this period adds to the beauty of Kashmir</li><br />
                                        <li><b>• October</b>: Early autumn provides crisp weather and stunning views, with fewer tourists around, allowing for a more peaceful experience.</li>
                                    </ul>
                                    <br />
                                    <p>Booking in advance, ideally a few months ahead, ensures availability and often better rates. If you're planning to travel during peak seasons or holidays, booking even earlier is advisable.</p>
                                    <p>Here’s a chart to help you choose the best season for booking your offbeat Kashmir holiday packages:</p>
                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf">
                                                <tr className="table-danger">
                                                    <th>Season</th>
                                                    <th>Months</th>
                                                    <th>Weather</th>
                                                    <th>Highlights</th>
                                                    <th>Considerations</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td data-title="Season">Spring</td>
                                                    <td data-title="Months">April - June</td>
                                                    <td data-title="Weather">Mild and pleasant; blooming flowers</td>
                                                    <td data-title="Highlights">Beautiful landscapes; ideal for sightseeing and trekking</td>
                                                    <td data-title="Considerations">Early summer can be slightly crowded; book early for best rates</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Season">Summer</td>
                                                    <td data-title="Months">July - September</td>
                                                    <td data-title="Weather">Warm and relatively dry; lush greenery</td>
                                                    <td data-title="Highlights">Good for outdoor activities; vibrant scenery</td>
                                                    <td data-title="Considerations">Can be hot in lower areas; occasional rain; check weather forecasts</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Season">Autumn</td>
                                                    <td data-title="Months">October</td>
                                                    <td data-title="Weather">Crisp and clear; beautiful fall colours</td>
                                                    <td data-title="Highlights">Less crowded; stunning views of autumn foliage</td>
                                                    <td data-title="Considerations">Cooler temperatures; pack appropriately for varying weather</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Season">Winter</td>
                                                    <td data-title="Months">November - March</td>
                                                    <td data-title="Weather">Cold and snowy; occasional heavy snowfall</td>
                                                    <td data-title="Highlights">Snow sports; serene landscapes; fewer tourists</td>
                                                    <td data-title="Considerations">Cold temperatures; some areas may be inaccessible; ensure proper gear</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <p>Booking during the best season for your preferences ensures an enjoyable and memorable experience in Kashmir.</p>
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>FEATURES OF A KASHMIRI HOUSEBOAT: WHAT MAKES THEM UNIQUE?</b></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>FEATURES OF A KASHMIRI HOUSEBOAT: WHAT MAKES THEM UNIQUE?</b></h4>
                                </div>
                                <div className="card-body">
                                    <h5><b>Traditional Design and Decor</b></h5><br />
                                    <ul>
                                        <li><b>• Handcrafted Interiors</b>: Houseboats are beautifully decorated with intricate wood carvings and detailed designs.</li><br />
                                        <li><b>• Kashmiri Carpets and Furnishings</b>: They feature luxurious carpets and colourful textiles that add a traditional touch.</li><br />
                                        <li><b>• Decorative Elements</b>: The interiors often include local art and crafts, creating a unique atmosphere.</li>
                                    </ul><br />
                                    <h5><b>Floating Accommodation</b></h5><br />
                                    <ul>
                                        <li><b>• On the Lake</b>: Most houseboats are moored on scenic lakes like Dal Lake, offering stunning water views.</li><br />
                                        <li><b>• Unique Experience</b>: Staying on a houseboat gives you a special experience that's different from staying in regular hotels.</li>
                                    </ul><br />

                                    <h5><b>Modern Amenities</b></h5><br />
                                    <ul>
                                        <li><b>• Comfortable Bedrooms</b>: Houseboats have cosy bedrooms with comfortable beds and private bathrooms.</li><br />
                                        <li><b>• Living Areas</b>: They include spacious living rooms with comfy seating and sometimes a dining area.</li><br />
                                        <li><b>• Heating Facilities</b>: Many houseboats have heaters to keep you warm during the cold nights.</li>
                                    </ul><br />

                                    <h5><b>Private Decks and Gardens</b></h5><br />
                                    <ul>
                                        <li><b>• Open Decks</b>: Many houseboats have decks where you can relax and enjoy the beautiful views.</li><br />
                                        <li><b>• Small Gardens</b>: Some have small flower gardens or plants on the deck, adding to the charm.</li>
                                    </ul><br />

                                    <h5><b>Personalized Service</b></h5><br />
                                    <ul>
                                        <li><b>• Caring Hosts</b>: The owners often offer personal service, including local tour arrangements and home-cooked meals.</li><br />
                                        <li><b>• Local Cuisine</b>: You can enjoy traditional Kashmiri dishes prepared by the houseboat staff.</li>
                                    </ul><br />

                                    <h5><b>Scenic Views</b></h5><br />
                                    <ul>
                                        <li><b>• Lake Views</b>: You’ll wake up to amazing views of the lake, mountains, and nature.</li><br />
                                        <li><b>• Sunset and Sunrise</b>: The views of the sunset and sunrise over the lake are breath-taking.</li>
                                    </ul><br />

                                    <h5><b>Cultural Immersion</b></h5><br />
                                    <ul>
                                        <li><b>• Local Experience</b>: Staying on a houseboat lets you experience Kashmiri culture and hospitality first-hand.</li><br />
                                        <li><b>• Cultural Activities</b>: Some houseboats offer activities like traditional music performances or guided tours.</li>
                                    </ul><br />
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>TOP ATTRACTIONS AND MUST-VISIT DESTINATIONS ON AN OFFBEAT KASHMIR TOUR</b></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>TOP ATTRACTIONS AND MUST-VISIT DESTINATIONS ON AN OFFBEAT KASHMIR TOUR</b></h4>
                                </div>
                                <div className="card-body">
                                    <h5><b>Here’s a chart outlining the top attractions and must-visit destinations for an <strong>offbeat Kashmir package tour</strong>:</b></h5><br />
                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf">
                                                <tr className="table-danger">
                                                    <th>Destination</th>
                                                    <th>Highlights</th>
                                                    <th>Activities</th>
                                                    <th>Best Time to Visit</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td data-title="Destination">Sinthan Top</td>
                                                    <td data-title="Highlights">High-altitude pass with panoramic views</td>
                                                    <td data-title="Activities">Scenic drives, trekking, photography</td>
                                                    <td data-title="Best Time to Visit">May - October</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Destination">Yusmarg</td>
                                                    <td data-title="Highlights">Lush meadows surrounded by pine forests</td>
                                                    <td data-title="Activities">Picnicking, nature walks, horse riding</td>
                                                    <td data-title="Best Time to Visit">April - October</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Destination">Doodhpathri</td>
                                                    <td data-title="Highlights">Serene meadows and clear streams</td>
                                                    <td data-title="Activities">Trekking, picnicking, river crossings</td>
                                                    <td data-title="Best Time to Visit">May - October</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Destination">Gulmarg</td>
                                                    <td data-title="Highlights">Snow-capped mountains and alpine meadows</td>
                                                    <td data-title="Activities">Gondola rides, skiing, hiking</td>
                                                    <td data-title="Best Time to Visit">April - October (Winter for skiing)</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Destination">Pahalgam</td>
                                                    <td data-title="Highlights">Picturesque village with river views</td>
                                                    <td data-title="Activities">River rafting, trekking, sightseeing</td>
                                                    <td data-title="Best Time to Visit">April - October</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Destination">Aharbal Waterfall</td>
                                                    <td data-title="Highlights">Stunning waterfall in a serene setting</td>
                                                    <td data-title="Activities">Hiking, photography, picnic</td>
                                                    <td data-title="Best Time to Visit">April - October</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Destination">Baisaran</td>
                                                    <td data-title="Highlights">Known as "Mini Switzerland" with lush green meadows</td>
                                                    <td data-title="Activities">Horse riding, picnicking, nature walks</td>
                                                    <td data-title="Best Time to Visit">May - October</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Destination">Pulwama</td>
                                                    <td data-title="Highlights">Saffron fields and gardens</td>
                                                    <td data-title="Activities">Saffron harvesting, local village tours</td>
                                                    <td data-title="Best Time to Visit">September - October</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Destination">Aru Valley</td>
                                                    <td data-title="Highlights">Pristine valley with breath-taking views</td>
                                                    <td data-title="Activities">Trekking, camping, nature walks</td>
                                                    <td data-title="Best Time to Visit">May - October</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br />
                                    <p>This chart provides a quick overview of offbeat destinations in Kashmir, highlighting their unique features, activities, and the best times to visit.</p>
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>SINTHAN TOP ADVENTURES: AN EXCLUSIVE EXPERIENCE IN KASHMIR OFFBEAT TOUR PACKAGES</b></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>SINTHAN TOP ADVENTURES: AN EXCLUSIVE EXPERIENCE IN KASHMIR OFFBEAT TOUR PACKAGES</b></h4>
                                </div>
                                <div className="card-body">
                                    <h5><b>What is Sinthan Top?</b></h5><br />
                                    <p>Sinthan Top is a high-altitude mountain pass located in the southern part of Kashmir. It stands at approximately 12,000 feet above sea level and offers stunning panoramic views of the surrounding snow-capped peaks and lush green meadows. The pass is known for its untouched beauty and is less frequented by tourists, making it a perfect spot for those seeking an <strong>Offbeat Kashmir Tour</strong>.</p>
                                    <h5><b>Adventures at Sinthan Top</b></h5><br />
                                    <h5><b>1. Scenic Drives</b></h5><br />
                                    <ul>
                                        <li><b>• Experience</b>: Enjoy a breathtaking drive through winding roads that lead up to Sinthan Top. The journey itself is filled with spectacular views of the mountains and valleys.</li><br />
                                        <li><b>• Best Time</b>: May to October for clear and safe driving conditions.</li>
                                    </ul><br />
                                    <h5><b>2. Trekking</b></h5><br />
                                    <ul>
                                        <li><b>• Experience</b>: Explore the rugged terrain around Sinthan Top with various trekking routes. The trails offer diverse landscapes, including alpine meadows and rocky paths.</li><br />
                                        <li><b>• Difficulty Level</b>: Moderate to challenging, depending on the trail.</li>
                                    </ul><br />

                                    <h5><b>3. Photography</b></h5><br />
                                    <ul>
                                        <li><b>• Experience</b>: Capture stunning photographs of the panoramic vistas, snow-capped peaks, and serene landscapes. The changing light and scenery make for excellent photo opportunities.</li><br />
                                        <li><b>• Best Time</b>: Early morning or late afternoon for the best lighting.</li>
                                    </ul><br />

                                    <h5><b>4. Nature Walks</b></h5><br />
                                    <ul>
                                        <li><b>• Experience</b>: Take leisurely walks around the pass to fully appreciate the natural beauty and tranquility of the area. Enjoy the fresh mountain air and serene environment.</li><br />
                                        <li><b>• Best Time</b>: During daylight hours when the weather is clear.</li>
                                    </ul><br />

                                    <h5><b>5. Picnic</b></h5><br />
                                    <ul>
                                        <li><b>• Experience</b>: Pack a picnic and enjoy a meal amidst the stunning natural surroundings. The peaceful setting provides a perfect backdrop for a relaxing break.</li><br />
                                        <li><b>• Best Time</b>: When the weather is clear and comfortable for outdoor dining.</li>
                                    </ul><br />

                                    <h5><b>6. Wildlife Spotting</b></h5><br />
                                    <ul>
                                        <li><b>• Experience</b>: Observe local wildlife in their natural habitat. The area around Sinthan Top is home to various species, including birds and small mammals.</li><br />
                                        <li><b>• Best Time</b>: Early morning or late afternoon when animals are more active.</li>
                                    </ul><br />
                                    <h5><b>Why Choose Sinthan Top?</b></h5>
                                    <p>Sinthan Top offers an exclusive adventure away from the usual tourist spots. Its high altitude, breath-taking views, and serene environment provide a unique experience for travellers looking to explore offbeat destinations in Kashmir. Whether you're an adventure enthusiast or someone seeking tranquillity, Sinthan Top has something special to offer.</p>
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>SONMARG TOP ADVENTURES: AN EXCLUSIVE EXPERIENCE IN KASHMIR OFFBEAT TOUR PACKAGES</b></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>SONMARG TOP ADVENTURES: AN EXCLUSIVE EXPERIENCE IN KASHMIR OFFBEAT TOUR PACKAGES</b></h4>
                                </div>
                                <div className="card-body">
                                    <h5><b>What is Sonmarg?</b></h5><br />
                                    <p>Sonmarg, also known as the "Meadow of Gold," is a picturesque town in Kashmir, renowned for its stunning landscapes and serene environment. Nestled along the banks of the Sindh River and surrounded by snow-capped peaks, <strong>Kashmir Offbeat Family Packages</strong> offers a range of activities and natural beauty that make it a must-visit destination for offbeat travelers.</p>
                                    <h5><b>Top Adventures in Sonmarg</b></h5><br />
                                    <h5><b>1. Thajiwas Glacier Trek</b></h5><br />
                                    <ul>
                                        <li><b>• Experience</b>: Embark on a trek to the Thajiwas Glacier, a highlight of Sonmarg. The trek takes you through lush meadows, dense forests, and rocky terrain, culminating in a breathtaking view of the glacier.</li><br />
                                        <li><b>• Difficulty Level</b>: Moderate to challenging, suitable for avid trekkers.</li>
                                    </ul><br />

                                    <h5><b>2. River Rafting</b></h5><br />
                                    <ul>
                                        <li><b>• Experience</b>: Enjoy an adrenaline-pumping river rafting experience on the Sindh River. The rapids offer a thrilling adventure, with stunning views of the surrounding landscapes.</li><br />
                                        <li><b>• Best Time</b>: May to September when the river is at its best for rafting.</li>
                                    </ul><br />

                                    <h5><b>3. Horse Riding</b></h5><br />
                                    <ul>
                                        <li><b>• Experience</b>: Explore Sonmarg’s beautiful meadows and trails on horseback. Horse riding is a popular way to cover more ground and enjoy the scenic beauty at a relaxed pace.</li><br />
                                        <li><b>• Best Time</b>: May to October, when the weather is pleasant.</li>
                                    </ul><br />

                                    <h5><b>4. Camping</b></h5><br />
                                    <ul>
                                        <li><b>• Experience</b>: Set up a camp in the tranquil meadows of Sonmarg and spend a night under the stars. Camping allows you to fully immerse yourself in the natural beauty and enjoy the peaceful surroundings.</li><br />
                                        <li><b>• Best Time</b>: Summer months (May to September) for comfortable temperatures and clear skies.</li>
                                    </ul><br />

                                    <h5><b>5. Fishing</b></h5><br />
                                    <ul>
                                        <li><b>• Experience</b>: Try your hand at fishing in the Sindh River, known for its clear waters and abundant trout. It’s a relaxing activity that offers a chance to enjoy the scenic beauty while engaging in a leisurely pursuit.</li><br />
                                        <li><b>• Best Time</b>: May to October, when fishing conditions are optimal.</li>
                                    </ul><br />

                                    <h5><b>6. Nature Walks</b></h5><br />
                                    <ul>
                                        <li><b>• Experience</b>: Take leisurely walks through Sonmarg’s lush meadows, picturesque valleys, and alongside the Sindh River. The peaceful environment and stunning views make nature walks a delightful experience.</li><br />
                                        <li><b>• Best Time</b>: During daylight hours, especially in the summer months.</li>
                                    </ul><br />

                                    <h5><b>7. Visit to Betaab Valley</b></h5><br />
                                    <ul>
                                        <li><b>• Experience</b>: Explore Betaab Valley, named after a Bollywood movie shot here. The valley offers beautiful landscapes, with lush greenery and snow-capped mountains providing a stunning backdrop.</li><br />
                                        <li><b>• Best Time</b>: May to October for clear and pleasant weather.</li>
                                    </ul><br />
                                    <p><strong>Kashmir Offbeat Holiday Package</strong> offers a unique blend of adventure and natural beauty. From thrilling activities like river rafting and glacier trekking to serene experiences like camping and nature walks, Sonmarg provides an exclusive escape from the usual tourist spots. Its stunning landscapes and range of activities make it an ideal destination for an offbeat Kashmir adventure.</p>
                                </div>
                            </div>
                            <br /><br />
                            {/* <!--insert table inclusion exclusion--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        {
                                            <InclusionExclusion />
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* <!--end table inclusion exclusion--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center" >
                                            <h2>The Khyber Himalayan Resort & Spa <span>- Gulmarg</span></h2>
                                            <h4><i>Next Level Luxury Resort </i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row py-3">
                                    <img src={brandedTourackageImage_1_URL} alt="..." />
                                </div>

                                <div id="no-more-tables" >
                                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                        <thead>
                                            <tr className="table-danger">
                                                <th>Category</th>
                                                <th>Size(Room)</th>
                                                <th>Hotel Brand</th>
                                                <th>Price (Per Night)</th>
                                                <th>Book Now</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td data-title="Packages">Platinum Accommodation</td>
                                                <td data-title="Size(Room)">386+ SQ.FT</td>
                                                <td data-title="Hotel Brand">Khyber Hotels (Heritage)<div className="reviews">
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                </div></td>
                                                <td data-title="Price (Per Night)">Rs 53,119/- </td>
                                                <td data-title="Book Now"><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick}>
                                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                                </a></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                <div className="card mx-0 my-5">
                                    <div className="card-header custHeaer">
                                        <h3 className='content-desktop' style={{ fontSize: "1.5vw" }}><b>HEAVEN IS CLOSER THAN YOU CAN IMAGINE</b></h3>
                                        <h3 className='content-mobile2' style={{ fontSize: "3.5vw" }}><b>HEAVEN IS CLOSER THAN YOU CAN IMAGINE</b></h3>
                                    </div>
                                    <div className="card-body">
                                        <p ><b>8,825 feet</b> above sea level in the Pir Panjal range of the Himalayas lies a higher order of luxury. <b>The Khyber Himalayan Resort & Spa</b> is spread over seven unspoilt, fir-laden acres, with majestic views of the Affarwat Peaks. This 85-room resort is framed at every vantage point by nature at its most spectacular. <b>The world’s highest ski lift</b>, the Gulmarg Gondola – which takes you to Kongdoori Mountain, is only a short walk away. Meadows and forests that have enchanted travellers for centuries wait to be explored by trekking enthusiasts and undying romantics. Clad in locally sourced pine and slate, the resort is built in a style true to the architectural heritage of Kashmir. In every way the indoors mirror the beauty of the outdoors. Specialty restaurants, a cigar lounge are amongst the necessary luxuries in place. Add to these other thoughtful additions like our children’s club, a mini theatre and a dedicated Activities Concierge and you will find The Khyber is a resort at par with the best in the world.</p>

                                        <a href="tel://9883359713" className="btn btn-Warning">Book Now</a>

                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2 >The LaLiT Grand Place <span>- Srinagar</span></h2>
                                            <h4 ><i>Fill the Luxury</i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row py-3">
                                    <img src={brandedTourackageImage_2_URL} alt="..." />
                                </div>

                                <div id="no-more-tables" >
                                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                        <thead>
                                            <tr className="table-danger">
                                                <th>Category</th>
                                                <th>Size(Room)</th>
                                                <th>Hotel Brand</th>
                                                <th>Price (Per Night)</th>
                                                <th>Book Now</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td data-title="Packages">Premium Accommodation</td>
                                                <td data-title="Size(Room)">290+ SQ.FT</td>
                                                <td data-title="Hotel Brand">The LaLiT Hotels<div className="reviews">
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                </div></td>
                                                <td data-title="Price (Per Night)">Rs 28,332/- </td>
                                                <td data-title="Book Now"><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick}>
                                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                                </a></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                <div className="card mx-0 my-5">
                                    <div className="card-header custHeaer">
                                        <h3 className='content-desktop' style={{ fontSize: "1.5vw" }}><b>EMBARKED ON A SENSE OF PRIDE AT PREMIUMNESS</b></h3>
                                        <h3 className='content-mobile2' style={{ fontSize: "3.5vw" }}><b>EMBARKED ON A SENSE OF PRIDE AT PREMIUMNESS</b></h3>
                                    </div>
                                    <div className="card-body">
                                        <p ><b>The LaLit Grand Palace</b> was originally built in 1901 for the royal family. It now provides luxurious rooms with views of Dal Lake and the beautiful gardens. An indoor pool, tennis courts and pampering spa treatments are also available.The tastefully designed rooms are modern with a touch of traditional charm. All have a satellite TV, minibar and free tea/coffee making facilities. It is 18 km from Srinagar Airport and 4 km from Lal Chowk city centre. Jammu Railway Station is 300 km away. Guests can enjoy a relaxing massage at Rejuve Spa Health Club, practice yoga, or play golf. The hotel also has a gym and 24-hour business center. The Chinar Garden serves North Indian cuisine, while international buffet spreads are available at The Chinar – The All Day Coffee Shop. Other dining options include Dal Bar and 24-hour room service.</p>

                                        <a href="tel://9883359713" className="btn btn-Warning">Book Now</a>

                                    </div>
                                </div>
                            </div>

                            {/* <!--start of package details--> */}
                            <div className="container-fluid py-5">

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>Kashmir<span> Tour With SinthanTop</span></h2>
                                            <h4>Related Kashmir Tour plan</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                                        <div className="carousel-inner">
                                            <div className="carousel-item active">

                                                <Slider {...settings}>
                                                    {hotPackagesView}
                                                </Slider>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!--end of package details--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2 >Frequently Ask <span>Questions</span></h2>
                                            <h4 >Kashmir Offbeat Tour Package With SinthanTop Related Questions</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="row" >
                                    <div className="accordion" id="accordionPanelsStayOpenExample">
                                        <div className="accordion" id="accordionFlushExample">
                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingOne">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                                        What is included in the Kashmir Offbeat Tour Package?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseOne" className="accordion-collapse collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        The package generally includes accommodation for 7 nights, breakfast and dinner (MAP), all transfers and sightseeing by non-AC vehicles, a 1-hour Shikara ride on Dal Lake, and necessary permits for visiting restricted areas. Taxes and toll fees are also covered.
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingTwo">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                                        What are the key attractions in the Kashmir Offbeat Tour?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseTwo" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        This tour covers several offbeat destinations like Pahalgam, Gulmarg, Sonmarg, and Sinthan Top. Key attractions include Thajiwas Glacier, Betaab Valley, Aru Valley, and the scenic Sinthan Top, known for its breathtaking views and adventure opportunities.
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingThree">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                                        What is Sinthan Top, and why is it special?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseThree" className="accordion-collapse collapse show" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        Sinthan Top is a high-altitude mountain pass located in the Anantnag district of Kashmir. It’s known for its stunning panoramic views, snow-covered peaks, and opportunities for trekking, skiing, and other adventure activities. It’s a less crowded destination, offering a peaceful escape from the usual tourist spots.
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingFour">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                                        When is the best time to visit Sinthan Top?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseFour" className="accordion-collapse collapse show" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        The best time to visit Sinthan Top is from May to September when the weather is pleasant, and the roads are accessible. During these months, the snow has melted enough to allow safe travel while still offering beautiful snowy vistas.
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingFive">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                                                        Are there any special permits required to visit Sinthan Top?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseFive" className="accordion-collapse collapse show" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        Yes, an inner line permit is required to visit Sinthan Top as it is in a restricted area. These permits are usually included in the tour package.
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingSix">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                                                        What kind of activities can I do at Sinthan Top?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseSix" className="accordion-collapse collapse show" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        At Sinthan Top, you can enjoy trekking, hiking, photography, and in the winter months, skiing. The area is also great for picnics and simply soaking in the stunning natural beauty.
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingSeven">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
                                                        Is the Kashmir Offbeat Tour suitable for families?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseSeven" className="accordion-collapse collapse show" aria-labelledby="flush-headingSeven" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        Yes, this tour is suitable for families. It offers a mix of adventure, relaxation, and sightseeing that can be enjoyed by all age groups.
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingEight">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
                                                        What type of accommodation is provided in the tour package?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseEight" className="accordion-collapse collapse show" aria-labelledby="flush-headingEight" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        The package includes stays in comfortable hotels or houseboats, depending on the location. The accommodations are chosen for their quality and proximity to key attractions.
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingNine">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseNine">
                                                        What should I pack for the Kashmir Offbeat Tour?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseNine" className="accordion-collapse collapse show" aria-labelledby="flush-headingNine" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        It’s important to pack warm clothing, especially if you’re traveling in the cooler months. Comfortable walking shoes, a good camera, sunscreen, and any personal medication are also recommended.
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingTen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
                                                        Are meals included in the tour package?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseTen" className="accordion-collapse collapse show" aria-labelledby="flush-headingTen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        Yes, the package includes a meal plan with breakfast and dinner. Non-vegetarian options on houseboats may not be included, so it’s best to confirm meal preferences in advance.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center" >
                                            <h3><b>Kashmir <span class="tomato">Holiday Reviews</span></b></h3>
                                            <h4><i>"Your Experience is our Marketing"</i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-cols-1">
                                    <div id="testimonials">
                                        <div className="testimonial-box-container" >

                                            {reviewersDataView}

                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="col-md-3">
                            <BookingBox />
                        </div>
                    </div >
                </div >
                <div class="btn-call-pulse">
                    <a style={{ color: 'white' }} href="tel:+91-91470-61467">
                        <i className="fa fa-phone"></i>
                        {/* <span><small>+91 9147061467</small></span> */}
                    </a>
                </div>
                <div className="btn-whatsapp-pulse">
                    <a style={{ color: 'white' }} href="#" onClick={this.handleClick}>
                        <i className="fab fa-whatsapp"></i>
                        {/* <span>
                                <small>+91 9883359713</small>
                            </span> */}
                    </a>
                </div>

            </>
        );
    }
}

export default Kashmir_7N_8D_Sinthan_Top_Package;