import React, { Component } from 'react';
import MainHeader from '../../../components/common/MainHeader';
import FooterDesktop from '../../../components/common/FooterDesktop';
import Meghalaya_6N_7D_Package from '../../../components/package/Meghalaya/Meghalaya_6N_7D_Package';
import { Helmet } from "react-helmet";

class Meghalaya_6N_7D_Page extends Component {
    constructor() {
        super();
        this.state = {
            TrendingPackagesData: [],
            ReviewersData: [],
        }
    }

    componentDidMount() {
        window.scroll(0, 0);
        const TrendingPackageDataList = [
            {
                Title: "Untouched Manipur",
                ImageName: "5N-6D-manipur.webp",
                AllternateText: "...",
                Description:"2N Kohima | 1N Imphal | 1N Moreh | 1N Imphal | Private Transfer | Deluxe Hotel  ",
                PricePerNight: "24,990",
                NoOfTours: 47,
                NoOfDeparture: 40,
                Include: "EX-GAU",
                MutedText: "*Per head / Min 6 Pax"
            },
            {
                Title: "Peaceful Meghalaya",
                ImageName: "6N-7D-meghalaya.webp",
                AllternateText: "...",
                Description: "2N Kaziranga | 3N Shillong | 1N Guwahati | Private Transfer | Deluxe Hotel",
                PricePerNight: "22,800",
                NoOfTours: 43,
                NoOfDeparture: 38,
                Include: "EX-GAU",
                MutedText: "*Per head / Min 6 Pax"
            },
            {
                Title: "Unexplored Manipur",
                ImageName: "7N-8D-meghalaya.webp",
                AllternateText: "...",
                Description: "2N Kaziranga | 2N Kohima | 3N Imphal | Private Transfer | Deluxe Hotel",
                PricePerNight: "27,900",
                NoOfTours: 44,
                NoOfDeparture: 37,
                Include: "EX-GAU",
                MutedText: "*Per head / Min 6 Pax"
            },

            {
                Title: "Extended Meghalaya",
                ImageName: "8N-9D-meghalaya.webp",
                AllternateText: "...",
                Description: "2N Kaziranga | 3N Shillong | 1N Cherrapunjee...more | Private Transfer | Deluxe Hotel",
                PricePerNight: "33,700",
                NoOfTours: 46,
                NoOfDeparture: 39,
                Include: "EX-GAU",
                MutedText: "*Per head / Min 6 Pax"
            },
            {
                Title: "Glimpses of North East",
                ImageName: "10N-11D-sangti-vally.webp",
                AllternateText: "...",
                Description: "2N Guwahati | 1N Kaziranga | 1N Bhalukpong...more | Private Transfer | Deluxe Hotel",
                PricePerNight: "28,300",
                NoOfTours: 44,
                NoOfDeparture: 37,
                Include: "EX-GAU",
                MutedText: "*Per head / Min 6 Pax"
            },
            {
                Title: "Extended North East",
                ImageName: "11N-12D-madhuri.webp",
                AllternateText: "...",
                Description: "2N Guwahati | 3N Shillong | 1N Kaziranga...more | Private Transfer | Deluxe Hotel",
                PricePerNight: "53,899",
                NoOfTours: 47,
                NoOfDeparture: 40,
                Include: "EX-GAU",
                MutedText: "*Per head / Min 6 Pax"
            }

        ];

        const reviewersDataList = [
            {
                Name: "Aditya Shah",
                ImageName: "Aditya-Shah.webp",
                AllternateText: "...",
                Location: "@Mumbai",
                Comment: "The trip was one of the most memorable experiences of my life .The people there were so friendly and jovial.The food was awesome and the rooms were comfortable and the staffs were always alert about our needs and comfort. I would definitely go with them on trips in future...actually am already planning my next trip with them. Its really hard to find such good and reliable people when you are on a trip. I will high recommend them to everyone .And last but not the least I would like to thank Mr Soumya and Ms Anushka for planning such a wonderful trip for us ❤️.",
            },
            {
                Name: "Mayur Gupta",
                ImageName: "Mayur-Gupta.webp",
                AllternateText: "...",
                Location: "@pune",
                Comment: "Last week we went to Assam Meghalaya with Family. The full tour was arranged by Adorable Vacation. Mr Akash prepared the itinerary and we had a pleasant stay and experience. Would like to travel again in future with Adorable Vacation, One of the best tour operator for Meghalaya.",
            },
            {
                Name: "Rehea Agarwal",
                ImageName: "Rehea-Agarwal.webp",
                AllternateText: "...",
                Location: "@Hyderabad",
                Comment: "Me and my son went to Meghalaya with Adorable Vacation. Our trip to Meghalaya with Adorable Vacation was an enjoyable and memorable one. Our contact person, Ms Anushka, was very helpful and regularly kept in contact with us to ensure that we were not suffering from any difficulties. The driver was also very good and led us to all the sightseeing spots without any delay. The hotels given to us were very beautiful, comfortable, and clean. Meghalaya is an extremely beautiful place to visit with gorgeous views, and I would definitely recommend anyone to travel with Adorable Vacation.",
            },
            {
                Name: "Mustafa Banatwala",
                ImageName: "Mustafa-Banatwala.webp",
                AllternateText: "...",
                Location: "@Mumbai",
                Comment: "Enjoyed the trip very much..Akash and Anushka both of them are very co operative and helpful.They help us with informations thoroughly throughout our trip.We are very much pleased with Team Adorable.Thank you so much to them to make our North East moments memorable.😊",
            },
        ]

        this.setState({
            TrendingPackagesData: TrendingPackageDataList,
            ReviewersData: reviewersDataList
        })
    }

    render() {
        return (
            <>
            <Helmet>
                    <title>Assam Meghalaya 6N 7D Tour Itinerary</title>
                </Helmet>
                <MainHeader />
                <Meghalaya_6N_7D_Package
                    TrendingPackagesData={this.state.TrendingPackagesData}
                    ReviewersData={this.state.ReviewersData}
                />
                <FooterDesktop />
            </>
        );
    }
}

export default Meghalaya_6N_7D_Page;