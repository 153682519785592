import React, {Component, Fragment} from 'react';
import {Breadcrumb, Card, Col, Container, Row} from "react-bootstrap";

class Purchase extends Component {
    render() {
        return (
            <Fragment>
                <Container className="TopSection">                    
                    <Breadcrumb className="w-100 bg-white">
                        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                        <Breadcrumb.Item>How To Purchase</Breadcrumb.Item>
                    </Breadcrumb>
                    <Row>
                        <Col className="mt-2" md={12} lg={12} sm={12} xs={12}>
                            <Card>
                                <Card.Header>
                                    <h3>Purchase Policy</h3>
                                </Card.Header>
                                <Card.Body>
                                    <div style={{ marginBottom: "20px"}}>
                                        <p>
                                            Lorem ipsum is a social enterprise which strives to maximize the affordability of quality education by bridging the gap between students and credible institutes. Lorem ipsum vouchers are safe, trusted, protected and 100% secured with PCI-DSS Level 1 compliant payment gateway. Lorem ipsum cashback vouchers help students get a part of their course fee in the form of guaranteed cashback.<br/>Lorem ipsum aspires to uphold the spirit of inclusivity in education. We help educational institutes find talented and prospective minds whom they can train and transform into inspiring professionals and citizens of better tomorrow. We do also strive to maximize the affordability and accessibility of education among students. We are committed to bridge the gap between educational institutes and talented prospects in every possible manner. We do firmly believe that education is the only weapon which we can use to create a better tomorrow. Hence, we invite every single individual, organization or corporate entity who share similar belief to support our cause of making education universally accessible and useful.
                                        </p>
                                    </div>
                                    <div style={{ marginBottom: "20px"}}>
                                        <h5><strong>Our Mission:</strong></h5>
                                        <p>Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have scrambled parts of Cicero's De Finibus Bonorum et Malorum for use in a type specimen book.</p>
                                    </div>

                                    <div style={{ marginBottom: "20px"}}>
                                        <h5><strong>Our Vision:</strong></h5>
                                        <p>Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have scrambled parts of Cicero's De Finibus Bonorum et Malorum for use in a type specimen book..</p>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default Purchase;