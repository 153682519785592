import React, { Component } from 'react';
import MainHeader from '../../../components/common/MainHeader';
import FooterDesktop from '../../../components/common/FooterDesktop';
import Sikkim_5N_6D_Package from '../../../components/package/Sikkim_Darjeeling/Sikkim_5N_6D_Package';
import { Helmet } from "react-helmet";

class Sikkim_5N_6D_Page extends Component {
    constructor() {
        super();
        this.state = {
            HotPackagesData: [],
            HotelsData: [],
            ReviewersData: [],
        }
    }

    componentDidMount() {
        window.scroll(0, 0);
        const hotPackageDataList = [
            {
                Title: "Sikkim for Soulmates",
                ImageName: "Sikkim-Tour-4N-5D.webp",
                AllternateText: "...",
                Description: "2N Gangtok | 2N Darjeeling | 3 Star Hotel",
                PricePerNight: "12,999",
                NoOfTours: 42,
                NoOfDeparture: 35,
                SDFInclude: "SDF Inc.",
                MutedText: "*Per head / Min 6 Pax",
                book: "Book Now",
                Url: 'tel://9147061467'
            },
            {
                Title: "Romanticize Sikkim",
                ImageName: "Sikkim-Tour-5N-6D.webp",
                AllternateText: "...",
                Description: "3N Gangtok | 1N Lachen | 1N Lachung | 3 Star Hotel",
                PricePerNight: "21,260",
                NoOfTours: 45,
                NoOfDeparture: 39,
                SDFInclude: "SDF Inc.",
                MutedText: "*Per head / Min 6 Pax",
                book: "Book Now",
                Url: 'tel://9147061467'
            },
            {
                Title: "Mesmerizing Sikkim",
                ImageName: "North-Sikkim-Packages-from-Gangtok-6N-7D.webp",
                AllternateText: "...",
                Description: "2N Gangtok | 1N Lachen | 1N Lachung | 2N Darjeeling | 3 Star Hotel",
                PricePerNight: "22,400",
                NoOfTours: 43,
                NoOfDeparture: 38,
                SDFInclude: "SDF Inc.",
                MutedText: "*Per head / Min 6 Pax",
                book: "Book Now",
                Url: 'tel://9147061467'
            },

            {
                Title: "Iconic Sikkim",
                ImageName: "North-Sikkim-Tour-5N-6D.webp",
                AllternateText: "...",
                Description: "3N Gangtok | 2N Darjeeling | 3 Star Hotel",
                PricePerNight: "15,120",
                NoOfTours: 47,
                NoOfDeparture: 40,
                SDFInclude: "SDF Inc.",
                MutedText: "*Per head / Min 6 Pax",
                book: "Book Now",
                Url: 'tel://9147061467'
            },
            {
                Title: "Pleasing Sikkim",
                ImageName: "Extended-Sikkim-Packages-7N-8D.webp",
                AllternateText: "...",
                Description: "3N Gangtok | 1N Lachen | 1N Lachung | 2N Darjeeling | 3 Star Hotel",
                PricePerNight: "21,126",
                NoOfTours: 44,
                NoOfDeparture: 37,
                SDFInclude: "SDF Inc.",
                MutedText: "*Per head / Min 6 Pax",
                book: "Book Now",
                Url: 'tel://9147061467'
            },
            {
                Title: "Extended Sikkim",
                ImageName: "Complete-Sikkim-Tour-Package-8N-9D.webp",
                AllternateText: "...",
                Description: "2N Gangtok | 1N Pelling | 1N Ravangla | 2 More.. | 3 Star Hotel",
                PricePerNight: "22,327",
                NoOfTours: 46,
                NoOfDeparture: 39,
                SDFInclude: "SDF Inc.",
                MutedText: "*Per head / Min 6 Pax",
                book: "Book Now",
                Url: 'tel://9147061467'
            }

        ];

        const hotelDataList = [
            {
                HotelName: 'Cilantro, Gangtok',
                ImageName: "Cilantro-Gangtok.webp",
                AllternateText: "...",
                HotelStar: '★★★',
                RatingBoxStar: '★',
                RatingValue: 4.9,
                Reviews: '50 Reviews'
            },
            {
                HotelName: 'Alpine Hills Retreat, Gangtok',
                ImageName: "Alpine-Hills-Gangtok.webp",
                AllternateText: "...",
                HotelStar: '★★★',
                RatingBoxStar: '★',
                RatingValue: 4.8,
                Reviews: '47 Reviews'
            },
            {
                HotelName: 'Sikkim Retreat, Gangtok',
                ImageName: "Sikkim-Retreat-Gangtok.webp",
                AllternateText: "...",
                HotelStar: '★★★',
                RatingBoxStar: '★',
                RatingValue: 4.7,
                Reviews: '46 Reviews'
            },
            {
                HotelName: 'Royal, Lachung',
                ImageName: "Royal-Lachung.webp",
                AllternateText: "...",
                HotelStar: '★★★',
                RatingBoxStar: '★',
                RatingValue: 4.6,
                Reviews: '45 Reviews'
            },
            {
                HotelName: 'Park Place, Lachung',
                ImageName: "Park-Palace-Lachung.webp",
                AllternateText: "...",
                HotelStar: '★★★',
                RatingBoxStar: '★',
                RatingValue: 4.9,
                Reviews: '49 Reviews'
            },
            {
                HotelName: 'Norling Zinkham, Lachung',
                ImageName: "Norling-Zinkham-Lachung.webp",
                AllternateText: "...",
                HotelStar: '★★★',
                RatingBoxStar: '★',
                RatingValue: 4.8,
                Reviews: '46 Reviews'
            },
            {
                HotelName: 'Deezong, Lachen',
                ImageName: "Lachen-Deezong.webp",
                AllternateText: "...",
                HotelStar: '★★★',
                RatingBoxStar: '★',
                RatingValue: 4.6,
                Reviews: '48 Reviews'
            },
            {
                HotelName: 'Royal, Lachen',
                ImageName: "Royal-Lachen.webp",
                AllternateText: "...",
                HotelStar: '★★★',
                RatingBoxStar: '★',
                RatingValue: 4.5,
                Reviews: '45 Reviews'
            },
        ];

        const reviewersDataList = [
            {
                Name: "Viraj Shah",
                ImageName: "himadri_sen.jpg",
                AllternateText: "...",
                Location: "@Bangaluru",
                Comment: "I am Going to review a beautiful Place, which is situated in northeast India.Its name is Sikkim.It is bordered by Tibet .I think it is a good tourist destination.It is known for its Greenery and wide range of mountains.Buddhism is basically practiced here .There are lot of beautiful lakes and rivers which enhances the beauty of Sikkim.",
            },
            {
                Name: "Preeti Mehta",
                ImageName: "sarbari_ghosal.jpg",
                AllternateText: "...",
                Location: "@Delhi",
                Comment: "A peron who loves trekking adventures, different food and culture you can take advantage of this heaven. how I called Sikkim is a hidden heaven in the earth . whereas you can feel like a dream world with a snow fall and a scenary that you show in your old book. I think just keep quite and keep keep watching all around you honestly ??",
            },
            {
                Name: "Ronit Paul",
                ImageName: "ronit_paul.jpg",
                AllternateText: "...",
                Location: "@Pune",
                Comment: "Sikkim is a beautiful place to go , it a good tourist place , you must try to go once.The food , the place, the temple, the natural beauty the people maintained, is unexplained .If you want a breaks from work or a daily activity try Sikkim , it beautiful.",
            },
            {
                Name: "Raja Basu",
                ImageName: "raja_basu.jpg",
                AllternateText: "...",
                Location: "@Kolkata",
                Comment: "Sikkim tours I am so happy when I am going to sikkim tours.the people of Sikkim is so good .the climate of Sikkim was very nice .the best place to visit is Gangtok mg marg",
            },
       
        ];


        this.setState({
            HotPackagesData: hotPackageDataList,
            HotelsData: hotelDataList,
            ReviewersData: reviewersDataList
        })
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>7 Sikkim Tour Packages - Sikkim Trip Packages with Great Deals</title>
                    <meta name="description" content="Sikkim Tour Packages - Get Sikkim trip packages at best prices. Book Sikkim Tours starting from Rs. 12,090 with assured health & safety measures from Adorable Vacation." />
                </Helmet>
                
                <MainHeader />
                <Sikkim_5N_6D_Package
                    HotPackagesData={this.state.HotPackagesData}
                    HotelsData={this.state.HotelsData}
                    ReviewersData={this.state.ReviewersData}
                />
                <FooterDesktop />
            </>
        );
    }
}

export default Sikkim_5N_6D_Page;