import React, { Component } from "react";
import BookingBox from "../BookingBox";
import Slider from "react-slick";
import ApiURL from "../../../api/ApiURL";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./InclusionExclusion";
import InclusionExclusion from "./InclusionExclusion_Pune";

class PuneToBhutanPackage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customActiveTab: "tab1",
    };
  }

  handleCustomTabClick = (tab) => {
    this.setState({
      customActiveTab: tab,
    });
  };

  render() {
    const { customActiveTab } = this.state;
    const packageTopBannerImageURL = ApiURL.BhutanTourPackageFromPuneImageURL + "/Bhutan-Tour Package-from-Pune.webp";
    const packageTopBanner2ImageURL = ApiURL.BhutanTourPackageFromPuneImageURL + "/Bhutan-Tour Packages-from-Pune.webp";
    const sightseen1ImageURL = ApiURL.BhutanTourPackageFromPuneImageURL + "/BEST-TIME-TO-BOOK-A-PUNE-TO-BHUTAN-TOUR-PACKAGES (3).webp";
    const sightseen2ImageURL = ApiURL.BhutanTourPackageFromPuneImageURL + "/BEST-TIME-TO-BOOK-A-PUNE-TO-BHUTAN-TOUR-PACKAGES (2).webp";
    const sightseen3ImageURL = ApiURL.BhutanTourPackageFromPuneImageURL + "/BEST-TIME-TO-BOOK-A-PUNE-TO-BHUTAN-TOUR-PACKAGES.webp";
    const sightseen4ImageURL = ApiURL.BhutanTourPackageFromPuneImageURL + "/MUST-VISIT-PLACES-DURING-YOUR-BHUTAN-TRIP (2).webp";
    const sightseen5ImageURL = ApiURL.BhutanTourPackageFromPuneImageURL + "/MUST-VISIT-PLACES-DURING-YOUR-BHUTAN-TRIP (3).webp";
    const sightseen6ImageURL = ApiURL.BhutanTourPackageFromPuneImageURL + "/MUST-VISIT-PLACES-DURING-YOUR-BHUTAN-TRIP.webp";
    const sightseen7ImageURL = ApiURL.BhutanTourPackageFromPuneImageURL + "/Places-of-attractions-in-Bhutan (3).webp";
    const sightseen8ImageURL = ApiURL.BhutanTourPackageFromPuneImageURL + "/Places-of-attractions-in-Bhutan (2).webp";
    const sightseen9ImageURL = ApiURL.BhutanTourPackageFromPuneImageURL + "/Places-of-attractions-in-Bhutan.webp";
    const sightseen10ImageURL = ApiURL.BhutanTourPackageFromPuneImageURL + "/BHUTAN-CULTURE (2).webp";
    const sightseen11ImageURL = ApiURL.BhutanTourPackageFromPuneImageURL + "/BHUTAN-CULTURE (3).webp";
    const sightseen12ImageURL = ApiURL.BhutanTourPackageFromPuneImageURL + "/BHUTAN-CULTURE.webp";
    const navDay1ImageURL = ApiURL.BhutanTourPackageFromPuneImageURL + "/Phuentsholing-The-Gateway-of-Bhutan-by-Road-from-India.webp";
    const navDay2ImageURL = ApiURL.BhutanTourPackageFromPuneImageURL + "/Phuentsholing-to-Thimphu-via-the-wonderful-mystic-town-Gedu.webp";
    const navDay3ImageURL = ApiURL.BhutanTourPackageFromPuneImageURL + "/Sightseeing-in-Thimphu.webp";
    const navDay4ImageURL = ApiURL.BhutanTourPackageFromPuneImageURL + "/Road-trip-from-Thimphu-to-Punakha-Wangdue-Phodrang.webp";
    const navDay5ImageURL = ApiURL.BhutanTourPackageFromPuneImageURL + "/Punakha-Wangdue-and-drive-to-Paro-via-Dochu-La-Pass.webp";
    const navDay6ImageURL = ApiURL.BhutanTourPackageFromPuneImageURL + "/Excursion-tour-to-Taktshang-Monastery.webp";
    const navDay7ImageURL = ApiURL.BhutanTourPackageFromPuneImageURL + "/Lataguri-Jeep-Safari.webp";

    const settings = {
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    const hotelDataList = this.props.HotelsData;
    const hotelDataView = hotelDataList.map((hotelItem, i) => {
      const hotelImageURL =
        ApiURL.BhutanTourPackageFromPuneImageURL + "/" + hotelItem.ImageName;
      return (
        <div className="col-md-3" key={i}>
          <img
            src={hotelImageURL}
            className="rounded mx-auto d-block"
            alt={hotelItem.AllternateText}
          />
          <br />
          <span>
            <b>{hotelItem.HotelName}</b> |
            <span className="tomato">{hotelItem.HotelStar}</span>
          </span>
          <br />
          <span className="rating-box">
            <span className="star">{hotelItem.RatingBoxStar}</span>
            <b className="rating-value">{hotelItem.RatingValue}</b>
          </span>{" "}
          &nbsp;<b>{hotelItem.Reviews}</b>
        </div>
      );
    });

    const themePackageSliderList = this.props.ThemePackagesData;
    const themePackagesDataView = themePackageSliderList.map(
      (sliderItem, i) => {
        const sliderImageURL =
          ApiURL.BhutanTourPackageFromPuneImageURL +
          "/" +
          sliderItem.ImageName;
        return (
          <div className="theme-cards-wrapper mx-2" key={i}>
            <div className="theme-card">
              <div className="image-wrapper">
                <img src={sliderImageURL} alt={sliderItem.AllternateText} />
              </div>
              <div className="card-body">
                <h5 className="card-title">{sliderItem.Title}</h5>
                <p className="card-text">{sliderItem.Description}</p>
                <Link to={"#"} className="btn btn-primary">
                  Book Now
                </Link>
              </div>
            </div>
            <div className="clearfix"></div>
          </div>
        );
      }
    );

    const trendingBhutanDataList = this.props.TrendingBhutanDataList;
    const trendingBhutanDataView = trendingBhutanDataList.map(
      (sliderItem, i) => {
        const trendingBhutanImgURL = ApiURL.BhutanTourPackageFromPuneImageURL + "/" + sliderItem.ImageName;
        return (
          <div className="theme-cards-wrapper mx-2" key={i}>
            <div className="theme-card">
              <div className="image-wrapper">
                <img src={trendingBhutanImgURL} alt="..." />
              </div>
              <div className="card-body">
                <h6 className="card-title">{sliderItem.Title}<span> &#8377; {sliderItem.PricePerNight}/-</span></h6>
                <p className="card-text">
                  <i className="fa fa-building tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-utensils tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-car-side tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-male tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-camera tomato"></i>
                </p>
                <p className="card-text"><span>{sliderItem.Description}</span></p>
                <p>
                  <span><b className="tomato">{sliderItem.NoOfTours} </b>Tours</span> |
                  <span><b className="tomato">{sliderItem.NoOfDeparture} </b>Departures</span> |
                  <span><b className="sdf-blue">SDF Inc.</b></span>
                </p>
                <div className="card-footer">
                  <small className="text-muted"><strong className="tomato">EX-IXB</strong></small>
                  <Link to={sliderItem.BookNowURL} className="btn btn-Warning btn-sm float-end">
                    Book Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        );
      }
    );

    const reviewersDataList = this.props.ReviewersData;
    const reviewersDataView = reviewersDataList.map((dataItem, i) => {
      const reviewerImageURL =
        ApiURL.BhutanTourPackageFromPuneImageURL + "/" + dataItem.ImageName;
      return (
        <div className="testimonial-box" key={i}>
          <div className="box-top">
            <div className="profile">
              <div className="profile-img">
                <img src={reviewerImageURL} alt="..." />
              </div>
              <div className="name-user">
                <strong>{dataItem.Name}</strong>
                <span>{dataItem.Location}</span>
              </div>
            </div>
            <div className="reviews">
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
            </div>
          </div>
          <div className="client-comment">
            <p>{dataItem.Comment}</p>
          </div>
        </div>
      );
    });

    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <img
              src={packageTopBannerImageURL}
              className="mobile-image2"
              alt="..."
            />
          </div>
        </div>

        <div className="container-fluid custom-container py-3">
          <div className="row">
            <div className="col-md-9">
              <div className="row">
                <div className="col-md-12">
                  <div className="container-fluid custom-container py-1">
                    <h1 className="content-desktop" style={{ fontSize: "2vw" }}><center>Book <strong>Bhutan Package Tour from Pune</strong></center></h1>

                    <h1 className="content-mobile" style={{ fontSize: "4vw" }}><center>Book <strong>Bhutan Package Tour from Pune</strong></center>
                      <hr />
                    </h1>
                    <div className="row">
                      <img src={packageTopBanner2ImageURL} className="mobile-image" alt="..." />

                    </div>
                    <hr />
                    <div className="d-flex flex-column flex-sm-row align-items-sm-center px-2">
                      <div className="font-lg">
                        5 Cities:{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Phuentsholing
                        </a>
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Thimphu
                        </a>
                        &nbsp;{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Paro
                        </a>{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Punakha
                        </a>
                        {" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Lataguri
                        </a>
                      </div>
                      <div className="reviews mx-5">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                      </div>
                    </div>
                    <p className="p-2">
                      If you are looking for a memorable <strong>Bhutan Tour from Pune</strong>, you have come to the right place!
                    </p>
                    <hr />
                  </div>
                  <h2 className="content-desktop p-4" style={{ fontSize: "1.4vw" }}> FOR BOOKING <strong> BEST BHUTAN PACKAGES FROM PUNE</strong> <a href='tel:9883359713' class="tomato">☎ CALL Mr. SOUMALYA +91- 98833-59713</a></h2>
                  <h2 className="content-mobile p-4" style={{ fontSize: "4.5vw" }}> FOR BOOKING <strong>BEST BHUTAN PACKAGES FROM PUNE</strong> <a href='tel:9883359713' class="tomato">☎ CALL Mr. SOUMALYA +91- 98833-59713</a></h2>
                </div>
              </div>

              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> WHY CHOOSE ADORABLE VACATION LLP ?</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}> WHY CHOOSE ADORABLE VACATION LLP ?</h4>
                </div>
                <div className="card-body">
                  <p>
                    Start an enchanting <strong>Bhutan Tour from Pune</strong> to the magical land of the Thunder Dragon. Bhutan, with its mix of spirituality, culture, and adventure, is a small gem with big charm. Experience Bhutan's stunning peaks, lush valleys, and vibrant Buddhist heritage by booking your <strong>Bhutan Tour Packages</strong>.
                  </p>
                  <p>
                    Pune, rich in history and culture, often gets overlooked for places like Goa or Kerala. But if you're into unique destinations, consider a <strong>Bhutan Tour Package From Pune</strong> for your next trip.
                  </p>
                  <p>Bhutan offers a peaceful escape with its beautiful natural scenery and friendly vibes. The country's focus on Gross National Happiness (GNH) is clear as you explore ancient monasteries, breathtaking landscapes, and traditional Bhutanese architecture. Visit iconic spots like Paro Taktsang Monastery, Punakha Dzong, and Chimi Lhakhang. Nature lovers will enjoy Bhutan's national parks, especially Jigme Dorji National Park and the Phobjikha Valley.</p>
                  <p>For a <strong>Bhutan Tour Packages from Pune</strong>, note there are no direct flights. Instead, take flights from Delhi, Kolkata, or Guwahati, which are easily reachable from Pune. Once in Bhutan, you'll receive a warm welcome and connect deeply with the country's rich culture. Choose a <strong>Bhutan Package Tour</strong> tailored to your interests and budget. Whether you love history, nature, or adventure, Bhutan has something for everyone.</p>
                  <p>In conclusion, if you're looking for a unique destination, Bhutan is perfect. Despite no direct flights from Pune, the journey is worth it. Pack your bags, book your <strong>Bhutan Tour from Pune</strong>, and get ready to experience the magic of the Thunder Dragon. Secure your spot at the best prices, including airfare, and find out the cost details for an unforgettable adventure</p>
                  <p>Adorable Vacation LLP also Offer  <a href="/mumbai-special-chartered-flight" target="_blank"><b className="tomato">Special Chertered Mumbai to Bhutan </b></a>,<a href="/bhutan-tour-package-from-pune" target="_blank"><b className="tomato">Bhutan Package Tour from Pune</b></a>,<a href="/bhutan-tour-package-from-hyderabad" target="_blank"><b className="tomato">Bhutan Package Tour from Hyderabad</b></a>,<a href="/bhutan-tour-from-delhi" target="_blank"><b className="tomato"> Bhutan Package Tour from Delhi</b></a>, <a href="/bhutan-tour-from-kolkata" target="_blank"><b className="tomato">Bhutan Package Tour from Kolkata</b></a>, <a href="/bhutan-tour-package-from-ahemdabad" target="_blank"><b className="tomato">Bhutan Package Tour from Ahmedabad</b></a>, <a href="/bhutan-tour-package-from-bagdogra" target="_blank"><b className="tomato">Bhutan Package Tour from Bagdogra</b></a>,<a href="/bhutan-tour-package" target="_blank"><b className="tomato"> Bhutan Package Tour from Jaigaon</b></a>, <a href="/bhutan-tour-package-from-chennai" target="_blank"><b className="tomato">Bhutan Package Tour from Chennai</b></a>,<a href="/bhutan-tour-package-from-surat" target="_blank"><b className="tomato">Bhutan Package Tour from Surat</b></a> etc at Best Price.</p>
                  <br />

                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen1ImageURL} alt="Sightseeing 1" />
                          <img src={sightseen3ImageURL} alt="Sightseeing 3" />
                          <img src={sightseen2ImageURL} alt="Sightseeing 2" />

                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <br /><br />
                  <a href="tel://9883359713" className="btn btn-Warning">
                    Call Now
                  </a>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> <strong>BHUTAN TOUR FROM PUNE VIA BAGDOGRA AIRPORT</strong> DURATION : 7N 8D</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>BHUTAN TOUR FROM PUNE VIA BAGDOGRA AIRPORT</strong> DURATION : 7N 8D</h4>
                </div>
                <div className="card-body">
                  <p>Places to cover in <strong>7N/8D Pune to Bhutan Tour from Paro International Airport</strong> : Phuentsholing 1N | Thimphu 2N | Punakha 1N | Paro 2N | Lataguri 1N</p>
                  <p>Pickup & Drop off  <strong>Pune to Bhutan Tour</strong> : Bagdogra International Airport [IXB]
                  </p>
                  <p><strong>Bhutan Package Cost from Pune Via Bagdogra</strong> : Rs. 35,199 /P [Land Cost starting from Bagdogra Airport]</p>
                  <br />
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr className="table-danger">
                          <th>CITY</th>
                          <th>ALTITUDE</th>
                          <th>CO-ORDINATES</th>

                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td data-title="CITY">Thimphu</td>
                          <td data-title="ALTITUDE">2,334 m</td>
                          <td data-title="CO-ORDINATES">27.4712° N, 89.6339° E</td>
                        </tr>
                        <tr>
                          <td data-title="CITY">Punakha</td>
                          <td data-title="ALTITUDE">1,274 m</td>
                          <td data-title="CO-ORDINATES">27.5921° N, 89.8797° E</td>
                        </tr>
                        <tr>
                          <td data-title="CITY">Paro</td>
                          <td data-title="ALTITUDE">2,200 m</td>
                          <td data-title="CO-ORDINATES">27.4287° N, 89.4164° E</td>
                        </tr>
                        <tr>
                          <td data-title="CITY">Phobjikha Valley</td>
                          <td data-title="ALTITUDE">3,000 m</td>
                          <td data-title="CO-ORDINATES">27.4594° N, 90.1802° E</td>
                        </tr>
                        <tr>
                          <td data-title="CITY">Chele La Pass</td>
                          <td data-title="ALTITUDE">3,988 m</td>
                          <td data-title="CO-ORDINATES">27.3698° N, 89.3466° E</td>
                        </tr>
                        <tr>
                          <td data-title="CITY">Dochula Pass</td>
                          <td data-title="ALTITUDE">3,100 m</td>
                          <td data-title="CO-ORDINATES">27.2924° N, 89.4501° E</td>
                        </tr>
                        <tr>
                          <td data-title="CITY">Tigers Nest Monastery</td>
                          <td data-title="ALTITUDE">3,120 m</td>
                          <td data-title="CO-ORDINATES">27.2930° N, 89.2148° E</td>
                        </tr>
                        <tr>
                          <td data-title="CITY">Haa Valley</td>
                          <td data-title="ALTITUDE">2,670 m</td>
                          <td data-title="CO-ORDINATES">27.3333° N, 89.1833° E</td>
                        </tr>
                        <tr>
                          <td data-title="CITY">Gasa</td>
                          <td data-title="ALTITUDE">2,850 m</td>
                          <td data-title="CO-ORDINATES">27.8983° N, 89.7310° E</td>
                        </tr>
                        <tr>
                          <td data-title="CITY">Dagana</td>
                          <td data-title="ALTITUDE">1,464 m</td>
                          <td data-title="CO-ORDINATES">27.0970° N, 89.8739° E</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br /><br />

              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> <strong>ATTRACTIONS IN PUNE TO BHUTAN TOUR PACKAGES</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>ATTRACTIONS IN PUNE TO BHUTAN TOUR PACKAGES</strong></h4>
                </div>
                <div className="card-body">
                  <p>There are many attractions to explore in this <strong>Bhutan Tour Package From Pune</strong>, such as:</p>
                  <p><b>Places of attractions in Thimphu :</b> Tashichho Dzong, Simply Bhutan Museum, Buddha Dordenma Statue, National Memorial Chorten, Motithang Takin Preserve, Changangkha Lhakhang, Royal Textile Academy of Bhutan, Centenary Farmers' Market, National Folk Heritage Museum, Simtokha Dzong, Pangri Zampa Monastery</p>
                  <p><b>Places of attractions in Punakha :</b> Punakha Dzong, Chimi Lhakhang (The Fertility Temple), Khamsum Yulley Namgyal Chorten, Sangchhen Dorji Lhuendrup Lhakhang Nunnery, Mo Chhu River Rafting</p>
                  <p><b>Places of attractions in Paro :</b> Taktsang (Tiger's Nest) Monastery, Paro Dzong (Rinpung Dzong), National Museum of Bhutan, Drukgyel Dzong, Kyichu Lhakhang, Jangsarbu Lhakhang, Dungtse Lhakhang, Chele La Pass, Paro Weekend Market</p>
                  <p><b>Places of attractions in Phobjikha Valley :</b> Gangte Goemba [Gangtey Monastery], Black-Necked Crane Information Centre, Gangtey Nature Trail, Nyelung Drachaling, Kuenzang Chholing Shedra, Khewang Lhakhang, Phobjikha Valley Hiking Trails, Kumbhu Lhakhang, Damcho Lhakhang, Dzongdrakha Goemba, Bumdeling Wildlife Sanctuary, Jigme Singye Wangchuck National Park, Gogona Village trek etc.</p>
                  <p><b>Places of attractions in Haa Valley :</b> Juneydrak Hermitage, Katsho Goemba, Lhakhang Karpo, Lhakhang Nagpo, Rigsum Gonpo, Trout Farm, Wangchuk Lo Dzong, Yangthang Goemba etc.</p>
                  <p></p>
                </div>
              </div>
              <br /><br />

              <div className="container-fluid py-5">
                <div className="row">
                  <div className="card">
                    <table className="table table-bordered">
                      <thead>
                        <tr className="table-danger">
                          <th>DAY WISE SUMMERY FOR 7N|8D <strong>PUNE TO BHUTAN TOUR ITINERARY</strong></th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingOne"
                          >
                            <b>DAY 1 → </b>
                            <span>
                              BAGDOGRA AIRPORT TO PHUENTSHOLING
                            </span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingTwo"
                          >
                            <b>DAY 2 → </b>
                            <span>PHUENTSHOLING TO THIMPHU</span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingThree"
                          >
                            <b>DAY 3 → </b>
                            <span>THIMPHU LOCAL SIGHTSEEING</span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingFour"
                          >
                            <b>DAY 4 → </b>
                            <span>THIMPHU TO PUNAKHA TRANSFER VIA DOCHULA PASS</span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingFive"
                          >
                            <b>DAY 5 → </b>
                            <span>PUNAKHA / WANGDUE TO PARO</span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingSix"
                          >
                            <b>DAY 6 → </b>
                            <span>PARO LOCAL SIGHTSEEING & TAKTSHANG MONASTERY HIKING</span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingSeven"
                          >
                            <b>DAY 7 → </b>
                            <span>PARO TO LATAGURI TRANSFER</span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingTen"
                          >
                            <b>DAY 8 → </b>
                            <span>LATAGURI TO BAGDOGRA AIRPORT DROP</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* <!--end Short description--> */}
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>Entry Requirements for Indian Tourists from Pune Under Bhutan's New Tourism Policy</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>Entry Requirements for Indian Tourists from Pune Under Bhutan's New Tourism Policy</h4>
                </div>
                <div className="card-body">

                  <li>Indian citizens don't need a visa for a <strong>Bhutan Tour from Pune</strong>, but an entry permit is required. A local Bhutan tour agency like Adorable Vacation LLP Bhutan can help process this permit.</li><br />
                  <li>A valid passport (with at least 6 months' validity) or a Voter ID card is needed for the tour.</li><br />
                  <li>A single permit allows tourists to travel anywhere beyond Thimphu and Paro.</li><br />
                  <li>According to the new Tourism Policy, all regional tourists must pay a Sustainable Development Fee (SDF) of Rs. 1200 per person per night, in addition to the package price (covering flights, hotels, meals, guides, transportation, and monument fees).</li><br />
                  <li>All permits for <a href="/bhutan-tour-package-from-kolkata"><strong>Bhutan Group Tours</strong></a> will be processed online.</li><br />
                  <li>Hotel bookings and tour guides approved by the Tourism Council of Bhutan (TCB) are mandatory.</li><br />
                  <li>Online permits can be processed through local registered tour operators or any international partners.</li><br />
                  <h5>WHAT DOCUMENTS ARE NEEDED FOR A <strong>BHUTAN TOUR FROM PUNE ?</strong></h5><br />
                  <li>For child below 18 years a valid Birth Certificate is required. </li><br />
                  <li>A valid Indian passport with at least 6 months of validity from the intended departure date from Bhutan.</li><br />
                  <li>A valid voter ID card issued by the Election Commission of India.</li>
                  <br /><br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen10ImageURL} alt="Sightseeing 1" />
                          <img src={sightseen11ImageURL} alt="Sightseeing 3" />
                          <img src={sightseen12ImageURL} alt="Sightseeing 2" />

                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <br /><br />
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>BHUTAN SIGHTSEEING TOUR & ENTRY FEES</b></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>BHUTAN SIGHTSEEING TOUR & ENTRY FEES</b></h4>
                </div>
                <div className="card-body">

                  <br />
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr className="table-danger">
                          <th>MUSEUMS & MONUMENTS</th>
                          <th>LOCATION</th>
                          <th>FEES(NU)</th>
                          <th>REMARKS</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td data-title="MUSEUMS">Bhutan Post Office Museum</td>
                          <td data-title="LOCATION">Thimphu</td>
                          <td data-title="FEES(NU)">150</td>
                          <td data-title="REMARKS">9AM - 5PM</td>
                        </tr>
                        <tr>
                          <td data-title="MUSEUMS">Folk Heritage Museum</td>
                          <td data-title="LOCATION">Thimphu</td>
                          <td data-title="FEES(NU)">200</td>
                          <td data-title="REMARKS">9AM - 5PM</td>
                        </tr>
                        <tr>
                          <td data-title="MUSEUMS">Zorig Chusum Arts and Crafts School</td>
                          <td data-title="LOCATION">Thimphu</td>
                          <td data-title="FEES(NU)">200</td>
                          <td data-title="REMARKS">9AM - 4:30PM</td>
                        </tr>
                        <tr>
                          <td data-title="MUSEUMS">Simply Bhutan Museum</td>
                          <td data-title="LOCATION">Thimphu</td>
                          <td data-title="FEES(NU)">1000</td>
                          <td data-title="REMARKS">9AM – 5PM</td>
                        </tr>
                        <tr>
                          <td data-title="MUSEUMS">Takin Preserve Center</td>
                          <td data-title="LOCATION">Thimphu</td>
                          <td data-title="FEES(NU)">300</td>
                          <td data-title="REMARKS">-</td>
                        </tr>
                        <tr>
                          <td data-title="MUSEUMS">National Handicraft Emporium</td>
                          <td data-title="LOCATION">Thimphu</td>
                          <td data-title="FEES(NU)">100</td>
                          <td data-title="REMARKS">-</td>
                        </tr>
                        <tr>
                          <td data-title="MUSEUMS">National Library</td>
                          <td data-title="LOCATION">Thimphu</td>
                          <td data-title="FEES(NU)">100</td>
                          <td data-title="REMARKS">-</td>
                        </tr>
                        <tr>
                          <td data-title="MUSEUMS">Jungshi Handmade Paper Factory</td>
                          <td data-title="LOCATION">Thimphu</td>
                          <td data-title="FEES(NU)">100</td>
                          <td data-title="REMARKS">-</td>
                        </tr>
                        <tr>
                          <td data-title="MUSEUMS">National Memorial Chorten</td>
                          <td data-title="LOCATION">Thimphu</td>
                          <td data-title="FEES(NU)">500</td>
                          <td data-title="REMARKS">9AM – 5PM</td>
                        </tr>
                        <tr>
                          <td data-title="MUSEUMS">Zilukha Nunnery</td>
                          <td data-title="LOCATION">Thimphu</td>
                          <td data-title="FEES(NU)">100</td>
                          <td data-title="REMARKS">9AM – 5PM</td>
                        </tr>
                        <tr>
                          <td data-title="MUSEUMS">Tashichho Dzong</td>
                          <td data-title="LOCATION">Thimphu</td>
                          <td data-title="FEES(NU)">500</td>
                          <td data-title="REMARKS">5PM – 6PM, 9AM – 5PM (PH)</td>
                        </tr>
                        <tr>
                          <td data-title="MUSEUMS">Lampelri Botanical Garden</td>
                          <td data-title="LOCATION">Thimphu</td>
                          <td data-title="FEES(NU)">300</td>
                          <td data-title="REMARKS">-</td>
                        </tr>
                        <tr>
                          <td data-title="MUSEUMS">Changangkha Lhakhang</td>
                          <td data-title="LOCATION">Thimphu</td>
                          <td data-title="FEES(NU)">500</td>
                          <td data-title="REMARKS">-</td>
                        </tr>
                        <tr>
                          <td data-title="MUSEUMS">National Textile Museum</td>
                          <td data-title="LOCATION">Thimphu</td>
                          <td data-title="FEES(NU)">250</td>
                          <td data-title="REMARKS">-</td>
                        </tr>
                        <tr>
                          <td data-title="MUSEUMS">Traditional Handmade Paper Mill</td>
                          <td data-title="LOCATION">Thimphu</td>
                          <td data-title="FEES(NU)">100</td>
                          <td data-title="REMARKS">-</td>
                        </tr>
                        <tr>
                          <td data-title="MUSEUMS">Traditional Medicine Center</td>
                          <td data-title="LOCATION">Thimphu</td>
                          <td data-title="FEES(NU)">500</td>
                          <td data-title="REMARKS">-</td>
                        </tr>
                        <tr>
                          <td data-title="MUSEUMS">Botanical Garden Servithang</td>
                          <td data-title="LOCATION">Thimphu</td>
                          <td data-title="FEES(NU)">200</td>
                          <td data-title="REMARKS">-</td>
                        </tr>
                        <tr>
                          <td data-title="MUSEUMS">Ta Dzong / National Museum</td>
                          <td data-title="LOCATION">Paro</td>
                          <td data-title="FEES(NU)">300</td>
                          <td data-title="REMARKS">-</td>
                        </tr>
                        <tr>
                          <td data-title="MUSEUMS">Taktshang / Tiger's Nest Temple</td>
                          <td data-title="LOCATION">Paro</td>
                          <td data-title="FEES(NU)">1000</td>
                          <td data-title="REMARKS">9AM – 12PM, 2PM – 5PM</td>
                        </tr>
                        <tr>
                          <td data-title="MUSEUMS">Kyichu Lhakhang</td>
                          <td data-title="LOCATION">Paro</td>
                          <td data-title="FEES(NU)">500</td>
                          <td data-title="REMARKS">9AM – 12PM, 1PM – 4:30PM</td>
                        </tr>
                        <tr>
                          <td data-title="MUSEUMS">Jangtsa Dumtseg Lhakhang</td>
                          <td data-title="LOCATION">Paro</td>
                          <td data-title="FEES(NU)">1000</td>
                          <td data-title="REMARKS">-</td>
                        </tr>
                        <tr>
                          <td data-title="MUSEUMS">Tachogang Lhakhang</td>
                          <td data-title="LOCATION">Paro</td>
                          <td data-title="FEES(NU)">500</td>
                          <td data-title="REMARKS">-</td>
                        </tr>
                        <tr>
                          <td data-title="MUSEUMS">Punakha Dzong</td>
                          <td data-title="LOCATION">Punakha</td>
                          <td data-title="FEES(NU)">500</td>
                          <td data-title="REMARKS">9AM- 5PM</td>
                        </tr>
                        <tr>
                          <td data-title="MUSEUMS">Chhimi Lhakhang</td>
                          <td data-title="LOCATION">Punakha</td>
                          <td data-title="FEES(NU)">500</td>
                          <td data-title="REMARKS">-</td>
                        </tr>
                        <tr>
                          <td data-title="MUSEUMS">Khamsum Yulley Namgyal Chorten</td>
                          <td data-title="LOCATION">Punakha</td>
                          <td data-title="FEES(NU)">100</td>
                          <td data-title="REMARKS">9AM – 5PM</td>
                        </tr>
                        <tr>
                          <td data-title="MUSEUMS">Sangchhen Dorji Lhuendrup Nunnery</td>
                          <td data-title="LOCATION">Punakha</td>
                          <td data-title="FEES(NU)">200</td>
                          <td data-title="REMARKS">-</td>
                        </tr>
                        <tr>
                          <td data-title="MUSEUMS">Black-Necked Crane Education Center</td>
                          <td data-title="LOCATION">Phobjikha</td>
                          <td data-title="FEES(NU)">200</td>
                          <td data-title="REMARKS">9AM-5PM</td>
                        </tr>
                        <tr>
                          <td data-title="MUSEUMS">Gangtey Monastery</td>
                          <td data-title="LOCATION">Phobjikha</td>
                          <td data-title="FEES(NU)">100</td>
                          <td data-title="REMARKS">-</td>
                        </tr>
                        <tr>
                          <td data-title="MUSEUMS">Trongsa National Museum</td>
                          <td data-title="LOCATION">Trongsa</td>
                          <td data-title="FEES(NU)">300</td>
                          <td data-title="REMARKS">9AM-5PM</td>
                        </tr>
                        <tr>
                          <td data-title="MUSEUMS">Swiss Factory</td>
                          <td data-title="LOCATION">Bumthang</td>
                          <td data-title="FEES(NU)">700</td>
                          <td data-title="REMARKS">-</td>
                        </tr>
                        <tr>
                          <td data-title="MUSEUMS">Ogyen Choling Palace Museum</td>
                          <td data-title="LOCATION">Bumthang</td>
                          <td data-title="FEES(NU)">100</td>
                          <td data-title="REMARKS">-</td>
                        </tr>
                        <tr>
                          <td data-title="MUSEUMS">Palden Tashi Choling Shedra</td>
                          <td data-title="LOCATION">Phuentsholing</td>
                          <td data-title="FEES(NU)">1000</td>
                          <td data-title="REMARKS">-</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                </div>
              </div>
              <br /><br />

              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>WHAT IS THE BEST WAY FOR A BHUTAN TRIP FROM PUNE?</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>WHAT IS THE BEST WAY FOR A BHUTAN TRIP FROM PUNE?</h4>
                </div>
                <div className="card-body">
                  <p>There are no direct flights from <strong>Pune to Bhutan</strong>. So, what is the best way to book a <strong>Bhutan Travel Package From Pune</strong>? This is a common question for anyone planning a <strong>Bhutan Tour From Pune</strong>. Here are some options for traveling from Pune to Bhutan:</p>
                  <br />
                  <p>OPTION 1: <strong>BHUTAN TOUR FROM PUNE VIA</strong> MUMBAI WITH DIRECT FLIGHT</p>
                  <p>Planning a <strong>Pune to Bhutan Tours</strong>? It's important to know your flight options. There are no direct <strong>Pune to Bhutan Flight</strong>. Currently, direct flights to Bhutan are available from Delhi, Kolkata, and Guwahati. But there's good news for those planning a <strong>Travel Bhutan from Pune</strong>. </p>
                  <br />
                  <p>OPTION 1 : <strong>BHUTAN TOUR FROM DELHI</strong> [DIRECT FLIGHT]</p>
                  <br />
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr className="table-danger">
                          <th>DEPARTURE</th>
                          <th>SECTOR</th>
                          <th>OUTWARD JOURNEY TIME</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td data-title="DEPARTURE">Sunday</td>
                          <td data-title="SECTOR">Delhi → Paro</td>
                          <td data-title="O/W JUR. TIME">Depart 10:55 - Arrive 14:35</td>
                        </tr>
                        <tr>
                          <td data-title="DEPARTURE">Monday</td>
                          <td data-title="SECTOR">Delhi → Paro</td>
                          <td data-title="O/W JUR. TIME">Depart 10:55 - Arrive 14:35</td>
                        </tr>
                        <tr>
                          <td data-title="DEPARTURE">Wednesday</td>
                          <td data-title="SECTOR">Delhi → Paro</td>
                          <td data-title="O/W JUR. TIME">Depart 10:55 - Arrive 14:35</td>
                        </tr>
                        <tr>
                          <td data-title="DEPARTURE">Saturday</td>
                          <td data-title="SECTOR">Delhi → Paro</td>
                          <td data-title="O/W JUR. TIME">Depart 10:55 - Arrive 14:35</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br />
                  <p>OPTION 1 : <strong>BHUTAN TOUR FROM DELHI RETURN</strong> [DIRECT FLIGHT]</p>
                  <br />
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr className="table-danger">
                          <th>DEPARTURE</th>
                          <th>SECTOR</th>
                          <th>INWARD JOURNEY TIME</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td data-title="DEPARTURE">Sunday</td>
                          <td data-title="SECTOR">Paro → Delhi</td>
                          <td data-title="I/W JUR. TIME">Depart 07:05 - Arrive 09:55</td>
                        </tr>
                        <tr>
                          <td data-title="DEPARTURE">Monday</td>
                          <td data-title="SECTOR">Paro → Delhi</td>
                          <td data-title="I/W JUR. TIME">Depart 07:05 - Arrive 09:55</td>
                        </tr>
                        <tr>
                          <td data-title="DEPARTURE">Wednesday</td>
                          <td data-title="SECTOR">Paro → Delhi</td>
                          <td data-title="I/W JUR. TIME">Depart 07:05 - Arrive 09:55</td>
                        </tr>
                        <tr>
                          <td data-title="DEPARTURE">Saturday</td>
                          <td data-title="SECTOR">Paro → Delhi</td>
                          <td data-title="I/W JUR. TIME">Depart 07:05 - Arrive 09:55</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br />
                  <h5>OPTION 2 [BY AIR]: <strong>BHUTAN TOUR FROM MUMBAI</strong> [DIRECT FLIGHT] </h5>
                  <br />
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr className="table-danger">
                          <th>DEPARTURE</th>
                          <th>SECTOR</th>
                          <th>OUTWARD JOURNEY TIME</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td data-title="DEPARTURE">Tuesday</td>
                          <td data-title="SECTOR">Mumbai → Paro</td>
                          <td data-title="O/W JUR. TIME">Depart 08:35 - Arrive 12:20</td>
                        </tr>
                        <tr>
                          <td data-title="DEPARTURE">FRIDAY</td>
                          <td data-title="SECTOR">Mumbai → Paro</td>
                          <td data-title="O/W JUR. TIME">Depart 04:00 - Arrive 07:40</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br />
                  <p>OPTION 2 : <strong>BHUTAN TOUR FROM MUMBAI RETURN</strong> [DIRECT FLIGHT]</p>
                  <br />
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr className="table-danger">
                          <th>DEPARTURE</th>
                          <th>SECTOR</th>
                          <th>INBOUND JOURNEY TIME</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td data-title="DEPARTURE">Monday</td>
                          <td data-title="SECTOR">Paro → Mumbai</td>
                          <td data-title="I/B JUR. TIME">Depart 15:15 - Arrive 19:05</td>
                        </tr>
                        <tr>
                          <td data-title="DEPARTURE">Thursday</td>
                          <td data-title="SECTOR">Paro → Mumbai</td>
                          <td data-title="I/B JUR. TIME">Depart 10:30 - Arrive 14:20</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br />  <br />
                  <h5>OPTION 3 [BY AIR]: <strong>BHUTAN TOUR FROM GUWAHATI</strong> [DIRECT FLIGHT]</h5>
                  <br />
                  <br />
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr className="table-danger">
                          <th>AIRLINES</th>
                          <th>FLIGHT NO</th>
                          <th>ORIGIN</th>
                          <th>DEPARTURE</th>
                          <th>DESTINATION</th>
                          <th>DEP DAYS</th>

                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td data-title="AIRLINES">Drukair-Royal Bhutan Airlines</td>
                          <td data-title="FLIGHT NO">KB 541</td>
                          <td data-title="ORIGIN">[GAU]</td>
                          <td data-title="DEPARTURE">15:20</td>
                          <td data-title="DESTINATION">[PBH]</td>
                          <td data-title="DEP DAYS">Every Sunday</td>
                        </tr>
                        <tr>
                          <td data-title="AIRLINES">Drukair-Royal Bhutan Airlines</td>
                          <td data-title="FLIGHT NO">KB 541</td>
                          <td data-title="ORIGIN">[GAU]</td>
                          <td data-title="DEPARTURE">15:20</td>
                          <td data-title="DESTINATION">[PBH]</td>
                          <td data-title="DEP DAYS">Every Thursday</td>
                        </tr>
                        <tr>
                          <td data-title="AIRLINES">Drukair-Royal Bhutan Airlines</td>
                          <td data-title="FLIGHT NO">KB 540</td>
                          <td data-title="ORIGIN">[PBH]</td>
                          <td data-title="DEPARTURE">08:05</td>
                          <td data-title="DESTINATION">[GAU]</td>
                          <td data-title="DEP DAYS">Every Saturday</td>
                        </tr>
                        <tr>
                          <td data-title="AIRLINES">Drukair-Royal Bhutan Airlines</td>
                          <td data-title="FLIGHT NO">KB 540</td>
                          <td data-title="ORIGIN">[PBH]</td>
                          <td data-title="DEPARTURE">08:05</td>
                          <td data-title="DESTINATION">[GAU]</td>
                          <td data-title="DEP DAYS">Every Wednesday</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br />
                  <h5>OPTION 4 [BY AIR]: <strong>BHUTAN TOUR FROM KOLKATA</strong> [DIRECT FLIGHT]</h5>
                  <br />
                  <br />
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr className="table-danger">
                          <th>SECTOR</th>
                          <th>FLIGHT NO</th>
                          <th>DEP DAY</th>
                          <th>DEP TIME</th>
                          <th>ARR TIME</th>
                          <th>DURATION</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td data-title="SECTOR">CCU - PBH</td>
                          <td data-title="FLIGHT NO">B3 701</td>
                          <td data-title="DEP DAY">EVERYDAY</td>
                          <td data-title="DEP TIME">08:15am</td>
                          <td data-title="ARR TIME">09:55pm</td>
                          <td data-title="DURATION">01 hrs 10 mins</td>
                        </tr>
                        <tr>
                          <td data-title="SECTOR">PBH - CCU</td>
                          <td data-title="FLIGHT NO">B3 700</td>
                          <td data-title="DEP DAY">EVERYDAY</td>
                          <td data-title="DEP TIME">10:35am</td>
                          <td data-title="ARR TIME">11:15pm</td>
                          <td data-title="DURATION">01 hrs 10 mins</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br />
                  <h5>OPTION 5 : <a href="/bhutan-tour-package-from-bagdogra"><strong>BHUTAN TOUR FROM BAGDOGRA AIRPORT</strong></a> BY ROAD</h5>
                  <br />
                  <p>For those looking to save money and reach Bhutan by road, booking a <strong>Bhutan Tour From Bagdogra</strong> Airport is a great option. Bagdogra Airport is about 16 km (9.9 mi) west of Siliguri in the Darjeeling district of northern West Bengal. The airport is well connected with Mumbai. Guests can fly from Mumbai to Bagdogra and then drive to Phuentsholing, the gateway to Bhutan, which is about 170 km (4 hours) from the airport. From there, Adorable Vacation offers many <a href="/bhutan-tour-package-from-kolkata"><strong>Bhutan Group Tours</strong></a>. Guests from Pune can easily fly to Bagdogra Airport and start their Bhutan Tour from there.</p>
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen4ImageURL} alt="Sightseeing 4" />
                          <img src={sightseen5ImageURL} alt="Sightseeing 5" />
                          <img src={sightseen6ImageURL} alt="Sightseeing 6" />

                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <br />
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>IMPORTANT FACTS FOR A</b><strong> BHUTAN PACKAGE TOUR FROM PUNE</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>IMPORTANT FACTS FOR A</b><strong> BHUTAN PACKAGE TOUR FROM PUNE</strong></h4>
                </div>
                <div className="card-body">
                  <p><b>Locals call their country "Druk Yul" - which means "Land of the Dragons"</b>: Bhutan is known as "Druk Yul" or "Land of the Dragons." This name reflects the country's mythical charm and strength, making it a fascinating place for travelers.</p>
                  <p><b>Bhutan has never been conquered</b>: Bhutan has a proud history of independence, never being colonized. This has helped preserve its unique culture and traditions.</p>
                  <p><b>Bhutan is the only carbon-negative country in the world</b>: Bhutan absorbs more carbon dioxide than it emits, making it a model for eco-friendly travelers.</p>
                  <p><b>Bhutan has excellent air quality</b>: The air in Bhutan is very clean, making it a great place to escape city pollution and enjoy nature.</p>
                  <p><b>Bhutan was isolated until the 1970s</b>: Tourism in Bhutan began in 1974 to help boost the economy and share its culture. Only 287 tourists visited in the first year.</p>
                  <p><b>Bhutan is a constitutional monarchy</b>: Bhutan is run by a constitutional monarchy, blending peaceful governance with royal traditions.</p>
                  <p><b>No traffic lights in Bhutan</b>: Bhutan doesn't have traffic lights; instead, the roads are managed with friendly interactions.</p>
                  <p><b>Bhutan values Gross National Happiness (GNH) over GDP</b>: Bhutan focuses on GNH for growth and development, emphasizing the well-being of its people over economic output.</p>
                  <p><b>Bhutan is a plastic-free country</b>: Bhutan discourages plastic use to protect its environment, making it ideal for eco-conscious travelers.</p>
                  <p><b>Few qualified pilots for Bhutan's only airport</b>: Fewer than 20 pilots can navigate the challenging terrain to land at Paro Airport, adding a sense of exclusivity to the trip.</p>
                  <p><b>Smoking is not allowed in Bhutan</b>: Smoking is banned in public places in Bhutan. Visitors can only smoke in private and must bring their own cigarettes.</p>
                  <p><b>Bhutan has some of the highest unclimbed mountains</b>: Bhutan is home to Gangkhar Puensum, one of the world's highest unclimbed mountains.</p>
                  <p><b>Takin is the national animal</b>: The takin, a goat-antelope, is Bhutan's national animal and part of its rich biodiversity.</p>
                  <p><b>Bhutanese food is very spicy</b>: Bhutanese cuisine features a lot of chili peppers, offering a bold and fiery flavor experience.</p>
                  <p><b>Chili peppers drying on rooftops</b>: You'll often see chili peppers drying on rooftops, adding vibrant color to Bhutanese homes.</p>
                  <p><b>Free education and healthcare</b>: Bhutan provides free education and healthcare to all citizens through government-run institutions.</p>
                  <p><b>Archery is the national sport</b>: Archery is very popular in Bhutan, along with dirt throwing. Visitors can experience these traditional sports.</p>
                  <p><b>National dress is mandatory</b>: Bhutanese people wear traditional dress (Gho for men and Kira for women) in schools, government offices, and festivals to preserve their culture.</p>
                  <p><b>Environmental cleanliness is a priority</b>: Bhutan prioritizes keeping its environment clean through strict waste management and community efforts.</p>
                  <p><b>Chewing Doma</b>: Bhutanese people often chew Doma, a combination of betel leaf and areca nut, as a cultural practice.</p>
                  <p><b>Ema Datshi is the national dish</b>: Ema Datshi, made with spicy chilies and local cheese, is Bhutan's national dish and a must-try for visitors.</p>
                </div>
              </div>

              <br /><br />
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>MUST-VISIT PLACES DURING YOUR</b> <strong>BHUTAN TRIP FROM PUNE</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>MUST-VISIT PLACES DURING YOUR</b> <strong>BHUTAN TRIP FROM PUNE</strong></h4>
                </div>
                <div className="card-body">
                  <p><b>Buddha Dordenma:</b> In Thimphu, this huge 169-foot statue of Lord Buddha overlooks the valley. It's one of the largest Buddha statues in the world and offers amazing views of the mountains and city.</p>
                  <p><b>Dagala Thousand Lakes:</b> This trek through the Dagala mountain range takes you to beautiful high-altitude lakes. You can hike or take a helicopter ride to enjoy the pristine scenery, including alpine meadows and lush forests.</p>
                  <p><b>Tashichho Dzong:</b> Located by the Wang Chu River in Thimphu, this fortress-monastery is the seat of Bhutan's government and the king's offices. It's known for its traditional architecture, colorful murals, and cultural festivals.</p>
                  <p><b>Clock Tower Square:</b> A lively area in Thimphu with shops, cafes, and cultural landmarks. The clock tower stands as a symbol of modernity amidst traditional Bhutanese architecture.</p>
                  <p><b>Simtokha Dzong:</b> Near Thimphu, this is Bhutan's oldest fortress, built in 1629. It's decorated with intricate carvings and paintings and serves as a center for religious and cultural activities.</p>
                  <p><b>National Memorial Chorten:</b> This sacred monument in Thimphu was built in memory of Bhutan's third king. It's surrounded by prayer wheels and colorful flags, offering a peaceful place for worship and meditation.</p>
                  <p><b>Haa Valley:</b> A hidden gem in western Bhutan, known for its natural beauty and cultural heritage. The valley features stunning vistas, tranquil monasteries, and traditional villages.</p>
                  <p><b>Rinpung Dzong:</b> Also known as Paro Dzong, this historic fortress-monastery overlooks Paro Valley. Built in the 15th century, it's famous for its woodwork, murals, and religious festivals.</p>
                  <p><b>Taktsang Monastery:</b> Also called Tiger's Nest, this iconic monastery in Paro Valley is perched on a cliffside. Visitors can hike through forests and scenic viewpoints to reach this sacred site.</p>
                  <p><b>Chimi Lhakhang Temple:</b> Located in Punakha, this temple is dedicated to the "Divine Madman," Lama Drukpa Kunley. It's known for its fertility blessings and the surrounding serene countryside.</p>
                  <p><b>Punakha Dzong:</b> Situated at the confluence of two rivers, this majestic fortress was built in the 17th century and served as the seat of government. It features grand courtyards, intricate woodwork, and sacred temples.</p>
                  <p><b>Punakha Suspension Bridge:</b> One of the longest suspension bridges in Bhutan, spanning the Mo Chhu River. It offers breathtaking views and an adventurous crossing.</p>
                  <p><b>Trashigang Dzong:</b> This historic fortress in eastern Bhutan overlooks the town of Trashigang. Built in the 17th century, it serves as a religious and administrative center.</p>
                  <p><b>Trongsa Dzong:</b> The largest dzong in Bhutan, located in central Bhutan, it was a vital stronghold controlling trade routes. The dzong features fortified walls, watchtowers, and panoramic views.</p>
                  <p><b>Gangtey Monastery:</b> Located in the Phobjikha Valley, this 17th-century monastery offers stunning views and a serene atmosphere. The valley is also home to endangered black-necked cranes during winter, adding to its natural beauty.</p>

                </div>
              </div>

              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>BEST TIME TO BOOK </b><strong>PUNE TO BHUTAN TOUR PACKAGES</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>BEST TIME TO BOOK </b><strong>PUNE TO BHUTAN TOUR PACKAGES</strong></h4>
                </div>
                <div className="card-body">
                  <p>Bhutan, nestled in the Himalayas, has unique seasonal changes. Each season offers something special, making Bhutan a great destination year-round. Here's the best time to plan your <strong>Bhutan package tour from Pune</strong>:</p>
                  <p><b>Monsoon (July to August) :</b> The monsoon season brings heavy rain and occasional drizzles. This can cause landslides and roadblocks, making travel difficult. It's not the best time for a <strong>Bhutan tour from Pune</strong>.</p>
                  <p><b>Autumn (September to November) :</b> Autumn offers pleasant weather with little rain, perfect for sightseeing. This season features vibrant festivals like the Black-Necked Crane Festival and Paro Tshechu, making it a great time to book a <strong>Bhutan tour package from Pune</strong>.</p>

                  <p><b>Winter (December to February) :</b> Winter in Bhutan is cold, with sub-zero temperatures in the north and heavy snowfall in Thimphu and Paro. Despite the cold, you can experience cultural festivals like Monggar Tshechu and Trashigang Tshechu. Winter tours from Pune offer a chance to see Bhutan's serene winter beauty.</p>
                  <p><b>Spring (March to May) :</b> Spring is the best time to visit Bhutan. The valleys and streets bloom with Jacaranda and Rhododendron flowers. The weather is clear and pleasant, perfect for treks like Jomolhari and Drukpath. Spring is popular for travelers from Pune and around the world due to its vibrant landscapes and cultural festivities.</p>
                  <p>For an unforgettable experience, Adorable Vacation recommends booking a <strong>Bhutan package from Pune</strong> during the spring months for the best weather and natural beauty. Whether you enjoy autumn's colorful festivals or spring's blooming landscapes, every season in Bhutan offers a unique and enriching journey.</p>
                  <br />


                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen7ImageURL} alt="Sightseeing 7" />
                          <img src={sightseen9ImageURL} alt="Sightseeing 9" />
                          <img src={sightseen8ImageURL} alt="Sightseeing 8" />
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <br />

                  <h5>TEMPERATURE IN BHUTAN EACH MONTH</h5><br />
                  <br /><br />
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr className="table-danger">
                          <th>MONTH</th>
                          <th>THIMPHU</th>
                          <th>PARO</th>
                          <th>PUNAKHA</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td data-title="MONTH">JANUARY</td>
                          <td data-title="THIMPHU">12.4 / -2.5°C</td>
                          <td data-title="PARO">9.5 / -5.8°C</td>
                          <td data-title="PUNAKHA">17 / 4.3°C</td>
                        </tr>
                        <tr>
                          <td data-title="MONTH">FEBRUARY</td>
                          <td data-title="THIMPHU">14.5 / 0.7°C</td>
                          <td data-title="PARO">13 / 1.6°C</td>
                          <td data-title="PUNAKHA">19 / 7.9°C</td>
                        </tr>
                        <tr>
                          <td data-title="MONTH">MARCH</td>
                          <td data-title="THIMPHU">16.7 / 3.9°C</td>
                          <td data-title="PARO">14.5 / 0.6°C</td>
                          <td data-title="PUNAKHA">22.8 / 10.4°C</td>
                        </tr>
                        <tr>
                          <td data-title="MONTH">APRIL</td>
                          <td data-title="THIMPHU">20 / 7.2°C</td>
                          <td data-title="PARO">17.5 / 4.6°C</td>
                          <td data-title="PUNAKHA">26.2 / 12.9°C</td>
                        </tr>
                        <tr>
                          <td data-title="MONTH">MAY</td>
                          <td data-title="THIMPHU">22.6 / 13.2°C</td>
                          <td data-title="PARO">23.5 / 10.6°C</td>
                          <td data-title="PUNAKHA">29.1 / 17.7°C</td>
                        </tr>
                        <tr>
                          <td data-title="MONTH">JUNE</td>
                          <td data-title="THIMPHU">24.5 / 15.3°C</td>
                          <td data-title="PARO">25.4 / 14.2°C</td>
                          <td data-title="PUNAKHA">29.2 / 20.1°C</td>
                        </tr>
                        <tr>
                          <td data-title="MONTH">JULY</td>
                          <td data-title="THIMPHU">25.8 / 15.5°C</td>
                          <td data-title="PARO">26.8 / 14.9°C</td>
                          <td data-title="PUNAKHA">30.4 / 20.6°C</td>
                        </tr>
                        <tr>
                          <td data-title="MONTH">AUGUST</td>
                          <td data-title="THIMPHU">25 / 15.7°C</td>
                          <td data-title="PARO">25.3 / 14.7°C</td>
                          <td data-title="PUNAKHA">29.1 / 20°C</td>
                        </tr>
                        <tr>
                          <td data-title="MONTH">SEPTEMBER</td>
                          <td data-title="THIMPHU">23.2 / 15°C</td>
                          <td data-title="PARO">23.4 / 11.8°C</td>
                          <td data-title="PUNAKHA">27.5 / 19.1°C</td>
                        </tr>
                        <tr>
                          <td data-title="MONTH">OCTOBER</td>
                          <td data-title="THIMPHU">21.8 / 10.5°C</td>
                          <td data-title="PARO">18.7 / 7.4°C</td>
                          <td data-title="PUNAKHA">26.1 / 14.3°C</td>
                        </tr>
                        <tr>
                          <td data-title="MONTH">NOVEMBER</td>
                          <td data-title="THIMPHU">17.8 / 5°C</td>
                          <td data-title="PARO">13.8 / 1.4°C</td>
                          <td data-title="PUNAKHA">22.6 / 9.6°C</td>
                        </tr>
                        <tr>
                          <td data-title="MONTH">DECEMBER</td>
                          <td data-title="THIMPHU">14.4 / -1.3°C</td>
                          <td data-title="PARO">11.2 / -1.5°C</td>
                          <td data-title="PUNAKHA">19.1 / 6.2°C</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                </div>
              </div>

              <br /><br />
              {/* <!--By Itinerary--> */}
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h2>
                        DAY WISE <strong><span>BHUTAN TOUR PLAN FROM PUNE</span></strong>
                      </h2>
                      <h4>7 Nights 8 Days <strong>Bhutan Tour Itinerary from Pune Airport</strong></h4>
                    </div>
                  </div>
                </div>


                {/* <!--end Short description--> */}
                <br />
                <div className="row">
                  <div
                    className="accordion"
                    id="accordionPanelsStayOpenExample"
                  >
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="day1"
                      >
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseOne"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseOne"
                        >
                          DAY 1  – : IXB Airport – Phuentsholing (Distance: 170 km. / Driving Time: 5 hrs. / Altitude of Phuentsholing: 293 mts.)
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingOne"
                      >
                        <div className="accordion-body">
                          <p>
                            Meet & Greet on arrival at NJP Railway Station / IXB Airport and transfer to Phuentsholing - The Gateway of Bhutan by Road from India. It is a thriving commercial center on the northern edge of the Indian Plains. On arrival check in at the hotel.Overnight stay at Phuentsholing.

                          </p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay1ImageURL} alt="..." /></center>
                          </div>

                          <div className="content-mobile2">
                            <center><img src={navDay1ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                          <br />
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="day2"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseTwo"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseTwo"
                        >
                          DAY 02: PHUENTSHOLING TO THIMPHU [DISTANCE: 176 KM | 6 HRS]
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseTwo"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingTwo"
                      >
                        <div className="accordion-body">
                          <p>
                            After breakfast, proceed towards Thimpu – the capital city of Bhutan via the wonderful mystic town Gedu, about 9000 ft above the sea and Chukha Dam. On the way, halt at Chojum to take photographs of the confluence of two rivers of Bhutan, Thumpu Chu, and Paro Chu. Reach Thimpu in the evening. On arrival check in at the hotel. In the evening, visit the Tashichho Dzong (Fortress of the Glorious Religion). Overnight stay at hotel in Thimphu.
                          </p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay2ImageURL} alt="..." /></center>
                          </div>

                          <div className="content-mobile2">
                            <center><img src={navDay2ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                          <br />
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="day3"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseThree"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          DAY 03: THIMPHU LOCAL SIGHTSEEING
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseThree"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingThree"
                      >
                        <div className="accordion-body">
                          <p>This is the third day of this mesmerising <strong>Bagdogra to Bhutan Trip</strong>. After breakfast, the day is dedicated to sightseeing in Thimphu. You will see the following tourist attractions in Thimphu including<br /><br />
                            <ul>
                              <li>&nbsp;• Kuenselphodrang</li>
                              <li>&nbsp;•  National Memorial Chorten</li>
                              <li>&nbsp;•  Folk Heritage Museum</li>
                              <li>&nbsp;•  Typical Bhutanese lunch at the Folk Heritage Restaurant would be a great experience</li>
                              <li>&nbsp;•  Sangaygang view point</li>
                              <li>&nbsp;•  Takin Reserve Centre</li>
                              <li>&nbsp;•  Changangkha Monastery</li>
                              <li>&nbsp;•  Tashichho Dzong (Fortress of the Glorious Religion)</li>
                            </ul>
                          </p>
                          <p>
                            Dinner and Overnight Stay at Thimphu Hotel.
                          </p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay3ImageURL} alt="..." /></center>
                          </div>

                          <div className="content-mobile2">
                            <center><img src={navDay3ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                          <br />
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="day4"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseFour"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          Day 04: - THIMPHU TO PUNAKHA TRANSFER VIA DOCHULA PASS
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseFour"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingFour"
                      >
                        <div className="accordion-body">
                          <p>After breakfast, you will be taken on a three-hour road trip from Thimphu to Punakha / Wangdue Phodrang, which is located across the famous Dochu La Pass. During the road trip, you will pass through the Dochula, where you will spot the mani wall, chorten, prayer flags, and others. This is the country’s highest road. You can even spot the peaks of surrounding mountains if the sky is clear. The top peaks to spot during your drive are Masagang, Tsendagang, Terigang, Kangphugang, Table Mountain, and others. This includes the Gangkhar Puensum Peak, which is the highest in Bhutan. On arrival Punakha, check into the Hotel. Post Lunch, You will see the following tourist sites in Punakha / Wangdue including</p>
                          <ul>
                            <li>&nbsp;• Punakha Dzong</li>
                            <li>&nbsp;• Chimi Lhakhang</li>
                            <li>&nbsp;• Punakha Suspension Bridge</li>
                            <li>&nbsp;• Punakha Village</li>
                          </ul>
                          <br />
                          <p>Overnight Stay in Punakha / Wangdue</p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay4ImageURL} alt="..." /></center>
                          </div>

                          <div className="content-mobile2">
                            <center><img src={navDay4ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                          <br />
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="day5"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseFive"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          DAY 05: PUNAKHA / WANGDUE TO PARO
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseFive"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingFive"
                      >
                        <div className="accordion-body">
                          <p>
                            After breakfast, you will leave Punakha / Wangdue and drive to Paro via Dochu La Pass again, covering Paro Chuu river valley and Wang Chhu river valley. On the way, you will be visiting Simtokha Dzong, an old fortress which is now a school for Dzongkha language. Once you reach Paro, you will be checked into a hotel. Later, you will be taken on a sightseeing tour covering important destinations of Paro
                            <br />
                            <br />
                            • Ta Dzong
                            <br />
                            • Rinpung Dzong
                            <br />
                            • Tamchog lhakhang
                            <br />
                          </p>
                          <p>
                            Overnight at the hotel in Paro
                          </p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay5ImageURL} alt="..." /></center>
                          </div>

                          <div className="content-mobile2">
                            <center><img src={navDay5ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                          <br />
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="day6"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseSix"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          DAY 06: PARO LOCAL SIGHTSEEING & TAKTSHANG MONASTERY HIKING
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseSix"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingSix"
                      >
                        <div className="accordion-body">
                          <p>
                            Today is the most important day of this wonderful <strong>Bhutan package from Bagdogra Airport</strong>. After early morning breakfast, you will be starting an excursion tour to Taktshang Monastery. Taktshang Palphug Monastery also known as Tiger's Nest is an amazing Buddhist monastery, temple and sacred site is located in the upper Paro town. Built in 1692, Padmasambhava is believed to have meditated at this site for 3 years. Guru Padmasambhava is known to be the founder of Buddhist religion in Bhutan. He is immensely respected and has a huge number of sacred followers in Bhutan.<br />
                            During your trek, enjoy the breath-taking view of Paro town - lush green valleys, a sparkling river, clear blue skies, and drifting clouds nature at its finest. Spend the night in Paro to fully savor this experience. Overnight at the Paro.

                          </p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay6ImageURL} alt="..." /></center>
                          </div>

                          <div className="content-mobile2">
                            <center><img src={navDay6ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                          <br />
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="day7"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseSeven"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          DAY 07: PARO TO LATAGURI TRANSFER
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseSeven"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingSeven"
                      >
                        <div className="accordion-body">
                          <p>
                            After breakfast check out from the hotel and transfer to Jaldapara. On arrival check in at the hotel. Afternoon Lataguri Jeep Safari (On a Direct Payment Basis). Overnight stay at Lataguri.
                          </p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay7ImageURL} alt="..." /></center>
                          </div>

                          <div className="content-mobile2">
                            <center><img src={navDay7ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                          <br />
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="day8"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseTen"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          DAY 08:LATAGURI TO BAGDOGRA AIRPORT
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseTen"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingTen"
                      >
                        <div className="accordion-body">
                          <p>
                            After breakfast check out from the hotel and take the departure transfer to Bagdogra Airport for your onward journey.
                          </p>
                          {/* <br />
                                            <div className="content-desktop">
                                                <center><img src={navDay8ImageURL} alt="..." /></center>
                                            </div>

                                            <div className="content-mobile2">
                                                <center><img src={navDay8ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                            </div>
                                            <br /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br /><br />
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">{<InclusionExclusion />}</div>
                </div>
              </div>
              <br />
              <br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>HOT SPRINGS AND MINERAL SPRINGS TO EXPERIENCE</b><strong>BHUTAN TOUR FROM PUNE</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>HOT SPRINGS AND MINERAL SPRINGS TO EXPERIENCE</b><strong>BHUTAN TOUR FROM PUNE</strong></h4>
                </div>
                <div className="card-body">
                  <p><b>HOT SPRINGS (TSHACHU) TO ENJOY IN BHUTAN :</b></p>
                  <li>Chuboog Tshachu - Punakha</li><br />
                  <li>Koma Tshachu - Punakha</li><br />
                  <li>Gasa Tsachu - Gasa</li><br />
                  <li>Gayza Tshachu - Gasa</li><br />
                  <li>Dhur Tshachu - Bumthang</li><br />
                  <li>Duenmang Tshachu - Zhemgang</li><br />
                  <li>Gelephu Tshachu - Sarpang</li><br />
                  <li>Khambalung gNey Tshachu - Lhuntse</li><br />
                  <p><b>MINERAL SPRINGS (DRUBCHU) TO EXPLORE DURING YOUR PUNE TO BHUTAN TRIP :</b></p>
                  <li>Bjagay Menchu - Paro</li><br />
                  <li>Dobji Menchu - Paro</li><br />
                  <li>Tokey Menchu - Gasa</li><br />
                  <li>Menchu Karp - Gasa</li><br />
                  <li>Ake Menchu - Gasa</li><br />
                  <li>Yame Menchu - Gasa</li><br />
                  <li>Bakey Menchu - Gasa</li><br />
                  <li>Kabisa Menchu - Punakha</li><br />
                  <li>Rekayzam Menchu - Wangdue Phodrang</li><br />
                  <li>Dankhar Menchu - Zhemgang</li><br />
                  <li>Bharab Menchu and Chethag Menchu - Lhuentse</li><br />
                  <li>Dhonphangma Menchu - Trashigang</li><br />
                  <li>Korphu Menchu - Trongsa</li><br />
                  <li>Khowabokto Menchu - Phobjikha Valley</li><br />
                  <li>Gela Menchu - Phobjikha Valley</li><br />
                  <li>Khasa Menchu - Phobjikha Valley</li><br />
                  <li>Menchu Karp - Thimphu</li><br />
                </div>
              </div>
              <br /><br />
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h2>
                        Frequently Ask <span>Questions</span>
                      </h2>
                      <h4><strong>BHUTAN TOUR PACKAGE FROM PUNE</strong> BY FLIGHT</h4>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div
                    className="accordion accordion-flush faQStyle"
                    id="accordionFlushExample"
                  >
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="question1">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseOne"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseOne"
                        >
                          1. Can I get AC Rooms in Bhutan Group Tour?
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingOne"
                      >
                        <div className="accordion-body">
                          <p>Bhutan generally has a cold climate except in places like Punakha. In Thimphu, Paro, and some hotels in Punakha, you can find rooms with AC facilities. During July and August, AC may be needed in Thimphu and Paro, and even in April and May for Punakha. It's best to discuss AC facilities with Adorable Vacation Bhutan experts before booking your <strong>Bhutan Tour from Pune</strong>.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="question2">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseTwo"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseTwo"
                        >
                          2. What kind of cars are available for a group of 6 on a <strong>Bhutan Tour Package from Pune</strong>?
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseTwo"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingTwo"
                      >
                        <div className="accordion-body">
                          <p>For a comfortable trip starting from Paro Airport, consider Toyota Hiace, known for its spaciousness and comfort. For larger groups of 8 to 12 travelers, Toyota Coaster cars are available. At Adorable Vacation, guest comfort during travel is a top priority.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="question3">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseThree"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          3. Do Indians need a visa to enter Bhutan Tour?
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseThree"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingThree"
                      >
                        <div className="accordion-body">
                          <p>Indian passport holders do not need a visa for Bhutan. However, an entry permit is required, which can be processed on arrival at Paro Airport or in advance through a local travel agent like Adorable Vacation Bhutan.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="question4">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseFour"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseFour"
                        >
                          4. Where can I take photos in Bhutanese dress?
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseFour"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingFour"
                      >
                        <div className="accordion-body">
                          <p>In Paro, outside Paro Dzong, you can take photos in Bhutanese dresses. For women, it's called Kira & Tego, and for men, it's called Goh. Dressing like Bhutanese locals against the backdrop of beautiful landscapes makes for a memorable experience.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="question5">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseFive"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseFive"
                        >
                          5. Are there wheelchair-friendly hotels in Bhutan Trip from Pune?
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseFive"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingFive"
                      >
                        <div className="accordion-body">
                          <p>Most hotels in Bhutan are not wheelchair-friendly, but Adorable Vacation has assisted many wheelchair travelers. Contact the Adorable vacation team for references from guests who have traveled to Bhutan with a wheelchair.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="question6">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseSix"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseSix"
                        >
                          6. What is Sustainable Development Fee (SDF) and how does it impact Indian tourists?
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseSix"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingSix"
                      >
                        <div className="accordion-body">
                          <p>The SDF is Rs. 1200 per person per night for Indian (regional) tourists, excluding tour package costs (flight, hotels, meals, guides, transportation, and monument fees). This fee aims to promote quality tourism in Bhutan.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="question7">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseSeven"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseSeven"
                        >
                          7. How is the climate and weather in Bhutan Sightseeing Tour?
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseSeven"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingSeven"
                      >
                        <div className="accordion-body">
                          <p>Bhutan's climate is generally cold in tourist areas, even in summer. Thimphu, Paro, Phobjikha, and Bumthang have pleasant summers with light woolens needed during the day. Winters are cold with occasional snow in January and February. Rainfall mainly occurs at night during the rainy season (typically July and August).</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="question8">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseEight"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseEight"
                        >
                          8. What is the luggage weight limit on Druk Air?
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseEight"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingEight"
                      >
                        <div className="accordion-body">
                          <p>Druk Air allows 20 kg in economy class and 30 kg in business class. An additional 5 kg is permitted for overhead bins or under the passenger's seat.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="question9">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseNine"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseNine"
                        >
                          9. What is the currency of Bhutan?
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseNine"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingNine"
                      >
                        <div className="accordion-body">
                          <p>The official currency of Bhutan is Ngultrum (Nu, BTN), where 1 Ngultrum equals 1 Indian Rupee.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="question10">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseTen"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseTen"
                        >
                          10. What is the National Game of Bhutan?
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseTen"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingTen"
                      >
                        <div className="accordion-body">
                          <p>Archery is the National Game of Bhutan. You can try your archery skills with bamboo bows in many places during your <strong>Bhutan tour from Pune</strong>.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="question11">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseEleven"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseEleven"
                        >
                          11. What is the National Bird of Bhutan?
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseEleven"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingEleven"
                      >
                        <div className="accordion-body">
                          <p>The Raven is the national bird of Bhutan and holds significance in Bhutanese culture and the Royal Crown.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="question12">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseTwelve"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseTwelve"
                        >
                          12. What is the National Flower of Bhutan?
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseTwelve"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingTwelve"
                      >
                        <div className="accordion-body">
                          <p>The Blue Poppy (Meconopsis) is Bhutan's national flower, found in high-altitude regions in various colors like red, pink, and white.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="question13">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseThirteen"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThirteen"
                        >
                          13. What is the language of Bhutan?
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseThirteen"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingThirteen"
                      >
                        <div className="accordion-body">
                          <p>Dzongkha is Bhutan's national language. Over two dozen languages are spoken in Bhutan, with Nepali being one of the major languages.</p>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h3>
                        <b>
                          Bhutan <span className="tomato">Tour Reviews</span>
                        </b>
                      </h3>
                      <h4>
                        <i>"Your Experience is our Marketing"</i>
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="row row-cols-1 ">
                  <div id="testimonials">
                    <div className="testimonial-box-container">
                      {reviewersDataView}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <BookingBox />
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default PuneToBhutanPackage;
