import React, { Component } from 'react';
import BookingBox from '../BookingBox';
import Slider from "react-slick";
import ApiURL from "../../../api/ApiURL";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InclusionExclusion from '../Sikkim_Darjeeling/InclusionExclusionSikkim';

class Sikkim_Darjeeling_4N_5D_Package extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customActiveTab: 'tab1',
            daywiseActiveTab: 'day1'
        }
    }

    handleCustomTabClick = (tab) => {
        this.setState({
            customActiveTab: tab,
        });
    };

    handleDayWiseTabClick = (tab) => {
        this.setState({
            daywiseActiveTab: tab,
        });
    };
    handleClick = () => {
        const currentUrl = window.location.href;
        const pageTitle = document.title;
        const message = `Hi, Please send about the Tour Package details "${pageTitle}"`;

        const whatsappLink = `https://api.whatsapp.com/send?phone=+919674738480&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
        window.open(whatsappLink, '_blank');
    };

    render() {
        const { customActiveTab, daywiseActiveTab } = this.state;
        const packageTopBannerImageURL = ApiURL.Sikkim_4N_5D_PackageImageURL + "/Sikkim-Darjeeling-Tour.webp";
        const packageTopBanner2ImageURL = ApiURL.Sikkim_4N_5D_PackageImageURL + "/Sikkim-Darjeeling-Packages.webp";
        const Topbanner0ImageURL = ApiURL.Sikkim_4N_5D_PackageImageURL + "/Sikkim-Tour-Package-with-Helicopter-Ride.webp";
        const Topbanner1ImageURL = ApiURL.Sikkim_4N_5D_PackageImageURL + "/River-Rafting-at-Teesta-River.webp";
        const Topbanner2ImageURL = ApiURL.Sikkim_4N_5D_PackageImageURL + "/Sikkim-Holidays-Package-from-Bagdogra-Airport.webp";
        const TopbannerImageURL = ApiURL.Sikkim_4N_5D_PackageImageURL + "/Darjeeling-Joy-Ride.webp";
        const navDay1ImageURL = ApiURL.Sikkim_4N_5D_PackageImageURL + "/day1.webp";
        const navDay2ImageURL = ApiURL.Sikkim_4N_5D_PackageImageURL + "/day2.webp";
        const navDay3ImageURL = ApiURL.Sikkim_4N_5D_PackageImageURL + "/day3.webp";
        const navDay4ImageURL = ApiURL.Sikkim_4N_5D_PackageImageURL + "/day4.webp";
        const navDay5ImageURL = ApiURL.Sikkim_4N_5D_PackageImageURL + "/day5.webp";

        const TrendingPackagesDataList = this.props.TrendingPackagesData;
        const hotPackagesView = TrendingPackagesDataList.length > 0 ?
            (
                TrendingPackagesDataList.map((dataItem, i) => {
                    const hotelImgURL = ApiURL.Sikkim_4N_5D_PackageImageURL + "/" + dataItem.ImageName;
                    return (<div className="col-md-12 p-0" key={i}>
                        <div className="card h-100">
                            <img src={hotelImgURL} className="card-img-top" alt={dataItem.AllternateText} />
                            <div className="card-body">
                                <h6 className="card-title">{dataItem.Title} <span> &#8377; {dataItem.PricePerNight}</span></h6>
                                <p className="card-text">
                                    <i className="fa fa-building tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-utensils tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-car-side tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-male tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-camera tomato"></i>
                                </p>
                                <p className="card-text"><span>{dataItem.Description}</span></p>
                                <p>
                                    <span><b className="tomato">{dataItem.NoOfTours} </b>Tours</span> | &nbsp;
                                    <span><b className="tomato">{dataItem.NoOfDeparture} </b>Departures</span> | &nbsp;
                                    <span><b className="sdf-blue"> {dataItem.Include}</b></span>
                                </p>
                            </div>
                            <div className="card-footer">
                                <small className="text-muted"><strong className="tomato">{dataItem.MutedText}</strong></small>
                                <a href="#" className="btn btn-success btn-sm float-end" onClick={this.handleClick}>
                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                </a>
                            </div>
                        </div>
                    </div>)
                })
            ) : (<div></div>)


        const settings = {
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const reviewersDataList = this.props.ReviewersData;
        const reviewersDataView = reviewersDataList.map((dataItem, i) => {
            const reviewerImageURL = ApiURL.Sikkim_4N_5D_PackageImageURL + "/" + dataItem.ImageName;
            return (
                <div className="testimonial-box" key={i}>
                    <div className="box-top">
                        <div className="profile">
                            <div className="profile-img">
                                <img src={reviewerImageURL} alt="..." />
                            </div>
                            <div className="name-user">
                                <strong>{dataItem.Name}</strong>
                                <span>{dataItem.Location}</span>
                            </div>
                        </div>
                        <div className="reviews">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </div>
                    </div>
                    <div className="client-comment">
                        <p>{dataItem.Comment}</p>
                    </div>
                </div>
            )
        })

        return (
            <>
                <div className="container-fluid">
                    <div className="row">
                        <img src={packageTopBannerImageURL} className="mobile-image2" alt="..." />
                    </div>
                </div>
                <div className="container-fluid custom-container py-3">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="container-fluid custom-container py-1">

                                        <h1 className="content-desktop" style={{ fontSize: "2vw" }}>Looking for <b>SIKKIM GANGTOK DARJEELING TOUR </b>From Bagdogra Airport (IXB)</h1>

                                        <h1 className="content-mobile" style={{ fontSize: "4vw" }}><center>Looking for <b>SIKKIM GANGTOK DARJEELING TOUR </b></center>
                                            <hr />
                                        </h1>
                                        <div className="row">
                                            <img src={packageTopBanner2ImageURL} className="mobile-image" alt="..." />

                                        </div>
                                        <hr />

                                        <div className="col-12">
                                            <big className="text-muted"><strong className="tomato">4N/5D </strong></big>&nbsp;
                                            <i className="fa fa-plane-departure"></i>&nbsp;&nbsp;
                                            <i className="fa fa-arrow-right"></i>&nbsp;Gangtok(2N)&nbsp;
                                            <i className="fa fa-arrow-right"></i>&nbsp;Darjeeling(2N)&nbsp;
                                            <i className="fa fa-arrow-right"></i>&nbsp;&nbsp;
                                            <i className="fa fa-plane-departure fa-flip-horizontal"></i>
                                            <br />
                                        </div>
                                        <hr />
                                        <h2 className="content-desktop p-2" style={{ fontSize: "1.5vw" }}> For Booking <b>DARJEELING GANGTOK PACKAGE</b> <a href='tel:9147061467' class="tomato">☎ CALL Mr. Soumya Sankar  +91- 91470-61467</a></h2>
                                        <h2 className="content-mobile p-4" style={{ fontSize: "4.5vw" }}> For Booking <b>DARJEELING GANGTOK PACKAGE</b> <a href='tel:9147061467' class="tomato">☎ CALL Mr. Soumya Sankar  +91- 91470-61467</a></h2>
                                        <div className="card">
                                            <div className="card-header custHeaer">
                                                <h3 className='content-desktop' style={{ fontSize: "1.5vw" }}>Heartwarming <b>Darjeeling Gangtok Tour</b> Activities </h3>
                                                <h3 className='content-mobile2' style={{ fontSize: "3.5vw" }}>Heartwarming <b>Darjeeling Gangtok Tour</b> Activities </h3>
                                            </div>
                                            <div className="card-body">
                                                <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>DARJEELING JOY RIDE (Toy Train):</b><b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>DARJEELING JOY RIDE (Toy Train):</b> In 1999, the Darjeeling Himalayan Railway was declared a <b>UNESCO</b> World Heritage Site, recognizing its historical and cultural significance as well as its engineering marvel. Darjeeling toy train, is a narrow-gauge railway that runs between New Jalpaiguri and Darjeeling in the Indian state of West Bengal. It is considered as most demanding activity in your <b>Gangtok Darjeeling Tour Plan</b>.</p>
                                                <center> <img src={TopbannerImageURL} className="img-fluid" alt="..." /></center>
                                                <p><b>Steam Locomotives:</b> The Darjeeling toy train is famous for its use of vintage British-built steam locomotives. The train's chugging and whistling sounds evoke a nostalgic atmosphere, attracting railway enthusiasts from around the world. This is most heritage attraction for <b>Sikkim Darjeeling Tour Package.</b></p>
                                                <ul>
                                                    <li><i>Joyride Steam Price – Rs 1600/- Per Head (Steam Engine) </i></li>
                                                    <li><i>Joyride Steam Timing – 00:40 hrs</i></li>
                                                    <li><i>Joyride Route – Ghum - Darjeeling</i></li>
                                                </ul>
                                                <br />
                                                <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>LUXURY HELICOPTER RIDE (Bagdogra to Gangtok):</b><b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>LUXURY HELICOPTER RIDE (Bagdogra to Gangtok):</b> Do you know that you can actually avoid the tiring four to five hour Bagdogra to Gangtok journey along the winding uphill road. You can instead take a helicopter ride during Sikkim Holiday.</p>
                                                <p><b>DIRECTION -</b> Bagdogra Airport to Gangtok.</p>
                                                <center> <img src={Topbanner0ImageURL} className="img-fluid" alt="..." /></center>
                                                <p><b>SERVICE -</b> Sikkim Tourism Development Corporation (STDC)
                                                    DURATION - 35 Minutes</p>
                                                <p><b>HELICOPTER SCHEDULE -</b>
                                                    <ol>
                                                        <li>Gangtok (Burtuk)10:30 A.M – Bagdogra 11:00 A.M</li>
                                                        <li>Bagdogra 11:30 A.M – Gangtok (Burtuk) -12:00 P.M</li>
                                                        <li>Gangtok (Burtuk) 12:30 P.M – Bagdogra – 01:00 P.M</li>
                                                        <li>Bagdogra 01:30 P.M – Gangtok (Burtuk) – 2: 00 P.M</li>
                                                        <li>Gangtok Joy Ride of 20 Mins – Rs.3000/- per person.</li>
                                                    </ol></p>
                                                <p><b>FARE INFORMATION -</b>
                                                    <ol>
                                                        <li>@Rs.5000/- per person one way, Gangtok -Bagdogra</li>
                                                        <li>Child under 2 years considered as infant/ticket fare is Rs.1200. Infant shall not be allotted a seat.</li>
                                                        <li>Luggage – 22 inch of luggage allowed with the Total weight of 10kgs (strictly).</li>
                                                    </ol>
                                                </p>
                                                <p><b>HELICOPTER SPECIFICATION</b></p>
                                                <ul>
                                                    <li>
                                                        Make – MI 172.
                                                    </li>
                                                    <li>Seating Capacity – 20 Seater.</li>
                                                </ul><br />
                                                <p><i>Note: ❖ All the fights are subject to weather condition, adjustment in flight time can also occur during VIP movement, medical emergencies and Air Force Exercise.</i></p>

                                                <br />
                                                <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>RIVER RAFTING - TEESTA RIVER:</b><b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>RIVER RAFTING - TEESTA RIVER:</b></p>
                                                <p>During <b>Sikkim Package Tour</b> from Bagdogra Airport - You can indulge yourself with white water river rafting at mighty Tessta River. Are you an adventure seekers ? We heard yes. You are in the right tourist place. Heart-pumping rapids and high adrenaline are what you will experience at the Rafting Activity. The Teesta River is famous for its rapids and hence hosts rafting activities. So all you got to do is keep up with the power paddling and navigate through the wild currents of the river. While paddling down the river, you’ll be surrounded by deep forests, paddy fields and tiny hamlets. In fact, if you are up for some added adventure you can even pitch a tent and camp on the banks of the river.
                                                </p>
                                                <p><b>Price -</b> Rs 1000/- (Sharing Basis)</p>
                                                <p><b>Timing - </b>10am to 4pm</p>
                                                <p><b>Ideal Day -</b> On the way to Gangtok or return day from Gangtok to Bagdogra airport.</p>
                                                <p><b>Ping - </b>Carry extra pair of cloths</p>
                                                <center> <img src={Topbanner1ImageURL} className="img-fluid" alt="..." /></center>
                                                <br />

                                                <a href="tel://9883359713" className="btn btn-Warning">Call Now</a>
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="container-fluid navtabCustom">
                                        <nav>
                                            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                                <button onClick={() => this.handleCustomTabClick('tab1')} className={`tab button ${customActiveTab === 'tab1' ? 'active' : ''}`} role="tab" aria-controls="nav-highlights" aria-selected={customActiveTab === 'tab1'}>Highlights</button>
                                                <button onClick={() => this.handleCustomTabClick('tab2')} className={`tab button ${customActiveTab === 'tab2' ? 'active' : ''}`} role="tab" aria-controls="nav-flights" aria-selected={customActiveTab === 'tab2'}>Flights</button>
                                                <button onClick={() => this.handleCustomTabClick('tab3')} className={`tab button ${customActiveTab === 'tab3' ? 'active' : ''}`} role="tab" aria-controls="nav-hotel" aria-selected={customActiveTab === 'tab3'}>Hotel</button>
                                                <button onClick={() => this.handleCustomTabClick('tab4')} className={`tab button ${customActiveTab === 'tab4' ? 'active' : ''}`} role="tab" aria-controls="nav-sightseeing" aria-selected={customActiveTab === 'tab4'}>Sightseeing</button>
                                                <button onClick={() => this.handleCustomTabClick('tab5')} className={`tab button ${customActiveTab === 'tab5' ? 'active' : ''}`} role="tab" aria-controls="nav-meals" aria-selected={customActiveTab === 'tab5'}>Meals</button>
                                            </div>
                                        </nav>
                                        <div className="tab-content" id="nav-tabContent">
                                            <div className={customActiveTab === 'tab1' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-highlights-tab">
                                                <p>Are you looking for an unforgettable Trip To <b>Darjeeling And Sikkim</b> through the picturesque landscapes of the Himalayas? Look no further than the <b>Sikkim Darjeeling Tour Packages</b> offering a blend of natural beauty, cultural heritage, and serene experiences that will leave you mesmerized. Let's delve into what makes this <b>Darjeeling And Sikkim Tour</b> a must-visit for any travel enthusiast.</p>

                                                <p><b>Unparalleled Scenic Beauty :</b><b>Sikkim And Darjeeling Tour</b>, nestled in the Eastern Himalayas, boast breathtaking views of snow-capped mountains, lush green valleys, and cascading waterfalls. From the panoramic vistas of Tiger Hill in Darjeeling to the serene lakes of Gangtok like Tsomgo Lake, every moment promises a postcard-worthy scene that will stay etched in your memory.</p>
                                                <p><b>Rich Cultural Heritage : </b>Embark on a cultural odyssey as you explore monasteries, temples, and heritage sites steeped in history. In Gangtok, visit the Enchey Monastery and Rumtek Monastery to witness the vibrant Buddhist culture and intricate architecture. In Darjeeling, the Ghoom Monastery and Peace Pagoda offer insights into the region's spiritual traditions.</p>
                                                <p><b>Adventure and Excitement : </b>For thrill-seekers, this <b>Trip To Darjeeling And Sikkim</b> has plenty to offer. Engage in activities like trekking to Dzongri in Sikkim for stunning mountain views or embark on the Darjeeling Himalayan Railway, a UNESCO World Heritage site famously known as the "Toy Train." Adventure enthusiasts can also indulge in river rafting, paragliding, and mountain biking amidst the Himalayan backdrop.</p>
                                                <p><b>Tea Gardens and Culinary Delights : </b>Darjeeling is synonymous with its world-renowned tea gardens. Take a tour of these lush plantations, learn about the tea-making process, and savor a cup of freshly brewed Darjeeling tea. Additionally, both Gangtok and Darjeeling offer a gastronomic adventure with local delicacies like momos, thukpa, and traditional Sikkimese and Nepali cuisine.</p>
                                                <p><b>Vibrant Markets and Shopping : </b>Explore the bustling markets of Gangtok and Darjeeling, where you can shop for souvenirs, handicrafts, woolens, and traditional artifacts. The Lal Market in Gangtok and the Mall Road in Darjeeling are perfect spots to immerse yourself in the local culture and pick up unique items to remember your trip by.</p>
                                                <p><b>Serenity and Relaxation : </b>After a day of exploration, unwind amidst the serene surroundings of Gangtok and Darjeeling. Enjoy a peaceful stroll along the Mall Road in Darjeeling, soak in the tranquility of Hanuman Tok in Gangtok, or indulge in a rejuvenating spa session to soothe your senses and rejuvenate your soul.</p>
                                                <p>Booking Your <b>Darjeeling And Sikkim Tour Package </b>: With a myriad of experiences awaiting you, <b>Sikkim To Darjeeling Tour Package</b> is the gateway to an unforgettable Himalayan adventure. Whether you're a nature lover, culture enthusiast, or adventure seeker, this tour offers something for everyone, making it an ideal choice for your next vacation. In conclusion, immerse yourself in the enchanting beauty, rich culture, and exciting experiences that <b>Darjeeling And Sikkim Tour</b> have to offer. Embark on a journey that promises memories to last a lifetime and discover why this <b>Darjeeling sikkim package</b> is a favorite among travelers seeking the allure of the Himalayas.
                                                </p>
                                                <p>Overall, <b>Darjeeling And Sikkim Package</b> offer a perfect blend of natural beauty, cultural diversity, adventure, and heritage, making them must-visit destinations for travelers seeking authentic experiences in the lap of the Himalayas.</p>
                                            </div>
                                            <div className={customActiveTab === 'tab2' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-flights-tab">
                                            <div className="overflow-table">
                                                <h4><b>BAGDOGRA SIKKIM </b>FLIGHT CONNECTION -
                                                    <br /><br />
                                                </h4>
                                                <table className="table table-bordered">
                                                    <tr className="table-danger">
                                                        <th>Airlines</th>
                                                        <th>Flight Number</th>
                                                        <th>City</th>
                                                        <th>Departure Time</th>
                                                        <th>Arrival Time</th>
                                                        <th>Travel Time</th>
                                                    </tr>
                                                    <tr>
                                                        <td>IndiGo</td>
                                                        <td>6E - 5305</td>
                                                        <td>Mumbai</td>
                                                        <td>8:05</td>
                                                        <td>10:50</td>
                                                        <td>2h 55m</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Akasa Air</td>
                                                        <td>QP - 1385</td>
                                                        <td>Mumbai</td>
                                                        <td>11:30</td>
                                                        <td>14:25</td>
                                                        <td>2h 55m</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Akasa Air</td>
                                                        <td>QP - 1850</td>
                                                        <td>Bangalore</td>
                                                        <td>9:10</td>
                                                        <td>12:10</td>
                                                        <td>3h 00m</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Air India</td>
                                                        <td>I5 - 1780</td>
                                                        <td>Bangalore</td>
                                                        <td>5:05</td>
                                                        <td>8:05</td>
                                                        <td>3h 00m</td>
                                                    </tr>
                                                    <tr>
                                                        <td>IndiGo</td>
                                                        <td>6E - 6518</td>
                                                        <td>Chennai</td>
                                                        <td>12:05</td>
                                                        <td>14:40</td>
                                                        <td>2h 35m</td>
                                                    </tr>
                                                    <tr>
                                                        <td>IndiGo</td>
                                                        <td>6E -149</td>
                                                        <td>Hyderabad</td>
                                                        <td>10:25</td>
                                                        <td>12:35</td>
                                                        <td>2h 10m</td>
                                                    </tr>
                                                    <tr>
                                                        <td>IndiGo</td>
                                                        <td>6E - 6872</td>
                                                        <td>Hyderabad</td>
                                                        <td>8:25</td>
                                                        <td>10:35</td>
                                                        <td>2h 10m</td>
                                                    </tr>
                                                </table>
                                                <br /><br />
                                                <h4><b>DELHI SPECIAL </b>FLIGHT CONNECTION -
                                                    <br /><br />
                                                </h4>
                                                <table className="table table-bordered">
                                                    <tr className="table-danger">
                                                        <th>Airlines</th>
                                                        <th>Flight Number</th>
                                                        <th>City</th>
                                                        <th>Departure Time</th>
                                                        <th>Arrival Time</th>
                                                        <th>Travel Time</th>
                                                    </tr>
                                                    <tr>
                                                        <td>IndiGo</td>
                                                        <td>6E - 5317</td>
                                                        <td>Delhi</td>
                                                        <td>7:30</td>
                                                        <td>9:35</td>
                                                        <td>2h 05m</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Air India</td>
                                                        <td>I5 - 552</td>
                                                        <td>Delhi</td>
                                                        <td>9:55</td>
                                                        <td>12:00</td>
                                                        <td>2h 05m</td>
                                                    </tr>
                                                </table>
                                            </div>                                            </div>
                                            <div className={customActiveTab === 'tab3' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-hotel-tab">

                                                <div className="overflow-table">
                                                    <table className="table table-bordered">
                                                        <tr className="table-primary text-center">
                                                            {/* <!-- Add the "text-center" className to center content --> */}
                                                            <th colspan="6">HOTEL CATEGORY - 3 STAR </th>
                                                        </tr>
                                                        <tr className="table-danger">
                                                            <th className="text-center">Destination</th>
                                                            <th className="text-center">Hotel Names</th>
                                                            <th className="text-center">Hotel Type</th>
                                                            <th className="text-center">No of Nights</th>
                                                            <th className="text-center">Meal Plan</th>
                                                        </tr>
                                                        <tr>
                                                            <td>Darjeeling</td>
                                                            <td>Taksha Retreat / Golden Potala /  Pink Mountain / Jagjeet Pradhan </td>
                                                            <td>3 Star</td>
                                                            <td>2N</td>
                                                            <td>Room with Breakfast & Dinner</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Gangtok</td>
                                                            <td>Cilantro / Alpine Hills Retreat / Himalayan Heights / Dachen Villa </td>
                                                            <td>3 Star</td>
                                                            <td>2N</td>
                                                            <td>Room with Breakfast & Dinner</td>
                                                        </tr>

                                                    </table>

                                                    <table className="table table-bordered">
                                                        <tr className="table-primary text-center">
                                                            {/*  <!-- Add the "text-center" className to center content --> */}
                                                            <th colspan="6">PACKAGE PRICE IN 3 STAR CATEGORY HOTEL</th>
                                                        </tr>
                                                        <tr class="table-danger">
                                                            <th class="text-center">No of Pax</th>
                                                            <th class="text-center">Package Price (Excluding GST.)</th>
                                                            <th>Transportation</th>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-center">02 Adults</td>
                                                            <td class="text-center">22,500/- (Per Person)</td>
                                                            <td class="text-center">WAGON R / SWIFT DZIRE </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-center">
                                                                04 Adults
                                                            </td>
                                                            <td class="text-center">
                                                                19,600/- (Per Person)
                                                            </td>
                                                            <td class="text-center">
                                                                INNOVA / XYLO
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-center">
                                                                06 Adults
                                                            </td>
                                                            <td class="text-center">
                                                                16,700/- (Per Person)
                                                            </td>
                                                            <td class="text-center">
                                                                INNOVA / XYLO
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    <br />

                                                </div>
                                            </div>
                                            <div className={customActiveTab === 'tab4' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-sightseeing-tab">
                                                <div className="container-fluid px-0 py-1">
                                                    <h4>MUST SEE PLACES IN <b>SIKKIM DARJEELING PACKAGE TOUR</b></h4><br />
                                                    <h5 class="tomato">Tashi View Point - </h5>
                                                    <ul class="place">
                                                        <p> Offer a panoramic view of <b>Mt. Kangchenjunga.</b></p>
                                                    </ul>
                                                    <h5 class="tomato">Gonjang Monastery - </h5>
                                                    <ul class="place">
                                                        <p>Founded in the year 1981 by H.E. Tingkye Gonjang Rimpoche, recognized as an incarnation of Yolmo Terton Ngakchang Shakya Zangpo, a 15th-century Nyingmapa Terton. The monastery follows the Jangter tradition of the Nyingmapa school of Tibetan Buddhism.</p>
                                                    </ul>
                                                    <h5 class="tomato">Do-drul Chorten - </h5>
                                                    <ul class="place">
                                                        <p> Built by Trullshi Rimpoche, head of the Nyingma order of Tibetan Buddhism, in 1945. Inside the stupa, there is a complete mandala set of Dorjee Phurba (Vajra Kilaya), a set of Kang Gyur relic (Holy Book), complete 'Zung' (mantras) and other religious objects. Around the Chorten, there are 108 Mani Lhakor (Prayer Wheels).</p>
                                                    </ul>
                                                    <h5 class="tomato">Namgyal Institute of Tibetology - </h5>
                                                    <ul class="place">
                                                        <p>The most prestigious research institute of its kind in the world, a treasure of vast collection of rare Lepcha, Tibetan and Sanskrit manuscripts, statues and rare Thankas (colorful tapestries used in Buddhist liturgy). Apart from an attractive museum, it has over 200 Buddhist icons and other prized objects of art.</p>
                                                    </ul>
                                                    <h5 class="tomato">Orchid House - </h5>
                                                    <ul class="place">
                                                        <p>Located just below NIT and boasts a population of 250 exotic species of orchids.</p>
                                                    </ul>
                                                    <h5 class="tomato">Hur-Huray Dara - </h5>
                                                    <ul class="place">
                                                        Provides a panoramic view of the lush forests and the vibrant green valley.
                                                    </ul><br />
                                                    <h5 class="tomato">Tsuklakhang (Palace Monastery) - </h5>
                                                    <ul class="place">
                                                        Located inside the palace of the Chogyals, the former rulers of Sikkim, it is the most accessible monastery within the city having interesting murals and images of the Buddhist pantheon.
                                                    </ul><br />
                                                    <h5 class="tomato">Flower Show Hall - </h5>
                                                    <ul class="place">
                                                        A popular permanent exhibition of Sikkims diverse flowering fauna, located near the White Hall.
                                                    </ul><br />
                                                    <h5 class="tomato">Gangtok Ropeway - </h5>
                                                    <ul class="place">
                                                        Offering a breathtaking panoramic birds-eye view of the mountains and the valley of Gangtok, Sikkim.
                                                    </ul><br />
                                                    <h5 class="tomato">Rumtek Monastery - </h5>
                                                    <ul class="place">
                                                        Most sacred pilgrimage sites of Sikkim following "Black Hat" order of the Tibetan Buddhists.
                                                    </ul><br />
                                                    <h5 class="tomato">Tiger Hill Sunrise Point - </h5>
                                                    <ul class="place">
                                                        Spot for an unforgettable sunrise over the snow-covered peaks of the majestic Himalayas.
                                                    </ul><br />
                                                    <h5 class="tomato">Batasia Loop - </h5>
                                                    <ul class="place">
                                                        A loop or a spiral of a narrow-gauge railway track that provides a breathtaking view of the surrounding mountains.
                                                    </ul><br />
                                                    <h5 class="tomato">Ghoom Monastery - </h5>
                                                    <ul class="place">
                                                        It follows the lineage of Sakya Sect of Vajrayana Buddhism and also has a small philosophical institute.
                                                    </ul><br />
                                                    <h5 class="tomato">Padmaja Naidu Himalayan Zoological Park - </h5>
                                                    <ul class="place">
                                                        A Zoo that houses animals of high altitude and mostly from the upper Himalayas like Red Pandas, Snow Leopards, Tibetan Wolf, Takin and a lot more.
                                                    </ul><br />
                                                    <h5 class="tomato">Japanese Temple and Peace Pagoda - </h5>
                                                    <ul class="place">
                                                        Located in the Jalapahar hill, the Japanese Temple is built in traditional Japanese style. Close to it is the Peace Pagoda where there are four large avatars of Lord Buddha.
                                                    </ul><br />
                                                    <h5 class="tomato">Rangeet Valley Passenger Ropeway - </h5>
                                                    <ul class="place">
                                                        Exhibite the mind-blowing view of the valley, lush tea gardens, dense forests, mountain streams in their full flow, waterfalls, and the awesome snow peaks at the background.
                                                    </ul><br />
                                                </div>
                                            </div>
                                            <div className={customActiveTab === 'tab5' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-meals-tab">
                                                <div className="overflow-table">
                                                    <h5 class="tomato"><center>Daily Breakfast and Dinner will be Provided at the Hotel</center></h5>
                                                    <table className="table table-bordered">
                                                        <tr className="table-danger">
                                                            <th colspan="6" className="text-center">Category</th>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-center">JAIN</td>
                                                            <td class="text-center">PURE-VEG</td>
                                                            <td class="text-center">VEGAN</td>
                                                            <td class="text-center">PURE-NON-VEG</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="card">
                                            <div
                                                className="card-header custHeaer"
                                            >
                                                <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>Easy Arrival <b>for Sikkim Darjeeling Holidays</b></h3>
                                                <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>Easy Arrival <b>for Sikkim Darjeeling Holidays</b></h3>
                                            </div>
                                            <div className="card-body">
                                                <p>Planning for a <b>Sikkim Gangtok Darjeeling Tour Package</b> from BagdograAirport ? but not sure what you should visit or how you should plan? Don’t worry, Adorable Vacation will fully assist you.</p>
                                                <p>Our <b>Gangtok Darjeeling Packages</b> comes in different sizes as the place itself is a confluence of art, culture, natural beauty and adventure. Popularly Sikkim Darjeeling Tour, North Sikkim Package, Gangtok Pelling Tour, Gurudongmar Lake Visit, Gangtok Darjeeling Package.</p>
                                                <p>Bagdogra Airport, widely recognized as the Civil Aerodrome Bagdogra, serves as a bustling hub for both domestic and international travelers. Situated in the picturesque town of Bagdogra, merely 16 km away from Siliguri in Darjeeling, this airport has undergone a significant transformation. Originally serving as an Indian Air Force base, it has now evolved into a fully-fledged airport, catering to a wide range of domestic and international flights.</p>
                                                <center> <img src={Topbanner2ImageURL} className="img-fluid" alt="..." /></center><br />
                                                <p>
                                                    <b>Sikkim-</b> a state that was once attributed as a different country became an inseparable part of India in 1975. It is situated in North East India with its rich history, and culture it attracts thousands of tourists from all across the world. Besides historical significance it offers adventurous activities to the people who love mountains.
                                                </p>
                                                <p>
                                                    The state is known for its anti-pollution and anti-tobacco drive campaign. The tiny state nestled in the Himalayas is leading a green revolution in its own way.
                                                </p>
                                                <p>
                                                    <b>Adorable Vacation</b>, sikkim travel agency is an unlimited specialist of the best Sikkim tour package with long years of experience. So, a trip with us will send you on an exclusive journey with an affordable sikkim tour cost and with the needed equipment.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <br /><br />
                            {/* <!--By Itinerary--> */}
                            <div className="container-fluid navtabCustom">

                                <h5 className="tomato">Day wise Itinerary</h5>
                                <hr />

                                <nav>
                                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                        <button onClick={() => this.handleDayWiseTabClick('day1')} className={`tab button ${daywiseActiveTab === 'day1' ? 'active' : ''}`} role="tab" aria-controls="nav-day-one" aria-selected={daywiseActiveTab === 'day1'}>DAY-1</button>
                                        <button onClick={() => this.handleDayWiseTabClick('day2')} className={`tab button ${daywiseActiveTab === 'day2' ? 'active' : ''}`} role="tab" aria-controls="nav-day-two" aria-selected={daywiseActiveTab === 'day2'}>DAY-2</button>
                                        <button onClick={() => this.handleDayWiseTabClick('day3')} className={`tab button ${daywiseActiveTab === 'day3' ? 'active' : ''}`} role="tab" aria-controls="nav-day-three" aria-selected={daywiseActiveTab === 'day3'}>DAY-3</button>
                                        <button onClick={() => this.handleDayWiseTabClick('day4')} className={`tab button ${daywiseActiveTab === 'day4' ? 'active' : ''}`} role="tab" aria-controls="nav-day-four" aria-selected={daywiseActiveTab === 'day4'}>DAY-4</button>
                                        <button onClick={() => this.handleDayWiseTabClick('day5')} className={`tab button ${daywiseActiveTab === 'day5' ? 'active' : ''}`} role="tab" aria-controls="nav-day-five" aria-selected={daywiseActiveTab === 'day5'}>DAY-5</button>
                                    </div>
                                </nav>
                                <div className="tab-content" id="nav-tabContent">
                                    <div className={daywiseActiveTab === 'day1' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-one-tab">
                                        <img src={navDay1ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 class="tomato"> <b>DAY 1: ARRIVAL AT IXB / NJP / SILIGURI AND TRANSFER TO GANGTOK [125 KMS/5 HR]</b></h5>
                                        <p><b>Welcome to the Cleanest State in India - Sikkim,</b> and transfer to the capital city. As the aircraft lands at the nearest airport, our representative waits outside to meet & greet on arrival at Bagdogra and drives towards Gangtok (Alt.5550Ft/1675M). The drive is about 4 hours following the most scenic way through the beautiful lanes of Sikkim along with the picturesque Teesta River. On arrival, check into the beautiful view rooms of the hotel well equipped with modern amenities and sink into the soft bed to get rid of the exhaustion. In the evening, take a stroll in the neighbourhood or enjoy some hot coffee sitting at the balcony while admiring the beauty of the place. Overnight at Gangtok.
                                        </p>
                                        <b>Overnight at Gangtok.</b>
                                    </div>
                                    <div className={daywiseActiveTab === 'day2' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-two-tab">
                                        <img src={navDay2ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 class="tomato"><b>Day 2 - DAY EXCURSION TO CHANGU LAKE, BABA MANDIR AND NATHULA PASS</b></h5>
                                        <p>
                                            <b>As the morning sores in, </b> the warm rays of the sun knock through the window to give a start-up call for the second day. Enjoy breakfast at the hotel in its peaceful surroundings, and load the bags with dry foods before leaving for a full day trip to Tsomgo Lake with Baba Mandir / Nathula Pass Excursion.<br /><br />
                                            <b>Tsomgo Lake (12400ft)- </b>Also known as Changu Lake is an oval shaped glacial lake which is only 40 km from Gangtok City, and almost about 1Km in length with 15 meters depth. The name Tsomgo is made of two words in Bhutia Language 'Tso' means 'lake' and 'Mgo' means 'head' in a short 'source of the lake'. This high-altitude lake is surrounded by snow-capped steep mountains and remains frozen during wintertime. As summer beings to step-in, the glacier melts and the lake gets its origin. The locals consider the lake to be sacred, and it reflects different colors with the change in seasons.<br /><br />
                                            <b>New Baba Harbhajan Singh Mandir - </b>The Baba Mandir was built in the Memorial of Baba Harbhajan Singh or the 'Hero of Nathula', a soldier in the Indian Army. It is situated at a distance of 12Km from Tsomgolake, and 52Km from Gangtok city. Baba Harbhajan served as a part of the border patrol force, and in the year 1968 while escorting a mule column he fell on a fast-flowing stream and got carried away by the powerful water current. Soldiers found his remains after 3 days and cremated with full military honour along with a shrine on his Samadhi built as per Baba’s wish, which he expressed through his friend post-death. The original bunker of Baba Harbhajan Singh is located further up near the Gnathang valley which is the Old Baba Mandir but because of its difficult terrain tourist can’t reach there so they build a New Mandir for easy access. This shrine is believed to have wish-fulfilling powers along with that Baba is still guarding the international border between India and China. On Sundays, a 'Langar' runs at the temple, which provides free meals to the visitors.<br /><br />
                                            <b>Nathula Pass - </b>A major mountain pass in the Himalayas in East Sikkim district, which is a corridor of passage between India and Tibet before it was closed in 1962 but reopened in 2006. Located around 56 km from Gangtok at an altitude of 14450 ft, the road to Nathula is the highest motorable road, passes through the Tsomgo lake, and requires an entry permit to visit. The view from this international border is breath-taking and allows the tourist to get a glimpse of China on the other side along with their Chinese militants. On a clear day, the road winding down the Chumbi valley can be seen.<br /><br />
                                            <b>(Nathula Pass is closed on every Tuesday and Permit depends on Weather conditions) and ALWAYS ON EXTRA PAYMENT BASIS</b><br />
                                        </p>
                                        <b>Overnight at Gangtok.</b>
                                    </div>
                                    <div className={daywiseActiveTab === 'day3' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-three-tab">
                                        <img src={navDay3ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 class="tomato"> <b>Day 3 - GANGTOK TO DARJEELING TRANSFER</b></h5>
                                        <p>Soon after the vibrant Sunrays has begun to warm the day, a shrill noise from the bustling city wake you up as the locals beginning their daily chores. Let your tummy fill with the morning delicacies before Check out from Gangtok and drive for 4 hours towards Darjeeling (Alt 7100Ft/2100M).<br /><br />
                                            <b> "The Queen of Hills" </b>- Darjeeling, a gem of a place known for its youthful vibe combined with modern as well as colonial charm. The dreamy town is situated at an altitude of 7100 ft, surrounded with tall mountains, coniferous trees, and green tea plantation. Darjeeling is a
                                            favoured spotfor everyone whether it’s a tourist, trekker, photographer, artist, or a botanist apart from its World-famous Darjeeling Tea.<br />
                                            On arrival, check-in at the hotel surrounded by Mother Nature with big, clean rooms facing theMt. Kanchenjunga standing head up high. In the afternoon or evening, stroll about and shop -<br />
                                        </p>
                                        <b>Overnight at Darjeeling.</b>
                                    </div>
                                    <div className={daywiseActiveTab === 'day4' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-four-tab">
                                        <img src={navDay4ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 class="tomato"> <b>Day 4 – DARJEELING LOCAL SIGHTSEEING</b></h5>
                                        <p>
                                            As the chilly night continues to dominate over Darjeeling this day calls to wake up even before the sun to witness the stunning view of sunrise from the viewpoint along with full day city tour Post breakfast. Early morning visit to - <br /><br />
                                            <b>Tiger Hill Sunrise Point - </b>An early morning visit to the tiger hill is perfect to witness the unforgettable sunrise over the snow-covered peaks of the majestic Himalayas. As the sunrise beings, the dazzling snow cover peaks of Mt. Kangchenjunga gloriously enlightens with a magnificent view, and on a clear day glimpse of the World’s highest peak Mt. Everest is visible. The sunrise from Tiger Hill has earned international fame for the magnificent view of the sunrise over Kanchenjunga and the great Eastern Himalayan Mountains.<br /><br />
                                            <b>Batasia Loop - </b>The Batasia Loop is a spiral narrow-gauge railway track where the Toy Train negotiates a sudden sharp descent from Ghoom. This place provides a breath-taking view of the surrounding mountains with a 360-degree view of the Darjeeling landscape in the backdrop of the majestic Kanchenjunga. The loop is decorated with grass, shrubs, and seasonal flowers, along benches laid inside the garden to enjoy nature while the Toy train enters hooting its way to complete the spiral route.<br /><br />
                                            <b>Ghoom Monastery -</b>Yiga Monastery or Old Ghoom Monastery is one of the oldest Tibetan monasteries in Darjeeling build in the year 1850 and below the Ghoom railway station. It follows the lineage of Sakya Sect of Vajra yana Buddhism and also has a small philosophical institute. This monastery has the biggest and the oldest statue of Buddha in Darjeeling and is being bought from Tibet. Back to Hotel for breakfast and freshen-up before heading towards city tour.<br /><br />
                                            <b>Padmaja Naidu Himalayan Zoological Park - </b>Also known as Darjeeling Zoo is now one of the best Zoo in the world after bagging the International Earth Hero Award in 2014. The unique feature of this zoo is, it houses animals of high altitude, and mostly from the upper Himalayas with rare animals like Red Pandas, Snow Leopards, Tibetan Wolf, etc. The zoo contributes significantly to the breeding programs of endangered animals and safeguards the vulnerable ones.<br /><br />
                                            <b>Himalayan Mountaineering Institute (Closed on Thursday) - </b>HMI is a pilgrimage for all climbing enthusiasts of the world. Established in honor of Tenzing Norgay, it has a well-stocked museum with mountaineering displays and many expedition artefacts. Pandit Jawaharlal Nehru laid the foundation stone of the Institute, and now it comprises a residential school for mountaineering providing students with basic and advanced courses.<br /><br />
                                            <b>Japanese Temple and Peace Pagoda - </b>Also known as Darjeeling Peace Pagoda are on the Jalapahar hill. They build the Temple under the guidance of Buddhist monks from Japan in traditional Japanese style, and this place is the house of four avatars of Buddha. Close to it is the Peace Pagoda, where it showcases the four large avatars of Lord Buddha. The view from the top is magnificent with the panoramic view of the Darjeeling town, and Mt. Kanchenjunga. During the visit, tourists can join for morning or evening prayers in the temple along with the monks.
                                            <br /><br />
                                            <b>Tenzing Rock & Gombu Rock - </b>This huge and giant natural rock named after Tenzing Norgay who created history after climbing the Mt. Everest is at Lebong Cart Road, also known as Monkey Rock. Located opposite of Tenzing rock is the Gombu rock name after Nawang Gombu, the nephew of Tenzing Norgay, who was the first person to climb Everest twice. Tenzing Norgay and his nephew used to climb this rock to train them for Everest climbing, and now these two steep rocks are being used by HMI during their Rock climbing training courses.
                                            <br /><br />
                                            <b>Tibetan Refugee Self Help Centre - </b>The help center was established on 1st October 1959, to rehabilitate the Tibetan refugees who followed Dalai Lama and escaped from Tibet. The workers in the centre produce excellent Tibetan handicrafts like carpets, woollens, wood, and leather works which they sell and export to different countries for a living.
                                            <br /><br />
                                            <b>Rangeet Valley Passenger Ropeway - </b>Also known as The Darjeeling Ropeway is the perfect spot to enjoy the breathtaking view of the valley below with the dreamy Darjeeling town, the lush green tea plantations, dense forests, mountain streams in their full flow, waterfalls, and the impressive snow peaks the background.
                                            <br /><br />
                                            You can also enjoy the <b>World UNESCO Heritage</b> world-famous Darjeeling railway or the Toy Train to Ghoom subject to track conditions. It’s the main and best attraction for visiting this hill train
                                            in Darjeeling. The path in which the train runs is well decorated by nature with lush green trees, flowering plants, small bushes, and vast Kanchenjunga dominating its presence throughout the journey.<br /><br />
                                            The evening is free to visit the Darjeeling Mall for shopping as this is the last spot to crack a great deal and grab something unique from this trip.
                                        </p>

                                        <b>Overnight Stay at Darjeeling.</b>
                                    </div>
                                    <div className={daywiseActiveTab === 'day5' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-five-tab">
                                        <img src={navDay5ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 class="tomato"> <b>Day 5 – DARJEELING TO NJP/IXB TRANSFER</b></h5>
                                        <p>
                                            <b>Morning after breakfast,</b>
                                            Proceed to NJP Rly Station / Bagdogra Airport (IXB) from Darjeeling with sweet memories.
                                        </p>
                                    </div>

                                </div>
                            </div>
                            {/* <!--By Itinerary--> */}

                            {/* <!--insert table inclusion exclusion--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        {
                                            <InclusionExclusion />
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* <!--end table inclusion exclusion--> */}

                            {/* <!--start of package details--> */}
                            <div className="container-fluid py-5">

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>Trending Sikkim Darjeeling <span>Tour Packages</span></h2>
                                            <h4>Related Sikkim Darjeeling Tour plan</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                                        <div className="carousel-inner">
                                            <div className="carousel-item active">

                                                <Slider {...settings}>
                                                    {hotPackagesView}
                                                </Slider>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!--end of package details--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h3>FREQUENTLY ASKED QUESTIONS [FAQ] FOR <b><span class="tomato">SIKKIM DARJEELING PACKAGE TOUR</span></b></h3>
                                            <h4>SUMMER HOLIDAYS FROM NJP / BAGDOGRA</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div
                                        className="accordion accordion-flush faQStyle"
                                        id="accordionFlushExample"
                                    >
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingOne">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseOne"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseOne"
                                                >
                                                    1. What is included in the Sikkim Darjeeling package tour?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseOne"
                                                className="accordion-collapse"
                                                aria-labelledby="flush-headingOne"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    •	The package typically includes accommodation, meals as per the itinerary, transportation between destinations, sightseeing tours and what else mentioned in your tour package.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingTwo">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseTwo"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseTwo"
                                                >
                                                    2.	What are the best times to visit Sikkim and Darjeeling?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseTwo"
                                                className="accordion-collapse collapseShow"
                                                aria-labelledby="flush-headingTwo"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    •	The best time to visit is during the spring (March to May) and autumn (September to November) seasons when the weather is pleasant, and the skies are clear, offering stunning views of the Himalayas.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingThree">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseThree"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseThree"
                                                >
                                                    3.	What are the must-visit attractions in Sikkim and Darjeeling?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseThree"
                                                className="accordion-collapse collapseShow"
                                                aria-labelledby="flush-headingThree"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    •	In Sikkim, must-visit attractions include Tsomgo Lake, Rumtek Monastery, Nathula Pass (subject to permits), and Pelling for its panoramic views. In Darjeeling, don't miss Tiger Hill, Batasia Loop, Ghoom Monastery, and a ride on the Darjeeling Himalayan Railway.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingFour">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseFour"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseFour"
                                                >
                                                    4. Are permits required to visit certain places in Sikkim?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseFour"
                                                className="accordion-collapse collapseShow"
                                                aria-labelledby="flush-headingFour"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    •	Yes, permits are required for certain areas like Nathula Pass and some parts of North Sikkim. We will assist you in obtaining the necessary permits.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingFive">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseFive"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseFive"
                                                >
                                                    5. What types of accommodations are available on the tour?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseFive"
                                                className="accordion-collapse collapseShow"
                                                aria-labelledby="flush-headingFive"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Yes, you can carry Indian Note to Bhutan. Except Rs
                                                    2000/- Note, which is not a legal tender in Bhutan?
                                                    However, many merchants in Bhutan now days accept Rs
                                                    2000/- Note.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingSix">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseSix"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseFive"
                                                >
                                                    6. Is the tour suitable for elderly or differently-abled travellers?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseSix"
                                                className="accordion-collapse collapseShow"
                                                aria-labelledby="flush-headingSix"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    •	While some areas may involve walking or hiking, many attractions are accessible by vehicle, making the tour suitable for elderly or differently-abled travellers. It's advisable to inform your tour operator in advance for any special arrangements needed.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingSeven">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseSeven"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseSeven"
                                                >
                                                    7.	Are meals included in the tour package?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseSeven"
                                                className="accordion-collapse collapseShow"
                                                aria-labelledby="flush-headingSeven"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    •	Yes, most tour packages include meals as per the itinerary, which may vary from breakfast-only to full-board options. You can also explore local cuisine at your own expense during free time.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingEight">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseEight"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseEight"
                                                >
                                                    8. What should I pack for the tour?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseEight"
                                                className="accordion-collapse collapseShow"
                                                aria-labelledby="flush-headingEight"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    •	Pack according to the season of your visit, including comfortable clothing, sturdy walking shoes, sunscreen, sunglasses, a hat, insect repellent, and any medications you may need. Don't forget your camera to capture the scenic beauty!
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingNine">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseNine"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseNine"
                                                >
                                                    9. Are there any cultural or local experiences included in the tour?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseNine"
                                                className="accordion-collapse collapseShow"
                                                aria-labelledby="flush-headingNine"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    •	Yes, many tours include cultural experiences such as visits to monasteries, interaction with local communities, traditional dance performances, and opportunities to learn about the region's history and heritage.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingTen">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseTen"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseTen"
                                                >
                                                    10. Can I customize the tour itinerary?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseTen"
                                                className="accordion-collapse collapseShow"
                                                aria-labelledby="flush-headingTen"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    •	Depending on the tour operator, you may have the option to customize certain aspects of the itinerary, such as extending your stay, adding extra activities, or choosing specific accommodations. Discuss your preferences with the tour provider for personalized arrangements.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingEleven">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseEleven"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseEleven"
                                                >
                                                    11.	How do I book the Sikkim Darjeeling package tour?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseEleven"
                                                className="accordion-collapse collapseShow"
                                                aria-labelledby="flush-headingEleven"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    •	You can contact us via Phone, WhatsApp or mail and we will be sharing the quotation via Email. Once you confirm the proposal we can proceed further towards booking.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingTwelve">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseTwelve"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseTwelve"
                                                >
                                                    12. Is travel insurance included in the tour package?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseEleven"
                                                className="accordion-collapse collapseShow"
                                                aria-labelledby="flush-headingEleven"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    • Travel insurance is typically not included in the tour package but is highly recommended for your safety and peace of mind. You can purchase travel insurance separately or inquire if the tour operator offers it as an optional add-on.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h3><b>Sikkim Darjeeling <span class="tomato">Tour Reviews</span></b></h3>
                                            <h4><i>"Your Experience is our Marketing"</i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-cols-1">
                                    <div id="testimonials">
                                        <div className="testimonial-box-container">

                                            {reviewersDataView}

                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="col-md-3">
                            <BookingBox />
                        </div>
                    </div >
                </div >
            </>
        );
    }
}

export default Sikkim_Darjeeling_4N_5D_Package;