import React, { Component } from 'react';
import ApiURL from "../../api/ApiURL";
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

// Import Swiper styles
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

class Banner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            HomeBannerSliderData: []
        }
    }

    componentDidMount() {
        const bannerDataList = [
            { ImageName: "Punakha-Zong.webp", AlternateText: "..." },
            { ImageName: "Bhutan.webp", AlternateText: "..." },
            { ImageName: "Rajasthan.webp", AlternateText: "..." },
            { ImageName: "Egypt.webp", AlternateText: "..." },
            { ImageName: "Beach.webp", AlternateText: "..." },
            { ImageName: "Maldives.webp", AlternateText: "..." },
            { ImageName: "Meghalaya.webp", AlternateText: "..." },
        ];

        this.setState({ HomeBannerSliderData: bannerDataList })
    }

    render() {

        const sliderList = this.state.HomeBannerSliderData;
        const MyView = sliderList.length > 0 ?
            (<Swiper
                spaceBetween={30}
                slidesPerView={1}
                navigation={false}
                pagination={{ clickable: true }}
                centeredSlides={true}
                autoplay={{ delay: 6000, disableOnInteraction: false }}
                /* onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={() => console.log('slide change')} */
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
            >
                {sliderList.map((sliderItem, i) => {
                    console.log(sliderItem);
                    const sliderImgURL = ApiURL.HomeBannerImageURL + "/" + sliderItem.ImageName;
                    return (<SwiperSlide key={i}>
                        <div className="carousel-item active">
                            <img src={sliderImgURL} className="d-block w-100" alt={sliderItem.AlternateText} />
                        </div>
                    </SwiperSlide>)
                })}
            </Swiper>) :
            (<div></div>)

        return (
            <>
                <div className="container-fluid">
                    <div className="row">
                        <div id="carouselExampleSlidesOnly" className="carousel slide px-0" data-bs-ride="carousel">
                            <div className="carousel-inner">
                                {MyView}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Banner;