import React, { Component } from 'react';

class InclusionExclusion_group extends Component {
    render() {
        return (
            <>
                <h4 className='tomato'><u>BHUTAN TOUR PACKAGE INCLUSION</u></h4>
                <ul>
                <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;BOM TO PBH DIRECT FLIGHT
                    </li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Transfer all on a Sharing Basis Vehicle
                    </li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Sustainable Development Fees (SDF)Rs.1,200/Per adult / Per Night</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Pick up & Drop at Bagdogra Airport.</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Unlimited Breakfast, Lunch, Dinner in Buffet.</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;1 Day Special Lunch LM Paro in 5 Star Property.</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Sightseeing as per the itinerary point-to-a-point basis.</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Accommodation in all places.</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Meal plan: Breakfast, Lunch & Dinner.</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;One bottle of Mineral Water per head per day.</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Professional English-Speaking Guide.</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;One tourist Sim Card on Arrival (Per Family).</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;One time Lataguri Jeep Safari.</li>
                </ul><br/><br/>
                <h4 className='tomato'><u>BHUTAN PACKAGE TOUR EXCLUSION</u></h4>
                <ul>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Extras of personal nature include personal tips, laundry charges, and additional tour charges.</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Travel Insurance (Mandatory for Bhutan travel).</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Insurance, Medical & Emergency rescue costs.</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Does not include Hard drinks or liquor or soft drinks/juices.</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Does not include Entrance Fees at Monasteries / Monuments /Museums etc.</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Air Fare.</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Additional sightseeing or extra usage of vehicles, other than those mentioned in the itinerary.</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Any cost arising due to natural calamities like landslides, road blockage, political disturbances (strikes), etc (to be borne by the client, which is directly payable on the spot).</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Any increase in taxes or fuel price, leading to an increase in cost on surface transportation & land arrangements, which may come into effect prior to departure.</li>
                </ul>
            </>
        );
    }
}

export default InclusionExclusion_group;