import React, {Component, Fragment} from 'react';
import FooterDesktop from '../components/common/FooterDesktop';
import MainHeader from '../components/common/MainHeader';
import Policy from "../components/Others/Policy";

class PolicyPage extends Component {    
    constructor(props) {
        super(props);
        this.state = {
            showMobileNavStatus: false
        }
    }

    componentDidMount() {
        window.scroll(0, 0);
        this.hideMobileNav();
    }

    showMobileNav = () => {
        this.setState({ showMobileNavStatus: true })
    }

    hideMobileNav = () => {
        this.setState({ showMobileNavStatus: false })
    }

    render() {
        return (
            <Fragment>
                <MainHeader />

                <Policy/>

                <FooterDesktop />
            </Fragment>
        );
    }
}

export default PolicyPage;
