import React, { Component } from "react";
import BookingBox from "../BookingBox";
import Slider from "react-slick";
import ApiURL from "../../../api/ApiURL";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./InclusionExclusion";
import InclusionExclusion from "./InclusionExclusion_Pune";

class ChennaiToBhutanPackage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customActiveTab: "tab1",
    };
  }

  handleCustomTabClick = (tab) => {
    this.setState({
      customActiveTab: tab,
    });
  };

  render() {
    const { customActiveTab } = this.state;
    const packageTopBannerImageURL = ApiURL.BhutanTourPackageFromChennaiImageURL + "/Bhutan-Tour-Package-from-Chennai.webp";
    const packageTopBanner2ImageURL = ApiURL.BhutanTourPackageFromChennaiImageURL + "/Bhutan-Package-Tour-from-Chennai.webp";
    const sightseen1ImageURL = ApiURL.BhutanTourPackageFromChennaiImageURL + "/FAMOUS-PLACES-IN-BHUTAN-SIGHTSEEING.webp";
    const sightseen2ImageURL = ApiURL.BhutanTourPackageFromChennaiImageURL + "/FAMOUS-PLACES-IN-BHUTAN-SIGHTSEEING (2).webp";
    const sightseen3ImageURL = ApiURL.BhutanTourPackageFromChennaiImageURL + "/FAMOUS-PLACES-IN-BHUTAN-SIGHTSEEING (3).webp";
    const sightseen4ImageURL = ApiURL.BhutanTourPackageFromChennaiImageURL + "/ADVENTURE-ACTIVITIES-TO-ENJOY-DURING-A-BHUTAN-TOUR-FROM-CHENNAI (2).webp";
    const sightseen5ImageURL = ApiURL.BhutanTourPackageFromChennaiImageURL + "/ADVENTURE-ACTIVITIES-TO-ENJOY-DURING-A-BHUTAN-TOUR-FROM-CHENNAI (3).webp";
    const sightseen6ImageURL = ApiURL.BhutanTourPackageFromChennaiImageURL + "/ADVENTURE-ACTIVITIES-TO-ENJOY-DURING-A-BHUTAN-TOUR-FROM-CHENNAI.webp";
    const sightseen7ImageURL = ApiURL.BhutanTourPackageFromChennaiImageURL + "/BEST-TIME-TO-BOOK-A-CHENNAI-TO-BHUTAN-TOUR-PACKAGES.webp";
    const sightseen8ImageURL = ApiURL.BhutanTourPackageFromChennaiImageURL + "/BEST-TIME-TO-BOOK-A-CHENNAI-TO-BHUTAN-TOUR-PACKAGES (2).webp";
    const sightseen9ImageURL = ApiURL.BhutanTourPackageFromChennaiImageURL + "/BEST-TIME-TO-BOOK-A-CHENNAI-TO-BHUTAN-TOUR-PACKAGES (3).webp";
    const sightseen10ImageURL = ApiURL.BhutanTourPackageFromChennaiImageURL + "/BHUTAN-CULTURE (2).webp";
    const sightseen11ImageURL = ApiURL.BhutanTourPackageFromChennaiImageURL + "/BHUTAN-CULTURE (3).webp";
    const sightseen12ImageURL = ApiURL.BhutanTourPackageFromChennaiImageURL + "/BHUTAN-CULTURE.webp";
    const navDay1ImageURL = ApiURL.BhutanTourPackageFromChennaiImageURL + "/The-Gateway-of-Bhutan-by-Road-from-India.webp";
    const navDay2ImageURL = ApiURL.BhutanTourPackageFromChennaiImageURL + "/Phuentsholing-to-Thimphu.webp";
    const navDay3ImageURL = ApiURL.BhutanTourPackageFromChennaiImageURL + "/Tourist-sites-in-Thimphu.webp";
    const navDay4ImageURL = ApiURL.BhutanTourPackageFromChennaiImageURL + "/Thimphu-to-Punakha-Wangdue-Phodrang.webp";
    const navDay5ImageURL = ApiURL.BhutanTourPackageFromChennaiImageURL + "/Punakha-Wangdue-and-drive-to-Paro.webp";
    const navDay6ImageURL = ApiURL.BhutanTourPackageFromChennaiImageURL + "/Excursion-tour-to-Taktshang-Monastery.webp";
    const navDay7ImageURL = ApiURL.BhutanTourPackageFromChennaiImageURL + "/Paro-to-Lataguri.webp";

    const settings = {
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    const hotelDataList = this.props.HotelsData;
    const hotelDataView = hotelDataList.map((hotelItem, i) => {
      const hotelImageURL =
        ApiURL.BhutanTourPackageFromChennaiImageURL + "/" + hotelItem.ImageName;
      return (
        <div className="col-md-3" key={i}>
          <img
            src={hotelImageURL}
            className="rounded mx-auto d-block"
            alt={hotelItem.AllternateText}
          />
          <br />
          <span>
            <b>{hotelItem.HotelName}</b> |
            <span className="tomato">{hotelItem.HotelStar}</span>
          </span>
          <br />
          <span className="rating-box">
            <span className="star">{hotelItem.RatingBoxStar}</span>
            <b className="rating-value">{hotelItem.RatingValue}</b>
          </span>{" "}
          &nbsp;<b>{hotelItem.Reviews}</b>
        </div>
      );
    });

    const themePackageSliderList = this.props.ThemePackagesData;
    const themePackagesDataView = themePackageSliderList.map(
      (sliderItem, i) => {
        const sliderImageURL =
          ApiURL.BhutanTourPackageFromChennaiImageURL +
          "/" +
          sliderItem.ImageName;
        return (
          <div className="theme-cards-wrapper mx-2" key={i}>
            <div className="theme-card">
              <div className="image-wrapper">
                <img src={sliderImageURL} alt={sliderItem.AllternateText} />
              </div>
              <div className="card-body">
                <h5 className="card-title">{sliderItem.Title}</h5>
                <p className="card-text">{sliderItem.Description}</p>
                <Link to={"#"} className="btn btn-primary">
                  Book Now
                </Link>
              </div>
            </div>
            <div className="clearfix"></div>
          </div>
        );
      }
    );

    const trendingBhutanDataList = this.props.TrendingBhutanDataList;
    const trendingBhutanDataView = trendingBhutanDataList.map(
      (sliderItem, i) => {
        const trendingBhutanImgURL = ApiURL.BhutanTourPackageFromChennaiImageURL + "/" + sliderItem.ImageName;
        return (
          <div className="theme-cards-wrapper mx-2" key={i}>
            <div className="theme-card">
              <div className="image-wrapper">
                <img src={trendingBhutanImgURL} alt="..." />
              </div>
              <div className="card-body">
                <h6 className="card-title">{sliderItem.Title}<span> &#8377; {sliderItem.PricePerNight}/-</span></h6>
                <p className="card-text">
                  <i className="fa fa-building tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-utensils tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-car-side tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-male tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-camera tomato"></i>
                </p>
                <p className="card-text"><span>{sliderItem.Description}</span></p>
                <p>
                  <span><b className="tomato">{sliderItem.NoOfTours} </b>Tours</span> |
                  <span><b className="tomato">{sliderItem.NoOfDeparture} </b>Departures</span> |
                  <span><b className="sdf-blue">SDF Inc.</b></span>
                </p>
                <div className="card-footer">
                  <small className="text-muted"><strong className="tomato">EX-IXB</strong></small>
                  <Link to={sliderItem.BookNowURL} className="btn btn-Warning btn-sm float-end">
                    Book Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        );
      }
    );

    const reviewersDataList = this.props.ReviewersData;
    const reviewersDataView = reviewersDataList.map((dataItem, i) => {
      const reviewerImageURL =
        ApiURL.BhutanTourPackageFromChennaiImageURL + "/" + dataItem.ImageName;
      return (
        <div className="testimonial-box" key={i}>
          <div className="box-top">
            <div className="profile">
              <div className="profile-img">
                <img src={reviewerImageURL} alt="..." />
              </div>
              <div className="name-user">
                <strong>{dataItem.Name}</strong>
                <span>{dataItem.Location}</span>
              </div>
            </div>
            <div className="reviews">
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
            </div>
          </div>
          <div className="client-comment">
            <p>{dataItem.Comment}</p>
          </div>
        </div>
      );
    });

    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <img
              src={packageTopBannerImageURL}
              className="mobile-image2"
              alt="..."
            />
          </div>
        </div>

        <div className="container-fluid custom-container py-3">
          <div className="row">
            <div className="col-md-9">
              <div className="row">
                <div className="col-md-12">
                  <div className="container-fluid custom-container py-1">
                    <h1 className="content-desktop" style={{ fontSize: "2vw" }}><center>Book <strong>Bhutan Package Tour from Chennai</strong></center></h1>

                    <h1 className="content-mobile" style={{ fontSize: "4vw" }}><center>Book <strong>Bhutan Package Tour from Chennai</strong></center>
                      <hr />
                    </h1>
                    <div className="row">
                      <img src={packageTopBanner2ImageURL} className="mobile-image" alt="..." />

                    </div>
                    <hr />
                    <div className="d-flex flex-column flex-sm-row align-items-sm-center px-2">
                      <div className="font-lg">
                        5 Cities:{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Phuentsholing
                        </a>
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Thimphu
                        </a>
                        &nbsp;{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Paro
                        </a>{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Punakha
                        </a>
                        {" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Lataguri
                        </a>
                      </div>
                      <div className="reviews mx-5">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                      </div>
                    </div>
                    <p className="p-2">
                      If you are looking for a memorable <strong>Bhutan Tour from Chennai</strong>, you have come to the right place!
                    </p>
                    <hr />
                  </div>
                  <h2 className="content-desktop p-4" style={{ fontSize: "1.4vw" }}> FOR BOOKING <strong> BHUTAN PACKAGES FROM CHENNAI</strong> <a href='tel:9883359713' class="tomato">☎ CALL Mr. SOUMALYA +91- 98833-59713</a></h2>
                  <h2 className="content-mobile p-4" style={{ fontSize: "4.5vw" }}> FOR BOOKING <strong> BHUTAN PACKAGES FROM CHENNAI</strong> <a href='tel:9883359713' class="tomato">☎ CALL Mr. SOUMALYA +91- 98833-59713</a></h2>
                </div>
              </div>

              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>WHY SHOULD BHUTAN BE ON YOUR TRAVEL WISH LIST?</b></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>WHY SHOULD BHUTAN BE ON YOUR TRAVEL WISH LIST?</b></h4>
                </div>
                <div className="card-body">
                  <p>
                    Start an amazing trip from the lively city of Chennai to the magical kingdom of Bhutan with our special <strong>Bhutan Tour Cost from Chennai</strong>. Bhutan, located in the Eastern Himalayas, is full of cultural treasures, peaceful landscapes, and spiritual sites. Here's why Bhutan should be on your travel wish list and why it's a top destination for travelers from Chennai.
                  </p>
                  <p><b>Cultural Richness</b><br />
                    Bhutan, known as the "Land of the Thunder Dragon," has a unique and well-preserved culture. The Bhutanese people take great pride in their traditions, seen in their distinct clothing, architecture, and daily practices. The annual Tsechu festivals feature colorful dances, religious ceremonies, and vibrant performances, making it a fascinating experience for those looking to explore Bhutan's culture.</p>

                  <p><b>Dzongs and Monasteries</b><br />
                    Bhutan is filled with majestic Dzongs, which are fortress-like buildings that serve as both administrative and religious centers. Paro Taktsang, or Tiger's Nest, is a famous monastery on a cliff with stunning views of the Paro Valley and is a must-visit. The Punakha Dzong, located at the meeting point of two rivers, is another architectural wonder that showcases Bhutan's history and spirituality. Other notable Dzongs include Zakar Dzong, Trongsa Dzong, Paro Dzong, Tashichho Dzong, and Lhuntse Dzong. These structures reveal Bhutan’s unique history and are essential stops for travelers.
                  </p>
                  <p><b>Spiritual Retreats</b><br />
                    Bhutan's monasteries and temples are places of peace and spirituality. Kyichu Lhakhang in Paro, one of the oldest temples in Bhutan, offers a serene atmosphere for meditation. Dochula Pass, with its 108 stupas, provides panoramic views of the Himalayas and a peaceful place for reflection. Other important temples include the National Memorial Chorten in Thimphu, Chimi Lhakhang in Punakha, Jokhang Temple in Bumthang, Taktsang Temple in Paro, Tango Monastery in Thimphu, and Gangteng Monastery in Phodrang. These sites are must-sees on a <strong>Bhutan Tour From Chennai</strong>.
                  </p>
                  <p><b>Natural Beauty</b><br />
                    Bhutan’s landscape is a showcase of untouched beauty, from lush green valleys to snow-capped mountains. The Phobjikha Valley, a glacial valley known for its scenic beauty and wildlife, is a must-visit for nature lovers. Bhutan’s commitment to environmental conservation ensures a pristine environment, making it a perfect destination for those seeking a natural retreat.
                  </p>
                  <p>Adorable Vacation LLP also Offer  <a href="/mumbai-special-chartered-flight" target="_blank"><b className="tomato">Special Chertered Mumbai to Bhutan </b></a>,<a href="/bhutan-tour-package-from-pune" target="_blank"><b className="tomato">Bhutan Package Tour from Pune</b></a>,<a href="/bhutan-tour-from-delhi" target="_blank"><b className="tomato"> Bhutan Package Tour from Delhi</b></a>, <a href="/bhutan-tour-package-from-mumbai" target="_blank"><b className="tomato">Bhutan Package Tour from Mumbai</b></a>, <a href="/bhutan-tour-package-from-ahemdabad" target="_blank"><b className="tomato">Bhutan Package Tour from Ahmedabad</b></a>, <a href="/bhutan-tour-from-bengaluru" target="_blank"><b className="tomato">Bhutan Package Tour from Bangalore</b></a>,<a href="/bhutan-tour-package-from-jaigaon" target="_blank"><b className="tomato"> Bhutan Package Tour from Jaigaon</b></a>, <a href="/bhutan-tour-package-from-kolkata" target="_blank"><b className="tomato">Bhutan Package Tour from Kolkata</b></a>,<a href="/bhutan-tour-package-from-bagdogra" target="_blank"><b className="tomato">Bhutan Package Tour from Bagdogra</b></a>,<a href="/bhutan-tour-package-from-surat" target="_blank"><b className="tomato">Bhutan Package Tour from Surat</b></a> etc at Best Price.</p>
                  <br />

                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen1ImageURL} alt="Sightseeing 1" />
                          <img src={sightseen3ImageURL} alt="Sightseeing 3" />
                          <img src={sightseen2ImageURL} alt="Sightseeing 2" />

                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <br /><br />
                  <a href="tel://9883359713" className="btn btn-Warning">
                    Call Now
                  </a>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> <strong>BHUTAN TOUR FROM CHENNAI VIA BAGDOGRA AIRPORT</strong> DURATION : 7N 8D</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>BHUTAN TOUR FROM CHENNAI VIA BAGDOGRA AIRPORT</strong> DURATION : 7N 8D</h4>
                </div>
                <div className="card-body">
                  <p>Destinations Covered in <strong>Bhutan Tour Package Booking from Chennai</strong> : Phuentsholing 1N | Thimphu 2N | Punakha 1N | Paro 2N | Lataguri 1N</p>
                  <p>Pickup & Drop off  <strong>Bhutan Tour Flight</strong> : Bagdogra International Airport [IXB]
                  </p>
                  <p><strong>7N/8D Bhutan Tour Package Cost from Chennai</strong> : Rs. 35,199 /P [Land Cost starting from Bagdogra Airport]</p>
                  <br />
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr className="table-danger">
                          <th>CITY</th>
                          <th>ALTITUDE</th>
                          <th>CO-ORDINATES</th>

                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td data-title="CITY">Thimphu</td>
                          <td data-title="ALTITUDE">2,334 m</td>
                          <td data-title="CO-ORDINATES">27.4712° N, 89.6339° E</td>
                        </tr>
                        <tr>
                          <td data-title="CITY">Punakha</td>
                          <td data-title="ALTITUDE">1,274 m</td>
                          <td data-title="CO-ORDINATES">27.5921° N, 89.8797° E</td>
                        </tr>
                        <tr>
                          <td data-title="CITY">Paro</td>
                          <td data-title="ALTITUDE">2,200 m</td>
                          <td data-title="CO-ORDINATES">27.4287° N, 89.4164° E</td>
                        </tr>

                        <tr>
                          <td data-title="CITY">Chele La Pass</td>
                          <td data-title="ALTITUDE">3,988 m</td>
                          <td data-title="CO-ORDINATES">27.3698° N, 89.3466° E</td>
                        </tr>
                        <tr>
                          <td data-title="CITY">Dochula Pass</td>
                          <td data-title="ALTITUDE">3,100 m</td>
                          <td data-title="CO-ORDINATES">27.2924° N, 89.4501° E</td>
                        </tr>
                        <tr>
                          <td data-title="CITY">Tigers Nest Monastery</td>
                          <td data-title="ALTITUDE">3,120 m</td>
                          <td data-title="CO-ORDINATES">27.2930° N, 89.2148° E</td>
                        </tr>
                        <tr>
                          <td data-title="CITY">Haa Valley</td>
                          <td data-title="ALTITUDE">2,670 m</td>
                          <td data-title="CO-ORDINATES">27.3333° N, 89.1833° E</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br /><br />

              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> <strong>ATTRACTIONS IN PUNE TO BHUTAN TOUR PACKAGES</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>ATTRACTIONS IN PUNE TO BHUTAN TOUR PACKAGES</strong></h4>
                </div>
                <div className="card-body">
                  <p>There are many attractions to explore in this <strong>Bhutan Tour Package From Pune</strong>, such as:</p>
                  <p><b>Places of attractions in Thimphu :</b> Tashichho Dzong, Simply Bhutan Museum, Buddha Dordenma Statue, National Memorial Chorten, Motithang Takin Preserve, Changangkha Lhakhang, Royal Textile Academy of Bhutan, Centenary Farmers' Market, National Folk Heritage Museum, Simtokha Dzong, Pangri Zampa Monastery</p>
                  <p><b>Places of attractions in Punakha :</b> Punakha Dzong, Chimi Lhakhang (The Fertility Temple), Khamsum Yulley Namgyal Chorten, Sangchhen Dorji Lhuendrup Lhakhang Nunnery, Mo Chhu River Rafting</p>
                  <p><b>Places of attractions in Paro :</b> Taktsang (Tiger's Nest) Monastery, Paro Dzong (Rinpung Dzong), National Museum of Bhutan, Drukgyel Dzong, Kyichu Lhakhang, Jangsarbu Lhakhang, Dungtse Lhakhang, Chele La Pass, Paro Weekend Market</p>
                  <p><b>Places of attractions in Phobjikha Valley :</b> Gangte Goemba [Gangtey Monastery], Black-Necked Crane Information Centre, Gangtey Nature Trail, Nyelung Drachaling, Kuenzang Chholing Shedra, Khewang Lhakhang, Phobjikha Valley Hiking Trails, Kumbhu Lhakhang, Damcho Lhakhang, Dzongdrakha Goemba, Bumdeling Wildlife Sanctuary, Jigme Singye Wangchuck National Park, Gogona Village trek etc.</p>
                  <p><b>Places of attractions in Haa Valley :</b> Juneydrak Hermitage, Katsho Goemba, Lhakhang Karpo, Lhakhang Nagpo, Rigsum Gonpo, Trout Farm, Wangchuk Lo Dzong, Yangthang Goemba etc.</p>
                  <p></p>
                </div>
              </div>
              <br /><br />

              <div className="container-fluid py-5">
                <div className="row">
                  <div className="card">
                    <table className="table table-bordered">
                      <thead>
                        <tr className="table-danger">
                          <th>DAY WISE SUMMERY FOR 7N|8D <strong>PUNE TO BHUTAN TOUR ITINERARY</strong></th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingOne"
                          >
                            <a href="#day1" style={{ color: 'black' }}><b>DAY 1 → </b>
                              <span>
                                BAGDOGRA AIRPORT TO PHUENTSHOLING
                              </span>
                            </a>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingTwo"
                          >
                            <a href="#day2" style={{ color: 'black' }}><b>DAY 2 → </b>
                              <span>PHUENTSHOLING TO THIMPHU</span>
                            </a>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingThree"
                          >
                            <a href="#day3" style={{ color: 'black' }}><b>DAY 3 → </b>
                              <span>THIMPHU LOCAL SIGHTSEEING</span>
                            </a>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingFour"
                          >
                            <a href="#day4" style={{ color: 'black' }}><b>DAY 4 → </b>
                              <span>THIMPHU TO PUNAKHA TRANSFER VIA DOCHULA PASS</span>
                            </a>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingFive"
                          >
                            <a href="#day5" style={{ color: 'black' }}><b>DAY 5 → </b>
                              <span>PUNAKHA / WANGDUE TO PARO</span>
                            </a>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingSix"
                          >
                            <a href="#day6" style={{ color: 'black' }}><b>DAY 6 → </b>
                              <span>PARO LOCAL SIGHTSEEING & TAKTSHANG MONASTERY HIKING</span>
                            </a>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingSeven"
                          >
                            <a href="#day7" style={{ color: 'black' }}><b>DAY 7 → </b>
                              <span>PARO TO LATAGURI TRANSFER</span>
                            </a>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingTen"
                          >
                            <a href="#day8" style={{ color: 'black' }}><b>DAY 8 → </b>
                              <span>LATAGURI TO BAGDOGRA AIRPORT DROP</span>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* <!--end Short description--> */}
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>ENTRY FORMALITIES IN BHUTAN AS PER NEW TOURISM POLICY</b></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>ENTRY FORMALITIES IN BHUTAN AS PER NEW TOURISM POLICY</b></h4>
                </div>
                <div className="card-body">

                  <li>Indian passport holders don't need a visa to visit Bhutan, but they do need entry permits. These can be arranged on arrival or in advance by a local travel agent like Adorable Vacation LLP Bhutan.</li><br />
                  <li>A valid passport with at least 6 months remaining is required to travel to Bhutan.</li><br />
                  <li>Tourists can travel beyond Thimphu and Paro with the same permit on any day.</li><br />
                  <li>Under the new tourism policy, regional tourists must pay a Sustainable Development Fee (SDF) of Rs. 1200 per person per night, in addition to the package price (which includes flights, hotels, meals, guides, transportation, and monument fees).</li><br />
                  <li>All permits for <strong>Bhutan Tours from Chennai</strong> will be processed online.</li><br />
                  <li>Booking with a Tourism Council of Bhutan (TCB) approved hotel and tour guide is mandatory.</li><br />
                  <li>Online permits can be arranged through local registered tour operators or any international partners.</li><br />
                  <br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen10ImageURL} alt="Sightseeing 1" />
                          <img src={sightseen11ImageURL} alt="Sightseeing 3" />
                          <img src={sightseen12ImageURL} alt="Sightseeing 2" />

                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <br /><br />

                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr className="table-danger">
                          <th>NAME</th>
                          <th>TOWN</th>
                          <th>START DATE</th>
                          <th>END DATE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th colspan="4"><center>PUNAKHA FESTIVALS 2024</center></th>
                        </tr>
                        <tr>
                          <td data-title="NAME">Punakha Drubchen</td>
                          <td data-title="TOWN">Punakha</td>
                          <td data-title="START DATE">Feb 16, 2024</td>
                          <td data-title="END DATE">Feb 18, 2024</td>
                        </tr>
                        <tr>
                          <td data-title="NAME">Punakha Tshechu</td>
                          <td data-title="TOWN">Punakha</td>
                          <td data-title="START DATE">Feb 19, 2024</td>
                          <td data-title="END DATE">Feb 21, 2024</td>
                        </tr>
                        <tr>
                          <td data-title="NAME">Talo Tshechu</td>
                          <td data-title="TOWN">Punakha</td>
                          <td data-title="START DATE">Mar 17, 2024</td>
                          <td data-title="END DATE">Mar 19, 2024</td>
                        </tr>
                        <tr>
                          <th colspan="4"><center>THIMPHU FESTIVALS 2024</center></th>
                        </tr>
                        <tr>
                          <td data-title="NAME">Rhododendron Festival</td>
                          <td data-title="TOWN">Thimphu</td>
                          <td data-title="START DATE">Apr 13, 2024</td>
                          <td data-title="END DATE">Apr 14, 2024</td>
                        </tr>
                        <tr>
                          <td data-title="NAME">Tour Of the Dragon (Mountain Bike Race)</td>
                          <td data-title="TOWN">Thimphu</td>
                          <td data-title="START DATE">Sep 02, 2024</td>
                          <td data-title="END DATE"></td>
                        </tr>
                        <tr>
                          <td data-title="NAME">Thimphu Drubchen</td>
                          <td data-title="TOWN">Thimphu</td>
                          <td data-title="START DATE">Sep 09, 2024</td>
                          <td data-title="END DATE"></td>
                        </tr>
                        <tr>
                          <td data-title="NAME">Thimphu Tshechu</td>
                          <td data-title="TOWN">Thimphu</td>
                          <td data-title="START DATE">Sep 13, 2024</td>
                          <td data-title="END DATE">Sep 15, 2024</td>
                        </tr>
                        <tr>
                          <td data-title="NAME">Dechenphu Tshechu</td>
                          <td data-title="TOWN">Thimphu</td>
                          <td data-title="START DATE">Oct 12, 2024</td>
                          <td data-title="END DATE"></td>
                        </tr>
                        <tr>
                          <td data-title="NAME">Jhomolhari Mountain Festival</td>
                          <td data-title="TOWN">Thimphu</td>
                          <td data-title="START DATE">Oct 14, 2024</td>
                          <td data-title="END DATE">Oct 15, 2024</td>
                        </tr>
                        <tr>
                          <td data-title="NAME">Druk Wangyel Tshechu</td>
                          <td data-title="TOWN">Thimphu</td>
                          <td data-title="START DATE">Dec 13, 2024</td>
                          <td data-title="END DATE"></td>
                        </tr>
                        <tr>
                          <th colspan="4"><center>BUMTHANG FESTIVALS 2024</center></th>
                        </tr>
                        <tr>
                          <td data-title="NAME">Tharpaling Thongdrol</td>
                          <td data-title="TOWN">Bumthang</td>
                          <td data-title="START DATE">Feb 24, 2024</td>
                          <td data-title="END DATE"></td>
                        </tr>
                        <tr>
                          <td data-title="NAME">Domkhar Tshechu</td>
                          <td data-title="TOWN">Bumthang</td>
                          <td data-title="START DATE">Apr 18, 2024</td>
                          <td data-title="END DATE">Apr 20, 2024</td>
                        </tr>
                        <tr>
                          <td data-title="NAME">Ura Yakchoe</td>
                          <td data-title="TOWN">Bumthang</td>
                          <td data-title="START DATE">Apr 20, 2024</td>
                          <td data-title="END DATE">Apr 24, 2024</td>
                        </tr>
                        <tr>
                          <td data-title="NAME">Nimalung Tshechu</td>
                          <td data-title="TOWN">Bumthang</td>
                          <td data-title="START DATE">Jun 14, 2024</td>
                          <td data-title="END DATE">Jun 16, 2024</td>
                        </tr>
                        <tr>
                          <td data-title="NAME">Kurjey Tshechu</td>
                          <td data-title="TOWN">Bumthang</td>
                          <td data-title="START DATE">Jun 16, 2024</td>
                          <td data-title="END DATE"></td>
                        </tr>
                        <tr>
                          <td data-title="NAME">Tamshing Phala Chhoepa</td>
                          <td data-title="TOWN">Bumthang</td>
                          <td data-title="START DATE">Sep 13, 2024</td>
                          <td data-title="END DATE">Sep 15, 2024</td>
                        </tr>
                        <tr>
                          <td data-title="NAME">Thangbi Mani</td>
                          <td data-title="TOWN">Bumthang</td>
                          <td data-title="START DATE">Sep 17, 2024</td>
                          <td data-title="END DATE">Sep 19, 2024</td>
                        </tr>
                        <tr>
                          <td data-title="NAME">Jakar Tshechu</td>
                          <td data-title="TOWN">Bumthang</td>
                          <td data-title="START DATE">Oct 10, 2024</td>
                          <td data-title="END DATE">Oct 12, 2024</td>
                        </tr>
                        <tr>
                          <td data-title="NAME">Prakhar Duchhoed</td>
                          <td data-title="TOWN">Bumthang</td>
                          <td data-title="START DATE">Oct 18, 2024</td>
                          <td data-title="END DATE">Oct 20, 2024</td>
                        </tr>
                        <tr>
                          <td data-title="NAME">Nalakhar Tshechu</td>
                          <td data-title="TOWN">Bumthang</td>
                          <td data-title="START DATE">Nov 15, 2024</td>
                          <td data-title="END DATE">Nov 17, 2024</td>
                        </tr>
                        <tr>
                          <td data-title="NAME">Jambay Lhakhang Drup</td>
                          <td data-title="TOWN">Bumthang</td>
                          <td data-title="START DATE">Nov 15, 2024</td>
                          <td data-title="END DATE">Nov 18, 2024</td>
                        </tr>
                        <tr>
                          <td data-title="NAME">Jambay Lhakhang Singye Cham</td>
                          <td data-title="TOWN">Bumthang</td>
                          <td data-title="START DATE">Nov 15, 2024</td>
                          <td data-title="END DATE"></td>
                        </tr>
                        <tr>
                          <th colspan="4"><center>TRASHIGANG FESTIVALS 2024</center></th>
                        </tr>
                        <tr>
                          <td data-title="NAME">Trashigang Tshechu</td>
                          <td data-title="TOWN">Trashigang</td>
                          <td data-title="START DATE">Nov 09, 2024</td>
                          <td data-title="END DATE">Nov 11, 2024</td>
                        </tr>
                        <tr>
                          <td data-title="NAME">Chhorten Kora</td>
                          <td data-title="TOWN">Trashigang</td>
                          <td data-title="START DATE">Mar 25, 2024</td>
                          <td data-title="END DATE">Apr 08, 2024</td>
                        </tr>
                        <tr>
                          <td data-title="NAME">Gomphukora</td>
                          <td data-title="TOWN">Trashigang</td>
                          <td data-title="START DATE">Mar 17, 2024</td>
                          <td data-title="END DATE">Mar 19, 2024</td>
                        </tr>
                        <tr>
                          <th colspan="4"><center>WANGDUE PHODRANG FESTIVALS 2024</center></th>
                        </tr>
                        <tr>
                          <td data-title="NAME">Wangdue Tshechu</td>
                          <td data-title="TOWN">Wangdue Phodrang</td>
                          <td data-title="START DATE">Sep 11, 2024</td>
                          <td data-title="END DATE">Sep 13, 2024</td>
                        </tr>
                        <tr>
                          <td data-title="NAME">Gangtey Tshechu</td>
                          <td data-title="TOWN">Wangdue Phodrang</td>
                          <td data-title="START DATE">Sep 16, 2024</td>
                          <td data-title="END DATE">Sep 18, 2024</td>
                        </tr>
                        <tr>
                          <td data-title="NAME">Black Necked Crane Festival</td>
                          <td data-title="TOWN">Wangdue Phodrang</td>
                          <td data-title="START DATE">Nov 11, 2024</td>
                          <td data-title="END DATE">-</td>
                        </tr>
                        <tr>
                          <th colspan="4"><center>TRONGSA FESTIVALS 2024</center></th>
                        </tr>
                        <tr>
                          <td data-title="NAME">Trongsa Tshechu</td>
                          <td data-title="TOWN">Trongsa</td>
                          <td data-title="START DATE">Dec 08, 2024</td>
                          <td data-title="END DATE">Dec 10, 2024</td>
                        </tr>
                        <tr>
                          <td data-title="NAME">Nabji Lhakhang Drup</td>
                          <td data-title="TOWN">Trongsa</td>
                          <td data-title="START DATE">Dec 15, 2024</td>
                          <td data-title="END DATE">Dec 16, 2024</td>
                        </tr>
                        <tr>
                          <th colspan="4"><center>OTHER FESTIVALS 2024</center></th>
                        </tr>
                        <tr>
                          <td data-title="NAME">Zhemgang Tshechu</td>
                          <td data-title="TOWN">Zhemgang</td>
                          <td data-title="START DATE">Mar 17, 2024</td>
                          <td data-title="END DATE">Mar 19, 2024</td>
                        </tr>
                        <tr>
                          <td data-title="NAME">Gasa Tshechu</td>
                          <td data-title="TOWN">Gasa</td>
                          <td data-title="START DATE">Mar 17, 2024</td>
                          <td data-title="END DATE">Mar 19, 2024</td>
                        </tr>
                        <tr>
                          <td data-title="NAME">Paro</td>
                          <td data-title="TOWN">Paro</td>
                          <td data-title="START DATE">Mar 21, 2024</td>
                          <td data-title="END DATE">Mar 25, 2024</td>
                        </tr>
                        <tr>
                          <td data-title="NAME">Chukha Tshechu</td>
                          <td data-title="TOWN">Chukha</td>
                          <td data-title="START DATE">Oct 10, 2024</td>
                          <td data-title="END DATE">Oct 12, 2024</td>
                        </tr>
                        <tr>
                          <td data-title="NAME">Royal Highland Festival (Laya)</td>
                          <td data-title="TOWN">Laya</td>
                          <td data-title="START DATE">Oct 23, 2024</td>
                          <td data-title="END DATE">Oct 24, 2024</td>
                        </tr>
                        <tr>
                          <td data-title="NAME">Pemagatshel Tshechu</td>
                          <td data-title="TOWN">Pemagatshel</td>
                          <td data-title="START DATE">Nov 09, 2024</td>
                          <td data-title="END DATE">Nov 11, 2024</td>
                        </tr>
                        <tr>
                          <td data-title="NAME">Mongar Tshechu</td>
                          <td data-title="TOWN">Mongar</td>
                          <td data-title="START DATE">Nov 09, 2024</td>
                          <td data-title="END DATE">Nov 11, 2024</td>
                        </tr>
                        <tr>
                          <td data-title="NAME">Lhuentse Tshechu</td>
                          <td data-title="TOWN">Lhuentse</td>
                          <td data-title="START DATE">Dec 08, 2024</td>
                          <td data-title="END DATE">Dec 10, 2024</td>
                        </tr>
                        <tr>
                          <td data-title="NAME">Samdrup Jongkhar Tshechu</td>
                          <td data-title="TOWN">Samdrup Jongkhar</td>
                          <td data-title="START DATE">Dec 13, 2024</td>
                          <td data-title="END DATE">Dec 15, 2024</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>WHAT IS THE BEST WAY FOR A BHUTAN FROM CHENNAI?</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>WHAT IS THE BEST WAY FOR A BHUTAN FROM CHENNAI?</h4>
                </div>
                <div className="card-body">
                  <p>Chennai is a popular city in India with a unique charm that attracts visitors from around the world. It has a great transportation system, making it easy to travel to almost anywhere.</p>
                  <p>If you're planning a <strong>Bhutan Tour Package From Chennai</strong>, you have several options. Let's explore these options to help you plan your <strong>Bhutan Trip from Chennai</strong>.</p>
                  <br />
                  <p><b>HOW TO REACH</b> <strong>BHUTAN TRIP FROM CHENNAI</strong> <b>BY AIR</b></p>
                  <p>To fly from Chennai to Bhutan, you need to make a stopover. Chennai doesn't have direct flights to Bhutan's Paro International Airport (PBH). Here's how to get there:</p>
                  <p><b>•	First Flight:</b> Fly from Chennai to either Delhi, Kolkata, or Guwahati. These cities offer direct flights to Paro.</p>
                  <p><b>•	Connecting Flight:</b> From Delhi (Indira Gandhi International Airport), Kolkata (Netaji Subhash Chandra Bose International Airport), or Guwahati (Lokpriya Gopinath Bordoloi International Airport), catch a direct flight to Paro.</p>
                  <br />
                  <p><strong>BHUTAN TOUR FROM DELHI</strong> [DIRECT FLIGHT]</p>
                  <br />
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr className="table-danger">
                          <th>DEPARTURE</th>
                          <th>SECTOR</th>
                          <th>OUTWARD JOURNEY TIME</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td data-title="DEPARTURE">Sunday</td>
                          <td data-title="SECTOR">Delhi → Paro</td>
                          <td data-title="O/W JUR. TIME">Depart 10:55 - Arrive 14:35</td>
                        </tr>
                        <tr>
                          <td data-title="DEPARTURE">Monday</td>
                          <td data-title="SECTOR">Delhi → Paro</td>
                          <td data-title="O/W JUR. TIME">Depart 10:55 - Arrive 14:35</td>
                        </tr>
                        <tr>
                          <td data-title="DEPARTURE">Wednesday</td>
                          <td data-title="SECTOR">Delhi → Paro</td>
                          <td data-title="O/W JUR. TIME">Depart 10:55 - Arrive 14:35</td>
                        </tr>
                        <tr>
                          <td data-title="DEPARTURE">Saturday</td>
                          <td data-title="SECTOR">Delhi → Paro</td>
                          <td data-title="O/W JUR. TIME">Depart 10:55 - Arrive 14:35</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br />
                  <p><strong>BHUTAN TOUR FROM DELHI RETURN</strong> [DIRECT FLIGHT]</p>
                  <br />
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr className="table-danger">
                          <th>DEPARTURE</th>
                          <th>SECTOR</th>
                          <th>OUTWARD JOURNEY TIME</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td data-title="DEPARTURE">Sunday</td>
                          <td data-title="SECTOR">Paro → Delhi</td>
                          <td data-title="O/W JUR. TIME">Depart 07:05 - Arrive 09:55</td>
                        </tr>
                        <tr>
                          <td data-title="DEPARTURE">Monday</td>
                          <td data-title="SECTOR">Paro → Delhi</td>
                          <td data-title="O/W JUR. TIME">Depart 07:05 - Arrive 09:55</td>
                        </tr>
                        <tr>
                          <td data-title="DEPARTURE">Wednesday</td>
                          <td data-title="SECTOR">Paro → Delhi</td>
                          <td data-title="O/W JUR. TIME">Depart 07:05 - Arrive 09:55</td>
                        </tr>
                        <tr>
                          <td data-title="DEPARTURE">Saturday</td>
                          <td data-title="SECTOR">Paro → Delhi</td>
                          <td data-title="O/W JUR. TIME">Depart 07:05 - Arrive 09:55</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br />
                  <h5><strong>BHUTAN TOUR FROM GUWAHATI</strong> [DIRECT FLIGHT]</h5>
                  <br />
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr className="table-danger">
                          <th>AIRLINES</th>
                          <th>FLIGHT NO</th>
                          <th>ORIGIN</th>
                          <th>DEPARTURE</th>
                          <th>DESTINATION</th>
                          <th>DEP DAYS</th>

                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td data-title="AIRLINES">Drukair-Royal Bhutan Airlines</td>
                          <td data-title="FLIGHT NO">KB 541</td>
                          <td data-title="ORIGIN">[GAU]</td>
                          <td data-title="DEPARTURE">15:20</td>
                          <td data-title="DESTINATION">[PBH]</td>
                          <td data-title="DEP DAYS">Every Sunday</td>
                        </tr>
                        <tr>
                          <td data-title="AIRLINES">Drukair-Royal Bhutan Airlines</td>
                          <td data-title="FLIGHT NO">KB 541</td>
                          <td data-title="ORIGIN">[GAU]</td>
                          <td data-title="DEPARTURE">15:20</td>
                          <td data-title="DESTINATION">[PBH]</td>
                          <td data-title="DEP DAYS">Every Thursday</td>
                        </tr>
                        <tr>
                          <td data-title="AIRLINES">Drukair-Royal Bhutan Airlines</td>
                          <td data-title="FLIGHT NO">KB 540</td>
                          <td data-title="ORIGIN">[PBH]</td>
                          <td data-title="DEPARTURE">07:50</td>
                          <td data-title="DESTINATION">[GAU]</td>
                          <td data-title="DEP DAYS">Every Saturday</td>
                        </tr>
                        <tr>
                          <td data-title="AIRLINES">Drukair-Royal Bhutan Airlines</td>
                          <td data-title="FLIGHT NO">KB 540</td>
                          <td data-title="ORIGIN">[PBH]</td>
                          <td data-title="DEPARTURE">07:50</td>
                          <td data-title="DESTINATION">[GAU]</td>
                          <td data-title="DEP DAYS">Every Wednesday</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br />
                  <h5><b>HOW TO REACH</b> <strong>CHENNAI TO BHUTAN TRIP BY TRAIN</strong></h5>
                  <br />
                  <p>Bhutan doesn’t have a direct train connection with India, but you can still enjoy a train journey part of the way. Here’s how to travel <strong>Chennai to Bhutan Tour by train</strong>:</p>
                  <p><b>1.	Train to West Bengal</b>: Take a train from Chennai to the nearest Indian railway stations near Bhutan. These stations include Hashimara, New Alipurduar Junction, Siliguri Town, New Jalpaiguri, and Hasimara.</p>
                  <p><b>2.	Drive to Bhutan</b>: From any of these railway stations in West Bengal, drive to Jaigaon, a town on the Indo-Bhutan border.</p>
                  <p><b>3.	Enter Bhutan</b>: Cross into Bhutan at Phuentsholing, and then drive 143 km (about 4 hours) along the Thimphu-Phuentsholing Highway to reach Thimphu.</p>
                  <p>Popular trains from Chennai to Kolkata and Chennai to Guwahati can be used for planning your <strong>Bhutan Tour</strong>.</p>
                  <br />
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr className="table-danger">
                          <th>TRAIN NO</th>
                          <th>TRAIN NAME</th>
                          <th>ORIGIN</th>
                          <th>DESTINATION</th>
                          <th>DURATION</th>
                          <th>DEP DAYS</th>

                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td data-title="TRAIN NO">22826</td>
                          <td data-title="TRAIN NAME">Mas Shm Express</td>
                          <td data-title="ORIGIN">CHENNAI [MAS - 16:25]</td>
                          <td data-title="DESTINATION">SHALIMAR [SHM - 21:30]</td>
                          <td data-title="DURATION">29 hrs 05 mins</td>
                          <td data-title="DEP DAYS">Every Thursday</td>
                        </tr>
                        <tr>
                          <td data-title="TRAIN NO">12840</td>
                          <td data-title="TRAIN NAME">Howrah Mail</td>
                          <td data-title="ORIGIN">CHENNAI [MAS - 23:40]</td>
                          <td data-title="DESTINATION">HOWRAH [HWH - 04:10]</td>
                          <td data-title="DURATION">28 hrs 30 mins</td>
                          <td data-title="DEP DAYS">Daily</td>
                        </tr>
                        <tr>
                          <td data-title="TRAIN NO">06029</td>
                          <td data-title="TRAIN NAME">Bangalore Express</td>
                          <td data-title="ORIGIN">CHENNAI [MAS - 07:40]</td>
                          <td data-title="DESTINATION">SHALIMAR [SHM - 11:10]</td>
                          <td data-title="DURATION">27 hrs 30 mins</td>
                          <td data-title="DEP DAYS">Friday, Saturday, Sunday</td>
                        </tr>
                        <tr>
                          <td data-title="TRAIN NO">12842</td>
                          <td data-title="TRAIN NAME">Coromandel Express</td>
                          <td data-title="ORIGIN">CHENNAI [MAS - 08:45]</td>
                          <td data-title="DESTINATION">HOWRAH [HWH - 11:50]</td>
                          <td data-title="DURATION">27 hrs 05 mins</td>
                          <td data-title="DEP DAYS">Daily</td>
                        </tr>
                        <tr>
                          <td data-title="TRAIN NO">02842</td>
                          <td data-title="TRAIN NAME">Mas Hwh Premium</td>
                          <td data-title="ORIGIN">CHENNAI [MAS - 17:20]</td>
                          <td data-title="DESTINATION">HOWRAH [HWH - 16:10]</td>
                          <td data-title="DURATION">24 hrs 50 mins</td>
                          <td data-title="DEP DAYS">Every Saturday</td>
                        </tr>
                        <tr>
                          <td data-title="TRAIN NO">12507</td>
                          <td data-title="TRAIN NAME">Guwahati Express</td>
                          <td data-title="ORIGIN">TRIVANDRUM [TVC - 17:00]</td>
                          <td data-title="DESTINATION">GUWAHATI [GHY - 07:55]</td>
                          <td data-title="DURATION">62 hrs 55 mins</td>
                          <td data-title="DEP DAYS">Every Tuesday</td>
                        </tr>
                        <tr>
                          <td data-title="TRAIN NO">12509</td>
                          <td data-title="TRAIN NAME">Guwahati Express</td>
                          <td data-title="ORIGIN">BANGALORE [SBC - 23:30]</td>
                          <td data-title="DESTINATION">GUWAHATI [GHY - 05:50]</td>
                          <td data-title="DURATION">54 hrs 20 mins</td>
                          <td data-title="DEP DAYS">Wednesday, Thursday, Friday</td>
                        </tr>
                        <tr>
                          <td data-title="TRAIN NO">15901</td>
                          <td data-title="TRAIN NAME">Dibrugarh Express</td>
                          <td data-title="ORIGIN">BANGALORE [SBC - 23:55]</td>
                          <td data-title="DESTINATION">DIBRUGARH [DBRG - 21:00]</td>
                          <td data-title="DURATION">62 hrs 55 mins</td>
                          <td data-title="DEP DAYS">Every Tuesday</td>
                        </tr>
                        <tr>
                          <td data-title="TRAIN NO">05901</td>
                          <td data-title="TRAIN NAME">Yesvantpur - Dibrugarh Spl</td>
                          <td data-title="ORIGIN">YESVANTPUR [YPR - 09:30]</td>
                          <td data-title="DESTINATION">DIBRUGARH [DBRG - 04:25]</td>
                          <td data-title="DURATION">66 hrs 55 mins</td>
                          <td data-title="DEP DAYS">Every Monday</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>HOW TO TRAVEL FROM</b> <strong>CHENNAI TO BHUTAN TRIP BY ROAD</strong>?</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>HOW TO TRAVEL FROM</b> <strong>CHENNAI TO BHUTAN TRIP BY ROAD</strong>?</h4>
                </div>
                <div className="card-body">
                  <p>Planning a <strong>Chennai To Bhutan Trip By Road</strong> can be a really exciting adventure! Starting from the southernmost part of India, this journey will take you through different landscapes, cities, towns, and more. If you're up for a <strong>Bhutan bike trip</strong>, this is a great way to explore two countries on wheels.</p>
                  <p>To kick off this awesome road trip, first plan a <strong>Bhutan Biking Tour from Chennai to Kolkata</strong>. Kolkata, known as the City of Joy, is the closest big city to Bhutan, making it an ideal stop for fans of the 'Land of Thunder Dragons'. Follow this route:</p>
                  <p>Chennai - Gudur - Nellore - Guntur - Vijayawada - Eluru - Rajamundry - Vishakhapatnam - Srikakulam - Puri - Cuttack - Balasore - Kharagpur - Kolkata.</p>
                  <p>Once in Kolkata, you can either take direct buses to Thimphu in Bhutan run by the Royal Bhutanese Government or start the second leg of your road trip to Jaigaon in the Alipurduar district of West Bengal. For those opting for buses, catch them at the Esplanade Bus Stand in Kolkata for an 18-hour journey to Bhutan's capital, Thimphu.<br />
                    If you're not a fan of bus rides, head to Jaigaon from Kolkata for the second part of your <strong>Bhutan road trip</strong>. Once there, enter Bhutan through Phuentsholing town. Drive along the Thimphu-Phuentsholing Highway, covering around 143 km (a 4-hour drive) to reach Thimphu.
                  </p>
                  <p>If you prefer flying or taking a train to Assam, you can still have an exciting <strong>Road Trip To Bhutan</strong>. From Bongaigaon Town or Guwahati, plan your route. From Bongaigaon, enter Bhutan through Gelephu point, about 50 km (a 1-hour drive) away. Continue driving along Wangdue-Tsirang Highway, covering about 250 km (an 8-hour drive) to reach Thimphu.</p>
                  <p>Alternatively, if you're starting from Guwahati, enter Bhutan through the Samdrup Jongkhar point, about 150 km (a 3-hour drive) away. Follow NH-27, driving another 459 km (a 10-hour journey) to finally reach Thimphu.</p>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>WHY BHUTAN IS A TOP TRAVEL DESTINATION FROM CHENNAI</b></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>WHY BHUTAN IS A TOP TRAVEL DESTINATION FROM CHENNAI</b></h4>
                </div>
                <div className="card-body">
                  <p><b>Easy Access</b><br />
                    Getting to Bhutan from Chennai is convenient. You can fly to Bagdogra, and from there, it's just a 150 km drive to Phuentsholing, the nearest border town in Bhutan. Our <strong>Bhutan Package Tour From Chennai</strong> ensures a smooth travel experience, so you can enjoy your trip without any hassle.
                  </p>
                  <p><b>Unique and Charming</b><br />
                    Bhutan's "High-Value, Low-Impact Tourism" policy keeps the number of tourists low, preserving its beautiful environment and rich culture. This makes it an exclusive and special destination for travelers from Chennai.
                  </p>
                  <p><b>Easy Booking</b><br />
                    Booking a <strong>Bhutan Tour Package From Chennai</strong> is simple with our easy-to-use platform. Our travel experts create itineraries that mix cultural exploration, natural beauty, and spiritual experiences, giving you a complete Bhutanese adventure.
                  </p>
                  <p><b>Great Value</b><br />
                    Our <strong>Bhutan Package from Chennai</strong> offers excellent value. We provide clear cost structures, ensuring you get the best experience without breaking the bank.
                  </p>
                  <p>Start your amazing journey from <strong>Chennai to Bhutan Tour</strong>, where culture, nature, and spirituality come together. Our <strong>Bhutan Tour Packages From Chennai</strong> offer an unforgettable experience. Book your <strong>Bhutan Tour</strong> now and discover the wonders of this Himalayan gem.</p>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>IMPORTANT FACTS ABOUT</b> <strong>BHUTAN TOUR PACKAGES FROM CHENNAI</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>IMPORTANT FACTS ABOUT</b> <strong>BHUTAN TOUR PACKAGES FROM CHENNAI</strong></h4>
                </div>
                <div className="card-body">
                  <li>Bhutan is called "Druk Yul" by locals, meaning "the Land of the Dragons."</li><br />
                  <li>Bhutan has never been conquered or ruled by any external force.</li><br />
                  <li>Bhutan is the only carbon-negative country in the world, with excellent air quality.</li><br />
                  <li>Until the 1970s, Bhutan was isolated with no connections to the outside world.</li><br />
                  <li>Bhutan doesn't have traffic lights and is governed by a constitutional monarchy.</li><br />
                  <li>Bhutan measures growth with GNH (Gross National Happiness) instead of GDP.</li><br />
                  <li>Bhutan is a plastic-free country and has fewer than 20 qualified pilots for its only airport.</li><br />
                  <li>Smoking is banned in Bhutan and punishable by law.</li><br />
                  <li>Gangkhar Puensum, at 7,570 meters, is one of the highest unclimbed mountains in the world.</li><br />
                  <li>The national animal of Bhutan is the Takin, a goat-antelope.</li><br />
                  <li>Bhutanese food is known for its spiciness, with chili peppers in almost every dish.</li><br />
                  <li>Many Bhutanese houses have red roofs and walls for drying chili peppers in the sun.</li><br />
                  <li>Education and healthcare are free in government-run hospitals and schools.</li><br />
                  <li>Archery is the national sport, and dirt throwing is also popular.</li><br />
                  <li>The king is highly respected, with his photo in almost every home.</li><br />
                  <li>Bhutan has a very low crime rate and prioritizes environmental cleanliness.</li><br />
                  <li>Chewing Doma, a mix of betel leaf and areca nut, is a popular pastime.</li><br />
                  <li>Ema Datshi, a spicy dish made with local cheese and chili peppers, is the national food.</li><br />

                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>ADVENTURE ACTIVITIES TO ENJOY DURING A </b> <strong>BHUTAN TOUR FROM CHENNAI</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>ADVENTURE ACTIVITIES TO ENJOY DURING A </b> <strong>BHUTAN TOUR FROM CHENNAI</strong></h4>
                </div>
                <div className="card-body">
                  <li><b>Trek to Tiger's Nest</b> :  Hike to the famous Tiger's Nest Monastery in Paro, which sits on a cliff, for amazing views and a spiritual experience.</li><br />
                  <li><b>White Water Rafting on the Punakha River</b> :  Feel the excitement of rafting on the Punakha River, surrounded by beautiful scenery.</li><br />
                  <li><b>Mountain Biking in Bumthang Valley</b> :  Ride through the beautiful Bumthang Valley on a bike, exploring old temples and quiet villages.</li><br />
                  <li><b>Cultural Homestay in Phobjikha Valley</b> :  Stay with a local family in Phobjikha Valley, known for its black-necked cranes, and experience Bhutanese culture.</li><br />
                  <li><b>Hot Spring Soaking in Gasa</b> :  Enjoy a relaxing soak in the natural hot springs of Gasa, surrounded by mountains.</li><br />
                  <li><b>Archery - Bhutan's National Sport</b> :  Try archery, Bhutan's traditional sport, and connect with the country's cultural heritage.</li><br />
                  <li><b>Wildlife Safari in Royal Manas National Park</b> :  Go on a safari in Bhutan's oldest national park to see Bengal tigers, elephants, and various bird species.</li><br />
                  <li><b>Camping in Haa Valley</b> :  Camp under the stars in the peaceful Haa Valley, surrounded by pristine landscapes.</li><br />
                  <li><b>Rock Climbing in Thimphu</b> :  Challenge yourself with rock climbing in Thimphu and enjoy great views of the capital city.</li><br />
                  <li><b>Bird Watching in Jigme Dorji National Park</b> :  Explore Jigme Dorji National Park to discover a variety of bird species, perfect for birdwatchers.</li><br />
                  <li><b>River Kayaking in Mo Chhu</b> :  Kayak down the Mo Chhu River, surrounded by lush greenery and majestic mountains.</li><br />
                  <li><b>Festival Celebrations in Paro or Thimphu</b> :  Join in vibrant festivals in Paro or Thimphu, experiencing traditional dances and rituals.</li>
                  <br />

                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen4ImageURL} alt="Sightseeing 1" />
                          <img src={sightseen5ImageURL} alt="Sightseeing 3" />
                          <img src={sightseen6ImageURL} alt="Sightseeing 2" />

                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <br />
                  <br />
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>BEST TIME TO BOOK A</b><strong> CHENNAI TO BHUTAN TOUR PACKAGES</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>BEST TIME TO BOOK A</b><strong> CHENNAI TO BHUTAN TOUR PACKAGES</strong></h4>
                </div>
                <div className="card-body">
                  <p>If you're planning a <strong>Chennai to Bhutan Package</strong>, the best times to visit are from October to December (Autumn) and March to May (Spring). During these months, the weather is dry and bright, perfect for exploring Bhutan's stunning landscapes without any interruptions. Here's a breakdown of what each season offers:</p>
                  <p><b>Autumn (October to December) :</b> The weather is clear and dry, with cool temperatures at high altitudes, making it ideal for trekking and hiking. This season is perfect for outdoor activities and enjoying Bhutan's beautiful scenery.</p>
                  <p><b>Winter (December to February) :</b> Winter is great for photographers, with clear skies and stunning views of snow-capped mountains. This season also features many annual Tshechu festivals in various Dzongs and Monasteries. Notable festivals include Monggar Tshechu, Trashigang Tshechu, Lhuntse Tshechu, and Trongsa Tshechu. Keep in mind that nights can be very cold, so pack warm clothes.</p>

                  <p><b>Spring (March to May) :</b>  Spring is when Bhutan's valleys come alive with blooming rhododendrons and other wildflowers. It's a fantastic time for nature lovers and hikers. Popular hikes during this season include Paro Taksang Hiking, Bumdrak Monastery Hiking, and Jomolhari Laya Gasa Trek.</p>
                  <p>Bhutan is a great destination year-round, offering unique experiences in every season. No matter when you visit, you'll be captivated by the country's natural beauty, rich culture, and warm hospitality. Plan your <strong>Bhutan tour from Chennai</strong> and get ready to explore the Land of the Thunder Dragon.</p>
                  <br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen7ImageURL} alt="Sightseeing 7" />
                          <img src={sightseen9ImageURL} alt="Sightseeing 9" />
                          <img src={sightseen8ImageURL} alt="Sightseeing 8" />
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <br />

                  <h5>TEMPERATURE IN BHUTAN EACH MONTH</h5><br />
                  <br /><br />
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr className="table-danger">
                          <th>MONTH</th>
                          <th>THIMPHU</th>
                          <th>PARO</th>
                          <th>PUNAKHA</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td data-title="MONTH">JANUARY</td>
                          <td data-title="THIMPHU">12.4 / -2.5°C</td>
                          <td data-title="PARO">9.5 / -5.8°C</td>
                          <td data-title="PUNAKHA">17 / 4.3°C</td>
                        </tr>
                        <tr>
                          <td data-title="MONTH">FEBRUARY</td>
                          <td data-title="THIMPHU">14.5 / 0.7°C</td>
                          <td data-title="PARO">13 / 1.6°C</td>
                          <td data-title="PUNAKHA">19 / 7.9°C</td>
                        </tr>
                        <tr>
                          <td data-title="MONTH">MARCH</td>
                          <td data-title="THIMPHU">16.7 / 3.9°C</td>
                          <td data-title="PARO">14.5 / 0.6°C</td>
                          <td data-title="PUNAKHA">22.8 / 10.4°C</td>
                        </tr>
                        <tr>
                          <td data-title="MONTH">APRIL</td>
                          <td data-title="THIMPHU">20 / 7.2°C</td>
                          <td data-title="PARO">17.5 / 4.6°C</td>
                          <td data-title="PUNAKHA">26.2 / 12.9°C</td>
                        </tr>
                        <tr>
                          <td data-title="MONTH">MAY</td>
                          <td data-title="THIMPHU">22.6 / 13.2°C</td>
                          <td data-title="PARO">23.5 / 10.6°C</td>
                          <td data-title="PUNAKHA">29.1 / 17.7°C</td>
                        </tr>
                        <tr>
                          <td data-title="MONTH">JUNE</td>
                          <td data-title="THIMPHU">24.5 / 15.3°C</td>
                          <td data-title="PARO">25.4 / 14.2°C</td>
                          <td data-title="PUNAKHA">29.2 / 20.1°C</td>
                        </tr>
                        <tr>
                          <td data-title="MONTH">JULY</td>
                          <td data-title="THIMPHU">25.8 / 15.5°C</td>
                          <td data-title="PARO">26.8 / 14.9°C</td>
                          <td data-title="PUNAKHA">30.4 / 20.6°C</td>
                        </tr>
                        <tr>
                          <td data-title="MONTH">AUGUST</td>
                          <td data-title="THIMPHU">25 / 15.7°C</td>
                          <td data-title="PARO">25.3 / 14.7°C</td>
                          <td data-title="PUNAKHA">29.1 / 20°C</td>
                        </tr>
                        <tr>
                          <td data-title="MONTH">SEPTEMBER</td>
                          <td data-title="THIMPHU">23.2 / 15°C</td>
                          <td data-title="PARO">23.4 / 11.8°C</td>
                          <td data-title="PUNAKHA">27.5 / 19.1°C</td>
                        </tr>
                        <tr>
                          <td data-title="MONTH">OCTOBER</td>
                          <td data-title="THIMPHU">21.8 / 10.5°C</td>
                          <td data-title="PARO">18.7 / 7.4°C</td>
                          <td data-title="PUNAKHA">26.1 / 14.3°C</td>
                        </tr>
                        <tr>
                          <td data-title="MONTH">NOVEMBER</td>
                          <td data-title="THIMPHU">17.8 / 5°C</td>
                          <td data-title="PARO">13.8 / 1.4°C</td>
                          <td data-title="PUNAKHA">22.6 / 9.6°C</td>
                        </tr>
                        <tr>
                          <td data-title="MONTH">DECEMBER</td>
                          <td data-title="THIMPHU">14.4 / -1.3°C</td>
                          <td data-title="PARO">11.2 / -1.5°C</td>
                          <td data-title="PUNAKHA">19.1 / 6.2°C</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                </div>
              </div>

              <br /><br />
              {/* <!--By Itinerary--> */}
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h2>
                        DAY WISE <strong><span>BHUTAN TOUR ITINERARY FROM CHENNAI</span></strong>
                      </h2>
                      <h4>7 Nights 8 Days <strong>Bhutan Tour Itinerary from Bagdogra Airport</strong></h4>
                    </div>
                  </div>
                </div>


                {/* <!--end Short description--> */}
                <br />
                <div className="row">
                  <div
                    className="accordion"
                    id="accordionPanelsStayOpenExample"
                  >
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="day1"
                      >
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseOne"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseOne"
                        >
                          DAY 1  – : IXB Airport – Phuentsholing (Distance: 170 km. / Driving Time: 5 hrs. / Altitude of Phuentsholing: 293 mts.)
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingOne"
                      >
                        <div className="accordion-body">
                          <p>
                            Meet & Greet on arrival at NJP Railway Station / IXB Airport and transfer to Phuentsholing - The Gateway of Bhutan by Road from India. It is a thriving commercial center on the northern edge of the Indian Plains. On arrival check in at the hotel.Overnight stay at Phuentsholing.

                          </p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay1ImageURL} alt="..." /></center>
                          </div>

                          <div className="content-mobile2">
                            <center><img src={navDay1ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                          <br />
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="day2"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseTwo"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseTwo"
                        >
                          DAY 02: PHUENTSHOLING TO THIMPHU [DISTANCE: 176 KM | 6 HRS]
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseTwo"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingTwo"
                      >
                        <div className="accordion-body">
                          <p>
                            After breakfast, proceed towards Thimpu – the capital city of Bhutan via the wonderful mystic town Gedu, about 9000 ft above the sea and Chukha Dam. On the way, halt at Chojum to take photographs of the confluence of two rivers of Bhutan, Thumpu Chu, and Paro Chu. Reach Thimpu in the evening. On arrival check in at the hotel. In the evening, visit the Tashichho Dzong (Fortress of the Glorious Religion). Overnight stay at hotel in Thimphu.
                          </p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay2ImageURL} alt="..." /></center>
                          </div>

                          <div className="content-mobile2">
                            <center><img src={navDay2ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                          <br />
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="day3"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseThree"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          DAY 03: THIMPHU LOCAL SIGHTSEEING
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseThree"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingThree"
                      >
                        <div className="accordion-body">
                          <p>This is the third day of this mesmerising <strong>Bagdogra to Bhutan Trip</strong>. After breakfast, the day is dedicated to sightseeing in Thimphu. You will see the following tourist attractions in Thimphu including<br /><br />
                            <ul>
                              <li>&nbsp;• Kuenselphodrang</li>
                              <li>&nbsp;•  National Memorial Chorten</li>
                              <li>&nbsp;•  Folk Heritage Museum</li>
                              <li>&nbsp;•  Typical Bhutanese lunch at the Folk Heritage Restaurant would be a great experience</li>
                              <li>&nbsp;•  Sangaygang view point</li>
                              <li>&nbsp;•  Takin Reserve Centre</li>
                              <li>&nbsp;•  Changangkha Monastery</li>
                              <li>&nbsp;•  Tashichho Dzong (Fortress of the Glorious Religion)</li>
                            </ul>
                          </p>
                          <p>
                            Dinner and Overnight Stay at Thimphu Hotel.
                          </p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay3ImageURL} alt="..." /></center>
                          </div>

                          <div className="content-mobile2">
                            <center><img src={navDay3ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                          <br />
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="day4"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseFour"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          Day 04: - THIMPHU TO PUNAKHA TRANSFER VIA DOCHULA PASS
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseFour"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingFour"
                      >
                        <div className="accordion-body">
                          <p>After breakfast, you will be taken on a three-hour road trip from Thimphu to Punakha / Wangdue Phodrang, which is located across the famous Dochu La Pass. During the road trip, you will pass through the Dochula, where you will spot the mani wall, chorten, prayer flags, and others. This is the country’s highest road. You can even spot the peaks of surrounding mountains if the sky is clear. The top peaks to spot during your drive are Masagang, Tsendagang, Terigang, Kangphugang, Table Mountain, and others. This includes the Gangkhar Puensum Peak, which is the highest in Bhutan. On arrival Punakha, check into the Hotel. Post Lunch, You will see the following tourist sites in Punakha / Wangdue including</p>
                          <ul>
                            <li>&nbsp;• Punakha Dzong</li>
                            <li>&nbsp;• Chimi Lhakhang</li>
                            <li>&nbsp;• Punakha Suspension Bridge</li>
                            <li>&nbsp;• Punakha Village</li>
                          </ul>
                          <br />
                          <p>Overnight Stay in Punakha / Wangdue</p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay4ImageURL} alt="..." /></center>
                          </div>

                          <div className="content-mobile2">
                            <center><img src={navDay4ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                          <br />
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="day5"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseFive"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          DAY 05: PUNAKHA / WANGDUE TO PARO
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseFive"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingFive"
                      >
                        <div className="accordion-body">
                          <p>
                            After breakfast, you will leave Punakha / Wangdue and drive to Paro via Dochu La Pass again, covering Paro Chuu river valley and Wang Chhu river valley. On the way, you will be visiting Simtokha Dzong, an old fortress which is now a school for Dzongkha language. Once you reach Paro, you will be checked into a hotel. Later, you will be taken on a sightseeing tour covering important destinations of Paro
                            <br />
                            <br />
                            • Ta Dzong
                            <br />
                            • Rinpung Dzong
                            <br />
                            • Tamchog lhakhang
                            <br />
                          </p>
                          <p>
                            Overnight at the hotel in Paro
                          </p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay5ImageURL} alt="..." /></center>
                          </div>

                          <div className="content-mobile2">
                            <center><img src={navDay5ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                          <br />
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="day6"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseSix"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          DAY 06: PARO LOCAL SIGHTSEEING & TAKTSHANG MONASTERY HIKING
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseSix"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingSix"
                      >
                        <div className="accordion-body">
                          <p>
                            Today is the most important day of this wonderful <strong>Bhutan package from Bagdogra Airport</strong>. After early morning breakfast, you will be starting an excursion tour to Taktshang Monastery. Taktshang Palphug Monastery also known as Tiger's Nest is an amazing Buddhist monastery, temple and sacred site is located in the upper Paro town. Built in 1692, Padmasambhava is believed to have meditated at this site for 3 years. Guru Padmasambhava is known to be the founder of Buddhist religion in Bhutan. He is immensely respected and has a huge number of sacred followers in Bhutan.<br />
                            During your trek, enjoy the breath-taking view of Paro town - lush green valleys, a sparkling river, clear blue skies, and drifting clouds nature at its finest. Spend the night in Paro to fully savor this experience. Overnight at the Paro.

                          </p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay6ImageURL} alt="..." /></center>
                          </div>

                          <div className="content-mobile2">
                            <center><img src={navDay6ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                          <br />
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="day7"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseSeven"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          DAY 07: PARO TO LATAGURI TRANSFER
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseSeven"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingSeven"
                      >
                        <div className="accordion-body">
                          <p>
                            After breakfast check out from the hotel and transfer to Jaldapara. On arrival check in at the hotel. Afternoon Lataguri Jeep Safari (On a Direct Payment Basis). Overnight stay at Lataguri.
                          </p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay7ImageURL} alt="..." /></center>
                          </div>

                          <div className="content-mobile2">
                            <center><img src={navDay7ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                          <br />
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="day8"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseTen"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          DAY 08:LATAGURI TO BAGDOGRA AIRPORT
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseTen"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingTen"
                      >
                        <div className="accordion-body">
                          <p>
                            After breakfast check out from the hotel and take the departure transfer to Bagdogra Airport for your onward journey.
                          </p>
                          {/* <br />
                                            <div className="content-desktop">
                                                <center><img src={navDay8ImageURL} alt="..." /></center>
                                            </div>

                                            <div className="content-mobile2">
                                                <center><img src={navDay8ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                            </div>
                                            <br /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br /><br />
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">{<InclusionExclusion />}</div>
                </div>
              </div>
              <br />
              <br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>HOT SPRINGS AND MINERAL SPRINGS TO EXPERIENCE BHUTAN TOUR FROM CHENNAI</b></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>HOT SPRINGS AND MINERAL SPRINGS TO EXPERIENCE BHUTAN TOUR FROM CHENNAI</b></h4>
                </div>
                <div className="card-body">
                  <p><b>HOT SPRINGS (TSHACHU) TO ENJOY IN BHUTAN :</b></p>
                  <li>Chuboog Tshachu - Punakha</li><br />
                  <li>Koma Tshachu - Punakha</li><br />
                  <li>Gasa Tsachu - Gasa</li><br />
                  <li>Gayza Tshachu - Gasa</li><br />
                  <li>Dhur Tshachu - Bumthang</li><br />
                  <li>Duenmang Tshachu - Zhemgang</li><br />
                  <li>Gelephu Tshachu - Sarpang</li><br />
                  <li>Khambalung gNey Tshachu - Lhuntse</li><br />
                  <p><b>MINERAL SPRINGS (DRUBCHU) TO EXPLORE DURING YOUR PUNE TO BHUTAN TRIP :</b></p>
                  <li>Bjagay Menchu - Paro</li><br />
                  <li>Dobji Menchu - Paro</li><br />
                  <li>Tokey Menchu - Gasa</li><br />
                  <li>Menchu Karp - Gasa</li><br />
                  <li>Ake Menchu - Gasa</li><br />
                  <li>Yame Menchu - Gasa</li><br />
                  <li>Bakey Menchu - Gasa</li><br />
                  <li>Kabisa Menchu - Punakha</li><br />
                  <li>Rekayzam Menchu - Wangdue Phodrang</li><br />
                  <li>Dankhar Menchu - Zhemgang</li><br />
                  <li>Bharab Menchu and Chethag Menchu - Lhuentse</li><br />
                  <li>Dhonphangma Menchu - Trashigang</li><br />
                  <li>Korphu Menchu - Trongsa</li><br />
                  <li>Khowabokto Menchu - Phobjikha Valley</li><br />
                  <li>Gela Menchu - Phobjikha Valley</li><br />
                  <li>Khasa Menchu - Phobjikha Valley</li><br />
                  <li>Menchu Karp - Thimphu</li><br />
                </div>
              </div>
              <br /><br />
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h2>
                        Frequently Ask <span>Questions</span>
                      </h2>
                      <h4>BHUTAN TOUR PACKAGE FROM CHENNAI BY FLIGHT</h4>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div
                    className="accordion accordion-flush faQStyle"
                    id="accordionFlushExample"
                  >
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="question1">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseOne"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseOne"
                        >
                          Q: What is the national language of Bhutan?
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingOne"
                      >
                        <div className="accordion-body">
                          <p>A: The national language of Bhutan is Dzongkha.</p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="question2">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseTwo"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseTwo"
                        >
                          Q: What is the capital of Bhutan?
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseTwo"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingTwo"
                      >
                        <div className="accordion-body">
                          <p>A: Thimphu (also spelled as Thimbu) is the capital city of Bhutan, located in the Himalayas at an altitude of over 2000 meters.</p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="question3">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseThree"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          Q: What is the currency of Bhutan?
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseThree"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingThree"
                      >
                        <div className="accordion-body">
                          <p>A: The official currency of Bhutan is the Bhutanese ngultrum (BTN), which is equivalent to the Indian rupee (1:1 or sometimes 0.99).</p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="question4">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseFour"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseFour"
                        >
                          Q: What is the international country code of Bhutan?
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseFour"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingFour"
                      >
                        <div className="accordion-body">
                          <p>A: The international country code for Bhutan is +975. When calling Bhutan from abroad, add this code before the phone number.</p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="question5">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseFive"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseFive"
                        >
                          Q: How much Indian currency can I bring into Bhutan Tour Package?
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseFive"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingFive"
                      >
                        <div className="accordion-body">
                          <p>A: You can bring Indian currency notes of Rs. 100 and above (Rs. 500 and Rs. 1,000) into Bhutan.</p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="question6">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseSix"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseSix"
                        >
                          Q: Where can I get a visitor SIM card?
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseSix"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingSix"
                      >
                        <div className="accordion-body">
                          <p>A: You can buy a Bhutanese SIM card at Paro International Airport’s visitor information center, from Bhutan Telecom and TashiCell branch offices, or from authorized agents in towns.</p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="question7">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseSeven"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseSeven"
                        >
                          Q: Can I use a drone for photography during my Bhutan Tour Packages?
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseSeven"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingSeven"
                      >
                        <div className="accordion-body">
                          <p>A: To fly a drone in Bhutan, whether for commercial or recreational use, you must get a permit. Flying a drone without a permit is illegal. For more information, contact media@tcb.gov.bt.</p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="question8">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseEight"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseEight"
                        >
                          Q: What are the best luxury hotels to choose for a Bhutan Package Tour?
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseEight"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingEight"
                      >
                        <div className="accordion-body">
                          <ul>
                            <li>Six Senses Hotels Resorts Spas - Rating: 5.0/5</li>
                            <li>Hotel Le Meridien, Thimphu - Rating: 4.5/5</li>
                            <li>Hotel Taj Tashi, Thimphu - Rating: 4.5/5</li>
                            <li>Hotel Amankora, Thimphu - Rating: 5.0/5</li>
                            <li>COMO Uma Paro, Paro - Rating: 4.5/5</li>
                            <li>Le Meridien Riverfront, Paro - Rating: 4.5/5</li>
                            <li>Naksel Boutique Hotel & Spa, Paro - Rating: 4.5/5</li>
                          </ul>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h3>
                        <b>
                          Bhutan <span className="tomato">Tour Reviews</span>
                        </b>
                      </h3>
                      <h4>
                        <i>"Your Experience is our Marketing"</i>
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="row row-cols-1 ">
                  <div id="testimonials">
                    <div className="testimonial-box-container">
                      {reviewersDataView}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <BookingBox />
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default ChennaiToBhutanPackage;
