import React, { Component } from 'react';
import BookingBox from '../BookingBox';
import axios from "axios";
import cogoToast from "cogo-toast";
import { Digital } from "react-activity";
import Slider from "react-slick";
import ApiURL from "../../../api/ApiURL";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InclusionExclusion from './InclusionExclusion_kashmir_delhi';

class Kashmir_4N_5D_Package_Tour_From_Delhi extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customActiveTab: 'tab1',
            daywiseActiveTab: 'day1',
            btn: "Submit",
            showLoader: false,
            isHidden: false,
            showModal: false,
            firstName: '',
            mobile: '',
            email: '',
            comments: ''
        }
    }

    handleCustomTabClick = (tab) => {
        this.setState({
            customActiveTab: tab,
        });
    };

    handleDayWiseTabClick = (tab) => {
        this.setState({
            daywiseActiveTab: tab,
        });
    };
    handleClick = () => {
        const currentUrl = window.location.href;
        const pageTitle = document.title;
        const message = `Hi, Please send about the Tour Package details "${pageTitle}"`;

        const whatsappLink = `https://api.whatsapp.com/send?phone=+919147061467&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
        window.open(whatsappLink, '_blank');
    };

    onRequestCallback = (e) => {
        e.preventDefault();
        // Prevent opening a new modal if another one is already open
        if (!this.state.showModal) {
            this.setState({ showModal: true });
        }
    }


    onCloseModal = (e) => {
        e.preventDefault();
        this.setState({
            ...this.state,
            showModal: false,
            firstName: '',
            mobile: '',
            email: '',
            comments: ''
        });
    }

    toggleHidden = (e) => {
        e.preventDefault();
        this.setState({ isHidden: !this.state.isHidden });
    }

    onChangeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    // Function to handle form submission
    onRequestCallbackSubmit = async (e) => {
        e.preventDefault();

        const { firstName, email, mobile, comments } = this.state;

        // Validation
        if (firstName.trim() === '') {
            cogoToast.error("Full Name Required.", {
                position: 'top-center', // Use top-center, then adjust via CSS
                hideAfter: 2, // optional: auto-hide after 2 seconds
                className: 'custom-toast' // Apply custom class
            });
        } else if (email.trim() === '') {
            cogoToast.error("Email Required.", {
                position: 'top-center', // Use top-center, then adjust via CSS
                hideAfter: 2, // optional: auto-hide after 2 seconds
                className: 'custom-toast' // Apply custom class
            });
        } else if (mobile.trim() === '' || !/^\d{10}$/.test(mobile.trim())) {
            cogoToast.error("Please Insert 10 digit Mobile No.", {
                position: 'top-center', // Use top-center, then adjust via CSS
                hideAfter: 2, // optional: auto-hide after 2 seconds
                className: 'custom-toast' // Apply custom class
            });
        } else {
            this.setState({ btn: "Processing..", showLoader: true });
            // Proceed with form submission
            try {
                const _ref = 'yes';
                const _url = window.location.href;

                const formData = {
                    FirstName: firstName,
                    //LastName: lastName,
                    Email: email,
                    Mobile: mobile,
                    // City: city,
                    // TravelDate: travelDate,
                    // TicketBooked: ticketBookedStatus,
                    // NoOfGuests: noOfGuests,
                    // HotelCategory: hotelCategory,
                    Comments: comments,
                    Referrer: _ref,
                    URL: _url
                };

                const response = await axios.post(ApiURL.SaveCustomerEnquery, formData);
                console.log('Data submitted successfully:', response.data);
                // Optionally, reset the form state after successful submission
                this.setState({ firstName: '', email: '', mobile: '', comments: '' });

                // Optionally, show a success message to the user
                cogoToast.success("Form submitted successfully.", {
                    position: 'top-center', // Use top-center, then adjust via CSS
                    hideAfter: 2, // optional: auto-hide after 2 seconds
                    className: 'custom-toast' // Apply custom class
                });
                this.setState({ btn: "Submit", showLoader: false, showModal: false });
            } catch (error) {
                console.error('Error submitting data:', error);
                this.setState({ btn: "Submit", showLoader: false });
                // Optionally, show an error message to the user
                cogoToast.error("Please try again after 24 hrs. later.", {
                    position: 'top-center', // Use top-center, then adjust via CSS
                    hideAfter: 2, // optional: auto-hide after 2 seconds
                    className: 'custom-toast' // Apply custom class
                });
            }

        }

    };
    render() {
        const { customActiveTab, daywiseActiveTab } = this.state;
        const packageTopBannerImageURL = ApiURL.Kashmir_Tour_Package_from_Delhi + "/Kashmir-Package-Tour-from-Delhi.webp";
        const packageTopBanner2ImageURL = ApiURL.Kashmir_Tour_Package_from_Delhi + "/Kashmir-Tour-Packages-from-Delhi.webp";
        const sightseen1ImageURL = ApiURL.Kashmir_Tour_Package_from_Delhi + "/Magical-Trip-to-kashmir-from-Delhi.webp";
        const sightseen2ImageURL = ApiURL.Kashmir_Tour_Package_from_Delhi + "/Delhi-to-Kashmir-Tour-Packages.webp";
        const sightseen3ImageURL = ApiURL.Kashmir_Tour_Package_from_Delhi + "/Kashmir-Package-Tour-Price-ex- Srinagar.webp";
        const sightseen4ImageURL = ApiURL.Kashmir_Tour_Package_from_Delhi + "/Kashmir-Tour-Itinerary-from-Delhi.webp";
        const sightseen5ImageURL = ApiURL.Kashmir_Tour_Package_from_Delhi + "/Kashmir-Tour-Package-from-Delhi.webp";
        const sightseen6ImageURL = ApiURL.Kashmir_Tour_Package_from_Delhi + "/Kashmir-Tour-Plan-from-Delhi.webp";
        const sightseen7ImageURL = ApiURL.Kashmir_Tour_Package_from_Delhi + "/Kashmir-Trip-from-Delhi.webp";
        const sightseen8ImageURL = ApiURL.Kashmir_Tour_Package_from_Delhi + "/Kashmir-from-Delhi.webp";
        const sightseen9ImageURL = ApiURL.Kashmir_Tour_Package_from_Delhi + "/Kashmir-Honeymoon-Trip.webp";
        const sightseen10ImageURL = ApiURL.Kashmir_Tour_Package_from_Delhi + "/Kashmir-Package-Tour.webp";
        const sightseen11ImageURL = ApiURL.Kashmir_Tour_Package_from_Delhi + "/Kashmir-Package-Tour-Cost.webp";
        const sightseen12ImageURL = ApiURL.Kashmir_Tour_Package_from_Delhi + "/Customized-Kashmir-Tour-Itinary.webp";
        //day wise itenaryy
        const navDay1ImageURL = ApiURL.Kashmir_Tour_Package_from_Delhi + "/7N-8D-Kashmir-Tulip-Festival-Package-Tour.webp";
        const navDay2ImageURL = ApiURL.Kashmir_Tour_Package_from_Delhi + "/2024-Tulip-Garden-Kashmir-Package.webp";
        const navDay3ImageURL = ApiURL.Kashmir_Tour_Package_from_Delhi + "/Customized-Kashmir-Tour.webp";
        const navDay4ImageURL = ApiURL.Kashmir_Tour_Package_from_Delhi + "/Tulip-Festival-in-Kashmir.webp";
        const navDay5ImageURL = ApiURL.Kashmir_Tour_Package_from_Delhi + "/Srinagar-Tulip-Garden-Tour.webp";
        const navDay6ImageURL = ApiURL.Kashmir_Tour_Package_from_Delhi + "/Tulip-Garden-Tour-Package.webp";
        const navDay7ImageURL = ApiURL.Kashmir_Tour_Package_from_Delhi + "/Book-Kashmir-Tulip-Trip.webp";
        const navDay8ImageURL = ApiURL.Kashmir_Tour_Package_from_Delhi + "/Tulip-Festival-Special-2024.webp";
        const Topbanner0ImageURL = ApiURL.Kashmir_Tour_Package_from_Delhi + "/Kashmir-Houseboat-Packages.webp";
        const Topbanner1ImageURL = ApiURL.Kashmir_Tour_Package_from_Delhi + "/Kashmir-Adventure-Tour-Plan.webp";
        const Topbanner2ImageURL = ApiURL.Kashmir_Tour_Package_from_Delhi + "/Kashmir-Gondola-Package-Tour.webp";
        const Topbanner3ImageURL = ApiURL.Kashmir_Tour_Package_from_Delhi + "/Kashmir-Trekking-Packages.webp";
        const Topbanner4ImageURL = ApiURL.Kashmir_Tour_Package_from_Delhi + "/Kashmir-White-River-Rafting-Plan.webp";
        const brandedTourackageImage_1_URL = ApiURL.Kashmir_Tour_Package_from_Delhi + "/The-Khyber-Himalayan-Resort-&-Spa.webp";
        const brandedTourackageImage_2_URL = ApiURL.Kashmir_Tour_Package_from_Delhi + "/The-LaLiT-Grand-Palace-Srinagar-Hotel.webp";
        const TrendingPackagesDataList = this.props.TrendingPackagesData;
        const hotPackagesView = TrendingPackagesDataList.length > 0 ?
            (
                TrendingPackagesDataList.map((dataItem, i) => {
                    const hotelImgURL = ApiURL.Kashmir_Tour_Package_from_Delhi + "/" + dataItem.ImageName;
                    return (<div className="col-md-12 p-0" key={i}>
                        <div className="card h-100">
                            <img src={hotelImgURL} className="card-img-top" alt={dataItem.AllternateText} />
                            <div className="card-body">
                                <h6 className="card-title">{dataItem.Title} <span> &#8377; {dataItem.PricePerNight}</span></h6>
                                <p className="card-text">
                                    <i className="fa fa-building tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-utensils tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-car-side tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-male tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-camera tomato"></i>
                                </p>
                                <p className="card-text"><span>{dataItem.Description}</span></p>
                                <p>
                                    <span><b className="tomato">{dataItem.NoOfTours} </b>Tours</span> | &nbsp;
                                    <span><b className="tomato">{dataItem.NoOfDeparture} </b>Departures</span> | &nbsp;
                                    <span><b className="sdf-blue"> {dataItem.Include}</b></span>
                                </p>
                            </div>
                            <div className="card-footer">
                                <small className="text-muted"><strong className="tomato">{dataItem.MutedText}</strong></small>
                                <a href="#" className="btn btn-success btn-sm float-end" onClick={this.handleClick}>
                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                </a>
                            </div>
                        </div>
                    </div>)
                })
            ) : (<div></div>)


        const settings = {
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const reviewersDataList = this.props.ReviewersData;
        const reviewersDataView = reviewersDataList.map((dataItem, i) => {
            const reviewerImageURL = ApiURL.Kashmir_Tour_Package_from_Delhi + "/" + dataItem.ImageName;
            return (
                <div className="testimonial-box" key={i}>
                    <div className="box-top">
                        <div className="profile">
                            <div className="profile-img">
                                <img src={reviewerImageURL} alt="..." />
                            </div>
                            <div className="name-user">
                                <strong>{dataItem.Name}</strong>
                                <span>{dataItem.Location}</span>
                            </div>
                        </div>
                        <div className="reviews">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </div>
                    </div>
                    <div className="client-comment">
                        <p>{dataItem.Comment}</p>
                    </div>
                </div>
            )
        })

        return (
            <>

                <div className="container-fluid">
                    <div className="row">
                        <img src={packageTopBannerImageURL} className="mobile-image2" alt="..." />
                    </div>
                </div>
                <div className="container-fluid custom-container py-3">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="container-fluid custom-container py-1">

                                        <h1 className="content-desktop" style={{ fontSize: "2vw" }}><center><strong>KASHMIR PACKAGE TOUR FROM DELHI </strong></center></h1>

                                        <h3 className="content-mobile" style={{ fontSize: "4vw" }}><center><strong>KASHMIR PACKAGE TOUR FROM DELHI </strong></center>
                                            <hr />
                                        </h3>
                                        <div className="row">
                                            <img src={packageTopBanner2ImageURL} className="mobile-image" alt="..." />
                                        </div>
                                        <hr />
                                        <div className="d-flex flex-column flex-sm-row align-items-sm-center px-2">
                                            <div className="font-lg">
                                                3 Destination:{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Srinagar (3N)
                                                </a>
                                                &nbsp;{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Gulmarg (1N)
                                                </a>{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Pahalgam (3N)
                                                </a>
                                            </div>
                                            <div className="reviews mx-5">
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                            </div>
                                        </div>
                                        <p className="p-2">
                                            If you are looking for a memorable <strong>Kashmir Package Tour From Delhi</strong>, you have come to the right place!
                                        </p>
                                        <hr />
                                        <br />
                                    </div>
                                    <h2 className="content-desktop p-2" style={{ fontSize: "1.6vw" }}><b>Looking for Kashmir Tour Packages from Delhi ?</b><a href='tel:9147061467' class="tomato"> To book a <strong>Kashmir Package Tour from Delhi</strong> ☎ CALL Mr. Soumya +91- 91470-61467</a></h2>
                                    <h2 className="content-mobile p-4" style={{ fontSize: "4.5vw" }}><b>Looking for Kashmir Tour Packages from Delhi?</b><a href='tel:9147061467' class="tomato"> To book a <strong>Kashmir Package Tour from Delhi</strong> ☎ CALL Mr. Soumya +91- 91470-61467</a></h2>
                                    <br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> Explore the Enchanting Beauty of Kashmir from Delhi with Adorable Vacation</h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}> Explore the Enchanting Beauty of Kashmir from Delhi with Adorable Vacation</h4>
                                        </div>
                                        <div className="card-body">
                                            <p>Are you searching for the best <strong>Kashmir Package Tour Price ex Srinagar</strong> or planning a family trip to Kashmir? Look no further! Our <strong>Kashmir Tour Packages</strong> include everything you need for a memorable vacation, like luxury accommodations, guided tours, and hassle-free travel arrangements. Enjoy the beautiful landscapes, taste local food, and create unforgettable memories with our carefully designed <strong>Kashmir Travel Packages from Delhi</strong>. Book now and start your dream vacation to India’s paradise!
                                            </p>
                                            <p>Adorable Vacation makes booking and paying online easy, with instant confirmations. Our <strong>Kashmir Trip from Delhi</strong> start at just ₹15,599. Whether you're planning a family trip, honeymoon, or weekend getaway with friends, we have the perfect option for you. The packages cover all the must-visit places in Kashmir, including houseboat stays in Srinagar, Shikara rides on Dal Lake, exploring Kashmir Valley, visiting Mughal Gardens, trekking tours in Pahalgam, offbeat Kashmir tours, and cultural experiences. Don’t miss out on our Luxury <strong>Kashmir Tour Packages from Delhi</strong>, offering top-notch services and the best hotels.
                                            </p>
                                            <p>Kashmir, located in the northernmost part of India, is known as the "Crown of India" for its stunning landscapes, peaceful valleys, and rich culture. Imagine waking up to snow-capped mountains, walking through meadows full of wildflowers, and floating on Dal Lake in a traditional Shikara. This is the magic of Kashmir, where nature’s beauty and heritage combine for an unforgettable experience.
                                            </p>
                                            <p>Starting your <strong>Kashmir Package from Delhi</strong> adds convenience, with great transport options making the journey smooth. Whether you travel by air, road, or rail, the journey from <strong>Delhi to Kashmir Tour</strong>  is comfortable and sets the tone for your adventure.</p>
                                            <p>Kashmir offers more than just scenic beauty. Explore ancient temples, vibrant markets, taste delicious local cuisine, and experience the warm hospitality of the Kashmiri people. Our <strong>Kashmir package tours from Delhi</strong> are designed to give you a complete and enchanting experience in this beautiful region.</p>
                                            <p>Join us on a trip from Delhi to Kashmir and get ready to be amazed. Book our <strong>Kashmir tour package from Delhi</strong> now and create memories that will last a lifetime!</p>

                                            <p><b>Apart from that, we also provide,</b></p>
                                            <a href='/kashmir-offbeat-package-tour-with-baisaran-valley-doodhpathri-yusmarg' target='_blank'><b className="tomato">Kashmir Package Tour with Doodhpathri Yusmarg</b></a>, <a href='/kashmir-offbeat-package-tour-with-gurez-valley' target='_blank'><b className="tomato">Kashmir Package Tour with Gurez Valley</b></a>,
                                            <a href='/kashmir-offbeat-package-tour-with-sinthan-top-aru-valley-betab-valley-chandanbari-pahalgam-sonmarg' target='_blank'><b className="tomato">Kashmir Offbeat Package Tour with Sinthan Top</b></a>,
                                            <a href='/kashmir-package-tour-with-vaishno-devi-katra-pahalgam-gulmarg-srinagar' target='_blank'><b className="tomato">Kashmir Package Tour with Vaishno Devi</b></a>,
                                            <a href='/kashmir-tulip-garden-festival-package-tour' target='_blank'><b className="tomato">Kashmir Package Tour with Tulip Package</b></a>,
                                            <a href='/kashmir-package-tour-from-kolkata-with-srinagar-gulmarg-pahalgam-sonmarg' target='_blank'><b className="tomato">Kashmir Package Tour from Kolkata </b></a>,
                                            <a href='/kashmir-package-tour-from-surat' target='_blank'><b className="tomato">Kashmir Package Tour from Surat </b></a>,
                                            <a href='/kashmir-package-tour-from-chennai' target='_blank'><b className="tomato">Kashmir Package Tour from Chennai </b></a>,
                                            <a href='/kashmir-package-tour-from-bangalore' target='_blank'><b className="tomato">Kashmir Package Tour from Bangalore </b></a>,
                                            <a href='/kashmir-package-tour-from-pune' target='_blank'><b className="tomato">Kashmir Package Tour from Pune </b></a>,
                                            <a href='/kashmir-package-tour-from-mumbai' target='_blank'><b className="tomato">Kashmir Package Tour from Mumbai </b></a>
                                            <br /><br />
                                            <p>Book your dream destination with Adorable Vacation and for further information do not hesitate to contact us at +91 9147061467</p>
                                            <br /><br />
                                            <table className="image-table">
                                                <tbody>
                                                    <tr>
                                                        <td className="image-cell">
                                                            <img src={sightseen1ImageURL} alt="kashmir tour packages" />
                                                            <img src={sightseen2ImageURL} alt="kashmir trip from srinagar airport" />
                                                            <img src={sightseen3ImageURL} alt="kashmir package tour cost from srinagar airport" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <br />
                                        </div>
                                    </div>
                                    <br /> <br /> <br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>Duration for Delhi to Kashmir Tour Package : 4N | 5D</strong></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>Duration for Delhi to Kashmir Tour Package : 4N | 5D</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <p><strong>Places Covered in the Delhi to Kashmir Package Tour </strong> : Srinagar Houseboat 1N | Gulmarg 1N | Pahalgam 1N | Srinagar 1N </p>
                                            <p>Best <strong>Kashmir Tour Packages from Delhi</strong> Pickup & Drop : Sheikh Ul Alam International Airport, Srinagar [SXR]</p>
                                            <p><b>Address</b>: Indra Gandhi Road, Humhama, Badgam, Srinagar, Jammu and Kashmir 190007</p>
                                            <br /><br />
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <tbody>
                                                        <tr>
                                                            <td><strong>Best Kashmir Tour Packages from Delhi</strong> <b>Pickup & Drop</b></td>
                                                            <td>Sheikh Ul Alam International Airport, Srinagar [SXR]</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Duration for Delhi to Kashmir Tour Package</strong></td>
                                                            <td>07 Nights | 08 Days</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Delhi to Kashmir Package Price </strong></td>
                                                            <td>Rs. 16,400/Based on 8 Pax</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Delhi to Kashmir Travel Packages Distance</strong></td>
                                                            <td>14 hr 54 min (720.6 km) via NH 44</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Srinagar</strong></td>
                                                            <td>1,585 m | Coordinates - 34.0837° N, 74.7973° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Gulmarg</strong></td>
                                                            <td>2,650 m | Coordinates - 34.0484° N, 74.3805° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Sonmarg</strong></td>
                                                            <td>2,730 m | Coordinates - 34.3032° N, 75.2931° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Pahalgam</strong></td>
                                                            <td>2,740 m | Coordinates - 34.0161° N, 75.3150° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Betaab Valley</strong></td>
                                                            <td>2,393 m | Coordinates - 34.0541° N, 75.3639° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Aru Valley</strong></td>
                                                            <td>2,414 m | Coordinates - 34.0922° N, 75.2632° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Chandanwadi</strong></td>
                                                            <td>3,288 m | Coordinates - 34.2122° N, 73.9295° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Doodhpathri</strong></td>
                                                            <td>2,730 m | Coordinates - 33.8509° N, 74.5630° E</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <br /><br /><br />
                                            <table className="image-table">
                                                <tbody>
                                                    <tr>
                                                        <td className="image-cell">
                                                            <img src={sightseen4ImageURL} alt="kashmir package tour from srinagar" />
                                                            <img src={sightseen5ImageURL} alt="kashmir package price from srinagar airport" />
                                                            <img src={sightseen6ImageURL} alt="kashmir packages from srinagar airport" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <br />

                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    {/* start short itinerary */}

                                    <div className="card">
                                        <div className="card-body">
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr className="table-danger">
                                                        <th>4N/5D CUSTOMIZED KASHMIR TOUR ITINERARY</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingOne"
                                                        >
                                                            <a href="#day1" style={{ color: 'black' }}><b>DAY 1 → </b>
                                                                <span>
                                                                    Arrival at Srinagar, Overnight stay in Houseboat
                                                                </span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingTwo"
                                                        >
                                                            <a href="#day2" style={{ color: 'black' }}><b>DAY 2 → </b>
                                                                <span>Srinagar to Gulmarg , Overnight stay in Gulmarg </span>
                                                            </a>

                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingThree"
                                                        >
                                                            <a href="#day3" style={{ color: 'black' }}><b>DAY 3 → </b>
                                                                <span>Gulmarg to-Pahalgam , Overnight stay in Pahalgam </span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingFour"
                                                        >
                                                            <a href="#day4" style={{ color: 'black' }}><b>DAY 4 → </b>
                                                                <span>Pahalgam to Srinagar , Overnight stay at Srinagar</span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingFive"
                                                        >
                                                            <a href="#day5" style={{ color: 'black' }}><b>DAY 5 → </b>
                                                                <span>Departure, Srinagar Airport</span>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    {/* <!--end Short description--> */}
                                    <br /><br /><br />

                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>POPULER</b> <strong>DELHI TO KASHMIR PACKAGE PRICE</strong> 2024</h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>POPULER</b> <strong>DELHI TO KASHMIR PACKAGE PRICE</strong> 2024</h4>
                                        </div>
                                        <div className="card-body">
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <thead className="cf">
                                                        <tr className="table-danger">
                                                            <th>Kashmir Tour Packages</th>
                                                            <th>Price (Per Person)</th>
                                                            <th>Days/Nights</th>
                                                            <th>Inclusions</th>
                                                            <th>Places to Visit</th>
                                                            <th>Book Now</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td data-title="Packages"><a href='/kashmir-package-tour-from-bangalore' target='_blank'><b className="tomato">Kashmir Package Tour from Bangalore </b></a></td>
                                                            <td data-title="Price(PP)">₹28,100/-</td>
                                                            <td data-title="Days/Nights">7N 8D</td>
                                                            <td data-title="Inclusions">Meals (MAP), Accommodation, Transfer, Sightseeing</td>
                                                            <td data-title="Places to Visit">Srinagar, Gulmarg, Pahalgam, Houseboat Nightstay</td>
                                                            <td data-title="Book Now">
                                                                <center>
                                                                    <a href="#" className="btn book-now-btn" onClick={this.onRequestCallback}>Book Now</a>
                                                                </center>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Packages"><a href='/kashmir-package-tour-from-pune' target='_blank'><b className="tomato">Kashmir Package Tour from Pune </b></a></td>
                                                            <td data-title="Price(PP)">₹28,100/-</td>
                                                            <td data-title="Days/Nights">7N 8D</td>
                                                            <td data-title="Inclusions">Meals (MAP), Accommodation, Transfer, Sightseeing</td>
                                                            <td data-title="Places to Visit">Srinagar, Gulmarg, Pahalgam, Doodh Pathri, Sonmarg</td>
                                                            <td data-title="Book Now">
                                                                <center>
                                                                    <a href="#" className="btn book-now-btn" onClick={this.onRequestCallback}>Book Now</a>
                                                                </center>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td data-title="Packages"><a href='/kashmir-package-tour-from-chennai' target='_blank'><b className="tomato">Kashmir Package Tour from Chennai </b></a></td>
                                                            <td data-title="Price(PP)">₹28,100/-</td>
                                                            <td data-title="Days/Nights">7N 8D</td>
                                                            <td data-title="Inclusions">Meals (MAP), Accommodation, Transfer, Sightseeing</td>
                                                            <td data-title="Places to Visit">Srinagar, Gulmarg, Pahalgam, Aru Valley, Betab Valley, Doodh Pathri, Sonmarg</td>
                                                            <td data-title="Book Now">
                                                                <center>
                                                                    <a href="#" className="btn book-now-btn" onClick={this.onRequestCallback}>Book Now</a>
                                                                </center>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td data-title="Packages"><a href='/kashmir-package-tour-from-ahmedabad' target='_blank'><b className="tomato">Kashmir Package Tour from Ahmedabad </b></a></td>
                                                            <td data-title="Price(PP)">₹28,100/-</td>
                                                            <td data-title="Days/Nights">7N 8D</td>
                                                            <td data-title="Inclusions">Meals (MAP), Accommodation, Transfer, Sightseeing</td>
                                                            <td data-title="Places to Visit">Srinagar, Gulmarg, Pahalgam, Doodh Pathri, Sonmarg</td>
                                                            <td data-title="Book Now">
                                                                <center>
                                                                    <a href="#" className="btn book-now-btn" onClick={this.onRequestCallback}>Book Now</a>
                                                                </center>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td data-title="Packages"><a href='/kashmir-package-tour-from-surat' target='_blank'><b className="tomato">Kashmir Package Tour from Surat </b></a></td>
                                                            <td data-title="Price(PP)">₹28,100/-</td>
                                                            <td data-title="Days/Nights">7N 8D</td>
                                                            <td data-title="Inclusions">Meals (MAP), Accommodation, Transfer, Sightseeing</td>
                                                            <td data-title="Places to Visit">Srinagar, Gulmarg, Pahalgam, Houseboat Nightstay</td>
                                                            <td data-title="Book Now">
                                                                <center>
                                                                    <a href="#" className="btn book-now-btn" onClick={this.onRequestCallback}>Book Now</a>
                                                                </center>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td data-title="Packages"><a href='/kashmir-package-tour-from-delhi' target='_blank'><b className="tomato">Kashmir Package Tour from Delhi </b></a></td>
                                                            <td data-title="Price(PP)">₹28,100/-</td>
                                                            <td data-title="Days/Nights">7N 8D</td>
                                                            <td data-title="Inclusions">Meals (MAP), Accommodation, Transfer, Sightseeing</td>
                                                            <td data-title="Places to Visit">Srinagar, Gulmarg, Pahalgam, Houseboat Nightstay</td>
                                                            <td data-title="Book Now">
                                                                <center>
                                                                    <a href="#" className="btn book-now-btn" onClick={this.onRequestCallback}>Book Now</a>
                                                                </center>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td data-title="Packages"><a href='/kashmir-package-tour-from-kolkata-with-srinagar-gulmarg-pahalgam-sonmarg' target='_blank'><b className="tomato">Kashmir Package Tour from Kolkata </b></a></td>
                                                            <td data-title="Price(PP)">₹28,100/-</td>
                                                            <td data-title="Days/Nights">7N 8D</td>
                                                            <td data-title="Inclusions">Meals (MAP), Accommodation, Transfer, Sightseeing</td>
                                                            <td data-title="Places to Visit">Srinagar, Gulmarg, Pahalgam, Aru Valley, Betab Valley, Doodh Pathri, Sonmarg</td>
                                                            <td data-title="Book Now">
                                                                <center>
                                                                    <a href="#" className="btn book-now-btn" onClick={this.onRequestCallback}>Book Now</a>
                                                                </center>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <br /><br /><br />

                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>CULTURAL IMMERSION: FESTIVALS AND TRADITIONS ON YOUR</b> <strong>KASHMIR TOUR PACKAGES</strong> </h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>CULTURAL IMMERSION: FESTIVALS AND TRADITIONS ON YOUR</b> <strong>KASHMIR TOUR PACKAGES</strong> </h4>
                                        </div>
                                        <div className="card-body">
                                            <p>Experiencing the festivals and traditions of Kashmir is an important part of your trip from Delhi. Here are some cultural events and traditions you might enjoy during your visit :</p>
                                            <p><b>Eid-ul-Fitr and Eid-ul-Adha </b>These are two important Islamic festivals celebrated with lots of energy in Kashmir. People gather for prayers at mosques, exchange greetings, and enjoy big feasts with family and friends.
                                            </p>
                                            <p><b>Navroz (Nowruz): </b>Navroz, the Persian New Year, is celebrated by the Kashmiri Pandit community with special rituals. You can see colorful processions, cultural performances, and community gatherings as they welcome the new year.</p>

                                            <p><b>Tulip Festival: </b>Held in April, the Tulip Festival in Srinagar marks the start of spring. Thousands of tulips bloom in the Indira Gandhi Memorial Tulip Garden, creating a beautiful scene. The festival also includes cultural performances, handicraft displays, and tasty local food.</p>

                                            <p><b>Shikara Festival: </b>The Shikara Festival takes place on Dal Lake, where decorated wooden boats called shikaras take part in a parade. The festival also features boat races, cultural shows, and showcases Kashmir's connection to its lakes and rivers.</p>

                                            <p><b>Harud (Autumn) Festival: </b>During the autumn season, the Harud Festival celebrates Kashmir’s beautiful fall scenery. You can enjoy music, dance performances, handicraft exhibitions, and traditional food against a backdrop of colorful autumn leaves.</p>

                                            <p><b>Traditional Kashmiri Weddings: </b>If you're lucky, you may get a chance to see a traditional Kashmiri wedding. These are joyful events with colorful rituals, bright clothes, and delicious food, offering a glimpse into Kashmiri culture and hospitality.</p>

                                            <p><b>Kashmiri Music and Dance: </b>Kashmiri music, played with instruments like the santoor, rabab, and tumbaknari, is both soothing and soulful. You can also watch traditional dances such as Rouf, Hafiza, and Bachha Nagma, which reflect the region’s rich artistic traditions.</p>
                                            <p>Taking part in these festivals and traditions gives you a chance to connect with the local culture and create unforgettable memories during your <strong>Kashmir Package from Delhi</strong>.</p>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>WHAT IS THE BEST WAY TO TRAVEL TO <strong>KASHMIR FROM DELHI</strong> ?</h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>WHAT IS THE BEST WAY TO TRAVEL TO <strong>KASHMIR FROM DELHI </strong>?</h4>
                                        </div>
                                        <div className="card-body">
                                            <p>You can easily travel from <strong>Delhi to Kashmir Tour Package</strong>. Look for trains departing from New Delhi Railway Station (NDLS) or Old Delhi Railway Station (DLI) to Jammu Tawi Railway Station (JAT). Use websites like the Indian Railways website (irctc.co.in) or other apps to check train times, availability, and book your tickets. It's a good idea to book in advance, especially during busy times.</p>
                                            <p>The train journey usually takes about 12-14 hours. Once you arrive in Jammu, you'll need to arrange further travel to Srinagar, the capital of Kashmir. You can take another train or choose road transport, such as buses or taxis, to reach Srinagar from Jammu.</p>
                                            <br />
                                            <h4>TRAIN TICKETS FOR <strong>KASHMIR PACKAGES FROM DELHI BY TRAIN</strong></h4><br />
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <thead className="cf">
                                                        <tr className="table-danger">
                                                            <th>Train Name</th>
                                                            <th>Service Days</th>
                                                            <th>Classes</th>
                                                            <th>Departs From</th>
                                                            <th>Arrive Station</th>
                                                            <th>Total Time</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td data-title="Train Name">VANDE BHARAT EX (22439)</td>
                                                            <td data-title="Days">Sun/Mon/Tue/Thurs/Fri/Sat</td>
                                                            <td data-title="Classes">CC, EC</td>
                                                            <td data-title="Departs From">NEW DELHI (DEL – 06:00)</td>
                                                            <td data-title="Arrive Station">JAMMU TAWI (JAT – 12:43)</td>
                                                            <td data-title="Total Time">06hr 43mins</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Train Name">VANDE BHARAT EX (22477)</td>
                                                            <td data-title="Days">Sun/Mon/Tue/Thurs/Fri/Sat</td>
                                                            <td data-title="Classes">CC, EC</td>
                                                            <td data-title="Departs From">NEW DELHI (DEL – 15:00)</td>
                                                            <td data-title="Arrive Station">JAMMU TAWI (JAT – 21:45)</td>
                                                            <td data-title="Total Time">06hr 45mins</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <br />
                                            <h4><strong>KASHMIR PACKAGE FROM DELHI BY ROAD</strong></h4><br />
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <thead className="cf">
                                                        <tr className="table-danger">
                                                            <th>JOURNEY DAYS</th>
                                                            <th>ROUTE FROM</th>
                                                            <th>ROUTE TO</th>
                                                            <th>HIGHWAY</th>
                                                            <th>DISTANCE</th>
                                                            <th>JOURNEY TIME</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td data-title="JOURNEY DAYS">Day 1</td>
                                                            <td data-title="ROUTE FROM">Delhi</td>
                                                            <td data-title="ROUTE TO">Jammu</td>
                                                            <td data-title="NH">Via NH 44</td>
                                                            <td data-title="DISTANCE">586.2 Km</td>
                                                            <td data-title="JOURNEY TIME">10hr 38mins</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="JOURNEY DAYS">Day 1</td>
                                                            <td data-title="ROUTE FROM">Jammu</td>
                                                            <td data-title="ROUTE TO">Srinagar</td>
                                                            <td data-title="NH">Via NH 244A and NH 44</td>
                                                            <td data-title="DISTANCE">244.5 Km</td>
                                                            <td data-title="JOURNEY TIME">05hr 47mins</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <p>Each way to travel has its pros and cons. Flying is the fastest but can be more costly. Driving or taking the train lets you enjoy the beautiful scenery along the way, but it takes longer and can be affected by weather and road conditions. Pick the option that best suits your preferences and needs.</p>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>KASHMIR TOUR PACKAGES FROM DELHI WITH FLIGHT</strong></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>KASHMIR TOUR PACKAGES FROM DELHI WITH FLIGHT</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <p>Flying from Delhi to Srinagar is the quickest and easiest way to reach Kashmir. Srinagar's Sheikh ul-Alam Airport is connected to major cities in India, including Delhi. Many airlines offer daily flights between Delhi and Srinagar, making it a popular choice for travelers.</p>
                                            <br />
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <thead className="cf">
                                                        <tr className="table-danger">
                                                            <th>Flight Name</th>
                                                            <th>Dep. Time</th>
                                                            <th>Arr. Time</th>
                                                            <th>Travel Time</th>
                                                            <th>Stop Over</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td data-title="Flight Name">IndiGo 6E - 2607</td>
                                                            <td data-title="Dep. Time">DEL 05:30</td>
                                                            <td data-title="Arr. Time">SXR 06:55</td>
                                                            <td data-title="Travel Time">1h 25m</td>
                                                            <td data-title="Stop Over">Non stop</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Flight Name">Air India Express IX - 1995</td>
                                                            <td data-title="Dep. Time">DEL 05:30</td>
                                                            <td data-title="Arr. Time">SXR 07:10</td>
                                                            <td data-title="Travel Time">1h 40m</td>
                                                            <td data-title="Stop Over">Non stop</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Flight Name">Air India AI - 827</td>
                                                            <td data-title="Dep. Time">DEL 07:30</td>
                                                            <td data-title="Arr. Time">SXR 08:45</td>
                                                            <td data-title="Travel Time">1h 15m</td>
                                                            <td data-title="Stop Over">Non stop</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Flight Name">IndiGo 6E - 2105</td>
                                                            <td data-title="Dep. Time">DEL 07:30</td>
                                                            <td data-title="Arr. Time">SXR 09:10</td>
                                                            <td data-title="Travel Time">1h 40m</td>
                                                            <td data-title="Stop Over">Non stop</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>GULMARG AND PAHALGAM: MUST-VISIT SPOTS ON YOUR <strong>KASHMIR TRIP FROM DELHI</strong></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>GULMARG AND PAHALGAM: MUST-VISIT SPOTS ON YOUR <strong>KASHMIR TRIP FROM DELHI</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <p><b>Gulmarg :</b></p>
                                            <p><b>•	Skiing:</b> Gulmarg is famous for skiing. With its snowy slopes, it’s great for both new and experienced skiers.</p>
                                            <p><b>•	Gondola Ride:</b> Enjoy a thrilling ride on one of the highest cable cars in the world, offering stunning views of snow-covered peaks and green meadows.</p>
                                            <p><b>•	Scenery:</b> Gulmarg's landscapes are beautiful year-round, with vibrant flowers in summer and snow in winter, perfect for nature lovers and photographers.</p>
                                            <p><b>•	Golfing:</b> Play golf at one of the highest golf courses in the world, set in a stunning location at over 8,500 feet above sea level.</p>
                                            <p><b>Pahalgam:</b></p>
                                            <p><b>•	Lidder River:</b> The Lidder River offers trout fishing, river rafting, and peaceful picnics by the river.</p>
                                            <p><b>•	Betaab Valley:</b> This scenic valley, named after a Bollywood movie, is surrounded by green meadows, pine forests, and snowy mountains, ideal for nature enthusiasts.</p>
                                            <p><b>•	Aru Valley:</b> Discover the peaceful Aru Valley, known for its breathtaking views, trekking paths, and camping spots in the Himalayan landscape.</p>
                                            <p><b>•	Amarnath Yatra Base Camp:</b> Pahalgam is the starting point for the Amarnath Yatra pilgrimage, drawing spiritual visitors from around the world.</p>
                                            <p>Gulmarg and Pahalgam showcase the natural beauty and cultural richness of Kashmir, offering a mix of adventure, outdoor activities, serene retreats, and spiritual experiences. Enjoy these unforgettable spots on your <b>Kashmir trip</b>.</p>
                                        </div>
                                    </div>
                                    <br /><br /><br />

                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>BEST KASHMIR OFFBEAT HOLIDAY PACKAGES FROM DELHI</strong></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>BEST KASHMIR OFFBEAT HOLIDAY PACKAGES FROM DELHI</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <thead className="cf">
                                                        <tr className="table-danger">
                                                            <th>Kashmir Tour Packages</th>
                                                            <th>Price (Per Person)</th>
                                                            <th>Days/Nights</th>
                                                            <th>Inclusions</th>
                                                            <th>Places to Visit</th>
                                                            <th>Book Now</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td data-title="Packages"><a href='/kashmir-tulip-garden-festival-package-tour' target='_blank'><b className="tomato">Kashmir Package Tour with Tulip Package</b></a></td>
                                                            <td data-title="Price(PP)">₹28,100/-</td>
                                                            <td data-title="Days/Nights">7N 8D</td>
                                                            <td data-title="Inclusions">Meals (MAP), Accommodation, Transfer, Sightseeing</td>
                                                            <td data-title="Places to Visit">Srinagar, Gulmarg, Pahalgam, Tulip Garden Tour</td>
                                                            <td data-title="Book Now">
                                                                <center>
                                                                    <a href="#" className="btn book-now-btn" onClick={this.onRequestCallback}>Book Now</a>
                                                                </center>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Packages"><a href='/kashmir-offbeat-package-tour-with-gurez-valley' target='_blank'><b className="tomato">Kashmir Package Tour with Gurez Valley</b></a></td>
                                                            <td data-title="Price(PP)">₹28,100/-</td>
                                                            <td data-title="Days/Nights">7N 8D</td>
                                                            <td data-title="Inclusions">Meals (MAP), Accommodation, Transfer, Sightseeing</td>
                                                            <td data-title="Places to Visit">Srinagar, Gulmarg, Pahalgam, Gurez Valley</td>
                                                            <td data-title="Book Now">
                                                                <center>
                                                                    <a href="#" className="btn book-now-btn" onClick={this.onRequestCallback}>Book Now</a>
                                                                </center>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td data-title="Packages"> <a href='/kashmir-offbeat-package-tour-with-baisaran-valley-doodhpathri-yusmarg' target='_blank'><b className="tomato">Kashmir Package Tour with Doodhpathri Yusmarg</b></a></td>
                                                            <td data-title="Price(PP)">₹28,100/-</td>
                                                            <td data-title="Days/Nights">7N 8D</td>
                                                            <td data-title="Inclusions">Meals (MAP), Accommodation, Transfer, Sightseeing</td>
                                                            <td data-title="Places to Visit">Srinagar, Gulmarg, Pahalgam, Doodh Pathri, Sonmarg, Yusmarg</td>
                                                            <td data-title="Book Now">
                                                                <center>
                                                                    <a href="#" className="btn book-now-btn" onClick={this.onRequestCallback}>Book Now</a>
                                                                </center>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td data-title="Packages"><a href='/kashmir-offbeat-package-tour-with-sinthan-top-aru-valley-betab-valley-chandanbari-pahalgam-sonmarg' target='_blank'><b className="tomato">Kashmir Offbeat Package Tour with Sinthan Top</b></a></td>
                                                            <td data-title="Price(PP)">₹28,100/-</td>
                                                            <td data-title="Days/Nights">7N 8D</td>
                                                            <td data-title="Inclusions">Meals (MAP), Accommodation, Transfer, Sightseeing</td>
                                                            <td data-title="Places to Visit">Srinagar, Gulmarg, Pahalgam, Sinthan Top, Sonmarg</td>
                                                            <td data-title="Book Now">
                                                                <center>
                                                                    <a href="#" className="btn book-now-btn" onClick={this.onRequestCallback}>Book Now</a>
                                                                </center>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td data-title="Packages"><a href='/jammu-kashmir-family-tour-packages' target='_blank'><b className="tomato">Jammu Kashmir Family Tour Packages</b></a></td>
                                                            <td data-title="Price(PP)">₹28,100/-</td>
                                                            <td data-title="Days/Nights">7N 8D</td>
                                                            <td data-title="Inclusions">Meals (MAP), Accommodation, Transfer, Sightseeing</td>
                                                            <td data-title="Places to Visit">Katra, Patnitop, Pahalgam, Gulmarg, Gurez Valley, Srinagar</td>
                                                            <td data-title="Book Now">
                                                                <center>
                                                                    <a href="#" className="btn book-now-btn" onClick={this.onRequestCallback}>Book Now</a>
                                                                </center>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td data-title="Packages"><a href='/kashmir-package-tour-with-amritsar-katra-vaishno-devi-pahalgam-gulmarg-srinagar-sonmarg' target='_blank'><b className="tomato">Kashmir Tour Packages with Amritsar Katra Vaishno Devi </b></a></td>
                                                            <td data-title="Price(PP)">₹28,100/-</td>
                                                            <td data-title="Days/Nights">7N 8D</td>
                                                            <td data-title="Inclusions">Meals (MAP), Accommodation, Transfer, Sightseeing</td>
                                                            <td data-title="Places to Visit">Amritsar, Vaishno Devi Darshan, Katra, Pahalgam, Gulmarg, Sonmarg, Srinagar</td>
                                                            <td data-title="Book Now">
                                                                <center>
                                                                    <a href="#" className="btn book-now-btn" onClick={this.onRequestCallback}>Book Now</a>
                                                                </center>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td data-title="Packages"><a href='/kashmir-package-tour-with-vaishno-devi-katra-pahalgam-gulmarg-srinagar' target='_blank'><b className="tomato">Kashmir Tour Packages with Vaishno Devi </b></a></td>
                                                            <td data-title="Price(PP)">₹28,100/-</td>
                                                            <td data-title="Days/Nights">7N 8D</td>
                                                            <td data-title="Inclusions">Meals (MAP), Accommodation, Transfer, Sightseeing</td>
                                                            <td data-title="Places to Visit">Vaishno Devi Darshan, Katra, Pahalgam, Gulmarg, Srinagar</td>
                                                            <td data-title="Book Now">
                                                                <center>
                                                                    <a href="#" className="btn book-now-btn" onClick={this.onRequestCallback}>Book Now</a>
                                                                </center>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>WHAT ARE THE FAMOUS LAKES TO EXPLORE ON YOUR <strong>KASHMIR TOUR FROM DELHI</strong> ?</h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>WHAT ARE THE FAMOUS LAKES TO EXPLORE ON YOUR <strong>KASHMIR TOUR FROM DELHI</strong> ?</h4>
                                        </div>
                                        <div className="card-body">
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <thead className="cf">
                                                        <tr className="table-danger">
                                                            <th>From</th>
                                                            <th>To</th>
                                                            <th>Distance</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td data-title="From">Surat</td>
                                                            <td data-title="To">Srinagar</td>
                                                            <td data-title="Route">36 hr (1,926.2 km) via NH 48</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="From">Chennai</td>
                                                            <td data-title="To">Srinagar</td>
                                                            <td data-title="Route">54 hr (3,017.4 km) via NH 44</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="From">Pune</td>
                                                            <td data-title="To">Srinagar</td>
                                                            <td data-title="Route">41 hr (2,245.6 km) via NH 44</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="From">Bangalore</td>
                                                            <td data-title="To">Srinagar</td>
                                                            <td data-title="Route">53 hr (2,985.2 km) via NH 44</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="From">Kolkata</td>
                                                            <td data-title="To">Srinagar</td>
                                                            <td data-title="Route">46 hr (2,343.4 km) via NH 44</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="From">Delhi</td>
                                                            <td data-title="To">Srinagar</td>
                                                            <td data-title="Route">18 hr 22 min (812.5 km) via NH 44</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="From">Mumbai</td>
                                                            <td data-title="To">Srinagar</td>
                                                            <td data-title="Route">42 hr (2,169.8 km) via NH 48</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="From">Jammu</td>
                                                            <td data-title="To">Srinagar</td>
                                                            <td data-title="Route">8 hr 14 min (266.5 km) via NH 44</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="From">Srinagar</td>
                                                            <td data-title="To">Gulmarg</td>
                                                            <td data-title="Route">1 hr 30 min (49.5 km) via Srinagar - Tangmarg Rd</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="From">Srinagar</td>
                                                            <td data-title="To">Sonmarg</td>
                                                            <td data-title="Route">2 hr 11 min (79.4 km) via NH1</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="From">Srinagar</td>
                                                            <td data-title="To">Pahalgam</td>
                                                            <td data-title="Route">2 hr 11 min (89.6 km) via Srinagar - Kanyakumari Hwy</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="From">Srinagar</td>
                                                            <td data-title="To">Dras</td>
                                                            <td data-title="Route">4 hr 2 min (141.2 km) via NH1</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="From">Srinagar</td>
                                                            <td data-title="To">Kargil</td>
                                                            <td data-title="Route">5 hr 19 min (202.1 km) via NH1</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="From">Srinagar</td>
                                                            <td data-title="To">Leh</td>
                                                            <td data-title="Route">9 hr 41 min (418.5 km) via NH1</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                            <br />
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>EXPLORING ANCIENT TEMPLES: A HIGHLIGHT OF <strong>KASHMIR HOLIDAY PACKAGES FROM DELHI</strong></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>EXPLORING ANCIENT TEMPLES: A HIGHLIGHT OF <strong>KASHMIR HOLIDAY PACKAGES FROM DELHI</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <p>Take a journey into the rich history of Kashmir with our specially designed <strong>Kashmir Trip booking from Delhi</strong>. Experience the cultural beauty of this stunning valley as you visit famous landmarks that reflect its glorious past.</p>
                                            <p><b>Martand Sun Temple : </b>Explore the Martand Sun Temple, an impressive structure dedicated to the sun god. Built in the 8th century, the temple is known for its detailed carvings and offers amazing views, making it a must-see for history lovers.
                                            </p>
                                            <p><b>Shankaracharya Temple: </b>Perched on a hill, the Shankaracharya Temple provides breathtaking views of Dal Lake and Srinagar. Also called Jyeshteshwara Temple, this ancient site has religious importance and dates back to 200 B.C.</p>

                                            <p><b>Mamleshwar Temple: </b>Feel the peace of the Mamleshwar Temple, dedicated to Lord Shiva. Its calming atmosphere and unique architecture make it a place of comfort for both devotees and visitors.</p>

                                            <p><b>Avantiswami Temple: </b>Explore the Avantiswami Temple ruins, built in the 9th century by King Avantivarman and dedicated to Lord Shiva. These ancient ruins give a glimpse into Kashmir’s artistic and architectural heritage.</p>

                                            <p><b>Zeashta Devi Shrine Temple: </b>Visit the Zeashta Devi Temple in the peaceful Gulmarg Valley, dedicated to the goddess of wealth. It attracts pilgrims and tourists seeking blessings in a quiet, scenic setting.</p>

                                            <p><b>Kheer Bhawani Temple: </b>Stop by the sacred Kheer Bhawani Temple near Srinagar, which is devoted to the goddess Ragnya Devi. The temple is known for its mystical spring that changes color, believed to reflect the condition of Kashmir.</p>

                                            <p><b>Sharika Devi Temple (Hari Parbat): </b>Located on Hari Parbat, Sharika Devi Temple is dedicated to Goddess Shakti and is important to the local religious community. The temple offers panoramic views of Srinagar, combining spirituality with scenic beauty.</p>
                                            <p>Book your <strong>Delhi to Kashmir holiday packages</strong> now and discover the spiritual and historical treasures of Kashmir. Let us guide you through a memorable experience that blends history, spirituality, and natural beauty.</p>
                                            <br /><br />
                                            <table className="image-table">
                                                <tbody>
                                                    <tr>
                                                        <td className="image-cell">
                                                            <img src={sightseen7ImageURL} alt="kashmir tour packages" />
                                                            <img src={sightseen8ImageURL} alt="kashmir trip from srinagar airport" />
                                                            <img src={sightseen9ImageURL} alt="kashmir package tour cost from srinagar airport" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <br />
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>WHAT IS THE BEST TIME TO <strong>KASHMIR HOLIDAY WITH HOUSEBOAT STAY PACKAGE FROM DELHI</strong>?</h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>WHAT IS THE BEST TIME TO <strong>KASHMIR HOLIDAY WITH HOUSEBOAT STAY PACKAGE FROM DELHI</strong>?</h4>
                                        </div>
                                        <div className="card-body">
                                            <p>The best time to book a <strong>Kashmir Tour Plan from Delhi</strong> is between March and November, from spring to autumn. During this time, Kashmir is at its most beautiful, with colorful flowers blooming, green meadows, and clear skies. The snow-capped mountains provide a peaceful and romantic backdrop, perfect for enjoying a Shikara ride on Dal Lake.</p>
                                            <p><b>SPRING [MARCH-EARLY MAY] </b>
                                                In spring, Kashmir comes to life with blooming flowers and green meadows as the snow melts. The temperature is pleasant, making it a great time for nature lovers to explore the valley as it refreshes after winter.
                                            </p>
                                            <p><b>SUMMER [EARLY MAY-LATE AUGUST] </b>
                                                During summer, the weather is mild and skies are clear, perfect for outdoor activities like trekking, golfing, and shikara rides on Dal Lake. It's an excellent time for family vacations, with a cool breeze and beautiful mountain views.
                                            </p>
                                            <p><b>AUTUMN [SEPTEMBER-NOVEMBER] </b>
                                                Autumn is magical in Kashmir, with the landscape turning red and gold as Chinar trees shed their leaves. This season offers a romantic, peaceful atmosphere, and you can also visit the famous Saffron fields of Pampore, known for their beauty and rare spices.
                                            </p>
                                            <p><b>WINTER [DECEMBER-EARLY MARCH] </b>
                                                If you love snow, visit Kashmir in winter for a snowy wonderland. The valley is covered in snow, offering activities like skiing and snowboarding. The frozen Dal Lake provides a special shikara ride experience amidst the snowy landscape.
                                            </p>
                                            <p>So, plan your <strong>Kashmir Trip From Delhi</strong> and experience the natural beauty of this incredible destination.</p>
                                            <br /><br />
                                            <h4><b>MONTH-WISE TEMPERATURE CHART FOR KASHMIR PACKAGES FROM DELHI</b></h4><br />
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <thead className="cf">
                                                        <tr className="table-danger">
                                                            <th>Months</th>
                                                            <th>Temp. (Min - Max)</th>
                                                            <th>Season</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td data-title="Months">March - May</td>
                                                            <td data-title="Temp. (Min - Max)">10°C - 20°C</td>
                                                            <td data-title="Season">Spring</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Months">June - August</td>
                                                            <td data-title="Temp. (Min - Max)">15°C - 30°C</td>
                                                            <td data-title="Season">Summer</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Months">September - November</td>
                                                            <td data-title="Temp. (Min - Max)">10°C - 25°C</td>
                                                            <td data-title="Season">Autumn</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Months">December - February</td>
                                                            <td data-title="Temp. (Min - Max)">-2°C - 10°C</td>
                                                            <td data-title="Season">Winter</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>MUST-VISIT TOURIST ATTRACTIONS IN THE DELHI TO <strong>KASHMIR TOUR PACKAGE</strong></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>MUST-VISIT TOURIST ATTRACTIONS IN THE DELHI TO <strong>KASHMIR TOUR PACKAGE</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <p>Starting your journey from <strong>Delhi to Kashmir Trip</strong> is a dream come true for any traveler. A well-planned <strong>Customized Kashmir Tour</strong> offers a mix of stunning scenery, rich culture, and memorable experiences. Here are the must-visit attractions that will make your trip unforgettable:</p>
                                            <p><b>Srinagar</b>:
                                                The heart of Kashmir, Srinagar, is a beautiful city with its peaceful Dal Lake and Mughal Gardens. Enjoy the charm of life by the Jhelum River, feeling like you're in paradise.
                                            </p>
                                            <p><b>Gulmarg</b>:
                                                Known as the "Meadow of Flowers," Gulmarg is a breathtaking destination featuring a renowned ski resort. With spectacular views of Nanga Parbat and Mt. Apharwat, it’s a must-visit for nature lovers and adventure seekers.
                                            </p>

                                            <p><b>Betaab Valley</b>:
                                                Made famous by the Bollywood movie "Betaab," this picturesque valley is surrounded by lush forests and meadows. It's perfect for a peaceful picnic or a tranquil weekend retreat.
                                            </p>

                                            <p><b>Pahalgam</b>:
                                                Also known as the "Valley of the Shepherds," Pahalgam is famous for its scenic Sheshnag Lake and dense pine forests. It’s a prime spot for trekking, zorbing, and other thrilling adventure activities.
                                            </p>

                                            <p><b>Pulwama</b>:
                                                Pulwama, often referred to as the "Rice Bowl of Kashmir," is celebrated for its vibrant saffron fields and apple orchards. It's a fantastic location for trekking, snowboarding, skiing, and admiring the scenic beauty.
                                            </p>

                                            <p><b>Sonamarg</b>:
                                                Nestled in the Himalayas, Sonamarg is known for its striking natural beauty. A pony ride through its meadows, snow-capped mountains, and flowing rivers makes for an unforgettable experience.
                                            </p>

                                            <p><b>Pari Mahal</b>:
                                                Also called the "Palace of Fairies," Pari Mahal is a stunning seven-terraced garden offering mesmerizing views of Dal Lake and the surrounding mountains. Its rich history and unique architecture make it a must-see.
                                            </p>

                                            <p><b>Shalimar Bagh</b>:
                                                Shalimar Bagh, a grand Mughal Garden in Srinagar, is famous for its magnificent terraces, vibrant flowerbeds, and elegant fountains. It’s an essential stop to experience the grandeur of Mughal architecture during your Kashmir tour.
                                            </p>

                                            <p><b>Shankaracharya Temple</b>:
                                                Perched on a hilltop, this temple dedicated to Lord Shiva offers awe-inspiring views of Srinagar. The 243-step climb is well worth it to take in the breathtaking scenery and the temple’s serene atmosphere.
                                            </p>
                                            <p>Book your <strong>Kashmir Tulip Festival Package Tour</strong> with Adorable Vacation LLP and let the beauty of Kashmir captivate your heart!</p>

                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>SAMPLING KASHMIRI CUISINE: FOOD EXPERIENCES DURING YOUR <strong>HONEYMOON PACKAGES FROM DELHI TO KASHMIR</strong></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>SAMPLING KASHMIRI CUISINE: FOOD EXPERIENCES DURING YOUR <strong>HONEYMOON PACKAGES FROM DELHI TO KASHMIR</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <p>Trying Kashmiri food is a key part of your trip. Here are some must-try dishes:</p>
                                            <br />
                                            <p><b>Rogan Josh:</b> Enjoy this famous lamb dish cooked in a rich, spicy gravy with fennel, cardamom, and cloves. It's a top choice for meat lovers.</p>
                                            <p><b>Wazwan Feast:</b> Experience a traditional Wazwan feast, a large meal served on a big platter called a 'trami.' It includes kebabs, rice dishes, rich gravies, and saffron desserts, showcasing Kashmiri cooking and hospitality.</p>

                                            <p><b>Kashmiri Pulao:</b> Try this aromatic rice dish made with saffron, dried fruits, and spices like cardamom and cloves. It’s often served with curries and kebabs.</p>

                                            <p><b>Kahwa:</b> Sip on Kashmiri Kahwa, a fragrant green tea with saffron, cardamom, cinnamon, and almonds. It’s a comforting drink served after meals or at gatherings.</p>

                                            <p><b>Gushtaba:</b> Taste Gushtaba, a creamy meatball dish made from minced mutton in a yogurt-based gravy with spices. It’s hearty and flavorful.</p>

                                            <p><b>Kashmiri Bakery Delights:</b> Sample Kashmiri breads like Sheermal and Baqerkhani, as well as sweets like Shufta, Phirni, and Modur Pulav, which highlight the region’s baking traditions.</p>

                                            <p><b>Kashmiri Kebabs:</b> Enjoy flavorful kebabs made from marinated meats cooked over charcoal. Try different types like Seekh Kebabs, Tabak Maaz (fried lamb ribs), and Rista (minced meatballs).</p>
                                            <p>Trying these dishes gives you a taste of Kashmiri culture and traditions, making your trip even more memorable.</p>

                                            <br /><br />
                                            <table className="image-table">
                                                <tbody>
                                                    <tr>
                                                        <td className="image-cell">
                                                            <img src={sightseen10ImageURL} alt="kashmir tour packages" />
                                                            <img src={sightseen11ImageURL} alt="kashmir trip from srinagar airport" />
                                                            <img src={sightseen12ImageURL} alt="kashmir package tour cost from srinagar airport" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <br />
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div className="card-header custHeaer">
                                            <h4 className='content-desktop' style={{ fontSize: "1.5vw" }}>SIGHTSEEING ENTRY FEES DURING <strong>BOOK KASHMIR PACKAGES FROM DELHI</strong></h4>
                                            <h4 className='content-mobile2' style={{ fontSize: "3.5vw" }}>SIGHTSEEING ENTRY FEES DURING <strong>BOOK KASHMIR PACKAGES FROM DELHI</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <tbody>
                                                        <tr>
                                                            <td>Places</td>
                                                            <td>Entry Fees</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Dal Lake</td>
                                                            <td>NIL</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Nishant Bagh</td>
                                                            <td>Rs. 24 per adult, Rs. 12 per child</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Pari Mahal</td>
                                                            <td>Rs. 40/Person</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Shalimar Bagh</td>
                                                            <td>Rs. 24 per adult, Rs. 12 per child</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Shankaracharya Temple</td>
                                                            <td>NIL</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Chashme Shahi</td>
                                                            <td>Rs. 24 per adult, Rs. 12 per child</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Verinag Garden</td>
                                                            <td>Rs. 10/Person</td>
                                                        </tr>
                                                        <tr>
                                                            <td>SPS Museum</td>
                                                            <td>Rs. 10 for Indian, Rs. 50 for Foreigners</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Tulip Garden</td>
                                                            <td>Rs. 75 per adult, Rs. 30 per child</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Aru Valley</td>
                                                            <td>Rs. 25/Person</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div className="card-header custHeaer">
                                            <h4 className='content-desktop' style={{ fontSize: "1.5vw" }}>MUST DO ACTIVITIES DURING <strong>KASHMIR TOUR PACKAGES FROM DELHI</strong></h4>
                                            <h4 className='content-mobile2' style={{ fontSize: "3.5vw" }}>MUST DO ACTIVITIES DURING <strong>KASHMIR TOUR PACKAGES FROM DELHI</strong></h4>

                                        </div>
                                        <div className="card-body">
                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>Shikara Rides:</b><b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Shikara Rides:</b> Experience the tranquility of Dal Lake with a leisurely Shikara ride During your <strong> Kashmir Tulip Festival Package Tour</strong>. Glide across the serene waters, passing by floating gardens, houseboats, and vibrant markets. Sunset and early morning rides offer particularly magical views.</p>
                                            <center> <img src={Topbanner0ImageURL} className="img-fluid" alt="..." /></center><br /><br /><br />

                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>Skiing and Snowboarding:</b>
                                                <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Skiing and Snowboarding:</b> During the winter months, places like Gulmarg transform into ski resorts, attracting enthusiasts from around the world. Gulmarg offers excellent slopes for skiing, snowboarding, and heli-skiing, with breathtaking views of the surrounding Himalayan peaks.</p>
                                            <center> <img src={Topbanner1ImageURL} className="img-fluid" alt="..." /></center><br /><br /><br />

                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>Gondola Ride: </b>
                                                <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Gondola Ride: </b>
                                                Take a ride on the Gulmarg Gondola, one of the highest cable cars in the world. Enjoy panoramic views of the snow-capped peaks as you ascend to Kongdori or Apharwat Peak, where you can indulge in activities like hiking and mountain biking.Experience this Awesome Ride During your <strong>Kashmir Tulip Garden Tour</strong> with Adorable Vacation and make this as a life long memories.</p>
                                            <center> <img src={Topbanner2ImageURL} className="img-fluid" alt="..." /></center><br /><br /><br />

                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>Trekking:</b>
                                                <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Trekking:</b> Kashmir is a paradise for trekkers, with numerous trails that cater to all levels of expertise. Popular trekking destinations include the Kashmir Great Lakes Trek, Tarsar Marsar Trek, and the trek to Thajiwas Glacier in Sonamarg.</p>
                                            <center> <img src={Topbanner3ImageURL} className="img-fluid" alt="..." /></center><br /><br /><br />
                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>River Rafting:</b>
                                                <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>River Rafting:</b> The Lidder River in Pahalgam offers exciting opportunities for white-water rafting. Experience the thrill of navigating through rapids while enjoying the scenic beauty of the surrounding pine forests and mountains.</p>
                                            <center> <img src={Topbanner4ImageURL} className="img-fluid" alt="..." /></center>
                                            <br /><br /><br />
                                            <a href="tel://9147061467" className="btn btn-Warning" style={{ padding: '15px 30px', fontSize: '20px' }}>CALL NOW</a>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="container-fluid navtabCustom">
                                        <nav>
                                            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                                <button onClick={() => this.handleCustomTabClick('tab1')} className={`tab button ${customActiveTab === 'tab1' ? 'active' : ''}`} role="tab" aria-controls="nav-highlights" aria-selected={customActiveTab === 'tab1'}>Highlights</button>
                                                <button onClick={() => this.handleCustomTabClick('tab2')} className={`tab button ${customActiveTab === 'tab2' ? 'active' : ''}`} role="tab" aria-controls="nav-flights" aria-selected={customActiveTab === 'tab2'}>Flights</button>
                                                <button onClick={() => this.handleCustomTabClick('tab3')} className={`tab button ${customActiveTab === 'tab3' ? 'active' : ''}`} role="tab" aria-controls="nav-hotel" aria-selected={customActiveTab === 'tab3'}>Hotel</button>
                                                <button onClick={() => this.handleCustomTabClick('tab4')} className={`tab button ${customActiveTab === 'tab4' ? 'active' : ''}`} role="tab" aria-controls="nav-sightseeing" aria-selected={customActiveTab === 'tab4'}>Sightseeing</button>
                                                <button onClick={() => this.handleCustomTabClick('tab5')} className={`tab button ${customActiveTab === 'tab5' ? 'active' : ''}`} role="tab" aria-controls="nav-meals" aria-selected={customActiveTab === 'tab5'}>Meals</button>
                                            </div>
                                        </nav>
                                        <div className="tab-content" id="nav-tabContent" >
                                            <div className={customActiveTab === 'tab1' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-highlights-tab">
                                                <p>The <strong>Kashmir Package Tour from Delhi</strong> is famous for its stunning landscapes, including well-known spots like Dal Lake, Nigeen Lake, Gulmarg, Pahalgam, and Sonamarg. These places draw tourists from all over the world who come to enjoy the beautiful scenery and take part in outdoor activities.</p>
                                                <p>Planning a <strong>Kashmir Tour Packages from Delhi</strong> is a great choice for travelers looking for beautiful scenery, cultural experiences, and adventure in a unique and stunning location.</p>
                                                <p>Kashmir is located in the Himalayan Mountains and is surrounded by India, Pakistan, and China. It has three main regions: Jammu, the Kashmir Valley, and Ladakh. The area is known for its snowy mountains, green valleys, winding rivers, and peaceful lakes.</p>
                                                <p>The <strong>Kashmir Tour Pakcage from Delhi</strong> are famous for their stunning landscapes, including places like Dal Lake, Nigeen Lake, Gulmarg, Pahalgam, and Sonamarg. Tourists from around the world visit to enjoy the beautiful scenery and take part in outdoor activities. Kashmir has always been a popular destination, known for its natural beauty, adventure sports, religious sites, and historical landmarks. Visitors can enjoy activities like trekking, skiing, staying in houseboats, taking shikara rides, and exploring Mughal gardens and ancient temples.</p>
                                            </div>
                                            <div className={customActiveTab === 'tab2' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-flights-tab">
                                                <div className="overflow-table">
                                                    <span>POPULAR FLIGHTS FOR THE KASHMIR TULIP GARDEN TRIP 2025<b> <i>(SXR)</i></b></span><br /><br />
                                                    <table className="table table-bordered">
                                                        <tr className="table-danger">
                                                            <th>Flight Operator</th>
                                                            <th>Departure From</th>
                                                            <th>Departure Time</th>
                                                            <th>Arrival at Srinagar</th>
                                                            <th>Stopover</th>
                                                            <th>Travel Time</th>
                                                        </tr>
                                                        <tr>
                                                            <td>IndiGo</td>
                                                            <td>Kolkata [CCU]</td>
                                                            <td>08:15</td>
                                                            <td>15:15</td>
                                                            <td>New Delhi [DEL]</td>
                                                            <td>7h 00m</td>
                                                        </tr>
                                                        <tr>
                                                            <td>IndiGo</td>
                                                            <td>Mumbai [BOM]</td>
                                                            <td>09:30</td>
                                                            <td>15:15</td>
                                                            <td>New Delhi [DEL]</td>
                                                            <td>5h 45m</td>
                                                        </tr>
                                                        <tr>
                                                            <td>SpiceJet</td>
                                                            <td>New Delhi [DEL]</td>
                                                            <td>08:00</td>
                                                            <td>09:25</td>
                                                            <td>(None)</td>
                                                            <td>1h 25m</td>
                                                        </tr>
                                                        <tr>
                                                            <td>IndiGo</td>
                                                            <td>Bangalore [BLR]</td>
                                                            <td>09:30</td>
                                                            <td>14:10</td>
                                                            <td>Sri Guru Ram Dass Jee [ATQ]</td>
                                                            <td>4h 40m</td>
                                                        </tr>
                                                        <tr>
                                                            <td>IndiGo</td>
                                                            <td>Goa [GOX]</td>
                                                            <td>10:30</td>
                                                            <td>16:45</td>
                                                            <td>New Delhi [DEL]</td>
                                                            <td>6h 15m</td>
                                                        </tr>
                                                        <tr>
                                                            <td>IndiGo</td>
                                                            <td>Hyderabad [HYD]</td>
                                                            <td>08:55</td>
                                                            <td>15:15</td>
                                                            <td>New Delhi [DEL]</td>
                                                            <td>6h 20m</td>
                                                        </tr>
                                                        <tr>
                                                            <td>IndiGo</td>
                                                            <td>Chennai [MAA]</td>
                                                            <td>08:30</td>
                                                            <td>14:20</td>
                                                            <td>New Delhi [DEL]</td>
                                                            <td>5h 50m</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className={customActiveTab === 'tab3' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-hotel-tab">

                                                <div className="overflow-table">
                                                    <div id="no-more-tables">
                                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                            <thead class="cf">
                                                                <tr className="text-center">
                                                                    {/* <!-- Add the "text-center" className to center content --> */}
                                                                    <th colspan="6" style={{ fontSize: "1.3vw" }}><h6><b>3 STAR CATEGORY HOTEL CERTIFIED BY 'ADORABLE VACATION' </b></h6></th>
                                                                </tr>
                                                                <tr className="table-danger">
                                                                    <th className="text-center">Destination</th>
                                                                    <th className="text-center">Hotel Names</th>
                                                                    <th className="text-center">Hotel Type</th>
                                                                    <th className="text-center">No of Nights</th>
                                                                    <th className="text-center">Meal Plan</th>
                                                                </tr>
                                                            </thead>
                                                            <tr>
                                                                <td data-title="Destination"><div style={{ display: 'inline-block' }}>Srinagar</div></td>
                                                                <td data-title="Hotel Names"><div style={{ display: 'inline-block' }}>Hotel Grand Fortune / Hotel Shefaf / Hotel Siddique Palace / Similar</div></td>
                                                                <td data-title="Hotel Type">3 Star
                                                                </td>
                                                                <td data-title="No of Nights"><div style={{ display: 'inline-block' }}>5N</div></td>
                                                                <td data-title="Meal Plan"><div style={{ display: 'inline-block' }}>Room with Breakfast & Dinner</div></td>
                                                            </tr>
                                                            <tr>
                                                                <td data-title="Destination"><div style={{ display: 'inline-block' }}>Gulmarg</div></td>
                                                                <td data-title="Hotel Names"><div style={{ display: 'inline-block' }}>Hotel Affarwat / Hotel Mama Palace /Gulmarg House / Similar
                                                                </div>
                                                                </td>
                                                                <td data-title="Hotel Type">3 Star
                                                                </td>
                                                                <td data-title="No of Nights"><div style={{ display: 'inline-block' }}>1N</div></td>
                                                                <td data-title="Meal Plan"><div style={{ display: 'inline-block' }}>Room with Breakfast & Dinner</div></td>
                                                            </tr>
                                                            <tr>
                                                                <td data-title="Destination"><div style={{ display: 'inline-block' }}>Pahalgam</div></td>
                                                                <td data-title="Hotel Names"><div style={{ display: 'inline-block' }}>Hotel Pine Palace Resort / Hotel Fifth Season / Hotel Moonview Resort /Similar
                                                                </div>
                                                                </td>
                                                                <td data-title="Hotel Type">3 Star
                                                                </td>
                                                                <td data-title="No of Nights"><div style={{ display: 'inline-block' }}>2N</div></td>
                                                                <td data-title="Meal Plan"><div style={{ display: 'inline-block' }}>Room with Breakfast & Dinner</div></td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={customActiveTab === 'tab4' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-sightseeing-tab">
                                                <div className="container-fluid px-0 py-1">
                                                    <h5>WHERE TO STAY DURING YOUR <strong>DELHI TO KASHMIR SIGHTSEEING TOURS</strong></h5><br />
                                                    <p>Your choice of accommodation can make a big difference in your Delhi to Kashmir Adventure Tours experience. Here are some great options for where you can stay: </p><br />
                                                    <p><b className='tomato'>Houseboats on Dal Lake </b> Stay in a traditional houseboat on Dal Lake in Srinagar for a truly unique experience. These beautifully decorated houseboats offer a peaceful and relaxing stay, surrounded by the lake’s calm waters and stunning scenery. You’ll also get to enjoy the warm hospitality of the locals.
                                                    </p>
                                                    <p><b className='tomato'>Luxury Hotels and Resorts: </b>Kashmir has plenty of luxury hotels and resorts in popular places like Srinagar, Gulmarg, and Pahalgam. These hotels provide top-quality service, modern amenities, and amazing views of the landscape, making your stay comfortable and unforgettable.</p>

                                                    <p><b className='tomato'>Boutique Guesthouses: </b>If you want a more personal experience, boutique guesthouses and homestays are a great choice. These cozy accommodations are often located in quiet areas or beautiful countryside settings, allowing you to connect with local culture while enjoying a comfortable stay.</p>

                                                    <p><b className='tomato'>Tented Camps: </b>For adventure lovers and nature enthusiasts, staying in a tented camp is a unique way to experience Kashmir’s natural beauty. Set in lush valleys, near mountains, or beside streams, these camps provide a simple yet comfortable stay close to nature.</p>

                                                    <p><b className='tomato'>Heritage Properties: </b>For a taste of Kashmir’s history and culture, you can stay in heritage properties like old mansions or palaces. These historic places offer a glimpse into Kashmir’s past while providing modern comforts, making your stay both elegant and memorable.</p>

                                                    <p><b className='tomato'>Eco-Resorts and Retreats: </b>If you care about the environment, eco-resorts and retreats are great options. These sustainable accommodations are designed to be eco-friendly without sacrificing comfort. Set in serene forests, meadows, or mountains, they offer a peaceful and green place to stay.</p>
                                                    <p>No matter which option you choose, each type of accommodation in Kashmir has its own special charm. Think about your preferences, budget, and travel plans to find the best fit for your <strong>Luxury Kashmir tours from Delhi</strong>.</p>
                                                </div>
                                            </div>
                                            <div className={customActiveTab === 'tab5' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-meals-tab">
                                                <div className="overflow-table">
                                                    <h5 class="tomato">7 times Meals and 7 times Breakfast has been Provided</h5>
                                                    <table className="table table-bordered">
                                                        <tr className="table-danger">
                                                            <th colspan="6" className="text-center">Category</th>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-center">JAIN</td>
                                                            <td class="text-center">PURE-VEG</td>
                                                            <td class="text-center">VEGAN</td>
                                                            <td class="text-center">PURE-NON-VEG</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <br /><br /><br />
                            {/* <!--By Itinerary--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>
                                                DAY WISE <strong><span>KASHMIR TOUR ITINERARY FROM DELHI</span></strong>
                                            </h2>
                                            <h4>4 Nights 5 Days <strong>Kashmir Tour Itinerary</strong></h4>
                                        </div>
                                    </div>
                                </div>


                                {/* <!--end Short description--> */}
                                <br />
                                <div className="row">
                                    <div
                                        className="accordion"
                                        id="accordionPanelsStayOpenExample"
                                    >
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day1"
                                            >
                                                <button
                                                    className="accordion-button"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseOne"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseOne"
                                                >
                                                    DAY 1  – ARRIVAL IN SRINAGAR
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseOne"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingOne"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        On your arrival at the Srinagar Airport, we will welcome you to pick you up from the Airport.  After checking in at a hotel in Srinagar enjoy a Shikara ride at Dal Lake and vegetable gardens & experience the aquatic life of Srinagar.<b>Dinner & overnight stay in Houseboat</b>.
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay1ImageURL} alt="Kashmir Offbeat Packages for Family" /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay1ImageURL} style={{ width: '320px' }} alt="Kashmir Offbeat Packages for Family" /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day2"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseTwo"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseTwo"
                                                >
                                                    DAY 02: SRINAGAR TO GULMARG
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseTwo"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingTwo"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        After breakfast in the morning, we will begin our countryside day trip towards Gulmarg. Gulmarg (2440 Meters above Sea level). It is one of the most beautiful hill stations and also called “Meadows of Flower”. Gulmarg has one of the best ski slopes in the world and the highest golf course with 18 holes.
                                                    </p>
                                                    <p>In Gulmarg, we will board the Gondola cable car system (on direct payment Basis), and ascend towards the Apharwat Mountain range. The 08 minutes of ropeway will take you past colorful shepherd huntsmen to the lower ridge of the Afarwat peak. From here you can click lots of photos of the nearby mountain ranges that stand tall like sentinels guarding the rich natural beauty of Kashmir valley. Dinner and Overnight at Hotel in Gulmarg.                           </p>

                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay2ImageURL} alt="Kashmir Tour Package cost offbeat" /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay2ImageURL} style={{ width: '320px' }} alt="Kashmir Tour Package cost offbeat" /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day3"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseThree"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 03: GULMARG TO PAHALGAM
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseThree"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingThree"
                                            >
                                                <div className="accordion-body">
                                                    <p>After breakfast & proceeding to Pahalgam,  we drive to Pahalgam via Pampore where you can visit famous saffron fields, Awantipora, and the village of Bijbehara which remains famous as the breadbasket of Kashmir. We switch from the national highway 1A at Khanabal and drive through the second-largest city of Anantnag. Pahalgam is a picturesque valley around 97 kilometers South of Kashmir. Known as Pahalgam (which means The Shepherds Village), the valley is renowned the world over for its exotic forests, pretty water streams, and imposing snow peaks. On the way to Pahalgam, we will take Awantipora's famous temple, famous Saffron field & Apple Valley Garden, Later we will move to the Pahalgam route beautiful view of Lidder river,After check-in hotel leave for pahalgam sightseeing ( Betab valley,Auro valley ,Chandanwadi  by local union cab)& overnight stay in Pahalgam.</p>
                                                    <br />

                                                    <div className="content-desktop">
                                                        <center><img src={navDay3ImageURL} alt="Kashmir Tour Packages" /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay3ImageURL} style={{ width: '320px' }} alt="Kashmir Tour Packages" /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day4"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseFour"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    Day 04: - Pahalgam to Srinagar
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseFour"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingFour"
                                            >
                                                <div className="accordion-body">
                                                    <p>After breakfast ,we will leave for full day srinagar via visit local sightseeing that includes a visit to World famous Tulip garden  , Mughal gardens , Cheshma Shahi(Royal Spring), Nishat(Garden of Pleasure), Shalimar ( Abode of Love) Mughal laid Out in the 16th century & these fall along the bank of Dal-Lake in the heart of Srinagar City  & Shankaracharya Temple located on a hillock, thus providing panoramic view of entire City, Enroute visiting handloom centre famous for  production of Pashmina/Shahtoosh shawls , world over reckoned Carpets & Char Chinari and Overnight stay at Srinagar.</p>

                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay4ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay4ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day5"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseFive"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 05: Departure
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseFive"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingFive"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        After enjoying a delightful morning breakfast, you will be escorted to Srinagar Airport for your departure. As you head to the airport, take a moment to reflect on the wonderful experiences and unforgettable memories you've made during your time in Kashmir, leaving with sweet memories that will last a lifetime.
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay5ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay5ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                            <br />
                            {/* <!--insert table inclusion exclusion--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        {
                                            <InclusionExclusion />
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* <!--end table inclusion exclusion--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center" >
                                            <h2>The Khyber Himalayan Resort & Spa <span>- Gulmarg</span></h2>
                                            <h4><i>Next Level Luxury Resort </i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row py-3">
                                    <img src={brandedTourackageImage_1_URL} alt="..." />
                                </div>

                                <div id="no-more-tables" >
                                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                        <thead>
                                            <tr className="table-danger">
                                                <th>Category</th>
                                                <th>Size(Room)</th>
                                                <th>Hotel Brand</th>
                                                <th>Price (Per Night)</th>
                                                <th>Book Now</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td data-title="Packages">Platinum Accommodation</td>
                                                <td data-title="Size(Room)">386+ SQ.FT</td>
                                                <td data-title="Hotel Brand">Khyber Hotels (Heritage)<div className="reviews">
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                </div></td>
                                                <td data-title="Price (Per Night)">Rs 53,119/- </td>
                                                <td data-title="Book Now"><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick}>
                                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                                </a></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2 >The LaLiT Grand Place <span>- Srinagar</span></h2>
                                            <h4 ><i>Fill the Luxury</i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row py-3">
                                    <img src={brandedTourackageImage_2_URL} alt="..." />
                                </div>

                                <div id="no-more-tables" >
                                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                        <thead>
                                            <tr className="table-danger">
                                                <th>Category</th>
                                                <th>Size(Room)</th>
                                                <th>Hotel Brand</th>
                                                <th>Price (Per Night)</th>
                                                <th>Book Now</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td data-title="Packages">Premium Accommodation</td>
                                                <td data-title="Size(Room)">290+ SQ.FT</td>
                                                <td data-title="Hotel Brand">The LaLiT Hotels<div className="reviews">
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                </div></td>
                                                <td data-title="Price (Per Night)">Rs 28,332/- </td>
                                                <td data-title="Book Now"><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick}>
                                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                                </a></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                <div className="card mx-0 my-5">
                                    <div className="card-header custHeaer">
                                        <h3 className='content-desktop' style={{ fontSize: "1.5vw" }}><b>EMBARKED ON A SENSE OF PRIDE AT PREMIUMNESS</b></h3>
                                        <h3 className='content-mobile2' style={{ fontSize: "3.5vw" }}><b>EMBARKED ON A SENSE OF PRIDE AT PREMIUMNESS</b></h3>
                                    </div>
                                    <div className="card-body">
                                        <p ><b>The LaLit Grand Palace</b> was originally built in 1901 for the royal family. It now provides luxurious rooms with views of Dal Lake and the beautiful gardens. An indoor pool, tennis courts and pampering spa treatments are also available.The tastefully designed rooms are modern with a touch of traditional charm. All have a satellite TV, minibar and free tea/coffee making facilities. It is 18 km from Srinagar Airport and 4 km from Lal Chowk city centre. Jammu Railway Station is 300 km away. Guests can enjoy a relaxing massage at Rejuve Spa Health Club, practice yoga, or play golf. The hotel also has a gym and 24-hour business center. The Chinar Garden serves North Indian cuisine, while international buffet spreads are available at The Chinar – The All Day Coffee Shop. Other dining options include Dal Bar and 24-hour room service.</p>

                                        <a href="tel://9883359713" className="btn btn-Warning">Book Now</a>

                                    </div>
                                </div>
                            </div>

                            <br /><br />
                            {/* <!--start of package details--> */}
                            <div className="container-fluid py-5">

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>Kashmir<span> Package Tour From Delhi </span></h2>
                                            <h4>Related Kashmir Tour plan</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                                        <div className="carousel-inner">
                                            <div className="carousel-item active">

                                                <Slider {...settings}>
                                                    {hotPackagesView}
                                                </Slider>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!--end of package details--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2 >Frequently Ask <span>Questions</span></h2>
                                            <h4 >Kashmir Package Tour From Delhi Related Questions</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="row" >
                                    <div className="accordion" id="accordionPanelsStayOpenExample">
                                        <div className="accordion" id="accordionFlushExample">
                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingEighteen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEighteen" aria-expanded="false" aria-controls="flush-collapseEighteen">
                                                        Q. What Are the Highlights of a Kashmir Package Tour from Delhi?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseEighteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingEighteen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p>A Kashmir tour from Delhi usually includes these top attractions:</p>
                                                        <ul>
                                                            <li><b>1.Srinagar:</b> Dal Lake, Mughal Gardens, Hazratbal Shrine, Shankaracharya Temple</li><br />
                                                            <li><b>2. GULMARG:</b> GONDOLA RIDE, GOLF COURSE</li><br />
                                                            <li><b>3. PAHALGAM:</b> BETAAB VALLEY, ARU VALLEY, LIDDER RIVER</li><br />
                                                            <li><b>4. SONAMARG:</b> THAJIWAS GLACIER, ZOJILA PASS</li><br />
                                                            <li><b>5. YUSMARG</b></li><br />
                                                            <li><b>6. TULIP GARDEN:</b> INDIRA GANDHI MEMORIAL TULIP GARDEN</li><br />
                                                            <li><b>7. SHOPPING AND LOCAL CUISINE:</b> LAL CHOWK</li><br />
                                                            <li><b>8. LOCAL CUISINE:</b> ROGAN JOSH, YAKHNI, DUM ALOO, AND TRADITIONAL WAZWAN DISHES</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingNineteen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNineteen" aria-expanded="false" aria-controls="flush-collapseNineteen">
                                                        Q. What Are the Best Selling Tour Packages from Delhi to Kashmir?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseNineteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingNineteen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p><b>Answer:</b> Popular packages from Delhi to Kashmir often include a 5-day tour covering Srinagar, Gulmarg, Pahalgam, and Sonamarg. There are also 7-day packages for a more relaxed trip with extra time to explore gardens, lakes, and markets. Winter packages for skiing in Gulmarg and spring tours during the Tulip Festival are also popular.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingTwenty">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwenty" aria-expanded="false" aria-controls="flush-collapseTwenty">
                                                        Q. Are There Group Tour Packages from Delhi to Kashmir?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseTwenty" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwenty" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p><b>Answer:</b> Yes, group tour packages are available. These packages usually include transportation, accommodation, guided tours, and activities, making it a convenient and cost-effective way to visit popular places like Srinagar, Gulmarg, Pahalgam, and Sonamarg.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingTwentyOne">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwentyOne" aria-expanded="false" aria-controls="flush-collapseTwentyOne">
                                                        Q. How Long Does a Kashmir Package Tour from Delhi Usually Last?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseTwentyOne" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwentyOne" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p><b>Answer:</b> Most Kashmir tours from Delhi last between 5 to 7 days, depending on what you want to see and do.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingTwentyTwo">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwentyTwo" aria-expanded="false" aria-controls="flush-collapseTwentyTwo">
                                                        Q. What Modes of Transportation Are Included in a Kashmir Package Tour from Delhi?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseTwentyTwo" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwentyTwo" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p><b>Answer:</b> Kashmir tour packages from Delhi typically include transportation by air, train, or road. Flights from Delhi to Srinagar are common, but some tours may also include train journeys or scenic road trips.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingTwentyThree">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwentyThree" aria-expanded="false" aria-controls="flush-collapseTwentyThree">
                                                        Q. What Travel Documents Do I Need for a Tour from Delhi to Kashmir?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseTwentyThree" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwentyThree" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p><b>Answer:</b> You need a valid government-issued photo ID (like an Aadhar card, passport, or driver’s license). Foreign nationals should carry their passport and Indian visa. It’s also a good idea to keep copies of your travel bookings and accommodation confirmations.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingTwentyFour">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwentyFour" aria-expanded="false" aria-controls="flush-collapseTwentyFour">
                                                        Q. Can I Get Adventure Tour Packages from Delhi to Kashmir?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseTwentyFour" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwentyFour" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p><b>Answer:</b> Yes, adventure packages are available and may include activities like trekking, skiing, river rafting, and paragliding for an exciting experience in Kashmir’s stunning landscapes.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingTwentyFive">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwentyFive" aria-expanded="false" aria-controls="flush-collapseTwentyFive">
                                                        Q. How Much Does a Kashmir Package Price from Delhi?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseTwentyFive" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwentyFive" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p><b>Answer:</b> A typical Kashmir tour package from Delhi costs around ₹15,599 for a 4-night, 5-day trip.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingTwentySix">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwentySix" aria-expanded="false" aria-controls="flush-collapseTwentySix">
                                                        Q. What Is the Best Time to Book a Kashmir Package Tour from Delhi?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseTwentySix" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwentySix" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p><b>Answer:</b> The best times to visit Kashmir are:
                                                            <ul>
                                                                <li><b>Spring (March to early May):</b> For blooming flowers and pleasant weather.</li>
                                                                <li><b>Summer (May to August):</b> For mild temperatures and outdoor activities.</li>
                                                                <li><b>Autumn (September to November):</b> For beautiful fall foliage and crisp air.</li>
                                                                <li><b>Winter (December to February):</b> For snow sports and winter landscapes.</li>
                                                            </ul>
                                                            Booking in advance is recommended, especially during peak seasons.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingTwentySeven">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwentySeven" aria-expanded="false" aria-controls="flush-collapseTwentySeven">
                                                        Q. What Kind of Accommodations Are Included in Kashmir Tour Packages from Delhi?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseTwentySeven" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwentySeven" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p><b>Answer:</b> Kashmir tour packages from Delhi generally include various accommodations, such as luxury hotels, mid-range hotels, budget hotels, and traditional houseboats on Dal Lake. The type of accommodation depends on the package you choose, catering to different preferences and budgets.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center" >
                                            <h3><b>Kashmir <span class="tomato">Holiday Reviews</span></b></h3>
                                            <h4><i>"Your Experience is our Marketing"</i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-cols-1">
                                    <div id="testimonials">
                                        <div className="testimonial-box-container" >

                                            {reviewersDataView}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <BookingBox />
                        </div>
                        {this.state.showModal && (
                            <div className="modal" style={{ display: this.state.showModal ? "block" : "none" }}>
                                <div className="modal-dialog">
                                    <div className="modal-content cust-modContent">
                                        <div className="modal-header">
                                            <h4 className="modal-title ms-auto" id="exampleModalLabel">Get a Free Callback &nbsp;&nbsp;<i className="fa fa-phone fa-rotate-90"></i>
                                            </h4>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={this.onCloseModal}></button>
                                        </div>
                                        <div className="modal-body">
                                            <form className="row g-3">
                                                <div className="col-md-12">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Name *"
                                                        name="firstName"
                                                        value={this.state.firstName}
                                                        onChange={this.onChangeHandler} />
                                                </div>
                                                {/* <div className="col-md-6">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Last Name *"
                                                        name="lastName"
                                                        value={this.state.lastName}
                                                        onChange={this.onChangeHandler} />
                                                </div> */}
                                                <div className="col-md-12">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="10 Digit Mobile No.*"
                                                        name="mobile"
                                                        value={this.state.mobile}
                                                        onChange={this.onChangeHandler} />
                                                </div>
                                                <div className="col-12">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Email *"
                                                        name="email"
                                                        value={this.state.email}
                                                        onChange={this.onChangeHandler} />
                                                </div>
                                                <div className="col-12">
                                                    <textarea
                                                        className="form-control"
                                                        placeholder="Comments"
                                                        rows="2"
                                                        name="comments"
                                                        value={this.state.comments}
                                                        onChange={this.onChangeHandler}></textarea>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="modal-footer">
                                            <div className='container'>
                                                <div className="row">
                                                    <div className='col-sm-4'>
                                                        {
                                                            (this.state.showLoader) &&
                                                            <div className='pull-left'><Digital size={25} /></div>
                                                        }
                                                    </div>
                                                    <div className='col-sm-8 '>
                                                        <div className='pull-right'>
                                                            <button type="button" className="btn btn-Warning" onClick={this.onRequestCallbackSubmit} >Submit</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div >
                </div >
                <div class="btn-call-pulse">
                    <a style={{ color: 'white' }} href="tel:+91-91470-61467">
                        <i className="fa fa-phone"></i>
                        {/* <span><small>+91 9147061467</small></span> */}
                    </a>
                </div>
                <div className="btn-whatsapp-pulse">
                    <a style={{ color: 'white' }} href="#" onClick={this.handleClick}>
                        <i className="fab fa-whatsapp"></i>
                        {/* <span>
                                <small>+91 9883359713</small>
                            </span> */}
                    </a>
                </div>

            </>
        );
    }
}

export default Kashmir_4N_5D_Package_Tour_From_Delhi;