import React, { Component } from 'react';
import MainHeader from '../../../components/common/MainHeader';
import FooterDesktop from '../../../components/common/FooterDesktop_ladakh';
import Leh_Ladakh_Kargil_8N_9D_Package from '../../../components/package/Ladakh/Leh_Ladakh_Kargil_8N_9D_Package';
import { Helmet } from "react-helmet";

class Leh_Ladakh_Kargil_8N_9D_Page extends Component {
    constructor() {
        super();
        this.state = {
            TrendingPackagesData: [],
            ReviewersData: [],
        }
    }

    componentDidMount() {
        window.scroll(0, 0);
        const TrendingPackageDataList = [
            {
                Title: "Glorious Ladakh",
                ImageName: "4N-5D-Leh-Ladakh-Tour.webp",
                AllternateText: "...",
                Description:"  2N Leh  |   1N Nubra Valley |  1N Leh |   Private Transfer |   SUV Vehicle |   4 Star Hotel       ",
                PricePerNight: "19,789",
                NoOfTours: 36,
                NoOfDeparture: 25,
                Include: "EX-IXL",
                MutedText: "*Per head / Min 6 Pax",
                Url: 'tel://9163676037'
            },
            {
                Title: "Best of Ladakh",
                ImageName: "5N-6D-Leh-Ladakh-Tour.webp",
                AllternateText: "...",
                Description:" 2N Leh | 1N Nubra Valley | 1N Pangong | 1N Leh | Private Transfer | SUV Vehicle | 4 Star Hotel | Premium Camp  ",
                PricePerNight: "24,729",
                NoOfTours: 42,
                NoOfDeparture: 29,
                Include: "EX-IXL",
                MutedText: "*Per head / Min 6 Pax",
                Url: 'tel://9163676037'
            },
            {
                Title: "Amazing Ladakh",
                ImageName: "6N-7D-Leh-Ladakh-Tour.webp",
                AllternateText: "...",
                Description:"2N Leh | 1N Nubra Valley | 1N Pangong | 1N Tsomoriri | 1N Leh | Private Transfer | SUV Vehicle | 4 Star Hotel | Premium Camp",
                PricePerNight: "31,349",
                NoOfTours: 29,
                NoOfDeparture: 18,
                Include: "EX-IXL",
                MutedText: "*Per head / Min 6 Pax",
                Url: 'tel://9163676037'
            },
            {
                Title: "Extended Ladakh with Kargil",
                ImageName: "8N-9D-Leh-Ladakh-Kargil-Tour.webp",
                AllternateText: "...",
                Description:"2N Leh | 2N Nubra Valley | 1N Pangong | 1N Leh | 1N Kargil | 1N Leh |Private Transfer | SUV Vehicle | 4 Star Hotel | Premium Camp",
                PricePerNight: "41,499",
                NoOfTours: 45,
                NoOfDeparture: 34,
                Include: "EX-IXL",
                MutedText: "*Per head / Min 6 Pax",
                Url: 'tel://9163676037'
            }

        ];

        
        const reviewersDataList = [
            {
                Name: "Sailesh Jain",
                ImageName: "sailash_jain.jpg",
                AllternateText: "...",
                Location: "@Mumbai",
                Comment: "Most of the people have leh ladakh trip in their bucket list this year i got the chance to go there for 12 days road trip so i contact with Adorable ma’am planed everything very carefully with minute details in whole trip. She provides us all hotels booking with map in full tour. All the hotels are goods and most of the important thing is we get JAIN FOOD also most of the places.She is continually in touch in whole trip to advice time to time because we don’t have local driver, we drive by self from delhi to jammu, kargil, leh, nubra, pangong, leh, jispa, manali and last delhi whole trip is near about 2500 km. I’m happy with Adorable services and I appreciate their work.",
            },
            {
                Name: "Sachin Amolkar",
                ImageName: "sachin.jpg",
                AllternateText: "...",
                Location: "@Mumbai",
                Comment: "We visited ladakh with  Adorable Vacation. It's was a great experience right from pickup to till last dropped at the airport. Mr. Soumalya Ghosh  (respected managing partner of Adorable  Group)  was our tour planner. She provided with an excellent driver cum guide who drove us to all the places within Leh,Nubra and pengong. The breakfast and dinner was tasty and hygienic in each of the places and stay is also pretty and decent. We definitely recommend for anyone planning to visit Leh/Ladakh.",
            },
            {
                Name: "Kalpesh Gupta",
                ImageName: "kalpesh.jpg",
                AllternateText: "...",
                Location: "@Delhi",
                Comment: "We visited Ladakh with our parents during first week of May this year. It was a superb experience right from pick up from the Leh airport till final drop-off at the airport. Mr. Soumalya was our tour planning PoC and Akash was our tour manager who were very helpful & made our stay very comfortable & memorable. The entire Adorable team took special care of us & assisted whenever needed since we are travelling with our parents. They helped us to get heaters & extra hot bags when asked for my parents.We were provided with an excellent driver & vehicle who drove us to all the places within Leh, Nubra . We had to miss Pangong due to bad weather & stayed an extra night in Nubra, this helped us to explore beautiful Nubra valley more.The breakfast/ dinner buffet (JAIN & Pure Veg) was tasty and hygienic in each of the places and the stay provided in each of the places has also been pretty decent. I definitely recommend Adorable Vacation LLP. for anyone planning to visit Leh/ Ladakh in the upcoming days.",
            },
            {
                Name: "Dr.Vrindha Patil",
                ImageName: "vadina.jpg",
                AllternateText: "...",
                Location: "@Chennai",
                Comment: "I would recommend anyone who is planning a vacation in Ladakh to kindly contact this company. They took good care of my trip and I didn't face any major issues. Excellent tour arranged by Adorable, our tour itinerary coordinator, was amazing in customizing our travel plan and was very helpful throughout the tour. Zakir Hussain, our driver and guide, was fantastic throughout the tour and went beyond his way to keep all our requests. This tour has been memorable because of them. Again, I would highly recommend them for Leh / Ladakh trips.",
            },
            {
                Name: "Dipali Mehta",
                ImageName: "dipali.jpg",
                AllternateText: "...",
                Location: "@Ahmedabad",
                Comment: "We booked a all girls trip to Leh-Ladakh with Adorable Vacation LLP, on August 2023, and it has been a wonderful experience. The entire correspondence was through Soumalya Ghosh and he did a great job. All specifications provided by us was fullfilled to more than our expectations. From airport pick up to drop everything was done meticulously. Once in Leh, Ms. Ananya took up the responsibility to co ordinate with us and make sure all was well. A special thanks to our driver Mr. Khan for his expertise in driving safe in the rough terrains of Ladakh. Thank you team Adorable Vacation LLP for giving us a great experience.",
            },
       
        ];


        this.setState({
            TrendingPackagesData: TrendingPackageDataList,
            ReviewersData: reviewersDataList
        })
    }

    render() {
        return (
            <> 
             <Helmet>
                    <title>Ladakh Packages Book Ladakh Tour Packages Upto 30% Off</title>
                    <meta name="description" content="Ladakh Tour Packages - Best offers on Ladakh packages up to 40% Off at Adorable. Book your next Ladakh trip and get exciting deals" />
                    <script async src="https://www.googletagmanager.com/gtag/js?id=G-1VTEZ159W5"></script>
                    <script>
                        {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-1VTEZ159W5');
          `}
                    </script>
                </Helmet>
                <MainHeader />
               
                <Leh_Ladakh_Kargil_8N_9D_Package
                    TrendingPackagesData={this.state.TrendingPackagesData}
                    ReviewersData={this.state.ReviewersData}
                />
                <FooterDesktop />
            </>
        );
    }
}

export default Leh_Ladakh_Kargil_8N_9D_Page;