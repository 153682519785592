import React, { Component } from 'react';

class InclusionExclusion_group extends Component {
    render() {
        return (
            <>
                <h4><u>INCLUSION: <strong className='tomato'>SHILLONG KAZIRANGA CHERRAPUNJEE TOUR PACKAGES</strong></u></h4>
                <ul>
                <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Accommodation in the listed 3-star category hotel.
                    </li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Meals Included  ( Breakfast + Dinner).
                    </li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Exclusive vehicle for all transfers & sightseeing.</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Jeep Safari at Kaziranga.</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;All toll, parking, fuel & driver allowance.</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Elephant Safari at Kaziranga .</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Operation executive on call for 24x7.</li>
                </ul><br/><br/><br/>
                <h4><u>EXCLUSIONS: <strong className='tomato'>SHILLONG KAZIRANGA PACKAGE TOUR FROM GUWAHATI</strong></u></h4>
                <ul>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Any entry fees of Temples, Museum, Parks ets.</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Personal expanses like tips, laundry, phone bills, any other meals from the mentioned above.</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Any additional sightseeing from Itinerary/pick up-drop from any other points/Uses of Vehicle at Night – will cost extra.</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Any additional Safari/Boating/Guide charges.</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Anything not mentioned in the Inclusions.</li>
                </ul>
            </>
        );
    }
}

export default InclusionExclusion_group;