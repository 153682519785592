import React, { Component } from "react";
import BookingBox from "../BookingBox";
import Slider from "react-slick";
import ApiURL from "../../../api/ApiURL";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./InclusionExclusion";
import InclusionExclusion from "./InclusionExclusion_Bangalore";

class BangaloreToBhutanPackage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customActiveTab: "tab1",
    };
  }

  handleCustomTabClick = (tab) => {
    this.setState({
      customActiveTab: tab,
    });
  };

  render() {
    const { customActiveTab } = this.state;
    const packageTopBannerImageURL = ApiURL.BhutanTourPackageFromBangaloreImageURL + "/Bhutan-Tour Package-from-Bangalore.webp";
    const packageTopBanner2ImageURL = ApiURL.BhutanTourPackageFromBangaloreImageURL + "/Bhutan-Package-Tour-from-Bangalore.webp";
    const sightseen1ImageURL = ApiURL.BhutanTourPackageFromBangaloreImageURL + "/ADVENTURE-IN-BHUTAN.webp";
    const sightseen2ImageURL = ApiURL.BhutanTourPackageFromBangaloreImageURL + "/HIKKING-IN-TIGER-NEST.webp";
    const sightseen3ImageURL = ApiURL.BhutanTourPackageFromBangaloreImageURL + "/RAFTING-IN-BHUTAN-PUNAKHA.webp";
    const sightseen4ImageURL = ApiURL.BhutanTourPackageFromBangaloreImageURL + "/BHUTAN-LUXURY-ACCOMMODATION.webp";
    const sightseen5ImageURL = ApiURL.BhutanTourPackageFromBangaloreImageURL + "/BHUTAN-LUXURY-STAY.webp";
    const sightseen6ImageURL = ApiURL.BhutanTourPackageFromBangaloreImageURL + "/BHUTAN-VIEW-ROOM.webp";
    const sightseen7ImageURL = ApiURL.BhutanTourPackageFromBangaloreImageURL + "/TIGER-NEST-HIKKING-POINT.webp";
    const sightseen8ImageURL = ApiURL.BhutanTourPackageFromBangaloreImageURL + "/BHUTAN-POPULAR-PLACES.webp";
    const sightseen9ImageURL = ApiURL.BhutanTourPackageFromBangaloreImageURL + "/BHUTAN-BUDDHA-POINT.webp";
    const sightseen10ImageURL = ApiURL.BhutanTourPackageFromBangaloreImageURL + "/BHUTAN-CULTURE.webp";
    const sightseen11ImageURL = ApiURL.BhutanTourPackageFromBangaloreImageURL + "/BHUTAN-IMADATSHI-DISH.webp";
    const sightseen12ImageURL = ApiURL.BhutanTourPackageFromBangaloreImageURL + "/BHUTAN-TRADITATION.webp";
    const navDay1ImageURL = ApiURL.BhutanTourPackageFromBangaloreImageURL + "/IXB-Airport–Phuentsholing.webp";
    const navDay2ImageURL = ApiURL.BhutanTourPackageFromBangaloreImageURL + "/Phuentsholing-to-Thimphu.webp";
    const navDay3ImageURL = ApiURL.BhutanTourPackageFromBangaloreImageURL + "/Thimphu-Local-Sightseeing.webp";
    const navDay4ImageURL = ApiURL.BhutanTourPackageFromBangaloreImageURL + "/Thimphu-to-Punakhka.webp";
    const navDay5ImageURL = ApiURL.BhutanTourPackageFromBangaloreImageURL + "/Punakha-to-Paro.webp";
    const navDay6ImageURL = ApiURL.BhutanTourPackageFromBangaloreImageURL + "/Paro-Local-Sightseeing.webp";
    const navDay7ImageURL = ApiURL.BhutanTourPackageFromBangaloreImageURL + "/Paro-to-Lataguri.webp";

    const settings = {
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    const hotelDataList = this.props.HotelsData;
    const hotelDataView = hotelDataList.map((hotelItem, i) => {
      const hotelImageURL =
        ApiURL.BhutanTourPackageFromBangaloreImageURL + "/" + hotelItem.ImageName;
      return (
        <div className="col-md-3" key={i}>
          <img
            src={hotelImageURL}
            className="rounded mx-auto d-block"
            alt={hotelItem.AllternateText}
          />
          <br />
          <span>
            <b>{hotelItem.HotelName}</b> |
            <span className="tomato">{hotelItem.HotelStar}</span>
          </span>
          <br />
          <span className="rating-box">
            <span className="star">{hotelItem.RatingBoxStar}</span>
            <b className="rating-value">{hotelItem.RatingValue}</b>
          </span>{" "}
          &nbsp;<b>{hotelItem.Reviews}</b>
        </div>
      );
    });

    const themePackageSliderList = this.props.ThemePackagesData;
    const themePackagesDataView = themePackageSliderList.map(
      (sliderItem, i) => {
        const sliderImageURL =
          ApiURL.BhutanTourPackageFromBangaloreImageURL +
          "/" +
          sliderItem.ImageName;
        return (
          <div className="theme-cards-wrapper mx-2" key={i}>
            <div className="theme-card">
              <div className="image-wrapper">
                <img src={sliderImageURL} alt={sliderItem.AllternateText} />
              </div>
              <div className="card-body">
                <h5 className="card-title">{sliderItem.Title}</h5>
                <p className="card-text">{sliderItem.Description}</p>
                <Link to={"#"} className="btn btn-primary">
                  Book Now
                </Link>
              </div>
            </div>
            <div className="clearfix"></div>
          </div>
        );
      }
    );

    const trendingBhutanDataList = this.props.TrendingBhutanDataList;
    const trendingBhutanDataView = trendingBhutanDataList.map(
      (sliderItem, i) => {
        const trendingBhutanImgURL = ApiURL.BhutanTourPackageFromBangaloreImageURL + "/" + sliderItem.ImageName;
        return (
          <div className="theme-cards-wrapper mx-2" key={i}>
            <div className="theme-card">
              <div className="image-wrapper">
                <img src={trendingBhutanImgURL} alt="..." />
              </div>
              <div className="card-body">
                <h6 className="card-title">{sliderItem.Title}<span> &#8377; {sliderItem.PricePerNight}/-</span></h6>
                <p className="card-text">
                  <i className="fa fa-building tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-utensils tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-car-side tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-male tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-camera tomato"></i>
                </p>
                <p className="card-text"><span>{sliderItem.Description}</span></p>
                <p>
                  <span><b className="tomato">{sliderItem.NoOfTours} </b>Tours</span> |
                  <span><b className="tomato">{sliderItem.NoOfDeparture} </b>Departures</span> |
                  <span><b className="sdf-blue">SDF Inc.</b></span>
                </p>
                <div className="card-footer">
                  <small className="text-muted"><strong className="tomato">EX-IXB</strong></small>
                  <Link to={sliderItem.BookNowURL} className="btn btn-Warning btn-sm float-end">
                    Book Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        );
      }
    );

    const reviewersDataList = this.props.ReviewersData;
    const reviewersDataView = reviewersDataList.map((dataItem, i) => {
      const reviewerImageURL =
        ApiURL.BhutanTourPackageFromBangaloreImageURL + "/" + dataItem.ImageName;
      return (
        <div className="testimonial-box" key={i}>
          <div className="box-top">
            <div className="profile">
              <div className="profile-img">
                <img src={reviewerImageURL} alt="..." />
              </div>
              <div className="name-user">
                <strong>{dataItem.Name}</strong>
                <span>{dataItem.Location}</span>
              </div>
            </div>
            <div className="reviews">
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
            </div>
          </div>
          <div className="client-comment">
            <p>{dataItem.Comment}</p>
          </div>
        </div>
      );
    });

    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <img
              src={packageTopBannerImageURL}
              className="mobile-image2"
              alt="..."
            />
          </div>
        </div>

        <div className="container-fluid custom-container py-3">
          <div className="row">
            <div className="col-md-9">
              <div className="row">
                <div className="col-md-12">
                  <div className="container-fluid custom-container py-1">
                    <h1 className="content-desktop" style={{ fontSize: "2vw" }}><center>Book <strong>Bhutan Package Tour from Bangalore</strong></center></h1>

                    <h1 className="content-mobile" style={{ fontSize: "4vw" }}><center>Book <strong>Bhutan Package Tour from Bangalore</strong></center>
                      <hr />
                    </h1>
                    <div className="row">
                      <img src={packageTopBanner2ImageURL} className="mobile-image" alt="..." />

                    </div>
                    <hr />
                    <div className="d-flex flex-column flex-sm-row align-items-sm-center px-2">
                      <div className="font-lg">
                        5 Cities:{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Phuentsholing
                        </a>
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Thimphu
                        </a>
                        &nbsp;{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Paro
                        </a>{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Punakha
                        </a>
                        {" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Lataguri
                        </a>
                      </div>
                      <div className="reviews mx-5">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                      </div>
                    </div>
                    <p className="p-2">
                      If you are looking for a memorable <strong>Bhutan Tour from Bagdogra</strong>, you have come to the right place!
                    </p>
                    <hr />
                  </div>
                  <h2 className="content-desktop p-4" style={{ fontSize: "1.4vw" }}> FOR BOOKING <strong>BHUTAN PACKAGES FROM BANGALORE</strong> <a href='tel:9883359713' class="tomato">☎ CALL Mr. SOUMALYA +91- 98833-59713</a></h2>
                  <h2 className="content-mobile p-4" style={{ fontSize: "4.5vw" }}> FOR BOOKING <strong>BHUTAN PACKAGES FROM BANGALORE</strong> <a href='tel:9883359713' class="tomato">☎ CALL Mr. SOUMALYA +91- 98833-59713</a></h2>
                </div>
              </div>

              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> WHY CHOOSE ADORABLE VACATION LLP ?</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}> WHY CHOOSE ADORABLE VACATION LLP ?</h4>
                </div>
                <div className="card-body">
                  <p>
                    Feeling tired of your daily 9 to 6 work routine? It's time for a break and some adventure! A <strong>Bhutan Package Tour from Bangalore</strong> is the perfect way to find happiness and peace. Located at the northeastern end of the Himalayas, Bhutan is known as the happiest country in the world and is just a short flight from Bangalore.
                    Bhutan offers unmatched natural beauty with its lush green mountains, beautiful valleys, and rivers. This enchanting destination will leave you spellbound with its serene environment.
                  </p>
                  <p>
                    Planning a <strong>Bhutan Tour Package from Bangalore</strong> is a treat. Bhutan is known for its hospitality and luxury travel experiences. With Adorable Vacation LLP, you can enjoy a tailor-made <strong>Bhutan Package from Bangalore</strong>.
                  </p>
                  <p>Besides its scenic beauty, Bhutan has much to offer. From unique culture and delicious cuisine to traditional architecture, Bhutan satisfies all travel desires. Must-visit places include the iconic Tiger's Nest Monastery, Punakha Dzong, Dochula Pass, Chele La Pass, and Thimphu for local shopping and food.</p>
                  <p>So why wait? Book your <strong>Bhutan Tour from Bangalore</strong> today and enjoy peace and happiness in the "Land of Happiness."</p>
                  <p>Adorable Vacation LLP also Offer  <a href="/mumbai-special-chartered-flight" target="_blank"><b className="tomato">Special Chartered Mumbai to Bhutan </b></a>,<a href="/bhutan-tour-package-from-pune" target="_blank"><b className="tomato">Bhutan Package Tour from Pune</b></a>,<a href="/bhutan-tour-package-from-hyderabad" target="_blank"><b className="tomato">Bhutan Package Tour from Hyderabad</b></a>,<a href="/bhutan-tour-from-delhi" target="_blank"><b className="tomato"> Bhutan Package Tour from Delhi</b></a>, <a href="/bhutan-tour-from-kolkata" target="_blank"><b className="tomato">Bhutan Package Tour from Kolkata</b></a>, <a href="/bhutan-tour-package-from-ahemdabad" target="_blank"><b className="tomato">Bhutan Package Tour from Ahmedabad</b></a>, <a href="/bhutan-tour-package-from-bagdogra" target="_blank"><b className="tomato">Bhutan Package Tour from Bagdogra</b></a>,<a href="/bhutan-tour-package" target="_blank"><b className="tomato"> Bhutan Package Tour from Jaigaon</b></a>, <a href="/bhutan-tour-package-from-chennai" target="_blank"><b className="tomato">Bhutan Package Tour from Chennai</b></a>,<a href="/bhutan-tour-package-from-bagdogra" target="_blank"><b className="tomato">Bhutan Package Tour from Bagdogra</b></a>,<a href="/bhutan-tour-package-from-surat" target="_blank"><b className="tomato">Bhutan Package Tour from Surat</b></a> etc at Best Price.</p>
                  <br />

                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen1ImageURL} alt="Sightseeing 1" />
                          <img src={sightseen3ImageURL} alt="Sightseeing 3" />
                          <img src={sightseen2ImageURL} alt="Sightseeing 2" />

                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <br /><br />
                  <a href="tel://9883359713" className="btn btn-Warning">
                    Call Now
                  </a>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> <strong>BHUTAN TOUR FROM BANGALORE VIA BAGDOGRA AIRPORT</strong> DURATION : 7N 8D</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>BHUTAN TOUR FROM BANGALORE VIA BAGDOGRA AIRPORT</strong> DURATION : 7N 8D</h4>
                </div>
                <div className="card-body">
                  <p>Places to cover in <strong>Bhutan Package Tour from Mumbai via Bagdogra Airport</strong> : Phuentsholing 1N | Thimphu 2N | Punakha 1N | Paro 2N | Lataguri 1N</p>
                  <p>Pickup & Drop off  <strong>Bhutan Trip from Bangalore</strong> : Bagdogra International Airport [IXB]
                  </p>
                  <p><strong>Bhutan Group Package Cost from Bangalore</strong> : Rs. 35,199 /- (Based on 6 Pax) Including Sustainable Development fee</p>
                  <br />
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr className="table-danger">
                          <th>CITY</th>
                          <th>ALTITUDE</th>
                          <th>CO-ORDINATES</th>

                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td data-title="CITY">Thimphu</td>
                          <td data-title="ALTITUDE">2,334 m</td>
                          <td data-title="CO-ORDINATES">27.4712° N, 89.6339° E</td>
                        </tr>
                        <tr>
                          <td data-title="CITY">Punakha</td>
                          <td data-title="ALTITUDE">1,274 m</td>
                          <td data-title="CO-ORDINATES">27.5921° N, 89.8797° E</td>
                        </tr>
                        <tr>
                          <td data-title="CITY">Paro</td>
                          <td data-title="ALTITUDE">2,200 m</td>
                          <td data-title="CO-ORDINATES">27.4287° N, 89.4164° E</td>
                        </tr>
                        <tr>
                          <td data-title="CITY">Phobjikha Valley</td>
                          <td data-title="ALTITUDE">3,000 m</td>
                          <td data-title="CO-ORDINATES">27.4594° N, 90.1802° E</td>
                        </tr>
                        <tr>
                          <td data-title="CITY">Chele La Pass</td>
                          <td data-title="ALTITUDE">3,988 m</td>
                          <td data-title="CO-ORDINATES">27.3698° N, 89.3466° E</td>
                        </tr>
                        <tr>
                          <td data-title="CITY">Dochula Pass</td>
                          <td data-title="ALTITUDE">3,100 m</td>
                          <td data-title="CO-ORDINATES">27.2924° N, 89.4501° E</td>
                        </tr>
                        <tr>
                          <td data-title="CITY">Tigers Nest Monastery</td>
                          <td data-title="ALTITUDE">3,120 m</td>
                          <td data-title="CO-ORDINATES">27.2930° N, 89.2148° E</td>
                        </tr>
                        <tr>
                          <td data-title="CITY">Haa Valley</td>
                          <td data-title="ALTITUDE">2,670 m</td>
                          <td data-title="CO-ORDINATES">27.3333° N, 89.1833° E</td>
                        </tr>
                        <tr>
                          <td data-title="CITY">Gasa</td>
                          <td data-title="ALTITUDE">2,850 m</td>
                          <td data-title="CO-ORDINATES">27.8983° N, 89.7310° E</td>
                        </tr>
                        <tr>
                          <td data-title="CITY">Dagana</td>
                          <td data-title="ALTITUDE">1,464 m</td>
                          <td data-title="CO-ORDINATES">27.0970° N, 89.8739° E</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br /><br />


              {/* <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h2>
                        Day Wise Bhutan Tour <span>Plan from India</span>
                      </h2>
                      <h4>7 Nights 8 Days Tour Plan from IXB</h4>
                    </div>
                  </div>
                </div> */}

              <div className="container-fluid py-5">
                <div className="row">
                  <div className="card">
                    <table className="table table-bordered">
                      <thead>
                        <tr className="table-danger">
                          <th>DAY WISE SUMMERY FOR 7N|8D <strong>BHUTAN TOUR PLAN FROM BANGALORE</strong></th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingOne"
                          >
                            <b>DAY 1 → </b>
                            <span>
                              BAGDOGRA AIRPORT TO PHUENTSHOLING
                            </span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingTwo"
                          >
                            <b>DAY 2 → </b>
                            <span>PHUENTSHOLING TO THIMPHU</span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingThree"
                          >
                            <b>DAY 3 → </b>
                            <span>THIMPHU LOCAL SIGHTSEEING</span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingFour"
                          >
                            <b>DAY 4 → </b>
                            <span>THIMPHU TO PUNAKHA TRANSFER VIA DOCHULA PASS</span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingFive"
                          >
                            <b>DAY 5 → </b>
                            <span>PUNAKHA / WANGDUE TO PARO</span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingSix"
                          >
                            <b>DAY 6 → </b>
                            <span>PARO LOCAL SIGHTSEEING & TAKTSHANG MONASTERY HIKING</span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingSeven"
                          >
                            <b>DAY 7 → </b>
                            <span>PARO TO LATAGURI </span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingTen"
                          >
                            <b>DAY 8 → </b>
                            <span>LATAGURI TO BAGDOGRA AIRPORT DROP</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* <!--end Short description--> */}
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>Entry Requirements for Indian Tourists from Bangalore Under Bhutan's New Tourism Policy</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>Entry Requirements for Indian Tourists from Bangalore Under Bhutan's New Tourism Policy</h4>
                </div>
                <div className="card-body">

                  <li>Indian citizens don't need a visa for a <strong>Bhutan Tour from Bangalore</strong>, but an entry permit is required. A local Bhutan tour agency like Adorable Vacation LLP Bhutan can help process this permit.</li><br />
                  <li>A valid passport (with at least 6 months' validity) or a Voter ID card is needed for the tour.</li><br />
                  <li>A single permit allows tourists to travel anywhere beyond Thimphu and Paro.</li><br />
                  <li>According to the new Tourism Policy, all regional tourists must pay a Sustainable Development Fee (SDF) of Rs. 1200 per person per night, in addition to the package price (covering flights, hotels, meals, guides, transportation, and monument fees).</li><br />
                  <li>All permits for <strong>Bhutan Group Tours</strong> will be processed online.</li><br />
                  <li>Hotel bookings and tour guides approved by the Tourism Council of Bhutan (TCB) are mandatory.</li><br />
                  <li>Online permits can be processed through local registered tour operators or any international partners.</li><br />
                  <h5>WHAT DOCUMENTS ARE NEEDED FOR A <strong>BHUTAN TOUR FROM BANGALORE ?</strong></h5><br />
                  <li>For child below 18 years a valid Birth Certificate is required. </li><br />
                  <li>A valid Indian passport with at least 6 months of validity from the intended departure date from Bhutan.</li><br />
                  <li>A valid voter ID card issued by the Election Commission of India.</li>
                  <br /><br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen10ImageURL} alt="Sightseeing 1" />
                          <img src={sightseen11ImageURL} alt="Sightseeing 3" />
                          <img src={sightseen12ImageURL} alt="Sightseeing 2" />

                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <br /><br />
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>BHUTAN SIGHTSEEING TOUR & ENTRY FEES</b></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>BHUTAN SIGHTSEEING TOUR & ENTRY FEES</b></h4>
                </div>
                <div className="card-body">

                  <br />
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr className="table-danger">
                          <th>MUSEUMS & MONUMENTS</th>
                          <th>LOCATION</th>
                          <th>FEES(NU)</th>
                          <th>REMARKS</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td data-title="MUSEUMS">Bhutan Post Office Museum</td>
                          <td data-title="LOCATION">Thimphu</td>
                          <td data-title="FEES(NU)">150</td>
                          <td data-title="REMARKS">9AM - 5PM</td>
                        </tr>
                        <tr>
                          <td data-title="MUSEUMS">Folk Heritage Museum</td>
                          <td data-title="LOCATION">Thimphu</td>
                          <td data-title="FEES(NU)">200</td>
                          <td data-title="REMARKS">9AM - 5PM</td>
                        </tr>
                        <tr>
                          <td data-title="MUSEUMS">Zorig Chusum Arts and Crafts School</td>
                          <td data-title="LOCATION">Thimphu</td>
                          <td data-title="FEES(NU)">200</td>
                          <td data-title="REMARKS">9AM - 4:30PM</td>
                        </tr>
                        <tr>
                          <td data-title="MUSEUMS">Simply Bhutan Museum</td>
                          <td data-title="LOCATION">Thimphu</td>
                          <td data-title="FEES(NU)">1000</td>
                          <td data-title="REMARKS">9AM – 5PM</td>
                        </tr>
                        <tr>
                          <td data-title="MUSEUMS">Takin Preserve Center</td>
                          <td data-title="LOCATION">Thimphu</td>
                          <td data-title="FEES(NU)">300</td>
                          <td data-title="REMARKS">-</td>
                        </tr>
                        <tr>
                          <td data-title="MUSEUMS">National Handicraft Emporium</td>
                          <td data-title="LOCATION">Thimphu</td>
                          <td data-title="FEES(NU)">100</td>
                          <td data-title="REMARKS">-</td>
                        </tr>
                        <tr>
                          <td data-title="MUSEUMS">National Library</td>
                          <td data-title="LOCATION">Thimphu</td>
                          <td data-title="FEES(NU)">100</td>
                          <td data-title="REMARKS">-</td>
                        </tr>
                        <tr>
                          <td data-title="MUSEUMS">Jungshi Handmade Paper Factory</td>
                          <td data-title="LOCATION">Thimphu</td>
                          <td data-title="FEES(NU)">100</td>
                          <td data-title="REMARKS">-</td>
                        </tr>
                        <tr>
                          <td data-title="MUSEUMS">National Memorial Chorten</td>
                          <td data-title="LOCATION">Thimphu</td>
                          <td data-title="FEES(NU)">500</td>
                          <td data-title="REMARKS">9AM – 5PM</td>
                        </tr>
                        <tr>
                          <td data-title="MUSEUMS">Zilukha Nunnery</td>
                          <td data-title="LOCATION">Thimphu</td>
                          <td data-title="FEES(NU)">100</td>
                          <td data-title="REMARKS">9AM – 5PM</td>
                        </tr>
                        <tr>
                          <td data-title="MUSEUMS">Tashichho Dzong</td>
                          <td data-title="LOCATION">Thimphu</td>
                          <td data-title="FEES(NU)">500</td>
                          <td data-title="REMARKS">5PM – 6PM, 9AM – 5PM (PH)</td>
                        </tr>
                        <tr>
                          <td data-title="MUSEUMS">Lampelri Botanical Garden</td>
                          <td data-title="LOCATION">Thimphu</td>
                          <td data-title="FEES(NU)">300</td>
                          <td data-title="REMARKS">-</td>
                        </tr>
                        <tr>
                          <td data-title="MUSEUMS">Changangkha Lhakhang</td>
                          <td data-title="LOCATION">Thimphu</td>
                          <td data-title="FEES(NU)">500</td>
                          <td data-title="REMARKS">-</td>
                        </tr>
                        <tr>
                          <td data-title="MUSEUMS">National Textile Museum</td>
                          <td data-title="LOCATION">Thimphu</td>
                          <td data-title="FEES(NU)">250</td>
                          <td data-title="REMARKS">-</td>
                        </tr>
                        <tr>
                          <td data-title="MUSEUMS">Traditional Handmade Paper Mill</td>
                          <td data-title="LOCATION">Thimphu</td>
                          <td data-title="FEES(NU)">100</td>
                          <td data-title="REMARKS">-</td>
                        </tr>
                        <tr>
                          <td data-title="MUSEUMS">Traditional Medicine Center</td>
                          <td data-title="LOCATION">Thimphu</td>
                          <td data-title="FEES(NU)">500</td>
                          <td data-title="REMARKS">-</td>
                        </tr>
                        <tr>
                          <td data-title="MUSEUMS">Botanical Garden Servithang</td>
                          <td data-title="LOCATION">Thimphu</td>
                          <td data-title="FEES(NU)">200</td>
                          <td data-title="REMARKS">-</td>
                        </tr>
                        <tr>
                          <td data-title="MUSEUMS">Ta Dzong / National Museum</td>
                          <td data-title="LOCATION">Paro</td>
                          <td data-title="FEES(NU)">300</td>
                          <td data-title="REMARKS">-</td>
                        </tr>
                        <tr>
                          <td data-title="MUSEUMS">Taktshang / Tiger's Nest Temple</td>
                          <td data-title="LOCATION">Paro</td>
                          <td data-title="FEES(NU)">1000</td>
                          <td data-title="REMARKS">9AM – 12PM, 2PM – 5PM</td>
                        </tr>
                        <tr>
                          <td data-title="MUSEUMS">Kyichu Lhakhang</td>
                          <td data-title="LOCATION">Paro</td>
                          <td data-title="FEES(NU)">500</td>
                          <td data-title="REMARKS">9AM – 12PM, 1PM – 4:30PM</td>
                        </tr>
                        <tr>
                          <td data-title="MUSEUMS">Jangtsa Dumtseg Lhakhang</td>
                          <td data-title="LOCATION">Paro</td>
                          <td data-title="FEES(NU)">1000</td>
                          <td data-title="REMARKS">-</td>
                        </tr>
                        <tr>
                          <td data-title="MUSEUMS">Tachogang Lhakhang</td>
                          <td data-title="LOCATION">Paro</td>
                          <td data-title="FEES(NU)">500</td>
                          <td data-title="REMARKS">-</td>
                        </tr>
                        <tr>
                          <td data-title="MUSEUMS">Punakha Dzong</td>
                          <td data-title="LOCATION">Punakha</td>
                          <td data-title="FEES(NU)">500</td>
                          <td data-title="REMARKS">9AM- 5PM</td>
                        </tr>
                        <tr>
                          <td data-title="MUSEUMS">Chhimi Lhakhang</td>
                          <td data-title="LOCATION">Punakha</td>
                          <td data-title="FEES(NU)">500</td>
                          <td data-title="REMARKS">-</td>
                        </tr>
                        <tr>
                          <td data-title="MUSEUMS">Khamsum Yulley Namgyal Chorten</td>
                          <td data-title="LOCATION">Punakha</td>
                          <td data-title="FEES(NU)">100</td>
                          <td data-title="REMARKS">9AM – 5PM</td>
                        </tr>
                        <tr>
                          <td data-title="MUSEUMS">Sangchhen Dorji Lhuendrup Nunnery</td>
                          <td data-title="LOCATION">Punakha</td>
                          <td data-title="FEES(NU)">200</td>
                          <td data-title="REMARKS">-</td>
                        </tr>
                        <tr>
                          <td data-title="MUSEUMS">Black-Necked Crane Education Center</td>
                          <td data-title="LOCATION">Phobjikha</td>
                          <td data-title="FEES(NU)">200</td>
                          <td data-title="REMARKS">9AM-5PM</td>
                        </tr>
                        <tr>
                          <td data-title="MUSEUMS">Gangtey Monastery</td>
                          <td data-title="LOCATION">Phobjikha</td>
                          <td data-title="FEES(NU)">100</td>
                          <td data-title="REMARKS">-</td>
                        </tr>
                        <tr>
                          <td data-title="MUSEUMS">Trongsa National Museum</td>
                          <td data-title="LOCATION">Trongsa</td>
                          <td data-title="FEES(NU)">300</td>
                          <td data-title="REMARKS">9AM-5PM</td>
                        </tr>
                        <tr>
                          <td data-title="MUSEUMS">Swiss Factory</td>
                          <td data-title="LOCATION">Bumthang</td>
                          <td data-title="FEES(NU)">700</td>
                          <td data-title="REMARKS">-</td>
                        </tr>
                        <tr>
                          <td data-title="MUSEUMS">Ogyen Choling Palace Museum</td>
                          <td data-title="LOCATION">Bumthang</td>
                          <td data-title="FEES(NU)">100</td>
                          <td data-title="REMARKS">-</td>
                        </tr>
                        <tr>
                          <td data-title="MUSEUMS">Palden Tashi Choling Shedra</td>
                          <td data-title="LOCATION">Phuentsholing</td>
                          <td data-title="FEES(NU)">1000</td>
                          <td data-title="REMARKS">-</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                </div>
              </div>
              <br /><br />

              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>HOW TO REACH BHUTAN FROM BANGALORE</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>HOW TO REACH BHUTAN FROM BANGALORE</h4>
                </div>
                <div className="card-body">
                  <p>Hello travelers, getting to Bhutan - The Land of Thunder Dragons, is easy from Bangalore. There are many flights from Kempegowda International Airport (BLR) in Bangalore to Bagdogra Airport (IXB). Bagdogra is about 16 km west of Siliguri in northern West Bengal and is well-connected to all major cities in India, including Bangalore. From Bagdogra Airport, you can travel to Phuentsholing, the gateway to Bhutan, which is 154 km away (about a 3.5-hour drive via NH 27). It's a scenic 4-hour journey through the beautiful greenery of the Tarai and Dooars. Guests from Bangalore can easily fly to Bagdogra Airport, from where Adorable Vacation offers many <strong>Bhutan packages</strong>. So, fly to Bagdogra and start your <strong>Bangalore to Bhutan Packages</strong> from there!</p>
                  <br />
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr className="table-danger">
                          <th>AIRLINES</th>
                          <th>ORIGIN</th>
                          <th>DEPARTURE</th>
                          <th>DESTINATION</th>
                          <th>ARRIVAL</th>
                          <th>DURATION</th>

                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td data-title="AIRLINES">AirAsia</td>
                          <td data-title="ORIGIN">Bangalore [BLR]</td>
                          <td data-title="DEPARTURE">05:20</td>
                          <td data-title="DESTINATION">Bagdogra [IXB]</td>
                          <td data-title="ARRIVAL">08:20</td>
                          <td data-title="DURATION">03 h 00 m (non-stop)</td>
                        </tr>
                        <tr>
                          <td data-title="AIRLINES">SpiceJet</td>
                          <td data-title="ORIGIN">Bangalore [BLR]</td>
                          <td data-title="DEPARTURE">06:05</td>
                          <td data-title="DESTINATION">Bagdogra [IXB]</td>
                          <td data-title="ARRIVAL">09:05</td>
                          <td data-title="DURATION">03 h 00 m (non-stop)</td>
                        </tr>
                        <tr>
                          <td data-title="AIRLINES">IndiGo</td>
                          <td data-title="ORIGIN">Bangalore [BLR]</td>
                          <td data-title="DEPARTURE">06:25</td>
                          <td data-title="DESTINATION">Bagdogra [IXB]</td>
                          <td data-title="ARRIVAL">09:15</td>
                          <td data-title="DURATION">02 h 50 m (non-stop)</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br /><br />
                  <h5>OPTION 2 [BY AIR]: DIRECT FLIGHT WITH <strong>BHUTAN TOUR FROM KOLKATA</strong> </h5>
                  <br />
                  <p>Bhutan Airlines and Druk Airways operate flights from Delhi, Kolkata, and Guwahati to Paro Airport (PBH) all year round. Guests can fly from Bangalore to Delhi, Kolkata, or Guwahati, and then take a direct flight to Paro, Bhutan.</p>
                  <h5>FLIGHTS FROM <strong>BANGALORE TO BHUTAN PACKAGE</strong> VIA KOLKATA OR DELHI </h5>
                  <br />
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr className="table-danger">
                          <th>AIRLINES</th>
                          <th>ORIGIN</th>
                          <th>DEPARTURE</th>
                          <th>DESTINATION</th>
                          <th>ARRIVAL</th>
                          <th>DURATION</th>

                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td data-title="AIRLINES">IndiGo</td>
                          <td data-title="ORIGIN">Bangalore [BLR]</td>
                          <td data-title="DEPARTURE">06:30</td>
                          <td data-title="DESTINATION">Kolkata [CCU]</td>
                          <td data-title="ARRIVAL">09:05</td>
                          <td data-title="DURATION">02 h 35 m (non-stop)</td>
                        </tr>
                        <tr>
                          <td data-title="AIRLINES">IndiGo</td>
                          <td data-title="ORIGIN">Bangalore [BLR]</td>
                          <td data-title="DEPARTURE">04:40</td>
                          <td data-title="DESTINATION">Delhi [DEL]</td>
                          <td data-title="ARRIVAL">07:25</td>
                          <td data-title="DURATION">02 h 45 m (non-stop)</td>
                        </tr>
                        <tr>
                          <td data-title="AIRLINES">AirIndia</td>
                          <td data-title="ORIGIN">Bangalore [BLR]</td>
                          <td data-title="DEPARTURE">06:10</td>
                          <td data-title="DESTINATION">Delhi [DEL]</td>
                          <td data-title="ARRIVAL">08:55</td>
                          <td data-title="DURATION">02 h 45 m (non-stop)</td>
                        </tr>
                        <tr>
                          <td data-title="AIRLINES">AirAsia</td>
                          <td data-title="ORIGIN">Bangalore [BLR]</td>
                          <td data-title="DEPARTURE">06:10</td>
                          <td data-title="DESTINATION">Delhi [DEL]</td>
                          <td data-title="ARRIVAL">08:55</td>
                          <td data-title="DURATION">02 h 45 m (non-stop)</td>
                        </tr>
                        <tr>
                          <td data-title="AIRLINES">SpiceJet</td>
                          <td data-title="ORIGIN">Bangalore [BLR]</td>
                          <td data-title="DEPARTURE">06:55</td>
                          <td data-title="DESTINATION">Delhi [DEL]</td>
                          <td data-title="ARRIVAL">08:25</td>
                          <td data-title="DURATION">02 h 30 m (non-stop)</td>
                        </tr>
                        <tr>
                          <td data-title="AIRLINES">Vistara</td>
                          <td data-title="ORIGIN">Bangalore [BLR]</td>
                          <td data-title="DEPARTURE">07:00</td>
                          <td data-title="DESTINATION">Delhi [DEL]</td>
                          <td data-title="ARRIVAL">09:40</td>
                          <td data-title="DURATION">02 h 40 m (non-stop)</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br />  <br />
                  <h5>OPTION 3 [BY AIR]: <strong>BHUTAN TOUR FROM GUWAHATI</strong> [DIRECT FLIGHT]</h5>
                  <br />
                  <p>Druk Air operates a <strong>Direct Flight from Guwahati to Paro</strong>, departing at 3:20 PM every Sunday. Guests from Bangalore can easily arrive in Guwahati by a morning flight and then board the flight to Bhutan.</p>
                  <p>Additionally, starting April 6th, there will be another direct flight from <strong>Guwahati to Paro</strong>, departing on Thursdays, with a return flight from Paro to Guwahati on Wednesdays. The <strong>Guwahati to Paro Flight</strong> schedule will be as follows:</p>
                  <br /><br />
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr className="table-danger">
                          <th>AIRLINES</th>
                          <th>FLIGHT NO</th>
                          <th>ORIGIN</th>
                          <th>DEPARTURE</th>
                          <th>DESTINATION</th>
                          <th>DEP DAYS</th>

                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td data-title="AIRLINES">Drukair-Royal Bhutan Airlines</td>
                          <td data-title="FLIGHT NO">KB 541</td>
                          <td data-title="ORIGIN">[GAU]</td>
                          <td data-title="DEPARTURE">15:20</td>
                          <td data-title="DESTINATION">[PBH]</td>
                          <td data-title="DEP DAYS">Every Sunday</td>
                        </tr>
                        <tr>
                          <td data-title="AIRLINES">Drukair-Royal Bhutan Airlines</td>
                          <td data-title="FLIGHT NO">KB 541</td>
                          <td data-title="ORIGIN">[GAU]</td>
                          <td data-title="DEPARTURE">15:20</td>
                          <td data-title="DESTINATION">[PBH]</td>
                          <td data-title="DEP DAYS">Every Thursday [Start from 6th April]</td>
                        </tr>
                        <tr>
                          <td data-title="AIRLINES">Drukair-Royal Bhutan Airlines</td>
                          <td data-title="FLIGHT NO">KB 540</td>
                          <td data-title="ORIGIN">[PBH]</td>
                          <td data-title="DEPARTURE">08:05</td>
                          <td data-title="DESTINATION">[GAU]</td>
                          <td data-title="DEP DAYS">Every Saturday</td>
                        </tr>
                        <tr>
                          <td data-title="AIRLINES">Drukair-Royal Bhutan Airlines</td>
                          <td data-title="FLIGHT NO">KB 540</td>
                          <td data-title="ORIGIN">[PBH]</td>
                          <td data-title="DEPARTURE">08:05</td>
                          <td data-title="DESTINATION">[GAU]</td>
                          <td data-title="DEP DAYS">Every Wednesday [Start from 12th April]</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br />


                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen4ImageURL} alt="Sightseeing 4" />
                          <img src={sightseen5ImageURL} alt="Sightseeing 5" />
                          <img src={sightseen6ImageURL} alt="Sightseeing 6" />

                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <br />
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>WHY BOOK BHUTAN PACKAGE TOUR FROM BANGALORE WITH ADORABLE VACATION?</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>WHY BOOK BHUTAN PACKAGE TOUR FROM BANGALORE WITH ADORABLE VACATION?</h4>
                </div>
                <div className="card-body">

                  <li>Trusted brand for <strong>Bhutan Tour Packages from Bangalore</strong> since 2011.</li><br />
                  <li>Offers ultra-luxury and premium packages with 4/5-star hotels like Six Senses, COMO Uma, Amankora, Le Méridien, and Taj Tashi.</li><br />
                  <li>Authorized selling agent of Bhutan Airlines and Druk Airlines.</li><br />
                  <li>Provides special airfare as PSA (Passenger Service Agent) of Bhutan Airlines.</li><br />
                  <li>Pre-purchased hotel rooms for peak season.</li><br />
                  <li>Authorized travel house by the Tourism Council of Bhutan.</li><br />
                  <li>Directly employs staff in Bhutan for personalized service.</li><br />
                  <li>Luxury packages with top hotels like Six Senses, COMO Uma, and Le Méridien Paro.</li><br />
                  <li>Customized itineraries with unique and signature tour programs.</li><br />
                  <li>In-depth knowledge of Bhutan's territory, hotels, laws, and regulations.</li><br />
                  <li>Ensures enriched experiences and preferred services during your stay.</li><br />
                  <li>Processes e-permits in advance for hassle-free sightseeing.</li><br />
                  <li>Provides safe and secure personal attention throughout your stay.</li><br />
                  <li>Takes full responsibility for your <strong>Bhutan Travel from Bangalore</strong>.</li><br />
                  <li>Offers the best deals for top properties and services.</li><br />
                  <li>Allows booking even during weekends and holidays.</li><br />
                </div>
              </div>

              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>BEST TIME TO <strong>BHUTAN GROUP TOUR FROM BANGALORE</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>BEST TIME TO <strong>BHUTAN GROUP TOUR FROM BANGALORE</strong></h4>
                </div>
                <div className="card-body">
                  <p>Bhutan, nestled in the Himalayas, is a small country with distinct seasons. Summer, winter, spring, and autumn each bring their own beauty, making Bhutan a great destination to visit any time of the year:</p>
                  <p><b>Monsoon (July to August) :</b> June to August is the monsoon season in Bhutan. It can drizzle or rain heavily, disrupting life in the hills. Landslides often cause roadblocks and heavy traffic. It's best to avoid traveling to Bhutan during the monsoon.</p>
                  <p><b>Autumn (September to November) :</b>  is a great time to visit Bhutan. With little to no rain, the weather is pleasant for travel and sightseeing. Many important festivals, such as the Black-Necked Crane Festival, Paro Tshechu, and Punakha Tshechu, take place during this season. Dzongs and monasteries are decorated with colorful flags, and dancers perform in vibrant masks. This festive atmosphere makes it an ideal time to book a <strong>Bhutan Tour Package from Bangalore.</strong></p>
                  <p><strong>Autumn (September to November) :</strong> September to November is a great time to visit Bhutan. The weather is pleasant with little to no rain, making travel and sightseeing enjoyable. Many important festivals take place during this time, such as the Black-Necked Crane Festival, Paro Tshechu, and Punakha Tshechu. Dzongs and monasteries are decorated with colorful flags and large Thonkas, and dancers in colorful masks perform with great energy. This is one of the best times to book a <strong>Bhutan Tour Package from Bangalore</strong>.</p>
                  <p>•	Winter (December to February) : Winter in Bhutan can be harsh, with many parts of the northern region experiencing sub-zero temperatures. Cities like Thimphu, Paro, and Punakha can turn white due to heavy snowfall. The government of Bhutan sometimes declares long holidays for schools and offices, allowing people to work from home. Despite the cold, life continues with resilience. Many festivals are celebrated during winter, including Monggar Tshechu, Trashigang Tshechu, Lhuntse Tshechu, and Trongsa Tshechu. If you want to experience Bhutan's diverse seasons, every season has its unique charm and options. You can also book your <strong>Bhutan Group Tour</strong> during winter to witness Bhutan in its snowy splendor.</p>
                  <br />


                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen7ImageURL} alt="Sightseeing 7" />
                          <img src={sightseen9ImageURL} alt="Sightseeing 9" />
                          <img src={sightseen8ImageURL} alt="Sightseeing 8" />
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <br />
                  <p>According to Adorable Vacation, the best time to book a <strong>Bangalore to Bhutan Tour Packages</strong> is during spring (March to May). This is when the valleys, streets, and alleys burst into life with blooming Jacaranda and Rhododendron flowers. The weather is soothing with clear skies and minimal rain, making it perfect for famous treks like Jomolhari and Drukpath. Travelers from around the world, including European countries, the USA, Canada, Brazil, China, Thailand, Cambodia, and Australia, flock to Bhutan during this season.</p><br />
                  <h5>TEMPERATURE IN BHUTAN EACH MONTH</h5><br />
                  <br /><br />
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr className="table-danger">
                          <th>MONTH</th>
                          <th>THIMPHU</th>
                          <th>PARO</th>
                          <th>PUNAKHA</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td data-title="MONTH">JANUARY</td>
                          <td data-title="THIMPHU">12.4 / -2.5°C</td>
                          <td data-title="PARO">9.5 / -5.8°C</td>
                          <td data-title="PUNAKHA">17 / 4.3°C</td>
                        </tr>
                        <tr>
                          <td data-title="MONTH">FEBRUARY</td>
                          <td data-title="THIMPHU">14.5 / 0.7°C</td>
                          <td data-title="PARO">13 / 1.6°C</td>
                          <td data-title="PUNAKHA">19 / 7.9°C</td>
                        </tr>
                        <tr>
                          <td data-title="MONTH">MARCH</td>
                          <td data-title="THIMPHU">16.7 / 3.9°C</td>
                          <td data-title="PARO">14.5 / 0.6°C</td>
                          <td data-title="PUNAKHA">22.8 / 10.4°C</td>
                        </tr>
                        <tr>
                          <td data-title="MONTH">APRIL</td>
                          <td data-title="THIMPHU">20 / 7.2°C</td>
                          <td data-title="PARO">17.5 / 4.6°C</td>
                          <td data-title="PUNAKHA">26.2 / 12.9°C</td>
                        </tr>
                        <tr>
                          <td data-title="MONTH">MAY</td>
                          <td data-title="THIMPHU">22.6 / 13.2°C</td>
                          <td data-title="PARO">23.5 / 10.6°C</td>
                          <td data-title="PUNAKHA">29.1 / 17.7°C</td>
                        </tr>
                        <tr>
                          <td data-title="MONTH">JUNE</td>
                          <td data-title="THIMPHU">24.5 / 15.3°C</td>
                          <td data-title="PARO">25.4 / 14.2°C</td>
                          <td data-title="PUNAKHA">29.2 / 20.1°C</td>
                        </tr>
                        <tr>
                          <td data-title="MONTH">JULY</td>
                          <td data-title="THIMPHU">25.8 / 15.5°C</td>
                          <td data-title="PARO">26.8 / 14.9°C</td>
                          <td data-title="PUNAKHA">30.4 / 20.6°C</td>
                        </tr>
                        <tr>
                          <td data-title="MONTH">AUGUST</td>
                          <td data-title="THIMPHU">25 / 15.7°C</td>
                          <td data-title="PARO">25.3 / 14.7°C</td>
                          <td data-title="PUNAKHA">29.1 / 20°C</td>
                        </tr>
                        <tr>
                          <td data-title="MONTH">SEPTEMBER</td>
                          <td data-title="THIMPHU">23.2 / 15°C</td>
                          <td data-title="PARO">23.4 / 11.8°C</td>
                          <td data-title="PUNAKHA">27.5 / 19.1°C</td>
                        </tr>
                        <tr>
                          <td data-title="MONTH">OCTOBER</td>
                          <td data-title="THIMPHU">21.8 / 10.5°C</td>
                          <td data-title="PARO">18.7 / 7.4°C</td>
                          <td data-title="PUNAKHA">26.1 / 14.3°C</td>
                        </tr>
                        <tr>
                          <td data-title="MONTH">NOVEMBER</td>
                          <td data-title="THIMPHU">17.8 / 5°C</td>
                          <td data-title="PARO">13.8 / 1.4°C</td>
                          <td data-title="PUNAKHA">22.6 / 9.6°C</td>
                        </tr>
                        <tr>
                          <td data-title="MONTH">DECEMBER</td>
                          <td data-title="THIMPHU">14.4 / -1.3°C</td>
                          <td data-title="PARO">11.2 / -1.5°C</td>
                          <td data-title="PUNAKHA">19.1 / 6.2°C</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                </div>
              </div>

              <br /><br />
              {/* <!--By Itinerary--> */}
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h2>
                        DAY WISE <strong><span>BHUTAN TOUR PLAN FROM BANGALORE</span></strong>
                      </h2>
                      <h4>7 Nights 8 Days <strong>Bhutan Tour Itinerary from Bangalore Airport</strong></h4>
                    </div>
                  </div>
                </div>


                {/* <!--end Short description--> */}
                <br />
                <div className="row">
                  <div
                    className="accordion"
                    id="accordionPanelsStayOpenExample"
                  >
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="day1"
                      >
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseOne"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseOne"
                        >
                          DAY 1  – : IXB Airport – Phuentsholing (Distance: 170 km. / Driving Time: 5 hrs. / Altitude of Phuentsholing: 293 mts.)
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingOne"
                      >
                        <div className="accordion-body">
                          <p>
                            Meet & Greet on arrival at NJP Railway Station / IXB Airport and transfer to Phuentsholing - The Gateway of Bhutan by Road from India. It is a thriving commercial center on the northern edge of the Indian Plains. On arrival check in at the hotel.Overnight stay at Phuentsholing.

                          </p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay1ImageURL} alt="..." /></center>
                          </div>

                          <div className="content-mobile2">
                            <center><img src={navDay1ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                          <br />
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="day2"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseTwo"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseTwo"
                        >
                          DAY 02: PHUENTSHOLING TO THIMPHU [DISTANCE: 176 KM | 6 HRS]
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseTwo"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingTwo"
                      >
                        <div className="accordion-body">
                          <p>
                            After breakfast, proceed towards Thimpu – the capital city of Bhutan via the wonderful mystic town Gedu, about 9000 ft above the sea and Chukha Dam. On the way, halt at Chojum to take photographs of the confluence of two rivers of Bhutan, Thumpu Chu, and Paro Chu. Reach Thimpu in the evening. On arrival check in at the hotel. In the evening, visit the Tashichho Dzong (Fortress of the Glorious Religion). Overnight stay at hotel in Thimphu.
                          </p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay2ImageURL} alt="..." /></center>
                          </div>

                          <div className="content-mobile2">
                            <center><img src={navDay2ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                          <br />
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="day3"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseThree"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          DAY 03: THIMPHU LOCAL SIGHTSEEING
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseThree"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingThree"
                      >
                        <div className="accordion-body">
                          <p>This is the third day of this mesmerising <strong>Bagdogra to Bhutan Trip</strong>. After breakfast, the day is dedicated to sightseeing in Thimphu. You will see the following tourist attractions in Thimphu including<br /><br />
                            <ul>
                              <li>&nbsp;• Kuenselphodrang</li>
                              <li>&nbsp;•  National Memorial Chorten</li>
                              <li>&nbsp;•  Folk Heritage Museum</li>
                              <li>&nbsp;•  Typical Bhutanese lunch at the Folk Heritage Restaurant would be a great experience</li>
                              <li>&nbsp;•  Sangaygang view point</li>
                              <li>&nbsp;•  Takin Reserve Centre</li>
                              <li>&nbsp;•  Changangkha Monastery</li>
                              <li>&nbsp;•  Tashichho Dzong (Fortress of the Glorious Religion)</li>
                            </ul>
                          </p>
                          <p>
                            Dinner and Overnight Stay at Thimphu Hotel.
                          </p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay3ImageURL} alt="..." /></center>
                          </div>

                          <div className="content-mobile2">
                            <center><img src={navDay3ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                          <br />
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="day4"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseFour"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          Day 04: - THIMPHU TO PUNAKHA TRANSFER VIA DOCHULA PASS
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseFour"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingFour"
                      >
                        <div className="accordion-body">
                          <p>After breakfast, you will be taken on a three-hour road trip from Thimphu to Punakha / Wangdue Phodrang, which is located across the famous Dochu La Pass. During the road trip, you will pass through the Dochula, where you will spot the mani wall, chorten, prayer flags, and others. This is the country’s highest road. You can even spot the peaks of surrounding mountains if the sky is clear. The top peaks to spot during your drive are Masagang, Tsendagang, Terigang, Kangphugang, Table Mountain, and others. This includes the Gangkhar Puensum Peak, which is the highest in Bhutan. On arrival Punakha, check into the Hotel. Post Lunch, You will see the following tourist sites in Punakha / Wangdue including</p>
                          <ul>
                            <li>&nbsp;• Punakha Dzong</li>
                            <li>&nbsp;• Chimi Lhakhang</li>
                            <li>&nbsp;• Punakha Suspension Bridge</li>
                            <li>&nbsp;• Punakha Village</li>
                          </ul>
                          <br />
                          <p>Overnight Stay in Punakha / Wangdue</p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay4ImageURL} alt="..." /></center>
                          </div>

                          <div className="content-mobile2">
                            <center><img src={navDay4ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                          <br />
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="day5"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseFive"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          DAY 05: PUNAKHA / WANGDUE TO PARO
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseFive"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingFive"
                      >
                        <div className="accordion-body">
                          <p>
                            After breakfast, you will leave Punakha / Wangdue and drive to Paro via Dochu La Pass again, covering Paro Chuu river valley and Wang Chhu river valley. On the way, you will be visiting Simtokha Dzong, an old fortress which is now a school for Dzongkha language. Once you reach Paro, you will be checked into a hotel. Later, you will be taken on a sightseeing tour covering important destinations of Paro
                            <br />
                            <br />
                            • Ta Dzong
                            <br />
                            • Rinpung Dzong
                            <br />
                            • Tamchog lhakhang
                            <br />
                          </p>
                          <p>
                            Overnight at the hotel in Paro
                          </p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay5ImageURL} alt="..." /></center>
                          </div>

                          <div className="content-mobile2">
                            <center><img src={navDay5ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                          <br />
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="day6"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseSix"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          DAY 06: PARO LOCAL SIGHTSEEING & TAKTSHANG MONASTERY HIKING
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseSix"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingSix"
                      >
                        <div className="accordion-body">
                          <p>
                            Today is the most important day of this wonderful <strong>Bhutan package from Bagdogra Airport</strong>. After early morning breakfast, you will be starting an excursion tour to Taktshang Monastery. Taktshang Palphug Monastery also known as Tiger's Nest is an amazing Buddhist monastery, temple and sacred site is located in the upper Paro town. Built in 1692, Padmasambhava is believed to have meditated at this site for 3 years. Guru Padmasambhava is known to be the founder of Buddhist religion in Bhutan. He is immensely respected and has a huge number of sacred followers in Bhutan.<br />
                            During your trek, enjoy the breath-taking view of Paro town - lush green valleys, a sparkling river, clear blue skies, and drifting clouds nature at its finest. Spend the night in Paro to fully savor this experience. Overnight at the Paro.

                          </p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay6ImageURL} alt="..." /></center>
                          </div>

                          <div className="content-mobile2">
                            <center><img src={navDay6ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                          <br />
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="day7"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseSeven"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          DAY 07: PARO TO LATAGURI TRANSFER
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseSeven"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingSeven"
                      >
                        <div className="accordion-body">
                          <p>
                            After breakfast check out from the hotel and transfer to Jaldapara. On arrival check in at the hotel. Afternoon Lataguri Jeep Safari (On a Direct Payment Basis). Overnight stay at Lataguri.
                          </p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay7ImageURL} alt="..." /></center>
                          </div>

                          <div className="content-mobile2">
                            <center><img src={navDay7ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                          <br />
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="day8"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseTen"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          DAY 08:LATAGURI TO BAGDOGRA AIRPORT
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseTen"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingTen"
                      >
                        <div className="accordion-body">
                          <p>
                            After breakfast check out from the hotel and take the departure transfer to Bagdogra Airport for your onward journey.
                          </p>
                          {/* <br />
                                            <div className="content-desktop">
                                                <center><img src={navDay8ImageURL} alt="..." /></center>
                                            </div>

                                            <div className="content-mobile2">
                                                <center><img src={navDay8ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                            </div>
                                            <br /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>SOME IMPORTANT FACTS ABOUT THE TIGER'S NEST MONASTERY (PARO TAKTSANG)</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>SOME IMPORTANT FACTS ABOUT THE TIGER'S NEST MONASTERY (PARO TAKTSANG)</h4>
                </div>
                <div className="card-body">
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">

                      <tbody>
                        <tr>
                          <td>Location</td>
                          <td>Approx 11.7 km from Paro town, Bhutan</td>
                        </tr>
                        <tr>
                          <td>Elevation</td>
                          <td>3,120 meters (10,236ft) above sea level</td>
                        </tr>
                        <tr>
                          <td>Duration of the Tiger's Nest hike</td>
                          <td>5-6 hours (Back and forth)</td>
                        </tr>
                        <tr>
                          <td>Tiger's Nest Trekking Distance</td>
                          <td>4 Miles (6.43 Kilometers) - Round Trip</td>
                        </tr>
                        <tr>
                          <td>Tiger's Nest Trek Difficulty</td>
                          <td>Moderate - Steep Ascend</td>
                        </tr>
                        <tr>
                          <td>Best Months to Visit Tiger's Nest Monastery</td>
                          <td>March, April, May and September, October, November, December</td>
                        </tr>
                        <tr>
                          <td>Gears needed to hike Tiger's Nest</td>
                          <td>Normal hiking shoes, Water bottle, Some snacks, energy bars, Camera, Hat, Sunglasses, Sunscreen, Walking Pole/Stick</td>
                        </tr>
                        <tr>
                          <td>Opening time of Tiger's Nest Monastery</td>
                          <td>(October - March) 8am to 1pm and 2pm to 5pm
                            (April - September) 8am to 1pm and 2pm to 6pm daily
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br /><br />
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">{<InclusionExclusion />}</div>
                </div>
              </div>
              <br />
              <br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>TOP ACTIVITIES TO ENJOY DURING YOUR <strong>BHUTAN TOUR FROM BANGALORE</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>TOP ACTIVITIES TO ENJOY DURING YOUR <strong>BHUTAN TOUR FROM BANGALORE</strong></h4>
                </div>
                <div className="card-body">
                  <li>Hiking to Tiger's Nest Monastery, Paro</li><br />
                  <li>River rafting on Punakha Chu River, Punakha</li><br />
                  <li>Visit to Trashi Chhoe Dzong, Thimphu</li><br />
                  <li>Visit to the Takin Preserve, Thimphu</li><br />
                  <li>Visit to Buddha Point, Thimphu</li><br />
                  <li>Visit to Punakha Dzong, Punakha</li><br />
                  <li>Explore Phobjikha Valley (Gangtey), Phobjikha</li><br />
                  <li>Enjoy lunch at Simply Bhutan Restaurant, Thimphu</li><br />
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>TIPS TO KEEP IN MIND FOR YOUR <strong>BHUTAN TOUR PACKAGE FROM BANGALORE</strong> WITH FLIGHT</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>TIPS TO KEEP IN MIND FOR YOUR <strong>BHUTAN TOUR PACKAGE FROM BANGALORE</strong> WITH FLIGHT</h4>
                </div>
                <div className="card-body">
                  <li>Travelers planning a <strong>Bhutan Tour from Bangalore</strong> must have a valid Passport or Voter ID Card.</li><br />
                  <li>Bhutan is a landlocked country, accessible by either flight or road.</li><br />
                  <li>Bhutan has one international airport, Paro [PBH], just a 1-hour drive from Thimphu, the capital city.</li><br />
                  <li>Bhutanese cuisine is spicy, with chilies present in almost every dish. If you enjoy spicy food, Bhutan is the perfect place for you to book a package from Bangalore.</li><br />
                  <li>Rice in Bhutan is red and holds significant cultural value, rich in fiber and minerals like potassium, magnesium, and iron.</li><br />
                  <li>Hiking is allowed in Bhutan, but mountaineering is prohibited due to the sacred nature of the mountains.</li><br />
                  <li>Bhutan is a smoke-free nation; visitors must bring their own cigars and can only smoke in private. Bringing up to 200 cigarettes is allowed.</li><br />
                  <li>Weeds grow naturally in Bhutan, but possession and use are strictly prohibited, punishable by up to 5 years imprisonment.</li><br />
                  <li>Archery, known as Dha, is Bhutan's national sport. Tourists can try their hand at archery at designated grounds with assistance from their Bhutan tour agent.</li><br />
                  <li>Bhutan is famous for vibrant cultural festivals like Thimphu Tshechu, Jambay Lhakhang Drup, and Paro Tshechu. Visiting during these festivals offers a glimpse into Bhutanese culture.</li><br />
                  <li>When booking a luxury 5-star Bhutan package tour from Bangalore with Adorable Vacation, payments can be made in USD or Indian Rupees (₹). Note that only ₹100 and ₹50 denominations are accepted in Bhutan; ₹2000 and ₹500 notes are not accepted.</li><br />
                  <li>Bhutanese people deeply respect their King and Royal family, regarding them as incarnations of gods. Visitors should show respect when visiting Dzongs, temples, and historical sites.</li><br />
                  <li>When visiting temples, monasteries, and historical places in Bhutan, it is important to dress modestly and respectfully.</li><br />
                </div>
              </div>
              <br /><br />
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h2>
                        Frequently Ask <span>Questions</span>
                      </h2>
                      <h4><strong>BHUTAN TOUR PACKAGE FROM BANGALORE</strong> <b>BY FLIGHT</b></h4>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div
                    className="accordion accordion-flush faQStyle"
                    id="accordionFlushExample"
                  >
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="question1">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseOne"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseOne"
                        >
                          1. Can I get AC Rooms in Bhutan Group Tour?
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingOne"
                      >
                        <div className="accordion-body">
                          <p>Bhutan generally has a cold climate except in places like Punakha. In Thimphu, Paro, and some hotels in Punakha, you can find rooms with AC facilities. During July and August, AC may be needed in Thimphu and Paro, and even in April and May for Punakha. It's best to discuss AC facilities with Adorable Vacation Bhutan experts before booking your <strong>Bhutan Tour from Bangalore</strong>.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="question2">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseTwo"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseTwo"
                        >
                          2. What kind of cars are available for a group of 6 on a <strong>Bhutan Tour Package from Bangalore</strong>?
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseTwo"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingTwo"
                      >
                        <div className="accordion-body">
                          <p>For a comfortable trip starting from Paro Airport, consider Toyota Hiace, known for its spaciousness and comfort. For larger groups of 8 to 12 travelers, Toyota Coaster cars are available. At Adorable Vacation, guest comfort during travel is a top priority.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="question3">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseThree"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          3. Do Indians need a visa to enter Bhutan Tour?
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseThree"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingThree"
                      >
                        <div className="accordion-body">
                          <p>Indian passport holders do not need a visa for Bhutan. However, an entry permit is required, which can be processed on arrival at Paro Airport or in advance through a local travel agent like Adorable Vacation Bhutan.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="question4">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseFour"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseFour"
                        >
                          4. Where can I take photos in Bhutanese dress?
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseFour"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingFour"
                      >
                        <div className="accordion-body">
                          <p>In Paro, outside Paro Dzong, you can take photos in Bhutanese dresses. For women, it's called Kira & Tego, and for men, it's called Goh. Dressing like Bhutanese locals against the backdrop of beautiful landscapes makes for a memorable experience.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="question5">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseFive"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseFive"
                        >
                          5. Are there wheelchair-friendly hotels in Bhutan Trip from Bangalore?
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseFive"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingFive"
                      >
                        <div className="accordion-body">
                          <p>Most hotels in Bhutan are not wheelchair-friendly, but Adorable Vacation has assisted many wheelchair travelers. Contact the Adorable vacation team for references from guests who have traveled to Bhutan with a wheelchair.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="question6">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseSix"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseSix"
                        >
                          6. What is Sustainable Development Fee (SDF) and how does it impact Indian tourists?
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseSix"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingSix"
                      >
                        <div className="accordion-body">
                          <p>The SDF is Rs. 1200 per person per night for Indian (regional) tourists, excluding tour package costs (flight, hotels, meals, guides, transportation, and monument fees). This fee aims to promote quality tourism in Bhutan.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="question7">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseSeven"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseSeven"
                        >
                          7. How is the climate and weather in Bhutan Sightseeing Tour?
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseSeven"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingSeven"
                      >
                        <div className="accordion-body">
                          <p>Bhutan's climate is generally cold in tourist areas, even in summer. Thimphu, Paro, Phobjikha, and Bumthang have pleasant summers with light woolens needed during the day. Winters are cold with occasional snow in January and February. Rainfall mainly occurs at night during the rainy season (typically July and August).</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="question8">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseEight"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseEight"
                        >
                          8. What is the luggage weight limit on Druk Air?
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseEight"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingEight"
                      >
                        <div className="accordion-body">
                          <p>Druk Air allows 20 kg in economy class and 30 kg in business class. An additional 5 kg is permitted for overhead bins or under the passenger's seat.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="question9">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseNine"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseNine"
                        >
                          9. What is the currency of Bhutan?
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseNine"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingNine"
                      >
                        <div className="accordion-body">
                          <p>The official currency of Bhutan is Ngultrum (Nu, BTN), where 1 Ngultrum equals 1 Indian Rupee.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="question10">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseTen"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseTen"
                        >
                          10. What is the National Game of Bhutan?
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseTen"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingTen"
                      >
                        <div className="accordion-body">
                          <p>Archery is the National Game of Bhutan. You can try your archery skills with bamboo bows in many places during your <strong>Bhutan tour from Bangalore</strong>.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="question11">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseEleven"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseEleven"
                        >
                          11. What is the National Bird of Bhutan?
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseEleven"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingEleven"
                      >
                        <div className="accordion-body">
                          <p>The Raven is the national bird of Bhutan and holds significance in Bhutanese culture and the Royal Crown.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="question12">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseTwelve"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseTwelve"
                        >
                          12. What is the National Flower of Bhutan?
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseTwelve"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingTwelve"
                      >
                        <div className="accordion-body">
                          <p>The Blue Poppy (Meconopsis) is Bhutan's national flower, found in high-altitude regions in various colors like red, pink, and white.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="question13">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseThirteen"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThirteen"
                        >
                          13. What is the language of Bhutan?
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseThirteen"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingThirteen"
                      >
                        <div className="accordion-body">
                          <p>Dzongkha is Bhutan's national language. Over two dozen languages are spoken in Bhutan, with Nepali being one of the major languages.</p>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h3>
                        <b>
                          Bhutan <span className="tomato">Tour Reviews</span>
                        </b>
                      </h3>
                      <h4>
                        <i>"Your Experience is our Marketing"</i>
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="row row-cols-1 ">
                  <div id="testimonials">
                    <div className="testimonial-box-container">
                      {reviewersDataView}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <BookingBox />
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default BangaloreToBhutanPackage;
