import React, { Component } from 'react';

class InclusionExclusion extends Component {
    render() {
        return (
            <>
                <table className="table-borderDodont">
                    <thead>
                        <tr>
                            <td className="tomato"><strong>BHUTAN TOUR PACKAGE INCLUSION</strong></td>
                            <td className="tomato"><strong>BHUTAN PACKAGE TOUR EXCLUSION</strong></td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>Adorable Welcome on
                                arrival.</td>
                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>Extras of a personal
                                nature include personal tips, laundry charges, and additional tour charges.</td>
                        </tr>
                        <tr>
                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>Transfer all on a
                                private basis.</td>
                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>Travel Insurance</td>
                        </tr>
                        <tr>
                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>Sightseeing as per the
                                itinerary point-to-a-point basis on Private Exclusive Mode.</td>
                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>Medical &
                                Emergency rescue costs.</td>
                        </tr>
                        <tr>
                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>Airport Transfers.</td>
                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>Does not include Hard
                                drinks liquor or soft drinks/juices.</td>
                        </tr>
                        <tr>
                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>Breakfast & Dinner meal
                                (Veg, Non Veg). Jain meal option available.</td>
                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>Does not include Entrance
                                Fees at Monasteries / Monuments /Museums etc.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>One bottle of Mineral
                                Water per head per day during sightseeing</td>
                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>Air Fare / Train Tickets.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>Professional
                                English-Speaking Guide.</td>
                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>Additional sightseeing or
                                extra usage of vehicles, other than those mentioned in the itinerary.</td>
                        </tr>
                        <tr>
                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>One tourist SIM card on
                                Arrival.</td>
                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>Any cost arising due to
                                natural calamities like landslides, road blockage,
                                political disturbances (strikes),etc (to be borne by the client, which is directly payable on the
                                spot).</td>
                        </tr>
                        <tr>
                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>SDF Per person per night - per head - Rs. 1200 || (5 &gt; &lt; 11 Years child SDF per day per night 600/-).</td>
                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>Any increase in taxes or
                                fuel price, leading to an increase in cost on surface
                                transportation & land arrangements, which may come into effect prior to departure.</td>
                        </tr>
                        <tr>
                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>24x7 Operation
                                Executive Support.</td>
                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>Does not include any
                                Lunch & Evening Tea/snacks.</td>
                        </tr>
                    </tbody>
                </table>
            </>
        );
    }
}

export default InclusionExclusion;