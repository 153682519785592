import React, { Component } from 'react';
import MainHeader from '../../../components/common/MainHeader';
import FooterDesktop from '../../../components/common/FooterDesktop_kashmir';
import Kashmir_8N_9D_Doodhpathri_Yusmarg_Package from '../../../components/package/Kashmir/Kashmir_8N_9D_Doodhpathri_Yusmarg_Package';
import { Helmet } from "react-helmet";

class Kashmir_8N_9D_Doodhpathri_Yusmarg_Page extends Component {
    constructor() {
        super();
        this.state = {
            TrendingPackagesData: [],
            ReviewersData: [],
        }
    }

    componentDidMount() {
        window.scroll(0, 0);
        const TrendingPackageDataList = [
            {
                Title: "Jannat-E-Kashmir",
                ImageName: "Kashmir-Package-Cost.webp",
                AllternateText: "...",
                Description: "3N Srinagar   |   Pahalgam |   Gulmarg |   Private Transfer   |   3 Star Hotel  |   Stay in House Boat  |   Shikara Ride",
                PricePerNight: "11,699",
                NoOfTours: 47,
                NoOfDeparture: 40,
                Include: "EX-SXR",
                MutedText: "*Per head / Min 6 Pax",
                Url: 'tel://9147061467'
            },
            {
                Title: "Peaceful Kashmir",
                ImageName: "Kashmir-Family-Tour-Plans.webp",
                AllternateText: "...",
                Description: "3N Srinagar | Sonmarg | Gulmarg | 1N Pahalgam | Private Transfer | 3 Star Hotel | Stay in House Boat | Shikara Ride",
                PricePerNight: "15,499",
                NoOfTours: 43,
                NoOfDeparture: 38,
                Include: "EX-SXR",
                MutedText: "*Per head / Min 6 Pax",
                Url: 'tel://9147061467'
            },
            {
                Title: "Charming Kashmir",
                ImageName: "Kashmir-Tour-Package-kolkata.webp",
                AllternateText: "...",
                Description: "4N Srinagar | Sonmarg | Gulmarg | 1N Pahalgam | Private Transfer | 3 Star Hotel | Stay in House Boat | Shikara Ride",
                PricePerNight: "18,499",
                NoOfTours: 44,
                NoOfDeparture: 37,
                Include: "EX-SXR",
                MutedText: "*Per head / Min 6 Pax",
                Url: 'tel://9147061467'
            },
            {
                Title: "Sparkling Kashmir",
                ImageName: "Kashmir-Honeymoon-Package.webp",
                AllternateText: "...",
                Description: "4N Srinagar | Sonmarg | 1N Gulmarg | 2N Pahalgam | Private Transfer | 3 Star Hotel | Stay in House Boat | Shikara Ride",
                PricePerNight: "26,499",
                NoOfTours: 46,
                NoOfDeparture: 39,
                Include: "EX-SXR",
                MutedText: "*Per head / Min 6 Pax",
                Url: 'tel://9147061467'
            }
        ];

        const reviewersDataList = [
            {
                Name: "Ajoy Mukherjee",
                ImageName: "Ajoy.jpeg",
                AllternateText: "...",
                Location: "@Kolkata",
                Comment: "Adorable Vacation exceeded my expectations with their Kashmir travel package! From seamless transportation to luxurious accommodations, every detail was impeccable. The guided tours to iconic destinations like Dal Lake and Gulmarg were informative and enjoyable. The local cuisine experiences and friendly staff added a personal touch to the trip. Highly recommend Adorable Vacation for an unforgettable Kashmir experience!",
            },
            {
                Name: "Rakesh Pandey",
                ImageName: "rakesh.jpeg",
                AllternateText: "...",
                Location: "@Mumbai",
                Comment: "I recently had an incredible Kashmir trip with Adorable Vacation and couldn't be happier! Akash, our point of contact, was exceptional - knowledgeable, friendly, and always ensuring our comfort. The itinerary was well-planned, covering stunning destinations like Srinagar and Gulmarg. The accommodations were top-notch, and the local cuisine was a delight. Highly recommend Adorable Vacation for a memorable Kashmir experience!",
            },
            {
                Name: "Suchismita Thakur",
                ImageName: "suchismita.jpeg",
                AllternateText: "...",
                Location: "@Delhi",
                Comment: "Thank you team for making Kashmir trip so memorabe.Looking forward to having this incredible team especially Soumalya Da and Soumya Da by our side on many more future trips.We all are very happy for the warm way this trip was carried. Met so many amazing people in this journey.The stay, the food, the journey everything was wonderful! Words are less to express my happiness. Highly recommend everyone to connect with Adorable Vacation. Looking forward for our next trip with them.",
            },
            {
                Name: "Mohitosh Sankar",
                ImageName: "mohitosh.jpeg",
                AllternateText: "...",
                Location: "@Bangalore",
                Comment: "An unforgettable journey with Adorable Vacation in Kashmir! Soumya's expertise ensured a seamless experience. From mesmerizing houseboats in Dal Lake to thrilling Gulmarg adventures, every moment was magical. The personalized service, knowledgeable guides, and top-notch accommodations exceeded expectations. Highly recommend Adorable Vacation for an authentic and delightful Kashmir experience!",
            },
        ]

        this.setState({
            TrendingPackagesData: TrendingPackageDataList,
            ReviewersData: reviewersDataList
        })
    }

    render() {
        return (
            <>
                
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Kashmir Tour Packages with Doodhpathri Yusmarg, Kashmir Packages Tour with Doodhpathri Yusmarg</title>
                    <meta name="description" content="We Offer the Best Kashmir Packages Tour with Doodhpathri Yusmarg. For Booking ☎ CALL +91-91470-61467." />
                </Helmet>
                <MainHeader />
                <Kashmir_8N_9D_Doodhpathri_Yusmarg_Package
                    TrendingPackagesData={this.state.TrendingPackagesData}
                    ReviewersData={this.state.ReviewersData}
                />
                <FooterDesktop />
            </>
        );
    }
}

export default Kashmir_8N_9D_Doodhpathri_Yusmarg_Page;