import React, { Component } from 'react';
import MainHeader from '../../../components/common/MainHeader';
import FooterDesktop from '../../../components/common/FooterDesktop_kashmir';
import Kashmir_Leh_Ladakh_Tour_Package from '../../../components/package/Kashmir/Kashmir_Leh_Ladakh_Tour_Package';
import { Helmet } from "react-helmet";
class Kashmir_Leh_Ladakh_Tour_Page extends Component {
    constructor() {
        super();
        this.state = {
            TrendingPackagesData: [],
            ReviewersData: [],
        }
    }

    componentDidMount() {
        window.scroll(0, 0);
        const TrendingPackageDataList = [
            {
                Title: "Jannat-E-Kashmir",
                ImageName: "Kashmir-Package-Cost.webp",
                AllternateText: "...",
                Description:"3N Srinagar   |   Pahalgam |   Gulmarg |   Private Transfer   |   3 Star Hotel  |   Stay in House Boat  |   Shikara Ride",
                PricePerNight: "11,699",
                NoOfTours: 47,
                NoOfDeparture: 40,
                Include: "EX-SXR",
                MutedText: "*Per head / Min 6 Pax",
                Url: 'tel://9147061467'
            },
            {
                Title: "Peaceful Kashmir",
                ImageName: "Kashmir-Family-Tour-Plans.webp",
                AllternateText: "...",
                Description: "3N Srinagar | Sonmarg | Gulmarg | 1N Pahalgam | Private Transfer | 3 Star Hotel | Stay in House Boat | Shikara Ride",
                PricePerNight: "15,499",
                NoOfTours: 43,
                NoOfDeparture: 38,
                Include: "EX-SXR",
                MutedText: "*Per head / Min 6 Pax",
                Url: 'tel://9147061467'
            },
            {
                Title: "Charming Kashmir",
                ImageName: "Kashmir-Tour-Package-kolkata.webp",
                AllternateText: "...",
                Description: "4N Srinagar | Sonmarg | Gulmarg | 1N Pahalgam | Private Transfer | 3 Star Hotel | Stay in House Boat | Shikara Ride",
                PricePerNight: "18,499",
                NoOfTours: 44,
                NoOfDeparture: 37,
                Include: "EX-SXR",
                MutedText: "*Per head / Min 6 Pax",
                Url: 'tel://9147061467'
            },
            {
                Title: "Sparkling Kashmir",
                ImageName: "Kashmir-Honeymoon-Package.webp",
                AllternateText: "...",
                Description: "4N Srinagar | Sonmarg | 1N Gulmarg | 2N Pahalgam | Private Transfer | 3 Star Hotel | Stay in House Boat | Shikara Ride",
                PricePerNight: "26,499",
                NoOfTours: 46,
                NoOfDeparture: 39,
                Include: "EX-SXR",
                MutedText: "*Per head / Min 6 Pax",
                Url: 'tel://9147061467'
            }
        ];

        const reviewersDataList = [
            {
                Name: "Mr. Ankit Shangbag",
                ImageName: "Ankit-Shanbag.webp",
                AllternateText: "...",
                Location: "@Mumbai",
                Comment: "Exceptional experience with the Adorable Vacation! Special thanks to Mr. Akash Dutta (Renowned Partner of Adorable Group)  for complete arrangements and planning. Our trip to Kashmir Kargil Leh was nothing short of magical, thanks to their meticulous planning and seamless execution. From breathtaking landscapes to cozy accommodations, every detail was well-curated, ensuring a wonderful and memorable journey. Highly recommend their services for a hassle-free and delightful travel experience.",
            },
            {
                Name: "Mrs. Naina Shah",
                ImageName: "Naina-Shah.webp",
                AllternateText: "...",
                Location: "@Mumbai",
                Comment: "I recently completed 10D/9N tour to Ladakh & Kashmir with Adorable Vacation which covered Srinagar, Pahalgam, Sonmarg, Kargil, Nubra, Leh & Pangong. The tour was perfectly planned by Mr. Soumya from Adorable. We were 4 members and it was awesome trip.The selection of Hotels, Cab and Driver was superb. We enjoyed sightseeing and various adventure activities.Thank you so much Team Adorable for such nice arrangement. We will surely suggest to our friends and relatives about Adorable Vacation LLP",
            },
            {
                Name: "Mr. Asutosh Sankar",
                ImageName: "Asutosh-Sankar.webp",
                AllternateText: "...",
                Location: "@Bangalore",
                Comment: "Recently enjoyed a 9-night, 10-day honeymoon trip to Leh Kashmir Tour, organized by Adorable Vacation. Our organizer Mr. Akash Dutta and the team did an excellent job with flawless planning, including hotel selection, cab arrangements, and a fantastic driver. We appreciate the superb service and want to express our gratitude to Akash & Soumya and the entire Adorable Vacation team for their excellent arrangements.",
            },
            {
                Name: "Mr. Sankar S Nadiawala",
                ImageName: "Sankar-S-Nadiawala.webp",
                AllternateText: "...",
                Location: "@Bangalore",
                Comment: "Lovely and memorable experience of Heaven on Earth – Jammu-Kashmir & Cold Dessert - Ladakh, thanks to Mr. Akash from Adorable Vacation LLP who helped us to choose the right package and from our starting to end of the trip provided full support. Had a wonderful time in Srinagar & Leh, all things were as mentioned in itinerary. we were very happy with luxury hotel like Khyber (5* Heritage Brand) and driver (Mr.Manoj ji) was so helpful, He knows all the roads very well and driving safely throughout the journey. Thanks again for making this trip such a wonderful experience.",
            },
            {
                Name: "Dr. Pinky Talwar",
                ImageName: "Pinky-Talwar.webp",
                AllternateText: "...",
                Location: "@Chennai",
                Comment: "I booked my Kashmir Ladakh Luxury trip with Adorable Vacation LLP, that was too much good. Special thanks to Mr. Soumya Sankar for complete and timely arrangements. The selection of hotels and cab was 👌🏽 super. That was lovely experience of jannat-e-Kashmir. Thank you so much entire Adorable group and for such nice arrangement",
            },
            {
                Name: "Mr.Kailash Mamajiwala",
                ImageName: "Kailash-Mamajiwala.webp",
                AllternateText: "...",
                Location: "@Ahmedabad",
                Comment: "I had one of the best moments in my life with the help of Adorable Vacation trip advisor Mr. Soumya really a fantastic journey was arranged by him he had planned very well time saving and especially the driver cum guide ram Babu helped us so much on many places Adorable team such a wonderful and kind persons once again thanks to the Mr.Soumalya and team never ever forget my experience thanks to all I felt satisfied.",
            },
        ]

        this.setState({
            TrendingPackagesData: TrendingPackageDataList,
            ReviewersData: reviewersDataList
        })
    }

    render() {
        return (
            <>
             <Helmet>
                    <title>Kashmir Leh Ladakh Tour Package 9N 10D - Book Now. 30% Off</title>
                    <meta name="description" content="Kashmir Ladakh Package Tour Plan – Explore the amazing beauty of Kashmir coupled with Leh Ladakh Package in 10 Days Complete Itinerary. Adorable Vacation LLP." />
                </Helmet>
                <MainHeader />
                <Kashmir_Leh_Ladakh_Tour_Package
                    TrendingPackagesData={this.state.TrendingPackagesData}
                    ReviewersData={this.state.ReviewersData}
                />
                <FooterDesktop />
            </>
        );
    }
}

export default Kashmir_Leh_Ladakh_Tour_Page;