import React, { Component } from 'react';

class InclusionExclusion_group extends Component {
    render() {
        return (
            <>
                <h4 className='tomato'><u>INCLUSIONS : <strong>KASHMIR PACKAGE TOUR WITH VAISHNO DEVI</strong></u></h4>
                <ul>
                <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Accommodation for 7 Nights
                    </li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Meal plan MAP (Breakfast and Dinner)
                    </li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;All transfer and sightseeing by Non-AC Cars</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Shikara Ride on Dal Lake for 1 Hour</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Vehicle will be provided on point to point basis and not at disposal</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Inner line permits to visit restricted / protected areas</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Above rates include all hotel taxes, toll taxes and vat</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Aru, Betaab valley and Chandanwari trip at Pahalgam</li>
                </ul><br/><br/>
                <h4 className='tomato'><u>EXCLUSIONS : <strong>KASHMIR TOUR PACKAGES WITH VAISHNO DEVI</strong></u></h4>
                <ul>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Air Tickets / Train Tickets</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Pony Ride/Sledging (Procure by Bargaining on spot)</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Tips to waiters/drivers/any other person</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Monument fees at all places and gardens</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Non-Veg item in Houseboat</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Any other expenses which is not mentioned in inclusions</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;<strong>Kashmir Tour Itinerary</strong> can be changed due to availability of Hotels / Houseboat / Weather Condition</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;<strong>All personal nature like video camera, tip, table drink, etc and any other</strong></li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;<strong>Thajiwas glacier / Baltal trip at Sonmarg</strong></li>
                </ul>
            </>
        );
    }
}

export default InclusionExclusion_group;