import React, { Component } from 'react';
import BookingBox from '../BookingBox';
import Slider from "react-slick";
import ApiURL from "../../../api/ApiURL";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InclusionExclusion from '../Bhutan/InclusionExclusion';

class FIFO_8N_9D_Package extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customActiveTab: 'tab1',
            daywiseActiveTab: 'day1'
        }
    }

    handleCustomTabClick = (tab) => {
        this.setState({
            customActiveTab: tab,
        });
    };

    handleDayWiseTabClick = (tab) => {
        this.setState({
            daywiseActiveTab: tab,
        });
    };

    render() {
        const { customActiveTab, daywiseActiveTab } = this.state;
        const packageTopBannerImageURL = ApiURL.FIFO_8N_9D_ImageURL + "/heder-image.webp";
        const navDay1ImageURL = ApiURL.FIFO_8N_9D_ImageURL + "/day1.webp";
        const navDay2ImageURL = ApiURL.FIFO_8N_9D_ImageURL + "/day2.webp";
        const navDay3ImageURL = ApiURL.FIFO_8N_9D_ImageURL + "/day3.webp";
        const navDay4ImageURL = ApiURL.FIFO_8N_9D_ImageURL + "/day4.webp";
        const navDay5ImageURL = ApiURL.FIFO_8N_9D_ImageURL + "/day5.webp";
        const navDay6ImageURL = ApiURL.FIFO_8N_9D_ImageURL + "/day6.webp";
        const navDay7ImageURL = ApiURL.FIFO_8N_9D_ImageURL + "/day7.webp";
        const navDay8ImageURL = ApiURL.FIFO_8N_9D_ImageURL + "/day8.webp";
        const navDay9ImageURL = ApiURL.FIFO_8N_9D_ImageURL + "/day9.webp";


        const hotPackagesDataList = this.props.HotPackagesData;
        console.log(hotPackagesDataList);
        const hotPackagesView = hotPackagesDataList.length > 0 ?
            (
                hotPackagesDataList.map((dataItem, i) => {
                    const hotelImgURL = ApiURL.FIFO_8N_9D_ImageURL + "/" + dataItem.ImageName;
                    return (<div className="col-md-12 p-0" key={i}>
                        <div className="card h-100">
                            <img src={hotelImgURL} className="card-img-top" alt={dataItem.AllternateText} />
                            <div className="card-body">
                                <h6 className="card-title">{dataItem.Title} <span> &#8377; {dataItem.PricePerNight}</span></h6>
                                <p className="card-text">
                                    <i className="fa fa-building tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-utensils tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-car-side tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-male tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-camera tomato"></i>
                                </p>
                                <p className="card-text"><span>{dataItem.Description}</span></p>
                                <p>
                                    <span><b className="tomato">{dataItem.NoOfTours} </b>Tours</span> | &nbsp;
                                    <span><b className="tomato">{dataItem.NoOfDeparture} </b>Departures</span> | &nbsp;
                                    <span><b className="sdf-blue">{dataItem.SDFInclude}</b></span>
                                </p>
                            </div>
                            <div className="card-footer">
                                <small className="text-muted"><strong className="tomato">{dataItem.MutedText}</strong></small>
                                <button type="button" className="btn btn-Warning btn-sm float-end">Book Now</button>
                            </div>
                        </div>
                    </div>)
                })
            ) : (<div></div>)


        const settings = {
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const reviewersDataList = this.props.ReviewersData;
        const reviewersDataView = reviewersDataList.map((dataItem, i) => {
            const reviewerImageURL = ApiURL.FIFO_8N_9D_ImageURL + "/" + dataItem.ImageName;
            return (
                <div className="testimonial-box" key={i}>
                    <div className="box-top">
                        <div className="profile">
                            <div className="profile-img">
                                <img src={reviewerImageURL} alt="..." />
                            </div>
                            <div className="name-user">
                                <strong>{dataItem.Name}</strong>
                                <span>{dataItem.Location}</span>
                            </div>
                        </div>
                        <div className="reviews">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </div>
                    </div>
                    <div className="client-comment">
                        <p>{dataItem.Comment}</p>
                    </div>
                </div>
            )
        })

        return (
            <>
                <div className="container-fluid">
                    <div className="row">
                        <img src={packageTopBannerImageURL} className="img-fluid px-0" alt="..." />
                    </div>
                </div>
                <div className="container-fluid py-3">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="container-fluid py-1">

                                        <h5 className="tomato">Breathtaking Bhutan 8N/9D Tour Package With Flight</h5>
                                        <hr />

                                        <div className="col-12">
                                            <big className="text-muted"><strong className="tomato">8N/9D</strong></big>&nbsp;
                                            <i className="fa fa-plane-departure"></i>&nbsp;&nbsp;
                                            <i className="fa fa-arrow-right"></i>&nbsp;Thimpu(3N)&nbsp;
                                            <i className="fa fa-arrow-right"></i>&nbsp;Punakha(2N)&nbsp;
                                            <i className="fa fa-arrow-right"></i>&nbsp;Paro(3N)&nbsp;
                                            <i className="fa fa-arrow-right"></i>&nbsp;&nbsp;
                                            <i className="fa fa-plane-departure fa-flip-horizontal"></i>
                                            <br />
                                        </div>
                                        <hr />
                                    </div>

                                    <div className="container-fluid navtabCustom">
                                        <nav>
                                            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                                <a onClick={() => this.handleCustomTabClick('tab1')} className={customActiveTab === 'tab1' ? 'nav-item nav-link active' : 'nav-item nav-link'} role="tab" aria-controls="nav-highlights" aria-selected="true">Highlights</a>
                                                <a onClick={() => this.handleCustomTabClick('tab2')} className={customActiveTab === 'tab2' ? 'nav-item nav-link active' : 'nav-item nav-link'} role="tab" aria-controls="nav-flights" aria-selected="false">Flights</a>
                                                <a onClick={() => this.handleCustomTabClick('tab3')} className={customActiveTab === 'tab3' ? 'nav-item nav-link active' : 'nav-item nav-link'} role="tab" aria-controls="nav-hotel" aria-selected="false">Hotel</a>
                                                <a onClick={() => this.handleCustomTabClick('tab4')} className={customActiveTab === 'tab4' ? 'nav-item nav-link active' : 'nav-item nav-link'} role="tab" aria-controls="nav-sightseeing" aria-selected="false">Sightseeings</a>
                                                <a onClick={() => this.handleCustomTabClick('tab5')} className={customActiveTab === 'tab5' ? 'nav-item nav-link active' : 'nav-item nav-link'} role="tab" aria-controls="nav-meals" aria-selected="false">Meals</a>
                                            </div>
                                        </nav>
                                        <div className="tab-content" id="nav-tabContent">
                                            <div className={customActiveTab === 'tab1' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-highlights-tab">
                                                It can be better to book your Kolkata to Bagdogra flight. As Druk Air & Bhutan Airlines operates
                                                flight on these five days only. Asia, IndiGo, Spice Jet, Go Air and Air India operate nonstop
                                                flight to Bagdogra Airport and the time taken by the flight ranges around 1 hour 10 minutes.
                                            </div>
                                            <div className={customActiveTab === 'tab2' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-flights-tab">
                                                <span>Bhutan is the only country which measures its progress through the Gross National Happiness Index and hence, it is known as the ‘Happiest Country in the World’. Tourists from all the corners of the world visit Bhutan to explore its ancient monasteries and indulge in its adventurous trekking expeditions. Even though Bhutan tourism is flourishing by the day, the country adopts a holistic approach to protecting and preserving its natural resources, environment, culture and heritage. Want to visit this happiest country in the world? Wondering how to reach Bhutan? Here are the answers to all your questions.<br /><br />

                                                    Paro is the only international airport in Bhutan and has connecting flights from Delhi, Bagdogra, Guwahati, and Mumbai. You can take direct flights from Kolkata to Paro.  Hence, there is more frequency of flights from Kolkata to reach Bhutan. The number of flights from Delhi and Mumbai are less as compared to Kolkata and Bagdogra. Druk Air is the national airline of Bhutan and flights can be booked only through the official websites of this airline.</span>
                                                <br />
                                                <br />
                                                <div className="overflow-table">
                                                    <span>Bhutan Flight From - <b>Kolkata</b> <i>(CCU - PBH)</i></span>
                                                    <table className="table table-bordered">
                                                        <thead>
                                                            <tr className="table-danger">
                                                                <th>Airlines</th>
                                                                <th>Flight Number</th>
                                                                <th>Operating Days</th>
                                                                <th>Time</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Druk Air</td>
                                                                <td>KB 211, KB 210</td>
                                                                <td>Sat, Mon, Wed, Fri</td>
                                                                <td>9:30 - 11:30</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Bhutan Airlines</td>
                                                                <td>B3 701, B3 700</td>
                                                                <td>Mon, Tue, Wed, Fri, Sat, Sun</td>
                                                                <td>8:15 - 9:55</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <span>Bhutan Flight From - <b>Delhi</b> <i>(DEL - PBH)</i></span>
                                                    <br />
                                                    <br />
                                                    <table className="table table-bordered">
                                                        <thead>
                                                            <tr className="table-danger">
                                                                <th>Airlines</th>
                                                                <th>Flight Number</th>
                                                                <th>Operating Days</th>
                                                                <th>Time</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Druk Air</td>
                                                                <td>KB 201, KB 200</td>
                                                                <td>Mon, Tue, Wed, Fri, Sat, Sun</td>
                                                                <td>12:30 - 15:20</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Bhutan Airlines</td>
                                                                <td>B3 774, B3 773</td>
                                                                <td>Mon, Wed, Sat, Sun</td>
                                                                <td>10:55 - 14:35</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className={customActiveTab === 'tab3' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-hotel-tab">

                                                <div className="overflow-table">
                                                    <table className="table table-bordered">
                                                        <thead>
                                                            <tr className="table-primary text-center">
                                                                {/* <!-- Add the "text-center" className to center content --> */}
                                                                <th colSpan="6">HOTEL CATEGORY - 3 STAR (TCB APPROVED)</th>
                                                            </tr>
                                                            <tr className="table-danger">
                                                                <th className="text-center">Destination</th>
                                                                <th className="text-center">Hotel Names</th>
                                                                <th className="text-center">Hotel Type</th>
                                                                <th className="text-center">No of Nights</th>
                                                                <th className="text-center">Meal Plan</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Thimpu</td>
                                                                <td>Hotel Amodhara/Hotel Ugyan / Hotel Nordenma / Hotel Riverside Bhutan</td>
                                                                <td>3 Star</td>
                                                                <td>3N</td>
                                                                <td>Room with Breakfast & Dinner</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Punakha</td>
                                                                <td>Hotel Khuru Khuru / Kingaling Resort & Spa / White Dragon / Punakha Residency</td>
                                                                <td>3 Star</td>
                                                                <td>2N</td>
                                                                <td>Room with Breakfast & Dinner</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Paro</td>
                                                                <td>Namsey Chholing Resort / Tsheringma Resort / Hotel Gawaling / Hotel Centre Point</td>
                                                                <td>3 Star</td>
                                                                <td>3N</td>
                                                                <td>Room with Breakfast & Dinner</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>

                                                    <table className="table table-bordered">
                                                        <thead>
                                                            <tr className="table-primary text-center">
                                                                {/* <!-- Add the "text-center" className to center content --> */}
                                                                <th colSpan="6">PACKAGE PRICE IN 3 STAR CATEGORY HOTEL</th>
                                                            </tr>
                                                            <tr className="table-danger">
                                                                <th className="text-center">No of Pax</th>
                                                                <th className="text-center">Package Price</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td className="text-center">02 Adults</td>
                                                                <td className="text-center">65,500/- PP</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-center">
                                                                    04 Adults
                                                                </td>
                                                                <td className="text-center">
                                                                    50,600/- PP
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-center">
                                                                    06 Adults
                                                                </td>
                                                                <td className="text-center">
                                                                    45,500/- PP
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <br />

                                                </div>
                                            </div>
                                            <div className={customActiveTab === 'tab4' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-sightseeing-tab">
                                                <div className="container-fluid px-0 py-1">
                                                    <h5 className="tomato">Thimpu</h5>
                                                    <ul className="place">
                                                        <p> The National Memorial Chorten, Sakyamuni Buddha, Buddha View Point, Changangkha
                                                            Lhakhang, Takin Zoo, The National Library, Zorig Chusum, The Royal Textile Museum, Trashi
                                                            Chhoe Dzong.</p>
                                                    </ul>
                                                    <h5 className="tomato">Punakha</h5>
                                                    <ul className="place">
                                                        <p> Dochula view point stop, Dochu La pass, Punakha Valley, Punakha Dzong, Wangdue. </p>
                                                    </ul>
                                                    <h5 className="tomato">Paro</h5>
                                                    <ul className="place">
                                                        <p>Simtokha Dzong,National Museum of Bhutan,Drukgyal Dzong,Ta Dzong,Rinpung Dzong,Nya Mey
                                                            Zam Bridge,Paro Airport View Point,Kyichu Lhakhang,Taktsang Monastery.</p>
                                                    </ul>

                                                </div>
                                            </div>
                                            <div className={customActiveTab === 'tab5' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-meals-tab">
                                                <div className="overflow-table">
                                                    <h5 className="tomato">8 times Meals and 8 times Breakfast has been Provided</h5>
                                                    <table className="table table-bordered">
                                                        <thead>
                                                            <tr className="table-danger">
                                                                <th colSpan="6" className="text-center">Category</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td className="text-center">JAIN</td>
                                                                <td className="text-center">PURE-VEG</td>
                                                                <td className="text-center">VEGAN</td>
                                                                <td className="text-center">PURE-NON-VEG</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <!--By Itinerary--> */}
                            <div className="container-fluid navtabCustom">

                                <h5 className="tomato">Day wise Itinerary</h5>
                                <hr />

                                <nav>
                                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                        <a onClick={() => this.handleDayWiseTabClick('day1')} className={daywiseActiveTab === 'day1' ? 'nav-item nav-link active' : 'nav-item nav-link'} role="tab" aria-controls="nav-day-one" aria-selected="true">DAY-1</a>
                                        <a onClick={() => this.handleDayWiseTabClick('day2')} className={daywiseActiveTab === 'day2' ? 'nav-item nav-link active' : 'nav-item nav-link'} role="tab" aria-controls="nav-day-two" aria-selected="false">DAY-2</a>
                                        <a onClick={() => this.handleDayWiseTabClick('day3')} className={daywiseActiveTab === 'day3' ? 'nav-item nav-link active' : 'nav-item nav-link'} role="tab" aria-controls="nav-day-three" aria-selected="false">DAY-3</a>
                                        <a onClick={() => this.handleDayWiseTabClick('day4')} className={daywiseActiveTab === 'day4' ? 'nav-item nav-link active' : 'nav-item nav-link'} role="tab" aria-controls="nav-day-four" aria-selected="true">DAY-4</a>
                                        <a onClick={() => this.handleDayWiseTabClick('day5')} className={daywiseActiveTab === 'day5' ? 'nav-item nav-link active' : 'nav-item nav-link'} role="tab" aria-controls="nav-day-five" aria-selected="false">DAY-5</a>
                                        <a onClick={() => this.handleDayWiseTabClick('day6')} className={daywiseActiveTab === 'day6' ? 'nav-item nav-link active' : 'nav-item nav-link'} role="tab" aria-controls="nav-day-six" aria-selected="false">DAY-6</a>
                                        <a onClick={() => this.handleDayWiseTabClick('day7')} className={daywiseActiveTab === 'day7' ? 'nav-item nav-link active' : 'nav-item nav-link'} role="tab" aria-controls="nav-day-seven" aria-selected="false">DAY-7</a>
                                        <a onClick={() => this.handleDayWiseTabClick('day8')} className={daywiseActiveTab === 'day8' ? 'nav-item nav-link active' : 'nav-item nav-link'} role="tab" aria-controls="nav-day-eight" aria-selected="false">DAY-8</a>
                                        <a onClick={() => this.handleDayWiseTabClick('day9')} className={daywiseActiveTab === 'day9' ? 'nav-item nav-link active' : 'nav-item nav-link'} role="tab" aria-controls="nav-day-nine" aria-selected="false">DAY-9</a>
                                    </div>
                                </nav>
                                <div className="tab-content" id="nav-tabContent">
                                    <div className={daywiseActiveTab === 'day1' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-one-tab">
                                        <img src={navDay1ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 className="tomato"> <b>Day1 – Welcome To Paro And Transfer To Thimphu [1 Hr 30 Min (65 Km) Via
                                            Paro - Thimphu Hwy]</b></h5>
                                        <p><b>Welcome to Bhutan, the Land of Thunder Dragon.</b> Bhutan is like a painter's dream, a
                                            colorful
                                            country surrounded by the Himalayas, a small dreamy paradise with vast green valleys,
                                            beautiful landscapes, rugged terrain, crystalline rivers, and happy people. Once the aircraft
                                            enters the Paro valley, look down to see the first glimpse of the Paro Dzong on the hillside
                                            overlooking Paro Chu (river) with Ta Dzong. Our representative will welcome you in a Bhutanese
                                            style with Tashi Khada (white scarf) and guide you at the airport, which marks the beginning
                                            of your Bhutan Tour Package from Mumbai with transfer to Thimphu (2334 m).</p>
                                    </div>
                                    <div className={daywiseActiveTab === 'day2' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-two-tab">
                                        <img src={navDay2ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 className="tomato"><b>Day2 - Thimpu Sightseeing - After breakfast, start for local Thimpu
                                            sightseeing</b></h5>
                                        <p>
                                            <b>The National Memorial Chorten </b>or Thimpu Chorten is a site for evening prayer. The
                                            chorten or
                                            stupa was built in the memory of the third king, continuously circumambulated by people,
                                            murmuring mantras and spinning their prayer wheels. Construction of this landmark was the idea
                                            of Bhutan's third king, His Majesty Jigme Dorji Wangchuk ("the father of modern Bhutan") who
                                            has wished to erect monument to world peace and prosperity. Completed in 1974 after his
                                            untimely death, it serves both as a memorial to the Late King and as a monument to
                                            peace.<br /><br />

                                            <b>Sakyamuni Buddha - </b>On the top of Kuensel Phodrang hill sits the statue of Sakyamuni
                                            Buddha.
                                            The 51.5-meter-long bronze statue would surely attract you and the uninterrupted view of the
                                            valley would leave you spellbound. The site is also known as Buddha View Point.<br /><br />
                                            <b>Changangkha Lhakhang - </b>At Thimphu you will go across several monasteries and
                                            temples. Changangkha Lhakhang is among the most important religious structures in Thimphu. The
                                            structure dates back to the 15th century and is one of the oldest in the region. The structure
                                            is dedicated to Avalokiteshvara, the sign of compassion. You will also enjoy the grand view of
                                            Thimphu valley from here.<br /><br />
                                            <b>Takin Zoo –</b> You will find the National animal of Bhutan “Takin” in this open zoo. Takin
                                            is one
                                            of the most endangered species of the World and it is available only in Bhutan.<br />
                                        </p>
                                        <b>Overnight in Thimphu.</b>
                                    </div>
                                    <div className={daywiseActiveTab === 'day3' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-three-tab">
                                        <img src={navDay3ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 className="tomato"><b>Day3 - Thimpu Sightseeing - After breakfast, start for local Thimpu
                                            sightseeing</b></h5>
                                        <p>
                                            <b>The National Library -</b>has got a fabulous collection of Buddhist manuscripts which are
                                            priceless. It also houses the World’s largest published book which is about 130 pounds and 5X7
                                            feet.<br /><br />
                                            <b>Zorig Chusum  - </b>Institute for Zorig Chusum (commonly known as the Painting School)
                                            where
                                            students undergo a 6-year training course in Bhutan's 13 traditional arts and crafts.<br /><br />
                                            <b>The Royal Textile Museum  -</b>Thimphu also introduces visitors with the culture and
                                            heritage of
                                            Bhutan. The Royal Textile Museum at Norzin Lam is a site where you will get introduced to
                                            Bhutanese textiles, some of which date back to the 1600s.<br /><br />
                                            <b>Trashi Chhoe Dzong</b> or the Fortress of the Glorious Region is another ‘must visit’ site.
                                            Situated on the west bank of Wang Chhu river, Trashi Chhoe Dzong is the formal coronation site
                                            of the fifth King. The Dzong also hosts the annual Tsechu festival. Built in 1641 by the
                                            political and religious unifier of Bhutan, Shabdrung Ngawang Namgyal, it was reconstructed in
                                            1960s in traditional Bhutanese manner, without nails or architectural plans.<br />
                                        </p>
                                        <b>Overnight in Thimphu.</b>
                                    </div>
                                    <div className={daywiseActiveTab === 'day4' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-four-tab">
                                        <img src={navDay4ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 className="tomato"> <b>Day 4 –Thimpu – Punakha </b></h5>
                                        <div>After breakfast, you will be taken on a three-hour road trip from Thimphu to Punakha /
                                            Wangdue Phodrang, which is located across the famous Dochu La Pass.
                                            During the road trip, you will pass through the Dochula, where you will spot mani wall,
                                            chorten, prayer flags, and others. This is the country’s highest road. You can even spot the
                                            peaks of surrounding mountains, if the sky is clear. Top peaks to spot during your drive are
                                            Masagang, Tsendagang, Terigang, Kangphugang, Table Mountain, and others. This includes the
                                            Gangkhar Puensum peak, which is the highest in Bhutan. On Arrival Check into the Hotel. Post
                                            Lunch You will see the following tourist sites in Punakha /Wangdue  include:-<br />
                                            <ul>
                                                <li>&bull;&nbsp;Punakha Dzong</li>
                                                <li>&bull;&nbsp;Chimi Lhakhang</li>
                                                <li>&bull;&nbsp;Punakha Suspension Bridge</li>
                                                <li>&bull;&nbsp;Punakha Village.</li>
                                            </ul>
                                        </div>
                                        <p><b>Overnight Stay at Punakha hotel.</b></p>
                                    </div>
                                    <div className={daywiseActiveTab === 'day5' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-five-tab">
                                        <img src={navDay5ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 className="tomato"> <b>Day 5 – Excursion tour to Gangtey/ Phobjikha</b></h5>
                                        <p>After breakfast drive to Gangtey valley . Its located in the beautiful, broad and U shaped
                                            glacial valley of Phobjika. After arrival hotel check in and latter after lunch we will hike
                                            to nature trails .From the small hilltop overlooking Gangtey.<br />

                                            Goemba, head downhill through flower meadows to Semchubara village and from here through
                                            beautiful forests and into the open valley. After passing a chorten and Khewa Lhakhang, the
                                            trail ends at the local community school; this involves a 30 minute walk up to the start of
                                            town.Black Necked Crane Information Centre.<br />
                                            Situated on the edge of the forest and wetland along the main road of<b> Phobjikha </b>valley, the
                                            black-necked crane information Centre has an observation room equipped with high power
                                            telescope and spotting scopes for catching the best view of the cranes. The centre also offers
                                            display information that outline the natural and cultural history of the area. There is a
                                            small gift shop, which sells handicrafts produced by the local people.
                                            Back to hotel.<br />
                                            <b>Overnight in Paro.</b>
                                        </p>
                                    </div>
                                    <div className={daywiseActiveTab === 'day6' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-six-tab">
                                        <img src={navDay6ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 className="tomato"> <b>Day 6 – Punakha / Wangdue to Paro</b></h5>
                                        <div>
                                            After breakfast, you will leave Punakha/Wangdue and drive to Paro via Dochu La Pass, covering
                                            Paro Chuu river valley and Wang Chhu river valley. On the way, you will be visiting Simtokha
                                            Dzong, an old fortress which is now a school for Dzongkha language.
                                            Once you reach Paro, you will be checked into a hotel. Later, you will be taken on a
                                            sightseeing tour covering important destinations of Paro:-<br />
                                            <ul>
                                                <li>&bullet;&nbsp;Ta Dzong</li>
                                                <li>&bullet;&nbsp;Rinpung Dzong</li>
                                                <li>&bullet;&nbsp;Tamchog lhakhang</li>
                                            </ul>
                                            <b>Overnight in Paro</b>
                                        </div>
                                    </div>
                                    <div className={daywiseActiveTab === 'day7' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-seven-tab">
                                        <img src={navDay7ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 className="tomato"> <b>Day 7 – Paro Local Sightseeing</b></h5>
                                        <p>
                                            After early morning breakfast, you will be starting a Excursion tour to Taktshang
                                            Monastery.Taksand Palphug Monastery also known as Tiger's Nest is an amazing Buddhist
                                            monastery, temple and sacred site is located in the upper Paro town. Built in 1692,
                                            Padmasambhava is believed to have meditated at this site for 3 years. Guru Padmasambhava is
                                            known to be the founder of Buddhist religion in Bhutan. He is immensely respected and has a
                                            huge number of sacred followers in Bhutan.</p>
                                        <b>Overnight in Paro</b>
                                    </div>
                                    <div className={daywiseActiveTab === 'day8' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-eight-tab">
                                        <img src={navDay8ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 className="tomato"> <b>Day 8 – EXCURSION TOUR TO Chele-La-Pass</b></h5>
                                        <p>
                                            After breakfast an excursion trip to Chele-La-Pass (13,088 ft.) – the highest motorable road
                                            in Bhutan. Enjoy the scenic beauty &amp; memorable landscapes from Chele-La-Pass. Back to
                                            Paro. Rest of the day is free for
                                            leisure.</p>
                                    </div>
                                    <div className={daywiseActiveTab === 'day9' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-day-nine-tab">
                                        <img src={navDay9ImageURL} className="img-fluid" alt="..." />
                                        <br /> <br />
                                        <h5 className="tomato"> <b>Day 9 – Paro To Airport Drop</b></h5>
                                        <p>
                                            Post breakfast bid farewell to the Last Buddhist Kingdom and start for Paro Airport with
                                            long-lasting sweet Bhutan Holiday memories, while chanting one mantra, "Life is short, and the
                                            World is wide"with Adorable Vacation LLP.</p>
                                    </div>
                                </div>
                            </div>

                            {/* <!--insert table inclusion exclusion--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        {
                                            <InclusionExclusion />
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* <!--end table inclusion exclusion--> */}


                            {/* <!--start of package details--> */}
                            <div className="container-fluid py-5">

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>Our Bhutan <span>Tour Packages</span></h2>
                                            <h4>Trending Bhutan Trip from BAGDOGRA Airport</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                                        <div className="carousel-inner">
                                            <div className="carousel-item active">

                                                <Slider {...settings}>
                                                    {hotPackagesView}
                                                </Slider>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            {/* <!--end of package details--> */}



                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h3><b>Bhutan <span className="tomato">Tour Reviews</span></b></h3>
                                            <h4><i>"Your Experience is our Marketing"</i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-cols-1">
                                    <div id="testimonials">
                                        <div className="testimonial-box-container">

                                            {reviewersDataView}

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-3">

                            <BookingBox />

                        </div>

                    </div>
                </div>

            </>
        );
    }
}

export default FIFO_8N_9D_Package;