import React, { Component } from 'react';

class InclusionExclusion extends Component {
    render() {
        return (
            <>
                <table className="table-borderDodont">
                    <thead>
                        <tr>
                            <td className="tomato"><h5><b>Meghalaya Tour</b> Inclusion</h5></td>
                            <td className="tomato"><h5><b>Meghalaya Packages</b> Exclusion</h5></td>
                        </tr>
                    </thead>
                    <tbody>
                    <tr>
                <td><span class="green-dot"><i class="fa fa-check" aria-hidden="true"></i></span>Rooms as per
                  mentioned above.</td>
                <td><span class="red-dot"><i class="fa fa-times" aria-hidden="true"></i></span>5% GST.</td>
              </tr>
              <tr>
                <td><span class="green-dot"><i class="fa fa-check" aria-hidden="true"></i></span>Meals as per
                  mentioned above.</td>
                <td><span class="red-dot"><i class="fa fa-times" aria-hidden="true"></i></span>Any entry fees of
                  Temples,Museum,Parks ets.</td>
              </tr>
              <tr>
                <td><span class="green-dot"><i class="fa fa-check" aria-hidden="true"></i></span>Exclusive
                  vehicle for all transfers & sightseeing.</td>
                <td><span class="red-dot"><i class="fa fa-times" aria-hidden="true"></i></span>Personal expanses
                  like tips, laundry, phone bills, any other meals from the mentioned above.</td>
              </tr>
              <tr>
                <td><span class="green-dot"><i class="fa fa-check" aria-hidden="true"></i></span>All
                  toll,parking,fuel & driver allowance.</td>
                <td><span class="red-dot"><i class="fa fa-times" aria-hidden="true"></i></span>Any additional
                  sightseeing from Itinerary/pick up-drop from any other points/Uses of Vehicle at Night – will
                  cost extra.</td>
              </tr>
              <tr>
                <td><span class="green-dot"><i class="fa fa-check" aria-hidden="true"></i></span>One jeep safari
                  at Kaziranga National Park.</td>
                <td><span class="red-dot"><i class="fa fa-times" aria-hidden="true"></i></span>The River Cruise
                  on Brahmaputra.
                </td>
              </tr>
              <tr>
                <td><span class="green-dot"><i class="fa fa-check" aria-hidden="true"></i></span>Operation
                  executive on call for 24x7.</td>
                <td><span class="red-dot"><i class="fa fa-times" aria-hidden="true"></i></span>Anything not
                  mentioned in the Inclusions.
                </td>
              </tr>
                    </tbody>
                </table>
            </>
        );
    }
}

export default InclusionExclusion;