import React, { Component } from 'react';

class InclusionExclusion extends Component {
    render() {
        return (
            <>
                <table className="table-borderDodont">
                    <thead>
                        <tr>
                            <td className="tomato"><h5><b>Kashmir Kargil Package</b> Inclusion</h5></td>
                            <td className="tomato"><h5><b>Leh Ladakh with Kashmir Tour </b> Exclusion</h5></td>
                        </tr>
                    </thead>
                    <tbody>
                    <tr>
                <td><span class="green-dot"><i class="fa fa-check" aria-hidden="true"></i></span>All Accommodation on branded 4 Star hotel (Double / Triple Sharing Basis).</td>
                <td><span class="red-dot"><i class="fa fa-times" aria-hidden="true"></i></span>Any kind of bus or rail or air fare.</td>
              </tr>
              <tr>
                <td><span class="green-dot"><i class="fa fa-check" aria-hidden="true"></i></span>Daily complimentary breakfast & dinner on buffet.</td>
                <td><span class="red-dot"><i class="fa fa-times" aria-hidden="true"></i></span>Personal expanses like tips, laundry, phone bills, any other meals from the mentioned above.</td>
              </tr>
              <tr>
                <td><span class="green-dot"><i class="fa fa-check" aria-hidden="true"></i></span>Exclusive luxury vehicle for all transfers &amp; sightseeing.</td>
                <td><span class="red-dot"><i class="fa fa-times" aria-hidden="true"></i></span>Any additional sightseeing from Itinerary / pick up-drop from any other points / Uses of Vehicle at Night – will cost extra.</td>
              </tr>
              <tr>
                <td><span class="green-dot"><i class="fa fa-check" aria-hidden="true"></i></span>All toll, parking, fuel &amp; driver allowance.</td>
                <td><span class="red-dot"><i class="fa fa-times" aria-hidden="true"></i></span>Doesn't include entrance fees at Garden/Monuments/Museum</td>
              </tr>
              <tr>
                <td><span class="green-dot"><i class="fa fa-check" aria-hidden="true"></i></span>Gondola Ride ticket, Pony Ride, Camera Fees and monument guide.</td>
                <td><span class="red-dot"><i class="fa fa-times" aria-hidden="true"></i></span>Professional Tourist Guide Charges.
                </td>
              </tr>
              <tr>
                <td><span class="green-dot"><i class="fa fa-check" aria-hidden="true"></i></span>01 Hour Sikhara Ride at Dal Lake.</td>
                <td><span class="red-dot"><i class="fa fa-times" aria-hidden="true"></i></span> Extra nights in hotel due to flight cancellation or medical illness.
                </td>
              </tr>
                    </tbody>
                </table>
            </>
        );
    }
}

export default InclusionExclusion;