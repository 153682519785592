import React, { Component } from 'react';
import axios from "axios";
import ApiURL from '../../api/ApiURL';
import cogoToast from "cogo-toast";
import validation from '../../validation/validation';
import { Digital } from "react-activity";
import "react-activity/dist/library.css";

class BookingBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            btn: "Submit",
            showLoader: false,
            firstName: '',
            lastName: '',
            mobile: '',
            email: '',
            comments: ''
        }
    }
    onChangeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onRequestCallbackSubmit = async (e) => {
        e.preventDefault();
        var { firstName, lastName, mobile, email, travelDate, comments } = this.state;
        if (firstName.length === 0) {
            cogoToast.error("First Name Required.", { position: 'bottom-center' });
        }
        else if (lastName.length === 0) {
            cogoToast.error("Last Name Required.", { position: 'bottom-center' });
        }
        else if (email.length === 0) {
            cogoToast.error("Email Required.", { position: 'bottom-center' });
        }
        else if (mobile.length === 0) {
            cogoToast.error("Mobile Number Required.", { position: 'bottom-center' });
        }
        else if (mobile.length > 0 && !(validation.MobileRegx).test(mobile)) {
            cogoToast.error("Invalid Mobile No.", { position: 'bottom-center' });
        }
        else {

            this.setState({ btn: "Processing..", showLoader: true });
        try {
            let _ref = document.referrer;
            let _url = window.location.href;

            var referrer = document.referrer;
            console.log("referrer url", referrer);
            console.log(_url);

            var formData = {
                FirstName: firstName,
                LastName: lastName,
                Email: email,
                Mobile: mobile,
                URL: _url,
                Referrer: _ref,
                Comments: comments
            };

            const response = await axios.post(ApiURL.SaveCustomerEnquery, formData);
                console.log('Data submitted successfully:', response.data);
                // Optionally, reset the form state after successful submission
                // this.setState({ firstName: '', lastName: '', email: '', mobile: '', ... });
    
                // Optionally, show a success message to the user
                 cogoToast.success("Form submitted successfully.", { position: 'bottom-center' });
                 this.setState({ btn: "Submit", showLoader: false })
                 // Reset the form fields after submission
            this.setState({
                firstName: '',
                lastName: '',
                email: '',
                mobile: '',
                comments: '',
                btn: "Submit",
                showLoader: false
            });
        }
        catch (error) {
            console.error('Error submitting data:', error);
            this.setState({ btn: "Submit", showLoader: false });
            // Optionally, show an error message to the user
             cogoToast.error("Failed to submit form. Please try again later.", { position: 'bottom-center' });
        }
        }
    }

    onResetData = () => {
        this.setState({
            ...this.state,
            firstName: '',
            lastName: '',
            mobile: '',
            email: '',
            comments: ''
        })
    }

    render() {
        return (
            <>
                <div className="sticky-top">
                    <div className="rightFixedForm">
                        <div>
                            <h5><center>Get a Free Callback &nbsp;&nbsp;<i className="fa fa-phone fa-rotate-90"></i></center></h5>
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    name="firstName"
                                    placeholder="First Name *"
                                    value={this.state.firstName}
                                    onChange={this.onChangeHandler} />
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    name="lastName"
                                    placeholder="Last Name *"
                                    value={this.state.lastName}
                                    onChange={this.onChangeHandler} />
                            </div>
                            <div className="form-group">
                                <input type="text"
                                    className="form-control"
                                    name="email"
                                    placeholder="Email *"
                                    value={this.state.email}
                                    onChange={this.onChangeHandler} />
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    name="mobile"
                                    placeholder="10 Digit Mobile No.*"
                                    value={this.state.mobile}
                                    onChange={this.onChangeHandler} />
                            </div>
                            <div className="form-group">
                                <textarea
                                    className="form-control"
                                    name="comments"
                                    placeholder="Comments"
                                    rows="3"
                                    value={this.state.comments}
                                    onChange={this.onChangeHandler}>
                                </textarea>
                            </div>
                            <div className='form-group'>
                                <div className="container row">
                                    <div className='col-sm-12'>
                                        <div className='pull-left'>
                                            <button type="button" className="btn btn-danger borderRadius5PX mr-2" onClick={this.onResetData}>Reset</button>
                                            <button type="button" className="btn btn-primary borderRadius5PX" onClick={this.onRequestCallbackSubmit}>Submit</button>
                                        </div>
                                    </div>
                                    <div className='col-sm-3'>
                                        {
                                            (this.state.showLoader) &&
                                            <div className='pull-right'><Digital size={25} /></div>
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="box-bottom">
                        <h6 className="font-sm"> We are the best tour operator</h6>
                        <p className="font-sm">Our custom tour program,<br />
                            Direct call
                            <a href="tel:+919883359713">
                                <span className="text-primary">+91-98833-59713</span>
                            </a>
                        </p>
                    </div>
                </div>
            </>
        );
    }
}

export default BookingBox;