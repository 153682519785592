import React, { Component } from "react";
import BookingBox from "../BookingBox";
import Slider from "react-slick";
import ApiURL from "../../../api/ApiURL";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./InclusionExclusion";
import InclusionExclusion from "./InclusionExclusion";

class AhemdabadToBhutanPackage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customActiveTab: "tab1",
    };
  }

  handleCustomTabClick = (tab) => {
    this.setState({
      customActiveTab: tab,
    });
  };

  render() {
    const { customActiveTab } = this.state;
    const packageTopBannerImageURL = ApiURL.BhutanTourPackageFromAhemdabadImageURL + "/Bhutan-Tour-from-Ahemdabad.webp";
    const bhutanTravelAgentImageURL = ApiURL.BhutanTourPackageFromAhemdabadImageURL + "/Bhutan-Travel-Agents.webp";
    const sixSenseBhutanTourPackagesImageURL = ApiURL.BhutanTourPackageFromAhemdabadImageURL + "/Six-Sense-Bhutan-Tour-Packages.webp";
    const amanKoraBhutanPackagesImageURL = ApiURL.BhutanTourPackageFromAhemdabadImageURL + "/Aman-Kora-Bhutan-Packages.webp";

    const hotPackagesDataList = this.props.HotPackagesData;
    const hotPackagesView = hotPackagesDataList.length > 0 ? (
      hotPackagesDataList.map((dataItem, i) => {
        const hotelImgURL = ApiURL.BhutanTourPackageFromAhemdabadImageURL + "/" + dataItem.ImageName;
        const packageURL = dataItem.Url;
        return (
          <div className="col-md-4" key={i}>
            <div className="card h-100">
              <img src={hotelImgURL} className="card-img-top" alt={dataItem.AllternateText} />
              <div className="card-body">
                <h5 className="card-title">{dataItem.Title} <span> &#8377; {dataItem.PricePerNight}/-</span></h5>
                <p className="card-text">
                  <i className="fa fa-building tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-utensils tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-car-side tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-male tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-camera tomato"></i>
                </p>
                <p className="card-text">{dataItem.Description}</p>
                <p>
                  <span><b className="tomato">{dataItem.NoOfTours} </b>Tours</span> |  
                  <span><b className="tomato">{dataItem.NoOfDeparture} </b>Departures</span> |  
                  <span><b className="sdf-blue">SDF Inc.</b></span>
                </p>
              </div>
              <div className="card-footer">
                <small className="text-muted">{dataItem.MutedText}</small>
                <Link
                  to={packageURL}
                  target="_blank"
                  className="btn btn-Warning btn-sm float-end"
                >
                  Book Now
                </Link>
              </div>
            </div>
          </div>
        );
      })
    ) : (
      <div></div>
    );

    const settings = {
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    const hotelDataList = this.props.HotelsData;
    const hotelDataView = hotelDataList.map((hotelItem, i) => {
      const hotelImageURL =
        ApiURL.BhutanTourPackageFromAhemdabadImageURL + "/" + hotelItem.ImageName;
      return (
        <div className="col-md-3" key={i}>
          <img
            src={hotelImageURL}
            className="rounded mx-auto d-block"
            alt={hotelItem.AllternateText}
          />
          <br />
          <span>
            <b>{hotelItem.HotelName}</b> | 
            <span className="tomato">{hotelItem.HotelStar}</span>
          </span>
          <br />
          <span className="rating-box">
            <span className="star">{hotelItem.RatingBoxStar}</span>
            <b className="rating-value">{hotelItem.RatingValue}</b>
          </span>{" "}
          &nbsp;<b>{hotelItem.Reviews}</b>
        </div>
      );
    });

    const themePackageSliderList = this.props.ThemePackagesData;
    const themePackagesDataView = themePackageSliderList.map(
      (sliderItem, i) => {
        const sliderImageURL =
          ApiURL.BhutanTourPackageFromAhemdabadImageURL +
          "/" +
          sliderItem.ImageName;
        return (
          <div className="theme-cards-wrapper mx-2" key={i}>
            <div className="theme-card">
              <div className="image-wrapper">
                <img src={sliderImageURL} alt={sliderItem.AllternateText} />
              </div>
              <div className="card-body">
                <h5 className="card-title">{sliderItem.Title}</h5>
                <p className="card-text">{sliderItem.Description}</p>
                <Link to={"#"} className="btn btn-primary">
                  Book Now
                </Link>
              </div>
            </div>
            <div className="clearfix"></div>
          </div>
        );
      }
    );

    const trendingBhutanDataList = this.props.TrendingBhutanDataList;
    const trendingBhutanDataView = trendingBhutanDataList.map(
      (sliderItem, i) => {
        const trendingBhutanImgURL = ApiURL.BhutanTourPackageFromAhemdabadImageURL + "/" + sliderItem.ImageName;
        return (
          <div className="theme-cards-wrapper mx-2" key={i}>
            <div className="theme-card">
              <div className="image-wrapper">
                <img src={trendingBhutanImgURL} alt="..." />
              </div>
              <div className="card-body">
                <h6 className="card-title">{sliderItem.Title}<span> &#8377; {sliderItem.PricePerNight}/-</span></h6>
                <p className="card-text">
                  <i className="fa fa-building tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-utensils tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-car-side tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-male tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-camera tomato"></i>
                </p>                
                <p className="card-text"><span>{sliderItem.Description}</span></p>
                <p>
                  <span><b className="tomato">{sliderItem.NoOfTours} </b>Tours</span> |  
                  <span><b className="tomato">{sliderItem.NoOfDeparture} </b>Departures</span> |  
                  <span><b className="sdf-blue">SDF Inc.</b></span>
                </p>
                <div className="card-footer">
                  <small className="text-muted"><strong className="tomato">EX-IXB</strong></small>
                  <Link to={sliderItem.BookNowURL} className="btn btn-Warning btn-sm float-end">
                    Book Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        );
      }
    );

    const reviewersDataList = this.props.ReviewersData;
    const reviewersDataView = reviewersDataList.map((dataItem, i) => {
      const reviewerImageURL =
        ApiURL.BhutanTourPackageFromAhemdabadImageURL + "/" + dataItem.ImageName;
      return (
        <div className="testimonial-box" key={i}>
          <div className="box-top">
            <div className="profile">
              <div className="profile-img">
                <img src={reviewerImageURL} alt="..." />
              </div>
              <div className="name-user">
                <strong>{dataItem.Name}</strong>
                <span>{dataItem.Location}</span>
              </div>
            </div>
            <div className="reviews">
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
            </div>
          </div>
          <div className="client-comment">
            <p>{dataItem.Comment}</p>
          </div>
        </div>
      );
    });

    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <img
              src={packageTopBannerImageURL}
              className="img-fluid px-0"
              alt="..."
            />
          </div>
        </div>

        <div className="container-fluid py-3">
          <div className="row">
            <div className="col-md-9">
              <div className="row">
                <div className="col-md-12">
                  <div className="container-fluid">
                    <h3 className="px-2">Bhutan Tour Package from Ahemdabad!</h3>

                    <div className="d-flex flex-column flex-sm-row align-items-sm-center px-2">
                      <div className="font-lg">
                        3 Cities:{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Thimphu
                        </a>
                        &nbsp;{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Paro
                        </a>{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Punakha
                        </a>
                      </div>
                      <div className="reviews mx-5">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                      </div>
                    </div>
                    <p className="p-2">
                      A Bhutan Trip to your favourite chillout spot or a new
                      adventure, you've come to the right place!
                    </p>

                    <div className="card">
                      <div
                        className="card-header custHeaer"
                        style={{ fontSize: "1.2vw" }}
                      >
                        India's Largest Bhutan Tour Operator (Own office at
                        PARO)
                      </div>
                      <div className="card-body">
                        <p>
                          Greetings, Travel Enthusiasts of Ahemdabad! Do you find
                          yourself trapped in the monotonous humdrum of busy
                          work and overwhelmed by the constant traffic pressure?
                          If the answer is a resounding yes , then it's time to
                          break free from the shackles and let your soul revel
                          in the mystical charm of nature with an enchanting
                          Bhutan Package Tour from Ahemdabad. There's an
                          inexplicable allure to this Buddhist Kingdom, as it
                          possesses a rare gift to touch your soul, mend it with
                          peace, and fill it with happiness, effectively
                          replacing the chaos of your hectic schedules. So why
                          wait? Take the leap and secure your Bhutan Tour
                          Package from Ahemdabad now to experience a world of
                          serenity, nestled in the lap of luxury, far away from
                          the hustle and bustle of Mumbai's marine drive, amidst
                          the soothing embrace of nature.
                        </p>
                        <h3 style={{ fontSize: "1.5vw" }}>
                          The Best Way to Reach Bhutan from Kolkata-
                        </h3>
                        <p className="tomato">
                          <b>LUXURY OPTION</b>
                        </p>
                        <p>
                          <b>
                            Direct Non-Stop Charter Flight from Delhi to Paro:{" "}
                          </b>
                          An exclusive non-stop charter flight from Delhi to
                          Paro ensures a seamless and comfortable journey.
                          Facility is only available during Summer month only.{" "}
                          <i>(APRIL, MAY & JUNE)</i>
                        </p>
                        <br />
                        <b>
                          <p className="tomato">ECONOMICAL OPTION</p> Direct
                          Flight to Paro via Delhi (DEL), Kolkata (CCU), or
                          Guwahati (GAU):{" "}
                        </b>
                        If you prefer traditional flights, you can take domestic
                        flights from Mumbai to Delhi, Kolkata, or Guwahati, and
                        then board a direct flight to Paro.
                        <br />
                        <br />
                        <p className="tomato">
                          {" "}
                          <b>CHEAPEST OPTION</b>
                        </p>
                        <p>
                          <b>Reach Bhutan by Road from Bagdogra Airport: </b>{" "}
                          For a different adventure, fly to Bagdogra Airport and
                          then proceed to the picturesque town of Phuentsholing
                          in Bhutan. Our office in Bhutan will ensure a smooth
                          transition as you cross the border.
                        </p>
                        <a href="tel://9883359713" className="btn btn-Warning">
                          Book Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h2>
                        Our Bhutan <span>Tour Packages</span>
                      </h2>
                      <h4>Trending Bhutan Trip from Ahemdabad</h4>
                    </div>
                  </div>
                </div>
                <div className="row row-cols-1 row-cols-md-4 g-4">
                  {hotPackagesView}
                </div>
              </div>

              <div className="card">
                <div
                  className="card-header custHeaer"
                  style={{ fontSize: "1.2vw" }}
                >
                  Why Choose Adorable Vacation LLP ?
                </div>
                <div className="card-body">
                  <p>
                    When it comes to Bhutan tour packages from Ahemdabad, Adorable
                    Vacation LLP stands out for numerous reasons:
                  </p>
                  <p>
                    <b>Years of Expertise: </b>
                    With years of experience in the travel industry, we have
                    honed our skills in creating unforgettable journeys for our
                    clients. Our expertise ensures that your Bhutan tour is
                    seamless and truly memorable.
                    <br />
                    <br />
                    <b>Bhutan Experts: </b>
                    Having our own office in Bhutan, located at Lamgong, Chukha,
                    Paro, sets us apart as true Bhutan experts. Our in-depth
                    understanding of the country, its culture, and its hidden
                    gems ensures that you experience the authentic essence of
                    Bhutan.
                    <br /> <br />
                    <b>Quality Accommodation: </b>
                    We offer stays in some of the best hotels in Bhutan,
                    providing you with comfort and luxury throughout your
                    journey.
                    <br /> <br />
                    <b>Ethical and Responsible Tourism: </b>
                    At Adorable Vacation LLP, we are committed to promoting
                    sustainable tourism practices. By traveling with us, you can
                    rest assured that your journey contributes positively to
                    Bhutan’s environment and communities. Choose Adorable
                    Vacation LLP for an extraordinary Bhutan tour from Ahemdabad
                    and immerse yourself in the Land of Happiness. Our TCB
                    License No. 1051529 and registration with the Tourism
                    Council of Bhutam assure you of our credibility and
                    dedication to offering you the best travel experience. Let
                    us be your trusted travel companion as you embark on this
                    magical journey to the Land of Happiness.
                    <br /> <br />
                    <b>Local Connections: </b>
                    Our local team in Bhutan is dedicated to providing you with
                    the best local guides, immersive experiences, and valuable
                    insights into the country’s culture and traditions.
                    Moreover, with Mr. Ganesh Pradhan at the helm, our local
                    connections extend far beyond the usual tourist trails. Our
                    Bhutan Tour Manager takes pride in sharing his profound
                    insights and ensuring that you have a deeper understanding
                    of Bhutan’s way of life, values, and customs.
                    <br /> <br />
                    <b>Exclusive Charter Flights: </b>
                    Our non-stop charter flights from Kolkata to Paro offer you a
                    hassle-free and premium travel experience.
                    <br /> <br />
                    <b>Personalized Itineraries: </b>
                    At Adorable Vacation LLP, we believe in tailoring your
                    journey to suit your preferences and interests. Our
                    personalised itineraries guarantee that every moment of your
                    trip is unique and tailored just for you..
                  </p>
                  <a href="tel://9883359713" className="btn btn-Warning">
                    Call Now
                  </a>
                </div>
              </div>

              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h2>
                        Day Wise Bhutan Tour <span>Plan from India</span>
                      </h2>
                      <h4>7 Nights 8 Days Tour Plan from IXB</h4>
                    </div>
                  </div>
                </div>

                <div className="container-fluid py-3">
                  <div className="row">
                    <table className="table table-bordered">
                      <thead>
                        <tr className="table-danger">
                          <th>DAY WISE SUMMARY FOR 7N/8D BHUTAN TOUR</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingOne"
                          >
                            <b>DAY 1 → </b>
                            <span>
                              PICK UP FROM BAGDOGRA AIRPORT & TRANSFER TO
                              PHUENTSHOLING
                            </span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingTwo"
                          >
                            <b>DAY 2 → </b>
                            <span>PHUENTSHOLING TO THIMPHU TRANSFER</span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingThree"
                          >
                            <b>DAY 3 → </b>
                            <span>THIMPHU LOCAL SIGHTSEEING</span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingFour"
                          >
                            <b>DAY 4 → </b>
                            <span>THIMPHU TO PUNAKHA</span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingFive"
                          >
                            <b>DAY 5 → </b>
                            <span>PUNAKHA TO PARO</span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingSix"
                          >
                            <b>DAY 6 → </b>
                            <span>PARO LOCAL SIGHTSEEING</span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingSeven"
                          >
                            <b>DAY 7 → </b>
                            <span>PARO TO LATAGURI </span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingTen"
                          >
                            <b>DAY 8 → </b>
                            <span>LATAGURI TO BAGDOGRA AIRPORT DROP</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* <!--end Short description--> */}

                <div className="row">
                  <div
                    className="accordion"
                    id="accordionPanelsStayOpenExample"
                  >
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="panelsStayOpen-headingOne"
                      >
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseOne"
                          aria-expanded="true"
                          aria-controls="panelsStayOpen-collapseOne"
                        >
                          DAY 01: NJP RLY STATION / IXB AIRPORT –
                          PHUENTSHOLING(DISTANCE: 170 KMS. / DRIVING TIME: 5
                          HRS. / ALTITUDE OF PHUENTSHOLING: 293 MTS.)
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingOne"
                      >
                        <div className="accordion-body">
                          Meet & Greet on arrival at NJP Railway Station / IXB
                          Airport and transfer to Phuentsholing - The Gateway of
                          Bhutan by Road from India. It is a thriving commercial
                          center on the northern edge of the Indian Plains. On
                          arrival check-in at the hotel.
                          <br />
                          <br />
                          <p>
                            <b>Overnight stay at Phuentsholing.</b>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="panelsStayOpen-headingTwo"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseTwo"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseTwo"
                        >
                          DAY 02: PHUENTSHOLING TO THIMPHU (DISTANCE: 176 KM. /
                          DRIVING TIME: 6 HRS. / ALTITUDE OF THIMPHU: 2350 MTS.)
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseTwo"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingTwo"
                      >
                        <div className="accordion-body">
                          <p>
                            After breakfast, proceed towards Thimpu via the
                            wonderful mystic town Gedu, which is about 9000 ft
                            above the sea and Chukha Dam. On the way, halt at
                            Chojum to take photographs of the confluence of two
                            rivers of Bhutan, Thumpu Chu, and Paro Chu. Reach
                            Thimpu in the evening. On arrival check-in at the
                            hotel. In the evening, visit the theTashichho Dzong
                            (Fortress of the Glorious Religion).
                            <br />
                            <br />
                          </p>
                          <p>
                            <b>Overnight stay at Thimphu.</b>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="panelsStayOpen-headingThree"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseThree"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          DAY 03: THIMPHU LOCAL SIGHT SEEN
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseThree"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingThree"
                      >
                        <div className="accordion-body">
                          <p>
                            After breakfast, the day is dedicated to sightseeing
                            in Thimphu. You will see the following tourist sites
                            in Thimphu include:-
                            <br />
                            <br />
                            • Kuenselphodrang
                            <br />
                            • National Memorial Chorten
                            <br />
                            • Folk Heritage Museum
                            <br />
                            • Typical Bhutanese lunch at the Folk Heritage
                            Restaurant would be a great experience.
                            <br />
                            • Sangaygang view point
                            <br />
                            • Takin Reserve Centre.
                            <br />
                            • Changangkha Monastery
                            <br />
                            • Tashichho Dzong (Fortress of the Glorious
                            Religion) <br />
                            <br />
                          </p>
                          <p>
                            <b>Dinner and Overnight Stay at Thimphu hotel.</b>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="panelsStayOpen-headingFour"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseFour"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          Day 04: - THIMPHU TO PUNAKHKA
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseFour"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingFour"
                      >
                        <div className="accordion-body">
                          <p>
                            After breakfast, you will be taken on a three-hour
                            road trip from Thimphu to Punakha / WangduePhodrang,
                            which is located across the famous Dochu La Pass.
                            During the road trip, you will pass through the
                            Dochula, where you will spot mani wall, chorten,
                            prayer flags, and others. This is the country’s
                            highest road. You can even spot the peaks of
                            surrounding mountains if the sky is clear. Top peaks
                            to spot during your drive are Masagang, Tsendagang,
                            Terigang, Kangphugang, Table Mountain, and others.
                            This includes the Gangkhar Puensum peak, which is
                            the highest in Bhutan.On Arrival Check Into the
                            Hotel. Post Lunch You will see the following tourist
                            sites in Punakha /Wangdue include:-
                            <br />
                            <br />
                            • Punakha Dzong
                            <br />
                            • Chimi Lhakhang
                            <br />
                            • Punakha Suspension Bridge
                            <br />
                            • Punakha Village
                            <br />
                            • Jigme Dorji National Park
                            <br />
                          </p>
                          <p>
                            <b>Overnight Stay in Punakha / Wangdue.</b>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="panelsStayOpen-headingFive"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseFive"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          DAY 05: PUNAKHA / WANGDUE TO PARO
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseFive"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingFive"
                      >
                        <div className="accordion-body">
                          <p>
                            After breakfast, you will leave Punakha/Wangdue and
                            drive to Paro via Dochu La Pass, covering the Paro
                            Chuu river valley and Wang Chhu river valley. On the
                            way, you will be visiting Simtokha Dzong, an old
                            fortress that is now a school for the Dzongkha
                            language. Once you reach Paro, you will be checked
                            into a hotel. Later, you will be taken on a
                            sightseeing tour covering important destinations of
                            Paro:-
                            <br />
                            <br />
                            • Ta Dzong
                            <br />
                            • Rinpung Dzong
                            <br />
                            • Tamchoglhakhang
                            <br />
                          </p>
                          <p>
                            <b>Overnight Stay in Paro.</b>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="panelsStayOpen-headingSix"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseSix"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          DAY 06: PARO LOCAL SIGHTSEEING
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseSix"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingSix"
                      >
                        <div className="accordion-body">
                          <p>
                            After breakfast go for a full day sight seeing in
                            Paro. You will see the following tourist sites in
                            Paro include:-
                            <br />
                            <br />
                            • View of Taktshang Monastery,
                            <br />
                            • Nya-meyZam,
                            <br />
                            • DungtseLhankhang,
                            <br />
                            • Kila Gompa,
                            <br />
                            • DrukChoeding
                            <br />
                            • Tamchoglhakhang
                            <br />
                            • Rinpung Dzong
                            <br />
                            • Ta Dzong
                            <br />
                          </p>
                          <p>
                            <b>Overnight at Paro</b>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="panelsStayOpen-headingSeven"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseSeven"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          DAY 07: PARO TO LATAGURI
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseSeven"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingSeven"
                      >
                        <div className="accordion-body">
                          <p>
                            After breakfast check out from the hotel and
                            transfer to Jaldapara. On arrival check-in at the
                            hotel.
                            <br />
                            <br />
                          </p>
                          <p>
                            <b>Overnight at Lataguri</b>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="panelsStayOpen-headingTen"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseTen"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          DAY 08: LATAGURI TO BAGDOGRA AIRPORT / NJP RAILWAY
                          STATION
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseTen"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingTen"
                      >
                        <div className="accordion-body">
                          <p>
                            {" "}
                            Early Morning you can Enjoy the Lataguri Jeep Safari{" "}
                            <b>(On a Direct Payment Basis)</b> After breakfast
                            checks out from the hotel and take the departure
                            transfer to NJP Railway Station / IXB Airport for
                            your onward journey.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container-fluid navtabCustom">
                                <nav>
                                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                        <a onClick={() => this.handleCustomTabClick('tab1')} className={customActiveTab === 'tab1' ? 'nav-item nav-link active' : 'nav-item nav-link'} role="tab" aria-controls="nav-price" aria-selected="true">Price</a>
                                        <a onClick={() => this.handleCustomTabClick('tab2')} className={customActiveTab === 'tab2' ? 'nav-item nav-link active' : 'nav-item nav-link'} role="tab" aria-controls="nav-hotels" aria-selected="false">Hotels</a>
                                        <a onClick={() => this.handleCustomTabClick('tab3')} className={customActiveTab === 'tab3' ? 'nav-item nav-link active' : 'nav-item nav-link'} role="tab" aria-controls="nav-travellers-info" aria-selected="false">Travellers Info</a>
                                        <a onClick={() => this.handleCustomTabClick('tab4')} className={customActiveTab === 'tab4' ? 'nav-item nav-link active' : 'nav-item nav-link'} role="tab" aria-controls="nav-flight" aria-selected="false">Flight</a>
                                    </div>
                                </nav>
                                <div className="tab-content" id="nav-tabContent">
                                    <div className={customActiveTab === 'tab1' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-price-tab">
                                        <div id="no-more-tables">
                                            <table class="col-md-12 table-bordered table-striped table-condensed cf">
                                                <thead class="cf">
                                                    <tr className="table-primary text-center">
                                                        <th colSpan="5">Fly-In & Fly-Out (FIFO) – EX PARO INTERNATIONAL AIRPORT (PBH)</th>
                                                    </tr>
                                                    <tr className="table-danger">
                                                        <th>Itinerary</th>
                                                        <th>02 Pax (INR)</th>
                                                        <th>04 Pax (INR)</th>
                                                        <th>06 Pax (INR)</th>
                                                        <th>Extra Person</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td data-title="Itinerary">07 nights – 08 days</td>
                                                        <td data-title="02 Pax (INR)">56,300 PP</td>
                                                        <td data-title="04 Pax (INR)">42,900 PP</td>
                                                        <td data-title="06 Pax (INR)">38,200 PP</td>
                                                        <td data-title="Extra Person">20,500 PP</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className={customActiveTab === 'tab2' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-hotels-tab">
                                        <h5 className="center font-weight">TCB Approved 3-Star Hotels</h5>
                                        <div className="row row-cols-1 row-cols-md-4 g-4">

                                            {hotelDataView}

                                        </div>
                                    </div>
                                    <div className={customActiveTab === 'tab3' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-travellers-info-tab">
                                        <h5><b>Bhutan Visa/ Permit</b></h5>
                                        <span className="green-dot px-3">●</span>While booking a package tour to Bhutan you need a permit/ visa
                                        to enter Bhutan.
                                        <br /><br />
                                        <span className="green-dot px-3">●</span>For Indians – 4 copies passport size recent colour
                                        photographs, Photo ID proof (Passport/ Voter ID) & hotel confirmation vouchers. For minor, its compulsory to carry
                                        original birth certificate along with school photo ID or passport.<br /><br />
                                        <span className="green-dot px-3">●</span>PAN & AADHAR cards are strictly not valid.<br /><br />
                                        <span className="green-dot px-3">●</span>Foreigners (other than Indians) must have visa clearance
                                        before entering Bhutan. Visa can be processed online by providing photo page of your passport.<br /><br />
                                        <span className="green-dot px-3">●</span>Passport should have a minimum 6 months validity
                                        left.<br /><br />
                                        <span className="green-dot px-3">●</span>Permit can be issued during Govt.holidays & weekends(Online
                                        & Offline both).<br /><br />
                                        <span className="green-dot px-3">●</span>Thimphu / Paro / Phuentsholing immigration office is opened on
                                        Saturday, Sunday & Government holidays.But online permit process will be closed, Guest has to
                                        choose the offline process.<br /><br />
                                        <h5><b>Bhutan Currency</b></h5>
                                        <span className="green-dot px-3">●</span>Bhutan currency in called Ngultrum & carries equal value to
                                        Indian Rupee.Indian Rupees are widely accepted except 10, 20, 50, 100, 500 rupee notes only.
                                        <br /><br />
                                        <span className="green-dot px-3">●</span>Bank & major hotels can exchange major currencies.But it’s
                                        important to carry the bank exchange receipt to get it changed back to US Dollar.Foreigners cannot carry
                                        Indian Rupee.<br /><br />
                                        <span className="green-dot px-3">●</span>Credit & Debit cards(Visa & Mastercard) are accepted in
                                        Bhutan.But the service is not widely available.So it is always advisable to carry sufficient cash.<br /><br />
                                        <h5><b>Communication </b></h5>
                                        While on your Bhutan Package Tour your Indian SIM card will not be working in the country unless you have
                                        an international roaming facility.So it’s important to carry a local SIM card.It will help you to
                                        communicate with the service providers.Our team will be in touch on a regular basis through your local
                                        no.You can easily opt for a local SIM by showing your Bhutan permit / visa.
                                        <br /><br />
                                        Almost all the hotels have WiFi facility.So you can communicate with any Indian / International no through
                                        WhatsApp or any other communication APP while you are on your Bhutan package tour.
                                        <br /><br />
                                    </div >
                                    <div className={customActiveTab === 'tab4' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-flight-tab">
                                        <span>Bhutan Flight From - <b>Kolkata</b> <i>(CCU - PBH)</i></span>
                                        <br />
                                        <br />
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr className="table-danger">
                                                    <th>Airlines</th>
                                                    <th>Flight Number</th>
                                                    <th>Operating Days</th>
                                                    <th>Time</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Druk Air</td>
                                                    <td>KB 211, KB 210</td>
                                                    <td>Sat, Mon, Wed, Fri</td>
                                                    <td>9:30 - 11:30</td>
                                                </tr>
                                                <tr>
                                                    <td>Bhutan Airlines</td>
                                                    <td>B3 701, B3 700</td>
                                                    <td>Mon, Tue, Wed, Fri, Sat, Sun</td>
                                                    <td>8:15 - 9:55</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <span>Bhutan Flight From - <b>Delhi</b> <i>(DEL - PBH)</i></span>
                                        <br />
                                        <br />
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr className="table-danger">
                                                    <th>Airlines</th>
                                                    <th>Flight Number</th>
                                                    <th>Operating Days</th>
                                                    <th>Time</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Druk Air</td>
                                                    <td>KB 201, KB 200</td>
                                                    <td>Mon, Tue, Wed, Fri, Sat, Sun</td>
                                                    <td>12:30 - 15:20</td>
                                                </tr>
                                                <tr>
                                                    <td>Bhutan Airlines</td>
                                                    <td>B3 774, B3 773</td>
                                                    <td>Mon, Wed, Sat, Sun</td>
                                                    <td>10:55 - 14:35</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">{<InclusionExclusion />}</div>
                </div>
              </div>

              <div className="row py-5">
                <img src={bhutanTravelAgentImageURL} alt="..." />
              </div>

              <div className="container-fluid">
                <div className="row placetoVisit">
                  <h2>7 BEST PLACES TO VISIT AT BHUTAN</h2>
                  <ul>
                    <li>
                      <strong>Punakha Dzong - </strong> The Punakha Dzong is
                      located in a serene surroundings. You have to cross the
                      Punakha river on a swinging bridge to reach the fort which
                      houses the monastery and the royal living quarters. The
                      whole place is very majestic and decorative. It is quite a
                      large space and it takes almost an hour to do even a quick
                      tour tour of the place. The doors, windows and the
                      courtyard are all so ornate and colourful. The prayer area
                      is very colourful and the statues are large sized and
                      realistic.
                    </li>
                    <li>
                      <strong>Paro Taktsang - </strong> If you google Bhutan,
                      it's impossible not to have come across an image of a
                      monastery perched precariously in the cliff side. Yes,
                      that's Paro Taktsang, also known as Tiger's Nest
                      monastery. If it is your first time there, you ought to
                      visit. According to legend, back in 8th century, Guru
                      Rinpoche (second Buddha) flew here on the back of a
                      tigress. Due to its location, the only way to reach up is
                      to hike or you could hire a horse for a major part of the
                      distance.
                    </li>
                    <li>
                      <strong>Buddha Dordenma - </strong> Great Buddha Dordenma
                      is a gigantic Shakyamuni Buddha statue in the mountains of
                      Bhutan celebrating the 60th anniversary of fourth king
                      Jigme Singye Wangchuck. The statue houses over one hundred
                      thousand smaller Buddha statues, each of which, like the
                      Great Buddha Dordenma itself, are made of bronze and
                      gilded in gold. The Great Buddha Dordenma is sited amidst
                      the ruins of Kuensel Phodrang, the palace of Sherab
                      Wangchuck, the thirteenth Desi Druk, overlooking the
                      southern approach to Thimphu, the capital of Bhutan.
                    </li>
                    <li>
                      <strong>Dochula (Pass) - </strong> Nestled in the eastern
                      edge of the Himalayas, the Kingdom of Bhutan boasts of
                      spectacular mountain views and some of the happiest people
                      in the world. And with this beautiful outlook of life came
                      the creation of Dochula Pass, a breath-taking mountain
                      pass, in commemoration of Bhutanese soldiers who passed
                      away in military operations for the country. Along the
                      Dochula Pass, there are 108 memorial chortens (also known
                      as stupas) called the 'Druk Wangyal Chortens' for each
                      soldier's life lost in war. The hills are also decorated
                      with colourful religious flags in five colours to
                      represent the natural elements: blue for the sky, red for
                      fire, green for water, white for clouds, and yellow for
                      earth. To the local Buddhist people, these flags are
                      symbols of veneration and the inscriptions of prayers on
                      each flag signals peace and prosperity for Bhutan.
                    </li>
                    <li>
                      <strong>Tashichho Dzong - </strong> Tashichho Dzong is a
                      fortress located about 2 km away from the city of Thimphu
                      in Bhutan. It had been constructed in 1641 and underwent
                      several restorations since then. It currently serves both
                      as a monastery and the central secretariat of the present
                      government. Also known as the "fortress of the glorious
                      religion", it is found right on the banks of the Wangchhu
                      River. Originally built in 1216, the original structure of
                      the Tashichho Dzong was destroyed in a fire, with the
                      current dzong structure being newly built. A lovely rose
                      garden in front of the building welcomes the visitors to
                      the fortress. The manicured garden surrounding the
                      building is a beautiful sight too. The tall building is a
                      prominent landmark of Thimphu with almost all sightseeing
                      tours of the city beginning with a visit to this beautiful
                      Dzong resplendent with a golden roof.
                    </li>
                    <li>
                      <strong>Chele La Pass - </strong> Bhutan, being a country
                      with a mountainous landscape, is linked with a series of
                      passes. Located at approximately 13,000 feet between the
                      valley of Paro and Haa, Chele La Pass is the highest
                      motorable road pass in Bhutan. The pass is famous for the
                      stunning Himalayan views it offers, especially Mt.
                      Jhomolari which is Bhutan’s most sacred peak at 22,000
                      feet, Tsherimgang mountains, Jichu Drake as well as views
                      of both the valleys, Paro and Haa. Just a two-hour drive
                      from the valley floor in Paro, you will reach Chele La
                      Pass, covered in untouched forests, home to thriving flora
                      and fauna. The surrounding area of this pass has several
                      ancient trails, perfect for hikers.
                    </li>
                    <li>
                      <strong>Pobjikha valley - </strong> Against the backdrop
                      of western slopes of the Black Mountains in Wangdue
                      Phodrang, lies the bowl-shaped Phobjikha valley that
                      offers breathtaking views of vast expanses of green
                      fields. It is home to the endangered black-necked crane
                      that migrates to this area during winters. Phobjikha
                      valley also called Gangtey, is one of the few glacial
                      valleys in the Kingdom of Bhutan. Bordering the Jigme
                      Singye Wangchuck National Park, the valley is located at
                      an altitude of 3000m above sea level, and hence
                      comparatively cooler. It boasts being the winter home to
                      black-necked cranes that fly from Tibet to escape the
                      harsh winter and also one of the most prominent
                      conservative sites of Bhutan. Relatively untouched by
                      outsiders, the use of electricity in the valley began only
                      a few years ago.
                    </li>
                  </ul>
                </div>
                <div className="container-fluid py-5">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="site-heading text-center">
                        <h2>
                          Branded Bhutan Tour <span>- The SIX SENSE</span>
                        </h2>
                        <h4>
                          <i>Next Level Luxury Sense</i>
                        </h4>
                      </div>
                    </div>
                  </div>

                  <div className="row py-3">
                    <img src={sixSenseBhutanTourPackagesImageURL} alt="..." />
                  </div>
                </div>

                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Packages</th>
                      <th>Duration</th>
                      <th>Hotel Brand</th>
                      <th>Price (Per Head)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Luxury Trip</td>
                      <td>5N/6D</td>
                      <td>
                        The SIX SENSE (Ultra Luxury)
                        <div className="reviews">
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                        </div>
                      </td>
                      <td>Rs 5,36,789/- </td>
                    </tr>
                  </tbody>
                </table>
                <div className="card mx-0 my-5">
                  <div className="card-header custHeaer">
                    Six Sense Bhutan Packages - An Ultra Luxury Five Star Brand
                  </div>
                  <div className="card-body">
                    <p>
                      Adorable can adjust your itinerary depending on your needs
                      & preference. We are happy to arrange your itinerary to
                      fit your interests, allowing you to explore and enjoy the
                      Kingdom of Bhutan at your own peace.
                    </p>
                    <h3 className="px-2">Unbeatable GEM of Bhutan</h3>
                    <p>
                      Travel back in time and take a journey that few people
                      have had the opportunity to experience. Druk Yul, Bhutan’s
                      official name, is known as the Land of the Dragon People
                      with a rich cultural heritage that has largely remained
                      hidden until its recent emergence onto the world stage.{" "}
                      <b>Your Six Senses GEM (Guest Experience Maker)</b> will
                      meet you upon arrival and accompany you on your khamsa
                      ‘royal stroll’ through the Kingdom, sharing insights to
                      the culture, history and local life of each valley as you
                      go. Discover the country’s breathtaking natural beauty,
                      meet and mingle with our Bhutanese friends as you embrace
                      the philosophy of a nation that is guided by the
                      principles of ‘Gross National Happiness’. Our khamsas are
                      designed with just the right balance of meaningful
                      encounters and experiences in mind along with a healthy
                      dose of fun. The following is only a guideline. Your GEM
                      will help to tailor your 5-day Bhutan itinerary to your
                      needs and interests each day.
                    </p>
                    <i>
                      <h3 style={{ fontSize: "1vw" }}>
                        Service available at Paro, Punakha, Thimphu, Bumthang
                        and Gangtey.
                      </h3>
                    </i>
                    <a href="tel://9883359713" className="btn btn-Warning">
                      Book Now
                    </a>
                  </div>
                </div>
              </div>

              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h2>
                        Branded Bhutan Tour <span>- The AMAN KORA</span>
                      </h2>
                      <h4>
                        <i>Stay with Trust</i>
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="row py-3">
                  <img src={amanKoraBhutanPackagesImageURL} alt="..." />
                </div>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Packages</th>
                      <th>Duration</th>
                      <th>Hotel Brand</th>
                      <th>Price (Per Head)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Premium Trip</td>
                      <td>5N/6D</td>
                      <td>
                        The AMAN KORA (Premium)
                        <div className="reviews">
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                        </div>
                      </td>
                      <td>Rs 4,70,389/- </td>
                    </tr>
                  </tbody>
                </table>
                <div className="card mx-0 my-5">
                  <div
                    className="card-header custHeaer"
                    style={{ fontSize: "1.2vw" }}
                  >
                    AMAN KORA Bhutan Packages - A Super Premium Five Star Brand
                  </div>
                  <div className="card-body">
                    <p>
                      Adorable can adjust your itinerary depending on your needs
                      & preference. We are happy to arrange your itinerary to
                      fit your interests, allowing you to explore and enjoy the
                      Kingdom of Bhutan at your own peace.
                    </p>
                    <h3 className="px-2">Unbeatable GEM of Bhutan</h3>
                    <p>
                      Travel back in time and take a journey that few people
                      have had the opportunity to experience. Druk Yul, Bhutan’s
                      official name, is known as the Land of the Dragon People
                      with a rich cultural heritage that has largely remained
                      hidden until its recent emergence onto the world stage.{" "}
                      <b>Your Six Senses GEM (Guest Experience Maker)</b> will
                      meet you upon arrival and accompany you on your khamsa
                      ‘royal stroll’ through the Kingdom, sharing insights to
                      the culture, history and local life of each valley as you
                      go. Discover the country’s breathtaking natural beauty,
                      meet and mingle with our Bhutanese friends as you embrace
                      the philosophy of a nation that is guided by the
                      principles of ‘Gross National Happiness’. Our khamsas are
                      designed with just the right balance of meaningful
                      encounters and experiences in mind along with a healthy
                      dose of fun. The following is only a guideline. Your GEM
                      will help to tailor your 5-day Bhutan itinerary to your
                      needs and interests each day.
                    </p>
                    <i>
                      <h3 style={{ fontSize: "1vw" }}>
                        Service available at Paro, Punakha, Thimphu, Bumthang
                        and Gangtey.
                      </h3>
                    </i>
                    <a href="tel://9883359713" className="btn btn-Warning">
                      Book Now
                    </a>
                  </div>
                </div>
              </div>

              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h2>
                        Our Bhutan <span>Tour Packages</span>
                      </h2>
                      <h4>Trending Bhutan Trip from BAGDOGRA Airport</h4>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div
                    id="carouselExampleControls"
                    className="carousel slide"
                    data-bs-ride="carousel"
                  >
                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        <Slider {...settings}>{trendingBhutanDataView}</Slider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h2>
                        Frequently Ask <span>Questions</span>
                      </h2>
                      <h4>Bhutan Trip Related Questions</h4>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div
                    className="accordion accordion-flush faQStyle"
                    id="accordionFlushExample"
                  >
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingOne">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseOne"
                          aria-expanded="false"
                          aria-controls="flush-collapseOne"
                        >
                          Is Bhutan visa free for Indian?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseOne"
                        className="accordion-collapse"
                        aria-labelledby="flush-headingOne"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Yes, Indian citizens do not require a visa to enter
                          Bhutan.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingTwo">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseTwo"
                          aria-expanded="false"
                          aria-controls="flush-collapseTwo"
                        >
                          How can I go to Bhutan from India?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingTwo"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          You can take a flight to Bagdogra (IXB). After that
                          enter to Bhutan by road via PHUENTSHOLING. This will
                          be an economical option. In terms of luxury option,
                          you can avail Direct Non Stop flight to PARO (PBH)
                          airport. Operated from three major cities DELHI,
                          KOLKATA & GUWAHATI.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingThree">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseThree"
                          aria-expanded="false"
                          aria-controls="flush-collapseThree"
                        >
                          Do Bhutanese Speak English?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseThree"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingThree"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Dzongkha is the national language of the Bhutan, which
                          is predominantly spoken in the western region of
                          Bhutan. However, there are 25 dialects spoken in
                          Bhutan. Bhutanese people speak English as it is the
                          medium of instruction in the schools. Hence it is easy
                          to talk to Bhutanese people if you are fluent in
                          English. The official documents, road signs, and even
                          the major national newspapers are published in English
                          making it easier for tourists to understand.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingFour">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFour"
                          aria-expanded="false"
                          aria-controls="flush-collapseFour"
                        >
                          Is Alcohol cheaper in Bhutan?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseFour"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingFour"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Yes, alcohol is cheaper in Bhutan, and the locally
                          made alcohol is good in taste, and these beverages act
                          as a great souvenir for the boozers. Do try out Ara,
                          the traditional hot alcoholic beverage consumed by the
                          majority of the population and is made of rice,
                          barley, wheat, or millet, and herbs which is either
                          fermented or diluted.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingFive">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFive"
                          aria-expanded="false"
                          aria-controls="flush-collapseFive"
                        >
                          Can I use Indian money in Bhutan Tour from Ahemdabad?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseFive"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingFive"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Yes, you can carry Indian Note to Bhutan. Except Rs
                          2000/- Note, which is not a legal tender in Bhutan?
                          However, many merchants in Bhutan now days accept Rs
                          2000/- Note.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingSix">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseSix"
                          aria-expanded="false"
                          aria-controls="flush-collapseFive"
                        >
                          How are the Bhutan Mobile / Internet Connectivity?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseSix"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingSix"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          ndian Mobile Operators provide the Global Roaming
                          Connectivity in Bhutan, However, it is expensive.
                          Please check with your operator about Global Roaming
                          Activation / Incoming and Out Going Charges. Tourists
                          can easily get the Bhutan Tourists SIM card (Tashi
                          Cell and BMobile ) at Rs 200/- with data option at Rs
                          99/- for 400 MB data.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingSeven">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseSeven"
                          aria-expanded="false"
                          aria-controls="flush-collapseSeven"
                        >
                          Can I get wine in Bhutan including imported Brands?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseSeven"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingSeven"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          In Paro Duty free shop, you can get various brands of
                          imported foreign Whisky, Wine, Vodka, Beer etc and
                          price is relatively cheaper compared to any Indian
                          Duty Free shop. In Thimphu, you can get the imported
                          wine brand, however in Paro and Punakha, you may get
                          the imported brands only in your hotel, not in any off
                          shop. However, in Bhutan, availability of local brand
                          wines is abundant and they are really worthy to buy.
                          While you are in Bhutan Package Tour, you must buy
                          Local Peach Wine and am confident you will love it.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container-fluid py-4">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h3>
                        <b>
                          Bhutan <span className="tomato">Theme Package</span>
                        </b>
                      </h3>
                      <h4>Similar Mountain Trip</h4>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div
                    id="carouselExampleControls"
                    className="carousel slide"
                    data-bs-ride="carousel"
                  >
                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        <Slider {...settings}>{themePackagesDataView}</Slider>
                      </div>
                    </div>
                  </div>

                  <br />
                </div>
              </div>

              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h3>
                        <b>
                          Bhutan <span className="tomato">Climate Guide</span>
                        </b>
                      </h3>
                      <h4>
                        <i>"Yearly Temperature Update"</i>
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table table-bordered table-striped table-condensed cf">
                    <thead>
                      <tr className="table-primary">
                        <th scope="col">Destination</th>
                        <th scope="col"></th>
                        <th scope="col">Jan</th>
                        <th scope="col">Feb</th>
                        <th scope="col">Mar</th>
                        <th scope="col">Apr</th>
                        <th scope="col">May</th>
                        <th scope="col">Jun</th>
                        <th scope="col">Jul</th>
                        <th scope="col">Aug</th>
                        <th scope="col">Sept</th>
                        <th scope="col">Oct</th>
                        <th scope="col">Nov</th>
                        <th scope="col">Dec</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="table-danger">
                        <th scope="row" rowspan="3">Paro</th>
                        <td rowspan="3">Min Temp(C)<br />Max Temp(C)<br />clear days</td>
                        <td rowspan="3">-1<br />11<br />26</td>
                        <td rowspan="3">1<br />11<br />23</td>
                        <td rowspan="3">4<br />14<br />25</td>
                        <td rowspan="3">7<br />17<br />25</td>
                        <td rowspan="3">10.5<br />20.4<br />22</td>
                        <td rowspan="3">13<br />21<br />14</td>
                        <td rowspan="3">15<br />22<br />14</td>
                        <td rowspan="3">14<br />22<br />16</td>
                        <td rowspan="3">13<br />21<br />20</td>
                        <td rowspan="3">8<br />19<br />27</td>
                        <td rowspan="3">3<br />16<br />27</td>
                        <td rowspan="3">-1<br />13<br />29</td>
                      </tr>
                      <tr></tr>
                      <tr></tr>
                      <tr></tr>

                      {/* <!-- Next set of data for Paro --> */}
                      <tr>
                        <th scope="row" rowspan="3">Thimphu</th>
                        <td rowspan="3">Min Temp(C)<br />Max Temp(C)<br />clear days</td>
                        <td rowspan="3">-2.6<br />13<br />27</td>
                        <td rowspan="3">0.6<br />14.4<br />23</td>
                        <td rowspan="3">4<br />13<br />25</td>
                        <td rowspan="3">7<br />16<br />25</td>
                        <td rowspan="3">9.5<br />20<br />22</td>
                        <td rowspan="3">12.5<br />21<br />21</td>
                        <td rowspan="3">14<br />22<br />14</td>
                        <td rowspan="3">14<br />21<br />17</td>
                        <td rowspan="3">12<br />21<br />20</td>
                        <td rowspan="3">7<br />19<br />27</td>
                        <td rowspan="3">2<br />15<br />28</td>
                        <td rowspan="3">-1.5<br />12<br />29</td>
                      </tr>
                      <tr></tr>
                      <tr></tr>
                      <tr></tr>
                      <tr className="table-danger">
                        <th scope="row" rowspan="3">Jakar</th>
                        <td rowspan="3">Min Temp(C)<br />Max Temp(C)<br />clear days</td>
                        <td rowspan="3">-3<br />9<br />29</td>
                        <td rowspan="3">-6<br />8<br />26</td>
                        <td rowspan="3">3<br />12<br />29</td>
                        <td rowspan="3">6<br />18.5<br />29</td>
                        <td rowspan="3">9.5<br />22.1<br />22</td>
                        <td rowspan="3">13<br />22<br />14</td>
                        <td rowspan="3">14<br />22<br />19</td>
                        <td rowspan="3">13<br />23<br />21</td>
                        <td rowspan="3">12<br />21<br />22</td>
                        <td rowspan="3">7<br />19<br />28</td>
                        <td rowspan="3">1<br />15<br />30</td>
                        <td rowspan="3">-2.5<br />12.5<br />30</td>
                      </tr>
                      <tr></tr>
                      <tr></tr>
                      <tr></tr>
                      <tr>
                        <th scope="row" rowspan="3">Bumthang</th>
                        <td rowspan="3">Min Temp(C)<br />Max Temp(C)<br />clear days</td>
                        <td rowspan="3">-3.7<br />11.1<br />29</td>
                        <td rowspan="3">-7<br />8<br />26</td>
                        <td rowspan="3">-5<br />4<br />29</td>
                        <td rowspan="3">-1<br />7<br />28</td>
                        <td rowspan="3">8.5<br />19.6<br />23</td>
                        <td rowspan="3">14<br />23<br />16</td>
                        <td rowspan="3">13<br />21<br />19</td>
                        <td rowspan="3">13<br />21<br />21</td>
                        <td rowspan="3">11<br />20<br />22</td>
                        <td rowspan="3">6<br />18<br />28</td>
                        <td rowspan="3">0<br />14<br />30</td>
                        <td rowspan="3">-3.5<br />11.5<br />30</td>
                      </tr>
                      <tr></tr>
                      <tr></tr>
                      <tr></tr>
                      <tr className="table-danger">
                        <th scope="row" rowspan="3">Punakha</th>
                        <td rowspan="3">Min Temp(C)<br />Max Temp(C)<br />clear days</td>
                        <td rowspan="3">4.9<br />16.8<br />29</td>
                        <td rowspan="3">7<br />18.7<br />27</td>
                        <td rowspan="3">10.5<br />22<br />29</td>
                        <td rowspan="3">13.8<br />24.7<br />29</td>
                        <td rowspan="3">16.5<br />25.8<br />19</td>
                        <td rowspan="3">19<br />23<br />16</td>
                        <td rowspan="3">20<br />27<br />20</td>
                        <td rowspan="3">19<br />26<br />22</td>
                        <td rowspan="3">18<br />26<br />23</td>
                        <td rowspan="3">15<br />25<br />28</td>
                        <td rowspan="3">9.5<br />21<br />29</td>
                        <td rowspan="3">6<br />18<br />30</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="container-fluid py-5">
                <div className="card">
                  <div
                    className="card-header custHeaer"
                    style={{ fontSize: "1.2vw" }}
                  >
                    Discover the Best Time to Book Bhutan Tour Package from
                    Ahemdabad with Adorable Vacation LLP
                  </div>
                  <div className="card-body">
                    <p>
                      Embarking on a Bhutan tour from Ahemdabad is a journey of a
                      lifetime, and choosing the right time to experience the
                      Land of Happiness is crucial to ensure a delightful and
                      hassle-free vacation. Adorable Vacation LLP presents you
                      with the ideal seasons to book your Bhutan package tour
                      from Ahemdabad, promising unforgettable memories and
                      immersive cultural experiences.
                    </p>
                    <span className="icon-img-color">
                      <i className="fa fa-sun-o" aria-hidden="true"></i>&nbsp;
                      <span className="black">
                        <strong>Summer Serenity (June to September)</strong>
                      </span>
                    </span>
                    <br />
                    <span>
                      <b>If you prefer sunny days and green landscapes,</b>{" "}
                      summer in Bhutan, from June to the end of September, is
                      the perfect time to visit. With temperatures ranging
                      between 20 to 25 degrees Celsius, the weather is warm with
                      a slight touch of humidity. Nights might get cold, making
                      it ideal to carry light woolen garments or jackets. The
                      surrounding cities and tourist spots are painted in lush
                      greenery, while the clear skies offer captivating views of
                      the snow-clad Himalayan peaks. Summer is a time to witness
                      the Haa Festival, celebrating Bhutanese food, culture,
                      tradition, and way of life. The lesser crowds during this
                      season allow you to explore various sightseeing spots in
                      tranquility, making it one of the best times to choose
                      Bhutan holidays from Ahemdabad.
                    </span>
                    <br />
                    <br />
                    <span className="icon-img-color">
                      <i className="fa fa-cloud" aria-hidden="true"></i>
                      &nbsp;
                      <span className="black">
                        <strong>Autumn Bliss (September to November)</strong>
                      </span>
                    </span>
                    <br />
                    <span>
                      <b>For a perfect Bhutan holiday tour from Ahemdabad,</b>{" "}
                      autumn takes center stage. The months of September to
                      November offer pleasant weather with temperatures ranging
                      from 10 to 15 degrees Celsius during the day, making
                      sightseeing and traveling comfortable. While nights might
                      be chilly, they are equally enjoyable. Autumn is the
                      festive season in Bhutan, where travelers can immerse
                      themselves in the authentic cultural heritage and witness
                      vibrant festivals like the Black-Necked Crane Festival,
                      Paro Tshechu, and Punakha Tshechu. Festivities come alive
                      with colorful flags fluttering in the wind, grand Thonkas,
                      and mesmerizing masked dances. To avoid peak season rush
                      and ensure availability of hotels and flight tickets,
                      Adorable Vacation suggests booking your Bhutan package within
                      the summer season.
                    </span>
                    <br />
                    <br />
                    <span className="icon-img-color">
                      <i className="fa fa-snowflake-o" aria-hidden="true"></i>
                      &nbsp;
                      <span className="black">
                        <strong>Winter Wonder (December to February)</strong>
                      </span>
                    </span>
                    <br />
                    <span>
                      <b>
                        While winter in Bhutan can be harsh with rapidly
                        dropping temperatures due to the high Himalayan
                        altitude,
                      </b>
                      it still offers a unique charm. Cities like Thimphu, Paro,
                      and Punakha get covered under a thick blanket of snow,
                      creating a picturesque winter wonderland. The northern
                      parts experience sub-zero temperatures, leading to school,
                      college, and government office holidays. Despite the harsh
                      weather, winter festivals like Monggar Tshechu, Trashigang
                      Tshechu, Lhuntse Tshechu, and Trongsa Tshechu add a touch
                      of cultural grandeur. If you wish to experience the snow
                      and cold while witnessing these festivals, booking your
                      Bhutan package tour during winter is an extraordinary
                      choice. Winter in Bhutan brings forth its own
                      awe-inspiring charm!
                    </span>
                    <br />
                    <br />
                    <span className="icon-img-color">
                      <i className="fa fa-tree" aria-hidden="true"></i>
                      &nbsp;
                      <span className="black">
                        <strong>Spring Spectacle (March to May)</strong>
                      </span>
                    </span>
                    <br />
                    <span>
                      <b>After a long and cold winter,</b> Bhutan springs to
                      life during March to May. The blooming Jacaranda and
                      Rhododendron flowers cover the roads, valleys, and
                      surroundings in beautiful hues of purple, creating a
                      heavenly ambience. With little to no rain, the sky becomes
                      crystal clear, marking the beginning of world-famous treks
                      like Jomolhari and Drukpath. Spring is also a time for
                      some of the most auspicious festivals like Paro Tshechu,
                      Gasa Tshechu, Rhododendron festivals, Talo Tshechu, etc.,
                      often graced by the presence of the King of Bhutan
                      himself. During these months, Bhutan is usually packed
                      with tourists, making it advisable to book your Bhutan
                      package from Ahemdabad as early as possible, along with
                      flights, to avoid peak season surcharges and ensure
                      accommodation and flight availability.
                    </span>
                    <br />
                    <br />
                  </div>
                </div>
              </div>

              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h3>
                        <b>
                          Bhutan <span className="tomato">Tour Reviews</span>
                        </b>
                      </h3>
                      <h4>
                        <i>"Your Experience is our Marketing"</i>
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="row row-cols-1 ">
                  <div id="testimonials">
                    <div className="testimonial-box-container">
                      {reviewersDataView}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <BookingBox />
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default AhemdabadToBhutanPackage;
