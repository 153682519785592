import React, { Component } from 'react';
import BookingBox from '../BookingBox';
import Slider from "react-slick";
import ApiURL from "../../../api/ApiURL";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InclusionExclusion from './InclusionExclusion_kashmir_pune';

class Kashmir_7N_8D_Package_Tour_From_Pune extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customActiveTab: 'tab1',
            daywiseActiveTab: 'day1'
        }
    }

    handleCustomTabClick = (tab) => {
        this.setState({
            customActiveTab: tab,
        });
    };

    handleDayWiseTabClick = (tab) => {
        this.setState({
            daywiseActiveTab: tab,
        });
    };
    handleClick = () => {
        const currentUrl = window.location.href;
        const pageTitle = document.title;
        const message = `Hi, Please send about the Tour Package details "${pageTitle}"`;

        const whatsappLink = `https://api.whatsapp.com/send?phone=+919147061467&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
        window.open(whatsappLink, '_blank');
    };

    render() {
        const { customActiveTab, daywiseActiveTab } = this.state;
        const packageTopBannerImageURL = ApiURL.Kashmir_Tour_Package_from_Pune + "/Kashmir-Package-Tour-from-Pune.webp";
        const packageTopBanner2ImageURL = ApiURL.Kashmir_Tour_Package_from_Pune + "/Kashmir-Tour-Packages-from-Pune.webp";
        const sightseen1ImageURL = ApiURL.Kashmir_Tour_Package_from_Pune + "/Kashmir-Packages-Tour-Cost.webp";
        const sightseen2ImageURL = ApiURL.Kashmir_Tour_Package_from_Pune + "/Kashmir-Package-Tour-Operator-in-Pune.webp";
        const sightseen3ImageURL = ApiURL.Kashmir_Tour_Package_from_Pune + "/Kashmir-Tours-from-Pune.webp";
        const sightseen4ImageURL = ApiURL.Kashmir_Tour_Package_from_Pune + "/Pune-to-Kashmir-Package-Tour.webp";
        const sightseen5ImageURL = ApiURL.Kashmir_Tour_Package_from_Pune + "/Pune-to-Kashmir-Package-Tour-Cost.webp";
        const sightseen6ImageURL = ApiURL.Kashmir_Tour_Package_from_Pune + "/Pune-to-Kashmir-Package-Tours.webp";
        const sightseen7ImageURL = ApiURL.Kashmir_Tour_Package_from_Pune + "/Pune-to-Kashmir-Tour-Package-Booking.webp";
        const sightseen8ImageURL = ApiURL.Kashmir_Tour_Package_from_Pune + "/Pune-to-Kashmir-Trip.webp";
        const sightseen9ImageURL = ApiURL.Kashmir_Tour_Package_from_Pune + "/Kashmir-from-Pune.webp";
        const sightseen10ImageURL = ApiURL.Kashmir_Tour_Package_from_Pune + "/Kashmir-Packages-from-Pune.webp";
        const sightseen11ImageURL = ApiURL.Kashmir_Tour_Package_from_Pune + "/Kashmir-Package-Tour.webp";
        const sightseen12ImageURL = ApiURL.Kashmir_Tour_Package_from_Pune + "/Kashmir-Package-Tour-Cost.webp";
        //day wise itenaryy
        const navDay1ImageURL = ApiURL.Kashmir_Tour_Package_from_Pune + "/7N-8D-Kashmir-Tulip-Festival-Package-Tour.webp";
        const navDay2ImageURL = ApiURL.Kashmir_Tour_Package_from_Pune + "/2024-Tulip-Garden-Kashmir-Package.webp";
        const navDay3ImageURL = ApiURL.Kashmir_Tour_Package_from_Pune + "/Customized-Kashmir-Tour.webp";
        const navDay4ImageURL = ApiURL.Kashmir_Tour_Package_from_Pune + "/Tulip-Festival-in-Kashmir.webp";
        const navDay5ImageURL = ApiURL.Kashmir_Tour_Package_from_Pune + "/Srinagar-Tulip-Garden-Tour.webp";
        const navDay6ImageURL = ApiURL.Kashmir_Tour_Package_from_Pune + "/Tulip-Garden-Tour-Package.webp";
        const navDay7ImageURL = ApiURL.Kashmir_Tour_Package_from_Pune + "/Book-Kashmir-Tulip-Trip.webp";
        const navDay8ImageURL = ApiURL.Kashmir_Tour_Package_from_Pune + "/Tulip-Festival-Special-2024.webp";
        const Topbanner0ImageURL = ApiURL.Kashmir_Tour_Package_from_Pune + "/Kashmir-Houseboat-Packages.webp";
        const Topbanner1ImageURL = ApiURL.Kashmir_Tour_Package_from_Pune + "/Kashmir-Adventure-Tour-Plan.webp";
        const Topbanner2ImageURL = ApiURL.Kashmir_Tour_Package_from_Pune + "/Kashmir-Gondola-Package-Tour.webp";
        const Topbanner3ImageURL = ApiURL.Kashmir_Tour_Package_from_Pune + "/Kashmir-Trekking-Packages.webp";
        const Topbanner4ImageURL = ApiURL.Kashmir_Tour_Package_from_Pune + "/Kashmir-White-River-Rafting-Plan.webp";
        const brandedTourackageImage_1_URL = ApiURL.Kashmir_Tour_Package_from_Pune + "/The-Khyber-Himalayan-Resort-&-Spa.webp";
        const brandedTourackageImage_2_URL = ApiURL.Kashmir_Tour_Package_from_Pune + "/The-LaLiT-Grand-Palace-Srinagar-Hotel.webp";
        const TrendingPackagesDataList = this.props.TrendingPackagesData;
        const hotPackagesView = TrendingPackagesDataList.length > 0 ?
            (
                TrendingPackagesDataList.map((dataItem, i) => {
                    const hotelImgURL = ApiURL.Kashmir_Tour_Package_from_Pune + "/" + dataItem.ImageName;
                    return (<div className="col-md-12 p-0" key={i}>
                        <div className="card h-100">
                            <img src={hotelImgURL} className="card-img-top" alt={dataItem.AllternateText} />
                            <div className="card-body">
                                <h6 className="card-title">{dataItem.Title} <span> &#8377; {dataItem.PricePerNight}</span></h6>
                                <p className="card-text">
                                    <i className="fa fa-building tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-utensils tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-car-side tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-male tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-camera tomato"></i>
                                </p>
                                <p className="card-text"><span>{dataItem.Description}</span></p>
                                <p>
                                    <span><b className="tomato">{dataItem.NoOfTours} </b>Tours</span> | &nbsp;
                                    <span><b className="tomato">{dataItem.NoOfDeparture} </b>Departures</span> | &nbsp;
                                    <span><b className="sdf-blue"> {dataItem.Include}</b></span>
                                </p>
                            </div>
                            <div className="card-footer">
                                <small className="text-muted"><strong className="tomato">{dataItem.MutedText}</strong></small>
                                <a href="#" className="btn btn-success btn-sm float-end" onClick={this.handleClick}>
                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                </a>
                            </div>
                        </div>
                    </div>)
                })
            ) : (<div></div>)


        const settings = {
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const reviewersDataList = this.props.ReviewersData;
        const reviewersDataView = reviewersDataList.map((dataItem, i) => {
            const reviewerImageURL = ApiURL.Kashmir_Tour_Package_from_Pune + "/" + dataItem.ImageName;
            return (
                <div className="testimonial-box" key={i}>
                    <div className="box-top">
                        <div className="profile">
                            <div className="profile-img">
                                <img src={reviewerImageURL} alt="..." />
                            </div>
                            <div className="name-user">
                                <strong>{dataItem.Name}</strong>
                                <span>{dataItem.Location}</span>
                            </div>
                        </div>
                        <div className="reviews">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </div>
                    </div>
                    <div className="client-comment">
                        <p>{dataItem.Comment}</p>
                    </div>
                </div>
            )
        })

        return (
            <>

                <div className="container-fluid">
                    <div className="row">
                        <img src={packageTopBannerImageURL} className="mobile-image2" alt="..." />
                    </div>
                </div>
                <div className="container-fluid custom-container py-3">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="container-fluid custom-container py-1">

                                        <h1 className="content-desktop" style={{ fontSize: "2vw" }}><center><strong>KASHMIR PACKAGE TOUR FROM PUNE </strong></center></h1>

                                        <h3 className="content-mobile" style={{ fontSize: "4vw" }}><center><strong>KASHMIR PACKAGE TOUR FROM PUNE </strong></center>
                                            <hr />
                                        </h3>
                                        <div className="row">
                                            <img src={packageTopBanner2ImageURL} className="mobile-image" alt="..." />
                                        </div>
                                        <hr />
                                        <div className="d-flex flex-column flex-sm-row align-items-sm-center px-2">
                                            <div className="font-lg">
                                                3 Destination:{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Srinagar (3N)
                                                </a>
                                                &nbsp;{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Gulmarg (1N)
                                                </a>{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Pahalgam (3N)
                                                </a>
                                            </div>
                                            <div className="reviews mx-5">
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                            </div>
                                        </div>
                                        <p className="p-2">
                                            If you are looking for a memorable <strong>Kashmir Package Tour From Pune</strong>, you have come to the right place!
                                        </p>
                                        <hr />
                                        <br />
                                    </div>
                                    <h2 className="content-desktop p-2" style={{ fontSize: "1.6vw" }}><b>Looking for Kashmir Tour Packages from Pune ?</b><a href='tel:9147061467' class="tomato"> To book a <strong>Kashmir Package from Pune</strong> ☎ CALL Mr. Soumya +91- 91470-61467</a></h2>
                                    <h2 className="content-mobile p-4" style={{ fontSize: "4.5vw" }}><b>Looking for Kashmir Tour Packages from Pune?</b><a href='tel:9147061467' class="tomato"> To book a <strong>Kashmir Package from Pune</strong> ☎ CALL Mr. Soumya +91- 91470-61467</a></h2>
                                    <br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> Explore the Enchanting Beauty of Kashmir from Pune with Adorable Vacation</h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}> Explore the Enchanting Beauty of Kashmir from Pune with Adorable Vacation</h4>
                                        </div>
                                        <div className="card-body">
                                            <p>Discover the hidden treasures of Kashmir, a true paradise on Earth, known for its unmatched beauty and peace. If you're looking for an unforgettable trip full of stunning views and rich culture, Adorable Vacation LLP offers the perfect <strong>Kashmir Tour from Pune</strong> to make your travel dreams come true.
                                            </p>
                                            <p>Begin a joyful adventure as you explore Kashmir, nestled in the Himalayas, with its scenic landscapes, clear lakes, lush valleys, and snowy mountains. It’s an ideal destination for nature lovers and adventure seekers.
                                            </p>
                                            <p>The journey from <strong>Pune to Kashmir Tour Package</strong> promises new experiences every day. Our tour includes famous attractions like the Mughal Gardens, Shankaracharya Temple, and Jamia Masjid, giving you a glimpse of Kashmir's rich culture.
                                            </p>
                                            <p>For adventure enthusiasts, Gulmarg Valley offers thrilling activities like skiing and trekking in the snow. Adorable Vacation ensures your Kashmir trip is not just a vacation but a memorable, soul-stirring experience.</p>
                                            <p>Enjoy the locals' warm hospitality, taste traditional Kashmiri food, and explore vibrant markets filled with beautiful handicrafts. Our <strong>Kashmir Package Tour from Pune</strong> provides the perfect mix of relaxation and exploration, ensuring you make lasting memories.</p>
                                            <p>Adorable Vacation offers comfortable accommodations, guided tours, and smooth transportation, focusing on your safety and comfort so you can fully enjoy the beauty of Kashmir.</p>
                                            <p>Book your <strong>Kashmir Trip</strong> with Adorable Vacation LLP and experience the magic of this breathtaking destination.</p>
                                            <p><b>Apart from that, we also provide,</b></p>
                                            <a href='/kashmir-offbeat-package-tour-with-baisaran-valley-doodhpathri-yusmarg' target='_blank'><b className="tomato">Kashmir Package Tour with Doodhpathri Yusmarg</b></a>, <a href='/kashmir-offbeat-package-tour-with-gurez-valley' target='_blank'><b className="tomato">Kashmir Package Tour with Gurez Valley</b></a>,
                                            <a href='/kashmir-offbeat-package-tour-with-sinthan-top-aru-valley-betab-valley-chandanbari-pahalgam-sonmarg' target='_blank'><b className="tomato">Kashmir Offbeat Package Tour with Sinthan Top</b></a>,
                                            <a href='/kashmir-package-tour-with-vaishno-devi-katra-pahalgam-gulmarg-srinagar' target='_blank'><b className="tomato">Kashmir Package Tour with Vaishno Devi</b></a>,
                                            <a href='/kashmir-tulip-garden-festival-package-tour' target='_blank'><b className="tomato">Kashmir Package Tour with Tulip Package</b></a>,
                                            <a href='/kashmir-package-tour-from-kolkata-with-srinagar-gulmarg-pahalgam-sonmarg' target='_blank'><b className="tomato">Kashmir Package Tour from Kolkata </b></a>,
                                            <a href='/kashmir-package-tour-from-surat' target='_blank'><b className="tomato">Kashmir Package Tour from Surat </b></a>,
                                            <a href='/kashmir-package-tour-from-chennai' target='_blank'><b className="tomato">Kashmir Package Tour from Chennai </b></a>,
                                            <a href='/kashmir-package-tour-from-bangalore' target='_blank'><b className="tomato">Kashmir Package Tour from Bangalore </b></a>
                                            <br /><br />
                                            <table className="image-table">
                                                <tbody>
                                                    <tr>
                                                        <td className="image-cell">
                                                            <img src={sightseen1ImageURL} alt="kashmir tour packages" />
                                                            <img src={sightseen2ImageURL} alt="kashmir trip from srinagar airport" />
                                                            <img src={sightseen3ImageURL} alt="kashmir package tour cost from srinagar airport" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <br />
                                        </div>
                                    </div>
                                    <br /> <br /> <br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>During Pune to Kashmir Tour Package Booking : 7N | 8D</strong></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>During Pune to Kashmir Tour Package Booking : 7N | 8D</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <p><strong>Places Covered in the Pune to Kashmir Package</strong> : Srinagar Houseboat 1N | Gulmarg 1N | Pahalgam 2N | Srinagar 3N </p>
                                            <p><b>Pune to <strong>Kashmir Tour Packages</strong> Pickup & Drop</b> Sheikh Ul Alam International Airport, Srinagar [SXR]</p>
                                            <br /><br />
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <tbody>
                                                        <tr>
                                                            <td><strong>Kashmir Trip from Pune </strong>Pickup & Drop</td>
                                                            <td>Sheikh Ul Alam International Airport, Srinagar [SXR]</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Kashmir Packages from Pune Duration</strong></td>
                                                            <td>07 Nights | 08 Days</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Pune to Kashmir Package Tour Cost </strong></td>
                                                            <td>Rs. 28,100/Based on 8 Pax</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Srinagar</strong></td>
                                                            <td>1,585 m | Coordinates - 34.0837° N, 74.7973° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Gulmarg</strong></td>
                                                            <td>2,650 m | Coordinates - 34.0484° N, 74.3805° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Sonmarg</strong></td>
                                                            <td>2,730 m | Coordinates - 34.3032° N, 75.2931° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Pahalgam</strong></td>
                                                            <td>2,740 m | Coordinates - 34.0161° N, 75.3150° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Betaab Valley</strong></td>
                                                            <td>2,393 m | Coordinates - 34.0541° N, 75.3639° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Aru Valley</strong></td>
                                                            <td>2,414 m | Coordinates - 34.0922° N, 75.2632° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Chandanwadi</strong></td>
                                                            <td>3,288 m | Coordinates - 34.2122° N, 73.9295° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Doodhpathri</strong></td>
                                                            <td>2,730 m | Coordinates - 33.8509° N, 74.5630° E</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <br /><br /><br />
                                            <table className="image-table">
                                                <tbody>
                                                    <tr>
                                                        <td className="image-cell">
                                                            <img src={sightseen4ImageURL} alt="kashmir package tour from srinagar" />
                                                            <img src={sightseen5ImageURL} alt="kashmir package price from srinagar airport" />
                                                            <img src={sightseen6ImageURL} alt="kashmir packages from srinagar airport" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <br />

                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    {/* start short itinerary */}

                                    <div className="card">
                                        <div className="card-body">
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr className="table-danger">
                                                        <th>DAY-BY-DAY SUMMARY FOR KASHMIR TOUR PACKAGES FROM PUNE</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingOne"
                                                        >
                                                            <a href="#day1" style={{ color: 'black' }}><b>DAY 1 → </b>
                                                                <span>
                                                                    ARRIVE IN SRINAGAR, ENJOY LOCAL SIGHTSEEING
                                                                </span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingTwo"
                                                        >
                                                            <a href="#day2" style={{ color: 'black' }}><b>DAY 2 → </b>
                                                                <span>TAKE A FULL-DAY TRIP TO GULMARG </span>
                                                            </a>

                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingThree"
                                                        >
                                                            <a href="#day3" style={{ color: 'black' }}><b>DAY 3 → </b>
                                                                <span>TRAVEL FROM GULMARG TO PAHALGAM AND STAY </span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingFour"
                                                        >
                                                            <a href="#day4" style={{ color: 'black' }}><b>DAY 4 → </b>
                                                                <span>EXPLORE PAHALGAM, VISITING BETAAB VALLEY AND MORE</span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingFive"
                                                        >
                                                            <a href="#day5" style={{ color: 'black' }}><b>DAY 5 → </b>
                                                                <span>TRANSFER BACK TO SRINAGAR</span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingSix"
                                                        >
                                                            <a href="#day6" style={{ color: 'black' }}><b>DAY 6 → </b>
                                                                <span>ENJOY A DAY TRIP TO DOODHPATHRI</span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingSeven"
                                                        >
                                                            <a href="#day7" style={{ color: 'black' }}><b>DAY 7 → </b>
                                                                <span>TAKE A DAY TRIP TO SONMARG </span>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingEight"
                                                        >
                                                            <a href="#day8" style={{ color: 'black' }}><b>DAY 8 → </b>
                                                                <span>DEPART FROM SRINAGAR AIRPORT </span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    {/* <!--end Short description--> */}
                                    <br /><br /><br />

                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>WHAT ARE THE BEST AREAS TO STAY DURING A PUNE TO KASHMIR PACKAGE TOUR?</b></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>WHAT ARE THE BEST AREAS TO STAY DURING A PUNE TO KASHMIR PACKAGE TOUR?</b></h4>
                                        </div>
                                        <div className="card-body">
                                            <p>When planning a <strong>Pune to Kashmir Trip</strong>, picking the right place to stay is key to having a great experience. One of the top areas is Srinagar, the summer capital of Jammu and Kashmir. Staying on houseboats on Dal Lake offers a unique experience, along with peaceful shikara rides. The beautiful gardens of Nishat Bagh and Shalimar Bagh add to the calm atmosphere.</p>
                                            <p>Another great option is Gulmarg, famous for its beautiful scenery and being a top skiing spot. The meadows and the Gulmarg Gondola, one of the highest cable cars in the world, make it perfect for nature lovers and adventurers.
                                            </p>
                                            <p>For those who prefer peace and quiet, Pahalgam is a charming town with green landscapes and the Lidder River. It's a good base for exploring the nearby Aru Valley and Betaab Valley.
                                            </p>

                                            <p>When booking your <strong>Pune to Kashmir Package</strong>, consider staying in these areas for a varied and magical experience. Whether you choose the houseboats in Srinagar, the adventure in Gulmarg, or the serenity of Pahalgam, each location offers something special to make your trip unforgettable.
                                            </p>
                                        </div>
                                    </div>
                                    <br /><br /><br />

                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>EXCITING ADVENTURE ACTIVITIES ON YOUR</b> <strong>KASHMIR PACKAGE TOUR FROM PUNE</strong></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>EXCITING ADVENTURE ACTIVITIES ON YOUR</b> <strong>KASHMIR PACKAGE TOUR FROM PUNE</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <p><b>•	Shikara Ride on Dal Lake</b>: Enjoy a relaxing boat ride on Dal Lake, surrounded by beautiful scenery. It’s a must-do activity during your trip.</p>
                                            <p><b>•	Stay in a Houseboat</b>: Experience staying in a houseboat on Dal Lake in Srinagar. Enjoy stunning views of the mountains and the peaceful atmosphere for a unique vacation experience.</p>
                                            <p><b>•	Skiing in Gulmarg</b>: Whether you're new to skiing or an expert, Gulmarg offers thrilling skiing opportunities on its snow-covered slopes. There are facilities to help you learn or improve your skills.</p>
                                            <p><b>•	Try Local Kashmiri Cuisine</b>: Taste delicious Kashmiri dishes like Rogan Josh, Nardoo Yakhni, Paneer Chaman, Dum Aloo, and Gushtaba. Don't forget to try the traditional Kashmiri Kahwa tea.</p>
                                            <p><b>•	Trek to Vaishno Devi Temple</b>: Go on a trek to the Vaishno Devi Temple, located in a cave. Enjoy the spiritual experience and the beautiful natural surroundings of this sacred site.</p>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>KASHMIR TOUR PACKAGE COST FROM PUNE</strong></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>KASHMIR TOUR PACKAGE COST FROM PUNE</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <thead className="cf">
                                                        <tr className="table-danger">
                                                            <th>TOUR DURATION</th>
                                                            <th>2 PAX</th>
                                                            <th>4 PAX</th>
                                                            <th>6 PAX</th>
                                                            <th>8 PAX</th>
                                                            <th>BOOK NOW</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td data-title="TOUR DURATION">4N/5D</td>
                                                            <td data-title="2 ADULTS">₹ 20,500/-</td>
                                                            <td data-title="4 ADULTS">₹ 18,300/-</td>
                                                            <td data-title="6 ADULTS">₹ 16,600/-</td>
                                                            <td data-title="8 ADULTS">₹ 16,400/-</td>
                                                            <td data-title="Book Now"><a href="#" class="btn btn-success" onClick={this.handleClick}>Book Now</a></td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="TOUR DURATION">5N/6D</td>
                                                            <td data-title="2 ADULTS">₹ 24,700/-</td>
                                                            <td data-title="4 ADULTS">₹ 22,000/-</td>
                                                            <td data-title="6 ADULTS">₹ 19,800/-</td>
                                                            <td data-title="8 ADULTS">₹ 20,800/-</td>
                                                            <td data-title="Book Now"><a href="#" class="btn btn-success" onClick={this.handleClick}>Book Now</a></td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="TOUR DURATION">6N/7D</td>
                                                            <td data-title="2 ADULTS">₹ 30,900/-</td>
                                                            <td data-title="4 ADULTS">₹ 27,400/-</td>
                                                            <td data-title="6 ADULTS">₹ 24,700/-</td>
                                                            <td data-title="8 ADULTS">₹ 24,500/-</td>
                                                            <td data-title="Book Now"><a href="#" class="btn btn-success" onClick={this.handleClick}>Book Now</a></td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="TOUR DURATION">7N/8D</td>
                                                            <td data-title="2 ADULTS">₹ 35,600/-</td>
                                                            <td data-title="4 ADULTS">₹ 30,800/-</td>
                                                            <td data-title="6 ADULTS">₹ 28,300/-</td>
                                                            <td data-title="8 ADULTS">₹ 28,100/-</td>
                                                            <td data-title="Book Now"><a href="#" class="btn btn-success" onClick={this.handleClick}>Book Now</a></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>WHAT ARE THE FAMOUS LAKES TO EXPLORE ON YOUR <strong>KASHMIR TOUR FROM PUNE</strong> ?</h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>WHAT ARE THE FAMOUS LAKES TO EXPLORE ON YOUR <strong>KASHMIR TOUR FROM PUNE</strong> ?</h4>
                                        </div>
                                        <div className="card-body">
                                            <p><b>Dal Lake </b>:
                                                Start your Kashmir tour with Dal Lake, often called the "Jewel of Kashmir." Enjoy a peaceful shikara ride among the floating gardens and houseboats, creating unforgettable memories. Our Kashmir Package Tour Operator ensure you fully experience the calm beauty of Dal Lake.
                                            </p>
                                            <p><b>Wular Lake </b> :
                                                Visit Wular Lake, the largest freshwater lake in India, surrounded by snowy mountains. Take a boat ride to enjoy the stunning views and spot a variety of birds.
                                            </p>
                                            <p><b>Nagin Lake </b>:
                                                Nagin Lake, next to Dal Lake, provides a quieter, more intimate experience. Explore its calm waters, surrounded by willow and poplar trees, with our specially crafted Kashmir from Pune.
                                            </p>
                                            <p><b>Surinsar Lake </b>:
                                                Located about 42 kilometers from Jammu, Surinsar Lake is one of the most scenic lakes near Jammu and a perfect addition to your tour.
                                            </p>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>WHEN IS THE BEST TIME TO <strong>KASHMIR PACKAGE TOUR BOOKING FROM PUNE</strong>?</h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>WHEN IS THE BEST TIME TO <strong>KASHMIR PACKAGE TOUR BOOKING FROM PUNE</strong>?</h4>
                                        </div>
                                        <div className="card-body">
                                            <p>Kashmir, known as "paradise on Earth," is beautiful all year round. However, the best time to visit from Pune is between late March and October, during the seasons of spring, summer, and autumn.</p>
                                            <p><b>SPRING [MARCH-EARLY MAY] </b>
                                                In spring, Kashmir comes to life with blooming flowers and green meadows as the snow melts. The temperature is pleasant, making it a great time for nature lovers to explore the valley as it refreshes after winter.
                                            </p>
                                            <p><b>SUMMER [EARLY MAY-LATE AUGUST] </b>
                                                During summer, the weather is mild and skies are clear, perfect for outdoor activities like trekking, golfing, and shikara rides on Dal Lake. It's an excellent time for family vacations, with a cool breeze and beautiful mountain views.
                                            </p>
                                            <p><b>AUTUMN [SEPTEMBER-NOVEMBER] </b>
                                                Autumn is magical in Kashmir, with the landscape turning red and gold as Chinar trees shed their leaves. This season offers a romantic, peaceful atmosphere, and you can also visit the famous Saffron fields of Pampore, known for their beauty and rare spices.
                                            </p>
                                            <p><b>WINTER [DECEMBER-EARLY MARCH] </b>
                                                If you love snow, visit Kashmir in winter for a snowy wonderland. The valley is covered in snow, offering activities like skiing and snowboarding. The frozen Dal Lake provides a special shikara ride experience amidst the snowy landscape.
                                            </p>
                                            <p>In summary, Kashmir is a year-round destination, offering different experiences in each season, from the colors of spring and summer to the romantic autumn and snowy winter.</p>
                                            <br /><br />
                                            <h4><b>MONTH-WISE TEMPERATURE CHART FOR KASHMIR PACKAGES FROM PUNE</b></h4><br />
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <thead className="cf">
                                                        <tr className="table-danger">
                                                            <th>Months</th>
                                                            <th>Temp. (Min - Max)</th>
                                                            <th>Season</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td data-title="Months">March - May</td>
                                                            <td data-title="Temp. (Min - Max)">10°C - 20°C</td>
                                                            <td data-title="Season">Spring</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Months">June - August</td>
                                                            <td data-title="Temp. (Min - Max)">15°C - 30°C</td>
                                                            <td data-title="Season">Summer</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Months">September - November</td>
                                                            <td data-title="Temp. (Min - Max)">10°C - 25°C</td>
                                                            <td data-title="Season">Autumn</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Months">December - February</td>
                                                            <td data-title="Temp. (Min - Max)">-2°C - 10°C</td>
                                                            <td data-title="Season">Winter</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>MUST-VISIT TOURIST ATTRACTIONS IN THE PUNE TO <strong>KASHMIR TOUR PACKAGE</strong></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>MUST-VISIT TOURIST ATTRACTIONS IN THE PUNE TO <strong>KASHMIR TOUR PACKAGE</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <p>Starting your journey from <strong>Pune to Kashmir Trip</strong> is a dream come true for any traveler. A well-planned <strong>Customized Kashmir Tour</strong> offers a mix of stunning scenery, rich culture, and memorable experiences. Here are the must-visit attractions that will make your trip unforgettable:</p>
                                            <p><b>Srinagar</b>:
                                                The heart of Kashmir, Srinagar, is a beautiful city with its peaceful Dal Lake and Mughal Gardens. Enjoy the charm of life by the Jhelum River, feeling like you're in paradise.
                                            </p>
                                            <p><b>Gulmarg</b>:
                                                Known as the "Meadow of Flowers," Gulmarg is a breathtaking destination featuring a renowned ski resort. With spectacular views of Nanga Parbat and Mt. Apharwat, it’s a must-visit for nature lovers and adventure seekers.
                                            </p>

                                            <p><b>Betaab Valley</b>:
                                                Made famous by the Bollywood movie "Betaab," this picturesque valley is surrounded by lush forests and meadows. It's perfect for a peaceful picnic or a tranquil weekend retreat.
                                            </p>

                                            <p><b>Pahalgam</b>:
                                                Also known as the "Valley of the Shepherds," Pahalgam is famous for its scenic Sheshnag Lake and dense pine forests. It’s a prime spot for trekking, zorbing, and other thrilling adventure activities.
                                            </p>

                                            <p><b>Pulwama</b>:
                                                Pulwama, often referred to as the "Rice Bowl of Kashmir," is celebrated for its vibrant saffron fields and apple orchards. It's a fantastic location for trekking, snowboarding, skiing, and admiring the scenic beauty.
                                            </p>

                                            <p><b>Sonamarg</b>:
                                                Nestled in the Himalayas, Sonamarg is known for its striking natural beauty. A pony ride through its meadows, snow-capped mountains, and flowing rivers makes for an unforgettable experience.
                                            </p>

                                            <p><b>Pari Mahal</b>:
                                                Also called the "Palace of Fairies," Pari Mahal is a stunning seven-terraced garden offering mesmerizing views of Dal Lake and the surrounding mountains. Its rich history and unique architecture make it a must-see.
                                            </p>

                                            <p><b>Shalimar Bagh</b>:
                                                Shalimar Bagh, a grand Mughal Garden in Srinagar, is famous for its magnificent terraces, vibrant flowerbeds, and elegant fountains. It’s an essential stop to experience the grandeur of Mughal architecture during your Kashmir tour.
                                            </p>

                                            <p><b>Shankaracharya Temple</b>:
                                                Perched on a hilltop, this temple dedicated to Lord Shiva offers awe-inspiring views of Srinagar. The 243-step climb is well worth it to take in the breathtaking scenery and the temple’s serene atmosphere.
                                            </p>
                                            <p>Book your <strong>Kashmir Tulip Festival Package Tour</strong> with Adorable Vacation LLP and let the beauty of Kashmir captivate your heart!</p>
                                            <br /><br />
                                            <table className="image-table">
                                                <tbody>
                                                    <tr>
                                                        <td className="image-cell">
                                                            <img src={sightseen7ImageURL} alt="kashmir tour packages" />
                                                            <img src={sightseen8ImageURL} alt="kashmir trip from srinagar airport" />
                                                            <img src={sightseen9ImageURL} alt="kashmir package tour cost from srinagar airport" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <br />
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>WHAT IS THE BEST WAY TO TRAVEL TO <strong>KASHMIR TOUR FROM PUNE</strong> BY FLIGHT?</b></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>WHAT IS THE BEST WAY TO TRAVEL TO <strong>KASHMIR TOUR FROM PUNE</strong> BY FLIGHT?</b></h4>
                                        </div>
                                        <div className="card-body">
                                            <p>Planning a peaceful getaway from Pune to the beautiful valleys of Kashmir? Here’s how you can make your journey smooth and enjoyable:</p>
                                            <p><b>Kashmir Tour from Pune by Flight</b>:
                                                The quickest and easiest way to travel from <strong>Kashmir Tour from Pune by Flight</strong>. The nearest airport to Srinagar, the summer capital of Jammu and Kashmir, is Sheikh ul-Alam International Airport (SXR). Once you arrive at this airport, you'll be ready to explore the stunning landscapes of Kashmir. Several airlines, such as IndiGo, SpiceJet, Vistara, Air India Express, and Air India, offer direct flights from Pune to Srinagar, making your trip comfortable and time-saving.
                                            </p>
                                            <h5>POPULAR FLIGHTS FROM KASHMIR TOUR PACKAGES FROM PUNE WITH AIRFARE</h5><br />
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <thead className="cf">
                                                        <tr className="table-danger">
                                                            <th>Flight Operator</th>
                                                            <th>Departure from Pune</th>
                                                            <th>Arrival at Srinagar</th>
                                                            <th>Stopover</th>
                                                            <th>Travel Time</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td data-title="Flight Operator">Air India Express</td>
                                                            <td data-title="Departure">06:40</td>
                                                            <td data-title="Arrival">12:30</td>
                                                            <td data-title="Stopover">Delhi</td>
                                                            <td data-title="Travel Time">5 hours 50 minutes</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Flight Operator">IndiGo</td>
                                                            <td data-title="Departure">04:40</td>
                                                            <td data-title="Arrival">09:05</td>
                                                            <td data-title="Stopover">Ahmedabad</td>
                                                            <td data-title="Travel Time">4 hours 25 minutes</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Flight Operator">SpiceJet</td>
                                                            <td data-title="Departure">07:20</td>
                                                            <td data-title="Arrival">12:25</td>
                                                            <td data-title="Stopover">Delhi</td>
                                                            <td data-title="Travel Time">5 hours 05 minutes</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Flight Operator">Air India</td>
                                                            <td data-title="Departure">07:40</td>
                                                            <td data-title="Arrival">13:10</td>
                                                            <td data-title="Stopover">Delhi</td>
                                                            <td data-title="Travel Time">5 hours 30 minutes</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div className="card-header custHeaer">
                                            <h4 className='content-desktop' style={{ fontSize: "1.5vw" }}>TRAVELING TO <strong>KASHMIR TRIP FROM PUNE</strong> BY TRAIN</h4>
                                            <h4 className='content-mobile2' style={{ fontSize: "3.5vw" }}>TRAVELING TO <strong>KASHMIR TRIP FROM PUNE</strong> BY TRAIN</h4>
                                        </div>
                                        <div className="card-body">
                                            <p>There is no direct train to <strong>Kashmir from Pune</strong>. However, you can take a train to Jammu Tawi, the nearest major railway station. From Jammu, you can either hire a taxi or take a bus to Srinagar, which is about a 9-10 hour drive. Another option is to take a train to Udhampur and then travel to Srinagar.</p>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div className="card-header custHeaer">
                                            <h4 className='content-desktop' style={{ fontSize: "1.5vw" }}>TRAVELING TO <strong>KASHMIR PACKAGE FROM PUNE</strong> BY ROAD</h4>
                                            <h4 className='content-mobile2' style={{ fontSize: "3.5vw" }}>TRAVELING TO <strong>KASHMIR PACKAGE FROM PUNE</strong> BY ROAD</h4>
                                        </div>
                                        <div className="card-body">
                                            <p>If you enjoy road trips, you can drive from <strong>Pune to Kashmir Tour</strong>. The route could be Pune - Mumbai – Surat – Ahmedabad - Udaipur - Jaipur - Delhi - Jammu - Srinagar. It’s a long journey, so plan for rest stops along the way.</p>
                                            <br />
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <thead className="cf">
                                                        <tr className="table-danger">
                                                            <th>Journey Days</th>
                                                            <th>Route From</th>
                                                            <th>Route To</th>
                                                            <th>National Highway</th>
                                                            <th>Distance</th>
                                                            <th>Journey Time</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td data-title="Journey Days">Day 1</td>
                                                            <td data-title="Route From">Pune</td>
                                                            <td data-title="Route To">Udaipur</td>
                                                            <td data-title="NH">Via NH 48</td>
                                                            <td data-title="Distance">905.2 Km</td>
                                                            <td data-title="Journey Time">16 hr 33 min</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Journey Days">Day 2</td>
                                                            <td data-title="Route From">Udaipur</td>
                                                            <td data-title="Route To">Jaipur</td>
                                                            <td data-title="NH">Via NH 48</td>
                                                            <td data-title="Distance">396.2 Km</td>
                                                            <td data-title="Journey Time">6 hr 54 min</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Journey Days">Day 3</td>
                                                            <td data-title="Route From">Jaipur</td>
                                                            <td data-title="Route To">Delhi</td>
                                                            <td data-title="NH">Via NE 4</td>
                                                            <td data-title="Distance">308.2 Km</td>
                                                            <td data-title="Journey Time">5 hr 18 min</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Journey Days">Day 4</td>
                                                            <td data-title="Route From">Delhi</td>
                                                            <td data-title="Route To">Jammu</td>
                                                            <td data-title="NH">Via NH 44</td>
                                                            <td data-title="Distance">586.2 Km</td>
                                                            <td data-title="Journey Time">10 hr 38 min</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Journey Days">Day 5</td>
                                                            <td data-title="Route From">Jammu</td>
                                                            <td data-title="Route To">Srinagar</td>
                                                            <td data-title="NH">Via NH 244A and NH 44</td>
                                                            <td data-title="Distance">244.5 Km</td>
                                                            <td data-title="Journey Time">5 hr 47 min</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div className="card-header custHeaer">
                                            <h4 className='content-desktop' style={{ fontSize: "1.5vw" }}><b>FAMOUS GARDENS TO VISIT ON A </b><strong>KASHMIR TOUR PACKAGE COST FROM PUNE</strong></h4>
                                            <h4 className='content-mobile2' style={{ fontSize: "3.5vw" }}><b>FAMOUS GARDENS TO VISIT ON A </b> <strong>KASHMIR TOUR PACKAGE COST FROM PUNE</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <p><b>1. Shalimar Bagh</b> :
                                                This beautiful Mughal garden, built in the 17th century, has terraced lawns, flowing water, and vibrant flowers. It's a must-see on your <strong>Kashmir Tours from Pune</strong>.
                                            </p>
                                            <p><b>2. Nishat Bagh</b>:
                                                Known as the 'Garden of Joy,' Nishat Bagh offers stunning views of Dal Lake. It’s a perfect place to relax and enjoy Kashmir's natural beauty, making it a key stop on your trip.
                                            </p>
                                            <p><b>3. Chashme Shahi</b>:
                                                Located in the Zabarwan Range, this garden is famous for its refreshing natural spring. It's a peaceful spot to explore while visiting Kashmir.
                                            </p>
                                            <p><b>4. Tulip Garden (Indira Gandhi Memorial Tulip Garden)</b>:
                                                If you visit in spring, don't miss the Tulip Garden. The vibrant colors of the tulips against the backdrop of the Zabarwan Range are a breathtaking sight.
                                            </p>
                                            <p>These beautiful gardens are must-visit spots to include in your <strong>Kashmir Tour Package Itinerary from Pune</strong> when planning a trip from Pune.</p>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div className="card-header custHeaer">
                                            <h4 className='content-desktop' style={{ fontSize: "1.5vw" }}><b>DISTANCES TO NOTE FOR KASHMIR TRIP BOOKING</b></h4>
                                            <h4 className='content-mobile2' style={{ fontSize: "3.5vw" }}><b>DISTANCES TO NOTE FOR KASHMIR TRIP BOOKING</b></h4>
                                        </div>
                                        <div className="card-body">
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <thead class="cf">
                                                        <tr className="table-danger">
                                                            <th className="text-center">From</th>
                                                            <th className="text-center">To</th>
                                                            <th className="text-center">Distance</th>
                                                            <th className="text-center">Duration</th>
                                                            <th className="text-center">Route</th>
                                                        </tr>
                                                    </thead>
                                                    <tr>
                                                        <td data-title="From">
                                                            <div style={{ display: 'inline-block' }}>Pune</div>
                                                        </td>
                                                        <td data-title="To">
                                                            <div style={{ display: 'inline-block' }}>Srinagar</div>
                                                        </td>
                                                        <td data-title="Distance">2,245.6 km</td>
                                                        <td data-title="Duration">
                                                            <div style={{ display: 'inline-block' }}>41 hours</div>
                                                        </td>
                                                        <td data-title="Route">
                                                            <div style={{ display: 'inline-block' }}>Via NH 44</div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td data-title="From">
                                                            <div style={{ display: 'inline-block' }}>Delhi</div>
                                                        </td>
                                                        <td data-title="To">
                                                            <div style={{ display: 'inline-block' }}>Srinagar</div>
                                                        </td>
                                                        <td data-title="Distance">812.5 km</td>
                                                        <td data-title="Duration">
                                                            <div style={{ display: 'inline-block' }}>18 hr 22 min</div>
                                                        </td>
                                                        <td data-title="Route">
                                                            <div style={{ display: 'inline-block' }}>Via NH 44</div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td data-title="From">
                                                            <div style={{ display: 'inline-block' }}>Mumbai</div>
                                                        </td>
                                                        <td data-title="To">
                                                            <div style={{ display: 'inline-block' }}>Srinagar</div>
                                                        </td>
                                                        <td data-title="Distance">2,169.8 km</td>
                                                        <td data-title="Duration">
                                                            <div style={{ display: 'inline-block' }}>42 hours</div>
                                                        </td>
                                                        <td data-title="Route">
                                                            <div style={{ display: 'inline-block' }}>Via NH 48</div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td data-title="From"><div style={{ display: 'inline-block' }}>Jammu</div></td>
                                                        <td data-title="To"><div style={{ display: 'inline-block' }}>Srinagar</div></td>
                                                        <td data-title="Distance">266.5 km</td>
                                                        <td data-title="Duration"><div style={{ display: 'inline-block' }}>8 hours 14 minutes</div></td>
                                                        <td data-title="Route"><div style={{ display: 'inline-block' }}>Via NH 44</div></td>
                                                    </tr>
                                                    <tr>
                                                        <td data-title="From"><div style={{ display: 'inline-block' }}>Srinagar</div></td>
                                                        <td data-title="To"><div style={{ display: 'inline-block' }}>Gulmarg</div></td>
                                                        <td data-title="Distance">49.5 km</td>
                                                        <td data-title="Duration"><div style={{ display: 'inline-block' }}>1 hour 30 minutes</div></td>
                                                        <td data-title="Route"><div style={{ display: 'inline-block' }}>Via Srinagar - Tangmarg Rd</div></td>
                                                    </tr>
                                                    <tr>
                                                        <td data-title="From"><div style={{ display: 'inline-block' }}>Srinagar</div></td>
                                                        <td data-title="To"><div style={{ display: 'inline-block' }}>Sonmarg</div></td>
                                                        <td data-title="Distance">79.4 km</td>
                                                        <td data-title="Duration"><div style={{ display: 'inline-block' }}>2 hours 11 minutes</div></td>
                                                        <td data-title="Route"><div style={{ display: 'inline-block' }}>Via NH 1</div></td>
                                                    </tr>
                                                    <tr>
                                                        <td data-title="From"><div style={{ display: 'inline-block' }}>Srinagar</div></td>
                                                        <td data-title="To"><div style={{ display: 'inline-block' }}>Pahalgam</div></td>
                                                        <td data-title="Distance">89.6 km</td>
                                                        <td data-title="Duration"><div style={{ display: 'inline-block' }}>2 hours 11 minutes</div></td>
                                                        <td data-title="Route"><div style={{ display: 'inline-block' }}>Via Srinagar - Kanyakumari Hwy</div></td>
                                                    </tr>
                                                    <tr>
                                                        <td data-title="From"><div style={{ display: 'inline-block' }}>Srinagar</div></td>
                                                        <td data-title="To"><div style={{ display: 'inline-block' }}>Dras</div></td>
                                                        <td data-title="Distance">141.2 km</td>
                                                        <td data-title="Duration"><div style={{ display: 'inline-block' }}>4 hours 2 minutes</div></td>
                                                        <td data-title="Route"><div style={{ display: 'inline-block' }}>Via NH 1</div></td>
                                                    </tr>
                                                    <tr>
                                                        <td data-title="From"><div style={{ display: 'inline-block' }}>Srinagar</div></td>
                                                        <td data-title="To"><div style={{ display: 'inline-block' }}>Kargil</div></td>
                                                        <td data-title="Distance">202.1 km</td>
                                                        <td data-title="Duration"><div style={{ display: 'inline-block' }}>5 hours 19 minutes</div></td>
                                                        <td data-title="Route"><div style={{ display: 'inline-block' }}>Via NH 1</div></td>
                                                    </tr>
                                                    <tr>
                                                        <td data-title="From"><div style={{ display: 'inline-block' }}>Srinagar</div></td>
                                                        <td data-title="To"><div style={{ display: 'inline-block' }}>Leh</div></td>
                                                        <td data-title="Distance">418.5 km</td>
                                                        <td data-title="Duration"><div style={{ display: 'inline-block' }}>9 hours 41 minutes</div></td>
                                                        <td data-title="Route"><div style={{ display: 'inline-block' }}>Via NH 1</div></td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div className="card-header custHeaer">
                                            <h4 className='content-desktop' style={{ fontSize: "1.5vw" }}>MUST DO ACTIVITIES DURING <strong>KASHMIR TOUR PACKAGES FROM PUNE</strong></h4>
                                            <h4 className='content-mobile2' style={{ fontSize: "3.5vw" }}>MUST DO ACTIVITIES DURING <strong>KASHMIR TOUR PACKAGES FROM PUNE</strong></h4>

                                        </div>
                                        <div className="card-body">
                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>Shikara Rides:</b><b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Shikara Rides:</b> Experience the tranquility of Dal Lake with a leisurely Shikara ride During your <strong> Kashmir Tulip Festival Package Tour</strong>. Glide across the serene waters, passing by floating gardens, houseboats, and vibrant markets. Sunset and early morning rides offer particularly magical views.</p>
                                            <center> <img src={Topbanner0ImageURL} className="img-fluid" alt="..." /></center><br /><br /><br />

                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>Skiing and Snowboarding:</b>
                                                <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Skiing and Snowboarding:</b> During the winter months, places like Gulmarg transform into ski resorts, attracting enthusiasts from around the world. Gulmarg offers excellent slopes for skiing, snowboarding, and heli-skiing, with breathtaking views of the surrounding Himalayan peaks.</p>
                                            <center> <img src={Topbanner1ImageURL} className="img-fluid" alt="..." /></center><br /><br /><br />

                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>Gondola Ride: </b>
                                                <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Gondola Ride: </b>
                                                Take a ride on the Gulmarg Gondola, one of the highest cable cars in the world. Enjoy panoramic views of the snow-capped peaks as you ascend to Kongdori or Apharwat Peak, where you can indulge in activities like hiking and mountain biking.Experience this Awesome Ride During your <strong>Kashmir Tulip Garden Tour</strong> with Adorable Vacation and make this as a life long memories.</p>
                                            <center> <img src={Topbanner2ImageURL} className="img-fluid" alt="..." /></center><br /><br /><br />

                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>Trekking:</b>
                                                <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Trekking:</b> Kashmir is a paradise for trekkers, with numerous trails that cater to all levels of expertise. Popular trekking destinations include the Kashmir Great Lakes Trek, Tarsar Marsar Trek, and the trek to Thajiwas Glacier in Sonamarg.</p>
                                            <center> <img src={Topbanner3ImageURL} className="img-fluid" alt="..." /></center><br /><br /><br />
                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>River Rafting:</b>
                                                <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>River Rafting:</b> The Lidder River in Pahalgam offers exciting opportunities for white-water rafting. Experience the thrill of navigating through rapids while enjoying the scenic beauty of the surrounding pine forests and mountains.</p>
                                            <center> <img src={Topbanner4ImageURL} className="img-fluid" alt="..." /></center>
                                            <br /><br /><br />
                                            <a href="tel://9147061467" className="btn btn-Warning" style={{ padding: '15px 30px', fontSize: '20px' }}>CALL NOW</a>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="container-fluid navtabCustom">
                                        <nav>
                                            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                                <button onClick={() => this.handleCustomTabClick('tab1')} className={`tab button ${customActiveTab === 'tab1' ? 'active' : ''}`} role="tab" aria-controls="nav-highlights" aria-selected={customActiveTab === 'tab1'}>Highlights</button>
                                                <button onClick={() => this.handleCustomTabClick('tab2')} className={`tab button ${customActiveTab === 'tab2' ? 'active' : ''}`} role="tab" aria-controls="nav-flights" aria-selected={customActiveTab === 'tab2'}>Flights</button>
                                                <button onClick={() => this.handleCustomTabClick('tab3')} className={`tab button ${customActiveTab === 'tab3' ? 'active' : ''}`} role="tab" aria-controls="nav-hotel" aria-selected={customActiveTab === 'tab3'}>Hotel</button>
                                                <button onClick={() => this.handleCustomTabClick('tab4')} className={`tab button ${customActiveTab === 'tab4' ? 'active' : ''}`} role="tab" aria-controls="nav-sightseeing" aria-selected={customActiveTab === 'tab4'}>Sightseeing</button>
                                                <button onClick={() => this.handleCustomTabClick('tab5')} className={`tab button ${customActiveTab === 'tab5' ? 'active' : ''}`} role="tab" aria-controls="nav-meals" aria-selected={customActiveTab === 'tab5'}>Meals</button>
                                            </div>
                                        </nav>
                                        <div className="tab-content" id="nav-tabContent" >
                                            <div className={customActiveTab === 'tab1' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-highlights-tab">
                                                <p>The <strong>Kashmir Package Tour from Pune</strong> is famous for its stunning landscapes, including well-known spots like Dal Lake, Nigeen Lake, Gulmarg, Pahalgam, and Sonamarg. These places draw tourists from all over the world who come to enjoy the beautiful scenery and take part in outdoor activities.</p>
                                                <p>Planning a <strong>Kashmir Tour Packages from Pune</strong> is a great choice for travelers looking for beautiful scenery, cultural experiences, and adventure in a unique and stunning location.</p>
                                                <p>Kashmir is located in the Himalayan Mountains and is surrounded by India, Pakistan, and China. It has three main regions: Jammu, the Kashmir Valley, and Ladakh. The area is known for its snowy mountains, green valleys, winding rivers, and peaceful lakes.</p>
                                                <p>The <strong>Kashmir Tour Pakcage from Pune</strong> are famous for their stunning landscapes, including places like Dal Lake, Nigeen Lake, Gulmarg, Pahalgam, and Sonamarg. Tourists from around the world visit to enjoy the beautiful scenery and take part in outdoor activities. Kashmir has always been a popular destination, known for its natural beauty, adventure sports, religious sites, and historical landmarks. Visitors can enjoy activities like trekking, skiing, staying in houseboats, taking shikara rides, and exploring Mughal gardens and ancient temples.</p>
                                            </div>
                                            <div className={customActiveTab === 'tab2' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-flights-tab">
                                                <div className="overflow-table">
                                                    <span>POPULAR FLIGHTS FOR THE KASHMIR TULIP GARDEN TRIP 2025<b> <i>(SXR)</i></b></span><br /><br />
                                                    <table className="table table-bordered">
                                                        <tr className="table-danger">
                                                            <th>Flight Operator</th>
                                                            <th>Departure From</th>
                                                            <th>Departure Time</th>
                                                            <th>Arrival at Srinagar</th>
                                                            <th>Stopover</th>
                                                            <th>Travel Time</th>
                                                        </tr>
                                                        <tr>
                                                            <td>IndiGo</td>
                                                            <td>Kolkata [CCU]</td>
                                                            <td>08:15</td>
                                                            <td>15:15</td>
                                                            <td>New Delhi [DEL]</td>
                                                            <td>7h 00m</td>
                                                        </tr>
                                                        <tr>
                                                            <td>IndiGo</td>
                                                            <td>Mumbai [BOM]</td>
                                                            <td>09:30</td>
                                                            <td>15:15</td>
                                                            <td>New Delhi [DEL]</td>
                                                            <td>5h 45m</td>
                                                        </tr>
                                                        <tr>
                                                            <td>SpiceJet</td>
                                                            <td>New Delhi [DEL]</td>
                                                            <td>08:00</td>
                                                            <td>09:25</td>
                                                            <td>(None)</td>
                                                            <td>1h 25m</td>
                                                        </tr>
                                                        <tr>
                                                            <td>IndiGo</td>
                                                            <td>Bangalore [BLR]</td>
                                                            <td>09:30</td>
                                                            <td>14:10</td>
                                                            <td>Sri Guru Ram Dass Jee [ATQ]</td>
                                                            <td>4h 40m</td>
                                                        </tr>
                                                        <tr>
                                                            <td>IndiGo</td>
                                                            <td>Goa [GOX]</td>
                                                            <td>10:30</td>
                                                            <td>16:45</td>
                                                            <td>New Delhi [DEL]</td>
                                                            <td>6h 15m</td>
                                                        </tr>
                                                        <tr>
                                                            <td>IndiGo</td>
                                                            <td>Hyderabad [HYD]</td>
                                                            <td>08:55</td>
                                                            <td>15:15</td>
                                                            <td>New Delhi [DEL]</td>
                                                            <td>6h 20m</td>
                                                        </tr>
                                                        <tr>
                                                            <td>IndiGo</td>
                                                            <td>Chennai [MAA]</td>
                                                            <td>08:30</td>
                                                            <td>14:20</td>
                                                            <td>New Delhi [DEL]</td>
                                                            <td>5h 50m</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className={customActiveTab === 'tab3' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-hotel-tab">

                                                <div className="overflow-table">
                                                    <div id="no-more-tables">
                                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                            <thead class="cf">
                                                                <tr className="text-center">
                                                                    {/* <!-- Add the "text-center" className to center content --> */}
                                                                    <th colspan="6" style={{ fontSize: "1.3vw" }}><h6><b>3 STAR CATEGORY HOTEL CERTIFIED BY 'ADORABLE VACATION' </b></h6></th>
                                                                </tr>
                                                                <tr className="table-danger">
                                                                    <th className="text-center">Destination</th>
                                                                    <th className="text-center">Hotel Names</th>
                                                                    <th className="text-center">Hotel Type</th>
                                                                    <th className="text-center">No of Nights</th>
                                                                    <th className="text-center">Meal Plan</th>
                                                                </tr>
                                                            </thead>
                                                            <tr>
                                                                <td data-title="Destination"><div style={{ display: 'inline-block' }}>Srinagar</div></td>
                                                                <td data-title="Hotel Names"><div style={{ display: 'inline-block' }}>Hotel Grand Fortune / Hotel Shefaf / Hotel Siddique Palace / Similar</div></td>
                                                                <td data-title="Hotel Type">3 Star
                                                                </td>
                                                                <td data-title="No of Nights"><div style={{ display: 'inline-block' }}>5N</div></td>
                                                                <td data-title="Meal Plan"><div style={{ display: 'inline-block' }}>Room with Breakfast & Dinner</div></td>
                                                            </tr>
                                                            <tr>
                                                                <td data-title="Destination"><div style={{ display: 'inline-block' }}>Gulmarg</div></td>
                                                                <td data-title="Hotel Names"><div style={{ display: 'inline-block' }}>Hotel Affarwat / Hotel Mama Palace /Gulmarg House / Similar
                                                                </div>
                                                                </td>
                                                                <td data-title="Hotel Type">3 Star
                                                                </td>
                                                                <td data-title="No of Nights"><div style={{ display: 'inline-block' }}>1N</div></td>
                                                                <td data-title="Meal Plan"><div style={{ display: 'inline-block' }}>Room with Breakfast & Dinner</div></td>
                                                            </tr>
                                                            <tr>
                                                                <td data-title="Destination"><div style={{ display: 'inline-block' }}>Pahalgam</div></td>
                                                                <td data-title="Hotel Names"><div style={{ display: 'inline-block' }}>Hotel Pine Palace Resort / Hotel Fifth Season / Hotel Moonview Resort /Similar
                                                                </div>
                                                                </td>
                                                                <td data-title="Hotel Type">3 Star
                                                                </td>
                                                                <td data-title="No of Nights"><div style={{ display: 'inline-block' }}>2N</div></td>
                                                                <td data-title="Meal Plan"><div style={{ display: 'inline-block' }}>Room with Breakfast & Dinner</div></td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={customActiveTab === 'tab4' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-sightseeing-tab">
                                                <div className="container-fluid px-0 py-1">
                                                    <h5>TOP ATTRACTIONS TO EXPLORE DURING THE <strong>KASHMIR PACKAGE TOUR FROM PUNE</strong></h5><br />
                                                    <p>During the Kashmir Tulip Holiday Package from Adorable Vacation, the region offers a wealth of captivating attractions that complement the vibrant display of tulips. Here are the must-see highlights:</p><br />
                                                    <p><b className='tomato'>1.	Indira Gandhi Memorial Tulip Garden:</b> Dive into the festival's heart, where sprawling tulip beds create a riot of colours against the backdrop of the majestic Himalayas.</p>
                                                    <p><b className='tomato'>2. Srinagar's Dal Lake:</b> Take a serene shikara ride on this iconic lake, known for its houseboats and picturesque views, offering a tranquil escape from the festival's bustle.</p>
                                                    <p><b className='tomato'>3. Mughal Gardens:</b> Explore the timeless beauty of Shalimar Bagh, Nishat Bagh, and Chashme Shahi, where Mughal architecture blends with lush landscapes.</p>
                                                    <p><b className='tomato'>4. Gulmarg:</b> Discover the lush meadows and pristine surroundings of Gulmarg, perfect for a day trip with opportunities for cable car rides and panoramic views.</p>
                                                    <p><b className='tomato'>5. Pahalgam:</b> Experience the natural splendor of Pahalgam, from its scenic meadows to the picturesque Betab Valley and Aru Valley.</p>
                                                    <p><b className='tomato'>6. Sonmarg:</b> Venture to Sonmarg's breathtaking landscapes, where stunning views and serene environments make for a memorable excursion.</p>
                                                    <p><b className='tomato'>7. Doodhpathri:</b> Explore this lesser-known gem, renowned for its lush green meadows and gentle streams, offering a peaceful retreat.</p>
                                                    <p><b className='tomato'>8. Yusmarg:</b> Visit this idyllic meadow surrounded by pine forests, providing a serene spot for relaxation and picnicking.</p>
                                                    <p><b className='tomato'>9. Srinagar's Local Markets:</b> Immerse yourself in the vibrant local culture by visiting bustling markets like Lal Chowk and Polo View Market, where you can shop for traditional Kashmiri crafts and souvenirs.</p>
                                                    <p><b className='tomato'>10. Vaishno Devi Temple:</b> Take a pilgrimage to this revered shrine, nestled in the Trikuta Mountains, offering spiritual solace and panoramic views.</p>
                                                    <p><b className='tomato'>11. Shankaracharya Temple:</b> Visit this ancient temple perched on a hilltop for a blend of spiritual significance and stunning panoramic views of Srinagar.</p>
                                                    <p>The <strong>Tulip Garden Tour Package</strong> promises not only a visual feast of flowers but also a rich array of cultural and natural wonders to explore.</p>
                                                </div>
                                            </div>
                                            <div className={customActiveTab === 'tab5' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-meals-tab">
                                                <div className="overflow-table">
                                                    <h5 class="tomato">7 times Meals and 7 times Breakfast has been Provided</h5>
                                                    <table className="table table-bordered">
                                                        <tr className="table-danger">
                                                            <th colspan="6" className="text-center">Category</th>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-center">JAIN</td>
                                                            <td class="text-center">PURE-VEG</td>
                                                            <td class="text-center">VEGAN</td>
                                                            <td class="text-center">PURE-NON-VEG</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <br /><br /><br />
                            {/* <!--By Itinerary--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>
                                                DAY WISE <strong><span>KASHMIR TOUR ITINERARY FROM PUNE</span></strong>
                                            </h2>
                                            <h4>7 Nights 8 Days <strong>Kashmir Tour Itinerary</strong></h4>
                                        </div>
                                    </div>
                                </div>


                                {/* <!--end Short description--> */}
                                <br />
                                <div className="row">
                                    <div
                                        className="accordion"
                                        id="accordionPanelsStayOpenExample"
                                    >
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day1"
                                            >
                                                <button
                                                    className="accordion-button"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseOne"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseOne"
                                                >
                                                    DAY 1  – ARRIVAL IN SRINAGAR
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseOne"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingOne"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        Arrive at Srinagar’s Sheikh Ul Alam International Airport. You’ll be greeted by our representative and taken to your hotel for check-in. Enjoy a welcome drink and then head out for a shikara ride on the famous Dal Lake. Explore the floating gardens and enjoy bird watching. Spend the night on a houseboat.
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay1ImageURL} alt="Kashmir Offbeat Packages for Family" /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay1ImageURL} style={{ width: '320px' }} alt="Kashmir Offbeat Packages for Family" /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day2"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseTwo"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseTwo"
                                                >
                                                    DAY 02: SRINAGAR TO GULMARG
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseTwo"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingTwo"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        After breakfast, check out from the houseboat and drive to Gulmarg, known as the “Meadow of Flowers.” Check in at your hotel in Gulmarg. You can take a gondola ride up to the first or second phase of the cable car, which offers stunning views and is one of the highest cable cars in the world. Gulmarg is also famous for skiing and its beautiful meadows. Return to your hotel for the night.
                                                    </p>

                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay2ImageURL} alt="Kashmir Tour Package cost offbeat" /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay2ImageURL} style={{ width: '320px' }} alt="Kashmir Tour Package cost offbeat" /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day3"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseThree"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 03: GULMARG TO PAHALGAM
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseThree"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingThree"
                                            >
                                                <div className="accordion-body">
                                                    <p>Enjoy breakfast before checking out and driving to Pahalgam, known as the “Valley of Shepherds.” This charming town sits where the streams from Sheshnag Lake meet the Lidder River. Check in at your hotel and enjoy the rest of the day at leisure.</p>
                                                    <br />

                                                    <div className="content-desktop">
                                                        <center><img src={navDay3ImageURL} alt="Kashmir Tour Packages" /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay3ImageURL} style={{ width: '320px' }} alt="Kashmir Tour Packages" /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day4"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseFour"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    Day 04: - PAHALGAM LOCAL SIGHTSEEING
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseFour"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingFour"
                                            >
                                                <div className="accordion-body">
                                                    <p>After breakfast, spend the day exploring Pahalgam. Visit Chandanwari, the starting point for the Amarnath Yatra, and enjoy snow sledding. Explore Aru Valley and Betaab Valley for their stunning scenery. You can also visit Baisaran Valley, known as “Mini Switzerland,” for beautiful meadows and dense pine forests. Return to your hotel in Pahalgam for the night.</p>

                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay4ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay4ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day5"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseFive"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 05: PAHALGAM TO SRINAGAR
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseFive"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingFive"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        Check out from your hotel and drive back to Srinagar. Visit the Hazratbal Shrine and Shankaracharya Temple. In the afternoon, explore the Mughal Gardens: Nishat Bagh, Shalimar Bagh, and Chashme Shahi. Return to your Srinagar hotel for the night.
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay5ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay5ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day6"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseSix"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 06: SRINAGAR TO DOODHPATHRI AND BACK
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseSix"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingSix"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        After breakfast, take a day trip to Doodhpathri, located 42 kilometers from Srinagar. Known as the "Valley of Milk," Doodhpathri is famous for its lush meadows and cold, milky streams. The area is covered with wildflowers in spring and summer. Return to Srinagar in the evening for an overnight stay.
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay6ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay6ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day7"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseSeven"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 07: SRINAGAR TO SONMARG AND BACK
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseSeven"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingSeven"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        After breakfast, check out from your hotel and head to Sonmarg, meaning "Meadow of Gold." The 87-kilometer drive offers stunning views of forests and mountains. Sonmarg is a gateway to trekking routes and glaciers. You can hire ponies or vehicles to visit Thajiwas Glacier, a major attraction. Return to Srinagar in the evening for your last overnight stay.
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay7ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay7ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day8"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseTen"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 08:DEPARTURE FROM SRINAGAR
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseTen"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingTen"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        Your <strong>Kashmir Tour From Pune</strong> concludes today. After breakfast, you will be transferred to the Domestic Airport for your flight to your next destination. Thank you for traveling with us, and we hope you had a memorable experience.
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay8ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay8ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <br />
                            {/* <!--insert table inclusion exclusion--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        {
                                            <InclusionExclusion />
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* <!--end table inclusion exclusion--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center" >
                                            <h2>The Khyber Himalayan Resort & Spa <span>- Gulmarg</span></h2>
                                            <h4><i>Next Level Luxury Resort </i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row py-3">
                                    <img src={brandedTourackageImage_1_URL} alt="..." />
                                </div>

                                <div id="no-more-tables" >
                                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                        <thead>
                                            <tr className="table-danger">
                                                <th>Category</th>
                                                <th>Size(Room)</th>
                                                <th>Hotel Brand</th>
                                                <th>Price (Per Night)</th>
                                                <th>Book Now</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td data-title="Packages">Platinum Accommodation</td>
                                                <td data-title="Size(Room)">386+ SQ.FT</td>
                                                <td data-title="Hotel Brand">Khyber Hotels (Heritage)<div className="reviews">
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                </div></td>
                                                <td data-title="Price (Per Night)">Rs 53,119/- </td>
                                                <td data-title="Book Now"><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick}>
                                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                                </a></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2 >The LaLiT Grand Place <span>- Srinagar</span></h2>
                                            <h4 ><i>Fill the Luxury</i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row py-3">
                                    <img src={brandedTourackageImage_2_URL} alt="..." />
                                </div>

                                <div id="no-more-tables" >
                                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                        <thead>
                                            <tr className="table-danger">
                                                <th>Category</th>
                                                <th>Size(Room)</th>
                                                <th>Hotel Brand</th>
                                                <th>Price (Per Night)</th>
                                                <th>Book Now</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td data-title="Packages">Premium Accommodation</td>
                                                <td data-title="Size(Room)">290+ SQ.FT</td>
                                                <td data-title="Hotel Brand">The LaLiT Hotels<div className="reviews">
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                </div></td>
                                                <td data-title="Price (Per Night)">Rs 28,332/- </td>
                                                <td data-title="Book Now"><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick}>
                                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                                </a></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                <div className="card mx-0 my-5">
                                    <div className="card-header custHeaer">
                                        <h3 className='content-desktop' style={{ fontSize: "1.5vw" }}><b>EMBARKED ON A SENSE OF PRIDE AT PREMIUMNESS</b></h3>
                                        <h3 className='content-mobile2' style={{ fontSize: "3.5vw" }}><b>EMBARKED ON A SENSE OF PRIDE AT PREMIUMNESS</b></h3>
                                    </div>
                                    <div className="card-body">
                                        <p ><b>The LaLit Grand Palace</b> was originally built in 1901 for the royal family. It now provides luxurious rooms with views of Dal Lake and the beautiful gardens. An indoor pool, tennis courts and pampering spa treatments are also available.The tastefully designed rooms are modern with a touch of traditional charm. All have a satellite TV, minibar and free tea/coffee making facilities. It is 18 km from Srinagar Airport and 4 km from Lal Chowk city centre. Jammu Railway Station is 300 km away. Guests can enjoy a relaxing massage at Rejuve Spa Health Club, practice yoga, or play golf. The hotel also has a gym and 24-hour business center. The Chinar Garden serves North Indian cuisine, while international buffet spreads are available at The Chinar – The All Day Coffee Shop. Other dining options include Dal Bar and 24-hour room service.</p>

                                        <a href="tel://9883359713" className="btn btn-Warning">Book Now</a>

                                    </div>
                                </div>
                            </div>

                            <br /><br />
                            {/* <!--start of package details--> */}
                            <div className="container-fluid py-5">

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>Kashmir<span> Package Tour From Pune </span></h2>
                                            <h4>Related Kashmir Tour plan</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                                        <div className="carousel-inner">
                                            <div className="carousel-item active">

                                                <Slider {...settings}>
                                                    {hotPackagesView}
                                                </Slider>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!--end of package details--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2 >Frequently Ask <span>Questions</span></h2>
                                            <h4 >Kashmir Package Tour From Pune Related Questions</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="row" >
                                    <div className="accordion" id="accordionPanelsStayOpenExample">
                                        <div className="accordion" id="accordionFlushExample">
                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingThirteen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThirteen" aria-expanded="false" aria-controls="flush-collapseThirteen">
                                                        Are there trekking opportunities on a Kashmir trip from Pune?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseThirteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingThirteen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p><b>Answer:</b> Yes, Kashmir is great for trekking. It has beautiful valleys, snow-capped mountains, and clear lakes, making it perfect for adventure lovers. Our Kashmir tour packages from Pune include popular trekking routes like the Aru Valley trek, Tarsar Marsar trek, and the Great Lakes trek. These routes offer stunning views and cultural experiences. Whether you're new to trekking or an expert, there are options for everyone, and local guides will ensure a safe and enjoyable experience.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingFourteen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFourteen" aria-expanded="false" aria-controls="flush-collapseFourteen">
                                                        Can I go skiing in Kashmir?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseFourteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingFourteen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p><b>Answer:</b> Yes, you can! Kashmir is fantastic for skiing, especially in Gulmarg. Our Kashmir tour packages from Pune include skiing adventures, with facilities and instructors available for all skill levels. Enjoy the beautiful snow-covered slopes and breathtaking views while you ski. Adorable Vacation ensures you have a memorable skiing experience with all the comfort and convenience.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingFifteen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFifteen" aria-expanded="false" aria-controls="flush-collapseFifteen">
                                                        How many lakes are there in Kashmir?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseFifteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingFifteen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p><b>Answer:</b> Kashmir is home to many beautiful lakes, including Dal Lake, Nagin Lake, Wular Lake, Manasbal Lake, and more. These lakes are known for their stunning beauty and are great spots to visit on your tour.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingSixteen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSixteen" aria-expanded="false" aria-controls="flush-collapseSixteen">
                                                        What are some must-try Kashmiri dishes?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseSixteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingSixteen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p><b>Answer:</b> When in Kashmir, don't miss these local dishes:</p>
                                                        <ul>
                                                            <li>Yoghurt Lamb Curry</li>
                                                            <li>Kashmiri Guji Gaad</li>
                                                            <li>Rogan Josh</li>
                                                            <li>Aab Gosht</li>
                                                            <li>Dum Olav</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingSeventeen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeventeen" aria-expanded="false" aria-controls="flush-collapseSeventeen">
                                                        How do I plan a Kashmir package tour from Pune by flight?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseSeventeen" className="accordion-collapse collapse show" aria-labelledby="flush-headingSeventeen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p><b>Answer:</b> You can fly to Srinagar Airport (SXR), which is 12 km from the city center, or Jammu Airport (IXJ), which is 8 km from the city center. Daily flights are available from Pune to these airports with airlines like Indian Airlines, Jet Airways, Deccan Airlines, and SpiceJet. Check flight schedules with these airlines to plan your trip.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center" >
                                            <h3><b>Kashmir <span class="tomato">Holiday Reviews</span></b></h3>
                                            <h4><i>"Your Experience is our Marketing"</i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-cols-1">
                                    <div id="testimonials">
                                        <div className="testimonial-box-container" >

                                            {reviewersDataView}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <BookingBox />
                        </div>
                    </div >
                </div >
                <div class="btn-call-pulse">
                    <a style={{ color: 'white' }} href="tel:+91-91470-61467">
                        <i className="fa fa-phone"></i>
                        {/* <span><small>+91 9147061467</small></span> */}
                    </a>
                </div>
                <div className="btn-whatsapp-pulse">
                    <a style={{ color: 'white' }} href="#" onClick={this.handleClick}>
                        <i className="fab fa-whatsapp"></i>
                        {/* <span>
                                <small>+91 9883359713</small>
                            </span> */}
                    </a>
                </div>

            </>
        );
    }
}

export default Kashmir_7N_8D_Package_Tour_From_Pune;