import React, { Component } from "react";
import BookingBox from "../BookingBox";
import Slider from "react-slick";
import axios from "axios";
import cogoToast from "cogo-toast";
import { Digital } from "react-activity";
import "react-activity/dist/library.css";
import ApiURL from "../../../api/ApiURL";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import "./InclusionExclusion";
import InclusionExclusion from "../Meghalaya/InclusionExclusion_ShillongMeghalaya_6n_7d";

class ShillongMeghalayaTour_6N_7D_Package extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customActiveTab: "tab1",
      currentPage: 1,
      btn: "Submit",
      showLoader: false,
      isHidden: true,
      showModal: false,
      firstName: '',
      mobile: '',
      email: '',
      comments: ''
    };
  }

  handleCustomTabClick = (tab) => {
    this.setState({
      customActiveTab: tab,
    });
  };
  handleClick = () => {
    const currentUrl = window.location.href;
    const pageTitle = document.title;
    const message = `Hi, Please send about the Tour Package details "${pageTitle}"`;

    const whatsappLink = `https://api.whatsapp.com/send?phone=+919147061467&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
    window.open(whatsappLink, '_blank');
  };
 
  onRequestCallback = (e) => {
    e.preventDefault();
    this.setState({ showModal: true });
  }



  onCloseModal = (e) => {
    e.preventDefault();
    this.setState({
      ...this.state,
      showModal: false,
      firstName: '',
      mobile: '',
      email: '',
      comments: ''
    });
  }

  onChangeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  // Function to handle form submission
  onRequestCallbackSubmit = async (e) => {
    e.preventDefault();

    const { firstName, email, mobile, comments } = this.state;

    // Validation
    if (firstName.trim() === '') {
        cogoToast.error("Full Name Required.", { 
            position: 'top-center', // Use top-center, then adjust via CSS
            hideAfter: 2, // optional: auto-hide after 2 seconds
            className: 'custom-toast' // Apply custom class
        });
    } else if (email.trim() === '') {
        cogoToast.error("Email Required.", { 
            position: 'top-center', // Use top-center, then adjust via CSS
            hideAfter: 2, // optional: auto-hide after 2 seconds
            className: 'custom-toast' // Apply custom class
        });
    } else if (mobile.trim() === '' || !/^\d{10}$/.test(mobile.trim())) {
        cogoToast.error("Please Insert 10 digit Mobile No.", { 
            position: 'top-center', // Use top-center, then adjust via CSS
            hideAfter: 2, // optional: auto-hide after 2 seconds
            className: 'custom-toast' // Apply custom class
        });
    } else {
        this.setState({ btn: "Processing..", showLoader: true });
        // Proceed with form submission
        try {
            const _ref = 'yes';
            const _url = window.location.href;

            const formData = {
                FirstName: firstName,
                //LastName: lastName,
                Email: email,
                Mobile: mobile,
                // City: city,
                // TravelDate: travelDate,
                // TicketBooked: ticketBookedStatus,
                // NoOfGuests: noOfGuests,
                // HotelCategory: hotelCategory,
                Comments: comments,
                Referrer: _ref,
                URL: _url
            };

            const response = await axios.post(ApiURL.SaveCustomerEnquery, formData);
            console.log('Data submitted successfully:', response.data);
            // Optionally, reset the form state after successful submission
            this.setState({ firstName: '', email: '', mobile: '', comments: '' });

            // Optionally, show a success message to the user
            cogoToast.success("Form submitted successfully.", { 
                position: 'top-center', // Use top-center, then adjust via CSS
                hideAfter: 2, // optional: auto-hide after 2 seconds
                className: 'custom-toast' // Apply custom class
            });
            this.setState({ btn: "Submit", showLoader: false, showModal: false });
        } catch (error) {
            console.error('Error submitting data:', error);
            this.setState({ btn: "Submit", showLoader: false });
            // Optionally, show an error message to the user
            cogoToast.error("Please try again after 24 hrs. later.", { 
                position: 'top-center', // Use top-center, then adjust via CSS
                hideAfter: 2, // optional: auto-hide after 2 seconds
                className: 'custom-toast' // Apply custom class
            });
        }

    }

};

  render() {
    const rows = [
      { Train_No: '12345', Train_Name: 'Saraighat Exp', Boarding_From: 'Howrah', Boarding_time: '16:05', Arrival: 'New Alipurduar', Day: 'M,T,W,T,F,S,S' },
      { Train_No: '12377', Train_Name: 'Padatik Exp', Boarding_From: 'Sealdah', Boarding_time: '23:20', Arrival: 'New Alipurduar', Day: 'M,T,W,T,F,S,S' },
      { Train_No: '13147', Train_Name: 'Uttar Banga Exp', Boarding_From: 'Sealdah', Boarding_time: '19:40', Arrival: 'New Cooch Behar', Day: 'M,T,W,T,F,S,S' },
      { Train_No: '13175', Train_Name: 'Kanchanjunga', Boarding_From: 'Sealdah', Boarding_time: '06:50', Arrival: 'NJP', Day: 'M,W,SAT' },
      { Train_No: '15959', Train_Name: 'Kamrup Exp', Boarding_From: 'Howrah', Boarding_time: '18:30', Arrival: 'New Alipurduar', Day: 'T,W,T,S,S' },
      { Train_No: '13141', Train_Name: 'Teesta Torsha', Boarding_From: 'Sealdah', Boarding_time: '15:00', Arrival: 'New Alipurduar', Day: 'M,T,W,T,F,S,S' },
      { Train_No: '13149', Train_Name: 'Kanchan Kanya', Boarding_From: 'Sealdah', Boarding_time: '20:35', Arrival: 'New Alipurduar', Day: 'M,T,W,T,F,S,S' },
    ];

    const rowsPerPage = 4;
    const totalPages = Math.ceil(rows.length / rowsPerPage);
    const currentRows = rows.slice((this.state.currentPage - 1) * rowsPerPage, this.state.currentPage * rowsPerPage);
    const { customActiveTab } = this.state;
    const packageTopBannerImageURL = ApiURL.Shillong_Meghalaya_6N_7D_PackageImageURL + "/Shillong-Meghalaya-Cherrapunji-Tour-Package.webp";
    const packageTopBanner2ImageURL = ApiURL.Shillong_Meghalaya_6N_7D_PackageImageURL + "/Shillong-Meghalaya-Cherrapunji-Tour-Packages.webp";
    const sightseen1ImageURL = ApiURL.Shillong_Meghalaya_6N_7D_PackageImageURL + "/MEGHALAYA.webp";
    const sightseen2ImageURL = ApiURL.Shillong_Meghalaya_6N_7D_PackageImageURL + "/ACTIVITIES.webp";
    const sightseen3ImageURL = ApiURL.Shillong_Meghalaya_6N_7D_PackageImageURL + "/ACCOMMODATION.webp";
    const sightseen4ImageURL = ApiURL.Shillong_Meghalaya_6N_7D_PackageImageURL + "/CULTURE.webp";

    const navDay1ImageURL = ApiURL.Shillong_Meghalaya_6N_7D_PackageImageURL + "/SHILLONG-CHERRAPUNJI-PACKAGE-TOUR.webp";
    const navDay2ImageURL = ApiURL.Shillong_Meghalaya_6N_7D_PackageImageURL + "/Crystal-clear-river-waters.webp";
    const navDay3ImageURL = ApiURL.Shillong_Meghalaya_6N_7D_PackageImageURL + "/SHILLONG-MEGHALAYA-PACKAGE-TOUR-BOOKING.webp";
    const navDay4ImageURL = ApiURL.Shillong_Meghalaya_6N_7D_PackageImageURL + "/Shillong-Meghalaya-Cherrapunji-tour.webp";
    const navDay5ImageURL = ApiURL.Shillong_Meghalaya_6N_7D_PackageImageURL + "/Krang-Suri-Waterfalls-Jowai.webp";
    const navDay6ImageURL = ApiURL.Shillong_Meghalaya_6N_7D_PackageImageURL + "/Assamese-silks-&-handicrafts.webp";
    const navDay7ImageURL = ApiURL.Shillong_Meghalaya_6N_7D_PackageImageURL + "/BOMDILA.webp";
  
   


    const settings = {
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    const reviewersDataList = this.props.ReviewersData;
    const reviewersDataView = reviewersDataList.map((dataItem, i) => {
      const reviewerImageURL = ApiURL.Shillong_Meghalaya_6N_7D_PackageImageURL + "/" + dataItem.ImageName;
      return (
        <div className="testimonial-box" key={i}>
          <div className="box-top">
            <div className="profile">
              <div className="profile-img">
                <img src={reviewerImageURL} alt="..." />
              </div>
              <div className="name-user">
                <strong>{dataItem.Name}</strong>
                <span>{dataItem.Location}</span>
              </div>
            </div>
            <div className="reviews">
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
            </div>
          </div>
          <div className="client-comment">
            <p>{dataItem.Comment}</p>
          </div>
        </div>
      )
    })


    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <img
              src={packageTopBannerImageURL}
              className="mobile-image2"
              alt="..."
            />
          </div>
        </div>

        <div className="container-fluid custom-container py-3">
          <div className="row">
            <div className="col-md-9">
              <div className="row">
                <div className="col-md-12">
                  <div className="container-fluid custom-container py-1">


                    <h1 className="content-desktop" style={{ fontSize: "2vw" }}><center><strong>Shillong Meghalaya Cherrapunji Package Tour from Guwahati</strong> – Best Deal from Adorable Vacation LLP</center></h1>

                    <h1 className="content-mobile" style={{ fontSize: "4vw" }}><center><strong>Shillong Meghalaya Cherrapunji Package Tour from Guwahati</strong> – Best Deal from Adorable Vacation LLP</center>
                      <hr />
                    </h1>
                    <div className="container-fluid">
                      <div className="row">
                        <img src={packageTopBanner2ImageURL} className="mobile-image" alt="..." />
                      </div>
                    </div>
                    <hr />
                  </div>
                  <h2 className="content-desktop p-4" style={{ fontSize: "2.0vw" }}> Want to  <strong>Visit Shillong Meghalaya Cherrapunji Tour Package from Guwahati?</strong> For the best <a href='tel:9674738480' class="tomato"><strong>Shillong Meghalaya Tour Package</strong>, ☎ CALL +91-96747-38480 </a></h2>
                  <h2 className="content-mobile p-4" style={{ fontSize: "4.5vw" }}>Want to  <strong>Visit Shillong Meghalaya Cherrapunji Tour Package from Guwahati?</strong> For the best <a href='tel:9674738480' class="tomato"><strong>Shillong Meghalaya Tour Package</strong>, ☎ CALL +91-96747-38480 </a></h2>
                  <br />
                  <div className="card">
                    <div
                      className="card-header custHeaer"
                    >
                      <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> India's Largest <b>North East Tour Operator</b></h3>
                      <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}> India's Largest <b>North East Tour Operator</b></h4>
                    </div>
                    <div className="card-body">
                      <p>Discover the beauty of <strong>Meghalaya Shillong Cherrapunji Tour Package</strong>. Experience the natural wonders and vibrant culture of this region. Visit Shillong, the capital city, which has serene lakes like Umiam Lake and beautiful waterfalls such as Elephant Falls. Enjoy the local Khasi culture at lively markets and try traditional foods. Known as the "Scotland of the East," Shillong's pleasant climate makes it a great place to visit all year</p>
                      <p>Take a scenic drive to Cherrapunji, the wettest place on earth, and see amazing sights like Nohkalikai Falls, one of India's tallest waterfalls, and the Seven Sisters Falls. Our <strong>Shillong Meghalaya Tour Packages from Guwahati</strong> offer an easy journey to explore Meghalaya's rich nature. Discover the lush greenery, breathtaking waterfalls, and unique living root bridges in Cherrapunji, making memories that will last forever.</p>
                      <p>Meghalaya, meaning "Abode of the Clouds," lives up to its name with misty hills, pristine landscapes, and a sense of peace. Each season offers something special, from the green monsoons to the crisp winter air. Meghalaya's diverse landscapes have something for everyone, whether you enjoy the bustling markets of Shillong, the living root bridges of Cherrapunji, or the ancient caves.</p>
                      <p>The warm hospitality of the Khasi people adds to your experience. The simplicity and friendliness of the locals make every visit special. Whether you want to trek through the hills, admire natural wonders, or relax in nature, Meghalaya has it all.
                      </p>
                      <br />
                      <div className="content-desktop">
                        <center><img src={sightseen1ImageURL} alt="..." /></center>
                      </div>

                      <div className="content-mobile2">
                        <center><img src={sightseen1ImageURL} style={{ width: '320px' }} alt="..." /></center>
                      </div>
                      <br />
                      <p>In short, Meghalaya is a place where every season reveals new beauty. Visiting at least once a year lets you see the changing landscapes, experience the unique culture, and create lasting memories. So, pack your bags and enjoy the charm of <strong>Shillong Meghalaya Tour</strong>, a place that welcomes you with open arms and offers a peaceful escape into nature.</p>
                    </div>
                  </div>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b> 6N | 7D :</b> <strong>SHILLONG MEGHALAYA PACKAGE TOUR DURATION</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}> India's Largest <b>North East Tour Operator</b></h4>
                </div>
                <div className="card-body">
                  <p>Places to cover in <strong>Shillong Meghalaya Tour</strong> : Shillong [3N] | Cherapunjee [3N]</p>
                  <p>Pickup & Drop for <strong>Shillong Meghalaya Cherrapunji Tour Package</strong> : Guwahati International Airport [GAU]</p>
                  <br />
                  <div className="row">
                    <table className="table table-bordered">
                      <thead>
                        <tr className="table-danger">
                          <th>
                            <strong>DAY WISE SHILLONG MEGHALAYA CHERRAPUNJI PACKAGE TOUR PLAN</strong>
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td
                            className="clickable"
                          >
                            <a href="#day1" style={{ color: 'black' }}><b>DAY 1 → </b>
                              <span>
                                ARRIVE AT GUWAHATI & TRANSFER TO SHILLONG
                              </span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day2" style={{ color: 'black' }}><b>DAY 2 → </b>
                              <span>SHILLONG - MAWLYNNONG - DAWKI – CHERRAPUNJEE</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day3" style={{ color: 'black' }}><b>DAY 3 → </b>
                              <span>CHERRAPUNJEE – NONGRIAT TREK DOUBLE DECKER BRIDGE</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day4" style={{ color: 'black' }}><b>DAY 4 → </b>
                              <span>CHERRAPUNJEE LOCAL SIGHTSEEING AND TRANSFER TO SHILLONG [APPROX. 2HRS]</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day5" style={{ color: 'black' }}><b>DAY 5 → </b>
                              <span>SHILLONG – JOWAI - SHILLONG</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day6" style={{ color: 'black' }}><b>DAY 6 → </b>
                              <span>SHILLONG TO GUWAHATI TRANSFER</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day7" style={{ color: 'black' }}><b>DAY 7 → </b>
                              <span>DEPARTURE FROM GUWAHATI AIRPORT</span>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>PLACES TO VISIT IN <strong>SHILLONG CHERRAPUNJI MEGHALAYA TOUR FROM GUWAHATI</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>PLACES TO VISIT IN <strong>SHILLONG CHERRAPUNJI MEGHALAYA TOUR FROM GUWAHATI</strong></h4>
                </div>
                <p>There are many attractions in Meghalaya and Shillong that we will explore on this tour. Some of the places include Shillong City, Umiam Lake, Elephant Falls, Shillong Peak, Spread Eagle Falls, Ward's Lake, Cathedral Catholic Church, Cherrapunji, Seven Sisters Falls, Mawsmai Cave, Nohkalikai Falls, Eco Park, Double Decker Living Root Bridge, Mawsynram, Jowai, Krang Suri Falls, Thadlaskein Lake, Stone Bridge, Tura, Mawlynnong, Dawki, Balpakram National Park, and Kyllang Rock.</p>
                <br /><br />
                <div className="card-body">
                  <div id="no-more-tables">
                    <table class="col-md-12 table-bordered table-striped table-condensed cf">
                      <tbody>
                        <tr>
                          <td><strong>Shillong Cherrapunji Tour Packages</strong> Pickup & Drop</td>
                          <td>Lokpriya Gopinath Bordoloi International Airport, Guwahati [GAU]</td>
                        </tr>
                        <tr>
                          <td><strong>Guwahati to Meghalaya Package Tour</strong> Duration</td>
                          <td>06 Nights | 07 Days</td>
                        </tr>
                        <tr>
                          <td>Altitude of Mawsynram</td>
                          <td>1,400 m [25.2975Â° N, 91.5826Â° E]</td>
                        </tr>
                        <tr>
                          <td>Altitude of Shillong</td>
                          <td>1,525 m [25.5788Â° N, 91.8933Â° E]</td>
                        </tr>
                        <tr>
                          <td>
                            Altitude of Cherapunjee
                          </td>
                          <td>1,430 m [25.2702Â° N, 91.7323Â° E]</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>SHILLONG CHERRAPUNJI PACKAGE TOUR COST FROM GUWAHATI</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>SHILLONG CHERRAPUNJI PACKAGE TOUR COST FROM GUWAHATI</strong></h4>
                </div>
                <br />
                <p className="p-4">There are many attractions in Meghalaya and Shillong that we will explore on this tour. Some of the places include Shillong City, Umiam Lake, Elephant Falls, Shillong Peak, Spread Eagle Falls, Ward's Lake, Cathedral Catholic Church, Cherrapunji, Seven Sisters Falls, Mawsmai Cave, Nohkalikai Falls, Eco Park, Double Decker Living Root Bridge, Mawsynram, Jowai, Krang Suri Falls, Thadlaskein Lake, Stone Bridge, Tura, Mawlynnong, Dawki, Balpakram National Park, and Kyllang Rock.</p>

                <div className="card-body">
                  <div id="no-more-tables">
                    <table class="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead class="cf">
                        <tr className="table-danger">
                          <th>NORTH EAST TOUR CATEGORY</th>
                          <th>NORTH EAST TOUR DURATION</th>
                          <th>NORTH EAST TOUR COST FROM GUWAHATI</th>
                          <th>FOR BOOK NORTH-EAST PACKAGE CONTACT US</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td data-title="CATEGORY">Kaziranga Elephant Safari Package Tour</td>
                          <td data-title="DURATION">4N/5D [without Airfare]</td>
                          <td data-title="COST">Rs 17,999/P [for 6 pax]</td>
                          <td data-title="Book Now"> <center><a href="#" className="btn btn-success" onClick={this.onRequestCallback}>
                            Book Now
                          </a></center></td>
                        </tr>
                        <tr>
                          <td data-title="CATEGORY">Assam Meghalaya Arunachal Pradesh Package Tour</td>
                          <td data-title="DURATION">19N/20D [without Airfare]</td>
                          <td data-title="COST">Rs 65,999/P [for 6 pax]</td>
                          <td data-title="Book Now">
                            <center>
                              <a href="#" className="btn btn-success" onClick={this.onRequestCallback}>Book Now</a>
                            </center>
                          </td>
                        </tr>
                        <tr>
                          <td data-title="CATEGORY">Kaziranga Pobitora Manas Package Tour from Guwahati</td>
                          <td data-title="DURATION">5N/6D [without Airfare]</td>
                          <td data-title="COST">Rs 28,000/P [for 6 pax]</td>
                          <td data-title="Book Now">
                            <center>
                              <a href="#" className="btn btn-success" onClick={this.onRequestCallback}>Book Now</a>
                            </center>
                          </td>
                        </tr>
                        <tr>
                          <td data-title="CATEGORY">Arunachal Package Tour from Bangalore</td>
                          <td data-title="DURATION">11N/12D [without Airfare]</td>
                          <td data-title="COST">Rs 35,599/P [for 6 pax]</td>
                          <td data-title="Book Now">
                            <center>
                              <a href="#" className="btn btn-success" onClick={this.onRequestCallback}>Book Now</a>
                            </center>
                          </td>
                        </tr>
                        <tr>
                          <td data-title="CATEGORY">Complete Arunachal Package Tour ex Guwahati</td>
                          <td data-title="DURATION">16N/17D [without Airfare]</td>
                          <td data-title="COST">Rs 66,499/P [for 6 pax]</td>
                          <td data-title="Book Now">
                            <center>
                              <a href="#" className="btn btn-success" onClick={this.onRequestCallback}>Book Now</a>
                            </center>
                          </td>
                        </tr>
                        <tr>
                          <td data-title="CATEGORY">Arunachal Pradesh Package Tour from Mumbai</td>
                          <td data-title="DURATION">14N/15D [without Airfare]</td>
                          <td data-title="COST">Rs 58,299/P [for 6 pax]</td>
                          <td data-title="Book Now">
                            <center>
                              <a href="#" className="btn btn-success" onClick={this.onRequestCallback}>Book Now</a>
                            </center>
                          </td>
                        </tr>
                        <tr>
                          <td data-title="CATEGORY">Arunachal Pradesh Package Tour from Delhi</td>
                          <td data-title="DURATION">13N/14D [without Airfare]</td>
                          <td data-title="COST">Rs 55,599/P [for 6 pax]</td>
                          <td data-title="Book Now">
                            <center>
                              <a href="#" className="btn btn-success" onClick={this.onRequestCallback}>Book Now</a>
                            </center>
                          </td>
                        </tr>
                        <tr>
                          <td data-title="CATEGORY">Arunachal Package Tour from Guwahati</td>
                          <td data-title="DURATION">15N/16D [without Airfare]</td>
                          <td data-title="COST">Rs 48,999/P [for 6 pax]</td>
                          <td data-title="Book Now">
                            <center>
                              <a href="#" className="btn btn-success" onClick={this.onRequestCallback}>Book Now</a>
                            </center>
                          </td>
                        </tr>
                        <tr>
                          <td data-title="CATEGORY">West Arunachal Tour Package</td>
                          <td data-title="DURATION">9N/10D [without Airfare]</td>
                          <td data-title="COST">Rs 31,999/P [for 6 pax]</td>
                          <td data-title="Book Now">
                            <center>
                              <a href="#" className="btn btn-success" onClick={this.onRequestCallback}>Book Now</a>
                            </center>
                          </td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br /> <br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> WHY CHOOSE A <strong>SHILLONG MEGHALAYA CHERRAPUNJI TOUR</strong> DURING THE 2024 MONSOON HOLIDAYS?</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>WHY CHOOSE A <strong>SHILLONG MEGHALAYA CHERRAPUNJI TOUR</strong> DURING THE 2024 MONSOON HOLIDAYS?</h4>
                </div>

                <div className="card-body">
                  <p>Choosing a <strong>Shillong  Meghalaya Cherrapunji Tour Package</strong> during the 2024 monsoon season offers a unique and unforgettable experience for several reasons:</p>
                  <p><b>LUSH GREENERY</b></p>
                  <p>During the monsoon, Shillong and Cherrapunji receive heavy rainfall, resulting in vibrant green landscapes. The hills and valleys become lush and picturesque, perfect for nature lovers and photographers. To fully experience North East India, book your <strong>Meghalaya Cherrapunji Shillong Package Tour</strong> during the monsoon. It's amazing.</p>
                  <p><b>WATERFALLS IN FULL FLOW</b></p>
                  <p>The monsoon season is the best time to see the waterfalls at their peak. Cherrapunji, one of the wettest places on Earth, has magnificent waterfalls like Nohkalikai Falls, Seven Sisters Falls, and Dainthlen Falls. The increased rainfall ensures these waterfalls are at their most impressive.</p>
                  <p><b>PLEASANT WEATHER</b></p>
                  <p>Despite the rain, the temperature remains moderate in Shillong and Cherrapunji during the monsoon. The rain keeps the atmosphere cool and refreshing, providing relief from the summer heat experienced in other parts of the country.</p>
                  <p><b>ADVENTURE ACTIVITIES</b></p>
                  <p>Enjoy adventure and cultural activities with <strong>Shillong Cherrapunji Meghalaya Package Tour</strong>. Trek along rain-soaked trails, explore mystical caves, and experience river rafting in the monsoon. Immerse yourself in the region's rich culture by participating in traditional festivals and savoring local delicacies.</p>
                  <p><b>CULTURAL FESTIVALS</b></p>
                  <p>Shillong and Cherrapunji have diverse tribal communities and rich cultural traditions. During the monsoon, various festivals and events take place, giving visitors a chance to experience local traditions, music, and cuisine of <strong>Shillong Meghalaya Package Tour</strong>.</p>
                  <p><b>LESS CROWDED</b></p>
                  <p>Unlike the peak tourist season, the monsoon months see fewer tourists. This allows you to enjoy the beauty of <strong>Shillong Cherrapunji Meghalaya Tour</strong> in a more peaceful atmosphere, exploring attractions without the crowds.</p>
                  <p><b>BUDGET-FRIENDLY</b></p>
                  <p>Traveling during the monsoon often means lower prices for accommodations and tour packages. You can take advantage of off-season discounts and deals, making your ,<strong>Shillong Meghalaya Cherrapunji Tour</strong> from Guwahati more budget-friendly.</p>
                  <p>Escape the crowds and embrace serenity with our <strong>Shillong Cherrapunji Tour Packages</strong>. Enjoy the tranquility of off-season travel with budget-friendly accommodations and exclusive deals. From Guwahati to bespoke itineraries tailored to your preferences, embark on a memorable journey through <strong>Shillong Meghalaya Cherrapunji Tour Package</strong> with your trusted travel partner Adorable Vacation LLP.</p>
                  <br />
                  <div className="content-desktop">
                    <center><img src={sightseen2ImageURL} alt="..." /></center>
                  </div>

                  <div className="content-mobile2">
                    <center><img src={sightseen2ImageURL} style={{ width: '320px' }} alt="..." /></center>
                  </div>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>TOP LUXURY HOTELS FOR YOUR <strong>SHILLONG MEGHALAYA TOUR FROM GUWAHATI AIRPORT</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>TOP LUXURY HOTELS FOR YOUR <strong>SHILLONG MEGHALAYA TOUR FROM GUWAHATI AIRPORT</strong></h4>
                </div>
                <div className="card-body">
                  <div id="no-more-tables">
                    <table class="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead class="cf">
                        <tr className="table-danger">
                          <th>HOTEL NAME</th>
                          <th>HOTEL LOCATION</th>
                          <th>HOTEL RATING</th>
                          <th>CONTACT US</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td data-title="HOTEL NAME">Polo Orchid Resort, Cherrapunji</td>
                          <td data-title="LOCATION">Address: Mawsmai, Nongthymmai, Cherrapunji, Meghalaya 793108</td>
                          <td data-title="RATING">3-star hotel</td>
                          <td data-title="Book Now"><center><a href="#" className="btn-sm btn-success" onClick={this.onRequestCallback}>
                            Book Now
                          </a></center></td>
                        </tr>
                        <tr>
                          <td data-title="HOTEL NAME">Cherrapunjee Holiday Resort</td>
                          <td data-title="LOCATION">Address: Laitkynsew View Point Rd, Village, Cherrapunji, Sohsarat, Meghalaya 793108</td>
                          <td data-title="RATING">3-star hotel</td>
                          <td data-title="Book Now"> <center><a href="#" className="btn-sm btn-success" onClick={this.onRequestCallback}>
                            Book Now
                          </a></center></td>
                        </tr>
                        <tr>
                          <td data-title="HOTEL NAME">Saimika Resort</td>
                          <td data-title="LOCATION">Address: Cherrapunji, Meghalaya 793108</td>
                          <td data-title="RATING">Resort hotel</td>
                          <td data-title="Book Now">
                            <center>
                              <a href="#" className="btn-sm btn-success" onClick={this.onRequestCallback}>Book Now</a>
                            </center>
                          </td>
                        </tr>
                        <tr>
                          <td data-title="HOTEL NAME">Jiva Resort</td>
                          <td data-title="LOCATION">Address: Saitsohpen, Near Kutmadan, Cherrapunji, Meghalaya 793108</td>
                          <td data-title="RATING">3-star hotel</td>
                          <td data-title="Book Now">
                            <center>
                              <a href="#" className="btn-sm btn-success" onClick={this.onRequestCallback}>Book Now</a>
                            </center>
                          </td>
                        </tr>
                        <tr>
                          <td data-title="HOTEL NAME">San-Neil-La</td>
                          <td data-title="LOCATION">Address: Land mark - Near NIT, SOHRA, Pynshadkhurai - Shella Road Saitsohpen, Cherrapunji, Meghalaya 793108</td>
                          <td data-title="RATING">Resort hotel</td>
                          <td data-title="Book Now">
                            <center>
                              <a href="#" className="btn-sm btn-success" onClick={this.onRequestCallback}>Book Now</a>
                            </center>
                          </td>
                        </tr>
                        <tr>
                          <td data-title="HOTEL NAME">CORDIAL lodge Cherrapunjee</td>
                          <td data-title="LOCATION">Address: Sohra-Shella Rd, Cherrapunji, Meghalaya 793111</td>
                          <td data-title="RATING">Star</td>
                          <td data-title="Book Now">
                            <center>
                              <a href="#" className="btn-sm btn-success" onClick={this.onRequestCallback}>Book Now</a>
                            </center>
                          </td>
                        </tr>
                        <tr>
                          <td data-title="HOTEL NAME">Ka Bri War Resort</td>
                          <td data-title="LOCATION">Address: Nongshyrngan, Dawki, Pynursla, Meghalaya 793110</td>
                          <td data-title="RATING">Resort hotel</td>
                          <td data-title="Book Now">
                            <center>
                              <a href="#" className="btn-sm btn-success" onClick={this.onRequestCallback}>Book Now</a>
                            </center>
                          </td>
                        </tr>
                        <tr>
                          <td data-title="HOTEL NAME">Four Season ECO Resort</td>
                          <td data-title="LOCATION">Address: Eco Park, Cherrapunji, Meghalaya 793108</td>
                          <td data-title="RATING">Resort hotel</td>
                          <td data-title="Book Now">
                            <center>
                              <a href="#" className="btn-sm btn-success" onClick={this.onRequestCallback}>Book Now</a>
                            </center>
                          </td>
                        </tr>
                        <tr>
                          <td data-title="HOTEL NAME">La Nicholas Dei Da Kine Resort</td>
                          <td data-title="LOCATION">Address: UMBIR-MAIRANG ROAD, NEAR SILOAM, Umbir Village, Shillong, Meghalaya 793122</td>
                          <td data-title="RATING">Resort hotel</td>
                          <td data-title="Book Now">
                            <center>
                              <a href="#" className="btn-sm btn-success" onClick={this.onRequestCallback}>Book Now</a>
                            </center>
                          </td>
                        </tr>
                        <tr>
                          <td data-title="HOTEL NAME">Ri Kynjai - Serenity By The Lake</td>
                          <td data-title="LOCATION">Address: Umniuh Khwan, Road, UCC, Meghalaya 793122</td>
                          <td data-title="RATING">4-star hotel</td>
                          <td data-title="Book Now">
                            <center>
                              <a href="#" className="btn-sm btn-success" onClick={this.onRequestCallback}>Book Now</a>
                            </center>
                          </td>
                        </tr>
                        <tr>
                          <td data-title="HOTEL NAME">Tripura Castle</td>
                          <td data-title="LOCATION">Address: HV5R+78F, Tripura Castle Rd, Risa Colony, Malki, Shillong, Meghalaya 793014</td>
                          <td data-title="RATING">3-star hotel</td>
                          <td data-title="Book Now">
                            <center>
                              <a href="#" className="btn-sm btn-success" onClick={this.onRequestCallback}>Book Now</a>
                            </center>
                          </td>
                        </tr>
                        <tr>
                          <td data-title="HOTEL NAME">Hotel M Crown</td>
                          <td data-title="LOCATION">Address: 3rd floor, MarBa Hub, Police Bazar, Shillong, Meghalaya 793001</td>
                          <td data-title="RATING">Resort hotel</td>
                          <td data-title="Book Now">
                            <center>
                              <a href="#" className="btn-sm btn-success" onClick={this.onRequestCallback}>Book Now</a>
                            </center>
                          </td>
                        </tr>
                        <tr>
                          <td data-title="HOTEL NAME">The Majestic Hotel</td>
                          <td data-title="LOCATION">Address: Me-ba-ai Plaza, Polo Market, Police Bazar, Shillong, Meghalaya 793001</td>
                          <td data-title="RATING">Resort hotel</td>
                          <td data-title="Book Now">
                            <center>
                              <a href="#" className="btn-sm btn-success" onClick={this.onRequestCallback}>Book Now</a>
                            </center>
                          </td>
                        </tr>

                      </tbody >
                    </table >
                  </div >
                </div >
              </div >
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> IS MONSOON A GOOD TIME TO BOOK A <strong>SHILLONG PACKAGE TOUR?</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>IS MONSOON A GOOD TIME TO BOOK A <strong>SHILLONG PACKAGE TOUR?</strong></h4>
                </div>

                <div className="card-body">
                  <p>The answer is YES, definitely. Monsoon in Cherrapunji and Mawsynram is different from anywhere else in India. Mawsynram holds the Guinness World Record for being the wettest place on earth, with around 11,871mm of rainfall each year. You might want to experience this unique weather!</p>
                  <p>During the monsoon, Cherrapunji and Mawsynram are especially charming. They become a paradise with lush greenery, full lakes, and stunning waterfalls. This is the best time for honeymooners, adventure lovers, and rain enthusiasts. There's nothing like sipping warm tea or coffee while listening to the heavy rain. The sound of rain in Mawsynram is incredibly loud and beautiful. Rain brings everything to life, including travelers. If you want to explore rain-washed Shillong, Cherrapunji, and Mawsynram, book your <strong>Meghalaya Shillong Cherrapunji Tour</strong> during the monsoon from July to September with Adorable Vacation LLP at a discounted price. It will be an amazing experience!</p>
                  <br />
                  <div className="content-desktop">
                    <center><img src={sightseen3ImageURL} alt="..." /></center>
                  </div>

                  <div className="content-mobile2">
                    <center><img src={sightseen3ImageURL} style={{ width: '320px' }} alt="..." /></center>
                  </div>
                  <br/>
                  <p><b>WHAT IS THE WEATHER LIKE IN SHILLONG TOUR PACKAGE?</b></p>
                  <p className="p-2">Shillong has a subtropical highland climate, similar to an oceanic climate, with cool, rainy summers and cold, dry winters. The table below shows Shillong's weather month by month.</p>
                  <div id="no-more-tables">
                    <table class="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead class="cf">
                        <tr className="table-danger">
                          <th>TRAVEL MONTH</th>
                          <th>MIN / MAX TEMPERATURE</th>
                          <th>SEASON</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td data-title="MONTH">March to June</td>
                          <td data-title="MIN / MAX TEMP">12° - 24°C</td>
                          <td data-title="SEASON">Pleasant</td>
                        </tr>
                        <tr>
                          <td data-title="MONTH">July to September</td>
                          <td data-title="MIN / MAX TEMP">10° - 22°C</td>
                          <td data-title="SEASON">Rainy Season</td>
                        </tr>
                        <tr>
                          <td data-title="MONTH">October to February</td>
                          <td data-title="MIN / MAX TEMP">5° - 14°C</td>
                          <td data-title="SEASON">Cold</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br />
                  <p><b>WHAT IS THE WEATHER LIKE IN CHERRAPUNJI TOUR PACKAGE?</b></p>
                  <p className="p-2">Cherrapunji gets a lot of rain throughout the year, making it humid. It's usually cool and pleasant to visit almost any time. August is the warmest and wettest month, while January is the coolest. The table below shows Cherrapunji's weather month by month.</p>
                  <div id="no-more-tables">
                    <table class="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead class="cf">
                        <tr className="table-danger">
                          <th>TRAVEL MONTH</th>
                          <th>MIN / MAX TEMPERATURE</th>
                          <th>SEASON</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td data-title="MONTH">March to June</td>
                          <td data-title="MIN / MAX TEMP">15° - 25°C</td>
                          <td data-title="SEASON">Warm but Pleasant</td>
                        </tr>
                        <tr>
                          <td data-title="MONTH">July to September</td>
                          <td data-title="MIN / MAX TEMP">18° - 28°C</td>
                          <td data-title="SEASON">Warm and Wet</td>
                        </tr>
                        <tr>
                          <td data-title="MONTH">October to February</td>
                          <td data-title="MIN / MAX TEMP">8° - 15°C</td>
                          <td data-title="SEASON">Cold & Pleasant</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> HOW TO REACH SHILLONG?</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>HOW TO REACH SHILLONG?</h4>
                </div>

                <div className="card-body">
                  <p className="p-2">You can reach Shillong in several ways. The nearest airport is Guwahati Airport [GAU], which has many domestic flights from across India and a few international flights. You can also fly into Shillong Airport [SHL], which is about an hour's drive from the city, though traffic can sometimes make the trip longer. Another option is Silchar Airport [IXS] in Assam. If you prefer traveling by train, you can go to Guwahati Station and then drive 100 km (about 3 hours) to Shillong. The table below shows more details:</p>

                  <div id="no-more-tables">
                    <table class="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead class="cf">
                        <tr className="table-danger">
                          <th>FROM</th>
                          <th>TO</th>
                          <th>DISTANCE/TIME</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td data-title="FROM">Shillong Airport</td>
                          <td data-title="TO">Shillong</td>
                          <td data-title="DISTANCE/TIME">55 min (30.9 km) via Shillong Bypass Rd and NH6</td>
                        </tr>
                        <tr>
                          <td data-title="FROM">Guwahati Airport</td>
                          <td data-title="TO">Shillong</td>
                          <td data-title="DISTANCE/TIME">2 hr 59 min (115.1 km) via NH6</td>
                        </tr>
                        <tr>
                          <td data-title="FROM">Silchar Airport</td>
                          <td data-title="TO">Shillong</td>
                          <td data-title="DISTANCE/TIME">4 hr 18 min (235.8 km) via NH6</td>
                        </tr>
                        <tr>
                          <td data-title="FROM">Guwahati Station</td>
                          <td data-title="TO">Shillong</td>
                          <td data-title="DISTANCE/TIME">2 hr 27 min (96.3 km) via NH6</td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                  <br />
                  <p><b>HOW TO REACH NORTH EAST INDIA FOR A </b><strong>CHERRAPUNJI PACKAGE TOUR?</strong></p>
                  <p className="p-2">You can reach Cherrapunji by traveling to Guwahati Station or Guwahati Airport, which has many domestic flights and a few international ones. From there, Cherrapunji is about 148 km away.</p>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>IMPORTANT DISTANCES FOR <strong>SHILLONG MEGHALAYA CHERRAPUNJI PACKAGE TOUR</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>IMPORTANT DISTANCES FOR <strong>SHILLONG MEGHALAYA CHERRAPUNJI PACKAGE TOUR</strong></h4>
                </div>
                <br />
                <p className="p-3">There are many attractions in Meghalaya and Shillong that we will explore on this tour. Some of the places include Shillong City, Umiam Lake, Elephant Falls, Shillong Peak, Spread Eagle Falls, Ward's Lake, Cathedral Catholic Church, Cherrapunji, Seven Sisters Falls, Mawsmai Cave, Nohkalikai Falls, Eco Park, Double Decker Living Root Bridge, Mawsynram, Jowai, Krang Suri Falls, Thadlaskein Lake, Stone Bridge, Tura, Mawlynnong, Dawki, Balpakram National Park, and Kyllang Rock.</p>
                <br />
                  <div className="content-desktop">
                    <center><img src={sightseen4ImageURL} alt="..." /></center>
                  </div>

                  <div className="content-mobile2">
                    <center><img src={sightseen4ImageURL} style={{ width: '320px' }} alt="..." /></center>
                  </div>
                  <br/>
                <br />
                <div className="card-body">
                  <div id="no-more-tables">
                    <table class="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead class="cf">
                        <tr className="table-danger">
                          <th>Place</th>
                          <th>DISTANCE/TIME</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td data-title="PLACES">Cherrapunjee to Double Decker Living Roots Bridge</td>
                          <td data-title="DISTANCE/TIME">54 min (21.2 km) via Dainthlen Road</td>
                        </tr>
                        <tr>
                          <td data-title="PLACES">Shillong to Nohkalikai Falls</td>
                          <td data-title="DISTANCE/TIME">1 hr 59 min (54.5 km) via SH 5</td>
                        </tr>
                        <tr>
                          <td data-title="PLACES">Cherrapunjee to Mawsmai Cave</td>
                          <td data-title="DISTANCE/TIME">10 min (3.6 km) via Sohra-Shella Rd</td>
                        </tr>
                        <tr>
                          <td data-title="PLACES">Mawlynnong to Dawki</td>
                          <td data-title="DISTANCE/TIME">1 hr 7 min (29.4 km) via NH206</td>
                        </tr>
                        <tr>
                          <td data-title="PLACES">Shillong to Dawki</td>
                          <td data-title="DISTANCE/TIME">2 hr 43 min (81.8 km) via NH206</td>
                        </tr>
                        <tr>
                          <td data-title="PLACES">Shillong to Mawlynnong</td>
                          <td data-title="DISTANCE/TIME">2 hr 35 min (73.0 km) via NH206</td>
                        </tr>
                        <tr>
                          <td data-title="PLACES">Shillong to Shillong Peak</td>
                          <td data-title="DISTANCE/TIME">46 min (10.6 km) via NH6</td>
                        </tr>
                        <tr>
                          <td data-title="PLACES">Shillong to Elephanta Falls</td>
                          <td data-title="DISTANCE/TIME">38 min (12.5 km) via NH106</td>
                        </tr>
                        <tr>
                          <td data-title="PLACES">Shillong to Umiam Lake</td>
                          <td data-title="DISTANCE/TIME">41 min (17.0 km) via NH6</td>
                        </tr>
                        <tr>
                          <td data-title="PLACES">Guwahati to Umiam Lake</td>
                          <td data-title="DISTANCE/TIME">2 hr 28 min (81.9 km) via NH6</td>
                        </tr>
                        <tr>
                          <td data-title="PLACES">Shillong to Cherrapunji</td>
                          <td data-title="DISTANCE/TIME">1 hr 57 min (54.0 km) via SH 5</td>
                        </tr>
                        <tr>
                          <td data-title="PLACES">Guwahati to Shillong</td>
                          <td data-title="DISTANCE/TIME">3 hr 18 min (98.9 km) via NH6</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>TOP 10 ATTRACTIONS IN <strong>MEGHALAYA PACKAGE TOUR</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>TOP 10 ATTRACTIONS IN <strong>MEGHALAYA PACKAGE TOUR</strong></h4>
                </div>
                <div className="card-body">
                  <p>Northeast India is a mysterious and amazing place to visit. When traveling with a North East tour package from Guwahati, make sure to visit these places:</p>
                  <ul>
                    <li><strong>Shillong :</strong>  Known as the Scotland of the East</li>
                    <li><strong>Cherrapunji :</strong> Also called Sohra or Churra, known as "The Land of Oranges"</li>
                    <li><strong>Mawsynram :</strong> One of the wettest places on Earth, with deep caves and waterfalls</li>
                    <li><strong>Jowai :</strong> Known as the Heart of Meghalaya</li>
                    <li><strong>Tura :</strong> A paradise for adventure lovers</li>
                    <li><strong>Mawlynnong :</strong> The Cleanest Village in India</li>
                    <li><strong>Dawki :</strong> Known for its stunning crystal-clear river waters</li>
                    <li><strong>Balpakram National Park :</strong> Famous for its forest-covered canyon-cum-gorge</li>
                    <li><strong>Kyllang Rock :</strong> A red-stoned rock famous for its stunning city views</li>
                    <li><strong>Double Decker Living Root Bridge :</strong> A remarkable man-made marvel</li>
                  </ul>
                </div>
              </div>
              <br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>BEST THINGS TO DO IN <strong>SHILLONG CHERRAPUNJI PACKAGE TOUR</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>BEST THINGS TO DO IN <strong>SHILLONG CHERRAPUNJI PACKAGE TOUR</strong></h4>
                </div>
                <div className="card-body">
                  <ul>
                    <li>•	Enjoying the stunning beauty of Shillong through photography</li>
                    <li>• Watching birds and exploring nature</li>
                    <li>• Taking leisurely walks around the beautiful streets of Shillong</li>
                    <li>• Shopping for handmade items in Shillong</li>
                    <li>• Participating in water sports like yachting, angling, and water skiing at Umiam Lake</li>
                    <li>• Boating and rappelling down waterfalls at Umiam Lake</li>
                    <li>• Tasting local delicacies in Shillong</li>
                    <li>• Exploring the Double Decker Living Root Bridge Trail</li>
                    <li>• Trekking in Mawlongbna Village</li>
                    <li>• Trekking and boating on the Dawki River bed</li>
                    <li>• Hiking David Scott's Trail</li>
                    <li>• Exploring caves like Krem Mawsmai, Krem Mawmluh, and Krem Umshyrpi</li>
                    <li>• Experiencing the vibrant nightlife of Shillong</li>
                  </ul>
                </div>
              </div>
              <br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>TOP TIPS FOR <strong>SHILLONG MEGHALAYA PACKAGE TOUR</strong> BOOKING</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>TOP TIPS FOR <strong>SHILLONG MEGHALAYA PACKAGE TOUR</strong> BOOKING</h4>
                </div>
                <div className="card-body">
                  <ul>
                    <li>•	Pack a raincoat or umbrella for your visit to Mawsynram, where it rains a lot.</li>
                    <li>• Booking a Shillong or Shillong Cherrapunji Mawsynram package tour during the monsoon lets you experience beautiful rain and waterfalls.</li>
                    <li>• Start sightseeing early in the morning to cover more attractions, as advised by Adorable Vacation LLP.</li>
                    <li>• Carry your valuables like money, credit cards, camera, and mobile during your Shillong Cherrapunji sightseeing tour.</li>
                    <li>• Remember that like most hill areas, Shillong shuts down by 8 to 9 pm, so finish dinner early.</li>
                    <li>• Carry original photo identification like Passport, Voter ID Card, or Driving License.</li>
                    <li>• Try local delicacies in Shillong Meghalaya.</li>
                    <li>• Taste fresh pineapple from local vendors on your way to Shillong for an amazing flavor.</li>
                    <li>• Always have bottled water with you during hotel stays and sightseeing tours.</li>
                    <li>• Bring your ID cards for the Shillong Peak tour, which is in a military area.</li>
                    <li>• Pack a power bank for your mobile.</li>
                    <li>• Include essential medicines and a first aid kit in your travel bag before starting your Shillong Meghalaya Cherrapunji tour.</li>
                    <li>• Carry a torch, candle, and lighter for emergencies.</li>
                    <li>• Have at least two different mobile connections during your Meghalaya trip.</li>
                    <li>• For the Double Decker Root Bridge, be prepared for a challenging trek of at least 2 hours over hills, so wear sturdy trekking shoes.</li>
                  </ul>
                </div>
              </div>
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h3 className="content-desktop" style={{ fontSize: "2.0vw" }}>
                        DAY WISE DETAIL <strong style={{ color: 'tomato' }}>SHILLONG MEGHALAYA TOUR PACKAGE ITINERARY</strong>
                      </h3>
                      <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>
                        DAY WISE DETAIL <strong>SHILLONG MEGHALAYA TOUR PACKAGE ITINERARY</strong>
                      </h3>
                    </div>
                  </div>
                </div>

                <br />
                <div className="row">
                  <div
                    className="accordion"
                    id="accordionPanelsStayOpen"
                  >
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="day1"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseOne"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseOne"
                        >
                          DAY 1 : ARRIVE AT GUWAHATI & TRANSFER TO SHILLONG
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingOne"
                      >
                        <div className="accordion-body">
                          <p>You will be received at the airport station in Guwahati by our representative. Then transferred to Shillong, shortly after which we will take a halt to witness the breath-taking sight of Umium Lake, which is considered to be the largest man-made lake of the North East. Check-in at a pre-booked hotel in Shillong.</p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay1ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay1ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="day2">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseTwo"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseTwo"
                        >
                          DAY 2 : SHILLONG - MAWLYNNONG - DAWKI – CHERRAPUNJEE
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseTwo"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingTwo"
                      >
                        <div className="accordion-body">
                          <p>Morning after breakfast we will proceed to visit the cleanest village of Asia - Mawlynnong. You will also get a chance to explore the nearby villages and witness the amazing living root bridges. Later on, you will be transferred to the international border of Bangladesh, wherein you will be visiting Dawki Village. This village is known for having some of the cleanest rivers, a classic example of which is the enchanting Umngot River.</p>
                          <p><b>Later on, you will go to Cherrapunjee for a relaxing overnight stay.</b></p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay2ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay2ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="day3">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseThree"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          DAY 3 : CHERRAPUNJEE – NONGRIAT TREK DOUBLE DECKER BRIDGE
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseThree"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingThree"
                      >
                        <div className="accordion-body">
                          <p>After breakfast, we proceed for a full day trekking to the Double Decker Living Root Bridge at Nongriat Village. We have to trek down 3200 steps approximately each way to the Double Decker Living Root Bridge (7-hour trek). Evening returns to Cherrapunjee. </p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay3ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay3ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="day4">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseFour"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseFour"
                        >
                          DAY 4 : CHERRAPUNJEE LOCAL SIGHTSEEING AND TRANSFER TO SHILLONG [APPROX. 2HRS]
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseFour"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingFour"
                      >
                        <div className="accordion-body">
                          <p>Entire Day Cherrapunjee Local, Includes, Today post breakfast, you will proceed to Shillong. En-route visit</p>
                          <ul>
                            <li>● Wei Sawdong Falls</li>
                            <li>● Seven Sisters Waterfalls</li>
                            <li>● Arwah Cave</li>
                            <li>● Nohkalikai Falls</li>
                            <li>● Mawsmai Cave</li>
                          </ul>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay4ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay4ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="day5">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseFive"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseFive"
                        >
                          DAY 5 : SHILLONG – JOWAI – SHILLONG
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseFive"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingFive"
                      >
                        <div className="accordion-body">
                          <p>After breakfast, start for Jowai to visit India's most magical waterfall:</p>
                          <ul>
                            <li>• Krang Suri Waterfalls</li>
                            <li>• Laitlum Canyon</li>
                            <li>• Thadlaskein Lake</li>
                            <li>• Stone Bridge. </li>
                          </ul>
                          <p>After getting there, get drenched and swim in the natural pools. Evening returns & free for Leisure.</p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay5ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay5ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="day6">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseSix"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseSix"
                        >
                          DAY 6 : SHILLONG TO GUWAHATI TRANSFER
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseSix"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingSix"
                      >
                        <div className="accordion-body">
                          <p>The morning after breakfast, check out from the hotel & transfer to Guwahati. </p>
                          <p>If time permits, an afternoon visit to Basistha Ashram, Srimanta Sankaradeva Kalakshetra, ‘Jagran’ store for authentic Assamese silks & handicrafts.  </p>
                          <p>Evening returns & free for Leisure.</p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay6ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay6ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="day7">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseSeven"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseSeven"
                        >
                          DAY 7 : DEPARTURE FROM GUWAHATI AIRPORT
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseSeven"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingSeven"
                      >
                        <div className="accordion-body">
                          <p>After breakfast check out from the hotel and drop at Guwahati Airport as per timings. The trip ends with memories.</p>
                          <br />
                          {/* <div className="content-desktop">
                            <center><img src={navDay7ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay7ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br /><br />
              {/* inclusion start  */}

              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">{<InclusionExclusion />}</div>
                </div>
              </div>

              {/* inclusion End  */}

              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h2>
                        Frequently Ask <span>Questions</span>
                      </h2>
                      <h4><strong>SHILLONG MEGHALAYA PACKAGE TOUR</strong></h4>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div
                    className="accordion accordion-flush faQStyle"
                    id="accordionFlushExample"
                  >
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingOne">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseOne"
                          aria-expanded="false"
                          aria-controls="flush-collapseOne"
                        >
                          What is the Wangala Festival?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseOne"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingOne"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          The Wangala Festival, also known as the Hundred Drums Festival, is one of the most celebrated festivals in Meghalaya. It takes place in the second week of November each year in Asanang village near Tura in the Garo Hills. This post-harvest festival marks the end of agriculture and is a thanksgiving celebration to the god of fertility. The festival is renowned for its music, drums, orchestra, and flutes, creating a festive atmosphere. Booking a Shillong Meghalaya Tour Package during November with Adorable Vacation LLP allows you to experience this festival up close.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingTwo">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseTwo"
                          aria-expanded="false"
                          aria-controls="flush-collapseTwo"
                        >
                          When is the best time to visit Shillong?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseTwo"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingTwo"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          The best time to visit Shillong is from October to April. Meghalaya can be visited year-round, but the waterfalls are most impressive during the summer months. From June to September, heavy monsoon rains can cause landslides and road closures.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingThree">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseThree"
                          aria-expanded="false"
                          aria-controls="flush-collapseThree"
                        >
                          Q. What is the nightlife like in Shillong?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseThree"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingThree"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Shillong has a vibrant nightlife that entertains both tourists and locals. It boasts renowned restaurants and bars offering good alcoholic beverages and food. Many places feature live musicians or DJs to enhance the evening atmosphere.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingFour">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFour"
                          aria-expanded="false"
                          aria-controls="flush-collapseFour"
                        >
                          Q. Which cellular network is best to use during our Shillong Meghalaya Tour Package?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseFour"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingFour"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Shillong has good coverage with networks like BSNL, Vodafone, Jio, and Airtel. You can carry a SIM card from any of these networks during your Shillong Meghalaya Tour.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingFive">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFive"
                          aria-expanded="false"
                          aria-controls="flush-collapseFive"
                        >
                          Q. What souvenirs can I buy during the Shillong Cherrapunjee Meghalaya Package Tour?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseFive"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingFive"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Shillong offers a variety of unique items to purchase. During your tour, you can buy Tibetan handicrafts, knick-knacks, forest honey, Khasi dresses, bamboo pickles, shawls and stoles, silk and cotton products to take home as souvenirs.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingSix">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseSix"
                          aria-expanded="false"
                          aria-controls="flush-collapseSix"
                        >
                          Q. Are there trekking routes to explore during our Shillong Cherrapunjee Meghalaya Tour?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseSix"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingSix"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Yes, Shillong has beautiful trekking routes that allow visitors to explore and enjoy the untouched beauty of the region.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingSeven">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseSeven"
                          aria-expanded="false"
                          aria-controls="flush-collapseSeven"
                        >
                          Q. Is Meghalaya safe for solo travelers?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseSeven"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingSeven"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Meghalaya is one of the safest states for solo travelers. There is no insurgency in the state, making it ideal for travelers who want to enjoy their own time in the mountains.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingEight">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseEight"
                          aria-expanded="false"
                          aria-controls="flush-collapseEight"
                        >
                          Q. Are Cherrapunji and Mawsynram the same place?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseEight"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingEight"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          No, Cherrapunji and Mawsynram are separate places about 10 miles apart. Mawsynram receives slightly more rainfall and holds the title of the Wettest Place on Earth by just 4 inches.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingNine">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseNine"
                          aria-expanded="false"
                          aria-controls="flush-collapseNine"
                        >
                          Q. Do we need a guide to visit the Living Root Bridge in Cherrapunji?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseNine"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingNine"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Generally, a guide is not required as the path to the Living Root Bridge is well-marked with good staircases to make the journey easier.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingTen">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseTen"
                          aria-expanded="false"
                          aria-controls="flush-collapseTen"
                        >
                          Q. Which is the cleanest river in India?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseTen"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingTen"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          The Umngot River in Dawki, Meghalaya, is known as the cleanest river in India. Boating on its transparent waters, where you can see the riverbed clearly, is a major attraction in the area.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingEleven">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseEleven"
                          aria-expanded="false"
                          aria-controls="flush-collapseEleven"
                        >
                          Q. What is the national festival of Meghalaya?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseEleven"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingEleven"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          The Wangala Festival, also known as the Hundred Drums Festival, is considered the national festival of Meghalaya. It celebrates the post-harvest season and takes place annually in Asanang village near Tura in the Garo Hills.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingTwelve">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseTwelve"
                          aria-expanded="false"
                          aria-controls="flush-collapseTwelve"
                        >
                          Q. Which is the most beautiful state in Northeast India?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseTwelve"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingTwelve"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Meghalaya, known as the "Abode of Clouds," is considered one of the most beautiful states in Northeast India. It offers a variety of sights, activities, food, and festivals for tourists to enjoy.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingThirteen">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseThirteen"
                          aria-expanded="false"
                          aria-controls="flush-collapseThirteen"
                        >
                          Q. How can I offer Puja at Kamakhya Temple without facing crowds?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseThirteen"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingThirteen"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          To avoid crowds, you can obtain a VIP Pass from the temple's counter, which opens around 7:00 AM. The pass costs Rs. 500 per person. After purchasing the ticket, you will receive darshan within the next hour, during which you can wait in the VIP waiting room at the temple.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingFourteen">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFourteen"
                          aria-expanded="false"
                          aria-controls="flush-collapseFourteen"
                        >
                          Q. What makes Meghalaya unique?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseFourteen"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingFourteen"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          As one of the Seven Sister states of Northeast India, Meghalaya is unique for its pristine beauty, perpetual clouds, misty landscapes, huge waterfalls, limestone caves, lush green forests, amazing living root bridges, and more, making it a captivating destination.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingFifteen">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFifteen"
                          aria-expanded="false"
                          aria-controls="flush-collapseFifteen"
                        >
                          Q. Why is Shillong famous?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseFifteen"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingFifteen"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Shillong is famous for being home to Cherrapunji and Mawsynram, known as the wettest places on Earth. The heavy rains here swell the waters of the Nohsngithiang waterfall, also known as the Seven Sister Waterfall. The town is also renowned for its limestone caves and orange honey.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingSixteen">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseSixteen"
                          aria-expanded="false"
                          aria-controls="flush-collapseSixteen"
                        >
                          Q. What makes Shillong a must-visit place?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseSixteen"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingSixteen"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Shillong is blessed with abundant nature and lush green forests. One of its treasures is the Mawphlang Sacred Forest, making it one of the top places to visit for nature lovers who wish to immerse themselves in nature's embrace.
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h3>
                        <b>
                          Shillong Meghalaya <span className="tomato">Tour Reviews</span>
                        </b>
                      </h3>
                      <h4>
                        <i>"Your Experience is our Marketing"</i>
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="row row-cols-1 ">
                  <div id="testimonials">
                    <div className="testimonial-box-container">
                      {reviewersDataView}
                    </div>
                  </div>
                </div>
              </div>
            </div >
            <div className="col-md-3">
              <BookingBox />
            </div>
            <div className="modal" style={{ display: this.state.showModal ? "block" : "none" }}>
                                <div className="modal-dialog">
                                    <div className="modal-content cust-modContent">
                                        <div className="modal-header">
                                            <h4 className="modal-title ms-auto" id="exampleModalLabel">Get a Free Callback &nbsp;&nbsp;<i className="fa fa-phone fa-rotate-90"></i>
                                            </h4>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={this.onCloseModal}></button>
                                        </div>
                                        <div className="modal-body">
                                            <form className="row g-3">
                                                <div className="col-md-12">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Name *"
                                                        name="firstName"
                                                        value={this.state.firstName}
                                                        onChange={this.onChangeHandler} />
                                                </div>
                                                {/* <div className="col-md-6">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Last Name *"
                                                        name="lastName"
                                                        value={this.state.lastName}
                                                        onChange={this.onChangeHandler} />
                                                </div> */}
                                                <div className="col-md-12">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="10 Digit Mobile No.*"
                                                        name="mobile"
                                                        value={this.state.mobile}
                                                        onChange={this.onChangeHandler} />
                                                </div>
                                                <div className="col-12">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Email *"
                                                        name="email"
                                                        value={this.state.email}
                                                        onChange={this.onChangeHandler} />
                                                </div>
                                                <div className="col-12">
                                                    <textarea
                                                        className="form-control"
                                                        placeholder="Comments"
                                                        rows="2"
                                                        name="comments"
                                                        value={this.state.comments}
                                                        onChange={this.onChangeHandler}></textarea>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="modal-footer">
                                            <div className='container'>
                                                <div className="row">
                                                    <div className='col-sm-4'>
                                                        {
                                                            (this.state.showLoader) &&
                                                            <div className='pull-left'><Digital size={25} /></div>
                                                        }
                                                    </div>
                                                    <div className='col-sm-8 '>
                                                        <div className='pull-right'>
                                                            <button type="button" className="btn btn-Warning" onClick={this.onRequestCallbackSubmit} >Submit</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
          </div >
          <div className="btn-whatsapp-pulse">
            <a style={{ color: 'white' }} href="#" onClick={this.handleClick}>
              <i className="fab fa-whatsapp"></i>
            </a>
          </div>
          <div class="btn-call-pulse">
            <a style={{ color: 'white' }} href="tel:+91-91470-61467">
              <i className="fa fa-phone"></i>
            </a>
          </div>
        </div >
      </>
    );
  }
}
export default ShillongMeghalayaTour_6N_7D_Package;
